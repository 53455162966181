import { useMemo, useContext, useCallback } from "react";

import {
  GridColDef,
  GridRowModel,
  GridColumnGroup,
  GridValueGetterParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

import { DSTooltip } from "@skillup/ui";

import useAreas from "hooks/useAreas";
import useTranslation from "hooks/useTranslation";
import { useEmployeeFields } from "hooks/useEmployees";
import { Field, useCoreHRFields } from "services/coreHR";
import { CampaignDetails, useCampaignReviews } from "services/peopleReview";
import type {
  GetFieldsDataParams,
  CampaignEmployeesData,
} from "services/peopleReview/reviews/getReviewsData";

import { SupervisionContext } from "../contexts";
import { TalentGridRow, TalentGridState } from "./../types";
import {
  statusColDef,
  userPopoverColDef,
  getActionMenuColDef,
  assignedActionsColDef,
} from "./../components/TalentGrid/Columns";

import styles from "../components/TalentGrid/TalentGrid.module.scss";

const generateUserDataColumn = ({
  areas,
  colConf,
}: {
  colConf: { key: string; label: string };
  areas: {
    uuid: string;
    name: string;
  }[];
}) => {
  const { key, label } = colConf;

  if (key === "reviewManager") {
    return {
      type: "string",
      field: key,
      headerName: label,
      minWidth: 150,
      valueGetter: ({ row }: GridValueGetterParams<TalentGridRow>) => {
        return row?.managerData?.fullName ?? "";
      },
    } satisfies GridColDef;
  }

  if (key === "areas") {
    return {
      type: "string",
      field: key,
      headerName: label,
      minWidth: 150,
      valueGetter: ({ row }: GridValueGetterParams<TalentGridRow>) => {
        return row?.userData?.areas
          ?.map((area) => areas.find((a) => a.uuid === area)?.name)
          ?.filter(Boolean) // some areas may have been deleted or are set to ""
          ?.join(", ");
      },
    } satisfies GridColDef;
  }

  // joinDate is a timestamp value this if statement allows us to parse it and format it as a human readable string
  if (key === "joinDate") {
    return {
      type: "string",
      field: key,
      headerName: label,
      minWidth: 150,
      valueGetter: ({ row }) => {
        return row?.userData?.joinDate
          ? new Date(row?.userData?.joinDate).toLocaleDateString()
          : "";
      },
    } satisfies GridColDef;
  }

  return {
    type: "string",
    field: key,
    headerName: label,
    minWidth: 150,
    valueGetter: ({ row }) => row?.userData?.[key] ?? "",
  } satisfies GridColDef;
};

const generateFieldColumn = ({ field, permissions }) => {
  const valueSetter = ({ row, value }) => {
    return {
      ...row,
      fieldsData: {
        ...row.fieldsData,
        [field.uuid]: {
          ...(row.fieldsData[field.uuid] || {}),
          coordinator: {
            coordinatorValue: isNaN(Number(value)) ? null : Number(value),
            createdAt: new Date().toISOString(),
          },
        },
      },
    };
  };

  const valueOptions = field.scale.options
    .filter((option) => option.isActive)
    .map((o) => ({
      label: o.label,
      value: o.value.toString(), // TODO Make backend send both correct formats
    }));

  // TODO: do not send empty strings as changes to back-end.
  // TODO: back-end should not allow empty strings as values. Both attitudes are possible:
  // - back-end ignores empty strings and does not update the value
  // - back-end throws an error => front-end should be more careful and handle the case.

  return {
    type: "singleSelect",
    editable: permissions.review.isEnabled,
    field: field.uuid,
    filterable: false,
    flex: 1,
    getOptionLabel: (option: (typeof field.scale.options)[number]) => option?.label ?? "",
    getOptionValue: (option: (typeof field.scale.options)[number]) => option?.value ?? "",
    headerName: field.label,
    minWidth: 150,
    valueGetter: ({ row }: GridValueGetterParams<TalentGridRow>) => {
      return (
        row?.fieldsData?.[field.uuid]?.coordinator?.coordinatorValue?.toString() ??
        row?.fieldsData?.[field.uuid]?.coordinator?.managerValue?.toString() ??
        ""
      );
    },
    valueOptions,
    valueSetter,
  } satisfies GridColDef;
};

const makeColumns = ({
  areas,
  campaignFields,
  employeeColumnsConf,
  hasManagerPrep,
  permissions,
  setState,
}: {
  campaignFields: Field[];
  hasManagerPrep: boolean;
  setState: (state: TalentGridState) => void;
  permissions: CampaignDetails["permissions"];
  areas: {
    uuid: string;
    name: string;
  }[];
  employeeColumnsConf?: { key: string; label: string }[];
}): GridColDef[] => {
  const fieldsColDefs = campaignFields.map((field) => {
    if (field.kind === "enumerable") return generateFieldColumn({ field, permissions });

    return {
      type: "string",
      editable: permissions.review.isEnabled,
      field: field.fieldKey,
      flex: 1,
      headerName: field.label,
      minWidth: 100,
    } satisfies GridColDef;
  });

  const userColumns = employeeColumnsConf.map((colConf) =>
    generateUserDataColumn({
      areas,
      colConf,
    })
  );

  const reviewColDef = {
    type: "string",
    cellClassName: "fake-editable--cell",
    field: "skillup_people_review_comment",
    filterable: false,
    flex: 1,
    headerName: "Commentaire coordinateur",
    minWidth: 200,
    valueGetter: ({ row }) => row?.comment ?? "",
  } satisfies GridColDef;

  const managerReviewCommentColDef = {
    type: "string",
    cellClassName: "fake-editable--cell",
    field: "skillup_people_review_manager_comment",
    filterable: false,
    flex: 1,
    headerName: "Commentaire manager",
    minWidth: 200,
    renderCell: ({ row }) => (
      <DSTooltip
        withPortal
        cropLongLabel={false}
        className={styles.tooltip}
        label={row?.managerPreparationComment ?? ""}
      >
        {row?.managerPreparationComment ?? ""}
      </DSTooltip>
    ),
  } satisfies GridColDef;

  return [
    userPopoverColDef,
    ...userColumns,
    statusColDef,
    ...fieldsColDefs,
    assignedActionsColDef,
    reviewColDef,
    hasManagerPrep && managerReviewCommentColDef,
    getActionMenuColDef({ hasManagerPrep, setState }),
  ].filter(Boolean);
};

const makeRows = ({
  campaignFields,
  campaignId,
  reviews,
}: {
  campaignId: string;
  campaignFields: Field[];
  reviews: CampaignEmployeesData["data"];
}): TalentGridRow[] => {
  return reviews.map(
    ({
      assignedActions,
      comment,
      data,
      managerData,
      managerPreparationComment,
      reviewID,
      status,
      userData,
    }) => {
      const { uuid, email, fullName, registrationNumber, role } = userData;
      const fieldsData = campaignFields.reduce<
        Record<string, CampaignEmployeesData["data"][number]["data"][number]>
      >((acc, { uuid: fieldUuid }) => {
        acc[fieldUuid] = data[fieldUuid] ?? null;
        return acc;
      }, {});

      const row: TalentGridRow = {
        id: reviewID,
        assignedActions,
        campaignId,
        comment,
        email,
        fieldsData,
        fullName,
        managerData: {
          id: managerData?.uuid,
          fullName: managerData?.fullName,
        },
        managerPreparationComment,
        registrationNumber, // TODO: Maybe delete this as we don't use it
        role,
        status,
        userData,
        userId: uuid,
      };

      return row;
    }
  );
};

export const useTalentGrid = (
  query: GetFieldsDataParams,
  {
    hasManagerPrep,
    setState,
  }: { hasManagerPrep: boolean; setState: (state: TalentGridState) => void }
) => {
  const { campaign } = useContext(SupervisionContext);
  const {
    data: employeeFieldsData,
    isError: isErrorEmployeeFieldsData,
    isLoading: isLoadingEmployeeFieldsData,
    mutations: employeeFieldsDataMutations,
    total: totalEmployeeFieldsData,
  } = useCampaignReviews({
    campaignID: campaign?.id,
    query,
  });

  const processRowUpdate = useCallback(
    (newRow: GridRowModel, original: GridRowModel) => {
      const { assignedActions, updateActions } = newRow;

      newRow.isNew = false;
      newRow.updateActions = undefined;

      const commentHasChanged = newRow.comment !== original.comment;
      const modifiedFields = campaign?.fields
        .map((fieldUuid) => {
          const fieldData = newRow?.fieldsData?.[fieldUuid];
          const originalFieldData = original?.fieldsData?.[fieldUuid]?.coordinator;

          if (
            fieldData?.coordinator?.coordinatorValue !== originalFieldData?.coordinatorValue &&
            fieldData?.coordinator?.coordinatorValue !== ""
          ) {
            return {
              fieldUuid,
              value: fieldData?.coordinator?.coordinatorValue,
            };
          }

          return undefined;
        })
        .filter(Boolean);

      if (modifiedFields.length > 0 || commentHasChanged) {
        newRow.status = "in_progress";
        employeeFieldsDataMutations.updateField({
          campaignId: campaign?.id,
          comment: newRow.comment,
          data: modifiedFields,
          employeeId: newRow.id,
        });
      }

      if (updateActions && assignedActions) {
        newRow.status = "in_progress";
        employeeFieldsDataMutations.assignActions({
          campaignID: campaign?.id,
          employeeActions: assignedActions
            .filter((a) => a.state !== "DENIED")
            .map((a) => ({
              actionID: a.actionID,
              coordinatorComment: a.coordinatorComment,
            })),
          employeeToReviewID: newRow.id,
        });
      }

      return newRow;
    },
    [campaign, employeeFieldsDataMutations]
  );

  const rows = useRows(employeeFieldsData);
  const columns = useColumns({ hasManagerPrep, setState });
  const groupingModel = useGroupingModel({ hasManagerPrep });

  const hiddenFields = useMemo(() => [GRID_CHECKBOX_SELECTION_COL_DEF.field], []);

  const getTogglableColumns = useCallback(
    (columns: GridColDef[]) => {
      return columns
        .filter((column) => !hiddenFields.includes(column.field))
        .map((column) => column.field);
    },
    [hiddenFields]
  );

  return {
    columns,
    getTogglableColumns,
    groupingModel,
    isErrorEmployeeFieldsData,
    isLoadingEmployeeFieldsData,
    processRowUpdate,
    rows,
    totalEmployeeFieldsData,
  };
};

function useRows(reviews: CampaignEmployeesData["data"]) {
  const { campaign } = useContext(SupervisionContext);
  const { fieldsMap } = useCoreHRFields();

  const rows = useMemo(() => {
    if (!campaign) return [];
    if (!reviews || !Object.keys(fieldsMap).length) {
      return [];
    }

    return makeRows({
      campaignFields: campaign.fields?.map((fieldUuid) => fieldsMap[fieldUuid]),
      campaignId: campaign.id,
      reviews: reviews,
    });
  }, [campaign, fieldsMap, reviews]);

  return rows;
}

function useColumns({
  hasManagerPrep,
  setState,
}: {
  hasManagerPrep: boolean;
  setState: (state: TalentGridState) => void;
}) {
  const { allAreas } = useAreas();
  const { campaign } = useContext(SupervisionContext);
  const { fieldsMap } = useCoreHRFields();
  const { getFields: getEmployeeFields } = useEmployeeFields();

  const { t } = useTranslation();

  const columns = useMemo(() => {
    if (!campaign?.fields || !Object.keys(fieldsMap).length) return [];

    let employeeColumnsConf = [];
    const employeeFields = getEmployeeFields();

    if (hasManagerPrep) {
      employeeColumnsConf = employeeColumnsConf.concat([
        {
          key: "reviewManager",
          label: "Manager",
        },
      ]);
    }

    employeeColumnsConf = employeeColumnsConf.concat(
      [
        employeeFields.firstName.key,
        employeeFields.lastName.key,
        employeeFields.email.key,
        employeeFields.role.key,
        employeeFields.areas.key,
        employeeFields.division.key,
        employeeFields.service.key,
        employeeFields.site.key,
        employeeFields.joinDate.key,
        employeeFields.contract.key,
        employeeFields.branch.key,
        employeeFields.registrationNumber.key,
      ].map((key) => ({
        key,
        label: t(employeeFields[key].traductionKey, {
          defaultValue: employeeFields[key].traductionDefaultValue,
        }),
      }))
    );

    return makeColumns({
      areas: allAreas,
      campaignFields: campaign?.fields?.map((fieldUuid) => fieldsMap[fieldUuid]),
      employeeColumnsConf,
      hasManagerPrep,
      permissions: campaign?.permissions,
      setState,
    });
  }, [
    campaign?.fields,
    campaign?.permissions,
    fieldsMap,
    getEmployeeFields,
    hasManagerPrep,
    allAreas,
    setState,
    t,
  ]);

  return columns;
}

function useGroupingModel({ hasManagerPrep }: { hasManagerPrep: boolean }) {
  const { getFields } = useEmployeeFields();
  const employeeFields = getFields();

  const { campaign } = useContext(SupervisionContext);
  const columnFields = useMemo(
    () => campaign?.fields?.map((fieldUuid) => ({ field: fieldUuid })) || [],
    [campaign?.fields]
  );

  const groupingModel = useMemo(() => {
    const collaboratorGroup: GridColumnGroup = {
      children: [
        employeeFields.fullName.key,
        employeeFields.firstName.key,
        employeeFields.lastName.key,
        employeeFields.role.key,
        employeeFields.email.key,
        employeeFields.division.key,
        employeeFields.service.key,
        employeeFields.site.key,
        employeeFields.joinDate.key,
        employeeFields.contract.key,
        employeeFields.branch.key,
        employeeFields.registrationNumber.key,
        employeeFields.areas.key,
        "reviewManager",
      ].map((field) => ({ field })),
      groupId: "Données collaborateur",
    };

    const arbitrationGroup: GridColumnGroup = {
      children: [
        { field: "status" },
        ...columnFields,
        { field: "assigned_actions" },
        { field: "skillup_people_review_manager_comment" },
        { field: "skillup_people_review_comment" },
      ],
      groupId: "Arbitrage",
    };

    return [collaboratorGroup, arbitrationGroup];
  }, [employeeFields, columnFields]);

  return groupingModel;
}
