import { useEffect } from "react";
import isNil from "lodash/isNil";

import { InterviewPairs } from "../../models/InterviewPair";

import styles from "./Steps.module.scss";
import AddTraineesContent from "components/AddTraineesModal/AddTraineesContent";
import { useUsersList } from "components/AddTraineesModal/add-trainees-context";
import { compact, map } from "lodash";
import { IUser, USER_FILE_VALIDATION_TYPE } from "@skillup/types";
import { useAreaUserGroups } from "components/AddTraineesModal/helpers";
import { DSCard } from "@skillup/ui";
interface IProps {
  readonly interviews: InterviewPairs[];
  readonly setInterviews: (interviews: InterviewPairs[]) => void;
  readonly campaignType: "legacy" | "ongoing";
}

const UsersStep = ({ interviews, setInterviews, campaignType }: IProps) => {
  const {
    state: { selectedPairs },
  } = useUsersList();
  useEffect(() => {
    if (interviews.length === selectedPairs.length) {
      return;
    }
    const newInterviews = map(selectedPairs, ({ employee, manager }) => {
      if (employee) {
        const newInterview = {
          employee: employee as IUser & { errors?: string[] },
          manager: manager as unknown as IUser & { linkedTo: string },
          invalid: campaignType === "ongoing" && isNil(employee.joinDate),
        };
        return newInterview;
      }
      return undefined;
    });
    setInterviews(compact(newInterviews));
  }, [selectedPairs, interviews, campaignType, setInterviews]);

  const scope =
    campaignType === "ongoing"
      ? USER_FILE_VALIDATION_TYPE.ONGOING_INTERVIEW_CAMPAIGN
      : USER_FILE_VALIDATION_TYPE.LEGACY_INTERVIEW_CAMPAIGN;
  const groups = useAreaUserGroups({
    scope,
  });

  return (
    <div className={styles.CardWrapper}>
      <DSCard
        className={styles.UsersStep}
        title="Sélectionner des collaborateurs"
        about="Un e-mail leur sera envoyé le jour de démarrage de la campagne."
      >
        <AddTraineesContent
          canUploadManager
          scope={scope}
          customUsersLists={
            groups && [
              {
                label: "Sélectionner par périmètre",
                groups,
                needSelectManager: true,
              },
            ]
          }
        />
      </DSCard>
    </div>
  );
};

export default UsersStep;
