import { BuilderStructure, ChildBlock, PageBlock, SectionBlock } from "../reducer";

export function isImmutableItem(
  structure: BuilderStructure,
  item: SectionBlock | PageBlock | ChildBlock
): boolean {
  const lastSectionIndex = structure.sections.length - 1;
  if (item.sectionIndex === lastSectionIndex) {
    return true;
  }
  if (item.type === "section") {
    if (item.sectionIndex === 0) return true;
  }

  if (item.type === "page") {
    if (item.sectionIndex === 0 && item.pageIndex === 0) return true;
  }

  if (item.type === "child") {
    const child =
      structure.sections[item.sectionIndex]?.pages[item.pageIndex]?.children[item.childIndex];
    if (child && isImmutableKind(child.kind)) return true;
  }

  return false;
}

function isImmutableKind(kind: string): boolean {
  return kind === "guidelines" || kind === "personnalisableGuidelines" || kind === "conclusion";
}
