export const DatatableSkillFields = {
  name: {
    fieldType: "TEXT",
    traductionKey: "skill.field.name",
    traductionDefaultValue: "Intitulé",
    key: "name",
  },
  category: {
    fieldType: "TEXT",
    traductionKey: "skill.field.category",
    traductionDefaultValue: "Catégorie",
    key: "category",
  },
  employeesEvaluated: {
    fieldType: "TEXT",
    traductionKey: "skill.field.employeesEvaluated",
    traductionDefaultValue: "Nombre de collaborateurs évalués",
    key: "employeesEvaluated",
  },
  averageEvaluationScore: {
    fieldType: "TEXT",
    traductionKey: "skill.field.averageEvaluationScore",
    traductionDefaultValue: "Moyenne des évaluations",
    key: "averageEvaluationScore",
  },
  averageEmployeesExpectedLevelDifference: {
    fieldType: "TEXT",
    traductionKey: "skill.field.averageEmployeesExpectedLevelDifference",
    traductionDefaultValue: "Collaborateurs à l'attendu",
    key: "averageEmployeesExpectedLevelDifference",
  },
  averageExpectedLevelDifference: {
    fieldType: "TEXT",
    traductionKey: "skill.field.averageExpectedLevelDifference",
    traductionDefaultValue: "Écart à l'attendu",
    key: "averageExpectedLevelDifference",
  },
} as const;
