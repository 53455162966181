import search from "./search";
import ajax from "./ajax";
import auth from "./auth";

export { search, ajax, auth };

export default {
  ajax,
  auth,
  search,
};
