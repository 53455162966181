export const DatatableJobFields = {
  name: {
    fieldType: "TEXT",
    traductionKey: "job.field.name",
    traductionDefaultValue: "Intitulé",
    key: "name",
  },
  lastEdit: {
    fieldType: "TEXT",
    traductionKey: "job.field.lastEdit",
    traductionDefaultValue: "Date de dernière modification",
    key: "lastEdit",
  },
  employees: {
    fieldType: "TEXT",
    traductionKey: "job.field.employees",
    traductionDefaultValue: "Collaborateurs associés",
    key: "employees",
  },
  skillsCount: {
    fieldType: "TEXT",
    traductionKey: "job.field.skillsCount",
    traductionDefaultValue: "Compétences liées",
    key: "skillsCount",
  },
  isPrivate: {
    fieldType: "TEXT",
    traductionKey: "job.field.isPrivate",
    traductionDefaultValue: "Visibilité dans l'explorateur",
    key: "isPrivate",
  },
} as const;
