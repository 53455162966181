import { v4 as uuid } from "uuid";
import { BuilderStructure } from "../types";
import { getChildErrors, getPageErrors, getSectionErrors } from "../utils";

export const checkStructureErrors = (
  state: BuilderStructure
  // [CSB] we do not allow to edit target scales for now
  // action: PayloadAction<{ choicesForOrdinalTargets: string[] }>
) => {
  return {
    ...state,
    sections: state.sections.map((section) => ({
      uuid: section.uuid ?? uuid(),
      title: section.title,
      pages: section.pages.map((page) => ({
        uuid: page.uuid ?? uuid(),
        title: page.title,
        hideInBuilder: page.hideInBuilder,
        children: page.children.map((child) => ({
          uuid: child.uuid ?? uuid(),
          ...child,
          errors: getChildErrors(child /*, action.payload.choicesForOrdinalTargets*/),
        })),
        errors: getPageErrors(page),
      })),
      errors: getSectionErrors(section),
    })),
  };
};
