import styles from "./UserAreaSelect.module.scss";
import { Select } from "@skillup/ui";
import useAreas from "hooks/useAreas";
import cx from "classnames";
import { useState, useMemo } from "react";
import { useUpdateEffect } from "react-use";
import { useTranslation } from "react-i18next";

interface Props {
  value?: string[];
  onChange?: (areas?: string[]) => void;
  className?: string;
  selectClassName?: string;
  selectPlaceholder?: string;
  isSearchable?: boolean;
}
function UserAreaSelect({
  className,
  selectClassName,
  value,
  onChange,
  selectPlaceholder,
  isSearchable,
}: Props) {
  const { t } = useTranslation();
  const [userAreas, setUserAreas] = useState(value);
  useUpdateEffect(() => onChange?.(userAreas), [userAreas]);

  const { allAreas } = useAreas();
  const areasFilter = useMemo(() => {
    if (allAreas?.length > 1) {
      return [
        ...allAreas?.map((a) => ({
          label: a.name,
          value: a.uuid,
        })),
      ];
    }
    return undefined;
  }, [allAreas]);
  const userAreasValue = useMemo(() => {
    if (!allAreas) return [];
    if (userAreas) return userAreas;
    return [undefined, ...allAreas.map((a) => a.uuid)];
  }, [userAreas, allAreas]);
  function handleAreasFilterChange(newSelectedOptions: string[]) {
    setUserAreas((prevFilters) => {
      const wasAllOptionSelected = prevFilters === undefined;
      const isAllOptionNowSelected = newSelectedOptions.includes(undefined);

      if (!wasAllOptionSelected && isAllOptionNowSelected) {
        // "All" option has been checked, clear filter
        return undefined;
      } else if (wasAllOptionSelected && !isAllOptionNowSelected) {
        // "All" option has been unchecked, uncheck all filters
        return [];
      } else {
        // Regular option has been checked

        const actualFilters = newSelectedOptions.filter((a) => a !== undefined);
        const allChecked = actualFilters.length === allAreas?.length;

        if (allChecked) return undefined;
        return actualFilters;
      }
    });
  }

  return (
    <div className={cx(styles.UserAreaSelect, styles.DropDown, className)}>
      {areasFilter && (
        <Select
          className={cx(styles.areaFilter, selectClassName)}
          multi
          placeholder={t("userAreaSelect.none", { defaultValue: "Aucun périmètre" })}
          options={areasFilter}
          value={userAreasValue}
          maxShownValues={1}
          canSelectAll
          selectAllLabel={t("userAreaSelect.selectAll", { defaultValue: "Tous les périmètres" })}
          clearable={false}
          isSearchable={isSearchable}
          selectPlaceholder={
            selectPlaceholder ??
            t("userAreaSelect.placeholder", {
              defaultValue: "Choisir un ou plusieurs périmètre(s)",
            })
          }
          extraValuesLabel={(count) =>
            t("userAreaSelect.extraValues", { defaultValue: "{{count}} périmètres", count })
          }
          allValuesLabel={t("userAreaSelect.allValues", { defaultValue: "Tous les périmètres" })}
          onChange={handleAreasFilterChange}
        />
      )}
    </div>
  );
}

export default UserAreaSelect;
