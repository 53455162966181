import get from "lodash/get";

import { type FilterConfigurationMap, type FilterValues, InMemoryDatasetFilter, type SortingProperties } from ".";
import { isNil } from "lodash";

export class InMemoryDataset<T extends object, Config extends FilterConfigurationMap> {
  private filterDataset: InMemoryDatasetFilter<T, Config>;

  constructor(
    configuration: Config,
    private readonly dataset: T[],
    private readonly comparators?: { [key: string]: (a?: any, b?: any) => number },
    dataAccessor?: string
  ) {
    this.filterDataset = new InMemoryDatasetFilter(configuration, dataset, dataAccessor);
  }

  private sortDataset(dataset: T[], sortOptions: SortingProperties) {
    const { property, direction } = sortOptions;
    const comparator: (a: any, b: any) => number =
      get(this.comparators ?? {}, property) ?? InMemoryDataset.NumberComparator;
    const reversed = direction === "ASC" ? 1 : -1;

    return dataset.sort((a, b) => {
      const aValue = get(a, property);
      const bValue = get(b, property);

      if (isNil(aValue) && isNil(bValue)) {
        return 0;
      }

      if (isNil(aValue)) {
        return 1 * reversed;
      }

      if (isNil(bValue)) {
        return -1 * reversed;
      }

      return comparator(aValue, bValue) * reversed;
    });
  }

  static NumberComparator(a = 0, b = 0) {
    return a - b;
  }
  static StringComparator(a = "", b = "") {
    return a.localeCompare(b);
  }
  static DateComparator(a = new Date(), b = new Date()) {
    return a.getTime() - b.getTime();
  }

  getRows({
    filterValues,
    sortOptions,
  }: {
    filterValues?: FilterValues<Config>;
    sortOptions?: SortingProperties;
  } = {}): T[] {
    const filteredData = filterValues
      ? this.filterDataset.filterDataSet(filterValues)
      : this.dataset;

    return sortOptions ? this.sortDataset([...filteredData], sortOptions) : filteredData;
  }
}
