import DataLayer from "utils/DataLayer";
import { State } from "../components/PortalLists";

import { ListInterface } from "../types";

const addList =
  (lists: ListInterface[], group: string, setState: (state: State) => void) =>
  async (): Promise<void> => {
    const newList = (await DataLayer.request({
      method: "POST",
      url: "/v1/portal/training-list",
      body: group && JSON.stringify({ groupUuid: group }),
    })) as ListInterface;
    setState({
      lists: [...lists, newList],
    });
  };

export default addList;
