import { PromisePool as SuperChargedPromisePool } from "@supercharge/promise-pool";

type Extract<T extends Array<() => PromiseLike<any>>> = {
  [P in keyof T]: T[P] extends () => PromiseLike<infer Result> ? Result : never;
};

export class PromisePool {
  public static async All<T extends [() => PromiseLike<any>] | Array<() => PromiseLike<any>>>(
    promises: T,
    concurency = 10
  ): Promise<Extract<T>> {
    const { results, errors } = await SuperChargedPromisePool.for(promises)
      .withConcurrency(concurency)
      .useCorrespondingResults()
      .process((element) => {
        return element();
      });

    if (errors.length > 0) {
      throw errors;
    }

    return results as any;
  }
}
