import { useMemo } from "react";

import { ConfigurationRoutes, ScheduleOperationsRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import Auth from "actions/auth";
import Acta from "utils/Acta";
import useTypedFetch from "./useTypedFetch";
import exportCSV from "../containers/Supervisor/routes/TrainingSchedule/utils/exportCSV";
import { ExportSchedulesProps } from "../containers/Supervisor/routes/TrainingSchedule/utils/ExportSchedules";

export type Schedule = ConfigurationRoutes.GetScheduleListForCompany["response"][0];

const useSchedules = () => {
  const userData = Acta.getState("userData");

  const { data, refetch } = useTypedFetch<ConfigurationRoutes.GetScheduleListForCompany>({
    method: "GET",
    path: "/configuration/schedules-for-company/{companyUuid}",
    params: { companyUuid: userData.activeCompany.uuid },
  });

  const scheduleList = useMemo(() => {
    return data ?? [];
  }, [data]);

  const activateSchedule = async (schedule: Schedule) => {
    await buildRequest<ScheduleOperationsRoutes.Activate>({
      method: "POST",
      path: "/schedule/{scheduleUuid}/operations/activate",
      params: { scheduleUuid: schedule.uuid },
    })();
    await Auth.signin();
  };

  const updateScheduleBudget = async (schedule: Schedule, parsedBudget: number) => {
    await buildRequest<ScheduleOperationsRoutes.UpdateBudget>({
      method: "POST",
      path: "/schedule/{scheduleUuid}/operations/updateBudget",
      params: { scheduleUuid: schedule.uuid },
      payload: { budget: parsedBudget },
    })();

    await refetch();
  };

  const removeSchedule = async (schedule: Schedule) => {
    await buildRequest<ScheduleOperationsRoutes.Delete>({
      method: "DELETE",
      path: "/schedule/{scheduleUuid}/operations/remove",
      params: { scheduleUuid: schedule.uuid },
    })();
  };

  const archiveSchedule = async (schedule: Schedule) => {
    await buildRequest<ScheduleOperationsRoutes.Archive>({
      method: "POST",
      path: "/schedule/{scheduleUuid}/operations/archive",
      params: { scheduleUuid: schedule.uuid },
    })();
    await Auth.signin();
  };

  const unarchiveSchedule = async (schedule: Schedule) => {
    await buildRequest<ScheduleOperationsRoutes.Unarchive>({
      method: "POST",
      path: "/schedule/{scheduleUuid}/operations/unarchive",
      params: { scheduleUuid: schedule.uuid },
    })();
    await Auth.signin();
  };

  const renameSchedule = async (schedule: Schedule) => {
    const name = prompt(`Nom du plan de formation`, schedule.name);
    if (name === null || name.trim() === "") return;

    await buildRequest<ScheduleOperationsRoutes.Rename>({
      method: "POST",
      path: "/schedule/{scheduleUuid}/operations/rename",
      params: { scheduleUuid: schedule.uuid },
      payload: { name },
    })();
    await Auth.signin();
  };

  const updateImportSetting = async (schedule: Schedule, value: boolean) => {
    await buildRequest<ScheduleOperationsRoutes.UpdateImportSetting>({
      method: "POST",
      path: "/schedule/{scheduleUuid}/operations/import-setting",
      params: { scheduleUuid: schedule.uuid },
      payload: { value },
    })();
  };

  const exportCSVSchedules = async (props: ExportSchedulesProps) => {
    await exportCSV({
      type: props.type,
      scheduleUuid: props.scheduleUuid,
      activeAreas: props.areas,
    });
  };

  const unarchivedSchedules = useMemo(() => {
    return scheduleList
      .filter((schedule) => schedule.archivedAt === undefined)
      .sort((a, b) => (a.createdAt <= b.createdAt ? 1 : -1));
  }, [scheduleList]);

  const allSchedules = useMemo(() => {
    return scheduleList.sort((a, b) => (a.createdAt <= b.createdAt ? 1 : -1));
  }, [scheduleList]);

  const activeSchedule = useMemo(() => {
    return unarchivedSchedules.find((schedule) => schedule.active !== undefined);
  }, [unarchivedSchedules]);

  return {
    unarchivedSchedules,
    activeSchedule,
    scheduleList,
    allSchedules,
    refetch,
    activateSchedule,
    updateScheduleBudget,
    removeSchedule,
    archiveSchedule,
    unarchiveSchedule,
    renameSchedule,
    exportCSVSchedules,
    updateImportSetting,
  };
};

export default useSchedules;
