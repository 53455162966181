import styles from "./BarChart.module.scss";

import { ChartIndicatorData } from "../../types";

interface Props {
  data: ChartIndicatorData;
}

const getNumberValue = (datum: Props["data"][0]) => {
  return datum.numberValue || datum.rawValue;
};

export default function BarChart({ data }: Props) {
  const maxValue = Math.max(...data?.map(getNumberValue));

  return (
    <div className={styles.BarChart}>
      <div className={styles.chart}>
        {data?.map((datum, index) => (
          <div key={index} className={styles.chartBar}>
            <div className={styles.value}>{datum.formattedValue}</div>
            <div
              className={styles.bar}
              style={{ height: `${(getNumberValue(datum) / maxValue) * 100}%` }}
            ></div>
          </div>
        ))}
      </div>
      <div className={styles.footer}>
        {data?.map((datum, index) => (
          <div key={index} className={styles.label}>
            <span className={styles.bullet} />
            <label>{datum.label || "N/A"}</label>
          </div>
        ))}
      </div>
    </div>
  );
}
