import React from "react";
import { TextInput, Icon, Button } from "@skillup/ui";
import { useRecoilState } from "recoil";
import { v4 as uuid } from "uuid";
import { omit } from "lodash";

import { selectionState } from "../store";
import { Section, Page as PageType } from "../types";

import TransferToAnotherSection from "./TransferToAnotherSection";

import styles from "./Page.module.scss";

interface PageProps {
  section: Section;
  page: PageType;
  pageIndex: number;
  updateSection: (newSection: Section) => void;
}

const Page = ({ page, pageIndex, section, updateSection }: PageProps) => {
  const [selection, setSelection] = useRecoilState(selectionState);

  const updatePage = (newPageData: PageType) => {
    const newPages = [...section.pages];
    newPages[pageIndex] = newPageData;

    updateSection({
      ...section,
      pages: newPages,
    });
  };

  const deletePage = (pageIndex: number) => {
    const newPages = section.pages.slice(0, pageIndex).concat(section.pages.slice(pageIndex + 1));

    updateSection({ ...section, pages: newPages });
  };

  const movePageUp = (pageIndex: number) => {
    const pages = [...section.pages];
    const pageToMoveUp = pages[pageIndex];
    const pageToMoveDown = pages[pageIndex - 1];
    pages[pageIndex] = pageToMoveDown;
    pages[pageIndex - 1] = pageToMoveUp;
    updateSection({ ...section, pages });
  };

  const movePageDown = (pageIndex: number) => {
    const pages = [...section.pages];
    const pageToMoveDown = pages[pageIndex];
    const pageToMoveUp = pages[pageIndex + 1];
    pages[pageIndex + 1] = pageToMoveDown;
    pages[pageIndex] = pageToMoveUp;
    updateSection({ ...section, pages });
  };

  const duplicate = () => {
    updateSection({
      ...section,
      pages: [
        ...section.pages,
        {
          ...omit(page, "uuid"),
          uuid: uuid(),
          title: `${page.title} - COPIE`,
          children: page.children.map((c) => ({
            ...c,
            uuid: uuid(),
          })),
        },
      ],
    });
  };

  const isTheSelectedPage = page.uuid === selection.page;

  return (
    <div key={page.uuid} className={styles.page} aria-label={`page ${pageIndex}`}>
      <TextInput
        aria-label="Titre de la page"
        key={page.uuid}
        value={page.title}
        onChange={(title) => updatePage({ ...page, title })}
      />
      <div className={styles.actionsRow}>
        <Button
          aria-label="Sélectionner"
          label={isTheSelectedPage ? "Page sélectionnée" : "Sélectionner la page"}
          className={isTheSelectedPage ? styles.selected : undefined}
          kind="secondary"
          onClick={() => setSelection({ page: page.uuid })}
        />
        <Button
          kind="secondary"
          label="Supprimer la page"
          aria-label="Supprimer la page"
          onClick={() => {
            const confirmation = window.confirm("Êtes-vous sûr de vouloir supprimer cette page ?");
            if (confirmation) deletePage(pageIndex);
          }}
        />
        <Button
          kind="secondary"
          aria-label="Dupliquer la page"
          label="Dupliquer la page"
          onClick={duplicate}
        />
      </div>
      <div className={styles.actionsRow}>
        <TransferToAnotherSection page={page} sectionUuid={section.uuid} />
        <div className={styles.movePage}>
          {pageIndex > 0 && (
            <Icon.ArrowDropup
              className={styles.closeIcon}
              onClick={() => movePageUp(pageIndex)}
              size="1.35rem"
              aria-label="Monter d'un cran"
            />
          )}
          {pageIndex < section.pages.length - 1 && (
            <Icon.ArrowDropdown
              className={styles.closeIcon}
              onClick={() => movePageDown(pageIndex)}
              size="1.35rem"
              aria-label="Descendre d'un cran"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Page;
