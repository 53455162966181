import React, { useEffect, useRef } from "react";
import { useSetState, useToggle } from "react-use";
import type { InterviewWithStates, IUser, IRoutes } from "@skillup/types";
import { SingleDatePicker } from "react-dates";
import moment, { Moment } from "moment";
import { stringify } from "qs";
import { isUndefined } from "lodash";
import { UserImportError } from "@skillup/ui";

import DateUtils from "utils/dates";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import Icon from "components/Icon";
import Button from "components/Button";
import useFetch from "hooks/useFetch";
import useTranslation from "hooks/useTranslation";
import User from "utils/User";
import FindUser from "components/FindUser";
import type { IUserIdentity } from "containers/Supervisor/routes/Projects/Components/UserSearchInput";
import { calendar as calendarIcon } from "uiAssets/StrokeIcons";

import AddUserModal from "./AddUserModal";

import styles from "./SetInterviewModal.module.scss";
import "./SingleDatePicker.scss";
import { formatError } from "services/errors";

type Campaign = IRoutes["/campaigns"]["GET"]["/"][0];

interface IUserPair {
  employee: IUser;
  manager: IUser;
}

interface IProps {
  readonly campaignUuid: string;
  readonly resync: () => Promise<InterviewWithStates[]>;
  readonly interviews: InterviewWithStates[];
  readonly employee: IUserIdentity;
}

interface IState {
  manager?: IUserIdentity | IUser;
  employee: IUserIdentity | IUser;
  focusedStart: boolean | null;
  focusedEnd: boolean | null;
  startDate?: Moment;
  endDate?: Moment;
  error?: string;
  canDispatchError?: boolean;
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const SetInterviewModal = ({ employee, interviews, campaignUuid, resync }: IProps): JSX.Element => {
  const { activeCompany } = User.getUserData();
  const [isLoading, setLoading] = useToggle(false);
  const [state, setState] = useSetState<IState>({
    focusedStart: false,
    focusedEnd: false,
    employee,
  });
  const { t } = useTranslation();

  const previousStartDate = usePrevious(state.startDate);

  const {
    data: pair,
    loading: fetchingUsers,
    error,
  } = useFetch<{ users: IUserPair }>({
    target: "PORTAL",
    url: `/manager?${stringify({ userEmail: employee.email, orgUuid: activeCompany.uuid })}`,
  });

  const { data: campaign, loading: fetchingCampaign } = useFetch<Campaign>({
    url: `/v1/campaigns/${campaignUuid}`,
  });

  /** This is the initial load hook. It's triggered by fetches,
   * and is injecting values into dates inputs if possible
   */
  useEffect(() => {
    const manager = pair?.users?.manager;
    const employee = pair?.users?.employee;

    if (!fetchingUsers && manager) setState({ manager });
    if (!fetchingUsers && employee) setState({ employee });

    if (!fetchingCampaign && campaign) {
      if (campaign.type === "legacy") {
        setState({
          startDate: moment(campaign.startDate).startOf("day"),
          endDate: moment(campaign.endDate).startOf("day"),
        });
      } else {
        const startDate =
          employee?.joinDate && campaign.type === "ongoing"
            ? DateUtils.getOngoingStartDate(employee.joinDate, campaign.frequency)
            : undefined;

        const endDate = startDate?.clone().add(campaign.duration, "days");

        setState({ startDate, endDate });
      }
    }
  }, [pair, fetchingUsers, campaign, fetchingCampaign, setState]);

  useEffect(() => {
    if (error?.message) {
      Acta.dispatchEvent("sendAppMessage", {
        message: error?.message,
        type: "error",
      });

      setState({ error: error?.message });
    }
  }, [error, setState]);

  /** If startDate changes (from input), and user did not already change
   * endDate, we calculate them from the new startDate
   * value.
   */
  const { startDate, endDate } = state;
  useEffect(() => {
    const canDispatchError = startDate?.isAfter(endDate);

    setState({
      canDispatchError,
    });

    if (!startDate?.isSame(previousStartDate) && campaign) {
      const newEndDate = endDate || startDate?.clone().add(campaign.duration, "days");

      setState({
        endDate: newEndDate,
      });
    }
  }, [campaign, previousStartDate, setState, startDate, endDate]);

  const setStartDate = (date: Moment) => {
    const startDate = date?.startOf("day");
    setState({ startDate });
  };

  const setEndDate = (date: Moment) => {
    const endDate = date?.startOf("day");
    setState({ endDate });
  };

  const createInterview = async (): Promise<void> => {
    if (isLoading) {
      return;
    }

    const startDateAsTimestamp = state.startDate.valueOf();
    const endDateAsTimestamp = state.endDate.valueOf();
    const startDateTimezoneOffset = new Date(startDateAsTimestamp).getTimezoneOffset() * 60 * 1000;
    const endDateTimezoneOffset = new Date(endDateAsTimestamp).getTimezoneOffset() * 60 * 1000;

    try {
      if (state.manager && state.startDate && state.endDate) {
        setLoading(true);
        await DataLayer.request({
          method: "POST",
          url: `/v1/campaigns/${campaignUuid}/interviews`,
          body: JSON.stringify({
            employeeUuid: state.employee.uuid,
            managerUuid: state.manager.uuid,
            startDate: startDateAsTimestamp - startDateTimezoneOffset,
            preparationEndDate: endDateAsTimestamp - endDateTimezoneOffset - 86400000,
            endDate: endDateAsTimestamp - endDateTimezoneOffset,
          }),
        });

        Acta.dispatchEvent("sendAppMessage", {
          message: "Entretien créé avec succès",
          type: "success",
        });

        setLoading(false);
      } else {
        throw new Error("Missing data");
      }
    } catch (error) {
      setLoading(false);
      Acta.dispatchEvent("sendAppMessage", {
        message: formatError(t, error, {
          defaultValue: t("error.interview.create"),
        }),
        type: "error",
      });
    } finally {
      resync();
      Acta.dispatchEvent("closeModal");
    }
  };

  const datePickerProps = {
    customInputIcon: <Icon strokeIcon={calendarIcon} width={16} />,
    placeholder: "../../..",
    numberOfMonths: 1,
    hideKeyboardShortcutsPanel: true,
    noBorder: true,
  };

  const today = moment();
  const isDayBlocked = (date: Moment) => (day: Moment) =>
    day.startOf("day").isBefore(date.clone().add(1, "day").startOf("day"));

  return (
    <div className={styles.SetInterviewModal}>
      <FindUser
        className={styles.user}
        label="Collaborateur"
        user={employee}
        error={state.error}
        showRightIcon
      />
      <FindUser
        className={styles.user}
        label="Manager de l'entretien"
        user={state.manager}
        onSelect={(manager) => {
          setState({ manager });
        }}
        onEdit={() => setState({ manager: undefined })}
        filter={(user: IUserIdentity) => user.email !== employee.email}
        showRightIcon
      />

      {campaign && campaign.type !== "legacy" && (
        <section className={styles.date} data-target="start-date">
          <label>Date de début</label>
          <SingleDatePicker
            date={state?.startDate}
            onDateChange={setStartDate}
            id="startDate"
            focused={Boolean(state.focusedStart)}
            onFocusChange={({ focused }) => setState({ focusedStart: focused })}
            {...datePickerProps}
          />
          {state.canDispatchError && (
            <UserImportError
              className={styles.errorMessage}
              message="La date de début d'entretien ne peut être postérieure aux dates limites de préparation ou de signature sélectionnées."
            />
          )}
        </section>
      )}

      {campaign && campaign.type !== "legacy" && (
        <section className={styles.date} data-target="end-date">
          <label>Date limite de signature *</label>
          <SingleDatePicker
            isDayBlocked={isDayBlocked(today)}
            date={state?.endDate}
            onDateChange={setEndDate}
            id="endDate"
            focused={Boolean(state.focusedEnd)}
            onFocusChange={({ focused }) => setState({ focusedEnd: focused })}
            {...datePickerProps}
          />
        </section>
      )}

      <footer className={styles.actions} data-target="validate">
        <Button
          className={styles.backBtn}
          onClick={() =>
            Acta.setState("modalDisplayed", {
              content: (
                <AddUserModal
                  campaignUuid={campaignUuid}
                  resync={resync}
                  interviews={interviews}
                  campaignType={campaign.type}
                />
              ),
              size: "small",
              title: "Ajouter un collaborateur",
              showOverflow: true,
            })
          }
        >
          Retour
        </Button>
        <Button
          onClick={createInterview}
          disabled={
            isUndefined(state.employee) ||
            isUndefined(state.manager) ||
            !moment.isMoment(state.startDate) ||
            !moment.isMoment(state.endDate) ||
            state.canDispatchError ||
            isLoading
          }
        >
          Valider
        </Button>
      </footer>
    </div>
  );
};

export default SetInterviewModal;
