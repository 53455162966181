import { uniqBy, uniq } from "lodash";
import { InterviewPairs } from "./models/InterviewPair";

export const getInterviewsEmployee = (interviews: InterviewPairs[]) => {
  return uniqBy(
    interviews.map((interview) => interview.employee),
    "uuid"
  );
};
export const getInterviewsManager = (interviews: InterviewPairs[]) => {
  return uniq(interviews.map((interview) => interview.manager));
};
