import React, { ReactNode } from "react";
import cx from "classnames";
import { pick } from "lodash";
import { stringify, parse } from "qs";

import Icon from "components/Icon";
import { check as checkIcon } from "uiAssets/StrokeIcons";
import {
  defaultDisplayableRowCount,
  defaultColumnWidth,
  rowHeight,
  headerRowHeight,
} from "constants/dataTable";
import EmptySVGBox from "uiAssets/SVGImages/emptyBox";
import history from "utils/history";

import styles from "./DataTableStyles.module.scss";

import { FilterModal, FiltersSummary, SidePanelWrapper, BodyRow, HeaderRow } from "./Components";

import { Column, Row, Filter, Filters, SortTarget, FilterValue, FiltersWithSort } from "./types";
import { ReturnedColumn } from "containers/Supervisor/routes/TrainingSchedule/utils/loadPartialData";

export { getVisibleRows } from "./getVisibleRows";

export interface IProps {
  readonly rows: Array<Row>;
  readonly columns: Array<Column>;
  readonly onAddColumn?: () => void;
  readonly onAddRow?: () => void;
  readonly onDeleteColumn?: () => void;
  readonly onDeleteRow?: () => void;
  readonly onSelectRows: (uuids: string[]) => void;
  readonly onSetFilters?: ({ filters, sort }: FiltersWithSort) => void;
  readonly onLoadRows?: (uuids: string[]) => void;
  readonly className?: string;
  readonly tableWrapperClassName?: string;
  readonly selectedRows: Array<string>;
  readonly reloadingRows: Array<string>;
  readonly DataSidePanelProps?: {};
  readonly deactivateFilters?: boolean;
  readonly deactivateMultiSelect: boolean;
  readonly withFiltersInSearch?: boolean;
  readonly headerLabel: string;
  readonly headerIcon: string;
  readonly headerActions: any;
  readonly sidePanel: ReactNode;
  readonly needScroll?: boolean;
  readonly returnedColumns?: ReturnedColumn[];
}

export interface IState {
  readonly allSelected: boolean;
  readonly displayableRowCount: number;
  readonly dataTableWidth: number;
  readonly filterModalColumn: Column | null;
  readonly filters: Filters;
  readonly firstDisplayedRowIndex: number;
  readonly prevRows: Array<Row> | null;
  readonly prevSelectedRows: Array<string>;
  readonly selectedColumnPosition: number;
  readonly scrollLeft: number;
  sortTarget?: SortTarget;
}

/**
 * Filterable and sortable Datatable component
 */
export default class DataTable extends React.PureComponent<IProps, IState> {
  private oldScrollTop = 0;
  private rowsContainer: HTMLElement | null;
  private debounceTimer: number;
  private throttleFlag: boolean;

  /**
   * LIFECYCLE
   * =========
   */

  public state: IState = {
    allSelected: false,
    dataTableWidth: 0,
    displayableRowCount: defaultDisplayableRowCount,
    filterModalColumn: null,
    filters: {},
    firstDisplayedRowIndex: 0,
    prevRows: null,
    prevSelectedRows: [],
    scrollLeft: 0,
    selectedColumnPosition: -1,
  };

  constructor(props: IProps) {
    super(props);
    this.updateDisplayableContent();
  }

  public resetFilters() {
    this.setState((state) => ({
      ...state,
      filters: {},
    }));
  }

  public resetFiltersAndSortings() {
    this.handleFilterChange({ filters: {}, sort: null });
  }

  public resetScroll() {
    if (this.rowsContainer) {
      this.rowsContainer.scrollTo({
        behavior: "auto",
        top: 0,
        left: 0,
      });
    }
  }

  public componentDidMount(): void {
    window.addEventListener("resize", this.updateDisplayableContent);
    if (this.props.onSetFilters) {
      let filters: Record<string, Filter> = {},
        rawFilters: Record<string, any> = {},
        sort: SortTarget,
        rawSort: FiltersWithSort["rawSort"];
      if (this.props.withFiltersInSearch && history.location.search) {
        rawFilters = parse(history.location.search, { ignoreQueryPrefix: true });
        if (rawFilters["sortTarget"]) {
          const key = rawFilters["sortTarget"].column;
          const target = this.props.columns?.find((c) => c.key === key);
          if (target) {
            sort = {
              ...rawFilters["sortTarget"],
              target,
            };
            rawSort = sort;
          } else {
            rawSort = {
              ...rawFilters["sortTarget"],
              target: { key },
            };
          }
          delete rawFilters["sortTarget"];
        }
        for (const [key, value] of Object.entries(rawFilters)) {
          const column = this.props.columns?.find((c) => c.key === key);
          if (column) filters[key] = { value, column, label: column.label };
        }
      }
      this.props.onSetFilters({ filters, rawFilters, sort, rawSort });
    }

    if (this.props.selectedRows.length > 0 && this.props.needScroll) {
      this.scrollToRowUuid(this.props.selectedRows[0]);
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", this.updateDisplayableContent);
  }

  public componentDidUpdate(prevProps: IProps): void {
    if (
      (!!prevProps.needScroll !== this.props.needScroll ||
        prevProps.selectedRows !== this.props.selectedRows) &&
      this.props.selectedRows.length > 0 &&
      this.props.needScroll
    ) {
      this.scrollToRowUuid(this.props.selectedRows[0]);
    }
  }

  public static getDerivedStateFromProps(
    nextProps: IProps,
    prevState: IState
  ): Partial<IState> | null {
    let state: Partial<IState> = {};
    const dataTableWidth = nextProps.columns?.reduce(
      (memo, column) => memo + (column.width || defaultColumnWidth),
      0
    );

    if (prevState.dataTableWidth !== dataTableWidth) {
      state = {
        ...state,
        dataTableWidth,
      };
    }

    if (prevState.prevSelectedRows !== nextProps.selectedRows) {
      const allSelected = nextProps.rows?.length === nextProps.selectedRows.length;

      state = {
        ...state,
        allSelected,
        prevSelectedRows: nextProps.selectedRows,
      };
    }

    if (!nextProps.rows) {
      state = {
        ...state,
        firstDisplayedRowIndex: 0,
      };
    }

    if (prevState.prevRows !== nextProps.rows && nextProps.rows) {
      const allSelected = nextProps.rows?.length === nextProps.selectedRows.length;

      state = {
        ...state,
        allSelected,
        prevRows: nextProps.rows,
      };

      if (!prevState.prevRows && nextProps.withFiltersInSearch && history.location.search) {
        const search = parse(history.location.search, {
          ignoreQueryPrefix: true,
        }) as {
          [key: string]: Array<Record<string, unknown>>;
        } & {
          sortTarget: Record<string, unknown>;
        };
        const filters = prevState.filters;
        for (const key of Object.keys(search)) {
          if (key === "sortTarget") {
            const column =
              nextProps.columns && nextProps.columns.find((c) => c.key === search[key].column);
            const direction = search[key].direction;

            state.sortTarget = column &&
              direction &&
              (direction === "up" || direction === "down") && { target: column, direction };
          } else {
            const column = nextProps.columns && nextProps.columns.find((c) => c.key === key);
            if (column) {
              filters[key] = {
                column,
                label: column.label,
                value: search[key],
              };
            }
          }
        }
        state = {
          ...state,
          filters,
        };
      }
    }

    if (prevState.sortTarget) {
      state = {
        ...state,
        sortTarget: prevState.sortTarget,
      };
    }

    return state;
  }

  /**
   * DISPLAYABLE ROWS
   * ================
   *
   * Compute optimal row count to display, based on screen height
   *
   * @return {number} — row count
   */
  private computeDisplayableRowCount = (): number => {
    return Math.round(window.innerHeight / rowHeight) + 15;
  };

  /**
   * Update the count of displayed row
   */
  private updateDisplayableContent = () => {
    if (this.throttleFlag) return;

    this.throttleFlag = true;
    window.setTimeout(() => {
      this.setState({
        displayableRowCount: this.computeDisplayableRowCount(),
      });
      this.throttleFlag = false;
    }, 200);
  };

  /**
   * Apply sort
   *
   * @param {target} column - filtered column
   * @param {"up" | "down"} direction - direction
   */
  private onSort = (target?: Column, direction?: "up" | "down"): void => {
    if (this.props.withFiltersInSearch) {
      const search = parse(history.location.search, { ignoreQueryPrefix: true });

      if (target && direction) {
        history.replace({
          search: stringify({ ...search, sortTarget: { column: target.key, direction } }),
        });
      } else {
        history.replace({ search: stringify({ ...search, sortTarget: undefined }) });
      }
    }

    const sort = target && direction ? { target, direction } : null;

    this.handleFilterChange({ sort });
  };

  /**
   * Remove filters - should be moved outside of datatable ?
   */
  private removeFilters = (): Promise<void> => {
    return new Promise((resolve) => {
      const { filters } = this.state;

      for (const key in filters) {
        if (this.props.withFiltersInSearch) {
          const search = parse(history.location.search, { ignoreQueryPrefix: true });
          history.replace({ search: stringify({ ...search, [key]: undefined }) });
        }
      }

      this.handleFilterChange({ filters: {}, sort: undefined, callback: resolve });
    });
  };

  /**
   * Apply filters - should be moved outside of datatable ?
   *
   * @param {Column} column - filtered column
   * @param {FilterValue | string} value - filter value
   */
  private updateFilter = (column: Column, value: FilterValue | string): void => {
    const filters: Filters = { ...this.state.filters };
    if (this.props.withFiltersInSearch) {
      const search = parse(history.location.search, { ignoreQueryPrefix: true });
      if (value) {
        history.replace({ search: stringify({ ...search, [column.key]: value }) });
      } else {
        history.replace({ search: stringify({ ...search, [column.key]: undefined }) });
      }
    }

    if (value) {
      filters[column.key] = {
        column,
        label: this.props.columns.find((c) => c.key === column.key).label,
        value,
      };
    } else if (filters[column.key]) {
      delete filters[column.key];
    }

    this.handleFilterChange({ filters });
  };

  private handleFilterChange({
    filters = this.state.filters,
    sort = this.state.sortTarget,
    callback = undefined,
  }) {
    if (this.props.onSetFilters) {
      const rawFilters: FiltersWithSort["rawFilters"] = {};
      for (const [key, value] of Object.entries(filters)) {
        if (typeof value === "object" && "value" in value) rawFilters[key] = value.value;
      }
      const rawSort = sort && {
        ...sort,
        target: pick(sort.target, ["key"]),
      };
      this.props.onSetFilters({ filters, rawFilters, sort, rawSort });
    }
    this.setState(
      {
        filters,
        firstDisplayedRowIndex: 0,
        sortTarget: sort,
      },
      callback
    );
  }

  /**
   * Call updateFilter with a debounce
   */
  private updateFilterDebounced = (column: Column, value: FilterValue | string) => {
    window.clearTimeout(this.debounceTimer);
    this.debounceTimer = window.setTimeout(() => this.updateFilter(column, value), 200);
  };

  private updateFilterWithDebounce = (
    column: Column,
    value: FilterValue | string,
    useDebounce: boolean
  ) => {
    if (!useDebounce) this.updateFilter(column, value);
    else this.updateFilterDebounced(column, value);
  };

  /**
   * Open filter modal
   *
   * @param {Column} column - filtered column
   * @return {*}
   */
  private openFilterModalForColumn = (column: Column): void => {
    const left = this.props.columns.reduce((memo, col) => {
      if (col.position > column.position) {
        return memo;
      } else {
        return memo + (col.width || defaultColumnWidth);
      }
    }, 0);
    const rowsContainerRect = this.rowsContainer.getBoundingClientRect();
    const currentScrollLeft = this.rowsContainer.scrollLeft;

    if (left > rowsContainerRect.width + currentScrollLeft || left < currentScrollLeft) {
      this.rowsContainer.scrollLeft = left - rowsContainerRect.width / 2;
      // forces handle scroll to be synchronously called and update header position
      this.handleScroll();
    }

    // TODO: we should be very careful when mutating columns
    // Check if the filter has been already selected.
    const hasFilter = Object.keys(this.state.filters).find((filter) => filter === column.key);

    if (hasFilter) {
      // So, snapshot has been set, reasign, else initialize with all rows.
      column.snapshot = column.snapshot || this.props.rows;
    } else {
      // Else, initialize with visible rows.
      column.snapshot = this.props.rows;
    }

    this.setState({
      filterModalColumn: column,
    });
    this.selectColumn(column.position);
  };

  /**
   * Open filter modal, based on a column key
   *
   * @param {string} columnKey - filtered column key
   * @return {*}
   */
  private openFilterModalForColumnKey = (columnKey: string): void => {
    const column = this.props.columns.find((c) => c.key === columnKey);
    if (column) {
      this.openFilterModalForColumn(column);
    }
  };

  /**
   * Close filter modal
   *
   * @return {*}
   */
  private closeFilterModal = (): void => {
    this.setState({
      filterModalColumn: null,
    });
    this.deselectColumn();
  };

  /**
   * Toggle column selection - only one column can be selected at once
   *
   * @param {number} position - column position to toggle
   * @param {boolean} useDeselectListener - add a deselect call on mouseup
   * @return {*}
   */
  private selectColumn = (position: number, useDeselectListener = true): void => {
    if (this.state.selectedColumnPosition !== position) {
      this.setState(
        {
          selectedColumnPosition: position,
        },
        () => {
          if (useDeselectListener) {
            window.addEventListener("mouseup", this.deselectColumn);
          }
        }
      );
    }
  };

  /**
   * Toggle column selection - only one column can be selected at once
   *
   * @param {number} position - column position to toggle
   * @return {*}
   */
  private deselectColumn = (): void => {
    this.setState(
      {
        selectedColumnPosition: -1,
      },
      () => window.removeEventListener("mouseup", this.deselectColumn)
    );
  };

  /** =================
   * SCROLL INTERACTION
   * ==================
   *
   * Scroll handler
   *
   * @param {event} e - scroll event
   * @return {*}
   */
  private handleScroll = (_e?: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    if (!this.rowsContainer) {
      return;
    }

    const newScrollTop = this.rowsContainer.scrollTop;
    if (newScrollTop !== this.oldScrollTop) {
      const firstRow = Math.max(0, Math.floor(newScrollTop / rowHeight) - 10);
      if (this.state.firstDisplayedRowIndex !== firstRow) {
        this.setState({
          firstDisplayedRowIndex: firstRow,
        });

        if (this.props.onLoadRows) {
          const { rows } = this.props;
          const lastRow = firstRow + this.state.displayableRowCount;
          if (rows.slice(firstRow, lastRow).some((row) => this.shouldFetchRow(row))) {
            // Fetch visible rows that are not already loaded
            // with a margin of 50 to avoid refetching each time a new row is visible
            const lookupMargin = 50;
            const firstRowToLookup = Math.max(0, firstRow - lookupMargin);
            const lastRowToLookup = Math.min(rows.length, lastRow + lookupMargin);
            const rowsToFetch = rows
              .slice(firstRowToLookup, lastRowToLookup)
              .filter((row) => this.shouldFetchRow(row))
              .map((row) => row.uuid);
            this.props.onLoadRows(rowsToFetch);
          }
        }
      }
      this.oldScrollTop = newScrollTop;
    }

    const newScrollLeft = this.rowsContainer.scrollLeft;
    if (this.state.scrollLeft !== newScrollLeft) {
      this.setState({
        scrollLeft: newScrollLeft,
      });
    }
  };

  private shouldFetchRow(row: Row) {
    return row.lazyLoading && !this.props.reloadingRows.includes(row.uuid);
  }

  /**
   * Scroll to row
   *
   * @param {string} uuid - row uuid
   * @return {*}
   */
  public scrollToRowUuid = async (uuid: string): Promise<void> => {
    if (this.rowsContainer) {
      let index = -1;

      this.state.prevRows.forEach((row, i) => {
        if (row.uuid === uuid) {
          index = i;
          return;
        }
      });

      this.rowsContainer.scrollTo({
        behavior: "auto",
        top: Math.max(0, index - 1) * rowHeight,
      });
    }
  };

  /** ============
   * ROW SELECTION
   * =============
   *
   * Select a row if not selected and vice versa - Does not alter the rest of the selection
   *
   * @param {string} uuid - row uuid
   * @return {*}
   */
  private toggleRow = (uuid: string): void => {
    const newSelectedRows = this.props.selectedRows.includes(uuid)
      ? this.props.selectedRows.filter((r) => r !== uuid)
      : this.props.selectedRows.concat(uuid);

    this.selectRows(newSelectedRows);
  };

  /**
   * Only select one row. Deselect everything else
   *
   * @param {string} uuid - row uuid
   * @return {*}
   */
  private toggleSingleRow = (uuid: string): void => {
    if (this.props.selectedRows.length === 1 && this.props.selectedRows[0] === uuid) {
      this.selectRows([]);
    } else {
      this.selectRows([uuid]);
    }
  };

  /**
   * Select several rows. Deselect everything else
   *
   * @param {Array<string>} uuids - list of row uuid to select
   * @return {*}
   */
  private selectRows = (uuids: string[]): void => {
    this.props.onSelectRows(uuids);
  };

  /**
   * If all row are selected, deselect them, otherwise select all
   *
   * @param {Array<string>} uuids - list of row uuid to select
   * @return {*}
   */
  private toggleAllRows = (): void => {
    const newSelectedRows = this.state.allSelected ? [] : this.props.rows.map(({ uuid }) => uuid);

    this.selectRows(newSelectedRows);
  };

  public render(): JSX.Element {
    const {
      className,
      tableWrapperClassName,
      columns,
      headerLabel,
      headerIcon,
      headerActions,
      selectedRows = [],
      reloadingRows = [],
      deactivateMultiSelect,
    } = this.props;

    const {
      allSelected,
      dataTableWidth,
      displayableRowCount,
      filterModalColumn,
      filters,
      firstDisplayedRowIndex,
      selectedColumnPosition,
      sortTarget,
    } = this.state;

    const rows = this.props.rows;

    const hasSortOrFilters = Object.keys(filters).length > 0 || sortTarget;

    const sortedColumnKey = sortTarget && sortTarget.target ? sortTarget.target.key : "";

    return (
      <div className={cx(styles.DataTable, className)}>
        <div className={styles.wrapper}>
          {(headerLabel || headerIcon || headerActions) && (
            <header>
              <h1>
                {headerIcon && <Icon strokeIcon={headerIcon} width={15} />}
                {headerLabel || ""}
                {rows && rows?.length > 0 && <span>{rows.length}</span>}
              </h1>
              {headerActions && <div>{headerActions}</div>}
            </header>
          )}
          {hasSortOrFilters && (
            <FiltersSummary
              filters={filters}
              setSort={this.onSort}
              sortTarget={sortTarget}
              openFilterModal={this.openFilterModalForColumnKey}
              updateFilter={this.updateFilter}
            />
          )}
          <div
            className={cx(styles.tableWrapper, tableWrapperClassName)}
            style={hasSortOrFilters ? { height: "calc(100% - 41px)" } : undefined}
          >
            {filterModalColumn && (
              <FilterModal
                column={filterModalColumn}
                currentFilter={filters[filterModalColumn.key] as Filter}
                setSort={this.onSort}
                onClose={this.closeFilterModal}
                updateFilter={this.updateFilterWithDebounce}
                deleteColumn={this.props.onDeleteColumn}
                rows={filterModalColumn && filterModalColumn.snapshot}
                scrollContainer={this.rowsContainer}
                returnedColumns={this.props.returnedColumns}
              />
            )}
            {rows && !deactivateMultiSelect && (
              <div
                className={cx(styles.checkbox, styles.addAll, { [styles.selected]: allSelected })}
                style={{ height: headerRowHeight }}
                onClick={this.toggleAllRows}
              >
                <div>
                  <Icon strokeIcon={checkIcon} width={8} stroke="#fff" />
                </div>
              </div>
            )}
            {rows && (
              <div
                className={styles.rows}
                id="rows"
                ref={(div) => (this.rowsContainer = div)}
                onScroll={this.handleScroll}
              >
                <HeaderRow
                  closeFilterModal={this.closeFilterModal}
                  columns={columns}
                  filters={filters}
                  openFilterModalForColumn={this.openFilterModalForColumn}
                  sortedColumnKey={sortedColumnKey}
                  width={dataTableWidth}
                  deactivateFilters={this.props.deactivateFilters}
                  deactivateMultiSelect={deactivateMultiSelect}
                />
                {rows.length === 0 && (
                  <div className={styles.emptySign}>
                    <EmptySVGBox />
                  </div>
                )}
                {/* placeholder - allow to scroll vertically */}
                <div
                  className={styles.placeholder}
                  style={{
                    height: `${rows.length * rowHeight + 1}px`,
                    width: `${dataTableWidth + 40}px`,
                  }}
                />
                {rows.map((row: Row, index: number) => (
                  <BodyRow
                    key={`${row.uuid}-row`}
                    shouldDisplay={
                      index >= firstDisplayedRowIndex &&
                      index < firstDisplayedRowIndex + displayableRowCount
                    }
                    isSelected={selectedRows.includes(row.uuid)}
                    rowUuid={row.uuid}
                    rowIndex={index}
                    onCheckBoxClick={this.toggleRow}
                    onRowClick={this.toggleSingleRow}
                    row={row}
                    columns={columns}
                    isLoading={row.lazyLoading || reloadingRows.includes(row.uuid)}
                    hoveredColumnIndex={selectedColumnPosition}
                    deactivateMultiSelect={deactivateMultiSelect}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        {this.props.DataSidePanelProps && (
          // @ts-ignore
          <SidePanelWrapper {...this.props.DataSidePanelProps} />
        )}
        {this.props.sidePanel && (
          // @ts-ignore
          <SidePanelWrapper>{this.props.sidePanel}</SidePanelWrapper>
        )}
      </div>
    );
  }
}
