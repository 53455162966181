import { useMemo } from "react";

import type { CampaignsRoutes } from "@skillup/espace-rh-bridge";
import CloseCampaignModal from "./CloseCampaignModal";

import ModifyCampaignModal from "./ModifyCampaignModal";
import NotificationCampaignModal from "./NotificationCampaignModal";
import ParameterCampaignModal from "./ParameterCampaignModal";
import UpdateStructureModal from "./UpdateStructureModal";

import { EditionContext } from "./utils";

export type Campaign = CampaignsRoutes.GetByUuid["response"];

interface IProps {
  readonly campaign?: Campaign;
  readonly context: EditionContext;
  readonly resync: () => Promise<void>;
  readonly onClose: () => void;
}

const useCampaignModals = ({ context, resync, campaign, onClose }: IProps) => {
  const modal = useMemo(() => {
    switch (context) {
      case EditionContext.MODIFY:
        return (
          <ModifyCampaignModal
            resync={resync}
            campaign={campaign}
            context={EditionContext.MODIFY}
            onClose={onClose}
          />
        );
      case EditionContext.PARAMETER:
        return <ParameterCampaignModal campaign={campaign} />;
      case EditionContext.NOTIFICATION:
        return (
          <NotificationCampaignModal
            campaign={campaign}
            warning
            resync={resync}
            onClose={onClose}
          />
        );
      case EditionContext.CLOSE:
        return <CloseCampaignModal campaign={campaign} onClose={onClose} resync={resync} />;
      case EditionContext.UPDATE_STRUCTURE:
        return <UpdateStructureModal campaign={campaign} isOpen={true} onClose={onClose} />;
      default:
        return <></>;
    }
  }, [context, resync, campaign, onClose]);

  return modal;
};

export default useCampaignModals;
