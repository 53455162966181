import { useState, useEffect } from "react";
import { loadJimoScript } from "services/Jimo/loadJimoScript";

declare global {
  interface Window {
    jimo: Function | [];
  }
}

interface Options {
  readonly consent?: {
    readonly necessary: boolean;
    readonly functionality: boolean;
    readonly analytics: boolean;
  };
}

const useJimo = ({ consent }: Options) => {
  const [isJimoUp, setIsJimoUp] = useState<boolean>(false);

  useEffect(() => {
    if (!isJimoUp && consent?.functionality) {
      window.jimo = window.jimo || [];

      loadJimoScript(
        window,
        document,
        "https://undercity.usejimo.com/jimo-invader.js",
        process.env.REACT_APP_JIMO_PROJECT_ID
      );
      setIsJimoUp(true);
    }
  }, [isJimoUp, consent]);

  return {
    isJimoUp,
  };
};

export default useJimo;
