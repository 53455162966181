import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DSButton } from "@skillup/ui";
import { Flex, Grid, Text } from "@skillup/design-system";

import User from "utils/User";
import { plural } from "utils/locale";
import DSLayout from "components/DSLayout";
import { useTemplates, type Template } from "services/peopleReview";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import { PR_Management_Routes } from "../router";
import { TemplateCard } from "../../components/cards";
import {
  EditTemplateModal,
  CreateTemplateModal,
  ArchiveTemplateModal,
  ArchivedTemplatesModal,
} from "../../components/modals";

import styles from "./TemplatesList.module.scss";

export const TemplatesList = () => {
  const { t } = useTranslation();
  const [state, setState] = useState<TemplatesListState>({ step: "idle" });

  const { archivedTemplates, templates } = useTemplates();

  const closeModal = () => setState({ step: "idle" });

  return (
    <DSLayout
      activeLayout={"Modèles de campagnes"}
      title="Configuration des People Reviews"
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              label="Créer un modèle de campagne"
              onClick={() => setState({ step: "creating" })}
            />
          ),
          tab: {
            id: "Modèles de campagnes",
            label: "Modèles de campagnes",
            url: PR_Management_Routes.TemplatesList,
          },
        },
        {
          tab: {
            id: "Actions",
            label: "Actions",
            url: PR_Management_Routes.ActionsList,
          },
        },
        User.isSkillupDeveloper() && {
          tab: {
            id: "Monitoring des campagnes",
            label: "Monitoring des campagnes",
            url: PR_Management_Routes.DevMonitoringCampaigns,
          },
        },
      ]}
    >
      <Flex
        padding="m"
        flexDirection="column"
        className={styles.templates}
        justifyContent="space-between"
      >
        <CurrentModal state={state} closeModal={closeModal} />

        <Grid
          gap="s"
          width="100%"
          gridTemplateColumns={{
            columnNumbers: "auto-fill",
            columnWidth: "minmax(18rem, 1fr)",
          }}
        >
          {templates
            ?.filter((t) => !t.archivedAt)
            ?.map((template) => (
              <TemplateCard
                key={template.uuid}
                template={template}
                onEdit={() => setState({ step: "editing", template })}
                onArchive={() => setState({ step: "archiving", template })}
              />
            ))}
        </Grid>
        <Flex marginTop="m">
          {archivedTemplates?.length > 0 ? (
            <DSButton
              buttonSize="S"
              emphasis="Low"
              onClick={() => setState({ step: "unarchive" })}
              label={t("supervisor.people-review-management.actions.displayArchivedAction", {
                defaultValue: "Voir {{label}}",
                label: plural(archivedTemplates?.length, "le modèle archivé", {
                  pluralText: "les %n modèles archivés",
                }),
              })}
            />
          ) : (
            <Text paddingVertical="xxs" paddingHorizontal="s" espaceFont="captionBold">
              {t("supervisor.people-review-management.actions.noActionArchived", {
                defaultValue: "Aucun modèle archivé",
              })}
            </Text>
          )}
        </Flex>
      </Flex>
    </DSLayout>
  );
};

type TemplatesListState =
  | { step: "idle" }
  | { step: "creating" }
  | { step: "unarchive" }
  | { step: "editing"; template: Template }
  | { step: "updating"; template: Template }
  | { step: "archiving"; template: Template };

type VoidFunction = () => void;

interface CurrentModalProps {
  closeModal: VoidFunction;
  state: TemplatesListState;
}

function CurrentModal({ closeModal, state }: CurrentModalProps) {
  switch (state.step) {
    case "archiving":
      return <ArchiveTemplateModal isOpen closeModal={closeModal} template={state.template} />;
    case "editing":
      return <EditTemplateModal isOpen closeModal={closeModal} template={state.template} />;
    case "creating":
      return <CreateTemplateModal isOpen closeModal={closeModal} />;
    case "unarchive":
      return <ArchivedTemplatesModal isOpen closeModal={closeModal} />;
    default:
      return null;
  }
}
