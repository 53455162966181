import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { Flex, Text } from "@skillup/design-system";
import useTranslation from "hooks/useTranslation";
import { trpc } from "utils/trpc";

import { EvaluationScale } from "types/skills";
import EvaluationScaleCard from "./components/EvaluationScaleCard";
import {
  CreationButton,
  EvaluationScaleListWrapper,
  ListLayout,
} from "./EvaluationScaleList.styled";

interface UpdateDataParams<T extends { uuid?: string }> {
  data: Array<T>;
  updatedItems: Array<T>;
}

function UpdateData<T extends { uuid?: string }>({ data, updatedItems }: UpdateDataParams<T>) {
  return data.map((item) => {
    const updatedItem = updatedItems.find((i) => i.uuid === item.uuid);
    if (updatedItem) {
      return updatedItem;
    }
    return item;
  });
}

interface RemoveDataParams<T extends { uuid?: string }> {
  data: Array<T>;
  removedUuid: string;
}

function RemoveData<T extends { uuid?: string }>({ data, removedUuid }: RemoveDataParams<T>) {
  return data.filter((item) => item.uuid !== removedUuid);
}

const EvaluationScaleList = (): JSX.Element => {
  const { t } = useTranslation();
  const history = useHistory();
  const pathname = history.location.pathname;
  const { addToast } = useToasts();
  const utils = trpc.useContext();

  const { data } = trpc.skillEvaluationsScales.getAll.useQuery();
  const setAsDefaultMutator = trpc.skillEvaluationsScales.setAsDefault.useMutation({
    onSuccess: (e) => {
      addToast(
        t("portal.config.evaluationScales.card.setAsDefault.success", {
          defaultValue: "L'échelle d'évaluation a bien été définie comme échelle par défaut",
        }),
        {
          appearance: "success",
        }
      );
      utils.skillEvaluationsScales.getAll.setData(
        undefined,
        UpdateData({
          data,
          updatedItems: e,
        })
      );
    },
    onError: () => {
      addToast(
        t("portal.config.evaluationScales.card.setAsDefault.error", {
          defaultValue: "L'échelle d'évaluation n'a pas pu être définie comme échelle par défaut",
        }),
        {
          appearance: "error",
        }
      );
    },
  });
  const removeMutator = trpc.skillEvaluationsScales.remove.useMutation({
    onSuccess: (e) => {
      addToast(
        t("portal.config.skills.createEvaluationScale.success", {
          defaultValue: "L'échelle d'évaluation a bien été supprimée",
        }),
        {
          appearance: "success",
        }
      );
      utils.skillEvaluationsScales.getAll.setData(
        undefined,
        RemoveData({
          data,
          removedUuid: e.uuid,
        })
      );
    },
    onError: () => {
      addToast(
        t("portal.config.skills.createEvaluationScale.error", {
          defaultValue: "L'échelle d'évaluation n'a pas pu être supprimée",
        }),
        {
          appearance: "error",
        }
      );
    },
  });

  const skillEvaluationsScales: EvaluationScale[] | undefined = data as
    | EvaluationScale[]
    | undefined;

  return (
    <ListLayout>
      <EvaluationScaleListWrapper>
        <Text espaceFont="body1Bold" color="plainText-onLight-default">
          {t("portal.config.skills.list.title", {
            defaultValue: "Échelles d'évaluation",
          })}
        </Text>
        <Text marginTop="s" espaceFont="captionRegular" color="plainText-onLight-lighter">
          {t("portal.config.skills.list.description", {
            defaultValue:
              "Les échelles d’évaluation créées dans cette interface seront utilisées lors de la création de nouvelles compétences. Les descriptions des niveaux d’évaluation pourront être personnalisées compétence par compétence.",
          })}
        </Text>
        <Flex flexDirection="column" gap="m" marginTop="xs">
          {skillEvaluationsScales?.map((evScale) => (
            <EvaluationScaleCard
              item={evScale}
              key={evScale.uuid}
              setAsDefault={async () => {
                await setAsDefaultMutator.mutate({
                  uuid: evScale.uuid,
                  version: evScale.version + 1,
                });
              }}
              remove={async () => {
                await removeMutator.mutate({
                  uuid: evScale.uuid,
                  version: evScale.version + 1,
                });
              }}
            />
          ))}
          <CreationButton
            label={t("portal.config.skills.list.createEvaluationScale", {
              defaultValue: "Créer une échelle d'évaluation",
            })}
            onClick={() => history.push(`${pathname}/new`)}
            emphasis="High"
            buttonSize="M"
          />
        </Flex>
      </EvaluationScaleListWrapper>
    </ListLayout>
  );
};

export default EvaluationScaleList;
