import { useMemo } from "react";

import { SessionUser } from "@skillup/espace-rh-bridge";
import {
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
} from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { Project } from "./types";

interface Props {
  type: "cancel" | "postpone";
  project: Project;
  trainees: SessionUser[];
  className?: string;
}
const AttendeeNotificationAlert = ({ type, project, trainees, className }: Props) => {
  const { t } = useTranslation();

  const allAttendeeWillReceiveNotification = trainees.every((trainee) =>
  project.attendeesSummonedBySkillup.some((t) => t === trainee.uuid)
  );
  const traineesNotSummoned = useMemo (() => {
    return trainees.filter((trainee) => !project.attendeesSummonedBySkillup.includes(trainee.uuid));
  }, [project, trainees]);

  if (!allAttendeeWillReceiveNotification && traineesNotSummoned.length !== trainees.length) {
    const traineesNotSummonedNames = traineesNotSummoned.map((trainee) => trainee.fullName).join(", ");

    return (
      <DSAlert className={className} display={DSAlertDisplay.INLINE} type={DSAlertType.WARNING}>
        {t(`trainings.manage.users.${type}.alert.someNotified`, {
          count: traineesNotSummoned.length,
          names: traineesNotSummonedNames,
          defaultValue:
            "Les collaborateurs recevront une notification sauf la personne convoquée hors Skillup : {{ names }}",
        })}
      </DSAlert>
    );
  }
  
  if (allAttendeeWillReceiveNotification) {
      return (
      <DSAlert className={className} display={DSAlertDisplay.INLINE} type={DSAlertType.INFO}>
        {t(`trainings.manage.users.${type}.alert.notified`, {
          count: trainees.length,
          defaultValue:
            "Le collaborateur recevra une notification.\nSi une invitation agenda avait été incluse dans la convocation initiale, elle sera supprimée.",
        })}
      </DSAlert>
    )
  }
  
  return (
    <DSAlert className={className} display={DSAlertDisplay.INLINE} type={DSAlertType.WARNING}>
      {t(`trainings.manage.users.${type}.alert.not_notified`, {
        count: trainees.length,
        defaultValue: `Le collaborateur ne sera pas notifié ${type === "cancel" ? "de l'annulation" : "du report"}}.`,
      })}
    </DSAlert>
  )
}

export default AttendeeNotificationAlert;