import { Dispatch, useMemo } from "react";

import {
  CreateTemplateModal,
  RenameTemplateModal,
  ArchiveTemplateModal,
  ArchivedTemplatesModal,
  TransferCopyTemplateModal,
  ChooseCampaignTypeModal,
  EditParamsTemplateModal,
  TargetSettingsTemplateModal,
  EditTemplateModal,
} from "../modals";
import { ModalStates, State } from "./useTemplatesActions";

export const useTemplatesModals = (
  currentAction: State,
  setActionsState: Dispatch<State>,
  closeSidePanel?: () => void
) => {
  const modal: JSX.Element = useMemo(() => {
    switch (currentAction.state) {
      case ModalStates.Idle:
        return <></>;
      case ModalStates.Create:
        return (
          <CreateTemplateModal
            isOpen={true}
            closeModal={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.Rename:
        return (
          <RenameTemplateModal
            isOpen={true}
            template={currentAction.template}
            closeModal={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.Archive:
        return (
          <ArchiveTemplateModal
            isOpen={true}
            template={currentAction.template}
            closeSidePanel={closeSidePanel}
            closeModal={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.ArchivedList:
        return (
          <ArchivedTemplatesModal
            isOpen={true}
            closeModal={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.TransferCopy:
        return (
          <TransferCopyTemplateModal
            templateName={currentAction.template.title}
            templateUuid={currentAction.template.uuid}
            onClose={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.ChooseCampaignType:
        return (
          <ChooseCampaignTypeModal
            template={currentAction.template}
            isOpen={true}
            closeModal={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.EditParams:
        return (
          <EditParamsTemplateModal
            template={currentAction.template}
            isOpen={true}
            closeModal={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.EditTargetSettings:
        return (
          <TargetSettingsTemplateModal
            template={currentAction.template}
            isOpen={true}
            closeModal={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.Edit:
        return (
          <EditTemplateModal
            isOpen={true}
            template={currentAction.template}
            closeModal={() => setActionsState({ state: ModalStates.Idle })}
            unarchivedRelatedCampaignsNumber={
              (currentAction.template.relatedCampaigns?.filter(
                (relatedCampaign) => !relatedCampaign?.archivedAt
              )).length
            }
          />
        );
      default:
        return <></>;
    }
  }, [currentAction, setActionsState, closeSidePanel]);

  return modal;
};
