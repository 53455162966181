/**
 * @export
 * @interface ISimpleSelectionUser
 */
export interface ISimpleSelectionUser {
  email: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  noEmail?: boolean;
  alreadyLinked?: boolean;
  type?: SIMPLE_SELECTION_USER_TYPE;
  uuid?: string;
  organizationsByUuid?: string[];
  joinDate?: number | string;
  errors?: string[];
  index?: number;
}

/**
 * @export
 * @interface ISimpleUserIdentity
 */
export interface ISimpleUserIdentity {
  email: string;
  firstName?: string;
  lastName?: string;
  fullName: string;
  identity?: string;
  organizations: string[];
  organizationsByUuid: string[];
  uuid?: string;
}
/**
 * @export
 * @interface ICustomUsersList
 */
export interface ICustomUsersList {
  label: string;
  users: ISimpleSelectionUser[];
}
/**
 * @export
 * @interface ICustomUsersList
 */
export interface ICustomUsersGroupList {
  label: string;
  needSelectManager?: boolean;
  groups: ISimpleSelectionGroup[];
}
export interface ISimpleSelectionUserWithManager extends ISimpleSelectionUser {
  managers?: Array<ISimpleManager>;
}
export interface ISimpleSelectionGroup {
  label: string;
  users: ISimpleSelectionUserWithManager[];
}

export enum SIMPLE_SELECTION_USER_TYPE {
  POSITIONED = "En attente de convocation",
  SUMMONED = "Convoqué",
}

/**
 * @export
 * @interface ISimpleManager
 */
export interface ISimpleManager extends ISimpleSelectionUser {
  linkedTo: string;
}
