import { useCallback, useEffect, useMemo } from "react";
import { useSetState } from "react-use";
import { Summary as ISummary } from "@skillup/types";

import {
  chevronRight as chevronRightIcon,
  chevronLeft as chevronLeftIcon,
} from "uiAssets/StrokeIcons";

import Acta from "utils/Acta";
import User from "utils/User";
import { buildRequest } from "utils/buildRequest";

import ManualTraining, { ManualTrainingData } from "../ManualRowForms/Training";
import ControlledHeader from "../components/ControlledHeader/ControlledHeader";
import Choices from "../components/Choices/Choices";

import { ScheduleRowManualRequestRoute } from "@skillup/espace-rh-bridge";

interface Props {
  reload: (rowsUuid: string[]) => Promise<void>;
  rows: string[];
  summary?: ISummary;
  showCatalog: () => void;
}

const RequalifyModal = ({ reload, rows, summary, showCatalog }: Props) => {
  const [state, setState] = useSetState<{
    training?: Partial<ManualTrainingData>;
    step: "choice" | "training" | "save";
    isSaving: boolean;
  }>({
    step: "choice",
    isSaving: false,
    training: summary?.isSpecificRequest
      ? {
          trainingName: summary.trainingName,
        }
      : undefined,
  });
  const { isSaving, training, step } = state;
  const useTaxSystem = User.isCompanyWithTax();

  const requalify = useCallback(async () => {
    setState({ isSaving: true });
    try {
      const { updatedRows } =
        await buildRequest<ScheduleRowManualRequestRoute.RequalifyToManualRequest>({
          method: "POST",
          path: "/scheduleRow/manual/requalify",
          payload: {
            rows,
            trainingData: {
              ...(training as ManualTrainingData),
              sessionDuration: training.sessionDuration,
            },
          },
        })();

      Acta.dispatchEvent("sendAppMessage", {
        message: "Requalification réussie !",
        type: "success",
      });

      Acta.dispatchEvent("closeModal");
      reload(updatedRows);
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de la requalification de cette demande",
        type: "error",
      });
      setState({ step: "training", isSaving: false });
    }
  }, [training, rows, reload, setState]);

  useEffect(() => {
    if (step === "save" && !isSaving) {
      requalify();
    }
  }, [step, requalify, isSaving]);

  const backButtonHandler = useMemo(() => {
    if (step === "training") return () => setState({ step: "choice" });
    return undefined;
  }, [step, setState]);

  const headerLabel = {
    choice: "Requalifier la demande",
    training: "Requalification hors Skillup",
  }[step];

  return (
    <div>
      <ControlledHeader label={headerLabel} backButtonHandler={backButtonHandler} />
      {step === "choice" && (
        <Choices
          choices={[
            {
              description:
                "Requalifier la demande depuis mes programmes, mes formations recommandées ou le catalogue Skillup.",
              title: "Depuis Skillup",
              icon: chevronRightIcon,
              onClick: showCatalog,
            },
            {
              description:
                "Requalifier la demande sans que cette dernière ne soit reliée à un programme du catalogue Skillup. Vous pourrez renseigner les informations nécessaires à son suivi dans Skillup.",
              title: "Programme hors Skillup",
              icon: chevronLeftIcon,
              onClick: () => setState({ step: "training" }),
            },
          ]}
        />
      )}

      {["training", "save"].includes(step) && (
        <ManualTraining
          isLoading={isSaving}
          useTaxSystem={useTaxSystem}
          trainingData={training}
          buttonLabel="Requalifier"
          onSuccess={(trainingData) => {
            setState({ training: trainingData, step: "save" });
          }}
        />
      )}
    </div>
  );
};

export default RequalifyModal;
