import React from "react";

import type { PatchAdminDataForCompany } from "types/api";

import Loader from "components/Loader";
import DataLayer from "utils/DataLayer";
import Acta from "utils/Acta";
import Toggle from "components/Toggle";

import styles from "./PortalAdminStyles.module.scss";
import { buildRequest } from "utils/buildRequest";

type CompanyAdminPayload = PatchAdminDataForCompany["payload"];
export type IState = CompanyAdminPayload & {
  dataError?: boolean;
  uuid?: string;
};

export default class PortalAdmin extends React.PureComponent<{}, IState> {
  public state: IState = {};
  public shareURL: HTMLInputElement | null;
  public shareURLNotifier: HTMLParagraphElement | null;

  public async componentDidMount(): Promise<void> {
    try {
      const { properties } = (await DataLayer.request({
        url: "/v1/portal/admin",
      })) as any;

      this.setState({
        ...properties,
        dataError: false,
      });
    } catch (error) {
      this.setState({
        dataError: true,
      });
    }
  }

  private onToggleChange = (label: keyof CompanyAdminPayload, value: boolean): void => {
    const body = {
      [label]: value,
    };
    this.saveChanges(body);
  };

  private saveChanges = async (payload: CompanyAdminPayload): Promise<void> => {
    this.setState(payload);
    const request = buildRequest<PatchAdminDataForCompany>({
      method: "POST",
      path: "/portal/admin",
      payload,
    });

    try {
      await request();
      Acta.dispatchEvent("sendAppMessage", {
        message: "Sauvegardé.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Échec d’enregistrement.",
        type: "error",
      });
    }
  };

  public render(): JSX.Element {
    const { dataError } = this.state;

    if (dataError) {
      return <div className={styles.errorContainer}>Erreur lors du chargement des données.</div>;
    }

    const CompanyAdminToggle = ({
      param,
      label,
    }: {
      param: string & keyof CompanyAdminPayload;
      label: string;
    }) => (
      <div className={styles.toggle}>
        <div>{label}</div>
        <Toggle item={param} onChange={this.onToggleChange} isSelected={!!this.state[param]} />
      </div>
    );

    if (dataError === undefined) {
      return (
        <div className={styles.errorContainer}>
          <Loader />
        </div>
      );
    }
    return (
      <div className={styles.PortalAdmin}>
        <div>
          <header>Activation</header>
          <div>
            <CompanyAdminToggle param="portalEnabled" label="Activer le portail" />
            <CompanyAdminToggle param="portalListsDisabled" label="Désactiver les listes" />
            <CompanyAdminToggle param="portalTagsDisabled" label="Désactiver les catégories" />
            <CompanyAdminToggle param="portalSearchDisabled" label="Désactiver le search" />
            <CompanyAdminToggle
              param="portalTitleBackgroundEnabled"
              label="Activer le fond blanc derrière le titre"
            />
            <CompanyAdminToggle param="portalTranslationEnabled" label="Activer la traduction" />
            <CompanyAdminToggle
              param="portalUsersCollectionEnabled"
              label="Activer le recueil pour les collaborateurs"
            />
            <CompanyAdminToggle
              param="portalManagersCollectionEnabled"
              label="Activer le recueil pour les managers"
            />
            <CompanyAdminToggle
              param="portalUsersPriceDisabled"
              label="Désactiver les prix dans le portail collaborateur"
            />
            <CompanyAdminToggle
              param="portalManagersPriceDisabled"
              label="Désactiver les prix dans le portail manager"
            />
            <CompanyAdminToggle
              param="portalCPFDisabled"
              label="Désactiver le CPF dans le portail"
            />
            <CompanyAdminToggle
              param="portalUsersSpecificTrainingEnabled"
              label="Activer la remontée libre dans l'espace collab"
            />
            <CompanyAdminToggle
              param="portalManagersSpecificTrainingEnabled"
              label="Activer la remontée libre dans l'espace RF"
            />
            <CompanyAdminToggle
              param="portalHRSpecificTrainingEnabled"
              label='Afficher l’onglet "A requalifier" dans l’espace RH'
            />
          </div>
        </div>
      </div>
    );
  }
}
