import { ListUtils } from "@skillup/shared-utils";

export const Filters: ListUtils.FilterConfigurationMap = {
  trainingNameOrReference: {
    type: ListUtils.FilterType.TEXT,
  },
  trainingOrganization: {
    type: ListUtils.FilterType.TEXT,
  },
  futureSessionsCount: {
    type: ListUtils.FilterType.TEXT,
  },
  doneSessionsCount: {
    type: ListUtils.FilterType.TEXT,
  },
  rawCreatedAt: {
    type: ListUtils.FilterType.TEXT,
  },
};
