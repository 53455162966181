import { TemplatePreviewRoles } from "@skillup/espace-rh-bridge";

export async function asyncPreviewAction(
  as: "collab" | "manager" | "hr",
  previewLinks: () => Promise<Record<TemplatePreviewRoles, string>>
) {
  let links: Record<TemplatePreviewRoles, string> = { employee: "", manager: "", hr: "" };
  switch (as) {
    case "collab":
      links = await previewLinks();
      window.open(links.employee, "_blank");
      break;
    case "manager":
      links = await previewLinks();
      window.open(links.manager, "_blank");
      break;
    case "hr":
      links = await previewLinks();
      window.open(links.hr, "_blank");
      window.open(links.employee, "_blank");
      window.open(links.manager, "_blank");
      break;
  }
}
