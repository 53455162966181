import { TemplateRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type UnarchiveTemplateParams = TemplateRoutes.UnarchiveTemplateRoute["params"];

export async function unarchiveTemplate({ templateUuid }: UnarchiveTemplateParams) {
  await buildRequest<TemplateRoutes.UnarchiveTemplateRoute>({
    method: "POST",
    params: {
      templateUuid,
    },
    path: `/templates/{templateUuid}/unarchive`,
    target: "PEOPLE_REVIEW",
  })();
}
