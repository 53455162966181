import React from "react";
import { TextArea } from "@skillup/ui";

import { Child } from "../types";

type Guidelines = Child & { kind: "guidelines" };
const GuidelinesBlock = ({
  data,
  updateChild,
}: {
  data: Guidelines;
  updateChild: (newChildData: Child) => void;
}) => {
  return (
    <form>
      <label>Description</label>
      <TextArea
        key={`description-${data.uuid}`}
        value={data.description}
        onChange={(description) => updateChild({ ...data, description })}
      />
    </form>
  );
};
export default GuidelinesBlock;
