import React from "react";
import { TextDropdown } from "@skillup/ui";

import { Child } from "../types";

// Sub components
import DisallowBlock, { commentRestrictions } from "./DisallowBlock";

type TrainingsHistory = Child & { kind: "trainingsHistory" };
const TrainingsHistoryBlock = ({
  data,
  updateChild,
}: {
  data: TrainingsHistory;
  updateChild: (newChildData: Child) => void;
}) => {
  const units = [
    { value: "months", label: "Mois" },
    { value: "years", label: "Années" },
  ];

  const ageUnitSingular = {
    months: "mois",
    years: "an",
  }[data.ageUnit];

  const ageUnitPlural = {
    months: "mois",
    years: "ans",
  }[data.ageUnit];

  const years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
    value,
    label: `${value} ${value === 1 ? ageUnitSingular : ageUnitPlural}`,
  }));

  return (
    <form>
      <label>Unité</label>
      <TextDropdown
        options={units}
        small
        selectedIndex={units.findIndex((unit) => unit.value === data.ageUnit)}
        onSelect={({ value }) =>
          updateChild({ ...data, ageUnit: value as TrainingsHistory["ageUnit"] })
        }
      />
      <label>Remonter jusqu'à:</label>
      <TextDropdown
        options={years}
        selectedIndex={years.findIndex((year) => year.value === data.maxAge)}
        small
        onSelect={({ value }) =>
          updateChild({ ...data, maxAge: value as TrainingsHistory["maxAge"] })
        }
      />
      <DisallowBlock data={data} updateChild={updateChild} restrictions={commentRestrictions} />
    </form>
  );
};

export default TrainingsHistoryBlock;
