import { v4 as uuid } from "uuid";

interface ClickContentItemEvent {
  type: "clickContentItem";
  uuid: string;
}

interface FocusContentItemFromScrollEvent {
  type: "focusContentItemFromScroll";
  uuid: string;
}

interface FocusStructureItemFromErrorDropdown {
  type: "focusStructureItemFromErrorDropdown";
  uuid: string;
}

interface ClickStructureItemEvent {
  type: "clickStructureItem";
  uuid: string;
}

interface ItemsUpdateAfterDragEnd {
  type: "itemsUpdateAfterDragEnd";
  uuid: string;
}

interface ItemsUpdateAfterActionOnBlock {
  type: "itemsUpdateAfterActionOnBlock";
  uuid: string;
}

export type BuilderEvent =
  | ClickContentItemEvent
  | ClickStructureItemEvent
  | FocusContentItemFromScrollEvent
  | FocusStructureItemFromErrorDropdown
  | ItemsUpdateAfterDragEnd
  | ItemsUpdateAfterActionOnBlock;

export class EventService {
  private subscribers: Map<string, Set<(event: BuilderEvent) => void>> = new Map();

  addEventListener(listener: (event: BuilderEvent) => void): string {
    const id = uuid();
    const listeners = this.subscribers.get(id) ?? new Set();
    listeners.add(listener);
    this.subscribers.set(id, listeners);
    return id;
  }

  removeEventListener(id: string) {
    this.subscribers.delete(id);
  }

  dispatchEvent(event: BuilderEvent) {
    this.subscribers.forEach((listeners) => listeners.forEach((listener) => listener(event)));
  }
}

export class EventObservable {
  private listenerId: string;
  constructor(private eventService: EventService) {}

  subscribe(observer: (event: BuilderEvent) => void) {
    this.listenerId = this.eventService.addEventListener((event: BuilderEvent) => {
      observer(event);
    });
    return () => {
      this.eventService.removeEventListener(this.listenerId);
    };
  }

  unsubscribe() {
    this.eventService.removeEventListener(this.listenerId);
  }
}

export class ClickContentItemObservable {
  private listenerId: string;
  constructor(private eventService: EventService) {}
  subscribe(observer: (uuid: string) => void) {
    this.listenerId = this.eventService.addEventListener((event: BuilderEvent) => {
      if (event.type === "clickContentItem") {
        observer(event.uuid);
      }
    });
  }

  unsubscribe() {
    this.eventService.removeEventListener(this.listenerId);
  }
}

export class FocusContentItemFromScrollObservable {
  private listenerId: string;
  constructor(private eventService: EventService) {}
  subscribe(observer: (uuid: string) => void) {
    this.listenerId = this.eventService.addEventListener((event: BuilderEvent) => {
      if (event.type === "focusContentItemFromScroll") {
        observer(event.uuid);
      }
    });
  }

  unsubscribe() {
    this.eventService.removeEventListener(this.listenerId);
  }
}

export class FocusStructureItemFromErrorDropdownObservable {
  private listenerId: string;
  constructor(private eventService: EventService) {}
  subscribe(observer: (uuid: string) => void) {
    this.listenerId = this.eventService.addEventListener((event: BuilderEvent) => {
      if (event.type === "focusStructureItemFromErrorDropdown") {
        observer(event.uuid);
      }
    });
  }

  unsubscribe() {
    this.eventService.removeEventListener(this.listenerId);
  }
}

export class ClickStructureItemObservable {
  private listenerId: string;
  constructor(private eventService: EventService) {}
  subscribe(observer: (uuid: string) => void) {
    this.listenerId = this.eventService.addEventListener((event: BuilderEvent) => {
      if (event.type === "clickStructureItem") {
        observer(event.uuid);
      }
    });
  }

  unsubscribe() {
    this.eventService.removeEventListener(this.listenerId);
  }
}

export class ItemsUpdateAfterDragEndObservable {
  private listenerId: string;
  constructor(private eventService: EventService) {}
  subscribe(observer: (uuid: string) => void) {
    this.listenerId = this.eventService.addEventListener((event: BuilderEvent) => {
      if (event.type === "itemsUpdateAfterDragEnd") {
        observer(event.uuid);
      }
    });
  }

  unsubscribe() {
    this.eventService.removeEventListener(this.listenerId);
  }
}

export class ItemsUpdateAfterActionOnBlockObservable {
  private listenerId: string;
  constructor(private eventService: EventService) {}
  subscribe(observer: (uuid: string) => void) {
    this.listenerId = this.eventService.addEventListener((event: BuilderEvent) => {
      if (event.type === "itemsUpdateAfterActionOnBlock") {
        observer(event.uuid);
      }
    });
  }

  unsubscribe() {
    this.eventService.removeEventListener(this.listenerId);
  }
}
