import { EmployeesRoutes, KeyofEmployee } from "@skillup/core-hr-bridge";

import { buildRequest } from "utils/buildRequest";

export async function getEmployees<TKey extends KeyofEmployee>({ employeeUuids, keys }: { employeeUuids: string[], keys: TKey[] }) {
  try {
    const response = await buildRequest<EmployeesRoutes.GetEmployees<TKey>>({
      method: "POST",
      path: "/employees",
      payload: { employeeUuids, keys },
      target: "CORE_HR",
    })();

    return response;
  } catch (err) {
    return undefined;
  }
}
export default getEmployees;
