import { useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import DSLayout from "components/DSLayout";
import useTranslation from "hooks/useTranslation";

import SkillCategories from "./components/SkillCategories/SkillCategories";
import EvaluationScaleList from "./components/SkillEvaluationScales/components/List";
import JobTemplateFields from "./components/JobTemplateFields";

export default function SkillsManagement() {
  const history = useHistory();
  const { t } = useTranslation();
  const pathname = history.location.pathname;

  const layouts = useMemo(() => {
    return [
      {
        tab: {
          id: "structure-de-fiches-de-poste",
          label: t("portal.config.skills.jobs", { defaultValue: "Fiches de poste" }),
          url: "/responsable/configuration-du-module-competences/structure-de-fiches-de-poste",
        },
      },
      {
        tab: {
          id: "gestion-des-echelles-d-evaluation",
          label: t("portal.config.skills.scale", { defaultValue: "Échelles d'évaluation" }),
          url: "/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation",
        },
      },
      {
        tab: {
          id: "categories-de-competences",
          label: t("portal.config.skills.categories", {
            defaultValue: "Catégories de compétences",
          }),
          url: "/responsable/configuration-du-module-competences/categories-de-competences",
        },
      },
    ] as const;
  }, [t]);

  const selectedTab = useMemo(() => {
    return layouts.length > 0 ? layouts.find((e) => e?.tab?.url === pathname)?.tab.id : null;
  }, [layouts, pathname]);

  return (
    <DSLayout
      title="Configuration du module Compétences"
      layouts={layouts}
      activeLayout={selectedTab}
    >
      <Switch>
        <Route
          exact
          path="/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation"
        >
          <EvaluationScaleList />
        </Route>

        <Route
          exact
          path="/responsable/configuration-du-module-competences/categories-de-competences"
        >
          <SkillCategories />
        </Route>

        <Route
          exact
          path="/responsable/configuration-du-module-competences/structure-de-fiches-de-poste"
        >
          <JobTemplateFields />
        </Route>
      </Switch>
    </DSLayout>
  );
}
