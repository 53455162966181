import React from "react";

import { ErrorMessage, Field, type FieldProps, getIn } from "formik";

import { DSTextArea, MaterialIcons } from "@skillup/ui";
import { Text } from "../Text";
import { Flex } from "../Flex";

interface Props {
  readonly name: string;
  readonly placeholder?: string;
  readonly value?: string;
}

export const FormikInputTextArea = ({ name, placeholder, value }: Props) => {
  return (
    <>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <DSTextArea
            id={field.name}
            value={value}
            onChange={(value) => form.setFieldValue(field.name, value)}
            error={getIn(form.errors, field.name) && getIn(form.touched, field.name)}
            placeholder={placeholder}
            debounceValue={1}
            fitParentHeight
          />
        )}
      </Field>
      <ErrorMessage
        name={name}
        render={(msg) => (
          <Text espaceFont="body1Regular" color="status-error-darker" fontSize="0.75rem">
            <Flex alignItems="center" gap="xs">
              <MaterialIcons.InfoOutline />
              {msg}
            </Flex>
          </Text>
        )}
      />
    </>
  );
};
