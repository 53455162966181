import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import type { TargetScaleLegacy } from "@skillup/espace-rh-bridge";
import { ChipNumber } from "@skillup/ui";
import { useMemo } from "react";
import { v4 as uuid } from "uuid";

export type TargetScaleLegacyRow = ReturnType<typeof parseTableRows>;

export const useTargetScaleDataLegacy = (
  scales: TargetScaleLegacy[] | undefined
): {
  data: TargetScaleLegacyRow[];
  columns: Array<GridColDef>;
} => {
  const columns = useMemo(generateColumns, []);
  const data = useMemo(() => generateTableRows(scales ?? []), [scales]);

  return { columns, data };
};

function generateTableRows(scales: Array<TargetScaleLegacy>) {
  return scales.map(parseTableRows);
}

const parseTableRows = (scale: TargetScaleLegacy) => ({
  id: uuid(),
  title: scale.title,
  levels: scale.scaleLevels,
  templateCount: scale.templateCount,
});

function generateColumns() {
  const columns = [
    {
      field: "title",
      headerName: "Intitulé de l'échelle",
      filterable: true,
      minWidth: 200,
      flex: 2,
    },
    {
      field: "levels",
      headerName: "Niveaux de l'échelle",
      filterable: true,
      minWidth: 500,
      flex: 4,
      valueGetter: ({ row }) => row.levels.join(", "),
    },
    {
      field: "templateCount",
      headerName: "Nombre de trames utilisant cette échelle",
      filterable: false,
      minWidth: 500,
      flex: 1,
      renderCell: (param: GridRenderCellParams<any, number>) => <ChipNumber value={param.value} />,
    },
  ];
  return columns;
}
