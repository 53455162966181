import React, { useState } from "react";
import { useSetState } from "react-use";
import { useHistory } from "react-router-dom";
import type { InterviewUser } from "@skillup/types";
import { isUndefined } from "lodash";

import { QuestionsStep, UsersStep, DatesStep, FinalStep } from "./components/Steps";
import { InterviewPairs } from "./models/InterviewPair";
import { Step } from "./models/Step";
import { createNewCampaign as createNewCampaignRequest } from "./requests/CreateCampain";

import CreateFormInterviewWrapper from "./CreateInterviewFormWrapper";
import { UsersListProvider } from "components/AddTraineesModal/add-trainees-context";
import { getInterviewsEmployee, getInterviewsManager } from "./helper";
import useTranslation from "hooks/useTranslation";
import OptionsStep from "./components/Steps/OptionsStep";

interface IState {
  titleForHR: string;
  titleForAttendees: string;
  interviews: InterviewPairs[];
  template?: { title: string; uuid: string };
  managerOnly?: boolean;
  start?: number;
  duration?: number;
  ongoingStartDate?: number;
  hideFromEmployeeUntil?: { managerState: InterviewUser["state"] };
  confidential: boolean;
}

const CreateNewAutomatedInterviews = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const [state, setState] = useSetState<IState>({
    titleForHR: "",
    titleForAttendees: "",
    interviews: [],
    confidential: false,
  });

  const {
    titleForHR,
    titleForAttendees,
    template,
    interviews,
    managerOnly,
    start,
    duration,
    hideFromEmployeeUntil,
    confidential,
  } = state;

  const [canCreateCampaign, setCanCreateCampaign] = useState(false);

  const steps: Step[] = [
    {
      label: "Trame et nom",
      id: "modelAndName",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <QuestionsStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          setQuestionsTemplate={(template) => setState({ template })}
          setCampaignTitle={(campaignName, scope: "HR" | "attendees") => {
            if (scope === "HR") {
              setState({ titleForHR: campaignName });
            } else if (scope === "attendees") {
              setState({ titleForAttendees: campaignName });
            }
          }}
        />
      ),
    },
    {
      label: "Dates",
      id: "dates",
      disabled: isUndefined(start) || isUndefined(duration),
      component: (
        <DatesStep
          start={start}
          setStart={(start) => setState({ start })}
          duration={duration}
          setDuration={(duration) => setState({ duration })}
          type="ongoing"
        />
      ),
    },
    {
      label: "Workflow et confidentialité",
      id: "workflowAndConfidentiality",
      disabled: !(
        titleForHR.length > 0 &&
        titleForAttendees.length > 0 &&
        !isUndefined(template) &&
        !isUndefined(start) &&
        !isUndefined(duration)
      ),
      component: (
        <OptionsStep
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          setHideFromEmployeeUntil={(hideFromEmployeeUntil) => setState({ hideFromEmployeeUntil })}
          managerOnly={managerOnly}
          setManagerOnly={(managerOnly) => setState({ managerOnly })}
          confidential={confidential}
          setConfidentiality={(confidential) => setState({ confidential })}
        />
      ),
    },
    {
      label: "Participants",
      id: "attendees",
      disabled: !(
        interviews.length > 0 &&
        getInterviewsEmployee(interviews).every((employee) => !employee.errors?.length) &&
        getInterviewsManager(interviews).every((manager) => !manager.errors?.includes("not-found"))
      ),
      component: (
        <UsersListProvider
          content={{
            selectedPairs: interviews,
          }}
        >
          <UsersStep
            interviews={interviews}
            setInterviews={(interviews) => setState({ interviews })}
            campaignType="ongoing"
          />
        </UsersListProvider>
      ),
    },
    {
      label: "Synthèse",
      id: "summary",
      disabled: !(
        titleForHR.length > 0 &&
        titleForAttendees.length > 0 &&
        !isUndefined(template) &&
        interviews.length > 0 &&
        !isUndefined(start) &&
        !isUndefined(duration)
      ),
      component: (
        <FinalStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          interviews={interviews}
          type="ongoing"
          start={start}
          duration={duration}
          setCanCreateCampaign={setCanCreateCampaign}
          managerOnly={managerOnly}
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          confidential={confidential}
        />
      ),
    },
  ];

  const createNewCampaign = async (toggleLoading: (nextValue?: boolean) => void) => {
    const campaignUuid = await createNewCampaignRequest({
      interviews,
      toggleLoading,
      t,
      confidential,
      data: {
        titleForHR: titleForHR,
        titleForAttendees: titleForAttendees,
        template: template.uuid,
        managerOnly,
        type: "ongoing",
        duration,
        preparationTime: duration - 1,
        frequency: start as number,
        hideFromEmployeeUntil,
      },
    });

    if (campaignUuid) history.push(`/responsable/campagne/${campaignUuid}`);
  };

  return (
    <CreateFormInterviewWrapper
      steps={steps}
      title="Entretiens en continu"
      sendButtonLabel="Créer les entretiens"
      createNewCampaign={createNewCampaign}
      canCreateCampaign={canCreateCampaign}
    />
  );
};

export default CreateNewAutomatedInterviews;
