import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { useFormik } from "formik";

import { Text, Flex } from "@skillup/design-system";
import { Label, DSModal, DSSimpleTextArea } from "@skillup/ui";

import { CampaignDetails } from "services/peopleReview";

import { TalentGridRow } from "../../types";
import { SupervisionContext } from "../../contexts";

import styles from "./CommentModal.module.scss";

type ReadOnlyCommentModalProps = {
  fullName: string;
  onClose: () => void;
  managerFullName: string;
  managerComment?: string;
  coordinatorComment?: string;
  canHaveManagerComments: boolean;
  campaignState: CampaignDetails["state"];
};

const ReadOnlyCommentModal = ({
  campaignState,
  canHaveManagerComments,
  coordinatorComment,
  fullName,
  managerComment,
  managerFullName,
  onClose,
}: ReadOnlyCommentModalProps) => {
  const { t } = useTranslation();

  const campaignNotStarted = campaignState === "not_started";
  const campaignClosed = campaignState === "closed";
  const campaignPaused = campaignState === "paused";

  return (
    <DSModal isOpen className={styles.editCommentModal}>
      <DSModal.Header onClose={onClose}>
        {fullName ? (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeCommentModal.title", {
              defaultValue: "Commentaires pour {{fullName}}",
              fullName,
            })}
          />
        ) : (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeCommentModal.titleNoName", {
              defaultValue: "Commentaires",
            })}
          />
        )}
      </DSModal.Header>

      <DSModal.Content className={styles.content}>
        {campaignNotStarted && (
          <Text marginBottom="s" espaceFont="body1Regular">
            Aucun commentaire définie pour {fullName}.
          </Text>
        )}
        {(campaignPaused || campaignClosed) && (
          <>
            {canHaveManagerComments && (
              <Flex paddingBottom="s" flexDirection="column">
                <Label
                  className={styles.label}
                  label={t("peopleReview.talentGrid.changeCommentModal.managerLabel", {
                    defaultValue: "Commentaire Manager",
                  })}
                />
                {managerComment ? (
                  <Text
                    paddingVertical="xs"
                    espaceFont="body1Regular"
                    color="plainText-onLight-default"
                  >
                    {managerComment}
                  </Text>
                ) : (
                  <Text
                    paddingVertical="xs"
                    espaceFont="body1Italic"
                    color="plainText-onLight-placeholder"
                  >
                    {!managerFullName
                      ? t(
                          "peopleReview.talentGrid.changeCommentModal.manager.placeholderNoManager",
                          {
                            defaultValue: "Ce collaborateur n'a pas de manager.",
                          }
                        )
                      : t("peopleReview.talentGrid.changeCommentModal.manager.placeholder", {
                          defaultValue: "{{managerFullName}} n'a pas laissé de commentaire.",
                          managerFullName,
                        })}
                  </Text>
                )}
              </Flex>
            )}
            <Flex paddingBottom="s" flexDirection="column">
              <Label
                className={styles.label}
                label={t("peopleReview.talentGrid.changeCommentModal.coordinatorlabel", {
                  defaultValue: "Commentaire Coordinateur",
                })}
              />
              {coordinatorComment ? (
                <Text
                  paddingVertical="xs"
                  espaceFont="body1Regular"
                  color="plainText-onLight-default"
                >
                  {coordinatorComment}
                </Text>
              ) : (
                <Text
                  paddingVertical="xs"
                  espaceFont="body1Italic"
                  color="plainText-onLight-placeholder"
                >
                  {t(
                    "peopleReview.talentGrid.changeCommentModal.coordinator.placeholderNoManager",
                    {
                      defaultValue: "Aucun commentaire attribué à ce collaborateur.",
                    }
                  )}
                </Text>
              )}
            </Flex>
          </>
        )}
      </DSModal.Content>

      <DSModal.FooterInfo>
        <DSModal.FooterInfo.CancelButton
          label={t("common.close", { defaultValue: "Fermer" })}
          onClick={onClose}
        />
      </DSModal.FooterInfo>
    </DSModal>
  );
};

type EditCommentModalProps = {
  fullName: string;
  onClose: () => void;
  managerFullName: string;
  managerComment?: string;
  coordinatorComment?: string;
  canHaveManagerComments: boolean;
  onSuccess: (newComment: string) => void;
};

const EditCommentModal = ({
  canHaveManagerComments,
  coordinatorComment,
  fullName,
  managerComment,
  managerFullName,
  onClose,
  onSuccess,
}: EditCommentModalProps) => {
  const { t } = useTranslation();

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      coordinatorComment: coordinatorComment || "",
    },
    onSubmit: (values) => onSuccess(values.coordinatorComment),
  });

  return (
    <DSModal isOpen className={styles.editCommentModal}>
      <DSModal.Header onClose={onClose}>
        {fullName ? (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeCommentModal.title", {
              defaultValue: "Commentaires pour {{fullName}}",
              fullName,
            })}
          />
        ) : (
          <DSModal.Header.Title
            title={t("peopleReview.talentGrid.changeCommentModal.titleNoName", {
              defaultValue: "Commentaires",
            })}
          />
        )}
      </DSModal.Header>

      <DSModal.Content className={styles.content}>
        {canHaveManagerComments && (
          <Flex paddingBottom="s" flexDirection="column">
            <Label
              className={styles.label}
              label={t("peopleReview.talentGrid.changeCommentModal.managerLabel", {
                defaultValue: "Commentaire Manager",
              })}
            />
            {managerComment ? (
              <Text
                paddingVertical="xs"
                espaceFont="body1Regular"
                color="plainText-onLight-default"
              >
                {managerComment}
              </Text>
            ) : (
              <Text
                paddingVertical="xs"
                espaceFont="body1Italic"
                color="plainText-onLight-placeholder"
              >
                {!managerFullName
                  ? t("peopleReview.talentGrid.changeCommentModal.manager.placeholderNoManager", {
                      defaultValue: "Ce collaborateur n'a pas de manager.",
                    })
                  : t("peopleReview.talentGrid.changeCommentModal.manager.placeholder", {
                      defaultValue: "{{managerFullName}} n'a pas laissé de commentaire.",
                      managerFullName,
                    })}
              </Text>
            )}
          </Flex>
        )}
        <Flex paddingBottom="s" flexDirection="column">
          <Label
            className={styles.label}
            label={t("peopleReview.talentGrid.changeCommentModal.coordinatorlabel", {
              defaultValue: "Commentaire Coordinateur",
            })}
          />
          <DSSimpleTextArea
            rows={3}
            id="coordinatorComment"
            name="coordinatorComment"
            className={styles.textArea}
            defaultValue={values.coordinatorComment}
            onChange={(val, e) => handleChange(e)}
            placeholder={t("peopleReview.talentGrid.changeCommentModal.coordinator.placeholder", {
              defaultValue: "Saisissez un commentaire",
            })}
          />
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.talentGrid.changeCommentModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          disabled={values.coordinatorComment === coordinatorComment}
          onClick={() => handleSubmit()}
          label={t("peopleReview.talentGrid.changeCommentModal.confirm", {
            defaultValue: "Confirmer le changement",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

type CommentModalProps = {
  row: TalentGridRow;
  isReadonly: boolean;
  onClose: () => void;
  onSuccess: (newComment: string) => void;
};

export function CommentModal({ isReadonly, onClose, onSuccess, row }: CommentModalProps) {
  const { campaign } = useContext(SupervisionContext);

  const fullName = row.userData.fullName;
  const managerFullName = row.managerData.fullName;
  const managerComment = row.managerPreparationComment;
  const coordinatorComment = row.comment;
  const canHaveManagerComments = campaign.managersPreparationEndDate !== undefined;

  if (isReadonly)
    return (
      <ReadOnlyCommentModal
        fullName={fullName}
        campaignState={campaign.state}
        managerComment={managerComment}
        managerFullName={managerFullName}
        coordinatorComment={coordinatorComment}
        canHaveManagerComments={canHaveManagerComments}
        onClose={onClose}
      />
    );

  return (
    <EditCommentModal
      fullName={fullName}
      managerComment={managerComment}
      managerFullName={managerFullName}
      coordinatorComment={coordinatorComment}
      canHaveManagerComments={canHaveManagerComments}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  );
}
