import React, { useMemo } from "react";
import cx from "classnames";

import { ModulesAccessList } from "@skillup/espace-rh-bridge";
import { DropDownCheckbox, Select } from "@skillup/ui";

import styles from "./ManageAdmins.module.scss";
import useSettings, { ModulesLabel } from "hooks/useSettings";
import useAdminData from "./useAdminData";

const dropdownLabel = {
  itemSingular: "",
  itemPlural: "",
  allItemsSelected: "Tous",
  noItemSelected: "Aucun",
};

export default function ManageAdmins() {
  const { state, onChangePermissions, onChangeModules, onChangeScopeAccess } = useAdminData();
  const { settings: companySettings } = useSettings();

  const allModules = useMemo(() => {
    return Object.values(ModulesAccessList).filter((module) => {
      if (module === ModulesAccessList.AccessListV2) return null;
      return companySettings.features.includes(module);
    });
  }, [companySettings]);

  const hasAdminTrainerEnabled = useMemo(() => {
    return companySettings.features.includes("trainer");
  }, [companySettings.features]);

  const hasAdminTrainerWithoutIntrasEnabled = useMemo(() => {
    return companySettings.features.includes("admin-without-intras");
  }, [companySettings.features]);

  const hasTrainerWithoutIntrasEnabled = useMemo(() => {
    return companySettings.features.includes("trainer-without-intras");
  }, [companySettings.features]);

  const hasTrainerQHSEEnabled = useMemo(() => {
    return companySettings.features.includes("trainer-qhse");
  }, [companySettings.features]);

  const adminOptions = [
    {
      value: "Admin",
      label: "Admin",
    },
    { value: "Custom", label: "Custom" },
  ];

  if (hasAdminTrainerWithoutIntrasEnabled) {
    adminOptions.push({ value: "Admin sans Programmes", label: "Admin sans Programmes" });
  }
  if (hasAdminTrainerEnabled) {
    adminOptions.push({ value: "Formateur", label: "Formateur" });
  }
  if (hasTrainerWithoutIntrasEnabled) {
    adminOptions.push({ value: "Formateur sans Programmes", label: "Formateur sans Programmes" });
  }
  if (hasTrainerQHSEEnabled) {
    adminOptions.push({ value: "Formateur QHSE", label: "Formateur QHSE" });
  }

  return (
    <div className={styles.manageAdminsView}>
      <div className={styles.manageAdminsContent}>
        <div className={styles.table}>
          <div className={cx(styles.tableRow, styles.tableHeader)}>
            <h2 className={cx(styles.tableCell, styles.tableHeaderCell)}>Responsable</h2>
            <h2 className={cx(styles.tableCell, styles.tableHeaderCell)}>Niveau d'accès</h2>
            <h2 className={cx(styles.tableCell, styles.tableHeaderCell)}>Périmètres</h2>
            <h2 className={cx(styles.tableCell, styles.tableHeaderCell)}>Modules</h2>
          </div>
          {state.map((admin, key) => (
            <div className={styles.tableRow} key={key}>
              <div className={styles.tableCell}>
                <h3 className={styles.fullName}>{admin.fullName}</h3>
                <span className={styles.email}>{admin.email}</span>
              </div>
              <div className={styles.tableCell}>
                {hasAdminTrainerEnabled ||
                hasAdminTrainerWithoutIntrasEnabled ||
                hasTrainerWithoutIntrasEnabled ||
                hasTrainerQHSEEnabled ? (
                  <Select
                    className={styles.select}
                    value={admin.role}
                    options={adminOptions}
                    onChange={onChangeScopeAccess(admin.uuid)}
                  />
                ) : (
                  <span className={styles.role}>{admin.role}</span>
                )}
              </div>
              <div className={styles.tableCell}>
                <DropDownCheckbox
                  className={styles.dropdown}
                  items={admin.userAreas}
                  onChange={onChangePermissions(admin.uuid)}
                  labels={dropdownLabel}
                />
              </div>
              <div className={styles.tableCell}>
                <DropDownCheckbox
                  className={styles.dropdown}
                  items={allModules.map((module) => ({
                    label: ModulesLabel[module],
                    value: module,
                    isSelected: admin.userModules?.includes(module),
                  }))}
                  onChange={onChangeModules(admin.uuid)}
                  labels={dropdownLabel}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
