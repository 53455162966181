import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import cx from "classnames";

import { DSButton, MaterialIcons, Modal } from "@skillup/ui";
import type { UserRoutes } from "types/api";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";

import { UserModalProps } from "../types";
import styles from "../ConnectionModes.module.scss";
import ClipboardText from "./ClipboardText";

const NewPasswordsModal = ({
  users,
  passwords,
  onClose,
}: UserModalProps & { passwords: Record<string, string> }) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();

  async function downloadPdf() {
    setLoading(true);
    try {
      await downloadTypedFileAsUser<UserRoutes.DownloadUsersPassword>(
        {
          method: "POST",
          path: "/users/password/pdf",
          payload: { passwords },
        },
        {
          filename: "Identifiants de connexion.pdf",
          target: "API",
          mimeType: "application/pdf",
        }
      );
    } catch (e) {
      addToast("Une erreur est survenue", { appearance: "error" });
    }
    setLoading(false);
  }

  if (users.length === 1) {
    const user = users[0];
    return (
      <Modal
        className={cx(styles.UserActionModal, styles.NewPasswordModal)}
        title="Nouveau mot de passe"
        onClose={onClose}
      >
        <div className={styles.modalContent}>
          <h2>Identifiant : {user.login}</h2>
          <div className={styles.explainText}>
            Voici le nouveau mot de passe à transmettre à {user.properties.fullName} :
          </div>
          <ClipboardText
            className={styles.clipboardText}
            value={passwords[user.properties.uuid]}
            tooltipText="Copier le mot de passe"
          />
          <div className={styles.warning}>
            <MaterialIcons.Warning size={24} />
            <div>
              Attention, une fois cette fenêtre fermée, vous ne pourrez plus afficher ce mot de
              passe.
            </div>
          </div>
        </div>
        <div className={styles.modalButtons}>
          <DSButton label={"Télécharger au format PDF"} loading={loading} onClick={downloadPdf} />
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      className={cx(styles.UserActionModal, styles.NewPasswordModal)}
      title="Réinitialisation de mot de passe"
      onClose={onClose}
    >
      <div className={styles.modalContent}>
        Les mots de passe associés aux différents identifiants sont prêts à être téléchargés.
      </div>
      <div className={styles.modalButtons}>
        <DSButton label={"Télécharger au format PDF"} loading={loading} onClick={downloadPdf} />
      </div>
    </Modal>
  );
};

export default NewPasswordsModal;
