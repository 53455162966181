import { CampaignsRoutes } from "@skillup/espace-rh-bridge";

import { useRouteQuery } from "hooks";

export const useCampaigns = (userAreas: string[]) => {
  const query = useRouteQuery<CampaignsRoutes.GetList>(["campaigns"], {
    method: "GET",
    path: "/campaigns",
    query: {
      areas: userAreas?.join(","),
      archived: true,
    },
  });
  if (!query.data) return [];
  return query.data;
};
