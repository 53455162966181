import { useCallback, useEffect, useMemo } from "react";
import { useSetState } from "react-use";
import { ISimpleSelectionUser } from "@skillup/types";

import {
  chevronRight as chevronRightIcon,
  chevronLeft as chevronLeftIcon,
} from "uiAssets/StrokeIcons";

import Acta from "utils/Acta";
import User from "utils/User";

import ManualTrainingForm, { ManualTrainingData } from "../../ManualRowForms/Training";
import ManualUserSelection from "../../ManualRowForms/UserSelection";
import ControlledHeader from "../../components/ControlledHeader/ControlledHeader";
import Choices from "../../components/Choices";
import { UsersListProvider } from "components/AddTraineesModal/add-trainees-context";

import { ScheduleRowManualRequestRoute } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

interface Props {
  scheduleUuid: string;
  addRowsToList: (rowsUuids: string[]) => Promise<void>;
  showCatalog: () => void;
}

const AddRowToCollectionModal = ({ addRowsToList, scheduleUuid, showCatalog }: Props) => {
  const [state, setState] = useSetState<{
    training?: ManualTrainingData;
    users: ISimpleSelectionUser[];
    step: "choice" | "training" | "user" | "save";
    isSaving: boolean;
  }>({ users: [], step: "choice", isSaving: false });
  const { isSaving, training, step, users } = state;
  const useTaxSystem = User.isCompanyWithTax();

  const save = useCallback(async () => {
    setState({ isSaving: true });
    try {
      const addRowToCollectionRequest = buildRequest<ScheduleRowManualRequestRoute.AddToCollection>(
        {
          method: "POST",
          path: "/scheduleRow/manual/collection",
          payload: {
            scheduleUuid,
            tab: "A Valider RH",
            trainingData: {
              ...training,
              sessionDuration: training.sessionDuration,
            },
            users: users.map((user) => user.uuid),
          },
        }
      );

      const { createdRows } = await addRowToCollectionRequest();
      addRowsToList(createdRows);

      Acta.dispatchEvent("sendAppMessage", {
        message: "Ajout réussi !",
        type: "success",
      });
      Acta.dispatchEvent("closeModal");
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de cet ajout de ligne",
        type: "error",
      });
      setState({ step: "user", isSaving: false });
    }
  }, [addRowsToList, training, users, scheduleUuid, setState]);

  useEffect(() => {
    if (step === "save" && !isSaving) {
      save();
    }
  }, [step, save, isSaving]);

  const backButtonHandler = useMemo(() => {
    if (step === "training") return () => setState({ step: "choice" });
    if (step === "user") return () => setState({ step: "training" });
    return undefined;
  }, [step, setState]);

  const headerLabel = {
    choice: "Ajouter au recueil",
    training: "Ajouter une ligne hors Skillup 1/2",
    user: "Ajouter une ligne hors Skillup 2/2",
  }[step];

  return (
    <div>
      <ControlledHeader label={headerLabel} backButtonHandler={backButtonHandler} />
      {step === "choice" && (
        <Choices
          choices={[
            {
              description:
                "Ajouter une ligne au plan depuis mes programmes, mes formations recommandées ou le catalogue Skillup.",
              title: "Depuis Skillup",
              icon: chevronRightIcon,
              onClick: showCatalog,
            },
            {
              description:
                "Ajouter une ligne au plan sans que cette dernière ne soit reliée à un programme du catalogue Skillup. Vous pourrez renseigner les informations nécessaires à son suivi dans Skillup.",
              title: "Programme hors Skillup",
              icon: chevronLeftIcon,
              onClick: () => setState({ step: "training" }),
            },
          ]}
        />
      )}

      {step === "training" && (
        <ManualTrainingForm
          useTaxSystem={useTaxSystem}
          trainingData={training}
          onSuccess={(trainingData) => {
            setState({ training: trainingData, step: "user" });
          }}
        />
      )}

      {["user", "save"].includes(step) && (
        <UsersListProvider>
          <ManualUserSelection
            isLoading={step === "save"}
            trainingData={training}
            target="collection"
            onSuccess={(data) => {
              setState({
                users: data.users,
                step: "save",
              });
            }}
          />
        </UsersListProvider>
      )}
    </div>
  );
};

export default AddRowToCollectionModal;
