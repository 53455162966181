import styled from "styled-components";
import { DSButton, DSColor, SkuiSpacing } from "@skillup/ui";

export const SkillLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: ${DSColor["surface-light-darker"]};
`;

export const SkillCategoriesListWrapper = styled.div`
  padding-top: ${SkuiSpacing.m};
  max-width: 650px;
`;

export const CreationButton = styled(DSButton)`
  margin-top: ${SkuiSpacing.s};
  margin-bottom: ${SkuiSpacing.s};
`;
