import { useState } from "react";

import { useModal } from "@skillup/ui";
import { getInitials } from "@skillup/shared-utils";

import { SidePanel } from "./SidePanel";
import {
  Gauge,
  Angle,
  CollabCircle,
  GaugeContainer,
  FirstGaugeValue,
  ThirdGaugeValue,
  SecondGaugeValue,
  AvatarGroupStyled,
} from "./LevelGauge.styled";

export interface Employee {
  role: string;
  fullName: string;
  employeeUuid: string;
  averageEvaluationsScore: number;
}

type GroupedEmployeesByEvaluation = {
  [averageEvaluationsScore: number]: Employee[];
};

interface GaugePropsType {
  name: string;
  employees: Array<Employee>;
}

export interface SidePanelPropsType {
  name: string;
  employees: Array<Employee>;
}

const LevelGauge = ({ employees, name }: GaugePropsType) => {
  const sidePanelState = useModal();

  const [sidePanelProps, setSidePanelProps] = useState<undefined | SidePanelPropsType>();

  const openSidePanelWithEmployees = (props: SidePanelPropsType) => {
    setSidePanelProps(props);
    sidePanelState.show();
  };

  const groupedEmployeeByEvaluation = employees.reduce(
    (acc: GroupedEmployeesByEvaluation, employee) => {
      const evaluation = employee.averageEvaluationsScore;

      const key = Math.floor(evaluation / 10) * 10;
      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(employee);

      return acc;
    },
    {}
  );

  return (
    <GaugeContainer>
      <Gauge>
        {Object.keys(groupedEmployeeByEvaluation)?.map((key, index) => {
          const employees: Array<Employee> = groupedEmployeeByEvaluation[key];

          return (
            <CollabCircle
              key={index}
              level={parseFloat(key)}
              onClick={() =>
                openSidePanelWithEmployees({
                  employees,
                  name,
                })
              }
            >
              <Angle />
              <AvatarGroupStyled
                size="S"
                maxItems={2}
                firstElementForeground
                totalItems={employees.length}
                onClick={() =>
                  openSidePanelWithEmployees({
                    employees,
                    name,
                  })
                }
                users={employees.map((employee) => {
                  const { employeeUuid, fullName } = employee;

                  return {
                    uuid: employeeUuid,
                    fullName,
                    initials: getInitials({ fullName }),
                  };
                })}
              />
            </CollabCircle>
          );
        })}
        <FirstGaugeValue>
          <span>0</span>
        </FirstGaugeValue>
        <SecondGaugeValue>
          <span>50</span>
        </SecondGaugeValue>
        <ThirdGaugeValue>
          <span>100</span>
        </ThirdGaugeValue>
      </Gauge>

      {sidePanelState.isOpen && (
        <SidePanel
          isOpen={sidePanelState.isOpen}
          sidePanelProps={sidePanelProps}
          close={() => sidePanelState.hide()}
        />
      )}
    </GaugeContainer>
  );
};

export default LevelGauge;
