import { useMemo } from "react";

import { Config, ICompanyField } from "@skillup/training-bridge";
import { DSDataGrid, DSFilters } from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";
import { GridColDef } from "@mui/x-data-grid-pro";

import useTranslation from "hooks/useTranslation";

import { GridProps, initialState } from "./config";
import style from "./GridFields.module.scss";
import useFiltersSettings from "../hooks/useFiltersSettings";
import generateColumns from "../utils/generateColumns";

const GridStandardFields = (props: GridProps) => {
  const { t } = useTranslation();
  const [filterRef, filters, filterValues, handleFilterValuesChange, handleTableColumnFilterClick] =
    useFiltersSettings(false);

  const columns: GridColDef[] = useMemo(() => {
    return generateColumns(t, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayedRows: ICompanyField[] = useMemo(() => {
    const datasetFilterManager = new ListUtils.InMemoryDataset(
      Config.Fields.FieldsFilters,
      props.fields
    );

    return datasetFilterManager.getRows({ filterValues });
  }, [props.fields, filterValues]);

  return (
    <div className={style.gridContainer}>
      <h4 className={style.mainTitle}>
        {t("fields.standardFields.mainTitle", {
          defaultValue: "Champs standards",
        })}
      </h4>
      <DSFilters
        t={t}
        config={Config.Fields.FieldsFilters}
        ref={filterRef}
        filters={filters}
        onChange={handleFilterValuesChange}
        translationPrefix="fields.standardFields"
        className={style.filters}
      />
      <DSDataGrid
        getRowId={(row) => row.uuid}
        loading={true}
        rowCount={displayedRows.length}
        columns={columns}
        rows={displayedRows}
        initialState={initialState}
        onRowClick={(gridRow) => {
          props.onSelectField(gridRow.row);
        }}
        pagination
        translationPrefix="fields.standardFields"
        onFilter={handleTableColumnFilterClick}
        className={style.grid}
      />
    </div>
  );
};

export default GridStandardFields;
