import { buildRequest } from "utils/buildRequest";
import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";

export interface IRow {
  uuid: string;
  booking?: string;
  leadInter?: string;
  leadIntra?: string;
}

export default async (rows: Array<IRow>, useCache: boolean) => {
  const request = await buildRequest<ScheduleRowRoutes.GetDetails>({
    method: "POST",
    path: "/scheduleRow/details",
    payload: {
      rows: rows.map((row) => ({
        primary: row.leadInter ? "leadInter" : row.leadIntra ? "leadIntra" : "booking",
        uuid: row.uuid,
      })),
      useCache,
    },
  });
  const details = await request();

  return details;
};
