import React, { useEffect } from "react";
import { useSetState } from "react-use/lib";
import { useDropzone } from "react-dropzone";

import type { GetPortailData, PortailUploadImage } from "types/api";
import { buildFileRequest, buildRequest } from "utils/buildRequest";
import Acta from "utils/Acta";

import Loader from "components/Loader";
import { ReactComponent as DefaultMountain } from "assets/default-mountains.svg";
import LoadingError from "../../LoadingError";
import styles from "./PortalAppearence.module.scss";

interface IPortalData {
  portalLogo: string;
  portalBackground: string;
  portalIntroMessage: string;
  portalIntroMessageColor: string;
  portalIntroButtonColor: string;
  portalIntroButtonBackgroundColor: string;
}
interface IState {
  PortalData?: IPortalData;
  loadingError?: boolean;
  backgroundLoading?: boolean;
  logoLoading?: boolean;
  previewBackgroundColour: string;
  portalBackgroundLoading?: boolean;
  portalLogoLoading?: boolean;
}

export default function PortalAppearence() {
  const userData = Acta.getState("userData");
  const companyUuid = userData.activeCompany.uuid;

  const [state, setState] = useSetState<IState>({
    previewBackgroundColour: "#888",
  });
  const { PortalData, loadingError } = state;
  useEffect(() => {
    const fetch = async () => {
      try {
        const { properties } = await buildRequest<GetPortailData>({
          method: "GET",
          path: "/portal/settings",
        })();

        setState({
          PortalData: properties,
        });
      } catch (error) {
        setState({ loadingError: true });
      }
    };
    fetch();
  }, [setState]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => onDropImage(companyUuid)(files, "portalBackground"),
    maxSize: 52428800,
    multiple: false,
  });

  if (loadingError) return <LoadingError />;

  if (!PortalData) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const onDropImage =
    (companyUuid: string) =>
    async (files: Array<File>, target: "portalBackground" | "portalLogo"): Promise<void> => {
      setState({
        [`${target}Loading`]: true,
      });

      try {
        const data = await buildFileRequest<PortailUploadImage>({
          method: "POST",
          path: "/portal/settings/image/{companyUuid?}",
          params: { companyUuid },
          query: { target },
          file: files[0],
        })();

        setState({
          [`${target}Loading`]: false,
          PortalData: {
            ...state.PortalData,
            //@ts-ignore
            ...data,
          },
        });
        Acta.dispatchEvent("sendAppMessage", {
          message: "Image mise à jour.",
          type: "success",
        });
      } catch (error) {
        setState({
          [`${target}Loading`]: false,
        });
        Acta.dispatchEvent("sendAppMessage", {
          message: "Échec de l’upload.",
          type: "error",
        });
      }
    };

  return (
    <div className={styles.PortalAppearence}>
      <h3 className={styles.title}>Bannière portail collaborateur</h3>

      <img src={`${process.env.PUBLIC_UPLOADS_URL}${PortalData.portalBackground}`} alt="" />
      <section className={styles.dropZone}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {
            <div>
              <DefaultMountain />
              <p>Déposez une image ici ou cliquez ici pour choisir une image</p>
            </div>
          }
        </div>
      </section>
    </div>
  );
}
