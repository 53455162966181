import { Flex } from "@skillup/ui";

import { Divided } from "../ChildWrappers";
import { Child } from "../../../../reducer";
import { Permissions } from "../../Permissions/Permissions";

import styles from "./FileUploadChild.module.scss";

type FileUpload = Extract<Child, { kind: "upload" }>;

export function FileUploadChild({
  child,
  indexes,
}: {
  child: FileUpload;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
}) {
  return (
    <Divided>
      <Flex row className={styles.fileUploadChild}>
        Le bloc « Téléchargement de fichiers » permet aux participants d’uploader des documents. Ces
        documents pourront être téléchargés par le collaborateur, le responsable de l’entretien et
        toutes les personnes habilitées à accéder à l’entretien. Ils seront annexés au PDF de
        l’entretien.
      </Flex>
      <Flex column>
        <Permissions
          restrictions={child.disallow}
          uuid={child.uuid}
          indexes={indexes}
          kind="upload"
          order={["upload", "comment"]}
          override={{
            comment: "Qui peut commenter le bloc ?",
          }}
          mandatory={{
            upload:
              "Vous devez sélectionner au moins un participant qui peut ajouter des fichiers.",
          }}
        />
      </Flex>
    </Divided>
  );
}
