import { useState } from "react";
import { parse, differenceInDays } from "date-fns";
import { useToasts } from "react-toast-notifications";

import { DataTable, DSDropdownItem, MaterialIcons } from "@skillup/ui";
import { useBaseUrl } from "@skillup/hooks";
import { plural } from "utils/locale";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
import { InterviewsRoutes } from "@skillup/espace-rh-bridge";

import { InterviewResults } from "../types";
import styles from "../UserInterview.module.scss";
import useTableData from "./useTableData";
import DownloadModal from "./DownloadModal";

interface Props {
  readonly interviews: InterviewResults;
}

export default ({ interviews }: Props) => {
  const { addToast } = useToasts();
  const { baseUrl } = useBaseUrl();
  const [interviewDownloadModal, setInterviewDownloadModal] = useState<InterviewResults[0] | null>(
    null
  );

  const { columns, data: tableData } = useTableData({ interviews: interviews ?? [] });

  const handleDownload = async (interviewUuid: string) => {
    try {
      await downloadTypedFileAsUser<InterviewsRoutes.GetPDF>(
        {
          method: "GET",
          path: "/interview/{interviewUuid}/pdf",
          params: { interviewUuid },
        },
        {
          target: "API",
          deduceFileDataFromResponseHeaders: true,
        }
      );
    } catch (error) {
      addToast("Une erreur est survenue pendant le téléchargement du fichier PDF.", {
        appearance: "error",
      });
    }
  };

  return (
    <>
      <DataTable
        className={styles.table}
        header={{
          totalRowsLabel: (total) => {
            let label = plural(total, "%n entretien%s");
            if (total < interviews.length) {
              label += `${plural(total, " filtrée%s")} sur ${interviews.length}`;
            }
            return label;
          },
        }}
        columns={columns}
        rows={tableData}
        mode="compact"
        actionsTooltip="Aucune action disponible pour cet entretien importé dans Skillup"
        actions={(row) =>
          row.data.source === "imported" && !row.data.file
            ? []
            : [
                <DSDropdownItem
                  key="download-pdf"
                  label="Télécharger l'entretien (PDF)"
                  onClick={() => {
                    const diff = differenceInDays(
                      parse(row.data.endDate, "dd/MM/yyyy", new Date()),
                      new Date()
                    );
                    if (diff >= 0) {
                      setInterviewDownloadModal(row.data);
                    } else {
                      handleDownload(row.data.uuid);
                    }
                  }}
                />,
                <DSDropdownItem
                  key="show-interview"
                  tooltipLabel={
                    row.data.source === "imported"
                      ? "Cet entretien a été importé dans Skillup"
                      : undefined
                  }
                  disabled={row.data.source === "imported"}
                  label={
                    <>
                      Voir l’entretien dans le suivi de campagne{" "}
                      <MaterialIcons.OpenInNew
                        style={{ fontSize: "1rem", verticalAlign: "middle" }}
                      />
                    </>
                  }
                  onClick={() => {
                    const win = window.open(
                      `${baseUrl}/campagne/${row.data.campaign.uuid}`,
                      "_blank"
                    );
                    win.focus();
                  }}
                />,
              ]
        }
        pagination={{
          rowsPerPageLabel: "Entretiens par page",
          itemsCountLabel: "Entretiens %range% sur %count%",
          pageLabel: "Page",
        }}
      />
      {interviewDownloadModal && (
        <DownloadModal
          onClose={() => setInterviewDownloadModal(null)}
          onConfirm={() => {
            handleDownload(interviewDownloadModal.uuid);
            setInterviewDownloadModal(null);
          }}
        />
      )}
    </>
  );
};
