import styled from "styled-components";

import { DSModal, DSCheckbox } from "@skillup/ui";

export const AiJobModal = styled(DSModal)`
  width: 40rem;
`;

export const LoadingImage = styled.img`
  aspect-ration: 1/1;
  height: 7rem;
`;

export const Checkbox = styled(DSCheckbox)`
  width: 100%;

  div {
    width: 100%;

    label {
      width: 95%;
      text-overflow: ellipsis;
    }
  }
`;
