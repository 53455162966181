import { v4 as uuid } from "uuid";
import type { TemplateItemPosition, PageBlock, BuilderTemplate } from "../../types";
import { replacePagesInSection, switchPages } from "../actions";

// keep old system with immutable add (but no hideInBuilder system)
// export function movePageToPage(
//   sections: Section[],
//   fromPosition: TemplateItemPosition<PageBlock>,
//   toPosition: TemplateItemPosition<PageBlock>
// ) {
//   const { sectionIndex: fromSectionIndex, pageIndex: fromPageIndex } = fromPosition;
//   const { sectionIndex: toSectionIndex, pageIndex: toPageIndex } = toPosition;

//   const fromSection = sections[fromSectionIndex];
//   const toSection = sections[toSectionIndex];
//   if (fromSectionIndex === toSectionIndex) {
//     const newPages = switchPages(fromSection.pages, fromPageIndex, toPageIndex);

//     const newSection = replacePagesInSection(fromSection, newPages);

//     const newSections = replaceSection(sections, newSection, fromSectionIndex);
//     return newSections;
//   }
//   const fromPage = fromSection.pages[fromPageIndex];

//   const newFromSection = removePageFromSection(fromSection, fromPageIndex);
//   const newToSection = addPageToSection(toSection, toPageIndex, fromPage);

//   const newSectionsTemp = replaceSection(sections, newFromSection, fromSectionIndex);
//   const newSections = replaceSection(newSectionsTemp, newToSection, toSectionIndex);
//   return newSections;
// }

export function movePageToPage(
  state: BuilderTemplate,
  fromPosition: TemplateItemPosition<PageBlock>,
  toPosition: TemplateItemPosition<PageBlock>
) {
  const { sectionIndex: fromSectionIndex, pageIndex: fromPageIndex } = fromPosition;
  const { sectionIndex: toSectionIndex, pageIndex: toPageIndex } = toPosition;

  const fromSection = state.sections[fromSectionIndex];
  const toSection = state.sections[toSectionIndex];

  if (fromSectionIndex === toSectionIndex) {
    const newPages = switchPages(fromSection.pages, fromPageIndex, toPageIndex);

    const newSection = replacePagesInSection(fromSection, newPages);
    state.sections[fromSectionIndex] = newSection;
    return state;
  }

  const newPagesToMove = fromSection.pages.splice(fromPageIndex, 1);
  // if the from section now have 0 pages, we add a new page (hideInBuilder)
  if (fromSection.pages.length === 0) {
    fromSection.pages = [
      {
        uuid: uuid(),
        title: "Nouvelle sous-section",
        children: [],
        hideInBuilder: true,
      },
    ];
  }

  // toSection has only one page and this page is hideInBuilder and has no children
  // --> We just replace this page with the new one
  if (
    toSection.pages.length === 1 &&
    toSection.pages[0].hideInBuilder &&
    toSection.pages[0].children?.length === 0
  ) {
    toSection.pages = newPagesToMove;
  } else {
    toSection.pages.splice(toPageIndex, 0, newPagesToMove[0]);
  }
  toSection.pages.forEach((page) => {
    if (page.hideInBuilder) {
      page.hideInBuilder = undefined;
    }
  });

  return state;
}
