import * as z from "zod";

export const SkillEvaluationScaleSchema = z.object({
  evaluationScaleName: z
    .string({
      required_error: "Ce champ est obligatoire",
    })
    .trim()
    .nonempty({ message: "Ce champ ne doit pas être vide" }),
  startAtZero: z.boolean(),
  hasDescriptions: z.boolean(),
  levels: z.array(
    z.object({
      level: z.number().nonnegative(),
      name: z
        .string({
          required_error: "Ce champ est obligatoire",
        })
        .trim()
        .nonempty({ message: "Ce champ ne doit pas être vide" }),
      description: z.string().trim().optional(),
      deletable: z.boolean(),
      placeholder: z.string().optional(),
    })
  ),
});

export const UpdateSkillEvaluationScaleSchema = SkillEvaluationScaleSchema.merge(
  z.object({
    uuid: z.string(),
  })
);
