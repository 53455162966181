import { Route, Switch, Redirect } from "react-router-dom";

import { PPRCampaignsList } from "./PPRCampaignsList/PPRCampaignsList";
import { CampaignCreation } from "./CampaignCreation/CampaignCreation";
import { CampaignSupervisionWrapper } from "./CampaignSupervision/CampaignSupervisionWrapper";

export const PeopleReviewRouter = () => {
  return (
    <Switch>
      <Route exact component={PPRCampaignsList} path={PeopleReviewRoutes.PPRCampaignsList} />
      <Route component={CampaignSupervisionWrapper} path={PeopleReviewRoutes.CampaignSupervision} />
      <Route component={CampaignCreation} path={PeopleReviewRoutes.CampaignCreation} />
      <Redirect to={PeopleReviewRoutes.PPRCampaignsList} />
    </Switch>
  );
};

export const BaseUrl = "/responsable/people-review" as const;
export const PeopleReviewRoutes = {
  BaseUrl,
  CampaignCreation: `${BaseUrl}/campaign-creation`,
  CampaignSupervision: `${BaseUrl}/campaigns/:campaignID/:currentView`,
  PPRCampaignsList: `${BaseUrl}/campaigns`,
} as const;
