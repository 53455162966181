import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Flex } from "@skillup/ui";
import { DNDSectionItem } from "../../dndReducer";
import { StructureSection } from "./StructureSection";
import { useBuilderContext } from "../../../BuilderContext";
import { selectHighlightUuid, setHighlightUuid } from "../../../reducer";
import styles from "../../StructureBuilderStructure.module.scss";

export const ImmutableSection = ({
  section,
  isDragging,
}: {
  section: DNDSectionItem;
  isDragging: boolean;
}) => {
  const { clickStructureItem } = useBuilderContext();
  const highlightUuid = useSelector(selectHighlightUuid);

  const handleClick = useCallback(
    (id: string) => {
      clickStructureItem(id);
    },
    [clickStructureItem]
  );

  const dispatchStore = useDispatch();

  const handleFocus = useCallback(
    (uuid: string) => {
      dispatchStore(setHighlightUuid(uuid));
    },
    [dispatchStore]
  );

  return (
    <Flex column className={isDragging ? styles["section--immutable--whenDragging"] : ""}>
      <StructureSection
        id={`structure_${section.uuid}`}
        title={section.title}
        errors={section.errors?.map((error) => error.structure)}
        highlight={highlightUuid === section.uuid}
        onClick={() => handleClick(section.uuid)}
        isImmutableSection
        onFocus={() => handleFocus(section.uuid)}
      />
    </Flex>
  );
};
