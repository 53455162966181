import { useMemo } from "react";

import { DSSidePanel, DSTooltip, DSUserPreview, Flex } from "@skillup/ui";
import { DSRating } from "@skillup/ui";
import { ReviewsRoutes } from "@skillup/espace-rh-bridge";

import { useTypedFetch } from "hooks";
import useTranslation from "hooks/useTranslation";
import { getUserInitials } from "utils/User";

import {
  MainAction,
  MenuActions,
  ReviewPanelContentNotReviewed,
} from "../../components/ReviewSidepanel";

import styles from "./HotReviewSidePanel.module.scss";
import HotReviewPanelContentReviewed from "./SidepanelContent/HotReviewPanelContentReviewed";

interface Props {
  readonly uuid: string;
  readonly onClose: () => void;
  readonly refetchRow: () => void;
}

const HotReviewSidePanel = ({ uuid, onClose, refetchRow }: Props) => {
  const { t } = useTranslation();

  const { data: reviewsDetails = [], refetch } = useTypedFetch<ReviewsRoutes.GetHotReviewsDetails>({
    method: "POST",
    path: `/reviews/hot/details`,
    payload: {
      reviewUuids: [uuid],
    },
  });

  const reviewDetails = reviewsDetails[0];

  const invalidateData = useMemo(() => {
    return () => {
      refetch();
      refetchRow();
    };
  }, [refetch, refetchRow]);

  const hasBeenReviewed = useMemo(() => {
    if (reviewDetails) {
      const hasMultipleChoiceWithScore = reviewDetails.questions.some((question) => {
        return question.type === "multiple" && question.score;
      });

      return hasMultipleChoiceWithScore;
    }

    return false;
  }, [reviewDetails]);

  if (!reviewDetails) {
    return null;
  }

  const tooltipDisabled = reviewDetails.session.training.name.length > 60;
  const { comment: headerComment } = reviewDetails ?? {};

  return (
    <DSSidePanel
      isOpen={!!uuid}
      portalTarget={document.body}
      onBackgroundClick={onClose}
      isAnimated={false}
      className={styles.HotReviewSidePanel}
    >
      <DSSidePanel.Header onClose={onClose}>
        <DSSidePanel.Header.Title
          title={t("trainings.manage.reviews.sidepanel.title", {
            name: reviewDetails.trainee.fullName,
            defaultValue: "Évaluation de {{ name }}",
          })}
        />
        <DSTooltip
          disabled={!tooltipDisabled}
          className={styles.subtitleWrapper}
          label={reviewDetails.session.training.name}
        >
          <DSSidePanel.Header.SubTitle subtitle={reviewDetails.session.training.name} />
        </DSTooltip>
        {reviewDetails.comment && (
          <DSSidePanel.Header.CustomContent>
            <div className={styles.headerContent}>
              {reviewDetails.score && (
                <>
                  <p>
                    {t("trainings.manage.reviews.sidepanel.rate", {
                      defaultValue: "Note générale",
                    })}
                  </p>
                  <DSRating value={reviewDetails.score} max={5} darkMode />
                </>
              )}
              {headerComment && headerComment.trim() && (
                <>
                  <DSUserPreview
                    className={styles.userPreview}
                    initials={getUserInitials(reviewDetails.trainee)}
                    fullName={reviewDetails.trainee.fullName}
                    size="M"
                  />
                  <Flex className={styles.comment} column>
                    <div>{headerComment}</div>
                  </Flex>
                </>
              )}
            </div>
          </DSSidePanel.Header.CustomContent>
        )}
        <Flex className={styles.actionMenu}>
          <MainAction
            status={reviewDetails.status}
            reviewUuid={reviewDetails.uuid}
            invalidateData={invalidateData}
          />
          <MenuActions status={reviewDetails.status} url={reviewDetails.url} />
        </Flex>
      </DSSidePanel.Header>

      <DSSidePanel.Content key={reviewDetails.uuid}>
        <div className={styles.content}>
          {hasBeenReviewed && <HotReviewPanelContentReviewed reviewDetails={reviewDetails} />}
          {!hasBeenReviewed && <ReviewPanelContentNotReviewed reviewDetails={reviewsDetails[0]} />}
        </div>
      </DSSidePanel.Content>

      <DSSidePanel.FooterEmpty />
    </DSSidePanel>
  );
};

export default HotReviewSidePanel;
