import { Fragment, useMemo } from "react";
import { orderBy } from "lodash";

import { ProgressBar, DSColors } from "@skillup/ui";

import { ChartIndicatorData } from "../../types";

import styles from "./BarList.module.scss";

interface Props {
  data: ChartIndicatorData;
}

const getNumberValue = (datum: Props["data"][0]) => {
  return datum.numberValue ?? datum.rawValue;
};

export default function BarList({ data }: Props) {
  const { values, maxValue } = useMemo(() => {
    return {
      maxValue: data ? Math.max(...data.map(getNumberValue)) : 0,
      values:
        orderBy(
          data?.map((datum) => ({ ...datum, numberValue: getNumberValue(datum) })),
          ["numberValue"],
          ["desc"]
        ) || [],
    };
  }, [data]);

  return (
    <div className={styles.BarList}>
      {values?.map((datum, index) => (
        <Fragment key={index}>
          <label>{datum.label || "N/A"}</label>
          <ProgressBar
            value={datum.numberValue}
            max={maxValue}
            side="none"
            color={DSColors["decoration-blue-base"]}
            className={styles.bar}
          />
          <div className={styles.value}>{datum.formattedValue}</div>
        </Fragment>
      ))}
    </div>
  );
}
