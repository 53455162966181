import React, { Fragment, useMemo } from "react";
import { match } from "react-router-dom";

import Acta from "utils/Acta";
import { IWithRouter } from "utils/types";

import { DSButton, MaterialIcons } from "@skillup/ui";
import { DashboardChildType } from "@skillup/espace-rh-bridge";

import ChooseCampaignModale from "../CampaignsList/ChooseCampaignModale";
import ClosedEndedQuestion from "./components/ClosedEndedQuestion/ClosedEndedQuestion";
import OpenEndedQuestion from "./components/OpenEndedQuestion/OpenEndedQuestion";
import PlaceholderCard from "./components/PlaceholderCard/PlaceholderCard";

import GlobalFilters from "./components/GlobalFilters/GlobalFilters";
import Summary from "./components/Summary/Summary";
import TableOfContent from "./components/TableOfContent/TableOfContent";
import { StructureChildPayload } from "./components/DashboardChildCard/DashboardChildCard";
import DSLayout from "components/DSLayout";

import { useDashboardData, DashboardDataContextProvider } from "./context";
import { useCampaigns } from "./state/campaigns";
import NoInterviewsSVG from "./assets/NoInterviews";

import styles from "./InterviewDashboard.module.scss";

interface IProps extends IWithRouter {
  match: match<{ interviewUuid?: string }>;
}

const Dashboard = ({ match: { params } }: IProps) => {
  const { interviewUuid } = params;
  const { userAreas } = useDashboardData();
  const fetchedCampaigns = useCampaigns(userAreas);

  const noInterviewsAfterLoad = useMemo(
    () => fetchedCampaigns && !fetchedCampaigns.length,
    [fetchedCampaigns]
  );

  const chooseCampaignModale = () => {
    Acta.setState("modalDisplayed", {
      content: <ChooseCampaignModale />,
      size: "small",
      title: "Nouveau",
      showOverflow: true,
    });
  };

  return (
    <DashboardDataContextProvider>
      <DSLayout title="Dashboard - Entretiens">
        <div className={styles.Dashboard}>
          {!noInterviewsAfterLoad && fetchedCampaigns && (
            <div className={styles.content}>
              <div className={styles.main}>
                <GlobalFilters activeCampaign={interviewUuid} />
                <Summary />
                <DashboardChildren />
              </div>
              <div className={styles.secondary}>
                <TableOfContent />
              </div>
            </div>
          )}
          {noInterviewsAfterLoad && (
            <div className={styles.noInterviews}>
              <p>
                Vous n’avez encore lancé aucune campagne d’entretiens, il n’y a rien à afficher ici
                pour l’instant.
              </p>
              <DSButton
                className={styles.campaignButton}
                label="Créer une campagne"
                onClick={chooseCampaignModale}
                buttonSize="M"
                emphasis="High"
              />
              <NoInterviewsSVG />
            </div>
          )}
        </div>
      </DSLayout>
    </DashboardDataContextProvider>
  );
};

function DashboardChildren() {
  const { campaignStructure } = useDashboardData();

  return (
    <div className={styles.DashboardChildren}>
      {campaignStructure.map((section) => (
        <Fragment key={section.uuid}>
          <h2
            id={`section-${section.uuid}`}
            className={section.pages.length > 1 ? "" : styles.aloneTitle}
          >
            {section.title}
          </h2>
          {section.pages.map((page) => (
            <Fragment key={page.uuid}>
              {section.pages.length > 1 && <h3 id={`page-${page.uuid}`}>{page.title}</h3>}
              {page.children.map((child) => (
                <DashboardChild key={child.uuid} child={child} />
              ))}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </div>
  );
}
interface DashboardChildProps {
  child: StructureChildPayload;
}
function DashboardChild({ child }: DashboardChildProps) {
  switch (child.type) {
    case DashboardChildType.CLOSED_QUESTION:
      return <ClosedEndedQuestion child={child} />;
    case DashboardChildType.OPEN_QUESTION:
      return <OpenEndedQuestion child={child} />;
    case DashboardChildType.TABLE:
      return (
        <PlaceholderCard
          child={child}
          typeName="Tableau"
          title={child.label}
          icon={<MaterialIcons.OutlineTableRows />}
        />
      );
    case DashboardChildType.CURRENT_TARGETS:
      return (
        <PlaceholderCard
          child={child}
          typeName="Objectifs évalués"
          icon={<MaterialIcons.TrackChanges />}
        />
      );
    case DashboardChildType.GLOBAL_TARGETS_COMPLETION:
      return (
        <PlaceholderCard
          child={child}
          typeName="Taux d’atteinte global"
          icon={<MaterialIcons.OutlineTableRows />}
        />
      );
    case DashboardChildType.FUTURE_TARGETS:
      return (
        <PlaceholderCard
          child={child}
          typeName="Objectifs futurs"
          icon={<MaterialIcons.TrackChanges />}
        />
      );
    case DashboardChildType.SKILLS:
      return (
        <PlaceholderCard child={child} typeName="Compétences" icon={<MaterialIcons.Psychology />} />
      );
    case DashboardChildType.TRAININGS_HISTORY:
      return (
        <PlaceholderCard
          child={child}
          typeName="Historique de formations"
          icon={<MaterialIcons.History />}
        />
      );
    case DashboardChildType.INTERVIEWS_HISTORY:
      return (
        <PlaceholderCard
          child={child}
          typeName="Historique d’entretiens"
          icon={<MaterialIcons.History />}
        />
      );
    case DashboardChildType.TRAININGS_COLLECTION:
      return (
        <PlaceholderCard
          child={child}
          typeName="Demande de formation"
          icon={<MaterialIcons.School />}
        />
      );
    case DashboardChildType.UPLOAD:
      return (
        <PlaceholderCard
          child={child}
          typeName="Ajout de fichier"
          icon={<MaterialIcons.InsertDriveFile />}
        />
      );
    default:
      return null;
  }
}

export default Dashboard;
