import TimeField from "react-simple-timefield";
import { FormatDate, DateTime } from "@skillup/shared-utils";

import styles from "./CalendarHourSettingInput.module.scss";
interface Props {
  days: string[];
  hours: string[];
  updateHours: (hours: string[]) => void;
  key: number;
  showError: boolean;
}

const format = "d MMM yyyy";

const CalendarHourSettingInput = (props: Props) => {
  return (
    <div className={styles.interval} key={props.key}>
      <div>
        <p className={styles.dates}>
          {props.days.length === 1 ? (
            formatDate(props.days[0])
          ) : (
            <>
              {formatDate(props.days[0])}&nbsp;
              {" > "}
              &nbsp;{formatDate(props.days[1])}
            </>
          )}
        </p>
      </div>

      <div className={styles.interval}>
        <p> {"de "} </p>
        <TimeField
          value={props.hours[0]}
          onChange={(_e, value) => {
            props.updateHours([value, props.hours[1]]);
          }}
          input={<input required type="text" className={props.showError ? styles.error : ""} />}
        />
        <p> {" à "} </p>
        <TimeField
          value={props.hours[1]}
          onChange={(_e, value) => {
            props.updateHours([props.hours[0], value]);
          }}
          input={<input required type="text" className={props.showError ? styles.error : ""} />}
        />
      </div>
    </div>
  );
};

function formatDate(date: string) {
  return FormatDate.ToStringFormat(
    DateTime.fromISO(date, {
      locale: "fr",
    }),
    format
  );
}
export { CalendarHourSettingInput };
