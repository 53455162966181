import { useMemo } from "react";

import { ListUtils } from "@skillup/shared-utils";

import { DashboardCardConfig, PanelConfigType, QuestionData } from "../../types";

import { useDashboardCardData } from "../../utils/card";

import { DetailedIndicator } from "./DetailedIndicator";
import { KeyIndicator } from "./KeyIndicator";

export type Props = {
  card: DashboardCardConfig;
  filters: ListUtils.FilterValues<any>;
};

export type CardProps = {
  data: QuestionData;
  loading: boolean;
  card: DashboardCardConfig;
};

const ComponentPanelTypeMap: { [key in PanelConfigType]: (props: CardProps) => any } = {
  "detail-indicators": DetailedIndicator,
  "key-indicators": KeyIndicator,
  monthly: ({ data }) => <div>Monthly {JSON.stringify(data)}</div>,
  summary: ({ data }) => <div>Summary {JSON.stringify(data)}</div>,
};

export const DashboardCard = ({ card, filters }: Props) => {
  const { data, loading } = useDashboardCardData(card, filters);

  const type = useMemo((): PanelConfigType => {
    if (card.question_meta.axes.length === 1) {
      return "key-indicators";
    }

    if (card.question_meta.axes.length === 2) {
      return "detail-indicators";
    }

    if (card.question_meta.axes.length === 4) {
      return "monthly";
    }

    return "summary";
  }, [card]);

  const Component = ComponentPanelTypeMap[type];

  return <Component data={data} loading={loading} card={card} />;
};
