import { ScalesRoutes } from "@skillup/core-hr-bridge";
import { buildRequest } from "utils/buildRequest";

export type Payload = ScalesRoutes["Create"]["payload"];

export async function createScale(properties: Payload) {
  const result = await buildRequest<ScalesRoutes["Create"]>({
    method: "POST",
    path: `/scales`,
    target: "CORE_HR",
    payload: properties,
  })();

  return result;
}
