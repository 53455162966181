import { Flex } from "@skillup/ui";
import { CardProps } from "./Card";

export const DetailedIndicator = ({ data, loading, card }: CardProps) => {
  return (
    <Flex>
      <h1 style={{ fontWeight: "bolder" }}>{card.short_name}</h1>

      {loading && <p>Loading...</p>}
      {JSON.stringify(data)}
    </Flex>
  );
};
