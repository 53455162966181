import React from "react";

import { DSTooltip, Label } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

export type Props = {
  children: React.ReactNode;
  label: string;
  required?: boolean;
  disabled?: boolean;
  disabledValue?: string | React.ReactNode;
  disableTooltip?: string;
  classname?: string;
  gap?: Parameters<typeof Flex>[0]["gap"];
};

const FieldFormItem = ({
  children,
  classname,
  label,
  required,
  disabled,
  disabledValue,
  disableTooltip,
  gap,
}: Props) => {
  return (
    <Flex flexDirection="column" gap={gap} className={classname}>
      <Label required={required} label={label} />
      {disabled ? <DSTooltip label={disableTooltip}>{disabledValue}</DSTooltip> : children}
    </Flex>
  );
};

export default FieldFormItem;
