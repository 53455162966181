import React, { Fragment } from "react";
import cx from "classnames";

import Icon from "components/Icon";
import { IActivity } from "containers/Supervisor/routes/TrainingSchedule/utils/fetchLogs";

import Loader from "../Loader";
import styles from "./ActivityStyles.module.scss";

export interface IProps {
  activitiesByDates?: { [key: string]: Array<IActivity> };
}

export default (props: IProps) => {
  const { activitiesByDates } = props;
  const addLineBreaks = (message) =>
    message.split("\n").map((text, index) => (
      <Fragment key={index}>
        {text}
        <br />
      </Fragment>
    ));

  return (
    <div className={styles.Activity}>
      {!activitiesByDates && <Loader width={30} />}
      {activitiesByDates && Object.keys(activitiesByDates).length === 0 && (
        <div className={styles.logs}>
          <div className={styles.noActivityPlaceholder}>
            Aucune activité disponible pour cette demande
          </div>
        </div>
      )}
      {activitiesByDates && Object.keys(activitiesByDates).length > 0 && (
        <div className={styles.logs}>
          {Object.keys(activitiesByDates).map((activityDate) => (
            <React.Fragment key={activityDate}>
              <li className={styles.activityDate}>
                <header>
                  <div />
                  <div>{activityDate}</div>
                </header>
                <div />
              </li>
              {activitiesByDates[activityDate].map((logItem) => (
                <li key={logItem.properties.uuid} data-event={logItem.properties.event}>
                  <header>
                    <div>
                      {typeof logItem.icon === "string" ? (
                        <Icon strokeIcon={logItem.icon} width={14} stroke={logItem.color} />
                      ) : (
                        <>{logItem.icon}</>
                      )}
                    </div>
                    <div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: logItem.getLabel(logItem),
                        }}
                      />
                    </div>
                  </header>
                  <div className={cx({ [styles.hasMessage]: logItem.properties.message })}>
                    {logItem.properties.message && (
                      <div>
                        <p>{addLineBreaks(logItem.properties.message)}</p>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
