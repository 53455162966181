import { useTranslation } from "react-i18next";

import { z } from "zod";
import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { Flex } from "@skillup/design-system";
import { DateTime } from "@skillup/shared-utils";
import { DSButton, DatePicker, DSSimpleTextArea, DSFormGroupTextInput } from "@skillup/ui";

import { CampaignCreationState } from "types/peopleReview";

import styles from "./../steps.module.scss";

const managersValidationSchema = toFormikValidationSchema(
  z.object({
    managersHelpText: z.string().min(1),
    managersPreparationEndDate: z.string(),
  })
);

interface ManagersProps {
  prevStep: (data: Partial<CampaignCreationState>) => void;
  nextStep: (data: Partial<CampaignCreationState>) => void;
  managersHelpText: CampaignCreationState["managersHelpText"];
  managersPreparationEndDate: CampaignCreationState["managersPreparationEndDate"];
}

export default function Managers({
  managersHelpText,
  managersPreparationEndDate,
  nextStep,
  prevStep,
}: ManagersProps) {
  const { t } = useTranslation();
  const onSubmit = (data: { managersHelpText: string; managersPreparationEndDate: string }) => {
    nextStep({
      managersHelpText: data.managersHelpText,
      managersPreparationEndDate: data.managersPreparationEndDate,
    });
  };

  return (
    <Formik
      validateOnMount={false}
      validationSchema={managersValidationSchema}
      onSubmit={onSubmit}
      initialValues={{
        managersHelpText: t("peopleReview.campaignCreation.managersHelpText.placeholder", {
          defaultValue:
            "Chers Managers,\nNous lançons notre période annuelle de revue du personnel pour évaluer les performances, identifier les talents sous-exploités et adresser les éventuels signes de désengagement. Merci de partager des retours constructifs sur les réalisations et de contribuer à maximiser le potentiel de chacun de vos collaborateurs.\nVotre implication est essentielle pour le succès de ce processus.\n\n...",
        }),
        managersPreparationEndDate,
      }}
    >
      {({ handleSubmit, isValid, setFieldValue, touched, values }) => (
        <Flex
          gap="l"
          width="100%"
          maxWidth="30rem"
          flexDirection="column"
          marginHorizontal="auto"
          className={styles.managers}
        >
          <DSFormGroupTextInput
            required
            label={t("peopleReview.campaignCreation.managersPreparationEndDate.label", {
              defaultValue: "Date limite de préparation par les managers",
            })}
            assistiveText={t("peopleReview.campaignCreation.managersPreparationEndDate.assistive", {
              defaultValue:
                "Cette date détermine à quel moment seront envoyés les mails de relance aux managers n'ayant pas encore réalisé les préparations dont ils sont responsables.",
            })}
          >
            <DatePicker
              className={styles.dateInput}
              minValue={DateTime.now().minus({ days: 1 }).toISO()}
              onChange={(date: Date) => {
                setFieldValue("managersPreparationEndDate", date?.toISOString());
              }}
              value={
                values.managersPreparationEndDate
                  ? new Date(values.managersPreparationEndDate)
                  : undefined
              }
            />
          </DSFormGroupTextInput>

          <DSFormGroupTextInput
            required
            label={t("peopleReview.campaignCreation.managersHelpText.label", {
              defaultValue: "Texte d'accompagnement",
            })}
            assistiveText={t("peopleReview.campaignCreation.managersHelpText.assistive", {
              defaultValue:
                "Ce texte sera accessible depuis l'interface de suivi de la campagne de People Review des managers.",
            })}
          >
            <DSSimpleTextArea
              rows={8}
              className={styles.textarea}
              value={values.managersHelpText}
              onChange={(value) => {
                setFieldValue("managersHelpText", value);
              }}
            />
          </DSFormGroupTextInput>

          <Flex justifyContent="space-between">
            <DSButton
              emphasis="Low"
              label={t("previousStep", {
                defaultValue: "Précédent",
              })}
              onClick={() => {
                prevStep({
                  managersHelpText: values.managersHelpText,
                  managersPreparationEndDate: values.managersPreparationEndDate,
                });
              }}
            />
            <DSButton
              disabled={!(isValid && touched)}
              onClick={() => handleSubmit()}
              label={t("nextStep", {
                defaultValue: "Suivant",
              })}
            />
          </Flex>
        </Flex>
      )}
    </Formik>
  );
}
