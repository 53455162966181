import { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { DSButton, Modal } from "@skillup/ui";

import DataLayer from "utils/DataLayer";
import { plural } from "utils/locale";
import styles from "../ConnectionModes.module.scss";
import { UserModalProps } from "../types";

const ResetPasswordsModal = ({ users, onSubmit, onClose }: UserModalProps) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();

  async function handleSubmit() {
    setLoading(true);
    try {
      const data = await DataLayer.request({
        method: "POST",
        target: "AUTH",
        url: "/organization/password-reset",
        body: JSON.stringify({ uuids: users.map((user) => user.properties.uuid) }),
      });
      onSubmit(data);
    } catch (e) {
      setLoading(false);
      addToast("Une erreur est survenue", { appearance: "error" });
    }
  }

  return (
    <Modal
      className={styles.UserActionModal}
      title="Réinitialisation de mot de passe"
      onClose={onClose}
    >
      <div className={styles.modalContent}>
        {plural(users.length, "Réinitialiser le mot de passe pour cet utilisateur ?", {
          pluralText: "Réinitialiser le mot de passe pour ces utilisateurs ?",
        })}
      </div>
      <div className={styles.modalButtons}>
        <DSButton emphasis="Mid" label={"Annuler"} loading={loading} onClick={onClose} />
        <DSButton label={"Réinitialiser"} loading={loading} onClick={handleSubmit} />
      </div>
    </Modal>
  );
};

export default ResetPasswordsModal;
