import React, { useEffect } from "react";
import { useSetState } from "react-use";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import { useDropzone } from "react-dropzone";

import Loader from "components/Loader";
import InteractiveButton from "components/InteractiveButton";
import { parseOnboardingSequenceModalMark } from "components/OnboardingSequenceModal";
import Colors from "uiAssets/Colors";

import styles from "./PortalOnboardingStyles.module.scss";

export interface IOnboardingSequenceModalContentInterface {
  navContent?: Array<string>;
  slidesContent?: Array<string>;
  parsingError?: boolean;
}

// const onboardingModalFixture: string = `
// Choisissez
// Validez
// Inscrivez-vous
// *****

// ![Choisissez vos formations](https://cdn.dribbble.com/users/285475/screenshots/4481732/patreon.gif)
// ## **Choisissez** votre formation sur le catalogue
// Vous avez accès sur cette plateforme à 20.000 formations. Les formations sont évaluées avec un système d’avis pour vous permettre un choix idéal.

// -----

// ![Sélectionnez vos besoins](https://cdn.dribbble.com/users/285475/screenshots/3798124/astronaut.gif)
// ## **Validez** votre demande de formation
// Utilisez [le formulaire de demande](https://www.skillup.co/static/b098e49279ed44c99d4064c1e519a97c.pdf) de formation pour valider votre besoin. Vous recevrez une confirmation par mail.

// -----

// ![Confirmez vos réservations](https://cdn.dribbble.com/users/285475/screenshots/3618358/skate_apple_dribbble.gif)
// ## **Inscrivez-vous** en ligne
// Une fois la formation validée, allez sur la page de votre formation. Inscrivez vous en choisissant votre date session et en complétant vos informations de contact avec votre adresse AccorHotels.`;

// const mediaListFixtures: Array<string> = [
//   'https://cdn.dribbble.com/users/285475/screenshots/3618358/skate_apple_dribbble.gif',
//   'https://cdn.dribbble.com/users/285475/screenshots/3798124/astronaut.gif',
//   'https://cdn.dribbble.com/users/285475/screenshots/4481732/patreon.gif',
//   'https://www.skillup.co/static/b098e49279ed44c99d4064c1e519a97c.pdf',
// ];

interface IState {
  dataError?: boolean;
  isDeletingMedia?: string | null;
  isUploadingImage?: boolean;
  portalMediaList?: Array<string>;
  portalModalData?: string;
  initialModalData?: string;
}
let timeout: number;

const PortalOnboarding = () => {
  const [state, setState] = useSetState<IState>({});

  useEffect(() => {
    const fetch = async () => {
      try {
        const { properties } = (await DataLayer.request({
          url: "/v1/portal/onboarding",
        })) as { properties: any };
        setState({
          initialModalData: properties.portalModalData || "",
          portalModalData: properties.portalModalData || "",
          portalMediaList: properties.portalMediaList || [],
          dataError: false,
        });
      } catch (error) {
        setState({
          dataError: true,
        });
      }
    };
    fetch();
  }, [setState]);

  const save = async (): Promise<void> => {
    try {
      const body = JSON.stringify({
        portalModalData: state.portalModalData,
      });
      await DataLayer.request({
        method: "POST",
        url: "/v1/portal/onboarding",
        body,
      });
      Acta.dispatchEvent("sendAppMessage", {
        message: "Texte sauvegardé.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Échec d’enregistrement.",
        type: "error",
      });
    }
  };

  const onContentChange = (event: React.FormEvent<HTMLDivElement>): void => {
    const newValue = event.currentTarget.innerText;

    setState({
      portalModalData: newValue,
    });

    if (timeout) {
      window.clearTimeout(timeout);
    }
    timeout = window.setTimeout(save, 1000);
  };

  const onFocusURL = (event: React.FormEvent<HTMLInputElement>): void => {
    try {
      event.currentTarget.select();
      document.execCommand("Copy");
      Acta.dispatchEvent("sendAppMessage", {
        message: "Code à ajouter copié dans votre presse papier",
        type: "success",
      });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const deleteMedia = async (mediaToRemove: string): Promise<void> => {
    try {
      const body = mediaToRemove;
      const portalMediaList = (await DataLayer.request({
        method: "DELETE",
        url: "/v1/portal/onboarding/image",
        contentType: "text/plain",
        body,
      })) as string[];

      setState({
        portalMediaList,
      });
      Acta.dispatchEvent("sendAppMessage", {
        message: "Image supprimée.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Échec de la suppression.",
        type: "error",
      });
    }
  };

  const addMedia = async (files: File[]): Promise<void> => {
    setState({
      isUploadingImage: true,
    });

    try {
      const body = new FormData();
      body.append("file", files[0]);

      const portalMediaList = (await DataLayer.request({
        method: "POST",
        url: "/v1/portal/onboarding/image",
        contentType: "multipart/form-data",
        body,
      })) as string[];

      setState({
        isUploadingImage: false,
        portalMediaList,
      });
      Acta.dispatchEvent("sendAppMessage", {
        message: "Image mise à jour.",
        type: "success",
      });
    } catch (error) {
      setState({
        isUploadingImage: false,
      });
      Acta.dispatchEvent("sendAppMessage", {
        message: "Échec de l’upload.",
        type: "error",
      });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: addMedia,
    maxSize: 52428800,
    multiple: false,
  });
  const {
    dataError,
    isDeletingMedia,
    isUploadingImage,
    portalMediaList,
    portalModalData,
    initialModalData,
  } = state;

  if (dataError)
    return <div className={styles.errorContainer}>Erreur lors du chargement des données.</div>;

  if (portalModalData === undefined || portalModalData === null)
    return (
      <div className={styles.errorContainer}>
        <Loader />
      </div>
    );

  const parsedData: IOnboardingSequenceModalContentInterface =
    parseOnboardingSequenceModalMark(portalModalData);

  return (
    <div className={styles.PortalOnboarding}>
      <div>
        <header>Édition</header>
        <h2>Contenus de la modale</h2>
        <div
          className={styles.contents}
          contentEditable
          dangerouslySetInnerHTML={{ __html: initialModalData }}
          onBlur={onContentChange}
          onInput={onContentChange}
        />
        <h2>Media pour la modale</h2>
        <div className={styles.mediaList}>
          {(portalMediaList || []).map((media: string) => {
            const isImage: boolean = /.gif|.jpg|.jpeg|.png|.svg/i.test(media);
            return (
              <div key={media}>
                {isImage ? (
                  <img src={`${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${media}`} alt="" />
                ) : (
                  <a href={media} rel="noopener noreferrer" target="_blank">
                    Télécharger
                  </a>
                )}
                <div>
                  <input
                    defaultValue={`${isImage ? "!" : ""}[${
                      isImage ? "Texte alternatif pour l’image." : "texte affiché dans le lien"
                    }](${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${media})`}
                    onFocus={onFocusURL}
                  />
                  {isDeletingMedia === media ? (
                    <React.Fragment>
                      <InteractiveButton
                        label="confirmer"
                        size="small"
                        background={Colors.error}
                        onClick={() => deleteMedia(media)}
                      />
                      <InteractiveButton
                        label="annuler"
                        size="small"
                        background="#999"
                        onClick={() =>
                          setState({
                            isDeletingMedia: null,
                          })
                        }
                      />
                    </React.Fragment>
                  ) : (
                    <InteractiveButton
                      label="supprimer"
                      size="small"
                      background={Colors.error}
                      onClick={() =>
                        setState({
                          isDeletingMedia: media,
                        })
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {isUploadingImage ? (
          <div className={styles.dropZone}>
            <Loader />
          </div>
        ) : (
          <section className={styles.dropZone}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {<p>Déposez votre media ici ou cliquez ici pour choisir un media</p>}
            </div>
          </section>
        )}
      </div>
      <div>
        <header>Prévisualisation</header>
        <h2>Menu</h2>
        <nav>
          {(parsedData.navContent || []).map((navItem) => (
            <h3 key={navItem}>{navItem}</h3>
          ))}
        </nav>
        <h2>Slides</h2>
        {(parsedData.slidesContent || []).map((slide) => (
          <section key={slide} dangerouslySetInnerHTML={{ __html: slide }} />
        ))}
      </div>
    </div>
  );
};

export default PortalOnboarding;
