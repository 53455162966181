import { DSDropdownItem } from "@skillup/ui";
import { useCallback } from "react";
import Acta from "utils/Acta";
import exportCSV, { PlanType } from "./exportCSV";

export interface ExportSchedulesProps {
  readonly scheduleUuid: string;
  readonly areas: string[];
  readonly type: PlanType;
  readonly isExporting: boolean;
  readonly setIsExporting: React.Dispatch<React.SetStateAction<boolean>>;
  readonly setShowAlertExport: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExportSchedules = (props: ExportSchedulesProps) => {
  const handleExport = useCallback(
    (pType?: PlanType) => async () => {
      props.setIsExporting(true);
      props.setShowAlertExport(true);
      try {
        await exportCSV({
          type: pType,
          scheduleUuid: props.scheduleUuid,
          activeAreas: props.areas,
        });
      } catch (e) {
        const decoder = new TextDecoder();
        const { message } = JSON.parse(decoder.decode(e));

        if (message === "too-much-data") {
          Acta.dispatchEvent("sendAppMessage", {
            message:
              "L'export de ce plan est momentanément indisponible. Pour toute question rapprochez-vous de votre référent Skillup",
            type: "error",
          });
          return;
        }
        Acta.dispatchEvent("sendAppMessage", {
          message: "Une erreur est survenue lors de la génération de l'export",
          type: "error",
        });
      } finally {
        props.setIsExporting(false);
        props.setShowAlertExport(false);
      }
    },
    [props]
  );

  return (
    <>
      <DSDropdownItem
        label={props.type === "plan" ? "Exporter le plan" : "Exporter le recueil"}
        onClick={handleExport(props.type)}
      />
      <DSDropdownItem label="Exporter le recueil et le plan" onClick={handleExport()} />
    </>
  );
};

export default ExportSchedules;
