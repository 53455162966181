import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Flex } from "@skillup/ui";

import { type Child, selectHighlightUuid } from "../../../../reducer";
import { Permissions } from "../../Permissions/Permissions";
import { Divided } from "../ChildWrappers";

import { BuilderFormInput } from "../../BuilderFormInput";
import styles from "./JobChild.module.scss";

type JobChildType = Extract<Child, { kind: "job" }>;

type JobChildProps = {
  child: JobChildType;
  updateChild: (newChild: Partial<JobChildType>) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
};

export function JobChild({ child, indexes, updateChild }: JobChildProps) {
  const highlightUuid = useSelector(selectHighlightUuid);
  const multipleAssistiveTexts = useMemo(() => {
    return {
      default: (
        <>
          Le bloc « Fiche de poste » affiche la description de la fiche de poste associée au
          collaborateur.
        </>
      ),
    };
  }, []);
  const changeTitle = useCallback(
    (value: string) => {
      updateChild({ title: value });
    },
    [updateChild]
  );

  return (
    <Divided>
      <Flex column className={styles.jobChild}>
        <BuilderFormInput
          type="text"
          value={child.title}
          onChange={changeTitle}
          debounceValue={300}
          label="Libellé du bloc"
          name={`${child.uuid}-job-title`}
          placeholder="Saisissez un libellé"
          multipleAssistiveTexts={multipleAssistiveTexts}
          autoFocus={highlightUuid === child.uuid}
        />
      </Flex>
      <Flex column>
        <Permissions
          uuid={child.uuid}
          indexes={indexes}
          kind="job"
          restrictions={child.disallow}
          override={{
            comment: "Qui peut commenter la fiche de poste ?",
          }}
        />
      </Flex>
    </Divided>
  );
}
