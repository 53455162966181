/**
 * Calendar
 */
const calendarMonths: Array<{
  long: string;
  short: string;
}> = [
  {
    long: "janvier",
    short: "jan",
  },
  {
    long: "février",
    short: "fev",
  },
  {
    long: "mars",
    short: "mar",
  },
  {
    long: "avril",
    short: "avr",
  },
  {
    long: "mai",
    short: "mai",
  },
  {
    long: "juin",
    short: "jui",
  },
  {
    long: "juillet",
    short: "jul",
  },
  {
    long: "août",
    short: "aou",
  },
  {
    long: "septembre",
    short: "sep",
  },
  {
    long: "octobre",
    short: "oct",
  },
  {
    long: "novembre",
    short: "nov",
  },
  {
    long: "décembre",
    short: "dec",
  },
];

export default calendarMonths;
