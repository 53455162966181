import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { stringify } from "qs";

import { ToggleButtonGroup, useMediaQueries, generateModalContext } from "@skillup/ui";
import { useQuery } from "@skillup/hooks";

import SidePanel from "./Components/SidePanel";
import { DataTableV2 } from "components/DataTable/v2";
import { Row } from "components/DataTable/v2/components";
import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";
import UserAreaSelect from "components/UserAreaSelect";

import User from "utils/User";
import useAreas from "hooks/useAreas";
import { useSessions, type Project } from "services/sessions";

import { allColumns, cancelledColumns } from "./utils";

import styles from "./Projects.module.scss";

import { CreationModal } from "./Components/Modals/CreationModal";
import { useSummonModal } from "./Modals/Summon";
import { CancelConfirmationModalV2 } from "./Components/CancelConfirmationModalV2";
import { SummonProjectV2 } from "services/sessions/summon";

enum SlugToTab {
  "toutes" = "all",
  "cloturees" = "closed",
  "realisees" = "realized",
  "en-cours" = "pending",
  "annulees" = "canceled",
}

const useCreationModal = generateModalContext();
const useCancelModal = generateModalContext();
const useCancelModalV2 = generateModalContext();

export default () => {
  const { projectUuid, tab } = useParams<{ projectUuid?: string; tab: string }>();
  const query = useQuery<any>();
  const { activeAreas, allAreas, setActiveAreas } = useAreas();
  const cancelModal = useCancelModal();
  const creationModal = useCreationModal();
  const cancelModalV2 = useCancelModalV2();
  const history = useHistory();
  const [token, setToken] = useState<number>(Math.random());

  const {
    data: sessions = [],
    isFetching,
    refetch: refetchList,
    actions,
  } = useSessions(
    { tab: SlugToTab[tab], areas: activeAreas ?? allAreas.map((a) => a.uuid) },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );
  const summonModal = useSummonModal({
    summonSession: async (params: SummonProjectV2) => {
      try {
        const summoningResponse = await actions.summonSession(params);
        await refetchList();
        return summoningResponse.success;
      } catch (e) {
        return false;
      }
    },
  });
  const { isMobile } = useMediaQueries();

  const computeInitialState = () => {
    let initialState = {};

    const index = sessions?.findIndex(({ properties }) => properties.uuid === projectUuid);

    if (index !== -1) {
      initialState = {
        selectedRowIds: {
          [index]: true,
        },
      };
    }

    return initialState;
  };

  const initialState = computeInitialState();
  const rows: Array<Row> = sessions.map(ParseProjectIntoRow);

  const projectSelected = useMemo(
    () =>
      sessions.find((p) => {
        return p.properties.uuid === projectUuid;
      }),
    [sessions, projectUuid]
  );

  const renderSidePanel = ({ toggleRowSelected }) => {
    function close() {
      const index = sessions.findIndex(({ properties }) => properties.uuid === projectUuid);
      toggleRowSelected(index, false);
      history.push(`/responsable/mes-sessions/${tab}${stringify(query, { addQueryPrefix: true })}`);
    }

    return projectSelected ? (
      <SidePanel
        actions={{
          partialCancel: actions.partialCancel,
          partialPostpone: actions.partialPostpone,
        }}
        onClose={close}
        refetchList={refetchList}
        isFetching={isFetching}
        project={projectSelected}
        modalProps={cancelModal}
        startSummoning={(updatedProject?: Project) =>
          summonModal.startSummoning(updatedProject ?? projectSelected)
        }
        openCancelModalV2={() => cancelModalV2.openModal()}
      />
    ) : null;
  };

  const renderRow = ({ index, style, prepareRow, rows }) => {
    const row = rows[index];
    prepareRow(row);

    function open() {
      row.toggleRowSelected(row.id, !row.isSelected);
      history.push(
        `/responsable/mes-sessions/${tab}/${sessions[row.id].properties.uuid}${stringify(query, {
          addQueryPrefix: true,
        })}`
      );
    }

    function close() {
      row.toggleRowSelected(row.id, !row.isSelected);
      history.push(`/responsable/mes-sessions/${tab}${stringify(query, { addQueryPrefix: true })}`);
    }
    return <Row row={row} style={style} onOpen={open} onClose={close} />;
  };

  const params = new URLSearchParams(history.location.search);
  const createSession = params.get("mode") === "create";
  const trainingUuidd = params.get("trainingUuid");
  const trainingReference = params.get("trainingReference");

  useEffect(() => {
    if (createSession) {
      creationModal.openModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSession]);

  return (
    <DSLayout
      title={"Sessions"}
      isHiddenOnMobile={isMobile}
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              emphasis="High"
              label={"Créer une session"}
              onClick={() => creationModal.openModal()}
            />
          ),
        },
      ]}
    >
      <>
        {isMobile ? (
          <NotAvailableInMobileView />
        ) : (
          <div className={styles.Projects}>
            <div className={styles.container}>
              <ToggleButtonGroup
                value={tab}
                options={tabs.map((tab) => ({
                  label: tab.label,
                  value: tab.slug,
                }))}
                onChange={(value) => {
                  history.push(`/responsable/mes-sessions/${value}`);
                }}
              />
              <UserAreaSelect
                className={styles.UserAreaSelect}
                selectClassName={styles.select}
                value={activeAreas}
                onChange={setActiveAreas}
              />
            </div>
            <div className={styles.dataTableWrapper}>
              <DataTableV2
                columns={tab !== "annulees" ? allColumns : cancelledColumns}
                data={rows}
                initialState={initialState}
                initialData={sessions}
                sidePanel={renderSidePanel}
                row={renderRow}
              />
            </div>

            {["en-cours", "toutes"].includes(tab) && sessions.length === 0 && (
              <div>
                <div className={styles.noResults}>
                  <p>
                    Aucune session en cours. Pour créer une nouvelle session, veuillez cliquer sur
                    le bouton "Créer une session" dans le coin supérieur droit de votre écran.
                  </p>
                </div>
              </div>
            )}

            <CancelConfirmationModalV2
              key={projectSelected?.properties.uuid + cancelModalV2.isOpen}
              cancelV2={actions.cancelV2}
              isOpen={cancelModalV2.isOpen}
              closeModal={cancelModalV2.closeModal}
              isPositioned={projectSelected?.positionnedTrainees?.length > 0}
              isSummoned={projectSelected?.summonedTrainees?.length > 0}
              isSummonedBySkillup={true}
              isSummonedWithCalendarInvitation={true}
              projectUuid={projectSelected?.properties.uuid}
            />
            <CreationModal
              key={token}
              trainingReference={trainingReference}
              trainingUuid={trainingUuidd}
              onClose={() => {
                creationModal.closeModal();
                setToken(Math.random());

                if (trainingUuidd || trainingReference) {
                  history.replace(`/responsable/mes-sessions/${tab}`);
                }

                refetchList();
              }}
              isOpen={creationModal.isOpen}
            />
          </div>
        )}
        {summonModal.modal}
      </>
    </DSLayout>
  );
};

const ParseProjectIntoRow = (project: Project) => {
  return {
    areaNames: project.areas?.map((a) => a.name),
    trainerNames: project.trainers?.map((a) => a.fullName).join(", "),
    state: project.properties.state,
    trainingName: project.training?.name,
    organizationName: project.training?.organization,
    startDate: project.properties.startDate,
    sessionCity: project.properties.city,
    positionnedTraineesCount:
      project.positionnedTrainees?.length + project.summonedTrainees?.length,
    unsummonedTraineesCount: project.positionnedTrainees.length,
    stock: project.properties.stock,
    totalPrice: User.isCompanyWithTax()
      ? project.training?.totalWithTax ?? project.training?.total
      : project.training?.total,
    cancelledBy: project.cancelledLog?.fullName,
    cancelledMessage: project.cancelledLog?.message,
    cancelledAt: project.properties.cancelledAt,
    reference: project.properties.reference,
  };
};
export type Row = ReturnType<typeof ParseProjectIntoRow>;

const tabs: { label: string; slug: string }[] = [
  {
    label: "En cours",
    slug: "en-cours",
  },
  {
    label: "Réalisées",
    slug: "realisees",
  },
  {
    label: "Clôturées",
    slug: "cloturees",
  },
  {
    label: "Annulées",
    slug: "annulees",
  },
  {
    label: "Toutes",
    slug: "toutes",
  },
];
