import { useState, useEffect } from "react";
import { analytics } from "services";

interface Options {
  readonly consent?: {
    readonly necessary: boolean;
    readonly functionality: boolean;
    readonly analytics: boolean;
  };
}

const useAnalytics = ({ consent }: Options) => {
  const [isAnalyticsUp, setIsAnalyticsUp] = useState<boolean>(false);

  useEffect(() => {
    if (!isAnalyticsUp && consent?.analytics) {
      analytics.init();
      setIsAnalyticsUp(true);
    }
  }, [isAnalyticsUp, consent]);

  return {
    isAnalyticsUp,
  };
};

export default useAnalytics;
