import { Flex } from "@skillup/ui";
import DSLayout from "components/DSLayout";
import User from "utils/User";
import styles from "./TargetCategoriesAndScalesLists.module.scss";
import { TargetCategoriesDataGrid } from "./components/TargetCategoriesDataGrid";
import { TargetCategoriesLegacyDataTable } from "./components/TargetCategoriesLegacyDataTable";
import { TargetScaleDataGrid } from "./components/TargetScalesDataGrid";
import { TargetScaleDataGridLegacy } from "./components/TargetScalesDataGridLegacy";

export const TargetCategoriesAndScalesLists = () => (
  <DSLayout title={"Configuration des entretiens et des objectifs"}>
    <Flex className={styles.container} column>
      <Flex className={styles.blockTable} column>
        <h4>Catégories d'objectifs</h4>
        <TargetCategoriesDataGrid />
      </Flex>
      {User.isSkillupDeveloper() && ( // double security on this legacy table that will have to be removed or hidden before releasing the page
        <Flex className={styles.blockTable} column>
          <h4>Catégories d'objectifs (LEGACY)</h4>
          <TargetCategoriesLegacyDataTable />
        </Flex>
      )}
      <Flex className={styles.blockTable} column>
        <h4>Échelles d'évaluation pour les objectifs qualitatifs</h4>
        <TargetScaleDataGrid />
      </Flex>
      {User.isSkillupDeveloper() && ( // double security on this legacy table that will have to be removed or hidden before releasing the page
        <Flex className={styles.blockTable} column>
          <h4>Échelles d'évaluation pour les objectifs qualitatifs (LEGACY)</h4>
          <TargetScaleDataGridLegacy />
        </Flex>
      )}
    </Flex>
  </DSLayout>
);
