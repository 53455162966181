import { PayloadAction } from "@reduxjs/toolkit";

import { BuilderStructure, Child } from "../types";
import { FromChild, FromPage, FromSection } from "./addBlock/addBlock";
import { getSectionErrors, getPageErrors, getChildErrors } from "../utils";

type SectionEditAction = {
  type: "section";
  from: FromSection;
  data: {
    title: string;
  };
};

type PageEditAction = {
  type: "page";
  from: FromPage;
  data: {
    title: string;
  };
};

type ChildEditAction = {
  type: "child";
  from: FromChild;
  data: Child;
};

export type EditAction = {
  // [CSB] we do not allow to edit target scales for now
  // choicesForOrdinalTargets?: string[];
} & (ChildEditAction | PageEditAction | SectionEditAction);

export function editBlock(state: BuilderStructure, action: PayloadAction<EditAction>) {
  if (action.payload.type === "child") {
    state.sections[action.payload.from.sectionIndex].pages[action.payload.from.pageIndex].children[
      action.payload.from.childIndex
    ] = {
      ...action.payload.data,
      errors: getChildErrors(action.payload.data /*, action.payload.choicesForOrdinalTargets*/),
    };
  }

  if (action.payload.type === "page") {
    const page =
      state.sections[action.payload.from.sectionIndex].pages[action.payload.from.pageIndex];
    page.title = action.payload.data.title;
    page.errors = getPageErrors(page);
  }

  if (action.payload.type === "section") {
    const section = state.sections[action.payload.from.sectionIndex];
    section.title = action.payload.data.title;
    section.errors = getSectionErrors(section);
  }

  return state;
}
