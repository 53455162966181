import React, { useState, useMemo, useEffect } from "react";
import { xorBy } from "lodash";
import { useBoolean } from "react-use";

import { DSAlertType } from "@skillup/ui";
import {
  ISimpleSelectionUser,
  ICustomUsersList,
  ICustomUsersGroupList,
  USER_FILE_VALIDATION_TYPE,
} from "@skillup/types";

import User from "utils/User";

import InteractiveButton from "components/InteractiveButton";
import Recap from "./Recap";

import styles from "./UserSelectionModal.module.scss";
import AddTraineesContent, {
  AddTraineeError,
  User as AddTraineeUser,
} from "components/AddTraineesModal/AddTraineesContent";
import { useUsersList } from "components/AddTraineesModal/add-trainees-context";
import { buildRegisteredUsers } from "./helper";
import Acta from "utils/Acta";
import { Project } from "../Actions/getProjects";

export interface Props {
  onSubmit: (users: Array<ISimpleSelectionUser>, doNotNotify?: boolean) => void;
  training: {
    name: string;
    organization: string;
    startDate: string;
    city: string;
  };
  isPastProject: boolean;
  usersToPickFrom?: Array<ISimpleSelectionUser>;
  customUsersLists?: Array<ICustomUsersList | ICustomUsersGroupList>;
  positionedUsers?: Array<ISimpleSelectionUser>;
  summonedUsers?: Array<ISimpleSelectionUser>;
  project?: Project;
}

export default ({
  onSubmit,
  training,
  isPastProject,
  positionedUsers = [],
  summonedUsers = [],
  customUsersLists = [],
  project,
}: Props) => {
  /** Hooks */
  const {
    state: { selectedUsers },
  } = useUsersList();
  const [doNotNotify, setDoNotNotify] = useState<boolean>(
    isPastProject || User.isSkillupAdmin() ? true : false
  );
  const [tab, setTab] = useState<"form" | "recap">("form");
  const [loading, setLoading] = useBoolean(false);
  const [error, setError] = useState<AddTraineeError>();

  const registeredUsers = useMemo(
    () =>
      buildRegisteredUsers({
        positionnedTrainees: positionedUsers,
        summonedTrainees: summonedUsers,
      }),
    [positionedUsers, summonedUsers]
  );

  const selectedUsersWithoutRegistered = (
    selected: ISimpleSelectionUser[],
    registered: ISimpleSelectionUser[]
  ) => {
    return xorBy(selected, registered, "email");
  };

  const goToRecap = () => {
    if (selectedUsersWithoutRegistered(selectedUsers, registeredUsers).length < 1) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Aucun collaborateur ajouté",
        type: "error",
      });

      return;
    }
    if (
      selectedUsersWithoutRegistered(selectedUsers, registeredUsers).some(
        (user) => !!user.errors?.length
      )
    ) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Certains collaborateurs sélectionnés contiennent des erreurs",
        type: "error",
      });

      return;
    }

    setTab("recap");
  };

  useEffect(() => {
    setTimeout(() => {
      const modalContainer = document.querySelector(`.${styles.UserSelectionModal}`)?.parentNode;
      if (modalContainer instanceof HTMLElement) {
        modalContainer.style.height = "";

        const appModalDialog = document.querySelector(`.${styles.appModalDialog}`);
        if (appModalDialog instanceof HTMLDivElement) appModalDialog.style.height = "";

        if (modalContainer.scrollHeight > modalContainer.offsetHeight) {
          if (appModalDialog instanceof HTMLDivElement) appModalDialog.style.height = "100%";
          const footer = modalContainer.querySelector(`.${styles.footer}`);
          if (footer instanceof HTMLElement) {
            modalContainer.style.height = `${modalContainer.offsetHeight - footer.offsetHeight}px`;
          }
        }
      }
    });
  }, [registeredUsers, customUsersLists]);

  const errorHandler = useMemo(() => {
    return (selectedUsers: Array<AddTraineeUser>) => {
      const isMinValid = project.properties.minStock
        ? selectedUsers.length >= project.properties.minStock
        : true;
      const isMaxValid = project.properties.stock
        ? selectedUsers.length <= project.properties.stock
        : true;
      let alertText = "";
      if (!isMaxValid) {
        alertText = `Le nombre de participants (${selectedUsers.length}) est supérieur au nombre de participants maximum (${project.properties.stock})`;
      } else if (!isMinValid) {
        alertText = `Le nombre de participants (${selectedUsers.length}) est inférieur au nombre de participants minimum (${project.properties.minStock})`;
      }

      if (isMaxValid && isMinValid) {
        setError(null);
      } else {
        setError({
          alertText,
          alertType: DSAlertType.WARNING,
        });
      }
    };
  }, [project, setError]);

  return (
    <div className={styles.UserSelectionModal}>
      <div className={styles.info}>
        Inscrivez vos stagiaires à la formation <span>{training?.name}</span> par{" "}
        <span>{training?.organization}</span> du{" "}
        <span>
          {new Date(training?.startDate).toLocaleDateString("fr-FR", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>{" "}
        à <span>{training?.city}</span>.
      </div>
      {tab === "form" && (
        <AddTraineesContent
          error={error}
          errorHandler={errorHandler}
          preselectedUsers={registeredUsers}
          customUsersLists={customUsersLists}
          canUploadManager={false}
          scope={USER_FILE_VALIDATION_TYPE.INTRA_SESSION}
        />
      )}
      {tab === "recap" && (
        <Recap
          users={selectedUsersWithoutRegistered(selectedUsers, registeredUsers)}
          doNotNotify={doNotNotify}
          setDoNotNotify={setDoNotNotify}
          isPastProject={isPastProject}
        />
      )}
      <div className={styles.footer}>
        {tab === "form" && <InteractiveButton label="Suivant" name="next" onClick={goToRecap} />}
        {tab === "recap" && (
          <>
            <InteractiveButton
              label="Précédent"
              name="previous"
              onClick={() => setTab("form")}
              style={{ margin: 0, marginRight: 30 }}
            />
            <InteractiveButton
              label="Confirmer"
              name="next"
              loading={loading}
              onClick={async () => {
                setLoading(true);
                try {
                  await onSubmit(
                    selectedUsersWithoutRegistered(selectedUsers, registeredUsers),
                    doNotNotify
                  );
                } finally {
                  setLoading(false);
                }
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};
