import { BookingRoutes } from "@skillup/espace-rh-bridge";
import { createReducerContext } from "react-use";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";

type ActiveStep = "mode-selection" | "off-bounds-positioning-form" | "disclaimer" | "warnings";

export interface UnmanagedBookingForm {
  sessionStartDate?: string;
  sessionEndDate?: string;
  sessionCity?: string;
  duration: number;
  durationUnit: "hours" | "days";
  price: number;
  priceWithTax?: number;
  trainingPrice: string;
  trainingPriceWithTax: string;
  canSendConfirmation?: boolean;
  canSendEval?: boolean;
  canCreateOpsFolder?: boolean;
  rows: string[];
}

export interface UnmanagedBookingContext {
  activeStep?: ActiveStep;
  addedManually?: boolean;
  tab?: string;
  uuids?: string[];
  users?: { noEmail: boolean; fullName: string }[];
  form?: UnmanagedBookingForm;
  warnings?: Array<{ key: string; value: any[] }>;
  book: (form: UnmanagedBookingForm, context) => Promise<void>;
  update: (form: UnmanagedBookingForm, context) => Promise<void>;
  context: any;
  reload: () => void;
}

async function book(form: UnmanagedBookingForm, reload: () => void): Promise<void> {
  try {
    await buildRequest<BookingRoutes.CreateUnManagedBooking>({
      method: "POST",
      path: "/booking/unmanaged-booking",
      payload: form,
    })();
    reload();
  } catch (e) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Une erreur s'est produite lors de la création du booking hors Skillup.",
      type: "error",
    });
  } finally {
    Acta.dispatchEvent("closeModal");
  }
}

async function update(form: UnmanagedBookingForm, reload: () => void): Promise<void> {
  try {
    await buildRequest<BookingRoutes.UpdateUnManagedBooking>({
      method: "PUT",
      path: "/booking/unmanaged-booking",
      payload: form,
    })();
    reload();
  } catch (e) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Une erreur s'est produite lors de la mise à jour.",
      type: "error",
    });
  } finally {
    Acta.dispatchEvent("closeModal");
  }
}

const initialState: {
  activeStep: ActiveStep;
  book: (form: UnmanagedBookingForm, reload: () => void) => Promise<void>;
  update: (form: UnmanagedBookingForm, reload: () => void) => Promise<void>;
  context;
  reload: () => void;
} = {
  activeStep: "mode-selection",
  book,
  update,
  context: {},
  reload: () => {},
};

const reducer = (state: UnmanagedBookingContext, action: Partial<UnmanagedBookingContext>) => ({
  ...state,
  ...action,
});

const [useUnmanagedBookingContext, PositioningProvider] = createReducerContext(
  reducer,
  initialState
);

export default PositioningProvider;

export { useUnmanagedBookingContext, initialState };
