import { TemplatesRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

export async function unarchiveTemplate(templateUuid: string) {
  try {
    const response = await buildRequest<TemplatesRoutes.Unarchive>({
      method: "POST",
      path: "/templates/unarchive/{templateUuid}",
      params: { templateUuid },
      payload: undefined,
    })();
    if (response.success === false) {
      throw new Error("Unarchive unsuccessful");
    }
    return response;
  } catch (err) {
    throw new Error(err);
  }
}
