import styles from "./NotAvailableInMobileView.module.scss";
import mobileViewImg from "assets/mobile_view.svg";

const NotAvailableInMobileView = () => (
  <div className={styles.NotAvailableInMobileView}>
    <img src={mobileViewImg} alt="mobile version is not supported" />
    <span>
      Cette vue n’est pas optimisée pour une utilisation sur mobile, utilisez plutôt un ordinateur.
    </span>
  </div>
);

export default NotAvailableInMobileView;
