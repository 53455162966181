export const isUndoKeyboardEvent = (event: KeyboardEvent) => {
  if (event.key !== "z") return false;
  if ((event.metaKey || event.ctrlKey) && !event.altKey && !event.shiftKey) return true;
  return false;
};
export const isRedoKeyboardEvent = (event: KeyboardEvent) => {
  if (event.key === "y") {
    return event.ctrlKey && !event.altKey && !event.shiftKey;
  } else if (event.key === "z") {
    return event.metaKey && event.shiftKey && !event.altKey;
  }
  return false;
};
