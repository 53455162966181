import React from "react";
import { Draggable } from "react-beautiful-dnd";

import styles from "./ChildGenerator.module.scss";

export type TChildGeneratorKind =
  | "guidelines"
  | "personnalisableGuidelines"
  | "title"
  | "html"
  | "question_text"
  | "question_checkbox"
  | "question_radio"
  | "table"
  | "targets"
  | "globalTargetsCompletion"
  | "skills"
  | "trainingsHistory"
  | "interviewsHistory"
  | "trainingsCollection"
  | "userSpecificData"
  | "upload"
  | "job";

interface ChildGeneratorProps {
  child: {
    label: string;
    kind: TChildGeneratorKind;
  };
  index: number;
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const ChildGenerator = ({ child, index }: ChildGeneratorProps) => {
  return (
    <div aria-label={`Générateur ${child.label}`} id={`child-template_${child.kind}`}>
      <Draggable
        id={`child-template_${child.kind}`}
        key={child.kind}
        draggableId={`child-template_${child.kind}`}
        index={index}
      >
        {(provided, snapshot) => (
          <div
            className={styles.childGenerator}
            key={child.kind}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
          >
            {child.label}
          </div>
        )}
      </Draggable>
    </div>
  );
};

export default ChildGenerator;
