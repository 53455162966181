import { useTranslation } from "react-i18next";

import { DSButton } from "@skillup/ui";

import { Action, useAction } from "services/peopleReview";

import styles from "./ArchiveActionButton.module.scss";

function ArchiveActionButton({ action }: { action: Action }) {
  const { t } = useTranslation();
  const { mutations } = useAction(action.uuid);

  return (
    <DSButton
      buttonSize="S"
      emphasis="Mid"
      loading={mutations.unarchive.isLoading}
      className={styles.modalArchiveActionBtn}
      onClick={() => {
        mutations.unarchive.mutate();
      }}
      label={t("supervisor.people-review-management.actions.archivedModal.buttonUnarchive", {
        defaultValue: "Désarchiver",
      })}
    />
  );
}

export default ArchiveActionButton;
