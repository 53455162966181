import { useHistory } from "react-router-dom";

import { DSDropdown, DSDropdownItem } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";

import { SkillRow } from "../../utils/parseSkillIntoRow";

const ActionsCell = ({
  actions,
  row,
  t,
}: {
  row: SkillRow;
  t: TranslationType;
  actions: (row: SkillRow) => void;
}) => {
  const history = useHistory();
  const pathname = history.location.pathname;

  return (
    <DSDropdown>
      <DSDropdownItem
        onClick={() => history.push(`${pathname}/edit/${row.id}`)}
        label={t("skills.list.label.editSkill", {
          defaultValue: "Modifier la compétence",
        })}
      />
      <DSDropdownItem
        onClick={() => actions(row)}
        label={t("skills.list.label.archiveSkill", {
          defaultValue: "Archiver la compétence",
        })}
      />
      <DSDropdownItem
        onClick={() => history.push(`/responsable/analyses/competence/${row.id}`)}
        label={t("skills.list.label.goToAnalytics", {
          defaultValue: "Accéder à la page d’analyse des évaluations",
        })}
      />
    </DSDropdown>
  );
};

export default ActionsCell;
