import { buildRequest } from "utils/buildRequest";

import type { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";

export type CreateCollaboratorPayload = CollaboratorsRoutes.Create["payload"];

export default async (payload: CollaboratorsRoutes.Create["payload"]) => {
  const user = await buildRequest<CollaboratorsRoutes.Create>({
    method: "POST",
    path: "/collaborators",
    payload,
  })();

  return user;
};
