import React, { useState } from "react";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";

import InteractiveButton from "components/InteractiveButton";

import styles from "./DeleteManualRow.module.scss";

import { ScheduleRowManualRequestRoute } from "@skillup/espace-rh-bridge";

interface Props {
  reload: (rowsUuids: string[]) => void;
  rows: string[];
}

const DeleteManualRow = ({ reload, rows }: Props) => {
  const [isLoading, toggleLoading] = useState(false);
  const deleteRows = async () => {
    try {
      toggleLoading(true);

      const { deletedRows } = await buildRequest<ScheduleRowManualRequestRoute.Delete>({
        method: "DELETE",
        path: "/scheduleRow/manual",
        payload: { rows },
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: "Suppression réussie !",
        type: "success",
      });

      await reload(deletedRows);

      Acta.dispatchEvent("closeModal");
    } catch (err) {
      toggleLoading(false);
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de la suppression de ces lignes.",
        type: "error",
      });
    }
  };
  return (
    <div className={styles.deleteManuelRowModal}>
      <p>
        Supprimer ces lignes ? Elle disparaîtront du recueil / plan de formation et ne seront plus
        du tout visibles.
      </p>
      <InteractiveButton
        aria-label="Supprimer"
        label="Supprimer"
        onClick={deleteRows}
        loading={isLoading}
      />
    </div>
  );
};

export default DeleteManualRow;
