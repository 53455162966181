import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { Child, add } from "../../reducer";
import {
  DSButton,
  DSDropdown,
  DSDropdownItem,
  DSDropdownTitle,
  DSDropdownDivider,
  MaterialIcons,
} from "@skillup/ui";
import { canCreateChildBlock } from "../../utils/canCreateChildBlock";
import { AddChildAction } from "../../reducer/actions/addBlock/addChild/addChild";
import { PositionItem } from "../../hooks/useBuilderModals";
import { useBuilderContext } from "../../BuilderContext";
import { ChildIcon } from "../../ChildIcon";

type ButtonAddContentItemProps = {
  item: PositionItem;
};

type AddCommonChild = { blockType: "child" };

type AddQuestionChild = AddCommonChild & {
  kind: "question";
  type: Extract<Child, { kind: "question" }>["type"];
};
type AddTargetsChild = AddCommonChild & {
  kind: "targets";
  type: Extract<Child, { kind: "targets" }>["type"];
};

type AddOtherChild = AddCommonChild & {
  kind: Exclude<
    Child,
    { kind: "question" | "targets" | "guidelines" | "personnalisableGuidelines" }
  >["kind"];
};

type AddChildBlock = AddQuestionChild | AddTargetsChild | AddOtherChild;

type AddBlock = AddChildBlock | { blockType: "page" | "section" };

export const ButtonAddContentItem = ({ item }: ButtonAddContentItemProps) => {
  const dispatch = useDispatch();
  const { updatedItemIdRef } = useBuilderContext();

  const indexes = useMemo(() => {
    const indexes = {
      sectionIndex: undefined,
      pageIndex: undefined,
      childIndex: undefined,
    };

    if (item.type === "section" || item.type === "page" || item.type === "child") {
      indexes.sectionIndex = item.sectionIndex;
    }

    if (item.type === "page" || item.type === "child") {
      indexes.pageIndex = item.pageIndex;
    }

    if (item.type === "child") {
      indexes.childIndex = item.childIndex;
    }

    return indexes;
  }, [item]);

  const addBlock = useCallback(
    (params: AddBlock) => {
      const newChildUuid = uuid();
      updatedItemIdRef.current = { uuid: newChildUuid, fromAction: "addBlock" };
      if (params.blockType === "child") {
        let addParams: AddChildAction;
        if (params.kind === "question") {
          addParams = {
            uuid: newChildUuid,
            from: indexes,
            blockType: params.blockType,
            kind: params.kind,
            type: params.type,
          };
        } else if (params.kind === "targets") {
          addParams = {
            uuid: newChildUuid,
            from: indexes,
            blockType: params.blockType,
            kind: params.kind,
            type: params.type,
          };
        } else {
          addParams = {
            uuid: newChildUuid,
            from: indexes,
            blockType: params.blockType,
            kind: params.kind,
          };
        }
        dispatch(add(addParams));
      } else {
        dispatch(
          add({
            uuid: newChildUuid,
            from: indexes,
            blockType: params.blockType,
          })
        );
      }
    },
    [indexes, dispatch, updatedItemIdRef]
  );

  return (
    <DSDropdown
      placementShift={[0, 3]}
      // placementShift: x, y shift in pixel from the popover anchor(default value is [ 8, 8 ]),
      // I put [ 0, 3 ] here to align with the button, hide the button shadow and part of the border radii to best fit the spec without reworking the DS
      button={
        <DSButton
          label="Ajouter un bloc"
          darkMode
          buttonSize="S"
          emphasis="High"
          actionIcon={<MaterialIcons.ExpandMore />}
        />
      }
    >
      <DSDropdownTitle label="Éléments structurels" />
      <DSDropdownItem
        label="Section"
        icon={<MaterialIcons.ViewAgenda />}
        onClick={() => {
          addBlock({ blockType: "section" });
        }}
      />
      <DSDropdownItem
        label="Sous-section"
        icon={<MaterialIcons.InsertDriveFile />}
        onClick={() => {
          addBlock({ blockType: "page" });
        }}
      />
      <DSDropdownDivider />

      <DSDropdownTitle label="Texte informatif" />
      {canCreateChildBlock("title") && (
        <DSDropdownItem
          label="Titre"
          icon={<ChildIcon kind="title" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "title" });
          }}
        />
      )}
      {canCreateChildBlock("html") && (
        <DSDropdownItem
          label="Texte d'information"
          icon={<ChildIcon kind="html" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "html" });
          }}
        />
      )}
      <DSDropdownDivider />

      <DSDropdownTitle label="Questions d'entretien" />
      {canCreateChildBlock("question") && (
        <DSDropdownItem
          label="Question ouverte"
          icon={<ChildIcon kind="question" type="text" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "question", type: "text" });
          }}
        />
      )}
      {canCreateChildBlock("question") && (
        <DSDropdownItem
          label="Choix multiple (plusieurs réponses possibles)"
          icon={<ChildIcon kind="question" type="checkbox" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "question", type: "checkbox" });
          }}
        />
      )}
      {canCreateChildBlock("question") && (
        <DSDropdownItem
          label="Choix multiple (une seule réponse possible)"
          icon={<ChildIcon kind="question" type="radio" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "question", type: "radio" });
          }}
        />
      )}
      {canCreateChildBlock("table") && (
        <DSDropdownItem
          label="Tableau à remplir"
          icon={<ChildIcon kind="table" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "table" });
          }}
        />
      )}
      {canCreateChildBlock("targets") && (
        <DSDropdownItem
          label="Fixation d'objectifs"
          icon={<ChildIcon kind="targets" type="next" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "targets", type: "next" });
          }}
        />
      )}
      {canCreateChildBlock("trainingsCollection") && (
        <DSDropdownItem
          label="Demande de formation"
          icon={<ChildIcon kind="trainingsCollection" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "trainingsCollection" });
          }}
        />
      )}
      {canCreateChildBlock("upload") && (
        <DSDropdownItem
          label="Partage de fichier"
          icon={<ChildIcon kind="upload" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "upload" });
          }}
        />
      )}
      <DSDropdownDivider />

      <DSDropdownTitle label="Contenus dynamiques" />
      {canCreateChildBlock("targets") && (
        <DSDropdownItem
          label="Revue des objectifs"
          icon={<ChildIcon kind="targets" type="current" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "targets", type: "current" });
          }}
        />
      )}
      {canCreateChildBlock("globalTargetsCompletion") && (
        <DSDropdownItem
          label="Taux d'atteinte global"
          icon={<ChildIcon kind="globalTargetsCompletion" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "globalTargetsCompletion" });
          }}
        />
      )}
      {canCreateChildBlock("job") && (
        <DSDropdownItem
          label="Fiche de poste"
          icon={<ChildIcon kind="job" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "job" });
          }}
        />
      )}
      {canCreateChildBlock("skills") && (
        <DSDropdownItem
          label="Compétences à évaluer"
          icon={<ChildIcon kind="skills" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "skills" });
          }}
        />
      )}
      {canCreateChildBlock("trainingsHistory") && (
        <DSDropdownItem
          label="Historique de formation"
          icon={<ChildIcon kind="trainingsHistory" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "trainingsHistory" });
          }}
        />
      )}
      {canCreateChildBlock("interviewsHistory") && (
        <DSDropdownItem
          label="Historique d'entretiens"
          icon={<ChildIcon kind="interviewsHistory" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "interviewsHistory" });
          }}
        />
      )}
      {canCreateChildBlock("userSpecificData") && (
        <DSDropdownItem
          label="Données spécifiques à l'utilisateur"
          icon={<ChildIcon kind="userSpecificData" />}
          onClick={() => {
            addBlock({ blockType: "child", kind: "userSpecificData" });
          }}
        />
      )}
    </DSDropdown>
  );
};
