import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Flex, Text } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";
import { Select, DSModal, DSAvatarGroup, Label as DSLabel } from "@skillup/ui";

import { AssignCollabPayload } from "types/skills";

import { useJobsContext } from "../../JobsContext";
import { StyledArchiveModal } from "../../pages/List/Jobs.styled";
import { JobRow, SelectedJobRowData } from "../../pages/List/utils/parseJobIntoRow";

interface SelectionOption {
  label: string;
  value: string;
}

interface ArchiveConfirmModalProps {
  open: boolean;
  close: () => void;
  datatableRows: Array<JobRow>;
  selectedJob?: SelectedJobRowData;
  reassignAndArchive: (assignPayload: AssignCollabPayload) => void;
}

export const ArchiveJobsModal = ({
  close,
  datatableRows,
  open,
  reassignAndArchive,
  selectedJob,
}: ArchiveConfirmModalProps) => {
  const { employeeList } = useJobsContext();
  const { t } = useTranslation();
  const [selectedJobUuid, setSelectedJobUuid] = useState<string>();

  const options: SelectionOption[] = datatableRows
    .map((job) => ({
      isArchived: job.isArchived,
      label: job.name,
      value: job.uuid,
    }))
    .filter((job) => job.value !== selectedJob?.uuid && !job.isArchived);

  const generateAssignCollabPayload = (): AssignCollabPayload => {
    const employees = employeeList?.filter((employee) => {
      return employee.jobs[0]?.uuid === selectedJob?.uuid;
    });

    const targetJob = datatableRows?.find((job) => {
      return job.uuid === selectedJobUuid;
    });

    const employeesArray = employees.map((employee) => ({
      companyUuid: employee.companyUuid,
      employeeUuid: employee.uuid,
      jobUuid: selectedJobUuid,
      version: employee.version + 1,
    }));

    const payload = {
      command: "assignEmployeeToJob",
      employees: employeesArray,
      targetJob: {
        uuid: targetJob.uuid,
        name: targetJob.name,
      },
    };

    return payload;
  };

  return (
    <StyledArchiveModal isOpen={open} portalTarget={document.body}>
      <DSModal.Header onClose={() => close()}>
        <DSModal.Header.Title
          title={t("jobs.archivedModal.buttonArchive", {
            defaultValue: "Archiver la fiche de poste «{{jobTitle}}»",
            jobTitle: selectedJob?.name,
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex gap="xs" height="100%" paddingVertical="s" flexDirection="column">
          {selectedJob?.employees.length > 1 && (
            <Text fontSize="1rem" espaceFont="body1Regular" color="plainText-onLight-default">
              {t("jobs.archivedModal.numberOfCollabLinked.plural", {
                defaultValue:
                  "Cette fiche de poste est actuellement associée à {{employeesCount}} collaborateurs.",
                employeesCount: selectedJob?.employees.length,
              })}
            </Text>
          )}
          {selectedJob?.employees.length === 1 && (
            <Text fontSize="1rem" espaceFont="body1Regular" color="plainText-onLight-default">
              {t("jobs.archivedModal.numberOfCollabLinked.singular", {
                defaultValue: "Cette fiche de poste est actuellement associée à 1 collaborateur.",
              })}
            </Text>
          )}
          {selectedJob && (
            <DSAvatarGroup
              size="S"
              maxItems={5}
              totalItems={selectedJob?.employees.length}
              users={selectedJob?.employees.map((employee) => ({
                uuid: employee.uuid,
                fullName: `${employee.firstName} ${employee.lastName}`,
                initials: getInitials({
                  firstName: employee.firstName,
                  lastName: employee.lastName,
                }),
              }))}
            />
          )}
          {selectedJob?.employees.length > 1 && (
            <>
              <Text fontSize="1rem" espaceFont="body1Regular" color="plainText-onLight-default">
                {t("jobs.archivedModal.warnlinkToCollab.plural", {
                  defaultValue:
                    "L’archivage de cette fiche de poste entraînera la rupture du lien d’association avec ces collaborateurs.",
                  nbCollab: selectedJob?.employees.length,
                })}
              </Text>
              <Text fontSize="1rem" espaceFont="body1Regular" color="plainText-onLight-default">
                {t("jobs.archivedModal.linkNewJob.plural", {
                  defaultValue:
                    "Vous devez associer une nouvelle fiche de poste aux {{employeesCount}} collaborateurs concernés avant de pouvoir l’archiver :",
                  employeesCount: selectedJob?.employees.length,
                })}
              </Text>
            </>
          )}

          {selectedJob?.employees.length === 1 && (
            <>
              <Text fontSize="1rem" espaceFont="body1Regular" color="plainText-onLight-default">
                {t("jobs.archivedModal.warnlinkToCollab.singular", {
                  defaultValue:
                    "L’archivage de cette fiche de poste entraînera la rupture du lien d’association avec ce collaborateur.",
                  nbCollab: selectedJob?.employees.length,
                })}
              </Text>
              <Text fontSize="1rem" espaceFont="body1Regular" color="plainText-onLight-default">
                {t("jobs.archivedModal.linkNewJob.singular", {
                  defaultValue:
                    "Vous devez associer une nouvelle fiche de poste au collaborateur concerné avant de pouvoir l’archiver :",
                })}
              </Text>
            </>
          )}
          <Flex flexDirection="column">
            <DSLabel
              required
              label={t("jobs.archivedModal.selectLabel", {
                defaultValue: "Nouvelle fiche de poste",
              })}
            />
            <Select
              options={options}
              value={selectedJobUuid}
              onChange={(val: string) => {
                setSelectedJobUuid(val);
              }}
              placeholder={t("jobs.archivedModal.selectNewJob", {
                defaultValue: "Selectionnez une fiche de poste",
              })}
            />
          </Flex>
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={() => close()}
          label={t("jobs.archivedModal.buttonCancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          onClick={() => reassignAndArchive(generateAssignCollabPayload())}
          label={t("jobs.archivedModal.buttonConfirm", {
            defaultValue: "Confirmer l'archivage",
          })}
        />
      </DSModal.Footer>
    </StyledArchiveModal>
  );
};
