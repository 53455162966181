import { useMemo } from "react";

import { useQuery, useMutation } from "@tanstack/react-query";

import { getReviewsData, type GetFieldsDataParams } from "./getReviewsData";
import { updateFieldsDataForEmployee } from "./../field/updateFieldsDataForEmployee";
import {
  assignActionsToEmployee,
  type AssignPayloadParams,
} from "./../field/assignActionToEmployee";

export type { CampaignEmployeesData } from "./getReviewsData";

type Options = {
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
};

const defaultOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

interface UseCampaignReviewsParams {
  options?: Options;
  campaignID: string;
  query: GetFieldsDataParams;
}

export function useCampaignReviews({
  campaignID,
  options = defaultOptions,
  query,
}: UseCampaignReviewsParams) {
  const reviewsQuery = useQuery(
    [
      "people-reviews",
      campaignID,
      { filters: query.filters, pagination: query.pagination, sorting: query.sorting },
    ],
    () =>
      getReviewsData({
        campaignID,
        filters: query.filters,
        pagination: query.pagination,
        queryOptions: query.queryOptions,
        sorting: query.sorting,
      }),
    {
      enabled: !!campaignID,
      ...defaultOptions,
      ...options,
      keepPreviousData: true, // to avoid flashing when paginating
    }
  );

  const fieldsData = useMemo(
    () =>
      reviewsQuery.data ?? {
        data: [],
        total: 0,
      },
    [reviewsQuery.data]
  );

  const updateFieldMutation = useMutation(
    async (...params: Parameters<typeof updateFieldsDataForEmployee>) => {
      try {
        await updateFieldsDataForEmployee(...params);
      } catch (err) {}
    }
  );

  const updateActionsMutation = useMutation(async (payload: AssignPayloadParams) => {
    try {
      await assignActionsToEmployee(payload);
    } catch (err) {}
  });

  return {
    data: fieldsData.data,
    isError: reviewsQuery.isError,
    isLoading: reviewsQuery.isLoading,
    mutations: {
      assignActions: updateActionsMutation.mutate,
      updateField: updateFieldMutation.mutate,
    },
    total: fieldsData.total,
  };
}
