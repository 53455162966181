import React, { useState, useRef, useEffect, useCallback } from "react";
import { useToggle } from "react-use";
import type { TPrimaryState } from "@skillup/types";

import { ScheduleRowOperationsRoute } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import { buildRequest } from "utils/buildRequest";

import InteractiveButton from "components/InteractiveButton";
import TextInput from "components/TextInput";
import DropDown from "components/DropDown";
import Colors from "uiAssets/Colors";
import { calendar as calendarIcon, mapPin as mapPinIcon } from "uiAssets/StrokeIcons";

import styles from "./PostponeOpsModal.module.scss";

export interface IPrimarycall {
  sessionsCount?: number;
  primaryState: TPrimaryState;
  uuid: string;
}

export interface Props {
  item: IPrimarycall;
}

const CancelOrPostponeModal = ({ item }: Props) => {
  const [sessions, setSessions] = useState({ sessionsByPlace: [], places: [] });
  const [currentSession, setCurrentSession] = useState(null);
  const [currentSessionPlace, setCurrentSessionPlace] = useState(null);
  const [comment, setComment] = useState<string>();
  const [loading, toggleLoading] = useToggle(false);
  const sessionDropdown = useRef(null);

  const loadSessionData = useCallback(async () => {
    const data = await DataLayer.request({ url: `/v1/scheduleRow/sessions/${item.uuid}` });
    // @ts-ignore
    setSessions(data);
    // @ts-ignore
    setCurrentSessionPlace(data.places[0].value);
  }, [item.uuid]);

  useEffect(() => {
    loadSessionData();
  }, [item, loadSessionData]);

  const handlePostponeSubmit = useCallback(async (): Promise<void> => {
    toggleLoading(true);

    try {
      await buildRequest<ScheduleRowOperationsRoute.Postpone>({
        method: "POST",
        path: "/scheduleRow/operations/postpone",
        payload: [{ uuid: item.uuid, sessionUuid: currentSession, comment }],
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: "Ligne reportée avec succès.",
        type: "success",
      });

      Acta.dispatchEvent("closeModal");

      window.location.reload();
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "La demande a échoué.",
        type: "error",
      });
    } finally {
      toggleLoading(false);
    }
  }, [toggleLoading, comment, item, currentSession]);

  return (
    <div className={styles.PostponeModal}>
      <p>Le report ne sera pas notifié à l'organisme.</p>
      <form>
        <DropDown
          defaultValue={currentSessionPlace}
          id="locationDropdown"
          label="Lieu"
          onSelect={(place) => {
            setCurrentSession(null);
            setCurrentSessionPlace(place);
          }}
          options={sessions.places}
          placeholder="Sélectionnez un lieu"
          strokeIcon={mapPinIcon}
        />
        <DropDown
          defaultValue={currentSession}
          label="Date"
          id="dateDropdown"
          onSelect={setCurrentSession}
          options={sessions.sessionsByPlace[currentSessionPlace] || []}
          placeholder="Sélectionnez une date"
          strokeIcon={calendarIcon}
          ref={sessionDropdown}
        />
        <TextInput
          label="Commentaire"
          autoComplete="off"
          autoFocus={true}
          alwaysOpen
          placeholder="Laissez un commentaire dans votre activité"
          onChange={(_e, value) => setComment(value)}
        />
      </form>
      <InteractiveButton
        label="Confirmer le report"
        onClick={handlePostponeSubmit}
        background={Colors.gradientBlue}
        size="small"
        loading={loading}
      />
    </div>
  );
};

export default CancelOrPostponeModal;
