import { useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { uniqBy } from "lodash";

import { DSModal, DSAlert, DSAlertDisplay, DSAlertType } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { useTemplate, type Template } from "services/interviews";

import { ChoicesSettings, useChoices } from "./ChoicesSettings";
import { TargetsCategoriesSettings, useCategories } from "./TargetsCategoriesSettings";
import styles from "./TargetSettingsModal.module.scss";
interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeModal: () => void;
}

export const TargetSettingsTemplateModal = ({ template, isOpen, closeModal }: IProps) => {
  const templateActions = useTemplate(template.uuid);
  const { addToast } = useToasts();
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedCategories, addCategory, handleChangeCategories, removeCategory } =
    useCategories(template);

  const usedCategories = useMemo(() => {
    const items: string[] = [
      ...new Set(
        template.sections.reduce((acc, section) => {
          section.pages.forEach((page) => {
            page.children.forEach((child) => {
              if (child?.kind === "targets") {
                if (child.creationCategories) acc.push(...child.creationCategories);
                if (child.displayCategories) acc.push(...child.displayCategories);
              }
            });
          }, []);
          return acc;
        }, [])
      ),
    ];
    return items;
  }, [template.sections]);

  const { choices, addChoice, removeChoice, handleChangeChoices, handleReorderChoices } =
    useChoices(template);

  const send = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await templateActions.editParams({
        targetsCategories: selectedCategories.reduce(
          (acc, category) => (category.label !== "" ? [...acc, category.label] : acc),
          []
        ),
        targetCategories: selectedCategories,
        choicesForOrdinalTargets: choices.reduce(
          (acc, choice) => (choice.title !== "" ? [...acc, choice.title] : acc),
          []
        ),
      });
      if (response.success) {
        addToast("Trame mise à jour", { appearance: "success" });
        closeModal();
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error("Echec lors de la modification de la trame");
      }
    } catch (err) {
      addToast("Echec lors de la modification de la trame", {
        appearance: "error",
      });
    }
  };

  const duplicateInCategories = useMemo(
    () => uniqBy(selectedCategories, "label").length !== selectedCategories.length,
    [selectedCategories]
  );

  const emptyValueInCategories = useMemo(
    () =>
      selectedCategories.filter((selectedCategory) => selectedCategory.label === "").length !== 0,
    [selectedCategories]
  );

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title={"Paramètres liés aux objectifs"} />
      </DSModal.Header>
      <DSModal.Content>
        <Flex flexDirection="column" gap="l" className={styles.TargetSettingsModal}>
          <DSAlert display={DSAlertDisplay.INLINE} type={DSAlertType.WARNING}>
            <>
              Tous les blocs de type « objectifs » de la trame <br />
              actuelle hériteront des paramètres définis ici.
            </>
          </DSAlert>
          <ChoicesSettings
            choices={choices}
            addChoice={addChoice}
            removeChoice={removeChoice}
            handleChangeChoices={handleChangeChoices}
            handleReorderChoices={handleReorderChoices}
          />
          <TargetsCategoriesSettings
            selectedCategories={selectedCategories}
            addCategory={addCategory}
            removeCategory={removeCategory}
            handleChangeCategories={handleChangeCategories}
            usedCategories={usedCategories}
          />
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={closeModal} label="Annuler" />
        <DSModal.Footer.PrimaryButton
          label={"Enregistrer"}
          onClick={send}
          disabled={
            (template.choicesForOrdinalTargets?.toString() ===
              choices.map(({ title }) => title).toString() &&
              template.targetsCategories?.sort().toString() ===
                selectedCategories
                  .map(({ label }) => label)
                  .sort()
                  .toString()) ||
            duplicateInCategories ||
            emptyValueInCategories
          }
          loading={loading}
          tooltip={
            duplicateInCategories
              ? "Vous ne pouvez sélectionner une catégorie qu'une seule fois."
              : emptyValueInCategories
              ? "Vous ne pouvez pas sauvegarder un champ vide."
              : undefined
          }
        />
      </DSModal.Footer>
    </DSModal>
  );
};
