import { useMemo, useCallback } from "react";

import Fuse from "fuse.js";
import { isEmpty } from "lodash";

import { useQuery, useMutation } from "@tanstack/react-query";

import { TemplateRoutes } from "@skillup/people-review-bridge";

import { Field, useCoreHRFields } from "services/coreHR";

import { getTemplates } from "./getTemplates";
import { createTemplate, type CreateTemplatePayload } from "./createTemplate";

export function useTemplates(options: Options = defaultOptions) {
  const query = useQuery(["people-review-templates"], getTemplates, {
    ...defaultOptions,
    ...options,
  });

  const { fieldsMap } = useCoreHRFields({
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const templatesWithFields = useMemo(() => {
    if (!query.data) {
      return [];
    }

    return query.data.map((template) => ({
      ...template,
      coreHRFields: template.coreHRFields.map((fieldUuid) => fieldsMap[fieldUuid]).filter(Boolean),
    }));
  }, [query.data, fieldsMap]);

  const search = useCallback(
    async (search: string) => {
      if (isEmpty(search)) return templatesWithFields;
      const fuse = new Fuse(templatesWithFields, {
        includeScore: true,
        keys: ["title"],
        shouldSort: true,
        threshold: 0.333,
      });
      return fuse.search(search).map((result) => result.item);
    },
    [templatesWithFields]
  );

  const getByUuid = useCallback(
    (templateUuid: string) => {
      return templatesWithFields.find((template) => template.uuid === templateUuid);
    },
    [templatesWithFields]
  );

  const createTemplateMutation = useMutation(
    async (payload: CreateTemplatePayload) => {
      const response = await createTemplate(payload);
      query.refetch();

      return response;
    },
    {
      mutationKey: ["create-action"],
    }
  );

  const templates = templatesWithFields?.filter((template) => !template.archivedAt);
  const archivedTemplates = templatesWithFields?.filter((template) => template.archivedAt);

  return {
    archivedTemplates,
    isError: query.isError,
    isLoading: query.isLoading,
    mutations: {
      create: createTemplateMutation,
      getByUuid,
      search,
    },
    templates,
  };
}

type Options = {
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
};

const defaultOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export type Template = Omit<
  TemplateRoutes.GetTemplatesRoute["response"][number],
  "coreHRFields"
> & {
  coreHRFields: Field[];
};
