import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function deleteCampaign(campaignID: string) {
  const response = await buildRequest<CampaignsRoutes.Delete>({
    method: "PUT",
    params: {
      campaignID,
    },
    path: "/campaign/delete/{campaignID}",
    target: "PEOPLE_REVIEW",
  })();

  return response;
}
