import { Collaborator } from "../../api/getUsers";

export interface IForm {
  [fieldBinding: string]:
    | {
        value?: any;
        error?: boolean;
        focused?: boolean;
      }
    | undefined;
}

export type CollaboratorForm =
  | keyof Collaborator
  | "hasBookingManager"
  | "bookingManager"
  | `observerLvl${number}`;

interface FieldElementBasic {
  name: string;
  field: CollaboratorForm;
  required?: boolean;
  display?: (form: IForm) => boolean;
}

export interface FieldTextElement extends FieldElementBasic {
  type: "TextInput";
  placeholder?: string;
}

export interface FieldNumberElement extends FieldElementBasic {
  type: "NumberInput";
  placeholder?: string;
}

export interface FieldDropdown extends FieldElementBasic {
  type: "TextDropdown";
  placeholder?: string;
}

export interface FieldBoolean extends FieldElementBasic {
  type: "Radio";
  canUncheck: boolean;
}

export interface FieldUserSearch extends FieldElementBasic {
  type: "UserSearch";
  placeholder?: string;
}

export interface FieldDate extends FieldElementBasic {
  type: "Date";
}

export interface TitleElement {
  type: "Title";
  name: string;
  field?: CollaboratorForm;
  required?: boolean;
  display?: (form: IForm) => boolean;
}

export type FieldElement =
  | FieldDate
  | FieldUserSearch
  | FieldTextElement
  | FieldDropdown
  | FieldBoolean
  | FieldNumberElement
  | TitleElement;

export const matchingFields = (customFields?: Array<FieldElement>) => {
  const fields: Array<FieldElement> = customFields ? [...customFields] : [...defaultValue];

  const withObserverFields = (numberOfObserverFields: number) => {
    Array.from({ length: numberOfObserverFields }, (_, i) => i + 1).forEach((level) => {
      fields.push({
        name: `Manager entretiens N + ${level}`,
        field: `observerLvl${level}`,
        type: "UserSearch",
        placeholder: "Ex: Claire Moreaux",
      });
    });
    return { generate };
  };

  const generate = () => {
    return fields;
  };

  return { generate, withObserverFields };
};

const defaultValue: Array<FieldElement> = [
  {
    name: "Prénom",
    field: "firstName",
    type: "TextInput",
    required: true,
  },
  {
    name: "Nom",
    field: "lastName",
    type: "TextInput",
    required: true,
  },
  {
    name: "E-mail",
    field: "email",
    type: "TextInput",
    required: true,
  },
  {
    name: "Role",
    field: "role",
    type: "TextInput",
  },
  {
    name: "Matricule",
    field: "registrationNumber",
    type: "TextInput",
  },
  {
    name: "Date d'embauche",
    field: "joinDate",
    type: "Date",
  },
  {
    name: "Périmètre",
    field: "areas",
    type: "TextDropdown",
    required: true,
  },
  {
    name: "Entreprise associée",
    field: "subCompanies",
    type: "TextDropdown",
  },
  {
    name: "Manager formations N + 1",
    field: "manager0Lvl0",
    type: "UserSearch",
    placeholder: "Ex: Claire Moreaux",
  },
  {
    name: "Manager formations N + 2",
    field: "manager0Lvl1",
    type: "UserSearch",
    placeholder: "Ex: Claire Moreaux",
  },
  {
    name: "A un gestionnaire de réservation individuel",
    field: "hasBookingManager",
    type: "Radio",
    canUncheck: false,
  },
  {
    name: "Gestionnaire de réservation",
    field: "bookingManager",
    type: "UserSearch",
    display: (form) => {
      return form?.["hasBookingManager"]?.value ?? false;
    },
  },
];
