import React, { useState } from "react";
import { useMachine } from "@xstate/react";
import { useToasts } from "react-toast-notifications";

import { DSModal, DSTextInput, Label } from "@skillup/ui";

import styles from "./CancelConfirmationModalV2.module.scss";
import { confirmMachine } from "stateMachine/confirm";
import { CancelParams } from "services/sessions/cancelV2";

export interface Props {
  isOpen: boolean;
  cancelV2: (params: CancelParams) => Promise<void>;
  projectUuid: string;
  closeModal: VoidFunction;
  isSummoned: boolean;
  isPositioned: boolean;
  isSummonedBySkillup: boolean;
  isSummonedWithCalendarInvitation: boolean;
}

export function CancelConfirmationModalV2({
  projectUuid,
  isOpen,
  cancelV2,
  closeModal,
  isPositioned,
  isSummoned,
  isSummonedBySkillup,
  isSummonedWithCalendarInvitation,
}: Props) {
  const [comment, setComment] = useState<string>("");

  const [current, send] = useMachine(confirmMachine, {
    actions: {
      onBack: () => closeModal(),
      onClose: () => closeModal(),
    },
    services: {
      onConfirm: () => {
        return cancelV2({ projectUuid, comment }).then(closeModal);
      },
    },
  });

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={() => send("ABORT")}>
        <DSModal.Header.Title title="Annuler la session" />
      </DSModal.Header>

      <DSModal.Content>
        <div className={styles.ModalContent} role="article">
          <CancelationText
            isPositioned={isPositioned}
            isSummoned={isSummoned}
            isSummonedBySkillup={isSummonedBySkillup}
            isSummonedWithCalendarInvitation={isSummonedWithCalendarInvitation}
            closeModal={closeModal}
          />

          <p>Êtes-vous sûr de vouloir définitivement annuler cette session ?</p>
        </div>
        <div className={styles.Comment}>
          <Label label="Commentaire (facultatif)" />
          <DSTextInput
            name="comment"
            placeholder="Votre commentaire ici..."
            autoFocus
            value={comment}
            onChange={(value) => setComment(value)}
          />
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          disabled={current.context.loading}
          onClick={() => send("BACK")}
          label="Annuler"
        />
        <DSModal.Footer.PrimaryButton
          loading={current.context.loading}
          onClick={() => send("CONFIRM")}
          label="Confirmer l'annulation"
        />
      </DSModal.Footer>
    </DSModal>
  );
}

enum CancelationTextCases {
  notSummonedNotPositioned = "notSummonedNotPositioned",
  notSummonedPositioned = "notSummonedPositioned",
  summonedWithoutSkillup = "summonedWithoutSkillup",
  isSummonedBySkillupWithoutCalendarInvitation = "isSummonedBySkillupWithoutCalendarInvitation",
  isSummonedBySkillupWithCalendarInvitation = "isSummonedBySkillupWithCalendarInvitation",
}

function CancelationText(props: {
  isPositioned: boolean;
  isSummoned: boolean;
  isSummonedBySkillup: boolean;
  isSummonedWithCalendarInvitation: boolean;
  closeModal: VoidFunction;
}) {
  const cancelationTextCase = assignCancelationTextCase(props);
  const { addToast } = useToasts();

  switch (cancelationTextCase) {
    case CancelationTextCases.notSummonedPositioned:
      return (
        <>
          Attention ! Cette session comporte des stagiaires inscrits.
          <br />
          <br />
          Suite à l'annulation :
          <br />
          <ul>
            <li>Ils ne seront pas notifiés de l'annulation de la session.</li>
            <li>Leurs demandes passeront au statut "À inscrire" dans le plan de formation.</li>
          </ul>
          <br />
        </>
      );
    case CancelationTextCases.summonedWithoutSkillup:
      return (
        <>
          Attention ! Des stagiaires ont été convoqués à cette session.
          <br />
          <br />
          Suite à l'annulation :
          <br />
          <ul>
            <li> Ils ne seront pas notifiés de l'annulation de la session. </li>
            <li>Leurs demandes passeront au statut "À inscrire" dans le plan de formation.</li>
            <br />
          </ul>
        </>
      );
    case CancelationTextCases.isSummonedBySkillupWithoutCalendarInvitation:
      return (
        <>
          Attention ! Des stagiaires ont été convoqués à cette session.
          <br />
          <br />
          Suite à l'annulation :
          <br />
          <ul>
            <li>Ils recevront un email les informant de l’annulation de la session.</li>
            <li>Leurs demandes passeront au statut "À inscrire" dans le plan de formation.</li>
            <br />
          </ul>
        </>
      );
    case CancelationTextCases.isSummonedBySkillupWithCalendarInvitation:
      return (
        <>
          Attention ! Des stagiaires ont été convoqués à cette session.
          <br />
          <br />
          Suite à l'annulation :
          <br />
          <ul>
            <li>Ils recevront un email les informant de l’annulation de la session.</li>
            <li>Les invitations agenda envoyées lors de la convocation seront annulées.</li>
            <li>Leurs demandes passeront au statut "À inscrire" dans le plan de formation.</li>
            <br />
          </ul>
        </>
      );
    case CancelationTextCases.notSummonedNotPositioned:
      return <></>;
    default:
      props.closeModal();
      addToast("Erreur lors de l'annulation.", {
        appearance: "error",
        autoDismiss: true,
      });
      return null;
  }
}

function assignCancelationTextCase({
  isPositioned,
  isSummoned,
  isSummonedBySkillup,
  isSummonedWithCalendarInvitation,
}) {
  if (!isSummoned && isPositioned) {
    return CancelationTextCases.notSummonedPositioned;
  }
  if (isSummoned && !isSummonedBySkillup) {
    return CancelationTextCases.summonedWithoutSkillup;
  }
  if (isSummoned && isSummonedBySkillup && !isSummonedWithCalendarInvitation) {
    return CancelationTextCases.isSummonedBySkillupWithoutCalendarInvitation;
  }
  if (isSummoned && isSummonedBySkillup && isSummonedWithCalendarInvitation) {
    return CancelationTextCases.isSummonedBySkillupWithCalendarInvitation;
  }
  return CancelationTextCases.notSummonedNotPositioned;
}
