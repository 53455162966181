import once from "lodash/once";
import isNumber from "lodash/isNumber";

if (typeof window !== "undefined") {
  // @ts-ignore
  window.GoogleAnalyticsObject = "ga";
  // @ts-ignore
  window.ga =
    // @ts-ignore
    window.ga ||
    function ga(...args) {
      // @ts-ignore
      (window.ga.q = window.ga.q || []).push(args);
    };
  // @ts-ignore
  window.ga.l = 1 * new Date();

  // @ts-ignore
  window.ga("create", process.env.REACT_APP_GOOGLE_ANALYTICS_ID, "auto");
  // @ts-ignore
  window.ga("set", "dimension2", "(Unauthentified)");
  // @ts-ignore
  window.ga("set", "dimension1", "(Company unknown)");
  // @ts-ignore
  window.ga("set", "dimension4", "(No uuid)");
}

const init = once(
  () =>
    new Promise((resolve) => {
      // @ts-ignore
      window.ga((tracker) => resolve(tracker.get("clientId")));

      const a = document.createElement("script");
      const m = document.getElementsByTagName("script")[0];
      // @ts-ignore
      a.async = 1;
      // @ts-ignore
      a.defer = 1;
      a.src = "https://www.google-analytics.com/analytics.js";
      m.parentNode.insertBefore(a, m);
    })
);

const sendPageview = (pathname) => {
  // @ts-ignore
  window.ga("send", "pageview", pathname);
};

const sendEvent = (category, action, label, value) => {
  window.ga(
    "send",
    "event",
    category,
    action,
    label,
    // @ts-ignore
    isNumber(value) ? value : undefined
  );
};

const setDimension = (dimensionName, dimensionValue) => {
  // @ts-ignore
  window.ga("set", dimensionName, dimensionValue);
};

const setUser = (userId) => {
  // @ts-ignore
  window.ga("set", "userId", userId);
};

export default {
  init,
  sendPageview,
  sendEvent,
  setDimension,
  setUser,
};
