export const loadUsetifulScript = function (w, d, s, t): any {
  const a: any = d.getElementsByTagName("head")[0];
  const r: any = d.createElement("script");

  r.async = 1;
  r.src = s;
  r.setAttribute("id", "usetifulScript");
  r.dataset.token = t;
  a.appendChild(r);
};
