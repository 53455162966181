import { useRef } from "react";
import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { DSModal, Loader } from "@skillup/ui";
import { EditableCompanyField } from "@skillup/training-bridge";

import useTranslation from "hooks/useTranslation";

import styles from "../Form.module.scss";

import FieldFormList from "./Form";

import { fieldFormValidation } from "../utils";

export type Props = {
  field?: EditableCompanyField;
  loading?: boolean;
  onSubmit: (fieldData: EditableCompanyField) => void;
  onClose: () => void;
};

const initialFormState: EditableCompanyField = {
  binding: "",
  type: "text",
  label: "",
  isTrainingField: false,
  isHidden: false,
  massEditAvailable: true,
  restrictions: [],
  options: [],
};

const FieldModalForm = ({ onSubmit, onClose, field, loading }: Props) => {
  const { t } = useTranslation();
  const portalRef = useRef<HTMLDivElement>(null);

  const isEdit = !!field;

  return (
    <Formik<EditableCompanyField>
      validateOnMount={false}
      enableReinitialize
      validationSchema={toFormikValidationSchema(fieldFormValidation(t, true))}
      onSubmit={onSubmit}
      initialValues={field ?? initialFormState}
    >
      {({ isValid, handleSubmit, isValidating }) => (
        <>
          <div ref={portalRef} className={styles.modalContainer} />
          {portalRef.current && (
            <DSModal isOpen={true} className={styles.modal} portalTarget={portalRef.current}>
              <DSModal.Header onClose={onClose}>
                <DSModal.Header.Title
                  title={t("fields.form.cancel.title", {
                    defaultValue: "Créer un champ personnalisé",
                  })}
                />
              </DSModal.Header>
              <DSModal.Content className={styles.modalContent}>
                <FieldFormList isEdit={false} />
              </DSModal.Content>
              <DSModal.Footer>
                <DSModal.Footer.CancelButton
                  disabled={loading}
                  onClick={onClose}
                  label={t("fields.form.cancel", {
                    defaultValue: "Annuler",
                  })}
                />

                <DSModal.Footer.PrimaryButton
                  disabled={loading || !isValid || isValidating}
                  tooltip={
                    !isValid
                      ? t("fields.form.errors", {
                          defaultValue:
                            "Tous les champs sont obligatoires (signalés par une astérisque rouge) doivent être renseignés",
                        })
                      : undefined
                  }
                  onClick={() => {
                    handleSubmit();
                  }}
                  icon={loading ? <Loader /> : undefined}
                  label={
                    isEdit
                      ? t("fields.form.update", {
                          defaultValue: "Enregistrer les modifications",
                        })
                      : t("fields.form.create", {
                          defaultValue: "Créer un champ personnalisé",
                        })
                  }
                />
              </DSModal.Footer>
            </DSModal>
          )}
        </>
      )}
    </Formik>
  );
};

export default FieldModalForm;
