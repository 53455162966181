import { useState, useEffect } from "react";
import { getCardData } from "../adapters/insights";
import { DashboardCardConfig, QuestionData } from "../types";
import { ListUtils } from "@skillup/shared-utils";

export const useDashboardCardData = (
  card: DashboardCardConfig,
  filters: ListUtils.FilterValues<any>
) => {
  const [data, setData] = useState<QuestionData>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const filterString = Object.keys(filters ?? {})
          .filter((key) => filters[key]?.value && card.filters.includes(key))
          .map((key) => filters[key].value.map((value) => `filter[${key}]=${value}`))
          .flat()
          .join("&");

        const data = await getCardData(card.question_meta.id, filterString);

        setData(data);
      } finally {
        setLoading(false);
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card, filters]);

  return { data, loading };
};
