import React, {
  useMemo,
  useState,
  type Dispatch,
  useCallback,
  type SetStateAction,
  type PropsWithChildren,
} from "react";

import { Popper } from "@mui/material";

import { DSButton, DSTooltip, DSTextInput, MaterialIcons, HorizontalDivider } from "@skillup/ui";

import { Text, Flex, Title } from "../../styled";

import styles from "./ColumnsVisibilityModal.module.scss";

type ColumnsVisibilityPopperProps = ColumnsVisibilityPopperContentProps;

export function ColumnsVisibilityPopper({
  columnsConfig,
  columnsVisibilityModel,
  setColumnsVisibilityModel,
}: ColumnsVisibilityPopperProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopperClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    },
    [anchorEl]
  );

  return (
    <>
      <DSTooltip label="Gérer les colonnes du tableau">
        <DSButton iconOnly icon={<MaterialIcons.Tune />} onClick={handlePopperClick} />
      </DSTooltip>
      <ColumnsVisibilityPopperWrapper anchor={anchorEl} isOpen={Boolean(anchorEl)}>
        <ColumnsVisibilityPopperContent
          columnsConfig={columnsConfig}
          columnsVisibilityModel={columnsVisibilityModel}
          setColumnsVisibilityModel={setColumnsVisibilityModel}
        />
      </ColumnsVisibilityPopperWrapper>
    </>
  );
}

type ColumnsVisibilityPopperWrapperProps = PropsWithChildren<{
  isOpen: boolean;
  anchor: null | HTMLElement;
}>;

export function ColumnsVisibilityPopperWrapper({
  anchor,
  children,
  isOpen,
}: ColumnsVisibilityPopperWrapperProps) {
  return (
    <Popper
      open={isOpen}
      disablePortal
      anchorEl={anchor}
      style={{ zIndex: 999 }}
      popperOptions={{
        placement: "bottom-end",
      }}
      modifiers={[
        {
          enabled: true,
          name: "preventOverflow",
          options: {
            altAxis: true,
            altBoundary: true,
            padding: 8,
            rootBoundary: "viewport",
            tether: true,
          },
        },
      ]}
    >
      {children}
    </Popper>
  );
}

type ColumnsVisibilityPopperContentProps = {
  columnsVisibilityModel: { [key: string]: boolean };
  columnsConfig: Array<{
    key: string;
    headerName: string;
  }>;
  setColumnsVisibilityModel: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
};

export function ColumnsVisibilityPopperContent({
  columnsConfig,
  columnsVisibilityModel,
  setColumnsVisibilityModel,
}: ColumnsVisibilityPopperContentProps) {
  const [query, setQuery] = useState<string>();

  const filteredColumns = useMemo(() => {
    if (!query || query === "") return columnsConfig;
    return columnsConfig.filter((column) =>
      column.headerName.toLowerCase().includes(query?.toLowerCase() ?? "")
    );
  }, [query, columnsConfig]);

  const visibleColumns = filteredColumns.filter((column) => columnsVisibilityModel[column.key]);
  const invisibleColumns = filteredColumns.filter((column) => !columnsVisibilityModel[column.key]);

  return (
    <Flex
      alignItems="start"
      paddingVertical="xs"
      flexDirection="column"
      paddingHorizontal="xs"
      className={styles.columnsVisibilityModal}
    >
      <Title h5>Gestion des colonnes</Title>
      <DSTextInput
        value={query}
        name="property-search"
        style={{ marginTop: "1rem" }}
        aria-label="chercher une propriété"
        placeholder="Chercher une propriété" // TODO: traduce label & aria-labels
        iconRight={<MaterialIcons.Search />}
        onChange={setQuery}
      />

      <Flex flexDirection="column" className={styles.content}>
        <Text paddingVertical="s" color="plainText-onLight-lighter">
          Colonnes affichées
        </Text>
        <Flex flexDirection="column" className={styles.columnsContainer}>
          {visibleColumns.map((column) => (
            <Flex
              key={column.key}
              flexWrap="nowrap"
              alignItems="center"
              paddingHorizontal="xs"
              className={styles.columnRow}
              justifyContent="space-between"
            >
              <Text
                hasEllipsis
                whiteSpace="nowrap"
                espaceFont="captionRegular"
                color="plainText-onLight-default"
              >
                {column.headerName}
              </Text>
              <MaterialIcons.Visibility
                color="action"
                fontSize="small"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setColumnsVisibilityModel((prevModel) => ({
                    ...prevModel,
                    [column.key]: false,
                  }));
                }}
              />
            </Flex>
          ))}
        </Flex>

        <HorizontalDivider top="xs" bottom="xs" />

        <Text paddingBottom="s" color="plainText-onLight-lighter">
          Colonnes masquées
        </Text>
        <Flex flexDirection="column" className={styles.columnsContainer}>
          {invisibleColumns.map((column) => (
            <Flex
              flexGrow={1}
              key={column.key}
              alignItems="center"
              paddingHorizontal="xs"
              className={styles.columnRow}
              justifyContent="space-between"
            >
              <Text espaceFont="captionRegular" color="plainText-onLight-default">
                {column.headerName}
              </Text>
              <MaterialIcons.VisibilityOff
                fontSize="small"
                color="disabled"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setColumnsVisibilityModel((prevModel) => ({
                    ...prevModel,
                    [column.key]: true,
                  }));
                }}
              />
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
