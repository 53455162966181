import {
  useCallback,
  useState,
  useRef,
  useEffect,
  createRef,
  useMemo,
  MouseEvent,
  RefObject,
} from "react";
import { useFetch } from "hooks";

import { Link, useHistory } from "react-router-dom";
import { chevronRight as chevronRightIcon } from "uiAssets/StrokeIcons";

import { useBaseUrl } from "@skillup/hooks";
import type { ITrainingTag, ITrainingDataCall } from "@skillup/types";

import Acta from "utils/Acta";

import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import Loader from "components/Loader";
import InteractiveButton from "components/InteractiveButton";
import Icon from "components/Icon";
import TrainingHeader from "components/TrainingHeader";
import Footer from "components/Footer";
import DSLayout from "components/DSLayout";

import createUserAccessChecker from "hooks/userAccessChecker";
import useSettings from "hooks/useSettings";

import styles from "./CatalogRootStyles.module.scss";

interface CatalogByTag extends ITrainingDataCall {
  readonly tags?: ITrainingTag[];
}

const CatalogRoot = () => {
  const { settings, userAccessModules, loading: loadingSettings } = useSettings();
  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);

  const history = useHistory();
  const { baseUrl } = useBaseUrl();

  const container = useRef<HTMLDivElement>(null);
  const sections = useRef<Record<string, RefObject<HTMLDivElement>>>({});
  const [tags, setTags] = useState<ITrainingTag[]>();

  const { data, loading, error } = useFetch<CatalogByTag>({ url: "/v1/data/catalog-by-tag" });

  const computeTags = useCallback((tags: ITrainingTag[]) => {
    const computedTags = tags.map((tag) => ({ ...tag }));

    const result = computedTags.reduce((acc, tag: ITrainingTag) => {
      tag.weight =
        tag.weight === undefined || tag.weight === null ? Number.MAX_SAFE_INTEGER : tag.weight;

      if (!tag.parents.length) {
        acc.push(tag);
      } else {
        tag.parents.forEach((parentName) => {
          const parent = computedTags.find((t) => t.name === parentName);
          if (parent) {
            // @ts-ignore
            parent.children = [...(parent.children || []), tag];
          }
        });
      }

      return acc;
    }, []);

    result.sort((tagA: ITrainingTag, tagB: ITrainingTag) => tagA.weight - tagB.weight);

    return result;
  }, []);

  const goToSection = (event: MouseEvent, uuid: string) => {
    event.preventDefault();
    container.current.scrollTo(0, sections.current[uuid].current.offsetTop - 170);
  };

  useEffect(() => {
    if (!loading && data) {
      const tags = computeTags(data.tags);

      tags.forEach(({ uuid }) => {
        sections.current[uuid] = createRef<HTMLDivElement>();
      });

      return setTags(tags);
    }

    if (error) {
      return Acta.dispatchEvent("sendAppMessage", {
        message: `Error lors du chargement de la page : ${error}`,
        type: "error",
      });
    }
  }, [data, loading, error, computeTags]);

  const canUseIntra = UserAccessChecker.Trainings.toTrainingsIntrasOrganization();
  const canUseCatalog = UserAccessChecker.Trainings.toTrainingsIntrasSkillup();

  const layouts = useMemo(() => {
    const layouts = [];

    if (canUseIntra) {
      layouts.push({
        tab: {
          id: "gestion-intras",
          label: "Catalogue entreprise",
          url: "/responsable/programmes/gestion-intras",
        },
        primaryButton: (
          <DSNewHeaderButton
            label="Créer un intra"
            onClick={() => history.push("/responsable/programmes/gestion-intras/creation")}
          />
        ),
      });
    }

    if (canUseCatalog) {
      layouts.push({
        tab: {
          id: "catalogue",
          label: "Catalogue Skillup",
          url: "/responsable/programmes/catalogue",
        },
        primaryButton: null,
      });
    }

    return layouts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canUseIntra, canUseCatalog]);

  if (loadingSettings) {
    return (
      <DSLayout title="Programmes">
        <Loader style={{ height: "calc(100vh - 56px)" }} />
      </DSLayout>
    );
  }

  return (
    <DSLayout
      title="Programmes"
      className={styles.CatalogRoot}
      layouts={layouts}
      activeLayout="catalogue"
    >
      <div className={styles.catalogLayout}>
        <TrainingHeader />

        <div className={styles.scrollableWrapper} ref={container}>
          {!tags ? (
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
          ) : (
            <>
              <div>
                {tags.map(({ uuid, name, trainingCount }) => (
                  <button key={`header-${uuid}`} onClick={(event) => goToSection(event, uuid)}>
                    <span>{name}</span>
                    <span className={styles.trainingCount}>{trainingCount} formations</span>
                    <span>
                      Découvrir les catégories
                      <Icon strokeIcon={chevronRightIcon} width={8} />{" "}
                    </span>
                  </button>
                ))}
              </div>

              {tags.map(({ uuid: uuid_1, seoSlug, name, trainingCount, children }) => (
                <section key={uuid_1} ref={sections.current[uuid_1]}>
                  <div>
                    <h2>{name}</h2>
                    <p>{trainingCount} formations</p>
                    <InteractiveButton
                      label="Voir la catégorie"
                      onClick={() =>
                        history.push(
                          `${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/${seoSlug}`
                        )
                      }
                      size="small"
                      style={{ display: "inline-block" }}
                    />
                  </div>

                  <div>
                    {(children || []).map(
                      // @ts-ignore
                      ({ uuid: uuid_2, seoSlug, name, children }) => (
                        <div key={`${uuid_1}-${uuid_2}`}>
                          <Link
                            to={`${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/${seoSlug}`}
                          >
                            {name}
                          </Link>
                          <div>
                            {(children || []).map(({ uuid: uuid_3, seoSlug, name }) => (
                              <Link
                                to={`${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/${
                                  seoSlug || ""
                                }`}
                                key={`${uuid_1}-${uuid_2}-${uuid_3}`}
                              >
                                {name}
                              </Link>
                            ))}
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </section>
              ))}
            </>
          )}
          <Footer />
        </div>
      </div>
    </DSLayout>
  );
};

export default CatalogRoot;
