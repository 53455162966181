import { useHistory } from "react-router";
import React, { Suspense, useState, useEffect } from "react";

import { useRecoilValue } from "recoil";
import { FiEdit3 } from "react-icons/fi";
import { IoMdMore } from "react-icons/io";

import { SearchInput } from "@skillup/ui";
import { DropDownMenu } from "@skillup/ui";
import { lightFormat } from "@skillup/shared-utils";

import type { JobsRoutes } from "types/api";
import LoaderShimmer from "components/LoaderShimmer";

import { jobsAtom, jobFieldsAtom } from "../state/jobs";
import { showJobDetailsModal } from "./JobsDetailsModal";
import List, { ListProps, TextFilter } from "../components/List";

import styles from "./Jobs.module.scss";

type GetJobsRoute = JobsRoutes["Jobs.GetList"];

const Jobs = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <div className={styles.JobsDescription}>
      <SearchInput
        value={searchQuery}
        className={styles.searchInput}
        placeholder="Rechercher une fiche de poste"
        onChange={handleSearch}
      />
      <div className={styles.tableContainer}>
        <Suspense
          fallback={
            <div className={styles.loading}>
              <LoaderShimmer className={styles.loadingLogo} />
              <p>Chargement en cours…</p>
            </div>
          }
        >
          <FilteredList searchQuery={searchQuery} />
        </Suspense>
      </div>
    </div>
  );
};

const fixedColumns: ListProps["columns"] = [
  {
    accessor: "title",
    Cell: ({ cell }) => {
      const job = cell.row.original?.job;
      return (
        <div className={styles.previewLink} onClick={() => showJobDetailsModal(job)}>
          {job.label}
        </div>
      );
    },
    Filter: TextFilter,
    filterType: "text",
    label: "Intitulé",
    width: 500,
  },
  {
    accessor: "updatedAt",
    Cell: ({ cell }) => {
      const date = cell.row.original.job.updatedAt;
      return <>{lightFormat(new Date(date), "dd/MM/yyyy")}</>;
    },
    Filter: TextFilter,
    filterType: "includes",
    label: "Date de dernière modification",
    sortType: "basic",
    width: 235,
  },
];

const FilteredList = ({ searchQuery }: { searchQuery: string }) => {
  const history = useHistory();
  const jobs = useRecoilValue(jobsAtom);
  const jobFields = useRecoilValue(jobFieldsAtom);

  const [displayedJobs, setDisplayedJobs] = useState<GetJobsRoute["response"]["jobs"]>(jobs);

  useEffect(() => {
    if (searchQuery?.length) {
      setDisplayedJobs(
        jobs?.filter((job) => job.label?.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    } else {
      setDisplayedJobs(jobs ?? []);
    }
  }, [searchQuery, setDisplayedJobs, jobs]);

  const redirectToJobEditionPage = (jobUuid: string) => () => {
    history.push({
      pathname: `/responsable/competences/fiches-de-poste/${jobUuid}`,
    });
  };

  const jobFieldsColumns = jobFields.map((jobField) => ({
    accessor: jobField.uuid,
    Cell: ({ cell }) => {
      const job = cell.row.original.job;

      return <>{job.fields[jobField.uuid]}</>;
    },
    Filter: TextFilter,
    label: jobField.label,
    width: 200,
  }));

  const liste = (
    <List
      initialSorting={{ id: "title" }}
      rows={displayedJobs.map((job) => ({
        job: job,
        linkedEmployees: job.employees.length,
        title: (job.label ?? "").toLowerCase(),
        updatedAt: lightFormat(new Date(job.updatedAt), "dd/MM/yyyy"),
        ...jobFields.reduce((acc, jobField) => {
          const fieldValue = job.fields[jobField.uuid];
          const fieldString = typeof fieldValue === "string" ? fieldValue.toLowerCase() : "";
          return {
            ...acc,
            [jobField.uuid]: fieldString,
          };
        }, {}),
        id: job.uuid,
      }))}
      columns={[
        ...fixedColumns,
        ...jobFieldsColumns,
        {
          accessor: "linkedEmployees",
          Filter: TextFilter,
          filterType: "equals",
          label: "Collaborateurs associés",
          sortType: "number",
          width: 190,
        },
        {
          accessor: "actions",
          Cell: ({ cell, row, rows }) => {
            const job = cell.row.original.job;
            return (
              <DropDownMenu
                togglerClassName={styles.actionsToggler}
                items={[
                  {
                    icon: <FiEdit3 size={16} />,
                    label: "Modifier la fiche de poste",
                    onClick: redirectToJobEditionPage(job.uuid),
                  },
                ]}
              >
                <IoMdMore size={16} />
              </DropDownMenu>
            );
          },
          disableFilters: true,
          disableSortBy: true,
          label: "Actions",
          showOverflow: true,
          width: 100,
        },
      ]}
    />
  );
  return liste;
};

export default Jobs;
