export function parseReferenceFromName(name: string | undefined): string {
  if (!name) {
    return "";
  }

  const newReference = getInitialsOfFirstThreeWordsOfName(name).concat(
    `-${generateRandomNumberBetween0And999AsStringWith3Digits()}`
  );

  return newReference;
}

export function generateRandomNumberBetween0And999AsStringWith3Digits() {
  return `${Math.floor(Math.random() * 1000)}`.padStart(3, "0");
}

export function getInitialsOfFirstThreeWordsOfName(name: string) {
  const nameWithoutAccents = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const nameWithoutForbiddenCharacters = nameWithoutAccents
    .split("")
    .filter((char) => !forbiddenCharacters.includes(char))
    .join("");

  const words = nameWithoutForbiddenCharacters.split(" ");
  const filteredWords = words.filter(
    (word) => !["du", "des", "de", "la", "le", "les", "l'", "l’"].includes(word)
  );

  const initials = filteredWords
    .slice(0, 3)
    .filter(Boolean) // in case there are less than 3 words
    .map((word) => word[0].toUpperCase())
    .join("");

  return initials;
}

const forbiddenCharacters = [
  "[",
  "]",
  "(",
  ")",
  "/",
  "\\",
  "’",
  "'",
  "’",
  "‘",
  "{",
  "}",
  "«",
  "»",
  "–",
  "-",
  "—",
  "–",
  "…",
  ":",
  ";",
  ",",
  ".",
  "!",
  "?",
  "°",
  "²",
];
