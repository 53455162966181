import { useState, useEffect, useMemo, useRef, ChangeEvent } from "react";
import { isNil, orderBy, isEmpty } from "lodash";
import Fuse from "fuse.js";

import styles from "./ScheduleFieldsV2.module.scss";
import { useTypedFetch } from "hooks";
import AddFieldModal, { IField } from "./AddFieldModal";
import InteractiveButton from "components/InteractiveButton";
import type { FieldRoutesType } from "types/api";
import Loader from "components/Loader";

const initialState = {
  fields: [],
  schedules: [],
};

const ScheduleFields = () => {
  const {
    data = initialState,
    refetch,
    loading: fieldsLoading,
  } = useTypedFetch<FieldRoutesType.Get>({
    method: "GET",
    path: "/fields",
    query: { showDeletedFields: true, showHiddenFields: true },
  });

  const { fields } = data;
  const [selectedField, setSelectedField] = useState<IField>();
  const [newFieldUuid, setNewFieldUuid] = useState<string | null>(null);
  const [mode, setMode] = useState<"edition" | "creation">("creation");
  const [fieldsSearchEngine, setFieldsSearchEngine] = useState(null);
  const [filteredFields, setFilteredFields] = useState<IField[]>([]);
  const searchInputRef = useRef(null);

  // In case of refresh we keep the selection
  useEffect(() => {
    if (!isNil(selectedField)) {
      const updatedField = fields.find((f) => f.uuid === selectedField.uuid);
      setSelectedField(updatedField);
    }
  }, [fields, selectedField]);

  // This is use for post-creation auto selection.
  useEffect(() => {
    const newField = fields.find((f) => f.uuid === newFieldUuid);
    if (newField) {
      setSelectedField(newField);
      setMode("edition");
    }
  }, [newFieldUuid, fields]);

  useMemo(() => {
    setFilteredFields(fields);
    setFieldsSearchEngine(new Fuse(fields, { keys: ["label"], threshold: 0.333 }));
  }, [fields]);

  const standardFields = useMemo(
    () => filteredFields?.filter((f) => f.isStandard === true && f.deletedAt === undefined),
    [filteredFields]
  );
  const customFields = useMemo(
    () => filteredFields?.filter((f) => f.isStandard !== true && f.deletedAt === undefined),
    [filteredFields]
  );
  const deletedFields = useMemo(
    () => filteredFields?.filter((f) => f.deletedAt !== undefined),
    [filteredFields]
  );

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedField(null);

    const value = event.target.value.trim() ?? "";

    if (isEmpty(value)) {
      setFilteredFields(fields);
    } else {
      const results = fieldsSearchEngine.search(value).map((r) => r.item);
      setFilteredFields(results);
    }
  };

  const printFields = (fields: IField[]) => {
    return (
      <ul>
        {orderBy(fields, [(field) => field.label.toLowerCase()]).map((field) => (
          <li
            className={selectedField?.uuid === field.uuid ? styles.selected : null}
            onClick={() => {
              setSelectedField(field);
              setMode("edition");
            }}
            key={field.uuid}
          >
            {field.label}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={styles.fields}>
      <div className={styles.content}>
        <div className={styles.list}>
          {fieldsLoading ? (
            <Loader />
          ) : (
            <>
              <div className={styles.title}>
                <h3>Champs</h3>
                <InteractiveButton
                  label="+"
                  onClick={() => {
                    setMode("creation");
                    setSelectedField(null);
                  }}
                  size="small"
                />
              </div>
              <div className={styles.content}>
                <input
                  type="text"
                  placeholder="Rechercher..."
                  onChange={onSearch}
                  ref={searchInputRef}
                />
                <h4>Champs standards</h4>
                {printFields(standardFields)}
                <h4>Champs personnalisés</h4>
                {printFields(customFields)}
                <h4>Champs supprimés</h4>
                {printFields(deletedFields)}
              </div>
            </>
          )}
        </div>
        <div className={styles.settings}>
          {mode === "edition" && (
            <AddFieldModal
              currentFields={fields}
              mode={mode}
              field={selectedField}
              onClose={refetch}
            />
          )}
          {mode === "creation" && (
            <AddFieldModal
              currentFields={fields}
              mode={mode}
              onClose={async (newFieldUuid) => {
                setNewFieldUuid(newFieldUuid);
                await refetch();
                setNewFieldUuid(null);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduleFields;
