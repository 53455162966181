import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { TextDropdown } from "@skillup/ui";

import { Child } from "../types";
import { selectedPageDataState, templateState } from "../store";

import styles from "./TransferToAnotherPage.module.scss";

interface ChildTransferProps {
  child: Child;
}

const TransferToAnotherPage = ({ child }: ChildTransferProps) => {
  const [template, setTemplate] = useRecoilState(templateState);
  const selectedPageData = useRecoilValue(selectedPageDataState);

  const transfer = (targetPageUuid: string) => {
    const newTemplate = {
      ...template,
      sections: template.sections.map((section) => ({
        ...section,
        pages: section.pages.map((page) => {
          if (page.uuid === selectedPageData.uuid) {
            return {
              ...page,
              children: page.children.filter((c) => c.uuid !== child.uuid),
            };
          }

          if (page.uuid === targetPageUuid) {
            // I put it on top to see it directly when changing page
            return {
              ...page,
              children: [child, ...page.children],
            };
          }

          return page;
        }),
      })),
    };

    setTemplate(newTemplate);
  };

  const pages = template.sections
    .map((s) => s.pages.map((p) => ({ ...p, section: s.title })))
    .flat()
    .filter((p) => p.uuid !== selectedPageData.uuid)
    .map((p) => ({ label: `${p.section} ${p.title}`, value: p.uuid }));

  return (
    <div className={styles.childTransfer} aria-label="zone de transfert de block">
      <TextDropdown
        options={pages}
        small
        placeholder="Transférer vers"
        onSelect={(selection) => {
          transfer(selection.value as string);
        }}
      />
    </div>
  );
};

export default TransferToAnotherPage;
