import { ActionRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type ArchiveActionParams = ActionRoutes.ArchiveActionRoute["params"];

export async function archiveAction({ actionID }: ArchiveActionParams) {
  await buildRequest<ActionRoutes.ArchiveActionRoute>({
    method: "POST",
    params: {
      actionID,
    },
    path: `/actions/{actionID}/archive`,
    target: "PEOPLE_REVIEW",
  })();
}
