import { Flex, Text } from "@skillup/design-system";
import { UserTarget } from "@skillup/espace-rh-bridge";
import { DateTime } from "@skillup/shared-utils";
import { DSModal, Label } from "@skillup/ui";
import { isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { Activities } from "./Activities";
import { UserTargetDetailEvaluation } from "./UserTargetDetailEvaluation/UserTargetDetailEvaluation";
import styles from "./UserTargetDetailModal.module.scss";

interface IProps {
  target: UserTarget;
  isOpen: boolean;
  closeModal: () => void;
}

export const UserTargetDetailModal = ({ target, isOpen, closeModal }: IProps) => {
  const displayPeriod = useMemo(() => {
    return target.period && target.period.startDate && target.period.endDate;
  }, [target]);

  const goal = useMemo(() => {
    if (target.scale !== "numeric" || isNil(target.goal)) return undefined;
    return target.goal / 100;
  }, [target]);

  const formatDate = useCallback((d: string) => {
    const date = DateTime.fromISO(d);
    return date.toLocaleString();
  }, []);

  return (
    <DSModal isOpen={isOpen} className={styles.UserTargetDetailModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title={target.name} />
      </DSModal.Header>
      <DSModal.Content className={styles.modal__content}>
        <Flex flexDirection="column" gap="l">
          <Flex flexDirection="row" gap="l">
            <Flex flexDirection="column" gap="s" flex={2}>
              {!target.evaluationCampaign && target.creationCampaign && (
                <TargetDetailBlock
                  title="Campagne de fixation de l'objectif"
                  value={target.creationCampaign.titleForHR}
                />
              )}
              {target.evaluationCampaign && (
                <TargetDetailBlock
                  title="Campagne d'évaluation de l'objectif"
                  value={target.evaluationCampaign.titleForHR}
                />
              )}
              {target.description && (
                <TargetDetailBlock title="Description" value={target.description} />
              )}
            </Flex>
            <Flex flexDirection="column" gap="xs" flex={1}>
              {displayPeriod && (
                <TargetDetailBlock
                  title="Période"
                  value={
                    formatDate(target.period.startDate) + " - " + formatDate(target.period.endDate)
                  }
                />
              )}
              {!isNil(goal) && target.scale === "numeric" && (
                <TargetDetailBlock title="Niveau attendu" value={`${goal} ${target.unit ?? "%"}`} />
              )}
              {!isNil(target.weight) && (
                <TargetDetailBlock title="Pondération" value={`${target.weight.toString()}%`} />
              )}
              {target.category && <TargetDetailBlock title="Catégorie" value={target.category} />}
            </Flex>
          </Flex>
          <Flex flexDirection="column" gap="xs" alignItems="stretch">
            {target.evaluation && (
              <Flex flexDirection="row" alignItems="center" flexGrow={1} margin="auto">
                <UserTargetDetailEvaluation target={target} />
              </Flex>
            )}
            {target.activities?.length > 0 && <Activities activities={target.activities} />}
          </Flex>
        </Flex>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </DSModal>
  );
};

const TargetDetailBlock = ({ title, value }: { title: string; value: string }) => {
  return (
    <Flex flexDirection="column">
      <Label label={title} />
      <Text color="plainText-onLight-default" espaceFont="body1Regular" paddingVertical="xs">
        {value}
      </Text>
    </Flex>
  );
};
