import { FormatDate } from "@skillup/shared-utils";
import { DSAvatar, Label } from "@skillup/ui";
import { getUserInitials } from "utils/User";

import { Collaborator } from "../../api";
import useSettings from "hooks/useSettings";
import createUserAccessChecker from "hooks/userAccessChecker";

import styles from "./UserHeader.module.scss";
interface Props {
  readonly user: Collaborator;
}

export default ({ user }: Props) => {
  const { settings, userAccessModules } = useSettings();
  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);

  const observerLvl1 = user.hierarchy?.observers?.find(
    (observer: Collaborator["hierarchy"]["observers"][0]) => observer.level === 1
  );
  return (
    <div className={styles.UserHeader}>
      <div className={styles.container}>
        <DSAvatar
          size="L"
          type="Initials"
          imageUrl={undefined}
          initials={user && getUserInitials(user)}
          randomColorSeedString={user?.uuid}
          darkMode
        />
        <div className={styles.groups}>
          <div className={styles.group}>
            <Label label="Rôle" className={styles.label} />
            <div>{user?.role}</div>
          </div>
          {user?.areas.length > 0 && (
            <div className={styles.group}>
              <Label label="Périmètre" className={styles.label} />
              <div>{user?.areas[0].name}</div>
            </div>
          )}
          <div className={styles.group}>
            <Label label="Date d’entrée dans l’entreprise" className={styles.label} />
            <div>
              {user?.joinDate
                ? FormatDate.toStringLiteralFormatFromUtc(user.joinDate, "dd/MM/yyyy")
                : null}
            </div>
          </div>
          <div className={styles.group}>
            <Label label="Manager formation N+1" className={styles.label} />
            <div>{user?.manager0Lvl0}</div>
          </div>
          {!UserAccessChecker.Interviews.toInterviews() && user?.manager0Lvl1 && (
            <div className={styles.group}>
              <Label label="Manager formation N+2" className={styles.label} />
              <div>{user.manager0Lvl1}</div>
            </div>
          )}
          {UserAccessChecker.Interviews.toInterviews() && observerLvl1 && (
            <div className={styles.group}>
              <Label label="Manager entretien N+1" className={styles.label} />
              <div>{observerLvl1.fullName}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
