import React from "react";

import styles from "./Tag.module.scss";

interface TagProps {
  color: ColorSettings;
  label: string;
}

const Tag = ({ color, label }: TagProps) => (
  <div className={styles.tag} style={{}}>
    <div
      className={styles.tagText}
      style={{ backgroundColor: color.background, color: color.color }}
    >
      {label}
    </div>
  </div>
);

export default Tag;

enum TagBackgroundColors {
  RED = "#FF6760",
  YELLOW = "#FFB229",
  GRAY = "rgba(25, 47, 75, 0.1)",
  GREEN = "#0EAF85",
}

enum TagTextColor {
  RED = "#fff",
  GREEN = "#fff",
  YELLOW = "#fff",
  GRAY = "#535D6D",
}

export type ColorSettings = {
  background: TagBackgroundColors;
  color: TagTextColor;
};

export const TagColors: Record<string, ColorSettings> = {
  RED: {
    background: TagBackgroundColors.RED,
    color: TagTextColor.RED,
  },
  GREEN: {
    background: TagBackgroundColors.GREEN,
    color: TagTextColor.GREEN,
  },
  YELLOW: {
    background: TagBackgroundColors.YELLOW,
    color: TagTextColor.YELLOW,
  },
  GRAY: {
    background: TagBackgroundColors.GRAY,
    color: TagTextColor.GRAY,
  },
};
