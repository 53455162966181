import { Flex, DSRating } from "@skillup/ui";
import { HotReviewDetails, ReviewQuestion } from "@skillup/training-bridge";

import useTranslation from "hooks/useTranslation";

import styles from "../HotReviewSidePanel.module.scss";
import { FormatDate, ParseDate } from "@skillup/shared-utils";

export const ReviewCategories = {
  NO_CATEGORY: "no-category",
  ORGANIZATION: "organization",
  ANIMATION: "animation",
  CONTENT: "content",
} as const;

export type ReviewCategoryKey = keyof typeof ReviewCategories;

export type ParsedCategories = {
  [key in ReviewCategoryKey]: {
    translationKey?: string;
    title?: string;
    globalRating?: number;
    questions: {
      label: string;
      min: number;
      max: number;
      score?: number;
    }[];
  };
};

export type sessionDetails = {
  completedAt: string;
  startDate: string;
  reference: string;
  organization: string;
};

interface Props {
  reviewDetails: HotReviewDetails;
}
const formatDateToLiteral = (date: number, locale: string) => {
  if (!date) {
    return null;
  }

  const dateInDateTime = ParseDate.FromMiliTimestamp(date);
  const dateFormat = FormatDate.GetLiteralMonthFormat(locale);

  return FormatDate.ToStringLiteralFormat(dateInDateTime, dateFormat, locale);
};

const HotReviewPanelContentReviewed = ({ reviewDetails }: Props) => {
  const { t, i18n } = useTranslation();

  const completedAt = formatDateToLiteral(reviewDetails.session.completedAt, i18n.language);
  const startDate = formatDateToLiteral(reviewDetails.session.startDate, i18n.language);

  const questionWithoutCategory = reviewDetails.questions.filter((question) => {
    return (
      (!question.category || question.category === "none") &&
      (question.type === "multiple" || question.type === "radio")
    );
  });

  return (
    <>
      {questionWithoutCategory.map((question: ReviewQuestion & { type: "multiple" }, index) => (
        <Flex key={`rating-without-categorie-${index}`} className={styles.question}>
          <DSRating className={styles.rating} value={question.score} max={question.max} />
          <p>{question.question}</p>
        </Flex>
      ))}
      {Object.keys(reviewDetails.categories).map((category, index) => {
        return (
          <section key={`section-${index}`}>
            <Flex className={styles.categoryTitle}>
              <h3>{t(`review.hot.sidepanel.category.${category}`)}</h3>
              <p>{reviewDetails.categories[category]}</p>
              <p>/5</p>
            </Flex>
            {reviewDetails.questions.map((question, ratingIndex) => {
              if (question.category !== category) {
                return null;
              }

              if (question.type !== "multiple" && question.type !== "radio") {
                return null;
              }

              return (
                <Flex key={`rating-${ratingIndex}`} className={styles.question}>
                  <DSRating className={styles.rating} value={question.score} max={question.max} />
                  <p>{question.question}</p>
                </Flex>
              );
            })}
          </section>
        );
      })}

      <section className={styles.info}>
        <p>
          {t("trainings.manage.reviews.session.completedAt", {
            date: completedAt,
            defaultValue: "Complétée le : {{ date }}",
          })}
        </p>
        <p>
          {t("trainings.manage.reviews.session.startDate", {
            date: startDate,
            defaultValue: "Début de la session : {{ date }}",
          })}
        </p>
        <p>
          {t("trainings.manage.reviews.session.reference", {
            reference: reviewDetails.session.reference,
            defaultValue: "ID de session : {{ reference }}",
          })}
        </p>
        <p>
          {t("trainings.manage.reviews.session.organization", {
            organization: reviewDetails.session.training.trainingOrganization,
            defaultValue: "Organisme : {{ organization }}",
          })}
        </p>
      </section>
    </>
  );
};

export default HotReviewPanelContentReviewed;