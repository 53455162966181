import { atom } from "recoil";
import { IResultType } from "../CreateNewTemplate/types";

const companiesState = atom<IResultType[]>({
  key: "companies",
  default: [],
});

const cursorState = atom<number>({
  key: "cursor",
  default: 20,
});

export { companiesState, cursorState };
