import { useQuery } from "@tanstack/react-query";
import { TargetsRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

async function getUserCampaignsQuery({ userUuid }: { userUuid: string }) {
  try {
    const result = await buildRequest<TargetsRoutes.GetUserCampaigns>({
      method: "GET",
      path: "/user/{userUuid}/targets/campaigns",
      params: { userUuid },
    })();
    return result;
  } catch (err) {
    return { campaigns: [] };
  }
}

export function useUserCampaigns({ userUuid }: { userUuid: string }) {
  const { data, isLoading } = useQuery(["targetsCampaign", userUuid], async () =>
    getUserCampaignsQuery({ userUuid })
  );

  return { ...data, isLoading };
}
