import React from "react";
import InterviewsHistoryImport from "./InterviewsHistoryImport";

import styles from "./Interviews.module.scss";

export default () => (
  <div>
    <div className={styles.Interviews}>
      <h2>Importer un historique d'entretiens</h2>
      <InterviewsHistoryImport />
    </div>
  </div>
);
