import { useState } from "react";

import { ListUtils } from "@skillup/shared-utils";
import { UseFilterProp, useUrlSyncedFilters } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";

export const useJobsGridFilters = (t: TranslationType) => {
  const [{ conf, initialValues }, setFilterConfig] = useState({
    conf: {
      name: {
        type: ListUtils.FilterType.TEXT,
        label: t("jobs.label.jobName", { defaultValue: "Fiche de poste" }),
      },
      skillsCount: {
        type: ListUtils.FilterType.TEXT,
        label: t("jobs.label.linkedSkill", { defaultValue: "Compétences liées" }),
      },
    },

    initialValues: {
      name: {
        label: t("jobs.label.jobName", { defaultValue: "Fiche de poste" }),
        placeholder: t("jobs.placeholder.jobName", {
          defaultValue: "Rechercher une fiche de poste",
        }),
        visibilityMode: "always",
      },
      skillsCount: {
        label: t("jobs.label.linkedSkill", { defaultValue: "Compétences liées" }),
        placeholder: t("jobs.placeholder.linkedSkill", {
          defaultValue: "Filtrer par compétences liées",
        }),
      },
    } as UseFilterProp<typeof conf>,
  });

  const [filters, filterValues, setFilterValues] = useUrlSyncedFilters(conf, initialValues);

  return [conf, filters, filterValues, setFilterValues, setFilterConfig] as [
    typeof conf,
    typeof filters,
    typeof filterValues,
    typeof setFilterValues,
    typeof setFilterConfig
  ];
};
