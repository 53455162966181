import { ColdReviewData, ColdReviewDetails, ReviewResultStatus } from "@skillup/training-bridge";

export const getQuestionsGroupBySentiment = (reviewDetails: ColdReviewDetails) => {
  const goodQuestions = reviewDetails.questions.filter((q) => {
    if (q.type === "multiple" || q.type === "radio") {
      return q.sentiment === ReviewResultStatus.POSITIVE;
    }

    return false;
  });
  const badQuestions = reviewDetails.questions.filter((q) => {
    if (q.type === "multiple" || q.type === "radio") {
      return q.sentiment === ReviewResultStatus.NEGATIVE;
    }

    return false;
  });

  return {
    positive: goodQuestions,
    negative: badQuestions,
  };
};

export const getCommentsQuestions = (reviewDetails: ColdReviewDetails) => {
  return reviewDetails.questions.filter((q) => {
    if (q.type === "comment" && q.comment && q.comment !== reviewDetails.data.comment) {
      return q;
    }
    return false;
  });
};

export const getSentimentColors = (reviewDetails: ColdReviewDetails) => {
  const colorPositive =
    reviewDetails.sentiment === ReviewResultStatus.POSITIVE ? "#0EAF84" : "#E5E8EB";
  const colorNegative =
    reviewDetails.sentiment === ReviewResultStatus.NEGATIVE ? "#F4645D" : "#E5E8EB";

  return {
    positive: colorPositive,
    negative: colorNegative,
  };
};

export const getSentimentPercentage = (
  reviewDetails: ColdReviewDetails,
  reviewData: ColdReviewData
) => {
  const { max, value } = reviewData;
  let percentagePositive = 0;
  let percentageNegative = 0;

  if (reviewDetails.sentiment === ReviewResultStatus.POSITIVE) {
    percentagePositive = Math.round((value / max) * 100);
    percentageNegative = 100 - percentagePositive;
  } else {
    percentageNegative = Math.round((value / max) * 100);
    percentagePositive = 100 - percentageNegative;
  }

  return {
    positive: percentagePositive,
    negative: percentageNegative,
  };
};
