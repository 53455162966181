import React from "react";
import cx from "classnames";

import { link as linkIcon } from "uiAssets/StrokeIcons";
import { mapPin as mapPinIcon } from "uiAssets/Icons";

import Icon from "components/Icon";
import modalitiesConstants from "constants/modalities";
import TrainingScore from "components/TrainingScore";

import { ITraining } from "../Actions/getTrainings";
import styles from "./TrainingStyles.module.scss";

export interface IProps {
  isSelected: boolean;
  data: ITraining;
  select: (training: ITraining) => void;
  lightMode?: true;
}

export interface IState {
  isExpanded: boolean;
  isSelected: boolean;
}

export default class Training extends React.PureComponent<IProps, IState> {
  public state = {
    isExpanded: false,
    isSelected: false,
  };

  private select = () => {
    this.props.select(this.props.data);
  };

  public render(): JSX.Element {
    const { lightMode } = this.props;
    const {
      certification,
      description,
      duration,
      isCPF,
      isNew,
      mode,
      name,
      organization,
      logo,
      objectives,
      price,
      reviewsCount,
      reviewsGlobalScore,
      seoSlug,
      sessions,
      sessionThreshold,
      subtitle,
      uuid,
      reference,
    } = this.props.data;

    const { isExpanded } = this.state;

    const { isSelected } = this.props;

    const nextSessions = (sessions || [])
      .map((session) => ({
        ...session,
        date: new Date(session.date),
      }))
      .filter(
        (session) => session.date.getTime() > (sessionThreshold ? sessionThreshold.getTime() : 0)
      )
      .slice(0, 3);

    const descriptionText = description || objectives || subtitle || null;

    return (
      <div
        className={cx({
          [styles.Training]: true,
          [styles.lightMode]: lightMode,
          [styles.classic]: !lightMode,
          [styles.selected]: isSelected,
        })}
        onClick={this.select}
      >
        <header>
          <div
            className={styles.logo}
            style={{
              backgroundImage: `url("${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${logo}")`,
            }}
          />

          <div className={styles.trainingLink}>
            <h3 className={styles.title}>{name}</h3>
            {reviewsCount && reviewsCount > 0 && reviewsGlobalScore ? (
              <TrainingScore
                score={Math.round(reviewsGlobalScore * 10) / 10}
                reviewsCount={reviewsCount}
              />
            ) : (
              ""
            )}
            {reference && <div className={styles.reference}>{reference}</div>}

            <div className={styles.tags}>
              {certification && <span className={styles.certification}>Certifiante</span>}
              {isCPF && <span className={styles.cpf}>CPF</span>}
              {isNew && <span className={styles.cpf}>Nouvelle</span>}
            </div>
          </div>

          <div className={styles.actions}>
            {!lightMode && (
              <button onClick={() => this.setState({ isExpanded: !isExpanded })}>
                {isExpanded ? "-" : "+"}
              </button>
            )}
            <button
              onClick={() => {
                if (seoSlug) {
                  window.open(`/responsable/programmes/catalogue/${seoSlug}`);
                } else {
                  window.open(`/responsable/programmes/gestion-intras/${uuid}`);
                }
              }}
            >
              <Icon strokeIcon={linkIcon} fill="#666" width={20} style={{ marginRight: 4 }} />
            </button>
          </div>
        </header>

        <div className={styles.summary}>
          <div>
            {mode && `${modalitiesConstants.mapping[mode]} -- `}
            {duration}
          </div>
          <div>{price}</div>
          <div>{`Par ${organization}`}</div>
          {
            // @ts-ignore
            !this.props.data.hasSessions && <div>Pas de session disponible</div>
          }
          {(nextSessions || [])[0] && (
            <div>
              <Icon icon={mapPinIcon} width={10} fill="#666" />
              {`${
                nextSessions[0].type !== "distancial" ? nextSessions[0].city : "À distance"
              } - ${new Date(nextSessions[0].date).toLocaleString("fr-FR", {
                day: "numeric",
                month: "long",
              })}`}
              {nextSessions.length > 1 && (
                <span>
                  {`+ ${nextSessions.length - 1} `}
                  <Icon icon={mapPinIcon} width={8} fill="#fff" />
                </span>
              )}
            </div>
          )}
        </div>

        <div className={styles.data} style={{ display: isExpanded ? "block" : "none" }}>
          {descriptionText && <h3>À propos</h3>}
          {descriptionText && <p>{descriptionText}</p>}
          {(nextSessions || []).length > 0 && (
            <h3>
              {`Prochaine${nextSessions.length > 1 ? "s" : ""} session${
                nextSessions.length > 1 ? "s" : ""
              }`}
            </h3>
          )}
          {nextSessions && nextSessions.length > 0 && (
            <div className={styles.nextSessionLinks}>
              {(nextSessions || []).map((session) => (
                <div key={session.uuid} className={styles.nextSessionLink}>
                  <Icon icon={mapPinIcon} width={12} />
                  {` ${session.type !== "distancial" ? session.city : "À distance"} - ${new Date(
                    session.date
                  ).toLocaleString("fr-FR", {
                    day: "numeric",
                    month: "long",
                  })}`}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
}
