import { ActionRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type CreateActionPayload = ActionRoutes.CreateActionRoute["payload"];

export async function createAction(payload: CreateActionPayload) {
  const result = await buildRequest<ActionRoutes.CreateActionRoute>({
    method: "POST",
    path: `/actions`,
    payload,
    target: "PEOPLE_REVIEW",
  })();

  return result;
}
