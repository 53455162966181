import React, { CSSProperties } from "react";
import cx from "classnames";

import Colors from "uiAssets/Colors";

import styles from "./Loader.module.scss";

export interface Props {
  readonly fill?: string;
  readonly style?: CSSProperties;
  readonly width?: number;
  readonly className?: string;
}

const Loader = ({ className, fill, width, style }: Props) => (
  <div style={style} className={cx(styles.Loader, className)} data-loader aria-label="loader">
    <svg
      height={`${width || "40"}px`}
      viewBox="0 0 50 50"
      width={`${width || "40"}px`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill || Colors.blue}
        d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.5s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
);

export default Loader;
