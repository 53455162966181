import { useEffect } from "react";
import { useSetState } from "react-use";

import DataLayer, { IOptions } from "utils/DataLayer";

interface IState<T> {
  data?: T;
  loading: boolean;
  error?: Error;
}

export interface IFetchResult<T> extends IState<T> {
  readonly refetch: () => Promise<T>;
}

const useFetch = <T>(options: IOptions): IFetchResult<T> => {
  const [{ data, error, loading }, setState] = useSetState<IState<T>>({ loading: true });

  let didCancel = false;

  const fetchData = (options: IOptions): Promise<T> =>
    new Promise((resolve, reject) => {
      DataLayer.request(options)
        .then((result) => {
          if (!didCancel) {
            setState({ data: result as T, loading: false });
            resolve(result as T);
          }
        })
        .catch((error) => {
          setState({ error, loading: false });
          reject(error);
        });
    });

  useEffect(() => {
    fetchData(options);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      didCancel = true;
    };
  }, [JSON.stringify(options)]);

  return { data, loading, error, refetch: async () => fetchData(options) };
};

export default useFetch;
