import User from "utils/User";

export const canCreateChildBlock = (blockType: string) => {
  switch (blockType) {
    case "trainingsCollection":
      return User.hasTrainingsEnabled();
    case "skills":
    case "job":
      return User.canAccessCompetencesModule();
    case "userSpecificData":
      return User.isSkillupAdmin();
    default:
      return true;
  }
};
