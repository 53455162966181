import React from "react";
import moment from "moment";
import { head, isNil } from "lodash";
import cx from "classnames";
import { FormatDate, fr } from "@skillup/shared-utils";
import { TextInput, RadioBox, TextDropdown, DSButton, NumberInput, DatePicker } from "@skillup/ui";
import { OrganizationDetails } from "hooks/useCompany";
import CreateLabel from "components/CreateLabel";
import FindUser from "components/FindUser";
import Colors from "uiAssets/Colors";
import { Collaborator, CreateCollaboratorPayload } from "../../api";
import { FieldElement, CollaboratorForm, IForm, matchingFields } from "./config";

import styles from "./UserForm.module.scss";

export const ParseUserAreasFromCredentials = (
  areas?: Array<{
    name: string;
    uuid: string;
    slug: string;
    createdAt: string;
    updatedAt: string;
  }>
): Array<{ label: string; value: string }> => {
  return (
    areas?.map((e) => ({
      label: e.name,
      value: e.uuid,
    })) ?? []
  );
};

export const ParseChildrenCompaniesFromOrganization = (
  company?: OrganizationDetails
): Array<{ label: string; value: string }> => {
  return (
    company?.children.map((e) => ({
      label: e.name,
      value: e.uuid,
    })) ?? []
  );
};

export const LoadCollabToForm = (
  collab?: Collaborator,
  maxLevel?: number,
  customFields?: Array<FieldElement>
) => {
  const sidePanelFields = matchingFields(customFields)
    .withObserverFields(maxLevel ?? 0)
    .generate();
  return sidePanelFields.reduce((acc, curr) => {
    if (curr.field === "manager0Lvl0") {
      return {
        ...acc,
        [curr.field]: collab?.manager0Lvl0
          ? {
              value: {
                email: collab?.manager0Lvl0Email,
                fullName: collab?.manager0Lvl0,
                organizations: [],
                organizationsByUuid: [],
              },
            }
          : undefined,
      };
    }

    if (curr.field === "manager0Lvl1") {
      return {
        ...acc,
        [curr.field]: collab?.manager0Lvl1
          ? {
              value: {
                email: collab?.manager0Lvl1Email,
                fullName: collab?.manager0Lvl1,
                organizations: [],
                organizationsByUuid: [],
              },
            }
          : undefined,
      };
    }

    if (curr.field === "joinDate") {
      return {
        ...acc,
        [curr.field]: collab?.joinDate ? { value: moment(collab.joinDate) } : undefined,
      };
    }

    if (curr.field === "areas") {
      return {
        ...acc,
        [curr.field]: collab.areas ? { value: head(collab.areas)?.uuid } : undefined,
      };
    }

    if (curr.field === "subCompanies") {
      return {
        ...acc,
        [curr.field]: collab.subCompanies ? { value: head(collab.subCompanies)?.uuid } : undefined,
      };
    }

    if (curr.field === "groups") {
      return {
        ...acc,
        [curr.field]: collab.groups ? { value: head(collab.groups)?.uuid } : undefined,
      };
    }

    if (curr.field === "bookingManager") {
      return {
        ...acc,
        [curr.field]: {
          value:
            collab?.bookingManager && collab?.hasBookingManager
              ? {
                  email: collab?.bookingManagerEmail,
                  fullName: collab?.bookingManager,
                  organizations: [],
                  organizationsByUuid: [],
                }
              : undefined,
        },
      };
    }

    // add observer levels dynamically
    for (let i = 1; i < maxLevel + 1; i++) {
      if (curr.field === `observerLvl${i}`) {
        return {
          ...acc,
          [curr.field]: collab.hierarchy?.observers.find(
            (e: { level: number; fullName: string; uuid: string; email: string }) => e.level === i
          )
            ? {
                value: {
                  fullName: collab.hierarchy?.observers.find(
                    (e: { level: number; fullName: string; uuid: string; email: string }) =>
                      e.level === i
                  )?.fullName,
                  email: collab.hierarchy?.observers.find(
                    (e: { level: number; fullName: string; uuid: string; email: string }) =>
                      e.level === i
                  )?.email,
                  organizations: [],
                  organizationsByUuid: [],
                },
              }
            : undefined,
        };
      }
    }

    const value = collab?.[curr.field];

    return {
      ...acc,
      [curr.field]: { value },
    };
  }, {});
};

export const BuildPayloadFromForm = (
  form: IForm,
  numberOfObserverFields?: number,
  customFields?: Array<FieldElement>
) => {
  let localObservers: { level: number; email: string; fullName: string; uuid: string }[] = [];
  return matchingFields(customFields)
    .withObserverFields(numberOfObserverFields ?? 0)
    .generate()
    .reduce((acc, curr) => {
      if (curr.field === "areas") {
        const value = form[curr.field]?.value;

        return {
          ...acc,
          ...(value && { userAreas: [value] }),
        };
      }

      if (curr.field === "subCompanies") {
        const value = form[curr.field]?.value;

        return {
          ...acc,
          ...(value && { subCompanyUuid: value }),
        };
      }

      if (curr.field === "groups") {
        const value = form[curr.field]?.value;

        return {
          ...acc,
          ...(value && { groupUuid: value }),
        };
      }

      if (curr.field === "manager0Lvl0") {
        const value = form[curr.field]?.value;

        return {
          ...acc,
          ...(value && { manager0Lvl0: value.email }),
        };
      }

      if (curr.field === "manager0Lvl1") {
        const value = form[curr.field]?.value;

        return {
          ...acc,
          ...(value && { manager0Lvl1: value.email }),
        };
      }

      if (curr.field === "hasBookingManager") {
        return acc;
      }

      if (curr.field === "bookingManager") {
        if (curr.display && curr.display(form)) {
          const value = form[curr.field]?.value;

          return {
            ...acc,
            ...(value && { bookingManager: value.email }),
          };
        } else {
          return acc;
        }
      }

      // add observer levels dynamically
      Array.from(Array(numberOfObserverFields).keys()).forEach((i) => {
        if (curr.field === `observerLvl${i + 1}`) {
          const value = form[curr.field]?.value;
          if (value) {
            localObservers.push({
              level: i + 1,
              email: value.email,
              fullName: value.fullName,
              uuid: "",
            });
          }
        }
      });

      const value = form[curr.field]?.value;

      return {
        ...acc,
        ...(value && { [curr.field]: value, hierarchy: { observers: localObservers } }),
      };
    }, {} as CreateCollaboratorPayload);
};

export interface Props {
  readonly form: IForm;
  readonly setFormState: (patch: Partial<IForm> | ((prevState: IForm) => Partial<IForm>)) => void;
  readonly userAreas: Array<{ label: string; value: string }>;
  readonly companyGroups: Array<{ label: string; value: string }>;
  readonly subCompanies: Array<{ label: string; value: string }>;
  readonly maxLevel?: number;
  readonly newObserverFields?: number;
  readonly setNewObserverFields?: React.Dispatch<React.SetStateAction<number>>;
  readonly customFields?: Array<FieldElement>;
}

const CompanyForm = ({
  form,
  setFormState,
  userAreas,
  companyGroups,
  subCompanies,
  maxLevel,
  newObserverFields,
  setNewObserverFields,
  customFields,
}: Props) => {
  const onClick = async (e) => {
    e.preventDefault();
    setNewObserverFields((n: number) => n + 1);
  };
  const onChangeInput = (field: CollaboratorForm) => (value: any) => {
    setFormState({
      [field]: { value },
    });
  };

  const RenderInput = (line: FieldElement) => {
    switch (line.type) {
      case "TextInput":
        return (
          <TextInput
            className={styles.input}
            value={form?.[line.field]?.value ?? ""}
            placeholder={line.placeholder ?? line.name}
            onChange={onChangeInput(line.field)}
            errored={!!form?.[line.field]?.error}
            controlled
          />
        );

      case "NumberInput":
        return (
          <NumberInput
            className={styles.input}
            value={form?.[line.field]?.value ?? ""}
            placeholder={line.placeholder ?? line.name}
            onChange={onChangeInput(line.field)}
            errored={!!form?.[line.field]?.error}
            controlled
            min={0}
          />
        );

      case "Radio":
        return (
          <div className={styles.radioBox}>
            <RadioBox
              className={styles.radioBoxElement}
              checked={form?.[line.field]?.value === true}
              label="Oui"
              value={true}
              onClick={(v) =>
                isNil(v) ? onChangeInput(line.field)(undefined) : onChangeInput(line.field)(v)
              }
              canUncheck={line.canUncheck}
            />
            <RadioBox
              className={styles.radioBoxElement}
              checked={form?.[line.field]?.value === false}
              label="Non"
              value={false}
              onClick={(v) =>
                isNil(v) ? onChangeInput(line.field)(undefined) : onChangeInput(line.field)(v)
              }
              canUncheck={line.canUncheck}
            />
          </div>
        );

      case "TextDropdown":
        let options = [];
        if (line.field === "areas") {
          options = userAreas;
        } else if (line.field === "subCompanies") {
          options = subCompanies;
        } else if (line.field === "groups") {
          options = companyGroups;
        }

        const index = options.findIndex((e) => e.value === form?.[line.field]?.value);

        return (
          <TextDropdown
            className={styles.input}
            options={options}
            selectedIndex={index !== -1 ? index : undefined}
            onClear={() => onChangeInput(line.field)(undefined)}
            onSelect={(item) => onChangeInput(line.field)(item.value)}
            placeholder={line.placeholder ?? line.name}
            onError={!!form?.[line.field]?.error}
          />
        );

      case "UserSearch":
        return (
          <FindUser
            onEdit={() => onChangeInput(line.field)(undefined)}
            showRightIcon
            user={form?.[line.field]?.value}
            autoFocus={false}
            onSelect={onChangeInput(line.field)}
            underlineColor={form?.[line.field]?.error ? Colors.error : undefined}
          />
        );

      case "Date":
        // The datepicker is not UTC compliant
        // Date here are only date in UTC (time don't matter)
        // We fix the DatePicker in fr locale
        // And Set the date to UTC equivalent from fr locale.
        const handleChangeDateInput = (date: Date) => {
          if (!date) {
            onChangeInput(line.field)(null);
            return;
          }
          const utcDate = FormatDate.getUTCDateFromLocalDateTime(date);
          onChangeInput(line.field)(utcDate);
        };

        return (
          <DatePicker
            value={form?.[line.field]?.value && new Date(form?.[line.field]?.value)}
            onChange={handleChangeDateInput}
            withIcon={false}
            className={styles.inputDate}
            classNameContainer={styles.inputDateContainter}
            locale={fr}
          />
        );

      case "Title":
        return undefined;
    }
  };

  const fields = matchingFields(customFields)
    .withObserverFields(maxLevel ? maxLevel + newObserverFields : newObserverFields)
    .generate();

  return (
    <>
      {fields.map((line, index) => {
        if (line.type === "Title") {
          return (
            <div className={cx(styles.title, { [styles.divider]: index !== 0 })}>{line.name}</div>
          );
        }
        if (!line.display || line.display(form)) {
          return (
            <div className={styles.inputLine} key={line.name}>
              <CreateLabel className={styles.label} label={line.name} required={line.required} />
              {RenderInput(line)}
            </div>
          );
        }

        return undefined;
      })}

      <DSButton
        className={styles.addNewObserver}
        label="Ajouter un manager entretiens"
        emphasis="Low"
        fontWeight="bold"
        buttonSize="S"
        onClick={onClick}
      />
    </>
  );
};

export default CompanyForm;
