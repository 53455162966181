import { Flex } from "@skillup/ui";

import emptyTemplateImg from "assets/interview/templates/empty-template.png";

import styles from "./EmptyStructureBlock.module.scss";

export const EmptyStructureBlock = () => {
  // const dispatch = useDispatch();
  // const { updatedItemIdRef } = useBuilderContext();
  // const handleCreateFirstSection = useCallback(() => {
  //   const sectionUuid = uuid();
  //   updatedItemIdRef.current = { uuid: sectionUuid, fromAction: "addBlock" };
  //   dispatch(
  //     add({
  //       uuid: sectionUuid,
  //       from: { sectionIndex: 0, pageIndex: 0, childIndex: 0 },
  //       blockType: "section",
  //     })
  //   );
  // }, [dispatch, updatedItemIdRef]);
  return (
    <Flex column center className={styles.emptyStructureBlock}>
      <img src={emptyTemplateImg} alt="empty-template" />
      <strong>Votre trame ne contient aucune section</strong>
      {/* [CSB] We do not allow to add a block for now 
      <DSButton
        label="Ajouter la première section"
        darkMode
        buttonSize="M"
        emphasis="High"
        onClick={handleCreateFirstSection}
      /> */}
    </Flex>
  );
};
