import { InterviewType, TemplatesRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import { Template } from "../useTemplates";

export type EditTemplateParams = {
  templateUuid: string;
  title?: string;
  type?: InterviewType;
  sections?: Template["sections"];
  targetsCategories?: Template["targetsCategories"];
  targetCategories?: { uuid?: string; label: string }[];
  choicesForOrdinalTargets?: Template["choicesForOrdinalTargets"];
};

async function editParamsTemplate(params: EditTemplateParams) {
  const {
    templateUuid,
    title,
    type,
    sections,
    targetsCategories,
    targetCategories,
    choicesForOrdinalTargets,
  } = params;
  try {
    const response = await buildRequest<TemplatesRoutes.EditParams>({
      method: "POST",
      path: "/templates/interviewsV2/editParams/{templateUuid}",
      params: { templateUuid },
      payload: {
        title,
        type,
        sections,
        targetsCategories,
        targetCategories,
        choicesForOrdinalTargets,
      },
    })();
    if (!response.success) {
      throw new Error("editParams unsuccessful");
    }
    return response;
  } catch (err) {
    throw new Error("editParams unsuccessful");
  }
}

export { editParamsTemplate };
