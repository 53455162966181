import styled from "styled-components";

export type LoaderInterface = {
  fillSpace?: boolean;
};

const LoaderWrapper = styled.div<LoaderInterface>`
  ${({ fillSpace }) =>
    fillSpace &&
    `
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center; 
`};
`;

export default LoaderWrapper;
