import { useTranslation } from "react-i18next";
import { useRef, useMemo, Dispatch, useState, useContext, SetStateAction } from "react";

import { ClickAwayListener } from "@mui/material";
import { GridRowId, GridRowModel, useGridApiContext } from "@mui/x-data-grid-pro";

import { DSButton, DSTooltip, DSDropdown, DSDropdownItem, DSDropdownDivider } from "@skillup/ui";
import {
  ColumnsVisibilityPopperWrapper,
  ColumnsVisibilityPopperContent,
} from "@skillup/design-system";

import { useRemoveReviews } from "services/peopleReview/campaign/useRemoveReviews";

import { SupervisionContext } from "../../contexts";
import { TalentGridRow, TalentGridState } from "../../types";

export function SelectActions({
  columnsConfig,
  columnsVisibilityModel,
  selectedRows,
  setColumnsVisibilityModel,
  setState,
}: {
  selectedRows: GridRowId[];
  setState: (state: TalentGridState) => void;
  columnsVisibilityModel: { [key: string]: boolean };
  columnsConfig: Array<{ key: string; headerName: string }>;
  setColumnsVisibilityModel: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
}) {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const [validating, setValidating] = useState(false);
  const popperAnchorRef = useRef<HTMLDivElement>(null);
  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);

  const { campaign, campaignMutations } = useContext(SupervisionContext);
  const currentSelection: TalentGridRow[] = selectedRows.map((r) => apiRef.current.getRow(r));
  const { removeReviews } = useRemoveReviews({ campaignID: campaign.id });

  const tooltipText = useMemo(() => {
    if (
      campaign?.permissions?.arbitrate.isVisible &&
      campaign?.permissions?.arbitrate.isEnabled === false
    ) {
      return t(campaign?.permissions?.arbitrate.reasonKey, {
        defaultValue: "Vous n'avez pas les droits pour valider les arbitrages.",
      });
    } else if (currentSelection.length === 0) {
      return "Sélectionnez des collaborateurs pour valider leurs arbitrages.";
    }

    return "";
  }, [currentSelection.length, campaign?.permissions?.arbitrate, t]);

  const canArbitrate = useMemo(() => {
    return (
      campaign?.permissions?.arbitrate.isEnabled &&
      currentSelection.length &&
      currentSelection.some((row) => row.status !== "done")
    );
  }, [campaign?.permissions?.arbitrate.isEnabled, currentSelection]);

  const arbitrateVisible = campaign?.permissions?.arbitrate.isVisible;
  const campaignIsRunning = campaign?.state === "running";

  return (
    <>
      {campaignIsRunning && arbitrateVisible && (
        <DSTooltip label={tooltipText}>
          <DSButton
            buttonSize="S"
            loading={validating}
            disabled={!canArbitrate}
            label={t("peopleReview.talentGrid.bulkActions.arbitrate.label", {
              defaultValue: "Valider les arbitrages",
            })}
            onClick={async () => {
              setValidating(true);

              const selectionToArbitrate = Array.from(
                apiRef.current.getSelectedRows(),
                ([id, row]) => [id, row] as [string, GridRowModel]
              )
                .filter(([_id, row]) => row.status !== "done")
                .map(([id, row]) => {
                  return [id, { ...row, status: "done" }] as [string, GridRowModel];
                });

              await campaignMutations?.employees?.arbitrate({
                campaignId: campaign?.id,
                employeesIds: selectionToArbitrate.map((r) => r[0]),
              });

              apiRef.current.updateRows(selectionToArbitrate.map((r) => r[1]));
              setValidating(false);
            }}
          />
        </DSTooltip>
      )}
      <div ref={popperAnchorRef}>
        <DSDropdown>
          {campaign.permissions["change-manager"].isVisible && (
            <DSDropdownItem
              key="change-manager"
              onClick={() => {
                setState({ kind: "changeManager", reviews: currentSelection });
              }}
              disabled={
                !currentSelection.length || !campaign.permissions["change-manager"].isEnabled
              }
              label={
                !currentSelection.length
                  ? t("peopleReview.talentGrid.bulkActions.changeManager.disabled.label", {
                      defaultValue: "Changer de manager",
                    })
                  : t("peopleReview.talentGrid.bulkActions.changeManager.label", {
                      count: currentSelection.length,
                      defaultValue:
                        currentSelection.length === 1
                          ? "Changer de manager pour un collaborateur"
                          : "Changer de manager pour {{count}} collaborateurs",
                    })
              }
              {...(!currentSelection.length && {
                tooltipLabel: t(
                  "peopleReview.talentGrid.bulkActions.changeManager.disabled.tooltip",
                  {
                    defaultValue: "Sélectionnez au moins une ligne dans le tableau",
                  }
                ),
              })}
            />
          )}
          {campaign.permissions["delete-reviews"].isVisible && (
            <DSDropdownItem
              key="remove-Review"
              onClick={() => {
                removeReviews({ ids: selectedRows.map((id) => id.toString()) });
              }}
              disabled={
                !currentSelection.length || !campaign.permissions["delete-reviews"].isEnabled
              }
              label={
                !currentSelection.length
                  ? t("peopleReview.talentGrid.bulkActions.removeReview.disabled.label", {
                      defaultValue: "Retirer un collaborateur de cette campagne",
                    })
                  : t("peopleReview.talentGrid.bulkActions.removeReview.label", {
                      count: currentSelection.length,
                      defaultValue:
                        currentSelection.length === 1
                          ? "Retirer un collaborateur de cette campagne"
                          : "Retirer les {{count}} collaborateurs de cette campagne",
                    })
              }
              {...(!currentSelection.length && {
                tooltipLabel: t(
                  "peopleReview.talentGrid.bulkActions.removeReview.disabledTooltip",
                  {
                    defaultValue: "Sélectionnez au moins une ligne dans le tableau",
                  }
                ),
              })}
            />
          )}
          {arbitrateVisible && <DSDropdownDivider />}
          <DSDropdownItem
            key="select-columns"
            onClick={() => setIsPopperOpen(true)}
            label={t("talentGrid.select-columns.label", {
              defaultValue: "Gérer les colonnes du tableau",
            })}
          />
        </DSDropdown>

        <ColumnsVisibilityPopperWrapper isOpen={isPopperOpen} anchor={popperAnchorRef.current}>
          <ClickAwayListener onClickAway={() => setIsPopperOpen(false)}>
            <div role="presentation">
              <ColumnsVisibilityPopperContent
                columnsConfig={columnsConfig}
                columnsVisibilityModel={columnsVisibilityModel}
                setColumnsVisibilityModel={setColumnsVisibilityModel}
              />
            </div>
          </ClickAwayListener>
        </ColumnsVisibilityPopperWrapper>
      </div>
    </>
  );
}
