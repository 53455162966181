import useTranslation from "hooks/useTranslation";

import styles from "./ReviewPanelContentNotReviewed.module.scss";
import { ColdReviewDetails, HotReviewDetails, ReviewStatus } from "@skillup/training-bridge";
import { Flex } from "@skillup/ui";

const ReviewPanelContentNotReviewed = ({
  reviewDetails,
}: {
  reviewDetails: ColdReviewDetails | HotReviewDetails;
}) => {
  const { t } = useTranslation();

  const showMailHistory = reviewDetails?.mailsHistory?.mailCount > 0;

  const lastMailDate = showMailHistory
    ? new Date(reviewDetails.mailsHistory.lastMailDate).toLocaleDateString("fr-FR", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : null;

  return (
    <>
      <h1 className={styles.placeholder}>
        {t("trainings.manage.reviews.sidepanel.content.notReviewed", {
          defaultValue:
            "Le questionnaire d'évaluation n'a pas encore été complété par le stagiaire.",
        })}
      </h1>
      {reviewDetails?.status === ReviewStatus.CANCELLED && (
        <h1 className={styles.placeholder}>
          {t("trainings.manage.reviews.sidepanel.content.cancelled", {
            defaultValue: "Les relances automatiques pour cette évaluation ont été annulées.",
          })}
        </h1>
      )}
      {showMailHistory && (
        <Flex column className={styles.mailHistory}>
          <h2>
            {t("trainings.manage.reviews.sidepanel.content.notReviewed.mailHistory", {
              defaultValue: "Historique d’e-mail",
            })}
          </h2>
          <span>
            {t("trainings.manage.reviews.sidepanel.content.notReviewed.lastMailSent", {
              defaultValue: "Dernier e-mail envoyé le {{date}}",
              date: lastMailDate || "",
            })}
          </span>
          <span>
            {t("trainings.manage.reviews.sidepanel.content.notReviewed.nbMailsSent", {
              defaultValue: "{{count}} e-mails envoyés",
              count: reviewDetails.mailsHistory?.mailCount,
            })}
          </span>
        </Flex>
      )}
    </>
  );
};

export default ReviewPanelContentNotReviewed;
