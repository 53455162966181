import { Duration } from "@skillup/shared-utils";

import { fetchIntraFields } from "../../api/fetchIntraFields";
import { FetchProps } from "./types";

export const fetchData = async ({
  intraUuid,
  setState,
  setFields,
  setFieldsForm,
  setTrainingForm,
}: FetchProps) => {
  const data = await fetchIntraFields(intraUuid);
  const fields = Object.values(data.fields)
    .filter((field) => !field.isHidden)
    .reduce((acc, key) => ({ ...acc, [key.binding]: key }), {});

  let form = {};

  if (fields) {
    for (const key in fields) {
      form[key] = { value: fields[key].value };
    }
  }

  setFields(fields);

  setFieldsForm(form);
  setTrainingForm({
    description: data.description,
    duration: Duration.fromIsoToHour(data.duration),
    mode: data.mode,
    prerequisites: data.prerequisites,
    price: data.price,
    priceWithTax: data.priceWithTax,
    program: data.program,
    objectives: data.objectives,
    targetAudience: data.targetAudience,
    name: data.name,
    trainingOrganization: data.trainingOrganizationName,
    trailer: data.trailer,
    tag: {
      value: data.tag?.uuid,
      label: data.tag?.name,
    },
    reference: data.reference,
    version: data.version,
  });
  setState({ status: "loaded" });
};
