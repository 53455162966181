import React, { useMemo } from "react";
import Icon from "components/Icon";
import colors from "uiAssets/Colors";

import styles from "./DashboardDataLinesStyles.module.scss";
import type { OrganizationRoutesType } from "types/api";
import useSettings from "hooks/useSettings";

type Settings = OrganizationRoutesType.GetSettings["response"];

export interface IDataLine {
  contents: Array<string | number>;
  icon?: string;
  iconColor?: string;
  key: string;
  type?: string;
  isVisible?: (settings?: Settings) => boolean;
}

export interface IDataLinesProps {
  lines: Array<IDataLine>;
}

function DataLines({ lines }: IDataLinesProps) {
  const { settings } = useSettings();
  const res = useMemo(
    () => (
      <ul className={styles.DataLines}>
        {lines
          .filter((line) => line?.isVisible?.(settings) ?? true)
          .map((line) => (
            <li key={line.key} className={line.type && styles[line.type]}>
              {(line.contents || []).map((content, index) => (
                <div key={String(content) + String(index)}>
                  {index === 0 && line.icon && (
                    <Icon
                      strokeIcon={line.icon}
                      stroke={line.iconColor || colors.blue}
                      width={11}
                    />
                  )}
                  {content}
                </div>
              ))}
            </li>
          ))}
      </ul>
    ),
    [settings, lines]
  );

  return res;
}

export default DataLines;
