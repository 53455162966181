import { useState, useCallback, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import isEmpty from "lodash/isEmpty";

import {
  DSModal,
  MaterialIcons,
  DSTextInput,
  DSButton,
  HorizontalDivider,
  Flex,
} from "@skillup/ui";
import { DateTime } from "@skillup/shared-utils";

import { plural } from "utils/locale";
import { type Template, useTemplates } from "services/interviews";

import styles from "./ArchivedTemplates.module.scss";

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
}

export type ModalState =
  | { step: "idle" }
  | { step: "unarchiving"; templateUuid: string }
  | { step: "success" }
  | { step: "error"; error: string };

export const ArchivedTemplatesModal = ({ isOpen, closeModal }: IProps) => {
  const [modalState, setModalState] = useState<ModalState>({ step: "idle" });
  const {
    archivedTemplates,
    actions: { searchOnArchived, unarchiveByUuid, canDo },
  } = useTemplates({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const canUnarchive = canDo("archive");

  const [filteredTemplates, setfilteredTemplates] = useState<Template[]>(archivedTemplates);
  const { addToast } = useToasts();

  useEffect(() => {
    switch (modalState.step) {
      case "error":
        addToast(modalState.error, {
          appearance: "error",
        });
        break;
      case "success":
        addToast("Trame désarchivée avec succès", {
          appearance: "success",
        });
        closeModal();
        break;
      case "unarchiving":
      case "idle":
      default:
        return;
    }
  }, [modalState, addToast, closeModal]);

  const handleChange = useCallback(
    async (e) => {
      const filtered = await searchOnArchived(e);
      setfilteredTemplates(filtered);
    },
    [searchOnArchived]
  );

  const onUnarchive = useCallback(
    async (templateUuid: string) => {
      if (!canUnarchive) return;
      if (modalState.step === "unarchiving") return;
      setModalState({ step: "unarchiving", templateUuid });
      try {
        const response = await unarchiveByUuid(templateUuid);
        if (response.success) {
          setModalState({ step: "success" });
        } else {
          throw new Error();
        }
      } catch (err) {
        setModalState({ step: "error", error: "Echec lors du désarchivage de la trame" });
      }
    },
    [unarchiveByUuid, setModalState, modalState, canUnarchive]
  );

  return (
    <DSModal isOpen={isOpen} className={styles.ArchivedTemplatesModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={plural(archivedTemplates.length, "%n trame%s d'entretien archivée%s")}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Flex column className={styles.list}>
          <div className={styles.search}>
            <DSTextInput
              placeholder="Rechercher parmi les trames archivées"
              name="filter-templates"
              actionButton={<MaterialIcons.Search />}
              onChange={handleChange}
            />
          </div>
          <ArchivedTemplatesList
            templates={archivedTemplates}
            filteredTemplates={filteredTemplates}
          >
            {filteredTemplates.map((template, index) => (
              <ArchivedTemplateItem
                isLastItem={index === filteredTemplates.length - 1}
                key={template.uuid}
                template={template}
                onUnarchive={canUnarchive ? onUnarchive : undefined}
                disabled={modalState.step === "unarchiving"}
                loading={modalState.step === "unarchiving" && modalState.templateUuid === null}
              />
            ))}
          </ArchivedTemplatesList>
        </Flex>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </DSModal>
  );
};

const ArchivedTemplatesList = ({
  templates,
  filteredTemplates,
  children,
}: {
  templates: Template[];
  filteredTemplates: Template[];
  children: React.ReactNode;
}) => {
  if (isEmpty(templates)) {
    return <section className={styles["list__item--empty"]}>Aucune trame archivée</section>;
  }
  if (isEmpty(filteredTemplates)) {
    return (
      <section className={styles["list__item--empty"]}>
        Aucune trame archivée ne correspond à votre recherche.
      </section>
    );
  }
  return <>{children}</>;
};

const ArchivedTemplateItem = ({
  template,
  onUnarchive,
  disabled,
  loading,
  isLastItem,
}: {
  template: Template;
  onUnarchive?: (templateUuid: string) => void;
  disabled: boolean;
  loading: boolean;
  isLastItem: boolean;
}) => {
  const unarchiveIsAvailable = onUnarchive !== undefined;
  return (
    <>
      <Flex row className={styles.list__item}>
        <div className={styles.list__item__content}>
          <div className={styles.list__item__content__title}>{template.title}</div>
          <div className={styles.list__item__content__subtitle}>
            Archivée le {DateTime.fromISO(template.archivedAt).toFormat("dd/MM/yyyy")}
            {template.formattedLogs?.archivist &&
              " par " + template.formattedLogs?.archivist?.fullName}
          </div>
        </div>
        {unarchiveIsAvailable && (
          <div>
            <DSButton
              buttonSize="S"
              emphasis="Mid"
              label="Désarchiver"
              onClick={() => onUnarchive(template.uuid)}
              disabled={disabled}
              loading={loading}
            />
          </div>
        )}
      </Flex>
      {isLastItem ? null : <HorizontalDivider top="none" bottom="none" />}
    </>
  );
};
