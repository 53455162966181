const FuzzyCheckString = (
  stringToTest: string,
  stringToScore: string,
  returnFormated = false
): {
  matched: boolean;
  score: number;
  formattedStr: string;
} => {
  /**
   * Scoring constants
   */
  const adjacency_bonus = 5; // bonus for adjacent matches
  const separator_bonus = 10; // bonus if match occurs after a separator
  const camel_bonus = 10; // bonus if match is uppercase and prev is lower
  const leading_letter_penalty = -3; // penalty applied for every letter in str before the first match
  const max_leading_letter_penalty = -9; // maximum penalty for leading letters
  const unmatched_letter_penalty = -1; // penalty for every letter that doesn't matter

  // Loop variables
  let score = 0;
  let prevMatched = false;
  let prevLower = false;
  let prevSeparator = true; // true so if first letter match gets separator bonus

  // Use "best" matched letter if multiple string letters match the stringToScore
  let bestLetter: string | null = null;
  let bestLower: string | null = null;
  let bestLetterIdx = 0;
  let bestLetterScore = 0;

  const matchedIndices = [];

  const stringToTestLower: string = stringToTest.toLowerCase();
  const stringToTestUpper: string = stringToTest.toUpperCase();
  let stringToTestChar: string | null;
  let stringToTestCharLower: string | null;
  let stringToTestCharUpper: string | null;
  let stringToTestCharPosition = 0;

  // const stringToScoreLength: number = stringToScore.length;
  let stringToScoreChar: string | null;
  let stringToScoreCharLower: string | null;
  let stringToScoreCharPosition = 0;
  const stringToScoreLower: string = stringToScore.toLowerCase();

  let nextMatch = false;
  // const patternRepeat = false;
  // const advancedOrRepat = false;

  let newScore = 0;
  let penalty = 0;

  for (
    stringToTestCharPosition;
    stringToTestCharPosition < stringToTest.length;
    stringToTestCharPosition++
  ) {
    // Get the current char in the string to tests
    stringToTestChar = stringToTest.charAt(stringToTestCharPosition);
    stringToTestCharLower = stringToTestLower.charAt(stringToTestCharPosition);
    stringToTestCharUpper = stringToTestUpper.charAt(stringToTestCharPosition);

    // Get the current char in the string to score
    stringToScoreChar = null;
    stringToScoreCharLower = null;
    nextMatch = false;
    if (stringToScoreCharPosition !== stringToScore.length) {
      stringToScoreChar = stringToScore.charAt(stringToScoreCharPosition);
      stringToScoreCharLower = stringToScoreLower.charAt(stringToScoreCharPosition);
      nextMatch = stringToScoreCharLower === stringToTestCharLower;
    }

    if (
      (nextMatch && bestLetter) ||
      (bestLetter && stringToScoreChar && bestLower === stringToScoreCharLower)
    ) {
      score += bestLetterScore;
      matchedIndices.push(bestLetterIdx);
      bestLetter = null;
      bestLower = null;
      bestLetterIdx = null;
      bestLetterScore = 0;
    }

    if (nextMatch || (bestLetter && bestLower === stringToTestCharLower)) {
      newScore = 0;

      // Apply penalty for each letter before the first stringToScore match
      // Note: std::max because penalties are negative values. So max is smallest penalty.
      if (stringToScoreCharPosition === 0) {
        penalty = Math.max(
          stringToTestCharPosition * leading_letter_penalty,
          max_leading_letter_penalty
        );
        score += penalty;
      }

      // Apply bonus for consecutive bonuses
      if (prevMatched) newScore += adjacency_bonus;

      // Apply bonus for matches after a separator
      if (prevSeparator) newScore += separator_bonus;

      // Apply bonus across camel case boundaries. Includes "clever" isLetter check.
      if (
        prevLower &&
        stringToTestChar === stringToTestCharUpper &&
        stringToTestCharLower !== stringToTestCharUpper
      )
        newScore += camel_bonus;

      // Update patter index IFF the next stringToScore letter was matched
      if (nextMatch) ++stringToScoreCharPosition;

      // Update best letter in str which may be for a "next" letter or a "rematch"
      if (newScore >= bestLetterScore) {
        // Apply penalty for now skipped letter
        if (bestLetter != null) score += unmatched_letter_penalty;

        bestLetter = stringToTestChar;
        bestLower = bestLetter.toLowerCase();
        bestLetterIdx = stringToTestCharPosition;
        bestLetterScore = newScore;
      }

      prevMatched = true;
    } else {
      // Append unmatch characters
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      formattedStr += stringToTestChar;

      score += unmatched_letter_penalty;
      prevMatched = false;
    }

    // Includes "clever" isLetter check.
    prevLower =
      stringToTestChar === stringToTestCharLower && stringToTestCharLower !== stringToTestCharUpper;
    prevSeparator = stringToTestChar === "_" || stringToTestChar === " ";
  }

  // Apply score for last match
  if (bestLetter) {
    score += bestLetterScore;
    matchedIndices.push(bestLetterIdx);
  }

  // Finish out formatted string after last stringToScore matched
  // Build formated string based on matched letters
  let formattedStr = "";
  if (returnFormated) {
    let lastIdx = 0;
    for (let i = 0; i < matchedIndices.length; ++i) {
      const idx = matchedIndices[i];
      formattedStr +=
        stringToTest.substr(lastIdx, idx - lastIdx) + "<b>" + stringToTest.charAt(idx) + "</b>";
      lastIdx = idx + 1;
    }
    formattedStr += stringToTest.substr(lastIdx, stringToTest.length - lastIdx);
  }

  return {
    matched: stringToScoreCharPosition === stringToScore.length,
    score,
    formattedStr,
  };
};

export default FuzzyCheckString;
