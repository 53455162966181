import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type CampaignDetails = CampaignsRoutes.GetDetails["response"];

export async function getCampaignDetails(campaignID: string) {
  const response = await buildRequest<CampaignsRoutes.GetDetails>({
    method: "GET",
    params: { campaignID },
    path: "/campaigns/{campaignID}",
    target: "PEOPLE_REVIEW",
  })();

  return response;
}
