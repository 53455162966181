import React from "react";

import styled from "styled-components";
import { DSColor, EspaceFont, FontFamily, SkuiSpacing } from "commons";

import { DSTooltip, MaterialIcons } from "@skillup/ui";

export const Card = styled.div<{ darkMode: boolean; isRHTheme: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 12.5rem;
  min-height: 7.5rem;
  width: 250px;
  border-radius: ${SkuiSpacing.s};
  padding: ${SkuiSpacing.s};

  ${({ isRHTheme }) =>
    isRHTheme ? `font-family: ${FontFamily.rh};` : `font-family: ${FontFamily.collab};`};

  ${({ darkMode }) =>
    darkMode
      ? `background-color: ${DSColor["surface-dark"]};
        border: 1px solid ${DSColor["border-onDark"]}`
      : `background-color: ${DSColor["surface-light"]};
        border: 1px solid ${DSColor["decoration-blue-lighter"]}`};
`;

export const FirstRow = styled.div<{ darkMode: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  color: ${DSColor["decoration-blue-darker"]};
  gap: ${SkuiSpacing.s};

  ${({ darkMode }) => darkMode && `color: ${DSColor["plainText-onDark-default"]}`};

  > span {
    font-size: ${SkuiSpacing.xxl};
    flex-grow: 100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > svg {
    height: inherit;
  }
`;

export const DynamicIcon = styled(({ name, ...props }) => {
  const Icon = MaterialIcons[name];
  return <Icon {...props} />;
})`
  opacity: 0.5;
  min-width: 3rem;
`;

export const SecondRow = styled.div<{ darkMode: boolean; isRHTheme: boolean }>`
  flex-grow: 100;
  display: flex;
  align-items: flex-end;

  ${({ isRHTheme }) => (isRHTheme ? EspaceFont.rh.captionBold : EspaceFont.collab.captionBold)};

  ${({ darkMode }) =>
    darkMode
      ? `color: ${DSColor["plainText-onDark-darker"]}`
      : `color: ${DSColor["plainText-onLight-lighter"]}`};
`;

export const TooltipLabel = styled(DSTooltip)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
`;
