import { MutableRefObject, useMemo, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import cx from "classnames";

import { DSAvatar, MaterialIcons } from "@skillup/ui";
import {
  InterviewRoles,
  InterviewActiveAttendeeRoles,
  OpenQuestionAnswer,
} from "@skillup/espace-rh-bridge";

import styles from "./OpenEndedQuestion.module.scss";
import QuestionCard from "../QuestionCard/QuestionCard";
import { StructureChildPayload } from "../DashboardChildCard/DashboardChildCard";

import crossImg from "assets/interview/dashboard/cross.svg";
import pendingImg from "assets/interview/dashboard/pending.svg";

export interface Props {
  child: StructureChildPayload;
}

function OpenEndedQuestion({ child }: Props) {
  const bodyRef = useRef();
  // canBeRepliedTo variable can happen in uncommon case
  // where clients ask for questions without answers
  const canBeRepliedTo = !child?.disallow?.employee?.reply || !child?.disallow?.manager?.reply;
  return (
    <QuestionCard
      child={child}
      className={styles.OpenEndedQuestion}
      icon={<MaterialIcons.ChatBubble />}
      typeName="Question ouverte"
      bodyRef={bodyRef}
    >
      {(dashboardPayload, state) => {
        if (dashboardPayload.answers.length)
          return (
            <AnswersList
              key={state.attendeeRole}
              bodyRef={bodyRef}
              answers={dashboardPayload.answers as OpenQuestionAnswer[]}
            />
          );
        return <EmptyAnswers attendeeRole={state.attendeeRole} canBeRepliedTo={canBeRepliedTo} />;
      }}
    </QuestionCard>
  );
}

interface AnswersListProps {
  answers: OpenQuestionAnswer[];
  bodyRef: MutableRefObject<any>;
}
function AnswersList({ answers, bodyRef }: AnswersListProps) {
  const rowVirtualizer = useVirtualizer({
    count: answers.length,
    getScrollElement: () => bodyRef.current,
    estimateSize: () => 100,
    overscan: 2,
  });

  return (
    <div
      className={styles.answersContainer}
      style={{
        height: `${rowVirtualizer.getTotalSize()}px`,
      }}
    >
      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
        const answer = answers[virtualRow.index];
        return (
          <div
            className={styles.answerContainer}
            key={`${virtualRow.index}_${answer.user.attendeeRole}`}
          >
            <div
              className={cx(styles.answer, {
                [styles.last]: virtualRow.index === answers.length - 1,
              })}
            >
              <div className={styles.header}>
                <DSAvatar
                  initials={answer.user.initials}
                  tooltip={answer.user.fullName}
                  tooltipDirection="right"
                  randomColorSeedString={answer.user.uuid}
                />
                <div className={styles.label}>
                  <div className={styles.name}>{answer.user.fullName}</div>
                  <div className={styles.role}>{formatInterviewRole(answer)}</div>
                </div>
              </div>
              <div className={styles.content}>{answer.content}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

interface EmptyAnswersProps {
  attendeeRole: InterviewActiveAttendeeRoles;
  canBeRepliedTo: boolean;
}
function EmptyAnswers({ attendeeRole, canBeRepliedTo }: EmptyAnswersProps) {
  const roleName = useMemo(() => {
    switch (attendeeRole) {
      case InterviewRoles.MANAGER:
        return "responsable d'entretien";
      default:
        return "collaborateur";
    }
  }, [attendeeRole]);

  return (
    <div className={styles.emptyAnswers}>
      {canBeRepliedTo ? (
        <div>
          <img className={styles.pendingImg} src={pendingImg} alt="aucune réponse" />
          <div className={styles.message}>
            Aucun {roleName} n'a répondu à cette question pour le moment.
          </div>
        </div>
      ) : (
        <div>
          <img className={styles.crossImg} src={crossImg} alt="aucune réponse" />
          <div className={styles.message}>Cette question n'accepte pas de réponse</div>
        </div>
      )}
    </div>
  );
}

function formatInterviewRole({
  user,
  interview,
}: {
  user: {
    attendeeRole: InterviewActiveAttendeeRoles;
  };
  interview: {
    employee: {
      fullName: string;
    };
  };
}) {
  if (user.attendeeRole === InterviewRoles.MANAGER) {
    return `Responsable d'entretien de ${interview.employee.fullName}`;
  }

  return `Collaborateur`;
}

export default OpenEndedQuestion;
