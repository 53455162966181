import React, { useCallback, useMemo, useRef } from "react";
import ReactSelect, { components, type SelectComponentsConfig } from "react-select";
import ReactCreatable from "react-select/creatable";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import { Checkbox } from "components/Form/Checkbox/Checkbox";

import styles from "./Select.module.scss";
import { PlaceHolderContainer, ChipValueContainer } from "./partials/ValueContainer";
import {
  type SelectOption,
  type SelectOptionEqualityFn,
  type SelectOptions,
  SelectProps,
  SelectPropsWithoutTheme,
  MenuPlacement,
} from "./type";
import { useReactSelectTheme } from "./partials/theme";
import { Close, ExpandMore } from "components/MaterialIcons";
import { SelectItem } from "./partials/SelectItem";

export { SelectProps, SelectPropsWithoutTheme, MenuPlacement };

const customComponents: SelectComponentsConfig<SelectOption<any>, boolean, any> | undefined = {
  DropdownIndicator: (dropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...dropdownIndicatorProps}>
        <ExpandMore size={"1.5rem"} />
      </components.DropdownIndicator>
    );
  },
  ClearIndicator: (clearIndicatorProps) => {
    return (
      <components.ClearIndicator {...clearIndicatorProps}>
        <Close />
      </components.ClearIndicator>
    );
  },
  Option: (optionProps) => {
    const { theme } = optionProps.selectProps;

    return (
      <components.Option {...optionProps}>
        <SelectItem
          theme={theme as any}
          label={optionProps.label}
          isSelected={optionProps.isSelected}
        />
      </components.Option>
    );
  },
};

const multiCustomComponents = {
  ...customComponents,
  MenuList: (menuListprops) => {
    const { dsTheme, canSelectAll, selectAllLabel } = menuListprops.selectProps;
    const { t } = useTranslation();

    const checked = useMemo(() => {
      const allSelected = menuListprops.getValue().length === menuListprops.options.length;
      if (!allSelected && menuListprops.getValue().length > 0) {
        return Checkbox.STATE_INDETERMINATE;
      }
      return allSelected;
    }, [menuListprops]);

    const onSelectAll = useCallback(
      (checked) => {
        if (checked) {
          menuListprops.setValue(menuListprops.options, "select-option");
        } else {
          menuListprops.setValue(
            menuListprops.options.map(({ value }) => value),
            "deselect-option"
          );
        }
      },
      [menuListprops.options]
    );
    return (
      <components.MenuList {...menuListprops}>
        <>
          {canSelectAll && !menuListprops.selectProps?.inputValue && (
            <Checkbox
              name="multi"
              label={
                selectAllLabel ||
                t("select.all", {
                  defaultValue: "Tout selectionner",
                })
              }
              theme={dsTheme}
              checked={checked}
              className={styles.checkbox}
              onChange={onSelectAll}
              clickable
            />
          )}
          {menuListprops.children}
        </>
      </components.MenuList>
    );
  },
  Option: (optionProps) => {
    const { dsTheme, canSelectAll } = optionProps.selectProps;

    return (
      <components.Option {...optionProps}>
        <Checkbox
          name={`multi.item.${optionProps.value?.toString()}`}
          label={optionProps.label}
          theme={dsTheme}
          checked={!!optionProps.getValue().find((val) => optionProps.label === val.label)}
          className={cx(styles.checkbox, {
            [styles.withAll]: canSelectAll && !optionProps.selectProps?.inputValue,
          })}
        />
      </components.Option>
    );
  },
  ValueContainer: (valueContainerProps) =>
    valueContainerProps.getValue().length === 0 ? (
      <PlaceHolderContainer<any>
        valueContainerProps={valueContainerProps}
        selectProps={valueContainerProps.selectProps}
      />
    ) : (
      <ChipValueContainer<any>
        valueContainerProps={valueContainerProps}
        selectProps={valueContainerProps.selectProps}
        handleSelect={valueContainerProps.selectProps.onChange}
      />
    ),
};

function Select<T>(props: SelectProps<T>) {
  const {
    "aria-label": ariaLabel,
    className,
    defaultValue,
    options,
    onChange,
    onInputChange,
    theme,
    value,
    multi,
    canSelectAll,
    disabled,
    isSearchable,
    clearable,
    placeholder,
    menuPlacement = MenuPlacement.AUTO,
    minMenuHeight = 140,
    maxMenuHeight = 300,
    noOptionsMessage,
    equalFn,
    autoFocus,
    createIfInexistent,
    createLabel,
    paginationCustomStyle: customStyle, // do no use in standard ui system
  } = props;
  const selectRef = useRef<HTMLInputElement>(null);

  const { theme: RStheme, styles: RSstyles } = useReactSelectTheme(props);

  const selectedOption = useMemo(() => {
    if (!options) return undefined;
    if (value === undefined) return undefined;
    if (value === null) return null;
    if (multi) return value?.map((val) => findOptionByValue(val, options, equalFn));
    return findOptionByValue(value, options, equalFn);
  }, [options, value, multi]);

  const currentComponents = !multi ? customComponents : multiCustomComponents;

  const handleSelect = useCallback(
    (option) => {
      if (!onChange || disabled) return;
      if (multi) onChange(option?.map((opt: SelectOption<T>) => opt.value) ?? []);
      else onChange(option?.value);
      // focus on nextTick
      setTimeout(() => selectRef.current?.focus());
    },
    [multi, onChange, disabled]
  );

  const reactComponent = {
    select: ReactSelect,
    creatable: ReactCreatable,
  };

  const SpecificComponent: ReactSelect | ReactCreatable = createIfInexistent
    ? reactComponent["creatable"]
    : reactComponent["select"];

  return (
    <SpecificComponent
      {...props}
      // @ts-expect-error ref type
      ref={selectRef}
      className={cx(className, styles.Select, styles[theme], {
        [styles.disabled]: disabled,
        [styles.customWidth]: customStyle?.width,
        [styles.customHeight]: customStyle?.height,
      })}
      defaultValue={defaultValue}
      aria-label={ariaLabel}
      tabIndex={disabled ? -1 : undefined}
      isSearchable={isSearchable}
      theme={RStheme}
      menuPortalTarget={document.body}
      styles={RSstyles}
      components={currentComponents}
      value={selectedOption}
      options={options}
      isMulti={multi}
      canSelectAll={canSelectAll}
      dsTheme={theme}
      closeMenuOnSelect={!multi}
      hideSelectedOptions={false}
      onChange={handleSelect}
      onInputChange={onInputChange}
      autoFocus={autoFocus}
      isClearable={clearable}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      minMenuHeight={minMenuHeight}
      maxMenuHeight={maxMenuHeight}
      noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : undefined}
      formatCreateLabel={(inputValue) =>
        createIfInexistent && (
          <div className={styles.createLabel}>
            {createLabel} {inputValue}
          </div>
        )
      }
    />
  );
}
function findOptionByValue<T>(
  value: T,
  options: SelectOptions<T>,
  equalFn: SelectOptionEqualityFn<T> = (a, b) => a === b
) {
  for (const item of options) {
    if ("options" in item) {
      const groupOptions = item.options;
      const selectedGroupOption = groupOptions.find((option) => equalFn(option.value, value));
      if (selectedGroupOption) return selectedOption(selectedGroupOption);
    } else if (equalFn(item.value, value)) return selectedOption(item);
  }
  return;
}
function selectedOption<T>(option: SelectOption<T>) {
  const { selectedLabel, label, value } = option;
  return {
    value,
    label: selectedLabel ?? label,
  };
}

export type SelectOptionWithGroup<T, U> = {
  option: T;
  group: U;
};
export function optionWithGroup<T, U>(option: T, group: U) {
  return { option, group };
}
export function optionWithGroupEquality<T, U>(
  value1: SelectOptionWithGroup<T, U>,
  value2: SelectOptionWithGroup<T, U>
) {
  return value1.option === value2.option && value1.group === value2.group;
}

export { Select };
