import { TemplatesRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import { Template } from "../useTemplates";

async function duplicateTemplate(fromTemplate: Template): Promise<string | undefined> {
  try {
    const data = {
      ...fromTemplate,
      uuid: undefined,
      title: `${fromTemplate.title} (copie)`,
      type: fromTemplate.type,
    };

    const request = buildRequest<TemplatesRoutes.Create>({
      method: "POST",
      path: "/templates",
      payload: {
        data: JSON.stringify(data),
        targetsCategories: fromTemplate.targetsCategories, // [TCY-1] retrocompatibility
        targetCategories: fromTemplate.targetCategories,
      },
    });

    const { templateUuid } = await request();
    return templateUuid;
  } catch (err) {
    throw new Error(err);
  }
}

export { duplicateTemplate };
