import { DSButton, Modal, Select, SelectProps } from "@skillup/ui";

import { Tracking } from "../../../state/tracking";

import styles from "./UpdateStateModal.module.scss";
import { useState } from "react";
import { TrackingRoutes } from "@skillup/espace-rh-api/src/routes/tracking";
import { Either, Future } from "@skillup/monads";
import { buildSafeRequest } from "utils/buildRequest";
import { useToasts } from "react-toast-notifications";
import { formatError } from "services/errors";
import useTranslation from "hooks/useTranslation";
import { useToggle } from "react-use";

export enum TrackingState {
  PENDING = "pending",
  WON = "won",
  REALIZED = "realized",
}

export interface Props {
  tracking: Tracking;
  onClose: () => void;
}

type Payload = TrackingRoutes.UpdateState["payload"];

const selectOptions: SelectProps<Payload["state"]>["options"] = [
  {
    value: "pending",
    label: "À inscrire",
  },
  {
    value: "won",
    label: "Inscrit(e)",
  },
  {
    value: "realized",
    label: "Réalisé",
  },
];

const updateTrackingState = async (
  trackingUuid: string,
  state: `${TrackingState}`
): Promise<
  Either<TrackingRoutes.UpdateState["errors"], TrackingRoutes.UpdateState["response"]>
> => {
  const request = await buildSafeRequest<TrackingRoutes.UpdateState>({
    method: "POST",
    path: "/tracking/{trackingUuid}/update-state",
    params: {
      trackingUuid,
    },
    payload: {
      state: state ?? null,
    },
  });

  return request.run();
};

const UpdateStateModal = ({ tracking, onClose }: Props) => {
  const [isLoading, setIsLoading] = useToggle(false);
  const [state, setState] = useState<`${TrackingState}`>(tracking.associatedRowState);

  const { addToast } = useToasts();
  const { t } = useTranslation();

  const submitRequest = async () => {
    setIsLoading(true);
    const request = updateTrackingState(tracking.uuid, state);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Une erreur est survenue lors de la modification de l'état",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      () => {
        addToast(
          t("tracking.associatedRowState.update.success", {
            defaultValue: "État modifié avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );

    setIsLoading(false);
  };

  return (
    <Modal size="big" title={"Modifier l'état"} onClose={onClose} disableOnClickAway>
      <div className={styles.updateStateModal}>
        <Select
          className={styles.input}
          placeholder={"Sélectionnez un état"}
          value={state}
          options={selectOptions}
          onChange={(value) => setState(value)}
          clearable
          autoFocus
          isSearchable={false}
        />
        <div className={styles.actions}>
          <DSButton label="Annuler" emphasis="Low" onClick={onClose} />
          <DSButton loading={isLoading} label="Confirmer" emphasis="High" onClick={submitRequest} />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateStateModal;
