import { useToasts } from "react-toast-notifications";

import { DSDropdown, DSDropdownItem, DSTooltip } from "@skillup/ui";
import { GridRowParams, GridRowSelectionModel } from "@mui/x-data-grid-pro";

import { type ColdReview, type HotReview, ReviewStatus } from "@skillup/training-bridge";
import useTranslation from "hooks/useTranslation";

import DataLayer from "utils/DataLayer";
import copyUrl from "../utils/copyUrl";
import { ReviewRow } from "../utils/types";

import styles from "./ActionsCell.module.scss";

const ActionsCell = (
  props: GridRowParams<ReviewRow<HotReview | ColdReview>> & {
    refetch: () => void;
    selectedRows: GridRowSelectionModel;
  }
) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const {
    row: {
      data: { status, url },
      id,
    },
    selectedRows,
    refetch,
  } = props;

  const sendTraineeRecallMail = async (): Promise<void> => {
    try {
      await DataLayer.request({
        target: "API",
        method: "POST",
        url: "/v1/review/trainee-recall",
        body: JSON.stringify([id]),
      });

      refetch();

      switch (status) {
        case ReviewStatus.PENDING:
          addToast(
            t("trainings.manage.reviews.toast.review.reminder.sent", {
              defaultValue: "L'email de relance a bien été envoyé",
            }),
            {
              appearance: "success",
              autoDismiss: true,
            }
          );
          break;
        case ReviewStatus.ABANDONED:
        case ReviewStatus.CANCELLED:
        case ReviewStatus.SUBMITTED:
          addToast(
            t("trainings.manage.reviews.toast.review.invitation.sent", {
              defaultValue: "L'email a bien été envoyé",
            }),
            {
              appearance: "success",
              autoDismiss: true,
            }
          );
      }
    } catch (err) {
      addToast(
        t("trainings.manage.reviews.toast.review.invitation.error", {
          defaultValue: "L'email n'a pas pu être envoyé",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
  };

  const existInSelection = selectedRows.some((row) => row === id);
  const calledReview = status !== ReviewStatus.COMPLETED && status !== ReviewStatus.PENDING;

  return (
    <DSTooltip
      disabled={status !== ReviewStatus.COMPLETED}
      tooltipClassName={styles.DSTooltip}
      label={t("trainings.manage.reviews.sidepanel.actions.alreadyCompleted", {
        defaultValue: "Cette évaluation a déjà été complétée",
      })}
    >
      <DSDropdown
        buttonSize="S"
        overflow="initial"
        disabled={status === ReviewStatus.COMPLETED || existInSelection}
      >
        {calledReview && (
          <DSDropdownItem
            label={t("trainings.manage.reviews.sidepanel.actions.sendRecallMail", {
              defaultValue: "Envoyer un email de relance",
            })}
            onClick={sendTraineeRecallMail}
          />
        )}
        {status === ReviewStatus.PENDING && (
          <DSDropdownItem
            label={t("trainings.manage.reviews.sidepanel.actions.invitToReview", {
              defaultValue: "Inviter à évaluer",
            })}
            onClick={sendTraineeRecallMail}
          />
        )}
        <DSDropdownItem
          label={t("trainings.manage.reviews.sidepanel.actions.copyUrl", {
            defaultValue: "Copier l'url du questionnaire d'évaluation",
          })}
          onClick={() => copyUrl(addToast, t, url)}
        />
      </DSDropdown>
    </DSTooltip>
  );
};

export default ActionsCell;
