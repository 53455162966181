import { FieldKey, DatasetsRoutes } from "@skillup/core-hr-bridge";

import { buildRequest } from "utils/buildRequest";

export async function getRecordsAtDate({
  employeeId,
  fieldsKeys,
  date,
}: {
  employeeId: string;
  fieldsKeys: FieldKey[];
  date: string;
}): Promise<DatasetsRoutes["GetEmployeeFieldValueAtDateRoute"]["response"]> {
  try {
    return await buildRequest<DatasetsRoutes["GetEmployeeFieldValueAtDateRoute"]>({
      method: "GET",
      path: `/datasets/get-employee-field-value-at-date/{employeeId}`,
      params: { employeeId },
      query: {
        fieldKeys: fieldsKeys,
        date,
      },
      target: "CORE_HR",
    })();
  } catch (err) {
    throw new Error("Error fetching records at date");
  }
}
