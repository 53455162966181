import React, { useState, useEffect, useRef } from "react";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import InteractiveButton from "components/InteractiveButton";

import styles from "./PortalCustomLabels.module.scss";

async function getLabels(): Promise<any> {
  const labels = await DataLayer.request({
    url: "/v1/portal/labels",
  });

  return labels;
}

async function saveLabels(values) {
  const labels = await DataLayer.request({
    url: "/v1/portal/labels",
    method: "POST",
    body: JSON.stringify(values),
  });

  return labels;
}

export default function PortalCustomLabels() {
  const [labels, setLabels] = useState();
  const [isPatching, setIsPatching] = useState<boolean>(false);

  const refs = {
    portalListsLabel: useRef<HTMLInputElement>(null),
  };

  useEffect(() => {
    async function fetchLabels() {
      const list = await getLabels();
      setLabels(list);
    }

    fetchLabels();
  }, []);

  async function handleSubmit() {
    if (isPatching) {
      return Acta.dispatchEvent("sendAppMessage", {
        message: "Un enregistrement est déjà en court",
        type: "message",
      });
    }
    try {
      setIsPatching(true);
      const values = {
        portalListsLabel: refs.portalListsLabel?.current?.value,
      };

      const newLabels = await saveLabels(values);
      // @ts-ignore
      setLabels(newLabels);
      Acta.dispatchEvent("sendAppMessage", {
        message: "Labels mis à jour avec succès",
        type: "success",
      });
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Echec de la mise à jour des labels",
        type: "error",
      });
    } finally {
      setIsPatching(false);
    }
  }

  return (
    <div className={styles.portalCustomLabels}>
      <header>Labels personnalisés</header>
      <div className={styles.labelsList}>
        <div>
          <label htmlFor="portalListsLabels">Formations recommandées</label>
          <input
            ref={refs.portalListsLabel}
            id="portalListsLabels"
            // @ts-ignore
            key={labels?.portalListsLabel}
            // @ts-ignore
            defaultValue={labels?.portalListsLabel}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <InteractiveButton
          label="Enregistrer"
          onClick={handleSubmit}
          style={{ margin: "none", marginRight: 25 }}
        />
      </div>
    </div>
  );
}
