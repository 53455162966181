import React, { useCallback, useState } from "react";
import { useMount, useUnmount } from "react-use";
import { uniqueId } from "lodash";

import Acta from "utils/Acta";
import Message, { IMessageProps } from "./Message";

import styles from "./styles.module.scss";

/**
 * To send a message from anywhere in the app,
 * dispatch an Acta event like follows :
 * Acta.dispatchEvent(
 *    'sendAppMessage', {
 *      title: 'title of the message',
 *      message: 'message content',
 *      type:'success',
 *      duration: 3000,
 *    } )
 *
 * @param {String} title - optionnal - title of the message
 * @param {String} message - mandatory - content of the message
 * @param {String} type - optionnal - default : 'info'
 *    - will set the color and sound of the message [ 'info', 'success', 'warning', 'error' ]
 * @param {Number} duration - optionnal - default : 2500 - duration in ms before destroy
 * @param {Boolean} manualCloseOnly - optionnal - is set to true, the message will not self destroy
 */

const AppMessages = () => {
  const [messages, setMessages] = useState<IMessageProps[]>([]);

  const closeMessage = useCallback(
    (messageKey: string): void => {
      return setMessages(messages.filter((m) => m.messageKey !== messageKey));
    },
    [messages, setMessages]
  );

  useMount(() => {
    Acta.subscribeEventWithoutContext(
      "sendAppMessage",
      (message: IMessageProps) => {
        setMessages(messages.concat({ ...message, messageKey: uniqueId("message-") }));
      },
      "AppMessages"
    );
  });

  useUnmount(() => {
    Acta.unsubscribeEventWithoutContext("sendAppMessage", "AppMessages");
  });

  return (
    <div className={styles.AppMessages}>
      {messages.map((message) => (
        <Message key={message.messageKey} selfDelete={closeMessage} {...message} />
      ))}
    </div>
  );
};

export default AppMessages;
