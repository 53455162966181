import React, { useEffect, useState } from "react";

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import { Icon } from "@skillup/ui";

import styles from "./List.module.scss";

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef } = useSortable({
    id: props.id,
  });

  return (
    <div ref={setNodeRef} className={styles.value}>
      <button {...listeners} {...attributes}>
        {props.index + 1}. {props.id}
      </button>
    </div>
  );
}

const List = ({ toList, onChange }: { toList: string[]; onChange: (values: string[]) => void }) => {
  const [items, setItems] = useState(toList);

  useEffect(() => {
    setItems(toList);
  }, [toList, setItems]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((id, index) => (
          <div className={styles.List}>
            <SortableItem key={id} id={id} index={index} handle />
            <div className={styles.chevrons}>
              {index > 0 && (
                <button onClick={() => moveList(index, "up")} id={`chevronup${index}`}>
                  <Icon.ChevronUp width={16} />
                </button>
              )}
              {index < items.length - 1 && (
                <button onClick={() => moveList(index, "down")} id={`chevrondown${index}`}>
                  <Icon.ChevronDown width={16} />
                </button>
              )}
            </div>
          </div>
        ))}
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      majArray(oldIndex, newIndex);
    }
  }

  function moveList(index: number, direction: string) {
    const oldIndex = index;
    const newIndex = direction === "up" ? index - 1 : index + 1;
    majArray(oldIndex, newIndex);
  }

  function majArray(oldIndex: number, newIndex: number) {
    setItems(() => {
      const newArray = arrayMove(items, oldIndex, newIndex);

      onChange(newArray);
      return newArray;
    });
  }
};

export default List;
