import { createContext } from "react";

import { EnumerableField } from "services/coreHR";
import { CampaignDetails, CampaignSettings } from "services/peopleReview";

import { PeopleReviewViews, TPeopleReviewViews } from "../types";

interface CampaignMutations {
  start: () => Promise<void>;
  pause: () => Promise<void>;
  close: () => Promise<void>;
  delete: () => Promise<void>;
  resume: () => Promise<void>;
  export: (columnsToExport: string[]) => Promise<void>;
  employees: {
    arbitrate: (params: any) => any;
  };
  editSettings: (settings: CampaignSettings) => Promise<{ success: true }>;
}

export interface SupervisionContextProps {
  hasManagerPrep?: boolean;
  columnsToExport?: string[];
  currentView: TPeopleReviewViews;
  campaign: null | CampaignDetails;
  campaignMutations: CampaignMutations;
  setColumnsToExport: (columns: string[]) => void;
  enumerableFieldsMap: Record<string, EnumerableField>;
}

const defaultContextValue: SupervisionContextProps = {
  campaign: null,
  campaignMutations: {
    close: async () => {},
    delete: async () => {},
    editSettings: async (settings: CampaignSettings) => ({ success: true }),
    employees: {
      arbitrate: async () => {},
    },
    export: async () => {},
    pause: async () => {},
    resume: async () => {},
    start: async () => {},
  },
  columnsToExport: [],
  currentView: PeopleReviewViews.GRID,
  enumerableFieldsMap: {},
  hasManagerPrep: undefined,
  setColumnsToExport: () => {},
};

const SupervisionContext = createContext<SupervisionContextProps>(defaultContextValue);

export { SupervisionContext };
