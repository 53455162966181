import { useCallback } from "react";
import { useSelector } from "react-redux";

import { Label as DSLabel, DSButton, Flex, DSCheckbox } from "@skillup/ui";

import { selectHighlightUuid } from "../../../../../reducer";
import { Divided } from "../../ChildWrappers";
import { Permissions } from "../../../Permissions/Permissions";
import { QuestionChild } from "../Question";
import { BuilderFormInput } from "../../../BuilderFormInput";

import styles from "./TextQuestion.module.scss";

type TextQuestionChild = Extract<QuestionChild, { type: "text" }>;

type TextQuestionProps = {
  child: TextQuestionChild;
  updateTemplate: (data: QuestionChild) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
};

export const TextQuestion = ({ child, updateTemplate, indexes }: TextQuestionProps) => {
  const highlightUuid = useSelector(selectHighlightUuid);
  const handleChange = useCallback(
    (key: string, value: string) => {
      updateTemplate({ ...child, [key]: value });
    },
    [child, updateTemplate]
  );

  const isAnswerRequired = useCallback(
    (value: boolean) => {
      updateTemplate({ ...child, required: value });
    },
    [child, updateTemplate]
  );

  return (
    <Divided>
      <div className={styles.container}>
        <div>
          <BuilderFormInput
            type="textarea"
            value={child.label}
            onChange={(newValue) => handleChange("label", newValue)}
            debounceValue={300}
            label="Libellé de la question"
            name={`question-child-${child.uuid}-label`}
            required
            placeholder="Saisissez le libellé de la question"
            autoFocus={highlightUuid === child.uuid}
          />
        </div>
        <div className={styles.descriptionHelp}>
          {child.description !== undefined ? (
            <div>
              <div className={styles.labelAndButton}>
                <DSLabel label="Description de la question" />
                <DSButton
                  label={"Supprimer la description"}
                  className={styles.buttonUnderline}
                  onClick={() => handleChange("description", undefined)}
                  buttonSize="S"
                  emphasis="Low"
                />
              </div>
              <BuilderFormInput
                type="textarea"
                value={child.description}
                onChange={(newValue) => handleChange("description", newValue)}
                debounceValue={300}
                name={`question-child-${child.uuid}-description`}
                placeholder="Saisissez la description de la question"
              />
            </div>
          ) : (
            <DSButton
              label={"Ajouter une description"}
              className={styles.buttonUnderline}
              onClick={() => handleChange("description", "")}
              buttonSize="S"
              emphasis="Low"
            />
          )}
        </div>
        <div className={styles.descriptionHelp}>
          {child.replyPlaceholder !== undefined ? (
            <div>
              <div className={styles.labelAndButton}>
                <DSLabel label="Placeholder" />
                <DSButton
                  label={"Supprimer le placeholder"}
                  className={styles.buttonUnderline}
                  onClick={() => handleChange("replyPlaceholder", undefined)}
                  buttonSize="S"
                  emphasis="Low"
                />
              </div>
              <BuilderFormInput
                type="textarea"
                value={child.replyPlaceholder}
                onChange={(newValue) => handleChange("replyPlaceholder", newValue)}
                debounceValue={300}
                name={`question-child-${child.uuid}-replyPlaceholder`}
                placeholder="Donnez à vos collaborateurs des indications sur la façon de répondre à cette question"
              />
            </div>
          ) : (
            <DSButton
              label={"Ajouter un placeholder"}
              className={styles.buttonUnderline}
              onClick={() => handleChange("replyPlaceholder", "")}
              buttonSize="S"
              emphasis="Low"
            />
          )}
        </div>
      </div>
      <div>
        <Flex className={styles.permissions__action}>
          <DSCheckbox
            className={styles.permissions__actionChoice}
            label="Réponse requise"
            checked={child.required}
            onChange={isAnswerRequired}
          />
        </Flex>
        <Permissions
          key={child.uuid}
          indexes={indexes}
          kind={child.kind}
          uuid={child.uuid}
          order={["reply", "comment"]}
          restrictions={child.disallow}
          mandatory={
            child.required
              ? {
                  reply:
                    "La réponse à cette question étant requise, vous devez sélectionner au moins un participant qui devra répondre.",
                }
              : undefined
          }
        />
      </div>
    </Divided>
  );
};
