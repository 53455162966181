import { isEmpty, isNil } from "lodash";
import qs from "qs";
import { Future, Either } from "@skillup/monads";

import DataLayer, { TargetType } from "./DataLayer";
import { BasicRouteType } from "types/route";

function buildPath(
  path: BasicRouteType["path"],
  params: BasicRouteType["params"],
  target: TargetType
): string {
  let finalPath = `${target === "API" ? "/v1" : ""}${path}`;
  if (!isEmpty(params)) {
    for (const paramKey in params) {
      const value = params[paramKey];
      if (isNil(value)) {
        finalPath = finalPath.replace(`{${paramKey}}`, "");
        finalPath = finalPath.replace(`{${paramKey}?}`, "");
      } else {
        finalPath = finalPath.replace(`{${paramKey}}`, `${value}`);
        finalPath = finalPath.replace(`{${paramKey}?}`, `${value}`);
      }
    }
  }

  finalPath = finalPath.replace(/{.*?\?}/g, "");

  return finalPath;
}

function buildQueryString(query: BasicRouteType["query"] = {}): string {
  if (isEmpty(query)) {
    return "";
  }

  return qs.stringify(query, { addQueryPrefix: true, arrayFormat: "brackets" });
}

export const buildURL = ({
  params,
  path,
  query,
  target,
}: Pick<BasicRouteType, "params" | "path" | "query"> & { target: TargetType }) => {
  const finalPath = buildPath(path, params, target);
  const queryString = buildQueryString(query);

  return `${finalPath}${queryString}`;
};

export function buildRequest<Route extends BasicRouteType>({
  params,
  payload,
  path,
  method,
  query,
  target = "API",
}: Omit<Route, "response"> & { target?: TargetType }): () => Promise<Route["response"]> {
  const url = buildURL({ params, path, query, target });
  const body = payload instanceof FormData ? payload : JSON.stringify(payload);
  const contentType =
    payload instanceof FormData ? "multipart/form-data" : "application/json; charset=UTF-8";

  return async () =>
    DataLayer.request<Route>({
      method,
      contentType,
      body,
      url,
      target,
    });
}

type SafeRequest<Route extends BasicRouteType> = {
  run: () => Future<Route["errors"], Route["response"]>;
};

export function buildSafeRequest<Route extends BasicRouteType>({
  params,
  payload,
  path,
  method,
  query,
  target = "API",
}: Omit<Route, "response" | "errors"> & { target?: TargetType }): SafeRequest<Route> {
  const url = buildURL({ params, path, query, target });
  const body = payload instanceof FormData ? payload : JSON.stringify(payload);
  const contentType =
    payload instanceof FormData ? "multipart/form-data" : "application/json; charset=UTF-8";

  const run: SafeRequest<Route>["run"] = async () => {
    try {
      const response = await DataLayer.request<Route>({
        method,
        contentType,
        body,
        url,
        target,
      });

      return Either.Right(response);
    } catch (err) {
      const message = err.errorKey as Route["errors"];
      return Either.Left(message);
    }
  };

  return {
    run,
  };
}

type WithFile<T> = T & {
  file?: File;
};

export function buildFileRequest<Route extends BasicRouteType>({
  file,
  params,
  path,
  method,
  query,
  target = "API",
}: WithFile<Omit<Route, "response" | "errors">> & { target?: TargetType }): () => Promise<
  Route["response"]
> {
  let body = new FormData();
  if (["POST", "PUT"].includes(method)) {
    if (isNil(file)) {
      throw new Error("Please provide a file FormData");
    }

    body.append("file", file);
  }

  if (["GET", "DELETE"].includes(method) && !isNil(file)) {
    throw new Error("These methods are not meant to send files");
  }

  const url = buildURL({ params, path, query, target });

  return async () =>
    DataLayer.request<Route>({
      method,
      contentType: "multipart/form-data",
      body,
      url,
      target,
    });
}
