import {
  hourglass as hourglassIcon,
  clipboard as clipboardIcon,
  edit as editIcon,
  cross as crossIcon,
  check as checkIcon,
  chart as chartIcon,
} from "uiAssets/StrokeIcons";
import { school as schoolIcon } from "uiAssets/Icons";
import type { OrganizationRoutesType } from "types/api";

type Settings = OrganizationRoutesType.GetSettings["response"];

export interface ITab {
  readonly icon?: string;
  readonly iconColor?: string;
  readonly key: string;
  readonly label: string;
  readonly isVisible?: (settings?: Settings) => boolean;
}

interface ITabs {
  [k: string]: {
    [k: string]: ITab;
  };
}

const tabs: ITabs = {
  collection: {
    dashboard: {
      icon: chartIcon,
      key: "dashboard",
      label: "Dashboard",
    },
    "collection-validating": {
      icon: hourglassIcon,
      key: "collection-validating",
      label: "En attente de validation manager",
    },
    "collection-requalifying": {
      icon: clipboardIcon,
      key: "collection-requalifying",
      label: "À requalifier",
      isVisible: (settings) => settings?.portalHRSpecificTrainingEnabled ?? false,
    },
    "collection-pending": {
      icon: editIcon,
      iconColor: "#607D8B",
      key: "collection-pending",
      label: "À valider RH",
    },
    "collection-denied": {
      icon: crossIcon,
      iconColor: "#ff7979",
      key: "collection-denied",
      label: "Refusées",
    },
    "collection-all": {
      key: "collection-all",
      label: "Tout",
    },
  },
  plan: {
    dashboard: {
      icon: chartIcon,
      key: "dashboard",
      label: "Dashboard",
    },
    "plan-approved": {
      icon: editIcon,
      key: "plan-approved",
      label: "À inscrire",
    },
    "plan-positioning": {
      icon: hourglassIcon,
      key: "plan-positioning",
      label: "Choix de session en cours",
    },
    "plan-processing": {
      icon: checkIcon,
      key: "plan-processing",
      label: "Inscrit",
    },
    "plan-attended": {
      icon: schoolIcon,
      iconColor: "#59b148",
      key: "plan-attended",
      label: "Réalisées",
    },
    "plan-denied": {
      icon: crossIcon,
      iconColor: "#ff7979",
      key: "plan-denied",
      label: "Annulées",
    },
    "plan-all": {
      key: "plan-all",
      label: "Tout",
    },
  },
};

export default tabs;
