import dompurify from "dompurify";

let DOMPurify: dompurify.DOMPurifyI;
export function sanitizeHtml(html: string) {
  if (!DOMPurify) DOMPurify = dompurify(window);
  return DOMPurify.sanitize(html, {
    ADD_TAGS: ["iframe"],
    ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "scrolling", "target"],
  });
}
function injectSafeHtml(html: string) {
  return {
    dangerouslySetInnerHTML: {
      __html: sanitizeHtml(html),
    },
  };
}

export default injectSafeHtml;
