import { useEffect, useMemo } from "react";
import { debounce } from "lodash";

// Custom hook to debounce a function in structureBuilder without breaking DS components
export function useDebounce({
  debounceValue,
  onChange,
}: {
  debounceValue?: number;
  onChange?: (...args: any[]) => any;
}) {
  const debouncedOnChange = useMemo(
    () => (onChange ? debounce(onChange, debounceValue ?? 0) : undefined),
    [onChange, debounceValue]
  );

  useEffect(() => {
    debouncedOnChange?.cancel();
  }, [debouncedOnChange]);

  return debouncedOnChange;
}
