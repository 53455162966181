import { DSFormGroupTextInput, Flex, DSNumberInput } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import User from "utils/User";

import styles from "../Modal.module.scss";

const PriceInputs = ({ state, setPriceHT, setPriceTTC }) => {
  const { t } = useTranslation();

  return (
    <Flex className={styles.sessionPrices}>
      <DSFormGroupTextInput
        label={t("supervisor.sessions.creationModal.priceHT", {
          defaultValue: "Prix HT",
        })}
        required
      >
        <DSNumberInput
          min={0}
          step={0.01}
          key={state.price.type}
          className={styles.priceInput}
          value={state.price.value?.toString()}
          onChange={(value) => {
            setPriceHT(Number(value.replace("-", "")));
          }}
          placeholder="Ex: 100"
        />
      </DSFormGroupTextInput>

      {User.isCompanyWithTax() && (
        <DSFormGroupTextInput
          label={t("supervisor.sessions.creationModal.priceTTC", {
            defaultValue: "Prix TTC",
          })}
          required
        >
          <DSNumberInput
            min={0}
            step={0.01}
            className={styles.priceInput}
            value={state.priceWithTax?.toString()}
            onChange={(value) => {
              setPriceTTC(Number(value.replace("-", "")));
            }}
            placeholder="Ex: 100"
          />
        </DSFormGroupTextInput>
      )}
    </Flex>
  );
};

export default PriceInputs;
