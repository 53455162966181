function matchesSearch(text, search) {
  return String(text).toLowerCase().includes(String(search).toLowerCase());
}

export default function (rows, _id, filterValue) {
  return rows.filter((row) => {
    return (
      matchesSearch(row.values.trainingName, filterValue) ||
      matchesSearch(row.original.reference, filterValue)
    );
  });
}
