import DataLayer from "utils/DataLayer";
import { ListInterface } from "../types";
import { State } from "../components/PortalLists";

const removeList =
  (lists: ListInterface[], setState: (state: State) => void) => async (uuid: string) => {
    setState({
      lists: lists
        .filter((list) => list.properties.uuid !== uuid)
        .sort((listA, listB) => (listA.properties.index <= listB.properties.index ? -1 : 1))
        .map((list, index) => ({
          ...list,
          properties: { ...list.properties, index },
        })),
    });

    await DataLayer.request({
      method: "DELETE",
      url: `/v1/portal/training-list/${uuid}`,
    });
  };

export default removeList;
