import { Flex } from "@skillup/design-system";
import { DSProgressBar, DSTooltip, MaterialIcons, TypeProgressBar } from "@skillup/ui";
import { isString } from "lodash";
import { QueryOptions, useTargetsCompletion } from "../../hooks";
import styles from "./CompletionRate.module.scss";

const error_code_to_string = (code: string) => {
  switch (code) {
    case "error:uncompatible-ordinal-scales":
      return "Votre sélection contient des objectifs évalués sur des échelles non homogènes : il n'est donc pas possible d'afficher une synthèse des évaluations.";
    case "error:inconsistent-weights":
      return "Votre sélection contient des objectifs dont la pondération n’est pas définie : il n’est donc pas possible d’afficher un taux d’atteinte global.";
    default:
      return "Une erreur est survenue";
  }
};

export const CompletionRates = ({
  userUuid,
  queryOptions,
}: {
  userUuid: string;
  queryOptions: QueryOptions;
}) => {
  const { numeric, ordinal } = useTargetsCompletion({
    userUuid,
    queryOptions,
  });

  return (
    <Flex flexDirection="row" alignItems="left" gap="m" paddingBottom="l">
      <NumericCompletionRates numeric={numeric} />
      <OrdinalCompletionRates ordinal={ordinal} />
    </Flex>
  );
};

const NumericCompletionRates = ({ numeric }: { numeric?: string | number }) => {
  if (numeric === undefined) return null;

  return (
    <Flex
      flexDirection="column"
      paddingTop="s"
      paddingBottom="m"
      paddingHorizontal="m"
      gap="m"
      width="20rem"
      position="relative"
      className={styles.CompletionRate}
    >
      <DSTooltip
        className={styles.HelpTooltip}
        label={
          "Le taux d'atteinte global est calculé sur la base des évaluations réalisées par le manager uniquement"
        }
      >
        <MaterialIcons.Help />
      </DSTooltip>
      <Flex flexDirection="column" gap="xxs">
        <div className={styles.Title}>Objectifs quantitatifs</div>
        <div className={styles.Caption}>Taux d’atteinte global</div>
      </Flex>
      {isString(numeric) ? (
        <div className={styles.Message}>{error_code_to_string(numeric)}</div>
      ) : (
        <div className={styles.Numeric}>{Math.round(numeric)}&nbsp;%</div>
      )}
    </Flex>
  );
};

const OrdinalCompletionRates = ({ ordinal }: { ordinal?: string | Record<string, number> }) => {
  if (ordinal === undefined) return null;

  const max = Object.values(ordinal).reduce((acc, current) => acc + current, 0);

  return (
    <Flex
      flexDirection="column"
      paddingVertical="s"
      paddingHorizontal="m"
      gap="m"
      width="20rem"
      className={styles.CompletionRate}
    >
      <Flex flexDirection="column" gap="xxs">
        <div className={styles.Title}>Objectifs qualitatifs</div>
        <div className={styles.Caption}>Distribution des évaluations</div>
      </Flex>
      {isString(ordinal) ? (
        <div className={styles.Message}>{error_code_to_string(ordinal)}</div>
      ) : (
        <DSProgressBar
          darkMode={true}
          layout="list"
          type={TypeProgressBar.RAW}
          max={max}
          thin={true}
          labelFormatter={(label, value) => ""}
          valueFormatter={(label, value) => (
            <div className={`${styles.Ordinal} ${styles.Label}`}>
              <span className={styles.Bold}>{value}</span>/{max}{" "}
              <span className={styles.Bold}>{label}</span>
            </div>
          )}
          data={Object.entries(ordinal)
            .toReversed()
            .map(([label, value]) => ({
              label,
              value,
              color: "yellow",
            }))}
        />
      )}
    </Flex>
  );
};
