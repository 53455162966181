import React from "react";
import { match } from "react-router-dom";

import { ITrainingDataCall, IUser } from "@skillup/types";

import utils from "utils";
import { IWithRouter } from "utils/types";
import Acta from "utils/Acta";

import Breadcrumb from "components/Breadcrumb";
import Paging from "components/Paging";
import TrainingsList from "components/TrainingsList";
import RelatedFormationsLinks from "./components/RelatedFormationsLinks";
import Filters from "./components/Filters";
import filtersConstants from "constants/filters";
import DSLayout from "components/DSLayout";
import TrainingHeader from "components/TrainingHeader";
import Footer from "components/Footer";

import styles from "./TrainingsResultsStyles.module.scss";

interface IProps extends IWithRouter {
  formationsData: ITrainingDataCall;
  querySlug: string;
  userData: IUser;
  match: match<{ query?: string }>;
}

export default class TrainingsResults extends React.PureComponent<IProps, {}> {
  private container: React.RefObject<HTMLDivElement>;
  public state = {};

  constructor(props) {
    super(props);
    this.container = React.createRef<HTMLDivElement>();
  }

  public static getDerivedStateFromProps(props, state) {
    if (state.prevAlternatives !== props.alternatives) {
      Acta.setState("alternatives", props.alternatives);
      return { alternatives: props.alternatives };
    }

    return null;
  }

  public componentDidMount(): void {
    Acta.subscribeState("userData", (userData) => this.setState({ userData }), this);
  }

  public componentWillUnmount(): void {
    Acta.setState("alternatives", null);
  }

  public render(): JSX.Element {
    const { formationsData, location, match, history } = this.props;
    const {
      associated,
      children,
      filters,
      name,
      parents,
      properties,
      querySlug,
      trainingAggregations,
      trainings,
    } = formationsData as any;

    const currentFilters = utils.search.getFiltersFromQueryString(location.search);

    let displayName = name;
    const currentTag =
      (decodeURIComponent((location.search || "").replace("?", ""))
        .split("&")
        .map((el) => el.split("="))
        .find((el) => /tag/.test(el[0])) || [])[1] || null;

    if (Object.keys(match.params).includes("query")) {
      displayName = `Recherche : "${decodeURIComponent(match.params.query)}"`;
      if (currentTag) {
        displayName += ` > "${currentTag}"`;
      }
    }

    let breadcrumbLinks = [{ path: querySlug, name: displayName }];
    if (properties && parents && parents[0]) {
      breadcrumbLinks = parents[0]
        .map((item) => ({ path: item.seoSlug, name: item.name }))
        .reverse();
      if (properties.name) {
        breadcrumbLinks.push({ path: querySlug, name: properties.name });
      }
    }
    if (Object.keys(match.params).includes("query")) {
      breadcrumbLinks = [
        {
          path: location.pathname,
          name: decodeURIComponent(match.params.query),
        },
      ];
      if (currentTag) {
        breadcrumbLinks.push({
          path: location.pathname + location.search,
          name: currentTag,
        });
      }
    }

    let relatedFormationsLinks = [];
    if (associated && associated.length) {
      relatedFormationsLinks = [
        ...associated,
        {
          name: properties.name,
          trainingCount: trainings.total,
          seoSlug: properties.seoSlug,
          isCurrent: true,
        },
      ];
    }

    if (children && children.length) {
      relatedFormationsLinks = children.map((child) => ({
        ...child,
        trainingCount:
          // @ts-ignore
          (((formationsData.trainingAggregations || {}).tags || {})[child.name] || {}).doc_count ||
          1,
      }));
    }

    if (relatedFormationsLinks.length === 0) {
      const tags = trainingAggregations.tags || {};

      for (const tagKey of Object.keys(tags)) {
        const tag = tags[tagKey];
        if (tag.parents) {
          if (currentFilters.tags && currentFilters.tags.length) {
            const currentFilterTag = currentFilters.tags[0];
            if (!tags[currentFilterTag]) continue;

            if (!tags[currentFilterTag].startpoint || !tags[currentFilterTag].endpoint) {
              if (tags[currentFilterTag].endpoint) {
                if (!tag.parents.some((i) => tags[currentFilterTag].parents.includes(i))) {
                  continue;
                }
              } else if (!tag.parents.some((i) => i === currentFilterTag)) {
                continue;
              }
            }
          } else if (tag.parents.length) continue;
        }

        relatedFormationsLinks.push({
          name: tagKey,
          trainingCount: tag.doc_count,
          tagKey,
          seoSlug: `${match.params.query || querySlug}${utils.search.getQueryStringFromFilters({
            ...utils.search.getFiltersFromQueryString(location.search),
            tags: [tagKey],
            page: null,
          })}`,
        });
      }
    }
    relatedFormationsLinks = relatedFormationsLinks
      .filter((item) => item.trainingCount > 0)
      .sort((a, b) => b.trainingCount - a.trainingCount);

    // Will be used in the trainings list to display only the
    // training session after the closest date in the selected filters
    let sessionThreshold = null;
    if (currentFilters.sessionMonth && currentFilters.sessionMonth.length > 0) {
      const tempDates = currentFilters.sessionMonth
        .map((date) => new Date(date))
        .sort((a, b) => a.getTime() > b.getTime());
      sessionThreshold = tempDates[0];
    }

    const pagesLinks = [];
    if (trainings.total > 10) {
      for (let i = 1; i <= Math.ceil(trainings.total / 10); i += 1) {
        pagesLinks.push(
          `${location.pathname}${utils.search.getQueryStringFromFilters({
            ...currentFilters,
            page: i,
          })}`
        );
      }
    }

    const availableFilters = filters || {};
    if (!filters) {
      // @ts-ignore
      for (const filter of filtersConstants.possibleFilters) {
        availableFilters[filter] = true;
      }
    }

    return (
      <DSLayout
        title={displayName}
        parent={{
          title: "Catalogue Skillup",
          onClick: () => history.goBack(),
        }}
      >
        <div className={styles.TrainingsResults} ref={this.container}>
          <TrainingHeader />

          <div>
            {
              // @ts-ignore
              <Filters
                availableFilters={availableFilters}
                aggregations={trainingAggregations}
                filters={currentFilters}
                {...this.props}
              />
            }

            <main>
              <h1>{displayName}</h1>

              <Breadcrumb links={breadcrumbLinks} associated={associated} />

              <RelatedFormationsLinks
                links={relatedFormationsLinks}
                currentName={properties && properties.name}
                tags={currentFilters.tags || []}
                {...this.props}
              />

              {trainings.total === 0 ? (
                <p className={styles.noTraining}>Aucun résultat dans {displayName}</p>
              ) : (
                <TrainingsList
                  trainings={trainings.hits}
                  sessionThreshold={sessionThreshold}
                  {...this.props}
                />
              )}

              {Math.ceil(trainings.total / 10) > 1 && (
                <Paging
                  currentPage={currentFilters.page || 1}
                  links={pagesLinks}
                  className={styles.pagination}
                  container={this.container.current as HTMLElement}
                />
              )}
            </main>
          </div>
          <Footer />
        </div>
      </DSLayout>
    );
  }
}
