import DataLayer from "utils/DataLayer";
import qs from "qs";
import { IRoutes } from "@skillup/types";

export interface IFilters {
  durationGroup?: Array<string>;
  price?: number;
  hasSessions?: boolean;
  type: "inter" | "intra" | "list";
  isCPF?: boolean;
  place?: {
    label: string;
    lat: string;
    lon: string;
  };
  sessionMonth?: Array<string>;
  organization?: Array<string>;
  withSessions?: boolean;
  sessionType?: "distancial";
}
export type ITraining = IRoutes["/requalification"]["GET"]["/"]["usefulTypes"]["ITraining"];
export type ITrainingAggregations = IRoutes["/requalification"]["GET"]["/"]["response"]["trainingAggregations"];

const getTrainings = async (
  filters: IFilters,
  searchQuery?: string
): Promise<{
  trainings: Array<ITraining>;
  trainingAggregations: ITrainingAggregations;
}> => {
  const response = (await DataLayer.request({
    method: "GET",
    url: `/v1/requalification${
      searchQuery && searchQuery.length !== 0 ? `/${searchQuery}` : ""
    }?${qs.stringify(filters, { arrayFormat: "brackets" })}`,
  })) as IRoutes["/requalification"]["GET"]["/"]["response"];
  return response;
};

export default getTrainings;
