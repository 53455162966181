import { HTMLAttributes } from "react";

import { MaterialIcons } from "@skillup/ui";

import styles from "./ErrorBadge.module.scss";

interface ErrorChipProps extends HTMLAttributes<HTMLDivElement> {
  value: number;
  onClick?: (event: React.MouseEvent) => void;
}

function ErrorBadge(props: ErrorChipProps) {
  return (
    <div className={styles.badge} onClick={props.onClick}>
      <button className={styles.icon}>
        <MaterialIcons.Error />
        <span className={styles.value}> {props.value.toString()} </span>
      </button>
    </div>
  );
}

export { ErrorBadge };
