import React, { useEffect, useState } from "react";
import { useSetState } from "react-use";
import ReactTooltip from "react-tooltip";
import cx from "classnames";
import { IoIosInformationCircle, IoIosAddCircle, IoIosSettings, IoIosTrash } from "react-icons/io";
import { Toggle } from "@skillup/ui";
import { orderBy } from "lodash";

import type { OrganizationRoutesTypeUserGroup } from "types/api";
import { ListInterface, Group } from "../types";

import Loader from "components/Loader";
import Colors from "uiAssets/Colors";
import Acta from "utils/Acta";
import User from "utils/User";

import AddButton from "./AddButton";
import AddOrEditGroupModal from "./GroupModals/AddOrEditGroupModal";
import DeleteGroupModal from "./GroupModals/DeleteGroupModal";
import LoadingError from "./LoadingError";
import List from "./List";

/** Methods **/
import addList from "../actions/addList";
import removeList from "../actions/removeList";
import moveList from "../actions/moveList";
import getLists from "../actions/getLists";
import { buildRequest } from "utils/buildRequest";

import styles from "./PortalListsStyles.module.scss";
import AdminPortalToggle, {
  PortalToggleType,
} from "./AdminPortal/AdminPortalToggle/AdminPortalToggle";

export interface State {
  isLoading?: boolean;
  lists?: Array<ListInterface>;
  loadingError?: boolean;
  group?: string | null;
  groups?: Group[];
}

export interface Props {
  location?: Location;
  groups: Group[];
}

const PortalLists = (props: Props) => {
  const [{ isLoading, lists, loadingError, group, groups }, setState] = useSetState<State>({
    isLoading: true,
    lists: [],
    loadingError: false,
    group: null,
    groups: props.groups,
  });

  const [hasGroups, setHasGroups] = useState(groups && groups.length > 1);

  useEffect(() => {
    getLists(group, setState);
  }, [group, setState]);

  if (loadingError) return <LoadingError />;

  const selectedGroup = (groups || []).find((g) => g.uuid === group);

  const updateGroup = async (group, fields) => {
    setState({
      groups: groups.map((g) => (g.uuid === group.uuid ? { ...g, ...fields } : g)),
    });

    try {
      const newGroups = await buildRequest<OrganizationRoutesTypeUserGroup.Update>({
        method: "POST",
        path: "/organization/group/{userGroupUuid}/settings",
        params: { userGroupUuid: group.uuid },
        payload: fields,
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: "Sauvegardé.",
        type: "success",
      });
      setState({
        groups: [{ name: "Groupe par défaut", uuid: null }, ...newGroups],
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Échec d’enregistrement.",
        type: "error",
      });
      setState({
        groups: groups.map((g) => (g.uuid === group.uuid ? { ...g, ...fields } : g)),
      });
    }
  };

  const showAddOrEditGroupModal = (editedGroup?: Group) => {
    Acta.setState("modalDisplayed", {
      content: (
        <AddOrEditGroupModal
          editedGroup={editedGroup}
          groups={(groups || []).map((g) => g.name)}
          onSuccess={(newGroups) => {
            setState({
              groups: [{ name: "Groupe par défaut", uuid: null }, ...newGroups],
              group,
            });
          }}
        />
      ),
      size: "small",
      title: editedGroup ? "Editer le groupe" : "Ajouter un groupe",
    });
  };

  const showDeleteModal = (groupToDelete: Group) => {
    Acta.setState("modalDisplayed", {
      content: (
        <DeleteGroupModal
          groupUuid={groupToDelete.uuid}
          onSuccess={(newGroups) => {
            setState({
              groups: [{ name: "Groupe par défaut", uuid: null }, ...newGroups],
              group:
                selectedGroup && groupToDelete.uuid !== selectedGroup.uuid
                  ? selectedGroup.uuid
                  : null,
            });
          }}
        />
      ),
      size: "small",
      title: "Supprimer le groupe",
    });
  };

  return (
    <div className={styles.PortalLists}>
      {groups && groups.length === 1 && (
        <div style={{ marginLeft: "1rem" }}>
          <AdminPortalToggle
            label="Activer les groupes de collaborateurs"
            type={PortalToggleType.ENABLED}
            isSelected={hasGroups}
            onChange={() => setHasGroups(!hasGroups)}
          />
        </div>
      )}
      {((groups && groups.length > 1) || hasGroups) && (
        <div className={`${styles.card} ${styles.groups}`}>
          <header>
            <h2>Groupes de collaborateurs</h2>
            <div
              data-for="groupsID"
              data-tip={`<p>Vos collaborateurs sont répartis en groupes.</p>
                <p>Leurs portails sont différenciés en fonction de l'appartenance à ces derniers.</p>
                `}
            >
              <IoIosInformationCircle size={22} color={Colors.blue} />
              <ReactTooltip
                id="groupsID"
                className={styles.dataTip}
                effect="solid"
                place="left"
                multiline
                html
              />
            </div>
          </header>
          <div className={styles.content}>
            <div
              onClick={() => setState({ group: groups[0].uuid })}
              className={cx(styles.group, groups[0].uuid === group && styles.active)}
            >
              {groups[0].name}
            </div>

            {orderBy(groups.slice(1), (g) => g.name.toLowerCase(), "asc").map((g) => (
              <div
                key={g.uuid}
                onClick={() => setState({ group: g.uuid })}
                className={cx(styles.group, g.uuid === group && styles.active)}
              >
                {g.name}
                {User.isSkillupAdmin() && (
                  <div onClick={() => showAddOrEditGroupModal(g)}>
                    <IoIosSettings size={18} />
                  </div>
                )}
                {User.isSkillupDeveloper() && (
                  <div onClick={() => showDeleteModal(g)}>
                    <IoIosTrash size={18} />
                  </div>
                )}
              </div>
            ))}

            {User.isSkillupAdmin() && (
              <div className={styles.addGroup} onClick={() => showAddOrEditGroupModal()}>
                <IoIosAddCircle size={32} />
              </div>
            )}
          </div>
        </div>
      )}

      {selectedGroup?.uuid && (
        <div className={`${styles.card} ${styles.settings}`} key={selectedGroup.uuid}>
          <header>
            <IoIosSettings size={18} />
            <h2>Groupe {selectedGroup.name} - Paramètres</h2>
          </header>
          <div className={styles.content}>
            <div className={styles.toggle}>
              <Toggle
                active={selectedGroup.portalSearchDisabled}
                onToggle={(checked) => {
                  updateGroup(selectedGroup, { portalSearchDisabled: checked });
                }}
              />
              <label>
                Désactiver le champ de recherche de formations dans le portail du collaborateur
              </label>
            </div>
          </div>
        </div>
      )}

      {!isLoading ? (
        <>
          {lists
            .sort((listA, listB) => (listA.properties.index <= listB.properties.index ? -1 : 1))
            .map((list) => {
              return (
                <List
                  key={list.properties.uuid}
                  {...list.properties}
                  trainings={list.trainings}
                  listLength={lists.length}
                  moveList={moveList(lists, setState)}
                  removeList={removeList(lists, setState)}
                  {...props}
                />
              );
            })}
          <AddButton
            label="Ajouter une nouvelle liste de formations recommandées"
            onClick={addList(lists, group, setState)}
            styles={{ marginLeft: 20, marginBottom: 20, width: 370 }}
          />
        </>
      ) : (
        <>
          <Loader />
        </>
      )}
    </div>
  );
};

export default PortalLists;
