import { type CSSProperties } from "react";

import styled from "styled-components";

import { type SpacingTokenValues, translateDSSpacingToken } from "../utils";

export type GridInterface = Pick<
  CSSProperties,
  "width" | "height" | "overflow" | "maxWidth" | "gridArea" | "overflowY" | "minHeight"
> & {
  gap?: SpacingTokenValues;

  margin?: SpacingTokenValues;
  padding?: SpacingTokenValues;
  marginTop?: SpacingTokenValues;
  paddingTop?: SpacingTokenValues;

  marginLeft?: SpacingTokenValues;
  gridRowGap?: SpacingTokenValues;

  paddingLeft?: SpacingTokenValues;
  marginRight?: SpacingTokenValues;
  paddingRight?: SpacingTokenValues;
  marginBottom?: SpacingTokenValues;
  paddingBottom?: SpacingTokenValues;

  gridColumnGap?: SpacingTokenValues;
  marginVertical?: SpacingTokenValues;

  paddingVertical?: SpacingTokenValues;
  marginHorizontal?: SpacingTokenValues;
  paddingHorizontal?: SpacingTokenValues;
  gridTemplateRows?: "auto" | [number, number];
  gridTemplateColumns?: {
    columnWidth: string;
    columnNumbers: number | "auto-fit" | "auto-fill";
  };
};

const GridWrapper = styled.div<GridInterface>`
  display: grid;

  & {
    ${({ gridArea }) => gridArea && `grid-area: ${gridArea}`};

    ${({ gap }) => gap && `gap: ${translateDSSpacingToken(gap)}`};

    ${({ gridColumnGap }) =>
      gridColumnGap && `grid-column-gap: ${translateDSSpacingToken(gridColumnGap)}`};

    ${({ gridRowGap }) => gridRowGap && `grid-row-gap: ${translateDSSpacingToken(gridRowGap)}`};

    ${({ gridTemplateColumns }) =>
      gridTemplateColumns &&
      `grid-template-columns: repeat(${gridTemplateColumns.columnNumbers}, ${gridTemplateColumns.columnWidth})`};

    ${({ gridTemplateRows }) =>
      gridTemplateRows && `grid-template-rows: ` + gridTemplateRows === "auto"
        ? "auto"
        : gridTemplateRows
        ? `repeat(${gridTemplateRows[0]}, ${gridTemplateRows[1]}fr)`
        : ""};

    ${({ width }) => width && `width: ${width}`};

    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};

    ${({ height }) => height && `height: ${height}`};

    ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};

    ${({ marginVertical }) =>
      marginVertical &&
      `margin-top: ${translateDSSpacingToken(
        marginVertical
      )}; margin-bottom: ${translateDSSpacingToken(marginVertical)}`};

    ${({ marginHorizontal }) =>
      marginHorizontal &&
      `margin-right: ${translateDSSpacingToken(
        marginHorizontal
      )}; margin-left: ${translateDSSpacingToken(marginHorizontal)}`};

    ${({ marginTop }) => marginTop && `margin-top: ${translateDSSpacingToken(marginTop)}`};

    ${({ marginBottom }) =>
      marginBottom && `margin-bottom: ${translateDSSpacingToken(marginBottom)}`};

    ${({ marginLeft }) => marginLeft && `margin-left: ${translateDSSpacingToken(marginLeft)}`};

    ${({ marginRight }) => marginRight && `margin-right: ${translateDSSpacingToken(marginRight)}`};

    ${({ margin }) => margin && `margin: ${translateDSSpacingToken(margin)}`};

    ${({ paddingVertical }) =>
      paddingVertical &&
      `padding-top: ${translateDSSpacingToken(
        paddingVertical
      )}; padding-bottom: ${translateDSSpacingToken(paddingVertical)}`};

    ${({ paddingHorizontal }) =>
      paddingHorizontal &&
      `padding-right: ${translateDSSpacingToken(
        paddingHorizontal
      )}; padding-left: ${translateDSSpacingToken(paddingHorizontal)}`};

    ${({ paddingTop }) => paddingTop && `padding-top: ${translateDSSpacingToken(paddingTop)}`};

    ${({ paddingBottom }) =>
      paddingBottom && `padding-bottom: ${translateDSSpacingToken(paddingBottom)}`};

    ${({ paddingLeft }) => paddingLeft && `padding-left: ${translateDSSpacingToken(paddingLeft)}`};

    ${({ paddingRight }) =>
      paddingRight && `padding-right: ${translateDSSpacingToken(paddingRight)}`};

    ${({ padding }) => padding && `padding: ${translateDSSpacingToken(padding)}`};

    ${({ overflowY }) => overflowY && `overflow-y: ${overflowY}`};
  }
`;

export default GridWrapper;
