import { useEffect, useState } from "react";

import { generateModalContext } from "@skillup/ui";

import { useFetch } from "hooks";
import { cross } from "uiAssets/StrokeIcons";
import Icon from "components/Icon";
import Files from "components/Files";

import { PartialCancelParams } from "services/sessions/partialCancel";
import { PartialPostponeParams } from "services/sessions/partialPostpone";

import { Project } from "../../Actions/getProjects";
import UserSummonedModalV2 from "../../Modals/Resummon/UserSummonedModalV2";

import Summary from "../Summary";
import { CopyText } from "../CopyText";
import ManageUsersModalWorkflowStateContainer from "../ManageUsersModals";

import ParticipantsSection from "./ParticipantsSection";

import styles from "./SidePanel.module.scss";

export interface File {
  uuid: string;
  name: string;
  category: string;
  createdAt: {};
  type: string;
  key: string;
}

interface Props {
  project: Project;
  onClose: VoidFunction;
  refetchList: VoidFunction;
  isFetching: boolean;
  modalProps?: {
    isOpen: boolean;
    closeModal: VoidFunction;
    openModal: VoidFunction;
  };
  startSummoning: VoidFunction;
  openCancelModalV2: VoidFunction;
  actions: {
    partialCancel: (params: PartialCancelParams) => Promise<void>;
    partialPostpone: (params: PartialPostponeParams) => Promise<void>;
  };
}

const useUserSummonedModal = generateModalContext();

const SidePanel = ({
  project,
  onClose,
  refetchList,
  isFetching,
  startSummoning,
  openCancelModalV2,
  actions,
}: Props) => {
  const [categories, setCategories] = useState({});
  const { data: files, refetch: refetchFiles } = useFetch<File[]>({
    url: `/v1/projects/files/${project.properties?.uuid}`,
  });
  const userSummonedModal = useUserSummonedModal();
  const [manageUsersModalIsOpen, setManageUsersModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (files) {
      const orderedCategories = orderFilesByCategory(files);
      setCategories(orderedCategories);
    }
  }, [files]);

  const orderFilesByCategory = (files = []) =>
    files.reduce((acc, file) => {
      const { category } = file;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(file);

      return acc;
    }, {});

  return (
    <div className={styles.SidePanel}>
      <header>
        <button onClick={onClose}>
          <Icon strokeIcon={cross} width={20} />
        </button>
      </header>
      <Summary
        project={project}
        refetchList={refetchList}
        isFetching={isFetching}
        openCancelModal={openCancelModalV2}
        openSummonModal={startSummoning}
      />

      <ParticipantsSection
        project={project}
        refetchList={refetchList}
        setManageUsersModalIsOpen={setManageUsersModalIsOpen}
        userSummonedModal={userSummonedModal}
      />
      <Files
        className={styles.traineesLists}
        categories={categories}
        refetch={refetchFiles}
        trainingSessionUuid={project.properties.uuid}
      />
      {project.properties.reference && (
        <div className={styles.referenceBlock}>
          <label className={styles.reference}>{project.properties.reference}</label>
          <CopyText text={project.properties.reference} />
        </div>
      )}
      {project.summon && (
        <UserSummonedModalV2
          summon={project.summon}
          training={project.training}
          onClose={userSummonedModal.closeModal}
          isOpen={userSummonedModal.isOpen}
          sessionId={project.properties.reference || ""}
        />
      )}

      {manageUsersModalIsOpen && (
        <ManageUsersModalWorkflowStateContainer
          onClose={() => setManageUsersModalIsOpen(false)}
          project={project}
          actions={actions}
        />
      )}
    </div>
  );
};

export default SidePanel;