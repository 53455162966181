import { useCallback, useEffect, useState } from "react";
import { CampaignTemplate, CampaignsRoutes, TemplatesRoutes } from "@skillup/espace-rh-bridge";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { buildRequest } from "utils/buildRequest";
import { editStructure } from "./actions/editStructure";

// [CSB] switch to api type
type FormattedLogs = TemplatesRoutes.GetTemplates["response"]["templates"][0]["formattedLogs"];

export type Structure = CampaignTemplate & {
  companyUuid?: string;
  formattedLogs?: FormattedLogs;
  relatedCampaigns?: { uuid: string; title: string; archivedAt?: string }[];
  createdAt?: string;
  updatedAt?: string;
};

async function getStructure(campaignUuid: string) {
  try {
    const result = await buildRequest<CampaignsRoutes.GetStructure>({
      method: "GET",
      path: "/campaigns/{campaignUuid}/structure",
      params: { campaignUuid },
    })();

    return result;
  } catch (err) {
    return undefined;
  }
}

async function getCampaignTitle(campaignUuid: string) {
  try {
    const result = await buildRequest<CampaignsRoutes.GetByUuid>({
      method: "GET",
      path: "/campaigns/{campaignUuid}",
      params: { campaignUuid },
    })();

    return result.titleForHR;
  } catch (err) {
    return undefined;
  }
}

const defaultOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
};

export function useCampaign(campaignUuid: string) {
  const queryForStructure = useQuery(
    ["campaign", campaignUuid],
    async () => getStructure(campaignUuid),
    {
      ...defaultOptions,
    }
  );

  const [fetchedStructure, setFetchedStructure] = useState<Structure>(queryForStructure.data);

  useEffect(() => {
    setFetchedStructure(queryForStructure.data);
  }, [setFetchedStructure, queryForStructure.data]);

  const queryForCampaignTitle = useQuery(
    ["campaignTitle", campaignUuid],
    async () => getCampaignTitle(campaignUuid),
    {
      ...defaultOptions,
    }
  );

  const editMutation = useMutation(editStructure, {
    mutationKey: ["edit-structure", campaignUuid],
  });

  const queryClient = useQueryClient();

  const saveContent = useCallback(
    async ({ sections, campaignUuid }) => {
      const result = await editMutation.mutateAsync({
        sections,
        campaignUuid,
        structure: fetchedStructure,
      });
      await queryClient.invalidateQueries(["structure"]);
      setFetchedStructure(result);
      return result;
    },
    [editMutation, queryClient, fetchedStructure]
  );

  return {
    isLoading: queryForStructure.isLoading || queryForCampaignTitle.isLoading,
    isError: queryForStructure.isError || queryForCampaignTitle.isError,
    structure: fetchedStructure,
    campaignTitle: queryForCampaignTitle.data,
    saveContent,
  };
}
