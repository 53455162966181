import React, { useState, useCallback } from "react";
import { useToggle } from "react-use";

import { ScheduleRowOperationsRoute } from "@skillup/espace-rh-bridge";

import Colors from "uiAssets/Colors";
import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";
import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";

import styles from "./AssociateToFileModal.module.scss";

interface Props {
  rows: string[];
}

const AssociateToFileModal = ({ rows }: Props) => {
  const [loading, toggleLoading] = useToggle(false);
  const [fileReference, setFileReference] = useState<string>();

  const handleSubmit = useCallback(async (): Promise<void> => {
    toggleLoading(true);

    try {
      const body = { rows, fileReference };

      await buildRequest<ScheduleRowOperationsRoute.AssociateToFile>({
        method: "POST",
        path: "/scheduleRow/operations/associate-to-file",
        payload: body,
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: "Ligne mise à jour avec succès.",
        type: "success",
      });

      Acta.dispatchEvent("closeModal");

      window.location.href = window.location.href.replace("plan-approved", "plan-all");
    } catch (error) {
      console.error(error);
      Acta.dispatchEvent("sendAppMessage", {
        message: "La demande a échoué.",
        type: "error",
      });
    } finally {
      toggleLoading(false);
    }
  }, [toggleLoading, fileReference, rows]);

  return (
    <div className={styles.CancelModal}>
      <div>
        <p>
          Entrer ci-dessous la référence d'un dossier dont l'objet principal est un booking associé
          au(x) même(s) stagiaire(s). Ce mécanisme n'envoie aucun mail.
        </p>
        <TextInput
          autoComplete="off"
          alwaysOpen
          key="fileReference"
          label="Référence du dossier"
          name="fileReference"
          onChange={(_e, value) => setFileReference(value)}
          required
        />
      </div>
      <InteractiveButton
        label={"Associer"}
        onClick={handleSubmit}
        background={Colors.gradientBlue}
        style={{ fontWeight: 500 }}
        size="small"
        loading={loading}
      />
    </div>
  );
};

export default AssociateToFileModal;
