import { useMemo, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useBaseUrl } from "@skillup/hooks";

import Icon from "components/Icon";
import { userPortal as userPortalIcon } from "uiAssets/StrokeIcons";
import CatalogMenu from "components/CatalogMenu";
import SearchInput from "components/SearchInput";
import { analytics } from "services";
import Acta from "utils/Acta";
import User from "utils/User";

import styles from "./TrainingHeaderStyles.module.scss";

const TrainingHeader = () => {
  const { pathname } = useLocation();
  const { baseUrl, isAdminUrl } = useBaseUrl();
  const user = User.getUserData();
  const isPortalSearchDisabled = User.isPortalSearchDisabled();

  const portalTranslationDisplayed = useMemo(() => {
    return !isAdminUrl() && user.activeCompany.portalTranslationEnabled;
  }, [user, isAdminUrl]);

  const portalLogo = useMemo(() => {
    return baseUrl === "" ? user.activeCompany.portalLogo : null;
  }, [baseUrl, user]);

  const hasHomeButton = useMemo(() => {
    return baseUrl === "" && pathname !== "/portail";
  }, [baseUrl, pathname]);

  const hasCatalogAndSearch = useMemo(() => {
    return isAdminUrl() || !isPortalSearchDisabled;
  }, [isAdminUrl, isPortalSearchDisabled]);

  const toggleLanguage = useCallback(() => {
    const locale = Acta.getState("LOCALE");

    if (locale) {
      Acta.deleteState("LOCALE", "localStorage");
    } else {
      Acta.setState("LOCALE", "en", "localStorage");
    }
    window.location.reload();
  }, []);

  return (
    <nav className={styles.TrainingHeader}>
      <div>
        {portalLogo && (
          <img
            src={process.env.REACT_APP_PUBLIC_UPLOADS_URL + portalLogo}
            alt={user.activeCompany.name}
          />
        )}

        {hasCatalogAndSearch && <CatalogMenu />}

        {hasCatalogAndSearch && (
          <SearchInput onEnter={() => analytics.sendEvent("Portal", "Portal_Search_Bar_Top")} />
        )}

        {hasHomeButton && (
          <Link to="/portail" className={styles.home}>
            <Icon strokeIcon={userPortalIcon} width={16} />
            Portail
          </Link>
        )}

        {portalTranslationDisplayed && (
          <div className={styles.locale}>
            <button onClick={toggleLanguage} disabled={Acta.getState("LOCALE") !== "en"}>
              FR
            </button>
            <span>/</span>
            <button onClick={toggleLanguage} disabled={Acta.getState("LOCALE") === "en"}>
              EN
            </button>
          </div>
        )}
      </div>
    </nav>
  );
};

export default TrainingHeader;
