import React, { useState } from "react";
import cx from "classnames";

import { signError } from "uiAssets/Icons";
import { cross as crossIcon } from "uiAssets/StrokeIcons";
import Icon from "components/Icon";
import Colors from "uiAssets/Colors";

import styles from "./Error.module.scss";

interface IProps {
  readonly message: string;
  readonly className?: string;
  readonly hideMessage?: () => void;
}

const Error = ({ message, hideMessage, className }: IProps): JSX.Element | null => {
  const [iconColor, setIconColor] = useState<string>(Colors.errorL);

  return (
    <div className={cx(styles.UserImportError, className)}>
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <Icon className={styles.icon} icon={signError} fill={Colors.error} width={28} />
        </div>
        <div className={styles.info}>{message}</div>
      </div>
      {hideMessage && (
        <button
          className={styles.hideBtn}
          type="reset"
          onClick={hideMessage}
          onMouseEnter={() => setIconColor(Colors.mainColorL)}
          onMouseLeave={() => setIconColor(Colors.errorL)}
        >
          <Icon
            className={styles.hideIcon}
            strokeIcon={crossIcon}
            width={8}
            stroke={iconColor}
            strokeWidth={7}
          />
        </button>
      )}
    </div>
  );
};

export default Error;
