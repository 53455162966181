import { useState, useEffect } from "react";
import { loadFrillScript } from "../services/Frill/loadFrillScript";

/**
 * Frill is a third-party service that allows us show what's new
 * in the app to users.
 * It will show a small red dot on the logo. When clicked, it will
 * show a sidepanel with the new features.
 */
const useFrill = () => {
  const [isFrillUp, setupFrill] = useState<boolean>(false);

  useEffect(() => {
    if (!isFrillUp) {
      // @ts-expect-error Frill_Config is a global variable
      window.Frill_Config = window.Frill_Config || [];
      // @ts-expect-error Frill_Config is a global variable
      window.Frill_Config.push({ key: process.env.REACT_APP_FRILL_KEY, disableCookies: true });

      loadFrillScript(
        window,
        document,
        "https://widget.frill.co/v2/widget.js",
        process.env.REACT_APP_FRILL_KEY
      );

      setupFrill(true);
    }
  }, [isFrillUp]);

  return {
    isFrillUp,
  };
};

export default useFrill;
