import { useHistory } from "react-router-dom";

import { DSModal, DSAlert, DSAlertType, DSAlertDisplay } from "@skillup/ui";
import { type Template } from "services/interviews";
import { plural } from "utils/locale";

import styles from "./EditTemplateModal.module.scss";

interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeModal: () => void;
  unarchivedRelatedCampaignsNumber: number;
}

const EditTemplateModal = ({
  template,
  isOpen,
  closeModal,
  unarchivedRelatedCampaignsNumber,
}: IProps) => {
  const { push } = useHistory();

  const alertContent = (
    <p>
      {plural(
        unarchivedRelatedCampaignsNumber,
        "Cette trame a été utilisée pour lancer 1 campagne.",
        {
          pluralText: "Cette trame a été utilisée pour lancer %n campagnes.",
        }
      )}
      <br />
      <strong>
        {plural(unarchivedRelatedCampaignsNumber, "Cette campagne ne sera pas modifiée.", {
          pluralText: "Ces campagnes ne seront pas modifiées.",
        })}
      </strong>
    </p>
  );

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title="Modifier la trame" />
      </DSModal.Header>

      <DSModal.Content>
        <div className={styles.contentContainer}>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
            {alertContent}
          </DSAlert>
          <p>
            Les modifications que vous apporterez{" "}
            <strong>ne seront répercutées que sur les futures campagnes</strong> utilisant cette
            trame.
          </p>
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={closeModal} label="Annuler" />
        <DSModal.Footer.PrimaryButton
          label="Continuer"
          onClick={() => push(`/responsable/template/${template.uuid}`)}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export { EditTemplateModal };
