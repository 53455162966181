const check = "<polygon points='32,8 27,3 12,18 5,11 0,16 12,28'/>";
const chevronBottom = "<polygon points='16,18 4,6 0,10 16,26 32,10 28,6'/>";
const chevronLeft = "<polygon points='14,16 26,4 22,0 6,16 22,32 26,28'/>";
const chevronRight = "<polygon points='18,16 6,28 10,32 26,16 10,0 6,4'/>";
const chevronTop = "<polygon points='16,14 28,26 32,22 16,6 0,22 4,26'/>";
const collapseLeft =
  "<polygon points='3,16 15,4 18,7 11,13.9 32,13.9 32,18 11,18 18,25 15,28 '/><rect width='3' height='32'/>";
const collapseRight =
  "<polygon points='29,16 17,28 14,25 21,18.1 0,18.1 0,14 21,14 14,7 17,4 '/><rect x='29' width='3' height='32'/>";
const cross = "<polygon points='32,5 27,0 16,11 5,0 0,5 11,16 0,27 5,32 16,21 27,32 32,27 21,16'/>";
const fourSquares =
  "<rect width='13' height='13'/><rect x='19' width='13' height='13'/><rect y='19' width='13' height='13'/><rect x='19' y='19' width='13' height='13'/>";
const heartEmpty =
  "<path d='M30,11c0,10-14,17.7-14,17.7S2,21,2,11c0-4,4-8,7-8s7,5.3,7,5.3S20,3,23,3S30,7,30,11z M23,1c-4,0-7,4-7,4 s-3-4-7-4S0,6,0,11c0,11,16,20,16,20s16-9,16-20C32,6,27,1,23,1L23,1z'/>";
const heartFull =
  "<path d='M23,1c-4,0-7,4-7,4s-3-4-7-4S0,6,0,11c0,11,16,20,16,20s16-9,16-20C32,6,27,1,23,1L23,1z'/>";
const home =
  "<polygon points='16,0 31,11 31,16 16,5 1,16 1,11'/><polygon points='4,32 4,16 16,7 28,16 28,32 19,32 19,23 16,21 13,23 13,32'/>";
const mapPin =
  "<path d='M16,0C10,0,5,5,5,11c0,10,11,21,11,21s11-11,11-21C27,5,22,0,16,0z M16,16c-2.8,0-5-2.2-5-5s2.2-5,5-5s5,2.2,5,5 S18.8,16,16,16z'/>";
const minus = "<rect y='13' width='32' height='6'/>";
const plus =
  "<polygon points='32,13 19,13 19,0 13,0 13,13 0,13 0,19 13,19 13,32 19,32 19,19 32,19'/>";
const signError =
  "<path d='M23,0H9L0,9v14l9,9h14l9-9V9L23,0z M18,24h-4v-4h4V24z M14,18V9h4v9H14z'/>";
const signInfo = "<path d='M28,2H4L0,6v25l6-5h22l4-4V6L28,2z'/>";
const signSuccess =
  "<polygon points='27,10 19,10 21,4 18,1 9,12 9,29 25,29 32,16'/><rect y='12' width='6' height='17'/>";
const signWarning = "<path d='M16,1L0,29h32L16,1z M17,25h-2v-3h2V25z M15,20v-9h2v9H15z'/>";
const star = "<polygon points='16,0 21,11 32,13 24,21 26,32 16,27 6,32 8,21 0,13 11,11'/>";
const splitStar =
  '<polygon class="firstHalf" points="11,11 0,13 8,21 6,32 16,27 16,0"/><polygon class="secondHalf" style="fill: #ccc;" points="32,13 21,11 16,0 16,27 26,32 24,21"/>';
const time =
  "<path d='M16,0C7.2,0,0,7.2,0,16s7.2,16,16,16s16-7.2,16-16S24.8,0,16,0z M16,29C8.8,29,3,23.2,3,16S8.8,3,16,3s13,5.8,13,13 S23.2,29,16,29z'/><path d='M17,14.3V6h-2v8.3c-0.6,0.3-1,1-1,1.7c0,1.1,0.9,2,2,2c0.7,0,1.4-0.4,1.7-1H22v-2h-4.3C17.5,14.7,17.3,14.5,17,14.3z'/>";
const user = `<path d='M16,0C7,0,0,7,0,16s7,16,16,16s16-7,16-16S25,0,16,0z M16,5c3,0,5,2,5,5s-2,5-5,5s-5-2-5-5S13,5,16,5z M16,28
c-6,0-10-6-10-6s2-5,10-5s10,5,10,5S22,28,16,28z'/>`;
const phone = '<path d="M6,13c1,4,9,12,13,13l3-4l10,1v9C14,32,0,18,0,0h9l1,10L6,13z"/>';
const folder = '<path d="M29,25H3V8h26V25z M29,5H16l-4-3H3L0,5v20l3,3h26l3-3V8L29,5z"/>';
const calendar =
  '<path d="M29,29H3V11h26V29z M23,0v3H9V0H5v3H3L0,6v23l3,3h26l3-3V6l-3-3h-2V0 M26,18h-8v8h8V18z"/>';
const questionMark = `<path d="M13,27h5v5h-5V27z"/>
<path d="M18,24c0-4,8-8,8-14S22,0,16,0S6,4,6,10h5c0-3,2-5,5-5s5,2,5,5s-8,9-8,14H18z"/>`;
const logout =
  '<rect x="14" width="4" height="12"/><path d="M21,1v4c4,2,7,6,7,11c0,6.6-5.4,12-12,12S4,22.6,4,16c0-5,3-9,7-11V1C5,3,0,9,0,16c0,9,7,16,16,16s16-7,16-16 C32,9,28,3,21,1z"/>';
const chatBubbles =
  '<path d="M19,4v3L7,7l0,10H3l-1,1V4H19 M21,2H0v17v4l4-4h5L9,9l12,0V2L21,2z"/><path d="M30,13v12l-1-1H13V13H30 M32,11H11v15h17l4,4v-4V11L32,11z"/>';
const linkedin =
  '<rect y="10" width="8" height="22"/><path d="M4,8C2,8,0,6,0,4s2-4,4-4s4,2,4,4C8,6,6,8,4,8z"/><path d="M32,32h-7V20c0-1,0-5-3-5s-4,3-4,5v12h-7V10h7v3c0,0,3-4,7-4s7,4,7,8V32z"/>';
const school = '<path d="M16,3L0,12l16,9l13-7v9h3V12L16,3z M6,18v6l10,5l10-5v-6l-10,5L6,18z"/>';
const list =
  '<path d="M8,19H2l3-6L8,19z M8,3v6H2V3H8z M5,29c2,0,3-1,3-3s-1-3-3-3s-3,1-3,3S3,29,5,29z M11,8h20V4H11V8z M11,28h20v-4H11V28z M11,18h20v-4H11V18z"/>';
const tag =
  '<path d="M20,1L1,20l11,11l19-19V1H20z M26,8c-1,0-2-1-2-2s1-2,2-2s2,1,2,2S27,8,26,8z"/>';
const file = '<path d="M22,1H6L4,3v26l2,2h20l2-2V7L22,1z M21,9l-1-1V3l6,6H21z"/>';
const bin =
  '<path d="M13.2288 1.62712H9.42025C9.39042 1.28814 9.28602 0.716475 8.92859 0.349559C8.70242 0.117695 8.41551 0 8.07598 0H5.36439C5.02486 0 4.73795 0.117695 4.51178 0.349559C4.15435 0.716475 4.04995 1.28814 4.02012 1.62712H0.754219C0.604524 1.62712 0.483032 1.74834 0.483032 1.89831C0.483032 2.04827 0.604524 2.16949 0.754219 2.16949H1.30771L1.82703 14.6498C1.83707 15.1167 2.12778 16 3.15802 16H10.825C11.8552 16 12.1459 15.1167 12.1557 14.6555L12.6753 2.16949H13.2288C13.3785 2.16949 13.5 2.04827 13.5 1.89831C13.5 1.74834 13.3785 1.62712 13.2288 1.62712ZM4.55083 13.5593C4.55083 13.7093 4.42934 13.8305 4.27964 13.8305C4.12995 13.8305 4.00846 13.7093 4.00846 13.5593V4.61017C4.00846 4.4602 4.12995 4.33898 4.27964 4.33898C4.42934 4.33898 4.55083 4.4602 4.55083 4.61017V13.5593ZM7.26269 13.5593C7.26269 13.7093 7.1412 13.8305 6.99151 13.8305C6.84181 13.8305 6.72032 13.7093 6.72032 13.5593V4.61017C6.72032 4.4602 6.84181 4.33898 6.99151 4.33898C7.1412 4.33898 7.26269 4.4602 7.26269 4.61017V13.5593ZM9.97456 13.5593C9.97456 13.7093 9.85307 13.8305 9.70337 13.8305C9.55368 13.8305 9.43218 13.7093 9.43218 13.5593V4.61017C9.43218 4.4602 9.55368 4.33898 9.70337 4.33898C9.85307 4.33898 9.97456 4.4602 9.97456 4.61017V13.5593ZM4.9012 0.727051C5.02269 0.602847 5.17429 0.542373 5.36439 0.542373H8.07598C8.26608 0.542373 8.41768 0.602847 8.53917 0.727051C8.75747 0.950237 8.84507 1.34292 8.87544 1.62712H4.5652C4.59557 1.34292 4.68317 0.950237 4.9012 0.727051Z"/>';
const skills =
  '<path fill-rule="evenodd" clip-rule="evenodd" d="M20.4101 3.39711C20.4101 2.63216 19.7838 2 19.0113 2H17.0611C16.283 2 15.6623 2.62551 15.6623 3.39711V19.6029C15.6623 20.3678 16.2885 21 17.0611 21H19.0113C19.7894 21 20.4101 20.3745 20.4101 19.6029V3.39711ZM12.6801 5.79995C13.4527 5.79995 14.0789 6.41464 14.0789 7.20269V19.5972C14.0789 20.3719 13.4582 20.9999 12.6801 20.9999H10.73C9.9574 20.9999 9.33113 20.3853 9.33113 19.5972V7.20269C9.33113 6.42798 9.95182 5.79995 10.73 5.79995H12.6801ZM6.34899 9.60003C7.12154 9.60003 7.74781 10.2129 7.74781 10.9979V19.6022C7.74781 20.3742 7.12712 21 6.34899 21H4.39882C3.62627 21 3 20.3871 3 19.6022V10.9979C3 10.2259 3.62069 9.60003 4.39882 9.60003H6.34899Z" fill="#92929D"/>';

export {
  calendar,
  chatBubbles,
  check,
  chevronBottom,
  chevronLeft,
  chevronRight,
  chevronTop,
  collapseLeft,
  cross,
  file,
  folder,
  fourSquares,
  heartEmpty,
  heartFull,
  home,
  linkedin,
  list,
  logout,
  mapPin,
  minus,
  phone,
  plus,
  questionMark,
  school,
  signError,
  signInfo,
  signSuccess,
  signWarning,
  splitStar,
  star,
  tag,
  time,
  user,
  collapseRight,
  bin,
  skills,
};
