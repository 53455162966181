import React from "react";

import Acta from "utils/Acta";
import InteractiveButton from "components/InteractiveButton";

import styles from "./ConfirmEvaluationDeletionModal.module.scss";

interface IProps {
  onConfirm: () => void;
}

const ConfirmEvaluationDeletionModal = ({ onConfirm }: IProps) => {
  return (
    <div className={styles.confirmEvaluationDeletionModal}>
      <p>Êtes-vous sûr de vouloir supprimer le niveau de maîtrise attendu ?</p>
      <div className={styles.action}>
        <InteractiveButton label="Confirmer" onClick={onConfirm} />
      </div>
    </div>
  );
};

export const showConfirmEvaluationModal = (onConfirm: IProps["onConfirm"]) => {
  Acta.setState("modalDisplayed", {
    content: <ConfirmEvaluationDeletionModal onConfirm={onConfirm} />,
    size: "small",
    title: "Supprimer le niveau de maitrise attendu",
  });
};
