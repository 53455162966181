import React from "react";

import { useTheme } from "context/themes";
import Highcharts from "highcharts/highcharts";
import PieChart from "highcharts-react-official";
import { Themes, DSColor, EspaceFont, FontFamily } from "commons";

import { colors } from "@skillup/ui";

interface PieProps {
  small?: boolean;
  chartSize?: string;
  valueTitle?: string;
  showLegend?: boolean;
  labelDistance?: number;
  legend: Array<{
    y: number;
    name: string;
    color?: string;
  }>;
}

interface PlotSeriesDataLabelsOptionsExtended extends Highcharts.PlotSeriesDataLabelsOptions {
  distance: number;
}

const Pie = ({
  chartSize = "100%",
  labelDistance = -50,
  legend,
  showLegend = true,
  valueTitle,
}: PieProps) => {
  const theme = useTheme();
  const isRHTheme = theme === Themes.ESPACE_RH;

  const fontFamily = isRHTheme ? FontFamily.rh : FontFamily.collab;
  const espaceFont = isRHTheme ? EspaceFont.rh : EspaceFont.collab;

  const options: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    colors: legend.map((e) => e.color ?? colors[e.name]),
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    legend: {
      align: "center",
      itemStyle: {
        color: DSColor["plainText-onLight-default"],
        fontFamily,
        fontSize: espaceFont.captionRegular.fontSize,
        padding: "0 0.5rem",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        point: {
          events: {
            legendItemClick: () => false,
          },
        },
        showInLegend: showLegend,
        size: chartSize,
      },
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: false,
          },
          {
            distance: labelDistance,
            enabled: true,
            filter: {
              operator: ">",
              property: "percentage",
              value: 9,
            },
            format: "{point.percentage:.0f} %",
            style: {
              color: "white",
              fontSize: "1rem",
              opacity: 0.7,
              textOutline: "none",
            },
          } as PlotSeriesDataLabelsOptionsExtended,
        ],
      },
    },
    series: [
      {
        type: "pie",
        data: legend.map((e) => {
          const { color, name, y } = e;
          return {
            color,
            name,
            y: Math.round(y),
          };
        }),
        dataLabels: {
          style: {
            color: DSColor["plainText-onLight-default"],
            fontFamily,
            fontSize: espaceFont.captionRegular.fontSize,
          },
        },
        name: valueTitle,
      },
    ],
    title: {
      text: "",
    },
    tooltip: {
      valueSuffix: "%",
    },
  };

  return <PieChart options={options} highcharts={Highcharts} />;
};

export default Pie;
