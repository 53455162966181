import { Link } from "react-router-dom";
import { useRef, useCallback } from "react";

import Highcharts from "highcharts";
import { useEmployees } from "hooks";
import timeline from "highcharts/modules/timeline";
import HighchartsReact from "highcharts-react-official";

import { GridRowParams, useGridApiRef } from "@mui/x-data-grid-pro";

import { DSDataGrid } from "@skillup/ui";
import { Loader } from "@skillup/design-system";
import { Text, Flex } from "@skillup/design-system";

import User from "utils/User";
import DSLayout from "components/DSLayout";
import { Monitoring, usePeopleReviewCampaigns } from "services/peopleReview";
import {
  ClosedStatus,
  PausedStatus,
  RunningStatus,
  NotStartedStatus,
} from "containers/Supervisor/routes/PeopleReview/components";

import { PR_Management_Routes } from "../../router";
import { getEventTimelineConf } from "./eventTimeline.conf";

import styles from "./DevMonitoringCampaigns.module.scss";

timeline(Highcharts);

function CampaignPanelContent({ row }) {
  const timelineComponentRef = useRef<HighchartsReact.RefObject>(null);
  const { notifications = [] } = Monitoring.useNotifications({
    campaignID: row?.id,
  });

  const managerUuids = notifications.map((notification) => notification.managerID);
  const observerUuids = row?.observers.map((observer) => observer.properties.userUuid);
  const coordinatorsUuids = row?.observers.map((coordinator) => coordinator.properties.userUuid);

  const { employees, isLoading } = useEmployees({
    employeeUuids: [...observerUuids, ...coordinatorsUuids, ...managerUuids],
    keys: ["fullName", "email", "uuid"],
  });

  const coordinators =
    employees?.filter((employee) => coordinatorsUuids.includes(employee.uuid)) ?? [];
  const managers = employees?.filter((employee) => managerUuids.includes(employee.uuid)) ?? [];
  const observers = employees?.filter((employee) => observerUuids.includes(employee.uuid)) ?? [];

  const data = row?.events?.map((event) => {
    return {
      description: event.properties.authorFullName,
      label: event.type,
      marker: {
        symbol: "circle",
      },
      name: event.type,
      x: new Date(event.properties.date).getTime(),
    };
  });

  const timelineConf = getEventTimelineConf({ data });

  return (
    <Flex flexDirection="column" className={styles.campaignCard}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Flex padding="s" justifyContent="space-between">
            <Flex flexGrow={1} flexDirection="column">
              <Text marginBottom="xs" color="plainText-onLight-default">
                Coordinators
              </Text>
              {coordinators?.map((coordinator) => <Text>{coordinator.fullName}</Text>)}
            </Flex>
            <Flex flexGrow={1} flexDirection="column">
              <Text marginBottom="xs" color="plainText-onLight-default">
                Managers
              </Text>
              {managers?.map((manager) => <Text>{manager.fullName}</Text>)}
            </Flex>
            <Flex flexGrow={1} flexDirection="column">
              <Text marginBottom="xs" color="plainText-onLight-default">
                Observers
              </Text>
              {observers?.map((observer) => <Text>{observer.fullName}</Text>)}
            </Flex>
          </Flex>

          {row?.events?.length > 0 ? (
            <HighchartsReact
              options={timelineConf}
              highcharts={Highcharts}
              ref={timelineComponentRef}
            />
          ) : (
            <Text>No events to display graph</Text>
          )}
        </>
      )}
    </Flex>
  );
}
export function DevMonitoringCampaigns() {
  const apiRef = useGridApiRef();
  const { campaigns = [] } = usePeopleReviewCampaigns();

  const rows = campaigns.map((campaign) => ({
    id: campaign.id,
    coordinators: campaign.coordinators,
    creatorFullName: campaign.creatorFullName,
    events: campaign.events,
    observers: campaign.observers,
    state: campaign.state,
    title: campaign.title,
    titleForAttendees: campaign.titleForManagers,
  }));

  const columns = [
    {
      type: "string",
      field: "title",
      flex: 1,
      headerName: "Title",
      minWidth: 100,
    },
    {
      type: "string",
      field: "titleForAttendees",
      flex: 1,
      headerName: "Title for attendees",
      minWidth: 100,
    },
    {
      type: "string",
      field: "state",
      flex: 1,
      headerName: "State",
      minWidth: 100,
      renderCell: ({ row }) => {
        switch (row.state) {
          case "not_started":
            return <NotStartedStatus isDark={false} />;
          case "paused":
            return <PausedStatus isDark={false} />;
          case "running":
            return <RunningStatus isDark={false} />;
          case "closed":
            return <ClosedStatus isDark={false} />;
          default:
            return <Text>{row.state}</Text>;
        }
      },
    },
    {
      type: "string",
      field: "creatorFullName",
      flex: 1,
      headerName: "creatorFullName",
      minWidth: 100,
    },
    {
      type: "string",
      field: "link",
      flex: 1,
      headerName: "Link",
      minWidth: 100,
      renderCell: ({ row }) => (
        <Link to={PR_Management_Routes.DevMonitoringCampaignDetails.replace(":campaignID", row.id)}>
          Notifications
        </Link>
      ),
    },
    {
      type: "string",
      field: "reviews",
      flex: 1,
      headerName: "reviews",
      minWidth: 100,
    },
  ];

  const getDetailPanelContent = useCallback(({ row }: GridRowParams) => {
    return <CampaignPanelContent row={row} key={row.id} />;
  }, []);
  const getDetailsPanelHeight = useCallback(() => "auto" as const, []);

  return (
    <DSLayout
      title="Configuration des People Reviews"
      activeLayout={"Monitoring des campagnes"}
      layouts={[
        {
          tab: {
            id: "Modèles de campagnes",
            label: "Modèles de campagnes",
            url: PR_Management_Routes.TemplatesList,
          },
        },
        {
          tab: {
            id: "Actions",
            label: "Actions",
            url: PR_Management_Routes.ActionsList,
          },
        },
        User.isSkillupDeveloper() && {
          tab: {
            id: "Monitoring des campagnes",
            label: "Monitoring des campagnes",
            url: PR_Management_Routes.DevMonitoringCampaigns,
          },
        },
      ]}
    >
      <Flex width="100%" paddingVertical="s" paddingHorizontal="l" flexDirection="column">
        <DSDataGrid
          rows={rows}
          editMode="row"
          apiRef={apiRef}
          columns={columns}
          entityName="Campaigns"
          disableRowSelectionOnClick
          getDetailPanelHeight={getDetailsPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          onRowClick={(params) => apiRef.current.setExpandedDetailPanels([params.id])}
        />
      </Flex>
    </DSLayout>
  );
}
