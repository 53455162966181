import React, {
  cloneElement,
  useRef,
  useMemo,
  type HTMLAttributes,
  type ReactElement,
  type MouseEvent,
  type MouseEventHandler,
  useEffect,
} from "react";
import { useClickAway, useSetState } from "react-use";

import { useMediaQueries } from "../../hooks/useMediaQueries";
import { throttle } from "lodash";
import cx from "classnames";

import { Themes } from "components/commonProps";
import { Bolt, Close } from "components/MaterialIcons";
import { Button as DSButton } from "components/Button";
import { Tooltip as DSTooltip } from "components/Tooltip";

import { SkillupLogo } from "./SkillupLogo";
import { NavigationItem } from "./NavigationItem";
import { NavigationSubItem } from "./NavigationSubItem";
import { NavigationDivider } from "./NavigationDivider";
import { HorizontalDivider } from "components/Divider/HorizontalDivider/HorizontalDivider";

import styles from "./NavigationSideNav.module.scss";
export interface NavigationSideNavProps extends HTMLAttributes<HTMLDivElement> {
  readonly activeNav?: string;
  readonly onChangeNav?: (navId: string) => void;

  readonly show?: boolean;
  readonly onClose?: MouseEventHandler<HTMLButtonElement>;

  // actions elements
  readonly children?: JSX.Element[] | JSX.Element;
  readonly footer?: JSX.Element[] | JSX.Element;

  readonly darkMode?: boolean;
  readonly theme: Themes;

  readonly portalLogo?: string;

  readonly forceOpenId?: string | undefined;
}

const { REACT_APP_PUBLIC_UPLOADS_URL } = process.env;

const PureNavigationSideNav = ({
  activeNav,
  onChangeNav,
  show,
  onClose,
  children,
  footer,
  darkMode,
  theme,
  portalLogo,
  className,
  forceOpenId,
  ...props
}: NavigationSideNavProps): JSX.Element => {
  const savedScrollPosition = Number(sessionStorage.getItem("scrollPosition"));

  const [state, setState] = useSetState({
    navScrollPosition: savedScrollPosition ?? 0,
  });

  const { isMobile } = useMediaQueries();

  const sideNavRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);

  useClickAway(sideNavRef, (e: unknown) => {
    if (isMobile && show) {
      return onClose?.(e as MouseEvent<HTMLButtonElement>);
    }
  });
  const isDarkMode = theme === Themes.ESPACE_RH;

  const enhanceNavItem = (elements: JSX.Element[] | JSX.Element | undefined) =>
    React.Children.toArray(elements).map((child) => {
      // @ts-ignore
      const subNavs = React.Children.toArray(child.props.children);
      // @ts-ignore
      const mainId = child.props.id;
      const isOpen =
        activeNav?.includes(mainId) ||
        // @ts-ignore
        subNavs.findIndex((sub) => activeNav?.includes(sub.props.id)) >= 0 ||
        // @ts-ignore
        child.props.id === forceOpenId;
      return cloneElement(child as ReactElement, {
        open: isOpen,
        active: subNavs.length === 0 && activeNav?.includes(mainId),
        onClick: () => subNavs.length === 0 && onChangeNav?.(mainId),
        theme: theme,
        darkMode: isDarkMode,
        // @ts-ignore
        forceOpenId: child.props.id !== "configuration" ? forceOpenId : null,
        children: subNavs?.map((subChild) => {
          // @ts-ignore
          const subId = subChild.props.id;
          return cloneElement(subChild as ReactElement, {
            active: activeNav?.includes(subId),
            onClick: () => onChangeNav?.(subId),
            theme: theme,
            darkMode: isDarkMode,
          });
        }),
      });
    });

  const { actionElements, footerElements } = useMemo(
    () => ({
      actionElements: enhanceNavItem(children),
      footerElements: enhanceNavItem(footer),
    }),
    [children, activeNav]
  );

  const handleScrollPosition = () => {
    if (scrollableRef && scrollableRef.current) {
      scrollableRef.current.addEventListener(
        "scroll",
        throttle(
          () => {
            sessionStorage.setItem(
              "scrollPosition",
              scrollableRef.current?.scrollTop.toString() ?? "0"
            );

            setState({
              navScrollPosition: scrollableRef.current?.scrollTop,
            });
          },
          100,
          { trailing: true, leading: true }
        )
      );
    }
  };

  const scrollToPreviousState = () => {
    if (scrollableRef && scrollableRef.current) {
      scrollableRef.current.scrollTo(0, Number(state.navScrollPosition));
    }
  };

  useEffect(() => {
    scrollToPreviousState();
    handleScrollPosition();
  }, []);

  return (
    <>
      <div className={cx(styles.overlay, { [styles.hide]: !show })} />
      <div
        ref={sideNavRef}
        aria-label="navigation-sidenav"
        className={cx(styles.NavigationSideNav, className, styles[theme], {
          [styles.darkMode]: isDarkMode,
          [styles.hide]: !show,
        })}
        {...props}
      >
        <div className={cx(styles.buttons)}>
          {
            // Don't remove the id on the button, it's used to
            // display Frill's notifications badge on top of it
          }
          <DSTooltip lightColor label="Nouveautés produit">
            <DSButton darkMode={isDarkMode} iconOnly icon={<Bolt />} id="navigation-company-logo" />
          </DSTooltip>
          <DSTooltip lightColor label="Masquer la navigation">
            <DSButton
              darkMode={isDarkMode}
              iconOnly
              icon={<Close />}
              className={styles.closeButton}
              onClick={onClose}
            />
          </DSTooltip>
        </div>
        <div className={styles.header}>
          <div className={styles.logo}>
            {portalLogo ? (
              <div>
                <img
                  className={styles.backgroundLogo}
                  src={REACT_APP_PUBLIC_UPLOADS_URL + portalLogo}
                />
              </div>
            ) : (
              <div>
                <SkillupLogo />
              </div>
            )}
          </div>
        </div>
        <div ref={scrollableRef} className={styles.scrollable}>
          <div className={styles.primary}>{actionElements}</div>
          <HorizontalDivider darkMode={isDarkMode} top={"s"} bottom={"s"} />
          <div
            onClick={() => {
              requestAnimationFrame(() => {
                scrollableRef.current?.scrollTo({
                  top: scrollableRef.current.scrollHeight,
                  behavior: "smooth",
                });
              });
            }}
            className={styles.secondary}
          >
            {footerElements}
          </div>
        </div>
      </div>
    </>
  );
};

export const NavigationSideNav = Object.assign(PureNavigationSideNav, {
  Item: NavigationItem,
  SubItem: NavigationSubItem,
  Divider: NavigationDivider,
});
