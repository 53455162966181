import { createContext, useContext, useState } from "react";
import { ModalState, ModalStates, useBuilderModals, PositionItem } from "./hooks";

type BuilderModalsContextType = {
  modalToDisplay: JSX.Element;
  showActionsButtons: (item: PositionItem) => boolean;
  handleDeletion: (item: PositionItem) => void;
  handleDuplication: (item: PositionItem) => void;
};

const BuilderModalsContext = createContext<BuilderModalsContextType>(null);

const useBuilderModalsContext = () => {
  const context = useContext(BuilderModalsContext);
  if (!context) {
    throw new Error("useBuilderModals must be used within a BuilderModalsProvider");
  }
  return context;
};

const BuilderModalsProvider = ({ children }: { children: React.ReactNode }) => {
  const [builderModal, setBuilderModal] = useState<ModalState>({ state: ModalStates.Idle });

  const { handleDeletion, handleDuplication, showActionsButtons, modalToDisplay } =
    useBuilderModals(builderModal, setBuilderModal);

  return (
    <BuilderModalsContext.Provider
      value={{
        modalToDisplay,
        showActionsButtons,
        handleDeletion,
        handleDuplication,
      }}
    >
      {children}
    </BuilderModalsContext.Provider>
  );
};

export { BuilderModalsContext, useBuilderModalsContext, BuilderModalsProvider };
