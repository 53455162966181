import React, { useCallback } from "react";
import { useToggle } from "react-use";
import type { InterviewWithStates } from "@skillup/types";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import InteractiveButton from "components/InteractiveButton";
import Colors from "uiAssets/Colors";

interface IProps {
  readonly campaignUuid: string;
  readonly interview: InterviewWithStates;
  readonly resync: () => Promise<InterviewWithStates[]>;
}

const DeletionModal = ({ campaignUuid, interview, resync }: IProps): JSX.Element => {
  const [loading, toggleLoading] = useToggle(false);

  const sendDelete = useCallback(async (): Promise<void> => {
    toggleLoading(true);

    try {
      await DataLayer.request({
        method: "DELETE",
        url: `/v1/campaigns/${campaignUuid}/interviews/${interview.uuid}`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "Entretien supprimé.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Impossible de supprimer l'entretien",
        type: "error",
      });
    } finally {
      resync();
      toggleLoading(false);
      Acta.dispatchEvent("closeModal");
    }
  }, [toggleLoading, campaignUuid, interview, resync]);

  return (
    <div>
      <p style={{ padding: 20, fontSize: 12 }}>
        Si vous supprimez l’entretien de
        <strong> {interview.employee.fullName}</strong> avec
        <strong> {interview.manager.fullName}</strong> :
        <ul style={{ margin: "0.75rem 0 0.75rem 1rem" }}>
          <li>
            les emails de notification qui leur ont été envoyés pointeront vers une page d’erreur
          </li>
          <li style={{ paddingTop: "0.25rem" }}>
            les réponses qui auraient été saisies dans le cadre de la préparation seront
            définitivement perdues
          </li>
        </ul>
        Êtes-vous sûr(e) de vouloir supprimer l’entretien ?
      </p>
      <footer style={{ display: "flex", padding: 20 }}>
        <InteractiveButton
          label="Précécent"
          onClick={() => Acta.dispatchEvent("closeModal")}
          size="small"
          background="#fff"
          color={Colors.blue}
        />
        <InteractiveButton
          loading={loading}
          title="delete-interview"
          label="Supprimer"
          onClick={sendDelete}
          size="small"
        />
      </footer>
    </div>
  );
};

export default DeletionModal;
