import React, { useCallback } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import utils from "utils";
import Icon from "components/Icon";
import PlaceFilter, { Place } from "components/PlaceFilter";
import Colors from "uiAssets/Colors";
import { check } from "uiAssets/Icons";

import styles from "./PlaceFilterStyles.module.scss";

interface Props {
  readonly filters: any;
  readonly aggregations: Record<string, unknown>;
}

export default ({ filters, aggregations }: Props) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const navigate = useCallback(
    async (place: Place): Promise<void> => {
      return history.push(
        pathname +
          utils.search.getQueryStringFromFilters({
            ...filters,
            page: null,
            place,
          })
      );
    },
    [history, pathname, filters]
  );

  // @ts-ignore
  const distancialSessionsActive = filters?.sessionType ?? false;
  // @ts-ignore
  const distancialSessionsCount = aggregations?.sessionType?.distancial?.doc_count ?? 0;

  const distancialSessionsLink =
    pathname +
    utils.search.getQueryStringFromFilters({
      ...filters,
      page: null,
      sessionType: distancialSessionsActive ? null : "distancial",
    });

  return (
    <div className={styles.PlaceFilter}>
      <PlaceFilter onPlaceSelect={navigate} />

      <Link
        key={`addFilter-distancial`}
        to={distancialSessionsLink}
        className={styles.addFilterLink}
      >
        <div>
          <div>
            {distancialSessionsActive && <Icon icon={check} fill={Colors.blue} width={10} />}
          </div>
          Session à distance
        </div>
        <span>{distancialSessionsCount}</span>
      </Link>
    </div>
  );
};
