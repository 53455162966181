import styled from "styled-components";

import { DSColor, DSModal, DSButton, SkuiSpacing, DSTextInput } from "@skillup/ui";

export const MultipleArchiveButton = styled(DSButton)`
  margin-bottom: ${SkuiSpacing.s};
`;

export const SearchInput = styled(DSTextInput)`
  width: fit-content;

  input {
    width: 255px;
    border-color: ${DSColor["border-onLight"]};
    font-size: ${SkuiSpacing.s};
    &::placeholder {
      font-style: italic;
    }
  }
  svg {
    fill: ${DSColor["plainText-onLight-lighter"]};
    height: ${SkuiSpacing.m};
    width: ${SkuiSpacing.m};
    margin-left: -${SkuiSpacing.l};
  }
`;

export const StyledArchiveModal = styled(DSModal)`
  width: 35rem;

  .modalSearch {
    display: flex;
    margin-bottom: ${SkuiSpacing.l};
    min-width: 15rem;

    .searchInput {
      width: 100%;

      input {
        width: 100%;
      }
      svg {
        margin-left: 0;
      }
    }
  }

  .modalArchiveBtn {
    display: inline;
  }
`;

export const EllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
