import React from "react";
import { useLocalStorage } from "react-use";
import { Toggle, Button } from "@skillup/ui";

import { useUnmanagedBookingContext } from "../../context";

import styles from "./Disclaimer.module.scss";

export default () => {
  const [, setUnmanagedBookingContext] = useUnmanagedBookingContext();
  const [hiddenDisclaimer, setHideDisclaimer] = useLocalStorage<boolean>(
    "hideUnmanagedBookingDisclaimer"
  );

  function setNextStep() {
    setUnmanagedBookingContext({ activeStep: "off-bounds-positioning-form" });
  }

  function displayDisclaimer(checked) {
    setHideDisclaimer(checked);
  }

  return (
    <div className={styles.Disclaimer}>
      <p>Si vous inscrivez hors Skillup:</p>
      <ul>
        <li>Vous devez inscrire le stagiaire auprès de l'organisme par vos propres moyens</li>
        <li>La formation passera au statut "Inscrit" de votre plan</li>
      </ul>
      <div className={styles.toggle}>
        <Toggle
          className={styles.checkbox}
          onToggle={displayDisclaimer}
          active={hiddenDisclaimer}
        />
        <label>Ne plus afficher ce message</label>
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={setNextStep} label="J'ai compris" />
      </div>
    </div>
  );
};
