import React from "react";
import Icon from "components/Icon";
import { home as homeIcon, chevronRight as chevronRightIcon } from "uiAssets/Icons";

import styles from "./TrainingResultsLoadingViewStyles.module.scss";

export default class TrainingResultsLoadingView extends React.PureComponent<{}, {}> {
  public render(): JSX.Element {
    return (
      <div className={styles.TrainingResultsLoadingView}>
        <aside />
        <main>
          <header>
            <div className={styles.heading} />
            <nav>
              <Icon icon={homeIcon} width={18} />
              <Icon icon={chevronRightIcon} width={12} />
              <div />
            </nav>
          </header>
          <div />
          <div />
          <div />
        </main>
      </div>
    );
  }
}
