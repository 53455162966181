import { DSFilters } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

import { useDashboardContext } from "../../useDashboardContext";

export default function Filters() {
  const { t } = useTranslation();
  const { filters, filterConfig, updateFilters } = useDashboardContext();

  return <DSFilters t={t} filters={filters} config={filterConfig} onChange={updateFilters} />;
}
