import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { DSModal, Label, DSTextInput } from "@skillup/ui";

import { useTemplate, type Template } from "services/interviews";
import styles from "./RenameTemplateModal.module.scss";

interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeModal: () => void;
}

const RenameTemplateModal = ({ template, isOpen, closeModal }: IProps) => {
  const { addToast } = useToasts();
  const templateActions = useTemplate(template.uuid);
  const [newTitle, setNewTitle] = useState<string>(template.title);
  const [loading, setLoading] = useState<boolean>(false);

  const send = async (): Promise<void> => {
    try {
      setLoading(true);
      const response = await templateActions.rename(newTitle);
      if (response.success) {
        addToast("Trame renommée", { appearance: "success" });
        closeModal();
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error("Echec lors du renommage de la trame");
      }
    } catch (err) {
      addToast("Echec lors du renommage de la trame", {
        appearance: "error",
      });
    }
  };
  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title="Renommer une trame" />
      </DSModal.Header>

      <DSModal.Content>
        <Label label="Titre de la trame" required />
        <div className={styles.marginBottom}>
          <DSTextInput
            value={newTitle}
            onChange={setNewTitle}
            name={"new title"}
            autoFocus
            required
          />
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={closeModal} label="Annuler" />
        <DSModal.Footer.PrimaryButton
          label="Enregistrer les modifications"
          onClick={send}
          disabled={newTitle === template.title}
          loading={loading}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export { RenameTemplateModal };
