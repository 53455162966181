import { useCallback, useState } from "react";
import { useMount } from "react-use";
import { isEqual, uniq, findIndex } from "lodash";

import { ModulesAccessList, ScopeType, ModuleType } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import type { UserRoutes } from "types/api";
import useSettings from "hooks/useSettings";

import { ALLOWED_ROLES, parseLabel, scopesMatrix } from "./utils";
import User from "utils/User";

export default function useAdminData() {
  const userData = User.getUserData();

  const [state, setState] = useState([]);

  const {
    activeCompany: { areas: possibleUserAreas },
  } = Acta.getState("userData");

  const { settings: companySettings } = useSettings();
  const hasAccessListV2 = companySettings?.features?.includes(ModulesAccessList.AccessListV2);

  const loadData = useCallback(async (): Promise<void> => {
    try {
      const companyAdmins = await buildRequest<UserRoutes.GetMultipleUsers>({
        method: "GET",
        path: "/user",
        query: { role: "admin" },
      })();

      const permissions = await buildRequest<UserRoutes.GetPermissions>({
        method: "GET",
        path: "/user/permissions",
        query: { uuids: companyAdmins.map((admin) => admin.properties.uuid) },
      })();

      const usersModules = await buildRequest<UserRoutes.GetUsersModules>({
        method: "GET",
        path: "/admin/modules",
        query: { userUuids: companyAdmins.map((admin) => admin.properties.uuid) },
      })();

      const parsedCompanyAdmins = companyAdmins.map((admin) => {
        const adminPermissions = permissions.find(
          (permission) => permission.uuid === admin.properties.uuid
        );

        const adminModules = usersModules.find((u) => u.userUuid === admin.properties.uuid).modules;

        return {
          uuid: admin.properties.uuid,
          fullName: admin.properties.fullName,
          email: admin.properties.email,
          role: parseLabel(admin.scope?.filter((scope) => ALLOWED_ROLES.includes(scope))),
          userAreas: possibleUserAreas.map((userArea) => {
            const isSelected = adminPermissions.acl.userAreas.some(
              (userAreaPermission) => userAreaPermission.uuid === userArea.uuid
            );

            return {
              label: userArea.name,
              value: userArea.uuid,
              isSelected,
            };
          }),
          userModules: adminModules,
        };
      });

      setState(parsedCompanyAdmins);
    } catch (error) {
      setState([]);
    }
  }, [possibleUserAreas]);

  const onChange = useCallback(
    (userUuid: string, type: string, value) => {
      const item = state.find((e) => e.uuid === userUuid);
      if (item) {
        const index = findIndex(state, { uuid: userUuid });
        item[type] = value;
        state.splice(index, 1, item);
        setState([...state]);
      }
    },
    [setState, state]
  );

  const onChangePermissions = useCallback(
    (userUuid: string) =>
      async (values: Array<{ isSelected: boolean; value: string; label: string }>) => {
        await buildRequest<UserRoutes.PatchAcl>({
          method: "POST",
          path: "/user/{uuid}/acl",
          params: { uuid: userUuid },
          payload: { areas: values.filter((e) => e.isSelected).map((e) => e.value) },
        })();

        onChange(userUuid, "userAreas", values);
      },
    [onChange]
  );

  const generateNewScopes = async (userUuid: string, option: string) => {
    const scopes: ScopeType[] = ["admin"];

    if (option === "Formateur") {
      scopes.push("trainer");
    }
    if (option === "Admin sans Programmes") {
      scopes.push("admin-without-intras");
    }
    if (option === "Formateur sans Programmes") {
      scopes.push("trainer-without-intras");
    }
    if (option === "Formateur QHSE") {
      scopes.push("trainer-qhse");
    }

    if (option === "Custom") {
      scopes.push("custom");
    }

    const data = await buildRequest<UserRoutes.GetUserScopes>({
      method: "GET",
      path: "/user/{userUuid}/scopes",
      params: { userUuid: userUuid },
    })();
    const newScopes = uniq([...data.scopes, ...scopes]);
    if (!scopes.includes("trainer")) {
      // remove trainer if not in scopes
      const index = newScopes.indexOf("trainer");
      if (index > -1) {
        newScopes.splice(index, 1);
      }
    }

    if (!scopes.includes("admin-without-intras")) {
      // remove admin-without-intras if not in scopes
      const index = newScopes.indexOf("admin-without-intras");
      if (index > -1) {
        newScopes.splice(index, 1);
      }
    }

    if (!scopes.includes("trainer-without-intras")) {
      // remove trainer-without-intras if not in scopes
      const index = newScopes.indexOf("trainer-without-intras");
      if (index > -1) {
        newScopes.splice(index, 1);
      }
    }

    if (!scopes.includes("trainer-qhse")) {
      // remove trainer-qhse if not in scopes
      const index = newScopes.indexOf("trainer-qhse");
      if (index > -1) {
        newScopes.splice(index, 1);
      }
    }

    if (!scopes.includes("custom")) {
      // remove custom if not in scopes
      const index = newScopes.indexOf("custom");
      if (index > -1) {
        newScopes.splice(index, 1);
      }
    }

    return newScopes;
  };

  const onChangeScopeAccess = useCallback(
    (userUuid: string) => async (option: string) => {
      const updateModules = async (userUuid: string, label: string) => {
        const scope = scopesMatrix.find((scope) => scope.label === label);

        if (scope) {
          const newModules = scope.modules.filter((module) => {
            return userData?.activeCompany?.features.includes(module);
          });

          await buildRequest<UserRoutes.PatchUserModules>({
            method: "PUT",
            path: "/user/{userUuid}/modules",
            params: { userUuid: userUuid },
            payload: {
              modules: newModules,
            },
          })();
          onChange(userUuid, "userModules", newModules);
        }
      };

      await buildRequest<UserRoutes.PatchUserScopes>({
        method: "POST",
        path: "/user/{userUuid}/scopes",
        params: { userUuid: userUuid },
        payload: {
          scopes: await generateNewScopes(userUuid, option),
        },
      })();

      onChange(userUuid, "role", option);
      await updateModules(userUuid, option);
    },
    [onChange, userData]
  );

  const onChangeModules = useCallback(
    (userUuid: string) =>
      async (values: Array<{ isSelected: boolean; value: string; label: string }>) => {
        const updateScopes = async (userUuid: string, modules: string[]) => {
          let newLabel = "Custom";
          for (const scope of scopesMatrix) {
            if (isEqual(modules.sort(), scope.modules.sort())) {
              newLabel = scope.label;
            }
          }

          await buildRequest<UserRoutes.PatchUserScopes>({
            method: "POST",
            path: "/user/{userUuid}/scopes",
            params: { userUuid: userUuid },
            payload: {
              scopes: await generateNewScopes(userUuid, newLabel),
            },
          })();
          onChange(userUuid, "role", newLabel);
        };

        const modules = values.filter((e) => e.isSelected).map((e) => e.value);
        if (hasAccessListV2) {
          modules.push(ModulesAccessList.AccessListV2);
        }

        await buildRequest<UserRoutes.PatchUserModules>({
          method: "PUT",
          path: "/user/{userUuid}/modules",
          params: { userUuid: userUuid },
          payload: {
            modules: modules as ModuleType[],
          },
        })();

        onChange(userUuid, "userModules", modules);
        updateScopes(userUuid, modules);
      },
    [onChange, hasAccessListV2]
  );

  useMount(loadData);

  return {
    state,
    loadData,
    onChangePermissions,
    onChangeModules,
    onChangeScopeAccess,
    generateNewScopes,
  };
}
