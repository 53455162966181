import { ProcessedSkill } from "types/skills";

type EvaluationScalesWithSkills = {
  [evaluationScaleId: string]: EvaluationScaleWithSkills;
};

type EvaluationScaleWithSkills = ProcessedSkill["evaluationScale"] & {
  skills: ProcessedSkill[];
};

export const getSkillsPerEvaluationScale = (skills: ProcessedSkill[]) => {
  return skills.reduce<EvaluationScalesWithSkills>((acc, skill) => {
    if (!acc[skill.evaluationScale.uuid]) {
      acc[skill.evaluationScale.uuid] = { ...skill.evaluationScale, skills: [skill] };
    } else {
      acc[skill.evaluationScale.uuid].skills.push(skill);
    }

    return acc;
  }, {});
};

export const formatPayloadGenerateSkillsLevel = (
  jobName: string,
  evaluationScale: EvaluationScaleWithSkills
) => {
  return {
    evaluationScale: evaluationScale.levels.reduce(
      (acc, level) => ({ ...acc, [level.uuid]: level.name }),
      {}
    ),
    jobName: jobName,
    skills: evaluationScale.skills.map((skill) => skill.name),
  };
};
