import { Flex, Text } from "@skillup/design-system";
import { FieldKey, DatasetsRoutes } from "@skillup/core-hr-bridge";

import useTranslation from "hooks/useTranslation";

type FieldDataProps = {
  fieldKey: FieldKey;
  fieldData: DatasetsRoutes["GetEmployeeFieldValueAtDateRoute"]["response"][FieldKey];
};

export const FieldData = ({ fieldKey, fieldData }: FieldDataProps) => {
  const { t } = useTranslation();

  return (
    <Flex padding="xs" flexDirection="column" flex={"1"} minWidth={"250px"}>
      <Text espaceFont="h6">{fieldKey}</Text>
      {!fieldData ? (
        <Text espaceFont="body1Regular">
          {t("collab.historic.fieldskeys.timeline.noData", {
            defaultValue: "Aucune donnée pour ce champs",
          })}
        </Text>
      ) : (
        <Text espaceFont="body1Regular">{fieldData.data}</Text>
      )}
    </Flex>
  );
};
