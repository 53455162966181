import React from "react";

import Icon from "components/Icon";

import { calendar as calendarIcon, cross as crossIcon } from "uiAssets/StrokeIcons";

import styles from "./CancelOrPostponeModalStyles.module.scss";
import { DSModal } from "@skillup/ui";

interface Props {
  rows: string[];
  onOptionChange: (option: string) => void;
  onClose: (needRefresh?: boolean) => void;
}

export default function CancelOrPostponeChooseModal({ rows, onOptionChange, onClose }: Props) {
  return (
    <DSModal isOpen className={styles.CancelOrPostponeModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={
            rows.length > 1 ? "Reporter ou annuler les demandes" : "Reporter ou annuler la demande"
          }
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <div className={styles.options}>
          <button onClick={() => onOptionChange("postpone")}>
            <Icon strokeIcon={calendarIcon} width={30} />
            <div>
              <h2>Reporter</h2>
              <p>
                Cette action permet d’annuler la réservation auprès de l’organisme puis de choisir
                une autre date de formation ou un autre programme. Attention ! Une fois l’action
                confirmée, la demande repassera dans l’onglet « À inscrire ».
              </p>
            </div>
          </button>
          <button onClick={() => onOptionChange("cancel")}>
            <Icon strokeIcon={crossIcon} width={30} />
            <div>
              <h2>Annuler</h2>
              <p>
                Cette action permet d’annuler définitivement la réservation auprès de l’organisme.
                Attention ! Une fois l’action confirmée, vous ne pourrez plus reporter l’inscription
                à une date ultérieure.
              </p>
            </div>
          </button>
        </div>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </DSModal>
  );
}
