import React from "react";
import cx from "classnames";

import Icon from "components/Icon";
import { defaultColumnWidth } from "constants/dataTable";
import { filter as filterIcon } from "uiAssets/StrokeIcons";
import { Column } from "components/DataTable/types";

import styles from "../../DataTableStyles.module.scss";

export interface IProps {
  closeFilterModal: () => void;
  column: Column;
  isBeingFiltered: boolean;
  hasFilter: boolean;
  openFilterModal: (column: Column) => void;
  deactivateFilters?: boolean;
}

export default class HeaderCell extends React.PureComponent<IProps, {}> {
  private toggleFilterModal = (): void => {
    if (!this.props.isBeingFiltered) {
      this.props.openFilterModal(this.props.column);
    } else {
      this.props.closeFilterModal();
    }
  };

  public render(): JSX.Element {
    const { deactivateFilters, isBeingFiltered, hasFilter } = this.props;

    const width = this.props.column.width || defaultColumnWidth;

    const { label, key } = this.props.column;

    return (
      <div style={{ width }} className={styles.headerCell} data-key={this.props.column.key}>
        <div
          className={cx(styles.content, {
            [styles.isActive]: isBeingFiltered,
          })}
        >
          <div>
            <h3>
              <span>{label}</span>
            </h3>
            {!deactivateFilters && (
              <button
                id={`${key}-filter-button`}
                className={cx(styles["column-filter"], hasFilter ? styles.active : styles.inactive)}
                onClick={this.toggleFilterModal}
              >
                <Icon strokeIcon={filterIcon} width={10} />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}
