import { GridColDef } from "@mui/x-data-grid-pro";
import { DSTooltip } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";

import style from "../components/GridFields.module.scss";
import ActionsCell from "../components/ActionsCell";

const generateColumns = (t: TranslationType, isAdmin: boolean, displayIsTrainingField = false) => {
  const columns: GridColDef[] = [
    {
      field: "label",
      headerName: t("fields.label.header", {
        defaultValue: "Intitulé",
      }),
      width: 400,
      renderCell: (params) => {
        return (
          <DSTooltip
            label={params.value}
            className={style.TooltipCell}
            enabledOnOverflow
            withPortal
          >
            {params.value}
          </DSTooltip>
        );
      },
    },
    {
      field: "binding",
      headerName: t("fields.binding.header", {
        defaultValue: "Identifiant technique",
      }),
      width: 200,
      renderCell: (params) => {
        return (
          <DSTooltip
            label={params.value}
            className={style.TooltipCell}
            enabledOnOverflow
            withPortal
          >
            {params.value}
          </DSTooltip>
        );
      },
    },
    {
      field: "type",
      headerName: t("fields.type.header", {
        defaultValue: "Type",
      }),
      sortComparator: (_, __, a, b) => {
        return t(`fields.type.${a.value}`).localeCompare(t(`fields.type.${b.value}`));
      },
      width: 200,
      renderCell: (params) => {
        return (
          <DSTooltip
            label={t(`fields.type.${params.value ?? false}`)}
            className={style.TooltipCell}
            enabledOnOverflow
            withPortal
          >
            {t(`fields.type.${params.value}`)}
          </DSTooltip>
        );
      },
    },
  ];

  if (displayIsTrainingField) {
    columns.push({
      field: "isTrainingField",
      headerName: t("fields.isTrainingField.header", {
        defaultValue: "Rattaché au programme",
      }),
      width: 200,
      sortComparator: (_, __, a, b) => {
        return t(`fields.isTrainingField.${a.value}`).localeCompare(
          t(`fields.isTrainingField.${b.value}`)
        );
      },
      renderCell: (params) => {
        return (
          <DSTooltip
            label={t(`fields.isTrainingField.${params.value ?? false}`)}
            className={style.TooltipCell}
            enabledOnOverflow
            withPortal
          >
            {t(`fields.isTrainingField.${params.value ?? false}`)}
          </DSTooltip>
        );
      },
    });
  }

  if (isAdmin) {
    columns.push({
      field: "actions",
      type: "actions",
      filterable: false,
      sortable: false,
      resizable: false,
      width: 50,
      getActions: ({ row }) => [<ActionsCell field={row} />],
    });
  }

  return columns;
};

export default generateColumns;
