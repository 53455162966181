import { TemplatesRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

async function renameTemplate(params: { templateUuid: string; title: string }) {
  const { templateUuid, title } = params;
  try {
    const response = await buildRequest<TemplatesRoutes.Rename>({
      method: "POST",
      path: "/templates/interviewsV2/rename/{templateUuid}",
      params: { templateUuid },
      payload: { title },
    })();
    return response;
  } catch (err) {
    throw new Error("Rename unsuccessful");
  }
}

export { renameTemplate };
