import { useCallback, useRef, useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-pro";

import {
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  DSDataGrid,
  DSFilters,
  FilterRef,
  Flex,
} from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";
import { ReviewsRoutes } from "@skillup/espace-rh-bridge";
import { ColdReview, Config } from "@skillup/training-bridge";

import { useTypedFetch } from "hooks";
import useTranslation from "hooks/useTranslation";

import useReviewFilters from "../utils/useReviewFilters";
import { initialState } from "../utils/dataGridProps";
import { recallTrainees } from "../utils/recallTrainees";
import { ReviewRow } from "../utils/types";

import useReviewsTableData from "./useReviewsTableData";
import { ToolbarButton } from "../components/ToolbarButton";
import ColdReviewSidePanel from "./components/ColdReviewSidePanel";
import styles from "./ColdReviewListView.module.scss";

export default function ColdReviewListView() {
  const { t } = useTranslation();
  const { id: currentUrlId } = useParams<{ id: string | null }>();
  const filterRef = useRef<FilterRef>();
  const [filters, filterValues, setFilterValues] = useReviewFilters(t);
  const [loadingSendMails, setLoadingSendMails] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [sortOptions, setSortOptions] = useState<ListUtils.SortingProperties>();
  const [paginationModel, setPaginationModel] = useState<ListUtils.PaginationProperties>({
    page: 0,
    pageSize: 20,
  });
  // paginationModel type is not aligned with the one used in the data grid component.
  const actualPaginationModel = useMemo(() => {
    return {
      page: paginationModel.page ?? 0,
      pageSize: paginationModel.pageSize ?? 20,
    };
  }, [paginationModel]);
  const [currentReviewUuid, setCurrentReviewUuid] = useState(currentUrlId);
  const history = useHistory();
  const { addToast } = useToasts();

  const {
    data: { items: reviews = [], total: reviewsCount } = {},
    loading,
    refetch,
  } = useTypedFetch<ReviewsRoutes.GetCompanyColdReviews>({
    method: "POST",
    path: `/reviews/cold`,
    payload: {
      filters: filterValues,
      sorting: sortOptions,
      pagination: paginationModel,
    },
  });

  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  };
  const handleTableColumnSortClick = (property: string, direction?: "ASC" | "DESC") => {
    if (!direction) {
      setSortOptions({
        property: "sessionStartDate",
        direction: "DESC",
      });
    } else {
      setSortOptions({ property, direction });
    }
  };

  useEffect(() => {
    setCurrentReviewUuid(currentUrlId);
  }, [currentUrlId]);

  const handleOnClickRow = useCallback(
    (row: ReviewRow<ColdReview>) => {
      const existInSelection = selectedRows.includes(row.id);
      if (existInSelection) {
        return;
      }

      history.push({
        pathname: `/responsable/evaluations/cold/${row.id}`,
        search: new URLSearchParams(window.location.search).toString(),
      });
    },
    [history, selectedRows]
  );

  const handleMultipleReviewAction = async () => {
    const selectedReviews = reviews.filter((review) => selectedRows.includes(review.uuid));

    const selectedReviewsUuids = selectedReviews.map((review) => review.uuid);

    await recallTrainees(
      {
        reviewsUuids: selectedReviewsUuids,
        setLoadingSendMails,
        setShowAlert,
        refetch,
      },
      addToast,
      t
    );
  };

  const handleCloseSidePanel = useCallback(() => {
    history.push({
      pathname: `/responsable/evaluations/cold`,
      search: new URLSearchParams(window.location.search).toString(),
    });
    setCurrentReviewUuid(null);
  }, [history]);

  const { rows, columns } = useReviewsTableData({ reviews, t, refetch, selectedRows });

  return (
    <Flex column className={styles.container}>
      {showAlert && (
        <DSAlert
          type={DSAlertType.WARNING}
          display={DSAlertDisplay.FULL_BLEED}
          onClose={() => setShowAlert(false)}
          closeButton
        >
          Les mails sont en cours d’envoi, cela peut prendre quelques minutes. Les statuts seront
          mis à jour une fois l’opération effectuée
        </DSAlert>
      )}
      <div className={styles.content}>
        <div className={styles.reviewList}>
          <div className={styles.reviewList}>
            <DSFilters
              ref={filterRef}
              t={t}
              config={Config.Reviews.Filters}
              filters={filters}
              onChange={setFilterValues}
            />
            <DSDataGrid
              toolbarButtons={[
                <ToolbarButton
                  selectedRows={selectedRows}
                  reviews={reviews}
                  handleMultipleReviewAction={handleMultipleReviewAction}
                />,
              ]}
              rows={rows || []}
              initialState={initialState}
              columns={columns}
              checkboxSelection
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedRows(newRowSelectionModel);
              }}
              disableRowSelectionOnClick
              onRowClick={({ row }) => handleOnClickRow(row)}
              loading={loading || loadingSendMails}
              onPageChange={(page, pageSize) => setPaginationModel({ page, pageSize })}
              paginationModel={actualPaginationModel}
              rowCount={reviewsCount}
              onFilter={handleTableColumnFilterClick}
              onSort={handleTableColumnSortClick}
              sorting={sortOptions}
              translationPrefix="supervisor.reviews"
            />
          </div>
          {currentReviewUuid && (
            <ColdReviewSidePanel
              uuid={currentReviewUuid}
              onClose={handleCloseSidePanel}
              refetchRow={refetch}
            />
          )}
        </div>
      </div>
    </Flex>
  );
}
