import React, { Suspense, useState, useEffect } from "react";

import { orderBy } from "lodash";
import { useRecoilValue } from "recoil";
import { IoMdMore } from "react-icons/io";
import { FiPaperclip } from "react-icons/fi";

import { SearchInput } from "@skillup/ui";
import { DropDownMenu } from "@skillup/ui";
import { useBaseUrl } from "@skillup/hooks";

import Acta from "utils/Acta";
import type { EmployeesRoutes } from "types/api";

import { useJobsState } from "../state/jobs";
import { showJobDetailsModal } from "../JobsV1/JobsDetailsModal";
import List, { ListProps, TextFilter } from "../components/List";
import AssignEmployeeToJobModal from "./AssignEmployeeToJobModal";
import { employeesAtom, useEmployeesState } from "../state/employees";

import styles from "./Employees.module.scss";

type GetEmployeesRoute = EmployeesRoutes["Employees.GetList"];
type Employee = GetEmployeesRoute["response"]["employees"][0];

const Employees = () => {
  const JobsState = useJobsState();
  const EmployeesState = useEmployeesState();

  const showAssignmentModal = (employee: Employee) => {
    Acta.setState("modalDisplayed", {
      content: (
        <AssignEmployeeToJobModal
          currentJobUuid={employee.job?.uuid}
          employee={{
            uuid: employee.uuid,
            fullName: employee.fullName,
          }}
          onSuccess={() => {
            Acta.dispatchEvent("closeModal");
            JobsState.refreshList();
            EmployeesState.refreshList();
          }}
        />
      ),
      showOverflow: true,
      size: "small",
      title: "Assigner une fiche de poste",
    });
  };

  const [searchQuery, setSearchQuery] = useState<string>("");

  return (
    <div className={styles.Employees}>
      <SearchInput
        value={searchQuery}
        className={styles.searchInput}
        placeholder="Rechercher un collaborateur"
        onChange={(query) => setSearchQuery(query.toLowerCase())}
      />
      <div className={styles.tableContainer}>
        <Suspense fallback={<ListPlaceholder />}>
          <FilteredList searchQuery={searchQuery} showAssignmentModal={showAssignmentModal} />
        </Suspense>
      </div>
    </div>
  );
};

const columns: ListProps["columns"] = [
  {
    accessor: "fullName",
    Filter: TextFilter,
    filterType: "text",
    label: "Collaborateur",
    width: 300,
  },
  {
    accessor: "email",
    Filter: TextFilter,
    filterType: "text",
    label: "Email",
    width: 300,
  },
  {
    accessor: "role",
    Filter: TextFilter,
    filterType: "text",
    label: "Fonction",
    width: 300,
  },
  {
    accessor: "jobLabel",
    Cell: ({ cell }) => {
      const job = cell.row.original.job;

      if (job) {
        return (
          <div className={styles.previewLink} onClick={() => showJobDetailsModal(job)}>
            {job.label}
          </div>
        );
      }

      return <></>;
    },
    Filter: TextFilter,
    filterType: "text",
    label: "Fiche de poste",
    width: 300,
  },
  {
    accessor: "actions",
    disableFilters: true,
    disableSortBy: true,
    label: "Actions",
    showOverflow: true,
    width: 100,
  },
];

const ListPlaceholder = () => <List rows={[]} columns={columns} />;

const FilteredList = ({
  searchQuery,
  showAssignmentModal,
}: {
  searchQuery: string;
  showAssignmentModal: (employee: Employee) => void;
}) => {
  const { baseUrl } = useBaseUrl();
  const employees = useRecoilValue(employeesAtom);
  const [displayedEmployees, setDisplayedEmployees] = useState<Employee[]>([]);

  useEffect(() => {
    let employeesToOrder = employees ?? [];

    if (searchQuery?.length) {
      employeesToOrder = employeesToOrder.filter((e) =>
        `${e.fullName?.toLowerCase()}${e.email.toLowerCase()}${e.job?.label.toLowerCase()}`.includes(
          searchQuery
        )
      );
    }

    const orderedEmployees = orderBy(employeesToOrder, (e) => e.fullName?.toLowerCase(), ["asc"]);
    setDisplayedEmployees(orderedEmployees);
  }, [searchQuery, setDisplayedEmployees, employees]);

  return (
    <List
      columns={columns}
      rows={displayedEmployees.map((employee, index) => ({
        id: employee.uuid,
        actions: (
          <DropDownMenu
            togglerClassName={styles.actionsToggler}
            items={[
              {
                icon: <FiPaperclip />,
                label: "Assigner une fiche de poste",
                onClick: () => showAssignmentModal(employee),
              },
            ]}
          >
            <IoMdMore size={16} />
          </DropDownMenu>
        ),
        baseUrl,
        email: employee.email ?? "",
        fullName: employee.fullName ?? "",
        job: employee.job,
        jobLabel: employee.job?.label ?? "",
        role: employee.role ?? "",
      }))}
    />
  );
};
export default Employees;
