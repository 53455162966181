import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import cx from "classnames";

import { DSButton } from "@skillup/ui";

import type { FieldRoutesType } from "types/api";
import { ConfigurationRoutes } from "@skillup/espace-rh-bridge";

import { useTypedFetch } from "hooks";

import styles from "./DragAndDropFieldsLayout.module.scss";

const displayType = (type: string) =>
  ({
    text: "Texte",
    textarea: "TextArea",
    date: "Date",
    monetary: "Prix",
    select: "Select",
    number: "Nombre",
  }[type] || "");

/** Drag & drop configuration */
// a little function to help us with reordering the result
const reorder = function <T>(list: T[], startIndex, endIndex): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const remove = function <T>(list: T[], index): T[] {
  const cleanedList = list.slice(0, index).concat(list.slice(index + 1));
  return cleanedList;
};

/**
 * Moves an item from one list to another list.
 */
const inject = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [injectedField] = sourceClone.splice(droppableSource.index, 1);

  const filteredData = destClone.filter(
    (e) => (e as any).binding !== (injectedField as any).binding
  );

  filteredData.splice(droppableDestination.index, 0, injectedField);

  return filteredData;
};

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#ebecf0",
  width: 250,
});

const getHorizontalListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#ebecf0",
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getHorizontalItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

type Schedule = ConfigurationRoutes.GetConfiguration["response"]["schedules"][0];

type Field = Schedule["tabs"]["collection"][0]["fields"][0];

interface ITabs {
  [x: string]: Array<Field>;
}

export interface Props {
  readonly tabs: ITabs;
  readonly setTabs: (tabs: ITabs) => void;
  readonly tabOrder: string[];
  readonly setHasChanged: (hasChanged: boolean) => void;
  readonly labels?: Record<string, string>;
  readonly hideHiddenMark?: boolean;
}

export default function DragAndDropLayout({
  tabs,
  setTabs,
  tabOrder,
  setHasChanged,
  labels,
  hideHiddenMark,
}: Props) {
  const { data: organizationFields } = useTypedFetch<FieldRoutesType.Get>({
    method: "GET",
    path: "/fields",
    query: { showDeletedFields: true, showHiddenFields: true },
  });
  const [showBindings, setShowBindings] = useState(false);

  const getList = (id): any => {
    if (id === "organizationFields") {
      // Moving in
      return organizationFields.fields;
    }
    return tabs[id];
  };

  const onDragEnd = (result) => {
    setHasChanged(true);
    const { source, destination } = result;

    // dropped outside the list
    if (!destination || destination?.droppableId === "organizationFields") {
      if (source.droppableId === "organizationFields") return;

      const updatedList = remove(getList(source.droppableId), source.index);
      setTabs({
        ...tabs,
        [source.droppableId]: updatedList,
      });

      return;
    }

    // Dropped inside the same list
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId !== "organizationFields") {
        const updatedFields = reorder(getList(source.droppableId), source.index, destination.index);
        setTabs({
          ...tabs,
          [destination.droppableId]: updatedFields,
        });
      }
    } else {
      if (source.droppableId === "organizationFields") {
        const updatedFields = inject(
          getList(source.droppableId),
          getList(destination.droppableId),
          source,
          destination
        );
        setTabs({
          ...tabs,
          [destination.droppableId]: updatedFields,
        });
      }
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={styles.dragzone}>
        <div className={styles.availableFields}>
          <Droppable droppableId="organizationFields" index={1}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                className={styles.section}
              >
                <h3>Champs disponibles</h3>
                <DSButton
                  label={showBindings ? "Cacher les bindings" : "Montrer les bindings"}
                  emphasis="Low"
                  buttonSize="S"
                  onClick={() => setShowBindings(!showBindings)}
                />
                <div className={styles.list}>
                  {(organizationFields?.fields || []).map((field, index) => (
                    <Draggable key={field.binding} draggableId={field.binding} index={index}>
                      {(provided, snapshot) => (
                        <div
                          key={field.binding}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          className={styles.field}
                        >
                          <div className={styles.fieldType}>
                            {displayType(field.type)}
                            {field.isHidden && !hideHiddenMark ? "- Champ Caché" : ""}
                          </div>
                          {showBindings && (
                            <div className={styles.fieldBinding}>{field.binding}</div>
                          )}
                          <div className={styles.fieldLabel}>{field.label}</div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className={styles.tabs}>
          {tabOrder.map((viewIndex) => {
            const view = tabs[viewIndex];
            return (
              <Droppable droppableId={viewIndex} key={viewIndex} direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getHorizontalListStyle(snapshot.isDraggingOver)}
                    className={styles.horizontal}
                  >
                    <h3>{labels ? labels[viewIndex] : viewIndex}</h3>
                    <div className={styles.list}>
                      {view?.map((field, index) => {
                        return (
                          <Draggable
                            key={`${viewIndex}-${field.binding}`}
                            draggableId={`${viewIndex}-${field.binding}`}
                            index={index}
                          >
                            {(dragProvided, snapshot) => (
                              <div
                                key={field.binding}
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                                style={getHorizontalItemStyle(
                                  snapshot.isDragging,
                                  dragProvided.draggableProps.style
                                )}
                                className={cx(styles.field, {
                                  [styles.isHidden]: field.isHidden,
                                })}
                              >
                                <div className={styles.fieldType}>
                                  {displayType(field.type)}
                                  {field.isHidden && !hideHiddenMark ? "- Champ Caché" : ""}
                                </div>
                                {showBindings && (
                                  <div className={styles.fieldBinding}>{field.binding}</div>
                                )}
                                <div className={styles.fieldLabel}>{field.label}</div>
                                {dragProvided.placeholder}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            );
          })}
        </div>
      </div>
    </DragDropContext>
  );
}
