export default {
  mapping: {
    blended: "Blended Learning",
    elearning: "E-Learning",
    individual: "Individuel",
    mooc: "MOOC",
    presential: "Présentiel",
    distancial: "Distanciel",
    digital: "Digital",
    "virtual-classroom": "Classe virtuelle",
    webinar: "Webinar",
    conference: "Conférence",
  },
  values: [
    "Blended Learning",
    "E-learning",
    "Individuel",
    "MOOC",
    "Présentiel",
    "Distanciel",
    "Digital",
    "Classe virtuelle",
    "Webinar",
    "Conférence",
  ],
};
