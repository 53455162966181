import { memo, useCallback, useMemo } from "react";
import cx from "classnames";

import { DSButton, DSTooltip, Flex, MaterialIcons } from "@skillup/ui";

import { ItemActions } from "../ItemActions";

import styles from "../../TemplateBuilderStructure.module.scss";

export const StructurePage = memo(function StructurePage({
  id,
  title,
  dragListeners,
  displayMenuOnHover = true,
  highlight = false,
  onClick,
  onFocus,
  showChildren,
  toggleShowChildren,
  onDuplication,
  onDeletion,
  errors,
  showActionsButtons = false,
}: {
  id?: string;
  title: string;
  dragListeners?: any;
  displayMenuOnHover?: boolean;
  highlight?: boolean;
  onClick?: () => void;
  onFocus?: () => void;
  showChildren?: boolean;
  toggleShowChildren?: () => void;
  onDuplication?: () => void;
  onDeletion?: () => void;
  errors?: string[];
  showActionsButtons?: boolean;
}) {
  const handleToggleShowChildren = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      toggleShowChildren && toggleShowChildren();
    },
    [toggleShowChildren]
  );
  const emptyTitle = useMemo(() => title.length === 0, [title]);
  return (
    <Flex
      row
      className={cx(
        highlight ? styles.highlight : "",
        styles.subsection + (displayMenuOnHover ? " " + styles["displayMenuOnHover"] : ""),
        errors?.length ? styles["error"] : ""
      )}
      id={id}
      tabIndex={0}
      onClick={onClick}
      onFocus={onFocus}
    >
      <DSButton
        iconOnly
        buttonSize="S"
        icon={<MaterialIcons.DragIndicator />}
        className={cx(styles["drag-indicator"], errors?.length ? styles["errors"] : "")}
        {...dragListeners}
      />
      <DSButton
        iconOnly
        buttonSize="M"
        icon={showChildren ? <MaterialIcons.ExpandMore /> : <MaterialIcons.ChevronRight />}
        className={styles["arrow"]}
        onClick={handleToggleShowChildren}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      />
      {errors?.length > 0 && (
        <DSTooltip label={errors[0]}>
          <MaterialIcons.Error
            className={cx(styles["error-icon"], styles["error-icon-structural-item"])}
          />
        </DSTooltip>
      )}
      <div
        className={cx(styles["ellipsisBlock"], emptyTitle ? styles["emptySubSectionTitle"] : "")}
      >
        {emptyTitle ? "Sans libellé" : title}{" "}
      </div>
      <Flex grow />
      <ItemActions
        deletionTooltipLabel="Supprimer la sous-section et son contenu"
        duplicationTooltipLabel="Dupliquer la sous-section et son contenu"
        showActionsButtons={showActionsButtons}
        onDuplication={onDuplication}
        onDeletion={onDeletion}
      />
    </Flex>
  );
});
