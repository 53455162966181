import { Timeline } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";
import { DatasetKey, FieldKey, TimelineRoutes } from "@skillup/core-hr-bridge";

import useTranslation from "hooks/useTranslation";
type Props = {
  fieldKey: FieldKey;
  fieldData: TimelineRoutes<DatasetKey>["GetTimelineForFieldsAsHR"]["response"][FieldKey];
};

export const FieldTimeline = ({ fieldKey, fieldData }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex padding="xs" flexDirection="column" flex={"1"} minWidth={"250px"}>
      <Text espaceFont="h6">{fieldKey}</Text>
      {fieldData.length === 0 ? (
        <Text espaceFont="body1Regular">
          {t("collab.historic.fieldskeys.timeline.noData", {
            defaultValue: "Aucune donnée pour ce champs",
          })}
        </Text>
      ) : (
        <Timeline
          size="M"
          items={fieldData.map((d) => ({
            timePeriod: (
              <Flex marginTop="xs">
                <p>{new Date(d.startDate).toLocaleDateString()}</p>
              </Flex>
            ),
            content: (
              <Flex marginTop="xs">
                <p>{d.data}</p>
              </Flex>
            ),
          }))}
        />
      )}
    </Flex>
  );
};
