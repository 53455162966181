import React from "react";

import { type Theme, createTheme, ThemeProvider } from "@mui/material";

import { type Themes } from "commons";
import { EspaceFont, FontFamily } from "commons/typography";

import { useTheme } from "./themes";
import { DSColor } from "commons/colors";
import { SkuiSpacing } from "commons/spacing";

/**
 * This function creates a MUI theme from the context data.
 * First usage will be to create a theme that will apply the right font family
 * But it can be used to apply any other context style to the MUI theme.
 * @param theme The context data
 * @returns The MUI theme
 */
export const createMuiThemeFromContext = (theme: Themes): Theme => {
  const themeKey = theme === "espace-rh" ? "rh" : "collab";

  return createTheme({
    typography: {
      fontFamily: FontFamily[themeKey],
      h1: EspaceFont[themeKey].h1,
      h2: EspaceFont[themeKey].h2,
      h3: EspaceFont[themeKey].h3,
      h4: EspaceFont[themeKey].h4,
      h5: EspaceFont[themeKey].h5,
      h6: EspaceFont[themeKey].h6,
    },
    components: {
      MuiPopper: {
        styleOverrides: {
          root: {
            borderRadius: SkuiSpacing.xs,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            "::placeholder": {
              ...EspaceFont[themeKey].body1Italic,
              color: DSColor["placeholder-onLight"],
            },
          },
          root: {
            borderRadius: "2px",
            input: {
              padding: `${SkuiSpacing.xs} ${SkuiSpacing.xs} ${SkuiSpacing.xs} ${SkuiSpacing.s}`,
            },
            "& > fieldset": {
              border: `1px solid ${DSColor["greyscale-medium"]}`,
              transition: "all 200ms",
              borderRadius: "2px",
              "& > legend > span": {
                ...EspaceFont[themeKey].body1Regular,
                lineHeight: 0,
              },
            },
            "&:hover, &:focus, &:active": {
              "> fieldset.MuiOutlinedInput-notchedOutline": {
                borderColor: DSColor["greyscale-dark"],
              },
            },
            "&.Mui-focused": {
              "fieldset.MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${DSColor["greyscale-dark"]}`,
              },
            },
          },
        },
      },
    },
  });
};

export const MuiThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const muiTheme = useMuiTheme();

  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;
};

export const useMuiTheme = () => {
  const theme = useTheme();
  return createMuiThemeFromContext(theme);
};
