import { TargetsRoutes } from "@skillup/espace-rh-bridge";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { buildRequest } from "utils/buildRequest";

export interface QueryAction {
  type: "sort" | "pagination" | "filter";
  sort?: string;
  order?: "ASC" | "DESC";
  page?: number;
  pageSize?: number;
  filter?: {
    isEvaluated: boolean;
    name?: string;
    targetCategoryLabel?: string;
    campaigns?: string[];
  };
}

export interface QueryOptions {
  sort?: string;
  order?: "ASC" | "DESC";
  limit?: number;
  offset?: number;
  filter?: string;
}

async function getUserTargetsCompletion({
  userUuid,
  queryOptions,
}: {
  userUuid: string;
  queryOptions: QueryOptions;
}) {
  try {
    const result = await buildRequest<TargetsRoutes.GetGlobalCompletionRateByUser>({
      method: "GET",
      path: "/user/{userUuid}/global-completion-rate",
      params: { userUuid },
      query: queryOptions,
    })();
    return result;
  } catch (err) {
    console.error(err, "getUserTargetsCompletion");
    return {};
  }
}

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};
const defaultOptions: Options = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export function useTargetsCompletion({
  userUuid,
  queryOptions,
}: {
  userUuid: string;
  queryOptions: QueryOptions;
}) {
  const { data, isLoading } = useQuery(
    ["targetsCompletion", userUuid, queryOptions],
    async () => getUserTargetsCompletion({ userUuid, queryOptions }),
    {
      ...defaultOptions,
    }
  );

  const [completion, setCompletion] = useState<any>({ numeric: undefined, ordinal: undefined });

  useEffect(() => {
    if (isLoading) return;
    setCompletion({
      numeric: data?.numeric,
      ordinal: !!data && Object.keys(data?.ordinal).length !== 0 ? data?.ordinal : undefined,
    });
  }, [data, isLoading]);

  return completion;
}
