import { SkuiSpacing, DSColor } from "@skillup/ui";
import styled from "styled-components";

export const LegendParent = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const LegendContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: ${SkuiSpacing.m};
  padding-left: ${SkuiSpacing.l};
`;

export const LevelGetIcon = styled.div`
  border-radius: 4px;
  height: 0.5rem;
  width: 8px;
  background: ${DSColor["action-onLight-default"]};
`;

export const LevelExpectedIcon = styled.div`
  border-top: solid 7px ${DSColor["decoration-red-darker"]};
  border-left: solid 4px rgba(0, 0, 0, 0);
  border-right: solid 4px rgba(0, 0, 0, 0);
`;
