import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTable, useFilters, useSortBy, useBlockLayout, useRowSelect } from "react-table";
import { FixedSizeList } from "react-window";
import { useQuery } from "@skillup/hooks";

import { InputFilter } from "./components";
import styles from "./DataTable.module.scss";
import { FiltersSummary } from "./components/Filters";

interface Props {
  columns: any[];
  data: Record<string, any>[];
  initialState?: Record<string, any>;
  initialData?: any;
  row: (props: any) => JSX.Element;
  sidePanel?: (props: any) => JSX.Element;
}

export default ({ columns, data, initialState, sidePanel, row, initialData }: Props) => {
  if (!data) {
    return null;
  }
  const dataTableRef = useRef<HTMLDivElement>();
  const headerHeightRef = useRef<HTMLDivElement>();
  const rowsCtnRef = useRef<HTMLDivElement>();
  const headerGroupRef = useRef<HTMLDivElement>();
  const [wrapperHeight, setWrapperHeight] = useState({ height: 0, header: 0 });

  useEffect(() => {
    function handleScroll(evt) {
      if (headerGroupRef.current) {
        headerGroupRef.current.style.left = `${-evt.target.scrollLeft}px`;
      }
    }

    function handleResize() {
      setWrapperHeight({
        height: dataTableRef?.current?.clientHeight,
        header: headerHeightRef?.current?.clientHeight,
      });
    }
    const div = rowsCtnRef.current;

    setWrapperHeight({
      height: dataTableRef?.current?.clientHeight,
      header: headerHeightRef?.current?.clientHeight,
    });

    if (!div) return undefined;

    div.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return function cleanup() {
      div.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { sortBy, ...filters } = useQuery<any>();

  const defaultColumn = useMemo(
    () => ({
      width: `calc(100% / ${columns.length - 1})`,
      Filter: InputFilter,
    }),
    [columns.length]
  );

  const computedFilters = useMemo(
    () => Object.entries(filters).map(([id, value]) => ({ id, value })),
    [filters]
  );
  const computedSortBy = useMemo(
    () =>
      Object.entries(sortBy ?? {}).map(([id, value]) => ({
        id,
        desc: value === "false" ? false : true,
      })),
    [sortBy]
  );

  const datatableHeight = useMemo(() => {
    const usableHeight = wrapperHeight.height - wrapperHeight.header;
    if (computedFilters?.length && computedSortBy?.length) {
      return usableHeight - 70;
    }

    if (computedFilters?.length || computedSortBy?.length) {
      return usableHeight - 50;
    }

    return usableHeight;
  }, [wrapperHeight, computedFilters, computedSortBy]);

  const datatableWidth = useMemo(() => {
    let res = 0;
    for (const column of columns) res += column.width;
    return res;
  }, [columns]);

  const defaultInitialState = {
    ...initialState,
    filters: computedFilters,
    sortBy: computedSortBy,
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleRowSelected } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        initialState: defaultInitialState,
        initialData,
      },
      useFilters,
      useSortBy,
      useBlockLayout,
      useRowSelect
    ) as any;

  return (
    <div className={styles.dataTable} ref={dataTableRef}>
      <div className={styles.dataTableModule} {...getTableProps()}>
        <FiltersSummary columns={columns} />
        {sidePanel && sidePanel({ toggleRowSelected })}
        <div {...getTableBodyProps()} className={styles.Rows}>
          <div className={styles.Header} ref={headerHeightRef}>
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                ref={headerGroupRef}
                className={styles.subHeader}
              >
                {headerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps()} className={styles.column}>
                    <p>{column.render("Header")}</p>
                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <FixedSizeList
            width={datatableWidth}
            height={datatableHeight}
            itemCount={rows.length}
            itemSize={60}
            outerRef={rowsCtnRef}
          >
            {(props) =>
              row({ ...props, style: { ...props.style, width: datatableWidth }, rows, prepareRow })
            }
          </FixedSizeList>
        </div>
      </div>
    </div>
  );
};
