import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { MdEdit, MdClose } from "react-icons/md";
import { MaterialIcons, colors } from "../../../../index";
import { Tooltip } from "components/Tooltip";
import { SkillLevel } from "../SkillLevel";

import styles from "./SkillItem.module.scss";

interface SkillItemValueProps {
  readonly name: string;
  readonly skillUuid: string;
  readonly level?: number;
  readonly totalLevel: Array<{ level: number }>;
  readonly generated: boolean;
}

interface SkillItemProps {
  readonly skill: SkillItemValueProps;
  readonly onEdit: (skillUuid: string) => void;
  readonly onRemove: (skillUuid: string) => void;
}

const SkillItem = ({ skill, onEdit, onRemove }: SkillItemProps) => {
  const { t } = useTranslation();

  const handleEdit = () => {
    onEdit(skill.skillUuid);
  };

  const handleRemove = () => {
    onRemove(skill.skillUuid);
  };

  return (
    <div className={styles.container}>
      {skill.generated && (
        <span className={styles.icon}>
          <MaterialIcons.TipsAndUpdates color={colors.blueyGrey} />
        </span>
      )}
      <div className={cx(styles.skillContainer, { [styles.actionBorder]: skill.generated })}>
        <div className={styles.skillName}>
          <Tooltip className={styles.tooltip} direction="top" label={skill.name} disabled={true}>
            <div className={styles.skillNameOverflow}>{skill.name}</div>
          </Tooltip>
        </div>
        <div className={styles.content}>
          <SkillLevel level={skill.level} totalLevel={skill.totalLevel} />
          <div className={styles.svgContainer}>
            <div onClick={handleEdit}>
              <Tooltip
                label={t("skillListDetail.tooltip.editExpectedLevel", {
                  defaultValue: "Modifier le niveau attendu",
                })}
                direction="bottom"
              >
                <MdEdit />
              </Tooltip>
            </div>
            <div onClick={handleRemove}>
              <Tooltip
                label={t("skillListDetail.tooltip.removeSkill", {
                  defaultValue: "Retirer la compétence",
                })}
                direction="bottom"
              >
                <MdClose />
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SkillItem };
