import { get } from "lodash";
import {
  type FilterConfiguration,
  type FilterConfigurationMap,
  type FilterType,
  type FilterValue,
  type FilterValues,
} from "./types";
import { IFilterManager } from "./IFilterManager";

export class InMemoryDatasetFilter<
  T extends object,
  Config extends FilterConfigurationMap,
> extends IFilterManager<(data: T[]) => T[], Config> {
  constructor(
    configuration: Config,
    private readonly dataset: T[],
    private readonly dataAccessor?: string
  ) {
    super(configuration);
  }

  override buildMultiSelectFilter(
    id: string,
    _filter: FilterConfiguration<typeof FilterType.MULTISELECT>,
    filterValue: FilterValue<typeof FilterType.MULTISELECT>
  ) {
    switch (filterValue.operator) {
      case "contains":
        return (data: T[]) =>
          data.filter((item) =>
            filterValue.value!.some(
              (value) => get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id) === value
            )
          );
    }
  }

  override buildTextFilter(
    id: string,
    _filter: FilterConfiguration<typeof FilterType.TEXT>,
    filterValue: FilterValue<typeof FilterType.TEXT>
  ) {
    switch (filterValue.operator) {
      case "contains":
        return (data: T[]) =>
          data.filter((item) =>
            get(item, this.dataAccessor ? `${this.dataAccessor}.${id}` : id)
              ?.toString()
              .toLowerCase()
              .includes(filterValue.value!.toString().toLowerCase())
          );
    }
  }

  public filterDataSet(filterValues: FilterValues<Config>) {
    const filterMethods = Object.keys(this.filterConfig)
      .filter((filterId) => !!filterValues[filterId] && filterValues[filterId].value !== undefined)
      .map((filterId) => this.buildFilter(filterId, filterValues[filterId]))
      .filter((filter) => !!filter);

    return filterMethods.reduce((acc, filter) => filter!(acc), [...this.dataset]);
  }
}
