import React, { useEffect } from "react";
import { useSetState } from "react-use";
import { useDropzone } from "react-dropzone";
import isEmpty from "lodash/isEmpty";

import type { PortailUploadImage } from "types/api";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import Loader from "components/Loader";
import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";

import LoadingError from "./LoadingError";
import { buildFileRequest } from "utils/buildRequest";

import styles from "./PortalSettingsStyles.module.scss";

export interface IPortalData {
  portalLogo: string;
  portalBackground: string;
  portalIntroMessage: string;
  portalIntroMessageColor: string;
  portalIntroButtonColor: string;
  portalIntroButtonBackgroundColor: string;
}

interface IState {
  PortalData?: IPortalData;
  loadingError?: boolean;
  backgroundLoading?: boolean;
  logoLoading?: boolean;
  previewBackgroundColour: string;
  portalBackgroundLoading?: boolean;
  portalLogoLoading?: boolean;
}

const PortalSettings = () => {
  const userData = Acta.getState("userData");
  const companyUuid = userData.activeCompany.uuid;

  const [state, setState] = useSetState<IState>({
    previewBackgroundColour: "#888",
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        const { properties } = (await DataLayer.request({
          url: "/v1/portal/settings",
        })) as { properties: any };
        setState({
          PortalData: properties,
        });
      } catch (error) {
        setState({ loadingError: true });
      }
    };
    fetch();
  }, [setState]);

  const onUpdateIntroText = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    try {
      const portalIntroMessageColor = isEmpty(
        event.currentTarget.elements["portalIntroMessageColor"].value
      )
        ? null
        : event.currentTarget.elements["portalIntroMessageColor"].value;

      const portalIntroButtonColor = isEmpty(
        event.currentTarget.elements["portalIntroButtonColor"].value
      )
        ? null
        : event.currentTarget.elements["portalIntroButtonColor"].value;

      const portalIntroButtonBackgroundColor = isEmpty(
        event.currentTarget.elements["portalIntroButtonBackgroundColor"].value
      )
        ? null
        : event.currentTarget.elements["portalIntroButtonBackgroundColor"].value;

      const body = JSON.stringify({
        portalIntroMessage: event.currentTarget.elements["portalIntroMessage"].value,
        portalIntroMessageColor,
        portalIntroButtonColor,
        portalIntroButtonBackgroundColor,
      });
      await DataLayer.request({
        body,
        method: "POST",
        url: "/v1/portal/settings",
      });
      Acta.dispatchEvent("sendAppMessage", {
        message: "Texte sauvegardé.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Échec d’ennregistrement.",
        type: "error",
      });
    }
  };

  const onDropImage =
    (companyUuid: string) =>
    async (files: Array<File>, target: "portalBackground" | "portalLogo"): Promise<void> => {
      setState({
        [`${target}Loading`]: true,
      });

      try {
        const data = await buildFileRequest<PortailUploadImage>({
          method: "POST",
          path: "/portal/settings/image/{companyUuid?}",
          params: { companyUuid },
          query: { target },
          file: files[0],
        })();

        setState({
          [`${target}Loading`]: false,
          PortalData: {
            ...state.PortalData,
            //@ts-ignore
            ...data,
          },
        });
        Acta.dispatchEvent("sendAppMessage", {
          message: "Image mise à jour.",
          type: "success",
        });
      } catch (error) {
        setState({
          [`${target}Loading`]: false,
        });
        Acta.dispatchEvent("sendAppMessage", {
          message: "Échec de l’upload.",
          type: "error",
        });
      }
    };

  const updateValue = (e) => {
    const { value, name } = e.target;
    setState({
      PortalData: {
        ...state.PortalData,
        [name]: value,
      },
    });
  };

  const { PortalData, loadingError, backgroundLoading, logoLoading } = state;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => onDropImage(companyUuid)(files, "portalBackground"),
    maxSize: 52428800,
    multiple: false,
  });

  const { getRootProps: getRootPropsPortalLogo, getInputProps: getInputPropsPortalLogo } =
    useDropzone({ onDrop: (files) => onDropImage(companyUuid)(files, "portalLogo") });

  if (loadingError) return <LoadingError />;

  if (!PortalData) {
    return (
      <div className={styles.errorContainer}>
        <Loader />
      </div>
    );
  }

  const style = {
    backgroundColor: "#ccc",
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    color: PortalData.portalIntroMessageColor || "#fff",
    fontSize: 32,
    fontWeight: 500,
    padding: "60px 0 60px 50%",
    height: 332,
    backgroundImage: `url(${process.env.PUBLIC_UPLOADS_URL}${PortalData.portalBackground})`,
  };

  return (
    <>
      <div style={style}>
        <div style={{ width: 300 }}>
          <p>{PortalData.portalIntroMessage}</p>
          <InteractiveButton
            style={{ width: "80%", margin: 0, marginTop: 20 }}
            label="En savoir plus"
            background={PortalData.portalIntroButtonColor}
            color={PortalData.portalIntroButtonBackgroundColor}
          />
        </div>
      </div>
      <div className={styles.PortalSettings}>
        <form onSubmit={onUpdateIntroText}>
          <TextInput
            label="Texte d’introduction"
            type="textarea"
            placeholder="Le texte qui sera affiché pour le portail utilisateur"
            alwaysOpen={true}
            onChange={updateValue}
            defaultValue={PortalData.portalIntroMessage}
            name="portalIntroMessage"
          />
          <TextInput
            label="Couleur du titre"
            type="text"
            placeholder="La couleur du texte, ex: #069 ou #ffffff"
            alwaysOpen
            onChange={updateValue}
            defaultValue={PortalData.portalIntroMessageColor}
            name="portalIntroMessageColor"
          />
          <TextInput
            label="Tu peux aussi changer la couleur du bouton en dessous du texte"
            type="text"
            alwaysOpen
            onChange={updateValue}
            defaultValue={PortalData.portalIntroButtonColor}
            name="portalIntroButtonColor"
          />
          <TextInput
            label="Et la couleur du texte du bouton"
            type="text"
            alwaysOpen
            onChange={updateValue}
            defaultValue={PortalData.portalIntroButtonBackgroundColor}
            name="portalIntroButtonBackgroundColor"
          />
          <InteractiveButton label="Sauver" type="submit" />
        </form>
        <section>
          <h2>Image de fond pour le header du portail</h2>
          {backgroundLoading ? (
            <Loader />
          ) : (
            <>
              <img src={`${process.env.PUBLIC_UPLOADS_URL}${PortalData.portalBackground}`} alt="" />
              <section className={styles.dropZone}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {<p>Déposez une image ici ou cliquez ici pour choisir une image</p>}
                </div>
              </section>
            </>
          )}
        </section>
        <section>
          <h2>Logo de la société</h2>
          {logoLoading ? (
            <Loader />
          ) : (
            <>
              <img alt="logo" src={`${process.env.PUBLIC_UPLOADS_URL}${PortalData.portalLogo}`} />
              <section className={styles.dropZone}>
                <div {...getRootPropsPortalLogo()}>
                  <input {...getInputPropsPortalLogo()} />
                  {<p>Déposez une image ici ou cliquez ici pour choisir une image</p>}
                </div>
              </section>
            </>
          )}
        </section>
      </div>
    </>
  );
};

export default PortalSettings;
