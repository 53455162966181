import LoaderShimmer from "components/LoaderShimmer";

import styles from "./SupervisorTrainingScheduleLoader.module.scss";

export default () => (
  <div className={styles.SupervisorTrainingScheduleLoader}>
    <LoaderShimmer className={styles.loader} />
    <p>Chargement en cours…</p>
  </div>
);
