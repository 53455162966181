import { SkillAnalytic } from "types/skills";

const parseSkillIntoRow = (skill: SkillAnalytic) => {
  const {
    uuid,
    averageEmployeesExpectedLevelDifference,
    averageEvaluationScore,
    averageExpectedLevelDifference,
    category,
    employeesEvaluated,
    name,
  } = skill;

  const rowData = {
    uuid,
    averageEmployeesExpectedLevelDifference,
    averageEvaluationScore,
    averageExpectedLevelDifference,
    category,
    employeesEvaluated,
    name,
  };

  return {
    ...rowData,
    id: uuid,
    averageEmployeesExpectedLevelDifference: skill.averageEmployeesExpectedLevelDifference
      ? {
          percentage: Math.round(skill.averageEmployeesExpectedLevelDifference?.percentage) ?? 0,
          values: skill.averageEmployeesExpectedLevelDifference?.values ?? "",
        }
      : null,
  };
};

export default parseSkillIntoRow;

export type SkillRow = ReturnType<typeof parseSkillIntoRow>;

export type SelectedSkillRowData = SkillRow;
