import styled, { css } from "styled-components";

import { DSColor } from "@skillup/ui";

type ColorDotProps = {
  color: string;
  selected: string;
};

const computeBoxShadow = (props: ColorDotProps) => {
  return props.selected === props.color
    ? css`
        box-shadow: inset 0 0 0 1px white, 0 0 0 1px ${DSColor["plainText-onLight-default"]};
      `
    : css`
        box-shadow: none;
      `;
};

export const ColorDot = styled.div<ColorDotProps>`
  align-items: center;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-right: 1.5rem;
  outline-offset: 1;
  width: 30px;
  ${computeBoxShadow}
`;
