import { DSDataGrid } from "@skillup/ui";
import useTableData from "../utils/useTableData";
import styles from "./DashboardDatagrid.module.scss";

const DashboardDatagrid = ({ data }) => {
  const { rows, columns } = useTableData(data);

  return (
    <DSDataGrid
      className={styles.DashboardDataGrid}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableToolbar
      hideFooter
      disableColumnSelector
      disableColumnMenu
      pinnedColumns={{
        left: ["Statut au plan"],
      }}
      getRowClassName={() => styles.Row}
    />
  );
};

export default DashboardDatagrid;
