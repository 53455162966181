import { useMemo } from "react";

import { DSAlert, DSAlertDisplay, DSAlertType, Loader } from "@skillup/ui";

import AdminPortalToggle, {
  CompanyAdminPayload,
  PortalToggleType,
} from "../AdminPortalToggle/AdminPortalToggle";
import useAdminProperties, { saveChanges } from "../state/useAdminProperties";

import styles from "./PortalFunctionnalities.module.scss";

export default function PortalFunctionnalities() {
  const { data, refetch, loading } = useAdminProperties();

  const adminProperties = useMemo(() => {
    return data?.properties ?? null;
  }, [data]);

  const handleOnChange = async (body: CompanyAdminPayload) => {
    await saveChanges(body);
    await refetch();
  };

  const alertContent = (
    <div>
      <p>
        La remontée libre n’est possible que si le recueil est activé pour le plan actif. Pour
        l’activer, allez dans{" "}
        <a target="_blank" href="/responsable/plans/onglets-plan-recueil">
          Configuration {">"} Plans {">"} Gestion des plans
        </a>
      </p>
    </div>
  );

  return (
    <div className={styles.PortalFunctionnalities}>
      <div className={styles.switchList}>
        {loading && <Loader />}
        {!loading && (
          <div className={styles.toggleItem}>
            <AdminPortalToggle
              propertiesKey="portalSearchDisabled"
              label="Activer la recherche dans le catalogue Skillup"
              type={PortalToggleType.DISABLED}
              isSelected={adminProperties ? adminProperties["portalSearchDisabled"] : false}
              onChange={handleOnChange}
              description="Permet à vos collaborateurs d'accéder aux programmes de formation de nos 200 organismes partenaires"
            />
          </div>
        )}
        {!loading && (
          <div className={styles.portalSpecifics}>
            <div className={styles.toggleItem}>
              <AdminPortalToggle
                propertiesKey="portalUsersSpecificTrainingEnabled"
                label="Activer la remontée libre dans l'espace collaborateurs"
                type={PortalToggleType.ENABLED}
                isSelected={
                  adminProperties ? adminProperties["portalUsersSpecificTrainingEnabled"] : false
                }
                onChange={handleOnChange}
                description="Permet à vos collaborateurs de faire remonter un besoin de formation sans programme
              associé"
              />
            </div>

            <div className={styles.toggleItem}>
              <AdminPortalToggle
                propertiesKey="portalManagersSpecificTrainingEnabled"
                label="Activer la remontée libre dans l'espace manager"
                type={PortalToggleType.ENABLED}
                isSelected={
                  adminProperties ? adminProperties["portalManagersSpecificTrainingEnabled"] : false
                }
                onChange={handleOnChange}
                description="Permet aux managers de faire remonter un besoin de formation sans programme associé"
              />
            </div>

            <div className={styles.toggleItem}>
              <AdminPortalToggle
                propertiesKey="portalHRSpecificTrainingEnabled"
                label='Afficher l’onglet "A requalifier" dans l’espace RH'
                type={PortalToggleType.ENABLED}
                isSelected={
                  adminProperties ? adminProperties["portalHRSpecificTrainingEnabled"] : false
                }
                onChange={handleOnChange}
                description='Permet d’afficher longlet "A requalifier" sur le recueil des besoins dans l’espace RH'
              />
            </div>
          </div>
        )}
      </div>
      <DSAlert className={styles.infoText} type={DSAlertType.INFO} display={DSAlertDisplay.INLINE}>
        {alertContent}
      </DSAlert>
    </div>
  );
}
