import { PlanType } from "./utils/exportCSV";

import PlanDashboard from "./PlanDashboard";
import CollectionDashboard from "./CollectionDashboard";

export interface IProps {
  type: PlanType;
  scheduleUuid: string;
}

const SupervisorTrainingDashboard = (props: IProps) => {
  if (props.type === "plan") {
    return <PlanDashboard scheduleUuid={props.scheduleUuid} />;
  }

  return <CollectionDashboard scheduleUuid={props.scheduleUuid} />;
};

export default SupervisorTrainingDashboard;
