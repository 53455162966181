import { useState } from "react";

import { PartialCancelParams } from "services/sessions/partialCancel";
import { PartialPostponeParams } from "services/sessions/partialPostpone";

import ParticipantsModal from "./ParticipantsModal";
import UnsubscribeModal from "./UnsubscribeModal";
import PostponeModal from "./PostponeModal";
import CancelModal from "./CancelModal";
import { Project, Trainee } from "./types";

export const ModalStates = {
  Idle: "Idle",
  Participants: "Participants",
  Unsubscribe: "Unsubscribe",
  Postpone: "Postpone",
  Cancel: "Cancel",
} as const;

export type State = { state: keyof typeof ModalStates; selectedParticipants: Trainee[] };

interface Props {
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  onClose: () => void;
  project: Project;
  actions: {
    partialCancel: (params: PartialCancelParams) => Promise<void>;
    partialPostpone: (params: PartialPostponeParams) => Promise<void>;
  };
}

export const ManageUsersModalWorkflow = ({ state, setState, onClose, project, actions }: Props) => {
  switch (state.state) {
    case ModalStates.Participants:
      return (
        <ParticipantsModal
          project={project}
          onSubmit={(participants: Trainee[]) =>
            setState({
              ...state,
              selectedParticipants: participants,
              state: ModalStates.Unsubscribe,
            })
          }
          onClose={onClose}
        />
      );
    case ModalStates.Unsubscribe:
      return (
        <UnsubscribeModal
          onSubmitCancel={() => setState({ ...state, state: ModalStates.Cancel })}
          onSubmitPostpone={() => setState({ ...state, state: ModalStates.Postpone })}
          onBack={() => setState({ ...state, state: ModalStates.Participants })}
          onClose={onClose}
          trainees={state.selectedParticipants}
        />
      );
    case ModalStates.Postpone:
      return (
        <PostponeModal
          onBack={() => setState({ ...state, state: ModalStates.Unsubscribe })}
          onClose={onClose}
          partialPostpone={actions.partialPostpone}
          trainees={state.selectedParticipants}
          project={project}
        />
      );
    case ModalStates.Cancel:
      return (
        <CancelModal
          project={project}
          trainees={state.selectedParticipants}
          onBack={() => setState({ ...state, state: ModalStates.Unsubscribe })}
          onClose={onClose}
          partialCancel={actions.partialCancel}
        />
      );
    default:
      return <></>;
  }
};

interface ContainerProps {
  onClose: () => void;
  project: Project;
  actions: {
    partialCancel: (params: PartialCancelParams) => Promise<void>;
    partialPostpone: (params: PartialPostponeParams) => Promise<void>;
  };
}
const ManageUsersModalWorkflowStateContainer = ({ onClose, project, actions }: ContainerProps) => {
  const [state, setState] = useState<State>({
    state: ModalStates.Participants,
    selectedParticipants: [],
  });

  return (
    <ManageUsersModalWorkflow
      state={state}
      setState={setState}
      onClose={onClose}
      project={project}
      actions={actions}
    />
  );
};

export default ManageUsersModalWorkflowStateContainer;
