import { Scopes } from "@skillup/espace-rh-bridge";
import { ModuleType } from "hooks/useSettings";

export type ScopeType = `${Scopes}`;

export const ALLOWED_ROLES = [
  "admin",
  "trainer",
  "admin-without-intras",
  "trainer-without-intras",
  "trainer-qhse",
  "custom",
];

export const rolesLabel = {
  admin: "Admin",
  "admin-without-intras": "Admin sans Programmes",
  trainer: "Formateur",
  "trainer-without-intras": "Formateur sans Programmes",
  "trainer-qhse": "Formateur QHSE",
  custom: "Custom",
};

export const parseLabel = (scopes?: Array<string>) => {
  if (scopes.length === 0) {
    return "";
  }

  if (scopes.includes("trainer")) {
    return rolesLabel["trainer"];
  }

  if (scopes.includes("admin-without-intras")) {
    return rolesLabel["admin-without-intras"];
  }

  if (scopes.includes("trainer-without-intras")) {
    return rolesLabel["trainer-without-intras"];
  }

  if (scopes.includes("trainer-qhse")) {
    return rolesLabel["trainer-qhse"];
  }

  if (scopes.includes("custom")) {
    return rolesLabel["custom"];
  }

  return rolesLabel["admin"];
};

type scopeMatrix = {
  key: string;
  label: string;
  modules: ModuleType[];
};
export const scopesMatrix: scopeMatrix[] = [
  {
    key: "admin",
    label: "Admin",
    modules: [
      "training-dashboard",
      "interview-dashboard",
      "trainings-schedule-plan",
      "trainings-schedule-collection",
      "trainings-regulatory-habilitations",
      "trainings-regulatory-trackings",
      "trainings-intras-organization",
      "trainings-intras-skillup",
      "trainings-sessions",
      "trainings-hot-evaluations",
      "trainings-cold-evaluations",
      "interviews-trackings",
      "interviews-templates",
      "skills-jobs",
      "skills-required",
      "skills-employees",
      "employees",
      "configuration-admin-platform",
      "configuration-plans",
      "configuration-portail",
      "configuration-imports",
      "campaign-creation",
      "users",
      "my-account",
      "access-list-v2",
    ],
  },
  {
    key: "admin-without-intras",
    label: "Admin sans Programmes",
    modules: [
      "training-dashboard",
      "interview-dashboard",
      "trainings-schedule-plan",
      "trainings-schedule-collection",
      "trainings-regulatory-habilitations",
      "trainings-regulatory-trackings",
      "trainings-intras-skillup",
      "trainings-sessions",
      "trainings-hot-evaluations",
      "trainings-cold-evaluations",
      "interviews-trackings",
      "interviews-templates",
      "skills-jobs",
      "skills-required",
      "skills-employees",
      "employees",
      "configuration-admin-platform",
      "configuration-plans",
      "configuration-portail",
      "configuration-imports",
      "campaign-creation",
      "users",
      "my-account",
      "access-list-v2",
    ],
  },
  {
    key: "trainer",
    label: "Formateur",
    modules: [
      "trainings-intras-organization",
      "trainings-sessions",
      "trainings-hot-evaluations",
      "trainings-cold-evaluations",
      "my-account",
      "access-list-v2",
    ],
  },
  {
    key: "trainer-without-intras",
    label: "Formateur sans Programmes",
    modules: [
      "trainings-sessions",
      "trainings-hot-evaluations",
      "trainings-cold-evaluations",
      "my-account",
      "access-list-v2",
    ],
  },
  {
    key: "trainer-qhse",
    label: "Formateur QHSE",
    modules: [
      "trainings-intras-organization",
      "trainings-regulatory-habilitations",
      "trainings-regulatory-trackings",
      "trainings-sessions",
      "trainings-hot-evaluations",
      "trainings-cold-evaluations",
      "my-account",
      "access-list-v2",
    ],
  },
];
