import React from "react";
import { ISimpleSelectionUser, ISimpleManager } from "@skillup/types";
import OldIcon from "components/Icon";
import { cross as crossIcon } from "uiAssets/StrokeIcons";
import { getUserInitials } from "utils/User";

import styles from "../AddTraineesContent.module.scss";
import { isPair, useUsersList } from "../add-trainees-context";

interface IProps {
  onRemove: (entry: { user: ISimpleSelectionUser; manager?: ISimpleManager }) => void;
  preselectedUsers?: Array<ISimpleSelectionUser>;
}

const SelectedUsersList = (props: IProps) => {
  const {
    state: { selectedUsers, selectedPairs },
  } = useUsersList();

  const renderUserInfo = (user: ISimpleSelectionUser, manager?: ISimpleManager) => {
    return (
      <>
        <div className={styles.userInitial}>
          {user.fullName ? (
            <p>{getUserInitials(user).toUpperCase()}</p>
          ) : (
            <p>{user.email[0].toUpperCase()}</p>
          )}
        </div>
        <div className={styles.userInfos}>
          {user.fullName ? (
            <div>
              <p>{user.fullName}</p>
              <span>{user.email}</span>
            </div>
          ) : (
            <p>{user.email}</p>
          )}
        </div>
        {!!manager && (
          <>
            <div className={styles.managerInitial}>
              {manager.fullName ? (
                <p>{getUserInitials(manager).toUpperCase()}</p>
              ) : (
                <p>{manager.email[0].toUpperCase()}</p>
              )}
            </div>
            <div className={styles.managerInfos}>
              {manager.fullName ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <p>{manager.fullName}</p>
                  <span>{manager.email}</span>
                </div>
              ) : (
                <p>{manager.email}</p>
              )}
            </div>
          </>
        )}
      </>
    );
  };

  const removeLine = (
    entry: ISimpleSelectionUser | { employee: ISimpleSelectionUser; manager: ISimpleManager }
  ) => {
    if (isPair(entry)) {
      props.onRemove({
        ...(entry.employee && { user: entry.employee }),
        ...(entry.manager && { manager: entry.manager }),
      });
      return;
    }
    props.onRemove({
      ...(entry && { user: entry }),
    });
  };

  const renderLi = (employee: ISimpleSelectionUser, manager?: ISimpleManager) => {
    if (!employee) {
      return undefined;
    }

    return (
      <li
        key={`${employee.email}-${employee.index}`}
        aria-label={employee.email}
        className={
          (!!employee.errors?.length || manager?.errors?.includes("not-found")) &&
          styles.errorOnEntry
        }
      >
        {renderUserInfo(employee, manager)}
        {!props.preselectedUsers?.some(
          (preselectedUser) => preselectedUser.email === employee.email
        ) || selectedPairs.length ? (
          <div
            className={styles.selectedUserCross}
            onClick={() => removeLine({ employee, manager })}
            aria-label={`supprimer-utilisateur-${employee.fullName}`}
          >
            <OldIcon strokeIcon={crossIcon} width={10} fill="#666" />
          </div>
        ) : (
          <div className={styles.selectedUsersState}>
            <p>{employee.type ?? undefined}</p>
          </div>
        )}
      </li>
    );
  };

  return (
    <>
      <div className={styles.selectedUsersList}>
        {(selectedUsers || []).map((user) => renderLi(user))}
        {(selectedPairs || []).map(({ employee, manager }) => renderLi(employee, manager))}
      </div>
    </>
  );
};

export default SelectedUsersList;
