import { useCallback, useMemo, useState } from "react";

import { Select, DSButton } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";
import { DatasetKey, FieldKey, TimelineRoutes } from "@skillup/core-hr-bridge";

import useTranslation from "hooks/useTranslation";
import { getTimelineForFields } from "services/coreHR/timeline/getTimelineForFields";
import { Collaborator } from "../../../api";
import { FieldTimeline } from "./Timeline";

type Props = {
  readonly user: Collaborator;
};

const FIELDS_KEYS: FieldKey[] = [
  "review.performance",
  "review.engagement",
  "review.flightRisk",
  "review.potential",
  "review.impact",
  "job.annualSalary",
  "job.monthlySalary",
  "job.hourlyRate",
  "job.jobTitle",
  "job.currency",
];

export const FieldsTimelines = ({ user }: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFieldsKeys, setSelectedFieldsKeys] = useState<FieldKey[]>([]);
  const [fieldsTimelines, setFieldsTimelines] =
    useState<TimelineRoutes<DatasetKey>["GetTimelineForFieldsAsHR"]["response"]>(undefined);

  const handleTimelineFields = useCallback(async () => {
    try {
      setLoading(true);
      const timelines = await getTimelineForFields({
        employeeID: user.uuid,
        fieldsKeys: selectedFieldsKeys,
      });
      setFieldsTimelines(timelines);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [user.uuid, selectedFieldsKeys]);

  const fieldsKey: FieldKey[] = useMemo(() => {
    return Object.keys(fieldsTimelines ?? {}) as FieldKey[];
  }, [fieldsTimelines]);

  return (
    <Flex padding="s" flexDirection="column">
      <Text espaceFont="h4">
        {t("collab.historic.fieldskeys.title", {
          defaultValue: "Historique des champ utilisateur",
        })}
      </Text>
      <Flex marginTop="s">
        <Select
          options={FIELDS_KEYS?.map((key) => ({
            label: key,
            value: key,
          }))}
          extraValuesLabel={(count) =>
            t("showmore.count", { count, defaultValue: "et {{count}} de plus" })
          }
          multi
          value={selectedFieldsKeys}
          onChange={(value) => {
            setSelectedFieldsKeys(value);
          }}
          selectPlaceholder={t("collab.historic.fieldskeys.nothingSelected", {
            defaultValue: "Aucun champs séléctionné",
          })}
        />
      </Flex>

      <Flex marginTop="s">
        <DSButton
          onClick={handleTimelineFields}
          disabled={selectedFieldsKeys.length === 0}
          label={t("collab.historic.fieldskeys.fetch", {
            defaultValue: "Recupérer les données",
          })}
          loading={loading}
        />
      </Flex>

      <Flex padding="xs" flexWrap="wrap">
        {fieldsKey?.map((field) => {
          return <FieldTimeline fieldKey={field} fieldData={fieldsTimelines[field]} />;
        })}
      </Flex>
    </Flex>
  );
};
