import { createMachine, assign } from "xstate";

type Context = {
  loading: boolean;
};
type Event = { type: "CONFIRM" } | { type: "BACK" } | { type: "ABORT" };
type TypeState =
  | { value: "idle"; context: Context }
  | { value: "loading"; context: Context }
  | { value: "done"; context: Context };

export const confirmMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QGMD2A7AZgSwE4FsBZAQ2QAtt0wA6bCAGzAGIBhAeQDkAxASQCVCAbQAMAXUSgADqljYALtgwSQAD0QBOAEzUALDoCsw4QGZD6-ZoCMOgBwAaEAE9EN7ZvXGbR0zu-qAvv4OaFh4RKQUVLQMzABCAIIsANIi4kgg0rIKSulqCFq6Bt5mFtb2Toia7tTGOgBsxsL6NjoA7JZ1reqtgcEYOAQk5JQ0dIxM8bFsfAAqqcqZ8oroynkFeoYmJVa2Ds4Ilq021Op1lo3G7cIerfq9ICED4cNR9KjEEJRQTBAYo+gAN1QAGsaBgWP0wvN0otsitcoh9JdqJo2mYjJo6jZ9Po9ogdNR9OZ6r5WsIGl4OvdHmEhpEaG8Pl8mGBcLhULhqJJ6MQ5JgOfhqODIQRoVIZEscqA1toNsVrqVdhUEABaSyWGo2SyaYSY7q3YzqcmBIIgdCoCBwZQ0wYREYLCVw1aIFV1AmWFrqbo47E3XHKlVelGnHXGM7GTRazR3U025706KMB1ZZbO1UdagenRe27NImXf37HXUVpndw2S4mcPGaki+MjaiMz7oKDJyXw6WVN3UM63YQ6dXqGytRqFxDGagY04WdTnAfWSy10K2l40X5UNtOhEIK4lsnCSz6dp1HGaVp4g76Am2BoHnRVQfWE3+IA */
  createMachine<Context, Event, TypeState>(
    {
      id: "confirmMachine",
      initial: "idle",
      states: {
        idle: {
          on: {
            CONFIRM: {
              target: "loading",
              actions: ["setLoading"],
            },
            BACK: {
              target: "done",
              actions: ["onBack"],
            },
            ABORT: {
              target: "done",
              actions: ["onClose"],
            },
          },
        },

        loading: {
          invoke: {
            id: "onConfirm",
            src: "onConfirm",
            onError: {
              target: "idle",
              actions: ["unsetLoading"],
            },
            onDone: {
              target: "done",
              actions: ["unsetLoading"],
            },
          },
        },

        done: {
          type: "final",
        },
      },
      context: { loading: false },
      predictableActionArguments: true,
      preserveActionOrder: true,
    },
    {
      actions: {
        setLoading: assign({
          // you can overload it in modal if side effect is needed
          loading: () => true,
        }),
        unsetLoading: assign({
          // you can overload it in modal if side effect is needed
          loading: () => false,
        }),
        onBack: () => {
          /** All the methods that throw here need to be injected
           * in the modal component, as a second argument of useMachine.
           * They will be called when the event is triggered so it is
           * mandatory.
           */
          throw new Error("Implement it in modal");
        },
        onClose: () => {
          throw new Error("Implement it in modal");
        },
      },
      services: {
        onConfirm: () => {
          throw new Error("Implement it in modal");
        },
      },
    }
  );
