import {
  atom,
  selector,
  selectorFamily,
  useRecoilState,
  useSetRecoilState,
  SetterOrUpdater,
} from "recoil";

import type { JobsRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";

type JobRoute = JobsRoutes["Jobs.GetOne"];
export type FormattedJob = JobRoute["response"]["job"];

const jobsListRequestId = atom<number>({
  default: 1,
  key: "jobsListRequestID",
});

const jobsListSelector = selector<FormattedJob[]>({
  key: "jobsListSelector",
  get: async ({ get }) => {
    get(jobsListRequestId);
    const request = buildRequest<JobsRoutes["Jobs.GetList"]>({
      method: "GET",
      path: "/competences/jobs",
    });
    const data = await request();

    return data?.jobs;
  },
});

export const jobsAtom = atom<FormattedJob[]>({
  key: "jobs",
  default: jobsListSelector,
});

export function useJobsState() {
  const [jobsListRequestID, updateJobListRequestID] = useRecoilState(jobsListRequestId);

  return {
    refreshList: () => updateJobListRequestID(jobsListRequestID + 1),
  };
}

const editJob =
  (jobUuid, setJobs: SetterOrUpdater<FormattedJob[]>) =>
  async (payload: JobsRoutes["Jobs.Edit"]["payload"]) => {
    const editRequest = await buildRequest<JobsRoutes["Jobs.Edit"]>({
      method: "POST",
      params: { jobUuid },
      path: "/competences/job/{jobUuid}",
      payload,
    });

    const response = await editRequest();
    if (!response.success) {
      throw new Error("did not fetch correctly");
    }

    const newJobDataRequest = await buildRequest<JobsRoutes["Jobs.GetOne"]>({
      method: "GET",
      params: { jobUuid },
      path: "/competences/jobs/{jobUuid}",
    });

    const newJobData = await newJobDataRequest();

    setJobs((jobs) =>
      jobs.map((job) => {
        if (job.uuid === jobUuid) return newJobData.job;

        return job;
      })
    );
  };

export function useJobState(jobUuid: string) {
  const setJobs = useSetRecoilState(jobsAtom);

  return {
    editJob: editJob(jobUuid, setJobs),
  };
}

const jobFieldsAsyncSelector = selector<JobFields>({
  key: "jobsFieldsAsyncSelector",
  get: async ({ get }) => {
    const request = buildRequest<JobsRoutes["Jobs.Fields.GetList"]>({
      method: "GET",
      path: "/competence/jobs/fields",
    });
    const data = await request();
    return data?.jobFields;
  },
});

type JobsFieldsListRoute = JobsRoutes["Jobs.Fields.GetList"];
export type JobFields = JobsFieldsListRoute["response"]["jobFields"];
export const jobFieldsAtom = atom<JobFields>({
  key: "jobFields",
  default: jobFieldsAsyncSelector,
});

type JobSelectorParams = { jobUuid: string };
export const getJobSelector = selectorFamily<FormattedJob, JobSelectorParams>({
  key: "getJob",
  get:
    ({ jobUuid }: JobSelectorParams) =>
    ({ get }) => {
      const jobs = get(jobsAtom);
      const job = jobs?.find((j) => j.uuid === jobUuid);
      return job;
    },
});
