import { CSVLink } from "react-csv";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useCallback } from "react";

import cx from "classnames";
import { Formik } from "formik";
import { isEmpty } from "lodash";

import {
  GridRowId,
  GridColDef,
  GridRowParams,
  useGridApiRef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";

import { Flex, Text } from "@skillup/design-system";
import { PeopleReviewRoutes } from "@skillup/espace-rh-bridge";
import {
  Link,
  DSAlert,
  FileDrop,
  DSButton,
  DSDataGrid,
  DSAlertType,
  DSAlertDisplay,
} from "@skillup/ui";

import { buildFileRequest } from "utils/buildRequest";
import { CampaignCreationState } from "types/peopleReview";

import { CollaboratorOwnManagerWarningAlert } from "../../../components/CollaboratorOwnManagerWarningAlert";
import { CollaboratorOwnManager } from "../../../components/CollaboratorOwnManagerWarningAlert/CollaboratorOwnManagerWarningAlert";

import styles from "./../steps.module.scss";

interface EmployeeInfos {
  uuid: string;
  email: string;
  fullName: string;
}

interface Pair {
  manager?: EmployeeInfos;
  collaborator?: EmployeeInfos;
}
interface TableRow {
  id: string;
  managerId: string;
  collaboratorEmail: string;
  managerEmail: null | string;
  collaboratorFullName: string;
  collaboratorId: null | string;
  managerFullName: null | string;
  isCollaboratorOwnManager: boolean;
}

const getFile = async (file) =>
  await buildFileRequest<PeopleReviewRoutes.UploadReviewedEmployees>({
    file,
    method: "POST",
    path: "/upload-reviewed-employees",
  })();

const fileRowsToTableRows = (attendees: Pair[]): (TableRow | undefined)[] => {
  return attendees.map((pair) => {
    if (pair.collaborator && pair.manager) {
      return {
        id: pair.collaborator.uuid,
        collaboratorEmail: pair.collaborator.email,
        collaboratorFullName: pair.collaborator.fullName,
        collaboratorId: pair.collaborator.uuid,
        isCollaboratorOwnManager: pair.collaborator.uuid === pair.manager.uuid,
        managerEmail: pair.manager.email,
        managerFullName: pair.manager.fullName,
        managerId: pair.manager.uuid,
      };
    }

    if (pair.collaborator && !pair.manager) {
      return {
        id: pair.collaborator.uuid,
        collaboratorEmail: pair.collaborator.email,
        collaboratorFullName: pair.collaborator.fullName,
        collaboratorId: pair.collaborator.uuid,
        isCollaboratorOwnManager: false,
        managerEmail: null,
        managerFullName: null,
        managerId: null,
      };
    }

    return undefined;
  });
};

interface AttendeesProps {
  file: File | null;
  hasManagers: boolean;
  prevStep: (data: Partial<CampaignCreationState>) => void;
  nextStep: (data: Partial<CampaignCreationState>) => void;
  selectedCollaboratorManagerPairs: CampaignCreationState["collaboratorManagerPairs"];
}

export default function Attendees({
  file,
  hasManagers,
  nextStep,
  prevStep,
  selectedCollaboratorManagerPairs,
}: AttendeesProps) {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const [rows, setRows] = useState<TableRow[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(file);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);

  const columns: GridColDef[] = [
    {
      field: "collaboratorFullName",
      flex: 1,
      headerName: "Collaborateur",
      renderCell: (data: GridRenderCellParams<TableRow, string>) => {
        return (
          <p
            className={cx({ [styles.invalidCollabManagerPair]: data.row.isCollaboratorOwnManager })}
          >
            {data.value}
          </p>
        );
      },
      resizable: false,
    },
    hasManagers && {
      field: "managerFullName",
      flex: 1,
      headerName: "Manager",
      renderCell: (data: GridRenderCellParams<TableRow, string>) => {
        return (
          <p
            className={cx({ [styles.invalidCollabManagerPair]: data.row.isCollaboratorOwnManager })}
          >
            {data.value}
          </p>
        );
      },
      resizable: false,
    },
  ].filter(Boolean);

  const handleFileUpload = useCallback(
    async (file: File) => {
      try {
        const attendees = await getFile(file);
        const tableRows = fileRowsToTableRows(attendees);
        // Filter out rows where the manager is the same as the collaborator
        const collaboratorsNotOwnManager = tableRows.filter(
          (row) => row.isCollaboratorOwnManager === false
        );
        setRows(tableRows);

        if (selectedCollaboratorManagerPairs?.length > 0) {
          apiRef.current.selectRows(
            selectedCollaboratorManagerPairs.map((pair) => pair.collaborator.id)
          );
        } else {
          apiRef.current.selectRows(collaboratorsNotOwnManager.map((pair) => pair.collaboratorId));
        }

        setIsError(false);
        setIsLoading(false);
      } catch (e) {
        setIsError(true);
        setIsLoading(false);
        setUploadedFile(null);
      }
    },
    [apiRef, selectedCollaboratorManagerPairs]
  );

  useEffect(() => {
    if (file) {
      setIsLoading(true);
      handleFileUpload(file);
    }
  }, [file, handleFileUpload]);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    maxSize: 52428800,
    multiple: false,
    onDrop: async (files: File[]) => {
      if (isLoading) return;
      setIsLoading(true);
      setUploadedFile(files[0]);
      handleFileUpload(files[0]);
    },
  });

  const hasManagersColumn = rows.some((row) => row.managerFullName);
  const collaboratorsOwnManager: CollaboratorOwnManager[] = rows
    .filter((row) => row.isCollaboratorOwnManager)
    .map((r) => ({
      id: r.id,
      collaboratorEmail: r.collaboratorEmail,
      collaboratorFullName: r.collaboratorFullName,
      collaboratorID: r.collaboratorId,
      isCollaboratorOwnManager: r.isCollaboratorOwnManager,
      managerEmail: r.managerEmail,
      managerFullName: r.managerFullName,
      managerID: r.managerId,
    }));

  return (
    <Formik
      validateOnMount={false}
      initialValues={[] as CampaignCreationState["collaboratorManagerPairs"]}
      onSubmit={() => {
        const selectedRowsMap = apiRef?.current?.getSelectedRows();
        const selectedPairsRowsValues = Array.from(selectedRowsMap.values()).filter(Boolean);
        const selectedReviewedPairs = selectedPairsRowsValues.map((pair) => ({
          collaborator: {
            id: pair.collaboratorId,
            fullName: pair.collaboratorFullName,
          },
          manager: pair?.managerId
            ? {
                id: pair.managerId,
                fullName: pair.managerFullName,
              }
            : null,
        }));

        nextStep({
          collaboratorManagerPairs: selectedReviewedPairs,
          uploadedFile: uploadedFile,
        });
      }}
    >
      {({ handleSubmit }) => (
        <Flex
          gap="l"
          width="100%"
          maxWidth="50rem"
          flexDirection="column"
          marginHorizontal="auto"
          className={styles.attendees}
        >
          {!uploadedFile ? (
            <Flex gap="l" flexDirection="column">
              <Flex gap="s" flexDirection="column">
                <FileDrop
                  rootProps={getRootProps()}
                  value={uploadedFile?.name}
                  isDragActive={isDragActive}
                  className={styles.fileDrop}
                  inputProps={getInputProps()}
                  btnLabel={t("peoplereview.upload.drop.button", {
                    defaultValue: "Choisir un fichier",
                  })}
                  dropLabel={t("peoplereview.upload.drop.message", {
                    defaultValue: "ou glisser déposer un fichier ici",
                  })}
                />
              </Flex>
              <Flex flexDirection="column">
                {hasManagers ? (
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peoplereview.upload.template.explanationText", {
                      defaultValue:
                        "Votre fichier doit comporter 1 ligne par collaborateur et 2 colonnes",
                    })}
                  </Text>
                ) : (
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peoplereview.upload.template.explanationText", {
                      defaultValue:
                        "Votre fichier doit comporter 1 ligne par collaborateur et 1 colonne",
                    })}
                  </Text>
                )}

                <ul className={styles.columnsText}>
                  <li>
                    <Text espaceFont="body1Regular" color="plainText-onLight-default">
                      {t("peoplereview.upload.template.explanationText.columnOne", {
                        defaultValue: "Colonne 1 : email du collaborateur",
                      })}
                    </Text>
                  </li>
                  {hasManagers && (
                    <li>
                      <Text espaceFont="body1Regular" color="plainText-onLight-default">
                        {t("peoplereview.upload.template.explanationText.columnTwo", {
                          defaultValue:
                            "Colonne 2 : email du manager en charge de la People Review du collaborateur",
                        })}
                      </Text>
                    </li>
                  )}
                </ul>
              </Flex>
              <CSVLink
                filename="template.csv"
                className={styles.getTemplateLink}
                data={[["collab_email", hasManagers && "manager_email"].filter(Boolean)]}
              >
                {t("peoplereview.upload.template.download.text", {
                  defaultValue: "Télécharger un template de fichier",
                })}
              </CSVLink>
            </Flex>
          ) : (
            <>
              <Flex
                gap="m"
                alignItems="center"
                justifyContent="center"
                className={styles.removeFileButton}
              >
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {uploadedFile.name}
                </Text>
                <DSButton
                  emphasis="Mid"
                  buttonSize="S"
                  onClick={() => {
                    setRows([]);
                    setUploadedFile(null);
                  }}
                  label={t("peoplereview.upload.removeFile", {
                    defaultValue: "Supprimer le fichier",
                  })}
                />
              </Flex>
              {!hasManagers && hasManagersColumn && (
                <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
                  {t("peoplereview.upload.template.download.hasError", {
                    defaultValue:
                      'Votre fichier contient une colonne "Manager". Vous pouvez activer l\'option de préparation manager à ',
                  })}
                  <Link
                    label="l'étape précédente."
                    className={styles.goToPreviousStepLink}
                    onClick={() => {
                      prevStep({
                        collaboratorManagerPairs: selectedCollaboratorManagerPairs,
                        uploadedFile: uploadedFile,
                      });
                    }}
                  />
                </DSAlert>
              )}

              {hasManagers && collaboratorsOwnManager.length > 0 && (
                <CollaboratorOwnManagerWarningAlert
                  collaboratorsOwnManager={collaboratorsOwnManager}
                  onDownload={() => null}
                />
              )}

              <DSDataGrid
                apiRef={apiRef}
                isError={isError}
                columns={columns}
                checkboxSelection
                rows={rows ?? []}
                hideFooter={true}
                loading={isLoading}
                onRowSelectionModelChange={(rowSelectionModel) =>
                  setSelectedRows(rowSelectionModel)
                }
                isRowSelectable={(params: GridRowParams<TableRow>) =>
                  !params.row.isCollaboratorOwnManager
                }
                entityName={t("peoplereview.creationStep.attendees.datagrid.entityName", {
                  count: rows?.length ?? 0,
                  defaultValue: "{{count}} collaborateurs",
                })}
                emptyOverlay={{
                  text: [
                    t("peopleReview.creationStep.attendees.emptyOverlayText.firstSentence", {
                      defaultValue: "Aucun collaborateur chargé pour le moment.",
                    }),
                    t("peopleReview.creationStep.attendees.emptyOverlayText.secondSentence", {
                      defaultValue: "Déposez un fichier correspondant au template pour commencer.",
                    }),
                  ],
                }}
                errorOverlay={{
                  text: [
                    t("peopleReview.creationStep.attendees.errorOverlayText.firstSentence", {
                      defaultValue: `Une erreur est survenue lors de l'import du fichier.`,
                    }),
                    t("peopleReview.creationStep.attendees.errorOverlayText.seconfSentence", {
                      defaultValue: `Merci de vérifier que votre fichier correspond au template`,
                    }),
                  ],
                }}
              />
            </>
          )}

          <Flex justifyContent="space-between">
            <DSButton
              emphasis="Low"
              label={t("previousStep", {
                defaultValue: "Précédent",
              })}
              onClick={() => {
                prevStep({
                  collaboratorManagerPairs: selectedCollaboratorManagerPairs,
                  uploadedFile: uploadedFile,
                });
              }}
            />
            <DSButton
              disabled={isError || isEmpty(selectedRows) || isLoading}
              onClick={() => handleSubmit()}
              label={t("nextStep", {
                defaultValue: "Suivant",
              })}
            />
          </Flex>
        </Flex>
      )}
    </Formik>
  );
}
