import { ColdReviewDetails, ReviewQuestion } from "@skillup/training-bridge";
import { matrixColdReviewComments, matrixColdReviewQuestionsAnswers } from "../utils/constants";
import { getCommentsQuestions } from "../utils/reviewDetailsQuestionsParser";
import styles from "./ColdReviewSidePanel.module.scss";

export interface Props {
  readonly reviewDetails: ColdReviewDetails;
  readonly reviewQuestions: ReviewQuestion[];
}

export const ContentQuestionsDetailsList = ({ reviewDetails, reviewQuestions }: Props) => {
  const comments = getCommentsQuestions(reviewDetails);

  return (
    <ul>
      {reviewQuestions.map((reviewQuestion) => {
        const scores = matrixColdReviewQuestionsAnswers[reviewQuestion.question];
        const comment = matrixColdReviewComments[reviewQuestion.question];

        if (!!scores && (reviewQuestion.type === "multiple" || reviewQuestion.type === "radio")) {
          const { score } = reviewQuestion;
          const label = scores[score];

          if (label) {
            const interpolatedLabel = label.replace("{user.name}", reviewDetails.trainee.fullName);
            const filteredComment = comments.find((c) => c.question === comment?.relate);

            if (!!filteredComment && filteredComment.type === "comment") {
              return (
                <>
                  <li>
                    {interpolatedLabel}
                    <p className={styles.commentQuestion}>
                      <span> {filteredComment?.comment} </span>
                    </p>
                  </li>
                </>
              );
            }

            return <li>{interpolatedLabel}</li>;
          }
        }

        return null;
      })}
    </ul>
  );
};

export default ContentQuestionsDetailsList;
