import type { OrganizationRoutesTypeUserGroup } from "types/api";

import { buildRequest } from "utils/buildRequest";

export default (groupName: string) => {
  return buildRequest<OrganizationRoutesTypeUserGroup.Create>({
    method: "POST",
    path: "/organization/group",
    payload: { groupName },
  })();
};
