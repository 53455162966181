import { Either } from "@skillup/monads";
import { ProjectRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

export type CancelParams = {
  projectUuid: string;
  comment?: string;
};
export async function cancelV2(params: CancelParams) {
  try {
    await buildRequest<ProjectRoutes.CancelProjectV2>({
      method: "DELETE",
      params: {
        uuid: params.projectUuid,
      },
      path: "/projects/{uuid}/v2",
      payload: {
        message: params.comment,
      },
    })();
    return Either.right(undefined);
  } catch (err) {
    return Either.left(undefined);
  }
}
