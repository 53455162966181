import DataLayer from "utils/DataLayer";
import { TPrimaryState } from "@skillup/types";

export interface IRelationCall {
  booking: string;
  leadInter: string;
  leadIntra: string;
  user: {
    noEmail?: boolean;
    noManager?: boolean;
    noEmailManager?: boolean;
  };
  uuid: string;
  primaryState: TPrimaryState;
}

export default async (rows: Array<string>, useCache: boolean) => {
  const data = (await DataLayer.request({
    body: JSON.stringify({ rows, useCache }),
    method: "POST",
    target: "API",
    url: `/v1/scheduleRow/relations`,
  })) as Array<IRelationCall>;

  return data;
};
