import { useMemo } from "react";

import { MaterialIcons, DSAvatarGroup, Select, ChipNumber } from "@skillup/ui";
import { renderCellDate } from "@skillup/shared-utils";

import type { Template } from "services/interviews";
import { getUserInitials } from "utils/User";

import styles from "./TemplatesList.module.scss";

export type CampaignTemplateRow = ReturnType<typeof parseTableRows>;

const useTableData = (
  templates: Array<Template>
): {
  data: CampaignTemplateRow[];
  columns: Array<{
    key: string;
    title: string;
    filterable: boolean;
    width: number;
  }>;
} => {
  const tableData = useMemo(
    () => ({
      columns: generateTableColumns(),
      data: generateTableRows(templates ?? []),
    }),
    [templates]
  );

  return tableData;
};

export default useTableData;

function generateTableRows(templates: Array<Template>) {
  return templates.map(parseTableRows);
}

function parseTableRows(template: Template) {
  const users = template.formattedLogs.contributors.map((contributor) => ({
    uuid: contributor.uuid,
    initials: getUserInitials({ fullName: contributor.fullName }),
    fullName: contributor.fullName,
  }));
  return {
    id: template.uuid,
    data: {
      title: template.title,
      type: template.type,
      campaigns: template.relatedCampaigns ? template.relatedCampaigns.length : 0,
      date: getLastUpdateDate(template),
      contributors: users.length ? users : [{ uuid: "", initials: "S", fullName: "Skillup" }],
    },
  };
}

function generateTableColumns() {
  const columns = [
    {
      key: "title",
      title: "Nom de la trame d'entretien",
      filterable: true,
      width: 15,
      sortFn: (a: CampaignTemplateRow, b: CampaignTemplateRow) =>
        a.data.title.localeCompare(b.data.title),
    },
    {
      key: "type",
      title: "Type d'entretien",
      filterable: true,
      width: 8,
      renderFilter: ({ value, onChange }) => {
        const options = [
          { label: "Entretien annuel", value: "Entretien annuel" },
          { label: "Entretien professionnel", value: "Entretien professionnel" },
          { label: "Bilan à 6 ans", value: "Bilan à 6 ans" },
          { label: "Autre", value: "Autre" },
        ];
        return (
          <div className={styles.selectFilter}>
            <Select
              multi
              allValuesLabel="Tous les types"
              value={value}
              options={options}
              onChange={onChange}
              extraValuesLabel={(count) => `${count} types sélectionnés`}
            />
          </div>
        );
      },
      filterFn: (row, values: Set<string>) => {
        return [...values].some((value) => row.data?.type?.toString() === value);
      },
      sortFn: (a: CampaignTemplateRow, b: CampaignTemplateRow) =>
        a.data.type?.toString().localeCompare(b.data.type?.toString()),
    },
    {
      key: "campaigns",
      title: "Nombre de campagnes utilisant cette trame",
      icon: <MaterialIcons.Link size="1rem" />,
      filterable: true,
      width: 2,
      sortFn: (a: CampaignTemplateRow, b: CampaignTemplateRow) =>
        a.data.campaigns - b.data.campaigns,
      renderCell: ({ campaigns }) => <ChipNumber value={campaigns} />,
    },
    {
      key: "date",
      title: "Date de la dernière modification",
      icon: <MaterialIcons.Event size="1rem" />,
      filterable: true,
      width: 4,
      sortFn: (a: CampaignTemplateRow, b: CampaignTemplateRow) => {
        const dateA = a.data.date;
        const dateB = b.data.date;
        return +dateA - +dateB;
      },
      renderCell: ({ date }) => <span>{renderCellDate(date)}</span>,
    },
    {
      key: "contributors",
      title: "Personne(s) ayant modifié la trame",
      icon: <MaterialIcons.Groups size="1rem" />,
      filterable: true,
      width: 6,
      sortFn: (a: CampaignTemplateRow, b: CampaignTemplateRow) => {
        if (a.data.contributors.length !== b.data.contributors.length) {
          return b.data.contributors.length - a.data.contributors.length;
        } else {
          return a.data.contributors[0].fullName.localeCompare(b.data.contributors[0].fullName);
        }
      },
      filterFn: (row: CampaignTemplateRow, filterValue: string) => {
        return row.data.contributors.some((contributor) =>
          contributor.fullName.toLowerCase().includes(filterValue.toLowerCase())
        );
      },
      renderCell: ({ contributors }) => (
        <DSAvatarGroup
          firstElementForeground
          users={contributors}
          maxItems={5}
          totalItems={contributors.length}
          size="S"
        />
      ),
    },
  ];
  return columns;
}

function getLastUpdateDate(template: Template) {
  return template.updatedAt ? new Date(template.updatedAt) : new Date(template.createdAt);
}
