import { ReviewActionState } from "@skillup/people-review-bridge";

import { CampaignEmployeesData } from "services/peopleReview/reviews/getReviewsData";

interface FieldsData {
  [fieldUuid: string]: {
    manager?: {
      createdAt?: Date;
      managerValue: null | number;
    };
    coordinator?: {
      createdAt?: Date;
      managerValue?: null | number;
      coordinatorValue: null | number;
    };
  };
}

type TalentGridRow = {
  id: string;
  role: string;
  email: string;
  status: string;
  userId: string;
  fullName: string;
  campaignId: string;
  fieldsData: FieldsData;
  registrationNumber: string;
  comment: string | undefined;
  managerPreparationComment: string | undefined;
  managerData: {
    id: string;
    fullName: string;
  };
  userData: CampaignEmployeesData["data"][number]["userData"];
  assignedActions: {
    actionID: string;
    managerComment?: string;
    state: ReviewActionState;
    coordinatorComment?: string;
    origin: "MANAGER" | "COORDINATOR";
  }[];
};

type TalentGridState =
  | { kind: "idle" }
  | { row: TalentGridRow; kind: "editingComment" }
  | { row: TalentGridRow; kind: "editingActions" }
  | { row: TalentGridRow; kind: "assigningActionsV2" }
  | { kind: "changeManager"; reviews: TalentGridRow[] };

  const PeopleReviewViews = {
    GRID: "grid",
    MATRIX: "matrix",
  } as const;

  type TPeopleReviewViews = Lowercase<keyof typeof PeopleReviewViews>;

  export { PeopleReviewViews };

  export type { TalentGridRow, TalentGridState, TPeopleReviewViews };
