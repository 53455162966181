import { useContext } from "react";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Flex } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";

import { TalentGridRow } from "../../../types";
import { DecorativeTag } from "../../../components";
import { SupervisionContext } from "../../../contexts";

import styles from "./../TalentGrid.module.scss";

type AssignedActionsCellProps = {
  params: GridRenderCellParams<TalentGridRow>;
};

const AssignedActionsCell = ({ params }: AssignedActionsCellProps) => {
  const { campaign } = useContext(SupervisionContext);
  const { t } = useTranslation();

  const campaignActions = campaign?.actions;
  const assignedActions = params.row?.assignedActions;

  return (
    <Flex alignItems="center" justifyContent="left" className={styles.clickable}>
      {assignedActions
        .filter((a) => a.state !== "DENIED")
        .map((assignedAction) => {
          const action = campaignActions.find((action) => action.id === assignedAction.actionID);
          if (assignedAction.coordinatorComment || assignedAction.managerComment) {
            return (
              <DecorativeTag.FreeColor
                color={action?.color}
                label={action?.label ?? ""}
                key={assignedAction.actionID}
                className={styles.cellCursor}
                tooltipLabel={`${
                  assignedAction.managerComment
                    ? `${t("peopleReview.manager", { defaultValue: "Manager" })} : ${
                        assignedAction.managerComment
                      } ${assignedAction.coordinatorComment ? " — " : ""}`
                    : ``
                } ${
                  assignedAction.coordinatorComment
                    ? `${t("peopleReview.coordinator", { defaultValue: "Coordinateur" })} : ${
                        assignedAction.coordinatorComment
                      }`
                    : ""
                }`}
              />
            );
          }
          return (
            <DecorativeTag.FreeColor
              color={action?.color}
              label={action?.label ?? ""}
              key={assignedAction.actionID}
            />
          );
        })}
    </Flex>
  );
};

export const assignedActionsColDef = {
  type: "string",
  cellClassName: "fake-editable--cell",
  field: "assigned_actions",
  filterable: false,
  flex: 1,
  headerName: "Actions",
  minWidth: 250,
  renderCell: (params: GridRenderCellParams) => <AssignedActionsCell params={params} />,
} satisfies GridColDef;
