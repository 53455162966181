import qs from "qs";
import { Location as ILocation } from "history";

const isNotEmptyContent = (content) => {
  switch (content) {
    case false:
    case null:
    case undefined:
    case "<div></div>":
    case "<p></p>":
    case "<p><br></p>":
      return false;
    default:
      return true;
  }
};

const renderContents = (properties) => {
  return [
    {
      content: properties.targetAudience,
      title: "Public cible",
      type: "quote",
    },
    {
      content: properties.objectives,
      title: "Objectifs",
      type: "quote",
    },
    {
      content: properties.prerequisites,
      title: "Pré-requis",
      type: "quote",
    },
    {
      content: properties.program,
      title: "Programme",
      type: "quote",
    },
    {
      content: properties.pedagogy,
      title: "Pédagogie",
      type: "quote",
    },
    {
      content: properties.trainerProfile,
      title: "Profil formateur",
      type: "quote",
    },
  ].filter((v) => isNotEmptyContent(v.content));
};

const computePlaceFromQuery = (location: ILocation) =>
  qs.parse(location.search, { ignoreQueryPrefix: true })?.place;

export { renderContents, isNotEmptyContent, computePlaceFromQuery };
