import type { GetProjectsRoute } from "types/api";

export enum Status {
  POSTITIONNED = "positionned",
  SUMMONED = "summoned",
}

type Trainees = {
  [key in Status]: string[];
};

export type TraineesList = {
  trainees: Trainees;
  checkedTrainees: string[];
  activeColumn: "positionned" | "summoned" | "both";
  columnChecked: boolean | "intermediate";
};

export type Project = GetProjectsRoute["response"]["projects"][number];
export type Trainee = Project["positionnedTrainees"][number];
