import { useMemo } from "react";
import { useSelector } from "react-redux";
import { DSAlert, DSAlertDisplay, DSAlertType, DSCheckbox, DSTooltip, Flex } from "@skillup/ui";

import { Divided } from "../ChildWrappers";
import { Permissions } from "../../Permissions/Permissions";

import { Child, selectStructureSections } from "../../../../reducer";
import styles from "./GlobalTargetsCompletionChild.module.scss";

type GlobalTargetsCompletion = Extract<Child, { kind: "globalTargetsCompletion" }>;

type Props = {
  child: GlobalTargetsCompletion;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  updateChild(data: Partial<GlobalTargetsCompletion>): void;
};

export function GlobalTargetsCompletionChild({ child, indexes, updateChild }: Props) {
  const sections = useSelector(selectStructureSections);
  const warningNoCurrentTargetsChild = useMemo(() => {
    return !sections.some((section) =>
      section.pages?.some((page) =>
        page.children?.some((child) => child.kind === "targets" && child.type === "current")
      )
    );
  }, [sections]);
  return (
    <Divided>
      <Flex column className={styles.globalTargetsCompletionChild}>
        <p>
          Le tableau de taux d’atteinte global agrège l'ensemble des objectifs{" "}
          <strong>évalués dans l’entretien.</strong>
        </p>
        {warningNoCurrentTargetsChild && (
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE} closeButton={false}>
            <p>
              Cette trame n'inclut pas de bloc d'évaluation d'objectifs, le tableau de taux
              d'atteinte global ne pourra donc pas être affiché.
            </p>
          </DSAlert>
        )}
        <p>
          Afin de permettre le calcul du taux d’atteinte global, le responsable de l’entretien sera
          invité à :
          <ul>
            <li>Vérifier la pondération des objectifs et la modifier si nécessaire</li>
            <li>
              Saisir un équivalent en pourcentage pour l’évaluation des objectifs qualitatifs.
            </li>
          </ul>
        </p>
      </Flex>
      <Flex column>
        <Flex column className={styles.settings}>
          <DSCheckbox
            className={styles.settings__actionChoice}
            label="Remplissage obligatoire"
            checked={child.required}
            onChange={(isRequired) => updateChild({ required: isRequired })}
          />
          <DSTooltip
            /* [CSB] permission changes disabled */
            label="Cet élément ne peut pas être modifié sur une campagne en cours."
          >
            <DSCheckbox
              className={styles.settings__actionChoice}
              label="Bloquer la pondération globale à 100"
              checked={child.forcePercentWeight}
              // onChange={(forcePercentWeight) => updateChild({ forcePercentWeight })} [CSB] permission changes disabled
              disabled // [CSB] permission changes disabled
            />
          </DSTooltip>
          <p>
            Seul le responsable de l'entretien peut compléter le tableau de taux d'atteinte global.
          </p>
        </Flex>
        <Permissions
          restrictions={child.disallow}
          uuid={child.uuid}
          indexes={indexes}
          order={["reply", "comment"]}
          kind="globalTargetsCompletion"
        />
      </Flex>
    </Divided>
  );
}
