import User from "utils/User";

const computeRFRoute = () => {
  if (User.hasTrainingsEnabled()) {
    return "/responsable/programmes/catalogue";
  }
  return "/responsable";
};

const computeManagerRoute = () => {
  if (!User.hasTrainingsEnabled()) {
    return "/manager/entretiens";
  }

  return "/manager/";
};

const computeCompanyUserRoute = () => {
  if (!User.hasTrainingsEnabled()) {
    return "/collaborateur/entretiens";
  }

  return "/collaborateur/";
};

export default {
  computeRFRoute,
  computeManagerRoute,
  computeCompanyUserRoute,
};
