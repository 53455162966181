import { ListUtils } from "@skillup/shared-utils";
import { EmployeeFields, EmployeeFilters, KeyofEmployee } from "@skillup/core-hr-bridge";
import { useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { getEmployees } from "services/coreHR";

type EmployeeFilterType = ListUtils.FilterValues<typeof EmployeeFilters>;

export type EmployeeFieldsType = typeof EmployeeFields;

export const buildEmployeeFilter = (
  field: string,
  value: string | Array<string>
): EmployeeFilterType | undefined => {
  const filter = EmployeeFilters[field];

  if (!filter) {
    return undefined;
  }

  return {
    [field]: {
      operator: "contains",
      type: filter.type,
      value,
    },
  };
};

const useEmployeeFields = () => {
  const getFields = useCallback(() => {
    return EmployeeFields;
  }, []);

  const getFilters = useCallback(() => {
    return EmployeeFilters;
  }, []);

  const buildFilter = useCallback(
    (field: string, value: string | Array<string>): EmployeeFilterType | undefined => {
      return buildEmployeeFilter(field, value);
    },
    []
  );

  return {
    getFields,
    getFilters,
    buildFilter,
  };
};

const defaultOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
};

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};

const useEmployees = <TKey extends KeyofEmployee>(
  { employeeUuids, keys }: { employeeUuids: string[]; keys: TKey[] },
  options: Options = defaultOptions
) => {
  const query = useQuery(
    ["core-hr-employees", employeeUuids],
    () => getEmployees({ employeeUuids, keys }),
    {
      enabled: !!employeeUuids.length,
      ...defaultOptions,
      ...options,
    }
  );

  return {
    employees: query.data?.employees,
    isError: query.isError,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
  };
};

export { useEmployees, useEmployeeFields };
