import styled from "styled-components";

import { Flex } from "@skillup/design-system";
import { DSColor, SkuiSpacing } from "@skillup/ui";

export const HistogramContainer = styled(Flex)`
  flex-direction: column;
  margin-top: ${SkuiSpacing.l};
  align-items: center;
  border: 1px solid ${DSColor["border-onLight"]};
  border-radius: ${SkuiSpacing.s};
  background-color: ${DSColor["surface-light"]};
  overflow: hidden;
  padding: ${SkuiSpacing.s} 0;
  width: 60%;
  user-select: none;
`;

export const SquareLegend = styled.div`
  width: 8px;
  height: 8px;
  background: ${DSColor["decoration-blue-base"]};
  margin-left ${SkuiSpacing.xxs};
  margin-right: ${SkuiSpacing.xs};
`;

export const AverageLegend = styled(SquareLegend)`
  background: ${DSColor["decoration-blue-base"]};
`;

export const ExpectedLegend = styled(SquareLegend)`
  background: ${DSColor["greyscale-medium"]};
`;

export const IconWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    color: ${({ disabled }) =>
      disabled ? DSColor["border-onLight"] : DSColor["plainText-onLight-lighter"]};
  }
  ${({ disabled }) => (disabled ? "pointer-events: none;" : "")}
`;
