import { useMemo } from "react";
import { Column } from "@skillup/ui";

interface CategoryList {
  uuid: string;
  version: number;
  label: string;
  deletedAt?: Date;
  index: number;
  skillsLinked?: number;
}

function useTableData(skills: Array<CategoryList>): {
  data: Array<CategoryRow>;
  columns: Array<Column<CategoryRow>>;
} {
  const tableData = useMemo(
    () => ({
      columns: generateColumns(),
      data: generateTableRows(skills ?? []),
    }),
    [skills]
  );

  return tableData;
}

export default useTableData;

const generateColumns = () => {
  const columns: Array<Column<CategoryRow>> = [
    {
      key: "label",
      title: "Intitulé de la catégorie",
      filterable: false,
      width: 12,
      sortFn: sortingWithAccents,
    },
    {
      key: "skillsLinked",
      title: "Nombre de compétences dans cette catégorie",
      filterable: false,
      width: 16,
      cellAlign: "right",
    },
  ];
  return columns;
};

const sortingWithAccents = (a: CategoryRow, b: CategoryRow) => {
  return a.data.label.localeCompare(b.data.label);
};

const generateTableRows = (skills: CategoryList[]): Array<CategoryRow> => {
  return skills?.map(parseCategoryIntoRow);
};

const parseCategoryIntoRow = (category: CategoryList) => {
  return {
    id: category.uuid,
    data: {
      uuid: category.uuid,
      version: category.version,
      index: category.index,
      label: category.label,
      skillsLinked: category.skillsLinked,
      deletedAt: category.deletedAt,
    },
  };
};

export type CategoryRow = ReturnType<typeof parseCategoryIntoRow>;
