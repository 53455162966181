import type { SkillsRoutes } from "types/api";
import { useRouteQuery } from "hooks";

type GetAllSkillsRoute = SkillsRoutes["Skills.GetList"];

export function useSkills() {
  const categoriesRequest = useRouteQuery<GetAllSkillsRoute>(["skills"], {
    method: "GET",
    path: "/competences/skills",
  });
  return categoriesRequest;
}
