import { AddToast } from "react-toast-notifications";

import { TranslationType } from "hooks/useTranslation";

export const copyUrl = async (
  addToast: AddToast,
  t: TranslationType,
  url: string
): Promise<void> => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(url);

    addToast(
      t("review.action.copy-url", { defaultValue: "L'URL du questionnaire a été copiée." }),
      { appearance: "success" }
    );
  } else {
    addToast(
      t("review.action.copy-url.error", {
        defaultValue: "L'URL du questionnaire a pas été copiée.",
      }),
      { appearance: "error" }
    );
  }
};

export default copyUrl;
