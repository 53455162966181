import { useMemo } from "react";
import { uniqBy } from "lodash";

import { InterviewWithStates } from "@skillup/types";
import { Column, ColumnType, Status } from "@skillup/ui";
import { renderCellDate } from "@skillup/shared-utils";

import interviewStatusKeyToValue from "utils/InterviewStatusKeyToValue";

function useTableData(
  interviews: Array<InterviewWithStates>,
  campaignType: "ongoing" | "legacy" | "adHoc"
): {
  data: Array<InterviewRow>;
  columns: Array<any>;
} {
  const tableData = useMemo(
    () => ({
      columns: generateColumns(interviews, campaignType),
      data: generateTableRows(interviews ?? []),
    }),
    [interviews, campaignType]
  );

  return tableData;
}

export default useTableData;

enum StatusValueToStatusType {
  "Signé" = "success",
  "Non signé" = "error",
  "Partagé" = "progress",
  "À préparer" = "done",
  "Not found" = "error",
}

const generateColumns = (
  interviews: InterviewWithStates[],
  campaignType: "ongoing" | "legacy" | "adHoc"
) => {
  const statusManagerOptions = uniqBy(interviews, (i) => {
    return interviewStatusKeyToValue(i.manager.state).shortValue;
  })
    .map((e) => ({
      value: interviewStatusKeyToValue(e.manager.state).shortValue,
      label: interviewStatusKeyToValue(e.manager.state).shortValue,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const statusCollaboratorOptions = uniqBy(interviews, (i) => {
    return interviewStatusKeyToValue(i.employee.state).shortValue;
  })
    .map((e) => ({
      value: interviewStatusKeyToValue(e.employee.state).shortValue,
      label: interviewStatusKeyToValue(e.employee.state).shortValue,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const perimeterOptions = uniqBy(interviews, (i) => {
    return i.employee.userAreasNames[0];
  })
    .map((i) => ({
      value: i.employee.userAreasNames[0],
      label: i.employee.userAreasNames[0],
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const columns: Array<Column<InterviewRow>> = [
    {
      key: "collaborator",
      title: "Collaborateur",
      minWidth: 16,
    },
    {
      key: "collaboratorStatus",
      title: "Statut collaborateur",
      type: ColumnType.SEARCH_SELECT,
      width: 13.125,
      filterable: true,
      options: statusCollaboratorOptions,
      renderCell: ({ collaboratorStatus }) => (
        <Status label={collaboratorStatus} type={StatusValueToStatusType[collaboratorStatus]} />
      ),
    },
    {
      key: "manager",
      title: "Responsable de l’entretien",
      minWidth: 16,
    },
    {
      key: "managerStatus",
      title: "Statut responsable",
      type: ColumnType.SEARCH_SELECT,
      width: 13.125,
      filterable: true,
      options: statusManagerOptions,
      renderCell: ({ managerStatus }) => (
        <Status label={managerStatus} type={StatusValueToStatusType[managerStatus]} />
      ),
    },
    ...(campaignType !== "legacy"
      ? [
          {
            key: "startDate",
            title: "Date de début",
            type: ColumnType.DATE,
            width: 14.375,
            sortable: true,
            sortFn: (a: InterviewRow, b: InterviewRow) => {
              const dateA = a.data.startDate;
              const dateB = b.data.startDate;
              return +dateA - +dateB;
            },
            renderCell: ({ startDate }) => renderCellDate(startDate),
          },
        ]
      : []),
    {
      key: "endDate",
      title: "Date limite de signature",
      type: ColumnType.DATE,
      width: 14.375,
      sortable: true,
      sortFn: (a: InterviewRow, b: InterviewRow) => {
        const dateA = a.data.endDate;
        const dateB = b.data.endDate;
        return +dateA - +dateB;
      },
      renderCell: ({ endDate }) => renderCellDate(endDate),
    },
    {
      key: "collaboratorPerimeter",
      title: "Périmètre collaborateur",
      options: perimeterOptions,
      type: ColumnType.SEARCH_SELECT,
      width: 14.375,
    },
  ];

  return columns;
};

function generateTableRows(interviews: InterviewWithStates[]): Array<InterviewRow> {
  return interviews?.map(parseCollaboratorIntoRow);
}

function parseCollaboratorIntoRow(interview: InterviewWithStates) {
  return {
    id: interview.uuid,
    data: {
      collaborator: interview.employee.fullName,
      collaboratorStatus: interviewStatusKeyToValue(interview.employee.state).shortValue,
      collaboratorPerimeter: interview.employee.userAreasNames[0],
      manager: interview.manager.fullName,
      managerStatus: interviewStatusKeyToValue(interview.manager.state).shortValue,
      startDate: new Date(interview.startDate),
      endDate: new Date(interview.endDate),
    },
  };
}

export type InterviewRow = ReturnType<typeof parseCollaboratorIntoRow>;
