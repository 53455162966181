import cx from "classnames";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { DSButton, MaterialIcons } from "@skillup/ui";

import { IBanner } from "./BannersList";

import styles from "./Banner.module.scss";

interface Props {
  size?: "large" | "small";
  banner: IBanner;
  onEdit: () => void;
  onCopy: () => void;
  onRemove: () => void;
}

export default ({ banner, size = "large", onEdit, onCopy, onRemove }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: banner.uuid,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className={cx(styles.Banner, styles[size])} style={style} {...attributes} ref={setNodeRef}>
      <div className={styles.content}>
        <div className={styles.drag} {...listeners}>
          <MaterialIcons.DragIndicator />
        </div>
        <div className={cx(styles.preview, styles[size])} style={{ background: banner.background }}>
          <div className={styles.logo}>
            <img src={banner.logo} alt="logo" />
          </div>
          <div className={styles.text}>
            <div>
              <p className={styles.intro} style={{ color: banner.subtitle?.color }}>
                {banner.subtitle?.label}
              </p>
              <p className={styles.main} style={{ color: banner.title.color }}>
                {banner.title.label}
              </p>
            </div>
            <DSButton
              className={cx(styles.DSButton, styles[banner.button.theme])}
              label={banner.button.label}
              buttonSize="M"
              emphasis="Mid"
              darkMode
            />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <DSButton iconOnly icon={<MaterialIcons.Delete />} buttonSize="M" onClick={onRemove} />
        <DSButton iconOnly icon={<MaterialIcons.Edit />} buttonSize="M" onClick={onEdit} />
        <DSButton iconOnly icon={<MaterialIcons.ContentCopy />} buttonSize="M" onClick={onCopy} />
      </div>
    </div>
  );
};
