import { TemplatesRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

async function archiveTemplate(templateUuid: string) {
  try {
    const response = await buildRequest<TemplatesRoutes.Archive>({
      method: "POST",
      path: "/templates/archive/{templateUuid}",
      params: { templateUuid },
      payload: undefined,
    })();
    if (response.success === false) {
      throw new Error("Archive unsuccessful");
    }
    return response;
  } catch (err) {
    throw new Error(err);
  }
}

export { archiveTemplate };
