import { Dispatch, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import {
  remove,
  duplicate,
  ChildBlock,
  PageBlock,
  SectionBlock,
  selectStructureSections,
  setHighlightUuid,
} from "../reducer";
import { Deletion, Duplication } from "../modals";
import { isImmutableItem } from "../utils";
import { getIndexes, getModalDetails, getPrecedentStructureItemUuid } from "./utils/getters";
import { useBuilderContext } from "../BuilderContext";

export enum ModalStates {
  Idle,
  DupplicateItem,
  RemoveItem,
}

export type ModalState =
  | { state: ModalStates.Idle }
  | {
      state: ModalStates.DupplicateItem;
      content: JSX.Element;
      type: "page" | "section";
      title: string;
      onConfirm: () => void;
    }
  | {
      state: ModalStates.RemoveItem;
      content: JSX.Element;
      type: "page" | "section";
      title: string;
      onConfirm: () => void;
    };

export type PositionItem = SectionBlock | PageBlock | ChildBlock;

export const useBuilderModals = (
  currentModal: ModalState,
  setModalsState: Dispatch<ModalState>
) => {
  const sections = useSelector(selectStructureSections);

  const dispatch = useDispatch();
  const { updatedItemIdRef } = useBuilderContext();

  const showActionsButtons = useCallback(
    (item: PositionItem) => !isImmutableItem({ sections }, item),
    [sections]
  );

  const dispatchDuplication = useCallback(
    (item: PositionItem) => {
      const newUuid = uuid();
      updatedItemIdRef.current = {
        uuid: newUuid,
        fromAction: "duplicateItem",
      };
      dispatch(
        duplicate({
          uuid: newUuid,
          from: getIndexes(item),
          blockType: item.type,
        })
      );
    },
    [dispatch, updatedItemIdRef]
  );

  const handleDuplication = useCallback(
    (item: PositionItem) => {
      if (item.type === "child") {
        return dispatchDuplication(item);
      }
      const { structureItem, content, counts } = getModalDetails({ sections }, item, "duplication");
      if (!structureItem) setModalsState({ state: ModalStates.Idle });
      if (counts.childrenCount === 0 && counts.pagesCount <= 1) {
        return dispatchDuplication(item);
      }
      setModalsState({
        state: ModalStates.DupplicateItem,
        content,
        type: item.type,
        title: structureItem.title,
        onConfirm: () => {
          dispatchDuplication(item);
          setModalsState({ state: ModalStates.Idle });
        },
      });
    },
    [sections, setModalsState, dispatchDuplication]
  );

  const dispatchDeletion = useCallback(
    (item: PositionItem) => {
      const precedentItemUuid = getPrecedentStructureItemUuid({ sections }, item);
      updatedItemIdRef.current = {
        uuid: precedentItemUuid,
        fromAction: "removeItem",
      };
      dispatch(setHighlightUuid(precedentItemUuid));
      dispatch(remove({ from: getIndexes(item), newHighlightUuid: precedentItemUuid }));
    },
    [sections, dispatch, updatedItemIdRef]
  );

  const handleDeletion = useCallback(
    (item: PositionItem) => {
      if (item.type === "child") {
        return dispatchDeletion(item);
      } else {
        const { structureItem, content, counts } = getModalDetails({ sections }, item, "deletion");
        if (!structureItem) setModalsState({ state: ModalStates.Idle });
        if (counts.childrenCount === 0 && counts.pagesCount <= 1) {
          return dispatchDeletion(item);
        }

        setModalsState({
          state: ModalStates.RemoveItem,
          content,
          type: item.type,
          title: structureItem.title,
          onConfirm: () => {
            dispatchDeletion(item);
            setModalsState({ state: ModalStates.Idle });
          },
        });
      }
    },
    [sections, setModalsState, dispatchDeletion]
  );

  const modalToDisplay: JSX.Element = useMemo(() => {
    switch (currentModal.state) {
      case ModalStates.Idle:
        return <></>;
      case ModalStates.DupplicateItem:
        return (
          <Duplication
            content={currentModal.content}
            type={currentModal.type}
            title={currentModal.title}
            isOpen
            closeModal={() => setModalsState({ state: ModalStates.Idle })}
            onConfirm={currentModal.onConfirm}
          />
        );
      case ModalStates.RemoveItem:
        return (
          <Deletion
            content={currentModal.content}
            type={currentModal.type}
            title={currentModal.title}
            isOpen
            closeModal={() => setModalsState({ state: ModalStates.Idle })}
            onConfirm={currentModal.onConfirm}
          />
        );
      default:
        return <></>;
    }
  }, [currentModal, setModalsState]);

  return {
    handleDeletion,
    handleDuplication,
    showActionsButtons,
    modalToDisplay,
  };
};
