import React, { useState, useEffect } from "react";

import { ConfigurationRoutes } from "@skillup/espace-rh-bridge";
import { Button } from "@skillup/ui";

import { useTypedFetch } from "hooks";
import User from "utils/User";
import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import { DragAndDropFieldsLayout, ScheduleSelector } from "../components";

import styles from "./ExportConfiguration.module.scss";

type Schedule = ConfigurationRoutes.GetConfiguration["response"]["schedules"][0];

type Field = Schedule["tabs"]["collection"][0]["fields"][0];

interface ITabs {
  [x: string]: Array<Field>;
}

export default function ExportConfiguration() {
  const user = User.getUserData();
  const { data } = useTypedFetch<ConfigurationRoutes.GetConfiguration>({
    method: "GET",
    path: "/configuration/{companyUuid}",
    params: { companyUuid: user.activeCompany.uuid },
    query: { showHiddenFields: true },
  });

  const [selectedSchedule, selectSchedule] = useState<Schedule>();
  const [tabs, setTabs] = useState<ITabs>({});
  const tabKeys = ["default", "plan", "collection"];
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    setTabs(
      [...(selectedSchedule?.exports ?? [])].reduce(
        (acc, tab) => ({
          ...acc,
          [tab.exportType]: tab.fields,
        }),
        {}
      )
    );
  }, [selectedSchedule]);

  const submitConfiguration = async () => {
    if (hasChanged) {
      try {
        const exports = Object.keys(tabs).reduce((acc, key) => {
          const curr = tabs[key];
          const exportUuid = selectedSchedule.exports.find((e) => e.exportType === key)?.uuid;

          return [
            ...acc,
            {
              uuid: exportUuid,
              fields: curr.map((e, index) => ({
                uuid: e.uuid,
                infos: {
                  index,
                  ...(e.width && { width: e.width }),
                },
              })),
            },
          ];
        }, []);

        await buildRequest<ConfigurationRoutes.UpdateExportConfiguration>({
          method: "PUT",
          path: `/configuration/{companyUuid}/exports`,
          params: { companyUuid: user.activeCompany.uuid },
          payload: {
            scheduleUuid: selectedSchedule.uuid,
            exports,
          },
        })();
        Acta.dispatchEvent("sendAppMessage", {
          message: "Modifications enregistrées avec succès",
          type: "success",
        });
        setHasChanged(false);
      } catch (err) {
        Acta.dispatchEvent("sendAppMessage", {
          message: "Echec des modifications.",
          type: "error",
        });
      }
    } else {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Aucun changement à enregistrer",
        type: "message",
      });
    }
  };

  return (
    <div className={styles.ExportConfiguration}>
      <ScheduleSelector
        schedules={data?.schedules}
        setSchedule={selectSchedule}
        hasChanged={hasChanged}
        selectedSchedule={selectedSchedule}
      >
        <Button label="Enregistrer" onClick={submitConfiguration} />
      </ScheduleSelector>
      <div className={styles.configuration}>
        {!selectedSchedule && (
          <>
            <h3>Veuillez sélectionner un plan à configurer</h3>
          </>
        )}

        {selectedSchedule && (
          <DragAndDropFieldsLayout
            tabs={tabs}
            setTabs={setTabs}
            tabOrder={tabKeys}
            setHasChanged={setHasChanged}
            hideHiddenMark
          />
        )}
      </div>
    </div>
  );
}
