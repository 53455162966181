import { useState, useEffect, useMemo } from "react";
import { head, isArray } from "lodash";
import {
  ISimpleSelectionUser,
  ICustomUsersList,
  ISimpleManager,
  ISimpleUserIdentity,
  ICustomUsersGroupList,
  ISimpleSelectionGroup,
  ISimpleSelectionUserWithManager,
  USER_FILE_VALIDATION_TYPE,
} from "@skillup/types";
import { DSAlert, DSAlertType, DSAlertDisplay } from "@skillup/ui";
import cx from "classnames";

import Acta from "utils/Acta";
import UserSearch from "./components/UserSearch";
import ImportFile from "./components/ImportFile";
import PendingUsersList from "./components/PendingUsersList";
import SelectedUsersList from "./components/SelectedUsersList";
import styles from "./AddTraineesContent.module.scss";
import { useUsersList, isPair } from "./add-trainees-context";
import { fetchUserManager } from "../../fetchers/users-fetcher";
import ErrorListCard from "./components/ErrorListCard";
import { buildErrorList, prepareList, prepareGroup } from "./helpers";
import PendingUsersGroupList from "./components/PendingUsersGroupList";

export type AddTraineeError = {
  alertText: string;
  alertType: DSAlertType;
};

export type User = ISimpleSelectionUser;

interface IProps {
  preselectedUsers?: Array<User>;
  customUsersLists?: Array<ICustomUsersList | ICustomUsersGroupList>;
  canUploadManager: boolean;
  error?: AddTraineeError;
  errorHandler?: (selection: Array<User>) => void;
  scope: USER_FILE_VALIDATION_TYPE;
}

const AddTraineesContent = (props: IProps) => {
  const [selectedUsersEmails, setSelectedUsersEmails] = useState<string[]>([]);
  const [errorList, setErrorList] = useState<string[]>([]);
  const [errorsCount, setErrorsCount] = useState<number>(0);

  const {
    dispatch,
    state: { selectedUsers, selectedPairs },
  } = useUsersList();

  const { errorHandler } = props;

  useEffect(() => {
    if (errorHandler) {
      errorHandler(selectedUsers);
    }
  }, [errorHandler, selectedUsers]);

  useEffect(() => {
    setSelectedUsersEmails([
      ...selectedUsers.map((user) => user.email),
      ...selectedPairs.map(({ employee }) => employee.email),
    ]);
    if (
      props.scope === USER_FILE_VALIDATION_TYPE.LEGACY_INTERVIEW_CAMPAIGN ||
      props.scope === USER_FILE_VALIDATION_TYPE.ONGOING_INTERVIEW_CAMPAIGN
    ) {
      setErrorList(buildErrorList(selectedPairs));
      setErrorsCount(
        selectedPairs.filter((pair) => !!pair.employee.errors?.length).length +
          selectedPairs.filter((pair) => !!pair.manager?.errors?.includes("not-found")).length
      );
    } else {
      setErrorList(buildErrorList(selectedUsers));
      setErrorsCount(selectedUsers.filter((user) => !!user.errors?.length).length);
    }
  }, [selectedUsers, setSelectedUsersEmails, selectedPairs, props.scope]);

  const removeSelectedUser = (entry: { user: ISimpleSelectionUser; manager?: ISimpleManager }) => {
    if (entry.manager || entry.user.errors?.includes("no-manager")) {
      dispatch({ type: "removePair", value: { employee: entry.user, manager: entry.manager } });
      return;
    }
    dispatch({ type: "remove", value: entry.user });
  };

  const selectUser = async (
    input:
      | ISimpleSelectionUser
      | ISimpleSelectionUserWithManager
      | ISimpleSelectionUser[]
      | { employee: ISimpleSelectionUser; manager: ISimpleManager },
    needSelectManager?: boolean
  ) => {
    if (isArray(input)) {
      input.forEach((user) => {
        dispatch({ type: "add", value: user });
      });

      return;
    }
    // concern mainly the file upload
    if (isPair(input)) {
      dispatch({ type: "addPair", value: input });

      return;
    }

    // concern mainly the search
    if (needSelectManager) {
      let manager = undefined;
      if (input["managers"]) {
        manager = input["managers"].find((m) => m.level === 0);
      } else {
        manager = await getManagerFromSearch({ email: input.email });
      }

      if (!manager) {
        input.errors = input.errors?.length ? [...input.errors, "no-manager"] : ["no-manager"];
      }
      dispatch({ type: "addPair", value: { employee: input, manager } });

      return;
    }

    dispatch({ type: "add", value: input });
  };

  const selectUserGroup = async (
    users: ISimpleSelectionUserWithManager[],
    needSelectManager?: boolean
  ) => {
    if (needSelectManager) {
      for (const user of users) selectUser(user, true);
    } else selectUser(users);
  };
  const unselectUserGroup = async (group: ISimpleSelectionGroup, needSelectManager?: boolean) => {
    for (const user of group.users) {
      if (props.preselectedUsers?.some(({ email }) => email === user.email)) continue;
      if (needSelectManager) {
        const pairsToRemove = selectedPairs.filter((pair) => pair.employee.email === user.email);
        for (const pair of pairsToRemove) {
          removeSelectedUser({ user: pair.employee, manager: pair.manager });
        }
      } else {
        const usersToRemove = selectedUsers.filter(({ email }) => email === user.email);
        for (const userToRemove of usersToRemove) {
          removeSelectedUser({ user: userToRemove });
        }
      }
    }
  };

  const { userSelectedText } = useMemo(() => {
    const userSelected = selectedUsers.length || selectedPairs.length;
    let userSelectedText = `${userSelected} utilisateurs sélectionnés`;
    if (userSelected === 0) {
      userSelectedText = "Aucun utilisateur sélectionné";
    } else if (userSelected === 1) {
      userSelectedText = `${userSelected} utilisateur sélectionné`;
    }

    return { userSelectedText };
  }, [selectedUsers, selectedPairs]);

  const getManagerFromSearch = async ({ email: userEmail }): Promise<ISimpleManager> => {
    try {
      const manager = head(await fetchUserManager(userEmail));
      if (!manager) {
        return undefined;
      }

      return {
        ...manager,
        joinDate: new Date(manager.joinDate).valueOf(),
        linkedTo: userEmail,
      };
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Impossible d'ajouter l'utilisateur",
        type: "error",
      });

      return undefined;
    }
  };

  const renderCustomLists = () => {
    const customUsersLists = props.customUsersLists ?? [];
    const alreadyLinkedUsers = {};
    for (const list of customUsersLists) {
      if ("users" in list) {
        for (const user of list.users) alreadyLinkedUsers[user.email] = true;
      }
    }
    return customUsersLists.map((list) => {
      if ("users" in list) {
        return (
          <PendingUsersList
            key={list.label}
            onSelect={selectUser}
            usersToPickFrom={prepareList(list.users, selectedUsersEmails, true)}
            label={list.label}
          />
        );
      }
      return (
        <PendingUsersGroupList
          key={list.label}
          onSelect={(users) => selectUserGroup(users, list.needSelectManager)}
          onUnSelect={(group) => unselectUserGroup(group, list.needSelectManager)}
          groups={list.groups}
          usersToPickFrom={prepareGroup(list.groups, selectedUsersEmails, alreadyLinkedUsers)}
          label={list.label}
        />
      );
    });
  };

  return (
    <>
      <div className={cx(styles.content, styles.AddTraineesContent)}>
        <div className={styles.filterColumn}>
          <div className={styles.filterSection}>
            <h2>Sélectionner un collaborateur</h2>
            <UserSearch
              autoFocus
              filter={(user: ISimpleUserIdentity) =>
                !selectedUsers.map((unwantedUser) => unwantedUser.email).includes(user.email) &&
                !selectedPairs.map(({ employee }) => employee.email).includes(user.email)
              }
              onSelect={selectUser}
              scope={props.scope}
            />
          </div>
          {renderCustomLists()}
          <ImportFile
            onSubmit={selectUser}
            canUploadManager={props.canUploadManager}
            scope={props.scope}
          />
        </div>
        <div className={styles.selectedColumn}>
          <div className={styles.selectedNumberBand}>
            <p>{userSelectedText}</p>
          </div>
          <SelectedUsersList
            onRemove={removeSelectedUser}
            preselectedUsers={props.preselectedUsers}
          />
        </div>
      </div>

      {props.error ? (
        <div className={styles.alert}>
          <DSAlert type={props.error.alertType} display={DSAlertDisplay.INLINE}>
            {props.error.alertText}
          </DSAlert>
        </div>
      ) : undefined}
      {!!errorsCount && (
        <ErrorListCard scope={props.scope} errorsCount={errorsCount} errorList={errorList} />
      )}
    </>
  );
};

export default AddTraineesContent;
