import { useSetState } from "react-use";
import { useTranslation } from "react-i18next";

import { DSModal } from "@skillup/ui";

import { ProcessedSkill, SkillWithExpectedLevel } from "types/skills";

import { Modal } from "../JobForm.styled";
import SkillLevelContent from "./SkillLevelContent";

interface Props {
  onBack: () => void;
  onClose: () => void;
  skill: ProcessedSkill;
  onConfirm: (skillWithExpectedLevel: SkillWithExpectedLevel) => void;
}

const AddExpectedLevelModal = ({ onBack, onClose, onConfirm, skill }: Props) => {
  const { t } = useTranslation();
  const [skillWithExpectedLevel, setSkillWithExpectedLevel] = useSetState<SkillWithExpectedLevel>({
    description: skill.description,
    evaluationScale: skill.evaluationScale,
    generated: skill.generated,
    levelNumber: undefined,
    levelUuid: undefined,
    skillName: skill.name,
    skillUuid: skill.uuid,
    totalLevel: skill.evaluationScale.levels.length,
  });

  const handleExpectedLevelSelection = (levelUuid?: string) => {
    setSkillWithExpectedLevel({
      levelNumber: skill.evaluationScale.levels.find((level) => level.uuid === levelUuid)?.level,
      levelUuid,
    });
  };

  return (
    <>
      <Modal isOpen>
        <DSModal.Header onClose={onClose}>
          <DSModal.Header.Title
            title={t("jobs.addSkillModal.titleLevel", {
              defaultValue:
                "Définir le niveau de maîtrise attendu pour la compétence «{{skillName}}»",
              skillName: skill.name,
            })}
          />
        </DSModal.Header>
        <DSModal.Content>
          <SkillLevelContent
            skillUuid={skill.uuid}
            levelUuid={skillWithExpectedLevel.levelUuid}
            evaluationScale={skillWithExpectedLevel.evaluationScale}
            onChange={handleExpectedLevelSelection}
          />
        </DSModal.Content>
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            onClick={onBack}
            label={t("skills.archivedModal.buttonCancel", {
              defaultValue: "Annuler",
            })}
          />
          <DSModal.Footer.PrimaryButton
            onClick={() => onConfirm(skillWithExpectedLevel)}
            label={t("jobs.addSkillsModal.buttonConfirm", {
              defaultValue: "Valider la sélection",
            })}
          />
        </DSModal.Footer>
      </Modal>
    </>
  );
};

export default AddExpectedLevelModal;
