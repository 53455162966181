import { buildRequest } from "utils/buildRequest";

import type { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";

export type Collaborator = CollaboratorsRoutes.Get["response"]["collaborators"][0];

export default async () => {
  const users = await buildRequest<CollaboratorsRoutes.Get>({
    method: "GET",
    path: "/collaborators",
  })();

  return users;
};
