import { createContext, useCallback, useContext, useEffect } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { UserNotificationRoutes, CampaignExportState, CampaignExportStates, CampaignsRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

const USER_CAMPAIGN_NOTIFICATIONS_TOKEN = "userCampaignNotifications"

const getUserCampaignNotifications = async ({
  campaignUuid
}: { campaignUuid: string }) => {
  try {
    const data = await buildRequest<UserNotificationRoutes.GetForCampaignExport>({
      path: "/user-notification-for-campaign-export",
      method: "GET",
      query: { campaignUuid },
    })();

    return data;
  } catch (err) {
    return undefined;
  }
};

const useGetUserCampaignNotifications = ({ campaignUuid }: { campaignUuid: string }) => {
  const query = useQuery(
    [USER_CAMPAIGN_NOTIFICATIONS_TOKEN],
    async () => getUserCampaignNotifications({ campaignUuid }),
    {
      refetchOnWindowFocus: true,
    }
  );

  return {
    data: query.data,
    isLoading: query.isLoading,
  };
};

type ExportAsyncContextType = {
  showAsyncExportAlert: boolean;
  userNotificationUuid: string | undefined;
  userNotificationState: CampaignExportState | undefined;
  exportCampaignAsync: (e: Array<string>) => Promise<void>;
};

const ExportAsyncContext = createContext<ExportAsyncContextType>(null);

const useExportAsyncContext = () => {
  const context = useContext(ExportAsyncContext);
  if (!context) {
    throw new Error("useExportAsyncContext must be used within a ExportAsyncContext");
  }
  return context;
};

const ExportAsyncProvider = ({
  campaignUuid,
  children,
}: {
  campaignUuid: string;
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();

  const { data } = useGetUserCampaignNotifications({ campaignUuid });

  const exportCampaignAsync = useCallback(
    async (interviewsUuids: Array<string>): Promise<void> => {
      const { campaignExportState } =
        await buildRequest<CampaignsRoutes.ExportCampaignInterviews>({
          path: "/campaign/{campaignUuid}/interviews/export",
          params: { campaignUuid },
          payload: { interviewsUuids },
          method: "POST",
        })();
      queryClient.setQueryData([USER_CAMPAIGN_NOTIFICATIONS_TOKEN], { campaignExportState });
    },
    [campaignUuid, queryClient]
  );

  useEffect(() => {
    let interval = undefined;
    if (CampaignExportStates.IN_PROGRESS === data?.campaignExportState) {
      interval = setInterval(() => {
        queryClient.invalidateQueries([USER_CAMPAIGN_NOTIFICATIONS_TOKEN]);
      }, 5000);
    }

    return () => clearInterval(interval);
  }, [campaignUuid, data, queryClient]);

  return (
    <ExportAsyncContext.Provider
      value={{
        userNotificationState: data?.campaignExportState,
        userNotificationUuid: data?.userNotificationUuid,
        showAsyncExportAlert: data?.campaignExportState ? true : false,
        exportCampaignAsync,
      }}
    >
      {children}
    </ExportAsyncContext.Provider>
  );
};

export { ExportAsyncContext, useExportAsyncContext, ExportAsyncProvider };
