import { buildRequest } from "utils/buildRequest";

import { ListUtils } from "@skillup/shared-utils";
import { FilterProp } from "@skillup/ui";

import type {
  GetDashboardConfigRouteType,
  GetDashboardFiltersRouteType,
  GetDashboardQuestionDataRouteType,
  QuestionData,
} from "../types";
import { getFields } from "./config";

export const fetchDashboardPanels = async () => {
  const layout = await buildRequest<GetDashboardConfigRouteType>({
    method: "GET",
    path: "/config",
    target: "INSIGHTS_SERVICE_V2",
  })();

  return layout.data.attributes.dashboards.find((dashboard) =>
    dashboard.feature_tags.includes("training")
  )?.panels;
};

export type FilterConfigType = {
  config: ListUtils.FilterConfigurationMap;
  props: FilterProp<any>;
};

export const getFiltersConfig = async (activeScheduleName: string): Promise<FilterConfigType> => {
  const filters = await buildRequest<GetDashboardFiltersRouteType>({
    method: "GET",
    path: "/config/dashboards_filters",
    target: "INSIGHTS_SERVICE_V2",
  })();

  const fields = await getFields();

  const dashboard = filters.data.attributes.dashboards.find((dashboard) =>
    dashboard.feature_tags.includes("training")
  );

  return dashboard.filters.reduce(
    (acc, filter) => {
      const ignoredFilters = ["filter_plan_name_multi_select"];
      const isPlanFilter = filter.id === "filter_plan_name_multi_select";
      const isStatusFilter = filter.id === "filter_plan_line_completion_phase_multi_select";

      if (ignoredFilters.includes(filter.id)) {
        return acc;
      }

      const field = fields.find((field) => field.name === filter.field_id);

      acc.config[filter.id] = {
        type: ListUtils.FilterType.MULTISELECT,
      };

      let value: string[] | undefined;
      if (isPlanFilter) {
        value = [activeScheduleName];
      }

      if (isStatusFilter) {
        value = filter.fingerprint.distinct_values.filter(
          (option) =>
            !isStatusFilter || (!option.startsWith("collection-") && option !== "plan-denied")
        );
      }

      acc.props[filter.id] = {
        label: field?.recommended_display_name ?? filter.field_id,
        type: ListUtils.FilterType.MULTISELECT,
        operator: ListUtils.FilterOperator.CONTAINS,
        value: value,
        defaultValue: value,
        options: filter.fingerprint.distinct_values
          .filter((option) => !isStatusFilter || !option.startsWith("collection-"))
          .map((option) => ({
            label: option,
            value: option,
          })),
      };

      return acc;
    },
    { props: {}, config: {} } as FilterConfigType
  );
};

export const getCardData = async (questionName: string, filters: string): Promise<QuestionData> => {
  const data = await buildRequest<GetDashboardQuestionDataRouteType>({
    method: "GET",
    path: `/indicator/{questionName}?{filters}`,
    params: { questionName, filters },
    target: "INSIGHTS_SERVICE_V2",
  })();

  return data.data[0]?.attributes;
};
