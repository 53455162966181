import { DSModal, Flex } from "@skillup/ui";
import styles from "./WarningQuitWithoutSave.module.scss";

interface IProps {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
}

export const WarningQuitWithoutSave = ({ isOpen, closeModal, onConfirm }: IProps) => {
  return (
    <DSModal isOpen={isOpen} className={styles.WarningQuitWithoutSave}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title="Abandonner les modifications ?" />
      </DSModal.Header>

      <DSModal.Content>
        <Flex column className={styles.content}>
          <p>Cette trame d'entretien contient des modifications qui n'ont pas été enregistrées.</p>
          <p>
            Si vous quittez cette page sans avoir enregistré vos modifications, elles seront
            définitivement perdues.
          </p>
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={closeModal} label="Annuler" />
        <DSModal.Footer.PrimaryButton label="Abandonner les modifications" onClick={onConfirm} />
      </DSModal.Footer>
    </DSModal>
  );
};
