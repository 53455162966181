import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { DSDropdownItem } from "@skillup/ui";

import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import { useHabilitations } from "./state/habilitations";
import type { TrackingRoutes } from "types/api";

import styles from "./Reglementaire.module.scss";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";

interface Props {
  readonly children: React.ReactNode;
  readonly actions: { label: string; onClick: () => void };
}

const Reglementaire = ({ children, actions }: Props) => {
  const { pathname } = useLocation();
  const { data } = useHabilitations();
  const { addToast } = useToasts();
  const noHabilitations = useMemo(() => !data?.habilitations?.length, [data?.habilitations]);

  const handleExport = useCallback(() => {
    try {
      downloadTypedFileAsUser<TrackingRoutes.ExportExcel>(
        {
          method: "GET",
          path: "/tracking/export",
        },
        {
          target: "API",
          deduceFileDataFromResponseHeaders: true,
        }
      );
    } catch (e) {
      addToast("Une erreur est survenue lors de la génération de l'export", {
        appearance: "error",
      });
    }
  }, [addToast]);

  const layouts = useMemo(() => {
    return [
      {
        tab: {
          id: "habilitations",
          label: "Habilitations",
          url: "/responsable/reglementaire/habilitations",
        },
      },
      {
        tab: {
          id: "collaborators",
          label: "Suivi par collaborateur",
          url: "/responsable/reglementaire/suivi",
          disabled: noHabilitations ? true : undefined,
          tooltipLabel: noHabilitations
            ? "Le suivi par collaborateur n’est pas disponible tant qu’aucune habilitation n’est créée"
            : undefined,
        },
        dropdownContent: [
          <DSDropdownItem
            key="export-suivi"
            label="Exporter le suivi par collaborateur"
            onClick={() => handleExport()}
          />,
        ],
      },
    ].map((e) => {
      return {
        ...e,
        primaryButton: <DSNewHeaderButton label={actions.label} onClick={actions.onClick} />,
      };
    });
  }, [actions.label, actions.onClick, noHabilitations, handleExport]);

  const selectedTab = useMemo(() => {
    return layouts.find((e) => e.tab.url === pathname)?.tab.id;
  }, [layouts, pathname]);

  return (
    <DSLayout title="Formations réglementaires" layouts={layouts} activeLayout={selectedTab}>
      <div className={styles.content}>{children}</div>
    </DSLayout>
  );
};

export default Reglementaire;
