import React, { useEffect } from "react";

import addons from "@storybook/addons";

import { Themes } from "commons";

const DesignSystemThemessContext = React.createContext<Themes>(Themes.ESPACE_COLLABORATEUR);

export const DesignSystemThemesProvider = DesignSystemThemessContext.Provider;

export const useTheme = () => {
  return React.useContext(DesignSystemThemessContext);
};

export const useStorybookTheme = () => {
  const [theme, setTheme] = React.useState<Themes>(Themes.ESPACE_COLLABORATEUR);

  useEffect(() => {
    const chan = addons.getChannel();
    chan.on("storybook-addon-themes/change", setTheme);
    return () => chan.off("storybook-addon-themes/change", setTheme);
  }, []);

  return theme;
};
