import React from "react";

import styles from "./CreateLabel.module.scss";

interface Props {
  readonly label: string;
  readonly className: string;
  readonly required?: boolean;
}

const CreateLabel = ({ className, label, required }: Props) => {
  return (
    <div className={styles.labelWrapper}>
      <label className={className}>{label}</label>
      {required && <span className={styles.required}>*</span>}
    </div>
  );
};

export default CreateLabel;
