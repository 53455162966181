import React, { useCallback, useEffect, useState } from "react";
import { useSetState } from "react-use";
import { useDropzone } from "react-dropzone";

import { DSButton, MaterialIcons, FileDrop, useModal } from "@skillup/ui";

import { UpdateChildPayload, UpdatePayload } from "hooks/useCompany";

import SidePanel from "components/SidePanel";
import CreateLabel from "components/CreateLabel";
import CompanyForm, {
  IForm,
  Organization,
  LoadOrganizationToForm,
  BuildPayloadFromForm,
  ValidateForm,
} from "../CompanyForm";

import DeleteCompany from "../DeleteCompany";
import styles from "./ModifyCompany.module.scss";

interface Props {
  readonly uuid: string;
  readonly details: Organization;
  readonly isChild: boolean;
  readonly submitRequest: (
    uuid: string,
    isChild: boolean,
    o: UpdateChildPayload | UpdatePayload,
    file?: File
  ) => void;
  readonly deleteRequest: (uuid: string) => void;
  readonly onClose: () => void;
  readonly loading: boolean;
}

export default ({
  uuid,
  details,
  isChild,
  submitRequest,
  deleteRequest,
  onClose,
  loading,
}: Props) => {
  const [printLogoReplace, setPrintLogoReplace] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [form, setFormState] = useSetState<IForm>({});

  const {
    isOpen: isDeletionModalOpen,
    show: showDeletionModal,
    hide: hideDeletionModal,
  } = useModal();

  useEffect(() => {
    setPrintLogoReplace(!details.portalLogo);
    setFormState(LoadOrganizationToForm(details));
  }, [setPrintLogoReplace, setFormState, details]);

  const handleMainAction = () => {
    const { form: newForm, hasError } = ValidateForm(form);

    if (hasError) {
      setFormState(newForm);
    }

    if (!hasError) {
      const payload = BuildPayloadFromForm(form);

      submitRequest(uuid, isChild, payload, file);
    }
  };

  const onDrop = useCallback(
    async ([cfile]: File[]) => {
      setFile(cfile);
    },
    [setFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 52428800,
  });

  return (
    <SidePanel
      title={details.name}
      subTitle={isChild ? "Entreprise Associée" : "Mon entreprise"}
      onClose={onClose}
    >
      <div className={styles.modifyCompany}>
        <div className={styles.logoLineWrapper}>
          <CreateLabel className={styles.label} label="Logo" />
          <div className={styles.logoWrapper}>
            {!printLogoReplace && details.portalLogo && (
              <div className={styles.logo}>
                <img
                  alt="logo"
                  src={`${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${details.portalLogo}`}
                />
              </div>
            )}
            {!printLogoReplace && (
              <DSButton
                label="Remplacer le logo"
                icon={<MaterialIcons.Replay />}
                emphasis="Mid"
                onClick={() => setPrintLogoReplace(true)}
              />
            )}
          </div>
          {printLogoReplace && (
            <FileDrop
              isDragActive={isDragActive}
              rootProps={getRootProps()}
              inputProps={getInputProps()}
              btnLabel="Choisir un logo"
              dropLabel="ou glisser déposer un fichier ici"
              value={file?.name}
            />
          )}
        </div>

        <CompanyForm form={form} setFormState={setFormState} isChild={isChild} />

        <div className={styles.buttonsWrapper}>
          <DSButton
            className={styles.button}
            emphasis="Mid"
            label="Annuler"
            onClick={onClose}
            loading={loading}
          />
          <DSButton
            className={styles.button}
            emphasis="High"
            label="Enregistrer les modifications"
            onClick={handleMainAction}
            loading={loading}
          />
        </div>
        {isChild && (
          <div className={styles.deleteButtonWrapper}>
            <DSButton
              className={styles.button}
              emphasis="Low"
              buttonSize="S"
              label="Supprimer cette entreprise associée"
              onClick={() => showDeletionModal()}
              loading={loading}
            />
          </div>
        )}
      </div>

      {isDeletionModalOpen && (
        <DeleteCompany
          subCompanyName={details.name}
          loading={loading}
          submitRequest={() => deleteRequest(uuid)}
          onClose={hideDeletionModal}
        />
      )}
    </SidePanel>
  );
};
