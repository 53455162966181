import UploadSkillEntityModal from "../../../components/Modals/UploadSkillEntityModal";

interface JobsUploadModalProps {
  open: boolean;
  close: () => void;
}

export const JobsUploadModal = ({ close, open }: JobsUploadModalProps) => {
  return (
    <UploadSkillEntityModal
      open={open}
      close={close}
      mutationEntity="jobs"
      mutationRoute="importJobs"
      entityName="fiches de poste"
    />
  );
};
