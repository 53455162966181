import React, {
  useState,
  useMemo,
  forwardRef,
  type ForwardedRef,
  useImperativeHandle,
} from "react";
import cx from "classnames";
import { useUpdateEffect } from "react-use";

import { ListUtils } from "@skillup/shared-utils";

import { type Themes } from "components/commonProps";

import { Flex } from "components/Flex";
import { Button } from "components/Button";
import { Dropdown } from "components/Dropdown/Dropdown";
import { Tooltip } from "components/Tooltip";

import { ExpandMore, Refresh } from "components/MaterialIcons";

import styles from "./Filters.module.scss";

import { Filter } from "./components/Filter";
import { type CompleteFilterProps, type FilterProp } from "./types";

const isFilterVisible = (filterId, filters: FilterProp<any>, init = false) => {
  if (!filters || !filters[filterId]) {
    return false;
  }

  const filter = filters[filterId];

  if (filter.visibilityMode === "always") {
    return true;
  }

  if (filter.visibilityMode === "never") {
    return false;
  }

  if (init && filter.value !== undefined) {
    return true;
  }

  if (filter.defaultValue !== undefined) {
    return true;
  }

  return false;
};

const getFilterValue = (
  filter: CompleteFilterProps<any>,
  init = false
): string | null | undefined => {
  if (init && filter.value !== undefined) {
    return filter.value;
  }

  if (filter.defaultValue !== undefined) {
    return filter.defaultValue;
  }

  return undefined;
};

export interface Props<T extends ListUtils.FilterConfigurationMap> {
  readonly theme: Themes;
  readonly filters: FilterProp<T>;
  readonly config: T;
  readonly onChange: (values: ListUtils.FilterValues<T>) => void;
  readonly className?: string;
  readonly t: (key: string, params?: { defaultValue: string } & any) => string;
  readonly translationPrefix?: string;
}

export interface FilterRef {
  addFilter(id: string): void;
  setValue(id: string, value: ListUtils.FilterValue): void;
  setValues(values: ListUtils.FilterValues<any>): void;
}

function PureFilters<T extends ListUtils.FilterConfigurationMap>(
  { t, translationPrefix = "filters", config, theme, className, filters, onChange }: Props<T>,
  ref: ForwardedRef<FilterRef>
): JSX.Element | null {
  const [values, setValues] = useState<ListUtils.FilterValues<T>>(
    Object.keys(filters ?? {}).reduce(
      (acc, filterId) => ({
        ...acc,
        [filterId]: {
          value: getFilterValue(filters[filterId], true),
          operator: filters[filterId].operator,
          type: filters[filterId].type,
        },
      }),
      {} as ListUtils.FilterValues<T>
    )
  );
  const [visibleFilters, setVisibleFilters] = useState(
    Object.keys(config ?? {}).filter((filterId) => isFilterVisible(filterId, filters ?? {}, true))
  );

  const translate = (key: string, obj?: any) => t(`${translationPrefix}.${key}`, obj);

  useImperativeHandle(
    ref,
    () => ({
      setValue(id: keyof T, value: ListUtils.FilterValue) {
        setValues((oldValues) => ({ ...oldValues, [id]: value }));
      },
      setValues(values: ListUtils.FilterValues<T>) {
        setValues(values);
      },
      addFilter(id: string) {
        handleAddFilter(id);
      },
    }),
    [visibleFilters]
  );

  // Events
  useUpdateEffect(() => {
    onChange(
      Object.keys(values)
        .filter(
          (filterId) =>
            values[filterId] &&
            values[filterId].value !== undefined &&
            values[filterId].value !== null
        )
        .reduce(
          (acc, filterId) => ({
            ...acc,
            [filterId]: values[filterId],
          }),
          {} as ListUtils.FilterValues<T>
        )
    );
  }, [values]);

  // Event handlers
  const handleAddFilter = (id: keyof T) => {
    if (!config[id]) {
      return;
    }

    if (!visibleFilters.includes(id.toString())) {
      setVisibleFilters((visibleFilters) => [...visibleFilters, id.toString()]);
    }
  };

  const handleUpdateFilter = (id: string, value: any) => {
    setValues((values) => ({
      ...values,
      [id]: value,
    }));
  };

  const handleResetFilter = (id: string) => {
    const { defaultValue } = filters?.[id] ?? {};

    setValues((values) => ({
      ...values,
      [id]: defaultValue ?? undefined,
    }));
  };

  const handleResetAllFilter = () => {
    setValues(
      Object.keys(filters ?? {}).reduce(
        (acc, filterId) => ({
          ...acc,
          [filterId]: {
            value: getFilterValue(filters![filterId]),
            operator: ListUtils.FilterOperator.CONTAINS,
            type: config[filterId].type,
          },
        }),
        {} as ListUtils.FilterValues<T>
      )
    );
    setVisibleFilters(
      Object.keys(config ?? {}).filter((filterId) => isFilterVisible(filterId, filters ?? {}))
    );
  };

  const handleDeleteFilter = (id: string) => {
    setValues((values) => ({
      ...values,
      [id]: undefined,
    }));
    setVisibleFilters((visibleFilters) => visibleFilters.filter((filterId) => filterId !== id));
  };

  const notVisibleFilters = useMemo(
    () =>
      Object.keys(config ?? {})
        .filter((id) => !visibleFilters.some((f) => f === id))
        .sort((idA, idB) =>
          translate(`filter.label.${idA}`).localeCompare(translate(`filter.label.${idB}`))
        ),
    [config, visibleFilters, values]
  );

  return (
    <Flex role="filters-container" className={cx(styles.Filters, styles[theme], className)}>
      <Flex className={cx(styles.FilterContainer)}>
        {visibleFilters.map((filterId) => (
          <Filter
            key={filterId}
            t={translate}
            id={filterId}
            translationPrefix={translationPrefix}
            filter={filters[filterId]}
            config={config[filterId]}
            value={values[filterId]}
            theme={theme}
            onChange={handleUpdateFilter}
            onReset={handleResetFilter}
            onDelete={handleDeleteFilter}
          />
        ))}
        <Flex role="filters-actions" className={cx(styles.ActionContainer)}>
          <Dropdown
            theme={theme}
            disabled={notVisibleFilters.length === 0}
            button={
              <Button
                role="add-filter-button"
                emphasis="Low"
                buttonSize="S"
                label={t(`${translationPrefix}-add_filter`, {
                  defaultValue: "Ajouter un filtre",
                })}
                actionIcon={<ExpandMore />}
              />
            }
          >
            {notVisibleFilters.map((id) => (
              <Dropdown.Item
                data-testid={`filter-${id}`}
                key={id}
                label={filters[id]?.label ?? translate(`filter.label.${id}`)}
                onClick={() => handleAddFilter(id)}
              />
            ))}
          </Dropdown>
          <Tooltip
            direction="top"
            label={t(`${translationPrefix}-reset_all`, {
              defaultValue: "Réinitialiser les filtres",
            })}
          >
            <Button
              role="clear-filters-button"
              className={cx(styles.RefreshButton)}
              buttonSize="S"
              iconOnly
              icon={<Refresh />}
              onClick={handleResetAllFilter}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
}

const Filters = forwardRef(PureFilters);

export { Filters };
