import { FormatDate, ParseDate } from "@skillup/shared-utils";
import { ReviewDetails } from "@skillup/training-bridge";
import i18n from "i18n";

const formatDateToLiteral = (date: number) => {
  if (!date) {
    return null;
  }

  const locale = i18n.language;
  const dateInDateTime = ParseDate.FromMiliTimestamp(date);
  const dateFormat = FormatDate.GetLiteralMonthFormat(locale);

  return FormatDate.ToStringLiteralFormat(dateInDateTime, dateFormat, locale);
};

export const getCompletedAtAndStartLiteralDates = (reviewDetails: ReviewDetails) => {
  if (!reviewDetails) {
    return { completedAt: null, startDate: null };
  }
  const completedAt = formatDateToLiteral(reviewDetails?.session.completedAt);
  const startDate = formatDateToLiteral(reviewDetails?.session.startDate);

  return { completedAt, startDate };
};
