import { Button } from "@skillup/ui";
import Loader from "components/Loader";
import React, { useEffect, useState } from "react";
import { formatError, TranslateFunction } from "services/errors";
import Acta from "utils/Acta";
import useTranslation from "hooks/useTranslation";
import DataLayer from "utils/DataLayer";

import styles from "./PreviewModal.module.scss";

interface IProps {
  templateUuid: string;
}

interface IPreviewData {
  asRf: string;
  asCollab: string;
  asManager: string;
}

const generatePreview = async (templateUuid: string, setPreview, t: TranslateFunction) => {
  try {
    const previewData = (await DataLayer.request({
      url: `/v1/templates/interviews/${templateUuid}/preview`,
      method: "POST",
    })) as IPreviewData;
    setPreview(previewData);
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      message: formatError(t, err, {
        defaultValue: t("error.interview.preview.generate"),
      }),
      type: "error",
    });
  }
};

const PreviewModal: React.FunctionComponent<IProps> = ({ templateUuid }) => {
  // générer les liens avec les previews/
  const [preview, setPreview] = useState<IPreviewData>();
  const { t } = useTranslation();
  useEffect(() => {
    generatePreview(templateUuid, setPreview, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateUuid]);

  if (!preview) {
    return <Loader width={50} />;
  }

  return (
    <div className={styles.preview}>
      <p>
        Deux entretiens ont été générés et reliés à votre compte, un en tant que collaborateur et un
        en tant que manager.
      </p>
      <p>Pour y accéder, cliquez sur les liens suivants:</p>
      <div className={styles.links}>
        <Button label="Espace Collaborateur" onClick={() => window.open(preview.asCollab)} />
        <Button label="Espace Manager" onClick={() => window.open(preview.asManager)} />
      </div>
      <p>Vous pouvez également accéder à la campagne depuis l'espace RF en suivant ce lien:</p>
      <div className={styles.links}>
        <Button label="Espace RF" onClick={() => window.open(preview.asRf)} />
      </div>
    </div>
  );
};

export default PreviewModal;
