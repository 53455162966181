import React, { useState, useCallback } from "react";
import cx from "classnames";

import styles from "./DeveloperEnvironment.module.scss";

const COOKIE_KEY = "DeveloperEnvironmentVisibility";
const COOKIE_MAX_AGE = 60; // seconds

const DeveloperEnvironment = (): JSX.Element | null => {
  const [isVisible, setVisibility] = useState<boolean>(!document.cookie.includes(COOKIE_KEY));

  const hide = useCallback(() => {
    setVisibility(false);
    document.cookie = `${COOKIE_KEY}=true;max-age=${COOKIE_MAX_AGE}`;
  }, [setVisibility]);

  if (!isVisible) return null;

  return (
    <button
      name="devEnvironment"
      onClick={hide}
      className={cx(styles.DeveloperEnvironment, `env-${process.env.REACT_APP_ENV}`)}
    >
      <span className={styles.env}>{process.env.REACT_APP_ENV}</span>
    </button>
  );
};

export default DeveloperEnvironment;
