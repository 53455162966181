import React from "react";
import { TextDropdown, TextInput } from "@skillup/ui";

import { Child } from "../types";

type InterviewPageTitle = Child & { kind: "title" };
const TitleBlock = ({
  data,
  updateChild,
}: {
  data: InterviewPageTitle;
  updateChild: (newChildData: Child) => void;
}) => {
  const variants = [{ value: "H1" }, { value: "H2" }, { value: "H3" }, { value: "H4" }];
  return (
    <form>
      <label>Variante</label>
      <TextDropdown
        options={variants}
        small
        selectedIndex={variants.findIndex((variant) => variant.value === data.variant)}
        onSelect={({ value }) =>
          updateChild({ ...data, variant: value as InterviewPageTitle["variant"] })
        }
      />
      <label>Titre</label>
      <TextInput
        key={`title-${data.uuid}`}
        value={data.title}
        onChange={(title) => updateChild({ ...data, title })}
      />
    </form>
  );
};

export default TitleBlock;
