type Watch = (newValue: string, oldValue: string, key: string) => void;
const watches: Record<
  string,
  {
    storage: Storage;
    callback: Watch;
  }[]
> = {};

if (typeof window !== "undefined") {
  window.addEventListener("storage", (e) => {
    if (watches[e.key]) {
      for (const watch of watches[e.key]) {
        if (watch.storage === e.storageArea) watch.callback(e.newValue, e.oldValue, e.key);
      }
    }
  });
}

export const allPersistentStorages = [localStorage, sessionStorage];

export default {
  get: (key: string, storages = [localStorage]) => {
    if (!key) return;

    let item;
    for (const storage of storages) {
      item = storage.getItem(key);
      if (item !== null) break;
    }
    try {
      return JSON.parse(item);
    } catch (error) {
      return item;
    }
  },

  off: (key: string, callback: Watch, storages = [localStorage]) => {
    for (const storage of storages)
      watches[key] = watches[key]?.filter(
        (watch) => watch.callback !== callback && watch.storage !== storage
      );
  },

  on: (key: string, callback: Watch, storages = [localStorage]) => {
    watches[key] = watches[key] || [];

    for (const storage of storages) {
      if (!watches[key].some((watch) => watch.callback === callback && watch.storage === storage)) {
        watches[key].push({ storage, callback });
      }
    }
  },

  remove: (key: string, storages = [localStorage]) => {
    if (!key) return;
    for (const storage of storages) storage.removeItem(key);
  },

  set: function (key: string, value: string, tries = 0, storages = [localStorage]) {
    if (!key) return;
    try {
      for (const storage of storages) storage.setItem(key, value);
    } catch (err) {
      const isCorruptedStorageRelated = [err.message, err.name].includes("NS_ERROR_FILE_CORRUPTED");
      if (isCorruptedStorageRelated && tries < 1) {
        // Clearing storages && trying once again.
        for (const storage of storages) storage.clear();
        this.set(key, value, tries + 1, storages);
      } else {
        throw err;
      }
    }
  },
};
