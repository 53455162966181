import { DSButton } from "@skillup/ui";

import ErrorPage from "components/ErrorPage";
import { Flex } from "@skillup/design-system";

import styles from "./Empty.module.scss";
import BlankStateNoSkillIcon from "assets/skill/blank-state-no-skill.svg";

interface EmptyViewProps {
  message: string;
  buttonLabel?: string;
  buttonClick?: () => void;
  withStyle?: boolean;
}

const EmptyView = ({ message, buttonLabel, buttonClick, withStyle }: EmptyViewProps) => (
  <Flex flexDirection="column" alignItems="center">
    <ErrorPage
      message={message}
      iconSrc={BlankStateNoSkillIcon}
      className={withStyle && styles.styledText}
    />
    {buttonLabel && buttonClick && (
      <DSButton label={buttonLabel} onClick={buttonClick} darkMode={true} />
    )}
  </Flex>
);

export default EmptyView;
