import { Stepper } from "@skillup/ui";
import { Step } from "../../models/Step";
import styles from "./Header.module.scss";

interface IProps {
  readonly currentStep: number;
  readonly setCurrentStep: (currentStep: number) => void;
  readonly steps: Step[];
}
const Header = ({ steps, currentStep, setCurrentStep }: IProps): JSX.Element => {
  return (
    <header className={styles.Header}>
      <div className={styles.container}>
        <Stepper
          steps={steps}
          activeStep={currentStep + 1}
          onChangeStep={(value) => {
            setCurrentStep(value - 1);
          }}
        />
      </div>
    </header>
  );
};

export default Header;
