import User from "utils/User";

import { analyzePrice } from "../../utils/analyzePrice";
import { analyzeDuration } from "../../utils/analyzeDuration";

import { TrainingForm } from "./types";

export function isValidIntraData(trainingForm: TrainingForm, creation = false) {
  let optionalChecks = true;

  if (User.isCompanyWithTax()) {
    optionalChecks =
      trainingForm.priceWithTax != null && !analyzePrice(trainingForm.priceWithTax).isInvalid;
  }

  return (
    trainingForm.name &&
    trainingForm.mode &&
    trainingForm.price != null &&
    !analyzeDuration(trainingForm.duration).isInvalid &&
    trainingForm.duration != null &&
    !analyzePrice(trainingForm.price).isInvalid &&
    trainingForm.trainingOrganization &&
    (trainingForm.reference || creation) &&
    trainingForm.tag &&
    optionalChecks
  );
}
