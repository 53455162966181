import { useTranslation } from "react-i18next";

import { Flex, Text } from "@skillup/design-system";
import { FormatDate, getInitials } from "@skillup/shared-utils";
import { DSAvatar, DSTooltip, ChipNumber, DSDropdown, DSDropdownItem } from "@skillup/ui";

import User from "utils/User";
import { Template } from "services/peopleReview";

import styles from "./TemplateCard.module.scss";

type TemplateCardProps = {
  template: Template;
  onEdit: () => void;
  onArchive: () => void;
};

export const TemplateCard = ({ onArchive, onEdit, template }: TemplateCardProps) => {
  const { t } = useTranslation();
  const user = User.getUserData();
  const { creation, edition, title } = template;

  const actionsTooltip = template.actions.map((action) => action.label).join(",\n");
  const coreHRFieldsTooltip = template.coreHRFields.map((field) => field.label).join(",\n");
  const initials = getInitials({ fullName: creation.author.name });

  const localeToUse = user?.currentLanguage || user?.defaultLanguage || "fr";
  const lastEventDate = FormatDate.ToRelative({
    from: (edition || creation).date,
    locale: localeToUse,
  });

  return (
    <Flex
      gap="l"
      paddingVertical="xs"
      paddingHorizontal="s"
      flexDirection="column"
      className={styles.templateCard}
    >
      <Flex gap="xxs" flexDirection="column">
        <Flex
          width="100%"
          marginBottom="xs"
          alignItems="center"
          flexDirection="row"
          justifyContent="start"
        >
          <Text espaceFont="body1Bold" color="plainText-onLight-default">
            {title}
          </Text>

          <DSDropdown buttonSize="S" className={styles.actions}>
            <DSDropdownItem label="Modifier" onClick={onEdit} />
            <DSDropdownItem label="Archiver" onClick={onArchive} />
          </DSDropdown>
        </Flex>
        <Flex>
          <DSAvatar
            size="XS"
            color="Grey"
            initials={initials}
            randomColorSeedString={creation.author.uuid}
          />

          {(edition || creation) && (
            <Text marginLeft="xxs" marginBottom="s" espaceFont="captionRegular">
              {edition
                ? t("templates.card.editedBy", {
                    defaultValue: "Modifié par {{user}}",
                    user: edition.author.name,
                  })
                : creation &&
                  t("templates.card.createdBy", {
                    defaultValue: "Créé par {{user}}",
                    user: creation.author.name,
                  })}{" "}
              {t("templates.card.eventAt", {
                date: lastEventDate,
                defaultValue: "{{date}}",
              })}
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex justifyContent="space-between">
        <Flex flexGrow={1}>
          <ChipNumber color="Sky" value={template.coreHRFields.length} />
          <DSTooltip label={coreHRFieldsTooltip}>
            <Text marginLeft="xxs" espaceFont="captionBold" color="plainText-onLight-default">
              Propriétés
            </Text>
          </DSTooltip>
        </Flex>
        <Flex flexGrow={1}>
          <ChipNumber color="Gold" value={template.actions.length} />
          <DSTooltip label={actionsTooltip}>
            <Text marginLeft="xxs" espaceFont="captionBold" color="plainText-onLight-default">
              Actions
            </Text>
          </DSTooltip>
        </Flex>
      </Flex>
      <Flex paddingVertical="xs">
        <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
          Utilisé pour 2 campagnes
        </Text>
      </Flex>
    </Flex>
  );
};