import React from "react";
import { useBoolean, useSetState } from "react-use";
import { useDropzone } from "react-dropzone";

import DropDown from "components/DropDown";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

import styles from "./ScheduleImportModalStyles.module.scss";
import { Toggle } from "@skillup/ui";
import QueryString from "qs";

interface IProps {
  scheduleUuid: string;
  reload: () => Promise<void>;
}

interface IState {
  importFrom: "catalog" | "manual";
  uploading?: boolean;
}

const triggerImportRun = async ({
  importFrom,
  scheduleUuid,
  body,
  executeImport,
}: {
  scheduleUuid: string;
  importFrom: IState["importFrom"];
  body: FormData;
  executeImport: boolean;
}) => {
  const query = executeImport ? QueryString.stringify({ executeImport }) : "";
  const response = (await DataLayer.request({
    method: "POST",
    url:
      importFrom === "catalog"
        ? `/v1/schedule/upload/${scheduleUuid}`
        : `/v1/schedule/upload/manual-requests/${scheduleUuid}?${query}`,
    body,
    contentType: "multipart/form-data",
  })) as {
    errors: string[];
    validRows: Record<string, any>[];
    invalidRows: Record<string, any>[];
  };

  return response;
};

const importOptions = [
  { label: "Ajouter des données reliées au catalogue", value: "catalog" },
  { label: "Ajouter des données manuelles hors-skillup", value: "manual" },
] as const;

const ScheduleImportModal = (props: IProps) => {
  const { reload } = props;
  const [state, setState] = useSetState<IState>({
    importFrom: "catalog",
  });

  const [canChooseToExecuteImport, unlockExecutesImportChoice] = useBoolean(false);
  const [executeImport, setExecuteImport] = useBoolean(false);

  const onDrop = async (files) => {
    setState({ uploading: true });

    const body = new FormData();
    body.append("file", files[0]);

    try {
      if (executeImport) {
        const confirmed = window.confirm("ATTENTION: Les données seront chargées. CAYCHAUD");
        if (!confirmed) throw new Error();
      }
      const response = await triggerImportRun({
        body,
        executeImport,
        importFrom: state.importFrom,
        scheduleUuid: props.scheduleUuid,
      });

      if (response.errors.length === 0) {
        unlockExecutesImportChoice(true);
      }

      Acta.dispatchEvent("sendAppMessage", {
        message: "Fichier envoyé.",
        type: "success",
      });
      setState({ uploading: false });
      reload();
    } catch (error) {
      unlockExecutesImportChoice(false);
      setExecuteImport(false);

      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de l’envoi de votre fichier.",
        type: "error",
      });
      setState({ uploading: false });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 52428800,
    multiple: false,
  });

  return (
    <div className={styles.ScheduleImportModal}>
      <DropDown
        defaultValue={state.importFrom}
        label="Stratégie"
        size="small"
        onSelect={(value) => setState({ importFrom: value })}
        options={importOptions}
      />
      <section className={styles.dropZone}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {<p>Déposez un fichier ici ou cliquez ici pour choisir un fichier</p>}
        </div>
      </section>
      {false && <h2>Résumé</h2>}
      {canChooseToExecuteImport && (
        <>
          <label>Le rapport ne contient aucune erreur. Charger les données ?</label>
          <Toggle active={executeImport} onToggle={() => setExecuteImport(!executeImport)} />
        </>
      )}
      {!executeImport && (
        <p>
          Les données ne seront pas chargées, vous recevrez seulement un rapport. Vous ne pourrez
          charger les données QUE si ce dernier ne contient aucune erreur
        </p>
      )}
    </div>
  );
};

export default ScheduleImportModal;
