import React, { Dispatch, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AddToast, useToasts } from "react-toast-notifications";

import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import type { IntraRoutes } from "types/api";

import { fetchIntra } from "../api/fetchByUuid";

import { IntraForm } from "../components/IntraForm";

import { isValidIntraData } from "../utils/isValidIntraData";

import styles from "./IntraEditionView.module.scss";
import { IntraActions, useIntras } from "services/intras";

type State = { status: "loading" } | { status: "loaded" } | { status: "saving" };

export default function IntraCreationView() {
  const history = useHistory();
  const { update } = useIntras();

  const params = useParams<{ intraUuid }>();

  const [state, setState] = useState<State>({ status: "loading" });

  const { intraData, setProperty, setTag } = useIntraData(params.intraUuid);
  const { addToast } = useToasts();

  useEffect(() => {
    if (state.status === "loading" && intraData != null) {
      setState({
        status: "loaded",
      });
    }
  }, [intraData, state.status]);

  const canSave = useMemo(() => {
    if (state.status !== "loaded") return false;

    return isValidIntraData(intraData);
  }, [intraData, state.status]);

  return (
    <DSLayout
      title={intraData?.properties?.name ?? ""}
      parent={{
        title: "Modification d'un intra",
        onClick: () => history.push("/responsable/programmes/gestion-intras/"),
      }}
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              disabled={!canSave}
              tooltip={!canSave ? "Veuillez remplir tous les champs obligatoires" : undefined}
              label="Enregistrer les modifications"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit({
                  intraData,
                  intraUuid: params.intraUuid,
                  setState,
                  state,
                  update,
                  addToast,
                });
              }}
              loading={state.status === "saving" || state.status === "loading"}
            />
          ),
        },
      ]}
    >
      <div className={styles.editionView}>
        {(state.status === "loaded" || state.status === "saving") && (
          <IntraForm setTag={setTag} intraData={intraData} setProperty={setProperty} />
        )}
      </div>
    </DSLayout>
  );
}

type IntraData = IntraRoutes.GET_BY_UUID["response"];
type IntraProperties = IntraData["properties"];

function useIntraData(intraUuid: string) {
  const [intraData, setIntraData] = useState<IntraData>();

  useEffect(() => {
    if (intraUuid) {
      fetchIntra(intraUuid).then((data) => {
        setIntraData(data);
      });
    }
  }, [intraUuid]);

  const setProperty = useCallback(
    <T extends keyof IntraProperties>(property: T) =>
      (value: IntraProperties[T]) => {
        setIntraData({
          ...intraData,
          properties: {
            ...intraData?.properties,
            [property]: value,
          },
        });
      },
    [intraData]
  );

  const setTag = useCallback(
    (tag: IntraData["tag"]) => {
      setIntraData({
        ...intraData,
        tag,
      });
    },
    [intraData]
  );

  return { intraData, setProperty, setTag };
}

function handleSubmit({
  setState,
  state,
  intraData,
  intraUuid,
  update,
  addToast,
}: {
  intraUuid: string;
  intraData: IntraData;
  state: State;
  setState: Dispatch<React.SetStateAction<State>>;
  update: IntraActions.UPDATE;
  addToast: AddToast;
}) {
  if (state.status === "loaded") {
    setState({ status: "saving" });
    update({
      intraUuid,
      intraDetails: {
        description: intraData.properties.description,
        duration: intraData.properties.duration,
        mode: intraData.properties.mode,
        prerequisites: intraData.properties.prerequisites,
        price: intraData.properties.price,
        priceWithTax: intraData.properties.priceWithTax,
        program: intraData.properties.program,
        objectives: intraData.properties.objectives,
        targetAudience: intraData.properties.targetAudience,
        name: intraData.properties.name,
        trainingOrganization: intraData.properties.trainingOrganization,
        trailer: intraData.properties.trailer,
        tag: intraData.tag?.value,
        reference: intraData.properties.reference,
      },
      addToast,
    });
  }
}
