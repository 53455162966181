import React, { useMemo } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { useBaseUrl } from "@skillup/hooks";
import { isUndefined } from "lodash";
import cx from "classnames";

import Icon from "components/Icon";
import { chevronRight, chevronBottom, home } from "uiAssets/Icons";
import Colors from "uiAssets/Colors";

import styles from "./BreadcrumbStyles.module.scss";

interface Props {
  training?: boolean;
  associated: Array<{
    name: string;
    seoSlug: string;
    trainingCount: number;
    uuid: string;
  }>;
  id?: string;
  links: Array<{
    name: string;
    path: string;
  }>;
}

const Breadcrumb = ({ training, associated, id, links }: Props) => {
  const className = useMemo(() => (training ? styles.training : null), [training]);
  const chevronWidth = useMemo(() => (training ? 10 : 12), [training]);

  const location = useLocation();
  const { query } = useParams<{ query?: string }>();
  const { baseUrl } = useBaseUrl();
  const rootUrl = `${baseUrl}/programmes/catalogue`;

  const linksPrefix = isUndefined(query)
    ? baseUrl
      ? `${baseUrl}/programmes/catalogue/`
      : ""
    : `${baseUrl}/recherche/`;

  return (
    <nav className={cx(styles.Breadcrumb, className)} id={id}>
      <Link to={rootUrl} className={styles.linkContainer}>
        <Icon icon={home} width={training ? 16 : 18} className={styles.home} />
        <Icon icon={chevronRight} width={chevronWidth} className={styles.chevron} />
      </Link>

      {links &&
        links.slice(0, links.length - 1).map((link) => (
          <Link
            key={link.path}
            to={linksPrefix + (query ?? link.path)}
            className={styles.linkContainer}
          >
            {link.name}
            <Icon icon={chevronRight} width={chevronWidth} className={styles.chevron} />
          </Link>
        ))}

      {associated && associated.length > 0 && (
        <div className={cx(styles.linkContainer, styles.currentLink)}>
          {links.slice(-1)[0].name}
          <Icon icon={chevronBottom} width={chevronWidth} className={styles.chevron} />
          <div>
            {associated.map((tag) => (
              <Link key={`associated-${tag.seoSlug}`} to={linksPrefix + tag.seoSlug}>
                {tag.name}
                <Icon icon={chevronRight} width={chevronWidth} fill={Colors.blue} />
              </Link>
            ))}
          </div>
        </div>
      )}

      {links && !!links.length && (!associated || associated.length === 0) && (
        <Link
          to={
            linksPrefix +
            (isUndefined(query) ? links.slice(-1)[0].path : `${query}${location.search}`)
          }
          className={cx(styles.linkContainer, styles.currentLink)}
        >
          {links.slice(-1)[0].name}
        </Link>
      )}
    </nav>
  );
};

export default Breadcrumb;
