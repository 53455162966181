import React from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@tanstack/react-query";

import { buildRequest } from "utils/buildRequest";

import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";
import { DSModal } from "@skillup/ui";

interface MutationParams {
  rows: string[];
}

interface RevertToPendingProps {
  reload: () => void;
  onClose: () => void;
  rowsUuids: string[];
}

export default ({ reload, onClose, rowsUuids }: RevertToPendingProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const { mutate, isLoading } = useMutation({
    mutationFn: async ({ rows }: MutationParams) =>
      await buildRequest<ScheduleRowRoutes.RevertToPending>({
        method: "POST",
        path: "/scheduleRow/revert-to-pending",
        payload: { rows },
      })(),
  });
  const numberOfRows = rowsUuids.length;

  const handleSubmit = () => {
    mutate(
      { rows: rowsUuids },
      {
        onSuccess: () => {
          addToast(t("app.success.message", { defaultValue: "Opération réussie" }), {
            appearance: "success",
          });
          reload();
          onClose();
        },
        onError: () => {
          addToast(t("app.errors.message"), { appearance: "error" });
        },
      }
    );
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("manager.bringUpNeed.specificTrainingRequestLink", {
            defaultValue: "Remettre dans « À valider RH »",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <p>
          {t("trainings.schedules.moveToPending.description.confirm", {
            count: numberOfRows,
            defaultValue: `Si vous confirmez cette action, la demande repassera dans l'onglet "A valider RH" du recueil`,
          })}
        </p>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("app.modal.action.cancel", { defaultValue: "Annuler" })}
          onClick={onClose}
        />
        <DSModal.Footer.PrimaryButton
          loading={isLoading}
          disabled={isLoading}
          label={t("trainings.schedules.moveToPending.button.confirm", {
            count: numberOfRows,
            defaultValue: "Renvoyer {{count}} ligne",
          })}
          onClick={handleSubmit}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
