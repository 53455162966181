import { useState } from "react";
import { useAreas } from "hooks";
import { TranslationType } from "hooks/useTranslation";

import { ListUtils } from "@skillup/shared-utils";
import { useUrlSyncedFilters } from "@skillup/ui";

export const useSkillsGridFilters = (t: TranslationType) => {
  const { allAreas } = useAreas();
  const perimeterVisibilityMode: ListUtils.FilterVisibility =
    allAreas.length > 1 ? "always" : "never";

  const [{ conf, initialValues }, setFilterConfig] = useState({
    conf: {
      name: {
        type: ListUtils.FilterType.TEXT,
        label: t("skills.label.skillName", { defaultValue: "Compétence" }),
      },
      category: {
        type: ListUtils.FilterType.TEXT,
        label: t("skills.label.category", { defaultValue: "Catégorie" }),
      },
      perimeters: {
        type: ListUtils.FilterType.MULTISELECT,
        label: t("skills.label.perimeter", { defaultValue: "Périmètre" }),
      },
    },

    initialValues: {
      name: {
        label: t("skills.label.skillName", { defaultValue: "Compétence" }),
        placeholder: t("skills.placeholder.skillName", {
          defaultValue: "Rechercher une compétence",
        }),
      },
      category: {
        label: t("skills.label.category", { defaultValue: "Catégorie" }),
        placeholder: t("skills.placeholder.category", {
          defaultValue: "Filtrer par catégorie",
        }),
      },
      perimeters: {
        initialValue: allAreas.map(({ uuid }) => uuid),
        options: allAreas.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
        visibilityMode: perimeterVisibilityMode,
      },
    },
  });

  const [filters, filterValues, setFilterValues] = useUrlSyncedFilters(conf, initialValues);

  return [conf, filters, filterValues, setFilterValues, setFilterConfig] as [
    typeof conf,
    typeof filters,
    typeof filterValues,
    typeof setFilterValues,
    typeof setFilterConfig,
  ];
};
