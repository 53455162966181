import React from "react";

import Icon from "components/Icon";
import { cross as crossIcon } from "uiAssets/StrokeIcons";

import Positioning from "../Modals/Positioning";

import styles from "./PositioningModalStyles.module.scss";

export interface IProps {
  items: Array<string>;
  noEmail?: boolean;
  noManager?: boolean;
  noEmailManager?: boolean;
  scheduleUuid: string;
  headerTitle?: string;
  onClose: (needRefresh?: boolean) => void;
}

const positioningModal: React.FunctionComponent<IProps> = ({
  items,
  scheduleUuid,
  noEmail,
  noManager,
  noEmailManager,
  headerTitle,
  onClose,
}) => {
  return (
    <div className={styles.positioningModal}>
      {headerTitle && (
        <header>
          <span>{headerTitle}</span>
          <button onClick={() => onClose()}>
            <Icon strokeIcon={crossIcon} width={12} />
          </button>
        </header>
      )}
      <div className={styles.content}>
        <Positioning
          items={items}
          onDone={() => onClose(true)}
          noEmail={noEmail}
          noManager={noManager}
          noEmailManager={noEmailManager}
          scheduleUuid={scheduleUuid}
        />
      </div>
    </div>
  );
};

export default React.memo(positioningModal);
