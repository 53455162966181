import UploadSkillEntityModal from "../../../../../components/Modals/UploadSkillEntityModal";

interface UploadEmployeesJobsModalProps {
  open: boolean;
  close: () => void;
}

const UploadEmployeesJobsModal = ({ close, open }: UploadEmployeesJobsModalProps) => {
  return (
    <UploadSkillEntityModal
      open={open}
      close={close}
      mutationEntity="employees"
      mutationRoute="importEmployeesJobs"
      entityName="associations de fiches de poste"
    />
  );
};

export default UploadEmployeesJobsModal;
