import React from "react";
import { ITraining } from "@skillup/types";

import Training from "./Training";
import styles from "./TrainingsList.module.scss";

interface Props {
  readonly trainings: ITraining[];
  readonly sessionThreshold: Date;
}

const TrainingsList = (props: Props): JSX.Element => (
  <div className={styles.TrainingsList} id="trainingList">
    {(props.trainings || []).map((training) => (
      <Training key={training.uuid} {...training} {...props} />
    ))}
  </div>
);

export default TrainingsList;
