import { useQuery, useQueryClient } from "@tanstack/react-query";

import { Either } from "@skillup/monads";

import { getScales, type Scale } from "./getScales";
import { createScale } from "./createScales";

export type { Scale };

export function useCoreHRScales(options: Options = defaultOptions) {
  const queryClient = useQueryClient();

  const query = useQuery(["core-hr-scales"], getScales, {
    ...defaultOptions,
    ...options,
  });

  const create = async (params: {
    label: string;
    options: { label: string; value: number; isActive: boolean }[];
  }) => {
    try {
      await createScale(params);

      queryClient.refetchQueries(["core-hr-scales"]);

      return Either.Right(undefined);
    } catch (err) {
      return Either.Left(err);
    }
  };

  return {
    scales: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    actions: {
      create,
    },
  };
}

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};
const defaultOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
};
