import { useCallback } from "react";

import {
  Label as DSLabel,
  Flex,
  DSRadioGroup,
  DSRadio,
  DSTextArea,
  AssistiveArea,
} from "@skillup/ui";

import type { Child } from "../../../../reducer";
import styles from "./HTMLChild.module.scss";

type Html = Extract<Child, { kind: "html" }>;

export function HTMLChild({
  child,
  updateChild,
}: {
  child: Html;
  updateChild: (data: Partial<Html>) => void;
}) {
  const changeContent = useCallback(
    (newContent: string) => {
      updateChild({ content: newContent });
    },
    [updateChild]
  );

  const changeVariant = useCallback(
    (newVariant: Html["variant"]) => {
      updateChild({ variant: newVariant });
    },
    [updateChild]
  );

  return (
    <div className={styles.htmlChild}>
      <DSLabel label="Style de texte" required />
      <Flex row className={styles.htmlChild__variants}>
        <DSRadioGroup name={"html-child-variant" + child.uuid}>
          <Flex column className={styles.htmlChild__variantsRadio}>
            <DSRadio
              label="Texte basique"
              checked={child.variant === "warning"}
              onChange={() => changeVariant("warning")}
              helperText="Pas de mise en forme particulière"
            />
          </Flex>
          <Flex column className={styles.htmlChild__variantsRadio}>
            <DSRadio
              label="Annonce"
              checked={child.variant === "info"}
              onChange={() => changeVariant("info")}
              helperText="Fond jaune pâle et icône “information” sur la gauche du paragraphe"
            />
          </Flex>
        </DSRadioGroup>
      </Flex>
      <DSLabel label="Texte à afficher" required />
      <DSTextArea
        value={child.content}
        onChange={changeContent}
        placeholder={"Saisissez le texte à afficher"}
        debounceValue={300}
        toolbarButtons={[
          "bold",
          "italic",
          "underline",
          "paragraphFormat",
          "|",
          "textColor",
          "backgroundColor",
          "|",
          "insertLink",
          "insertVideo",
          "|",
          "formatUL",
          "formatOL",
          "|",
          "outdent",
          "indent",
          "|",
          "alignLeft",
          "alignCenter",
          "alignJustify",
          "|",
          "selectAll",
          "clearFormatting",
          "|",
          "help",
          "html",
          "|",
          "undo",
          "redo",
        ]}
      />
      {!child.content && <AssistiveArea mode="error" text="Ce champ doit être rempli." />}
    </div>
  );
}
