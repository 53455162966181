import React from "react";

export type TranslationFunction = (key: string, params?: { defaultValue?: string } & any) => string;

const DesignSystemTranslationsContext = React.createContext<TranslationFunction>(
  (key: string) => key
);

export const DesignSystemTranslationsProvider = DesignSystemTranslationsContext.Provider;

export const useTranslation = () => React.useContext(DesignSystemTranslationsContext);
