export default (pathname: string): string => {
  let baseURL = "";
  const splitedPath = pathname.split("/");

  if (splitedPath.includes("collaborateur")) {
    baseURL = "/collaborateur";
  } else if (splitedPath.includes("manager")) {
    baseURL = "/manager";
  } else if (splitedPath.includes("responsable")) {
    baseURL = "/responsable";
  }

  return baseURL;
};
