import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSButton, Modal } from "@skillup/ui";
import { Future } from "@skillup/monads";
import type { TrackingRoutes } from "types/api";

import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";
import type { Tracking } from "../../../state/tracking";

import styles from "./UnarchiveTrackingModal.module.scss";

interface ArchiveProps {
  readonly tracking: Tracking;
  readonly onClose: () => void;
}

async function generateUnarchiveRequest(trackingUuid: string) {
  return buildSafeRequest<TrackingRoutes.Unarchive>({
    method: "POST",
    params: {
      trackingUuid,
    },
    path: "/tracking/{trackingUuid}/unarchive",
  });
}

function UnarchiveTrackingModal(props: ArchiveProps) {
  const { tracking, onClose } = props;

  const { addToast } = useToasts();
  const { t } = useTranslation();

  async function unarchiveTracking(trackingUuid: string, onClose: () => void) {
    const request = await generateUnarchiveRequest(trackingUuid);
    await handleUnarchiveResponse(request, addToast, t, onClose);
  }

  return (
    <Modal size="big" title="Repasser en ligne active" onClose={props.onClose} disableOnClickAway>
      <div className={styles.unarchiveTrackingModal}>
        <div className={styles.warningText}>
          <p>
            En réactivant la ligne de suivi {tracking.habilitation.name} pour le collaborateur{" "}
            {tracking.user.fullName}, vous pourrez de nouveau suivre son statut.
          </p>
        </div>

        <div className={styles.actions}>
          <DSButton label="Annuler" emphasis="Low" onClick={props.onClose} />
          <DSButton
            label="Confirmer"
            emphasis="High"
            onClick={() => unarchiveTracking(tracking.uuid, onClose)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default UnarchiveTrackingModal;

async function handleUnarchiveResponse(
  unarchiveTracking: {
    run: () => Future<
      | "tracking.unarchive.tracking_is_not_archived"
      | "tracking.unarchive.failed_to_unarchive"
      | "tracking.unarchive.habilitation_is_deleted"
      | "tracking.unarchive.snapshot_failed",
      { success: true }
    >;
  },
  addToast,
  t,
  refetch
) {
  await Future.unwrap(
    unarchiveTracking.run(),
    handleError(addToast, t),
    handleSuccess(addToast, t, refetch)
  );
}

function handleSuccess(
  addToast: any,
  t: any,
  refetch: () => void
): (val: { success: true }) => void {
  return () => {
    addToast(
      t("tracking.unarchive.success", {
        defaultValue: "Ligne de suivi désarchivée avec succès",
      }),
      {
        appearance: "success",
      }
    );
    refetch();
  };
}

function handleError(
  addToast: any,
  t: any
): (
  val:
    | "tracking.unarchive.tracking_is_not_archived"
    | "tracking.unarchive.failed_to_unarchive"
    | "tracking.unarchive.snapshot_failed"
) => void {
  return (error) => {
    addToast(
      formatError(t, error, {
        defaultValue: "Une erreur est survenue lors du désarchivage de la ligne de suivi.",
      }),
      {
        appearance: "error",
        autoDismiss: true,
      }
    );
  };
}
