import { GridColDef, GridValueGetterParams, GridColumnHeaderParams } from "@mui/x-data-grid-pro";

import { Flex, Text } from "@skillup/design-system";
import { DSTooltip, MaterialIcons } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";

import { SkillRow } from "./parseSkillIntoRow";
import { DatatableSkillFields } from "./getSkillsFields";
import {
  EllipsisText,
  PercentageLine,
  FullWidthTooltip,
  LevelDifferenceContainer,
} from "../Overview.styled";

const renderFlexCell = (value: string | number) => (
  <Flex width="100%" alignItems="center" justifyContent="center">
    {typeof value === "string" ? (
      <Flex width="100%" justifyContent="flex-start">
        <EllipsisText>{value}</EllipsisText>
      </Flex>
    ) : (
      <Flex width="100%" justifyContent="flex-end">
        {value}
      </Flex>
    )}
  </Flex>
);

const renderAverageEmployeesExpectedLevelDifferenceCell = (
  value: { values: string; percentage: number },
  t: TranslationType
) =>
  value ? (
    <FullWidthTooltip title={value.values}>
      <Flex width="100%" alignItems="center">
        <PercentageLine variant="determinate" sx={{ width: "100%" }} value={value.percentage} />
        <Flex marginLeft="xs">{value.percentage} %</Flex>
      </Flex>
    </FullWidthTooltip>
  ) : (
    <Text espaceFont="captionRegular">
      {t("skills.dashboard.noExpectedLevel", {
        defaultValue: "Pas de niveau attendu",
      })}
    </Text>
  );

const renderAverageExpectedLevelDifferenceCell = (value: number, t: TranslationType) => {
  return (
    value !== undefined && (
      <LevelDifferenceContainer isNegative={value < 0}>{value}</LevelDifferenceContainer>
    )
  );
};

const renderAverageEvaluationScoreCell = (value: number) => (
  <Flex width="100%" justifyContent="flex-end">
    {value}
  </Flex>
);

const renderEmployeesEvaluatedHeader = (_, t: TranslationType) => (
  <Flex width="100%">
    <DSTooltip
      title={t("skill.field.employeesEvaluated", {
        defaultValue: "Nombre de collaborateurs évalués",
      })}
    >
      <MaterialIcons.Groups size="1rem" />
    </DSTooltip>
  </Flex>
);

const renderAverageEmployeesExpectedLevelDifferenceHeader = (
  headerName: string,
  t: TranslationType
) => (
  <DSTooltip
    title={t("skill.field.averageEmployeesExpectedLevelDifference", {
      defaultValue:
        "Pourcentage de collaborateurs dont le niveau évalué est supérieur ou égal au niveau attendu à leur poste",
    })}
  >
    <Flex alignItems="center">
      <Text marginRight="xs" espaceFont="captionBold">
        {headerName}
      </Text>
      <MaterialIcons.HelpOutlineIcon fontSize="small" />
    </Flex>
  </DSTooltip>
);

const renderAverageExpectedLevelDifferenceHeader = (headerName: string, t: TranslationType) => (
  <DSTooltip
    title={t("skill.field.averageExpectedLevelDifference", {
      defaultValue: "Écart moyen entre le niveau évalué et le niveau attendu",
    })}
  >
    <Flex alignItems="center">
      <Text marginRight="xs" espaceFont="captionBold">
        {headerName}
      </Text>
      <MaterialIcons.HelpOutlineIcon fontSize="small" />
    </Flex>
  </DSTooltip>
);

const cellRenderers = {
  averageEmployeesExpectedLevelDifference: renderAverageEmployeesExpectedLevelDifferenceCell,
  averageEvaluationScore: renderAverageEvaluationScoreCell,
  averageExpectedLevelDifference: renderAverageExpectedLevelDifferenceCell,
};

const headerRenderers = {
  averageEmployeesExpectedLevelDifference: renderAverageEmployeesExpectedLevelDifferenceHeader,
  averageExpectedLevelDifference: renderAverageExpectedLevelDifferenceHeader,
  employeesEvaluated: renderEmployeesEvaluatedHeader,
};

const minWidths = {
  averageEmployeesExpectedLevelDifference: 260,
  averageExpectedLevelDifference: 210,
  employeesEvaluated: 25,
};

const sortComparator = (fieldKey: string) => (a, b) => {
  const stringColumn = ["category", "name"];
  const getValue = (value) => {
    if (stringColumn.includes(fieldKey)) return value;
    return parseFloat(value);
  };

  if (fieldKey === "averageEmployeesExpectedLevelDifference") {
    return (a?.percentage ?? 0) - (b?.percentage ?? 0);
  }

  const valueA = getValue(a);
  const valueB = getValue(b);

  return stringColumn.includes(fieldKey) ? valueA.localeCompare(valueB) : valueA - valueB;
};

const isFilterable = (fieldKey: string) => {
  return fieldKey === "category" || fieldKey === "name";
};

const generateColumns = ({
  fields,
  t,
}: {
  t: TranslationType;
  fields: typeof DatatableSkillFields;
}): GridColDef[] => {
  return Object.values(fields).map((fieldData) => {
    const minWidth = minWidths[fieldData.key] ?? 190;

    return {
      field: fieldData.key,
      filterable: isFilterable(fieldData.key),
      headerAlign: fieldData.key === "employeesEvaluated" ? "center" : "left",
      headerName: t(fieldData.traductionKey, {
        defaultValue: fieldData.traductionDefaultValue,
      }),
      minWidth,
      renderCell: ({ row }: GridValueGetterParams<SkillRow>) => {
        const renderCell = cellRenderers[fieldData.key] || renderFlexCell;
        return renderCell(row[fieldData.key], t);
      },
      renderHeader: (params: GridColumnHeaderParams) => {
        const renderHeader = headerRenderers[fieldData.key];
        return renderHeader ? renderHeader(params.colDef.headerName, t) : undefined;
      },
      sortable: true,
      sortComparator: sortComparator(fieldData.key),
    };
  });
};

export default generateColumns;
