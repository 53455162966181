import UploadSkillEntityModal from "../../../../../components/Modals/UploadSkillEntityModal";

interface UploadSkillsModalProps {
  open: boolean;
  close: () => void;
}

const UploadSkillsModal = ({ close, open }: UploadSkillsModalProps) => {
  return (
    <UploadSkillEntityModal
      open={open}
      close={close}
      mutationEntity="skills"
      entityName="compétences"
      mutationRoute="importSkills"
    />
  );
};

export default UploadSkillsModal;
