import { PayloadAction } from "@reduxjs/toolkit";

import { FromIndexes } from "../addBlock";
import { ActionHelper } from "../utils";
import { BuilderStructure, Child } from "../../../types";

export type AddPageAction = {
  uuid: string;
  from: FromIndexes;
  blockType: "page";
};

export const addPage = (
  state: BuilderStructure,
  action: PayloadAction<AddPageAction>
): BuilderStructure => {
  const fromSectionIndex = action.payload.from.sectionIndex;
  if (
    ActionHelper.comesFromChild(action) &&
    state.sections[fromSectionIndex] &&
    state.sections[fromSectionIndex].pages[action.payload.from.pageIndex]
  ) {
    // delete children after fromPosition and put them in const
    const children = state.sections[fromSectionIndex].pages[
      action.payload.from.pageIndex
    ].children.splice(action.payload.from.childIndex + 1);

    // add new page at from.pageIndex + 1 with children
    state.sections[fromSectionIndex].pages.splice(
      action.payload.from.pageIndex + 1,
      0,
      generatePage({ uuid: action.payload.uuid, children })
    );
    // Now that you add a page, you can't hide the page in builder anymore
    state.sections[fromSectionIndex].pages.forEach((page) => {
      if (page.hideInBuilder) {
        page.hideInBuilder = undefined;
      }
    });
    return state;
  }

  if (ActionHelper.comesFromPage(action) && state.sections[fromSectionIndex]) {
    // get children of fromPage
    const children =
      state.sections[fromSectionIndex].pages[action.payload.from.pageIndex].children.splice(0);

    // add new page at from.pageIndex + 1 with children (the fromPage become empty)
    // because you add page from a page, we consider fromPage cannot be hideInBuilder (so we don't take care of that)
    state.sections[fromSectionIndex].pages.splice(
      action.payload.from.pageIndex + 1,
      0,
      generatePage({ uuid: action.payload.uuid, children })
    );

    return state;
  }

  if (ActionHelper.comesFromSection(action)) {
    const section = state.sections[fromSectionIndex];
    const pages = section.pages;
    // if the section has only one page hideInBuilder we just remove the hideInBuilder flag (like a replacement)
    if (pages.length === 1 && pages[0].hideInBuilder) {
      state.sections[fromSectionIndex].pages[0].uuid = action.payload.uuid;
    } else {
      // if it has more than one page, we add a new page at the beginning
      state.sections[fromSectionIndex].pages.splice(
        0,
        0,
        generatePage({ uuid: action.payload.uuid, children: [] })
      );
    }
    // to be sure, we remove hideInBuilder flag on all pages, because user add a page
    pages.forEach((page) => {
      if (page.hideInBuilder) {
        page.hideInBuilder = undefined;
      }
    });
    return state;
  }

  throw new Error();
};

function generatePage({ uuid, children }: { uuid: string; children: Child[] }) {
  return {
    uuid,
    title: "Nouvelle sous-section",
    children,
  };
}
