import { useMemo, useCallback } from "react";

import Fuse from "fuse.js";
import { isEmpty } from "lodash";

import { useQuery } from "@tanstack/react-query";

import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { getCampaigns } from "./getCampaigns";

export type BasicCampaign = CampaignsRoutes.GetAll["response"][number];

type Options = {
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
};

const defaultOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export function usePeopleReviewCampaigns(options: Options = defaultOptions) {
  const query = useQuery(["people-reviews-campaigns"], getCampaigns, {
    ...defaultOptions,
    ...options,
  });

  const campaigns = useMemo(() => query.data ?? [], [query.data]);

  const search = useCallback(
    async (search: string) => {
      if (isEmpty(search)) return campaigns;
      const fuse = new Fuse(campaigns, {
        includeScore: true,
        keys: ["title"],
        shouldSort: true,
        threshold: 0.333,
      });
      return fuse.search(search).map((result) => result.item);
    },
    [campaigns]
  );

  return {
    campaigns,
    isLoading: query.isLoading,
    mutations: {
      search,
    },
  };
}
