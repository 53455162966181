import { Flex, Text } from "@skillup/design-system";

type EmptyLayoutProps = {
  fullName: string;
};

export const EmptyLayout = ({ fullName }: EmptyLayoutProps) => {
  return (
    <Flex gap="l" paddingTop="l" justifyContent="center" alignItems="center" flexDirection="column">
      <Flex>
        <svg
          width="426"
          height="276"
          viewBox="0 0 426 276"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_4915_51699"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="426"
            height="276"
          >
            <rect width="426" height="276" fill="url(#paint0_linear_4915_51699)" />
          </mask>
          <g mask="url(#mask0_4915_51699)">
            <g filter="url(#filter0_d_4915_51699)">
              <rect x="-0.26828" y="96.3254" width="205" height="136" rx="16" fill="white" />
              <path
                d="M47.7317 117.659C33.0117 117.659 21.0651 129.605 21.0651 144.325C21.0651 159.045 33.0117 170.992 47.7317 170.992C62.4517 170.992 74.3984 159.045 74.3984 144.325C74.3984 129.605 62.4517 117.659 47.7317 117.659ZM47.7317 125.659C52.1584 125.659 55.7317 129.232 55.7317 133.659C55.7317 138.085 52.1584 141.659 47.7317 141.659C43.3051 141.659 39.7317 138.085 39.7317 133.659C39.7317 129.232 43.3051 125.659 47.7317 125.659ZM47.7317 163.525C41.0651 163.525 35.1717 160.112 31.7317 154.939C31.8117 149.632 42.3984 146.725 47.7317 146.725C53.0384 146.725 63.6517 149.632 63.7317 154.939C60.2917 160.112 54.3984 163.525 47.7317 163.525Z"
                fill="#E5E8EB"
              />
              <rect x="92.7317" y="124.325" width="96" height="8" rx="4" fill="#C9CED4" />
              <rect x="92.7317" y="140.325" width="96" height="8" rx="4" fill="#C9CED4" />
              <rect x="92.7317" y="156.325" width="96" height="8" rx="4" fill="#C9CED4" />
              <path
                d="M171.398 208.326H174.065V205.659H171.398V208.326ZM172.732 186.992C165.372 186.992 159.398 192.966 159.398 200.326C159.398 207.686 165.372 213.659 172.732 213.659C180.092 213.659 186.065 207.686 186.065 200.326C186.065 192.966 180.092 186.992 172.732 186.992ZM172.732 210.992C166.852 210.992 162.065 206.206 162.065 200.326C162.065 194.446 166.852 189.659 172.732 189.659C178.612 189.659 183.398 194.446 183.398 200.326C183.398 206.206 178.612 210.992 172.732 210.992ZM172.732 192.326C169.785 192.326 167.398 194.712 167.398 197.659H170.065C170.065 196.192 171.265 194.992 172.732 194.992C174.198 194.992 175.398 196.192 175.398 197.659C175.398 200.326 171.398 199.992 171.398 204.326H174.065C174.065 201.326 178.065 200.992 178.065 197.659C178.065 194.712 175.678 192.326 172.732 192.326Z"
                fill="#69798C"
              />
            </g>
            <g filter="url(#filter1_d_4915_51699)">
              <g clip-path="url(#clip0_4915_51699)">
                <rect
                  x="134.195"
                  y="37.5176"
                  width="205"
                  height="136"
                  rx="16"
                  transform="rotate(-5 134.195 37.5176)"
                  fill="white"
                />
                <path
                  d="M183.872 54.5862C169.208 55.8692 158.348 68.8116 159.631 83.4756C160.914 98.1396 173.856 109 188.52 107.717C203.184 106.434 214.044 93.4913 212.761 78.8273C211.478 64.1633 198.536 53.3033 183.872 54.5862ZM184.569 62.5558C188.979 62.17 192.85 65.4183 193.236 69.8281C193.622 74.2379 190.373 78.1091 185.964 78.4949C181.554 78.8807 177.683 75.6324 177.297 71.2226C176.911 66.8128 180.159 62.9416 184.569 62.5558ZM187.869 100.278C181.228 100.859 175.06 97.9727 171.182 93.1189C170.799 87.8254 181.092 84.0071 186.405 83.5423C191.692 83.0798 202.518 85.0504 203.06 90.3299C200.084 95.7834 194.511 99.6973 187.869 100.278Z"
                  fill="#E5E8EB"
                />
                <rect
                  x="229.282"
                  y="57.3057"
                  width="96"
                  height="8"
                  rx="4"
                  transform="rotate(-5 229.282 57.3057)"
                  fill="#C9CED4"
                />
                <rect
                  x="230.676"
                  y="73.2449"
                  width="96"
                  height="8"
                  rx="4"
                  transform="rotate(-5 230.676 73.2449)"
                  fill="#C9CED4"
                />
                <rect
                  x="232.071"
                  y="89.1838"
                  width="96"
                  height="8"
                  rx="4"
                  transform="rotate(-5 232.071 89.1838)"
                  fill="#C9CED4"
                />
                <path
                  d="M314.97 134.13L317.627 133.897L317.394 131.241L314.738 131.473L314.97 134.13ZM314.439 112.761C307.107 113.403 301.677 119.874 302.318 127.206C302.96 134.538 309.431 139.968 316.763 139.326C324.095 138.685 329.525 132.214 328.884 124.882C328.242 117.55 321.771 112.12 314.439 112.761ZM316.531 136.67C310.673 137.182 305.487 132.831 304.975 126.974C304.463 121.116 308.814 115.93 314.671 115.418C320.529 114.905 325.715 119.257 326.227 125.114C326.74 130.972 322.388 136.157 316.531 136.67ZM314.904 118.074C311.968 118.331 309.799 120.917 310.056 123.852L312.712 123.62C312.584 122.159 313.675 120.859 315.136 120.731C316.597 120.603 317.897 121.694 318.025 123.155C318.258 125.811 314.244 125.828 314.621 130.145L317.278 129.912C317.016 126.924 320.972 126.243 320.682 122.923C320.425 119.987 317.839 117.817 314.904 118.074Z"
                  fill="#69798C"
                />
              </g>
            </g>
            <g filter="url(#filter2_d_4915_51699)">
              <g clip-path="url(#clip1_4915_51699)">
                <rect
                  x="222.048"
                  y="127.651"
                  width="205"
                  height="136"
                  rx="16"
                  transform="rotate(5 222.048 127.651)"
                  fill="white"
                />
                <path
                  d="M268.006 153.086C253.342 151.803 240.4 162.663 239.117 177.327C237.834 191.991 248.694 204.934 263.358 206.217C278.022 207.499 290.964 196.639 292.247 181.975C293.53 167.311 282.67 154.369 268.006 153.086ZM267.309 161.056C271.719 161.441 274.967 165.313 274.581 169.722C274.196 174.132 270.324 177.381 265.915 176.995C261.505 176.609 258.256 172.738 258.642 168.328C259.028 163.918 262.899 160.67 267.309 161.056ZM264.009 198.778C257.368 198.197 251.794 194.283 248.818 188.83C249.36 183.55 260.16 181.577 265.473 182.042C270.76 182.505 281.079 186.325 280.696 191.619C276.819 196.473 270.65 199.359 264.009 198.778Z"
                  fill="#E5E8EB"
                />
                <rect
                  x="312.254"
                  y="163.649"
                  width="96"
                  height="8"
                  rx="4"
                  transform="rotate(5 312.254 163.649)"
                  fill="#C9CED4"
                />
                <rect
                  x="310.86"
                  y="179.589"
                  width="96"
                  height="8"
                  rx="4"
                  transform="rotate(5 310.86 179.589)"
                  fill="#C9CED4"
                />
                <rect
                  x="309.465"
                  y="195.528"
                  width="96"
                  height="8"
                  rx="4"
                  transform="rotate(5 309.465 195.528)"
                  fill="#C9CED4"
                />
                <path
                  d="M383.3 254.186L385.957 254.419L386.189 251.762L383.533 251.53L383.3 254.186ZM386.488 233.05C379.156 232.409 372.685 237.839 372.043 245.171C371.402 252.503 376.832 258.974 384.164 259.615C391.496 260.257 397.967 254.827 398.608 247.495C399.25 240.163 393.82 233.692 386.488 233.05ZM384.396 256.959C378.539 256.446 374.187 251.261 374.7 245.403C375.212 239.546 380.398 235.194 386.256 235.707C392.113 236.219 396.464 241.405 395.952 247.263C395.439 253.12 390.254 257.471 384.396 256.959ZM386.023 238.363C383.088 238.106 380.502 240.276 380.245 243.212L382.902 243.444C383.03 241.983 384.33 240.892 385.791 241.02C387.252 241.148 388.343 242.448 388.215 243.909C387.982 246.565 384.027 245.885 383.649 250.201L386.305 250.434C386.567 247.445 390.581 247.462 390.871 244.141C391.128 241.206 388.959 238.62 386.023 238.363Z"
                  fill="#69798C"
                />
              </g>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4915_51699"
              x="-16.2683"
              y="84.3254"
              width="237"
              height="168"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="8" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4915_51699"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4915_51699"
                result="shape"
              />
            </filter>
            <filter
              id="filter1_d_4915_51699"
              x="118.195"
              y="7.65063"
              width="248.073"
              height="185.349"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="8" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4915_51699"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4915_51699"
                result="shape"
              />
            </filter>
            <filter
              id="filter2_d_4915_51699"
              x="194.195"
              y="115.651"
              width="248.073"
              height="185.349"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="8" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.4128 0 0 0 0 0.47328 0 0 0 0 0.5472 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4915_51699"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4915_51699"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_4915_51699"
              x1="213"
              y1="0"
              x2="213"
              y2="276"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#D9D9D9" />
              <stop offset="1" stop-color="#D9D9D9" stop-opacity="0" />
            </linearGradient>
            <clipPath id="clip0_4915_51699">
              <rect
                x="134.195"
                y="37.5176"
                width="205"
                height="136"
                rx="16"
                transform="rotate(-5 134.195 37.5176)"
                fill="white"
              />
            </clipPath>
            <clipPath id="clip1_4915_51699">
              <rect
                x="222.048"
                y="127.651"
                width="205"
                height="136"
                rx="16"
                transform="rotate(5 222.048 127.651)"
                fill="white"
              />
            </clipPath>
          </defs>
        </svg>
      </Flex>
      <Text espaceFont="body1Italic" color="plainText-onLight-placeholder">
        Aucune donnée d'historique n'est disponible pour {fullName}.
      </Text>
    </Flex>
  );
};
