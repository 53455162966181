import { Fragment } from "react";
import { DSButton, DSCard, MaterialIcons, Switch } from "@skillup/ui";

import styles from "./Steps.module.scss";
import { CampaignMailingSettings } from "@skillup/espace-rh-bridge";

interface IProps {
  readonly mailingSettings: CampaignMailingSettings;
  readonly updateOne: (key: string, enabled: boolean) => void;
  readonly updateAllNotif: (enabled: boolean) => void;
  readonly updateAllRemind: (enabled: boolean) => void;
  readonly warning: boolean;
}

const notificationsMapping = {
  notificationStart: "Lancement de campagne",
  notificationCreated: "Création d'un entretien",
  notificationManagerChange: "Changement de manager",
  notificationShared: "Partage de la préparation",
  notificationSigned: "Entretien prêt à être signé",
  notificationReopen: "Réouverture d’un entretien",
};

const remindersMapping = {
  reminderMinus14: "14 jours avant la date de fin",
  reminderMinus7: "7 jours avant la date de fin",
  reminderMinus1: "La veille de la date de fin",
  reminderPlus1: "Le lendemain de la date de fin",
};

const MailingSettings = ({
  mailingSettings,
  updateOne,
  updateAllNotif,
  updateAllRemind,
  warning,
}: IProps) => {
  const everythingNotifDisabled = Object.keys(mailingSettings)
    .filter((s) => notificationsMapping[s])
    .every((k) => mailingSettings[k] === false);
  const everythingRemindDisabled = Object.keys(mailingSettings)
    .filter((s) => remindersMapping[s])
    .every((k) => mailingSettings[k] === false);

  // TODO: Once new design system components are implemented, make sure accessibility works properly on all controls

  return (
    <div className={styles.CardWrapper}>
      <DSCard className={styles.MailingSettingsCard} showTitle={false}>
        <div className={styles.MailingSettingsWrapper}>
          <div className={styles.notificationsWrapper}>
            <label>Notifications par mail par événement :</label>
            {Object.keys(notificationsMapping).map((key) => {
              const label = notificationsMapping[key];
              return (
                <Fragment key={`toggle-${key}`}>
                  <div className={styles.notificationsToggleWrapper}>
                    <Switch
                      active={mailingSettings[key]}
                      onToggle={(checked) => updateOne(key, checked)}
                      className={styles.notificationsToggle}
                      label={label}
                    />
                  </div>
                </Fragment>
              );
            })}
            <DSButton
              label={everythingNotifDisabled ? "Tout activer" : "Tout désactiver"}
              className={styles.toggleAllButton}
              onClick={() => {
                updateAllNotif(everythingNotifDisabled);
              }}
              emphasis="Low"
              buttonSize="S"
            />
          </div>

          <div className={styles.remindersWrapper}>
            <label>Relances en cas de non-signature : </label>
            {Object.keys(remindersMapping).map((key) => {
              const label = remindersMapping[key];
              return (
                <div key={`toggle-${key}`} className={styles.remindersToggleWrapper}>
                  <Switch
                    active={mailingSettings[key]}
                    onToggle={(checked) => updateOne(key, checked)}
                    className={styles.remindersToggle}
                    label={label}
                  />
                </div>
              );
            })}
            <DSButton
              label={everythingRemindDisabled ? "Tout activer" : "Tout désactiver"}
              className={styles.toggleAllButton}
              onClick={() => {
                updateAllRemind(everythingRemindDisabled);
              }}
              emphasis="Low"
              buttonSize="S"
            />
          </div>
        </div>

        {warning && (
          <div className={styles.warningContainer}>
            <>
              <MaterialIcons.Warning color="#FE9D58" />
            </>
            <div className={styles.warningText}>
              Les modifications apportées ne sont pas rétroactives : il est possible que certaines
              notifications aient déjà été envoyées.
            </div>
          </div>
        )}
      </DSCard>
    </div>
  );
};

export default MailingSettings;
