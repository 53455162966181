import { plural } from "utils/locale";
import type { Child, ItemError } from "../types";

// [CSB] we do not allow to edit target scales for now
export const getChildErrors = (child: Child/*, choicesForOrdinalTargets?: string[]*/) => {
  const errors: ItemError[] = [];
  switch (child.kind) {
    case "title":
      if (!child.title)
        errors.push({
          structure: "Vous n'avez pas défini de libellé",
          header: "Titre sans libellé",
        });
      break;
    case "html":
      if (!child.variant)
        errors.push({
          structure: "Vous n'avez pas renseigné de texte",
          header: "Texte d'information sans style",
        });
      if (!child.content)
        errors.push({
          structure: "Vous n'avez pas renseigné de contenu",
          header: "Texte d'information sans contenu",
        });
      break;
    case "userSpecificData":
      if (!child.userSpecificDataKey)
        errors.push({
          structure: "Vous n'avez pas renseigné de clé",
          header: "Donnée utilisateur sans clé",
        });
      break;
    case "interviewsHistory":
      if (!child.maxAge)
        errors.push({
          structure: "Vous n'avez pas défini de durée d'historique",
          header: "Historique d'entretien sans durée définie",
        });
      if (!child.ageUnit)
        errors.push({
          structure: "Vous n'avez pas défini d'unité de durée",
          header: "Historique d'entretien sans unité de durée",
        });
      break;
    case "trainingsHistory":
      if (!child.maxAge)
        errors.push({
          structure: "Vous n'avez pas défini de durée d'historique",
          header: "Historique de formation sans durée définie",
        });
      if (!child.ageUnit)
        errors.push({
          structure: "Vous n'avez pas défini d'unité de durée",
          header: "Historique de formation sans unité de durée",
        });
      break;
    case "question":
      if (child.required && child.disallow) {
        if (child.disallow.employee?.reply && child.disallow.manager?.reply) {
          errors.push({
            structure: "Vous n'avez pas défini de répondant",
            header: "Question avec réponse requise sans répondant selectionné",
          });
        }
      }
      switch (child.type) {
        case "text":
          if (!child.label)
            errors.push({
              structure: "Vous n'avez pas défini de libellé",
              header: "Question ouverte sans libellé",
            });
          break;
        case "radio":
        case "checkbox":
          if (!child.label)
            errors.push({
              structure: "Vous n'avez pas défini de libellé",
              header: "Question à choix multiple sans libellé",
            });
          if (child.choices.length === 0)
            errors.push({
              structure: "Vous n'avez pas défini de choix de réponse",
              header: "Question à choix multiple sans choix de réponse",
            });
          break;
      }
      break;
    case "targets":
      if (child.type === "next" || child.isCreateOptionsVisible) {
        // [CSB] we do not allow to edit target scale for now
        // if (!(child.restrictScaleTo === "numeric")) {
        //   if (!choicesForOrdinalTargets || choicesForOrdinalTargets.length === 0) {
        //     errors.push({
        //       structure:
        //         "Vous n'avez pas défini d'echelle d'évaluation pour les objectifs qualitatifs",
        //       header: "Aucune échelle d'évaluation définie pour les objectifs qualitatifs",
        //     });
        //   }
        // }
        const disallow = child.disallow;
        if (disallow && disallow.employee.creation && disallow.manager.creation) {
          errors.push({
            structure: "Vous n'avez pas accordé de droits de création d'objectifs",
            header: "Création d'objectifs sans droit de création accordé aux participants",
          });
        }
      }
      // [CSB] we do not allow to change the review type for now
      // if (child.type === "current" && child.reviewType === undefined) {
      //   errors.push({
      //     structure: "Vous n'avez pas défini le type de revue",
      //     header: "Revue des objectifs sans type de revue défini",
      //   });
      // }
      break;
    case "table":
      checkTableChildErrors(child, errors);
      break;
    case "upload":
      if (child.disallow) {
        if (child.disallow.employee?.upload && child.disallow.manager?.upload) {
          errors.push({
            structure: "Vous n'avez pas selectionné de participant pour partager un fichier",
            header: "Partage de fichiers sans participant selectionné",
          });
        }
      }
      break;
    case "personnalisableGuidelines":
      if (!child.description && !child.richDescription) {
        errors.push({
          structure: "Vous n'avez pas défini de texte d'introduction",
          header: "Introduction sans texte défini",
        });
      }
      if (!child.employee.title) {
        errors.push({
          structure: "Vous n'avez pas défini de libellé collaborateur",
          header: "Introduction sans libellé collaborateur défini",
        });
      }
      if (!child.manager.title) {
        errors.push({
          structure: "Vous n'avez pas défini de libellé responsable",
          header: "Introduction sans libellé responsable défini",
        });
      }
      break;
    default:
      break;
  }
  return errors.length > 0 ? errors : undefined;
};

function checkTableChildErrors(child: Child, errors: ItemError[]) {
  if (child.kind !== "table") return;
  if (!child.rowsCount || child.rowsCount === 0)
    errors.push({
      structure: "Vous n'avez pas renseigné le nombre de lignes",
      header: "Tableau sans lignes",
    });

  const tableContentErrors = { columnType: 0, emptyChoice: 0, fixedValue: 0 };
  child.columns.forEach((column) => {
    if (!column.type) tableContentErrors.columnType++;
    if (column.type === "dropdown") {
      if (column?.choices?.length === 0) tableContentErrors.emptyChoice++;
    } else if (column.type === "fixed") {
      const emptyLines = child.rowsCount - column.values?.length;
      if (emptyLines > 0) tableContentErrors.fixedValue++;
    }
  });
  setColumnsErrors(tableContentErrors, errors);
}

function setColumnsErrors(tableContentErrors, errors) {
  if (tableContentErrors.columnType > 0)
    errors.push({
      structure: `Vous n'avez pas défini de type pour ${tableContentErrors.columnType} ${plural(
        tableContentErrors.columnType,
        "colonne%s"
      )}`,
      header: `${tableContentErrors.columnType} ${plural(
        tableContentErrors.columnType,
        "colonne%s"
      )} de tableau sans type`,
    });
  if (tableContentErrors.emptyChoice > 0)
    errors.push({
      structure: `Vous n'avez pas défini de choix dans ${tableContentErrors.emptyChoice} ${plural(
        tableContentErrors.emptyChoice,
        "colonne%s"
      )}`,
      header: `${tableContentErrors.emptyChoice} ${plural(
        tableContentErrors.emptyChoice,
        "colonne%s"
      )} du tableau sans choix de réponse`,
    });
  if (tableContentErrors.fixedValue > 0)
    errors.push({
      structure: `Vous n'avez pas défini de valeur(s) fixe(s) pour ${
        tableContentErrors.fixedValue
      } ${plural(tableContentErrors.fixedValue, "colonne%s")}`,
      header: `${tableContentErrors.fixedValue} ${plural(
        tableContentErrors.fixedValue,
        "colonne%s"
      )} du tableau sans valeur(s) fixe(s) définie(s)`,
    });
}
