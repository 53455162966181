import { AddToast } from "react-toast-notifications";
import { ReactElement } from "react";

import { DSRating, DSAvatarGroup } from "@skillup/ui";
import {
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";

import { HotReview, ReviewUser } from "@skillup/training-bridge";
import { Flex } from "@skillup/design-system";

import { getUserInitials } from "utils/User";
import { TranslationType } from "hooks/useTranslation";

import ReviewStatus from "../../../components/ReviewStatus";
import ActionsCell from "../../../components/ActionsCell";
import { ReviewRow } from "../../../utils/types";

import styles from "../../HotReviewListView.module.scss";

const renderFlexCell = (value: string | ReactElement) => {
  return (
    <Flex className={styles.cell} alignItems="center" justifyContent="center">
      {typeof value === "string" ? <p className={styles.textCell}>{value}</p> : value}
    </Flex>
  );
};

const generateColumns = ({
  t,
  addToast,
  refetch,
  selectedRows,
}: {
  t: TranslationType;
  addToast: AddToast;
  refetch: () => void;
  selectedRows: GridRowSelectionModel;
}): GridColDef<any>[] => {
  const formattedTrainers = (trainers: ReviewUser[]) => {
    return trainers.map((t) => ({
      uuid: t.uuid,
      fullName: t.fullName,
      initials: getUserInitials(t),
    }));
  };

  return [
    {
      field: "reviewStatus",
      headerName: t("reviews.list.label.reviewStatus"),
      width: 180,
      sortable: false,
      renderCell: ({ row }: GridValueGetterParams) => {
        return renderFlexCell(<ReviewStatus status={row.data.status} />);
      },
    },
    {
      field: "traineeName",
      headerName: t("reviews.list.label.traineeName"),
      width: 150,
      renderCell: ({ row }: GridValueGetterParams) => {
        return renderFlexCell(row.data.trainee ?? "-");
      },
    },
    {
      field: "trainingName",
      headerName: t("reviews.list.label.trainingName"),
      width: 250,
      renderCell: ({ row }: GridValueGetterParams) => {
        return renderFlexCell(row.data.trainingName);
      },
    },
    {
      field: "trainingOrganization",
      headerName: t("reviews.list.label.trainingOrganization"),
      width: 180,
      renderCell: ({ row }: GridValueGetterParams) => {
        return renderFlexCell(row.data.trainingOrganization ?? "-");
      },
    },
    {
      field: "score",
      headerName: t("reviews.list.label.score", {
        defaultValue: "Note",
      }),
      width: 200,
      filterable: false,
      sortable: false,
      renderCell: ({ row }: GridValueGetterParams) => {
        if (row.data.score) {
          return renderFlexCell(
            <DSRating value={row.data.score} max={row.data.max} grade={true} />
          );
        }
        return renderFlexCell("-");
      },
    },
    {
      field: "sessionStartDate",
      headerName: t("reviews.list.label.sessionStartDate"),
      width: 150,
      renderCell: ({ row }: GridValueGetterParams) => {
        return renderFlexCell(row.data.startDate);
      },
    },
    {
      field: "sessionReference",
      headerName: t("reviews.list.label.sessionReference"),
      width: 150,
      renderCell: ({ row }: GridValueGetterParams) => {
        return renderFlexCell(row.data.sessionReference ?? "-");
      },
    },
    {
      field: "trainerName",
      headerName: t("reviews.list.label.trainerName"),
      width: 150,
      renderCell: ({ row }: GridValueGetterParams) => {
        if (row.data.trainers.length !== 0) {
          return renderFlexCell(
            <DSAvatarGroup users={formattedTrainers(row.data.trainers)} type="Initials" size="S" />
          );
        }
        return renderFlexCell("-");
      },
    },
    {
      field: "comment",
      headerName: t("reviews.list.label.comment", {
        defaultValue: "Commentaire",
      }),
      filterable: false,
      sortable: false,
      width: 300,
      renderCell: ({ row }: GridValueGetterParams) => {
        return renderFlexCell(row.data.comment);
      },
    },
    {
      field: "actions",
      width: 50,
      filterable: false,
      sortable: false,
      type: "actions",
      getActions: (params: GridRowParams<ReviewRow<HotReview>>) => [
        <ActionsCell {...params} refetch={refetch} selectedRows={selectedRows} />,
      ],
    },
  ];
};

export default generateColumns;
