import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export function useRemoveReviews({ campaignID }: { campaignID: string }) {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    async (data: { ids: string[] }) => {
      await buildRequest<CampaignsRoutes.RemoveReview>({
        method: "POST",
        params: {
          campaignID,
        },
        path: "/campaign/{campaignID}/remove-review",
        payload: {
          reviewIDs: data.ids,
        },
        target: "PEOPLE_REVIEW",
      })();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaigns", "details", campaignID]);
        queryClient.invalidateQueries(["people-reviews", campaignID]);
      },
    }
  );

  return { removeReviews: mutate };
}
