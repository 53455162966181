import { useCallback } from "react";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import { arbitrateEmployees } from "./arbitrateEmployees";
import { getCampaignDetails } from "./getCampaignDetails";
import { exportPPRCampaign } from "./exportCampaign.query";
import { deleteCampaign as deleteC } from "./deleteCampaign.mutation";
import { CampaignSettings, editCampaignSettings } from "./editCampaignSettings";
import { startCampaign, pauseCampaign, closeCampaign, resumeCampaign } from "./updateCampaignState";

export { type CampaignDetails } from "./getCampaignDetails";

type Options = {
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
};

const defaultOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

export function useCampaignDetails(campaignID: string, options: Options = defaultOptions) {
  const { data, isError, isLoading, refetch } = useQuery(
    ["campaigns", "details", campaignID],
    async () => getCampaignDetails(campaignID),
    {
      refetchOnMount: options.refetchOnMount,
      refetchOnWindowFocus: options.refetchOnWindowFocus,
    }
  );

  const queryClient = useQueryClient();

  const startCampaignMutation = useMutation(startCampaign, {
    mutationKey: ["update-PR-template"],
  });

  const pauseCampaignMutation = useMutation(pauseCampaign, {
    mutationKey: ["update-PR-template"],
  });

  const closeCampaignMutation = useMutation(closeCampaign, {
    mutationKey: ["update-PR-template"],
  });

  const resumeCampaignMutation = useMutation(resumeCampaign, {
    mutationKey: ["update-PR-template"],
  });

  const editSettingsMutation = useMutation(editCampaignSettings, {
    mutationKey: ["settings-campaign"],
  });

  const deleteCampaignMutation = useMutation(deleteC, {
    mutationKey: ["delete-campaign"],
    onSuccess: () => {
      queryClient.invalidateQueries(["people-reviews-campaigns"]);
    },
  });

  const editSettings = useCallback(
    async (settings: CampaignSettings) => {
      const response = await editSettingsMutation.mutateAsync({
        campaignId: campaignID,
        settings,
      });
      refetch();

      return response;
    },
    [campaignID, editSettingsMutation, refetch]
  );

  const start = useCallback(async () => {
    await startCampaignMutation.mutateAsync(campaignID);
    refetch();
  }, [campaignID, refetch, startCampaignMutation]);

  const pause = useCallback(async () => {
    await pauseCampaignMutation.mutateAsync(campaignID);
    refetch();
  }, [campaignID, refetch, pauseCampaignMutation]);

  const close = useCallback(async () => {
    await closeCampaignMutation.mutateAsync(campaignID);
    refetch();
  }, [campaignID, refetch, closeCampaignMutation]);

  const resume = useCallback(async () => {
    await resumeCampaignMutation.mutateAsync(campaignID);
    refetch();
  }, [campaignID, refetch, resumeCampaignMutation]);

  const deleteCampaign = useCallback(async () => {
    await deleteCampaignMutation.mutateAsync(campaignID);
  }, [campaignID, deleteCampaignMutation]);

  const arbitrateEmployeesMutation = useMutation(
    async ({ campaignId, employeesIds }: { campaignId: string; employeesIds: string[] }) => {
      const arbitrateResult = await arbitrateEmployees({
        campaignId,
        employeesIds,
      });

      if (arbitrateResult.isRight()) {
        queryClient.invalidateQueries(["campaigns", "details", campaignID]);
        queryClient.invalidateQueries(["people-reviews", campaignID]);
      }
    }
  );

  const exportDataMutation = useMutation(async (columns: string[]) => {
    await exportPPRCampaign(campaignID, columns);
  });

  return {
    data,
    isError,
    isLoading,
    mutations: {
      close,
      delete: deleteCampaign,
      editSettings,
      employees: {
        arbitrate: arbitrateEmployeesMutation.mutateAsync,
      },
      export: exportDataMutation.mutateAsync,
      pause,
      resume,
      start,
    },
  };
}
