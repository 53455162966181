import { useCallback } from "react";
import { ContentItem } from "./types";
import { useBuilderContext } from "../BuilderContext";

export const useFindVisibleItem = (items: ContentItem[]) => {
  const { contentItemsRefs: itemsRefs } = useBuilderContext();

  const findItem = useCallback(
    (predicate, mainRect) => {
      return items.find((item) => {
        const itemRef = itemsRefs.current[item.uuid];
        if (!itemRef) {
          return false;
        }
        const itemRect = itemRef.getBoundingClientRect();
        return predicate(itemRect, mainRect);
      });
    },
    [items, itemsRefs]
  );

  const isFullyVisible = useCallback((itemRect, mainRect) => {
    return (
      (itemRect.top >= mainRect.top && itemRect.bottom <= mainRect.bottom) ||
      (itemRect.top <= mainRect.top && itemRect.bottom >= mainRect.bottom)
    );
  }, []);

  const isBottomVisible = useCallback((itemRect, mainRect) => {
    return itemRect.bottom <= mainRect.bottom && itemRect.bottom >= mainRect.top;
  }, []);

  const isTopVisible = useCallback((itemRect, mainRect) => {
    return itemRect.top <= mainRect.bottom && itemRect.top >= mainRect.top;
  }, []);

  const findItemBottomVisibleOrTopVisible = useCallback(
    (mainRect) => {
      let topFirstVisibleItem = null;
      let bottomFirstVisibleItem = null;
      items.some((item) => {
        const itemRef = itemsRefs.current[item.uuid];
        if (!itemRef) {
          return false;
        }
        const itemRect = itemRef.getBoundingClientRect();
        if (isBottomVisible(itemRect, mainRect)) {
          bottomFirstVisibleItem = item;
          return true;
        }
        if (!topFirstVisibleItem && isTopVisible(itemRect, mainRect)) {
          topFirstVisibleItem = item;
        }
        return false;
      });
      return bottomFirstVisibleItem || topFirstVisibleItem;
    },
    [items, itemsRefs, isBottomVisible, isTopVisible]
  );

  const findVisibleItem = useCallback(
    (mainRect) => {
      const firstVisibleItem = findItem(isFullyVisible, mainRect);
      if (firstVisibleItem) {
        return firstVisibleItem;
      }
      // if no item is fully visible find the first item visible at the bottom then at the top
      const visibleItem = findItemBottomVisibleOrTopVisible(mainRect);
      if (visibleItem) {
        return visibleItem;
      }
      return null;
    },
    [findItem, findItemBottomVisibleOrTopVisible, isFullyVisible]
  );
  return {
    findVisibleItem,
  };
};
