import { useEffect } from "react";
import { useSetState } from "react-use";
import { ScheduleDashboardRoutes } from "@skillup/espace-rh-bridge";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import useTypedFetch from "hooks/useTypedFetch";
import DataLines, { IDataLine } from "../Components/DashboardDataLines";
import TimeGraph from "./TimeGraph";
import AccountTrainingScheduleLoader from "../Components/SupervisorTrainingScheduleLoader";
import colors from "uiAssets/Colors";
import {
  cross as crossIcon,
  hourglass as hourglassIcon,
  edit as editIcon,
  check as checkIcon,
  numbers as numbersIcon,
} from "uiAssets/StrokeIcons";
import { school as schoolIcon } from "uiAssets/Icons";
import useAreas from "hooks/useAreas";

import styles from "./PlanDashboardStyles.module.scss";
import { formatDashBoardMonetaryFields } from "./formatNumber";

type PlanDashboardDataCall =
  ScheduleDashboardRoutes.GetDashboardDataForSchedule<"plan">["response"];

interface IProps {
  scheduleUuid?: string;
}

interface IState {
  years: Array<number>;
  currentYear: number;
  dashboardData: PlanDashboardDataCall | null;
}

const buildGlobalDataLines: (dashboardData: PlanDashboardDataCall) => Array<IDataLine> = (
  dashboardData: PlanDashboardDataCall
) => {
  const lines = [
    {
      contents: ["Plan", "Formations", "Stagiaires", "Heures", "Total"],
      key: "header",
      type: "header",
    },
    {
      contents: [
        "À inscrire",
        (dashboardData.toBook || {}).actions || 0,
        (dashboardData.toBook || {}).trainees || 0,
        (dashboardData.toBook || {}).hours || 0,
        (dashboardData.toBook || {}).cost || "",
      ],
      icon: editIcon,
      key: "toEnroll",
    },
    {
      contents: [
        "Choix de session en cours",
        (dashboardData.sessionToChoose || {}).actions || 0,
        (dashboardData.sessionToChoose || {}).trainees || 0,
        (dashboardData.sessionToChoose || {}).hours || 0,
        (dashboardData.sessionToChoose || {}).cost || "",
      ],
      icon: hourglassIcon,
      key: "sessionToChoose",
    },
    {
      contents: [
        "Inscrit",
        (dashboardData.booked || {}).actions || 0,
        (dashboardData.booked || {}).trainees || 0,
        (dashboardData.booked || {}).hours || 0,
        (dashboardData.booked || {}).cost || "",
      ],
      icon: checkIcon,
      key: "enrolled",
    },
    {
      contents: [
        "Réalisé",
        (dashboardData.done || {}).actions || 0,
        (dashboardData.done || {}).trainees || 0,
        (dashboardData.done || {}).hours || 0,
        (dashboardData.done || {}).cost || "",
      ],
      icon: schoolIcon,
      iconColor: colors.success,
      key: "done",
    },
    {
      contents: [
        "Total au plan",
        (dashboardData.totalInPlan || {}).actions || 0,
        (dashboardData.totalInPlan || {}).trainees || 0,
        (dashboardData.totalInPlan || {}).hours || 0,
        (dashboardData.totalInPlan || {}).cost || "",
      ],
      icon: numbersIcon,
      iconColor: "#666",
      key: "totalPlan",
      type: "total",
    },
    {
      contents: [
        "Annulé",
        (dashboardData.denied || {}).actions || 0,
        (dashboardData.denied || {}).trainees || 0,
        (dashboardData.denied || {}).hours || 0,
        (dashboardData.denied || {}).cost || "",
      ],
      icon: crossIcon,
      iconColor: "#ff7979",
      key: "denied",
    },
  ];

  return lines;
};

const PlanDashboardView = (props: IProps) => {
  const [state, setState] = useSetState<IState>({
    years: null,
    currentYear: null,
    dashboardData: null,
  });

  const settings = useSettings();
  const { i18n } = useTranslation();

  const { scheduleUuid } = props;
  const { activeAreas, hasAllAreasActive } = useAreas();
  const areas = hasAllAreasActive ? "" : activeAreas?.length ? activeAreas.join(",") : "";

  const { data } = useTypedFetch<ScheduleDashboardRoutes.GetDashboardDataForSchedule<"plan">>({
    method: "GET",
    path: "/schedule-dashboard/{type}/{scheduleUuid?}",
    params: {
      type: "plan",
      scheduleUuid,
    },
    query: { areas },
  });

  useEffect(() => {
    if (data) {
      let years: Array<number> = [];

      for (const month of data.months || []) {
        if (!years.includes(month.year)) years.push(month.year);
      }
      years = years.sort();

      setState({
        dashboardData: data,
        years,
        currentYear: years[0],
      });
    }
  }, [data, setState]);

  const setYear = (year) => setState({ currentYear: year });

  const { years, currentYear, dashboardData } = state;

  if (!dashboardData) {
    return <AccountTrainingScheduleLoader />;
  }
  const formatedData = formatDashBoardMonetaryFields(
    dashboardData,
    settings.settings.currency,
    i18n.language
  );

  return (
    <div className={styles.PlanDashboardView}>
      <DataLines lines={buildGlobalDataLines(formatedData)} />
      {
        //@ts-ignore
        <TimeGraph
          {...formatedData}
          title="Dépenses mensuelles"
          years={years}
          currentYear={currentYear}
          setYear={setYear}
          currency={settings.settings.currency}
          i18n={i18n}
        />
      }
    </div>
  );
};

export default PlanDashboardView;
