import { FormatDate } from "@skillup/shared-utils";
import { capitalize } from "lodash";

import styles from "../ConnectionModes.module.scss";

const LastConnection = (props) => {
  if (!props.date) return <div className={styles.error}>Jamais</div>;

  const lastDayConnected = FormatDate.DifferenceInDays({ to: props.date });

  if (lastDayConnected === 0 || lastDayConnected === -1) {
    return <div>Aujourd'hui</div>;
  }
  if (lastDayConnected === 1) return <div>Hier</div>;

  return <div>{capitalize(FormatDate.ToRelative({ to: props.date }))}</div>;
};

export default LastConnection;
