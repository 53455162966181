import { FormatDate, ListUtils, ParseDate } from "@skillup/shared-utils";
import { FilterProps } from "@skillup/ui";

import { ICompanyField, IParsedField } from "@skillup/training-bridge";
import { TranslationType } from "hooks/useTranslation";

export const Filters: ListUtils.FilterConfigurationMap = {
  trainingNameOrReference: {
    type: ListUtils.FilterType.TEXT,
  },
  trainingOrganizationName: {
    type: ListUtils.FilterType.TEXT,
  },
  "stats.futureSessionsCount": {
    type: ListUtils.FilterType.TEXT,
  },
  "stats.doneSessionsCount": {
    type: ListUtils.FilterType.TEXT,
  },
  rawCreatedAt: {
    type: ListUtils.FilterType.TEXT,
  },
};

const convertFieldToFilterConfig = (
  field: ICompanyField,
  t: TranslationType
): ListUtils.FilterConfigurationMap["any"] => {
  const label = t(`supervisor.fields.label.${field.binding}`, {
    defaultValue: field.label,
  });

  switch (field.type) {
    case "select":
      return {
        type: "multiselect",
        label,
      };
    case "date":
    case "monetary":
    case "number":
    case "text":
    default:
      return {
        type: "text",
        label,
      };
  }
};

const convertFieldToFilterProps = (field: ICompanyField, t: TranslationType): FilterProps<any> => {
  const placeholder = t(`supervisor.fields.placeholder.${field.binding}`, {
    defaultValue: field.label,
  });

  switch (field.type) {
    case "select":
      return {
        options: field.options?.map((option) => ({
          value: option.key,
          label: option.value,
        })),
        placeholder,
      };
    case "date":
    case "monetary":
    case "number":
    case "text":
    default:
      return {
        placeholder,
      };
  }
};

export const filterProps: Record<keyof typeof Filters, FilterProps<any>> = {
  trainingNameOrReference: { visibilityMode: "always" },
};

export const getFilterConfig = (fields: ICompanyField[] | undefined, t: TranslationType) => {
  const fieldFilterConfig = fields?.reduce((acc, field) => {
    return {
      ...acc,
      [`fieldValues.${field.binding}`]: convertFieldToFilterConfig(field, t),
    };
  }, {});
  const fieldFilterProps = fields?.reduce((acc, field) => {
    return {
      ...acc,
      [`fieldValues.${field.binding}`]: convertFieldToFilterProps(field, t),
    };
  }, {});

  return [
    { ...Filters, ...fieldFilterConfig },
    {
      ...filterProps,
      ...fieldFilterProps,
    },
  ];
};

export const getFieldValue = (field: IParsedField) => {
  if (!field.value) {
    return "";
  }

  switch (field.type) {
    case "date":
      return FormatDate.ToStringFormat(
        ParseDate.FromParsableJS(new Date(field.value)),
        FormatDate.getDayMonthYearFormat()
      );
    case "select":
      return field.options?.find((option) => option.key === field.value)?.value;
    case "monetary":
    case "number":
    case "text":
    default:
      return field.value;
  }
};

export const getColTypeFromField = (field: ICompanyField) => {
  switch (field.type) {
    case "monetary":
    case "number":
      return "number";
    case "date":
    case "select":
    case "text":
    default:
      return "string";
  }
};

export const getFieldComparators = (fields?: ICompanyField[]) => {
  return (
    fields.reduce((acc, field) => {
      let comparator: Function = ListUtils.InMemoryDataset.StringComparator;
      switch (field.type) {
        case "monetary":
        case "number":
          comparator = ListUtils.InMemoryDataset.NumberComparator;
          break;
        case "date":
        case "select":
        case "text":
        default:
          break;
      }

      return {
        ...acc,
        [`fieldValues.${field.binding}`]: comparator,
      };
    }, {}) ?? {}
  );
};
