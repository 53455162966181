import * as z from "zod";

export const TemplateFieldsSchema = z.object({
  templateFields: z.array(
    z.object({
      uuid: z.string().uuid().optional(),
      version: z.number(),
      value: z.string().trim().optional(),
      name: z.string(),
      index: z.number(),
    })
  ),
});
