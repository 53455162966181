import { ProjectRoutes } from "@skillup/espace-rh-bridge";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { buildRequest } from "utils/buildRequest";

export function useReferenceValidation(reference: string, omitSessionUuid?: string) {
  const [searchReference, setSearchReference] = useState<string | undefined>(reference);
  const [isSearching, setIsSearching] = useState(false);

  const query = useQuery(
    ["reference-validation", searchReference, omitSessionUuid],
    async () => {
      const response = await getReferenceValidity(searchReference, omitSessionUuid);
      setIsSearching(false);
      return response;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    // debounce the search
    setIsSearching(true);
    const timeout = setTimeout(() => {
      setSearchReference(reference);
    }, 500);

    return () => clearTimeout(timeout);
  }, [reference]);

  return {
    ...query,
    isLoading: query.isLoading || isSearching,
    data: isSearching ? { isValid: false, because: "LOADING" } : query.data,
  };
}

async function getReferenceValidity(reference: string | undefined, omitSessionUuid?: string) {
  try {
    const response = await buildRequest<ProjectRoutes.CheckReferenceValidity>({
      method: "GET",
      path: "/projects/check-validity/{reference?}",
      params: {
        reference,
      },
      query: {
        omitSessionUuid,
      },
    })();

    return response;
  } catch (err) {
    return {
      isValid: false,
      because: "UNKNOWN",
    };
  }
}
