import { useEffect } from "react";
import { useSetState } from "react-use";
import { BasicRouteType } from "types/route";
import { buildRequest } from "utils/buildRequest";

interface IState<T> {
  data?: T;
  loading: boolean;
  error?: Error;
}

export interface IFetchResult<T> extends IState<T> {
  readonly refetch: () => Promise<T>;
}

const useFetch = <Route extends BasicRouteType>(
  options: Omit<Route, "response">
): IFetchResult<Route["response"]> => {
  const [{ data, error, loading }, setState] = useSetState<IState<Route["response"]>>({
    loading: true,
  });

  let didCancel = false;

  const fetchData = (options: Omit<Route, "response">): Promise<Route["response"]> =>
    buildRequest<Route>(options)()
      .then((result) => {
        if (!didCancel) {
          setState({ data: result, loading: false });
          return result;
        }
        return undefined;
      })
      .catch((error) => {
        setState({ error, loading: false });
        throw error;
      });

  useEffect(() => {
    fetchData(options);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      didCancel = true;
    };
  }, [JSON.stringify(options)]);

  return { data, loading, error, refetch: async () => fetchData(options) };
};

export default useFetch;
