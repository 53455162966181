import type { StructureItemPosition, PageBlock, ChildBlock, Section } from "../..";
import {
  addChildToPage,
  removeChildFromPage,
  replacePageInSection,
  replaceSection,
} from "../actions";

export function moveChildToPage(
  sections: Section[],
  fromPosition: StructureItemPosition<ChildBlock>,
  toPosition: StructureItemPosition<PageBlock>
) {
  const {
    sectionIndex: fromSectionIndex,
    pageIndex: fromPageIndex,
    childIndex: fromChildIndex,
  } = fromPosition;
  const { sectionIndex: toSectionIndex, pageIndex: toPageIndex } = toPosition;

  const fromSection = sections[fromSectionIndex];
  const toSection = sections[toSectionIndex];

  const fromPage = fromSection.pages[fromPageIndex];
  const toPage = toSection.pages[toPageIndex];

  const fromChild = fromPage.children[fromChildIndex];

  const newFromPage = removeChildFromPage(fromPage, fromChildIndex);
  const newToPage = addChildToPage(toPage, 0, fromChild);

  const newFromSection = replacePageInSection(fromSection, newFromPage, fromPageIndex);
  if (fromSectionIndex === toSectionIndex) {
    const newSection = replacePageInSection(newFromSection, newToPage, toPageIndex);

    const newSections = replaceSection(sections, newSection, fromSectionIndex);
    return newSections;
  }
  const newToSection = replacePageInSection(toSection, newToPage, toPageIndex);

  const newSectionsTemp = replaceSection(sections, newFromSection, fromSectionIndex);
  const newSections = replaceSection(newSectionsTemp, newToSection, toSectionIndex);
  return newSections;
}
