import { Either } from "./either";
import { Left } from "./left";

export class Right<L, R> implements Either<L, R> {
  public readonly value: R;

  private readonly _isLeft = false;
  private readonly _isRight = true;

  constructor(value: R) {
    this.value = value;
  }

  public left(): never {
    throw new Error("Cannot call left() on Right");
  }

  public right(): R {
    return this.value;
  }

  public assertIsRight(): void {}

  public map<T>(cb: (l: R) => T): Right<never, T> {
    return new Right(cb(this.value));
  }

  public leftMap<T>(_: (l: L) => T): Right<never, R> {
    return new Right(this.value);
  }

  public flatMap<T>(cb: (l: R) => Either<L, T>): Either<L, T> {
    return cb(this.value);
  }

  public isLeft(): this is Left<L, never> {
    return this._isLeft;
  }

  public isRight(): this is Right<never, R> {
    return this._isRight;
  }

  public fold<NL, NR>(_: (r: L) => NL, rcb: (r: R) => NR): NR {
    return rcb(this.value);
  }
}
