import { SkuiSpacing } from "commons/spacing";

export type SpacingTokenValues = keyof typeof SkuiSpacing;

export const translateSpacing = (values: string | number) => {
  if (typeof values === "number") {
    return values;
  }
  return values
    .split(" ")
    .map((value) => SkuiSpacing[value] || value)
    .join(" ");
};
