import { z } from "zod";
import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { DSButton, Flex } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

import { DashboardConfigForm, PanelConfigForm } from "../../types";
import { Panel } from "./Panel";
import { useTrainingDashboardContext } from "../../context";

export type ConfigurationFormProps = {
  initialValue?: DashboardConfigForm;
  onSubmit: (values: DashboardConfigForm) => void;
};

const validationSchema = z.object({
  panels: z.array(
    z.object({
      short_name: z.string(),
      type: z.enum(["key-indicators", "detail-indicators", "summary", "monthly"]),
      cards: z.array(
        z.object({
          short_name: z.string(),
          question_name: z.string(),
          indicator: z.string().optional(),
          dimension: z.string().optional(),
        })
      ),
    })
  ),
});

export const ConfigurationForm = ({ initialValue, onSubmit }: ConfigurationFormProps) => {
  const { t } = useTranslation();

  const { summaryQuestion, monthlyQuestion } = useTrainingDashboardContext();

  return (
    <Formik<DashboardConfigForm>
      validateOnMount={false}
      enableReinitialize
      validationSchema={toFormikValidationSchema(validationSchema)}
      onSubmit={onSubmit}
      initialValues={initialValue}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <div style={{ margin: 100 }}>
          <DSButton
            label={t("training.dashboard.configuration.panel.add.key.indicator", {
              defaultValue: "Ajouter une section: indicateur clef",
            })}
            onClick={() => {
              setFieldValue("panels", [
                ...values.panels,
                {
                  short_name: "indicateurs clef",
                  cards: [],
                  type: "key-indicators",
                },
              ]);
            }}
          />
          <DSButton
            label={t("training.dashboard.configuration.panel.add.indicator.detailed", {
              defaultValue: "Ajouter une section: indicateurs détaillée",
            })}
            onClick={() => {
              setFieldValue("panels", [
                ...values.panels,
                {
                  short_name: "indicateur détaillé",
                  cards: [],
                  type: "detail-indicators",
                },
              ]);
            }}
          />
          <DSButton
            label={t("training.dashboard.configuration.panel.add.summary", {
              defaultValue: "Ajouter une section: Résumé du plan de formation",
            })}
            onClick={() => {
              setFieldValue("panels", [
                ...values.panels,
                {
                  short_name: summaryQuestion.recommended_short_name,
                  cards: [],
                  type: "summary",
                },
              ]);
            }}
          />
          <DSButton
            label={t("training.dashboard.configuration.panel.add.monthly", {
              defaultValue: "Ajouter une section: mensuel",
            })}
            onClick={() => {
              setFieldValue("panels", [
                ...values.panels,
                {
                  short_name: monthlyQuestion.recommended_short_name,
                  cards: [],
                  type: "monthly",
                },
              ]);
            }}
          />

          {values.panels.map((panel: PanelConfigForm, panelIndex: number) => (
            <Flex column>
              <Panel panel={panel} panelIndex={panelIndex} setFieldValue={setFieldValue} />

              <DSButton
                style={{ height: 40 }}
                label={t("training.dashboard.configuration.panel.delete", {
                  defaultValue: "Supprimer la section",
                })}
                onClick={() => {
                  setFieldValue(
                    "panels",
                    values.panels.filter((_, i) => i !== panelIndex)
                  );
                }}
              />
            </Flex>
          ))}

          <DSButton type="submit" label="Submit" onClick={() => handleSubmit()} />
        </div>
      )}
    </Formik>
  );
};
