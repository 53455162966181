import { useContext } from "react";

import { GridRowModel } from "@mui/x-data-grid-pro";

import { TalentGridState } from "./../../types";
import { SupervisionContext } from "./../../contexts";
import { CommentModal, ChangeManagerModal, AssignActionsModal } from "./../../components";

export function Modals(props: {
  apiRef: any;
  state: TalentGridState;
  setState: (state: TalentGridState) => void;
  processRowUpdate: (newRow: GridRowModel, original: GridRowModel) => void;
}) {
  const { campaign } = useContext(SupervisionContext);
  const { apiRef, processRowUpdate, setState, state } = props;

  const handleEditCommentSuccess = async ({
    newComment,
    row,
  }: {
    row: GridRowModel;
    newComment: string;
  }) => {
    const newRow = {
      ...row,
      comment: newComment,
    };

    apiRef.current.updateRows([newRow]); // propagate optimistic in the grid

    await processRowUpdate(newRow, row);

    setState({ kind: "idle" });
  };

  switch (state.kind) {
    case "assigningActionsV2":
      return (
        <AssignActionsModal
          fullName={state.row.fullName}
          campaignActions={campaign.actions}
          isReadonly={!campaign.permissions["assign-actions"].isEnabled}
          onClose={() => setState({ kind: "idle" })}
          assignedActions={
            state.row.assignedActions?.map((action) => ({
              actionID: action.actionID,
              coordinatorComment: action.coordinatorComment,
              managerComment: action.managerComment,
              origin: action.origin,
              state: action.state,
            })) ?? []
          }
          onSuccess={async (selection) => {
            const newRow = {
              ...state.row,
              assignedActions: selection.map((s) => ({
                actionID: s.actionID,
                coordinatorComment: s.coordinatorComment,
                managerComment: s.managerComment,
                origin: s.origin,
                state: s.state,
              })),
              updateActions: true,
            };

            newRow.status = "in_progress";
            apiRef.current.updateRows([newRow]);

            await processRowUpdate(newRow, state.row);

            setState({ kind: "idle" });
          }}
        />
      );
    case "changeManager": {
      return (
        <ChangeManagerModal
          reviews={state.reviews}
          campaignID={campaign.id}
          onClose={() => setState({ kind: "idle" })}
          onSuccess={() => setState({ kind: "idle" })}
        />
      );
    }
    case "editingComment":
      const isReviewEnabled = !campaign.permissions["review"].isEnabled;

      return (
        <CommentModal
          row={state.row}
          isReadonly={isReviewEnabled}
          onClose={() => setState({ kind: "idle" })}
          onSuccess={(newComment) => handleEditCommentSuccess({ newComment, row: state.row })}
        />
      );
    default:
      return <></>;
  }
}
