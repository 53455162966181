import React from "react";
import cx from "classnames";
import isNil from "lodash/isNil";
import toString from "lodash/toString";

import EasingEquations from "utils/EasingEquations";
import Icon from "components/Icon";
import { chevronBottom, check } from "uiAssets/StrokeIcons";
import colors from "uiAssets/Colors";

import styles from "./DropDownStyles.module.scss";

export type DropDownOptions<T> = ReadonlyArray<{ label: string; value: T, disabled?: boolean }>;

export interface IDropDownPropsInterface<T extends DropDownOptions<any>> {
  readonly background?: string;
  readonly borderColor?: string;
  readonly className?: string;
  readonly defaultValue?: T[number]["value"];
  readonly icon?: string;
  readonly iconStyles?: object;
  readonly isRequired?: boolean;
  readonly label?: string;
  readonly description?: string;
  readonly name?: string;
  readonly onSelect?: (key: T[number]["value"]) => void;
  readonly options: T;
  readonly placeholder?: string;
  readonly strokeIcon?: string;
  readonly styles?: object;
  readonly underlineColor?: string;
  readonly id?: string;
  readonly size?: string;
  readonly showRequired?: boolean;
}

export default class DropDown<T extends DropDownOptions<any>> extends React.PureComponent<
  IDropDownPropsInterface<T>,
  {}
> {
  private container: HTMLDivElement;
  private openIcon: SVGSVGElement;
  private optionsContainer: HTMLDivElement;
  private optionsInnerContainer: HTMLDivElement;
  private originalHeight: number;
  public iconContainer;
  public input: HTMLInputElement;
  public label: HTMLLabelElement;

  public state = {
    currentValue: this.props.defaultValue || null,
    optionsDisplayed: false,
  };

  public componentDidUpdate(prevProps) {
    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.setState({ currentValue: this.props.defaultValue });
    }
  }

  public checkValidity = (): boolean => {
    const possibleOptions = this.props.options.map((o) => o.value);
    if (this.props.isRequired) {
      return !isNil(this.state.currentValue) && possibleOptions.includes(this.state.currentValue);
    }
    return possibleOptions.includes(this.state.currentValue);
  };

  public getValue = (): T[number]["value"] => this.state.currentValue;

  /**
   * Animation functions
   */
  private selfOpen = (): void => {
    if (this.state.optionsDisplayed) return;

    this.setState({ optionsDisplayed: true });
    this.optionsContainer.style.display = "block";
    this.originalHeight = this.originalHeight || this.optionsContainer.offsetHeight;
    this.optionsInnerContainer.style.overflowY = "hidden";
    this.container.style.zIndex = "999";

    this.openIcon.animate([{ transform: "rotate(0deg)" }, { transform: "rotate(180deg)" }], {
      duration: 300,
      easing: EasingEquations.easeOutBack,
      fill: "forwards",
    });
    this.optionsContainer.animate(
      [
        { opacity: 0, height: "0px" },
        {
          height: `${this.originalHeight}px`,
          opacity: 1,
        },
      ],
      {
        duration: 250,
        fill: "forwards",
      }
    );
    this.props.options.forEach((_value, index) =>
      this[`option${index}`].animate(
        [
          {
            opacity: 0,
            transform: "transLateX(150px)",
          },
          {
            opacity: 1,
            transform: "transLateX(0px)",
          },
        ],
        {
          delay: 150 + index * 50,
          duration: 150,
          easing: EasingEquations.easeOutSine,
          fill: "forwards",
        }
      )
    );

    window.setTimeout(() => {
      if (this.optionsContainer) {
        this.optionsInnerContainer.style.overflowY = "auto";
        if (this.container) this.container.style.zIndex = "99";
      }
    }, 300);
  };

  private selfClose = (): void => {
    if (!this.state.optionsDisplayed) return;

    window.removeEventListener("mouseup", this.selfClose);
    if (!this.optionsContainer) return;
    this.optionsInnerContainer.style.overflowY = "hidden";
    this.setState({ optionsDisplayed: false });
    this.openIcon.animate([{ transform: "rotate(180deg)" }, { transform: "rotate(0deg)" }], {
      duration: 300,
      easing: EasingEquations.easeOutBack,
      fill: "forwards",
    });
    this.props.options.forEach((_value, index) =>
      this[`option${index}`].animate(
        [
          {
            opacity: 1,
          },
          {
            opacity: 0,
          },
        ],
        {
          delay: this.props.options.length - index * 50,
          duration: 100,
          fill: "forwards",
        }
      )
    );
    this.optionsContainer.animate([{ height: `${this.originalHeight}px` }, { height: "0px" }], {
      delay: 100,
      duration: 150,
      fill: "forwards",
    }).onfinish = () => {
      if (this.optionsContainer) this.optionsContainer.style.display = "none";
      if (this.container) this.container.style.zIndex = "1";
    };
  };

  private displayOptions = (): void => {
    if (this.state.optionsDisplayed) {
      this.selfClose();
      return;
    }
    this.selfOpen();
    window.setTimeout(() => window.addEventListener("mouseup", this.selfClose, false), 50);
  };

  public selectOption = (optionKey): void => {
    this.setState({ currentValue: optionKey });
    if (this.props.onSelect) this.props.onSelect(optionKey);
    this.selfClose();
  };

  public render(): JSX.Element {
    const {
      background,
      className,
      icon,
      iconStyles,
      isRequired,
      label,
      description,
      name,
      options,
      placeholder,
      strokeIcon,
      underlineColor,
      id,
      size,
      showRequired,
    } = this.props;
    const { currentValue } = this.state;

    const option = options.find((o) => o.value === currentValue);
    const currentLabel = option ? option.label || option.value : placeholder || null;

    return (
      <div
        aria-label={`dropdown-${name}`}
        className={cx(styles.DropDown, className, {
          [styles.hasIcon]: icon || strokeIcon,
          [styles.small]: size === "small",
        })}
        ref={(div) => (this.container = div as HTMLDivElement)}
        id={id}
        style={this.props.styles}
      >
        <input
          onFocus={this.displayOptions}
          value={toString(currentValue)}
          required={!!isRequired}
          onChange={() => null}
          autoComplete="off"
          name={name || ""}
          ref={(inputElement) => (this.input = inputElement as HTMLInputElement)}
        />

        {!!label && (
          <span
            className={styles.label}
            ref={(labelElement) => (this.label = labelElement as HTMLLabelElement)}
          >
            {label} {showRequired ? <span>*</span> : ""}
          </span>
        )}

        {description ? <p className={styles.description}>{description}</p> : false}

        <button
          onMouseUp={this.displayOptions}
          type="button"
          style={{ boxShadow: `0 0 0 1000px ${background || "#f6f6f6"} inset` }}
        >
          {(icon || strokeIcon) && (
            <div
              ref={(div) => (this.iconContainer = div as HTMLDivElement)}
              className={styles.iconContainer}
            >
              <Icon
                className={styles.icon}
                icon={icon || undefined}
                strokeIcon={strokeIcon || undefined}
                style={iconStyles}
              />
            </div>
          )}
          <span>{currentLabel}</span>
          <Icon
            strokeIcon={chevronBottom}
            width={10}
            ref={(iconElement) => (this.openIcon = iconElement)}
          />
        </button>

        <div
          className={styles.borderBottom}
          style={{ background: underlineColor || colors.gradientMain }}
        />

        <div
          className={styles.optionsContainer}
          ref={(div) => (this.optionsContainer = div as HTMLDivElement)}
        >
          <div
            className={styles.optionsInnerContainer}
            ref={(div) => (this.optionsInnerContainer = div as HTMLDivElement)}
          >
            {options.map((currentOption, index) => [
              <button
                className={styles.option}
                key={String(currentOption.value)}
                ref={(el) => (this[`option${index}`] = el)}
                onClick={() => this.selectOption(currentOption.value)}
                type="button"
                aria-label={`option-${currentOption.label}`}
                data-value={currentOption.value}
                disabled={currentOption.disabled}
              >
                <span>{currentOption.label}</span>
                {currentOption.value === currentValue && <Icon strokeIcon={check} width={15} />}
              </button>,
              index < options.length - 1 ? (
                <div className={styles.border} key={`${currentOption.value}-border`} />
              ) : null,
            ])}
          </div>
        </div>
      </div>
    );
  }
}
