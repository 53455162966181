/**
 * Return string and add the plural letters if needed
 * Example: plural(1, "%n saumon%s") => "1 saumon"
 * Example: plural(2, "%n saumon%s") => "2 saumons"
 * Example: plural(2, "cet utilisateur", {pluralText:"ces %n utilisateurs"}) => "ces 2 utilisateurs"
 */
export function plural(
  count: number,
  text: string,
  { pluralText = text, pluralLetter = "s" } = {}
) {
  const isPlural = count > 1;
  const rightText = isPlural ? pluralText : text;
  return rightText.replace(/%n/g, count.toString()).replace(/%s/g, isPlural ? pluralLetter : "");
}
