import { FormikInputText } from "@skillup/design-system";
import { DSButton, Flex } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

import type { PanelConfigForm } from "../../types";

import { DetailedIndicatorCard } from "./DetailedIndicatorCard";
import { KeyIndicatorCard } from "./KeyIndicatorCard";
import { useEffect } from "react";
import { useTrainingDashboardContext } from "../../context";

type Props = {
  panelIndex: number;
  panel: PanelConfigForm;
  setFieldValue: (field: string, value: any) => void;
};

export const Panel = ({ panelIndex, panel, setFieldValue }: Props) => {
  const { t } = useTranslation();
  const { summaryQuestion, monthlyQuestion } = useTrainingDashboardContext();

  useEffect(() => {
    if (panel.type === "summary") {
      setFieldValue(`panels[${panelIndex}].cards`, [
        {
          question_name: summaryQuestion.name,
          short_name: summaryQuestion.recommended_short_name,
        },
      ]);
    }

    if (panel.type === "monthly") {
      setFieldValue(`panels[${panelIndex}].cards`, [
        {
          question_name: monthlyQuestion.name,
          short_name: monthlyQuestion.recommended_short_name,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCards = panel.type === "detail-indicators" || panel.type === "key-indicators";

  return (
    <Flex column style={{ margin: 50, padding: 5, border: "solid black 1px" }}>
      <FormikInputText name={`panels[${panelIndex}].short_name`} value={panel.short_name} />
      {hasCards && (
        <>
          <DSButton
            label={t("training.dashboard.configuration.card.add", {
              defaultValue: "Ajouter une carte",
            })}
            onClick={() => {
              setFieldValue(`panels[${panelIndex}].cards`, [...panel.cards, { short_name: "" }]);
            }}
          />

          <Flex row>
            {panel.cards.map((card, cardIndex) => (
              <Flex column style={{ margin: 50 }}>
                {panel.type === "detail-indicators" && (
                  <DetailedIndicatorCard
                    path={`panels[${panelIndex}].cards[${cardIndex}]`}
                    card={card}
                    setFieldValue={setFieldValue}
                  />
                )}
                {panel.type === "key-indicators" && (
                  <KeyIndicatorCard
                    path={`panels[${panelIndex}].cards[${cardIndex}]`}
                    card={card}
                    setFieldValue={setFieldValue}
                  />
                )}

                <DSButton
                  label={t("training.dashboard.configuration.card.delete", {
                    defaultValue: "Supprimer la carte",
                  })}
                  onClick={() => {
                    setFieldValue(
                      `panels[${panelIndex}].cards`,
                      panel.cards.filter((_, i) => i !== cardIndex)
                    );
                  }}
                />
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </Flex>
  );
};
