import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";

import { ProjectRoutes } from "@skillup/espace-rh-bridge";

import type { Project } from "./getProjects";

type DeleteProjectRouteType = ProjectRoutes.DeleteProject;

const deleteProject = async (
  project: Project,
  toggleLoading: (nextValue?: boolean) => void
): Promise<DeleteProjectRouteType["response"]> => {
  toggleLoading(true);

  const response = await buildRequest<DeleteProjectRouteType>({
    method: "DELETE",
    path: "/projects/{uuid}",
    params: { uuid: project.properties.uuid },
  })();

  Acta.dispatchEvent("sendAppMessage", {
    message: "Session supprimée avec succès",
    type: "success",
  });
  Acta.dispatchEvent("closeModal");

  return response;
};

export default deleteProject;
