import React from "react";
import { isNil } from "lodash";

import { MaterialIcons } from "@skillup/ui";
import { IScheduleLog } from "@skillup/types";

import DataLayer from "utils/DataLayer";
import {
  arobase as mailIcon,
  calendar as calendarIcon,
  check as checkIcon,
  cross as crossIcon,
  plus as plusIcon,
  letter,
  edit as editIcon,
} from "uiAssets/StrokeIcons";
import colors from "uiAssets/Colors";

const logsStaticData = {
  "added-to-intra": {
    getLabel: (argument: IScheduleLog): string =>
      `<span>${
        argument.user.fullName || argument.user.email || ""
      }</span> rattaché au programme intra.`,
    icon: plusIcon,
  },
  "added-to-plan": {
    getLabel: (argument: IScheduleLog): string =>
      `Demande de formation ajoutée au plan <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: plusIcon,
  },
  approved_by_manager: {
    color: colors.success,
    getLabel: (argument: IScheduleLog) =>
      `Demande approuvée <span>par ${argument.user.fullName || argument.user.email || ""}</span>`,
    icon: checkIcon,
    iconStroke: "#fff",
    style: {
      background: colors.successL,
    },
  },
  "automatic-reminder-email": {
    getLabel: (argument: IScheduleLog): string =>
      `Relance de l’email de choix de session <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: mailIcon,
  },
  "booking-created": {
    getLabel: (argument: IScheduleLog): string =>
      `Inscription réalisée <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: calendarIcon,
  },
  "booking-created-outside-skillup": {
    getLabel: (argument: IScheduleLog): string =>
      `Inscription réalisée hors Skillup <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: calendarIcon,
  },
  "booking-postponed": {
    getLabel: (argument: IScheduleLog): string =>
      `Inscription reportée <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: calendarIcon,
  },
  cancelled: {
    color: colors.error,
    getLabel: (argument: IScheduleLog): string =>
      `Demande annulée <span>par ${argument.user.fullName || argument.user.email || ""}</span>.`,
    icon: crossIcon,
  },
  "cancelled-booking": {
    color: colors.error,
    getLabel: (argument: IScheduleLog): string =>
      `Session annulée <span>par ${argument.user.fullName || argument.user.email || ""}</span>.`,
    icon: crossIcon,
  },
  "cancelled-project": {
    color: colors.error,
    getLabel: (argument: IScheduleLog): string =>
      `Session du ${new Date(argument.properties.oldSessionDate).toLocaleDateString("fr-FR", {
        day: "numeric",
        month: "short",
        weekday: "long",
      })} à ${argument.properties.oldSessionCity} annulée <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>. En attente d'une nouvelle inscription.`,
    icon: crossIcon,
  },
  "cancelling-booking": {
    color: colors.warning,
    getLabel: (argument: IScheduleLog): string =>
      `Demande d’annulation émise <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>.`,
    icon: crossIcon,
  },
  collected: {
    getLabel: (argument: IScheduleLog): string =>
      `Demande de formation émise <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: plusIcon,
  },
  "convocation-by-skillup": {
    getLabel: (_argument: IScheduleLog): string => "Convocation envoyée <span>avec Skillup</span>",
    icon: letter,
  },
  "convocation-by-rf": {
    getLabel: (argument: IScheduleLog): string =>
      `Convocation envoyée <span>hors Skillup par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: letter,
  },
  denied: {
    color: colors.error,
    getLabel: (argument: IScheduleLog): string =>
      `Demande refusée <span>par ${argument.user.fullName || argument.user.email || ""}</span>.`,
    icon: crossIcon,
  },
  denied_by_manager: {
    color: colors.error,
    getLabel: (argument: IScheduleLog) =>
      `Demande refusée <span>par ${argument.user.fullName || argument.user.email || ""}</span>`,
    icon: crossIcon,
    iconStroke: "#fff",
    iconWidth: 12,
    style: {
      background: colors.errorL,
    },
  },
  imported: {
    getLabel: (argument: IScheduleLog): string =>
      `Ligne importée <span>par ${argument.user.fullName || argument.user.email || ""}</span>`,
    icon: plusIcon,
  },
  "intra-created": {
    getLabel: (argument: IScheduleLog): string =>
      `Création du programme Intra <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>.`,
    icon: plusIcon,
  },
  "moved-to-plan": {
    color: colors.success,
    getLabel: (argument: IScheduleLog): string =>
      `Demande validée <span>par ${argument.user.fullName || argument.user.email || ""}</span>.`,
    icon: checkIcon,
  },
  positioned: {
    getLabel: (argument: IScheduleLog): string =>
      `Inscription réalisée <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: calendarIcon,
  },
  "positioning-email": {
    getLabel: (argument: IScheduleLog): string =>
      `Email de choix de session envoyé <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>.`,
    icon: mailIcon,
  },
  "positioning-email-by-manager": {
    getLabel: (argument: IScheduleLog): string =>
      // @ts-ignore
      `Email de choix de session envoyé au manager ${argument.properties.managerID} <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>.`,
    icon: mailIcon,
  },
  postponed: {
    getLabel: (argument: IScheduleLog): string =>
      `Inscription à la session du ${new Date(
        argument.properties.oldSessionDate
      ).toLocaleDateString("fr-FR")} ${
        argument.properties.oldSessionCity ? `à ${argument.properties.oldSessionCity}` : ""
      } annulée, en attente d’une nouvelle inscription <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: calendarIcon,
  },
  "project-postponed": {
    getLabel: (argument: IScheduleLog): string =>
      `Session reportée
      du
        <strong><em>${new Date(argument.properties.oldSessionDate).toLocaleDateString(
          "fr-FR"
        )}</em></strong>
          à <strong>${argument.properties.oldSessionCity}</strong>
        au <strong><em>${new Date(argument.properties.newSessionDate).toLocaleDateString(
          "fr-FR"
        )}</em></strong>
          à <strong>${argument.properties.newSessionCity}</strong>
          par ${argument.user.fullName || argument.user.email || ""}</span>`,
    icon: calendarIcon,
  },
  "proposed-by-manager": {
    getLabel: (argument: IScheduleLog): string =>
      `Formation proposée par <strong>${argument.user.fullName}</strong>`,
    icon: plusIcon,
  },
  "reminder-email": {
    getLabel: (argument: IScheduleLog): string =>
      `Relance de l’email de choix de session <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>.`,
    icon: mailIcon,
  },
  requalified: {
    getLabel: (argument: IScheduleLog): string => {
      const originMessage = !isNil(argument.properties.originOrg)
        ? `Demande requalifiée <span>
            par ${argument.user.fullName || argument.user.email || ""} depuis
            <strong>"<em>${argument.properties.origin}</em>"</strong> par <strong>${
            argument.properties.originOrg
          }</strong>`
        : `Demande requalifiée <span>
            par ${argument.user.fullName || argument.user.email || ""} depuis
            la remontée de besoin libre <strong>"<em>${argument.properties.origin}</em>"</strong>`;

      return `${originMessage}
        vers <strong>"<em>${argument.properties.target}</em>"</strong> par <strong>${argument.properties.targetOrg}</strong>.
      </span>`;
    },
    icon: plusIcon,
  },
  "validation-reminder": {
    getLabel: (argument: IScheduleLog): string =>
      `Relance de l’email de validation <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>.`,
    icon: mailIcon,
  },
  "moved-from": {
    getLabel: (argument: IScheduleLog): string =>
      `Demande déplacée depuis <span><em>${argument.properties.originPlan}</em></span> par ${
        argument.user.fullName || argument.user.email || ""
      }.`,
    icon: plusIcon,
  },
  "manual-edition": {
    getLabel: (argument: IScheduleLog): string =>
      `Demande éditée manuellement <span>par ${
        argument.user.fullName || argument.user.email
      }</span>`,
    icon: editIcon,
  },
  "added-to-collection": {
    getLabel: (argument: IScheduleLog): string =>
      `Demande de formation ajoutée au recueil <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: plusIcon,
  },
  "reverted-to-approved": {
    getLabel: (argument: IScheduleLog): string =>
      `Demande renvoyée au statut "À inscrire" <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: <MaterialIcons.Replay color={colors.blue} size="18px" />,
  },
  "reverted-to-pending": {
    getLabel: (argument: IScheduleLog): string =>
      `Demande renvoyée au statut "À valider RH" <span>par ${
        argument.user.fullName || argument.user.email || ""
      }</span>`,
    icon: <MaterialIcons.Replay color={colors.blue} size="18px" />,
  },
};

export interface IActivity extends IScheduleLog {
  color?: string;
  getLabel: (argument: IScheduleLog) => string;
  icon: string | React.ReactNode;
  iconStroke?: "#fff";
  iconWidth?: 12;
  style?: {
    [key: string]: string;
  };
  properties: IScheduleLog["properties"];
}

export default async (rowUuid) => {
  try {
    const logs = (await DataLayer.request({
      method: "GET",
      target: "API",
      url: `/v1/scheduleRow/logs/${rowUuid}`,
    })) as Array<IScheduleLog>;

    const activities: Array<IActivity> = [...(logs || [])].map((logItem) => {
      return {
        ...logItem,
        ...logsStaticData[logItem.properties.event],
      };
    });

    const activitiesByDates = activities.reduce((accByDates, act) => {
      const d = new Date(act.properties.createdAt)
        .toLocaleDateString("fr", {
          day: "numeric",
          month: "long",
          weekday: "long",
        })
        .toString();

      accByDates[d] = [...(accByDates[d] || []), act];
      return accByDates;
    }, {});

    return activitiesByDates;
  } catch (err) {
    return undefined;
  }
};
