import type { BasicRouteType, SkillupApi } from "@skillup/http-server-utils";
import { DashboardChildType } from "./getChild";

interface ExportDashboardChildRoute extends BasicRouteType {
  method: "GET";
  params: { uuid: string };
  query: { areas?: string };
  response: any;
}
export type ExportChildParams = {
  uuid: string;
  userAreas?: string[];
  activeCompany: SkillupApi.Credentials["activeCompany"];
  asUser: string;
  type?: "current" | "next";
};

export interface ExportDashboardOpenQuestionRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/open-question/{uuid}/export";
}
export interface ExportDashboardClosedQuestionRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/closed-question/{uuid}/export";
}
export interface ExportDashboardTableRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/table/{uuid}/export";
}
export interface ExportDashboardCurrentTargetsRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/current-targets/{uuid}/export";
}
export interface ExportDashboardFutureTargetsRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/future-targets/{uuid}/export";
}
export interface ExportDashboardGlobalTargetsCompletionRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/global-targets-completion/{uuid}/export";
}
export interface ExportDashboardTrainingsHistoryRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/trainings-history/{uuid}/export";
}
export interface ExportDashboardInterviewsHistoryRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/interviews-history/{uuid}/export";
}
export interface ExportDashboardTrainingsCollectionRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/trainings-collection/{uuid}/export";
}
export interface ExportDashboardSkillsRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/skills/{uuid}/export";
}
export interface ExportDashboardUploadRoute extends ExportDashboardChildRoute {
  path: "/interviews/dashboard/child/upload/{uuid}/export";
}

export type ExportDashboardChildRoutes = {
  [DashboardChildType.OPEN_QUESTION]: ExportDashboardOpenQuestionRoute;
  [DashboardChildType.CLOSED_QUESTION]: ExportDashboardClosedQuestionRoute;
  [DashboardChildType.TABLE]: ExportDashboardTableRoute;
  [DashboardChildType.CURRENT_TARGETS]: ExportDashboardCurrentTargetsRoute;
  [DashboardChildType.FUTURE_TARGETS]: ExportDashboardFutureTargetsRoute;
  [DashboardChildType.GLOBAL_TARGETS_COMPLETION]: ExportDashboardGlobalTargetsCompletionRoute;
  [DashboardChildType.TRAININGS_HISTORY]: ExportDashboardTrainingsHistoryRoute;
  [DashboardChildType.INTERVIEWS_HISTORY]: ExportDashboardInterviewsHistoryRoute;
  [DashboardChildType.TRAININGS_COLLECTION]: ExportDashboardTrainingsCollectionRoute;
  [DashboardChildType.SKILLS]: ExportDashboardSkillsRoute;
  [DashboardChildType.UPLOAD]: ExportDashboardUploadRoute;
};
