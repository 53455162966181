import React from "react";
import Colors from "uiAssets/Colors";

const LoadingError: React.SFC<{}> = (): JSX.Element => (
  <div
    style={{
      background: Colors.error,
      borderRadius: 7,
      color: "#fff",
      margin: "30px auto",
      maxWidth: 500,
      padding: 20,
      textAlign: "center",
      width: "calc( 100% - 30px )",
    }}
  >
    Erreur lors du chargement des données.
  </div>
);

export default LoadingError;
