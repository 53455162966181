import useTranslation from "hooks/useTranslation";
import { Pie, MaterialIcons } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { ColdReviewDetails } from "@skillup/training-bridge";

import styles from "./ColdReviewSidePanel.module.scss";
import { getSentimentColors, getSentimentPercentage } from "../utils/reviewDetailsQuestionsParser";

export interface Props {
  reviewDetails: ColdReviewDetails;
}

const PieChart = ({ reviewDetails }: Props) => {
  const { t } = useTranslation();
  const { positive: percentagePositiveQuestions, negative: percentageNegativeQuestions } =
    getSentimentPercentage(reviewDetails, reviewDetails.data);
  const { positive: positiveColor, negative: negativeColor } = getSentimentColors(reviewDetails);

  const positiveLabel = t("trainings.manage.reviews.legend.positive", {
    defaultValue: "{{percentagePositiveQuestions}}% de réponse positive",
    percentagePositiveQuestions: percentagePositiveQuestions.toFixed(0),
  });
  const negativeLabel = t("trainings.manage.reviews.legend.negative", {
    defaultValue: "{{percentageNegativeQuestions}}% de réponse négative",
    percentageNegativeQuestions: percentageNegativeQuestions.toFixed(0),
  });

  const data = [
    {
      value: percentageNegativeQuestions.toString(),
      label: negativeLabel,
      color: negativeColor,
    },
    {
      value: percentagePositiveQuestions.toString(),
      label: positiveLabel,
      color: positiveColor,
    },
  ];

  return (
    <div className={styles.chartComponent}>
      <Pie
        margin={{
          top: 4,
          bottom: 4,
          right: 4,
          left: 4,
        }}
        isInteractive={true}
        activeOuterRadiusOffset={4}
        animate
        width={150}
        height={150}
        data={data}
        tooltip={({ datum }) => {
          return (
            <div
              style={{
                padding: "4px 8px 4px 8px",
                border: "1px solid #00000024",
                background: "#0A4D71",
                color: "white",
                width: "250px",
                fontSize: "12px",
                borderRadius: "4px",
                textAlign: "center",
                fontFamily: "Noto Sans Display",
                fontWeight: "40px",
                lineHeight: "18px",
                maxWidth: "12rem",
              }}
            >
              <span style={{ whiteSpace: "normal" }}> {datum.label} </span>
            </div>
          );
        }}
      />
      <Flex flexDirection="column" className={styles.legend} gap="xs" justifyContent="flex-end">
        <Flex gap="xs" justifyContent="flex-start" alignItems="center">
          <MaterialIcons.Record color={positiveColor} />
          <span className={styles.legendItem}>
            {t("trainings.manage.reviews.legend.positive", {
              defaultValue: "{{percentagePositiveQuestions}}% de réponse positive",
              percentagePositiveQuestions,
            })}
          </span>
        </Flex>
        <Flex gap="xs" justifyContent="flex-start" alignItems="center">
          <MaterialIcons.Record color={negativeColor} />
          <span className={styles.legendItem}>
            {t("trainings.manage.reviews.legend.negative", {
              defaultValue: "{{percentageNegativeQuestions}}% de réponse négative",
              percentageNegativeQuestions,
            })}
          </span>
        </Flex>
      </Flex>
    </div>
  );
};

export default PieChart;
