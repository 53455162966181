import React from "react";

import InteractiveButton from "components/InteractiveButton";

import styles from "./NoSessionModalStyles.module.scss";

export interface IProps {
  requalify: () => void;
  goToProjects: () => void;
  type?: "inter" | "intra";
}

const NoSessionModal = ({ requalify, goToProjects, type }: IProps) => (
  <div className={styles.noSessionModal}>
    <p>Il semblerait que cette formation ne propose pas de date de session.</p>
    {type !== "intra" && (
      <p>
        Souhaitez-vous requalifier la demande afin de l'associer à un programme équivalent avec des
        sessions ?
      </p>
    )}
    {type === "intra" && (
      <p>
        Pour ajouter une session ou modifier une session existante, allez dans la section "Mes
        sessions".
      </p>
    )}
    <div className={styles.buttons}>
      <InteractiveButton
        label={type !== "intra" ? "Requalifer" : "Ajouter / Modifier une session"}
        onClick={type !== "intra" ? requalify : goToProjects}
        size="small"
      />
    </div>
  </div>
);

export default NoSessionModal;
