import { ModulesAccessList } from "@skillup/espace-rh-bridge";

import { ModuleType } from "hooks/useSettings";
import User from "utils/User";

const Trainings = (hasAccess: { to: (module: ModuleType) => boolean }) => {
  const hasTrainingsModuleEnabled = (moduleAccess: ModulesAccessList) =>
    User.isSkillupDeveloper() || (User.hasTrainingsEnabled() && hasAccess.to(moduleAccess));

  const toTrainingsSchedulePlan = () =>
    hasTrainingsModuleEnabled(ModulesAccessList.TrainingsSchedulePlan);
  const toTrainingsScheduleCollection = () =>
    hasTrainingsModuleEnabled(ModulesAccessList.TrainingsScheduleCollection);
  const toTrainingsRegulatoryHabilitations = () =>
    hasTrainingsModuleEnabled(ModulesAccessList.TrainingsRegulatoryHabilitations);
  const toTrainingsRegulatoryTrackings = () =>
    hasTrainingsModuleEnabled(ModulesAccessList.TrainingsRegulatoryTrackings);
  const toTrainingsIntrasOrganization = () =>
    hasTrainingsModuleEnabled(ModulesAccessList.TrainingsIntrasOrganization);
  const toTrainingsIntrasSkillup = () =>
    hasTrainingsModuleEnabled(ModulesAccessList.TrainingsIntrasSkillup);
  const toTrainingsSessions = () => hasTrainingsModuleEnabled(ModulesAccessList.TrainingsSessions);
  const toTrainingsHotEvaluations = () =>
    hasTrainingsModuleEnabled(ModulesAccessList.TrainingHotEvaluations);
  const toTrainingsColdEvaluations = () =>
    hasTrainingsModuleEnabled(ModulesAccessList.TrainingColdEvaluations);

  const accessesToTrainings = [
    {
      id: "recueil-des-besoins",
      label: "Recueil des besoins",
      url: "/responsable/{uuid}/recueil-des-besoins/collection-pending",
      access: toTrainingsSchedulePlan,
    },
    {
      id: "plan-de-formation",
      label: "Plan de formation",
      url: "/responsable/{uuid}/plan-de-formation/plan-approved",
      access: toTrainingsScheduleCollection,
    },
    {
      id: "reglementaire",
      label: "Réglementaire",
      url: "/responsable/reglementaire/habilitations",
      access: toTrainingsRegulatoryHabilitations,
    },
    {
      id: "reglementaire",
      label: "Réglementaire",
      url: "/responsable/reglementaire/suivi",
      access: toTrainingsRegulatoryTrackings,
    },
    {
      id: "programmes",
      label: "Programmes",
      url: "/responsable/programmes/gestion-intras",
      access: toTrainingsIntrasOrganization,
    },
    {
      id: "programmes",
      label: "Programmes",
      url: "/responsable/programmes/catalogue",
      access: toTrainingsIntrasSkillup,
    },
    {
      id: "sessions",
      label: "Sessions",
      url: "/responsable/mes-sessions/en-cours",
      access: toTrainingsSessions,
    },
    {
      id: "evaluations",
      label: "Évaluations",
      url: "/responsable/evaluations/hot",
      access: toTrainingsHotEvaluations,
    },
    {
      id: "evaluations",
      label: "Évaluations",
      url: "/responsable/evaluations/cold",
      access: toTrainingsColdEvaluations,
    },
  ];

  const toTrainings = () => accessesToTrainings.some((item) => item.access());

  const getFirstRouteAccessible = () => {
    accessesToTrainings.forEach((item) => {
      if (item.access()) {
        return item.url;
      }
      return undefined;
    });
  };

  return {
    toTrainings,
    toTrainingsSchedulePlan,
    toTrainingsScheduleCollection,
    toTrainingsRegulatoryHabilitations,
    toTrainingsRegulatoryTrackings,
    toTrainingsIntrasOrganization,
    toTrainingsIntrasSkillup,
    toTrainingsSessions,
    toTrainingsHotEvaluations,
    toTrainingsColdEvaluations,
    getFirstRouteAccessible,
  };
};

export default Trainings;
