import cx from "classnames";

import styles from "./Collapsible.module.scss";

export const Collapsible = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: React.ReactNode;
}) => {
  return (
    <div className={cx(styles["wrapper"], isOpen ? styles["show"] : "")}>
      <div className={styles["inner"]}>{children}</div>
    </div>
  );
};
