import type { StructureItemPosition, PageBlock, SectionBlock, ChildBlock } from "../../reducer";
import type { Action, DNDStructure } from "./types";

export function dndStructureReducer(state: DNDStructure, action: Action) {
  const lastSectionIndex = state.sections.length !== 0 ? state.sections.length - 1 : 0;
  if (action.type === "init") {
    return action.structure;
  }
  if (action.type === "movePageToSection") {
    if (action.toSectionIndex === 0 || action.toSectionIndex === lastSectionIndex) {
      return state;
    }
    return movePageToSection(state, action.fromPosition, action.toSectionIndex);
  }
  if (action.type === "moveChildToPage") {
    const { fromPosition, toPosition } = action;
    if (toPosition.sectionIndex === 0 || toPosition.sectionIndex === lastSectionIndex) {
      return state;
    }
    return moveChildToPage(state, fromPosition, toPosition);
  }
  return state;
}

function moveChildToPage(
  state: DNDStructure,
  fromPosition: StructureItemPosition<ChildBlock>,
  toPosition:
    | StructureItemPosition<PageBlock>
    | StructureItemPosition<SectionBlock>
    | StructureItemPosition<ChildBlock>
) {
  if (fromPosition.type !== "child") {
    throw new Error();
  }
  if (fromPosition.sectionIndex === toPosition.sectionIndex) {
    if (toPosition.type === "section") {
      return state;
    }
  }

  const {
    sectionIndex: fromSectionIndex,
    pageIndex: fromPageIndex,
    childIndex: fromChildIndex,
  } = fromPosition;
  const { sectionIndex: toSectionIndex } = toPosition;
  const toPageIndex = toPosition.type !== "section" ? toPosition.pageIndex : 0;
  const fromPage = state.sections[fromSectionIndex].pages[fromPageIndex];
  const toPage = state.sections[toSectionIndex].pages[toPageIndex];

  const fromChild = fromPage.children[fromChildIndex];
  if (!fromChild) {
    return state;
  }
  fromPage.children.splice(fromChildIndex, 1);
  if (toSectionIndex === fromSectionIndex) {
    if (toPageIndex < fromPageIndex) {
      toPage.children.push(fromChild);
    } else {
      toPage.children.unshift(fromChild);
    }
  } else {
    if (toSectionIndex < fromSectionIndex) {
      toPage.children.push(fromChild);
    } else {
      toPage.children.unshift(fromChild);
    }
  }
  const newSections = state.sections.map((section, sectionIndex) => {
    if (sectionIndex === fromSectionIndex || sectionIndex === toSectionIndex) {
      const newPages = section.pages.map((page, pageIndex) => {
        if (sectionIndex === fromSectionIndex && pageIndex === fromPageIndex) {
          return {
            ...page,
            children: fromPage.children,
          };
        }
        if (sectionIndex === toSectionIndex && pageIndex === toPageIndex) {
          return {
            ...page,
            children: toPage.children,
          };
        }
        return page;
      });
      return {
        ...section,
        pages: newPages,
      };
    }
    return section;
  });
  return {
    sections: newSections,
  };
}

function movePageToSection(
  state: DNDStructure,
  fromPosition: StructureItemPosition<PageBlock>,
  toSectionIndex: number
) {
  if (fromPosition.type !== "page") {
    throw new Error();
  }
  if (fromPosition.sectionIndex === toSectionIndex) {
    return state;
  }
  const { sectionIndex: fromSectionIndex, pageIndex: fromPageIndex } = fromPosition;
  const fromPage = { ...state.sections[fromSectionIndex].pages[fromPageIndex] };
  if (!fromPage) return state;

  const fromSection = { ...state.sections[fromSectionIndex] };
  const toSection = { ...state.sections[toSectionIndex] };
  const newFromSection = {
    ...fromSection,
    pages: [
      ...fromSection.pages.slice(0, fromPageIndex),
      ...fromSection.pages.slice(fromPageIndex + 1),
    ],
  };
  const newToSection = {
    ...toSection,
    pages:
      toSectionIndex < fromSectionIndex && fromPageIndex === 0
        ? [...toSection.pages, fromPage]
        : [fromPage, ...toSection.pages],
  };
  const newSections = [...state.sections];
  newSections[toSectionIndex] = newToSection;
  newSections[fromSectionIndex] = newFromSection;

  return { sections: newSections };
}
