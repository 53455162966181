import { Either } from "@skillup/monads";
import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function arbitrateEmployees(params: { campaignId: string; employeesIds: string[] }) {
  try {
    const request = await buildRequest<CampaignsRoutes.Employee["setReviewAsDone"]>({
      method: "PUT",
      params: {
        campaignId: params.campaignId,
      },
      path: "/campaign/{campaignId}/employees/review-done",
      payload: {
        employeesIds: params.employeesIds,
      },
      target: "PEOPLE_REVIEW",
    });

    await request();
    return Either.right(undefined);
  } catch (err) {
    return Either.left(err);
  }
}
