import DataLayer from "utils/DataLayer";
import { IState } from "../components/List";
import { TrainingInterface } from "../types";

const removeTrainingFromlist =
  (listUuid: string, trainings: TrainingInterface[], setState: (state: IState) => void) =>
  async (uuid: string): Promise<void> => {
    setState({
      trainings: trainings
        .filter((training) => training.properties.uuid !== uuid)
        .sort((trainigA, trainingB) => (trainigA.index <= trainingB.index ? -1 : 1))
        .map((training, index) => ({
          ...training,
          index,
        })),
    });

    await DataLayer.request({
      method: "DELETE",
      url: `/v1/portal/training-list/${listUuid}/training/${uuid}`,
    });
  };

export default removeTrainingFromlist;
