import { Status, StatusType } from "@skillup/ui";

import type { Tracking } from "../../state/tracking";

function TrackingStatus({
  status,
  darkMode = false,
}: Pick<Tracking, "status"> & { darkMode?: boolean }) {
  switch (status) {
    case "valid":
      return <Status type={StatusType.SUCCESS} label="À jour" darkMode={darkMode} />;
    case "pending":
      return <Status type={StatusType.WARNING} label="En attente" darkMode={darkMode} />;
    case "archived":
      return <Status type={StatusType.DONE} label="Archivée" darkMode={darkMode} />;
    case "expiring":
      return <Status type={StatusType.WARNING} label="Échéance proche" darkMode={darkMode} />;
    case "expired":
      return <Status type={StatusType.ERROR} label="Expirée" darkMode={darkMode} />;
  }
}

export default TrackingStatus;
