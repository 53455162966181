import { TChildGeneratorKind } from "./ChildGenerator";

const availableChildren: {
  kind: TChildGeneratorKind;
  label: string;
}[] = [
  { kind: "guidelines", label: "Guidelines" },
  { kind: "personnalisableGuidelines", label: "En-tête de l'entretien" },
  { kind: "title", label: "Titre" },
  { kind: "html", label: "HTML" },
  { kind: "question_text", label: "Question texte" },
  { kind: "question_checkbox", label: "Question choix multiples" },
  { kind: "question_radio", label: "Question radio" },
  { kind: "table", label: "Tableau" },
  { kind: "targets", label: "Objectifs" },
  { kind: "globalTargetsCompletion", label: "Taux d'atteinte global" },
  { kind: "skills", label: "Évaluation de compétences" },
  { kind: "trainingsHistory", label: "Historique de formation" },
  { kind: "interviewsHistory", label: "Historique d'entretiens" },
  { kind: "trainingsCollection", label: "Demande de formation" },
  { kind: "upload", label: "Téléchargement de fichiers" },
  { kind: "userSpecificData", label: "Données spécifiques à l'utilisateur" },
  { kind: "job", label: "Fiche de poste" },
];

export default availableChildren;
