import { TemplateRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type CreateTemplatePayload = TemplateRoutes.CreateTemplateRoute["payload"];

export async function createTemplate({ actionsIds, fieldsUuids, title }: CreateTemplatePayload) {
  const result = await buildRequest<TemplateRoutes.CreateTemplateRoute>({
    method: "POST",
    path: `/templates`,
    payload: {
      actionsIds,
      fieldsUuids,
      title,
    },
    target: "PEOPLE_REVIEW",
  })();

  return result;
}
