import { GridRenderEditCellParams } from "@mui/x-data-grid-pro";

import { RadioBox } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

import styles from "./DataGridRadio.module.scss";

type AdminGridRow = {
  id: string;
  noRole: boolean;
  observator: boolean;
  coordinator: boolean;
  administrator: string;
  setRole: (userId: string, role: "noRole" | "observator" | "coordinator") => void;
};

export function DataGridRadio(props: GridRenderEditCellParams<AdminGridRow>) {
  const { field, row, value } = props;

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      className={styles.radioCell}
      onClick={(_e) => {
        switch (field) {
          case "coordinator":
            row.setRole(row.id, "coordinator");
            break;
          case "observator":
            row.setRole(row.id, "observator");
            break;
          case "noRole":
            row.setRole(row.id, "noRole");
            break;
        }
      }}
    >
      <RadioBox value={value} checked={value} disabled={props.isDisabled} />
    </Flex>
  );
}
