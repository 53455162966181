type EventTimelineConfParams = {
  data: {
    id: string;
    type: string;
    properties: {
      date: string;
      authorFullName: string;
    };
  }[];
};

export function getEventTimelineConf({ data }: EventTimelineConfParams) {
  const timelineEventGraphConf: Highcharts.Options = {
    accessibility: {
      point: {
        valueDescriptionFormat: "{index}. {point.label}. {point.description}.",
      },
      screenReaderSection: {
        beforeChartFormat:
          "<h5>{chartTitle}</h5>" +
          "<div>{typeDescription}</div>" +
          "<div>{chartSubtitle}</div>" +
          "<div>{chartLongdesc}</div>" +
          "<div>{viewTableButton}</div>",
      },
    },
    chart: {
      type: "timeline",
      zooming: {
        type: "x",
      },
    },
    colors: ["#4185F3", "#427CDD", "#406AB2", "#3E5A8E", "#3B4A68", "#363C46"],
    series: [
      {
        type: "timeline",
        data: data,
        dataLabels: {
          allowOverlap: false,
          format:
            '<span style="color:{point.color}">● </span><span style="font-weight: bold;" > ' +
            "{point.x:%d %b %Y at %H:%m}</span><br/>{point.label} by {point.description}",
        },
        marker: {
          symbol: "circle",
        },
      },
    ],
    title: null,
    tooltip: {
      style: {
        width: 300,
      },
    },
    xAxis: {
      type: "datetime",
      visible: false,
    },
    yAxis: {
      gridLineWidth: 1,
      labels: {
        enabled: false,
      },
      title: null,
    },
  };

  return timelineEventGraphConf;
}
