import { useToasts } from "react-toast-notifications";
import { useToggle } from "react-use";
import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { DSModal, DSFormGroupTextInput } from "@skillup/ui";
import { FormikInputText } from "@skillup/design-system";

import { trpc } from "utils/trpc";
import { Category } from "types/skills";
import useTranslation from "hooks/useTranslation";
import { SkillCategorySchema } from "./SkillCategorySchema";

import styles from "./SkillCategoryModal.module.scss";

interface ModalProps {
  category: Category;
  isOpen: boolean;
  closeModal: () => void;
  refetch: () => void;
}

interface FormValues {
  categoryLabel: string;
}

const UpdateSkillCategoryModal = ({ category, isOpen, refetch, closeModal }: ModalProps) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [loading, toggleLoading] = useToggle(false);
  const updateMutator = trpc.categories.updateCategory.useMutation({
    onSuccess: () => {
      toggleLoading(false);
      addToast(
        t("portal.config.skills.createCategory.success", {
          defaultValue: "La catégorie a bien été mise à jour",
        }),
        {
          appearance: "success",
        }
      );
      refetch();
    },
    onError: () => {
      toggleLoading(false);
      addToast(
        t("portal.config.skills.createCategory.error", {
          defaultValue: "La catégorie n'a pas pu être mise à jour",
        }),
        {
          appearance: "error",
        }
      );
    },
  });

  const handleSubmit = async (values: FormValues) => {
    toggleLoading(true);
    updateMutator.mutate({
      uuid: category.uuid,
      label: values.categoryLabel,
      index: category.index,
      version: category.version + 1,
    });
    closeModal();
  };

  return (
    <Formik
      initialValues={{
        categoryLabel: category.label,
      }}
      validationSchema={toFormikValidationSchema(SkillCategorySchema)}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {(formikProps) => {
        return (
          <DSModal isOpen={isOpen} className={styles.skillCategoryModal}>
            <DSModal.Header onClose={closeModal}>
              <DSModal.Header.Title
                title={t("skills.list.skillCategory.editSkillCategory", {
                  defaultValue: "Renommer la catégorie",
                })}
              />
            </DSModal.Header>

            <DSModal.Content>
              <DSFormGroupTextInput
                label={t("skills.list.skillCategoryModal.categoryLabel", {
                  defaultValue: "Intitulé de la catégorie",
                })}
                required
              >
                <FormikInputText
                  name="categoryLabel"
                  value={formikProps.values.categoryLabel}
                  placeholder="Saisissez un intitulé"
                />
              </DSFormGroupTextInput>
            </DSModal.Content>

            <DSModal.Footer>
              <DSModal.Footer.CancelButton
                onClick={closeModal}
                label={t("skills.list.skillCategoryModal.cancel", {
                  defaultValue: "Annuler",
                })}
              />
              <DSModal.Footer.PrimaryButton
                disabled={!formikProps.isValid || !formikProps.dirty}
                onClick={() => {
                  formikProps.handleSubmit();
                }}
                label={t("skills.list.skillCategory.rename", {
                  defaultValue: "Renommer",
                })}
                loading={loading}
              />
            </DSModal.Footer>
          </DSModal>
        );
      }}
    </Formik>
  );
};

export default UpdateSkillCategoryModal;
