import React from "react";

import browserError from "assets/browser-error.svg";
import { Button, Flex } from "@skillup/ui";

import styles from "./ApplicationError.module.scss";
import useEnvironment from "hooks/useEnvironment";

interface Props {
  error: Error;
  componentStack: string | null;
}

export default function ApplicationError({ error, componentStack }: Props) {
  const { isDevelopment } = useEnvironment();

  return (
    <Flex center column className={styles.ApplicationError}>
      <img src={browserError} alt="browser error" />
      <p>
        Une erreur s’est produite lors du chargement de cette page. Actualisez la page pour la
        recharger.
      </p>
      <Button label="Actualiser" onClick={() => window.location.reload()} />
      <div style={{ display: isDevelopment ? "block" : "none", color: "red" }}>
        <div>You have encountered an error</div>
        <div>{error.toString()}</div>
        <div style={{ whiteSpace: "pre-wrap" }}>{componentStack}</div>
      </div>
    </Flex>
  );
}
