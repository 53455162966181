import { useMemo, useState } from "react";

import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { DSSidePanel, DSButton, Loader } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { EditableCompanyField } from "@skillup/training-bridge";

import useTranslation from "hooks/useTranslation";

import styles from "../Form.module.scss";

import FieldFormList from "./Form";
import FieldFormConfirmModal from "./ConfirmModal";

import { compareValues, fieldFormValidation } from "../utils";

export type Props = {
  field?: EditableCompanyField;
  loading?: boolean;
  onSubmit: (fieldData: EditableCompanyField) => void;
  onClose: () => void;
};

const SidePanelFieldForm = ({ onSubmit, onClose, field, loading }: Props) => {
  const { t } = useTranslation();
  const [cancel, setCancel] = useState(false);

  const isEdit = !!field;

  const initialFormState: EditableCompanyField = useMemo(
    () => ({
      binding: "",
      defaultValue: field.defaultValue || "",
      type: "text",
      label: "",
      isTrainingField: false,
      isHidden: false,
      massEditAvailable: true,
      restrictions: [],
      options: [],
    }),
    [field]
  );

  const handleClose = (values: EditableCompanyField) => {
    if (!compareValues(field ?? initialFormState, values)) {
      setCancel(true);
    } else {
      onClose();
    }
  };

  return (
    <Formik<EditableCompanyField>
      validateOnMount={false}
      enableReinitialize
      validationSchema={toFormikValidationSchema(fieldFormValidation(t, false))}
      onSubmit={onSubmit}
      initialValues={field ?? initialFormState}
    >
      {({ values, isValid, handleSubmit }) => (
        <DSSidePanel isOpen isAnimated={false} className={styles.sidepanel}>
          <DSSidePanel.Header onClose={() => handleClose(values)}>
            <DSSidePanel.Header.Title
              title={
                field
                  ? t(`fields.label.${field.binding}`, {
                      defaultValue: field.label,
                    })
                  : null
              }
            />
          </DSSidePanel.Header>
          <DSSidePanel.Content>
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              className={styles.FieldForm}
            >
              <FieldFormList isEdit={isEdit} />

              {!compareValues(field ?? initialFormState, values) && (
                <Flex gap={"s"} justifyContent="center" className={styles.FormFooter}>
                  <DSButton
                    emphasis="Mid"
                    disabled={loading}
                    onClick={() => handleClose(values)}
                    label={t("fields.form.cancel", {
                      defaultValue: "Annuler",
                    })}
                  />

                  <DSButton
                    disabled={loading || !isValid}
                    tooltip={
                      !isValid
                        ? t("fields.form.errors", {
                            defaultValue:
                              "Tous les champs sont obligatoires (signalés par une astérisque rouge) doivent être renseignés",
                          })
                        : undefined
                    }
                    onClick={() => {
                      handleSubmit();
                    }}
                    icon={loading ? <Loader /> : undefined}
                    label={
                      isEdit
                        ? t("fields.form.update", {
                            defaultValue: "Enregistrer les modifications",
                          })
                        : t("fields.form.create")
                    }
                  />
                </Flex>
              )}

              <FieldFormConfirmModal
                isOpen={cancel}
                onOk={onClose}
                onCancel={() => setCancel(false)}
              />
            </Flex>
          </DSSidePanel.Content>
          <DSSidePanel.FooterEmpty />
        </DSSidePanel>
      )}
    </Formik>
  );
};

export default SidePanelFieldForm;
