import React from "react";
import { marked, Renderer } from "marked";
import Loader from "components/Loader";
import DataLayer from "utils/DataLayer";
import Acta from "utils/Acta";
import blankLinks from "constants/blankLinks";

import styles from "./PortalFAQStyles.module.scss";

const renderer = new Renderer();
renderer.link = function (href, title, text) {
  const credentials = Acta.getState("userData");
  const orgUuid = credentials && credentials.activeCompany && credentials.activeCompany.uuid;
  if (orgUuid && blankLinks[orgUuid] && blankLinks[orgUuid].includes(href)) {
    return '<a target="_blank" href="' + href + '" title="' + title + '">' + text + "</a>";
  }
  return '<a href="' + href + '" title="' + title + '">' + text + "</a>";
};

export interface IState {
  FAQData?: string | null;
  dataError?: boolean;
}

export default class PortalFAQ extends React.PureComponent<{}, IState> {
  private initialFAQData: string;
  private timeout: number;

  public state: IState = {};

  public async componentDidMount(): Promise<void> {
    try {
      const data = (await DataLayer.request({
        url: "/v1/portal/faq",
      })) as unknown as string; // TODO type this route;
      // Je laisse comme ça car Thimothé a travaillé sur cette vue et je ne veux pas
      // empiéter.

      this.initialFAQData = data || "";
      this.setState({
        FAQData: data || "",
        dataError: false,
      });
    } catch (error) {
      this.setState({
        dataError: true,
      });
    }
  }

  private onContentChange = (event: React.FormEvent<HTMLDivElement>) => {
    const newValue = event.currentTarget.innerText;

    this.setState({
      FAQData: newValue,
    });

    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
    this.timeout = window.setTimeout(this.save, 1000);
  };

  private save = async (): Promise<void> => {
    try {
      const body = JSON.stringify({ portalFAQ: this.state.FAQData });
      await DataLayer.request({ method: "POST", url: "/v1/portal/faq", body });
      Acta.dispatchEvent("sendAppMessage", {
        message: "Texte sauvegardé.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Échec d’ennregistrement.",
        type: "error",
      });
    }
  };

  public render(): JSX.Element {
    const { FAQData, dataError } = this.state;

    if (dataError) {
      return <div className={styles.errorContainer}>Erreur lors du chargement des données.</div>;
    }

    if (!FAQData) {
      return (
        <div className={styles.errorContainer}>
          <Loader />
        </div>
      );
    }

    return (
      <div className={styles.PortalFAQ}>
        <div>
          <header>Édition</header>
          <div
            contentEditable
            onBlur={this.onContentChange}
            onInput={this.onContentChange}
            dangerouslySetInnerHTML={{ __html: this.initialFAQData }}
          />
        </div>
        <div>
          <header>Prévisualisation</header>
          <div
            dangerouslySetInnerHTML={{ __html: marked.parse(FAQData, { renderer, async: false }) }}
          />
        </div>
      </div>
    );
  }
}
