import { useRef, Suspense } from "react";
import { useParams, useHistory } from "react-router-dom";

import { ReactComponent as EmptySkillEvals } from "assets/skill/EmptySkillEvals.svg";

import { Flex, Text, Loader } from "@skillup/design-system";
import { DSFilters, FilterRef, useMediaQueries } from "@skillup/ui";

import DSLayout from "components/DSLayout";
import useTranslation from "hooks/useTranslation";
import NotAvailableInNarrowView from "components/NotAvailableInNarrowView";

import SearchBar from "../../../components/SearchBar";
import { HeaderSection } from "./SkillAnalytic.styled";
import SkillAnalyticContainer from "./SkillAnalyticContainer";
import { usePerimeterFilter } from "../utils/usePerimeterFilter";
import { HiddenFilterDropdown } from "../utils/PerimeterFilter.styled";
import { SkillsProvider, useSkillsContext } from "../../../../Skills/SkillsContext";

const SkillAnalytic = () => {
  return (
    <SkillsProvider>
      <Suspense fallback={<Loader fillSpace />}>
        <Layout />
      </Suspense>
    </SkillsProvider>
  );
};

const Layout = () => {
  const history = useHistory();
  const filterRef = useRef<FilterRef>();
  const { t } = useTranslation();
  const { isTooNarrow } = useMediaQueries();
  const { uuid } = useParams<{ uuid: string }>();

  const [conf, filters, filterValues, setFilterValues] = usePerimeterFilter(t);
  const { getSkillAnalytics, getSkills } = useSkillsContext();
  const { data: skillData, error, status } = getSkills({ uuids: [uuid] });
  const {
    data: skillsAnalytics,
    error: getAnalyticsError,
    status: getAnalyticsStatus,
  } = getSkillAnalytics(uuid, filterValues);

  const evaluatedEmployees = skillsAnalytics?.evaluatedEmployees ?? 0;

  return (
    <DSLayout
      parent={{
        onClick: () => history.goBack(),
        title: "",
      }}
      title={t("skills.dashboard.layout.name", {
        defaultValue: "Analyse des évaluations de compétences",
      })}
    >
      {isTooNarrow ? (
        <NotAvailableInNarrowView />
      ) : (
        <Flex width="100%" alignItems="center" flexDirection="column">
          <HeaderSection
            width="100%"
            height="100px"
            paddingTop="m"
            paddingBottom="l"
            alignItems="center"
            position="relative"
            justifyContent="center"
            backgroundColor="surface-dark"
          >
            <Flex width="40%" position="relative" flexDirection="column">
              <Text espaceFont="captionBold" color="plainText-onDark-darker">
                {t("skills.dashboard.skillOrJob", { defaultValue: "Fiche de poste, compétence" })}
              </Text>
              <SearchBar data={skillData?.length ? skillData[0] : undefined} />
            </Flex>
          </HeaderSection>

          <Flex
            width="100%"
            paddingTop="m"
            maxWidth="1200px"
            flexDirection="row"
            alignItems="flex-start"
            paddingHorizontal="jumbo"
          >
            <HiddenFilterDropdown>
              <DSFilters
                t={t}
                config={conf}
                ref={filterRef}
                filters={filters}
                onChange={setFilterValues}
              />
            </HiddenFilterDropdown>
          </Flex>

          {evaluatedEmployees === 0 ? (
            <Flex
              width="100%"
              height="100vh"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <EmptySkillEvals />
              <Text>
                {t("skills.dashboard.noSkillsEvaluations", {
                  defaultValue:
                    "Aucun collaborateur de votre périmètre n’a été évalué sur cette compétence.",
                })}
              </Text>
            </Flex>
          ) : (
            <Flex
              width="100%"
              paddingBottom="l"
              maxWidth="1200px"
              justifyContent="center"
              paddingHorizontal="jumbo"
              backgroundColor="surface-light-darker"
            >
              <SkillAnalyticContainer
                skillData={skillData}
                skillsAnalytics={skillsAnalytics}
                error={Boolean(error || getAnalyticsError)}
                loading={status === "loading" || getAnalyticsStatus === "loading"}
              />
            </Flex>
          )}
        </Flex>
      )}
    </DSLayout>
  );
};

export default SkillAnalytic;
