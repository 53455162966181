import styled from "styled-components";

import { Flex, DSColor } from "@skillup/design-system";
import { EspaceFont, SkuiSpacing, DSAvatarGroup } from "@skillup/ui";

const espaceRhFont = `font-family: "Noto Sans Display", "Helvetica Neue", sans-serif`;

export const GaugeContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${SkuiSpacing.l} 0;
  margin-top: ${SkuiSpacing.m};
  flex: 1;
  ${espaceRhFont};
  color: ${DSColor["plainText-onLight-default"]};
`;

export const Gauge = styled(Flex)`
  position: relative;
  width: 100%;
  padding: 3px;
  background: linear-gradient(90deg, #f3fbfc 0%, ${DSColor["decoration-blue-darker"]} 100%);
`;

export const GaugeValue = styled.div<{ position: number }>`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 16px;
  background: ${DSColor["surface-dark-darker"]};
  left: ${({ position }) => `${position}%`};
`;

export const GaugeName = styled(Flex)<{ widthSize: number }>`
  ${EspaceFont.rh.captionRegular};
  font-size: 14px;
  justify-content: center;
  width: ${({ widthSize }) => `${widthSize}%`};
  text-align: center;
`;

export const CollabCircle = styled(Flex)<{ level: number }>`
  cursor: pointer;
  position: absolute;
  top: -25px;
  left: ${({ level }) => `${level}%`};
  transform: translate(-50%, -50%);
  border: 3px solid ${DSColor["decoration-blue-base"]};
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;

export const Angle = styled.span`
  position: absolute;
  bottom: -6px;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  background: ${DSColor["decoration-blue-base"]};
`;

export const AvatarGroupStyled = styled(DSAvatarGroup)`
  border-radius: 24px;
  z-index: 2;
  padding: 0.25rem;
  background: white;
  margin: 0;
  
    > div > div > span {
      margin-left: ${SkuiSpacing.xxs};
    }
  }
`;

export const SidePanelLevelName = styled(Flex)`
  flex-direction: column;
  margin-bottom: ${SkuiSpacing.xs};
  color: ${DSColor["plainText-onLight-lighter"]};
  ${EspaceFont.rh.body2Regular};
`;
