import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Acta from "utils/Acta";

import CancelOrPostponeChooseModal from "./CancelOrPostponeChooseModal";
import CancelModal from "./CancelModal";
import PositioningModal from "./PositioningModal";
import PostponeModal from "./PostponeModal";

export interface IProps {
  rows: Array<string>;
  scheduleUuid: string;
  reload: () => void;
  rowsAreManuallyAdded: boolean;
  rowsManagedBySkillup: boolean;
  type: string;
  hasReceivedNotification: boolean;
}

const CancelOrPostponeModal = ({
  rows,
  reload,
  rowsAreManuallyAdded,
  rowsManagedBySkillup,
  type,
  scheduleUuid,
  hasReceivedNotification,
}: IProps) => {
  const { t } = useTranslation();
  const [option, setOption] = useState(null);

  const handleClose = (needRefresh?: boolean) => {
    Acta.dispatchEvent("closeModal");
    if (needRefresh === true) reload();
  };

  if (option === "cancel") {
    return (
      <CancelModal
        hasReceivedNotification={hasReceivedNotification}
        rowsUuids={rows}
        onBack={() => setOption(null)}
        onClose={handleClose}
      />
    );
  }

  if (option === "postpone") {
    return (
      <PostponeModal
        rows={rows}
        hasReceivedNotification={hasReceivedNotification}
        rowsManagedBySkillup={rowsManagedBySkillup}
        type={type}
        rowsAreManuallyAdded={rowsAreManuallyAdded}
        onDone={() => {
          setOption("positioning");
          reload();
        }}
        onBack={() => setOption(null)}
        onClose={handleClose}
      />
    );
  }

  if (option === "positioning") {
    return (
      <PositioningModal
        items={rows}
        onClose={handleClose}
        scheduleUuid={scheduleUuid}
        headerTitle={t("trainings.schedules.cancelorpostpone.modal.header", {
          defaultValue: "La demande a été reportée, voulez-vous...",
        })}
      />
    );
  }

  return (
    <CancelOrPostponeChooseModal rows={rows} onOptionChange={setOption} onClose={handleClose} />
  );
};

export default CancelOrPostponeModal;
