import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";
import type { ScheduleRoutes } from "types/api";
/**
 * This action is called when the user wants to
 * export the data table as a csv
 *
 * @export
 * @param {string} type
 * @param {string} uuid
 */

export type PlanType = "plan" | "collection" | undefined;

const filenameForType = {
  collection: "Recueil des besoins.xlsx",
  plan: "Mon plan de formation.xlsx",
  undefined: "Recueil des besoins et du plan.xlsx",
};

export interface ExportCSVParams {
  type?: PlanType;
  scheduleUuid: string;
  activeAreas: Array<string>;
}

export default async function exportCSV({ scheduleUuid, type, activeAreas }: ExportCSVParams) {
  const fileName = filenameForType[type];

  await downloadTypedFileAsUser<ScheduleRoutes.GetExcel>(
    {
      method: "GET",
      path: "/schedule/export/{scheduleUuid}/{filename}",
      params: {
        scheduleUuid,
        filename: encodeURI(fileName),
      },
      query: {
        timestamp: new Date().valueOf(),
        type,
        areas: activeAreas && activeAreas.join(","),
      },
    },
    {
      target: "API",
      deduceFileDataFromResponseHeaders: true,
    }
  );
}
