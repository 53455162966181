import React from "react";

import { formatMonetary } from "@skillup/shared-utils";

import useSettings from "hooks/useSettings";
import useTranslation from "hooks/useTranslation";

interface IData {
  name: string;
  data: Array<{ [key: string]: number }>;
}

const Table = ({ data, name }: IData) => {
  const settings = useSettings();
  const { i18n } = useTranslation();
  return (
    <div>
      <h3>{name}</h3>

      <ul>
        {data.map(({ key, value }) => (
          <li key={key}>
            <span>{key}</span>
            <span>
              {formatMonetary(value, {
                locale: i18n.language,
                currency: settings.settings.currency,
              })}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default React.memo(Table);
