import React, { useEffect, useMemo, useState } from "react";

import faker from "faker";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { trpc } from "utils/trpc";

import { Flex, Text } from "@skillup/design-system";
import { DSButton, Loader, DSSortable } from "@skillup/ui";

import { JobLayout } from "./JobTemplateFields.styled";
import useAdminProperties, {
  saveChanges,
} from "containers/Supervisor/routes/PortalManagement/components/AdminPortal/state/useAdminProperties";
import AdminPortalToggle, {
  CompanyAdminPayload,
  PortalToggleType,
} from "containers/Supervisor/routes/PortalManagement/components/AdminPortal/AdminPortalToggle/AdminPortalToggle";
import User from "utils/User";

function JobTemplateFields(): JSX.Element {
  const { t } = useTranslation();
  const history = useHistory();
  const { data: adminData, refetch, loading } = useAdminProperties();
  const userIsAdmin = User.isSkillupAdmin();

  const handleOnChange = async (body: CompanyAdminPayload) => {
    await saveChanges(body);
    await refetch();
  };

  const adminProperties = useMemo(() => {
    return adminData?.properties ?? null;
  }, [adminData]);

  const [items, setItems] = useState([]);
  const { status, data, error } = trpc.jobTemplates.getAll.useQuery();

  useEffect(() => {
    if (data) {
      setItems(
        data.map(({ name: title }) => ({
          title,
          id: faker.datatype.uuid(),
          disabled: true,
        }))
      );
    }
  }, [data]);

  return (
    <JobLayout>
      <Flex>
        <Flex flexDirection="column" marginTop="m">
          <Text espaceFont="body2Bold" color="plainText-onLight-default">
            {t("portal.config.skills.jobTemplateFields.list.title", {
              defaultValue: "Structure des fiches de poste",
            })}
          </Text>
          <Flex flexDirection="column" marginTop="xs">
            {status === "loading" && <Loader />}
            {status === "error" && <div>{error.message}</div>}
            {status === "success" && (
              <Flex flexDirection="column">
                <DSSortable items={items} locked={true} />
              </Flex>
            )}
          </Flex>
          <Flex width="fit-content" marginTop="m">
            <DSButton
              type="button"
              label={t("portal.config.skills.jobTemplateFields.set", {
                defaultValue: "Modifier la structure des fiches de poste",
              })}
              onClick={() => history.push("/responsable/structure-de-fiches-de-poste/set")}
            />
          </Flex>

          {userIsAdmin && (
            <Flex flexDirection="column" marginTop="xxl">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <Text espaceFont="body2Bold" color="plainText-onLight-default">
                    {t("portal.config.skills.jobTemplateFields.feature.exploratorTitle", {
                      defaultValue: "Page métiers dans l’espace collaborateur",
                    })}
                  </Text>
                  <Flex marginTop="xs" flexDirection="column" gap="xs">
                    <AdminPortalToggle
                      propertiesKey="jobsExploratorEnabled"
                      label={t("portal.config.skills.jobTemplateFields.feature.exploratorContent", {
                        defaultValue: "Montrer les collaborateurs associés aux fiches de poste",
                      })}
                      type={PortalToggleType.ENABLED}
                      isSelected={
                        adminProperties ? adminProperties["jobsExploratorEnabled"] : false
                      }
                      onChange={handleOnChange}
                    />
                    <AdminPortalToggle
                      propertiesKey="jobsExploratorProximityJobEnabled"
                      label={t(
                        "portal.config.skills.jobTemplateFields.feature.exploratorProximityJobs",
                        {
                          defaultValue: "Afficher la section des postes en adéquation",
                        }
                      )}
                      type={PortalToggleType.ENABLED}
                      isSelected={
                        adminProperties
                          ? adminProperties["jobsExploratorProximityJobEnabled"]
                          : false
                      }
                      onChange={handleOnChange}
                    />
                  </Flex>
                </>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    </JobLayout>
  );
}

export default JobTemplateFields;
