import React from "react";

import generateContext from "utils/generateContext";

import reducer, { IState, TAction, initialState } from "./reducer";

export interface IContext {
  state: IState;
  dispatch: React.Dispatch<TAction>;
}

const { context: SupervisorUserViewContext, ContextProvider, ContextConsumer } = generateContext<
  IState,
  TAction
>(initialState, reducer);

export { ContextProvider, ContextConsumer, SupervisorUserViewContext };

export default {
  ContextProvider,
  ContextConsumer,
  SupervisorUserViewContext,
};
