import React, { useCallback, useMemo } from "react";
import cx from "classnames";

import { Flex, TextArea, TextInput, TextDropdown, Icon } from "@skillup/ui";
import { UserRoutes, InterviewPersonalisableGuidelines } from "@skillup/espace-rh-bridge";

import { useTypedFetch } from "hooks";
import { InterviewRoles, InterviewTypes } from "types/Interview";

import styles from "./PersonalisableGuidelines.module.scss";

type Guidelines = InterviewPersonalisableGuidelines;
type PersonalisableField = Guidelines[InterviewRoles.EMPLOYEE]["fields"][0];

interface PersonalisableFieldBlockProps {
  readonly className?: string;
  readonly value: PersonalisableField;
  readonly userProperties: Array<{
    label: string;
    value: string;
    fieldType: "date" | "string" | "number" | "monetary" | "boolean";
  }>;
  readonly onChange: (field: PersonalisableField) => void;
  readonly remove: () => void;
}

const PersonalisableFieldBlock = ({
  value,
  onChange,
  remove,
  userProperties,
  className,
}: PersonalisableFieldBlockProps) => {
  const onPropertyChange = ({
    value: newProperty,
    fieldType,
  }: {
    value: string;
    fieldType: "string" | "date" | "number" | "monetary" | "boolean";
  }) => {
    if (value.type === "field") {
      onChange({
        ...value,
        fieldType: fieldType,
        fieldName: newProperty,
      });
    }
  };

  return (
    <Flex className={cx(styles.personalisableFieldBlock, className)}>
      {value.type === "const" && (
        <>
          <TextInput
            className={styles.labelInput}
            value={value.label}
            onChange={(e) => {
              onChange({
                ...value,
                label: e,
              });
            }}
            placeholder="Libellé du champ"
          />
          <TextInput
            className={styles.valueInput}
            value={value.value}
            onChange={(e) => {
              onChange({
                ...value,
                value: e,
              });
            }}
            placeholder="Valeur du champ"
          />
        </>
      )}
      {value.type === "field" && (
        <>
          <TextInput
            className={styles.labelInput}
            value={value.label}
            onChange={(e) => {
              onChange({
                ...value,
                label: e,
              });
            }}
            placeholder="Libellé du champ"
          />
          <TextDropdown
            placeholder="Champ de User"
            className={styles.dropDownType}
            options={userProperties}
            selectedIndex={userProperties.findIndex((e) => e.value === value.fieldName)}
            onSelect={onPropertyChange}
            activeItemContainerClass={styles.activeItemContainer}
            small
          />
        </>
      )}

      <div className={styles.delete} aria-label="Supprimer ligne">
        <Icon.Trash
          size={20}
          color="#92929D"
          onClick={() => {
            const confirmation = window.confirm("Êtes-vous sûr ?");
            if (confirmation) remove();
          }}
        />
      </div>
    </Flex>
  );
};

export interface PersonalisableGuidelinesProps {
  data: Guidelines;
  updateChild: (newChildData: InterviewPersonalisableGuidelines) => void;
}

const PersonalisableGuidelines = ({ data, updateChild }: PersonalisableGuidelinesProps) => {
  const { data: userFetchedProperties = [] } = useTypedFetch<UserRoutes.GetUserProperties>({
    method: "GET",
    path: "/user/properties",
  });

  const userProperties = useMemo(() => {
    return userFetchedProperties.map(({ label, field, fieldType }) => ({
      label,
      value: field,
      fieldType,
    }));
  }, [userFetchedProperties]);

  const changeTitle = useCallback(
    (field: InterviewTypes.ActiveRoles) => {
      return (title: string) => {
        updateChild({
          ...data,
          [field]: {
            ...data[field],
            title,
          },
        });
      };
    },
    [data, updateChild]
  );

  const changeElement = useCallback(
    (field: InterviewTypes.ActiveRoles, index: number) => {
      return (value: PersonalisableField) => {
        updateChild({
          ...data,
          [field]: {
            ...data[field],
            fields: data[field].fields.map((e, i) => (index === i ? value : e)),
          },
        });
      };
    },
    [data, updateChild]
  );

  const addElement = useCallback(
    (field: InterviewTypes.ActiveRoles, fieldType: "const" | "field") => {
      updateChild({
        ...data,
        [field]: {
          ...data[field],
          fields: [
            ...data[field].fields,
            ...(fieldType === "const"
              ? [
                  {
                    type: "const",
                    label: "",
                    value: "",
                  },
                ]
              : []),
            ...(fieldType === "field"
              ? [
                  {
                    type: "field",
                    fieldName: "",
                    label: "",
                    fieldType: "string" as const,
                  },
                ]
              : []),
          ],
        },
      });
    },
    [data, updateChild]
  );

  const removeElement = useCallback(
    (field: InterviewTypes.ActiveRoles, index: number) => {
      return () => {
        updateChild({
          ...data,
          [field]: {
            ...data[field],
            fields: data[field].fields.reduce(
              (acc, curr, i) => [...acc, ...(index === i ? [] : [curr])],
              []
            ),
          },
        });
      };
    },
    [data, updateChild]
  );

  const subSectionTitle = (field: InterviewTypes.ActiveRoles) => (
    <Flex className={styles.sectionTitleWrapper}>
      <span className={styles.subSectionWrapper}>
        {field === InterviewRoles.MANAGER
          ? "Données sur le responsable de l'entretien"
          : "Données sur le collaborateur"}
      </span>
    </Flex>
  );

  const changeTitleLine = (field: InterviewTypes.ActiveRoles) => (
    <Flex className={styles.informationBlockWrapper}>
      <span className={styles.informationBlockLabel}> Libellé du bloc d'Informations </span>
      <TextInput
        className={styles.informationBlockInput}
        value={data[field].title}
        onChange={changeTitle(field)}
      />
    </Flex>
  );

  const addLineButtons = (field: InterviewTypes.ActiveRoles) => (
    <Flex className={styles.addButtons}>
      <span className={styles.add} onClick={() => addElement(field, "field")}>
        Ajouter une donnée
      </span>
      <span className={styles.add} onClick={() => addElement(field, "const")}>
        Ajouter une constante
      </span>
    </Flex>
  );

  return (
    <form>
      <span>Description</span>
      <TextArea
        key={`description-${data.uuid}`}
        value={data.description}
        onChange={(description) => updateChild({ ...data, description })}
      />
      {data.description?.length > 1500 && (
        <Flex className={styles.warning}>
          /!\ La description compte plus de 1500 caractères : les informations destinées à la page
          de garde risquent de déborder sur la seconde page.
        </Flex>
      )}
      <Flex className={styles.employeeList} column>
        <Flex className={styles.sectionTitleWrapper}>
          <span className={styles.sectionTitle}> Informations sur le collaborateur </span>
        </Flex>

        {changeTitleLine(InterviewRoles.EMPLOYEE)}

        {subSectionTitle(InterviewRoles.EMPLOYEE)}

        {data.employee.fields.map((eField, index) => (
          <PersonalisableFieldBlock
            className={styles.listElement}
            key={index}
            value={eField}
            userProperties={userProperties}
            onChange={changeElement(InterviewRoles.EMPLOYEE, index)}
            remove={removeElement(InterviewRoles.EMPLOYEE, index)}
          />
        ))}

        {addLineButtons(InterviewRoles.EMPLOYEE)}
      </Flex>
      <Flex className={styles.managerList} column>
        <Flex className={styles.sectionTitleWrapper}>
          <span className={styles.sectionTitle}> Informations sur le responsable </span>
        </Flex>

        {changeTitleLine(InterviewRoles.MANAGER)}

        {subSectionTitle(InterviewRoles.MANAGER)}

        {data.manager.fields.map((eField, index) => (
          <PersonalisableFieldBlock
            className={styles.listElement}
            key={index}
            value={eField}
            userProperties={userProperties}
            onChange={changeElement(InterviewRoles.MANAGER, index)}
            remove={removeElement(InterviewRoles.MANAGER, index)}
          />
        ))}

        {addLineButtons(InterviewRoles.MANAGER)}
      </Flex>
    </form>
  );
};
export default PersonalisableGuidelines;
