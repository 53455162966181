import React from "react";

import ClassicCampaignForm from "./ClassicCampaignForm";
import OngoingCampaignForm from "./OngoingCampaignForm";
import styles from "./Steps.module.scss";
import { DSCard } from "@skillup/ui";

type TCampaignType = "ongoing" | "legacy";

interface IProps {
  readonly start?: number;
  readonly setStart: (start?: number) => void;
  readonly duration?: number;
  readonly setDuration: (duration?: number) => void;
  readonly type: TCampaignType;
  readonly ongoingStartDate?: number;
}

const DatesStep = (props: IProps) => {
  const { type } = props;

  return (
    <div className={styles.CardWrapper}>
      <DSCard className={styles.DatesStep} showTitle={false}>
        {type === "legacy" && <ClassicCampaignForm {...props} />}
        {type === "ongoing" && <OngoingCampaignForm {...props} />}
      </DSCard>
    </div>
  );
};

export default DatesStep;
