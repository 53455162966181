import { Fragment } from "react";
import { useSetState } from "react-use";
import { useToasts } from "react-toast-notifications";

import moment from "moment";
import useTranslation from "hooks/useTranslation";

import { DSAlert, DSAlertDisplay, DSAlertType, DSButton, Modal, Switch } from "@skillup/ui";

import CreateLabel from "components/CreateLabel";

import { IEditionState } from "./ModifyCampaignModal";

import { submitModification } from "./utils";
import { Campaign } from "./useCampaignModals";

import styles from "./NotificationCampaignModal.module.scss";

interface IProps {
  readonly campaign: Campaign;
  readonly warning: boolean;
  readonly resync: () => void;
  readonly onClose: () => void;
}
export default ({ campaign, warning, resync, onClose }: IProps) => {
  const notificationsMapping = {
    notificationStart: "Lancement de campagne",
    notificationCreated: "Création d'un entretien",
    notificationManagerChange: "Changement de manager",
    notificationShared: "Partage de la préparation",
    notificationSigned: "Entretien prêt à être signé",
    notificationReopen: "Réouverture d’un entretien",
  };

  const remindersMapping = {
    reminderMinus14: "14 jours avant la date de fin",
    reminderMinus7: "7 jours avant la date de fin",
    reminderMinus1: "La veille de la date de fin",
    reminderPlus1: "Le lendemain de la date de fin",
  };

  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [state, setState] = useSetState<IEditionState>({
    isUpdating: false,
    startDate: campaign.startDate ? moment(campaign.startDate).startOf("day").valueOf() : undefined,
    duration: campaign.duration,
    frequency: campaign.frequency,
    titleForHR: campaign.titleForHR,
    titleForAttendees: campaign.titleForAttendees,
    mailingSettings: campaign.mailingSettings,
    errors: [],
    confidential: undefined,
  });

  const { mailingSettings } = state;

  const isAllDisabled = (type: string) =>
    Object.keys(mailingSettings)
      .filter((key) => key.includes(type))
      .every((key) => {
        return mailingSettings[key] === false;
      });

  const updateOne = (key: string, checked: boolean): void => {
    setState({ mailingSettings: { ...state.mailingSettings, [key]: checked } });
  };

  const updateAll = (type: string) => {
    let newMailingSettings = Object.assign({}, state.mailingSettings);
    Object.keys(newMailingSettings).forEach(function (key) {
      if (key.includes(type)) {
        newMailingSettings[key] = isAllDisabled(type);
      }
    });
    setState({ mailingSettings: newMailingSettings });
  };

  return (
    campaign && (
      <Modal title="Paramètres notifications" className={styles.modal}>
        <div className={styles.content}>
          <div className={styles.toggles}>
            <section>
              <CreateLabel
                className={styles.label}
                label="Notifications par mail par événement :"
              />
              <div className={styles.notificationsWrapper}>
                {Object.keys(notificationsMapping).map((key) => {
                  const label = notificationsMapping[key];
                  return (
                    <Fragment key={`toggle-${key}`}>
                      <div className={styles.notificationsToggleWrapper}>
                        <Switch
                          active={mailingSettings[key]}
                          onToggle={(checked) => updateOne(key, checked)}
                          label={label}
                        />
                      </div>
                    </Fragment>
                  );
                })}
                <DSButton
                  label={isAllDisabled("notification") ? "Tout activer" : "Tout désactiver"}
                  className={styles.toggleButton}
                  onClick={() => {
                    updateAll("notification");
                  }}
                  emphasis="Low"
                  buttonSize="S"
                />
              </div>
            </section>
            <section>
              <CreateLabel className={styles.label} label="Relances en cas de non-signature :" />
              <div className={styles.notificationsWrapper}>
                {Object.keys(remindersMapping).map((key) => {
                  const label = remindersMapping[key];
                  return (
                    <div key={`toggle-${key}`} className={styles.notificationsToggleWrapper}>
                      <Switch
                        active={mailingSettings[key]}
                        onToggle={(checked) => updateOne(key, checked)}
                        label={label}
                      />
                    </div>
                  );
                })}

                <DSButton
                  label={isAllDisabled("reminder") ? "Tout activer" : "Tout désactiver"}
                  className={styles.toggleButton}
                  onClick={() => {
                    updateAll("reminder");
                  }}
                  emphasis="Low"
                  buttonSize="S"
                />
              </div>
            </section>
          </div>

          {warning && (
            <div className={styles.alert}>
              <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
                Les modifications apportées ne sont pas rétroactives : il est possible que certaines
                notifications aient déjà été envoyées.
              </DSAlert>
            </div>
          )}

          <div className={styles.footer}>
            <DSButton className={styles.button} emphasis="Low" label="Annuler" onClick={onClose} />
            <DSButton
              className={styles.button}
              emphasis="High"
              label="Enregistrer les modifications"
              onClick={() =>
                submitModification({
                  campaign,
                  state,
                  setState,
                  addToast,
                  onClose,
                  resync,
                  t,
                })
              }
            />
          </div>
        </div>
      </Modal>
    )
  );
};
