import React, { useEffect, useState } from "react";
import { IRoutes } from "@skillup/types";

import Icon from "components/Icon";
import DataLayer from "utils/DataLayer";
import { chevronRight as chevronRightIcon } from "uiAssets/StrokeIcons";

import { ITraining } from "../Actions/getTrainings";
import styles from "./CompareBlockStyles.module.scss";

export interface IProps {
  addOnly?: boolean;
  rows: Array<string>;
  selection?: ITraining;
}

type OldData = IRoutes["/requalification"]["GET"]["/basic-training-data/"]["response"];

const setOldData = async (row: string, setter) => {
  const oldData = (await DataLayer.request({
    url: `/v1/requalification/basic-training-data/${row}`,
  })) as OldData;
  setter(oldData);
};

const CompareBlock = ({ rows, selection, addOnly }: IProps) => {
  const [oldTraining, setOldTraining] = useState<OldData | null>(null);

  useEffect(() => {
    if (!rows) return;

    if (rows.length !== 1) {
      setOldTraining(null);
    } else {
      setOldData(rows[0], setOldTraining);
    }
  }, [rows, selection]);

  return (
    <div className={styles.CompareBlock}>
      {!addOnly && (
        <div className={styles.info}>
          <h5>Ancienne formation</h5>
          {oldTraining && (
            <>
              <h3>{oldTraining.name}</h3>
              <h4>
                {oldTraining.organization} - {oldTraining.price} - {oldTraining.duration}
              </h4>
            </>
          )}
          {!oldTraining && rows.length > 1 && (
            <>
              <h3>{rows.length} lignes sélectionnées</h3>
              <h4>&nbsp;</h4>
            </>
          )}
        </div>
      )}
      {!addOnly && <Icon strokeIcon={chevronRightIcon} width={20} stroke="#888" />}
      <div className={styles.info}>
        {addOnly && <h5>Formation sélectionée</h5>}
        {!addOnly && <h5>Nouvelle formation</h5>}
        {selection && (
          <>
            <h3>{selection ? selection.name : <></>}</h3>
            <h4>
              {selection ? selection.organization : ""} - {selection ? selection.price : " "} -{" "}
              {selection ? selection.duration : " "}
            </h4>
          </>
        )}
        {!selection && (
          <>
            <h3>Veuillez sélectionner une formation</h3>
            <h4>&nbsp;</h4>
          </>
        )}
      </div>
    </div>
  );
};

export default CompareBlock;
