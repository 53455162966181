import { ModulesAccessList } from "@skillup/espace-rh-bridge";

import { ModuleType } from "hooks/useSettings";
import User from "utils/User";

const Skills = (hasAccess: { to: (module: ModuleType) => boolean }) => {
  const hasSkillsModuleEnabled = (moduleAccess: ModulesAccessList) =>
    User.isSkillupDeveloper() || (User.hasSkillsEnabled() && hasAccess.to(moduleAccess));

  const toSkillsJobs = () => hasSkillsModuleEnabled(ModulesAccessList.SkillsJobs);
  const toSkillsRequired = () => hasSkillsModuleEnabled(ModulesAccessList.SkillsRequired);
  const toSkillsEmployees = () => hasSkillsModuleEnabled(ModulesAccessList.SkillsEmployees);

  const toSkills = () => toSkillsJobs() || toSkillsRequired() || toSkillsEmployees();

  return {
    toSkills,
    toSkillsJobs,
    toSkillsRequired,
    toSkillsEmployees,
  };
};

export default Skills;
