import { type Themes } from "index";

export type SelectOption<T> = {
  label: string;
  selectedLabel?: string;
  value: T;
  isDisabled?: boolean;
};
export type SelectOptionGroup<T> = {
  label: string;
  options: SelectOption<T>[];
};
export enum MenuPlacement {
  AUTO = "auto",
  BOTTOM = "bottom",
  TOP = "top",
}
export type SelectOptions<T> = (SelectOption<T> | SelectOptionGroup<T>)[];
export type SelectOptionEqualityFn<T> = (a: T, b: T) => boolean;
export type SelectPropsWithoutTheme<T> = {
  readonly "aria-label"?: string;
  readonly className?: string;
  readonly options?: SelectOptions<T>;
  readonly disabled?: boolean;
  readonly clearable?: boolean;
  readonly isSearchable?: boolean;
  readonly createIfInexistent?: boolean;
  readonly createLabel?: string;
  readonly placeholder?: string;
  readonly error?: boolean;
  readonly menuPlacement?: MenuPlacement;
  readonly minMenuHeight?: number;
  readonly maxMenuHeight?: number;
  readonly noOptionsMessage?: string;
  readonly selectPlaceholder?: string;
  readonly missingItemsMessage?: string;
  readonly autoFocus?: boolean;
  readonly paginationCustomStyle?: {
    width?: React.CSSProperties["width"];
    height?: React.CSSProperties["height"];
    paddingTop?: React.CSSProperties["paddingTop"];
    paddingBottom?: React.CSSProperties["paddingBottom"];
    paddingLeft?: React.CSSProperties["paddingLeft"];
    paddingRight?: React.CSSProperties["paddingRight"];
    flexWrap?: React.CSSProperties["flexWrap"];
  };
  readonly darkMode?: boolean;
  readonly equalFn?: SelectOptionEqualityFn<T>;
  readonly onInputChange?: (search: string) => void;
} & (
  | {
      readonly multi?: false;
      readonly canSelectAll?: boolean;
      readonly value?: T;
      readonly defaultValue?: SelectOption<T>;
      readonly onChange?: (option: T | undefined) => void;
    }
  | {
      readonly multi: true;
      readonly canSelectAll?: boolean;
      readonly selectAllLabel?: string;
      readonly value?: T[];
      readonly defaultValue?: SelectOption<T>[];
      readonly maxShownValues?: number;
      readonly extraValuesLabel?: (count: number) => React.ReactNode;
      readonly allValuesLabel?: string;
      readonly onChange?: (options: T[] | undefined) => void;
    }
);
export type SelectProps<T> = SelectPropsWithoutTheme<T> & {
  readonly theme: Themes;
};
