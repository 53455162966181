import { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { DSButton, Modal } from "@skillup/ui";

import type { UserRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";

import { UserModalProps } from "../types";
import styles from "../ConnectionModes.module.scss";

const RevokeUsersModal = ({ users, onSubmit, onClose }: UserModalProps) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();

  async function handleSubmit() {
    setLoading(true);
    try {
      await buildRequest<UserRoutes.PatchAll>({
        method: "POST",
        path: `/users`,
        payload: {
          users: users.map((user) => ({
            uuid: user.properties.uuid,
            properties: {
              disabled: true,
              deletedAt: new Date().getTime(),
            },
          })),
        },
      })();
      addToast("Utilisateur révoqué", { appearance: "success" });
      onSubmit();
    } catch (e) {
      setLoading(false);
      addToast("Une erreur est survenue", { appearance: "error" });
    }
  }

  return (
    <Modal className={styles.UserActionModal} title="Révoquer l'accès à Skillup" onClose={onClose}>
      <div className={styles.modalContent}>
        {users.length === 1 ? (
          <>
            Révoquer l'accès à Skillup de l'utilisateur {users[0].properties.fullName} ?
            <ul>
              <li>Il n'apparaîtra plus dans les listes de collaborateurs.</li>
              <li>Il ne pourra plus se connecter à la plateforme</li>
            </ul>
          </>
        ) : (
          <>
            Si vous révoquez l’accès de ces utilisateurs à Skillup :
            <ul>
              <li>Ils n’apparaîtront plus dans les listes de collaborateurs</li>
              <li>Ils ne pourront plus se connecter à la plateforme</li>
            </ul>
          </>
        )}
      </div>
      <div className={styles.modalButtons}>
        <DSButton emphasis="Mid" label={"Annuler"} loading={loading} onClick={onClose} />
        <DSButton label={"Révoquer l'accès"} loading={loading} onClick={handleSubmit} />
      </div>
    </Modal>
  );
};

export default RevokeUsersModal;
