import React, { useEffect, useMemo } from "react";
import cx from "classnames";
import Select from "react-select";
import { isNil } from "lodash";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

import { TextInput } from "@skillup/ui";

import { useTypedFetch } from "hooks";
import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import InteractiveButton from "components/InteractiveButton";
import Toggle from "components/Toggle";

import styles from "./AddFieldModal.module.scss";

import type { FieldRoutesType } from "@skillup/espace-rh-bridge";

export interface IField {
  binding: string;
  type: "number" | "text" | "select" | "monetary" | "textarea";
  defaultValue?: string;
  label: string;
  uuid: string;
  massEditAvailable: boolean;
  isHidden: boolean;
  options?: { key: string; value: string; disabled?: boolean }[];
  hasRows?: boolean;
  deletedAt?: number;
  restrictions?: (
    | "label"
    | "binding"
    | "options"
    | "addOption"
    | "defaultValue"
    | "collectable"
    | "type"
    | "editable_rf"
    | "massEditAvailable"
    | "isHidden"
  )[];
  source?: string;
  isStandard: boolean;
}

const typeOptions = [
  { label: "Text", value: "text" },
  { label: "TextArea", value: "textarea" },
  { label: "Nombre", value: "number" },
  { label: "Prix", value: "monetary" },
  { label: "Select", value: "select" },
] as const;

const formatOption = (option: { key: string; value: string }) => {
  if (option)
    return {
      value: option.key,
      label: option.value,
    };
  return undefined;
};

const CustomFieldPanel = ({
  fieldData,
  setFieldData,
}: {
  modifiable: boolean;
  fieldData: IField;
  setFieldData: (newFieldData: IField) => void;
}) => {
  const optionsForSelect = (fieldData.options || []).map(formatOption);
  const defaultOption = optionsForSelect.find((option) => option.value === fieldData.defaultValue);

  const [option, setOption] = React.useState<string>("");

  const { restrictions = [], hasRows } = fieldData;

  const createLabel = (value?: boolean) => {
    return (value && "Oui") || "Non";
  };

  const editableRfMemo = useMemo(() => {
    return restrictions.includes("editable_rf");
  }, [restrictions]);

  const massEditAvailableMemo = useMemo(() => {
    return fieldData.massEditAvailable;
  }, [fieldData]);

  const isHiddenMemo = useMemo(() => {
    return fieldData.isHidden;
  }, [fieldData]);

  return (
    <form className={styles.settings}>
      <div>
        <label>Label</label>
        {restrictions.includes("label") && <p>{fieldData.label}</p>}
        {!restrictions.includes("label") && (
          <TextInput
            key={fieldData.uuid}
            value={fieldData.label}
            onChange={(newLabel) => {
              setFieldData({
                ...fieldData,
                label: newLabel,
              });
            }}
          />
        )}
      </div>

      <div>
        <label>Binding</label>
        {hasRows || restrictions.includes("binding") ? (
          <p>{fieldData.binding}</p>
        ) : (
          <TextInput
            value={fieldData.binding}
            onChange={(newBinding) => {
              setFieldData({
                ...fieldData,
                binding: newBinding,
              });
            }}
          />
        )}
      </div>

      <div>
        <label>Type</label>
        {hasRows || restrictions.includes("type") ? (
          <p>{fieldData.type}</p>
        ) : (
          <Select
            options={typeOptions}
            value={typeOptions.find((option) => option.value === fieldData.type)}
            onChange={(newType) => {
              setFieldData({
                ...fieldData,
                type: newType.value,
                defaultValue: undefined,
                options: newType.value === "select" ? fieldData.options || [] : undefined,
              });
            }}
          />
        )}
      </div>

      <div className={cx({ [styles.toggleWrapper]: !fieldData.isStandard })}>
        <label>Edition de Masse</label>
        {fieldData.isStandard ? (
          <p>{createLabel(massEditAvailableMemo)}</p>
        ) : (
          <Toggle
            item="massEditAvailable"
            isSelected={fieldData.massEditAvailable}
            onChange={(_, massEditAvailable) => {
              setFieldData({
                ...fieldData,
                massEditAvailable,
              });
            }}
          />
        )}
      </div>

      <div className={cx({ [styles.toggleWrapper]: !fieldData.isStandard })}>
        <label>Les RF peuvent modifier ce champ</label>
        {fieldData.isStandard ? (
          <p>{createLabel(!editableRfMemo)}</p>
        ) : (
          <Toggle
            item="editable_rf"
            isSelected={!editableRfMemo}
            onChange={(_, editableRf) => {
              setFieldData({
                ...fieldData,
                restrictions: [
                  ...(fieldData.restrictions.filter((e) => e !== "editable_rf") ?? []),
                  ...(!editableRf ? ["editable_rf" as const] : []),
                ],
              });
            }}
          />
        )}
      </div>

      <div className={cx({ [styles.toggleWrapper]: !fieldData.isStandard })}>
        <label>Cacher ce champ (apparaitra toujours dans les exports)</label>
        {fieldData.isStandard ? (
          <p>{createLabel(isHiddenMemo)}</p>
        ) : (
          <Toggle
            item="isHidden"
            isSelected={fieldData.isHidden}
            onChange={(_, isHidden) => {
              setFieldData({
                ...fieldData,
                isHidden,
              });
            }}
          />
        )}
      </div>

      {fieldData.type === "select" && (
        <div>
          <label>Options</label>
          <ul className={styles.options}>
            {fieldData.options.map((option) => {
              if (!restrictions.includes("options")) {
                return (
                  <li key={`${fieldData.binding}-${option.key}`} className={styles.option}>
                    <TextInput
                      readOnly={option.disabled || hasRows}
                      value={option.value}
                      onChange={(newValue) => {
                        const newOptions = [...fieldData.options];
                        const index = newOptions.map((o) => o.key).indexOf(option.key);
                        newOptions[index].value = newValue;

                        setFieldData({ ...fieldData, options: newOptions });
                      }}
                    />
                    <div
                      className={styles.toggle}
                      onClick={() => {
                        const newOptions = [...fieldData.options];
                        const index = newOptions.map((o) => o.key).indexOf(option.key);
                        newOptions[index].disabled = !newOptions[index].disabled;

                        setFieldData({ ...fieldData, options: newOptions });
                      }}
                    >
                      {option.disabled ? <BsToggleOff size={35} /> : <BsToggleOn size={35} />}
                    </div>
                  </li>
                );
              }

              return (
                <li>
                  <p>{option.value}</p>
                </li>
              );
            })}
          </ul>

          {!restrictions.includes("addOption") && (
            <div className={styles.addOption}>
              <label htmlFor="addOption">Ajouter une option</label>
              <div>
                <TextInput
                  value={option}
                  onChange={(newOption) => {
                    // TODO check for unicity
                    setOption(newOption);
                  }}
                />
                <InteractiveButton
                  label="Ajouter"
                  size="small"
                  onClick={(e) => {
                    setFieldData({
                      ...fieldData,
                      options: [
                        ...fieldData.options,
                        { key: `${fieldData.options?.length ?? 0}`, value: option },
                      ],
                    });
                    setOption("");
                  }}
                />
              </div>
            </div>
          )}
          {!restrictions.includes("defaultValue") && !hasRows && (
            <>
              <label>Option par défaut (optionnel)</label>
              <Select
                options={optionsForSelect}
                value={defaultOption}
                onChange={(newOption) => {
                  setFieldData({
                    ...fieldData,
                    defaultValue: newOption.value,
                  });
                }}
              />
            </>
          )}
          {restrictions.includes("defaultValue") && fieldData.defaultValue && (
            <>
              <label>Option par défaut</label>
              <p>{defaultOption.label}</p>
            </>
          )}
        </div>
      )}

      {fieldData.type !== "select" && (
        <div>
          {!restrictions.includes("defaultValue") && !hasRows && (
            <>
              <label>Valeur par défaut (optionnel)</label>
              <TextInput
                value={fieldData.defaultValue}
                onChange={(newDefaultValue) => {
                  setFieldData({
                    ...fieldData,
                    defaultValue: newDefaultValue,
                  });
                }}
              />
            </>
          )}
          {restrictions.includes("defaultValue") && fieldData.defaultValue && (
            <>
              <label>Valeur par défaut</label>
              <p>Valeur par défaut: {fieldData.defaultValue}</p>
            </>
          )}
        </div>
      )}

      <div>
        <label>Champ standard</label>
        <p>{fieldData.isStandard ? "Oui" : "Non"}</p>
      </div>

      <div>
        <label>Restrictions</label>
        <p>{fieldData.restrictions?.join(", ") ?? "N/A"}</p>
      </div>

      <div>
        <label>Source</label>
        <p>{fieldData.source ?? "N/A"}</p>
      </div>
    </form>
  );
};

const ChoicePanel = ({ setFieldData, currentFields }) => {
  const { data: standardFields = [] } = useTypedFetch<FieldRoutesType.GetStandard>({
    method: "GET",
    path: "/fields/standard",
  });

  const currentFieldsBindings = currentFields.map((f) => f.binding);
  const options = standardFields
    .filter((f) => !currentFieldsBindings.includes(f.binding))
    .map((f) => ({
      label: f.label,
      value: f.binding,
    }));

  return (
    <form className={styles.choice}>
      <p>Choisissez un champ standard à relier</p>
      <Select
        options={options}
        onChange={(selectedField) => {
          setFieldData(standardFields.find((field) => field.binding === selectedField.value));
        }}
      />
      <InteractiveButton
        label="Créer un champ personnalisé"
        size="small"
        onClick={() => {
          setFieldData({
            binding: "",
            label: "",
            type: "text",
            massEditAvailable: false, // by default.
            isHidden: false,
          });
        }}
        style={{ marginTop: 20 }}
      />
    </form>
  );
};

interface AddFieldModalProps {
  onClose: (fieldUuid?: string) => void;
  currentFields: IField[];
  scheduleUuid?: string;
  mode: "creation" | "edition";
  field?: IField;
  modifiable?: boolean;
}

enum LoadingStateEnum {
  LOADING,
  IDLE,
}

const AddFieldModal = ({ mode, onClose, currentFields, modifiable, field }: AddFieldModalProps) => {
  const [fieldData, setFieldData] = React.useState<IField | undefined>(field);
  const [LoadingState, setLoadingState] = React.useState(LoadingStateEnum.IDLE);

  // update on prop change
  useEffect(() => {
    if (field?.uuid !== fieldData?.uuid || field?.deletedAt !== fieldData?.deletedAt) {
      setFieldData(field);
    }
  }, [field, fieldData]);

  const submit = async (field: IField) => {
    setLoadingState(LoadingStateEnum.LOADING);
    try {
      if (mode === "creation") {
        const creationRequest = buildRequest<FieldRoutesType.Create>({
          method: "POST",
          path: "/fields",
          payload: field,
        });
        const { fieldUuid } = await creationRequest();
        await onClose(fieldUuid);
        Acta.dispatchEvent("sendAppMessage", {
          message: "Champ créé avec succès",
          type: "success",
        });
      } else if (mode === "edition") {
        const updateRequest = buildRequest<FieldRoutesType.Update>({
          method: "POST",
          path: "/fields/{fieldUuid}",
          params: { fieldUuid: field.uuid },
          payload: field,
        });
        await updateRequest();
        await onClose();
        Acta.dispatchEvent("sendAppMessage", {
          message: "Modifications enregistrées",
          type: "success",
        });
      }
    } catch (err) {
      console.error(err);
      Acta.dispatchEvent("sendAppMessage", {
        message: `Une erreur est survenue lors de ${
          mode === "creation" ? "la création" : "l'édition"
        }.`,
        type: "error",
      });
    }
    setLoadingState(LoadingStateEnum.IDLE);
  };

  const deleteField = async (field: IField) => {
    setLoadingState(LoadingStateEnum.LOADING);
    try {
      const deletionRequest = buildRequest<FieldRoutesType.Delete>({
        method: "DELETE",
        path: "/fields/{fieldUuid}",
        params: { fieldUuid: field.uuid },
      });
      await deletionRequest();
      await onClose();
      Acta.dispatchEvent("sendAppMessage", {
        message: "Champ supprimé avec succès",
        type: "success",
      });
    } catch (err) {
      console.error(err);
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de la suppression.",
        type: "error",
      });
    }
    setLoadingState(LoadingStateEnum.IDLE);
  };

  const restoreField = async (field: IField) => {
    setLoadingState(LoadingStateEnum.LOADING);
    try {
      const restoreRequest = buildRequest<FieldRoutesType.Restore>({
        method: "POST",
        path: "/fields/{fieldUuid}/restore",
        params: { fieldUuid: field.uuid },
      });
      const { fieldUuid } = await restoreRequest();

      await onClose(fieldUuid);

      Acta.dispatchEvent("sendAppMessage", {
        message: "Champ restauré avec succès",
        type: "success",
      });
    } catch (err) {
      console.error(err);
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de la restauration.",
        type: "error",
      });
    }
    setLoadingState(LoadingStateEnum.IDLE);
  };

  return (
    <div className={styles.addFieldModal}>
      <h3>
        {mode === "creation" ? "Ajout" : "Edition"}
        {!isNil(fieldData) && isNil(fieldData.deletedAt) && (
          <>
            {!fieldData.isStandard && mode !== "creation" && (
              <InteractiveButton
                label="Supprimer"
                onClick={() => deleteField(fieldData)}
                size="small"
                style={{ position: "absolute", right: 110, top: 5 }}
                loading={LoadingState === LoadingStateEnum.LOADING}
              />
            )}
            <InteractiveButton
              label="Enregistrer"
              onClick={() => submit(fieldData)}
              size="small"
              style={{ position: "absolute", right: 10, top: 5 }}
              loading={LoadingState === LoadingStateEnum.LOADING}
            />
          </>
        )}
      </h3>
      {!isNil(fieldData) && !isNil(fieldData.deletedAt) && (
        <div className={styles.hidden}>
          <InteractiveButton
            label="Restaurer"
            onClick={() => restoreField(fieldData)}
            size="small"
            loading={LoadingState === LoadingStateEnum.LOADING}
          />
        </div>
      )}
      {!isNil(fieldData) && isNil(fieldData.deletedAt) && (
        <CustomFieldPanel
          modifiable={modifiable}
          fieldData={fieldData}
          setFieldData={setFieldData}
        />
      )}
      {isNil(fieldData) && (
        <ChoicePanel setFieldData={setFieldData} currentFields={currentFields} />
      )}
    </div>
  );
};

export default AddFieldModal;
