import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    style={{
      fill: "none",
      stroke: "#333333",
      strokeWidth: 3,
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 10,
    }}
  >
    <circle cx="183" cy="140" r="5" />
    <circle cx="116" cy="12" r="5" />
    <circle style={{ stroke: "#288BFF" }} cx="49" cy="175" r="5" />
    <line style={{ stroke: "#288BFF" }} x1="6" y1="13" x2="13" y2="13" />
    <line style={{ stroke: "#288BFF" }} x1="18" y1="13" x2="41" y2="13" />
    <line style={{ stroke: "#288BFF" }} x1="6" y1="21" x2="20" y2="21" />
    <line style={{ stroke: "#288BFF" }} x1="26" y1="21" x2="32" y2="21" />
    <line style={{ stroke: "#288BFF" }} x1="196" y1="181" x2="189" y2="181" />
    <line style={{ stroke: "#288BFF" }} x1="184" y1="181" x2="161" y2="181" />
    <line style={{ stroke: "#288BFF" }} x1="196" y1="173" x2="182" y2="173" />
    <line style={{ stroke: "#288BFF" }} x1="176" y1="173" x2="170" y2="173" />
    <line x1="172" y1="16" x2="176" y2="20" />
    <line x1="180" y1="24" x2="184" y2="28" />
    <line x1="172" y1="28" x2="176" y2="24" />
    <line x1="180" y1="20" x2="184" y2="16" />
    <line x1="121" y1="181" x2="125" y2="185" />
    <line x1="129" y1="189" x2="133" y2="193" />
    <line x1="121" y1="193" x2="125" y2="189" />
    <line x1="129" y1="185" x2="133" y2="181" />
    <line x1="2" y1="171" x2="6" y2="175" />
    <line x1="10" y1="179" x2="14" y2="183" />
    <line x1="2" y1="183" x2="6" y2="179" />
    <line x1="10" y1="175" x2="14" y2="171" />
    <line x1="15" y1="111" x2="15" y2="128" />
    <line x1="15" y1="133" x2="15" y2="139" />
    <line x1="194" y1="47" x2="194" y2="64" />
    <line x1="194" y1="69" x2="194" y2="75" />
    <polygon points="117,124 183,87 84,31 18,68 " />
    <polygon points="82,123 16,86 115,30 181,67 " />
    <polyline points="154,103 154,144 99,175 43,143 43,101 " />
    <line x1="99" y1="114" x2="99" y2="175" />
    <line x1="99" y1="108" x2="99" y2="114" />
    <line x1="99" y1="40" x2="99" y2="47" />
    <line x1="99" y1="54" x2="99" y2="60" />
    <line x1="99" y1="68" x2="99" y2="74" />
    <line x1="99" y1="81" x2="99" y2="87" />
    <line x1="99" y1="95" x2="99" y2="101" />
  </svg>
);
