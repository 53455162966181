import { ReviewRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type AssignPayloadParams = ReviewRoutes.AssignActions["payload"];

export async function assignActionsToEmployee(payload: ReviewRoutes.AssignActions["payload"]) {
  try {
    const response = await buildRequest<ReviewRoutes.AssignActions>({
      method: "POST",
      path: "/review/assign-actions",
      payload,
      target: "PEOPLE_REVIEW",
    })();

    return response;
  } catch (err) {
    return [];
  }
}
