import { DSButton } from "@skillup/ui";
import type { TrainingRoutes } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import { TranslationType } from "hooks/useTranslation";

import RequalificationModal from "components/RequalificationModal";

import { parseReferenceFromName } from "../utils/parseReferenceFromName";
import { TrainingForm } from "../EditionView/utils";
import styles from "./IntraCreationView.module.scss";

const mapForIntraCreation = (props): TrainingForm => {
  const { properties, organization, categories } = props;
  const [firstList] = categories;
  const tag = {
    value: firstList?.[firstList.length - 1]?.uuid,
    label: firstList?.[firstList.length - 1]?.name,
  };

  return {
    price: undefined,
    priceWithTax: undefined,
    trailer: undefined,

    name: properties.name,
    trainingOrganization: organization.name,
    mode: properties.mode,
    duration: properties.duration,
    description: properties.description,
    targetAudience: properties.targetAudience,
    objectives: properties.objectives,
    prerequisites: properties.prerequisites,
    program: properties.program,
    reference: parseReferenceFromName(properties.name),
    tag,
  };
};

const showCatalogModal = (setTrainingForm: (form: TrainingForm) => void, t: TranslationType) => {
  function Footer({ selection, isLoading, setState: localSetState }) {
    const submit = async () => {
      if (!isLoading) {
        localSetState({ isLoading: true });
        const request = buildRequest<TrainingRoutes.Search.GetBySlug>({
          method: "GET",
          path: "/training/{slug}",
          params: {
            slug: selection.seoSlug,
          },
        });

        const data = await request();

        const mappedValues = mapForIntraCreation(data.training);
        setTrainingForm(mappedValues);

        localSetState({ isLoading: false });
        Acta.dispatchEvent("closeModal");
      }
    };

    return (
      <div className={styles.actions}>
        {selection && (
          <div className={styles.info}>
            <p>
              {t("trainings.intra.creation.catalog.selected", {
                defaultValue: "Formation séléctionnée",
              })}
            </p>
            <b>{selection.name}</b>
            <p>
              {selection.organization} - {selection.price} - {selection.duration}
            </p>
          </div>
        )}
        <div className={styles.spacer} />
        <DSButton
          name="submit"
          label={t("trainings.intra.creation.catalog.intraFromProgram", {
            defaultValue: "Créer un intra depuis ce programme",
          })}
          disabled={!selection}
          onClick={submit}
          loading={isLoading}
        />
      </div>
    );
  }

  Acta.setState("modalDisplayed", {
    border: true,
    content: (
      <RequalificationModal rows={[]} displayedTrainingTypes={["inter"]} renderFooter={Footer} />
    ),
    noHeader: true,
    size: "xxLarge",
  });
};

export default showCatalogModal;
