import React from "react";
import CreatableSelect from "react-select/creatable";
import { trim } from "lodash";
import { TextArea, TextInput, Toggle } from "@skillup/ui";

import { Child } from "../types";

// Sub components
import DisallowBlock from "./DisallowBlock";

import styles from "./Child.module.scss";

type RadioQuestion = Child & { kind: "question"; type: "radio" };
const RadioQuestionBlock = ({
  data,
  updateChild,
}: {
  data: RadioQuestion;
  updateChild: (newChildData: Child) => void;
}) => {
  return (
    <form>
      <label>Label</label>
      <TextInput
        key={`label-${data.uuid}`}
        value={data.label}
        onChange={(label) => updateChild({ ...data, label })}
      />
      <label>Description</label>
      <TextArea
        key={`description-${data.uuid}`}
        value={data.description}
        onChange={(description) => updateChild({ ...data, description })}
      />
      <label>Placeholder</label>
      <TextInput
        key={`placeholder-${data.uuid}`}
        value={data.replyPlaceholder}
        onChange={(replyPlaceholder) => updateChild({ ...data, replyPlaceholder })}
      />
      <label>Choix possibles</label>
      <CreatableSelect
        isMulti
        name="Choix"
        options={(data?.choices ?? []).map((choice) => ({ ...choice, selected: true }))}
        value={(data?.choices ?? []).map((choice) => ({ ...choice, selected: true }))}
        onChange={(choices) => {
          updateChild({
            ...data,
            choices: choices.map((choice) => ({
              ...choice,
              label: trim(choice.label),
              // @ts-ignore
              value: trim(choice.value),
            })),
          });
        }}
        className={styles.questionChoices}
      />
      <label>Question requise ?</label>
      <Toggle
        active={data.required}
        onToggle={() => updateChild({ ...data, required: !data.required })}
      />
      <DisallowBlock data={data} updateChild={updateChild} />
    </form>
  );
};

export default RadioQuestionBlock;
