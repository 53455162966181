import { useTypedFetch } from "hooks";
import { PatchAdminDataForCompany } from "@skillup/espace-rh-api/src/routes/portal/patchAdminDataForCompany";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";

import { CompanyAdminPayload } from "../AdminPortalToggle/AdminPortalToggle";

export type AdminProperties = (CompanyAdminPayload & { dataError: boolean }) | null;

export const useAdminProperties = () => {
  const { data, refetch, loading, error } = useTypedFetch<any>({
    method: "GET",
    path: "/portal/admin",
  });

  return {
    data,
    refetch,
    loading,
    error,
  };
};
export default useAdminProperties;

export async function saveChanges(payload: CompanyAdminPayload): Promise<void> {
  const request = buildRequest<PatchAdminDataForCompany>({
    method: "POST",
    path: "/portal/admin",
    payload,
  });
  try {
    await request();
    Acta.dispatchEvent("sendAppMessage", {
      message: "Sauvegardé.",
      type: "success",
    });
  } catch (error) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Échec d’enregistrement.",
      type: "error",
    });
  }
}
