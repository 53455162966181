export type TSortedColumn =
  | "lastName"
  | "firstName"
  | "email"
  | "manager0Lvl0"
  | "manager0Lvl1"
  | "role"
  | "joinDate";

export interface ISortingState {
  key?: TSortedColumn;
  order?: "asc" | "desc";
}

export type TSortingAction =
  | { type: "setSorting"; order: "asc" | "desc"; key: TSortedColumn }
  | { type: "removeSorting" };

const reducer = (state: ISortingState, action: TSortingAction): ISortingState => {
  try {
    switch (action.type) {
      // removes the selection
      case "setSorting":
        return {
          order: action.order,
          key: action.key,
        };
      case "removeSorting":
        return {};
      default:
        return state;
    }
  } catch (err) {
    console.error(err);
    return state;
  }
};

export default reducer;
