import styled from "styled-components";
import { DSColor, EspaceFont } from "@skillup/ui";

export const SkillHeaderContainer = styled.div`
  background: ${DSColor["surface-dark"]};
  border-radius: 8px;
  width: 100%;

  div {
    color: ${DSColor["surface-light"]};
  }
`;

export const StyledLink = styled.span`
  text-decoration: underline;
  cursor: pointer;
  ${EspaceFont.rh.body1Link}
`;
