import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Flex, Label as DSLabel, DSNumberInput, Select } from "@skillup/ui";

import { type Child, selectHighlightUuid } from "../../../../reducer";
import { Permissions } from "../../Permissions/Permissions";

import { Divided } from "../ChildWrappers";

import styles from "./TrainingsHistory.module.scss";

type TrainingsHistory = Extract<Child, { kind: "trainingsHistory" }>;

export function TrainingsHistoryChild({
  child,
  indexes,
  updateStructure,
}: {
  child: TrainingsHistory;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  updateStructure: (data: TrainingsHistory) => void;
}) {
  const highlightUuid = useSelector(selectHighlightUuid);
  const changeDurationNumber = useCallback(
    (newMaxAge: TrainingsHistory["maxAge"]) => {
      updateStructure({
        ...child,
        maxAge: newMaxAge,
      });
    },
    [child, updateStructure]
  );

  const changeDurationUnit = useCallback(
    (newUnit: TrainingsHistory["ageUnit"]) => {
      updateStructure({
        ...child,
        ageUnit: newUnit,
      });
    },
    [child, updateStructure]
  );

  return (
    <Divided>
      <Flex column className={styles.trainingsHistoryChild}>
        <p>
          Le bloc « Historique de formation » liste l’ensemble des formations qu’a réalisées le
          collaborateur.
        </p>

        <Flex column className={styles.trainingsHistoryChild__timeSettings}>
          <DSLabel label="Durée de l'historique" required />
          <Flex row>
            <DSNumberInput
              min={1}
              max={20}
              name="interviews-history-maxAge"
              value={child.maxAge?.toString() ?? undefined}
              onChange={(newMaxAge) => changeDurationNumber(Number(newMaxAge))}
              autoFocus={highlightUuid === child.uuid}
            />

            <Select
              className={styles.trainingsHistoryChild__unitSelect}
              options={[
                {
                  label: "Mois",
                  value: "months",
                },
                {
                  label: "An(s)",
                  value: "years",
                },
              ]}
              value={child.ageUnit}
              onChange={(newUnit) => changeDurationUnit(newUnit)}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex column>
        <Permissions
          restrictions={child.disallow}
          uuid={child.uuid}
          indexes={indexes}
          kind="interviewsHistory"
          order={["comment", "downloadPDF"]}
          override={{
            comment: "Qui peut commenter l'historique de formation ?",
          }}
        />
      </Flex>
    </Divided>
  );
}
