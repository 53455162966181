import React, { type ReactNode } from "react";

import FlexWrapper, { type FlexInterface } from "./FlexWrapper";

export type SkillFlexProps = {
  className?: string;
  disabled?: boolean;
  children?: ReactNode | undefined;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
} & FlexInterface;

export const Flex = ({ children, disabled, ...props }: SkillFlexProps) => {
  if (disabled) {
    return null;
  }

  return <FlexWrapper {...props}>{children}</FlexWrapper>;
};
