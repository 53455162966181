import styled from "styled-components";

import { styled as style } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";

import { DSTooltip, SkuiSpacing } from "@skillup/ui";
import { Flex, DSColor } from "@skillup/design-system";

export const SearchContainer = styled(Flex)`
  width: 40%;
  flex-direction: column;
`;

export const LevelDifferenceContainer = styled(Flex)<{ isNegative?: boolean }>`
  padding: ${SkuiSpacing.xxs} ${SkuiSpacing.xs};
  border: 1px solid;
  border-color: ${({ isNegative }) =>
    isNegative ? DSColor["status-error-default"] : "transparent"};
  background-color: ${({ isNegative }) =>
    isNegative ? DSColor["status-error-lightest"] : DSColor["status-success-lightest"]};
  color: ${({ isNegative }) =>
    isNegative ? DSColor["status-error-darker"] : DSColor["status-success-darker"]};
`;

export const PercentageLine = style(LinearProgress)(() => ({
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: DSColor["decoration-blue-base"],
    borderRadius: 5,
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: DSColor["greyscale-light"],
  },
  borderRadius: 5,
  height: 8,
}));

export const JobPercentageLine = style(PercentageLine)(() => ({
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: DSColor["decoration-yellow-base"],
  },
}));

export const EllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FullWidthTooltip = styled(DSTooltip)`
  width: 100%;
`;

