type GetNotificationTimelineParams = {
  title: string;
  data: {
    x: number;
    name: string;
    label: string;
    description: string;
    marker: {
      symbol: string;
    };
  }[];
};

export function getNotificationsTimeline({ data, title }: GetNotificationTimelineParams) {
  const timelineGraphConf: Highcharts.Options = {
    accessibility: {
      point: {
        valueDescriptionFormat: "{index}. {point.label}. {point.description}.",
      },
      screenReaderSection: {
        beforeChartFormat:
          "<h5>{chartTitle}</h5>" +
          "<div>{typeDescription}</div>" +
          "<div>{chartSubtitle}</div>" +
          "<div>{chartLongdesc}</div>" +
          "<div>{viewTableButton}</div>",
      },
    },
    chart: {
      type: "timeline",
      zooming: {
        type: "x",
      },
    },
    colors: ["#4185F3", "#427CDD", "#406AB2", "#3E5A8E", "#3B4A68", "#363C46"],
    series: [
      {
        type: "timeline",
        data,
        dataLabels: {
          allowOverlap: false,
          format:
            '<span style="color:{point.color}">● </span><span style="font-weight: bold;" > ' +
            "{point.x:%d %b %Y}</span><br/>{point.label}",
        },
        marker: {
          symbol: "circle",
        },
      },
    ],
    title: {
      text: title ?? "loading...",
    },
    tooltip: {
      style: {
        width: 300,
      },
    },
    xAxis: {
      type: "datetime",
      visible: false,
    },
    yAxis: {
      gridLineWidth: 1,
      labels: {
        enabled: false,
      },
      title: null,
    },
  };

  return timelineGraphConf;
}
