import { useToasts } from "react-toast-notifications";
import { useMemo, useState, useCallback } from "react";

import { Text } from "@skillup/design-system";
import { ReviewActionState, ReviewActionStates } from "@skillup/people-review-bridge";
import { DSModal, DSButton, DSDropdown, MaterialIcons, DSDropdownItem } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { Action } from "./components/Action/Action";
import { ActionDenied } from "./components/ActionDenied/ActionDenied";

import styles from "./AssignActionsModal.module.scss";

export type SelectedAction = {
  deletedAt?: Date;
  actionID: string;
  managerComment?: string;
  state: ReviewActionState;
  coordinatorComment?: string;
  origin: "MANAGER" | "COORDINATOR";
};

interface ActionsModalProps {
  fullName: string;
  onClose: () => void;
  isReadonly?: boolean;
  assignedActions: SelectedAction[];
  onSuccess: (selection: SelectedAction[]) => void;
  campaignActions: { id: string; label: string }[];
}

type ModalState = "idle" | "awaitingConfirmation";

export function AssignActionsModal({
  assignedActions,
  campaignActions,
  fullName,
  isReadonly,
  onClose,
  onSuccess,
}: ActionsModalProps) {
  const { t } = useTranslation();
  const [actions, setActions] = useState<SelectedAction[]>(assignedActions);
  const { addToast } = useToasts();
  const [state, setState] = useState<ModalState>("idle");

  const labelsMap = campaignActions.reduce(
    (acc, action) => ({ ...acc, [action.id]: action.label }),
    {}
  );

  const handleSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    setState("awaitingConfirmation");
    try {
      await onSuccess(actions.filter(a => a.deletedAt === undefined));
    } catch (err) {
      addToast(
        t("peopleReview.talentGrid.assignActionsModal.failed", {
          defaultValue: "Un erreur est survenue",
        }),
        {
          appearance: "error",
        }
      );
      setState("idle");
    }
  };

  const handleRestore = useCallback(
    (selectedAction: SelectedAction) => {
      if (isReadonly) return;

      const newActions = [...actions];
      const action = newActions.find((a) => a.actionID === selectedAction.actionID);
      if (action) {
        action.state = ReviewActionStates.ASSIGNED;
      }
      setActions(newActions);
    },
    [actions, isReadonly]
  );

  const handleClickAction = useCallback(
    (selectedAction: SelectedAction) => {
      if (isReadonly) return;

      const newActions = [...actions];

      const isActionArleadySelected = newActions.find(
        (a) => a.actionID === selectedAction.actionID
      );
      if (!isActionArleadySelected) {
        newActions.push(selectedAction);
        setActions(newActions);
        return;
      }

      const arleadySelectedActionIndex = newActions.findIndex(
        (a) => a.actionID === selectedAction.actionID
      );
      newActions[arleadySelectedActionIndex].state = ReviewActionStates.ASSIGNED;
      setActions(newActions);
    },
    [actions, isReadonly]
  );

  const handleRemove = useCallback(
    (selectedAction: SelectedAction) => {
      if (isReadonly) return;

      const newActions = [...actions];
      const action = newActions.find((a) => a.actionID === selectedAction.actionID);

      if (action) {
        if (action.origin === "MANAGER" || !!action.managerComment) {
          action.state = ReviewActionStates.DENIED;
        }
        if (action.state === ReviewActionStates.ASSIGNED) {
          action.deletedAt = new Date();
        }
      }

      setActions(newActions);
    },
    [actions, isReadonly]
  );

  const actionsToList = useMemo(() => {
    return actions.filter((a) => a.deletedAt === undefined);
  }, [actions]);
  const noActionsYet = actionsToList.length === 0;
  const availableActions = campaignActions.length !== actionsToList.length;

  return (
    <DSModal isOpen className={styles.ActionsModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title={`Actions pour ${fullName}`} />
      </DSModal.Header>

      <DSModal.Content className={styles.ActionsModal__Content}>
        {noActionsYet && (
          <Text marginBottom="s" espaceFont="body1Regular">
            Aucune action définie pour {fullName}.
          </Text>
        )}
        {actionsToList.map((selectedAction) => {
          if (selectedAction.state === ReviewActionStates.DENIED)
            return (
              <ActionDenied
                labelsMap={labelsMap}
                key={selectedAction.actionID}
                handleRestore={handleRestore}
                selectedAction={selectedAction}
              />
            );
          return (
            <Action
              actions={actions}
              labelsMap={labelsMap}
              setActions={setActions}
              isReadonly={isReadonly}
              handleRemove={handleRemove}
              key={selectedAction.actionID}
              selectedAction={selectedAction}
              campaignActions={campaignActions}
            />
          );
        })}
        {availableActions && !isReadonly && (
          <DSDropdown
            placementShift={[0, 3]}
            button={
              <DSButton
                buttonSize="S"
                emphasis="Mid"
                actionIcon={<MaterialIcons.ExpandMore />}
                label={t("peopleReview.talentGrid.assignActionsModal.addActions", {
                  defaultValue: "Ajouter une action",
                })}
              />
            }
          >
            {campaignActions.map((a) => (
              <DSDropdownItem
                key={a.id}
                label={a.label}
                disabled={actionsToList.some((s) => s.actionID === a.id)}
                onClick={() =>
                  handleClickAction({
                    actionID: a.id,
                    origin: "COORDINATOR",
                    state: ReviewActionStates.ASSIGNED,
                  })
                }
              />
            ))}
          </DSDropdown>
        )}
      </DSModal.Content>
      {isReadonly ? (
        <DSModal.FooterInfo>
          <DSModal.FooterInfo.CancelButton
            label={t("common.close", { defaultValue: "Fermer" })}
            onClick={onClose}
          />
        </DSModal.FooterInfo>
      ) : (
        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            disabled={state === "awaitingConfirmation"}
            label={t("common.cancel", { defaultValue: "Annuler" })}
            onClick={onClose}
          />
          <DSModal.Footer.PrimaryButton
            loading={state === "awaitingConfirmation"}
            label={t("common.save", { defaultValue: "Enregistrer" })}
            onClick={handleSubmit}
          />
        </DSModal.Footer>
      )}
    </DSModal>
  );
}
