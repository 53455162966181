import type { UserRoutes } from "types/api";
import { buildFileRequest, buildRequest } from "utils/buildRequest";

type SearchByQueryRouteType = UserRoutes.SearchByQuery;
type SearchByFileRouteType = UserRoutes.SearchByFile;
type GetManagerRouteType = UserRoutes.GetManager;

export const searchUsersByFile = async (
  file: File,
  scope: string
): Promise<SearchByFileRouteType["response"]> => {
  const request = buildFileRequest<SearchByFileRouteType>({
    method: "POST",
    path: "/user/search-by-file",
    query: {
      scope,
    },
    file,
  });

  const response = await request();

  return response;
};

export const fetchUserManager = async (
  userEmail: string
): Promise<GetManagerRouteType["response"]> => {
  const request = buildRequest<GetManagerRouteType>({
    method: "GET",
    path: "/user/get-manager",
    query: { userEmail },
  });

  const response = await request();
  return response;
};

export type QueryUserHit = SearchByQueryRouteType["response"][0];
export const searchUsersByQuery = async (query: string, scope: string): Promise<any> => {
  const users = await buildRequest<SearchByQueryRouteType>({
    method: "GET",
    query: { input: query.toLowerCase(), scope },
    path: "/user/search-by-query",
  })();

  return users;
};
