import type { OrganizationRoutesTypeUserGroup } from "types/api";

import { buildRequest } from "utils/buildRequest";

export default (groupName: string, userGroupUuid: string) => {
  return buildRequest<OrganizationRoutesTypeUserGroup.Rename>({
    method: "POST",
    path: "/organization/group/{userGroupUuid}",
    params: { userGroupUuid },
    payload: { groupName },
  })();
};
