import { useMemo } from "react";
import { parse, format } from "date-fns";

import { Column, ColumnType, DSTooltip, Status, StatusType } from "@skillup/ui";
import { InterviewResults } from "../types";

import styles from "../UserInterview.module.scss";
import { sortBy, uniqBy } from "lodash";
import interviewStatusKeyToValue from "utils/InterviewStatusKeyToValue";

type Props = {
  interviews: InterviewResults;
};

function useTableData({ interviews = [] }: Props): {
  data: InterviewRow[];
  columns: Column<InterviewRow>[];
} {
  const tableData = useMemo(
    () => ({
      columns: generateColumns(interviews),
      data: generateTableRows(interviews),
    }),
    [interviews]
  );

  return tableData;
}

export default useTableData;

const generateColumns = (interviews: InterviewResults) => {
  const columns: Array<Column<InterviewRow>> = [
    {
      key: "title",
      title: "Intitulé et type d’entretien",
      filterable: false,
      minWidth: 20,
      sticky: true,
      renderCell: (interview) => (
        <div className={styles.cell}>
          <div className={styles.title}>
            <DSTooltip
              label={interview.title}
              enabledOnOverflow
              tooltipClassName={styles.tooltip}
              withPortal
            >
              {interview.title}
            </DSTooltip>
          </div>
          <div className={styles.subtitle}>
            {interview.type}
            {interview.source === "imported" ? " (Entretien importé)" : ""}
          </div>
        </div>
      ),
    },
    {
      key: "startDate",
      title: "Période de l’entretien",
      filterable: false,
      sortable: true,
      renderCell: (interview) =>
        interview.startDate ? `${interview.startDate} - ${interview.endDate}` : interview.endDate,
      sortFn: sortingRow,
    },
    {
      key: "managerFullName",
      title: "Responsable de l'entretien",
    },
    {
      key: "employeeState",
      title: "Statut collaborateur",
      type: ColumnType.SEARCH_SELECT,
      options: uniqBy(interviews, (i) => interviewStatusKeyToValue(i.employee?.state).shortValue)
        .map((e) => {
          const value = interviewStatusKeyToValue(e.employee?.state);
          return {
            value: value.shortValue,
            label: value.key === "404" ? "Non renseigné" : value.shortValue,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
      renderCell: ({ employeeState, source }) => {
        return source !== "imported" ? (
          <Status label={employeeState} type={getDSStatusType(employeeState)} />
        ) : null;
      },
    },
    {
      key: "managerState",
      title: "Statut responsable",
      type: ColumnType.SEARCH_SELECT,
      options: uniqBy(interviews, (i) => interviewStatusKeyToValue(i.manager.state).shortValue)
        .map((e) => {
          const value = interviewStatusKeyToValue(e.employee?.state);
          return {
            value: value.shortValue,
            label: value.key === "404" ? "Non renseigné" : value.shortValue,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
      renderCell: ({ managerState, source }) => {
        return source !== "imported" ? (
          <Status label={managerState} type={getDSStatusType(managerState)} />
        ) : null;
      },
    },
  ];

  return columns;
};

function generateTableRows(interviews: InterviewResults): Array<InterviewRow> {
  const rows = interviews?.map(parseInterviewIntoRow);
  return sortBy(rows, sortingRow);
}

function parseInterviewIntoRow(interview: InterviewResults[0]) {
  return {
    id: interview.uuid,
    data: {
      ...interview,
      managerFullName: interview.manager.fullName,
      managerState: interviewStatusKeyToValue(interview.manager?.state).shortValue,
      employeeState: interviewStatusKeyToValue(interview.employee?.state).shortValue,
      startDate: interview.startDate
        ? format(new Date(interview.startDate), "dd/MM/yyyy").toLocaleString()
        : "",
      endDate: interview.endDate
        ? format(new Date(interview.endDate), "dd/MM/yyyy").toLocaleString()
        : "",
    },
  };
}

export type InterviewRow = ReturnType<typeof parseInterviewIntoRow>;

function getDSStatusType(status: string): StatusType {
  switch (status) {
    case "Signé":
      return StatusType.SUCCESS;
    case "Partagé":
      return StatusType.PROGRESS;
    case "À préparer":
      return StatusType.DONE;
    case "Not found":
      return StatusType.ERROR;
    default:
      return StatusType.ERROR;
  }
}

const sortingRow = (row) => {
  if (row.data.endDate) return -parse(row.data.endDate, "dd/MM/yyyyy", new Date()).getTime();
  else if (row.data.startDate)
    return -parse(row.data.startDate, "dd/MM/yyyyy", new Date()).getTime();
  else return 0;
};
