import React from "react";
import { Toggle } from "@skillup/ui";

import { useUnmanagedBookingContext } from "../../../context";
import { UnmanagedBookingContext } from "../../../context/UnmanagedBookingContext";

import styles from "./Options.module.scss";
import useTranslation from "hooks/useTranslation";

interface Props {
  form: UnmanagedBookingContext["form"];
  setForm: Function;
  showFolderOps: boolean;
  showConfirmation: boolean;
  showUnavaibleOptions: boolean;
  showEval: boolean;
}

export default ({
  form,
  setForm,
  showFolderOps,
  showConfirmation,
  showUnavaibleOptions,
  showEval,
}: Props) => {
  const { t } = useTranslation();
  const [unmanagedBookingContext] = useUnmanagedBookingContext();
  const isPlanProcessing = unmanagedBookingContext.tab === "plan-processing";
  const hasManyRows = unmanagedBookingContext?.users?.length > 1;

  return (
    <div>
      {showUnavaibleOptions && (
        <div>
          {isPlanProcessing ? (
            <span>
              {t("trainingSchedule:unmanagedBookingForm.options.unavailable.questionnaire", {
                defaultValue: `L'option "Envoyer le questionaire d'évaluation" n'est pas disponible`,
              })}
            </span>
          ) : (
            <span>
              {t("trainingSchedule:unmanagedBookingForm.options.unavailable.confirmation", {
                defaultValue: `Les options "Envoyer un e-mail de confirmation" et "Envoyer le questionnaire d'évaluation" ne sont pas disponibles`,
              })}
            </span>
          )}
          {hasManyRows ? (
            <span>
              {t("trainingSchedule:unmanagedBookingForm.options.unavailable.manyRows", {
                defaultValue: " car les adresses e-mails des stagiaires sont invalides.",
              })}{" "}
            </span>
          ) : (
            <span>
              {t("trainingSchedule:unmanagedBookingForm.options.unavailable.oneRow", {
                defaultValue: " car l'adresse e-mail du stagiaire est invalide.",
              })}{" "}
            </span>
          )}
        </div>
      )}
      {showConfirmation && (
        <div className={styles.toggle}>
          <Toggle
            onToggle={() => setForm({ canSendConfirmation: !form.canSendConfirmation })}
            active={form?.canSendConfirmation}
            className={styles.checkbox}
          />
          <label>
            {t("trainingSchedule:unmanagedBookingForm.options.sendConfirmation", {
              defaultValue: "Envoyer un e-mail de confirmation d'inscription au stagiaire",
            })}
          </label>
        </div>
      )}
      {showEval && (
        <div className={styles.toggle}>
          <Toggle
            onToggle={() => setForm({ canSendEval: !form.canSendEval })}
            active={form?.canSendEval}
            className={styles.checkbox}
          />
          <label>
            {t("trainingSchedule:unmanagedBookingForm.options.sendEval", {
              defaultValue:
                "Envoyer le questionnaire d'évaluation au stagiaire une fois la formation terminée",
            })}
          </label>
        </div>
      )}
      {showFolderOps && (
        <div>
          <div className={styles.toggle}>
            <Toggle
              onToggle={() => setForm({ canCreateOpsFolder: !form.canCreateOpsFolder })}
              active={form?.canCreateOpsFolder}
              className={styles.checkbox}
            />
            <label>
              {t("trainingSchedule:unmanagedBookingForm.options.createOpsFolder", {
                defaultValue: "Créer un dossier dans le Back-Office",
              })}
            </label>
          </div>
          <p className={styles.folderWarning}>
            {t("trainingSchedule:unmanagedBookingForm.options.createOpsFolderWarning", {
              defaultValue:
                "(Attention, si cette option est sélectionnée, alors le booking ne sera plus au status 'Hors Skillup')",
            })}
          </p>
        </div>
      )}
    </div>
  );
};
