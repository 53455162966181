import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import z from "zod";
import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { useQueryClient } from "@tanstack/react-query";

import { Label, DSModal, DSColor } from "@skillup/ui";
import { Flex, FormikInputText } from "@skillup/design-system";

import { Action, useAction } from "services/peopleReview";

import ColorPicker from "../../picker/ColorPicker";

const ActionSchema = z.object({
  label: z
    .string({
      required_error: "Ce champ est obligatoire",
    })
    .trim()
    .min(1, "Ce champ ne doit pas être vide"),
});

type EditActionModalProps = {
  action: Action;
  isOpen: boolean;
  closeModal: () => void;
};

export const EditActionModal = ({ action, closeModal, isOpen }: EditActionModalProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const queryClient = useQueryClient();
  const { mutations } = useAction(action.uuid);

  const editAction = async ({ color, label }) => {
    try {
      mutations.edit.mutateAsync({ color, label });
      queryClient.refetchQueries(["people-reviews"]);
      addToast("Action modifiée avec succès", { appearance: "success" });
      closeModal();

      return true;
    } catch (err) {
      addToast("Une erreur est survenue", { appearance: "error" });
      closeModal();
      return [];
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={toFormikValidationSchema(ActionSchema)}
      onSubmit={(values) => editAction(values)}
      initialValues={{
        color: action.color || DSColor["greyscale-dark"],
        label: action.label || "",
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <DSModal isOpen={isOpen} portalTarget={document.body}>
          <DSModal.Header onClose={closeModal}>
            <DSModal.Header.Title
              title={t("peoplereviewsConfiguration.actions.modals.edit.title", {
                defaultValue: "Modifier une action",
              })}
            />
          </DSModal.Header>
          <DSModal.Content>
            <Flex paddingBottom="s" flexDirection="column">
              <Label required aria-required label="Intitulé de l'action" />
              <FormikInputText
                name="label"
                value={values.label}
                placeholder={t(
                  "peoplereviewsConfiguration.actions.modals.edit.fields.title.placeholder",
                  {
                    defaultValue: "Intitulé de l'action",
                  }
                )}
              />
              <ColorPicker
                selected={values.color}
                onSelect={(newColor) => setFieldValue("color", newColor)}
              />
            </Flex>
          </DSModal.Content>
          <DSModal.Footer>
            <DSModal.Footer.CancelButton
              onClick={closeModal}
              label={t("peoplereviewsConfiguration.actions.modals.edit.cancel", {
                defaultValue: "Annuler",
              })}
            />
            <DSModal.Footer.PrimaryButton
              onClick={() => handleSubmit()}
              label={t("peoplereviewsConfiguration.actions.modals.edit.confirm", {
                defaultValue: "Enregistrer les modifications",
              })}
            />
          </DSModal.Footer>
        </DSModal>
      )}
    </Formik>
  );
};
