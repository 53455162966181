import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

async function createCampaign(payload: CampaignsRoutes.Create["payload"]) {
  const response = await buildRequest<CampaignsRoutes.Create>({
    method: "POST",
    path: "/campaigns",
    payload,
    target: "PEOPLE_REVIEW",
  })();

  return response.id;
}

export function useCampaigns() {
  return {
    campaigns: [],
    create: createCampaign,
  };
}
