import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import z from "zod";
import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { DSModal } from "@skillup/ui";

import { useTemplates } from "services/peopleReview";

import { TemplateForm } from "./TemplateForm";

import styles from "./CreateTemplate.module.scss";

const initialValues = { actions: [], fields: [], title: "" };

interface CreateTemplateParams {
  title: string;
  fields: string[];
  actions: string[];
}

const TemplateSchema = z.object({
  title: z
    .string({
      required_error: "Ce champ est obligatoire",
    })
    .trim()
    .min(1, "Ce champ ne doit pas être vide"),
});

export const CreateTemplateModal = ({ closeModal, isOpen }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { mutations } = useTemplates({ refetchOnMount: false, refetchOnWindowFocus: false });
  const [state, setState] = useState<CreateModalState>({ step: "idle" });

  const createTemplate = async ({ actions, fields, title }: CreateTemplateParams) => {
    try {
      setState({ step: "creating" });
      await mutations.create.mutateAsync({
        actionsIds: actions,
        fieldsUuids: fields,
        title,
      });
      addToast("Template créé avec succès", { appearance: "success" });
      closeModal();
    } catch (err) {
      addToast("Une erreur est survenue", { appearance: "error" });
      setState({ step: "idle" });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(TemplateSchema)}
      onSubmit={createTemplate}
    >
      {({ handleSubmit, values }) => (
        <DSModal isOpen={isOpen} portalTarget={document.body} className={styles.createTemplate}>
          <DSModal.Header onClose={closeModal}>
            <DSModal.Header.Title
              title={t("peoplereviewsConfiguration.templates.modals.creation.title", {
                defaultValue: "Nouveau modèle de campagne",
              })}
            />
          </DSModal.Header>
          <TemplateForm title={values.title} fields={values.fields} actions={values.actions} />
          <DSModal.Footer>
            <DSModal.Footer.CancelButton
              loading={state.step === "creating"}
              disabled={state.step === "creating"}
              onClick={closeModal}
              label={t("peoplereviewsConfiguration.templates.modals.creation.cancel", {
                defaultValue: "Annuler",
              })}
            />
            <DSModal.Footer.PrimaryButton
              loading={state.step === "creating"}
              disabled={state.step === "creating"}
              onClick={() => handleSubmit()}
              label={t("peoplereviewsConfiguration.templates.modals.creation.confirm", {
                defaultValue: "Créer le modèle",
              })}
            />
          </DSModal.Footer>
        </DSModal>
      )}
    </Formik>
  );
};

type CreateModalState = { step: "idle" } | { step: "creating" };
