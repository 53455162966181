import { Either } from "@skillup/monads";
import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

export type PartialCancelParams = {
  rowsUuid: string[];
  comment?: string;
};
export async function partialCancel(params: PartialCancelParams) {
  try {
  await buildRequest<ScheduleRowRoutes.CancelRow>({
    method: "POST",
    path: "/scheduleRow/cancel",
    payload: {
      rows: params.rowsUuid,
      comment: params.comment,
    },
  })();
    return Either.right(undefined);
  } catch (err) {
    return Either.left(undefined);
  }
}
