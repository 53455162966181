import { IntraRoutes } from "types/api";
import { analyzeDuration } from "./analyzeDuration";
import { analyzePrice } from "./analyzePrice";

type IntraData = IntraRoutes.GET_BY_UUID["response"];

export function isValidIntraData(
  intraData: {
    properties: Partial<IntraData["properties"]>;
    tag: IntraData["tag"];
  },
  options: { checkTaxes?: boolean } = {}
) {
  let optionalChecks = true;
  if (options.checkTaxes) {
    optionalChecks =
      optionalChecks &&
      intraData.properties.priceWithTax != null &&
      !analyzePrice(intraData.properties.priceWithTax).isInvalid;
  }

  return (
    intraData.properties.name &&
    intraData.properties.mode &&
    intraData.properties.price != null &&
    !analyzeDuration(intraData.properties.duration).isInvalid &&
    intraData.properties.duration != null &&
    !analyzePrice(intraData.properties.price).isInvalid &&
    intraData.properties.trainingOrganization &&
    intraData.properties.reference &&
    intraData.tag &&
    optionalChecks
  );
}
