import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";
import { DSButton, Flex } from "@skillup/ui";

import nemptyTemplateImg from "assets/interview/templates/empty-template.png";
import { useBuilderContext } from "../../../BuilderContext";
import { add } from "../../../reducer";
import styles from "./EmptyTemplateBlock.module.scss";

export const EmptyTemplateBlock = () => {
  const dispatch = useDispatch();
  const { updatedItemIdRef } = useBuilderContext();
  const handleCreateFirstSection = useCallback(() => {
    const sectionUuid = uuid();
    updatedItemIdRef.current = { uuid: sectionUuid, fromAction: "addBlock" };
    dispatch(
      add({
        uuid: sectionUuid,
        from: { sectionIndex: 0, pageIndex: 0, childIndex: 0 },
        blockType: "section",
      })
    );
  }, [dispatch, updatedItemIdRef]);
  return (
    <Flex column center className={styles.emptyTemplateBlock}>
      <img src={nemptyTemplateImg} alt="empty-template" />
      <strong>Votre trame ne contient encore aucune section</strong>
      <DSButton
        label="Ajouter la première section"
        darkMode
        buttonSize="M"
        emphasis="High"
        onClick={handleCreateFirstSection}
      />
    </Flex>
  );
};
