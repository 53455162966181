import { Flex, Text } from "@skillup/design-system";
import { Icon, Status, DSButton, StatusType } from "@skillup/ui";

import { SelectedAction } from "../../AssignActionsModal";

import styles from "./ActionDenied.module.scss";

interface ActionDeniedProps {
  selectedAction: SelectedAction;
  labelsMap: { [key: string]: string };
  handleRestore: (SelectedAction) => void;
}

export const ActionDenied = ({ handleRestore, labelsMap, selectedAction }: ActionDeniedProps) => {
  return (
    <Flex
      padding="s"
      marginBottom="xs"
      justifyContent="space-between"
      className={styles.ActionDenied__Container}
    >
      <Flex>
        <Flex marginRight="s">
          <DSButton
            iconOnly
            icon={<Icon.Refresh color="#69798C" />}
            onClick={() => handleRestore(selectedAction)}
          />
        </Flex>
        <div className={styles.ActionDenied__Label}>
          <Text espaceFont="h4">{labelsMap[selectedAction.actionID]}</Text>
        </div>
      </Flex>
      <Status type={StatusType.SUCCESS} label="Proposition manager" />
    </Flex>
  );
};
