import { useEffect, useRef, useState } from "react";

// Intersection observer to detect when the element is visible in the page
// Code comes from https://dev.to/producthackers/intersection-observer-using-react-49ko
const useIntersectionObserver = (options?: IntersectionObserverInit) => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction = (entries) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const containerElt = containerRef.current;
    if (!containerElt) return undefined;

    const observer = new IntersectionObserver(callbackFunction, options);
    observer.observe(containerElt);

    return () => {
      observer.unobserve(containerElt);
    };
  }, [containerRef, options]);

  return { containerRef, isVisible };
};

export default useIntersectionObserver;
