import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { buildRequest } from "utils/buildRequest";
import type { GetProjectsRoute } from "types/api";
import useTranslation from "hooks/useTranslation";

import { createSession, type SessionCreationParams } from "./createSession";
import { updateSession, type UpdateSessionParams } from "./updateSession";
import { cancelV2, type CancelParams } from "./cancelV2";
import { partialCancel, type PartialCancelParams } from "./partialCancel";
import { partialPostpone, type PartialPostponeParams } from "./partialPostpone";
import { summonProjectV2, SummonProjectV2 } from "./summon";

export type Project = GetProjectsRoute["response"]["projects"][number];
export type Actions = {
  update: (params: UpdateSessionParams) => Promise<Project>;
  create: (params: SessionCreationParams) => Promise<string>;
};

async function getSessions(tab: GetProjectsRoute["query"]["type"], areas?: Array<string>) {
  try {
    const data = await buildRequest<GetProjectsRoute>({
      method: "GET",
      path: "/projects",
      query: {
        type: tab,
        areas: areas?.join(",") ?? "",
      },
    })();

    return data.projects;
  } catch (err) {
    return [];
  }
}

type Tab = GetProjectsRoute["query"]["type"];
type UseSessionsParams = { tab: Tab; areas: Array<string> };
type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};
const defaultOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
};

export function useSessions({ tab, areas }: UseSessionsParams, options: Options = defaultOptions) {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery([`sessions-${tab}`], async () => getSessions(tab, areas), {
    ...defaultOptions,
    ...options,
  });
  const { addToast } = useToasts();
  
  const createSessionMutation = useMutation(createSession, {
    mutationKey: ["create-session"],
  });

  const updateSessionMutation = useMutation(updateSession, {
    mutationKey: ["update-session"],
  });

  const cancelMutation = useMutation(cancelV2, { mutationKey: ["cancel-session"] });
  const partialCancelMutation = useMutation(partialCancel, { mutationKey: ["partial-cancel-session"] });
  
  const summonSessionMutation = useMutation(summonProjectV2, {
    mutationKey: ["summon-session"],
  });

  const partialPostponeMutation = useMutation(partialPostpone, {
    mutationKey: ["postpone-session"],
  });

  const create = useCallback(
    async (params: SessionCreationParams) => {
      const sessionUuid = await createSessionMutation.mutateAsync(params);
      history.push(`/responsable/mes-sessions/en-cours/${sessionUuid}`);
    },
    [createSessionMutation, history]
  );

  const update = useCallback(
    async (params: UpdateSessionParams) => {
      const updatedProject = await updateSessionMutation.mutateAsync(params);
      return updatedProject;
    },
    [updateSessionMutation]
  );

  const cancelSessionV2 = useCallback(
    async (params: CancelParams) => {
      try {
        await cancelMutation.mutateAsync(params);
        addToast("La session a été annulée avec succès", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (err) {
        addToast("Erreur lors de l'annulation de la session", {
          appearance: "error",
          autoDismiss: true,
        });
        console.error("Error while cancelling session: ", err);
      }

      await query.refetch();
    },
    [cancelMutation, query, addToast]
  );

  const partialCancelSession = useCallback(
    async (params: PartialCancelParams) => {
      const cancellation = await partialCancelMutation.mutateAsync(params);
      if (cancellation.isRight()) {
        addToast(
          t("trainings.sessions.toast.cancel.success", {
            defaultValue: "La demande a bien été annulée",
            count: params.rowsUuid.length,
          }), 
            {
              appearance: "success",
              autoDismiss: true,
            }
        ) 
        } else {
          addToast(
            t("trainings.sessions.toast.cancel.error", {
              defaultValue: "Erreur lors de l’annulation de la demande",
              count: params.rowsUuid.length,
             }), 
            {
              appearance: "error",
              autoDismiss: true,
            }
          );
          console.error("Error while cancelling session: ", cancellation.left());
        }
      await query.refetch();
    },
    [partialCancelMutation, query, addToast, t]
  );

  const partialPostponeSession = useCallback(
    async (params: PartialPostponeParams) => {
      const postpone = await partialPostponeMutation.mutateAsync(params);
      if (postpone.isRight()) {
        addToast(
          t("trainings.sessions.toast.postpone.success", {
          defaultValue: "La demande a bien été reportée",
          count: params.rowsUuid.length,
          }), 
          {
            appearance: "success",
            autoDismiss: true,
          }
        );
      } else {
        addToast(
          t("trainings.sessions.toast.postpone.error", {
          defaultValue: "Erreur lors du report de la demande",
          count: params.rowsUuid.length,
          }), 
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
        console.error("Error while postponing the session: ", postpone.left());
      }
      await query.refetch();
    },
    [partialPostponeMutation, query, addToast, t]
  );

  const summonSession = useCallback(
    async (params: SummonProjectV2) => {
      const summoningResponse = await summonSessionMutation.mutateAsync(params);
      return summoningResponse;
    },
    [summonSessionMutation]
  );

  return {
    ...query,
    actions: {
      create,
      update,
      cancelV2: cancelSessionV2,
      summonSession,
      partialCancel: partialCancelSession,
      partialPostpone: partialPostponeSession
    },
  };
}

export const SESSIONS_LIST = "SESSIONS_LIST";
export const ReferenceValidityError = {
  ALREADY_USED: "ALREADY_USED",
  EMPTY: "EMPTY",
  UNKNOWN: "UNKNOWN",
  TOO_LONG: "TOO_LONG",
  TOO_SHORT: "TOO_SHORT",
} as const;

export type ReferenceValidityError =
  (typeof ReferenceValidityError)[keyof typeof ReferenceValidityError];