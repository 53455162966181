import { DataTable } from "@skillup/ui";

import { useCoreHRFields } from "services/coreHR/fields/useCoreHRFields";

import { useFieldsDataTable } from "./hooks/useFieldsDataTable";

import styles from "./Fields.module.scss";

export const Fields = () => {
  const { enumerableFields } = useCoreHRFields();
  const { columns, data: tableData } = useFieldsDataTable(enumerableFields);

  return (
    <div className={styles.container}>
      <h1>Propriétés d'un utilisateur</h1>
      <hr />
      <div className={styles.arrayContainer}>
        <DataTable mode="compact" rows={tableData} columns={columns} />
      </div>
    </div>
  );
};
