import { DSModal } from "@skillup/ui";
import { Text } from "@skillup/design-system";

// import { getUserInitials } from "utils/User";
import useTranslation from "hooks/useTranslation";

import styles from "./ManagerPreparationWarningModal.module.scss";

type UserData = {
  uuid: string;
  fullName?: string;
};

type ManagerPreparationWarningModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  usersData: {
    managersCount: number;
    collaborators: UserData[];
  };
};
export const ManagerPreparationWarningModal = ({
  onClose,
  onConfirm,
  usersData,
}: ManagerPreparationWarningModalProps) => {
  const { t } = useTranslation();

  const collaboratorsLength = usersData.collaborators.length;

  return (
    <DSModal isOpen={true}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("peopleReview.button.managerPreparationWarningModal.title", {
            defaultValue: "Notifier {{managersCount}} manager(s) du démarrage de la campagne",
            managersCount: usersData.managersCount,
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.modal}>
        <Text espaceFont="body2Regular" color="plainText-onLight-default">
          {t("peopleReview.button.managerPreparationWarningModal.summary", {
            collaboratorsLength,
            defaultValue:
              "Cette campagne concerne {{collaboratorsLength}} collaborateur(s), répartis sous la responsabilité de {{managersCount}} manager(s).",
            managersCount: usersData.managersCount,
          })}
        </Text>
        {/* TODO: Uncomment it when real users data is given */}
        {/* <Flex marginTop="s" width={"100%"} marginBottom="s">
          <Flex flex={1} flexDirection="column">
            <Flex marginBottom="xs">
              <Label
                label={t("peopleReview.button.managerPreparationWarningModal.collaborators", {
                  defaultValue: "Collaborateur(s).",
                })}
              />
            </Flex>
            <DSAvatarGroup
              size="S"
              maxItems={5}
              totalItems={collaboratorsLength}
              users={usersData.collaborators.map((collab) => ({
                uuid: collab.uuid,
                fullName: collab.fullName,
                initials: getUserInitials({ fullName: collab.fullName }),
              }))}
            />
          </Flex>
          <Flex flex={1} flexDirection="column">
            <Flex marginBottom="xs">
              <Label
                label={t("peopleReview.button.managerPreparationWarningModal.managers", {
                  defaultValue: "Manager(s).",
                })}
              />
            </Flex>
            <DSAvatarGroup
              size="S"
              maxItems={5}
              totalItems={managersCount}
              users={usersData.managers.map((manager) => ({
                uuid: manager.uuid,
                fullName: manager.fullName,
                initials: getUserInitials({ fullName: manager.fullName }),
              }))}
            />
          </Flex>
        </Flex> */}
        <Text espaceFont="body2Regular" color="plainText-onLight-default">
          {t("peopleReview.button.managerPreparationWarningModal.whatWillHappenMessage", {
            defaultValue:
              "Le démarrage de la campagne déclenchera l’envoi automatique des mails de notification à destination des managers.",
          })}
        </Text>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("peopleReview.button.managerPreparationWarningModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          onClick={onConfirm}
          label={t("peopleReview.button.managerPreparationWarningModal.launch", {
            defaultValue: "Démarrer la campagne",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
