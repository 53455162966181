import type { StructureItemPosition, PageBlock, SectionBlock, ChildBlock, Section } from "../..";
import { moveChildToPage } from "./moveChildToPage";

export function moveChildToSection(
  sections: Section[],
  fromPosition: StructureItemPosition<ChildBlock>,
  toPosition: StructureItemPosition<SectionBlock>
) {
  const toPagePosition = {
    type: "page",
    pageIndex: 0,
    sectionIndex: toPosition.sectionIndex,
  };
  return moveChildToPage(
    sections,
    fromPosition,
    toPagePosition as StructureItemPosition<PageBlock>
  );
}
