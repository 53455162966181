import React, { PureComponent } from "react";
import { ITrainingDataCall, IUser } from "@skillup/types";
import { match } from "react-router-dom";

import { IWithRouter } from "utils/types";
import Acta from "utils/Acta";
import Loader from "components/Loader";
import TrainingPage from "./TrainingPage";
import { buildRequest } from "utils/buildRequest";
import type { TrainingRoutes } from "@skillup/espace-rh-bridge";

export interface IProps extends IWithRouter {
  trainingData?: ITrainingDataCall;
  match: match<{ slug?: string }>;
}

export interface IState {
  isLoading: boolean;
  serverError: boolean;
  notFound: boolean;
  trainingData: ITrainingDataCall | null;
  userData?: IUser;
}

export default class TrainingPageContainer extends PureComponent<IProps, IState> {
  public state: IState = {
    isLoading: true,
    notFound: false,
    serverError: false,
    trainingData: null,
  };

  constructor(props) {
    super(props);
    if (props.trainingData) {
      this.state = {
        isLoading: false,
        notFound: false,
        serverError: false,
        trainingData: this.processTrainingData(props.trainingData, props.unmanagedBySkillup),
      };
    } else {
      this.loadData(this.props);
    }
  }

  public componentDidMount(): void {
    Acta.subscribeState("userData", (userData) => this.setState({ userData }), this);
  }

  public componentDidUpdate(prevProps) {
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      this.loadData(this.props);
    }
  }

  private loadData = async (props: IProps): Promise<void> => {
    try {
      const getBySlugResponse = await buildRequest<TrainingRoutes.Search.GetBySlug>({
        method: "GET",
        path: "/training/{slug}",
        params: {
          slug: `formation-${props.match.params.slug}`,
        },
      })();

      this.setState({
        trainingData: this.processTrainingData(
          getBySlugResponse.training,
          getBySlugResponse.isUnmanagedBySkillup
        ),
      });
    } catch (error) {
      console.error(error);
      this.setState({
        notFound: error.statusCode === 404,
        serverError: true,
      });
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  private processTrainingData = (
    trainingData: ITrainingDataCall,
    isUnmanagedBySkillup: boolean
  ) => {
    const { sessions } = trainingData;
    const computePlace = (session): string => {
      if (session.type === "distancial") return "À distance";
      else if (session.addressName) {
        return `${session.addressName} - ${session.city}`;
      }
      return session.city || "Lieu non déterminé";
    };

    const places: Array<string> = (
      sessions && sessions.length ? Array.from(new Set(sessions.map(computePlace)).values()) : []
    ) as Array<string>;

    const sessionsByPlace =
      sessions && sessions.length
        ? sessions.reduce((acc, val) => {
            const place = computePlace(val);
            acc[place] = acc[place] || [];
            acc[place].push(val);
            return acc;
          }, {})
        : {};

    return {
      categories: trainingData.categories,
      entities: trainingData.entities,
      organization: {
        ...trainingData.organization,
        logoUrl: trainingData.organization.profileLogoOptiSmall
          ? `${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${trainingData.organization.profileLogoOptiSmall}`
          : "",
      },
      places,
      properties: {
        isUnmanagedBySkillup,
        ...trainingData.properties,
      },
      reviews: [...(trainingData.reviews || [])],
      sessions: [...(trainingData.sessions || [])],
      sessionsByPlace,
    };
  };

  public render(): JSX.Element {
    const { isLoading, trainingData, serverError, notFound } = this.state;

    if (isLoading) return <Loader />;

    if (notFound) return <div />;

    if (serverError) return <div />;

    // @ts-ignore
    return <TrainingPage {...this.props} {...trainingData} />;
  }
}
