import React, { useMemo, useState } from "react";
import moment from "moment";
import cx from "classnames";
import { isEmpty, isNil } from "lodash";
import { useSetState } from "react-use";

import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";

import type { ManualTrainingData } from "./Training";
import { modes } from "./Training";
import Dates from "./Dates";

import styles from "./Session.module.scss";
import { switchOnError } from "../AddRow/helper";

export type AvailableState = "pending" | "approved_by_hr" | "won";
type StateOption = { label: string; value: AvailableState };
const states: StateOption[] = [
  { label: "À inscrire", value: "approved_by_hr" },
  { label: "Inscrit/Réalisé", value: "won" },
];

export type ManualSessionData = {
  sessionStartDate?: string;
  sessionEndDate?: string;
  sessionCity?: string;
};

export type User = { fullName: string; uuid: string };

interface ManualSessionProps {
  trainingData: ManualTrainingData;
  initialSessionData?: ManualSessionData;
  desiredState?: AvailableState;
  isLoading?: boolean;
  target: "collection" | "plan";
  onSuccess: (data: {
    sessionData?: Required<ManualSessionData>;
    desiredState: AvailableState;
  }) => void;
}

const defaultState: Record<"collection" | "plan", AvailableState> = {
  collection: "pending",
  plan: "approved_by_hr",
};

const ManualImportSession = ({
  target,
  onSuccess,
  trainingData,
  initialSessionData,
  desiredState,
}: ManualSessionProps) => {
  const [sessionData, setSessionData] = useSetState<ManualSessionData>(initialSessionData);
  const [selectedState, selectState] = useState<StateOption["value"]>(
    desiredState ?? defaultState[target]
  );

  const setSessionDataKey = (key, value) => {
    const parsedValue = isEmpty(value) ? undefined : value;
    setSessionData({ ...sessionData, [key]: parsedValue });
  };

  const today = useMemo(() => moment(), []);
  const datesExplanation = useMemo(() => {
    if (isNil(sessionData.sessionEndDate)) return undefined;
    if (moment(sessionData.sessionEndDate).isAfter(today)) {
      return (
        <p className={styles.datesExplanation}>
          La date de fin de la session est dans le futur : les demandes seront placées dans l'onglet
          "Inscrit"
        </p>
      );
    } else {
      return (
        <p className={styles.datesExplanation}>
          La date de fin de la session est dans le passé : les demandes seront placées dans l'onglet
          "Réalisé"
        </p>
      );
    }
  }, [sessionData.sessionEndDate, today]);

  const submit = async () => {
    try {
      const requireInscription = selectedState === "won";
      if (requireInscription) {
        if (!sessionData.sessionCity) {
          throw new Error("address");
        }

        if (isNil(sessionData.sessionStartDate) || isNil(sessionData.sessionEndDate)) {
          throw new Error("dates");
        }

        if (moment(sessionData.sessionStartDate).isAfter(sessionData.sessionEndDate)) {
          throw new Error("invalidEndDate");
        }

        onSuccess({
          sessionData: {
            sessionEndDate: sessionData.sessionEndDate,
            sessionStartDate: sessionData.sessionStartDate,
            sessionCity: sessionData.sessionCity,
          },
          desiredState: selectedState,
        });
      } else {
        onSuccess({
          desiredState: selectedState,
        });
      }
    } catch (err) {
      switchOnError(err);
    }
  };

  return (
    <div className={styles.manualImportSessionModal}>
      <div className={styles.preview} aria-label="preview-formation">
        <p className={styles.recall}>Programme créé hors Skillup</p>
        <p>
          <strong>{trainingData.trainingName}</strong> / {[trainingData.sessionType]} -{" "}
          {modes[trainingData.sessionMode]} / {trainingData.trainingOrganizationName} -{" "}
          {`${trainingData.total * 0.01} € - ${trainingData.sessionDuration} heures`}
        </p>
      </div>

      {target === "plan" && (
        <>
          <p style={{ marginLeft: "20px" }}>Ajouter dans l'onglet</p>
          <div className={styles.pickState} aria-label="sélection-onglet">
            {states.map((option) => (
              <RadioButton
                key={option.value}
                option={option}
                select={() => {
                  selectState(option.value);
                  setSessionData({ sessionEndDate: undefined, sessionStartDate: undefined });
                }}
                isSelected={selectedState === option.value}
              />
            ))}
          </div>

          {selectedState === "won" && (
            <>
              <Dates sessionData={sessionData} setSessionData={setSessionData} />
              {datesExplanation}
              <TextInput
                label="Lieu"
                alwaysOpen
                required
                showRequired
                defaultValue={sessionData.sessionCity}
                name="sessionCity"
                onChange={(e) => setSessionDataKey("sessionCity", e.currentTarget.value)}
                autoComplete="off"
                style={{ marginLeft: "20px" }}
              />
            </>
          )}
        </>
      )}
      <div className={styles.nextStep}>
        <InteractiveButton label="Suivant" onClick={submit} />
      </div>
    </div>
  );
};

const RadioButton = ({
  isSelected,
  option,
  select,
}: {
  isSelected: boolean;
  option: { label: string; value: string };
  select: (string) => void;
}) => {
  return (
    <div className={styles.radioButton} aria-label={`onglet-${option.label}`}>
      <div
        className={cx(styles.option, { [styles.selected]: isSelected })}
        onClick={() => select(option.value)}
      >
        {isSelected && <div className={styles.middleCircle} />}
      </div>
      <p>{option.label}</p>
    </div>
  );
};

export default ManualImportSession;
