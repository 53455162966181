import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { Flex } from "@skillup/ui";
import { DNDSectionItem } from "../../dndReducer";
import { SortablePage } from "../Page/SortablePage";

import styles from "../../TemplateBuilderStructure.module.scss";
import { useMemo } from "react";

type DraggableSection = DNDSectionItem & {
  type: "section";
  sectionIndex: number;
};

export const SortableSectionPages = ({
  section,
  disabled,
  disabledSortableChildren,
  lastSectionIndex,
}: {
  section: DraggableSection;
  disabled: boolean;
  disabledSortableChildren: boolean;
  lastSectionIndex: number;
}) => {
  const { pages, pagesIds } = useMemo(() => {
    if (!section) {
      return { pages: [], pagesIds: [] };
    }
    return { pages: section.pages, pagesIds: section.pages.map((page) => page.uuid) };
  }, [section]);
  return (
    <SortableContext items={pagesIds} disabled={disabled} strategy={verticalListSortingStrategy}>
      <Flex column className={styles["section__pages"]}>
        {pages.map((page, index) => (
          <SortablePage
            onlyOnePage={section.pages.length === 1}
            disabledSortableChildren={disabledSortableChildren}
            key={page.uuid}
            page={{
              ...page,
              type: "page",
              sectionIndex: section.sectionIndex,
              pageIndex: index,
            }}
          />
        ))}
      </Flex>
    </SortableContext>
  );
};
