import { useToggle } from "react-use";
import React, { useState } from "react";

import Acta from "utils/Acta";
import TextInput from "components/TextInput";
import type { SkillsRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";
import InteractiveButton from "components/InteractiveButton";

import styles from "./CreateSkillCategoryModal.module.scss";

type CreateCategoryRoute = SkillsRoutes["Skills.Categories.Create"];
const CreateSkillCategoryModal = () => {
  const [categoryLabel, setCategoryLabel] = useState<string>();
  const [categoryIndex, setCategoryIndex] = useState<string>();

  const [loading, toggleLoading] = useToggle(false);

  const createSkillCategory = async () => {
    toggleLoading(true);
    try {
      const creationRequest = buildRequest<CreateCategoryRoute>({
        method: "POST",
        path: "/competences/skills/category",
        payload: {
          index: parseInt(categoryIndex),
          label: categoryLabel,
        },
      });

      const response = await creationRequest();

      if (!response?.uuid) {
        throw new Error();
      }

      Acta.dispatchEvent("sendAppMessage", {
        type: "success",
        message: "Catégories ajouté.",
      });
      Acta.dispatchEvent("closeModal");
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        type: "error",
        message: "Echec lors de l'ajout de la catégorie.",
      });
    }
    toggleLoading(false);
  };

  return (
    <div className={styles.createSkillCategory}>
      <TextInput
        alwaysOpen
        label="Label"
        autoComplete="off"
        onChange={(e) => setCategoryLabel(e.currentTarget.value)}
      />
      <TextInput
        min={0}
        alwaysOpen
        type="number"
        label="Index"
        onChange={(e) => setCategoryIndex(e.currentTarget.value)}
      />
      <InteractiveButton label="Créer" loading={loading} onClick={createSkillCategory} />
    </div>
  );
};

export default CreateSkillCategoryModal;
