import { type CSSProperties } from "react";

import styled from "styled-components";

import { DSColor, EspaceFont } from "@skillup/ui";

import { translateSpacing } from "../utils/translateSpacing";

export type TitleInterface = Pick<CSSProperties, "color" | "margin" | "padding"> & {
  h5?: boolean;
  h4?: boolean;
  h3?: boolean;
  h2?: boolean;
  h1?: boolean;
  width?: string;
  collab?: boolean;
};

const TitleWrapper = styled.div<TitleInterface>`
  ${({ color }) =>
    color ? `color: ${DSColor[color]}` : `color: ${DSColor["plainText-onLight-default"]}`};
  & {
    ${({ h5 }) => h5 && EspaceFont.rh.h5}
    ${({ collab, h5 }) => h5 && collab && EspaceFont.collab.h5}
    ${({ h4 }) => h4 && EspaceFont.rh.h4}
    ${({ collab, h4 }) => h4 && collab && EspaceFont.collab.h4}
    ${({ h3 }) => h3 && EspaceFont.rh.h3}
    ${({ collab, h3 }) => h3 && collab && EspaceFont.collab.h3}
    ${({ h2 }) => h2 && EspaceFont.rh.h2}
    ${({ collab, h2 }) => h2 && collab && EspaceFont.collab.h2}
    ${({ h1 }) => h1 && EspaceFont.rh.h1}
    ${({ collab, h1 }) => h1 && collab && EspaceFont.collab.h1}

    ${({ margin }) => margin && `margin: ${translateSpacing(margin)}`};
    ${({ padding }) => padding && `padding: ${translateSpacing(padding)}`};

    ${({ width }) => width && `width: ${width}`};
  }
`;

export default TitleWrapper;
