import once from "lodash/once";

const init = once(
  () =>
    new Promise((resolve) => {
      const url = "https://static.hotjar.com/c/hotjar-";

      // @ts-ignore
      window.hj =
        // @ts-ignore
        window.hj ||
        function () {
          // @ts-ignore
          (window.hj.q = window.hj.q || []).push(arguments);
        };

      // @ts-ignore
      window._hjSettings = { hjid: 2720862, hjsv: 6 };
      const a = document.getElementsByTagName("head")[0];
      const r = document.createElement("script");
      // @ts-ignore
      r.async = 1;
      // @ts-ignore
      r.src = `${url + window._hjSettings.hjid}.js?sv=${
        // @ts-ignore
        window._hjSettings.hjsv
      }`;
      a.appendChild(r);

      // @ts-ignore
      resolve();
    })
);

export default {
  init,
};
