import styled from "styled-components";

import { DSColor, EspaceFont, SkuiSpacing } from "@skillup/ui";

import { Flex, Text } from "../../styled";

export const BoardLayout = styled(Flex)`
  flex: 1;
  flex-direction: column;
  border-radius: ${SkuiSpacing.s};
  background-color: ${DSColor["surface-light"]};
  padding: ${SkuiSpacing.s};
  color: ${DSColor["plainText-onLight-default"]};
  ${EspaceFont.rh.captionRegular};
  border: 1px solid ${DSColor["border-onLight"]};
`;

export const CategoryName = styled(Flex)`
  flex: 1;
  color: ${DSColor["plainText-onLight-lighter"]};
  margin-top: ${SkuiSpacing.s};
  ${EspaceFont.rh.h6};
`;

export const EmptyContainer = styled(Flex)`
  flex: 1;
  min-width: 33.33%;
`;

export const EvaluationLevelContainer = styled(Flex)`
  flex: 2;
`;

export const SkillRow = styled(Flex)<{ isSelected: boolean }>`
  margin-top: ${SkuiSpacing.xs};
  align-items: center;
  flex-direction: row;
  border: 2px solid transparent;
  transition: border-color 0.3s ease-in-out;
  ${({ isSelected }) => isSelected && `border-color: ${DSColor["action-onLight-default"]}`};
  border-radius: ${SkuiSpacing.xxs};
  padding: ${SkuiSpacing.xxs};
`;

export const SkillContainer = styled(Flex)`
  flex: 1;
  align-items: center;
  margin-right: ${SkuiSpacing.xs};
`;

export const LevelContainer = styled(Flex)`
  flex: 2;
`;

export const LevelName = styled(Flex)`
  min-width: 0%;
  flex: 1;
  padding: ${SkuiSpacing.xs};
  padding-bottom: 0;
  margin-left: ${SkuiSpacing.xs};
  justify-content: center;
  align-items: center;
  ${EspaceFont.rh.captionRegular};
`;

export const EllipsisText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const CenteredEllipsisText = styled(EllipsisText)`
  text-align: center;
`;

export const LinkEllipsisText = styled(EllipsisText)`
  cursor: pointer;
  text-decoration: underline;
`;

export const LevelBlock = styled(Flex)<{ isAboveExpectedLevel: boolean }>`
  flex: 1 1 0;
  height: 36px;
  background-color: ${({ isAboveExpectedLevel }) =>
    isAboveExpectedLevel ? DSColor["decoration-yellow-lighter"] : DSColor["greyscale-lighter"]};
  justify-content: center;
  align-items: center;
  margin-left: ${SkuiSpacing.xxs};
  padding: ${SkuiSpacing.xs};
  cursor: pointer;
  border-radius: ${SkuiSpacing.xxs};
`;

export const EmployeeNumber = styled(Text)<{ isMostAssigned?: boolean }>`
  ${({ isMostAssigned }) =>
    isMostAssigned ? EspaceFont.rh.body1Bold : EspaceFont.rh.body1Regular};
  color: ${({ isMostAssigned }) =>
    isMostAssigned ? DSColor["plainText-onLight-default"] : DSColor["plainText-onLight-lighter"]};
`;

export const SidePanelLevelName = styled(Flex)`
  flex-direction: column;
  margin-bottom: ${SkuiSpacing.xs};
  color: ${DSColor["plainText-onLight-lighter"]};
  ${EspaceFont.rh.body2Regular};
`;

export const Legend = styled.span`
  width: 16px;
  height: 8px;
  border-radius: 12px;
  margin-right: ${SkuiSpacing.xxs};
`;

export const LowerLegend = styled(Legend)`
  background-color: ${DSColor["greyscale-light"]};
`;

export const UpperLegend = styled(Legend)`
  background-color: ${DSColor["decoration-yellow-lighter"]};
`;
