import { Either } from "@skillup/monads";
import DataLayer from "utils/DataLayer";

export type PartialPostponeParams = {
  rowsUuid: string[];
  comment?: string;
};
export async function partialPostpone({rowsUuid, comment}: PartialPostponeParams) {
  try {
    await DataLayer.request({
      body: JSON.stringify({ rows: rowsUuid, comment }),
      method: "POST",
      url: `/v1/scheduleRow/postpone`,
    });

    return Either.right(undefined);
  } catch (err) {
    return Either.left(undefined);
  }
}