import * as XLSX from "xlsx";

export const createXlsx = (rows: unknown[], headers: { [key: string]: string }) => {
  const workbook = XLSX.utils.book_new();

  const columns = Object.keys(headers);

  const worksheet = XLSX.utils.json_to_sheet(rows, { header: columns });

  const columnNames = Object.values(headers);

  XLSX.utils.sheet_add_aoa(worksheet, [columnNames], { origin: "A1" });

  fitHeaderSizes(worksheet, columnNames);

  XLSX.utils.book_append_sheet(workbook, worksheet, "Feuille1");

  const excelBuffer = XLSX.write(workbook, { type: "array", bookType: "xlsx" });

  return new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
};

const fitHeaderSizes = (
  worksheet: XLSX.WorkSheet,
  columnNames: string[],
  additionalPadding = 4
) => {
  const headerLengths = Object.values(columnNames).map((header) => header.length);

  worksheet["!cols"] = headerLengths.map((w) => ({ wch: w + additionalPadding }));
};
