import { useMemo } from "react";

import {
  DSFormGroupTextInput,
  DSSimpleTextArea,
  DSTextInput,
  DSNumberInput,
  Select,
} from "@skillup/ui";
import { type IParsedField, type TrainingFields } from "@skillup/training-bridge";
import { DatePicker } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";

import styles from "./CustomFieldsRenderer.module.scss";
import { IForm } from "containers/Supervisor/routes/User/components/UserForm";

export interface FieldComponentProps {
  readonly translationPrefix: string;
  readonly field: IParsedField;
  readonly className?: string;
  readonly form: IForm;
  readonly setForm: (patch: Partial<IForm> | ((prevState: IForm) => Partial<IForm>)) => void;
}

export interface Props extends Omit<FieldComponentProps, "field"> {
  readonly fieldsToDisplay: TrainingFields;
}

const validFieldTypes: IParsedField["type"][] = [
  "select",
  "textarea",
  "text",
  "monetary",
  "number",
  "date",
];

const FieldComponent = ({
  field,
  form,
  setForm,
  translationPrefix,
  className,
}: FieldComponentProps) => {
  const { t } = useTranslation();
  const isValidType = validFieldTypes.includes(field.type);

  const renderedField = useMemo(() => {
    const handleChange = (field: IParsedField, value: string | Date): void => {
      setForm({
        ...form,
        [field.binding]: { value },
      });
    };

    switch (field.type) {
      case "select":
        const options =
          field.options?.map((option) => ({ label: option.value, value: option.key })) || [];

        return (
          <Select
            value={form[field.binding]?.value}
            options={options}
            placeholder={t(`${translationPrefix}.field.placeholder.${field.binding}`, {
              defaultValue: "Choisir une option",
            })}
            onChange={(value) => handleChange(field, options.find((o) => o.value === value)?.value)}
          />
        );
      case "textarea":
        return (
          <DSSimpleTextArea
            name={field.binding}
            placeholder={t(`${translationPrefix}.field.placeholder.${field.binding}`, {
              defaultValue: "Saisir un texte",
            })}
            value={form?.[field.binding]?.value ?? ""}
            onChange={(value) => handleChange(field, value)}
            rows={2}
          />
        );
      case "text":
        return (
          <DSTextInput
            name={field.binding}
            className={styles.textArea}
            placeholder={t(`${translationPrefix}.field.placeholder.${field.binding}`, {
              defaultValue: "Saisir un texte",
            })}
            value={form?.[field.binding]?.value ?? ""}
            onChange={(value) => handleChange(field, value)}
          />
        );
      case "monetary":
      case "number":
        return (
          <DSNumberInput
            name={field.binding}
            placeholder={t(`${translationPrefix}.field.placeholder.${field.binding}`, {
              defaultValue: "Saisir un nombre",
            })}
            value={
              form?.[field.binding]?.value ? parseFloat(form?.[field.binding]!.value!) : undefined
            }
            onChange={(value) => handleChange(field, value)}
          />
        );
      case "date":
        return (
          <DatePicker
            className={styles.datePicker}
            value={
              (form?.[field.binding]?.value
                ? new Date(form?.[field.binding]?.value)
                : undefined) as never
            }
            onChange={(date) => handleChange(field, date)}
          />
        );
      default:
        return null;
    }
  }, [setForm, t, field, form, translationPrefix]);

  if (!isValidType) return null;

  return (
    <>
      <DSFormGroupTextInput
        label={t(`${translationPrefix}.field.label.${field.binding}`, {
          defaultValue: field.label,
        })}
        className={className}
      >
        {renderedField}
      </DSFormGroupTextInput>
    </>
  );
};

const CustomFieldsRenderer = ({ fieldsToDisplay, form, setForm, translationPrefix }: Props) => (
  <>
    {Object.values(fieldsToDisplay).map((field, index) => (
      <FieldComponent
        className={styles.customField}
        key={index}
        field={field}
        translationPrefix={translationPrefix}
        form={form}
        setForm={setForm}
      />
    ))}
  </>
);

export default CustomFieldsRenderer;
