import React from "react";
import { useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";

import GetBaseURLFromLocation from "utils/GetBaseURLFromLocation";

import styles from "./Skills.module.scss";

export default ({ entities }) => {
  const { pathname } = useLocation();

  if (isEmpty(entities)) return null;

  const baseURL = GetBaseURLFromLocation(pathname);
  const skills = orderBy(entities, ["nameCount", "programCount"], ["desc", "desc"]);

  return (
    <ul className={styles.Skills}>
      {skills.map((skill) => (
        <li key={skill.uuid}>
          <a href={`${baseURL}/programmes/catalogue/formations-entity-${skill.slug}`}>
            {skill.shape}
          </a>
        </li>
      ))}
    </ul>
  );
};
