import React, { useMemo, type ReactNode, useCallback, type HTMLAttributes } from "react";

import cx from "classnames";
import { isArray } from "lodash";

import { type Theme } from "@mui/material";

import { useTheme } from "context/themes";

import { Close } from "../../commons";

import styles from "./Chip.module.scss";

export interface ChipProps<T = void> extends HTMLAttributes<HTMLDivElement> {
  readonly value?: T;
  readonly label: string;
  readonly muiTheme?: Theme;
  readonly icon?: ReactNode;
  readonly darkMode?: boolean;
  readonly dismissible?: boolean;
  readonly displayValue?: boolean;
  readonly renderValue?: (value: T) => string;
  readonly onClick?: (event: React.MouseEvent) => void;
  readonly onDelete?: (event: ChipDeleteEvent<T>) => void;
}
export type ChipDeleteEvent<T> = {
  value?: T;
  label: string;
};
// TODO make imcompatible properties mix impossible (if "dismissible" is true, "onDelete" must be defined, if not, the compiler should throw an error)
export function Chip<T = void>({
  className,
  darkMode = false,
  dismissible = false,
  displayValue = false,
  icon,
  label,
  onClick,
  onDelete,
  renderValue,
  value,
  ...props
}: ChipProps<T>) {
  const theme = useTheme();

  const renderedValue = useMemo(() => {
    // We don't handle null value at the moment (display value is conditionned by if (value))
    if (value === undefined || value === null) {
      return "";
    }
    if (renderValue) {
      return renderValue(value);
    }
    if (typeof value == "string") {
      return value;
    }
    if (typeof value == "number") {
      return value.toString();
    }
    if (typeof value == "boolean") {
      return value ? "Oui" : "Non";
    }
    if (isArray(value)) {
      return value.join(", ");
    }
    return "";
  }, [value, renderValue]);

  const handleOnDelete = useCallback(() => {
    if (onDelete) {
      if (value) {
        onDelete({ label, value });
        return;
      }
      onDelete({ label });
    }
  }, [onDelete, label, value]);

  return (
    <div
      aria-label={`chip-${label}`}
      className={cx(styles.Chip, className, styles[theme], {
        [styles.darkMode]: darkMode,
        [styles.hasIcon]: icon,
      })}
      {...props}
    >
      {icon && <div className={styles.icon}>{icon}</div>}

      <div className={styles.textContainer}>
        <span className={styles.label}>{label}</span>
        {value && displayValue && <span className={styles.value}>{renderedValue}</span>}
      </div>

      {dismissible && (
        <button
          aria-label={`chip-button-${label}`}
          className={cx(styles.dismissButton)}
          onClick={handleOnDelete}
        >
          <Close aria-label="Fermer" />
        </button>
      )}
    </div>
  );
}
