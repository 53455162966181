import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { TextDropdown } from "@skillup/ui";
// TODO, check behaviour
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

import styles from "./OpenRows.module.scss";
import User from "utils/User";

interface State {
  uploading: boolean;
}

export default function OpenRows() {
  const [state, setState] = useState<State>({ uploading: false });
  const [optionSelected, setOptionSelected] = useState(null);
  const {
    activeCompany: { schedules },
  } = User.getUserData();
  const options = schedules.map(({ name, uuid }) => ({ label: name, value: uuid }));

  const onDrop = async (files) => {
    if (!optionSelected) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Vous devez choisir un schedule dans lequel uploader.",
        type: "error",
      });
      return;
    }

    setState({ uploading: true });

    const body = new FormData();
    body.append("file", files[0]);

    try {
      await DataLayer.request({
        body,
        contentType: "multipart/form-data",
        method: "POST",
        url: `/v1/scheduleRow/upload/open-rows/${optionSelected?.value}`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "Fichier envoyé.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de l’envoi de votre fichier.",
        type: "error",
      });
    } finally {
      setState({ uploading: false });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 52428800,
    multiple: false,
  });

  return (
    <div className={styles.OpenRows}>
      <section className={styles.dropDown}>
        <TextDropdown
          placeholder="Plan dans lequel importer"
          options={options}
          selectedIndex={options.findIndex((option) => option.value === optionSelected?.value)}
          onSelect={setOptionSelected}
        />
      </section>
      <section className={styles.dropZone}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {state.uploading ? (
            <p>Processing en cours...</p>
          ) : (
            <p>Déposez un fichier ici ou cliquez ici pour choisir un fichier</p>
          )}
        </div>
      </section>
    </div>
  );
}
