import React, { type ReactNode } from "react";

import GridWrapper, { type GridInterface } from "./GridWrapper";

export type SkillGridProps = {
  className?: string;
  children: ReactNode | undefined;
} & GridInterface;

export const Grid = ({ children, ...props }: SkillGridProps) => {
  return <GridWrapper {...props}>{children}</GridWrapper>;
};
