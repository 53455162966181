import { CampaignsRoutes } from "@skillup/people-review-bridge";

export function useCampaignPermissions(
  campaignPermissions: CampaignsRoutes.GetDetails["response"]["permissions"]
) {
  const canSeeCampaignStateButtons =
    campaignPermissions?.close.isVisible ||
    campaignPermissions?.resume.isVisible ||
    campaignPermissions?.pause.isVisible;

  return {
    canSeeCampaignStateButtons,
    ...campaignPermissions,
  };
}
