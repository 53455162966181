import { useState } from "react";
import { AppearanceTypes, useToasts } from "react-toast-notifications";
import DataLayer from "utils/DataLayer";
import useTranslation from "hooks/useTranslation";
import { ReviewStatus, TrainingUtils } from "@skillup/training-bridge";
import { DSButton } from "@skillup/ui";

interface Props {
  readonly status: ReviewStatus;
  readonly reviewUuid: string;
  readonly invalidateData: () => void;
}

export function MainAction({ status, reviewUuid, invalidateData }: Props) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { canSendReviewRecall } = TrainingUtils;

  const toastBuilder = (tSuffix: string, value: string, succes: AppearanceTypes) => {
    addToast(
      t(`trainings.manage.reviews.toast.review.${tSuffix}`, {
        defaultValue: value,
      }),
      {
        appearance: succes,
        autoDismiss: true,
      }
    );
  };

  const sendTraineeRecallMail = async (): Promise<void> => {
    try {
      setLoading(true);

      await DataLayer.request({
        target: "API",
        method: "POST",
        url: "/v1/review/trainee-recall",
        body: JSON.stringify([reviewUuid]),
      });

      switch (status) {
        case ReviewStatus.PENDING:
          invalidateData();
          toastBuilder("reminder.sent", "L'email a bien été envoyé", "success");
          setLoading(false);
          break;
        case ReviewStatus.SUBMITTED:
        case ReviewStatus.ABANDONED:
        case ReviewStatus.CANCELLED:
          invalidateData();
          toastBuilder("invitation.sent", "L'email a bien été envoyé", "success");
          setLoading(false);
      }
    } catch (err) {
      toastBuilder("invitation.error", "L'email n'a pas pu être envoyé", "error");
      setLoading(false);
    }
  };

  const showLabelByStatus = (status: ReviewStatus) => {
    switch (status) {
      case ReviewStatus.PENDING:
        return t("trainings.manage.reviews.sidepanel.button.ask.to.review", {
          defaultValue: "Inviter à évaluer",
        });
      case ReviewStatus.SUBMITTED:
      case ReviewStatus.ABANDONED:
      case ReviewStatus.CANCELLED:
        return t("trainings.manage.reviews.sidepanel.button.send.reminder", {
          defaultValue: "Envoyer un email de relance",
        });
      default:
        return "";
    }
  };

  if (canSendReviewRecall(status)) {
    return (
      <DSButton
        darkMode
        label={showLabelByStatus(status)}
        buttonSize="M"
        onClick={sendTraineeRecallMail}
        loading={loading}
      />
    );
  }

  return <></>;
}
