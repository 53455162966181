import { DSAlert, DSAlertDisplay, DSAlertType, DSButton, Loader } from "@skillup/ui";

import { DashboardDataContextProvider, useDashboardSettings } from "./useDashboardContext";
import DSLayout from "components/DSLayout";

import DashboardIndicator from "./partials/DashboardIndicator/DashboardIndicator";

import styles from "./TrainingDashboard.module.scss";
import Filters from "./partials/Filters/Filters";

const Dashboard = () => {
  return (
    <DashboardDataContextProvider>
      <DashboardLayout />
    </DashboardDataContextProvider>
  );
};

const DashboardLayout = () => {
  const { layout, lastUpdate, isWaitingRefresh, isFetching, isError, reload } =
    useDashboardSettings();

  return (
    <DSLayout title="Dashboard - Formation bêta" className={styles.Dashboard}>
      <div className={styles.actions}>
        {lastUpdate && (
          <p>
            Dernière mise à jour le {lastUpdate.toLocaleDateString()}{" "}
            {lastUpdate.toLocaleTimeString()}
          </p>
        )}
        <DSButton
          emphasis="Mid"
          label="Rafraichir les données"
          loading={isFetching || isWaitingRefresh}
          disabled={isFetching || isWaitingRefresh}
          onClick={reload}
        />
      </div>
      {isError && (
        <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE}>
          Une erreur est survenue lors du chargement des données
        </DSAlert>
      )}
      {isFetching && !layout && (
        <div>
          Chargement... <Loader />
        </div>
      )}
      {!isError && layout && (
        <>
          {/* <div className={cx(styles.header, styles.card)}>
              <h2>Indicateurs clés</h2>
              <KeyFigures
                // TODO: retreive data from api
                figures={[
                  { label: "Nombre de collaborateurs actifs", value: "213" },
                  { label: "Nombre de collaborateurs formés", value: "110" },
                  { label: "Nombre de collaborateurs actifs formés", value: "86" },
                  { label: "Pourcentage de collaborateurs actifs formés", value: "40%" },
                ]}
              />
            </div> */}
          <div className={styles.filters}>
            <Filters />
          </div>
          <div className={styles.content}>
            {layout?.map((item) => (
              // @ts-expect-error string is valid for size
              <DashboardIndicator key={item.name} item={item} />
            ))}
          </div>
        </>
      )}
    </DSLayout>
  );
};

export default Dashboard;
