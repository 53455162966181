import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useState } from "react";

import { CampaignsRoutes, DashboardCampaignStructure } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import history from "utils/history";

const getCampaignDashboardData = async (campaingUuid: string, userAreas?: string[]) => {
  const result = await buildRequest<CampaignsRoutes.Dashboard.GetCampaign>({
    method: "GET",
    path: "/interviews/dashboard/campaign/{uuid}",
    params: {
      uuid: campaingUuid,
    },
    query: {
      areas: userAreas?.join(","),
    },
  })();

  return result;
};
export interface Context {
  activeCampaign: string | undefined;
  setActiveCampaign: (uuid: string) => void;
  campaignStructure: DashboardCampaignStructure["sections"];
  userAreas: string[] | undefined;
  setUserAreas: (areas: string[] | undefined) => void;
  dashboardData: { [campaingUuid: string]: CampaignsRoutes.Dashboard.GetCampaign["response"] };
  isLoading: boolean;
  isError?: any;
}

export const DashboardDataContext = React.createContext<Context>({
  activeCampaign: undefined,
  setActiveCampaign: () => {},
  userAreas: undefined,
  setUserAreas: () => {},
  isLoading: false,
  dashboardData: undefined,
  campaignStructure: [],
});

export interface Props {
  children: React.ReactNode;
}
export const DashboardDataContextProvider = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<any>(undefined);
  const [activeCampaign, setActiveCampaign] = useState<string>();
  const [userAreas, setUserAreas] = useState<string[]>(undefined);
  const [dashboardData, setDashboardData] = useState<{
    [campaingUuid: string]: CampaignsRoutes.Dashboard.GetCampaign["response"];
  }>({});
  const campaignStructure = useMemo(() => {
    const activeCampaignData = dashboardData?.[activeCampaign];
    const sections = activeCampaignData?.structure?.sections || [];
    const sectionsWithDashboardCard = sections
      .map((section) => ({
        ...section,
        pages: section.pages
          .map((page) => ({
            ...page,
            children: page.children.filter((child) => child.type),
          }))
          .filter((page) => page.children.length),
      }))
      .filter((section) => section.pages.length);

    const defaultPageTitle = "Nouvelle page";
    const sectionsWithoutDefaultTitle = sectionsWithDashboardCard.map((section) => ({
      ...section,
      pages:
        section.pages.length === 1 && section.pages[0].title === defaultPageTitle
          ? section.pages.map((page) => ({
              ...page,
              title: "",
            }))
          : section.pages,
    }));

    return sectionsWithoutDefaultTitle;
  }, [dashboardData, activeCampaign]);

  const changeActiveCampaign = useCallback(
    async (campaingUuid: string | undefined) => {
      if (!campaingUuid) return;
      setIsLoading(true);
      try {
        const data = await getCampaignDashboardData(campaingUuid, userAreas);
        if (!data) {
          history.push("/responsable/dashboard/interview");
        } else {
          setDashboardData({
            ...dashboardData,
            [campaingUuid]: data,
          });
        }
      } catch (e) {
        setIsError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [dashboardData, setDashboardData, userAreas, setIsLoading, setIsError]
  );

  useEffect(() => {
    setIsLoading(true);
    changeActiveCampaign(activeCampaign)
      .catch(({ error }) => {
        setIsError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCampaign, userAreas]);

  const value = {
    activeCampaign,
    setActiveCampaign,
    userAreas,
    setUserAreas,
    isLoading,
    isError,
    dashboardData,
    campaignStructure,
  };

  return (
    <DashboardDataContext.Provider value={value}>{props.children}</DashboardDataContext.Provider>
  );
};

export function useDashboardData() {
  const context = useContext(DashboardDataContext);

  if (context === undefined) {
    throw new Error("useDashboardData must be used within a DashboardDataContext");
  }

  return context;
}
