import React from "react";
import { useRecoilState } from "recoil";
import { omit } from "lodash";
import { v4 as uuid } from "uuid";
import { Icon } from "@skillup/ui";
import { selectedPageDataState } from "../store";
import { Child } from "../types";

// Sub components
import availableChildren from "../PageSettings/availableChildren";
import TargetsBlock from "./TargetsBlock";
import GuidelinesBlock from "./GuidelinesBlock";
import PersonalisableGuidelinesBlock from "./PersonalisableGuidelines";
import TitleBlock from "./TitleBlock";
import HTMLBlock from "./HTMLBlock";
import InterviewsHistoryBlock from "./InterviewsHistoryBlock";
import TrainingsHistoryBlock from "./TrainingsHistoryBlock";
import TextQuestionBlock from "./TextQuestionBlock";
import CheckboxQuestionBlock from "./CheckboxQuestionBlock";
import RadioQuestionBlock from "./RadioQuestionBlock";
import TableBlock from "./TableBlock";
import TrainingsCollectionBlock from "./TrainingsCollectionBlock";
import FilesUploadBlock from "./FilesUploadBlock";

import TransferToAnotherPage from "./TransferToAnotherPage";

import styles from "./Child.module.scss";
import SkillsBlock from "./SkillsBlock";
import GlobalTargetsCompletionBlock from "./GlobalTargetsCompletionBlock";
import UserSpecificDataBlock from "./UserSpecificDataBlock";
import { JobBlock } from "./JobBlock";

interface ChildProps {
  childIndex: number;
}

const getChildContent = (child: Child, updateChild: (newChildData: Child) => void) => {
  switch (child.kind) {
    case "guidelines":
      return <GuidelinesBlock data={child} updateChild={updateChild} />;
    case "personnalisableGuidelines":
      return <PersonalisableGuidelinesBlock data={child} updateChild={updateChild} />;
    case "title":
      return <TitleBlock data={child} updateChild={updateChild} />;
    case "html":
      return <HTMLBlock data={child} updateChild={updateChild} />;
    case "interviewsHistory":
      return <InterviewsHistoryBlock data={child} updateChild={updateChild} />;
    case "trainingsHistory":
      return <TrainingsHistoryBlock data={child} updateChild={updateChild} />;
    case "targets":
      return <TargetsBlock data={child} updateChild={updateChild} />;
    case "globalTargetsCompletion":
      return <GlobalTargetsCompletionBlock data={child} updateChild={updateChild} />;
    case "trainingsCollection":
      return <TrainingsCollectionBlock data={child} updateChild={updateChild} />;
    case "userSpecificData":
      return <UserSpecificDataBlock data={child} updateChild={updateChild} />;
    case "question":
      if (child.type === "text") {
        return <TextQuestionBlock data={child} updateChild={updateChild} />;
      } else if (child.type === "checkbox") {
        return <CheckboxQuestionBlock data={child} updateChild={updateChild} />;
      } else if (child.type === "radio") {
        return <RadioQuestionBlock data={child} updateChild={updateChild} />;
      }
      return <p>À CONSTRUIRE</p>;
    case "table":
      return <TableBlock data={child} updateChild={updateChild} />;
    case "upload":
      return <FilesUploadBlock data={child} updateChild={updateChild} />;
    case "skills":
      return <SkillsBlock data={child} updateChild={updateChild} />;
    case "job":
      return <JobBlock data={child} updateChild={updateChild} />;
    default:
      return <p>À CONSTRUIRE</p>;
  }
};

const ChildComponent = ({ childIndex }: ChildProps) => {
  const [pageData, updateSelectedPage] = useRecoilState(selectedPageDataState);
  const child = pageData.children[childIndex];
  const childTypeLabel = availableChildren.find((c) => c.kind === child.kind)?.label;

  const updateChild = (newChildData: Child) => {
    const newChildren = [...pageData.children];
    newChildren[childIndex] = newChildData;
    updateSelectedPage({ ...pageData, children: newChildren });
  };

  const duplicate = (childData: Child) => {
    const newChildren = [...pageData.children];
    newChildren.push({
      ...omit(childData, "uuid"),
      uuid: uuid(),
    } as Child);
    updateSelectedPage({ ...pageData, children: newChildren });
  };

  const deleteChild = () => {
    const newChildren = pageData.children.filter((c) => c.uuid !== child.uuid);
    updateSelectedPage({ ...pageData, children: newChildren });
  };

  const content = getChildContent(child, updateChild);
  return (
    <div
      key={child.uuid}
      className={styles.child}
      aria-label={`Paramétrage block ${child.kind} ${childIndex}`}
    >
      <div className={styles.duplicate} aria-label="Dupliquer block">
        <Icon.Plus
          size={20}
          onClick={() => {
            const confirmation = window.confirm("Dupliquer ce child ?");
            if (confirmation) duplicate(child);
          }}
        />
      </div>
      <div className={styles.delete} aria-label="Supprimer block">
        <Icon.Trash
          size={20}
          onClick={() => {
            const confirmation = window.confirm("Êtes-vous sûr ?");
            if (confirmation) deleteChild();
          }}
        />
      </div>
      <span className={styles.kind}>
        {childTypeLabel ?? child.kind}
        {
          // @ts-ignore
          child?.type ? `  ${child?.type}` : undefined
        }
      </span>
      {content}
      <div className={styles.footer}>
        <TransferToAnotherPage child={child} />
      </div>
    </div>
  );
};

export default ChildComponent;
