import styled from "styled-components";

import { Select, DSModal, DSButton, DSFilters, DSTextInput, SkuiSpacing } from "@skillup/ui";

export const DSFiltersStyled = styled(DSFilters)`
  height: 68px;
`;

export const MassAssignButton = styled(DSButton)`
  margin-bottom: ${SkuiSpacing.s};
`;

export const SearchInput = styled(DSTextInput)`
  input {
    width: 255px;
    border-color: $border-onLight;
    font-size: $skui-spacing-s;
    &::placeholder {
      font-style: italic;
    }
  }
  svg {
    fill: $plainText-onLight-lighter;
    height: $skui-spacing-m;
    width: $skui-spacing-m;
    margin-left: -$skui-spacing-l;
  }
`;

export const AssignModal = styled(DSModal)`
  width: 35rem;
`;

export const AssignModalSelect = styled(Select)`
  width: 100%;
`;
