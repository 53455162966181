import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

export default async (
  rows,
  reminder,
  scheduleUuid: string,
  reloadRows: (uuids: string[]) => void
) => {
  try {
    const body = JSON.stringify({ rows, reminder });

    await DataLayer.request({
      body,
      method: "POST",
      url: `/v1/schedule/action/${scheduleUuid}`,
    });

    reloadRows(rows);

    Acta.dispatchEvent("closeModal");

    Acta.dispatchEvent("sendAppMessage", {
      message: rows.length === 1 ? "Email envoyé avec succès." : "Emails envoyés avec succès.",
      type: "success",
    });
  } catch (error) {
    const details = error.message;
    if (details.isMissingConfiguration) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Colonne E-mail non associée",
        type: "error",
      });
    }
    if (details.isMissingEmail) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "E-mails manquants !",
        type: "error",
      });
    }
  }
};
