import { buildRequest } from "utils/buildRequest";
import { IntraRoutes } from "types/api";

export const fetchIntra = async (intraUuid: string) => {
  const request = await buildRequest<IntraRoutes.GET_BY_UUID>({
    path: "/intra/{intraUuid}",
    method: "GET",
    params: { intraUuid },
  });

  return request();
};
