import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";

import { DSModal } from "@skillup/ui";
import { ScheduleOperationsRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

import { Schedule } from "../../PortalSchedules";

import styles from "../../PortalSchedules.module.scss";

type Props = {
  schedule: Schedule;
  oldActivePlanName: string;
  onClose: (needRefresh?: boolean) => void;
};

export default function ScheduleActivateModal({ schedule, oldActivePlanName, onClose }: Props) {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation({
    mutationFn: async () =>
      buildRequest<ScheduleOperationsRoutes.Activate>({
        method: "POST",
        path: "/schedule/{scheduleUuid}/operations/activate",
        params: { scheduleUuid: schedule.uuid },
      })(),
  });

  const handleSubmit = () => {
    mutate(null, {
      onSuccess: () => {
        addToast(t("app.success.message", { defaultValue: "Opération réussie" }), {
          appearance: "success",
        });
        onClose(true);
      },
      onError: () => {
        addToast(t("app.errors.message"), { appearance: "error" });
      },
    });
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("supervisor.portalschedules.modal.activate.title", {
            defaultValue: "Remplacer le plan actuellement actif ?",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <p className={styles.modalContent}>
          {t("supervisor.portalschedules.modal.activate.description", {
            new: schedule.name,
            old: oldActivePlanName,
            defaultValue:
              "Il ne peut y avoir qu’un seul plan actif.\n\nSi vous décidez de rendre actif « {{new}} », le plan actuellement actif (« {{old}} ») sera rendu inactif.",
          })}
        </p>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("app.modal.action.cancel", {
            defaultValue: "Annuler",
          })}
          onClick={() => onClose()}
        />
        <DSModal.Footer.PrimaryButton
          label={t("app.modal.action.confirm", {
            defaultValue: "Confirmer",
          })}
          onClick={handleSubmit}
          loading={isLoading}
        />
      </DSModal.Footer>
    </DSModal>
  );
}
