import { Loader } from "@skillup/ui";
import { useMemo } from "react";

import AdminPortalToggle, {
  CompanyAdminPayload,
  PortalToggleType,
} from "../AdminPortalToggle/AdminPortalToggle";
import useAdminProperties, { saveChanges } from "../state/useAdminProperties";

import styles from "./PortalContent.module.scss";

interface ContentTogglesProps {
  hasGroups: boolean;
}

export default function ContentToggles({ hasGroups }: ContentTogglesProps) {
  const { data, refetch, loading } = useAdminProperties();

  const adminProperties = useMemo(() => {
    return data?.properties ?? null;
  }, [data]);

  const handleOnChange = async (body: CompanyAdminPayload) => {
    await saveChanges(body);
    await refetch();
  };

  return (
    <div className={styles.contentToggles}>
      <div>
        <h3 id="section-content-toggles" className={styles.title}>
          Informations affichées
        </h3>
        {loading && <Loader />}
        {!loading && (
          <div className={styles.switchList}>
            <AdminPortalToggle
              propertiesKey="portalUsersPriceDisabled"
              label="Afficher les prix dans le portail collaborateurs"
              type={PortalToggleType.DISABLED}
              isSelected={adminProperties ? adminProperties["portalUsersPriceDisabled"] : false}
              onChange={handleOnChange}
            />
            <AdminPortalToggle
              propertiesKey="portalManagersPriceDisabled"
              label="Afficher les prix dans le portail manager"
              type={PortalToggleType.DISABLED}
              isSelected={adminProperties ? adminProperties["portalManagersPriceDisabled"] : false}
              onChange={handleOnChange}
            />
            <AdminPortalToggle
              propertiesKey="portalCPFDisabled"
              label="Afficher l'éligibilité au CPF dans les portails"
              type={PortalToggleType.DISABLED}
              isSelected={adminProperties ? adminProperties["portalCPFDisabled"] : false}
              onChange={handleOnChange}
            />
            <AdminPortalToggle
              propertiesKey="portalIntraReferencesEnabled"
              label="Afficher les IDs de programme dans les formations recommandées"
              type={PortalToggleType.ENABLED}
              isSelected={adminProperties ? adminProperties["portalIntraReferencesEnabled"] : false}
              onChange={handleOnChange}
            />
          </div>
        )}
      </div>

      <div>
        <h3 id="section-formations" className={styles.title}>
          Formations recommandées
        </h3>
        {loading && <Loader />}
        {!loading && (
          <div className={styles.switchList}>
            <AdminPortalToggle
              propertiesKey="portalListsDisabled"
              label="Activer les formations recommandées"
              type={PortalToggleType.DISABLED}
              isSelected={adminProperties ? adminProperties["portalListsDisabled"] : false}
              onChange={handleOnChange}
            />
            {hasGroups && (
              <AdminPortalToggle
                propertiesKey="portalTagsDisabled"
                label="Personnaliser les formations recommandées par groupe de collaborateurs"
                type={PortalToggleType.DISABLED}
                isSelected={adminProperties ? adminProperties["portalTagsDisabled"] : false}
                onChange={handleOnChange}
                description="Vos collaborateurs sont répartis en groupes. Leurs portails sont différenciés en fonction de l'appartenance à ces derniers."
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
