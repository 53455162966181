import { ModulesAccessList } from "@skillup/espace-rh-bridge";
import { ISettings, ModuleType } from "hooks/useSettings";

import User from "utils/User";

import Dashboards from "./Dashboards";
import Interviews from "./Interviews";
import Trainings from "./Trainings";
import Skills from "./Skills";
import Configuration from "./Configuration";

function getAccess(settings?: ISettings, userAccessRights?: ModuleType[]) {
  return {
    to: function (module: ModuleType): boolean {
      return (
        User.isSkillupDeveloper() ||
        !!(settings?.features?.includes(module) && userAccessRights?.includes(module))
      );
    },
  };
}

/**
 * @deprecated Use `useAccessCheckFor` and `useAccessCheckForList` from the useSettings.ts file instead
*/
export function createUserAccessChecker(settings?: ISettings, userAccessRights?: ModuleType[]) {
  const hasAccess = getAccess(settings, userAccessRights);

  const trainings = Trainings(hasAccess);
  const interviews = Interviews(hasAccess);
  return {
    Trainings: trainings,
    Interviews: interviews,
    Dashboards: Dashboards(hasAccess, trainings.toTrainings(), interviews.toInterviews()),
    Skills: Skills(hasAccess),
    Configuration: Configuration(hasAccess),

    toEmployees: () => hasAccess.to(ModulesAccessList.Employees),
    toCampaignCreation: () =>
      hasAccess.to(ModulesAccessList.CampaignCreation) ||
      (User.isSkillupAdmin() && hasAccess.to(ModulesAccessList.InterviewsTrackings)),

    toMyAccount: () => hasAccess.to(ModulesAccessList.MyAccount),
    toUsers: () => hasAccess.to(ModulesAccessList.Users),
  };
}
