import { UsersListProvider } from "components/AddTraineesModal/add-trainees-context";

import Acta from "utils/Acta";

import UserSelectionModal from "../../Components/UserSelectionModal";
import styles from "../../Components/UserSelectionModal.module.scss";

import { Project } from "../getProjects";
import linkUsersToProject from "../linkUsersToProject";
import { buildRegisteredUsers } from "../../Components/helper";
import { useAreaUserGroups } from "components/AddTraineesModal/helpers";

interface Props {
  project: Project;
  refetchList: VoidFunction;
  isPastProject: boolean;
}
function UserChoiceModal({ project, isPastProject, refetchList }: Props) {
  const groups = useAreaUserGroups();

  return (
    <UsersListProvider content={{ selectedUsers: buildRegisteredUsers(project) }}>
      <UserSelectionModal
        project={project}
        training={{
          city: project.properties.city,
          name: project.training.name,
          organization: project.training.organization,
          startDate: project.properties.startDate,
        }}
        isPastProject={isPastProject}
        onSubmit={async (users, doNotNotify) => {
          await linkUsersToProject(users, project, doNotNotify);
          refetchList();
        }}
        positionedUsers={project.positionnedTrainees}
        summonedUsers={project.summonedTrainees}
        customUsersLists={[
          {
            label: "En attente d'inscription",
            users: project.pendingTrainees,
          },
          ...(groups
            ? [
                {
                  label: "Sélectionner par périmètre",
                  groups,
                },
              ]
            : []),
        ]}
      />
    </UsersListProvider>
  );
}

const openUsersModal = async (project: Project, refetchList: VoidFunction) => {
  const isPastProject = project.properties.isPast;

  Acta.setState("modalDisplayed", {
    content: (
      <UserChoiceModal refetchList={refetchList} project={project} isPastProject={isPastProject} />
    ),
    size: "xLarge",
    title: "Ajouter des collaborateurs",
    containerClassName: styles.appModalContainer,
    dialogClassName: styles.appModalDialog,
  });
};

export default openUsersModal;
