import React, { type HTMLAttributes } from "react";

import { Themes } from "commons";
import { useDarkMode } from "context";
import { useTheme } from "context/themes";

import { type MaterialIcons } from "@skillup/ui";

import TooltipContainer from "./components/TooltipContainer";
import { Card, FirstRow, SecondRow, DynamicIcon, TooltipLabel } from "./KPI.styled";

type MaterialIconsType = keyof typeof MaterialIcons;
export interface KPIProps extends HTMLAttributes<HTMLDivElement> {
  readonly value: string;
  readonly label: string;
  readonly tooltip?: string;
  readonly icon: MaterialIconsType;
  readonly tooltipDirection?: "top" | "left" | "right" | "bottom";
}

const isEllipsisActive = (element: null | HTMLElement): boolean => {
  if (!element) return false;
  return element.offsetHeight < element.scrollHeight;
};

const KPI = ({ icon, label, tooltip, tooltipDirection, value }: KPIProps) => {
  const darkMode = useDarkMode();
  const labelDiv = document.getElementById("label");
  const displayLabelTooltip = isEllipsisActive(labelDiv);
  const theme = useTheme();

  return (
    <Card darkMode={darkMode} isRHTheme={theme === Themes.ESPACE_RH}>
      {tooltip && <TooltipContainer label={tooltip} darkMode={darkMode} />}
      <FirstRow darkMode={darkMode}>
        <DynamicIcon name={icon} size="3rem" />
        <span>{value}</span>
      </FirstRow>
      <SecondRow darkMode={darkMode} isRHTheme={theme === Themes.ESPACE_RH}>
        <TooltipLabel
          label={label}
          theme={theme}
          darkMode={darkMode}
          disabled={!displayLabelTooltip}
          direction={tooltipDirection ?? "top"}
        >
          <div id="label">{label}</div>
        </TooltipLabel>
      </SecondRow>
    </Card>
  );
};

export default KPI;
