import React from "react";
import { Toggle } from "@skillup/ui";

import { Child } from "../types";

import styles from "./Child.module.scss";
import DisallowBlock, { commentRestrictions } from "./DisallowBlock";

type GlobalTargetsCompletion = Child & { kind: "globalTargetsCompletion" };
const GlobalTargetsCompletionBlock = ({
  data,
  updateChild,
}: {
  data: GlobalTargetsCompletion;
  updateChild: (newChildData: Child) => void;
}) => {
  return (
    <form>
      <label>Pondération globale à 100 obligatoire</label>
      <Toggle
        className={styles.toggle}
        active={data.forcePercentWeight}
        onToggle={(forcePercentWeight) => updateChild({ ...data, forcePercentWeight })}
      />
      <label>Remplissage obligatoire</label>
      <Toggle
        className={styles.toggle}
        active={data.required}
        onToggle={(required) => updateChild({ ...data, required })}
      />
      <DisallowBlock data={data} updateChild={updateChild} restrictions={commentRestrictions} />
    </form>
  );
};

export default GlobalTargetsCompletionBlock;
