import { DSFormGroupTextInput, Modal } from "@skillup/ui";

import { Campaign } from "./useCampaignModals";

import styles from "./ParameterCampaignModal.module.scss";

interface IProps {
  readonly campaign: Campaign;
}
const typeEntretienLabel = {
  ongoing: "Entretiens en continu",
  legacy: "Campagne d'entretiens",
  adHoc: "Entretiens ponctuels",
};

export default ({ campaign }: IProps) => {
  return (
    campaign && (
      <Modal title="Paramètres de la campagne">
        <div className={styles.content}>
          <DSFormGroupTextInput label="Type d'entretiens">
            <span>{typeEntretienLabel[campaign.type]}</span>
          </DSFormGroupTextInput>

          {campaign.template && (
            <DSFormGroupTextInput label="Trame d'entretien">
              <span>{campaign.template.title}</span>
            </DSFormGroupTextInput>
          )}

          {(campaign.managerOnly || campaign.hideFromEmployeeUntil) && (
            <DSFormGroupTextInput label="Préférences de workflow">
              <div>
                {campaign.managerOnly && (
                  <p>• Entretiens gérés unilatéralement par les responsables</p>
                )}
                {campaign.hideFromEmployeeUntil && (
                  <p>
                    • Entretiens ouverts aux collaborateurs uniquement après le partage du
                    responsable
                  </p>
                )}
              </div>
            </DSFormGroupTextInput>
          )}
        </div>
      </Modal>
    )
  );
};
