import { Route, Switch, Redirect } from "react-router-dom";
import { Loader } from "@skillup/ui";

import AccessGuardRoute from "./components/AccessGuardRoute";

import CatalogRoot from "containers/CatalogRoot";
import TrainingsResults from "containers/TrainingsResults";
import TrainingPage from "containers/TrainingPage";
import TrainingCategory from "containers/TrainingCategory";
import OpsAdmin from "containers/Supervisor/routes/OpsAdmin";

import User from "utils/User";

import CompanySwitch from "./routes/CompanySwitch";
import IntraListView from "./routes/Intra";
import IntraCreationView from "./routes/Intra/CreationView/IntraCreationView";
import IntraCreationViewV2 from "./routes/Intra/CreationView/IntraCreationViewV2";
import IntraEditionView from "./routes/Intra/EditionView/IntraEditionView";
import IntraEditionViewV2 from "./routes/Intra/EditionView/IntraEditionViewV2";

import PortalManagement from "./routes/PortalManagement";
import ReviewList from "./routes/ReviewList";
import TrainingSchedule from "./routes/TrainingSchedule";
import NewJobDescription from "./routes/Skills/Legacy/Jobs/Create";
import EditJob from "./routes/Skills/Legacy/Jobs/Edit";
import NewSkill from "./routes/Skills/Legacy/NewSkill/NewSkill";
import { CreateSkill } from "./routes/Skills/Skills/pages";
import { UpdateSkill } from "./routes/Skills/Skills/pages";
import Skills from "./routes/Skills";
import SkillsDashboard from "./routes/Skills/Dashboard";
import JobAnalytic from "./routes/Skills/Dashboard/pages/Analytic/Job";
import SkillAnalytic from "./routes/Skills/Dashboard/pages/Analytic/Skill";
import CampaignsList from "./routes/CampaignsList";
import Campaign from "./routes/Campaign";
import {
  CreateNewInterviewCampaign,
  CreateNewAutomatedInterviews,
  CreateNewAdHocInterviews,
} from "./routes/CreateNewInterviewTemplate";
import Projects from "./routes/Projects";
import UserRoute, { SupervisorUserDetailView as UserDetailRoute } from "./routes/User";
import CreateNewTemplate from "./routes/CreateNewTemplate";
import UploadUserDatabases from "./routes/UploadUserDatabases";
import TrainingDashboardOld from "./routes/TrainingDashboardOld";
import { TrainingDashboard } from "./routes/TrainingDashboard";
import TrainingDashboardV2 from "./routes/TrainingDashboardV2";
import InterviewDashboard from "./routes/InterviewDashboard";
import Habilitations from "./routes/Reglementaire/Habilitations";
import Trackings from "./routes/Reglementaire/Trackings";
import PortalAdmin from "./routes/PortalAdmin";
import PortalPlans from "./routes/PortalPlans";
import AdminPortal from "./routes/PortalManagement/components/AdminPortal/AdminPortal";
import SkillsManagement from "./routes/SkillsManagement";
import CreateEvaluationScale from "./routes/SkillsManagement/components/SkillEvaluationScales/pages/Create";
import UpdateEvaluationScale from "./routes/SkillsManagement/components/SkillEvaluationScales/pages/Update";
import { CreateJob } from "./routes/Skills/Jobs/pages/Create";
import JobDetail from "./routes/Skills/Jobs/pages/Detail";
import UpdateJob from "./routes/Skills/Jobs/pages/Update";
import JobTemplateFieldsForm from "./routes/SkillsManagement/components/JobTemplateFields/JobTemplateFieldsForm";

import useSettings from "hooks/useSettings";
import createUserAccessChecker from "hooks/userAccessChecker";
import { useDefaultRedirectionPath } from "hooks/useRedirectionRoute";
import { TemplatesList, TemplateBuilder } from "./routes/TemplatesV2";
import { StructureBuilder } from "./routes/Structure";
import { TargetCategoriesAndScalesLists } from "./routes/InterviewsAndTargetConfiguration";
import {
  PR_Management_Routes,
  PeopleReviewManagementRouter,
} from "./routes/PeopleReviewManagement";
import { PeopleReviewRoutes, PeopleReviewRouter } from "./routes/PeopleReview";
import { CoreHRManagement } from "./routes/CoreHR/Management";
import { TrainingDashboardConfiguration } from "./routes/TrainingDashboard/views/Configuration";

const SupervisorView = () => {
  const { settings, userAccessModules, loading } = useSettings();
  const defaultRedirectionPath = useDefaultRedirectionPath();

  const isSkillupAdmin = User.isSkillupAdmin();
  const isSkillupDeveloper = User.isSkillupDeveloper();
  const hasMVPTrainingDashboard = User.hasMVPTrainingDashboardEnabled();

  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);

  return (
    <div>
      {loading ? (
        <Loader style={{ height: "100vh" }} />
      ) : (
        <Switch>
          <AccessGuardRoute
            exact
            path="/responsable/dashboard/training"
            component={hasMVPTrainingDashboard ? TrainingDashboard : TrainingDashboardOld}
            cannotAccess={!UserAccessChecker.Dashboards.toTrainingDashboard()}
          />
          {hasMVPTrainingDashboard && (
            <AccessGuardRoute
              exact
              path="/responsable/dashboard/training/configuration"
              component={TrainingDashboardConfiguration}
              cannotAccess={!UserAccessChecker.Dashboards.toTrainingDashboard()}
            />
          )}
          <AccessGuardRoute
            exact
            path="/responsable/dashboard/training-v2"
            component={TrainingDashboardV2}
            cannotAccess={!UserAccessChecker.Dashboards.toTrainingDashboardV2()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/dashboard/interview/:interviewUuid?"
            component={InterviewDashboard}
            cannotAccess={!UserAccessChecker.Dashboards.toInterviewDashboard()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/dashboard/interview/:campaignUuid"
            component={InterviewDashboard}
            cannotAccess={!UserAccessChecker.Dashboards.toInterviewDashboard()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/:uuid?/:type(plan-de-formation|recueil-des-besoins)/:tab?/:selectedUUID?"
            component={TrainingSchedule}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsSchedulePlan()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/reglementaire/habilitations"
            component={Habilitations}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsRegulatoryHabilitations()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/reglementaire/suivi"
            component={Trackings}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsRegulatoryTrackings()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/programmes/gestion-intras"
            component={IntraListView}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsIntrasOrganization()}
            redirectPath="/responsable"
          />
          <AccessGuardRoute
            exact
            path="/responsable/programmes/gestion-intras/creation"
            component={!User.hasFieldsV2Disable ? IntraCreationView : IntraCreationViewV2}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsIntrasOrganization()}
            redirectPath="/responsable"
          />
          <AccessGuardRoute
            exact
            path="/responsable/programmes/gestion-intras/:intraUuid"
            component={User.hasFieldsV2Disable() ? IntraEditionView : IntraEditionViewV2}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsIntrasOrganization()}
            redirectPath="/responsable"
          />
          <AccessGuardRoute
            exact
            path="/responsable/programmes/catalogue"
            component={CatalogRoot}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsIntrasSkillup()}
          />
          <AccessGuardRoute
            path="/responsable/programmes/catalogue/formations-:slug"
            component={TrainingCategory}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsIntrasSkillup()}
          />
          <AccessGuardRoute
            path="/responsable/programmes/catalogue/formation-:slug"
            component={TrainingPage}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsIntrasSkillup()}
          />
          <AccessGuardRoute
            path="/responsable/evaluations/:scope/:id?"
            component={ReviewList}
            cannotAccess={
              !UserAccessChecker.Trainings.toTrainingsHotEvaluations() &&
              !UserAccessChecker.Trainings.toTrainingsColdEvaluations()
            }
          />
          <AccessGuardRoute
            exact
            path="/responsable/mes-sessions/:tab(toutes|en-cours|realisees|cloturees|annulees)/:projectUuid?"
            component={Projects}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsSessions()}
          />
          <AccessGuardRoute
            path="/responsable/recherche/:query"
            component={TrainingsResults}
            cannotAccess={!UserAccessChecker.Trainings.toTrainingsIntrasSkillup()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/campagnes"
            component={CampaignsList}
            cannotAccess={!UserAccessChecker.Interviews.toInterviewsTrackings()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/campagne/creation-campagne-entretiens"
            component={CreateNewInterviewCampaign}
            cannotAccess={!UserAccessChecker.toCampaignCreation()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/campagne/creation-entretiens-automatises"
            component={CreateNewAutomatedInterviews}
            cannotAccess={!UserAccessChecker.toCampaignCreation()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/campagne/creation-entretiens-ponctuels"
            component={CreateNewAdHocInterviews}
            cannotAccess={!UserAccessChecker.toCampaignCreation()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/campagne/:campaignUuid"
            component={Campaign}
            cannotAccess={!UserAccessChecker.Interviews.toInterviewsTrackings()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/templates"
            component={TemplatesList}
            // you should have access to the interviews module to access this view
            // No need for builder access (current "toInterviewsTemplates") because
            // actions are now protected in back-end.
            cannotAccess={!UserAccessChecker.Interviews.toInterviews()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/template/:templateUuid?"
            component={TemplateBuilder}
            cannotAccess={!UserAccessChecker.Interviews.toInterviews()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/campagne/:campaignUuid/edit"
            component={StructureBuilder}
            cannotAccess={!isSkillupAdmin}
          />
          <AccessGuardRoute
            exact
            path="/responsable/templatev1/:templateUuid?"
            component={CreateNewTemplate}
            cannotAccess={!isSkillupDeveloper}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/fiches-de-poste-v1/new"
            component={NewJobDescription}
            cannotAccess={!UserAccessChecker.Skills.toSkillsJobs()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/fiches-de-poste/new"
            component={CreateJob}
            cannotAccess={!UserAccessChecker.Skills.toSkillsJobs()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/fiches-de-poste/new/:jobUuid"
            component={CreateJob}
            cannotAccess={!UserAccessChecker.Skills.toSkillsJobs()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/fiches-de-poste-v1/:jobUuid"
            component={EditJob}
            cannotAccess={!UserAccessChecker.Skills.toSkillsJobs()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/fiches-de-poste/:jobUuid"
            component={JobDetail}
            cannotAccess={!UserAccessChecker.Skills.toSkillsJobs()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/fiches-de-poste/edit/:jobUuid"
            component={UpdateJob}
            cannotAccess={!UserAccessChecker.Skills.toSkillsJobs()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/competences-v1/new"
            component={NewSkill}
            cannotAccess={!UserAccessChecker.Skills.toSkillsRequired()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/competences/new"
            component={CreateSkill}
            cannotAccess={!UserAccessChecker.Skills.toSkillsRequired()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/competences/edit/:skillUuid"
            component={UpdateSkill}
            cannotAccess={!UserAccessChecker.Skills.toSkillsRequired()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/analyses/competences"
            component={SkillsDashboard}
            cannotAccess={!UserAccessChecker.Skills.toSkills()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/analyses/fiches-de-poste"
            component={SkillsDashboard}
            cannotAccess={!UserAccessChecker.Skills.toSkills()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/analyses/competence/:uuid"
            component={SkillAnalytic}
            cannotAccess={!UserAccessChecker.Skills.toSkills()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/analyses/fiche-de-poste/:uuid"
            component={JobAnalytic}
            cannotAccess={!UserAccessChecker.Skills.toSkills()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/referentiels/:tab?"
            component={Skills}
            cannotAccess={!UserAccessChecker.Skills.toSkills()}
          />
          <AccessGuardRoute
            path="/responsable/v2/gestion-du-portail"
            component={AdminPortal}
            cannotAccess={!UserAccessChecker.Configuration.toConfigurationPortail()}
          />
          <AccessGuardRoute
            path="/responsable/gestion-du-portail"
            component={PortalManagement}
            cannotAccess={!UserAccessChecker.Configuration.toConfigurationPortail()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation/new"
            component={CreateEvaluationScale}
            cannotAccess={!UserAccessChecker.Configuration.toSkillsManagement()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/configuration-du-module-competences/gestion-des-echelles-d-evaluation/edit/:uuid"
            component={UpdateEvaluationScale}
            cannotAccess={!UserAccessChecker.Configuration.toSkillsManagement()}
          />
          <AccessGuardRoute
            path="/responsable/configuration-du-module-competences/:tab?"
            component={SkillsManagement}
            cannotAccess={!UserAccessChecker.Configuration.toSkillsManagement()}
          />
          <AccessGuardRoute
            path="/responsable/configuration-du-core-hr"
            component={CoreHRManagement}
            cannotAccess={!isSkillupDeveloper}
          />
          <AccessGuardRoute
            exact
            path="/responsable/structure-de-fiches-de-poste/set"
            component={JobTemplateFieldsForm}
            cannotAccess={!UserAccessChecker.Configuration.toSkillsManagement()}
          />
          <AccessGuardRoute
            path="/responsable/administration-du-portail"
            component={PortalAdmin}
            cannotAccess={!UserAccessChecker.Configuration.toConfigurationAdminPlatform()}
          />
          <AccessGuardRoute
            path="/responsable/plans"
            component={PortalPlans}
            cannotAccess={!UserAccessChecker.Configuration.toConfigurationPlans()}
          />
          <AccessGuardRoute
            path="/responsable/configuration-des-entretiens-et-des-objectifs"
            component={TargetCategoriesAndScalesLists}
            cannotAccess={!UserAccessChecker.Configuration.toTargetCategories()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/collaborateurs"
            component={UserRoute}
            cannotAccess={!UserAccessChecker.toEmployees()}
          />
          <AccessGuardRoute
            exact
            path="/responsable/collaborateurs/imports"
            component={UploadUserDatabases}
            cannotAccess={
              !UserAccessChecker.toEmployees() && !settings?.portalHRUploadUserDBEnabled
            }
          />
          <AccessGuardRoute
            exact
            path="/responsable/collaborateurs/:uuid/:tab?"
            component={UserDetailRoute}
            cannotAccess={!UserAccessChecker.toEmployees()}
          />
          <AccessGuardRoute
            path="/responsable/changer-d-entreprise"
            component={CompanySwitch}
            cannotAccess={!isSkillupAdmin}
          />
          <AccessGuardRoute
            exact
            path="/responsable/ops-administration"
            component={OpsAdmin}
            cannotAccess={!isSkillupDeveloper}
          />
          <AccessGuardRoute
            exact
            path="/responsable/ops-administration/*"
            component={OpsAdmin}
            cannotAccess={!isSkillupDeveloper}
          />
          <AccessGuardRoute
            path={PR_Management_Routes.BaseURL}
            component={PeopleReviewManagementRouter}
            cannotAccess={!(User.isSkillupDeveloper() || User.hasPeopleReviewEnabled())}
          />
          <AccessGuardRoute
            path={PeopleReviewRoutes.BaseUrl}
            component={PeopleReviewRouter}
            cannotAccess={!(User.isSkillupDeveloper() || User.hasPeopleReviewEnabled())}
          />
          <Route>
            <Redirect to={defaultRedirectionPath} />
          </Route>
        </Switch>
      )}
    </div>
  );
};

export default SupervisorView;
