import { useToggle } from "react-use";
import { useHistory } from "react-router-dom";
import React, { useMemo, useState, useEffect, useCallback } from "react";

import { useRecoilValue } from "recoil";
import { isEmpty, cloneDeep } from "lodash";

import { Icon, Select, colors } from "@skillup/ui";

import Acta from "utils/Acta";
import Button from "components/Button";
import DataLayer from "utils/DataLayer";
import DSLayout from "components/DSLayout";
import TextInput from "components/TextInput";
import type { SkillsRoutes } from "types/api";
import { useCategories } from "services/Skills/useCategories";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import { Skill, skillsAtom, useSkillsState } from "../state/skills";
import { showConfirmEvaluationModal } from "./ConfirmEvaluationDeletionModal";

import styles from "./NewSkill.module.scss";

type CreateSkillRoute = SkillsRoutes["Skills.Create"];
type GetCategoriesRoute = SkillsRoutes["Skills.Categories.GetList"];

const createSkill = async (body: CreateSkillRoute["payload"], onSuccess: () => void) => {
  try {
    const method: CreateSkillRoute["method"] = "POST";
    const path: CreateSkillRoute["path"] = "/competences/skills";
    const response = (await DataLayer.request({
      body: JSON.stringify(body),
      method,
      url: `/v1${path}`,
    })) as CreateSkillRoute["response"];

    if (!response.uuid) {
      throw new Error();
    }

    Acta.dispatchEvent("sendAppMessage", {
      type: "success",
      message: "Compétence ajoutée avec succès.",
    });

    onSuccess();
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      type: "error",
      message: "Echec lors de l'ajout de la compétence.",
    });
  }
};

type ErrorReport = {
  label?: boolean;
  evaluations?: number[];
};

type Options = { label: string; value: string };
type CategoriesSkills = Record<string, Options[]>;

const DEFAULT_FOUR_SKILL_LEVELS = [
  { description: "", label: "" },
  { description: "", label: "" },
  { description: "", label: "" },
  { description: "", label: "" },
];

const NewSkill = () => {
  const history = useHistory();
  const SkillsState = useSkillsState();

  const [skillLabel, setSkillLabel] = useState<string>();
  const [skillDescription, setSkillDescription] = useState<string>();
  const [loading, toggleLoading] = useToggle(false);
  const [categoriesValues, setCategoriesValues] = useState<string[]>([]);

  const { data } = useCategories();
  const skills = useRecoilValue(skillsAtom);

  useEffect(() => {
    if (data?.categories?.length) {
      setCategoriesValues(new Array(data.categories.length).fill(undefined));
    }
  }, [data?.categories]);

  const suggestions = useCategoriesSuggestions(data?.categories, skills);

  const changeAssignedCategoryValue = useCallback(
    (index: number, newValue: string) => {
      setCategoriesValues((prev) => prev.map((value, i) => (i === index ? newValue : value)));
    },
    [setCategoriesValues]
  );

  /** Niveaux d'évaluation */
  const [evaluationsLevels, setEvaluationsLevels] =
    useState<{ label: string; description: string }[]>(DEFAULT_FOUR_SKILL_LEVELS);

  const addEvaluation = () =>
    setEvaluationsLevels([...evaluationsLevels, { description: "", label: "" }]);
  const removeEvaluation = (index: number) => {
    const filteredEvaluations = evaluationsLevels.filter((_e, evalIndex) => evalIndex !== index);
    setEvaluationsLevels(filteredEvaluations);
  };

  const changeEvaluationLabel = (index: number) => (newLabel: string) => {
    const modifiedEvals = cloneDeep(evaluationsLevels);
    modifiedEvals[index].label = newLabel;
    setEvaluationsLevels(modifiedEvals);
  };

  const changeEvaluationDescription = (index: number) => (newDescription: string) => {
    const modifiedEvals = cloneDeep(evaluationsLevels);
    modifiedEvals[index].description = newDescription;
    setEvaluationsLevels(modifiedEvals);
  };

  /** Errors */
  const [errors, setErrors] = useState<ErrorReport>({});

  /** Submission */
  const handleSubmit = () => {
    toggleLoading(true);

    const submissionErrors: ErrorReport = {};

    const emptyEvaluations = evaluationsLevels
      .map((e, index) => ({ index, label: e.label }))
      .filter((e) => isEmpty(e.label));

    if (emptyEvaluations.length) {
      submissionErrors.evaluations = emptyEvaluations.map((e) => e.index);
    }

    if (isEmpty(skillLabel)) {
      submissionErrors.label = true;
    }

    if (!isEmpty(submissionErrors)) {
      setErrors(submissionErrors);
      toggleLoading(false);
      return;
    } else {
      setErrors({});
    }

    const body: CreateSkillRoute["payload"] = {
      categories: data?.categories.map((category, index) => ({
        uuid: category.uuid,
        value: categoriesValues[index],
      })),
      data: {
        description: skillDescription,
        label: skillLabel,
      },
      evaluationsLevels,
    };

    const onSuccess = () => {
      history.push("/responsable/competences/competences");
      SkillsState.refreshList();
    };
    createSkill(body, onSuccess);
  };

  /** Rendering */
  return (
    <DSLayout
      title="Nouvelle compétence"
      parent={{
        onClick: () => history.goBack(),
        title: "Création d'une compétence",
      }}
      layouts={
        [
          {
            primaryButton: (
              <DSNewHeaderButton loading={loading} label="Enregistrer" onClick={handleSubmit} />
            ),
          },
        ] as const
      }
    >
      <div className={styles.newSkill}>
        <div className={styles.content}>
          <div className={styles.title}>
            <h2>Informations</h2>
            <div className={styles.underline} />
          </div>
          <div className={styles.inputs}>
            <TextInput
              required
              alwaysOpen
              showRequired
              autoComplete="off"
              label="Nom de la compétence"
              underlineColor={errors.label ? colors.error : undefined}
              onChange={(e) => setSkillLabel(e.currentTarget.value)}
            />
            <TextInput
              alwaysOpen
              autoComplete="off"
              label="Description (optionnel)"
              onChange={(e) => setSkillDescription(e.currentTarget.value)}
            />
          </div>

          {data?.categories.length !== 0 && (
            <div className={styles.categories}>
              <div className={styles.title}>
                <h2>Catégories</h2>
                <div className={styles.underline} />
              </div>
              <form>
                {data?.categories?.map((category, categoryIndex) => (
                  <div>
                    <p className={styles.label}>{category.label}</p>
                    <Select
                      placeholder=""
                      createIfInexistent
                      createLabel="Créer"
                      key={category.uuid}
                      options={suggestions[category.uuid]}
                      value={categoriesValues[categoryIndex]}
                      onChange={(inputValue) =>
                        changeAssignedCategoryValue(categoryIndex, inputValue)
                      }
                    />
                  </div>
                ))}
              </form>
            </div>
          )}

          <div className={styles.evaluations}>
            <div className={styles.title}>
              <h2>Niveaux d'évaluation</h2>
              <div className={styles.underline} />
            </div>

            {evaluationsLevels.map((evaluation, evalIndex) => (
              <div key={evalIndex} className={styles.evaluation}>
                <TextInput
                  alwaysOpen
                  showRequired
                  autoComplete="off"
                  defaultValue={evaluation.label}
                  placeholder="Ex: Intermédiaire"
                  label={`Niveau ${evalIndex + 1}: Label`}
                  onChange={(e) => changeEvaluationLabel(evalIndex)(e.currentTarget.value)}
                  underlineColor={
                    errors.evaluations?.includes(evalIndex) ? colors.error : undefined
                  }
                />
                <TextInput
                  alwaysOpen
                  autoComplete="off"
                  defaultValue={evaluation.description}
                  label={`Niveau ${evalIndex + 1}: Description`}
                  onChange={(e) => changeEvaluationDescription(evalIndex)(e.currentTarget.value)}
                />
                <Icon.Trash
                  size={20}
                  color="grey"
                  onClick={() =>
                    showConfirmEvaluationModal(() => {
                      removeEvaluation(evalIndex);
                      Acta.dispatchEvent("closeModal");
                    })
                  }
                />
              </div>
            ))}

            <Button onClick={addEvaluation}> Ajouter un niveau d'évaluation</Button>
          </div>
        </div>
      </div>
    </DSLayout>
  );
};
export default NewSkill;

function useCategoriesSuggestions(
  categories: GetCategoriesRoute["response"]["categories"],
  skills: Skill[]
) {
  const categoriesAsList: { uuid: string; label: string }[] = useMemo(() => {
    if (!categories) return [];
    return Object.keys(categories).map((key) => categories[key]);
  }, [categories]);

  const suggestions = useMemo(
    () =>
      categoriesAsList.reduce<CategoriesSkills>((acc, category) => {
        const options = [
          ...new Set(
            skills.map((skill) => skill.categories[category.uuid]).filter((elem) => !!elem)
          ),
        ].map((skillCategory) => ({ label: skillCategory, value: skillCategory }));

        return { ...acc, [category.uuid]: options };
      }, {}),
    [skills, categoriesAsList]
  );

  return suggestions;
}
