import { Status, StatusType } from "@skillup/ui";
import { Review } from "@skillup/training-bridge";
import useTranslation from "hooks/useTranslation";

function ReviewStatus({
  status,
  darkMode = false,
}: Pick<Review, "status"> & { darkMode?: boolean }) {
  const { t } = useTranslation();

  switch (status) {
    case "completed":
      return (
        <Status
          type={StatusType.SUCCESS}
          label={t("review.status.completed", {
            defaultValue: "Complétée",
          })}
          darkMode={darkMode}
        />
      );
    case "submitted":
      return (
        <Status
          type={StatusType.PROGRESS}
          label={t("review.status.pending", {
            defaultValue: "En attente",
          })}
          darkMode={darkMode}
        />
      );
    case "pending":
      return (
        <Status
          type={StatusType.WARNING}
          label={t("review.status.not_sent", {
            defaultValue: "Non envoyée",
          })}
          darkMode={darkMode}
        />
      );
    case "cancelled":
      return (
        <Status
          type={StatusType.ERROR}
          label={t("review.status.cancelled", {
            defaultValue: "Annulée",
          })}
          darkMode={darkMode}
        />
      );
    case "abandoned":
      return (
        <Status
          type={StatusType.WARNING}
          label={t("review.status.abandoned", {
            defaultValue: "Expirée",
          })}
          darkMode={darkMode}
        />
      );
    default:
      return (
        <Status
          type={StatusType.DONE}
          label={t("review.status.unknown", {
            defaultValue: "Non renseigné",
          })}
          darkMode={darkMode}
        />
      );
  }
}

export default ReviewStatus;
