import utils from "utils";

import durationGroups from "constants/durationGroups";
import languages from "constants/languages";
import modalities from "constants/modalities";

const today = new Date();

export default {
  place: {
    key: "place",
    label: () => "Lieu de session",
    buildRemovalLinks(basePath, filters) {
      const { place, ...filtersWithoutPlace } = filters;
      return [
        {
          label: place.label,
          url:
            basePath +
            utils.search.getQueryStringFromFilters({
              ...filtersWithoutPlace,
              page: null,
            }),
        },
      ];
    },
  },

  sessionType: {
    key: "sessionType",
    label: () => "Type de session",
    buildRemovalLinks(basePath, filters) {
      const { sessionType, ...filtersWithoutSessionType } = filters;
      return [
        {
          label: "A distance",
          url:
            basePath +
            utils.search.getQueryStringFromFilters({
              ...filtersWithoutSessionType,
              page: null,
            }),
        },
      ];
    },
  },

  sessionMonth: {
    key: "sessionMonth",
    label: () => "Date de session",
    buildAdditionLinks: (basePath, filters, aggregations) =>
      Array.from(
        Array(12),
        (value, index) =>
          `${today.getFullYear() + Math.floor((index + today.getMonth()) / 12)}-${`0${
            index + today.getMonth() + (index + today.getMonth() > 11 ? -11 : 1)
          }`.slice(-2)}`
      ).map((date, index) => {
        const updatedSessionsMonth = new Set(filters.sessionMonth || []);
        if (updatedSessionsMonth.has(date)) updatedSessionsMonth.delete(date);
        else updatedSessionsMonth.add(date);
        return {
          label: new Date(date).toLocaleString("fr-FR", {
            month: "long",
            year: "numeric",
          }),
          count: (aggregations[`month_${index}`] || {}).doc_count,
          isSelected: filters.sessionMonth && filters.sessionMonth.includes(date),
          url:
            basePath +
            utils.search.getQueryStringFromFilters({
              ...filters,
              sessionMonth: Array.from(updatedSessionsMonth.values()),
              page: null,
            }),
        };
      }),
    buildRemovalLinks: (basePath, filters) =>
      filters.sessionMonth.map((date) => ({
        label: new Date(date).toLocaleString("fr-FR", {
          month: "long",
          year: "numeric",
        }),
        url:
          basePath +
          utils.search.getQueryStringFromFilters({
            ...filters,
            sessionMonth: filters.sessionMonth.filter((currentMonth) => currentMonth !== date),
            page: null,
          }),
      })),
  },

  durationGroup: {
    key: "durationGroup",
    label: () => "Durée",
    buildAdditionLinks: (basePath, filters, aggregations) =>
      [0, 1, 2, 3].map((index) => {
        const updatedDurationGroups = new Set(filters.durationGroup || []);
        if (updatedDurationGroups.has(String(index))) {
          updatedDurationGroups.delete(String(index));
        } else updatedDurationGroups.add(String(index));
        return {
          label: durationGroups.values[index],
          count: aggregations[`duration_${index}`].doc_count,
          isSelected: filters.durationGroup && filters.durationGroup.includes(String(index)),
          url:
            basePath +
            utils.search.getQueryStringFromFilters({
              ...filters,
              durationGroup: Array.from(updatedDurationGroups.values()),
              page: null,
            }),
        };
      }),
    buildRemovalLinks: (basePath, filters) =>
      filters.durationGroup.map((duration) => ({
        label: durationGroups.mapping[duration],
        url:
          basePath +
          utils.search.getQueryStringFromFilters({
            ...filters,
            durationGroup: filters.durationGroup.filter(
              (currentDuration) => currentDuration !== duration
            ),
            page: null,
          }),
      })),
  },

  isCPF: {
    key: "isCPF",
    label: () => "Eligibilité CPF",
    buildAdditionLinks: (basePath, filters, aggregations) => [
      {
        label: "Éligible",
        count:
          (
            (Object.values(aggregations.isCPF).find(
              // @ts-ignore
              (agg) => agg.key_as_string === "true"
            ) || {}) as { doc_count?: number }
          ).doc_count || 0,
        isSelected: !!filters.isCPF,
        url:
          basePath +
          utils.search.getQueryStringFromFilters({
            ...filters,
            isCPF: !filters.isCPF,
            page: null,
          }),
      },
    ],
    buildRemovalLinks(basePath, filters) {
      const { isCPF, ...filtersWithoutCPF } = filters;
      return [
        {
          label: "oui",
          url:
            basePath +
            utils.search.getQueryStringFromFilters({
              ...filtersWithoutCPF,
              page: null,
            }),
        },
      ];
    },
  },

  language: {
    key: "language",
    label: () => "Langue",
    buildAdditionLinks: (basePath, filters, aggregations) =>
      ["english"].map((key) => {
        const updatedLanguageGroups = new Set(filters.language || []);
        if (updatedLanguageGroups.has(String(key))) {
          updatedLanguageGroups.delete(String(key));
        } else updatedLanguageGroups.add(String(key));
        return {
          label: languages.mapping[key],
          count: aggregations.language[key] ? aggregations.language[key].doc_count : 0,
          isSelected: filters.language && filters.language.includes(String(key)),
          url:
            basePath +
            utils.search.getQueryStringFromFilters({
              ...filters,
              language: Array.from(updatedLanguageGroups.values()),
              page: null,
            }),
        };
      }),
    buildRemovalLinks: (basePath, filters) =>
      filters.language.map((certif) => ({
        label: languages.mapping[certif],
        url:
          basePath +
          utils.search.getQueryStringFromFilters({
            ...filters,
            language: filters.language.filter((currentLanguage) => currentLanguage !== certif),
            page: null,
          }),
      })),
  },

  mode: {
    key: "mode",
    label: () => "Modalités pédagogiques",
    buildAdditionLinks: (basePath, filters, aggregations) =>
      [
        "presential",
        "blended",
        "elearning",
        "mooc",
        "distancial",
        "individual",
        "digital",
        "virtual-classroom",
        "webinar",
        "conference",
      ].map((key) => {
        const updatedModesGroups = new Set(filters.mode || []);
        if (updatedModesGroups.has(String(key))) {
          updatedModesGroups.delete(String(key));
        } else updatedModesGroups.add(String(key));
        return {
          label: modalities.mapping[key],
          count: aggregations.mode[key] ? aggregations.mode[key].doc_count : 0,
          isSelected: filters.mode && filters.mode.includes(String(key)),
          url:
            basePath +
            utils.search.getQueryStringFromFilters({
              ...filters,
              mode: Array.from(updatedModesGroups.values()),
              page: null,
            }),
        };
      }),
    buildRemovalLinks: (basePath, filters) =>
      filters.mode.map((mode) => ({
        // @ts-ignore
        label: modalities.mapping[mode],
        url:
          basePath +
          utils.search.getQueryStringFromFilters({
            ...filters,
            mode: filters.mode.filter((currentMode) => currentMode !== mode),
            page: null,
          }),
      })),
  },

  isCertifying: {
    key: "isCertifying",
    label: () => "Certifiante",
    buildAdditionLinks: (basePath, filters, aggregations) => [
      {
        label: "Certifiante",
        count:
          (
            (Object.values(aggregations.isCertifying).find(
              // @ts-ignore
              (agg) => agg.key_as_string === "true"
            ) || {}) as { doc_count?: number }
          ).doc_count || 0,
        isSelected: !!filters.isCertifying,
        url:
          basePath +
          utils.search.getQueryStringFromFilters({
            ...filters,
            isCertifying: !filters.isCertifying,
            page: null,
          }),
      },
    ],
    buildRemovalLinks(basePath, filters) {
      const { isCertifying, ...filtersWithoutCertification } = filters;
      return [
        {
          label: "Certifiante",
          url:
            basePath +
            utils.search.getQueryStringFromFilters({
              ...filtersWithoutCertification,
              page: null,
            }),
        },
      ];
    },
  },

  organization: {
    key: "organization",
    label: () => "Organismes de formation",
    buildAdditionLinks: (basePath, filters, aggregations) =>
      Object.keys(aggregations.organization)
        .sort()
        .map((organizationKey) => {
          const updatedOrganizationsGroup = new Set(filters.organization || []);
          if (updatedOrganizationsGroup.has(String(organizationKey))) {
            updatedOrganizationsGroup.delete(String(organizationKey));
          } else updatedOrganizationsGroup.add(String(organizationKey));
          return {
            label: organizationKey,
            count: aggregations.organization[organizationKey].doc_count,
            isSelected:
              filters.organization && filters.organization.includes(String(organizationKey)),
            url:
              basePath +
              utils.search.getQueryStringFromFilters({
                ...filters,
                organization: Array.from(updatedOrganizationsGroup.values()),
                page: null,
              }),
          };
        }),
    buildRemovalLinks: (basePath, filters) =>
      filters.organization.map((organization) => ({
        label: organization,
        url:
          basePath +
          utils.search.getQueryStringFromFilters({
            ...filters,
            organization: filters.organization.filter(
              (currentOrganization) => currentOrganization !== organization
            ),
            page: null,
          }),
      })),
  },

  entities: {
    key: "entities",
    label: () => "Entités",
    optional: true,
    buildAdditionLinks: (basePath, filters, aggregations) =>
      Object.keys(aggregations.entities)
        .sort((a, b) => {
          if (aggregations.entities[a].doc_count < aggregations.entities[b].doc_count) {
            return 1;
          } else if (aggregations.entities[a].doc_count > aggregations.entities[b].doc_count) {
            return -1;
          }
          return 0;
        })
        .map((entitiesKey) => {
          const updatedNameEntitiesGroup = new Set(filters.entities || []);
          if (updatedNameEntitiesGroup.has(String(entitiesKey))) {
            updatedNameEntitiesGroup.delete(String(entitiesKey));
          } else updatedNameEntitiesGroup.add(String(entitiesKey));
          return {
            label: entitiesKey,
            count: aggregations.entities[entitiesKey].doc_count,
            isSelected: filters.entities && filters.entities.includes(String(entitiesKey)),
            url:
              basePath +
              utils.search.getQueryStringFromFilters({
                ...filters,
                entities: Array.from(updatedNameEntitiesGroup.values()),
                page: null,
              }),
          };
        }),
    buildRemovalLinks: (basePath, filters) =>
      filters.entities.map((entities) => ({
        label: entities,
        url:
          basePath +
          utils.search.getQueryStringFromFilters({
            ...filters,
            entities: filters.entities.filter(
              (currentNameEntities) => currentNameEntities !== entities
            ),
            page: null,
          }),
      })),
  },
};
