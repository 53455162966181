export type TranslateFunction = (key: string, options?: Record<string, any>) => string;

export function formatError(
  t: TranslateFunction,
  error: any,
  { defaultValue = "Une erreur est survenue" }
) {
  if (typeof error === "string") {
    return t(`error.${error}`, {
      defaultValue,
    });
  }
  if (error?.errorKey && error.message) {
    return t(`error.${error.errorKey}`, {
      defaultValue,
    });
  }
  return defaultValue;
}
