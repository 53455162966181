export enum AuthStrategyType {
  OTL = "otl",
  SAML = "saml",
  PASSWORD = "password",
}

export type AuthStrategy =
  | {
      uuid: string;
      isDefaultStrategy?: boolean;
      type: AuthStrategyType.OTL;
      name: string;
      isMandatoryIfUnknownStrategy?: boolean;
      authSettings?: {
        samlRequestQueryParameters?: Record<string, string>;
      };
    }
  | {
      uuid: string;
      isDefaultStrategy?: boolean;
      type: AuthStrategyType.PASSWORD;
      name: string;
      isMandatoryIfUnknownStrategy?: boolean;
      authSettings?: {
        samlRequestQueryParameters?: Record<string, string>;
      };
    }
  | {
      uuid: string;
      isDefaultStrategy?: boolean;
      type: AuthStrategyType.SAML;
      name: string;
      authProvider: string;
      isMandatoryIfUnknownStrategy?: boolean;
      authSettings?: {
        samlRequestQueryParameters?: Record<string, string>;
      };
    };

export type CreateAuthStrategyPayload =
  | {
      isDefaultStrategy?: boolean;
      type: AuthStrategyType.OTL;
      name: string;
      isMandatoryIfUnknownStrategy?: boolean;
      authSettings?: {
        samlRequestQueryParameters?: string;
      };
    }
  | {
      isDefaultStrategy?: boolean;
      type: AuthStrategyType.PASSWORD;
      name: string;
      isMandatoryIfUnknownStrategy?: boolean;
      authSettings?: {
        samlRequestQueryParameters?: string;
      };
    }
  | {
      isDefaultStrategy?: boolean;
      type: AuthStrategyType.SAML;
      name: string;
      authProvider: string;
      isMandatoryIfUnknownStrategy?: boolean;
      authSettings?: {
        samlRequestQueryParameters?: string;
      };
    };

export type UpdateAuthStrategyPayload = CreateAuthStrategyPayload;
