import { Flex, MaterialIcons } from "@skillup/ui";

import styles from "../Modal.module.scss";

const AssistiveErrorText = ({ errorText }: { errorText: string }): any => {
  return (
    <Flex className={styles.assistiveErrorText}>
      <MaterialIcons.Error size="1rem" />
      {errorText}
    </Flex>
  );
};

export default AssistiveErrorText;
