import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { DSAvatarGroup, DSButton, Flex } from "@skillup/ui";
import { SessionUser } from "@skillup/espace-rh-bridge";

import { getUserInitials } from "utils/User";

import { Project } from "../../Actions/getProjects";
import showUserChoiceModal from "../../Actions/Modals/showUserChoiceModal";

import FormattedUsers from "./FormattedUsers";
import styles from "./SidePanel.module.scss";
interface ParticipantsSectionProps {
  project: Project;
  refetchList: VoidFunction;
  userSummonedModal: {
    isOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
  };
  setManageUsersModalIsOpen: (value: boolean) => void;
}

const ParticipantsSection = ({
  project,
  refetchList,
  userSummonedModal,
  setManageUsersModalIsOpen,
}: ParticipantsSectionProps) => {
  const { t } = useTranslation();

  const DSAvatarGroupUsers = (props: { users: SessionUser[] }) => {
    const { users } = props;
    return (
      <DSAvatarGroup
        maxItems={5}
        size="S"
        tooltipDirection={"bottom"}
        totalItems={users.length}
        users={users.map((user) => {
          return {
            uuid: user.uuid,
            initials: getUserInitials(user),
            fullName: user.fullName,
          };
        })}
      />
    );
  };

  const displayActionButton =
    project.properties.state !== "Annulée" && project.properties.state !== "Clôturée";

  const pendingTrainees = useMemo(() => {
    const uniqueSet = new Set();
    
    return project.pendingTrainees.filter((user) => {
      if (!uniqueSet.has(user.email)) {
        uniqueSet.add(user.email);
        return true;
      }
      return false;
    });
  }, [project.pendingTrainees])
  
  return (
    <>
      <section className={styles.participants}>
        <Flex className={styles.header}>
          <h2>
            {t("trainings.sessions.sidepanel.participants", { defaultValue: "Participants" })}
          </h2>
          {displayActionButton && (
            <DSButton
              name="manage-users"
              label="Gérer les participants"
              buttonSize="S"
              emphasis="Mid"
              onClick={() => setManageUsersModalIsOpen(true)}
            />
          )}
        </Flex>
        <div>
          <h3>
            {t("trainings.sessions.sidepanel.summonedTrainees", {
              count: project.summonedTrainees.length,
              defaultValue: "Convoqués ({{ count }})",
            })}
          </h3>
          {project.summonedTrainees.length > 0 && (
            <DSAvatarGroupUsers users={project.summonedTrainees} />
          )}
          {project.summon && (
            <button className={styles.link} onClick={userSummonedModal.openModal}>
              {t("trainings.sessions.sidepanel.seeSummons", {
                defaultValue: "Voir les convocations",
              })}
            </button>
          )}
        </div>
        <div>
          <h3>
            {t("trainings.sessions.sidepanel.positionnedTrainees", {
              count: project.positionnedTrainees.length,
              defaultValue: "Inscrits non convoqués ({{ count }})",
            })}
          </h3>
          <DSAvatarGroupUsers users={project.positionnedTrainees} />
        </div>
      </section>
      <section>
        <Flex className={styles.header}>
          <h2>
            {t("trainings.sessions.sidepanel.unsubscribed", {
              defaultValue: "Non inscrits",
            })}
          </h2>
          {displayActionButton && (
            <DSButton
              name="subscribe-users"
              label="Inscrire un stagiaire"
              buttonSize="S"
              emphasis="Mid"
              onClick={() => showUserChoiceModal(project, refetchList)}
            />
          )}
        </Flex>

        {project.positionningTrainees.length > 0 && (
          <div>
            <h3>
              {t("trainings.sessions.sidepanel.positionningTrainees", {
                count: project.positionningTrainees.length,
                defaultValue: "Choix de session en cours ({{ count }})",
              })}
            </h3>
            <ul>
              <FormattedUsers project={project} users={project.positionningTrainees} />
            </ul>
          </div>
        )}
        {pendingTrainees.length > 0 && (
          <div>
            <h3>
              {t("trainings.sessions.sidepanel.pendingTrainees", {
                count: pendingTrainees.length,
                defaultValue: "En attente d'inscription ({{ count }})",
              })}
            </h3>
            <ul>
              <FormattedUsers project={project} users={pendingTrainees} />
            </ul>
          </div>
        )}
      </section>
    </>
  );
};

export default ParticipantsSection;
