import { useState, FormEvent, useRef, useMemo, useEffect } from "react";
import { useSetState } from "react-use";
import { useQueryClient } from "@tanstack/react-query";
import { useDropzone } from "react-dropzone";

import { LmsBannerCreationPayload, PortalRoutes } from "@skillup/espace-rh-bridge";
import { LmsBanner, LmsBannerTheme } from "@skillup/espace-rh-bridge";
import {
  DSButton,
  DSModal,
  DSTextInput,
  FileDrop,
  DSFormGroupTextInput,
  RadioForm,
  TextArea,
} from "@skillup/ui";

import styles from "./EditBannerModal.module.scss";
import { buildRequest } from "utils/buildRequest";
import { hashToFormData } from "utils/DataLayer";
import { IBanner } from "../BannersList";

type ModalState = {
  redirUrl: string;
  backgroundColor: string;
  introText: string;
  introTextColor: string;
  mainText: string;
  mainTextColor: string;
  actionButonText: string;
  actionButonColor: LmsBannerTheme.LIGHT | LmsBannerTheme.DARK;
  logo?: string;
};

const initialState: ModalState = {
  redirUrl: "",
  backgroundColor: "",
  introText: "",
  introTextColor: "",
  mainText: "",
  mainTextColor: "",
  actionButonText: "",
  actionButonColor: LmsBannerTheme.LIGHT,
};

export interface Props {
  banner?: LmsBanner | null;
  isOpen: boolean;
  onClose: (banner?: LmsBanner) => void;
}

export default ({ banner, isOpen, onClose }: Props) => {
  const formRef = useRef<HTMLFormElement>();

  const [state, setState] = useSetState<ModalState>(initialState);
  const [file, setFile] = useState<File>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isFormValid = useMemo(() => {
    if (
      state.redirUrl &&
      state.mainText &&
      state.mainTextColor &&
      state.actionButonText &&
      (file || state.logo)
    ) {
      if (!state.introText || state.introTextColor) {
        return true;
      }
    }
    return false;
  }, [state, file]);

  useEffect(() => {
    setState(
      banner
        ? {
            redirUrl: banner.button.redirUrl,
            backgroundColor: banner.background || null,
            introText: banner.subtitle?.label,
            introTextColor: banner.subtitle?.color,
            mainText: banner.title.label,
            mainTextColor: banner.title.color,
            actionButonText: banner.button.label,
            actionButonColor: banner.button.theme,
            logo: banner.logo,
          }
        : initialState
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banner]);

  const queryClient = useQueryClient();
  const oldBanners: IBanner[] = queryClient.getQueryData(["lmsbanners"]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const body: Omit<LmsBanner, "logo"> | Omit<LmsBannerCreationPayload, "logo"> = {
        uuid: banner?.uuid,
        background: state.backgroundColor,
        subtitle: state.introText
          ? {
              label: state.introText,
              color: state.introTextColor,
            }
          : undefined,
        title: {
          label: state.mainText,
          color: state.mainTextColor,
        },
        button: {
          label: state.actionButonText,
          theme: state.actionButonColor,
          redirUrl: state.redirUrl,
        },
        index: banner?.index,
      };

      const formData = hashToFormData(body, "banner");
      file && formData.append("banner[logo]", file);

      let response;
      if (!banner) {
        response = await buildRequest<PortalRoutes.CreateLmsBanner>({
          method: "POST",
          path: "/portal/lms-banners",
          payload: formData,
        })();
        queryClient.setQueryData(["lmsbanners"], [...oldBanners, response]);
      } else {
        response = await buildRequest<PortalRoutes.UpdateLmsBanner>({
          method: "PUT",
          path: "/portal/lms-banners",
          payload: formData,
        })();
        queryClient.setQueryData(
          ["lmsbanners"],
          oldBanners.map((oldBanner) => (oldBanner.uuid === banner.uuid ? response : oldBanner))
        );
      }
      onClose(response);
    } finally {
      setIsLoading(false);
    }
  };

  const onDrop = async ([file]: File[]) => {
    setFile(file);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 52428800,
  });

  return (
    <DSModal isOpen={isOpen} className={styles.Modal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Configurer un accès LMS" />
      </DSModal.Header>
      <DSModal.Content>
        <div className={styles.ModalContent}>
          <form className={styles.modalForm} onSubmit={handleSubmit} ref={formRef}>
            <DSFormGroupTextInput label="URL du LMS" required>
              <DSTextInput
                name="redirUrl"
                placeholder="https://…"
                onChange={(value) => setState({ ...state, redirUrl: value })}
                value={state.redirUrl}
                required
              />
            </DSFormGroupTextInput>
            <DSFormGroupTextInput
              label="Couleur de fond de la bannière"
              assistiveText={
                <AssistiveColorText>
                  {" "}
                  ou un{" "}
                  <a href="https://cssgradient.io/" target="_blank" rel="noreferrer">
                    dégradé CSS
                  </a>
                </AssistiveColorText>
              }
            >
              <DSTextInput
                name="backgroundColor"
                placeholder="Ex. : #FFB900"
                onChange={(value) => setState({ ...state, backgroundColor: value })}
                value={state.backgroundColor}
              />
            </DSFormGroupTextInput>
            <DSFormGroupTextInput
              label="Logo du LMS"
              assistiveText={
                <ul className={styles.assistive}>
                  <li>Formats autorisés : JPEG, GIF, PNG, SVG</li>
                  <li>Proportions de l'image : 3/2</li>
                  <li>Taille de fichier maximale : 50kB"</li>
                </ul>
              }
              required
            >
              <>
                <FileDrop
                  isDragActive={isDragActive}
                  rootProps={getRootProps()}
                  inputProps={getInputProps()}
                  btnLabel={"Choisir un fichier"}
                  dropLabel={"ou glisser déposer un fichier ici"}
                  value={
                    file || state.logo ? (
                      <div className={styles.logoPreview}>
                        <img
                          src={file ? URL.createObjectURL(file) : state.logo}
                          alt={file?.name}
                          width={50}
                          height={50}
                        />
                        <label>{file?.name}</label>
                      </div>
                    ) : undefined
                  }
                />
                {(file || state.logo) && (
                  <DSButton
                    label="Supprimer"
                    emphasis="Low"
                    buttonSize="S"
                    onClick={(_) => {
                      setFile(null);
                      setState({ ...state, logo: null });
                    }}
                  />
                )}
              </>
            </DSFormGroupTextInput>
            <DSFormGroupTextInput label="Texte introductif" assistiveArea={"0/250"}>
              <TextArea
                id="introText"
                placeholder="Saisissez un texte présentant les avantages de l’accès à la plateforme"
                className={styles.textarea}
                onChange={(value) => setState({ ...state, introText: value })}
                value={state.introText}
              />
            </DSFormGroupTextInput>
            <DSFormGroupTextInput
              label="Couleur du texte introductif"
              assistiveText={<AssistiveColorText checkContrast />}
              required={!!state.introText}
            >
              <DSTextInput
                name="introTextColor"
                placeholder="Ex. : rgba(0,0,0,.9)"
                onChange={(value) => setState({ ...state, introTextColor: value })}
                value={state.introTextColor}
                required={!!state.introText}
              />
            </DSFormGroupTextInput>
            <DSFormGroupTextInput label="Texte principal" required assistiveArea={"0/250"}>
              <TextArea
                id="mainText"
                placeholder="Saisissez un texte invitant l'utilisateur à accéder à la plateforme"
                className={styles.textarea}
                onChange={(value) => setState({ ...state, mainText: value })}
                value={state.mainText}
              />
            </DSFormGroupTextInput>
            <DSFormGroupTextInput
              label="Couleur du texte principal"
              required
              assistiveText={<AssistiveColorText checkContrast />}
            >
              <DSTextInput
                name="mainTextColor"
                placeholder="Ex. : #000000"
                onChange={(value) => setState({ ...state, mainTextColor: value })}
                value={state.mainTextColor}
                required
              />
            </DSFormGroupTextInput>
            <DSFormGroupTextInput label="Texte du bouton d’action" required>
              <DSTextInput
                name="actionButonText"
                placeholder="Ex. : Accéder au catalogue"
                value={state.actionButonText}
                onChange={(value) => setState({ ...state, actionButonText: value })}
                required
              />
            </DSFormGroupTextInput>
            <DSFormGroupTextInput label="Couleur du bouton d’action">
              {/* TODO: use new DS component */}
              <RadioForm
                active={state.actionButonColor}
                onChange={(value: ModalState["actionButonColor"]) =>
                  setState({ ...state, actionButonColor: value })
                }
                choices={[
                  {
                    label: "Blanc opaque",
                    value: LmsBannerTheme.LIGHT,
                  },
                  {
                    label: "Blanc à 20% d'opacité",
                    value: LmsBannerTheme.DARK,
                    helpText: "À réserver pour les couleurs d’arrière-plan foncées",
                  },
                ]}
                className={styles.radioForm}
              />
            </DSFormGroupTextInput>
          </form>
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={() => onClose()} label="Annuler" />
        <DSModal.Footer.PrimaryButton
          disabled={!isFormValid}
          loading={isLoading}
          tooltip={
            isFormValid
              ? undefined
              : "Tous les champs obligatoires doivent être remplis (indiqués par une astérisque rouge)"
          }
          label={
            isLoading
              ? "Chargement..."
              : banner
              ? "Modifier la bannière LMS"
              : "Ajouter l'accès LMS"
          }
          onClick={() => formRef.current?.requestSubmit()}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

const AssistiveColorText = ({
  checkContrast,
  children,
}: {
  checkContrast?: boolean;
  children?: any;
}) => (
  <p>
    Vous pouvez définir une couleur par{" "}
    <a
      href="https://en.wikipedia.org/wiki/Web_colors#HTML_color_names"
      target="_blank"
      rel="noreferrer"
    >
      son nom
    </a>
    , sa valeur RGBA ou hexadécimale
    {checkContrast &&
      ". Assurez-vous que le contraste entre la couleur de texte et la couleur d'arrière-plan est suffisant pour garantir sa lisibilité."}
    {children}
  </p>
);
