import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getTimelineForFields } from "./getTimelineForFields";
import { FieldKey } from "@skillup/core-hr-bridge";

function useTimelineRefresh(fields: FieldKey[]) {
  const queryClient = useQueryClient();

  function refresh() {
    queryClient.invalidateQueries(["core-hr-timeline", fields]);
  }

  return refresh;
}

export function useTimelineForFields({
  employeeID,
  fieldsKeys,
  options = defaultOptions,
}: {
  employeeID: string;
  fieldsKeys: FieldKey[];
  options?: Options;
}) {
  const refreshTimeline = useTimelineRefresh(fieldsKeys);

  const query = useQuery(
    ["core-hr-timeline", fieldsKeys],
    () =>
      getTimelineForFields({
        employeeID,
        fieldsKeys,
      }),
    {
      ...defaultOptions,
      ...options,
    }
  );

  return {
    timelineRecords: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    refreshTimeline,
  };
}

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};

const defaultOptions = {
  refetchOnWindowFocus: true,
  refetchOnMount: true,
};
