import React, { useState } from "react";
import { isEmpty, isNil } from "lodash";

import DropDown from "components/DropDown";
import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";

import Acta from "utils/Acta";

import styles from "./Training.module.scss";

const dropDownSessionTypeOptions = [
  { label: "Intra", value: "Intra" },
  { label: "Inter", value: "Inter" },
] as const;

type Mode =
  | "presential"
  | "blended"
  | "elearning"
  | "mooc"
  | "individual"
  | "distancial"
  | "digital"
  | "virtual-classroom"
  | "webinar"
  | "conference";

export type ManualTrainingData = {
  trainingName: string;
  trainingOrganizationName: string;
  sessionMode: Mode;
  sessionType: "Inter" | "Intra";
  sessionDuration: number;
  total: number; // price
  totalWithTax?: number;
};

export const modes: Record<Mode, string> = {
  presential: "Présentiel",
  blended: "Blended Learning",
  elearning: "E-Learning",
  mooc: "MOOC",
  individual: "Individuel",
  distancial: "Distanciel",
  digital: "Digital",
  "virtual-classroom": "Classe virtuelle",
  webinar: "Webinar",
  conference: "Conférence",
};

export const reversedModes: Record<string, Mode> = Object.entries(modes).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }),
  {}
);

const checkTraining = (trainingData: Partial<ManualTrainingData>, useTaxSystem): boolean => {
  for (const key of ["trainingName", "trainingOrganizationName", "sessionMode", "sessionType"]) {
    if (isEmpty(trainingData[key])) return false;
  }

  if (isNil(trainingData.total) || isEmpty(`${trainingData.total}`)) {
    return false;
  }

  if (
    useTaxSystem &&
    (isNil(trainingData.totalWithTax) || isEmpty(`${trainingData.totalWithTax}`))
  ) {
    return false;
  }

  if (isNil(trainingData.sessionDuration) || isEmpty(`${trainingData.sessionDuration}`))
    return false;

  return true;
};

interface ManualImportProps {
  trainingData?: Partial<ManualTrainingData>;
  onSuccess: (trainingData: ManualTrainingData) => void;
  useTaxSystem: boolean;
  buttonLabel?: string;
  isLoading?: boolean;
}

const defaultTrainingData: Partial<ManualTrainingData> = {
  sessionMode: "presential",
  sessionType: "Inter",
};

const TrainingDataModal = (props: Partial<ManualImportProps>) => {
  const [trainingData, setTrainingData] = useState<Partial<ManualTrainingData>>({
    ...defaultTrainingData,
    ...props.trainingData,
  });
  const { buttonLabel, isLoading, onSuccess, useTaxSystem } = props;

  const setTrainingDataKey = (key, value) => {
    if (key === "sessionDuration") {
      const parsedValue = isEmpty(value) ? undefined : parseFloat(value);
      setTrainingData({
        ...trainingData,
        sessionDuration: parsedValue,
      });
    } else if (["total", "totalWithTax"].includes(key)) {
      const parsedValue = isEmpty(value) ? undefined : Math.round(parseFloat(value) * 100);
      setTrainingData({ ...trainingData, [key]: parsedValue });
    } else {
      const filteredValue = isEmpty(value) ? undefined : value;
      setTrainingData({ ...trainingData, [key]: filteredValue });
    }
  };

  return (
    <div className={styles.manualTrainingDataModal} aria-label="Formulaire manuel formation">
      <form>
        <TextInput
          label="Formation"
          alwaysOpen
          required
          showRequired
          defaultValue={trainingData.trainingName}
          name="trainingName"
          onChange={(e) => setTrainingDataKey("trainingName", e.currentTarget.value)}
          autoComplete="off"
        />
        <TextInput
          label="Organisme"
          alwaysOpen
          required
          showRequired
          defaultValue={trainingData.trainingOrganizationName}
          name="trainingOrganizationName"
          onChange={(e) => setTrainingDataKey("trainingOrganizationName", e.currentTarget.value)}
          autoComplete="off"
        />
        <DropDown
          label="Mode"
          name="sessionMode"
          defaultValue={trainingData.sessionMode}
          isRequired
          showRequired
          onSelect={(key) => setTrainingDataKey("sessionMode", key)}
          options={Object.keys(modes).map((rawMode) => ({ label: modes[rawMode], value: rawMode }))}
        />
        <DropDown
          label="Type"
          name="sessionType"
          isRequired
          showRequired
          defaultValue={trainingData.sessionType}
          onSelect={(key) => setTrainingDataKey("sessionType", key)}
          options={dropDownSessionTypeOptions}
        />
        <TextInput
          required
          showRequired
          alwaysOpen
          label="Durée (en heures)"
          type="number"
          min={0}
          defaultValue={trainingData.sessionDuration}
          name="sessionDuration"
          onChange={(e) => setTrainingDataKey("sessionDuration", e.currentTarget.value)}
          autoComplete="off"
        />
        <TextInput
          label={useTaxSystem ? "Prix HT" : "Prix"}
          alwaysOpen
          required
          showRequired
          defaultValue={trainingData.total ? trainingData.total * 0.01 : trainingData.total}
          name="total"
          type="number"
          min={0}
          onChange={(e) => setTrainingDataKey("total", e.currentTarget.value)}
          autoComplete="off"
        />
        {useTaxSystem && (
          <TextInput
            label="Prix TTC"
            alwaysOpen
            required
            showRequired
            defaultValue={
              trainingData.totalWithTax
                ? trainingData.totalWithTax * 0.01
                : trainingData.totalWithTax
            }
            name="totalWithTax"
            type="number"
            min={0}
            onChange={(e) => setTrainingDataKey("totalWithTax", e.currentTarget.value)}
            autoComplete="off"
          />
        )}
        <div className={styles.nextStep}>
          <InteractiveButton
            aria-label={buttonLabel ?? "suivant"}
            label={buttonLabel ?? "Suivant"}
            loading={isLoading}
            onClick={() => {
              const isValid = checkTraining(trainingData, useTaxSystem);
              if (isValid) {
                onSuccess(trainingData as ManualTrainingData);
              } else {
                Acta.dispatchEvent("sendAppMessage", {
                  message: "Veuillez remplir tous les champs",
                  type: "error",
                });
              }
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default TrainingDataModal;
