import React, { useMemo, useState } from "react";
import { Row, useTable, useSortBy, useFilters } from "react-table";

import cx from "classnames";

import { Icon, Popover, TextInput } from "@skillup/ui";

import styles from "./List.module.scss";

export interface ListProps {
  theme?: "new" | "classic";
  initialSorting?: {
    id: string;
    desc?: boolean;
  };
  rowProps?: (row: any) => React.HTMLProps<HTMLTableRowElement>;
  rows: ({ id: string } & Record<string, object | number | string | JSX.Element>)[];
  columns: {
    label: string;
    width?: number;
    accessor: string;
    filterable?: boolean;
    showOverflow?: boolean;
    disableSortBy?: boolean;
    disableFilters?: boolean;
    sortMethod?: (a, b) => number;
    Cell?: (cellData: any) => JSX.Element;
    filterType?: "text" | "equals" | "includes";
    Filter?: (params: { column: any }) => JSX.Element;
    sortType?: "basic" | "number" | "string" | "datetime" | "alphanumeric";
  }[];
}

/**
 *
 * React Table helper to sort tables
 * with case-insensitive support
 *
 */
export const customInsensitiveCompare = (rowA: Row, rowB: Row, columnId: string, desc: boolean) => {
  const valueA = rowA.values[columnId].toLowerCase();
  const valueB = rowB.values[columnId].toLowerCase();

  if (desc) {
    return valueA.localeCompare(valueB) > 0 ? 1 : -1;
  }
  return valueB.localeCompare(valueA) > 0 ? -1 : 1;
};

export const TextFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
  return (
    <div className={styles.columnOptions} onClick={(e) => e.stopPropagation()}>
      <div className={styles.filter}>
        <TextInput
          autoFocus
          defaultValue={filterValue}
          placeholder="filtrer avec..."
          onChange={(newFilter) => setFilter(newFilter)}
          onKeyDown={function (e) {
            if (e.key === "Enter") {
              if (e.target instanceof HTMLInputElement) e.target.blur();
            }
          }}
        />
      </div>
    </div>
  );
};

const List = ({ columns, initialSorting, rowProps, rows, theme = "classic" }: ListProps) => {
  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const memoizedColumns = useMemo(
    () =>
      columns.map((c) => ({
        ...c,
        id: c.accessor,
        filter: c.filterType ?? "includes",
      })),
    [columns]
  );
  const memoizedRows = useMemo(() => rows, [rows]);

  const initialState = {};
  if (initialSorting) {
    initialState["sortBy"] = [initialSorting];
  }

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows: tableRows,
  } = useTable(
    {
      columns: memoizedColumns,
      data: memoizedRows,
      // @ts-ignore
      filterTypes,
      initialState,
      sortTypes: {
        alphanumeric: customInsensitiveCompare,
      },
    },
    useFilters,
    useSortBy
  ) as any;

  return (
    <table
      className={cx({
        [styles.classic]: theme === "classic",
        [styles.list]: true,
        [styles.new]: theme === "new",
      })}
      {...getTableProps()}
    >
      <colgroup>
        {columns.map((column) => (
          <col key={column.accessor} style={{ width: column.width }} />
        ))}
      </colgroup>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                aria-label={column.label}
              >
                {column.render("label")}
                {/* Add a sort direction indicator */}
                {column.isSorted ? (
                  column.isSortedDesc ? (
                    <Icon.ArrowDropdown className={styles.sort} />
                  ) : (
                    <Icon.ArrowDropup className={styles.sort} />
                  )
                ) : (
                  ""
                )}
                {/* Add a filter indicator */}
                {column.canFilter && <ListFilter column={column} />}
              </th>
            ))}
            <th aria-label="header-cell-filler"></th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {tableRows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              {...(rowProps && rowProps(row))}
              aria-label={`ligne-${row.original.id}`}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    aria-label={`cell-${cell.column.label}`}
                    className={cx({
                      [styles.showOverflow]: cell.column.showOverflow,
                    })}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
              <td aria-label="cell-filler"></td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

function ListFilter({ column }) {
  const [closePopover, setClosePopover] = useState<() => void>();

  return (
    <Popover
      placement={["top", "left"]}
      className={styles.toggleColumnOptions}
      content={() => <div onBlur={closePopover}>{column.render("Filter")}</div>}
      actions={({ close }) => {
        setClosePopover(() => close);
      }}
    >
      <Icon.Filter />
    </Popover>
  );
}

export default List;