import styles from "./TrainingDashboard.module.scss";
import { DashboardDataContextProvider, useDashboardData } from "../../../../hooks/dashboardContext";
import DSLayout from "components/DSLayout";

import { DSDashboardCardView, TypeGauge, colors, Select, DSFormGroupTextInput } from "@skillup/ui";

const Dashboard = () => {
  return (
    <DashboardDataContextProvider>
      <DSLayout title="Dashboard - Formation">
        <div className={styles.Dashboard}>
          <DashboardContent />
        </div>
      </DSLayout>
    </DashboardDataContextProvider>
  );
};

function DashboardContent() {
  const { dashboardData, activeSchedule, setActiveSchedule, schedules } = useDashboardData();

  return (
    <>
      <div className={styles.filters}>
        <DSFormGroupTextInput label="Plan" required className={styles.form}>
          <Select
            options={schedules.map((schedule) => ({ label: schedule.name, value: schedule.uuid }))}
            value={activeSchedule}
            onChange={(uuid) => setActiveSchedule(uuid)}
          />
        </DSFormGroupTextInput>
      </div>
      <div className={styles.content}>
        <DSDashboardCardView
          cards={
            dashboardData
              ? [
                  {
                    id: "1",
                    order: 1,
                    size: "L",
                    componentName: "DashboardCardTable",
                    componentData: {
                      title: "Indicateurs clés",
                      singleRow: true,
                      header: [
                        { value: "Effort de formation", align: "left" },
                        { value: "Heures de formation", align: "left" },
                        { value: "Collaborateurs formés", align: "left" },
                        {
                          value: "Durée moyenne de formation par collaborateur formé",
                          align: "left",
                        },
                        { value: "Budget moyen par collaborateur formé", align: "left" },
                        { value: "Collaborateurs n’ayant pas fait de demande", align: "left" },
                      ],
                      data: [
                        {
                          cells: [
                            {
                              value: dashboardData.done.cost.toLocaleString("fr-FR", {
                                currency: "EUR",
                                style: "currency",
                                maximumFractionDigits: 0,
                              }),
                            },
                            { value: `${dashboardData.done.hours} h` },
                            { value: dashboardData.done.trainees },
                            {
                              value: `${Math.floor(
                                dashboardData.done.hours / dashboardData.done.trainees
                              )} h`,
                            },
                            {
                              value: Math.floor(
                                dashboardData.done.cost / dashboardData.done.trainees
                              ).toLocaleString("fr-FR", {
                                currency: "EUR",
                                style: "currency",
                                maximumFractionDigits: 0,
                              }),
                            },
                            {
                              value:
                                dashboardData.totalInPlan.trainees - dashboardData.done.trainees,
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    id: "2",
                    order: 2,
                    size: "S",
                    componentName: "DashboardCardGauge",
                    componentData: {
                      title: "Part des collaborateurs formés",
                      activeColor: colors.$orangeBase,
                      inactiveColor: colors.$greyLighter,
                      activeLabel: "Formés",
                      inactiveLabel: "Non formés",
                      type: TypeGauge.RAW,
                      currentValue: dashboardData.done.trainees,
                      max: dashboardData.totalInPlan.trainees,
                    },
                  },
                  {
                    id: "3",
                    order: 3,
                    size: "S",
                    componentName: "DashboardCardTable",
                    componentData: {
                      title: "Effort de formation",
                      firstColumnLabel: true,
                      data: [
                        {
                          cells: [
                            { value: "Coût pédagogique" },
                            {
                              value: dashboardData.trainingCost.educationalCost.toLocaleString(
                                "fr-FR",
                                {
                                  currency: "EUR",
                                  style: "currency",
                                  maximumFractionDigits: 0,
                                }
                              ),
                              align: "right",
                            },
                          ],
                        },
                        {
                          cells: [
                            { value: "Coût salarial" },
                            {
                              value: dashboardData.trainingCost.salaryCost.toLocaleString("fr-FR", {
                                currency: "EUR",
                                style: "currency",
                                maximumFractionDigits: 0,
                              }),
                              align: "right",
                            },
                          ],
                        },
                        {
                          cells: [
                            { value: "Frais annexes" },
                            {
                              value: dashboardData.trainingCost.additionalExpenses.toLocaleString(
                                "fr-FR",
                                {
                                  currency: "EUR",
                                  style: "currency",
                                  maximumFractionDigits: 0,
                                }
                              ),
                              align: "right",
                            },
                          ],
                        },
                        {
                          cells: [
                            { value: "Financement" },
                            {
                              value: dashboardData.trainingCost.funding.toLocaleString("fr-FR", {
                                currency: "EUR",
                                style: "currency",
                                maximumFractionDigits: 0,
                              }),
                              align: "right",
                            },
                          ],
                        },
                        {
                          highlight: true,
                          cells: [
                            { value: "Total", bold: true },
                            {
                              value: dashboardData.done.cost.toLocaleString("fr-FR", {
                                currency: "EUR",
                                style: "currency",
                                maximumFractionDigits: 0,
                              }),
                              align: "right",
                              bold: true,
                            },
                          ],
                        },
                      ],
                    },
                  },
                  {
                    id: "4",
                    order: 4,
                    size: "S",
                    componentName: "DashboardCardGauge",
                    componentData: {
                      title: "Réalisé vs Budget",
                      activeColor: "#FFBB00",
                      inactiveColor: "#F6F7F8",
                      activeLabel: "Réalisé",
                      inactiveLabel: "Restant",
                      currentValue: dashboardData.done.cost,
                      max: dashboardData.budget,
                      type: TypeGauge.RAW,
                    },
                  },
                  {
                    id: "5",
                    order: 5,
                    size: "S",
                    componentName: "DashboardCardBar",
                    componentData: {
                      title: "Âge",
                      data: [
                        {
                          value: dashboardData.age.range1824,
                          label: "18-24 ans",
                          color: "#99CD32",
                        },
                        {
                          value: dashboardData.age.range2534,
                          label: "25-34 ans",
                          color: "#60C3D7",
                        },
                        {
                          value: dashboardData.age.range3544,
                          label: "35-44",
                          color: "#FFBB00",
                        },
                        {
                          value: dashboardData.age.range45,
                          label: "Plus de 45",
                          color: "#F4645D",
                        },
                      ],
                    },
                  },
                  {
                    id: "6",
                    order: 6,
                    size: "S",
                    componentName: "DashboardCardBar",
                    componentData: {
                      title: "CSP",
                      data: [
                        {
                          value: dashboardData.csp.cadreCount,
                          label: "Cadre",
                          color: "#99CD32",
                        },
                        {
                          value: dashboardData.csp.workerCount,
                          label: "Ouvrier",
                          color: "#FF6600",
                        },
                        {
                          value: dashboardData.csp.otherCount,
                          label: "Non renseigné",
                          color: "#E5E8EB",
                        },
                      ],
                    },
                  },
                  {
                    id: "7",
                    order: 7,
                    size: "S",
                    componentName: "DashboardCardBar",
                    componentData: {
                      title: "Genre",
                      data: [
                        {
                          value: dashboardData.gender.maleCount,
                          label: "Hommes",
                          color: "#60C3D7",
                        },
                        {
                          value: dashboardData.gender.femaleCount,
                          label: "Femmes",
                          color: "#FF6600",
                        },
                        {
                          value: dashboardData.gender.otherCount,
                          label: "Non renseigné",
                          color: "#E5E8EB",
                        },
                      ],
                    },
                  },
                ]
              : []
          }
        />
      </div>
    </>
  );
}

export default Dashboard;
