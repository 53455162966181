import {
  ChildrenRestrictions,
  InterviewActiveAttendeeRoles,
  InterviewRoles,
} from "@skillup/espace-rh-bridge";

/**
 * @description
 * This generic type is used to get the restrictions type (Disallow) of a specific Kind (e.g. "targets" | "question")
 */
export type GetRestrictions<TKind extends keyof ChildrenRestrictions> = ChildrenRestrictions[TKind];

export type GetActions<TKind extends keyof ChildrenRestrictions> = keyof Required<
  Res<TKind>
>["employee"];
export type Res<TKind extends keyof ChildrenRestrictions> = ChildrenRestrictions[TKind];
/**
 * @description
 * This hook is used to get permissions from a restrictions object.
 * It is more convenient since it's closer to the UI.
 * @param restrictions The restrictions set of the child
 * @returns two helpers, one for the employee and one for the manager.
 *
 */
export function usePermissions<TRestrictions extends Res<keyof ChildrenRestrictions>>(
  restrictions: TRestrictions
) {
  const permissions = generatePermissionsFromRestrictions(restrictions);

  function canPerformAction(action: keyof typeof permissions, role: InterviewRoles) {
    return permissions[action].includes(role as InterviewActiveAttendeeRoles);
  }

  return {
    employee: {
      canPerformAction: (action: keyof typeof permissions) =>
        canPerformAction(action, InterviewRoles.EMPLOYEE),
    },
    manager: {
      canPerformAction: (action: keyof typeof permissions) =>
        canPerformAction(action, InterviewRoles.MANAGER),
    },
    actionsForThisChild: Object.keys(permissions) as (keyof typeof permissions)[],
  };
}

function generatePermissionsFromRestrictions<
  TKind extends keyof ChildrenRestrictions,
  TChildRestrictions extends ChildrenRestrictions[TKind]
>(restrictions: TChildRestrictions) {
  const actions = Object.keys(restrictions["employee"]) as (keyof TChildRestrictions["employee"])[];
  const permissions: Partial<Record<(typeof actions)[number], InterviewActiveAttendeeRoles[]>> = {};

  for (const action of actions) {
    const actionPermissions: InterviewActiveAttendeeRoles[] = [];
    const employeeIsRestricted = restrictions[InterviewRoles.EMPLOYEE][action];
    if (!employeeIsRestricted) {
      actionPermissions.push(InterviewRoles.EMPLOYEE);
    }

    const managerIsRestricted = restrictions[InterviewRoles.MANAGER][action];
    if (!managerIsRestricted) {
      actionPermissions.push(InterviewRoles.MANAGER);
    }

    permissions[action] = actionPermissions;
  }

  return permissions;
}
