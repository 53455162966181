import React from "react";
import { Toggle, DSButton, HorizontalDivider, Flex } from "@skillup/ui";

import { Child } from "../types";
import styles from "./Child.module.scss";

import { InterviewRoles, InterviewTypes } from "types/Interview";

type InterviewsHistory = Child & { kind: "interviewsHistory" };
type InterviewsHistoryDisallow = InterviewsHistory["disallow"];

type TrainingsCollection = Child & { kind: "trainingsCollection" };
type TrainingsCollectionDisallow = TrainingsCollection["disallow"];

type FileUpload = Child & { kind: "upload" };
type FileUploadDisallow = FileUpload["disallow"];

type Skills = Child & { kind: "skills" };
type SkillsDisallow = Skills["disallow"];

type RestrictionAction =
  | keyof InterviewTypes.Child.Disallow[InterviewRoles.EMPLOYEE]
  | keyof InterviewTypes.Child.Targets.Disallow[InterviewRoles.EMPLOYEE]
  | keyof InterviewsHistoryDisallow[InterviewRoles.EMPLOYEE]
  | keyof TrainingsCollectionDisallow[InterviewRoles.EMPLOYEE]
  | keyof FileUploadDisallow[InterviewRoles.EMPLOYEE]
  | keyof SkillsDisallow[InterviewRoles.EMPLOYEE];

export type Restriction = {
  action: RestrictionAction;
  role: InterviewTypes.ActiveRoles;
  label: string;
  disabled?: boolean;
};

const commentRestrictions: Restriction[] = [
  { action: "comment", role: InterviewRoles.EMPLOYEE, label: "Commentaire employé" },
  { action: "comment", role: InterviewRoles.MANAGER, label: "Commentaire manager" },
];

const replyRestrictions: Restriction[] = [
  { action: "reply", role: InterviewRoles.EMPLOYEE, label: "Réponse employé" },
  { action: "reply", role: InterviewRoles.MANAGER, label: "Réponse manager" },
];

const basicRestrictions: Restriction[] = [...commentRestrictions, ...replyRestrictions];

export { commentRestrictions, replyRestrictions, basicRestrictions };

const DisallowBlock = ({
  data,
  updateChild,
  restrictions = basicRestrictions,
}: {
  data: Child;
  updateChild: (newChildData: Child) => void;
  restrictions?: Restriction[];
}) => {
  // @ts-ignore
  const { disallow = {} } = data;
  const toggleRight = (
    role: InterviewTypes.ActiveRoles,
    action: RestrictionAction,
    authorized: boolean
  ) => {
    const newDissalow = {
      ...disallow,
      [role]: {
        ...disallow[role],
        [action]: authorized,
      },
    };
    updateChild({
      ...data,
      // @ts-ignore
      disallow: newDissalow,
    });
  };

  const toggleONAllRestrictions = (role: InterviewTypes.ActiveRoles, ON = true) => {
    const actions = restrictions.map((restriction) => restriction.action);
    const newDissalow = {
      ...disallow,
      [role]: {},
    };

    for (const action of actions) {
      newDissalow[role][action] = ON;
    }

    updateChild({
      ...data,
      // @ts-ignore
      disallow: newDissalow,
    });
  };
  const toggleOFFAllRestrictions = (role: InterviewTypes.ActiveRoles) => {
    toggleONAllRestrictions(role, false);
  };

  const employeeRestrictions = restrictions.filter(({ role }) => role === InterviewRoles.EMPLOYEE);
  const managerRestrictions = restrictions.filter(({ role }) => role === InterviewRoles.MANAGER);

  return (
    <div className={styles.disallow} aria-label="restrictions">
      <h3>Restrictions Collaborateur</h3>
      <div style={{ display: "flex" }}>
        <DSButton
          label="Tout permettre"
          buttonSize="S"
          emphasis="Low"
          onClick={(e) => {
            e.preventDefault();
            toggleOFFAllRestrictions(InterviewRoles.EMPLOYEE);
          }}
        />
        <DSButton
          label="Tout restreindre"
          buttonSize="S"
          emphasis="Low"
          onClick={(e) => {
            e.preventDefault();
            toggleONAllRestrictions(InterviewRoles.EMPLOYEE);
          }}
        />
      </div>
      {employeeRestrictions.map((restriction) => (
        <div
          aria-label={`restriction ${restriction.action} ${restriction.role}`}
          key={restriction.label}
          className={styles.disallowAuth}
        >
          <Toggle
            className={styles.toggle}
            active={disallow?.[restriction.role]?.[restriction.action]}
            disabled={restriction.disabled}
            onToggle={(authorized) => toggleRight(restriction.role, restriction.action, authorized)}
          />
          <p>{restriction.label}</p>
        </div>
      ))}
      <HorizontalDivider bottom="s" top="s" />
      <h3>Restrictions Responsable d’entretien</h3>
      <Flex>
        <DSButton
          label="Tout permettre"
          buttonSize="S"
          emphasis="Low"
          onClick={(e) => {
            e.preventDefault();
            toggleOFFAllRestrictions(InterviewRoles.MANAGER);
          }}
        />
        <DSButton
          label="Tout restreindre"
          buttonSize="S"
          emphasis="Low"
          onClick={(e) => {
            e.preventDefault();
            toggleONAllRestrictions(InterviewRoles.MANAGER);
          }}
        />
      </Flex>
      {managerRestrictions.map((restriction) => (
        <div
          aria-label={`restriction ${restriction.action} ${restriction.role}`}
          key={restriction.label}
          className={styles.disallowAuth}
        >
          <Toggle
            className={styles.toggle}
            active={disallow?.[restriction.role]?.[restriction.action]}
            disabled={restriction.disabled}
            onToggle={(authorized) => toggleRight(restriction.role, restriction.action, authorized)}
          />
          <p>{restriction.label}</p>
        </div>
      ))}
    </div>
  );
};

export default DisallowBlock;
