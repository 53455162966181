import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { Flex } from "@skillup/ui";

import { DNDSectionItem } from "../../dndReducer";
import { StructureSection } from "./StructureSection";
import { SortableSectionPages } from "./SortableSectionPages";
import { Collapsible } from "../Collapsible/Collapsible";
import { useBuilderContext } from "../../../BuilderContext";
import { useBuilderModalsContext } from "../../../BuilderModalsContext";

import styles from "../../StructureBuilderStructure.module.scss";
import { selectHighlightUuid, setHighlightUuid } from "../../../reducer";

type DraggableSection = DNDSectionItem & {
  type: "section";
  sectionIndex: number;
};

export const SortableSection = ({
  section,
  disabledSortablePages,
  disabledSortableChildren,
  lastSectionIndex,
}: {
  section: DraggableSection;
  disabledSortablePages: boolean;
  disabledSortableChildren: boolean;
  lastSectionIndex: number;
}) => {
  const highlightUuid = useSelector(selectHighlightUuid);
  const { closedItems, setClosedItems, clickStructureItem } = useBuilderContext();
  const item = useMemo(() => {
    return {
      type: section.type,
      uuid: section.uuid,
      sectionIndex: section.sectionIndex,
    };
  }, [section]);
  const { active, isDragging, isOver, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: section.uuid,
      data: { ...item, uuid: undefined },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const toggleShowPages = useCallback(() => {
    setClosedItems((prev) => ({ ...prev, [section.uuid]: prev[section.uuid] ? undefined : true }));
    if (!closedItems[section.uuid] && highlightUuid) {
      const isHighlightIdInPagesOrChildren = section.pages.some(
        (page) =>
          page.uuid === highlightUuid || page.children.some((child) => child.uuid === highlightUuid)
      );
      if (isHighlightIdInPagesOrChildren) {
        const structureItem = document.getElementById(`structure_${section.uuid}`);
        if (structureItem) {
          structureItem.focus();
        }
        clickStructureItem(section.uuid);
      }
    }
  }, [closedItems, clickStructureItem, highlightUuid, section, setClosedItems]);

  const isOverByChildOrPage = useMemo(() => {
    return isOver && active?.data.current?.type !== "section";
  }, [isOver, active]);

  const handleClick = useCallback(() => {
    clickStructureItem(section.uuid);
  }, [clickStructureItem, section.uuid]);

  const { handleDeletion, handleDuplication, showActionsButtons } = useBuilderModalsContext();

  const dispatchStore = useDispatch();
  const handleFocus = useCallback(() => {
    dispatchStore(setHighlightUuid(section.uuid));
  }, [dispatchStore, section.uuid]);

  return (
    <Flex
      column
      ref={setNodeRef}
      style={style}
      className={cx(
        isDragging ? styles.dragging : "",
        isOverByChildOrPage && closedItems[section.uuid] ? styles.overClosed : ""
      )}
      {...attributes}
    >
      <StructureSection
        id={`structure_${section.uuid}`}
        displayMenuOnHover={!active}
        title={section.title}
        errors={section.errors?.map((error) => error.structure)}
        dragListeners={listeners}
        highlight={highlightUuid === section.uuid}
        onClick={handleClick}
        onFocus={handleFocus}
        showPages={!closedItems[section.uuid]}
        toggleShowPages={toggleShowPages}
        onDuplication={() => handleDuplication(item)}
        onDeletion={() => handleDeletion(item)}
        showActionsButtons={showActionsButtons(item)}
      />
      <Collapsible isOpen={!closedItems[section.uuid]}>
        <SortableSectionPages
          section={section}
          disabledSortableChildren={closedItems[section.uuid] || disabledSortableChildren}
          disabled={closedItems[section.uuid] || disabledSortablePages}
          lastSectionIndex={lastSectionIndex}
        />
      </Collapsible>
    </Flex>
  );
};
