import React from "react";
import { formatMonetary } from "@skillup/shared-utils";

import useSettings from "hooks/useSettings";
import useTranslation from "hooks/useTranslation";

import styles from "./CollectionDashboardStyles.module.scss";

interface IData {
  name: string;
  data: { [key: string]: { value: number; label: string } };
}

interface ISlice {
  color: string;
  path: string;
  key: string;
  value: { value: number; label: string };
}

const diameter = 60;
const margin = 3;
const radius: number = diameter / 2;
const cx = margin + radius;
const cy = margin + radius;

const colors = [
  "#5b55d4",
  "#a647c7",
  "#d938af",
  "#fb3392",
  "#ff4571",
  "#ff6451",
  "#ff852f",
  "#ffa600",
  "#5b55d4",
  "#a647c7",
  "#d938af",
  "#fb3392",
  "#ff4571",
  "#ff6451",
  "#ff852f",
  "#ffa600",
  "#5b55d4",
  "#a647c7",
  "#d938af",
  "#fb3392",
  "#ff4571",
];

const Split = ({ data, name }: IData) => {
  const settings = useSettings();
  const { i18n } = useTranslation();

  const total = Object.values(data).reduce((count, memo) => memo.value + count, 0);
  let origin = [margin + radius, margin];
  let cumulatedAngle = 0;

  const sortedData = Object.entries(data).sort((a, b) => (a[1].value > b[1].value ? -1 : 1));
  const others: Array<string> = [];

  // if (sortedData.length > 20) {
  //   const fullyDisplayed = sortedData.slice(0, 20);
  //   let totalOthers: number = 0;
  //   for (const dataPoint of sortedData.slice(20)) {
  //     totalOthers += dataPoint[1].value;
  //     others.push(`${dataPoint[0]} (${dataPoint[1].label})`);
  //   }

  //   sortedData = [...fullyDisplayed, ['Autres', totalOthers]];
  // }

  const slices: Array<ISlice> = sortedData.map((dataPoint, index) => {
    const sliceValue = (dataPoint[1].value / total) * 100;
    const angle = 2 * Math.PI * ((sliceValue as number) / 100);
    const currentOrigin = origin;
    const slice1X = cx + radius * Math.cos(Math.PI / 2 - (angle + cumulatedAngle));
    const slice1Y = cy - radius * Math.sin(Math.PI / 2 - (angle + cumulatedAngle));
    origin = [slice1X, slice1Y];
    cumulatedAngle += angle;

    return {
      color: colors[index],
      key: dataPoint[0],
      path: `M ${currentOrigin[0]} ${currentOrigin[1]} A ${radius} ${radius} 0 ${
        sliceValue < 50 ? "0" : "1"
      } 1 ${slice1X} ${slice1Y} L ${margin + radius} ${margin + radius} L ${currentOrigin[0]} ${
        currentOrigin[1]
      } Z`,
      value: dataPoint[1],
    };
  });

  return (
    <div>
      <h3>{name}</h3>

      <svg width={diameter + margin * 2} height={diameter + margin * 2}>
        {slices.map(({ path, color }, index) => (
          <path key={index} d={path} fill={color} />
        ))}
      </svg>

      <ul>
        {slices.map(({ color, key, value }) => (
          <li key={key}>
            <div style={{ backgroundColor: color }} />
            <span>{key}</span>
            <span>
              {formatMonetary(value.value, {
                locale: i18n.language,
                currency: settings.settings.currency,
              })}
            </span>
          </li>
        ))}
      </ul>

      {others.length > 0 && <div className={styles.others}>{others.join(", ")}</div>}
    </div>
  );
};

export default React.memo(Split);
