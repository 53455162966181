import React, { ReactNode } from "react";
import Icon from "components/Icon";

import styles from "./Header.module.scss";

interface IProps {
  children?: ReactNode;
  label?: string;
  strokeIcon?: string;
  skillupUiIcon?: ReactNode;
}

export default function Header(props: IProps) {
  const { children, label, strokeIcon, skillupUiIcon } = props;

  return (
    <header className={styles.Header}>
      {label && (
        <h1>
          {strokeIcon && <Icon strokeIcon={strokeIcon} width={15} />}
          {skillupUiIcon}
          {label}
        </h1>
      )}
      {children && label && <div className={styles.children}>{children}</div>}
      {children && !label && children}
    </header>
  );
}
