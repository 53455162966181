import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

export default async (rows, reloadRows: (uuids: string[]) => void) => {
  try {
    await DataLayer.request({
      body: JSON.stringify({ rows }),
      method: "POST",
      url: "/v1/scheduleRow/action/validation-reminder",
    });

    reloadRows(rows);

    Acta.dispatchEvent("closeModal");

    Acta.dispatchEvent("sendAppMessage", {
      message: rows.length === 1 ? "Email envoyé avec succès." : "Emails envoyés avec succès.",
      type: "success",
    });
  } catch (error) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "L‘email n‘a pas pu être envoyé",
      type: "error",
    });
  }
};
