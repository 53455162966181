import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";

import Icon from "components/Icon";
import scrollTo from "utils/scrollTo";
import { chevronLeft, chevronRight } from "uiAssets/Icons";

import styles from "./Pagingstyles.module.scss";

interface ILink {
  class: string;
  label?: string | number;
  to: string;
  type?: string;
}
interface IProps {
  links: Array<string>;
  currentPage: number;
  className?: string;
  container: HTMLElement;
}

export default class Paging extends React.PureComponent<IProps, {}> {
  private onLinkClick = () => {
    const { container } = this.props;
    scrollTo(0, container, { minDuration: 250, maxDuration: 500 });
  };

  public render(): JSX.Element {
    const { currentPage, links, className } = this.props;

    let startPage = currentPage - 5;
    if (startPage > links.length - 10) startPage = links.length - 10;
    startPage = Math.max(0, startPage);

    let displayedLinks: Array<ILink> = [];

    if (startPage > 0) {
      displayedLinks = [
        {
          class: styles.endingsLinks,
          label: 1,
          to: links[0],
          type: "first-last",
        },
      ];
    }

    displayedLinks = [
      ...displayedLinks,
      {
        class: styles.dark,
        to: links[Math.min(links.length - 1, Math.max(currentPage - 2, 0))],
        type: "prev",
      },
      ...links.slice(startPage, startPage + 10).map((link, index) => ({
        class: cx(styles.light, {
          [styles.currentLink]: index + startPage === currentPage - 1,
        }),
        label: index + startPage + 1,
        to: link,
      })),
      {
        class: styles.dark,
        to: links[Math.min(currentPage, links.length - 1)],
        type: "next",
      },
    ];

    if (startPage < links.length - 10) {
      displayedLinks = [
        ...displayedLinks,
        {
          class: styles.endingsLinks,
          label: links.length,
          to: links[links.length - 1],
          type: "first-last",
        },
      ];
    }

    return (
      <nav className={cx(styles.Paging, className)}>
        {displayedLinks.map((link) => (
          <Link
            key={link.to + link.type}
            to={link.to}
            className={link.class}
            onClick={this.onLinkClick}
          >
            {(link.type === "prev" || link.type === "next") && (
              <Icon
                icon={link.type === "prev" ? chevronLeft : chevronRight}
                width={10}
                fill="#fff"
              />
            )}
            {link.label}
          </Link>
        ))}
      </nav>
    );
  }
}
