import React, { useEffect, useCallback } from "react";
import moment from "moment";
import { useLocalStorage } from "react-use";
import { isNil } from "lodash";
import { Summary as ISummary } from "@skillup/types";

import { Disclaimer, Warnings } from "./components";
import UnmanagedBookingFormComponent from "./components/UnmanagedBookingForm";
import PositioningProvider, { useUnmanagedBookingContext, initialState } from "./context";

interface Props {
  users: { noEmail: boolean; fullName: string }[];
  noEmail?: boolean;
  noManager?: boolean;
  activeTab: string;
  summary: ISummary;
  uuids: string[];
  reload: () => void;
}

const DATE_FORMAT = "YYYY-MM-DD";

function Step({ summary }: { summary: ISummary }) {
  const [{ activeStep, uuids }, setUnmanagedBookingContext] = useUnmanagedBookingContext();

  useEffect(() => {
    if (summary) {
      setUnmanagedBookingContext({
        addedManually: summary.addedManually,
        form: {
          sessionCity: summary.sessionCity,
          duration: summary.durationAsHours,
          price: !isNil(summary.rawPrice) ? summary.rawPrice * 0.01 : undefined,
          trainingPrice: (!isNil(summary.trainingPrice)
            ? summary.trainingPrice * 0.01
            : ""
          ).toString(),
          priceWithTax: !isNil(summary.rawPriceWithTax)
            ? summary.rawPriceWithTax * 0.01
            : undefined,
          trainingPriceWithTax: (!isNil(summary.trainingPriceWithTax)
            ? summary.trainingPriceWithTax * 0.01
            : ""
          ).toString(),
          durationUnit: "hours",
          rows: uuids,
          sessionStartDate:
            summary.sessionStartDate && moment(summary.sessionStartDate).format(DATE_FORMAT),
          sessionEndDate:
            summary.sessionEndDate && moment(summary.sessionEndDate).format(DATE_FORMAT),
          canSendEval: summary.holdReviews === false,
        },
      });
    }
  }, [uuids, setUnmanagedBookingContext, summary]);

  switch (activeStep) {
    case "disclaimer":
      return <Disclaimer />;
    case "off-bounds-positioning-form":
      return <UnmanagedBookingFormComponent />;
    case "warnings":
      return <Warnings />;
    default:
      return null;
  }
}

export default (props: Props) => {
  const { activeTab, uuids, users, reload, summary } = props;
  const isProcessingTab = activeTab === "plan-processing";
  const [canHideDisclaimer] = useLocalStorage("hideUnmanagedBookingDisclaimer");

  const computeActiveStep = useCallback(() => {
    if (isProcessingTab) {
      return "off-bounds-positioning-form";
    }

    if (canHideDisclaimer) {
      return "off-bounds-positioning-form";
    }

    return "disclaimer";
  }, [canHideDisclaimer, isProcessingTab]);

  const activeStep = computeActiveStep();

  return (
    <PositioningProvider
      initialState={{ ...initialState, activeStep, uuids, tab: activeTab, users, reload }}
    >
      <Step summary={summary} />
    </PositioningProvider>
  );
};
