import { Dispatch, SetStateAction } from "react";

import { GridRowParams } from "@mui/x-data-grid-pro";
import { DSDropdown, DSDropdownDivider, DSDropdownItem } from "@skillup/ui";
import { TrainingRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import { mapForIntraCreation } from "containers/Supervisor/routes/Intra/CreationView/IntraCreationView";

import { ModalState } from "../IntraListView";
import { TrainingIntra } from "../types";

const ActionsCell = (
  props: GridRowParams<TrainingIntra> & {
    history: any;
    setModalState: Dispatch<SetStateAction<ModalState>>;
  }
) => {
  const { row, history, setModalState } = props;

  const duplicateProgram = async (row: TrainingIntra) => {
    const request = buildRequest<TrainingRoutes.Search.GetBySlug>({
      method: "GET",
      path: "/training/{slug}",
      params: {
        slug: row.seoSlug,
      },
    });

    const data = await request();
    const training = {
      ...data.training,
      properties: {
        ...data.training.properties,
        name: `${data.training.properties.name} (copie)`,
      },
    };
    const mappedProps = mapForIntraCreation(training);
    history.push("/responsable/programmes/gestion-intras/creation", mappedProps);
  };

  const openIntraDeletionModal = (row: TrainingIntra) => {
    setModalState({ selectedRow: row, isOpen: true });
  };

  return (
    <>
      <DSDropdown buttonSize="S" overflow="initial">
        <DSDropdownItem
          label="Voir dans le catalogue"
          onClick={() => history.push(`/responsable/programmes/catalogue/${row.seoSlug}`)}
        />
        <DSDropdownDivider />
        <DSDropdownItem
          label="Créer une session"
          onClick={() =>
            history.push(
              `/responsable/mes-sessions/en-cours?mode=create&trainingReference=${row.reference}`
            )
          }
        />
        <DSDropdownDivider />
        <DSDropdownItem label="Dupliquer" onClick={() => duplicateProgram(row)} />
        <DSDropdownItem label="Supprimer" onClick={() => openIntraDeletionModal(row)} />
      </DSDropdown>
    </>
  );
};

export default ActionsCell;
