import React from "react";

import CheckBox from "components/CheckBox";
import User from "utils/User";

import styles from "./Recap.module.scss";

interface IUser {
  email: string;
  fullName?: string;
  noEmail?: boolean;
  alreadyLinked?: boolean;
}

interface IProps {
  users: Array<IUser>;
  doNotNotify: boolean;
  setDoNotNotify: React.Dispatch<React.SetStateAction<boolean>>;
  isPastProject: boolean;
}

const Recap = ({ users, doNotNotify, setDoNotNotify, isPastProject }: IProps) => {
  const alreadyLinkedUsers = users.filter((u) => u.alreadyLinked);
  const newUsers = users.filter((u) => !u.alreadyLinked);
  const usersWithoutEmail = users.filter((u) => u.noEmail);

  return (
    <div className={styles.Recap}>
      {newUsers.length > 0 && (
        <div className={styles.block}>
          <p>
            Les lignes suivantes seront créées et directement placées dans l'onglet "Inscrit" du
            Plan de formation:
          </p>
          <ul>
            {newUsers.map((u) => (
              <li key={u.email}>{u.fullName || u.email}</li>
            ))}
          </ul>
        </div>
      )}
      {alreadyLinkedUsers.length > 0 && (
        <div className={styles.block}>
          <p>
            Les lignes suivantes seront déplacées de l'onglet "À inscrire" vers l'onglet "Inscrit"
            du Plan de formation:
          </p>
          <ul>
            {alreadyLinkedUsers.map((u) => (
              <li key={u.email}>{u.fullName || u.email}</li>
            ))}
          </ul>
        </div>
      )}
      {isPastProject && (
        <p className={styles.warningBox}>
          Les collaborateurs ne recevront pas d'e-mail de confirmation d'inscription.
        </p>
      )}
      {!isPastProject && (
        <CheckBox
          item={{
            label: User.isSkillupAdmin()
              ? "Ne pas envoyer un email de confirmation au collaborateur"
              : "Envoyer un email de confirmation d'inscription au collaborateur",
            value: "true",
          }}
          isSelected={User.isSkillupAdmin() ? doNotNotify : !doNotNotify}
          onChange={(_, isSelected) =>
            setDoNotNotify(User.isSkillupAdmin() ? isSelected : !isSelected)
          }
        />
      )}
      {usersWithoutEmail.length === 1 && (
        <p className={styles.warning}>
          L'utilisateur {usersWithoutEmail[0].fullName} n'a pas d'adresse e-mail valide.
          <br /> Il ne recevra pas d'e-mail de confirmation d'inscription.
        </p>
      )}
      {usersWithoutEmail.length > 1 && (
        <p className={styles.warning}>
          Les adresses e-mail des utilisateurs {usersWithoutEmail.map((u) => u.fullName).join(", ")}{" "}
          sont invalides.
          <br /> Ils ne recevront pas d'e-mail de confirmation d'inscription.
        </p>
      )}
    </div>
  );
};

export default Recap;
