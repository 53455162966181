import React from "react";
import { InterviewSection, InterviewSectionPage } from "@skillup/types";
import { Button, TextInput } from "@skillup/ui";
import { v4 as uuid } from "uuid";
import { omit } from "lodash";
import { useRecoilState } from "recoil";

import Page from "../Page";
import { templateState } from "../store";

import styles from "./Section.module.scss";

interface SectionProps {
  section: InterviewSection;
  sectionIndex: number;
  updateSection: (newSectionData: InterviewSection) => void;
  deleteSection: () => void;
}

const Section: React.FunctionComponent<SectionProps> = ({
  section,
  sectionIndex,
  updateSection,
  deleteSection,
}) => {
  const [template, setTemplate] = useRecoilState(templateState);
  const addPage = () => {
    const newPage: InterviewSectionPage = {
      children: [],
      title: "Nouvelle page",
      uuid: uuid(),
    };

    updateSection({
      ...section,
      pages: [...section.pages, newPage],
    });
  };

  const duplicate = () => {
    setTemplate({
      ...template,
      sections: [
        ...template.sections,
        {
          ...omit(section, "uuid"),
          title: `COPIE ${section.title}`,
          pages: section.pages.map((p) => ({
            ...p,
            uuid: uuid(),
            children: p.children.map((c) => ({
              ...c,
              uuid: uuid(),
            })),
          })),
          uuid: uuid(),
        },
      ],
    });
  };

  return (
    <div className={styles.section} aria-label={`section ${sectionIndex}`}>
      <TextInput
        aria-label="Titre de la section"
        key={section.uuid}
        value={section.title}
        onChange={(title) => updateSection({ ...section, title })}
        className={styles.input}
      />
      <div className={styles.pages}>
        {section.pages?.length === 0 && <p className={styles.info}>Aucune page pour l'instant</p>}
        {section.pages.map((page, pageIndex) => (
          <Page
            key={page.uuid}
            pageIndex={pageIndex}
            page={page}
            section={section}
            updateSection={updateSection}
          />
        ))}
      </div>
      <div className={styles.action}>
        <Button aria-label="Ajouter une page" label="Ajouter une page" onClick={addPage} />
        <Button aria-label="Dupliquer la section" label="Dupliquer" onClick={duplicate} />
        <Button
          aria-label="Supprimer la section"
          label="Supprimer"
          onClick={() => {
            const confirmation = window.confirm(
              "Êtes-vous sûr de vouloir supprimer cette section ?"
            );
            if (confirmation) deleteSection();
          }}
          kind="danger"
        />
      </div>
    </div>
  );
};

export default Section;
