import React, { useCallback } from "react";
import { useToggle } from "react-use";
import { useBaseUrl, useQuery } from "@skillup/hooks";
import { Link, useHistory } from "react-router-dom";
import type { ITraining } from "@skillup/types";
import { isNil } from "lodash";

import { analytics } from "services";
import { chevronRight as chevronRightIcon, mapPin as mapPinIcon } from "uiAssets/Icons";
import Colors from "uiAssets/Colors";

import FormatPrice from "utils/FormatPrice";
import User from "utils/User";
import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";
import InteractiveButton from "components/InteractiveButton";
import Icon from "components/Icon";
import modalitiesConstants from "constants/modalities";
import TrainingScore from "components/TrainingScore";

import styles from "./TrainingsList.module.scss";

export interface Props extends ITraining {
  certification?: string;
  formattedDuration?: string;
  isCPF?: boolean;
  priceIntra?: number;
  sessionThreshold?: Date;
  subtitle?: string;
}

export default (props: Props): JSX.Element => {
  const {
    certification,
    description,
    formattedDuration,
    isCPF,
    isNew,
    mode,
    name,
    objectives,
    organization,
    organizationProfileLogoSmall,
    price,
    priceWithTax,
    priceIntra,
    reviewsCount,
    reviewsGlobalScore,
    seoSlug_original,
    sessions,
    sessionThreshold,
    subtitle,
    tags,
  } = props;

  const history = useHistory();
  const settings = useSettings();
  const { i18n } = useTranslation();
  const [isExpanded, toggleExpanded] = useToggle(false);
  const { baseUrl } = useBaseUrl();
  const { place } = useQuery<{ place?: any }>();

  const nextSessions = (sessions || [])
    .map((session) => ({
      ...session,
      date: new Date(session.date),
    }))
    .filter(
      (session) => session.date.getTime() > (sessionThreshold ? sessionThreshold.getTime() : 0)
    )
    .slice(0, 3);

  const descriptionText = description || objectives || subtitle || null;

  const computeLink = useCallback((): string => {
    const link = `${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/${seoSlug_original}`;

    if (place) {
      return link + `?place=${encodeURIComponent(place.label)}`;
    }

    return link;
  }, [baseUrl, place, seoSlug_original]);

  const link = computeLink();
  const computedPrice = User.isCompanyWithTax()
    ? FormatPrice({
        price: priceWithTax ?? price ?? priceIntra,
        withTax: !isNil(priceWithTax),
        currency: settings.settings.currency,
        language: i18n.language,
      })
    : FormatPrice({
        price: price ?? priceIntra,
        currency: settings.settings.currency,
        language: i18n.language,
      });

  return (
    <div>
      <header>
        <div
          className={styles.logo}
          style={{
            backgroundImage: `url("${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${organizationProfileLogoSmall}")`,
          }}
        />
        <Link to={link} className={styles.trainingLink}>
          <h3 className={styles.title}>{name}</h3>
          {reviewsCount > 0 && (
            <TrainingScore
              score={Math.round((reviewsGlobalScore as number) * 10) / 10}
              reviewsCount={reviewsCount}
            />
          )}
          <p className={styles.organizationLink}>{`Par ${organization}`}</p>

          {descriptionText && <p className={styles.descriptionText}>{descriptionText}</p>}

          <div className={styles.tags}>
            {certification && <span className={styles.certification}>Certifiante</span>}
            {isCPF && <span className={styles.cpf}>CPF</span>}
            {isNew && <span className={styles.cpf}>Nouvelle</span>}
            {false && (tags[0] || []).map((tag) => <span key={tag}>{tag}</span>)}
          </div>
        </Link>
        <div className={styles.actions}>
          <button
            onClick={() => {
              analytics.sendEvent("Catalog", "Catalog_Training_Button_Plus");
              toggleExpanded();
            }}
          >
            {isExpanded ? "-" : "+"}
          </button>
        </div>
      </header>

      <div className={styles.summary}>
        <div>
          {mode && `${modalitiesConstants.mapping[mode]} -- `}
          {formattedDuration}
        </div>
        {computedPrice && <div>{computedPrice}</div>}
        {(nextSessions || [])[0] && (
          <Link
            onClick={() => {
              analytics.sendEvent("Catalog", "Catalog_Training_Button_Voir_Les_Sessions");
            }}
            to={link + "#sessions"}
            className={styles.sessionsLink}
          >
            Voir les sessions
          </Link>
        )}
      </div>

      <div className={styles.data} style={{ display: isExpanded ? "block" : "none" }}>
        {descriptionText && <h3>À propos</h3>}
        {descriptionText && <p>{descriptionText}</p>}
        {(nextSessions || []).length > 0 && (
          <h3>
            Prochaine{nextSessions.length > 1 ? "s" : ""} session
            {nextSessions.length > 1 ? "s" : ""}
          </h3>
        )}
        {nextSessions && nextSessions.length > 0 && (
          <div className={styles.nextSessionLinks}>
            {(nextSessions || []).map((session) => (
              <Link
                key={session.uuid}
                to={`${seoSlug_original}`}
                className={styles.nextSessionLink}
              >
                <Icon icon={mapPinIcon} width={12} />
                {
                  // @ts-ignore
                  session.type !== "distancial" ? session.city : "À distance"
                }{" "}
                -{" "}
                {new Date(session.date).toLocaleString("fr-FR", {
                  day: "numeric",
                  month: "long",
                })}
              </Link>
            ))}
          </div>
        )}
        <nav>
          <InteractiveButton
            size="small"
            label="Voir la formation"
            onClick={() => history.push(link)}
            iconRight={chevronRightIcon}
            className={styles.formationLinkButton}
            style={{
              background: Colors.highlightColor,
              border: `1px solid ${Colors.highlightColor}`,
              fontWeight: 700,
            }}
          />
        </nav>
      </div>
    </div>
  );
};
