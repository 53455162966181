import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DSLayout from "components/DSLayout";
import { DSFilters, Flex } from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";

import Loader from "components/Loader";
import useTranslation from "hooks/useTranslation";
import User from "utils/User";

import { DashboardConfigData } from "../types";
import { defaultConfig } from "../utils/defaultConfig";

import { fetchAnalyticsConfig, upsertAnalyticsConfig } from "../adapters/config";
import { fetchDashboardPanels, getFiltersConfig } from "../adapters/insights";

import { DashboardCard } from "../components/Cards/Card";

export const TrainingDashboard = () => {
  return (
    <DSLayout title="Dashboard - Formation">
      <DashboardContent />
    </DSLayout>
  );
};

function DashboardContent() {
  const activeUser = User.getUserData();
  const activeSchedule = User.getActiveSchedule();

  const { t } = useTranslation();
  const [filters, setFilters] = useState<{
    config: any;
    props: any;
  }>();
  const [dashboardPanels, setDashboardPanels] = useState<DashboardConfigData["panels"]>();
  const [filterValues, setFilterValues] = useState<Record<string, any>>({});

  const companyUuid = activeUser.activeCompany.uuid;

  useEffect(() => {
    (async () => {
      const analyticsConfig = await fetchAnalyticsConfig(companyUuid);
      if (!analyticsConfig) {
        await upsertAnalyticsConfig(companyUuid, defaultConfig);
      }

      const [panels, filters] = await Promise.all([
        fetchDashboardPanels(),
        getFiltersConfig(activeSchedule.name),
      ]);

      const initialFilterValues = Object.keys(filters.config).reduce((acc, key) => {
        acc[key] = {
          value: filters.props[key].defaultValue,
          operator: ListUtils.FilterOperator.CONTAINS,
          type: ListUtils.FilterType.MULTISELECT,
        };

        return acc;
      }, {});

      setFilters(filters);
      setFilterValues(initialFilterValues);
      setDashboardPanels(panels);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!filters || !filterValues) {
    return (
      <div>
        Waiting for filters to load... <Loader />
      </div>
    );
  }

  return (
    <Flex column>
      <p> Training Dashboard </p>
      <Link to="/responsable/dashboard/training/configuration">Configuration</Link>
      <DSFilters t={t} filters={filters.props} config={filters.config} onChange={setFilterValues} />

      {dashboardPanels?.map((panel) => (
        <Flex key={panel.short_name} column>
          <p>{panel.short_name}</p>
          {panel.cards.map((card) => (
            <DashboardCard key={card.short_name} card={card} filters={filterValues} />
          ))}
        </Flex>
      ))}
    </Flex>
  );
}
