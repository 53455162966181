import React from "react";
import cx from "classnames";

import Icon from "components/Icon";
import { check as checkIcon } from "uiAssets/StrokeIcons";
import { rowHeight, headerRowHeight } from "constants/dataTable";

import { Column, Row } from "components/DataTable/types";

import BodyCell from "../Cell/BodyCell";
import styles from "../../DataTableStyles.module.scss";

export interface IProps {
  rowUuid: string;
  isLoading: boolean;
  isSelected: boolean;
  rowIndex: number;
  onCheckBoxClick: (uuid: string) => void;
  onRowClick: (uuid: string) => void;
  columns: Array<Column>;
  row: Row;
  hoveredColumnIndex: number;
  shouldDisplay: boolean;
  deactivateMultiSelect: boolean;
}

export default class BodyRow extends React.PureComponent<IProps, {}> {
  public computeRowPosition = (rowIndex: number): string => {
    return `${rowHeight * rowIndex + headerRowHeight}px`;
  };

  public render(): JSX.Element {
    const {
      isSelected,
      isLoading,
      rowUuid,
      rowIndex,
      onCheckBoxClick,
      onRowClick,
      row,
      columns,
      hoveredColumnIndex,
      shouldDisplay,
      deactivateMultiSelect,
    } = this.props;

    return (
      <React.Fragment>
        {shouldDisplay && (
          <div
            className={cx(styles.row, styles.bodyRow, {
              [styles.selected]: isSelected,
              [styles.loading]: isLoading,
            })}
            style={{
              top: this.computeRowPosition(rowIndex),
            }}
            data-uuid={row.uuid}
          >
            {!deactivateMultiSelect && (
              <div
                className={cx(styles.checkbox, { [styles.selected]: isSelected })}
                onClick={() => onCheckBoxClick(rowUuid)}
              >
                <div>
                  <Icon strokeIcon={checkIcon} width={8} stroke="#fff" />
                </div>
              </div>
            )}
            {columns.map((column, colIndex) => {
              return (
                <BodyCell
                  key={`${rowUuid}${column.key}${colIndex}`}
                  width={columns[colIndex].width}
                  hovered={hoveredColumnIndex === colIndex + 1}
                  lazyLoading={row.lazyLoading}
                  data={row.columns[column.key]}
                  type={columns[colIndex].type}
                  editable={Boolean(columns[colIndex].editable)}
                  onClick={onRowClick}
                  column={column}
                  rowUuid={rowUuid}
                />
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}
