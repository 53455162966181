import { ComponentClass, ReactNode, useMemo } from "react";
import { Route, Switch, RouteComponentProps, useLocation } from "react-router-dom";
import { DSDropdownItem } from "@skillup/ui";

import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import PortalSchedules from "./components/PortalSchedules";
import ScheduleFieldsV2 from "./components/Fields/ScheduleFieldsV2";
import EnrichmentFields from "./components/FieldsV2";
import ScheduleTabsConfiguration from "./components/ScheduleTabsConfiguration";
import ExportConfiguration from "./components/ExportConfiguration";
import SidePanelConfiguration from "./components/SidePanelConfiguration";
import AddTrainingScheduleModal from "./components/AddTrainingScheduleModal/AddTrainingScheduleModal";
import Acta from "utils/Acta";
import User from "utils/User";
import history from "utils/history";
import { useSchedule } from "hooks";

import styles from "./PortalPlans.module.scss";

interface IRouteInterface {
  path: string;
  label: string;
  component: ReactNode;
  hasAccess?: boolean;
}

const isAdmin = User.isSkillupAdmin() || User.isSkillupDeveloper();

const PortalRoutes: Array<IRouteInterface> = [
  {
    component: PortalSchedules,
    label: "Plans de formation (V2)",
    path: "/responsable/plans",
    hasAccess: isAdmin,
  },
  {
    component: ScheduleFieldsV2,
    label: "Enrichissement",
    path: "/responsable/plans/enrichissement",
    hasAccess: isAdmin,
  },
  {
    component: EnrichmentFields,
    label: "Enrichissement",
    path: "/responsable/plans/enrichissement-v2",
    hasAccess: isAdmin || (User.isRF() && !User.hasFieldsV2Disable()),
  },
  {
    component: ScheduleTabsConfiguration,
    label: "Onglets Plan & Recueil",
    path: "/responsable/plans/onglets-plan-recueil",
    hasAccess: isAdmin,
  },
  {
    component: ExportConfiguration,
    label: "Export",
    path: "/responsable/plans/export",
    hasAccess: isAdmin,
  },
  {
    component: SidePanelConfiguration,
    label: "SidePanel",
    path: "/responsable/plans/panneau-lateral",
    hasAccess: isAdmin,
  },
];

export default function Plans() {
  const { pathname } = useLocation();
  const { refetch, scheduleList } = useSchedule();
  const { activeCompany } = User.getUserData();
  const companyName = activeCompany?.name;
  const hasFieldsDisabled = User.hasFieldsV2Disable();

  const layouts = useMemo(() => {
    const showAddTrainingScheduleModal = () => {
      Acta.setState("modalDisplayed", {
        content: (
          <AddTrainingScheduleModal
            schedules={scheduleList}
            reload={refetch}
            companyName={companyName}
          />
        ),
        size: "medium",
        showOverflow: true,
        title: "Nouveau plan de formation",
      });
    };

    const fieldsTabs = {
      v1: {
        id: "enrichissement",
        label: "Enrichissement",
        url: "/responsable/plans/enrichissement",
      },
      v2: {
        id: "enrichissement-v2",
        label: "Enrichissement",
        url: "/responsable/plans/enrichissement-v2",
      },
    };

    const tabs = [
      {
        tab: {
          id: "plans-formation",
          label: "Plans de formation",
          url: "/responsable/plans",
        },
        primaryButton: (
          <DSNewHeaderButton
            label="Ajouter un plan de formation"
            onClick={() => showAddTrainingScheduleModal()}
          />
        ),
        dropdownContent: User.isSkillupDeveloper()
          ? [
              <DSDropdownItem
                key="import-plan"
                label="Import de plan historique"
                onClick={() => history.push("/responsable/ops-administration/import")}
              />,
            ]
          : undefined,
      },
      { tab: hasFieldsDisabled ? fieldsTabs.v1 : fieldsTabs.v2 },
      {
        tab: {
          id: "onglets-plan-recueil",
          label: "Onglets Plan & Recueil",
          url: "/responsable/plans/onglets-plan-recueil",
        },
      },
      {
        tab: {
          id: "export",
          label: "Export",
          url: "/responsable/plans/export",
        },
      },
      {
        tab: {
          id: "panneau-lateral",
          label: "SidePanel",
          url: "/responsable/plans/panneau-lateral",
        },
      },
    ];

    return tabs;
  }, [companyName, refetch, scheduleList, hasFieldsDisabled]);

  const restrictedLayouts = useMemo(() => {
    return layouts.filter(
      ({ tab }) => PortalRoutes.find((route) => route.path === tab.url).hasAccess
    );
  }, [layouts]);

  const selectedTab = useMemo(() => {
    const selectedTabId = restrictedLayouts.find((e) => e.tab.url === pathname)?.tab.id;
    if (selectedTabId === undefined && restrictedLayouts.length > 0) {
      history.push(restrictedLayouts[0]?.tab.url);
    }
    return selectedTabId;
  }, [restrictedLayouts, pathname]);

  return (
    <DSLayout
      title="Configuration des plans"
      layouts={restrictedLayouts}
      activeLayout={selectedTab}
    >
      <div className={styles.PortalPlans}>
        <Switch>
          {PortalRoutes.map(({ path, component }) => (
            <Route
              exact
              key={path}
              path={path}
              component={component as ComponentClass<RouteComponentProps, any>}
            />
          ))}
        </Switch>
      </div>
    </DSLayout>
  );
}
