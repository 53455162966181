import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Loader } from "@skillup/ui";

import User from "utils/User";
import { useCoreHRFields } from "services/coreHR";
import { useCampaignDetails } from "services/peopleReview";

import { SupervisionContext } from "./../../contexts";
import { BaseUrl as PeopleReviewBaseURL } from "../router";
import { CampaignSupervision } from "./CampaignSupervision";
import { PeopleReviewViews, TPeopleReviewViews } from "../../types";
import { SupervisionContextProps } from "../../contexts/SupervisionContext";

type CampaignSupervisionParams = {
  campaignID: string;
  currentView: TPeopleReviewViews;
};

export const CampaignSupervisionWrapper = () => {
  const params = useParams<CampaignSupervisionParams>();
  const history = useHistory();
  const {
    data: campaign,
    isLoading: campaignLoading,
    mutations: campaignMutations,
  } = useCampaignDetails(params.campaignID);
  const { enumerableFieldsMap, isLoading: coreHRFieldsLoading } = useCoreHRFields();

  const [columnsToExport, setColumnsToExport] = useState([]);

  const isDev = User.isSkillupDeveloper();

  // TODO: Remove it when release in production for everyone
  useEffect(() => {
    // Condition to protect accessing the matrix view directly from url
    if (!isDev && params.currentView === PeopleReviewViews.MATRIX) {
      history.push(
        `${PeopleReviewBaseURL}/campaigns/${params.campaignID}/${PeopleReviewViews.GRID}`
      );
    }
  }, [params.currentView, isDev, history, params.campaignID]);

  const supervisionContextValue: SupervisionContextProps = {
    campaign,
    campaignMutations,
    columnsToExport,
    currentView: params.currentView,
    enumerableFieldsMap,
    hasManagerPrep: !!campaign?.managersPreparationEndDate,
    setColumnsToExport,
  };

  return (
    <SupervisionContext.Provider value={supervisionContextValue}>
      {campaignLoading || coreHRFieldsLoading ? <Loader /> : <CampaignSupervision />}
    </SupervisionContext.Provider>
  );
};
