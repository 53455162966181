const baseStyle = {
  width: "100%",
  padding: "10px 0px",
};

const activeStyle = {
  backgroundColor: "#DEEBFF",
  opacity: 0.8,
};

const acceptStyle = {
  backgroundColor: "#DEEBFF",
  opacity: 0.8,
};

const rejectStyle = {
  backgroundColor: "#FFC6C6",
  opacity: 0.8,
};

export { baseStyle, activeStyle, acceptStyle, rejectStyle };
