import React, { useState, useEffect } from "react";
import cx from "classnames";

import Acta from "utils/Acta";
import Loader from "components/Loader";
import DataLayer from "utils/DataLayer";

import Icon from "components/Icon";
import { plus as plusIcon, arobase as arobaseIcon } from "uiAssets/StrokeIcons";

import styles from "./Positioning.module.scss";
import { FlatButton } from "@skillup/ui";

interface Props {
  items: string[];
  noEmail?: boolean;
  noManager?: boolean;
  noEmailManager?: boolean;
  onDone?: () => void;
  scheduleUuid: string;
}

export default ({ items, onDone, noEmail, noManager, noEmailManager, scheduleUuid }: Props) => {
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState(null);

  useEffect(() => {
    const loadSessionData = async () => {
      setSessions(null);
      const data = await DataLayer.request({ url: `/v1/scheduleRow/sessions/${items[0]}` });
      setSessions(data);
    };

    loadSessionData();
  }, [setSessions, items]);

  const openBookingPage = () => {
    window.open(`${process.env.REACT_APP_PUBLIC_APP_URL}entreprise/inscription/${items[0]}`);
    Acta.dispatchEvent("closeModal");
  };

  const sendEmails = async () => {
    setLoading(true);

    try {
      const body = JSON.stringify({ rows: items });

      await DataLayer.request({
        body,
        method: "POST",
        url: `/v1/schedule/action/${scheduleUuid}`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "Email envoyé avec succès.",
        type: "success",
      });

      if (onDone) {
        onDone();
      }
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "La demande a échoué.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const sendEmailToManager = async () => {
    setLoading(true);

    try {
      const body = JSON.stringify({ rows: items });

      await DataLayer.request({
        body,
        method: "POST",
        url: `/v1/schedule/action-for-manager/${scheduleUuid}`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "Email envoyé avec succès.",
        type: "success",
      });

      if (onDone) {
        onDone();
      }
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "La demande a échoué.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (!sessions && items.length === 1) {
    return (
      <div className={styles.pleaseWait}>
        <Loader />
        <p>Veuillez patienter...</p>
      </div>
    );
  }

  const hasSessions = sessions && sessions.places.length > 0;

  return (
    <div className={styles.positioningModal}>
      <div className={cx(styles.options, styles.small)}>
        {hasSessions && items.length === 1 && (
          <FlatButton
            icon={
              !loading ? (
                <Icon strokeIcon={plusIcon} width={30} />
              ) : (
                <Loader className={styles.loader} />
              )
            }
            title="Inscrire le stagiaire maintenant"
            description="Cette action permet de choisir un lieu et une date de session. Vous pourrez ensuite y inscrire votre stagiaire."
            onClick={loading ? null : openBookingPage}
            name="directBooking"
          />
        )}
        {hasSessions && (
          <FlatButton
            disabled={noEmail}
            icon={
              !loading ? (
                <Icon strokeIcon={arobaseIcon} width={30} stroke={noEmail ? "#999" : undefined} />
              ) : (
                <Loader className={styles.loader} />
              )
            }
            title="Demander au stagiaire de s'inscrire"
            description={
              noEmail
                ? "Cette action n'est pas disponible car les adresses e-mail de certains stagiaires sont invalides"
                : "Un email sera envoyé au stagiaire avec la liste des prochaines sessions. Il sera invité à s’inscrire à celle qu’il souhaite."
            }
            onClick={loading || noEmail ? null : sendEmails}
            name="sendBookingEmail"
          />
        )}
        {!noManager && (
          <FlatButton
            disabled={noEmailManager}
            icon={
              !loading ? (
                <Icon strokeIcon={arobaseIcon} width={30} />
              ) : (
                <Loader className={styles.loader} />
              )
            }
            title="Demander au manager d'inscrire le stagiaire"
            description={
              noEmailManager
                ? "Cette action n'est pas disponible car l'adresse e-mail du manager est invalide."
                : "Un email sera envoyé au manager avec la liste des prochaines sessions. Il sera invité à inscrire le stagiaire à celle qu'il souhaite."
            }
            onClick={loading ? null : sendEmailToManager}
            name="sendBookingEmail"
          />
        )}
      </div>
    </div>
  );
};
