import { type CSSProperties } from "react";

import styled from "styled-components";

import { DSColor, EspaceFont } from "@skillup/ui";

import { type SpacingTokenValues, translateDSSpacingToken } from "../utils";

export type TextInterface = Pick<CSSProperties, "display" | "fontSize" | "textAlign"> & {
  collab?: boolean;
  hasEllipsis?: boolean;
  margin?: SpacingTokenValues;

  padding?: SpacingTokenValues;
  color?: keyof typeof DSColor;

  marginTop?: SpacingTokenValues;
  paddingTop?: SpacingTokenValues;
  marginLeft?: SpacingTokenValues;
  paddingLeft?: SpacingTokenValues;
  marginRight?: SpacingTokenValues;

  paddingRight?: SpacingTokenValues;
  marginBottom?: SpacingTokenValues;

  paddingBottom?: SpacingTokenValues;
  marginVertical?: SpacingTokenValues;
  paddingVertical?: SpacingTokenValues;
  marginHorizontal?: SpacingTokenValues;
  paddingHorizontal?: SpacingTokenValues;

  whiteSpace?: CSSProperties["whiteSpace"];
  espaceFont?: keyof (typeof EspaceFont)["rh"] | keyof (typeof EspaceFont)["collab"];
};

const TextWrapper = styled.div<TextInterface>`
  ${({ color }) =>
    color ? `color: ${DSColor[color]}` : `color: ${DSColor["plainText-onLight-lighter"]}`};
  & {
    ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

    ${({ espaceFont }) => espaceFont && EspaceFont.rh[espaceFont]};

    ${({ collab, espaceFont }) => espaceFont && collab && EspaceFont.collab[espaceFont]};

    ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};

    ${({ display }) => display && `display: ${display}`};

    ${({ hasEllipsis }) => hasEllipsis && `text-overflow: ellipsis; overflow: hidden;`};
    ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace}`};

    ${({ marginVertical }) =>
      marginVertical &&
      `margin-top: ${translateDSSpacingToken(
        marginVertical
      )}; margin-bottom: ${translateDSSpacingToken(marginVertical)}`};

    ${({ marginHorizontal }) =>
      marginHorizontal &&
      `margin-right: ${translateDSSpacingToken(
        marginHorizontal
      )}; margin-left: ${translateDSSpacingToken(marginHorizontal)}`};

    ${({ marginTop }) => marginTop && `margin-top: ${translateDSSpacingToken(marginTop)}`};

    ${({ marginBottom }) =>
      marginBottom && `margin-bottom: ${translateDSSpacingToken(marginBottom)}`};

    ${({ marginLeft }) => marginLeft && `margin-left: ${translateDSSpacingToken(marginLeft)}`};

    ${({ marginRight }) => marginRight && `margin-right: ${translateDSSpacingToken(marginRight)}`};

    ${({ margin }) => margin && `margin: ${translateDSSpacingToken(margin)}`};

    ${({ paddingVertical }) =>
      paddingVertical &&
      `padding-top: ${translateDSSpacingToken(
        paddingVertical
      )}; padding-bottom: ${translateDSSpacingToken(paddingVertical)}`};

    ${({ paddingHorizontal }) =>
      paddingHorizontal &&
      `padding-right: ${translateDSSpacingToken(
        paddingHorizontal
      )}; padding-left: ${translateDSSpacingToken(paddingHorizontal)}`};

    ${({ paddingTop }) => paddingTop && `padding-top: ${translateDSSpacingToken(paddingTop)}`};

    ${({ paddingBottom }) =>
      paddingBottom && `padding-bottom: ${translateDSSpacingToken(paddingBottom)}`};

    ${({ paddingLeft }) => paddingLeft && `padding-left: ${translateDSSpacingToken(paddingLeft)}`};

    ${({ paddingRight }) =>
      paddingRight && `padding-right: ${translateDSSpacingToken(paddingRight)}`};

    ${({ padding }) => padding && `padding: ${translateDSSpacingToken(padding)}`};
  }
`;

export default TextWrapper;
