import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { GridColDef, useGridApiContext, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { DSTooltip, DSDropdown, DSDropdownItem, DSDropdownDivider } from "@skillup/ui";

import { useRemoveReviews } from "services/peopleReview/campaign/useRemoveReviews";

import { SupervisionContext } from "../../../contexts";
import { TalentGridRow, TalentGridState } from "../../../types";

type ActionsMenuProps = {
  hasManagerPrep: boolean;
  setState: (state: TalentGridState) => void;
  params: GridRenderCellParams<TalentGridRow>;
};

const ActionsMenu = ({ hasManagerPrep, params, setState }: ActionsMenuProps) => {
  const { t } = useTranslation();
  const apiContext = useGridApiContext();
  const { campaign, campaignMutations } = useContext(SupervisionContext);
  const { removeReviews } = useRemoveReviews({ campaignID: campaign.id });

  let arbitrateTooltip = "";
  const canArbitrate = campaign?.permissions.arbitrate.isEnabled;

  if (canArbitrate === false)
    arbitrateTooltip = t(campaign?.permissions?.arbitrate?.reasonKey, {
      defaultValue: "Vous n'avez pas les droits nécessaires pour arbitrer cette ligne.",
    });

  const isRowArbitrated = params.row.status === "done";

  if (isRowArbitrated)
    arbitrateTooltip = t("peoplereview.talent-grid.row-actions.arbitrate.done", {
      defaultValue: "Le collaborateur a déjà été arbitré",
    });

  return (
    <DSDropdown buttonSize="S" overflow="initial">
      {campaign?.permissions.arbitrate.isVisible && (
        <DSTooltip label={arbitrateTooltip}>
          <DSDropdownItem
            style={{ width: "100%" }}
            tooltipLabel={arbitrateTooltip}
            disabled={!campaign?.permissions.arbitrate.isEnabled || params.row.status === "done"}
            label={t("peoplereview.talent-grid.row-actions.arbitrate", {
              defaultValue: 'Passer la ligne au statut "Arbitré"',
            })}
            onClick={async () => {
              await campaignMutations?.employees?.arbitrate({
                campaignId: params.row.campaignId,
                employeesIds: [params.row.id],
              });

              apiContext.current.updateRows([{ ...params.row, status: "done" }]);
            }}
          />
        </DSTooltip>
      )}

      {hasManagerPrep && (
        <DSTooltip
          label={t("not.implemented", {
            defaultValue: "Bientôt disponible",
          })}
        >
          <DSDropdownItem
            disabled
            label={t("peoplereview.talent-grid.row-actions.reset-prep-manager", {
              defaultValue: "Rétablir la préparation manager",
            })}
          />
        </DSTooltip>
      )}

      <DSDropdownDivider />

      {hasManagerPrep &&
        campaign.permissions["change-manager"].isVisible &&
        (isRowArbitrated ? (
          <DSTooltip
            label={t("peoplereview.talent-grid.row-actions.change-manager.arbitrated", {
              defaultValue: "Impossible de changer le manager sur une ligne déjà arbitrée",
            })}
          >
            <DSDropdownItem
              disabled
              label={t("peoplereview.talent-grid.row-actions.change-manager", {
                defaultValue: "Changer le manager",
              })}
            />
          </DSTooltip>
        ) : (
          <DSDropdownItem
            disabled={!campaign.permissions["change-manager"].isEnabled}
            label={t("peoplereview.talent-grid.row-actions.change-manager", {
              defaultValue: "Changer le manager",
            })}
            onClick={() =>
              setState({
                kind: "changeManager",
                reviews: [params.row],
              })
            }
          />
        ))}

      {campaign.permissions["delete-reviews"].isVisible && (
        <DSDropdownItem
          disabled={!campaign.permissions["delete-reviews"].isEnabled}
          onClick={() => removeReviews({ ids: [params.row.id] })}
          label={t("peoplereview.talent-grid.row-actions.remove-employee", {
            defaultValue: "Retirer le collaborateur de cette campagne",
          })}
        />
      )}
    </DSDropdown>
  );
};

export function getActionMenuColDef({
  hasManagerPrep,
  setState,
}: {
  hasManagerPrep: boolean;
  setState: (state: TalentGridState) => void;
}) {
  return {
    type: "actions",
    field: "actions",
    filterable: false,
    headerAlign: "right",
    renderCell: (params: GridRenderCellParams<TalentGridRow>) => (
      <ActionsMenu params={params} setState={setState} hasManagerPrep={hasManagerPrep} />
    ),
    width: 50,
  } satisfies GridColDef;
}
