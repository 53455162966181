import { isNil } from "lodash";

import { Child } from "../../reducer";

export function getChildLabel(child: Child) {
  switch (child.kind) {
    case "question":
      if (child.type === "radio") {
        return child.label;
      }
      if (child.type === "checkbox") {
        return child.label;
      }
      if (child.type === "text") {
        return child.label;
      }
      return "Unknown question type";
    case "targets":
      if (child.type === "current") {
        return "Revue des objectifs";
      }
      return "Fixation d'objectifs";
    case "table":
      return child.label;
    case "guidelines":
      return trimHtmlTagsAndEntities(child.description);
    case "personnalisableGuidelines":
      return trimHtmlTagsAndEntities(child.description);
    case "conclusion":
      return "Partage et signature";
    case "html":
      return trimHtmlTagsAndEntities(child.content);
    case "title":
      return child.title;
    case "interviewsHistory":
      return "Historique d'entretiens";
    case "trainingsHistory":
      return "Historique de formation";
    case "trainingsCollection":
      return "Demande de formation";
    case "job":
      return child.title;
    case "upload":
      return "Téléchargement de fichiers";
    case "globalTargetsCompletion":
      return "Taux d’atteinte global";
    case "skills":
      return "Compétences";
    case "userSpecificData":
      return "Données spécifiques à l'utilisateur";
    default:
      return "Unknown child kind";
  }
}

export function trimHtmlTagsAndEntities(htmlString: string) {
  if (isNil(htmlString)) {
    return "";
  }
  return htmlString.replace(/<[^>]+>|&(?:[a-z\d]+|#\d+|#x[a-f\d]+);/gi, "");
}
