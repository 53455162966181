import { ReactNode, ComponentClass, useMemo, useCallback } from "react";

import { Route, Switch, RouteComponentProps, useLocation } from "react-router-dom";

import CompanySettings from "./pages/CompanySettings";
import ConnectionModes from "./pages/ConnectionModes";
import ManageAdmins, { useAdminData } from "./pages/ManageAdmins";

import User from "utils/User";

import styles from "./SupervisorPortalAdminView.module.scss";
import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import AddAdminModal from "./components/AddAdminModal/AddAdminModal";
import Acta from "utils/Acta";

interface IRouteInterface {
  path: string;
  label: string;
  scope: string[];
  component: ReactNode;
}

const PortalRoutes: Array<IRouteInterface> = [
  {
    component: ConnectionModes,
    label: "Modes de connexion",
    path: "/responsable/administration-du-portail",
    scope: ["admin", "regular"],
  },
  {
    component: CompanySettings,
    label: "Entreprise(s)",
    path: "/responsable/administration-du-portail/entreprises",
    scope: ["admin", "regular"],
  },
  {
    component: ManageAdmins,
    label: "Rôles et permissions",
    path: "/responsable/administration-du-portail/manage-admin",
    scope: ["admin"],
  },
];

export default function AccountPortalManagement() {
  const scope = User.isSkillupAdmin() ? "admin" : "regular";
  const filteredRoutes = PortalRoutes.filter((e) => e.scope.includes(scope));
  const { loadData } = useAdminData();
  const { pathname } = useLocation();

  const showAddAdminModal = useCallback(() => {
    Acta.setState("modalDisplayed", {
      content: <AddAdminModal refresh={loadData} />,
      size: "small",
      title: "Ajouter un administrateur",
      showOverflow: true,
    });
  }, [loadData]);

  const tabs = useMemo(() => {
    return [
      {
        tab: {
          id: "connexion" as const,
          label: "Accès à Skillup",
          url: "/responsable/administration-du-portail",
          scope: ["admin", "regular"],
        },
      },
      {
        tab: {
          id: "manage-admin" as const,
          label: "Rôles et permissions",
          url: "/responsable/administration-du-portail/manage-admin",
          scope: ["admin"],
        },
        primaryButton: (
          <DSNewHeaderButton label="Ajouter un administrateur" onClick={showAddAdminModal} />
        ),
      },
      {
        tab: {
          id: "entreprises" as const,
          label: "Entreprises et sous-entreprises",
          url: "/responsable/administration-du-portail/entreprises",
          scope: ["admin", "regular"],
        },
      },
    ];
  }, [showAddAdminModal]);

  const selectedTab = useMemo(() => {
    return tabs.find((e) => e.tab.url === pathname)?.tab.id;
  }, [tabs, pathname]);

  const regularTabs = useMemo(() => {
    return tabs.filter((e) => e.tab.scope.includes("regular"));
  }, [tabs]);

  return (
    <DSLayout
      title="Administration de la plateforme"
      layouts={User.isSkillupAdmin() ? tabs : regularTabs}
      activeLayout={selectedTab}
    >
      <div className={styles.AccountPortalManagement}>
        <Switch>
          {filteredRoutes
            .filter((p) => p.scope.includes(scope))
            .map(({ path, component }: IRouteInterface) => (
              <Route
                exact
                key={path}
                path={path}
                component={component as ComponentClass<RouteComponentProps, any>}
              />
            ))}
        </Switch>
      </div>
    </DSLayout>
  );
}
