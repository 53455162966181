import DashboardChildCard, {
  Props as DashboardChildCardProps,
} from "../DashboardChildCard/DashboardChildCard";
import { DashboardChildType } from "@skillup/espace-rh-bridge";
import { useMemo } from "react";

type QuestionType = DashboardChildType.CLOSED_QUESTION | DashboardChildType.OPEN_QUESTION;

export type Props<T extends QuestionType> = Pick<
  DashboardChildCardProps<T>,
  "child" | "className" | "icon" | "typeName" | "bodyRef" | "children"
>;
function Question<T extends QuestionType>(props: Props<T>) {
  const { child } = props;

  const replyRestrictions = useMemo(() => {
    if (child.disallow?.employee?.reply) {
      // Not sure this case exists, actually
      if (child.disallow?.manager?.reply) return "Question sans réponse possible";
      else return "Seuls les responsables d’entretien peuvent répondre à cette question";
    } else {
      if (child.disallow?.manager?.reply)
        return "Seuls les collaborateurs peuvent répondre à cette question";
      else
        return "Les collaborateurs et les responsables d’entretien peuvent répondre à cette question";
    }
  }, [child]);
  const noReplyRestrictions = useMemo(
    () => !child.disallow?.employee?.reply && !child.disallow?.manager?.reply,
    [child]
  );

  return (
    <DashboardChildCard
      title={child.label ?? ""}
      subTitle={replyRestrictions}
      withRoleFilter={noReplyRestrictions}
      {...props}
    />
  );
}

export default Question;
