import { useCallback, useMemo } from "react";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Either } from "@skillup/monads";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";

import type { IntraRoutes } from "types/api";

import deleteIntra from "./deleteIntra";
import createIntra, { type IntraCreationParams } from "./createIntra";
import updateIntra, { type IntraUpdateParams } from "./updateIntra";
import { TrainingRoutes } from "@skillup/espace-rh-bridge";

export type IIntra = IntraRoutes.GET_LIST["response"][0];

export default async function getList() {
  try {
    const data = await buildRequest<TrainingRoutes.Get>({
      method: "GET",
      path: "/training",
    })().then((res) =>
      res.map(
        (details): IIntra => ({
          createdAt: new Date(details.createdAt).getTime(),
          doneSessionsCount: details.stats.doneSessionsCount,
          futureSessionsCount: details.stats.futureSessionsCount,
          name: details.name,
          price: details.price,
          reference: details.reference,
          priceWithTax: details.priceWithTax,
          seoSlug: details.seoSlug,
          uuid: details.uuid,
          trainingOrganization: details.trainingOrganizationName,
          user: "", // ??
        })
      )
    );

    return data;
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Erreur lors de la récupération des programmes.",
      type: "error",
    });
    return [];
  }
}

export const INTRAS_LIST = "INTRAS_LIST";
export enum ErrorCode {
  EMPTY = "EMPTY",
  UNKNOWN = "UNKNOWN",
  TOO_LONG = "TOO_LONG",
  TOO_SHORT = "TOO_SHORT",
  ALREADY_USED = "ALREADY_USED",
}
export type ReferenceValidityError = {
  error: ErrorCode;
  data?: Record<string, any>;
};
export const MAX_REFERENCE_LENGTH = 15;
export const MIN_REFERENCE_LENGTH = 4;
export function useIntras() {
  const queryClient = useQueryClient();
  const query = useQuery([INTRAS_LIST], getList, {
    // The list is heavy to fetch, so we cache it for 5 minutes (default)
    refetchOnWindowFocus: false,
    // We don't want to refetch on mount because we don't want to fetch the list in modals, etc...
    refetchOnMount: false,
  });
  const deleteMutation = useMutation(deleteIntra, {
    mutationKey: ["delete-intra"],
  });
  const createIntraMutation = useMutation(createIntra, {
    mutationKey: ["create-intra"],
  });
  const updateIntraMutation = useMutation(updateIntra, {
    mutationKey: ["update-intra"],
  });

  const references = useMemo(
    () => (query?.data ?? []).map((intra) => [intra.reference, intra.uuid]),
    [query.data]
  );

  const trainingOrganizations = useMemo(() => {
    const allTrainingOrganizations = (query?.data ?? []).map((intra) => intra.trainingOrganization);
    const trainingOrganizationsWithoutDuplicates = [...new Set(allTrainingOrganizations)];

    return trainingOrganizationsWithoutDuplicates;
  }, [query.data]);

  const getIntraByReference = useCallback(
    (reference: string) => {
      const intra = query.data?.find((intra) => intra.reference === reference);
      return intra;
    },
    [query.data]
  );

  const validateReference = useCallback(
    (
      reference: string | undefined,
      omitIntraUuid?: string
    ): Either<ReferenceValidityError, void> => {
      if (query.isLoading) {
        return Either.left({ error: ErrorCode.UNKNOWN });
      }

      if (query.isError) {
        return Either.left({ error: ErrorCode.UNKNOWN });
      }

      const trimmedReference = reference?.trim();

      if (trimmedReference?.length === 0) {
        return Either.left({ error: ErrorCode.EMPTY });
      }

      if (trimmedReference?.length > MAX_REFERENCE_LENGTH) {
        return Either.left({ error: ErrorCode.TOO_LONG });
      }

      if (trimmedReference?.length < MIN_REFERENCE_LENGTH) {
        return Either.left({ error: ErrorCode.TOO_SHORT });
      }

      const referencesWithoutOmittedIntra = references
        .filter(([_, uuid]) => uuid !== omitIntraUuid)
        .map(([reference]) => reference);

      if (referencesWithoutOmittedIntra.includes(trimmedReference)) {
        const programName = getIntraByReference(trimmedReference)?.name ?? "";
        return Either.left({ error: ErrorCode.ALREADY_USED, data: { programName } });
      }

      return Either.right(undefined);
    },
    [query.isLoading, query.isError, references, getIntraByReference]
  );

  const softDeleteIntra = useCallback(
    async (intraUuid: string) => {
      const result = await deleteMutation.mutateAsync(intraUuid);
      if (result.success) {
        queryClient.setQueryData(
          [INTRAS_LIST],
          query.data.filter((intra) => intra.uuid !== intraUuid)
        );
      }
      return result;
    },
    [deleteMutation, queryClient, query.data]
  );

  const create = useCallback(
    async (params: IntraCreationParams) => {
      await createIntraMutation.mutateAsync(params);
      query.refetch(); // later we might want to inject the new intra in the list
      // instead of refetching the whole list
    },
    [createIntraMutation, query]
  );

  const update = useCallback(
    async (params: IntraUpdateParams) => {
      await updateIntraMutation.mutateAsync(params);
      query.refetch();
      // later we might want to inject the new intra in the list
      // instead of refetching the whole list
    },
    [updateIntraMutation, query]
  );

  const invalidateList = useCallback(() => {
    queryClient.invalidateQueries([INTRAS_LIST]);
  }, [queryClient]);

  return {
    ...query,
    trainingOrganizations,
    validateReference,
    softDeleteIntra,
    create,
    update,
    getIntraByReference,
    invalidateList,
  };
}
