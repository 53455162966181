import { findIndex, isEmpty } from "lodash";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";

import { LmsBanner, PortalRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

import Banner from "./Banner";

import styles from "./Banner.module.scss";

export type IBanner = LmsBanner & {
  id: string;
};

function compare(a: IBanner, b: IBanner) {
  const orderA = a.index || 0;
  const orderB = b.index || 0;
  if (orderA !== orderB) {
    return orderA > orderB ? 1 : -1;
  } else {
    return 0;
  }
}

interface Props {
  onEditBanner: (banner: LmsBanner) => void;
}

export default ({ onEditBanner }: Props) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const { addToast } = useToasts();

  const queryClient = useQueryClient();
  const {
    data: banners,
    isLoading,
    error,
  } = useQuery<IBanner[]>(["lmsbanners"], async () => {
    const data = await buildRequest<PortalRoutes.GetLmsBanners>({
      method: "GET",
      path: "/portal/lms-banners",
    })();
    return data?.sort(compare)?.map((b) => ({ ...b, id: b.uuid }));
  });

  const handleRemove = (banner) => async () => {
    try {
      await buildRequest<PortalRoutes.DeleteLmsBanner>({
        method: "DELETE",
        path: "/portal/lms-banners/{lmsBannerUuid}",
        params: { lmsBannerUuid: banner.uuid },
      })();
      const oldBanners: IBanner[] = queryClient.getQueryData(["lmsbanners"]);
      queryClient.setQueryData(
        ["lmsbanners"],
        oldBanners.filter((b) => b !== banner)
      );
    } catch (e) {
      addToast("Echec lors de la suppression de la bannière", { appearance: "error" });
    }
  };

  const handleCopy = (banner: IBanner) => async () => {
    const oldBanners: IBanner[] = queryClient.getQueryData(["lmsbanners"]);

    const newBanner = await buildRequest<PortalRoutes.DuplicateLmsBanner>({
      method: "POST",
      path: "/portal/lms-banners/duplicate/{existingLmsBannerUuid}",
      params: { existingLmsBannerUuid: banner.uuid },
    })();
    queryClient.setQueryData(["lmsbanners"], [...oldBanners, newBanner]);
  };

  async function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldBanners: IBanner[] = queryClient.getQueryData(["lmsbanners"]);
      const oldIndex = findIndex(oldBanners, ({ id }) => active.id === id);
      const newIndex = findIndex(oldBanners, ({ id }) => over?.id === id);

      oldBanners[oldIndex].index = newIndex;
      oldBanners[newIndex].index = oldIndex;
      const updatedBanners = arrayMove(oldBanners, oldIndex, newIndex);
      queryClient.setQueryData(["lmsbanners"], updatedBanners);

      try {
        await buildRequest<PortalRoutes.UpdateLmsBannerOrder>({
          method: "POST",
          path: `/portal/lms-banners/reorder`,
          payload: {
            uuid: oldBanners[oldIndex].uuid,
            newOrder: newIndex + 1,
          },
        })();
      } catch (err) {
        const newBanners = arrayMove(updatedBanners, oldIndex, newIndex);
        queryClient.setQueryData(["lmsbanners"], newBanners);
      }
    }
  }

  if (isEmpty(banners)) return <p>Aucun accès LMS n’est encore configuré</p>;

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={banners || []} strategy={rectSortingStrategy}>
        <div className={styles.BannersList}>
          {isLoading && <div>Loading...</div>}
          {/* @ts-ignore */}
          {error && <div>Error: {error?.message}</div>}
          {banners?.map((banner) => (
            <Banner
              banner={banner}
              onEdit={() => onEditBanner(banner)}
              onCopy={handleCopy(banner)}
              onRemove={handleRemove(banner)}
              size={banners.length > 1 ? "small" : "large"}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};
