const NoInterviews = () => {
  return (
    <svg width="320" height="308" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity=".75">
        <g opacity=".3">
          <path
            d="M159.562 306.715c88.123 0 159.562-41.337 159.562-92.329 0-50.991-71.439-92.328-159.562-92.328C71.438 122.058 0 163.395 0 214.386c0 50.992 71.438 92.329 159.562 92.329Z"
            fill="#F6F7F8"
          />
          <path
            d="m243.466 244.499 48.497-28.069c3.049-1.763 3.049-4.622 0-6.385l-30.377-17.579c-3.049-1.762-8.044-1.86-11.151-.209l-50.869 26.979c-3.113 1.652-3.159 4.42-.11 6.182l32.97 19.081c3.048 1.762 7.991 1.762 11.04 0ZM78.973 244.499 30.47 216.43c-3.048-1.763-3.048-4.622 0-6.385l30.384-17.579c3.049-1.762 8.044-1.86 11.151-.209l50.869 26.979c3.106 1.652 3.159 4.42.11 6.182l-32.97 19.081c-3.048 1.762-7.959 1.762-11.04 0Z"
            fill="#E5E8EB"
          />
          <path
            d="M161.405 295.506c38.583 0 69.861-18.099 69.861-40.426s-31.278-40.426-69.861-40.426c-38.583 0-69.861 18.099-69.861 40.426s31.278 40.426 69.861 40.426Z"
            fill="#E5E8EB"
          />
          <path
            d="m248.077 276.922-19.286-11.136c-.938-.549-.938-1.43 0-1.959l54.106-31.333a3.773 3.773 0 0 1 3.406 0l19.28 11.137c.944.548.944 1.429 0 1.958l-54.099 31.333a3.777 3.777 0 0 1-3.407 0ZM75.222 276.922l19.285-11.136c.938-.549.938-1.43 0-1.959l-54.105-31.333a3.761 3.761 0 0 0-3.4 0l-19.286 11.15c-.944.548-.944 1.429 0 1.958l54.1 31.333a3.778 3.778 0 0 0 3.406-.013Z"
            fill="#E5E8EB"
          />
          <path
            d="M38.148 206.703s1.12-11.75-1.876-21.176c-2.996-9.426-8.72-16.319-15.169-18.617-6.448-2.298-13.391 2.363-7.438 8.428 5.953 6.064 17.104 17.729 18.7 34.858l5.783-3.493Z"
            fill="#FB0"
          />
          <path
            opacity=".65"
            d="M38.148 206.703s1.12-11.75-1.876-21.176c-2.996-9.426-8.72-16.319-15.169-18.617-6.448-2.298-13.391 2.363-7.438 8.428 5.953 6.064 17.104 17.729 18.7 34.858l5.783-3.493Z"
            fill="#fff"
          />
          <path
            d="M34.572 204.014h.079a.384.384 0 0 0 .32-.411c-.841-19.734-11.816-31.334-17.47-34.362a.371.371 0 0 0-.495.156.367.367 0 0 0 .15.49c5.543 2.944 16.049 14.511 17.09 33.781a.352.352 0 0 0 .326.346Z"
            fill="#fff"
          />
          <path
            d="M2.534 186.859c.17 1.142 1.133 1.991 2.051 2.696.919.705 1.922 1.469 2.215 2.611.58 2.272-2.032 4.498-1.348 6.737a3.648 3.648 0 0 0 1.993 2.049c.73.359 1.517.575 2.28.862.374.098.755.173 1.14.222a5.248 5.248 0 0 1 3.126 1.247c1.302 1.305 1.237 3.394 1.758 5.17.619 2.121 1.59 4.014 3.517 5.059a9.675 9.675 0 0 0 5.823.933l7.276-4.249c2.429-.738 2.533-3.036 2.605-5.549a9.856 9.856 0 0 0-2.566-6.528 13.809 13.809 0 0 0-3.485-2.611 5.476 5.476 0 0 1-1.908-1.305 4.219 4.219 0 0 1-.541-1.959 12.487 12.487 0 0 0-.358-2.356 8.707 8.707 0 0 0-1.147-2.194 4.308 4.308 0 0 0-2.07-1.664 15.15 15.15 0 0 0-3.909-.979c-1.706-.183-3.563.294-5.21-.255-1.648-.548-3.094-1.423-4.8-1.769a6.028 6.028 0 0 0-5.21 1.097 3.261 3.261 0 0 0-1.232 2.735Z"
            fill="#FB0"
          />
          <path
            opacity=".25"
            d="M2.534 186.859c.17 1.142 1.133 1.991 2.051 2.696.919.705 1.922 1.469 2.215 2.611.58 2.272-2.032 4.498-1.348 6.737a3.648 3.648 0 0 0 1.993 2.049c.73.359 1.517.575 2.28.862.374.098.755.173 1.14.222a5.248 5.248 0 0 1 3.126 1.247c1.302 1.305 1.237 3.394 1.758 5.17.619 2.121 1.59 4.014 3.517 5.059a9.675 9.675 0 0 0 5.823.933l7.276-4.249c2.429-.738 2.533-3.036 2.605-5.549a9.856 9.856 0 0 0-2.566-6.528 13.809 13.809 0 0 0-3.485-2.611 5.476 5.476 0 0 1-1.908-1.305 4.219 4.219 0 0 1-.541-1.959 12.487 12.487 0 0 0-.358-2.356 8.707 8.707 0 0 0-1.147-2.194 4.308 4.308 0 0 0-2.07-1.664 15.15 15.15 0 0 0-3.909-.979c-1.706-.183-3.563.294-5.21-.255-1.648-.548-3.094-1.423-4.8-1.769a6.028 6.028 0 0 0-5.21 1.097 3.261 3.261 0 0 0-1.232 2.735Z"
            fill="#fff"
          />
          <path
            d="M23.474 197.44a1.541 1.541 0 0 0-.137-.195c-4.793-7.122-12.7-10.367-15.293-10.895a.252.252 0 0 0-.196.04.265.265 0 0 0-.11.169.26.26 0 0 0 .202.306c2.462.497 9.809 3.486 14.525 10.02a14.312 14.312 0 0 0-11.412.405.258.258 0 0 0-.091.353.238.238 0 0 0 .202.13.208.208 0 0 0 .15-.032 13.857 13.857 0 0 1 11.684-.072 21.9 21.9 0 0 1 3.635 12.585.256.256 0 0 0 .065.178.256.256 0 0 0 .17.083.266.266 0 0 0 .267-.261 22.389 22.389 0 0 0-3.661-12.814Z"
            fill="#fff"
          />
          <path
            d="M283.262 208.89s-1.544-16.842 7.405-33.187c5.002-9.139 12.623-12.912 17.039-12.957 4.95-.053 9.457 4.406 4.416 10.281-4.794 5.581-13.027 7.311-19.449 22.396a60.247 60.247 0 0 0-4.24 14.361c-.156 1.038-1.53 1.534-2.488.901l-2.683-1.795Z"
            fill="#FB0"
          />
          <path
            opacity=".5"
            d="M283.262 208.89s-1.544-16.842 7.405-33.187c5.002-9.139 12.623-12.912 17.039-12.957 4.95-.053 9.457 4.406 4.416 10.281-4.794 5.581-13.027 7.311-19.449 22.396a60.247 60.247 0 0 0-4.24 14.361c-.156 1.038-1.53 1.534-2.488.901l-2.683-1.795Z"
            fill="#fff"
          />
          <path
            d="M286.694 205.509a.288.288 0 0 0 .283-.158.3.3 0 0 0 .03-.11c0-19.113 12.245-34.055 20.94-38.559a.25.25 0 0 0 .104-.366.336.336 0 0 0-.423-.098c-8.819 4.57-21.24 19.701-21.24 39.023a.276.276 0 0 0 .098.198.29.29 0 0 0 .208.07Z"
            fill="#fff"
          />
          <path
            d="M286.394 210.946a1.651 1.651 0 0 0 1.342.24 1.659 1.659 0 0 0 1.068-.847c1.557-3.675 4.749-7.317 10.311-10.953 6.468-4.23 15.111-7.834 14.401-12.181-.71-4.348-7.256-4.015-12.056-2.109-4.801 1.907-15.971 9.792-15.743 25.393l.677.457Z"
            fill="#FB0"
          />
          <path
            opacity=".25"
            d="M286.394 210.946a1.651 1.651 0 0 0 1.342.24 1.659 1.659 0 0 0 1.068-.847c1.557-3.675 4.749-7.317 10.311-10.953 6.468-4.23 15.111-7.834 14.401-12.181-.71-4.348-7.256-4.015-12.056-2.109-4.801 1.907-15.971 9.792-15.743 25.393l.677.457Z"
            fill="#fff"
          />
          <path
            d="M286.766 211.292a.291.291 0 0 0 .306-.222c1.042-11.586 13.756-23.356 21.976-24.955a.265.265 0 0 0 .186-.108.258.258 0 0 0 .042-.212.326.326 0 0 0-.378-.196c-8.076.992-21.995 13.708-22.432 25.373a.29.29 0 0 0 .248.314l.052.006Z"
            fill="#fff"
          />
          <path
            d="m200.797 221.985 5.582-60.506 5.178-.261-8.148 60.76a.7.7 0 0 1-.345.464 2.044 2.044 0 0 1-1.85.097.656.656 0 0 1-.404-.554h-.013Z"
            fill="#1A304D"
          />
          <path
            d="m206.379 161.479 5.178-.261-1.524 10.046a20.645 20.645 0 0 1-3.068-1.41 3.15 3.15 0 0 1-1.367-1.175l.781-7.2ZM275.198 153.705h5.211l8.298 57.555a.65.65 0 0 1-.372.574 2.044 2.044 0 0 1-1.849 0 .714.714 0 0 1-.372-.45l-10.916-57.679Z"
            fill="#1A304D"
          />
          <path
            d="m280.383 153.705 2.012 13.975a21.573 21.573 0 0 1-4.09 2.514l-3.107-16.489h5.185ZM235.448 239.753l4.116-61.4 5.178-.261-6.689 61.655a.72.72 0 0 1-.345.463 2.042 2.042 0 0 1-1.843.098.661.661 0 0 1-.411-.555h-.006Z"
            fill="#1A304D"
          />
          <path
            d="m239.024 186.121.521-7.768 5.178-.261-.912 8.375c-1.133.353-3.263.137-4.787-.346ZM246.898 137.216h5.211l8.298 58.684a.661.661 0 0 1-.378.568 2 2 0 0 1-1.843 0 .692.692 0 0 1-.372-.444l-10.916-58.808Z"
            fill="#1A304D"
          />
          <path
            d="M290.127 96.3a29.605 29.605 0 0 1 2.071 9.139c.593 6.997-1.772 29.642-3.192 39.773-2.051 16.92-4.559 16.972-8.232 19.786-6.292 4.869-24.386 14.419-30.365 16.776-10.832 4.275-19.69-2.311-26.294-5.581a153.423 153.423 0 0 1-17.482-10.386c-1.648-1.208-2.501-2.422-2.488-3.701v-3.127c0-1.632 1.44-3.381 4.475-5.353 5.595-3.636 25.812-15.457 33.647-19.805a.089.089 0 0 1 .022-.043.075.075 0 0 1 .043-.022c6.032-3.669 9.66-28.605 13.835-41.869.091-.287.175-.561.273-.823.098-.26.143-.417.215-.62.241-.666.515-1.32.821-1.958"
            fill="#FB0"
          />
          <path
            d="M204.139 158.966c0-1.632 1.439-3.381 4.474-5.353 5.595-3.636 25.812-15.457 33.648-19.805a.075.075 0 0 1 .022-.043.072.072 0 0 1 .043-.022c6.031-3.669 9.659-28.605 13.834-41.869.247-.829.538-1.644.873-2.441l.078-.177c.078-.176.156-.359.241-.541l.117-.268a12.708 12.708 0 0 1 2.788-4.034 6.026 6.026 0 0 1 1.361-.966c.652-.379 2.104-1.175 2.462-1.384 4.097-2.455 9.464.49 16.16 4.165 4.162 2.29 7.718 5.006 9.88 10.072a29.577 29.577 0 0 1 2.071 9.139c.593 6.997-1.771 29.642-3.191 39.773-2.052 16.92-4.559 16.972-8.233 19.786-6.292 4.869-24.385 14.419-30.365 16.776-10.831 4.276-19.689-2.311-26.294-5.581a153.42 153.42 0 0 1-17.475-10.386c-1.648-1.208-2.501-2.422-2.488-3.701.007-.522-.006-2.605-.006-3.14Z"
            fill="#FB0"
          />
          <path
            opacity=".25"
            d="M204.139 158.966c0-1.632 1.439-3.381 4.474-5.353 5.595-3.636 25.812-15.457 33.648-19.805a.075.075 0 0 1 .022-.043.072.072 0 0 1 .043-.022c6.031-3.669 9.659-28.605 13.834-41.869.247-.829.538-1.644.873-2.441l.078-.177c.078-.176.156-.359.241-.541l.117-.268a12.708 12.708 0 0 1 2.788-4.034 6.026 6.026 0 0 1 1.361-.966c.652-.379 2.104-1.175 2.462-1.384 4.097-2.455 9.464.49 16.16 4.165 4.162 2.29 7.718 5.006 9.88 10.072a29.577 29.577 0 0 1 2.071 9.139c.593 6.997-1.771 29.642-3.191 39.773-2.052 16.92-4.559 16.972-8.233 19.786-6.292 4.869-24.385 14.419-30.365 16.776-10.831 4.276-19.689-2.311-26.294-5.581a153.42 153.42 0 0 1-17.475-10.386c-1.648-1.208-2.501-2.422-2.488-3.701.007-.522-.006-2.605-.006-3.14Z"
            fill="#fff"
          />
          <path
            d="M224.128 173.027a152.835 152.835 0 0 1-17.495-10.386c-3.751-2.729-3.406-5.529 1.954-9.028 7.478-4.856 37.96-22.194 37.96-22.194s38.532 22.795 36.871 26.999c-1.661 4.203-25.506 17.226-33.022 20.19-10.819 4.276-19.67-2.311-26.268-5.581Z"
            fill="#FB0"
          />
          <path
            opacity=".6"
            d="M224.128 173.027a152.835 152.835 0 0 1-17.495-10.386c-3.751-2.729-3.406-5.529 1.954-9.028 7.478-4.856 37.96-22.194 37.96-22.194s38.532 22.795 36.871 26.999c-1.661 4.203-25.506 17.226-33.022 20.19-10.819 4.276-19.67-2.311-26.268-5.581Z"
            fill="#fff"
          />
          <path
            d="M261.625 83.447c4.058-2.128 9.327.77 15.847 4.347 4.181 2.298 7.737 5.014 9.9 10.105l2.755-1.6c-2.163-5.065-5.719-7.78-9.881-10.072-6.695-3.675-12.062-6.619-16.159-4.164-.358.19-1.785 1.005-2.462 1.384Z"
            fill="#FB0"
          />
          <path
            opacity=".5"
            d="M261.625 83.447c4.058-2.128 9.327.77 15.847 4.347 4.181 2.298 7.737 5.014 9.9 10.105l2.755-1.6c-2.163-5.065-5.719-7.78-9.881-10.072-6.695-3.675-12.062-6.619-16.159-4.164-.358.19-1.785 1.005-2.462 1.384Z"
            fill="#fff"
          />
          <path
            d="M242.326 133.743s3.908 1.305 17.95 8.192c5.752 2.827 9.536 5.686 14.922 9.08 7.934 5 7.328 8.715 7.328 8.715 3.908-3.264 7.868-41.242 6.904-52.738a28.968 28.968 0 0 0-2.26-9.563 14.65 14.65 0 0 0-.326-.653c-.111-.209-.15-.306-.234-.457a2.367 2.367 0 0 0-.118-.209 17.004 17.004 0 0 0-.996-1.599c-.085-.13-.176-.254-.261-.372l-.28-.365a6.163 6.163 0 0 0-.28-.353l-.241-.28a16.978 16.978 0 0 0-1.426-1.482 4.94 4.94 0 0 0-.371-.346 23.818 23.818 0 0 0-2.515-1.907c-.13-.097-.267-.182-.403-.267l-.548-.346c-.156-.092-.306-.19-.462-.274-.41-.242-.821-.477-1.238-.705-4.982-2.735-9.235-5.079-12.779-5.079a6.585 6.585 0 0 0-4.429 1.69c-2.605 2.312-4.012 7.181-4.103 7.462-4.175 13.251-7.803 38.187-13.834 41.856Z"
            fill="#FB0"
          />
          <path
            opacity=".4"
            d="M242.326 133.743s3.908 1.305 17.95 8.192c5.752 2.827 9.536 5.686 14.922 9.08 7.934 5 7.328 8.715 7.328 8.715 3.908-3.264 7.868-41.242 6.904-52.738a28.968 28.968 0 0 0-2.26-9.563 14.65 14.65 0 0 0-.326-.653c-.111-.209-.15-.306-.234-.457a2.367 2.367 0 0 0-.118-.209 17.004 17.004 0 0 0-.996-1.599c-.085-.13-.176-.254-.261-.372l-.28-.365a6.163 6.163 0 0 0-.28-.353l-.241-.28a16.978 16.978 0 0 0-1.426-1.482 4.94 4.94 0 0 0-.371-.346 23.818 23.818 0 0 0-2.515-1.907c-.13-.097-.267-.182-.403-.267l-.548-.346c-.156-.092-.306-.19-.462-.274-.41-.242-.821-.477-1.238-.705-4.982-2.735-9.235-5.079-12.779-5.079a6.585 6.585 0 0 0-4.429 1.69c-2.605 2.312-4.012 7.181-4.103 7.462-4.175 13.251-7.803 38.187-13.834 41.856Z"
            fill="#fff"
          />
          <path
            d="M227.697 188.151c3.706-.509 6.898 2.455 7.204 3.408.41 1.305.28 3.884-1.218 7.102a22.307 22.307 0 0 0-1.609 10.112c.28 2.937 1.7 10.261-1.244 13.956-2.944 3.695-9.509 6.919-11.861 1.886-.768-1.651.749-7.376 1.427-11.547a510.109 510.109 0 0 0 2.345-16.581l4.956-8.336Z"
            fill="#1A304D"
          />
          <path
            d="M228.882 194.784a81.405 81.405 0 0 0-.931 12.2c.091 4.458-.84 7.396-2.371 9.387-1.687 2.206-5.927 3.446-5.438-1.149.338-3.205 2.605-18.735 2.605-18.735l6.135-1.703Z"
            fill="#FFA8A7"
          />
          <path
            opacity=".15"
            d="M233.663 190.09c.176 2.748-1.303 10.046-4.963 16.163-3.621 6.005-8.923 13.055-9.926 17.344-.104-1.958.71-5.562 1.302-8.767l.039-.209.098-.555c0-.084.033-.169.046-.254.045-.235.084-.47.117-.699v-.045a560.594 560.594 0 0 0 1.837-12.527c.091-.705.176-1.306.247-1.886.039-.268.072-.523.098-.745l.182-1.423 4.937-8.336a7.3 7.3 0 0 1 .723-.052 8.126 8.126 0 0 1 5.263 1.991Z"
            fill="#0A4D71"
          />
          <path
            d="M212.886 141.654c11.789-8.819 25.148-18.78 25.148-18.78l37.842 2.285-37.282 27.952-16.062 10.894s-3.068-.241-1.185 4.57c.592 1.521 1.498 4.569 2.449 8.022l-7.452 20.589s-4.715-6.867-9.854-20.177c-5.211-13.474-5.536-17.912-5.002-20.589.71-3.512 1.765-7.585 11.398-14.766Z"
            fill="#0C5D88"
          />
          <path
            d="M219.829 142.066a96.776 96.776 0 0 0-8.689 7.52 35.37 35.37 0 0 0-3.764 4.236 6.77 6.77 0 0 0-1.199 2.324 19.052 19.052 0 0 0-.358 2.774 24.998 24.998 0 0 0 .43 5.595c.319 1.866.775 3.72 1.263 5.568 1.01 3.707 1.954 7.441 2.886 11.175-1.401-3.59-2.658-7.233-3.824-10.901a49.9 49.9 0 0 1-1.491-5.627c-.41-1.93-.57-3.904-.476-5.875.064-.99.202-1.974.411-2.944a7.757 7.757 0 0 1 1.465-2.748 31.849 31.849 0 0 1 4.045-4.23 63.561 63.561 0 0 1 9.301-6.867Z"
            fill="#1A304D"
          />
          <path
            d="M242.326 145.14c-.476.32-2.566 1.789-3.12 2.181-7.419 5.261-19.95 15.451-21.35 19.916-.873 2.761-4.175 10.81-5.986 21.26 0 0 .234-6.925.495-13.708.358-9.485 2.071-12.154 5.145-15.666 2.964-3.375 18.746-13.056 19.846-13.676-2.722-2.161-4.064-6.619-4.058-8.865 0 .007 4 6.815 9.028 8.558ZM220.357 221.887c.964-.066 1.029 1.586 1.615 3.681.378 1.358 1.101 3.63.312 6.287-.788 2.656-4.969 3.192-7.021 6.723-2.052 3.532-4.989 9.844-12.375 11.959s-13.281.033-14.837-2.109c-1.557-2.141-.45-4.347 4.162-7.428 4.891-3.264 6.272-4.237 6.272-4.237l21.872-14.876Z"
            fill="#1A304D"
          />
          <path
            d="M210.365 209.412s-1.863 8.317-3.738 13.056c-1.876 4.739-5.061 9.38-8.644 13.708-.775.946-2.559 2.715-3.198 3.479a1.462 1.462 0 0 0 .202 2.233c1.674 1.527 8.611 1.743 12.434-1.267 3.823-3.009 7.178-9.269 9.457-11.671a37.71 37.71 0 0 0 2.404-2.801 4.858 4.858 0 0 0 1.12-3.133c-.18-1.495-.116-3.01.189-4.485.475-2.46.983-3.42 1.693-5.816l-11.919-3.303Z"
            fill="#FFA8A7"
          />
          <path
            d="M275.198 125.113c4.143 12.67 3.257 19.466.958 24.903-1.909 4.531-5.478 9.237-15.776 14.211-9.769 4.713-18.888 9.707-21.318 10.849-2.429 1.143-3.211 3.832-4.337 8.637-1.069 4.569-2.404 11.932-7.36 21.613-5.211 10.222-6.65 13.245-6.65 13.245s-7.093 2.213-12.206-2.572c0 0 4.657-39.167 5.81-45.492.768-4.25 1.902-6.6 5.295-9.655 7.894-7.108 22.471-15.666 22.471-15.666s-1.088-12.918 1.433-20.04l31.68-.033Z"
            fill="#0C5D88"
          />
          <path
            d="M212.651 208.015a399.8 399.8 0 0 1 1.889-17.05c.756-5.673 1.57-11.326 2.697-16.972.143-.705.312-1.41.482-2.115l.312-1.071.385-1.044a16.841 16.841 0 0 1 2.214-3.832 31.515 31.515 0 0 1 6.318-5.953c2.299-1.724 4.722-3.264 7.125-4.785 2.404-1.521 4.82-3.062 7.263-4.569-2.293 1.71-4.625 3.355-6.937 5.032-2.312 1.678-4.657 3.264-6.871 5.053a32.574 32.574 0 0 0-5.973 5.875 16.382 16.382 0 0 0-2.019 3.59l-.352.966-.286.992c-.157.686-.32 1.371-.456 2.07-.587 2.774-1.062 5.587-1.537 8.401l-1.407 8.453a1248.714 1248.714 0 0 1-2.847 16.959Z"
            fill="#1A304D"
          />
          <path
            d="M179.401 64.732a4.011 4.011 0 0 1 2.345.182 25.612 25.612 0 0 1 3.354 1.887c1.72.933 4.481 2.467 6.468 2.383.404 0 1.238-.183.69-.653a16.66 16.66 0 0 1-2.09-2.037 3.49 3.49 0 0 1-.814-2.709c.033-.295.177-.566.403-.757.332-.248.567-.091.828.104.284.199.58.38.885.542a55.92 55.92 0 0 1 1.87 1.07c3.608 2.122 7.223 4.57 9.359 8.31.306.542 2.176 4.779 3.381 7.181.977 2.18 8.408 15.843 8.408 15.843L227.73 83.14c2.963-2.97 5.927-5.725 8.558-6.737 1.882-.724 4.488-1.697 4.488-1.697l-4.833 22.736s-13.991 10.542-15.99 11.992c-2.964 2.154-8.884 5.633-11.822 1.997-3.986-4.935-5.927-12.057-7.816-17.488-1.889-5.43-2.852-8.727-3.908-10.6-1.055-1.874-2.82-2.005-4.051-2.39a18.97 18.97 0 0 1-4.859-2.128 17.426 17.426 0 0 1-3.738-2.761 5.504 5.504 0 0 1-1.446-1.737c-.189-.594-.078-1.246-.346-1.847-.267-.6-.898-1.195-1.12-1.9a6.97 6.97 0 0 1-.117-1.73c-.039-.92-.599-1.455-1.238-2.042a2.69 2.69 0 0 1-.807-.954c-.235-.672.195-.979.716-1.122Z"
            fill="#FFA8A7"
          />
          <path
            d="m208.835 85.111-1.843-3.087a9.42 9.42 0 0 1-3.759 4.419c-2.983 1.99-5.002 1.266-5.002 1.266l1.088 3.715s2.254.842 5.816-1.515c3.563-2.356 3.7-4.798 3.7-4.798Z"
            fill="#FB0"
          />
          <path
            d="M243.472 73.074c-5.445.366-9.047 2.611-12.147 5.222-3.1 2.611-16.674 15.36-16.674 15.36l-6.409-9.883s-.371 2.729-4.097 4.896c-3.908 2.285-5.354 1.508-5.354 1.508s2.918 11.75 6.129 17.527c3.211 5.777 5.048 7.663 10.291 5.294 5.243-2.37 23.161-15.732 23.161-15.732l5.1-24.192Z"
            fill="#1A304D"
          />
          <path
            d="M193.835 70.913c.827 2.272 3.113 4.844 4.435 5.595 0 0-2.169-.222-3.302-1.691a5.004 5.004 0 0 1-1.133-3.903Z"
            fill="#F28F8F"
          />
          <path
            d="M214.651 93.663c2.241 3.916 2.944 8.903 2.475 11.462 1.628-4.119 1.746-8.453-1.022-12.82l-1.453 1.358Z"
            fill="#0A4D71"
          />
          <path
            d="M258.707 32.968a11.94 11.94 0 0 1 10.382-.398c4.514 2.2 5.419 4.68 5.979 9.928.84 7.886 1.459 10.255 2.449 12.024a6.645 6.645 0 0 0 4.006 3.192 4.594 4.594 0 0 1-4.462.333c.912 3.773 2.123 6.926 1.303 10.973-.573 2.729-1.883 5.875-3.908 7.834-1.713 1.71-4.234 2.297-6.331 3.296 1.335-3.133 2.013-6.077.905-9.517-1.218-3.793-3.908-6.972-5.145-10.797-1.752-5.294-3.732-10.764-4.358-16.32-.553-4.693-.749-7.324-.82-10.548Z"
            fill="#1A304D"
          />
          <path
            d="M257.971 32.641c.475-.822 3.537-1.305 5.953.281 2.416 1.586 4.409 4.354 2.749 6.665-1.564 2.193-11.913-3.728-8.702-6.946Z"
            fill="#FB0"
          />
          <path
            d="M267.617 74.96c4.559 1.796 5.035 1.959 5.035 1.959.951 2.493 5.171 9.348 4.057 16.045-1.113 6.698-4.559 20.889-3.81 23.676.749 2.787 4.599 10.562 5.973 18.931 0 0-2.736 1.638-5.211 2.741 0 0-1.543-9.693-4.103-13.134.212 4.165.212 8.337 0 12.501a39.448 39.448 0 0 1-23.48.568c-13.346-3.845-14.33-10.673-14.33-10.673s2.319-8.636 2.319-13.055c0-4.42-.801-11.509-.801-11.509s-5.732-5.64-3.804-11.932c1.831-5.967 11.874-15.954 13.997-18.004 1.976.035 3.95-.122 5.895-.47l18.263 2.357Z"
            fill="#1A304D"
          />
          <path
            d="M263.006 74.562c-.652 3.264-1.954 4.067-5.647 8.127-3.693 4.06-15.248 15.125-16.284 19.93-.697-4.12-1.634-9.635-.553-14.46 1.081-4.823 3.719-11.22 7.861-14.478l14.284-.607.339 1.488Z"
            fill="#FB0"
          />
          <path
            d="M237.832 49.855c-3.452-12.755 8.278-19.276 19.95-17.214 3.843.653 8.806 2.88 9.334 8.369 5.08 1.6 7.522 8.408 4.383 15.666-2.273 5.281-6.819 7.906-8.878 11.15l-24.789-17.971Z"
            fill="#1A304D"
          />
          <path
            d="M267.35 51.676c-3.257-1.43-4.905 1.998-4.905 1.998-2.123 1.305-3.347-2.944-3.81-4.224-.814-2.245-1.583-5.444-4.064-6.37-1.843-.693-3.908 0-5.614 1.005a4.255 4.255 0 0 1-3.374.45c-1.01-.274-1.303-1.86-2.247-2.35-2.651-1.43-4.71 2.944-5.074 5.04a43.081 43.081 0 0 0-.613 8.485c.196 9.505 3.374 13.37 4.983 14.237 1.609.868 3.595 1.103 6.761.842l-.046 2.807a26.48 26.48 0 0 0-2.273 9.792c6.982-2.31 11.203-5.47 15.567-9.648V60.998c.599 1.24 3.413 1.834 5.152-.398 2.234-2.918 2.683-7.553-.443-8.924Z"
            fill="#FFA8A7"
          />
          <path
            d="m250.591 61.912-4.429 1.37a2.278 2.278 0 0 0 2.853 1.613 2.438 2.438 0 0 0 1.576-2.983Z"
            fill="#B16668"
          />
          <path
            d="M250.298 62.93a2.54 2.54 0 0 0-1.631.52 2.555 2.555 0 0 0-.935 1.438c.424.135.879.135 1.303 0a2.393 2.393 0 0 0 1.628-1.919 2.093 2.093 0 0 0-.365-.039ZM249.393 70.776c3.074-.47 8.682-1.958 9.614-4.504 0 0 .143 2.056-2.234 3.871-1.987 1.515-7.406 2.461-7.406 2.461l.026-1.828Z"
            fill="#F28F8F"
          />
          <path
            d="m239.421 47.74 3.159.947a1.584 1.584 0 0 0-.082-1.25 1.58 1.58 0 0 0-.96-.8 1.735 1.735 0 0 0-2.117 1.103ZM253.626 49.614l-2.878-1.593a1.57 1.57 0 0 1 .944-.82 1.581 1.581 0 0 1 1.244.121 1.735 1.735 0 0 1 .69 2.291ZM239.988 53.452a1.334 1.334 0 0 0 2.627.364 1.337 1.337 0 0 0-1.188-1.67 1.356 1.356 0 0 0-.992.35 1.37 1.37 0 0 0-.447.956ZM250.096 54.183a1.348 1.348 0 0 0 1.543 1.365 1.34 1.34 0 0 0 1.097-.99 1.353 1.353 0 0 0-.475-1.402 1.339 1.339 0 0 0-.719-.279 1.379 1.379 0 0 0-1.446 1.306Z"
            fill="#1A304D"
          />
          <path
            d="M234.126 50.266c.084.523.309 1.013.651 1.417.122.146.209.318.254.502.248.953.469 2.749.932 3.773a4.49 4.49 0 0 0 4.044 2.866 5.41 5.41 0 0 0 1.362-.04h.104a3.71 3.71 0 0 0 1.101-.378l.065-.039.097-.052.118-.078c.036-.024.071-.05.104-.079a.896.896 0 0 0 .124-.091l.11-.092a3.05 3.05 0 0 0 .209-.208c.369-.402.665-.866.872-1.371.554-1.267.378-3.1 1.303-3.264h.241c1.303.091 1.179 2.07 1.954 3.525a5.256 5.256 0 0 0 4.761 3.1H253.184c.218-.006.435-.025.651-.058l.208-.04.202-.045h.131c.264-.075.517-.182.755-.32a3.368 3.368 0 0 0 .684-.516c.354-.38.635-.822.827-1.305.423-.96.508-2.735.71-3.656.031-.187.102-.365.209-.522.333-.36.545-.818.605-1.305a1.262 1.262 0 0 0-.794-1.215l-.691-.124c-2.221-.391-5.106-.652-6.826-.293-2.156.476-2.892 1.129-4.103 1.05-1.212-.078-1.869-.848-3.908-1.631-1.641-.653-4.338-.751-6.396-.653l-.651.032c-.333 0-.723.516-.671 1.11Zm14.257 2.128c.167-.2.376-.36.613-.47l.11-.052h.059l.3-.124.28-.104c.302-.099.611-.177.924-.235l.365-.072c.313-.046.652-.079.945-.098h.228c2.077-.059 3.386.503 3.868.96.482.456.307 2.382.118 3.348a.288.288 0 0 1 0 .072.342.342 0 0 1 0 .078 4.03 4.03 0 0 1-.085.392l-.059.242c-.039.13-.078.267-.13.404l-.058.137a1.486 1.486 0 0 1-.079.17.853.853 0 0 1-.052.117l-.071.124c-.024.045-.05.09-.079.131l-.091.137a3.025 3.025 0 0 1-2.892 1.214 4.308 4.308 0 0 1-3.093-1.305 4.733 4.733 0 0 1-1.043-2.037c-.319-1.168-.475-2.454-.078-3.029Zm-12.271-1.684c.147-.129.318-.228.502-.294h.039c.074-.028.15-.052.228-.071l.137-.04h.332a3.53 3.53 0 0 1 .475-.052H238.939c.324.029.646.077.964.144 1.485.294 3.048 1.064 3.465 1.697.417.633.345 1.9.169 2.983a4.8 4.8 0 0 1-.182.718.228.228 0 0 1 0 .052.432.432 0 0 0 0 .072v.053s0 .058-.032.084l-.079.177-.078.15v.052l-.058.098-.059.091c-.508.764-1.42 1.397-3.074 1.247a3.836 3.836 0 0 1-1.563-.463c-1.492-.83-1.896-2.514-2.078-3.264-.339-1.077-.658-3.036-.222-3.434Z"
            fill="#1A304D"
          />
          <path
            d="m257.482 50.952 6.325 1.083a5.43 5.43 0 0 0-1.231 1.397l-5.12-.875.026-1.605Z"
            fill="#1A304D"
          />
          <path
            d="m246.358 53.302-.821 7.735-4.559-1.6 4.937-6.312c.159.023.311.083.443.177Z"
            fill="#F28F8F"
          />
          <path
            d="M263.917 59.405a1.216 1.216 0 0 0 .746 1.116 1.202 1.202 0 0 0 1.642-.884 1.21 1.21 0 0 0-.518-1.24 1.201 1.201 0 0 0-1.131-.108 1.204 1.204 0 0 0-.739 1.116Z"
            fill="#F6F7F8"
          />
          <path
            d="M262.641 71.612v1.462a121.073 121.073 0 0 1-8.467 10.549c-5.602 6.195-10.389 10.908-12.109 15.203a45.736 45.736 0 0 0-2.39 9.628 24.101 24.101 0 0 1 8.317-12.324c4.058-3.264 10.617-5.757 11.855-7.833 1.165-1.959-1.179-4.674-1.179-4.674s3.992-1.273 6.435-3.049c2.442-1.775 2.501-5.613 2.501-5.613l-4.963-3.35Z"
            fill="#1A304D"
          />
          <path
            d="M249.347 73.583a26.314 26.314 0 0 0-6.884 12.762c-1.036 5.516-1.492 18.062-1.492 18.062s-1.459-8.486-2.253-12.063c-.795-3.577-1.205-6.371 2.709-10.072-.964-1.678-1.133-2.305.912-4.753a18.245 18.245 0 0 1 3.81-3.55l3.198-2.057v1.671Z"
            fill="#1A304D"
          />
          <path
            d="M241.219 120.89c-2.182-.222-4.286-2.239-7.093-3.31-2.807-1.07-5.211-1.103-7.165-1.886-.794.45-.384 2.134.56 2.859 1.472 1.129 3.713 1.834 3.179 2.435-.788.887-3.817.274-4.735.085-2.834-.588-5.862-2.292-8.793-1.665a1.299 1.299 0 0 0-.808.444 1.017 1.017 0 0 0 .228 1.207c.333.29.698.539 1.088.745a1.514 1.514 0 0 1 .749 1.038c.032.404-.209.796-.156 1.194.049.257.166.496.338.692.302.375.657.703 1.055.973.34.192.665.41.971.652a10.045 10.045 0 0 1 1.713 1.698c1.55 2.526 4.872 3.264 7.568 3.753 5.764 1.005 8.409-.209 11.809-.144 3.4.066 17.872-.378 25.304-.913 7.783-.555 8.728-2.004 10.922-9.453a213.983 213.983 0 0 0 5.83-26.574c1.231-9.354-3.035-14.42-9.855-17.096-1.615 1.43-5.171 8.166-6.734 20.562l-3.329 18.683s-20.458 4.249-22.646 4.021Z"
            fill="#FFA8A7"
          />
          <path
            d="m246.709 119.963-3.602.783c.392 1.849.61 3.731.652 5.62a19.666 19.666 0 0 1-.521 5.308l4.39.13s2.468-6.854-.919-11.841Z"
            fill="#FB0"
          />
          <path
            d="M272.652 76.906c4.194 1.482 7.275 3.081 9.411 6.456 2.137 3.375 2.553 7.552 1.648 14.557-.905 7.004-5.21 23.943-6.402 27.821-1.192 3.877-1.954 4.752-6.058 5.47-4.103.718-25.265.914-25.265.914s.795-2.265.482-6.332c-.312-4.067-1.107-5.81-1.107-5.81l18.237-3.329s2.899-17.468 3.908-22.951c1.01-5.484 2.006-10.34 5.146-16.796Z"
            fill="#1A304D"
          />
          <path
            d="M263.631 116.634c2.52-.275 6.754 1.423 7.998 3.76-.521-2.461-3.126-5.164-7.738-5.151l-.26 1.391Z"
            fill="#0A4D71"
          />
          <path
            d="m121.785 221.723-5.628-60.982-5.21-.261 8.213 61.237a.708.708 0 0 0 .351.47c.281.161.595.253.918.268.322.016.644-.044.939-.177a.653.653 0 0 0 .41-.555h.007Z"
            fill="#1A304D"
          />
          <path
            d="m116.144 160.741-5.21-.261 1.537 10.125a20.287 20.287 0 0 0 3.087-1.423 3.184 3.184 0 0 0 1.381-1.182l-.795-7.259ZM46.791 152.908h-5.21l-8.377 58.006a.656.656 0 0 0 .378.574 2.04 2.04 0 0 0 1.863 0 .711.711 0 0 0 .371-.45l10.975-58.13Z"
            fill="#1A304D"
          />
          <path
            d="m41.567 152.908-2.032 14.087c1.277.993 2.66 1.84 4.123 2.526l3.133-16.613h-5.224ZM86.854 239.629l-4.149-61.876-5.21-.268 6.74 62.138a.716.716 0 0 0 .353.47 2.056 2.056 0 0 0 1.856.091.654.654 0 0 0 .41-.555Z"
            fill="#1A304D"
          />
          <path
            d="m83.233 185.579-.528-7.833-5.21-.267.911 8.446c1.16.359 3.303.118 4.827-.346ZM75.313 136.289h-5.211l-8.363 59.141a.652.652 0 0 0 .384.574 2.04 2.04 0 0 0 1.863 0 .684.684 0 0 0 .371-.45l10.956-59.265Z"
            fill="#1A304D"
          />
          <path
            d="M31.752 95.06a29.596 29.596 0 0 0-2.09 9.197c-.593 7.043 1.784 29.871 3.217 40.08 2.064 17.051 4.605 17.083 8.291 19.936 6.344 4.915 24.581 14.537 30.612 16.913 10.917 4.309 19.84-2.33 26.496-5.627a153.705 153.705 0 0 0 17.625-10.444c1.661-1.214 2.521-2.435 2.508-3.727v-3.147c0-1.645-1.453-3.414-4.507-5.398-5.641-3.669-26.02-15.575-33.915-19.962a.08.08 0 0 0-.065-.065c-6.077-3.695-9.73-28.827-13.938-42.195a41.359 41.359 0 0 0-.274-.83c-.091-.267-.15-.424-.221-.62a21.945 21.945 0 0 0-.828-2.004"
            fill="#FB0"
          />
          <path
            d="M118.404 158.215c0-1.645-1.452-3.414-4.507-5.398-5.64-3.669-26.02-15.575-33.914-19.962a.077.077 0 0 0-.022-.043.073.073 0 0 0-.044-.022c-6.076-3.695-9.73-28.827-13.938-42.195a25.347 25.347 0 0 0-.86-2.468c0-.059-.052-.117-.071-.176-.079-.176-.157-.36-.241-.542-.085-.183-.079-.19-.124-.274a12.794 12.794 0 0 0-2.807-4.067 6.075 6.075 0 0 0-1.375-.966c-.65-.385-2.116-1.188-2.475-1.397-4.129-2.474-9.542.496-16.283 4.197-4.194 2.305-7.777 5.046-9.952 10.151a29.59 29.59 0 0 0-2.09 9.198c-.593 7.043 1.784 29.87 3.217 40.08 2.065 17.05 4.605 17.083 8.291 19.935 6.344 4.916 24.581 14.538 30.612 16.914 10.917 4.308 19.84-2.331 26.496-5.627a153.712 153.712 0 0 0 17.625-10.444c1.661-1.215 2.521-2.435 2.508-3.728-.059-.529-.046-2.63-.046-3.166Z"
            fill="#FB0"
          />
          <path
            opacity=".25"
            d="M118.404 158.215c0-1.645-1.452-3.414-4.507-5.398-5.64-3.669-26.02-15.575-33.914-19.962a.077.077 0 0 0-.022-.043.073.073 0 0 0-.044-.022c-6.076-3.695-9.73-28.827-13.938-42.195a25.347 25.347 0 0 0-.86-2.468c0-.059-.052-.117-.071-.176-.079-.176-.157-.36-.241-.542-.085-.183-.079-.19-.124-.274a12.794 12.794 0 0 0-2.807-4.067 6.075 6.075 0 0 0-1.375-.966c-.65-.385-2.116-1.188-2.475-1.397-4.129-2.474-9.542.496-16.283 4.197-4.194 2.305-7.777 5.046-9.952 10.151a29.59 29.59 0 0 0-2.09 9.198c-.593 7.043 1.784 29.87 3.217 40.08 2.065 17.05 4.605 17.083 8.291 19.935 6.344 4.916 24.581 14.538 30.612 16.914 10.917 4.308 19.84-2.331 26.496-5.627a153.712 153.712 0 0 0 17.625-10.444c1.661-1.215 2.521-2.435 2.508-3.728-.059-.529-.046-2.63-.046-3.166Z"
            fill="#fff"
          />
          <path
            d="M98.259 172.38a154.214 154.214 0 0 0 17.664-10.49c3.784-2.754 3.432-5.568-1.993-9.099-7.53-4.896-38.246-22.364-38.246-22.364s-38.865 22.977-37.197 27.233c1.667 4.256 25.708 17.364 33.276 20.347 10.942 4.309 19.846-2.33 26.496-5.627Z"
            fill="#FB0"
          />
          <path
            opacity=".6"
            d="M98.259 172.38a154.214 154.214 0 0 0 17.664-10.49c3.784-2.754 3.432-5.568-1.993-9.099-7.53-4.896-38.246-22.364-38.246-22.364s-38.865 22.977-37.197 27.233c1.667 4.256 25.708 17.364 33.276 20.347 10.942 4.309 19.846-2.33 26.496-5.627Z"
            fill="#fff"
          />
          <path
            d="M60.469 82.109c-4.09-2.155-9.399.77-15.97 4.38-4.208 2.31-7.817 5.052-9.972 10.176l-2.775-1.606c2.175-5.104 5.758-7.833 9.952-10.15 6.748-3.701 12.16-6.672 16.283-4.198.365.236 1.798 1.012 2.482 1.398Z"
            fill="#FB0"
          />
          <path
            opacity=".5"
            d="M60.469 82.109c-4.09-2.155-9.399.77-15.97 4.38-4.208 2.31-7.817 5.052-9.972 10.176l-2.775-1.606c2.175-5.104 5.758-7.833 9.952-10.15 6.748-3.701 12.16-6.672 16.283-4.198.365.236 1.798 1.012 2.482 1.398Z"
            fill="#fff"
          />
          <path
            d="M65.98 90.594c-.092-.287-1.499-5.189-4.137-7.52a6.661 6.661 0 0 0-4.468-1.703c-3.57 0-7.855 2.363-12.877 5.118-.416.235-.833.47-1.244.711-.156.091-.312.183-.462.28l-.56.347-.404.267a24.198 24.198 0 0 0-2.534 1.959c-.132.11-.258.228-.377.352-.507.467-.986.964-1.433 1.489-.085.097-.17.189-.241.287l-.287.352c-.098.118-.182.242-.28.366-.098.124-.176.248-.26.378a17.173 17.173 0 0 0-1.01 1.606l-.117.216c-.085.15-.157.3-.235.457-.078.156-.221.45-.326.652a29.044 29.044 0 0 0-2.273 9.642c-.977 11.58 2.983 49.846 6.95 53.149 0 0-.606-3.747 7.386-8.787 5.432-3.42 9.242-6.299 15.04-9.139 14.126-6.952 18.087-8.257 18.087-8.257-6.077-3.721-9.731-28.853-13.939-42.222Z"
            fill="#FB0"
          />
          <path
            opacity=".4"
            d="M65.98 90.594c-.092-.287-1.499-5.189-4.137-7.52a6.661 6.661 0 0 0-4.468-1.703c-3.57 0-7.855 2.363-12.877 5.118-.416.235-.833.47-1.244.711-.156.091-.312.183-.462.28l-.56.347-.404.267a24.198 24.198 0 0 0-2.534 1.959c-.132.11-.258.228-.377.352-.507.467-.986.964-1.433 1.489-.085.097-.17.189-.241.287l-.287.352c-.098.118-.182.242-.28.366-.098.124-.176.248-.26.378a17.173 17.173 0 0 0-1.01 1.606l-.117.216c-.085.15-.157.3-.235.457-.078.156-.221.45-.326.652a29.044 29.044 0 0 0-2.273 9.642c-.977 11.58 2.983 49.846 6.95 53.149 0 0-.606-3.747 7.386-8.787 5.432-3.42 9.242-6.299 15.04-9.139 14.126-6.952 18.087-8.257 18.087-8.257-6.077-3.721-9.731-28.853-13.939-42.222Z"
            fill="#fff"
          />
          <path d="m89.043 211.88 4.806.946.352-3.433-3.777.75-1.381 1.737Z" fill="#0A4D71" />
          <path
            d="M89.355 202.362s-1.72 2.448-2.247 3.225c-.527.777 1.342 5.379 1.954 6.312l1.381-1.736c.28 1.188.143 2.389.085 4.243-.046 1.547-.54 4.23.358 6.821 1.303 3.839 4.82 7.533 8.982 10.321 4.162 2.787 7.249 3.264 9.027 3.074 1.778-.189 2.833-1.025 3.198-2.297.365-1.273-22.738-29.963-22.738-29.963Z"
            fill="#1A304D"
          />
          <path
            d="m106.088 200.704.964-1.449c.794.464-.424 2.663-.437 5.222 0 3.48.313 8.036 1.271 11.391 1.888 6.613 4.214 11.469 5.047 14.439 1.121 3.969-5.738 4.942-12.447.255-3.328-2.33-8.389-7.833-8.851-10.96-.463-3.127.436-6.946 0-9.289s-2.501-5.659-2.254-7.99c.137-1.305 1.811-3.44 3.296-5.222 1.374-1.645 3.126-4.374 4-3.519l-.496.967c-.071 1.044.085 2.944 2.606 5.13 1.191 1.11 5.256 3.793 7.301 1.025Z"
            fill="#1A304D"
          />
          <path
            opacity=".15"
            d="M100.942 201.148c1.394.705 3.094 1.149 4.39.294h.046a3.986 3.986 0 0 0-.847 3.394c.345 2.226 1.87 17.358 1.87 17.358s-2.091-12.997-2.508-15.458c-.619-3.643-1.107-4.328-2.964-5.562l.013-.026ZM100.981 204.973a.501.501 0 0 1-.788-.62.505.505 0 0 1 .69-.067.504.504 0 0 1 .098.687ZM101.633 208.485a.509.509 0 0 1-.704.085.527.527 0 0 1-.183-.346.519.519 0 0 1 .461-.559.519.519 0 0 1 .374.115.512.512 0 0 1 .052.705ZM102.284 212.023a.49.49 0 0 1-.333.192.494.494 0 0 1-.37-.1.509.509 0 0 1-.163-.546.516.516 0 0 1 .249-.302.51.51 0 0 1 .565.058.497.497 0 0 1 .052.698ZM102.935 215.568a.501.501 0 1 1-.79-.619.501.501 0 0 1 .79.619ZM103.587 219.106a.502.502 0 1 1 .042-.552.505.505 0 0 1 .046.379.506.506 0 0 1-.088.173Z"
            fill="#0A4D71"
          />
          <path
            d="M100.467 204.797a.202.202 0 0 1 .072-.359c.143-.033 3.4-.744 5.979 1.071a.204.204 0 0 1 .045.274.2.2 0 0 1-.28.052c-2.436-1.71-5.627-1.012-5.66-1.005a.203.203 0 0 1-.156-.033ZM101.125 208.335a.221.221 0 0 1-.078-.124.202.202 0 0 1 .025-.149.2.2 0 0 1 .124-.086c.144-.033 3.4-.744 5.98 1.064a.21.21 0 0 1 .045.281.203.203 0 0 1-.28.046c-2.429-1.704-5.627-1.006-5.66-.999a.203.203 0 0 1-.156-.033ZM101.77 211.88a.207.207 0 0 1-.078-.118.205.205 0 0 1 .03-.152.205.205 0 0 1 .126-.089c.137 0 3.393-.744 5.979 1.064a.192.192 0 0 1 .083.127.182.182 0 0 1-.004.077.18.18 0 0 1-.033.07.205.205 0 0 1-.13.084.2.2 0 0 1-.15-.032c-2.43-1.704-5.628-1.012-5.66-1.005a.204.204 0 0 1-.163-.026ZM102.421 215.418a.22.22 0 0 1-.078-.118.21.21 0 0 1 .156-.241c.137 0 3.4-.744 5.979 1.07a.202.202 0 0 1 .099.218.204.204 0 0 1-.176.16.205.205 0 0 1-.157-.051c-2.43-1.704-5.628-1.006-5.66-.999a.208.208 0 0 1-.163-.039ZM103.079 218.962a.165.165 0 0 1-.051-.051.16.16 0 0 1-.027-.066.192.192 0 0 1 .077-.211.21.21 0 0 1 .072-.031c.137-.032 3.4-.744 5.98 1.064a.204.204 0 0 1-.235.333c-2.429-1.71-5.627-1.012-5.653-1.005a.207.207 0 0 1-.086.002.2.2 0 0 1-.077-.035Z"
            fill="#1A304D"
          />
          <path
            d="m112.315 245.876 4.624-2.115-1.771-3.114-2.736 2.925-.117 2.304Z"
            fill="#0A4D71"
          />
          <path
            d="M106.804 237.612s0 3.107.04 4.086c.039.98 4.383 3.728 5.445 4.159l.117-2.305c.951.836 1.557 1.959 2.605 3.532.892 1.305 2.084 3.916 4.403 5.542 3.439 2.441 8.611 3.446 13.808 3.264 5.198-.183 8.077-1.645 9.471-2.873 1.393-1.227 1.778-2.572 1.302-3.864-.475-1.292-37.191-11.541-37.191-11.541Z"
            fill="#1A304D"
          />
          <path
            d="m119.974 226.123-.058-1.801c.95-.098 1.244 2.5 2.794 4.686 2.077 2.938 5.1 6.6 7.926 8.846 5.576 4.445 10.474 7.141 12.962 9.138 3.335 2.677-1.863 7.651-10.356 7.749-4.214.052-11.815-1.554-14.082-3.917-2.267-2.363-3.81-6.136-5.614-7.833-1.805-1.697-5.524-3.264-6.716-5.386-.651-1.168-.547-4.001-.364-6.41.162-2.213 0-5.581 1.257-5.392l.163 1.123c.566.921 1.843 2.428 5.269 2.768 1.706.196 6.761.006 6.819-3.571Z"
            fill="#1A304D"
          />
          <path
            d="M115.877 229.622c1.603-.248 3.309-.901 3.908-2.409v-.045a4.129 4.129 0 0 0 1.303 3.375c1.635 1.671 12.037 13.525 12.037 13.525s-9.568-9.72-11.405-11.535c-2.716-2.702-3.537-2.983-5.862-2.904l.019-.007Z"
            fill="#1A304D"
          />
          <path
            opacity=".15"
            d="M118.216 232.795a.519.519 0 0 1-.902.327.525.525 0 0 1-.093-.571.52.52 0 0 1 .293-.269.529.529 0 0 1 .702.513ZM120.899 235.393a.521.521 0 0 1-1.035-.046.496.496 0 0 1 .162-.361.508.508 0 0 1 .372-.135.53.53 0 0 1 .501.542ZM123.583 237.984a.528.528 0 0 1-.167.361.492.492 0 0 1-.174.105.487.487 0 0 1-.2.03.502.502 0 0 1-.358-.165.504.504 0 0 1-.137-.37.522.522 0 0 1 .534-.503.53.53 0 0 1 .502.542ZM126.266 240.582a.52.52 0 0 1-1.036.058.525.525 0 0 1 .902-.433.524.524 0 0 1 .134.375ZM128.949 243.174a.524.524 0 0 1-.826.403.522.522 0 1 1 .826-.403Z"
            fill="#0A4D71"
          />
          <path
            d="M117.695 232.984a.209.209 0 0 1-.157-.353c.098-.104 2.423-2.683 5.693-2.715a.208.208 0 0 1 .147.356.205.205 0 0 1-.147.062c-3.081 0-5.36 2.552-5.38 2.578a.228.228 0 0 1-.156.072ZM120.378 235.569a.21.21 0 0 1-.137-.052.212.212 0 0 1 0-.294c.098-.111 2.423-2.69 5.693-2.722a.208.208 0 0 1 .147.356.205.205 0 0 1-.147.062c-3.075.032-5.361 2.552-5.38 2.578a.206.206 0 0 1-.08.057.196.196 0 0 1-.096.015ZM123.088 238.173a.203.203 0 0 1-.137-.052.207.207 0 0 1-.06-.147c0-.055.021-.108.06-.147.098-.111 2.423-2.689 5.692-2.722a.208.208 0 1 1 0 .418c-3.074.033-5.36 2.552-5.38 2.579a.206.206 0 0 1-.175.071ZM125.745 240.771a.213.213 0 0 1-.137-.058.207.207 0 0 1-.06-.147c0-.055.021-.108.06-.147.098-.111 2.423-2.683 5.693-2.716a.208.208 0 0 1 0 .418c-3.075.033-5.361 2.553-5.38 2.611a.204.204 0 0 1-.176.039ZM128.428 243.363a.206.206 0 0 1-.136-.052.208.208 0 0 1 0-.294c.097-.111 2.423-2.69 5.692-2.722a.214.214 0 0 1 .202.209.208.208 0 0 1-.208.209c-3.075.032-5.354 2.552-5.38 2.578a.21.21 0 0 1-.17.072Z"
            fill="#1A304D"
          />
          <path
            d="M115.369 199.098c1.577-5.424 3.967-13.708 5.491-19.263 2.429-8.852 4.097-18.056 3.257-22.357-1.088-5.804-6.742-11.75-14.922-17.292-8.904-6.038-20.628-13.924-20.628-13.924l-48.282.653c.136 7.8-1.095 13.904 1.686 21.319 2.456 6.528 6.566 10.282 19.162 17.743 8.943 5.287 19.41 11.254 21.67 12.742 2.866 1.88 5.569 3.388 6.442 8.29.833 4.674 1.745 12.781 6.37 22.847 5.288 11.541 10.01 21.809 10.01 21.809s10.298 2.311 16.811-5.287c0 0-3.374-13.225-7.067-27.28Z"
            fill="#0C5D88"
          />
          <path
            d="M115.206 197.832c-3.478-13.179-7.008-26.222-8.089-29.048-2.312-5.999-25.858-24.055-26.587-24.636 0 0 4.735-2.565 7.53-9.759-.216 5.875-2.828 8.486-3.674 9.961 3.562 2.951 11.62 9.211 15.234 12.403 4.559 3.988 9.321 8.728 10.838 14.909 1.127 4.589 3.563 18.996 4.748 26.17Z"
            fill="#1A304D"
          />
          <path
            d="M110.641 93.428c6.728-5.81 8.662-7.422 9.32-9.413.658-1.991.599-3.74.918-6.92.32-3.179.743-6.156.945-7.833.208-1.77 1.303-2.31 2.605-.653 1.107 1.456.782 3.786.925 5.386.163 1.893 1.172 1.71 2.234.43s6.057-7.67 7.862-9.524c1.804-1.853 3.198-1.58 3.784-.744.339.477-.593 1.593-2.775 4.51-2.182 2.919-3.374 4.329-3.165 4.485.208.157 1.459-1.344 4.057-3.981 2.391-2.435 3.081-3.16 4.351-3.767 1.505-.718 2.326.803 1.564 1.789-.763.985-1.303 1.716-4.651 5.418-2.325 2.572-2.475 2.93-2.286 3.12.189.19 1.303-.764 3.817-2.944 2.703-2.318 4.09-3.062 5.034-2.357a.955.955 0 0 1 .163 1.104 11.807 11.807 0 0 1-2.703 2.891c-1.693 1.45-2.234 2.004-3.087 2.749-.853.744-.97 1.063-.769 1.272.202.21 1.759-.724 4.924-3.192 1.127-.874 2.606.653.508 2.579-1.12 1.038-5.933 5.94-8.643 8.434-3.035 2.8-5.301 3.662-7.731 6.149-2.149 2.271-10.616 11.378-10.616 11.378l-6.585-10.366Z"
            fill="#B16668"
          />
          <path
            d="M126.748 77.487c1.498 2.97 1.205 7.63-.651 10.216 2.983-1.593 3.842-7.083.651-10.216Z"
            fill="#9A4A4D"
          />
          <path
            d="m78.641 96.711-3.003-26.594c6.514.914 9.282 2.566 14.01 9.139 2.606 3.655 8.578 11.62 14.134 18.845l13.313-11.475s6.592 4.798 8.272 10.176c0 0-15.501 16.32-18.986 19.251-3.178 2.663-5.601 4.288-10.87-.699-5.27-4.987-15.411-16.587-16.87-18.643Z"
            fill="#F6F7F8"
          />
          <path
            d="M120.28 96.822a32.569 32.569 0 0 0-2.533-2.748 10.284 10.284 0 0 0-3.257-2.298c-.274-.104-.423.19-.195.366a30.251 30.251 0 0 1 5.321 5.091c.708.861 1.348 1.775 1.915 2.735.098.17.189.34.28.516l.547-.568a18.696 18.696 0 0 0-2.078-3.094ZM103.782 98.082c-2.82 2.337-5.445 6.528-5.64 9.667-.222-4.902 1.433-8.283 4.592-11.038l1.048 1.37Z"
            fill="#C9CED4"
          />
          <path
            d="M36.95 77.585s-4.175 6.9-1.564 18.153c2.046 8.832 3.791 12.181 4.162 21.405.482 11.972.306 22.723.306 22.723 16.05 5.392 44.037-3.845 48.68-13.604 0 0-1.667-7.964-4.682-40.779a22.407 22.407 0 0 0-2.552-8.518 22.363 22.363 0 0 0-5.662-6.848l-8.258-.992-11.561-.144-6.598 2.546-12.271 6.058Z"
            fill="#F6F7F8"
          />
          <path
            d="M77.586 34.045c-2.469-4.165-6.605-6.626-13.77-6.254-8.688.45-13.202 4.694-13.82 8.251a6.174 6.174 0 0 0-3.257 3.114c-.827 1.573-1.413 4.459.814 12.696a33.331 33.331 0 0 0 3.908 9.094 4.04 4.04 0 0 0 2.358 1.893l-.156-6.332-.228-2.526s3.146-.249 3.217-8.937c0-1.175.124-2.134.078-2.924l21.722 1.455s1.609-5.366-.866-9.53Z"
            fill="#1A304D"
          />
          <path
            d="M47.931 58.36c2.475 1.306 4.768-.992 4.768-.992l.475 13.056s.254 2.52 4.253 4.732c5.68 3.147 9.269 3.578 11.724 6.933 1.837-5.627 2.755-8.454-.873-12.723v-3.518a20.057 20.057 0 0 0 5.315-.529c3.452-.999 4.742-2.833 5.393-6.195.919-4.739.606-10.862-1.127-18.787a6.654 6.654 0 0 0-2.396-3.916c-3.83-2.898-9.523-.111-14.375 1.775-2.006.777-5.98.94-7.263.333 1.688 1.874 2.078 6.528 1.108 11.221-.339 1.62-.899 1.959-1.25 1.959-1.408-.04-1.955-1.776-2.821-2.71-.866-.933-4.234-1.768-5.68 1.306a6.023 6.023 0 0 0 .884 6.631 6.002 6.002 0 0 0 1.865 1.425Z"
            fill="#B16668"
          />
          <path
            d="M68.246 65.848c-4.722-.268-8.07-1.09-9.887-2.494a16.743 16.743 0 0 1-4.038-5.046s.703 4.472 3.308 6.528c2.606 2.056 5.68 3.212 10.623 3.212l-.006-2.2Z"
            fill="#9A4A4D"
          />
          <path
            d="m65.36 44.26-3.458.464a1.844 1.844 0 0 1 1.544-2.01 1.695 1.695 0 0 1 1.722.89c.108.204.173.427.193.657ZM62.95 48.217a1.425 1.425 0 0 0 .92 1.285 1.418 1.418 0 0 0 1.901-1.103 1.426 1.426 0 0 0-.655-1.44 1.417 1.417 0 0 0-1.784.24 1.425 1.425 0 0 0-.381 1.018ZM73.587 42.962l3.256 1.227a1.838 1.838 0 0 0-1.055-2.304 1.696 1.696 0 0 0-2.201 1.077ZM76.407 47.303a1.424 1.424 0 0 1-.923 1.28 1.417 1.417 0 0 1-1.897-1.103 1.426 1.426 0 0 1 .65-1.438 1.417 1.417 0 0 1 1.787.238c.257.277.395.645.383 1.023Z"
            fill="#1A304D"
          />
          <path
            d="m68.63 44.665 1.16 9.067 4.78-1.762a21.153 21.153 0 0 0-5.94-7.305ZM66.24 56.141l4.813.875a2.41 2.41 0 0 1-1.828 2.026c-.315.074-.641.085-.96.03a2.576 2.576 0 0 1-2.026-2.93Z"
            fill="#9A4A4D"
          />
          <path
            d="M66.846 56.925a2.522 2.522 0 0 0-.593 0 2.536 2.536 0 0 0 2.013 2.14c.258.046.523.046.781 0a2.241 2.241 0 0 0-.676-1.508 2.231 2.231 0 0 0-1.525-.633Z"
            fill="#F28F8F"
          />
          <path
            d="M71.17 81.084s1.856-4.217 1.752-5.817l-2.768-2.5-.365 6.71 1.381 1.607Z"
            fill="#FB0"
          />
          <path
            opacity=".2"
            d="M71.17 81.084s1.856-4.217 1.752-5.817l-2.768-2.5-.365 6.71 1.381 1.607Z"
            fill="#0A4D71"
          />
          <path d="m67.53 79.89-4.606-2.703-3.517 2.722 7.008 3.009 1.114-3.029Z" fill="#FB0" />
          <path
            opacity=".2"
            d="m67.53 79.89-4.606-2.703-3.517 2.722 7.008 3.009 1.114-3.029Z"
            fill="#0A4D71"
          />
          <path
            d="M68.702 113.585c-.606-14.256-.065-25.138-.56-28.585l2.403-.398c1.954 4.04 8.148 16.026 8.682 25.869.352 3.421-1.303 11.326-3.589 11.568-2.286.241-6.845-6.339-6.936-8.454Z"
            fill="#FB0"
          />
          <path
            opacity=".1"
            d="M68.702 113.585c-.606-14.256-.065-25.138-.56-28.585l2.403-.398c1.954 4.04 8.148 16.026 8.682 25.869.352 3.421-1.303 11.326-3.589 11.568-2.286.241-6.845-6.339-6.936-8.454Z"
            fill="#fff"
          />
          <path
            opacity=".2"
            d="M70.793 85.13c-.105-.208-.176-.352-.248-.508l-2.403.398c.045.294.071.58.11 1.044a4.209 4.209 0 0 0 2.54-.933Z"
            fill="#0A4D71"
          />
          <path
            d="M67.451 79.563a10.18 10.18 0 0 1 2.273-.255 5.593 5.593 0 0 1 1.446 1.776 8.399 8.399 0 0 1-.651 3.538 2.895 2.895 0 0 1-2.404.398 5.043 5.043 0 0 1-1.888-2.206 4.575 4.575 0 0 1 1.224-3.251Z"
            fill="#FB0"
          />
          <path
            opacity=".1"
            d="M67.451 79.563a10.18 10.18 0 0 1 2.273-.255 5.593 5.593 0 0 1 1.446 1.776 8.399 8.399 0 0 1-.651 3.538 2.895 2.895 0 0 1-2.404.398 5.043 5.043 0 0 1-1.888-2.206 4.575 4.575 0 0 1 1.224-3.251Z"
            fill="#fff"
          />
          <path
            d="M67.497 79.55c-.078 0-.17.195-.248.209-1.732-.803-2.019-1.097-2.462-.94-.827.293-2.605 3.916-3.028 5.117-.424 1.201-.34 1.15-1.954.21a27.737 27.737 0 0 1-7.445-6.372 19.181 19.181 0 0 1-3.133-6.247 21.684 21.684 0 0 0 1.817-2.709c.9-1.867.945-2.715 1.954-2.611l.046 1.26c.273 1.566 2.781 2.97 5.093 4.739 2.312 1.769 5.986 3.368 6.872 4.132a16.507 16.507 0 0 1 2.488 3.212ZM75.54 80.398c-.208.653-2.553-4.25-3.549-4.08-.475.066-1.478 1.495-2.293 2.99h-.651c.436-1.265.705-2.582.801-3.917.104-1.73-1.583-6.07-1.583-6.07v-1.959c.267 0 .867 0 1.205-.039.267.242.795 1.306 1.303 1.92 1.179 1.468 3.908 3.61 4.318 5.509.469 1.843.62 3.752.45 5.646Z"
            fill="#E5E8EB"
          />
          <path
            d="M39.842 76.162c-6.201 2.14-11.145 4.87-12.636 10.907-1.492 6.039-2.827 20.537-3.622 29.271-.65 7.487.222 14.119 2.456 16.972l14.33-8.806 3.829-23.846s2.032-17.97-4.357-24.498Z"
            fill="#F6F7F8"
          />
          <path
            d="M40.089 113.233c-1.023 2.004-2.403 2.937-5.425 4.961-2.82 1.886-6.13 3.766-7.458 7.343.065-3.133 1.504-4.895 4.963-7.598 3.459-2.702 8.61-6.064 8.61-6.064l-.69 1.358Z"
            fill="#C9CED4"
          />
          <path
            d="M40.089 113.233c-1.023 2.004-2.403 2.937-5.426 4.961-2.82 1.886-6.128 3.766-7.457 7.343-1.173 3.153-2.547 5.843-1.166 7.795.95 1.344 5.334 1.612 9.314.737 3.98-.875 6.194-3.753 9.809-7.252 3.1-2.996 4.273-4.224 4.273-4.224l-9.347-9.36Z"
            fill="#F6F7F8"
          />
          <path
            d="M45.892 115.184c-1.7-1.116-2.253-2.5-4.559-3.263-2.306-.764-3.641-.438-6.096-1.117-2.456-.679-4.384-.881-5.947 0-1.563.882-1.1 1.802.326 2.337 1.426.536 3.803 1.123 5.536 1.913 1.732.79 3.328 1.103 3.725 3.016.554 3.051.945 6.13 1.173 9.223.247 3.264.156 6.006.462 6.3.306.293 1.713.169 2.189-1.795.475-1.965.215-8.695.215-8.695s1.55 4.386 2.449 7.441c.898 3.055 1.491 5.418 1.72 6.064.227.647 2.318-.326 2.181-2.741s-2.032-9.283-2.032-9.283l4.09 4.57c1.603 1.788 3.635 4.302 4.26 4.889.625.588 1.759-.92 1.042-2.715-.716-1.796-4.058-5.875-4.058-5.875s3.511 2.421 4.078 2.611c.566.189 2.051-.366 1.1-2.318-.651-1.305-1.7-1.997-4.559-4.171-2.86-2.174-3.068-3.264-4.045-4.347-1.283-1.397-2.012-1.228-3.25-2.044Z"
            fill="#B16668"
          />
          <path
            d="M40.37 117.463c2.513.548 4.558-.96 4.754-1.6.176 1.639-2.8 3.212-4.755 1.6ZM58.053 126.934a1.07 1.07 0 0 1-1.472.196c-.652-.365-2.45-1.677-3.257-2.284-.808-.608-3.576-2.775-3.576-2.775l2.82 3.401s3.51 2.422 4.078 2.611a1.15 1.15 0 0 0 .985-.228 1.144 1.144 0 0 0 .422-.921Z"
            fill="#9A4A4D"
          />
          <path
            d="m203.474 185.508 8.734 75.95a1.023 1.023 0 0 0 .574.79 3.068 3.068 0 0 0 2.781 0 1.016 1.016 0 0 0 .573-.79l-7.074-75.95a1.38 1.38 0 0 0-.814-1.136 4.334 4.334 0 0 0-3.908 0 1.378 1.378 0 0 0-.866 1.136Zm-96.982 75.95a.986.986 0 0 0 .573.79 3.055 3.055 0 0 0 2.775 0c.155-.071.29-.181.391-.319a.996.996 0 0 0 .182-.471l8.767-75.95a1.379 1.379 0 0 0-.815-1.136 4.334 4.334 0 0 0-3.908 0 1.366 1.366 0 0 0-.807 1.136l-7.158 75.95Zm51.813-64.246.892 82.249a1.108 1.108 0 0 0 .651.862 3.39 3.39 0 0 0 3.049 0 1.096 1.096 0 0 0 .651-.862l.892-82.249a1.529 1.529 0 0 0-.892-1.253 4.78 4.78 0 0 0-4.312 0 1.508 1.508 0 0 0-.931 1.253Z"
            fill="#FB0"
          />
          <path
            opacity=".3"
            d="m203.474 185.508 8.734 75.95a1.023 1.023 0 0 0 .574.79 3.068 3.068 0 0 0 2.781 0 1.016 1.016 0 0 0 .573-.79l-7.074-75.95a1.38 1.38 0 0 0-.814-1.136 4.334 4.334 0 0 0-3.908 0 1.378 1.378 0 0 0-.866 1.136Zm-96.982 75.95a.986.986 0 0 0 .573.79 3.055 3.055 0 0 0 2.775 0c.155-.071.29-.181.391-.319a.996.996 0 0 0 .182-.471l8.767-75.95a1.379 1.379 0 0 0-.815-1.136 4.334 4.334 0 0 0-3.908 0 1.366 1.366 0 0 0-.807 1.136l-7.158 75.95Zm51.813-64.246.892 82.249a1.108 1.108 0 0 0 .651.862 3.39 3.39 0 0 0 3.049 0 1.096 1.096 0 0 0 .651-.862l.892-82.249a1.529 1.529 0 0 0-.892-1.253 4.78 4.78 0 0 0-4.312 0 1.508 1.508 0 0 0-.931 1.253Z"
            fill="#0A4D71"
          />
          <g opacity=".15" fill="#0A4D71">
            <path
              opacity=".15"
              d="m158.546 219.282-.241-22.07a1.518 1.518 0 0 1 .892-1.253 4.78 4.78 0 0 1 4.312 0 1.526 1.526 0 0 1 .892 1.253l-.241 22.064a95.528 95.528 0 0 1-5.614.006ZM113.604 185.508a1.367 1.367 0 0 1 .808-1.136 4.334 4.334 0 0 1 3.908 0c.225.098.419.256.562.456.144.2.231.434.252.68l-2.67 23.382c-.411-.229-.828-.444-1.231-.653-1.231-.711-2.436-1.501-3.55-2.252l1.921-20.477ZM204.288 184.372a4.337 4.337 0 0 1 3.908 0 1.38 1.38 0 0 1 .814 1.136l1.915 20.497c-1.127.763-2.331 1.553-3.582 2.278-.391.222-.795.437-1.192.653l-2.696-23.428a1.388 1.388 0 0 1 .833-1.136Z"
            />
          </g>
          <path
            d="M207.525 149.298c-25.532-14.772-66.93-14.772-92.488 0-12.766 7.383-19.136 17.064-19.136 26.764v1.88c0 9.681 6.37 19.361 19.136 26.764 25.532 14.778 66.93 14.778 92.488 0 12.766-7.39 19.136-17.07 19.13-26.764v-1.88c-.007-9.7-6.364-19.394-19.13-26.764Z"
            fill="#FB0"
          />
          <path
            opacity=".2"
            d="M207.525 149.298c-25.532-14.772-66.93-14.772-92.488 0-12.766 7.383-19.136 17.064-19.136 26.764v1.88c0 9.681 6.37 19.361 19.136 26.764 25.532 14.778 66.93 14.778 92.488 0 12.766-7.39 19.136-17.07 19.13-26.764v-1.88c-.007-9.7-6.364-19.394-19.13-26.764Z"
            fill="#0A4D71"
          />
          <path
            d="M207.577 144.39c-25.532-14.779-66.923-14.779-92.488 0-12.766 7.382-19.136 17.063-19.136 26.763v3.231c0 9.681 6.37 19.362 19.136 26.764 25.532 14.779 66.924 14.779 92.488 0 12.766-7.383 19.143-18.408 19.136-28.069v-1.88c.007-9.746-6.37-19.427-19.136-26.809Z"
            fill="#FB0"
          />
          <path
            opacity=".4"
            d="M207.577 144.39c-25.532-14.779-66.923-14.779-92.488 0-12.766 7.382-19.136 17.063-19.136 26.763v3.231c0 9.681 6.37 19.362 19.136 26.764 25.532 14.779 66.924 14.779 92.488 0 12.766-7.383 19.143-18.408 19.136-28.069v-1.88c.007-9.746-6.37-19.427-19.136-26.809Z"
            fill="#fff"
          />
          <path
            opacity=".1"
            d="M207.577 144.39c-25.532-14.779-66.923-14.779-92.488 0-12.766 7.382-19.136 17.063-19.136 26.763v3.231c0 9.681 6.37 19.362 19.136 26.764 25.532 14.779 66.924 14.779 92.488 0 12.766-7.383 19.143-18.408 19.136-28.069v-1.88c.007-9.746-6.37-19.427-19.136-26.809Z"
            fill="#0A4D71"
          />
          <path
            d="M161.353 208.962c36.108 0 65.38-16.936 65.38-37.828 0-20.892-29.272-37.829-65.38-37.829-36.109 0-65.38 16.937-65.38 37.829s29.271 37.828 65.38 37.828Z"
            fill="#FB0"
          />
          <path
            opacity=".85"
            d="M161.353 208.962c36.108 0 65.38-16.936 65.38-37.828 0-20.892-29.272-37.829-65.38-37.829-36.109 0-65.38 16.937-65.38 37.829s29.271 37.828 65.38 37.828Z"
            fill="#fff"
          />
          <path
            opacity=".2"
            d="m143.122 159.279 28.007-16.215a1.612 1.612 0 0 1 1.609 0l48.615 28.135a.363.363 0 0 1 .178.31.364.364 0 0 1-.178.31l-28.287 16.372-49.944-28.912Z"
            fill="#FB0"
          />
          <path
            d="M145.233 155.839v.653a2.615 2.615 0 0 0 1.302 2.252l45.287 26.196c.395.23.845.35 1.303.346.457.001.906-.118 1.302-.346l24.679-14.237a2.612 2.612 0 0 0 1.303-2.246v-.875a.319.319 0 0 1-.163.281l-27.108 15.667-47.905-27.691Z"
            fill="#1A304D"
          />
          <path
            d="m145.233 155.833 26.854-15.536a1.55 1.55 0 0 1 1.543 0l46.577 26.959a.346.346 0 0 1 0 .594l-27.102 15.667-47.872-27.684Z"
            fill="#0C5D88"
          />
          <path
            d="m193.333 181.252 14.485-8.369-42.349-24.505-14.557 8.329 42.421 24.545Z"
            fill="#1A304D"
          />
          <path
            d="m150.912 156.707.554.32 14.003-8.009 41.789 24.185.554-.32-42.343-24.505-14.557 8.329ZM200.452 157.967l-.267.15-7.099 4.106-12.623-7.304-.261-.144 7.367-4.262 12.883 7.454Z"
            fill="#1A304D"
          />
          <path
            d="m200.185 158.117-7.099 4.106-12.623-7.304 7.106-4.106 12.616 7.304ZM193.086 183.536v1.724a2.545 2.545 0 0 1-1.303-.346l-45.248-26.196a2.615 2.615 0 0 1-1.302-2.252v-.653l47.853 27.723Z"
            fill="#1A304D"
          />
          <path
            d="M192.356 183.921a3.059 3.059 0 0 1-2.644-.208l-45.085-26.111a3.111 3.111 0 0 1-1.492-2.148l-4.93-29.466a2.573 2.573 0 0 1-.046-.503c0-.406.08-.808.235-1.184.156-.375.384-.716.671-1.003l46.856 27.117a2.26 2.26 0 0 1 1.101 1.592l5.334 31.914Z"
            fill="#1A304D"
          />
          <path
            d="m193.086 183.536-.306.177a3.698 3.698 0 0 1-.424.208l-5.334-31.927a2.273 2.273 0 0 0-1.101-1.592l-46.856-27.117c.197-.184.416-.344.651-.476l46.948 27.168a2.302 2.302 0 0 1 1.1 1.586l5.322 31.973Z"
            fill="#0C5D88"
          />
          <path
            d="m186.585 151.002.73-.418c.217.289.364.624.43.979l5.34 31.986-.306.177a3.684 3.684 0 0 1-.423.208l-5.334-31.927a2.235 2.235 0 0 0-.437-1.005Z"
            fill="#1A304D"
          />
          <path
            d="M166.967 156.668c-.332-1.958-2.371-4.569-4.559-5.875-2.188-1.305-3.68-.653-3.348 1.306.332 1.958 2.365 4.602 4.559 5.875 2.195 1.273 3.674.685 3.348-1.306Z"
            fill="#FB0"
          />
          <path
            opacity=".4"
            d="M133.118 179.594c4.559 2.519 4.559 6.593 0 9.106s-11.965 2.513-16.531 0c-4.566-2.513-4.559-6.587 0-9.106 4.559-2.52 11.965-2.514 16.531 0Z"
            fill="#FB0"
          />
          <path
            d="M117.61 161.531c4.097-1.475 10.61-1.442 14.551.072 3.94 1.515 3.908 3.917 0 5.425s-10.474 1.442-14.551-.072c-4.077-1.515-4.097-3.949 0-5.425Z"
            fill="#1A304D"
          />
          <path
            d="m114.347 156.374 20.953.562-1.791 27.847c-.124.888-.99 1.749-2.606 2.396-3.452 1.364-9.079 1.22-12.57-.327-1.642-.724-2.488-1.632-2.566-2.526l-1.42-27.952Z"
            fill="#F6F7F8"
          />
          <path
            d="m135.3 156.936-.449 4.98a9.381 9.381 0 0 1-1.779.888c-4.559 1.75-12.056 1.56-16.693-.431-.202-.084-.365-.176-.547-.267a11.234 11.234 0 0 1-1.303-.771l-.189-4.961 20.96.562Z"
            fill="#E5E8EB"
          />
          <path
            d="m112.998 157.066.098-3.753 2.443.059c.332-.164.69-.314 1.087-.464 4.736-1.743 12.213-1.553 16.694.431.345.153.682.326 1.009.516l2.378.065-.098 3.753c0 1.221-1.198 2.416-3.491 3.264-4.559 1.756-12.05 1.56-16.687-.424-2.325-.973-3.465-2.226-3.433-3.447Z"
            fill="#1A304D"
          />
          <path
            d="M116.698 150.023c4.742-1.75 12.212-1.554 16.694.424 4.481 1.978 4.409 5.013-.176 6.763-4.586 1.749-12.05 1.56-16.687-.431-4.638-1.991-4.566-5.013.169-6.756Z"
            fill="#0C5D88"
          />
          <path
            d="m115.272 152.464.833-3.081 5.993.157a24.613 24.613 0 0 1 5.966.157l5.816.15.651 3.113c.346 1.136-.579 2.292-2.794 3.095-3.771 1.383-9.933 1.22-13.762-.353-2.208-.92-3.101-2.121-2.703-3.238Z"
            fill="#1A304D"
          />
          <path
            d="M118.782 147.477c3.583-1.194 9.243-1.051 12.636.32 3.393 1.371 3.348 3.46-.117 4.654-3.465 1.195-9.119 1.051-12.642-.326-3.524-1.377-3.459-3.46.123-4.648Z"
            fill="#0C5D88"
          />
          <path
            d="M117.291 149.768c.08.183.211.34.378.451.441.323.925.582 1.439.77a18.634 18.634 0 0 0 5.921 1.044 17.77 17.77 0 0 0 5.861-.737 4.912 4.912 0 0 0 1.427-.705c.299-.222.378-.392.384-.438.007-.045-.293-.652-1.745-1.22a17.712 17.712 0 0 0-5.823-1.038 18.476 18.476 0 0 0-5.973.738c-1.478.496-1.863 1.024-1.869 1.135Z"
            fill="#1A304D"
          />
          <path
            d="M117.668 150.219c.442.323.926.582 1.44.77a18.633 18.633 0 0 0 5.92 1.044 17.773 17.773 0 0 0 5.862-.737 4.912 4.912 0 0 0 1.427-.705 4.995 4.995 0 0 0-1.388-.77 17.624 17.624 0 0 0-5.822-1.038 18.3 18.3 0 0 0-5.967.737 5.408 5.408 0 0 0-1.472.699Z"
            fill="#1A304D"
          />
          <path
            opacity=".5"
            d="M121.225 150.388a1.768 1.768 0 0 0-1.394-.3c-.267.137-.065.496.443.803a1.787 1.787 0 0 0 1.4.307c.261-.144.059-.503-.449-.81Z"
            fill="#0A4D71"
          />
          <path
            d="M134.473 173.34c0 .927-.938 1.847-2.807 2.539-3.726 1.397-9.868 1.365-13.678-.065-1.915-.712-2.873-1.638-2.873-2.565l-.553-9.022c0 .979 1.016 1.959 3.054 2.729 4.078 1.514 10.591 1.547 14.551.072 1.954-.745 2.977-1.717 2.977-2.696l-.671 9.008Z"
            fill="#0C5D88"
          />
          <path
            d="M128.07 173.843c.308-.491.584-1.002.827-1.528a4.57 4.57 0 0 1 2.378-2.063.301.301 0 0 1 .371.131 3.212 3.212 0 0 1-.886 3.564 3.242 3.242 0 0 1-1.648.984 3.245 3.245 0 0 1-1.915-.116c-.058 0-.078-.143 0-.169.341-.208.637-.481.873-.803ZM130.708 169.965a4.944 4.944 0 0 0-1.498.908c-.46.406-.83.904-1.088 1.462a4.343 4.343 0 0 1-1.374 1.788.263.263 0 0 1-.102.05.278.278 0 0 1-.302-.115c-.697-1.051-.41-2.657.743-3.793a3.253 3.253 0 0 1 3.66-.822.29.29 0 0 1 .149.274.282.282 0 0 1-.058.152.283.283 0 0 1-.13.096ZM258.928 217.67h-2.364v-10.849a8.729 8.729 0 0 1 3.999-6.939l12.655-7.311a3.17 3.17 0 0 1 3.578-.238 3.192 3.192 0 0 1 1.522 3.253l-.215 11.013-2.364-.046.221-11.025c0-.581-.124-1.032-.358-1.169a1.374 1.374 0 0 0-1.198.248l-12.656 7.324a6.355 6.355 0 0 0-2.813 4.89l-.007 10.849Z"
            fill="#1A304D"
          />
          <path
            d="m298.587 244.309-2.533-46.732a4.085 4.085 0 0 0-1.837-2.996l-1.453-.842a1.245 1.245 0 0 0-1.302-.098l-48.726 28.2 6.709 51.771 48.712-28.167a1.162 1.162 0 0 0 .43-1.136Z"
            fill="#1A304D"
          />
          <path
            d="m240.978 269.213 7.353 4.256c.951.555 1.68.104 1.615-.999l-2.527-46.732a4.128 4.128 0 0 0-1.843-2.996l-1.453-.842c-.957-.555-1.771-.105-1.83.998l-2.95 43.318a3.59 3.59 0 0 0 1.635 2.997Z"
            fill="#1A304D"
          />
          <path
            d="M266.588 259.402a.652.652 0 0 1-.652-.653l-1.302-21.894a.648.648 0 0 1 .651-.705.644.644 0 0 1 .495.171.66.66 0 0 1 .208.482l1.303 21.894a.644.644 0 0 1-.171.495.644.644 0 0 1-.48.21h-.052ZM285.268 248.657a.652.652 0 0 1-.652-.653l-1.302-21.894a.652.652 0 1 1 1.302-.078l1.303 21.894a.651.651 0 0 1-.651.705v.026Z"
            fill="#FB0"
          />
          <path
            d="M253.678 247.312a3.527 3.527 0 0 1-4.743-1.175 3.545 3.545 0 0 1-.545-1.697l-1.003-18.709a4.144 4.144 0 0 0-1.85-2.983l-1.446-.842a1.31 1.31 0 0 0-1.185-.163l48.575-28.108a1.182 1.182 0 0 1 1.303.091l1.466.842a4.14 4.14 0 0 1 1.836 2.996l.964 18.506a9.808 9.808 0 0 1-1.162 5.188 9.78 9.78 0 0 1-3.697 3.814l-38.513 22.24Z"
            fill="#0C5D88"
          />
          <path d="m270.73 235.321 7.061-4.087.306 3.917-7.08 4.099-.287-3.929Z" fill="#FB0" />
          <path
            d="M82.165 227.644v39.016c0 1.893-1.303 2.983-2.964 3.95l-4.097 2.369a3.9 3.9 0 0 1-3.198.653 8.657 8.657 0 0 1-2.182-.894.227.227 0 0 1-.084-.046l-.222-.124-41.84-24.238a9.881 9.881 0 0 1-3.62-3.624 9.913 9.913 0 0 1-1.324-4.953v-35.524a2.815 2.815 0 0 1 1.27-2.206l6.988-4.041a2.752 2.752 0 0 1 2.534 0l47.469 27.462c.374.234.684.56.898.947.226.38.354.811.372 1.253Z"
            fill="#1A304D"
          />
          <path
            d="M82.165 227.644v39.016c0 1.893-1.303 2.984-2.964 3.95l-4.097 2.369a3.889 3.889 0 0 1-3.198.653 8.656 8.656 0 0 1-2.182-.894c1.629.77 2.912-.085 2.912-2.017v-36.804a3.918 3.918 0 0 0-.56-1.899l9.717-5.627c.226.38.354.811.372 1.253Z"
            fill="#1A304D"
          />
          <path
            d="M72.642 233.917a4.283 4.283 0 0 0-1.954-3.335l-44.83-25.961c-1.772-1.025-3.224-.183-3.224 1.867v33.259a9.93 9.93 0 0 0 4.943 8.577l41.848 24.218c1.771 1.025 3.256.189 3.256-1.867l-.039-36.758Z"
            fill="#0C5D88"
          />
          <path
            d="m28.645 199.288 47.547 27.788a4.476 4.476 0 0 1 2.208 3.852v40.152l-.73.417v-40.569a3.745 3.745 0 0 0-1.843-3.225l-47.924-27.998.742-.417Z"
            fill="#FB0"
          />
          <path
            opacity=".2"
            d="M77.794 228.708c.386.676.59 1.441.593 2.22v40.152l-.73.417v-40.569c0-.651-.17-1.29-.494-1.854l.631-.366Z"
            fill="#0A4D71"
          />
          <path
            opacity=".2"
            d="M77.163 229.074a3.72 3.72 0 0 0-1.349-1.371l-47.911-27.998.723-.417 47.547 27.788c.67.395 1.226.957 1.615 1.632"
            fill="#0A4D71"
          />
          <path
            d="M45.521 204.275v6.045l-2.358 1.364v-8.016c0-.418.287-.581.652-.379l1.706.986Z"
            fill="#FB0"
          />
          <path
            opacity=".4"
            d="M45.521 204.275v6.045l-2.358 1.364v-8.016c0-.418.287-.581.652-.379l1.706.986Z"
            fill="#0A4D71"
          />
          <path
            d="M62.084 210.248v9.791l-2.247 1.306-1.517-.875v-8.029a1.433 1.433 0 0 0-.652-1.116l-11.476-6.658-2.384-1.384c-.358-.203-.651-.04-.651.378v8.023l-1.485-.881v-9.746a.802.802 0 0 1 0-.124 1.451 1.451 0 0 1 .651-.993l.958-.561a1.458 1.458 0 0 1 1.302 0l16.896 9.792a1.435 1.435 0 0 1 .605 1.077Z"
            fill="#FB0"
          />
          <path
            opacity=".2"
            d="M59.837 211.553v9.792l-1.517-.875v-8.029a1.433 1.433 0 0 0-.652-1.116l-11.476-6.658-2.384-1.384c-.358-.203-.651-.04-.651.378v8.023l-1.485-.881v-9.746a.802.802 0 0 1 0-.124c.058-.32.319-.431.651-.248l16.935 9.791a1.46 1.46 0 0 1 .58 1.077Z"
            fill="#0A4D71"
          />
        </g>
        <path
          d="m218.445 49.613-.28-10.444s18.1-2.833 14.42-21.117C230.488 7.607 219.272-.631 191.766.772c-31.263 1.594-29.062 22.123-24.932 28.658 6.663 10.555 23.519 12.507 43.443 11.097l8.168 9.086Z"
          fill="#FB0"
        />
        <path
          d="M189.711 19.925c0-.7.179-1.187.536-1.464.373-.276.82-.414 1.339-.414.503 0 .942.138 1.315.414.373.277.56.765.56 1.465 0 .666-.187 1.146-.56 1.44-.373.292-.812.438-1.315.438-.519 0-.966-.146-1.339-.439-.357-.293-.536-.773-.536-1.44Zm6.72 0c0-.7.179-1.187.536-1.464.373-.276.82-.414 1.339-.414.503 0 .942.138 1.315.414.373.277.56.765.56 1.465 0 .666-.187 1.146-.56 1.44-.373.292-.812.438-1.315.438-.519 0-.966-.146-1.339-.439-.357-.293-.536-.773-.536-1.44Zm6.72 0c0-.7.179-1.187.536-1.464.373-.276.82-.414 1.339-.414.503 0 .942.138 1.315.414.373.277.56.765.56 1.465 0 .666-.187 1.146-.56 1.44-.373.292-.812.438-1.315.438-.519 0-.966-.146-1.339-.439-.357-.293-.536-.773-.536-1.44Z"
          fill="#fff"
        />
        <path
          d="m98.555 56.07 9.353-8.807s8.324 1.697 20.992.92c18.739-1.11 29.31-9.282 26.509-20.418-3.295-13.14-15.703-18.017-38.037-17.148-12.564.496-21.422 4.517-25.597 10.098-5.107 6.828-4.67 17.462 7.523 23.84l-.743 11.514Z"
          fill="#0C5D88"
        />
        <path
          d="M113.19 30.384c0-.7.178-1.188.535-1.465.374-.276.82-.415 1.339-.415.504 0 .942.139 1.315.415.374.277.56.765.56 1.465 0 .666-.186 1.146-.56 1.44-.373.292-.811.438-1.315.438-.519 0-.965-.146-1.339-.439-.357-.293-.535-.773-.535-1.44Zm6.72 0c0-.7.178-1.188.535-1.465.374-.276.82-.415 1.339-.415.504 0 .942.139 1.315.415.374.277.56.765.56 1.465 0 .666-.186 1.146-.56 1.44-.373.292-.811.438-1.315.438-.519 0-.965-.146-1.339-.439-.357-.293-.535-.773-.535-1.44Zm6.72 0c0-.7.178-1.188.535-1.465.374-.276.82-.415 1.339-.415.504 0 .942.139 1.315.415.374.277.56.765.56 1.465 0 .666-.186 1.146-.56 1.44-.373.292-.811.438-1.315.438-.519 0-.965-.146-1.339-.439-.357-.293-.535-.773-.535-1.44Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(0 .617)" d="M0 0h320v306.767H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoInterviews;
