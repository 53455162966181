import React from "react";
import { History as IHistory } from "history";
import type { TrainingRoutes } from "@skillup/espace-rh-bridge";
import { Currency, LocaleDate } from "@skillup/shared-utils";

import Acta from "utils/Acta";
import modalitiesConstants from "constants/modalities";
import { pdfDoc as pdfDocIcon, arobase as arobaseIcon } from "uiAssets/StrokeIcons";
import InteractiveButton from "components/InteractiveButton";
import Colors from "uiAssets/Colors";
import FormatPrice from "utils/FormatPrice";
import { isSkillupAdmin, isCompanyWithTax } from "utils/User";
import downloadFileAsUser from "utils/downloadFileAsUser";
import auth from "actions/auth";
import { mapForIntraCreation } from "containers/Supervisor/routes/Intra/CreationView/IntraCreationView";

import AskEstimateModal from "./AskEstimateModal";
import BookingModal from "./BookingModal";

import styles from "./ActionsBoxStyles.module.scss";
import { buildRequest } from "utils/buildRequest";

export interface IProps {
  readonly categories?: Array<Array<string>>;
  readonly isSupervisor?: boolean;
  readonly organization: IOrganization;
  readonly places: Array<string>;
  readonly properties: ITraining;
  readonly sessions?: Array<ITrainingSession>;
  readonly sessionsByPlace?: {
    [key: string]: Array<ITrainingSession>;
  };
  readonly history: IHistory;
  readonly currency: Currency;
  readonly language: LocaleDate.Locales;
}

export interface IState {
  currentSession?: string | undefined;
  currentSessionPlace?: string | undefined;
  formattedPrice?: string;
  formattedPriceWithTax?: string;
  formattedPriceIntra?: string;
  reference?: string;
  possibleDates?: Array<{
    value: string;
    label: string;
  }>;
  possiblePlaces?: Array<{
    value: string;
    label: string;
  }>;
  success?: boolean;
  trainingDuration?: string;
  isLoading?: boolean;
}

export default class ActionsBox extends React.PureComponent<IProps, IState> {
  public state: IState = {};

  public static getDerivedStateFromProps(props, state) {
    let derivedState = null;

    if (state.places !== props.places || state.sessionsByPlace !== props.sessionsByPlace) {
      derivedState = {
        ...(derivedState || {}),
        sessionsByPlace: props.sessionsByPlace,
      };
    }

    const { properties, organization, sessions } = props;

    if (sessions !== state.sessions) {
      derivedState = {
        ...(derivedState || {}),
        sessions,
      };
    }

    if (properties !== state.properties || organization !== state.organization) {
      const trainingDuration = properties.formattedDuration;
      const formattedPrice = FormatPrice({
        price: properties.price,
        currency: props.currency,
        language: props.language,
      });

      const formattedPriceWithTax = FormatPrice({
        price: properties.priceWithTax ?? properties.price,
        withTax: true,
        currency: props.currency,
        language: props.language,
      });

      let formattedPriceIntra;

      if (
        properties.types &&
        properties.types.length === 1 &&
        properties.types[0] === "intra" &&
        (properties.priceIntra === undefined || properties.priceIntra === null)
      ) {
        formattedPriceIntra = formattedPrice;
      } else if (properties.priceIntra > 0) {
        formattedPriceIntra = FormatPrice({
          price: properties.priceIntra,
          currency: props.currency,
          language: props.language,
        });
      }

      derivedState = {
        ...(derivedState || {}),
        formattedPrice,
        formattedPriceWithTax,
        formattedPriceIntra,
        organization,
        properties,
        success: false,
        trainingDuration,
      };
    }

    return derivedState;
  }

  private getPDF = async () => {
    const { properties, organization } = this.props;
    const filename = `${properties.name}.pdf`;
    const url = `/v1/pdf/${properties.uuid}/${encodeURIComponent(
      organization.name + "-" + properties.name
    )}.pdf`;

    try {
      this.setState({ isLoading: true });
      await downloadFileAsUser(url, filename, "API", "application/pdf");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors du téléchargement de votre fichier.",
        type: "error",
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private displayAskEstimateModal = (): void =>
    Acta.setState("modalDisplayed", {
      // @ts-ignore
      content: <AskEstimateModal {...this.props} />,
      size: "small",
      title: "Demander un devis intra",
    });

  private showNoSessionModal = () => {
    Acta.setState("modalDisplayed", {
      content: (
        <div
          style={{
            lineHeight: "1.6em",
            padding: "20px",
          }}
        >
          Oups, il semblerait que cette formation ne propose pas de date de session !<br />{" "}
          Contactez-nous à <a href="mailto:support@skillup.co">support@skillup.co</a> pour que nous
          vous proposions un programme équivalent avec des dates.
          <InteractiveButton
            label="OK"
            name="closeModal"
            onClick={() => Acta.dispatchEvent("closeModal")}
          />
        </div>
      ),
      size: "small",
      title: "Pas de session disponible",
    });
  };

  private showBookingModal = (mode) => {
    Acta.setState("modalDisplayed", {
      // @ts-ignore
      content: <BookingModal {...{ ...this.props, mode }} {...this.state} />,
      noHeader: true,
      size: "small",
    });
  };

  private dispatchModal = (mode) => {
    const { sessions } = this.props;

    if (sessions?.length) {
      this.showBookingModal(mode);
    } else {
      this.showNoSessionModal();
    }
  };

  private renderSkillupAdminActions = (properties, categories): JSX.Element => (
    <div className={styles.skillupActions}>
      <h4>Skillup Admin</h4>
      {properties.internalOriginUrl && (
        <a href={properties.internalOriginUrl} target="_blank" rel="noopener noreferrer">
          Voir sur le site de l'organisme
        </a>
      )}
      {!properties.isCompanyIntra && (
        <button onClick={this.logInAsOrganization}>Voir sur le back office de l'organisme</button>
      )}
      {categories && categories.length > 0 && (
        <button onClick={this.untagTraining(properties.uuid)}>
          Supprimer {categories.length > 1 ? `les ${categories.length} catégories` : "la catégorie"}
        </button>
      )}
      <input defaultValue={`${process.env.REACT_APP_PUBLIC_APP_URL}uuid/${properties.uuid}`} />
    </div>
  );

  private logInAsOrganization = async (): Promise<void> => {
    await auth.switchTo(this.props.organization.uuid);

    window.open(`${process.env.REACT_APP_BACK_OF_URL}formations/${this.props.properties.uuid}`);
  };

  private untagTraining = (uuid: string) => async () => {
    if (window.confirm("Etes-vous sûr de vouloir retirer les catégories de cette formation ?")) {
      try {
        const request = buildRequest<TrainingRoutes.DeleteTag>({
          method: "DELETE",
          path: "/training/{uuid}/tags",
          params: {
            uuid,
          },
        });
        await request();

        Acta.dispatchEvent("sendAppMessage", { message: "OK", type: "success" });
      } catch (error) {
        Acta.dispatchEvent("sendAppMessage", { message: "KO", type: "error" });
      }
    }
  };

  public render(): JSX.Element {
    const mappedProps = mapForIntraCreation(this.props);
    const { properties, categories } = this.props;

    const { formattedPrice, formattedPriceWithTax, formattedPriceIntra, trainingDuration } =
      this.state;

    return (
      <aside className={styles.ActionsBox} id="aside">
        <div className={styles.content}>
          <ul>
            {properties.reference && (
              <li>
                <span>ID</span>
                <span>{properties.reference}</span>
              </li>
            )}
            <li>
              <span>Prix HT</span>
              <span>{formattedPrice}</span>
              {
                // @ts-ignore
                properties.priceDetails && <small>{properties.priceDetails}</small>
              }
            </li>
            {isCompanyWithTax() && (
              <li>
                <span>Prix TTC</span>
                <span>{formattedPriceWithTax}</span>
              </li>
            )}
            {formattedPriceIntra && !properties.isCompanyIntra && (
              <li>
                <span>Prix HT intra</span>
                <span>{formattedPriceIntra}</span>
              </li>
            )}
            <li>
              <span>Durée</span>
              <span>{trainingDuration}</span>
              {
                // @ts-ignore
                properties.durationDetails && <small>{properties.durationDetails}</small>
              }
            </li>
            {properties.mode && (
              <li>
                <span>Mode</span>
                <span>{modalitiesConstants.mapping[properties.mode]}</span>
                {
                  // @ts-ignore
                  properties.modeDetails && <small>{properties.modeDetails}</small>
                }
              </li>
            )}
          </ul>
          <div className={styles.actionsWrapper} key="user-private-1">
            {properties.isUnmanagedBySkillup || properties.isCompanyIntra ? (
              <div className={styles.unmanagedWarning}>
                Le service d'inscription via Skillup n'est pas disponible pour ce programme.
              </div>
            ) : (
              <>
                <InteractiveButton
                  className={styles.action}
                  onClick={() => this.dispatchModal("forTrainee")}
                  background={Colors.gradientBlue}
                  label="Inscrire un stagiaire"
                  id="registerTrainee"
                />
                <InteractiveButton
                  className={styles.action}
                  onClick={() => this.dispatchModal("byTrainee")}
                  background={Colors.gradientBlue}
                  strokeIconLeft={arobaseIcon}
                  label="Demander au stagiaire de s'inscrire"
                  id="askForATrainee"
                />
              </>
            )}
            {!properties.isCompanyIntra && (
              <InteractiveButton
                className={styles.action}
                label="Demander un devis intra"
                onClick={this.displayAskEstimateModal}
                background={Colors.gradientYellow}
                style={{
                  color: "rgb(51, 51, 51)",
                }}
                id="leadTrainingButton"
              />
            )}
            {!properties.isCompanyIntra && (
              <InteractiveButton
                className={styles.action}
                label="Créer un intra depuis ce programme"
                onClick={() =>
                  this.props.history.push(
                    "/responsable/programmes/gestion-intras/creation",
                    mappedProps
                  )
                }
                background={Colors.gradientYellow}
                style={{
                  color: "rgb(51, 51, 51)",
                }}
              />
            )}
          </div>
        </div>
        <div key="user-private-2" className={styles.actions}>
          <InteractiveButton
            style={{ borderRadius: 100 }}
            size="big"
            strokeIconLeft={pdfDocIcon}
            onClick={this.state.isLoading ? () => null : this.getPDF}
            id="pdfTrainingButton"
          />
        </div>
        {
          // Admin actions
          isSkillupAdmin() && this.renderSkillupAdminActions(properties, categories)
        }
      </aside>
    );
  }
}

interface ITraining {
  description: string;
  duration: string | number;
  rawDuration?: string | number;
  hoursPerDay: number;
  isBest: boolean;
  isCertifying: boolean;
  isNew: boolean;
  mode?: string;
  name: string;
  objectives: string;
  organization: string;
  organizationProfileLogoSmall: string;
  organizationSlug: string;
  price?: number;
  priceWithTax?: number;
  reviewsCount: number;
  reviewsCountScore: number;
  reviewsGlobalScore: number;
  reviewsRatingScore: number;
  semanticScore: number;
  seoSlug_original: string;
  sessions: Array<ITrainingSession>;
  tags: Array<Array<string>>;
  types: Array<string>;
  uuid: string;
  isCompanyIntra?: boolean;
  isUnmanagedBySkillup?: boolean;
  reference?: string;
}

interface ITrainingSession {
  uuid: string;
  city: string;
  date: string;
  dates: Array<string>;
  dates_original: Array<string>;
  department: string;
  location: {
    lon: number;
    lat: number;
  };
  region: string;
  type: "inter" | "intra";
  country?: string;
  countryCode?: string;
  address?: string;
  latitude?: number;
  longitude?: number;
  online?: boolean;
}

interface IOrganization {
  trainingsCount?: number;
  logo?: string;
  name?: string;
  profileLogoOptiSmall?: string;
  profileWebsite?: string;
  seoNoIndex?: boolean;
  slug?: string;
  traineePhoneRequired?: boolean;
  uuid?: string;
  globalDiscountRateOverride?: number;
}
