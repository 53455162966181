import React from "react";

import styles from "./CancelledAt.module.scss";

export default ({ value }: { value: string }) => {
  function formatDate(d) {
    if (!d) return "";
    return new Date(d).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  }

  return (
    <div className={styles.CancelledAt}>
      <span>{formatDate(value)}</span>
    </div>
  );
};
