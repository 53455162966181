import type { UserRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";

export async function getHRs() {
  try {
    const companyAdmins = await buildRequest<UserRoutes.GetMultipleUsers>({
      method: "GET",
      path: "/user",
      query: { role: "admin" },
    })();

    return companyAdmins;
  } catch (err) {
    return [];
  }
}
