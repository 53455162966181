import { Either } from "@skillup/monads";

import { HabilitationsRoutes } from "@skillup/espace-rh-bridge";
import { useRouteQuery } from "hooks";
import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { userAreasFilter } from "./filters";

// results are sorted on request side (to change when adding translations)
export const useHabilitations = () => {
  const query = useRouteQuery<HabilitationsRoutes.Get>(["habilitations"], {
    method: "GET",
    path: "/habilitations",
    query: { showDeletedHabilitations: false },
  });

  return query;
};

export const useFilteredHabilitations = () => {
  const [userAreas] = useRecoilState(userAreasFilter);
  const areas = useMemo(() => userAreas?.join(","), [userAreas]);

  const query = useRouteQuery<HabilitationsRoutes.Get>(["habilitations.filtered", areas], {
    method: "GET",
    path: "/habilitations",
    query: { areas, showDeletedHabilitations: false },
  });

  return query;
};

export const useHabilitationByUuid = (
  habilitationUuid: string
): Either<"habilitation-not-found", HabilitationsRoutes.Get["response"]["habilitations"][0]> => {
  const query = useFilteredHabilitations();
  const habilitation = useMemo(
    () => query.data?.habilitations?.find((habilitation) => habilitation.uuid === habilitationUuid),
    [query.data, habilitationUuid]
  );

  if (habilitation == null) return Either.left("habilitation-not-found");

  return Either.right(habilitation);
};
