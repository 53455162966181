import { useEffect, useContext, useState, useCallback, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import { useSetState } from "react-use";
import { useHistory } from "react-router-dom";

import { atom, useRecoilState } from "recoil";
import { useAreas } from "hooks";

import { UserRoutes } from "@skillup/espace-rh-bridge";

/** Global assets */
import {
  useModal,
  useModal2,
  DataTable,
  DSDropdownItem,
  MaterialIcons,
  DSFormGroupTextInput,
  DSTextInput,
} from "@skillup/ui";
import useSettings from "hooks/useSettings";
import createUserAccessChecker from "hooks/userAccessChecker";

/** Integration components */
import AddUserModal from "../AddUserModal";
import DSLayout from "components/DSLayout";
import useTableData, { CollaboratorRow } from "./useTableData";

/** Api related functions */
import getUsers from "../../api/getUsers";
import addUserApi, { CreateCollaboratorPayload } from "../../api/addUser";
import patchUser, { UpdateCollaboratorPayload } from "../../api/patchUser";

/** Local utils */
import EditUserModal from "../EditUserModal/EditUserModal";

import { plural } from "utils/locale";
import User from "utils/User";
import { SupervisorUserViewContext, IContext } from "../../Context";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";

/** SCSS */
import styles from "./CollaboratorTable.module.scss";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import UserAreaSelect from "components/UserAreaSelect/UserAreaSelect";
interface IState {
  shouldFetch: boolean;
}

function UserTable() {
  const [{ shouldFetch }, setState] = useSetState<IState>({
    shouldFetch: true,
  });
  const { addToast } = useToasts();
  const history = useHistory();
  const { settings, userAccessModules } = useSettings();
  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);
  const {
    isOpen: isCreationModalOpen,
    show: showCreationModal,
    hide: hideCreationModal,
  } = useModal();
  const {
    isOpen: isEditCollabModalOpen,
    show: openEditCollabModal,
    hide: closeModal,
  } = useModal2();
  const {
    dispatch,
    state: {
      users: { collabs, selectedCollab, maxLevel },
    },
  } = useContext<IContext>(SupervisorUserViewContext);

  // Fetching and setting initial context
  useEffect(() => {
    const fetchUsers = async () => {
      const users = await getUsers();
      dispatch({ type: "setCollabList", collabs: users.collaborators, maxLevel: users.maxLevel });
    };
    if (shouldFetch && collabs.length === 0) {
      setState({ shouldFetch: false });
      fetchUsers();
    }
  }, [collabs.length, maxLevel, dispatch, setState, shouldFetch]);

  const createUser = useCallback(
    async (payload: CreateCollaboratorPayload) => {
      try {
        const collaborator = await addUserApi(payload);
        hideCreationModal();
        if (collaborator) {
          dispatch({ type: "addCollab", newCollab: collaborator });
        }
      } catch (err) {
        addToast(`Impossible de créer un collaborateur ${err?.message}`, {
          appearance: "error",
        });
      }
    },
    [addToast, dispatch, hideCreationModal]
  );

  const updateUser = useCallback(
    async (uuid: string, payload: UpdateCollaboratorPayload) => {
      try {
        const collaborator = await patchUser(uuid, payload);
        dispatch({ type: "replaceCollab", collab: collaborator });
        addToast("Les données du collaborateur ont été mises à jour.", {
          appearance: "success",
        });
      } catch (err) {
        addToast("Erreur lors de la mise à jour du collaborateur", { appearance: "error" });
      }
    },
    [addToast, dispatch]
  );

  const openExportFile = useCallback(async () => {
    return downloadTypedFileAsUser<UserRoutes.GetUserExportRouteData>(
      {
        method: "GET",
        path: "/user/export/{fileName}",
        params: { fileName: "Utilisateurs.xlsx" },
      },
      {
        filename: "Utilisateurs.xlsx",
        target: "API",
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    );
  }, []);

  const openAllUsersExportFile = useCallback(async () => {
    return downloadTypedFileAsUser<UserRoutes.GetAllUsersExportRouteData>(
      {
        method: "GET",
        path: "/user/export-all/{fileName}",
        params: { fileName: "UtilisateursFull.xlsx" },
      },
      {
        filename: "UtilisateursFull.xlsx",
        target: "API",
        mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    );
  }, []);

  const { columns, data: tableData } = useTableData({
    maxLevel,
    collaborators: collabs ?? [],
  });

  /** TODO: Optimize by only rendering rows that are displayed to the user */
  /** Rendering */
  const paginationHook = useRecoilState(collaboratorsPagination);

  const [userAreas, setUserAreas] = useRecoilState(collaboratorsUserAreas);
  const { allAreas } = useAreas();

  const [userFilter, setUserFilter] = useState("");
  function handleUserFilterChange(value) {
    setUserFilter(value);
  }

  const tableDataDisplayed = useMemo(() => {
    let tableDataFilterByUser = tableData;
    if (userFilter) {
      const filter = userFilter.toLowerCase();
      tableDataFilterByUser = tableDataFilterByUser.filter((user) => {
        const {
          data: { lastName, firstName, email },
        } = user;

        if (
          !lastName?.toLowerCase().includes(filter) &&
          !firstName?.toLowerCase().includes(filter) &&
          !email?.toLowerCase().includes(filter)
        )
          return false;
        return true;
      });
    }

    if (userAreas) {
      const userAreasFormated = userAreas.map((area) => allAreas.find((a) => a.uuid === area));
      if (userAreas.length > 0) {
        return tableDataFilterByUser.filter((d) => {
          const rowUserAreas = d.data.areas?.split(",");
          return rowUserAreas.some((area) => userAreasFormated?.find((a) => a.name === area));
        });
      }
      return [];
    }
    return tableDataFilterByUser;
  }, [userAreas, userFilter, tableData, allAreas]);

  return (
    <DSLayout
      title={collabs.length > 0 ? `Collaborateurs (${collabs.length})` : "Collaborateurs"}
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              label="Ajouter un collaborateur"
              onClick={showCreationModal}
              icon={<MaterialIcons.PersonAdd />}
            />
          ),
          dropdownContent: [
            settings?.portalHRUploadUserDBEnabled ? (
              <DSDropdownItem
                key="base-import"
                label="Importer une base"
                onClick={() => history.push("/responsable/collaborateurs/imports")}
              />
            ) : undefined,
            <DSDropdownItem
              key="base-export"
              label="Exporter la base utilisateur"
              onClick={openExportFile}
            />,
            User.isSkillupAdmin() ? (
              <DSDropdownItem
                key="export-all-users"
                label="[OPS] Exporter la base avec les utilisateurs inactifs"
                onClick={openAllUsersExportFile}
              />
            ) : undefined,
          ].filter(Boolean),
        },
      ]}
    >
      <div className={styles.collaborators}>
        <div className={styles.filters}>
          {allAreas?.length > 1 && (
            <DSFormGroupTextInput label="Périmètres" className={styles.filter}>
              <UserAreaSelect
                selectPlaceholder="Sélectionner un périmètre"
                className={styles.UserAreaSelect}
                selectClassName={styles.select}
                value={userAreas}
                onChange={setUserAreas}
              />
            </DSFormGroupTextInput>
          )}

          <DSFormGroupTextInput label="Collaborateur" className={styles.filter}>
            <div className={styles.inputFilter}>
              <DSTextInput
                name="searchCollab"
                placeholder="Rechercher un collaborateur"
                value={userFilter}
                onChange={handleUserFilterChange}
                actionButton={<MaterialIcons.Search />}
              />
            </div>
          </DSFormGroupTextInput>
        </div>

        <div className={styles.collaboratorTable}>
          <DataTable<CollaboratorRow>
            columns={columns}
            rows={tableDataDisplayed}
            actions={(row) => {
              const collab = collabs.find((collab) => collab.uuid === row.id);
              return (
                <DSDropdownItem
                  label="Modifier les informations du collaborateur"
                  onClick={() => {
                    dispatch({
                      type: "selectCollab",
                      collab,
                    });
                    openEditCollabModal();
                  }}
                />
              );
            }}
            onClickRow={(row) =>
              history.push(
                `/responsable/collaborateurs/${row.id}${
                  UserAccessChecker.Trainings.toTrainings() ? "/formations" : ""
                }`
              )
            }
            header={{
              totalRowsLabel: (total) => {
                let label = plural(total, "%n collaborateur%s");
                if (total < collabs.length) {
                  label += `${plural(total, " filtré%s")} sur ${collabs.length}`;
                }
                return label;
              },
            }}
            pagination={{
              rowsPerPageLabel: "Lignes par page",
              itemsCountLabel: "Collaborateurs %range% sur %count%",
              pageLabel: "Page",
              stateHook: paginationHook,
            }}
            mode="compact"
          />

          {isCreationModalOpen && (
            <AddUserModal submitRequest={createUser} onClose={hideCreationModal} loading={false} />
          )}

          {selectedCollab && (
            <EditUserModal
              isOpen={isEditCollabModalOpen}
              userUuid={selectedCollab.uuid}
              userData={selectedCollab}
              closeModal={() => {
                closeModal();
                dispatch({ type: "resetSelection" });
              }}
              updateRequest={updateUser}
            />
          )}
        </div>
      </div>
    </DSLayout>
  );
}

const collaboratorsPagination = atom<number>({
  key: "collaborators.pagination",
  default: 10,
});

const collaboratorsUserAreas = atom<string[]>({
  key: "collaborators.userAreasFilter",
  default: undefined,
});

export default UserTable;
