import * as React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useDefaultRedirectionPath } from "hooks/useRedirectionRoute";

export interface ProtectedFromTrainerRouteProps extends RouteProps {
  cannotAccess?: boolean;
  redirectPath?: string;
}

const AccessGuardRoute = (props: ProtectedFromTrainerRouteProps) => {
  const defaultRedirectionPath = useDefaultRedirectionPath();
  let redirectPath: string = "";

  if (props.cannotAccess) {
    redirectPath = props.redirectPath ?? defaultRedirectionPath;
  }

  if (redirectPath) {
    const renderComponent = () => <Redirect to={{ pathname: redirectPath }} />;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};

export default AccessGuardRoute;
