import { Either } from "@skillup/monads";
import { CampaignsRoutes } from "@skillup/people-review-bridge";

import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";

export async function exportPPRCampaign(campaignID: string, columnsToExport: string[]) {
  try {
    await downloadTypedFileAsUser<CampaignsRoutes.Export>(
      {
        method: "GET",
        params: { campaignID },
        path: "/campaigns/{campaignID}/export",
        query: { columns: columnsToExport },
      },
      {
        deduceFileDataFromResponseHeaders: true,
        target: "PEOPLE_REVIEW",
      }
    );
    return Either.right(undefined);
  } catch (err) {
    return Either.left(err);
  }
}
