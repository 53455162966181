import { ICompanyField } from "@skillup/training-bridge";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

export type GridProps = {
  fields: ICompanyField[];
  loading: boolean;
  onSelectField?: (field: ICompanyField) => void;
};

export const initialState: GridInitialStatePro = {
  sorting: {
    sortModel: [{ field: "label", sort: "asc" }],
  },
  pagination: {
    paginationModel: {
      pageSize: 20,
    },
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ["actions"],
  },
};
