import { Dispatch, useMemo } from "react";

import { TemplatesSidePanel } from "./TemplateSidePanel";
import { SidePanelStates, State } from "./useTemplatesSidePanelActions";

const useTemplatesSidePanel = (currentAction: State, setActionsState: Dispatch<State>) => {
  const sidePanel: JSX.Element = useMemo(() => {
    switch (currentAction.state) {
      case SidePanelStates.Idle:
        return <></>;
      case SidePanelStates.Open:
        return (
          <TemplatesSidePanel
            templateUuid={currentAction.template.uuid}
            openActionModal={currentAction.openActionModal}
            onClose={() => setActionsState({ state: SidePanelStates.Idle, selectedRow: null })}
          />
        );
      default:
        return <></>;
    }
  }, [currentAction, setActionsState]);

  return sidePanel;
};

export default useTemplatesSidePanel;
