import React, { useState, useCallback } from "react";
import { useToggle } from "react-use";
import { trim } from "lodash";

import Colors from "uiAssets/Colors";
import InteractiveButton from "components/InteractiveButton";
import TextInput from "components/TextInput";
import Acta from "utils/Acta";

import addGroup from "../../actions/addGroup";
import editGroupName from "../../actions/editGroupName";

import styles from "./AddOrEditGroupModalStyles.module.scss";

interface IProps {
  groups: string[];
  editedGroup?: { uuid: string; name: string };
  onSuccess: (newGroups: { uuid: string; name: string }[]) => void;
}

export default ({ groups, editedGroup, onSuccess }: IProps) => {
  const lowerCasedGroups = groups.map((g) => trim(g.toLowerCase()));
  const [isValid, setIsValid] = useState(true);
  const [loading, toggleLoading] = useToggle(false);
  const [name, setName] = useState<string>();

  const checkAndCreate = useCallback(
    async (e) => {
      e.preventDefault();

      toggleLoading(true);

      if (name && name.length !== 0 && !lowerCasedGroups.includes(trim(name.toLowerCase()))) {
        try {
          let newGroups;

          if (editedGroup) {
            newGroups = await editGroupName(name, editedGroup.uuid);
          } else {
            newGroups = await addGroup(name);
          }
          onSuccess(newGroups);
          Acta.dispatchEvent("closeModal");
        } catch (err) {
          setIsValid(false);
        }
      } else {
        setIsValid(false);
        // unset button loading mode
        toggleLoading(false);
      }
    },
    [toggleLoading, name, setIsValid, onSuccess, editedGroup, lowerCasedGroups]
  );

  return (
    <div className={styles.addGroupModal}>
      <form onSubmit={checkAndCreate}>
        <TextInput
          defaultValue={editedGroup && editedGroup.name}
          label="Nom du groupe"
          onChange={(_e, value) => setName(value)}
          alwaysOpen
          autoFocus
          borderColor={isValid ? undefined : Colors.error}
          style={{ width: 300 }}
        />
      </form>
      <div className={styles.actions}>
        <InteractiveButton
          loading={loading}
          label={editedGroup ? "Changer le nom" : "Ajouter un groupe"}
          onClick={checkAndCreate}
        />
      </div>
    </div>
  );
};
