import React, {
  type HTMLAttributes,
  forwardRef,
  cloneElement,
  useMemo,
  type ReactElement,
  type MouseEvent,
} from "react";
import cx from "classnames";

import { Themes } from "components/commonProps";
import { Button } from "components/Button/Button";
import { Tooltip as DSTooltip } from "components/Tooltip";
import { ArrowLeft, Menu } from "components/MaterialIcons";

import styles from "./NavigationHeader.module.scss";
export interface NavigationHeaderProps extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  readonly showMenu?: boolean;
  readonly onClickMenu?: (e: MouseEvent) => void;
  readonly childPage?: boolean;
  readonly leftItem?: JSX.Element;
  readonly isHiddenOnMobile?: boolean;
  readonly title: string | JSX.Element;
  readonly subtitle?: JSX.Element;
  readonly parent?: {
    title?: string;
    onClick?: (e?: MouseEvent) => void;
  };

  // actions elements
  readonly children?: (JSX.Element | undefined)[] | JSX.Element;

  readonly darkMode?: boolean;
  readonly theme: Themes;
}

const NavigationHeader = forwardRef<HTMLDivElement, NavigationHeaderProps>(
  (
    {
      showMenu,
      onClickMenu,
      leftItem,
      isHiddenOnMobile,
      title,
      subtitle,
      parent,
      children,
      darkMode,
      theme,
      className,
      ...props
    },
    ref
  ): JSX.Element => {
    const isDarkMode = theme === Themes.ESPACE_RH; // || darkMode === true;

    const actionElements = useMemo(() => {
      return React.Children.toArray(children).map((child) =>
        cloneElement(child as ReactElement, {
          // @ts-expect-error child.props is defined
          className: cx(child.props?.className, styles.spacingLeft),
          theme: theme,
          darkMode: isDarkMode,
        })
      );
    }, [children]);
    return (
      <div
        ref={ref}
        className={cx(styles.NavigationHeader, className, styles[theme], {
          [styles.darkMode]: isDarkMode,
        })}
        {...props}
      >
        <div className={styles.primary}>
          {showMenu && !parent && (
            <DSTooltip label="Afficher la navigation">
              <Button
                iconOnly
                icon={<Menu />}
                onClick={onClickMenu}
                className={styles.spacingRight}
                theme={theme}
                darkMode={isDarkMode}
              />
            </DSTooltip>
          )}
          {parent?.onClick && (
            <Button
              iconOnly
              icon={<ArrowLeft />}
              onClick={parent.onClick}
              className={styles.spacingRight}
              theme={theme}
              darkMode={isDarkMode}
            />
          )}
          <div
            className={cx({
              [styles.showMenu]: showMenu || parent,
              [styles.hidden]: isHiddenOnMobile,
              [styles.title]: !isHiddenOnMobile,
            })}
          >
            {parent && <label className={styles.parentTitle}>{parent.title}</label>}
            <div className={styles.childTitle}>
              <h1 className={styles.mainTitle}>{title}</h1>
              <h2 className={styles.subTitle}>{subtitle}</h2>
            </div>
          </div>
          <div
            className={cx({
              [styles.hidden]: isHiddenOnMobile,
              [styles.item]: !isHiddenOnMobile,
            })}
          >
            {leftItem}
          </div>
        </div>
        <div
          className={cx({
            [styles.hidden]: isHiddenOnMobile,
            [styles.secondary]: !isHiddenOnMobile,
          })}
        >
          {actionElements}
        </div>
      </div>
    );
  }
);

export { NavigationHeader };
