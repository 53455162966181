import { Dispatch, useState } from "react";
import { type Template } from "services/interviews";

export enum ModalStates {
  Idle,
  Create,
  Edit,
  EditParams,
  EditTargetSettings,
  Rename,
  Archive,
  ArchivedList,
  TransferCopy,
  ChooseCampaignType,
}

export type State =
  | { state: ModalStates.Idle }
  | { state: ModalStates.Create }
  | { state: ModalStates.Edit; template: Template }
  | { state: ModalStates.EditParams; template: Template }
  | { state: ModalStates.EditTargetSettings; template: Template }
  | { state: ModalStates.Rename; template: Template }
  | { state: ModalStates.Archive; template: Template }
  | { state: ModalStates.ArchivedList }
  | { state: ModalStates.TransferCopy; template: Template }
  | { state: ModalStates.ChooseCampaignType; template: Template };

type TemplatesActionsHook = [
  {
    state: State;
  },
  Dispatch<State>
];

export const useTemplatesActions = (): TemplatesActionsHook => {
  const [state, setState] = useState<State>({ state: ModalStates.Idle });
  return [
    { state },
    (stateChange: State) => {
      return setState(stateChange);
    },
  ];
};
