import React from "react";
import { DSCheckbox } from "@skillup/ui";

interface Props {
  review: {
    uuid: string;
    state: string;
    scope: string;
  };
  checked: boolean;
  setState: Function;
  scope: string;
}

export default function Review({ review, checked, setState, scope }: Props) {
  if (!review) {
    return (
      <DSCheckbox
        checked={false}
        disabled
        label={`Évaluation à ${scope === "hot" ? "chaud" : "froid"}`}
        helpText={`L'évaluation à ${scope === "hot" ? "chaud" : "froid"} n'existe pas.`}
      />
    );
  }

  if (["abandoned", "cancelled"].includes(review.state)) {
    return (
      <DSCheckbox
        checked={false}
        disabled
        label={`Évaluation à ${scope === "hot" ? "chaud" : "froid"}`}
        helpText={`L'évaluation à ${scope === "hot" ? "chaud" : "froid"} a déjà été annulée.`}
      />
    );
  }

  if (review.state === "completed") {
    return (
      <DSCheckbox
        checked={false}
        disabled
        label={`Évaluation à ${scope === "hot" ? "chaud" : "froid"}`}
        helpText={`L'évaluation à ${scope === "hot" ? "chaud" : "froid"} a déjà été complétée.`}
      />
    );
  }

  return (
    <>
      <DSCheckbox
        checked={checked}
        onChange={() => setState({ [scope]: !checked })}
        label={`Évaluation à ${scope === "hot" ? "chaud" : "froid"}`}
      />
    </>
  );
}
