import type { TemplateItemPosition, ChildBlock, Section } from "../..";
import {
  switchChildren,
  removeChildFromPage,
  addChildToPage,
  replaceChildrenInPage,
  replacePageInSection,
  replaceSection,
} from "../actions";

export function moveChildToChild(
  sections: Section[],
  fromPosition: TemplateItemPosition<ChildBlock>,
  toPosition: TemplateItemPosition<ChildBlock>
) {
  const {
    sectionIndex: fromSectionIndex,
    pageIndex: fromPageIndex,
    childIndex: fromChildIndex,
  } = fromPosition;
  const {
    sectionIndex: toSectionIndex,
    pageIndex: toPageIndex,
    childIndex: toChildIndex,
  } = toPosition;

  const fromSection = sections[fromSectionIndex];
  const toSection = sections[toSectionIndex];

  const fromPage = fromSection.pages[fromPageIndex];
  const toPage = toSection.pages[toPageIndex];

  const fromChild = fromPage.children[fromChildIndex];

  if (fromSectionIndex === toSectionIndex && fromPageIndex === toPageIndex) {
    const newChildren = switchChildren(fromPage.children, fromChildIndex, toChildIndex);

    const newPage = replaceChildrenInPage(fromPage, newChildren);

    const newSection = replacePageInSection(fromSection, newPage, fromPageIndex);

    const newSections = replaceSection(sections, newSection, fromSectionIndex);
    return newSections;
  }

  const newFromPage = removeChildFromPage(fromPage, fromChildIndex);
  const newToPage = addChildToPage(toPage, toChildIndex, fromChild);

  const newFromSection = replacePageInSection(fromSection, newFromPage, fromPageIndex);

  if (fromSectionIndex === toSectionIndex) {
    const newSection = replacePageInSection(newFromSection, newToPage, toPageIndex);

    const newSections = replaceSection(sections, newSection, fromSectionIndex);
    return newSections;
  }

  const newToSection = replacePageInSection(toSection, newToPage, toPageIndex);

  const newSectionsTemp = replaceSection(sections, newFromSection, fromSectionIndex);
  const newSections = replaceSection(newSectionsTemp, newToSection, toSectionIndex);
  return newSections;
}
