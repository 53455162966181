import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { useToasts } from "react-toast-notifications";

import { DSFormGroupTextInput, DSModal, DSNumberInput } from "@skillup/ui";
import { ScheduleOperationsRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

import { Schedule } from "../../PortalSchedules";

type Props = {
  schedule: Schedule;
  onClose: (needRefresh?: boolean) => void;
};

export default function ScheduleBudgetModal({ schedule, onClose }: Props) {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [budget, setBudget] = useState(schedule.budget);

  const { mutate, isLoading } = useMutation({
    mutationFn: async ({ budget }: { budget: number }) =>
      buildRequest<ScheduleOperationsRoutes.UpdateBudget>({
        method: "POST",
        path: "/schedule/{scheduleUuid}/operations/updateBudget",
        params: { scheduleUuid: schedule.uuid },
        payload: { budget },
      })(),
  });

  const handleSubmit = () => {
    mutate(
      {
        budget,
      },
      {
        onSuccess: () => {
          addToast(t("app.success.message", { defaultValue: "Opération réussie" }), {
            appearance: "success",
          });
          onClose(true);
        },
        onError: () => {
          addToast(t("app.errors.message"), { appearance: "error" });
        },
      }
    );
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("supervisor.portalschedules.modal.updatebudget.title", {
            defaultValue: "Budget du plan de formation",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <DSFormGroupTextInput
          label={t("supervisor.portalschedules.modal.updatebudget.field.budget", {
            defaultValue: "Budget du plan",
          })}
          required
        >
          <DSNumberInput
            name="budget"
            value={String(budget)}
            onChange={(value) => setBudget(Number(value))}
          />
        </DSFormGroupTextInput>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("app.modal.action.cancel", {
            defaultValue: "Annuler",
          })}
          onClick={() => onClose()}
        />
        <DSModal.Footer.PrimaryButton
          label={t("app.modal.action.confirm", {
            defaultValue: "Confirmer",
          })}
          onClick={handleSubmit}
          disabled={!budget}
          loading={isLoading}
        />
      </DSModal.Footer>
    </DSModal>
  );
}
