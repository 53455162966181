import React from "react";

import cx from "classnames";

import { Themes } from "../../commonProps";

import styles from "./Radio.module.scss";

import { useRadioGroupContext } from "./RadioGroup";

export interface RadioProps {
  name?: string;
  label?: string;
  helperText?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  theme?: Themes;
}

const Radio = ({
  label,
  helperText,
  checked,
  disabled,
  onChange,
  theme = Themes.ESPACE_RH,
}: RadioProps) => {
  const name = useRadioGroupContext();

  return (
    <label
      className={cx(styles.Radio, styles[theme], {
        [styles.disabled]: disabled,
        [styles.withLabel]: !!label,
      })}
    >
      <input type="radio" name={name} disabled={disabled} checked={checked} onChange={onChange} />
      {label && (
        <div className={styles.textContainer}>
          <span className={styles.label}>{label}</span>
          {helperText && <span className={styles.helperText}>{helperText}</span>}
        </div>
      )}
    </label>
  );
};

export { Radio };
