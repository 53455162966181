import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { DSButton, Flex, Status, StatusType } from "@skillup/ui";

import { Schedule } from "../PortalSchedules";
import styles from "../PortalSchedules.module.scss";

import ScheduleItemMenu from "./ScheduleItemMenu";

export type ScheduleAction =
  | "rename"
  | "delete"
  | "activate"
  | "budget"
  | "archive"
  | "unarchive"
  | "mask-as-imported"
  | "mask-as-not-imported";

export interface ScheduleItemProps {
  schedule: Schedule;
  onEdit: (action: ScheduleAction) => void;
}

export default function ScheduleItem({ schedule, onEdit }: ScheduleItemProps) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Flex grow className={styles.item}>
      <Flex center className={styles.infos}>
        {schedule.isImported && (
          <Status
            type={StatusType.DONE}
            label={t("supervisor.portalschedules.schedule.imported", {
              defaultValue: "Plan importé",
            })}
          />
        )}
        <span className={styles.title}>{schedule.name}</span>
        <span>
          {t("supervisor.portalschedules.schedule.createdAt", {
            date: format(new Date(schedule.createdAt), "dd/MM/yyyy"),
            defaultValue: "Créé le {{date}}",
          })}
        </span>
        {schedule.archivedAt ? (
          <span>
            {t("supervisor.portalschedules.schedule.archivedAt", {
              date: format(new Date(schedule.archivedAt), "dd/MM/yyyy"),
              defaultValue: "Archivé le {{date}}",
            })}
          </span>
        ) : null}
        {schedule.fromSchedule ? (
          <span>
            {t("supervisor.portalschedules.schedule.fromSchedule", {
              from: schedule.fromSchedule,
              defaultValue: "Généré depuis : {{from}}",
            })}
          </span>
        ) : null}
      </Flex>
      <Flex className={styles.actions}>
        <DSButton
          label={t("supervisor.portalschedules.schedule.showcollection", {
            defaultValue: "Accéder au recueil",
          })}
          buttonSize="S"
          emphasis={schedule.active ? "High" : "Low"}
          onClick={() => {
            sessionStorage.setItem("selectedScheduleUuid", schedule.uuid);
            history.push(`/responsable/${schedule.uuid}/recueil-des-besoins`);
          }}
        />
        <DSButton
          label={t("supervisor.portalschedules.schedule.showplan", {
            defaultValue: "Accéder au plan",
          })}
          buttonSize="S"
          emphasis={schedule.active ? "High" : "Low"}
          onClick={() => {
            sessionStorage.setItem("selectedScheduleUuid", schedule.uuid);
            history.push(`/responsable/${schedule.uuid}/plan-de-formation`);
          }}
        />
        <ScheduleItemMenu schedule={schedule} onEdit={onEdit} />
      </Flex>
    </Flex>
  );
}
