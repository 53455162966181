import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { debounce, isEmpty } from "lodash";

import type { FieldRoutesType, PatchValidationModalData } from "types/api";

import { useRouteQuery } from "hooks";
import { buildRequest } from "utils/buildRequest";
import DataLayer from "utils/DataLayer";
import moveInArray from "utils/moveInArray";
import Acta from "utils/Acta";

import DropDown from "components/DropDown";
import TextInput from "components/TextInput";
import Icon from "components/Icon";
import InteractiveButton from "components/InteractiveButton";

import { trashBin as trashBinIcon } from "uiAssets/StrokeIcons";
import Colors from "uiAssets/Colors";

import styles from "./PortalForm.module.scss";

interface IModalData {
  buttonLabel: string;
  title: string;
  validateButtonLabel: string;
  successMessage: string;
  validationLevel: number;
  fields: IField[];
}

interface ISettings {
  validate: {
    fields: IField[];
  };
}

interface IFetchedSettings {
  managerValidationForms: {
    validate: {
      fields: IField[];
    };
  };
}

interface IField {
  binding: string;
  fromStandard?: boolean;
  type: "number" | "text" | "select" | "monetary" | "textarea";
  source?: string;
  defaultValue: string;
  label: string;
  uuid: string;
  options?: { key: string; value: string }[];
  required?: boolean;
  placeholder?: string;
  description?: string;
}

const Field = ({ field }: { field: IField }) => {
  if (field.type === "select") {
    return (
      <DropDown
        placeholder={field.placeholder}
        label={field.label}
        defaultValue={field.defaultValue}
        options={field.options.map((o) => ({ label: o.value, value: o.key }))}
      />
    );
  }

  if (field.type === "text") {
    return (
      <TextInput
        alwaysOpen
        defaultValue={field.defaultValue}
        label={field.label}
        placeholder={field.placeholder}
      />
    );
  }

  if (field.type === "textarea") {
    return (
      <TextInput
        alwaysOpen
        defaultValue={field.defaultValue}
        label={field.label}
        placeholder={field.placeholder}
      />
    );
  }

  if (field.type === "monetary" || field.type === "number") {
    return (
      <TextInput
        alwaysOpen
        defaultValue={field.defaultValue}
        label={field.label}
        type="number"
        placeholder={field.placeholder}
      />
    );
  }

  return <div></div>;
};

const patchModalData = debounce(async (newModalData) => {
  try {
    await buildRequest<PatchValidationModalData>({
      method: "POST",
      path: "/scheduleManager",
      payload: {
        validationLevel: newModalData.validationLevel,
        fields: newModalData.fields.map((f) => ({
          uuid: f.uuid,
          required: f.required,
          description: f.description,
          placeholder: f.placeholder,
          validationLevel: newModalData.validationLevel,
        })),
      },
    })();

    Acta.dispatchEvent("sendAppMessage", {
      message: "Modifications enregistrées avec succès",
      type: "success",
    });
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Echec des modifications.",
      type: "error",
    });
  }
}, 500);

const ManagerPortalForm = () => {
  const [fields, setFields] = useState<IField[]>([]);
  const [validationLevel, setValidationLevel] = useState<number>(0);

  const { data } = useRouteQuery<FieldRoutesType.Get>(["fields"], {
    method: "GET",
    path: "/fields",
    query: { showDeletedFields: true, showHiddenFields: true },
  });

  useEffect(() => {
    if (data?.fields) {
      setFields(data.fields.filter((f) => !["row", "user"].includes(f.source)) as Array<IField>);
    }
  }, [data]);

  const [settings, setSettings] = useState<ISettings>();
  const fetchSettings = useCallback(async () => {
    const { managerValidationForms } = (await DataLayer.request({
      method: "GET",
      target: "PORTAL",
      url: "/settings",
    })) as IFetchedSettings;

    setSettings(managerValidationForms);
  }, []);

  const [modalData, setModalData] = useState<IModalData | null>(null);
  const [hasModified, setHasModified] = useState<boolean>(false);
  const [isPatching, setIsPatching] = useState<boolean>(false);

  const changeModalData = (newModalData: IModalData) => {
    setHasModified(true);
    setModalData(newModalData);
  };
  const debounceChangeModalData = debounce(changeModalData, 500);

  useEffect(() => {
    const defaultModalData = {
      validateButtonLabel: "Accepter",
      buttonLabel: "Accepter",
      successMessage: "Demande validée",
      title: "Accepter la demande de formation de { nom du collaborateur }",
      fields: [],
    };

    const withMappedValidationFormFields = (collectionFormFields = []) => {
      const hasNoUuid = collectionFormFields.some((field) => !field.uuid);
      const validationLevelHasChanged = collectionFormFields.some(
        (field) => field.validationLevel !== validationLevel
      );

      if (hasNoUuid && !isEmpty(fields)) {
        return collectionFormFields.map((field) => {
          if (!field.uuid) {
            const { uuid } = fields.find((f) => f.binding === field.binding);
            return {
              ...field,
              uuid,
            };
          }
          return field;
        });
      }

      if (validationLevelHasChanged && !isEmpty(fields)) {
        collectionFormFields.map((field) => {
          field.validationLevel = validationLevel;
        });
      }

      return collectionFormFields;
    };

    setModalData(
      {
        ...settings?.validate[validationLevel ?? 0].fields,
        fields: withMappedValidationFormFields(settings?.validate[validationLevel ?? 0].fields),
        validateButtonLabel: defaultModalData.validateButtonLabel,
        buttonLabel: defaultModalData.buttonLabel,
        successMessage: defaultModalData.successMessage,
        title: defaultModalData.title,
        validationLevel: validationLevel ?? 0,
      } ?? defaultModalData
    );
  }, [settings, fields, validationLevel]);

  useEffect(() => {
    fetchSettings();
  }, [setValidationLevel, validationLevel, fetchSettings]);

  const moveFieldInList = async (from: number, to: number) => {
    const updatedFields = moveInArray(modalData.fields, from, to);
    changeModalData({ ...modalData, fields: updatedFields });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    moveFieldInList(oldIndex, newIndex);
  };

  const fakeSuccess = () =>
    Acta.dispatchEvent("sendAppMessage", {
      message: modalData.successMessage,
      type: "success",
    });

  const alreadyAddedFields = (modalData?.fields ?? []).map((f) => f.binding);
  const fieldsForSelect = fields
    .filter((field) => !alreadyAddedFields.includes(field.binding))
    .map((field) => ({
      label: field.label,
      value: field.binding,
    }));

  const setFieldProperty = (binding: string, property: string, value: string) => {
    debounceChangeModalData({
      ...modalData,
      fields: modalData.fields.map((f) => {
        if (f.binding !== binding) return f;
        return {
          ...f,
          [property]: value,
        };
      }),
    });
  };

  const SortableItem = SortableElement(({ field }: { field: IField }) => (
    <div className={styles.sortableField}>
      <div>
        <span>
          <strong>Label:</strong> {field.label}
        </span>
        <span>
          <strong>Binding:</strong> {field.binding}
        </span>
        <span
          onClick={() => {
            changeModalData({
              ...modalData,
              fields: modalData.fields.map((f) => {
                if (f.binding !== field.binding) return f;
                return { ...field, required: !field.required };
              }),
            });
          }}
        >
          <strong>Champ requis: </strong>
          <span className={styles.requiredField}>{field.required ? "Oui" : "Non"}</span>
        </span>
        <span>
          <TextInput
            alwaysOpen
            label="Placeholder"
            defaultValue={field.placeholder}
            onChange={(e) => {
              const { value } = e.currentTarget;
              setFieldProperty(field.binding, "placeholder", value);
            }}
          />
        </span>
        <span>
          <TextInput
            type="textarea"
            alwaysOpen
            label="Description"
            defaultValue={field.description}
            onChange={(e) => {
              const { value } = e.currentTarget;
              setFieldProperty(field.binding, "description", value);
            }}
          />
        </span>
      </div>
      <div className={styles.dndContainer}>
        <div
          title="Supprimer le champ"
          onClick={(e) => {
            e.preventDefault();
            changeModalData({
              ...modalData,
              fields: modalData.fields.filter((f) => f.binding !== field.binding),
            });
          }}
        >
          <Icon strokeIcon={trashBinIcon} width={20} stroke={Colors.error} />
        </div>
        <div
          title="Drag'n'drop le champ à une autre position"
          onClick={(e) => {
            e.preventDefault();
            changeModalData({
              ...modalData,
              fields: modalData.fields.filter((f) => f.binding !== field.binding),
            });
          }}
        >
          <p>Déplacer le champ</p>
        </div>
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ fields: fieldsItems }: { fields: IField[] }) => {
    return (
      <div>
        {fieldsItems.map((value, i) => (
          <SortableItem key={`item-${i}`} index={i} field={value} />
        ))}
      </div>
    );
  });

  return (
    <div className={styles.portalForm}>
      <div className={styles.settings}>
        <div className={styles.editionContent}>
          <div className={styles.labels}>
            <div>
              <InteractiveButton
                label="Enregistrer les changements"
                size="small"
                onClick={async () => {
                  if (hasModified && !isPatching) {
                    setHasModified(false);
                    setIsPatching(true);
                    await patchModalData(modalData);
                    setIsPatching(false);
                  }
                }}
                background={hasModified ? "#069" : "#ccc"}
                color={hasModified ? undefined : "#444"}
              />
            </div>
          </div>
          <div className={styles.addFieldContainer}>
            <p>
              <strong>Niveau managérial:</strong>
            </p>
            <Select
              options={[
                { value: 0, label: "N+1" },
                { value: 1, label: "N+2" },
              ]}
              defaultValue={{ value: 0, label: "N+1" }}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 100,
                }),
              }}
              onChange={(level) => setValidationLevel(level.value)}
            />
          </div>
          <div className={styles.addFieldContainer}>
            <p>
              <strong>Ajouter un champ dans la modale:</strong>
            </p>
            <Select
              options={fieldsForSelect}
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 100,
                }),
              }}
              onChange={(newField) => {
                const selectedField = fields.find((f) => f.binding === newField.value);
                changeModalData({
                  ...modalData,
                  fields: [...modalData.fields, selectedField],
                });
              }}
            />
          </div>
          <div className={styles.fields}>
            <SortableList
              onSortEnd={onSortEnd}
              fields={modalData?.fields ?? []}
              shouldCancelStart={(e) => {
                if (
                  [
                    "a",
                    "textarea",
                    "input",
                    "svg",
                    "span",
                    "button",
                    "rect",
                    "polyline",
                    "line",
                  ].includes((e.target as any).tagName.toLowerCase())
                ) {
                  return true; // Return true to cancel sorting
                }
                return false;
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.preview}>
        {/* <h3>Prévisualisation</h3> */}
        <div className={styles.previewContent}>
          <div className={styles.modal}>
            <h3>{modalData?.title ?? ""}</h3>
            <form>
              {(modalData?.fields ?? []).map((field) => (
                <div key={field.uuid}>
                  {field.description && <p>{field.description}</p>}
                  <Field field={field} />
                </div>
              ))}

              <InteractiveButton
                label={modalData?.validateButtonLabel ?? ""}
                onClick={fakeSuccess}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerPortalForm;
