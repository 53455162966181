import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { edit, selectHighlightUuid } from "../../../reducer";
import type { ContentItem } from "../../types";
import { BuilderFormInput } from "../BuilderFormInput";

import styles from "./Page.module.scss";

export const PageContent = ({ page }: { page: Extract<ContentItem, { type: "page" }> }) => {
  const highlightUuid = useSelector(selectHighlightUuid);
  const dispatch = useDispatch();

  const multipleAssistiveTexts = useMemo(() => {
    return {
      default: (
        <>
          Ce titre sera repris dans la navigation, donc visible par toutes les personnes habilitées
          à voir l’entretien.
          <br />
          Il ne sera pas repris dans le corps de l’entretien.
        </>
      ),
      error: "Ce champ doit être rempli.",
    };
  }, []);

  const updateTemplate = (title: Extract<ContentItem, { type: "page" }>["data"]["title"]) => {
    dispatch(
      edit({
        data: {
          title: title,
        },
        from: {
          sectionIndex: page.sectionIndex,
          pageIndex: page.pageIndex,
        },
        type: "page",
      })
    );
  };

  return (
    <div>
      <BuilderFormInput
        label="Intitulé de la sous-section"
        placeholder={`Section ${page.sectionIndex + 1} - Page ${page.pageIndex + 1}`}
        name={`section-${page.sectionIndex}-page-${page.pageIndex}`}
        value={page.data.title}
        onChange={updateTemplate}
        debounceValue={50}
        required
        multipleAssistiveTexts={multipleAssistiveTexts}
        formGroupClassName={styles.page}
        autoFocus={highlightUuid === page.uuid}
      />
    </div>
  );
};
