import { enGB, format, fr, parseISO } from "@skillup/shared-utils";
import cx from "classnames";
import { isEmpty } from "lodash";
import { useMemo } from "react";

import type { QuestionReply } from "@skillup/types";
import { DSAvatar, DSButton, Flex } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import styles from "./Comments.module.scss";

import { InterviewRoles } from "@skillup/espace-rh-bridge";

interface Props {
  author;
  readonly data?: {
    [role in InterviewRoles]?: {
      comment?: QuestionReply<string>;
    };
  };
  readonly showComments: boolean;
  readonly toggleComments?: () => void;
  readonly showDate?: boolean;
  readonly className?: string;
}

const formatDate = ({ locale, updatedAt }: { locale: Locale; updatedAt?: string }) => {
  return updatedAt ? format(parseISO(updatedAt), "d MMMM yyy", { locale }) : undefined;
};

export const ToggleComments = ({ data, showComments, toggleComments }: Props) => {
  const { t } = useTranslation();

  const managerComment = useMemo(() => data?.manager?.comment, [data]);
  const employeeComment = useMemo(() => data?.employee?.comment, [data]);

  const nbActiveComments = useMemo(() => {
    return (isEmpty(managerComment?.value) ? 0 : 1) + (isEmpty(employeeComment?.value) ? 0 : 1);
  }, [managerComment, employeeComment]);

  if (!nbActiveComments) {
    return <></>;
  }

  return (
    <DSButton
      emphasis="Low"
      buttonSize="S"
      className={styles.ToggleComments}
      onClick={toggleComments}
      label={t(
        showComments
          ? "interview.targets.actions.comments.hide"
          : "interview.targets.actions.comments.show",
        {
          defaultValue: showComments
            ? "Afficher les commentaires ({{count}})"
            : "Masquer les commentaires",
          count: nbActiveComments,
        }
      )}
    />
  );
};

const Comments = ({ author, data, showComments, showDate = true, className }: Props) => {
  if (!showComments) {
    return <></>;
  }

  const employeeComment = data?.employee?.comment;
  const managerComment = data?.manager?.comment;

  return (
    <CommentsBox
      employeeInitials={author?.initials ?? ""}
      managerInitials={author?.initials ?? ""}
      employeeFullName={author?.fullName ?? ""}
      managerFullName={author?.fullName ?? ""}
      employeeComment={employeeComment?.value}
      managerComment={managerComment?.value}
      employeeCommentDate={employeeComment?.updatedAt}
      managerCommentDate={managerComment?.updatedAt}
      showDate={showDate}
      className={className}
    />
  );
};

export default Comments;

/** Comments */

const CommentsBox = ({
  employeeInitials,
  managerInitials,
  employeeFullName,
  managerFullName,
  employeeComment,
  employeeCommentDate,
  managerComment,
  managerCommentDate,
  showDate = true,
  className,
}: {
  employeeInitials: string;
  managerInitials: string;
  employeeFullName: string;
  managerFullName: string;
  employeeComment?: string | null;
  employeeCommentDate?: string | null;
  managerComment?: string | null;
  managerCommentDate?: string | null;
  showDate?: boolean;
  className?: string;
}) => {
  const { i18n } = useTranslation();
  const locale = useMemo(() => (i18n.language === "en" ? enGB : fr), [i18n.language]);
  if (!employeeComment && !managerComment) {
    return null;
  }

  const get_startof_day = (date: string) => {
    const date_obj = new Date(date);
    date_obj.setHours(0, 0, 0, 0);
    return date_obj.toISOString();
  };

  const is_same_date =
    employeeCommentDate &&
    managerCommentDate &&
    get_startof_day(employeeCommentDate) === get_startof_day(managerCommentDate);

  return (
    <div className={cx(styles.commentsBox, className)}>
      {showDate && is_same_date && (
        <CommentDate
          date={formatDate({
            locale,
            updatedAt: employeeCommentDate,
          })}
        />
      )}
      {employeeComment ? (
        <>
          {showDate && !is_same_date && employeeCommentDate && (
            <CommentDate date={formatDate({ locale, updatedAt: employeeCommentDate })} />
          )}
          <Flex className={styles.comment_wrapper}>
            <DSAvatar
              size="M"
              type="Initials"
              imageUrl={undefined}
              initials={employeeInitials}
              // TODO add uuid in activity's author
              // randomColorSeedString={author.uuid}
              color="Grey"
              generateRandomColor={false}
              tooltip={employeeFullName}
            />
            <Comment text={employeeComment} />
            <div style={{ flexGrow: 1 }} />
          </Flex>
        </>
      ) : null}

      {managerComment ? (
        <>
          {showDate && !is_same_date && managerCommentDate && (
            <CommentDate date={formatDate({ locale, updatedAt: managerCommentDate })} />
          )}
          <Flex className={styles.comment_wrapper}>
            <DSAvatar
              size="M"
              type="Initials"
              imageUrl={undefined}
              initials={managerInitials}
              // TODO add uuid in activity's author
              // randomColorSeedString={author.uuid}
              color="Grey"
              generateRandomColor={false}
              tooltip={managerFullName}
            />
            <Comment text={managerComment} />
            <div style={{ flexGrow: 1 }} />
          </Flex>
        </>
      ) : null}
    </div>
  );
};

const Comment = (props: { text: string }) => {
  if (!props.text) {
    return null;
  }

  return <p className={styles.comment}>{props.text}</p>;
};

/** Comment Date */
const CommentDate = (props: { date?: string }) => {
  if (!props.date) {
    return null;
  }

  return (
    <Flex className={styles.comment_date}>
      <hr />
      <p>{props.date}</p>
      <hr />
    </Flex>
  );
};
