import cx from "classnames";
import useTranslation from "hooks/useTranslation";
import { ExpansionPanel } from "@skillup/ui";
import { ColdReviewDetails, ReviewResultStatus } from "@skillup/training-bridge";

import styles from "./ColdReviewSidePanel.module.scss";
import { getQuestionsGroupBySentiment } from "../utils/reviewDetailsQuestionsParser";
import { ParseDate } from "@skillup/shared-utils";
import { ContentQuestionsDetailsList } from "./ContentQuestionsDetailsList";

export interface Props {
  readonly reviewDetails: ColdReviewDetails;
}

const QuestionsDetails = ({ reviewDetails }: Props) => {
  const { t } = useTranslation();
  const { positive: positiveQuestions, negative: negativeQuestions } =
    getQuestionsGroupBySentiment(reviewDetails);

  const endOfFormation = reviewDetails.session.endDate || reviewDetails.session.startDate;
  const parsedEndOfFormation = ParseDate.FromMiliTimestamp(endOfFormation);
  const parsedReviewCompletedAt = ParseDate.FromMiliTimestamp(
    reviewDetails.session?.completedAt || 0
  );

  const duration = parsedReviewCompletedAt.diff(parsedEndOfFormation, ["months"]);

  return (
    <>
      <div
        className={cx(styles.QuestionsDetails, styles.positive, {
          [styles.selected]: reviewDetails.sentiment === ReviewResultStatus.POSITIVE,
        })}
      >
        {positiveQuestions.length ? (
          <ExpansionPanel
            title={t("trainings.manage.reviews.details.summary.positive.title", {
              defaultValue:
                "Points positifs remontés par {{fullName}} {{numberOfMonths}} mois après la formation",
              fullName: reviewDetails.trainee.fullName,
              numberOfMonths: Math.round(duration.months),
            })}
            fullBleed
            className={styles.titleQuestionLabel}
          >
            <ContentQuestionsDetailsList
              reviewDetails={reviewDetails}
              reviewQuestions={positiveQuestions}
            />
          </ExpansionPanel>
        ) : null}
      </div>
      <div
        className={cx(styles.QuestionsDetails, styles.negative, {
          [styles.selected]: reviewDetails.sentiment === ReviewResultStatus.NEGATIVE,
        })}
      >
        {negativeQuestions.length ? (
          <ExpansionPanel
            title={t("trainings.manage.reviews.details.summary.negative.title", {
              defaultValue:
                "Points negatifs remontés par {{fullName}} {{numberOfMonths}} mois après la formation",
              fullName: reviewDetails.trainee.fullName,
              numberOfMonths: Math.round(duration.months),
            })}
            fullBleed
            className={styles.titleQuestionLabel}
          >
            <ContentQuestionsDetailsList
              reviewDetails={reviewDetails}
              reviewQuestions={negativeQuestions}
            />
          </ExpansionPanel>
        ) : null}
      </div>
    </>
  );
};

export default QuestionsDetails;
