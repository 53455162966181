import { DSModal } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";

export type Props = {
  onOk: () => void;
  onCancel: () => void;
  isOpen: boolean;
};

const FieldFormConfirmModal = ({ isOpen, onOk, onCancel }: Props) => {
  const { t } = useTranslation();

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={onCancel}>
        <DSModal.Header.Title
          title={t("fields.form.cancel.title", {
            defaultValue: "Abandonner les modifications ?",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex gap="s" flexDirection="column">
          <p>
            {t("fields.form.cancel.content.1", {
              defaultValue: `Ce champ personnalisé contient des modifications qui n’ont pas été enregistrées.`,
            })}
          </p>
          <p>
            {t("fields.form.cancel.content.2", {
              defaultValue: `Si vous fermez ce panneau sans avoir enregistré vos modifications, elles seront définitivement perdues.`,
            })}
          </p>
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("fields.form.cancel", { defaultValue: "Annuler" })}
          onClick={onCancel}
        />
        <DSModal.Footer.PrimaryButton
          label={t("fields.form.cancel.confirm", {
            defaultValue: "Abandonner les modifications",
          })}
          onClick={onOk}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default FieldFormConfirmModal;
