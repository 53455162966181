import React from "react";
import SuccessImage from "assets/stepRegistration/success.png";
import ErrorImage from "assets/stepRegistration/error.png";
import ExpiredImage from "assets/stepRegistration/expired.png";
import styles from "./StepRegistrationStyles.module.scss";

import { getFullName } from "utils/User";
import { Button } from "@skillup/ui";
interface IProps {
  step: string;
  trainee: any;
  currentSession: {
    name: string;
    formattedTimespan: string;
    city: string;
    place: string;
  };
}

export default class StepRegistration extends React.PureComponent<IProps, {}> {
  private static steps = {
    cancelled: {
      title: "Demande annulée",
    },
    success: {
      title: "L'inscription est terminée",
    },
    error: {
      title: "Erreur",
    },
    invalid: {
      title: "Lien expiré",
    },
    full: {
      title: "Session complète",
    },
  };

  private renderContent = (): React.ReactNode => {
    const { currentSession, step, trainee } = this.props;

    switch (step) {
      case "success":
        return (
          <>
            {getFullName(trainee)} est inscrit(e) à la formation <b>{currentSession.name}</b>{" "}
            {currentSession.formattedTimespan.toLowerCase()} {currentSession.city ? "à " : ""}{" "}
            {currentSession.place}. <br /> <br />
            La convocation sera envoyée quelques semaines avant la formation.
          </>
        );
      case "invalid":
        return (
          <>
            Le lien que vous avez suivi n’est plus valide.
            <br />
            Cela peut être dû à plusieurs raisons :<br />- vous êtes déjà inscrit
            <br />- la session a été annulée
            <br />- la session est en cours de modification par votre responsable formation
          </>
        );
      case "cancelled":
        return "Cette demande a été annulée par votre responsable formation.";
      case "error":
        return "L’inscription n’a pu aboutir en raison d’un problème technique. Veuillez ré-essayer plus tard";
      case "full":
        return "L’inscription n’a pu aboutir car la date sélectionnée n’est plus disponible";
      default:
        return "";
    }
  };

  public render(): JSX.Element {
    const { step } = this.props;
    return (
      <div className={styles.StepRegistration}>
        <header>
          <h1>{StepRegistration.steps[step].title}</h1>
        </header>
        <div className={styles.training}>
          <div>
            {step === "success" && <img src={SuccessImage} alt="success" />}
            {(step === "error" || step === "cancelled" || step === "full") && (
              <img src={ErrorImage} alt="error" />
            )}
            {step === "invalid" && <img src={ExpiredImage} alt="invalid" />}
          </div>
          <div className={styles.content}>{this.renderContent()}</div>
          {step === "full" && (
            <div className={styles.buttonsContainer}>
              <Button
                kind="primary"
                label="Revenir au formulaire"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
