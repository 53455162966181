import React from "react";
import { Moment } from "moment";

import TextInput from "components/TextInput";
import { calendar as calendarIcon } from "uiAssets/StrokeIcons";

import styles from "./Steps.module.scss";

type TCampaignType = "ongoing" | "legacy";

interface IProps {
  readonly start?: string | number | Moment;
  readonly duration?: number;
  readonly type?: TCampaignType;
  readonly ongoingStartDate?: number;
  readonly setStart: (start?: string | number) => void;
  readonly setDuration: (duration?: number) => void;
  readonly setType?: (type: TCampaignType) => void;
}

const OngoingCampaignForm = (props: IProps) => {
  const { start, duration, setDuration, setStart } = props;

  const inputDefaultValues = {
    required: true,
    showRequired: true,
    strokeIcon: calendarIcon,
    iconStyles: { strokeWidth: 2 },
    styles: { margin: 0 },
    alwaysOpen: true,
    autoComplete: "off",
    type: "number",
  };

  return (
    <>
      <section className={styles.option}>
        <TextInput
          {...inputDefaultValues}
          label="Délai de lancement (jours après l'embauche)"
          id="start"
          defaultValue={start as number}
          min={0}
          onChange={(_, value) => setStart(parseInt(value))}
        />
        <span className={styles.info}>
          Durée après l'embauche au bout de laquelle un email sera envoyé au collaborateur et
          manager pour les inviter à préparer leur entretien.
        </span>
      </section>
      <section className={styles.option}>
        <TextInput
          {...inputDefaultValues}
          label="Délai de signature (durée en jours)"
          id="endDate"
          min={1}
          defaultValue={duration}
          onChange={(_, value) => setDuration(parseInt(value))}
        />
        <span className={styles.info}>
          Durée au bout de laquelle l'entretien devra avoir été conduit et signé.
        </span>
      </section>
    </>
  );
};

export default OngoingCampaignForm;
