import { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { DSButton, Modal } from "@skillup/ui";
import type { UserRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";

import { UserModalProps } from "../types";
import styles from "../ConnectionModes.module.scss";
import { plural } from "utils/locale";

const RestoreUsersModal = ({ users, onSubmit, onClose }: UserModalProps) => {
  const [loading, setLoading] = useState(false);

  const { addToast } = useToasts();

  async function handleSubmit() {
    setLoading(true);
    try {
      await buildRequest<UserRoutes.PatchAll>({
        method: "POST",
        path: `/users`,
        payload: {
          users: users.map((user) => ({
            uuid: user.properties.uuid,
            properties: {
              disabled: null,
              deletedAt: null,
            },
          })),
        },
      })();
      addToast("Utilisateur restauré", { appearance: "success" });
      onSubmit();
    } catch (e) {
      setLoading(false);
      addToast("Une erreur est survenue", { appearance: "error" });
    }
  }

  return (
    <Modal className={styles.UserActionModal} title="Restaurer l'accès à Skillup" onClose={onClose}>
      <div className={styles.modalContent}>
        {plural(users.length, "Êtes vous sûr de vouloir réactiver cet utilisateur ?", {
          pluralText: "Êtes vous sûr de vouloir réactiver ces utilisateurs ?",
        })}
      </div>
      <div className={styles.modalButtons}>
        <DSButton emphasis="Mid" label={"Annuler"} loading={loading} onClick={onClose} />
        <DSButton label={"Restaurer l'accès"} loading={loading} onClick={handleSubmit} />
      </div>
    </Modal>
  );
};

export default RestoreUsersModal;
