const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g;

const escapeStringRegexp = (string: string): string => {
  if (typeof string !== "string") {
    throw new TypeError("Expected a string");
  }

  return string.replace(matchOperatorsRegex, "\\$&");
};

export default escapeStringRegexp;
