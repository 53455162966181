import { useHistory } from "react-router-dom";
import { useMemo, Suspense, useState, useEffect } from "react";

import { MaterialIcons } from "@skillup/ui";
import { Loader } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import { JobFromAPI, ProcessedSkill } from "types/skills";

import { Link, SearchInput, SearchResult } from "./SearchBar.styled";
import { JobsProvider, useJobsContext } from "../../../Jobs/JobsContext";
import { SkillsProvider, useSkillsContext } from "../../../Skills/SkillsContext";

type SearchProps = {
  data?: JobFromAPI | ProcessedSkill;
};

const SearchBar = ({ data }: SearchProps) => {
  return (
    <SkillsProvider>
      <JobsProvider>
        <Suspense fallback={<Loader fillSpace />}>
          <Layout data={data} />
        </Suspense>
      </JobsProvider>
    </SkillsProvider>
  );
};

const Layout = ({ data }: SearchProps) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>();

  const { getSkills } = useSkillsContext();
  const { data: skillList, status: skillListStatus } = getSkills();
  const { jobList, jobListLoading } = useJobsContext();

  const jobsToDisplay = useMemo(() => {
    if (jobListLoading) {
      return [];
    }

    return !searchQuery || searchQuery.trim() === ""
      ? jobList.filter((job) => !job.isArchived)
      : jobList.filter(
          (job) =>
            !job.isArchived && `${job.name}`.toLowerCase().includes(searchQuery.toLowerCase())
        );
  }, [jobList, jobListLoading, searchQuery]);

  const skillsToDisplay = useMemo(() => {
    if (skillListStatus === "loading") {
      return [];
    }

    return !searchQuery || searchQuery.trim() === ""
      ? skillList.filter((skill) => !skill.isArchived)
      : skillList.filter(
          (skill) =>
            !skill.isArchived && `${skill.name}`.toLowerCase().includes(searchQuery.toLowerCase())
        );
  }, [skillList, skillListStatus, searchQuery]);

  useEffect(() => {
    if (data) {
      setSearchQuery(data.name);
    }
  }, [data]);

  const hasAtLeastOneResult =
    searchQuery &&
    searchQuery !== data?.name &&
    (jobsToDisplay.length > 0 || skillsToDisplay.length > 0);

  return (
    <>
      <SearchInput
        name="search"
        value={searchQuery}
        actionButton={<MaterialIcons.Search />}
        onChange={setSearchQuery}
        placeholder={t("skills.dashboard.searchJobOrSkill", {
          defaultValue: "Rechercher une fiche de poste ou une compétence",
        })}
      />
      {hasAtLeastOneResult && (
        <SearchResult>
          {jobsToDisplay.length !== 0 && (
            <span>{t("skills.dashboard.jobs", { defaultValue: "Fiches de poste" })}</span>
          )}
          {jobsToDisplay.map((job) => (
            <Link
              key={job.uuid}
              onClick={() => history.push(`/responsable/analyses/fiche-de-poste/${job.uuid}`)}
            >
              {job.name}
            </Link>
          ))}
          {skillsToDisplay.length !== 0 && (
            <span>{t("skills.dashboard.skills", { defaultValue: "Compétences" })}</span>
          )}
          {skillsToDisplay.map((skill) => (
            <Link
              key={skill.uuid}
              onClick={() => history.push(`/responsable/analyses/competence/${skill.uuid}`)}
            >
              {skill.name}
            </Link>
          ))}
        </SearchResult>
      )}
    </>
  );
};

export default SearchBar;
