import { Dispatch, useState } from "react";

import { type Template } from "services/interviews";

import { State as ActionState } from "../hooks";

export enum SidePanelStates {
  Idle,
  Open,
}

export type State =
  | { state: SidePanelStates.Idle; selectedRow: string }
  | {
      state: SidePanelStates.Open;
      template: Template;
      openActionModal: (state: ActionState) => void;
      selectedRow: string;
    };

type TemplatesSidePanelHook = [
  {
    state: State;
  },
  Dispatch<State>
];

const useTemplatesSidePanelActions = (): TemplatesSidePanelHook => {
  const [state, setState] = useState<State>({ state: SidePanelStates.Idle, selectedRow: null });
  return [
    { state },
    (stateChange: State) => {
      return setState(stateChange);
    },
  ];
};

export default useTemplatesSidePanelActions;
