import type { GetUsersByFilterResponse } from "types/api";
import type { GetUsersRouteType } from "types/api";
import {
  ISimpleManager,
  ISimpleSelectionUser,
  ISimpleSelectionUserWithManager,
  ISimpleSelectionGroup,
} from "@skillup/types";
import { useTypedFetch } from "hooks";
import { uniq, uniqBy } from "lodash";
import { useMemo } from "react";
import Acta from "utils/Acta";
import { isPair } from "./add-trainees-context";

export interface UserArea {
  uuid: string;
  name: string;
  slug: string;
  type?: "default";
}

export const prepareList = (
  list: ISimpleSelectionUser[],
  selectedUsersEmails: string[],
  alreadyLinked?: boolean
) => {
  return uniqBy(
    list
      .filter((user) => !selectedUsersEmails.includes(user.email))
      .map((user) => ({ ...user, alreadyLinked })),
    "email"
  );
};
export const prepareGroup = (
  groups: ISimpleSelectionGroup[],
  selectedUsersEmails: string[],
  alreadyLinkedUsers: Record<string, boolean>
) => {
  return groups.map((group) =>
    prepareList(group.users, selectedUsersEmails).map((user) => ({
      ...user,
      alreadyLinked: alreadyLinkedUsers[user.email],
    }))
  );
};

export const buildErrorList = (
  entryList: ISimpleSelectionUser[] | { employee: ISimpleSelectionUser; manager: ISimpleManager }[]
) => {
  let usersWithErrors = [];
  let managersWithErrors = [];
  if (isPair(entryList[0])) {
    const filteredPairs = entryList.filter(
      (entry: { employee: ISimpleSelectionUser; manager: ISimpleManager }) =>
        entry.employee.errors?.length
    );
    usersWithErrors = filteredPairs.map(
      (pair: { employee: ISimpleSelectionUser; manager: ISimpleManager }) => pair.employee
    );
    managersWithErrors = entryList.filter(
      (entry: { employee: ISimpleSelectionUser; manager: ISimpleManager }) =>
        entry.manager?.errors?.length
    );
    managersWithErrors = managersWithErrors.map(
      (pair: { employee: ISimpleSelectionUser; manager: ISimpleManager }) => pair.manager
    );
  } else {
    usersWithErrors = entryList.filter((user: ISimpleSelectionUser) => user.errors?.length);
  }
  // Build errors array
  const notFoundUsers = getErrorUsersMail(usersWithErrors, "not-found");
  const noManagerUsers = getErrorUsersMail(usersWithErrors, "no-manager");
  const duplicateUsers = getErrorUsersMail(usersWithErrors, "duplicate");
  const invalideJoinDateUsers = getErrorUsersMail(usersWithErrors, "missing-join-date");
  const notFoundManagers = getErrorUsersMail(managersWithErrors, "not-found");

  // Build errors string
  const notFoundString = getErrorStringFromArray(notFoundUsers, "Utilisateurs non trouvés");
  const noManagerString = getErrorStringFromArray(noManagerUsers, "Utilisateurs sans manager");
  const notFoundManagersString = getErrorStringFromArray(notFoundManagers, "Managers non trouvés");
  const duplicateString = getErrorStringFromArray(duplicateUsers, "Doublons");
  const invalideJoinDateString = getErrorStringFromArray(
    invalideJoinDateUsers,
    "Erreurs de dates d'embauche"
  );
  // Push all errors in result array
  let result = [];
  result = pushInArrayIfNotEmpty(result, notFoundString);
  result = pushInArrayIfNotEmpty(result, noManagerString);
  result = pushInArrayIfNotEmpty(result, notFoundManagersString);
  result = pushInArrayIfNotEmpty(result, duplicateString);
  result = pushInArrayIfNotEmpty(result, invalideJoinDateString);

  return result;
};

const pushInArrayIfNotEmpty = (result: string[], errorString: string): string[] => {
  if (errorString) {
    result.push(errorString);
  }

  return result;
};
export const getErrorStringFromArray = (array: string[], head: string) => {
  return (array.length && "".concat(`${head}: `, uniq(array).join(", "))) || "";
};

export const getErrorUsersMail = (usersWithErrors, error: string) => {
  return usersWithErrors.filter((user) => user.errors?.includes(error)).map((user) => user.email);
};

export const groupUsersByAreas = (
  users: GetUsersByFilterResponse,
  possibleUserAreas: UserArea[]
): ISimpleSelectionGroup[] | null => {
  if (!users?.length) return null;
  if (!possibleUserAreas?.length) return null;
  const groupsByArea: Record<string, ISimpleSelectionGroup> = {};
  groupsByArea["all"] = {
    label: "Tous les périmètres",
    users: [],
  };
  for (const area of possibleUserAreas) {
    groupsByArea[area.uuid] = {
      label: area.name,
      users: [],
    };
  }

  const userWithManager = (user: GetUsersByFilterResponse[0]): ISimpleSelectionUserWithManager => ({
    ...user.properties,
    errors: user.errors,
    managers: user.managers.map((manager) => ({
      ...manager,
      email: manager.email ?? "",
      linkedTo: user.properties.email,
    })),
  });
  for (const user of users) {
    groupsByArea["all"].users.push(userWithManager(user));
    for (const area of user.areas) {
      if (groupsByArea[area.uuid]) groupsByArea[area.uuid].users.push(userWithManager(user));
    }
  }
  return Object.values(groupsByArea);
};
export const useAreaUserGroups = (query?: GetUsersRouteType["query"]) => {
  const { data } = useTypedFetch<GetUsersRouteType>({
    method: "GET",
    path: "/user",
    query,
  });
  const users = data as GetUsersByFilterResponse;
  const possibleUserAreas: UserArea[] =
    Acta.getState("userData")?.activeCompany?.accessControlList?.userAreas;
  const groups = useMemo(() => {
    return groupUsersByAreas(users, possibleUserAreas);
  }, [users, possibleUserAreas]);

  return groups;
};

export const not =
  (func) =>
  (...args) =>
    !func(...args);
