import styled from "styled-components";
import { DSColor, SkuiSpacing } from "@skillup/ui";

export const RadarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${DSColor["surface-light"]};
  border: 1px solid ${DSColor["border-onLight"]};
  border-radius: ${SkuiSpacing.xxs};
  margin-bottom: ${SkuiSpacing.xs};
  height: 420px;
  width: 100%;
  padding: ${SkuiSpacing.s};
`;
