import { ActionRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type Action = ActionRoutes.GetActionsRoute["response"][number];

export async function getActions() {
  const result = await buildRequest<ActionRoutes.GetActionsRoute>({
    method: "GET",
    path: `/actions`,
    target: "PEOPLE_REVIEW",
  })();

  return result;
}
