import { DSButton, Modal } from "@skillup/ui";
import styles from "./DocumentDeleteConfirmModal.module.scss";

type Props = {
  documentName: string;
  deleting: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DocumentDeleteConfirmModal = ({ documentName, deleting, onClose, onConfirm }: Props) => {
  return (
    <Modal title={"Supprimer le document"} onClose={onClose} disableOnClickAway>
      <div className={styles.deleteModal}>
        <div className={styles.label}>
          Êtes-vous sûr de vouloir supprimer le document “{documentName}” ?
        </div>
        <div className={styles.buttonsWrapper}>
          <DSButton className={styles.button} emphasis="Low" label="Annuler" onClick={onClose} />
          <DSButton
            className={styles.button}
            loading={deleting}
            onClick={onConfirm}
            emphasis="High"
            label="Confirmer"
          />
        </div>
      </div>
    </Modal>
  );
};

export default DocumentDeleteConfirmModal;
