import {
  CampaignsRoutes,
  DashboardChildType,
  InterviewActiveAttendeeRoles,
} from "@skillup/espace-rh-bridge";
import { useRouteQuery } from "hooks";
import { useDashboardData } from "../context";

type DashboardChildRoute = CampaignsRoutes.Dashboard.GetChild;
export type DashboardChildPayload<T extends DashboardChildType> =
  CampaignsRoutes.Dashboard.GetChild[T]["response"];

export type { InterviewActiveAttendeeRoles };

function useDashboardChild<T extends DashboardChildType>(
  type: T,
  uuid: string,
  options?: {
    attendeeRole?: InterviewActiveAttendeeRoles;
    enabled?: boolean;
  }
) {
  const { userAreas } = useDashboardData();

  const { data: dashboardPayload, ...res } = useRouteQuery<DashboardChildRoute[T]>(
    ["dashboard-child", uuid, options?.attendeeRole, userAreas],
    {
      method: "GET",
      path: `/interviews/dashboard/child/${type}/{uuid}` as any,
      params: {
        uuid,
      },
      query: {
        role: options?.attendeeRole,
        areas: userAreas?.join(","),
      },
    } as DashboardChildRoute[T],
    {
      enabled: options?.enabled,
      keepPreviousData: true,
    }
  );

  return { dashboardPayload, ...res };
}

export default useDashboardChild;
