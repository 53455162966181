import { useState } from "react";

import { ListUtils } from "@skillup/shared-utils";
import { UseFilterProp, useUrlSyncedFilters } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";

export const useSkillsGridFilters = (t: TranslationType) => {
  const [{ conf, initialValues }, setFilterConfig] = useState({
    conf: {
      categoryLabel: {
        type: ListUtils.FilterType.TEXT,
        label: t("skills.list.tableColumns.category", { defaultValue: "Catégorie" }),
      },
      jobsCount: {
        type: ListUtils.FilterType.TEXT,
        label: t("skills.list.tableColumns.jobsCount", { defaultValue: "Fiches de poste liées" }),
      },
      name: {
        type: ListUtils.FilterType.TEXT,
        label: t("skills.list.tableColumns.skill", { defaultValue: "Compétence" }),
      },
    },

    initialValues: {
      categoryLabel: {
        label: t("skills.list.tableColumns.category", { defaultValue: "Catégorie" }),
        placeholder: t("skills.list.placeholder.category", {
          defaultValue: "Filtrer par catégorie",
        }),
      },
      jobsCount: {
        label: t("skills.list.tableColumns.jobsCount", { defaultValue: "Fiches de poste liées" }),
        placeholder: t("skills.list.placeholder.jobsCount", {
          defaultValue: "Filtrer par fiches de poste liées",
        }),
      },
      name: {
        label: t("skills.list.tableColumns.skill", { defaultValue: "Compétence" }),
        placeholder: t("skills.list.placeholder.name", {
          defaultValue: "Rechercher une compétence",
        }),
        visibilityMode: "always",
      },
    } as UseFilterProp<typeof conf>,
  });

  const [filters, filterValues, setFilterValues] = useUrlSyncedFilters(conf, initialValues);

  return [conf, filters, filterValues, setFilterValues, setFilterConfig] as [
    typeof conf,
    typeof filters,
    typeof filterValues,
    typeof setFilterValues,
    typeof setFilterConfig
  ];
};
