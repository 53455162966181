import React from "react";

import { useTheme } from "context";
import Highcharts from "highcharts/highcharts";
import exporting from "highcharts/modules/exporting";
import exportdata from "highcharts/modules/export-data";
import { Themes, DSColor, FontFamily, EspaceFont } from "commons";
import HighchartsReact, { type HighchartsReactRefObject } from "highcharts-react-official";

exporting(Highcharts);
exportdata(Highcharts);

interface HistogramProps {
  tooltipNameValue: string;
  tooltipNameMoreValue?: string;
  tooltipNameComparedValue?: string;
  chartRef?: React.MutableRefObject<null | HighchartsReactRefObject>;
  values: Array<{
    name: string;
    value: number;
    pathUrl?: string;
    moreValue?: number;
    comparedValue?: number;
  }>;
}

interface ExtendedHighchartOptions extends Highcharts.Options {
  urlPath: Record<string, string | undefined>;
}

const Histogram = ({
  chartRef,
  tooltipNameComparedValue,
  tooltipNameMoreValue,
  tooltipNameValue,
  values,
}: HistogramProps) => {
  const theme = useTheme();
  const isRHTheme = theme === Themes.ESPACE_RH;

  const fontFamily = isRHTheme ? FontFamily.rh : FontFamily.collab;
  const espaceFont = isRHTheme ? EspaceFont.rh : EspaceFont.collab;

  const urlPath = values.reduce(
    (acc, value) => {
      acc[value.name] = value.pathUrl;
      return acc;
    },
    {} as Record<string, string | undefined>
  );

  const options: ExtendedHighchartOptions = {
    chart: {
      type: "column",
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        grouping: false,
      },
      series: {
        borderWidth: 0,
      },
    },
    series: [
      {
        type: "column",
        data: values.map((val) => {
          return {
            color: DSColor["greyscale-medium"],
            name: val.name,
            ownURL: val.pathUrl,
            y: val.comparedValue,
          };
        }),
        dataLabels: {
          style: {
            color: DSColor["plainText-onLight-default"],
            fontFamily: fontFamily,
            fontSize: espaceFont.captionRegular.fontSize,
          },
        },
        linkedTo: "main",
        name: tooltipNameComparedValue,
        pointPlacement: -0.15,
      },
      {
        id: "main",
        type: "column",
        data: values.map((val) => {
          return {
            color:
              val.comparedValue && val.value >= val.comparedValue
                ? DSColor["decoration-blue-base"]
                : DSColor["decoration-blue-lighter"],
            moreValue: val.moreValue && `${tooltipNameMoreValue}: <b>${val.moreValue}</b>`,
            name: val.name,
            y: val.value,
          };
        }),
        dataLabels: {
          style: {
            color: DSColor["plainText-onLight-default"],
            fontFamily: fontFamily,
            fontSize: espaceFont.captionRegular.fontSize,
          },
        },
        name: tooltipNameValue,
      },
    ],
    title: {
      text: "",
    },
    tooltip: {
      headerFormat: "<b>{point.key}</b><br>",
      pointFormat: "{series.name}: <b>{point.y}</b><br/> {point.moreValue}",
      shared: true,
    },
    urlPath,
    xAxis: {
      type: "category",
      accessibility: {
        description: tooltipNameValue,
      },
      labels: {
        formatter: function () {
          return `<a href="${urlPath[this.value]}" style="color:#1A304D; font-size:12px;">${this.value}</a>`;
        },
        style: {
          textAlign: "center",
        },
        useHTML: true,
      },
      minRange: 1,
    },
    yAxis: [
      {
        max: values.reduce((acc, val) => Math.max(acc, val.value, val.comparedValue || 0), 0),
        showFirstLabel: false,
        title: {
          text: "",
        },
      },
    ],
  };

  return <HighchartsReact ref={chartRef} options={options} highcharts={Highcharts} />;
};

export default Histogram;
