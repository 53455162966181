import { DSButton, Modal } from "@skillup/ui";

import styles from "./TrackingUuidNotFound.module.scss";

interface TrackingUuidNotFoundProps {
  readonly trackingUuid: string;
  readonly onClose: () => void;
}

function TrackingUuidNotFound({ onClose, trackingUuid }: TrackingUuidNotFoundProps) {
  return (
    <Modal size="big" title="Ligne de suivi non trouvée" onClose={onClose} disableOnClickAway>
      <div className={styles.trackingUuidNotFound}>
        <div className={styles.warningText}>
          <p>La ligne de suivi n'a pas été trouvée. Essayez de rafraîchir la page.</p>
        </div>

        <div className={styles.actions}>
          <DSButton label="Fermer" emphasis="High" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
}

export default TrackingUuidNotFound;
