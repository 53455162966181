import { useMemo } from "react";

import { AuthStrategy, AuthStrategyType } from "@skillup/espace-rh-bridge";

export type TableRow = ReturnType<typeof parseAuthStrategyIntoRow>;

const useTableData = (
  authStrategies: Array<AuthStrategy>,
  authLoginUrl: string
): {
  data: Array<TableRow>;
  columns: Array<{ key: string; title: string }>;
} => {
  const tableData = useMemo(
    () => ({
      columns: generateTableColumns(),
      data: generateTableRows(authStrategies ?? [], authLoginUrl),
    }),
    [authStrategies, authLoginUrl]
  );

  return tableData;
};

export default useTableData;

function generateTableRows(authStrategies: Array<AuthStrategy>, authLoginUrl: string) {
  return authStrategies?.map((auth) => parseAuthStrategyIntoRow(auth, authLoginUrl));
}

function generateTableColumns() {
  const columns = [
    {
      key: "name",
      title: "Nom",
      width: 8,
    },
    {
      key: "type",
      title: "Type",
      width: 6,
    },
    {
      key: "authProvider",
      title: "Auth Provider",
      width: 10,
    },
    {
      key: "isDefaultStrategy",
      title: "Stratégie user par défaut",
      width: 13,
    },
    {
      key: "isMandatoryIfUnknownStrategy",
      title: "Stratégie orga par défaut",
      width: 13,
    },
    {
      key: "link",
      title: "Lien de connexion à Skillup avec cette strategy",
    },
  ];
  return columns;
}

function parseAuthStrategyIntoRow(authStrategy: AuthStrategy, authLoginUrl) {
  const result = {
    id: authStrategy.uuid,
    data: {
      name: authStrategy.name,
      type: authStrategy.type,
      authProvider:
        authStrategy.type === AuthStrategyType.SAML ? authStrategy.authProvider : undefined,
      isDefaultStrategy: authStrategy.isDefaultStrategy ? "Oui" : "",
      isMandatoryIfUnknownStrategy: authStrategy.isMandatoryIfUnknownStrategy ? "Oui" : "",
      link: `${authLoginUrl}&strategy=${authStrategy.name}`,
      actions: [
        {
          type: "modify",
          label: "Modifier la stratégie de connexion",
        },
        {
          type: "delete",
          label: "Supprimer la stratégie de connexion",
        },
      ],
    },
  };
  if (!authStrategy.isDefaultStrategy) {
    result.data.actions.push({
      type: "default-strategy",
      label: "Mettre en stratégie par default",
    });
  }

  if (!authStrategy.isMandatoryIfUnknownStrategy) {
    result.data.actions.push({
      type: "mandatory-unknown",
      label: "Mettre en stratégie orga par default",
    });
  } else {
    result.data.actions.push({
      type: "delete-mandatory-unknown",
      label: "Supprimer la stratégie orga par default",
    });
  }

  if (authStrategy.type === AuthStrategyType.SAML) {
    result.data.actions.push({
      type: "display-connection-info",
      label: "Afficher les informations de connexion",
    });
  }

  return result;
}
