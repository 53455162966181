import { plural } from "utils/locale";

import { ClosedQuestionResponse } from "@skillup/espace-rh-bridge";

import { user } from "../ClosedEndedQuestion";
import styles from "./UnresponsiveUsersBanner.module.scss";

function getUnresponsiveUsersCount(allUsers: user[], dashboardPayload: ClosedQuestionResponse) {
  return (
    allUsers.length -
    dashboardPayload.answers.reduce((acc, answer) => {
      return acc + answer.replies.count;
    }, 0)
  );
}

export const UnresponsiveUsersBanner = ({
  users,
  dashboardPayload,
}: {
  users: user[];
  dashboardPayload: ClosedQuestionResponse;
}) => {
  const unresponsiveUsersCount = getUnresponsiveUsersCount(users, dashboardPayload);

  return (
    unresponsiveUsersCount > 0 && (
      <div className={styles.infos}>
        <p>La réponse à cette question n'est pas obligatoire.</p>
        <div className={styles.users}>
          <p>
            {plural(unresponsiveUsersCount, "%n collaborateur n'a pas répondu", {
              pluralText: "%n collaborateurs n'ont pas répondu",
            })}{" "}
          </p>
        </div>
      </div>
    )
  );
};
