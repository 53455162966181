import { TargetHistory, TargetLog } from "@skillup/espace-rh-bridge";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

function getObjectDiff(obj1: any, obj2: any) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

export default function UpdateLogDescription({ log }: { log: TargetLog }) {
  const { t } = useTranslation();

  const history = log.data as { before: TargetHistory; after: TargetHistory };
  const updatedFields = getObjectDiff(history.before, history.after);

  if (updatedFields.length === 0) {
    return null;
  }
  return (
    <div>
      {t(`interview.targets.activity.update.fields.label`, {
        defaultValue: "Données modifiées",
      })}{" "}
      :{" "}
      {updatedFields
        .map((field) =>
          t(`interview.targets.new.${field}.label`, {
            defaultValue: field,
          }).toLocaleLowerCase()
        )
        .join(", ")}{" "}
    </div>
  );
}
