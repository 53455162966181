import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Label as DSLabel, RadioBox, Flex } from "@skillup/ui";

import { type Child, selectHighlightUuid } from "../../../../reducer";

import styles from "./Title.module.scss";
import { BuilderFormInput } from "../../BuilderFormInput";

type Title = Extract<Child, { kind: "title" }>;

export function Title({ child, updateTemplate }: { child: Title; updateTemplate: (data: Title) => void }) {
  const highlightUuid = useSelector(selectHighlightUuid);
  const changeTitle = useCallback(
    (value: string) => {
      updateTemplate({ ...child, title: value });
    },
    [updateTemplate, child]
  );

  const multipleAssistiveTexts = useMemo(() => {
    return {
      default: (
        <>
          Ce titre sera affiché dans le corps de l'entretien mais ne sera pas repris dans la table
          des matières.
        </>
      ),
      error: "Ce champ doit être rempli.",
    };
  }, []);

  const changeVariant = useCallback(
    (value: "H1" | "H2") => {
      updateTemplate({ ...child, variant: value });
    },
    [updateTemplate, child]
  );

  return (
    <div className={styles.titleChild}>
      <DSLabel label="Style de titre" required />
      <Flex row className={styles.titleChild__variants}>
        <RadioBox
          className={styles.titleChild__variantsRadio}
          label="H1"
          value="H1"
          checked={child.variant === "H1"}
          onClick={() => changeVariant("H1")}
        />
        <RadioBox
          className={styles.titleChild__variantsRadio}
          label="H2"
          value="H2"
          checked={child.variant === "H2"}
          onClick={() => changeVariant("H2")}
        />
      </Flex>
      <BuilderFormInput
        type="text"
        value={child.title}
        onChange={changeTitle}
        debounceValue={300}
        label="Texte à afficher"
        name={`title-child-${child.uuid}`}
        required
        placeholder="Saisissez le texte à afficher"
        multipleAssistiveTexts={multipleAssistiveTexts}
        autoFocus={highlightUuid === child.uuid}
      />
    </div>
  );
}
