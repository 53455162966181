import { InterviewState } from "@skillup/espace-rh-bridge";
interface IColorKeyValue {
  color: string;
  key: string;
  value: string;
  shortValue: string;
}

const interviewStatusKeyToValue = (key?: InterviewState): IColorKeyValue => {
  switch (key) {
    case "signed":
      return {
        color: "green",
        key: "signed",
        value: "Entretien signé",
        shortValue: "Signé",
      };
    case "shared":
      return {
        color: "blue",
        key: "shared",
        value: "Préparation partagée",
        shortValue: "Partagé",
      };
    case "pending":
      return {
        color: "grey",
        key: "pending",
        value: "Préparation non partagée",
        shortValue: "À préparer",
      };
    case "unsigned":
      return {
        color: "red",
        key: "unsigned",
        value: "Entretien non signé",
        shortValue: "Non signé",
      };
    default:
      return {
        color: "red",
        key: "404",
        value: "Not found status",
        shortValue: "Not found",
      };
  }
};

export default interviewStatusKeyToValue;
