import { DSButton, SkuiSpacing } from "@skillup/ui";
import styled from "styled-components";

export const MultipleArchiveButton = styled(DSButton)`
  margin-bottom: ${SkuiSpacing.s};
`;

export const EllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
