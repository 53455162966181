import moment, { Moment } from "moment";

const computeForDisplay = (intervals: string[][]) => {
  // Now array of array of strings: [[jeudi machin, vendredi truc], [lundi blabla]]
  // Also support hours: [[jeudi 14h, jeudi 18h], [vendredi 9h, vendredi 12h]]
  return intervals.map((interval) => {
    const intervalInfos = parseDateRange(interval, {
      dateFormat: "DD/MM/YYYY",
      hourFormat: "H:mm",
    });
    const { days, hours } = intervalInfos;
    const dateInfos = days[1] ? `Du ${days[0]} au ${days[1]}` : `Le ${days[0]}`;
    const hourInfos = hours ? ` de ${hours[0]} à ${hours[1]}` : " (toute la journée)";
    return dateInfos + hourInfos;
  });
};

interface DateRangeInfos {
  days: string[];
  hours?: string[];
}
function parseDateRange(
  interval: string[],
  { dateFormat = "YYYY-MM-DD", hourFormat = "HH:mm" } = {}
): DateRangeInfos {
  const moments = interval.map((date) => moment(date));
  let [startDate, endDate] = moments.map((date) => date.format(dateFormat));
  const days = !endDate || startDate === endDate ? [startDate] : [startDate, endDate];
  if (!endDate) return { days };
  const hours = moments.map((date) => date.format(hourFormat));
  if (hours[0] === hours[1]) return { days };
  return { days, hours };
}

interface CampaignOptions {
  duration: number;
  start: number;
}

type TFormat = "timestamp" | string;

const getLegacyCampaignDates = (
  { duration, start }: CampaignOptions,
  format: TFormat = "DD/MM/YYYY"
) => {
  const endDate = moment(start).add(duration, "days");

  if (format === "timestamp") {
    return {
      start: start.valueOf(),
      endDate: endDate.valueOf(),
    } as { start: number; endDate: number };
  }

  return {
    start: moment(start).format(format),
    endDate: endDate.format(format),
    format,
  } as { start: string; endDate: string };
};

const formatDates = (dates: string[], format?: string) => {
  if (format === "timestamp") {
    return dates.map((date) => moment(date).valueOf());
  }
  return dates.map((date) => moment(date).format(format ?? "DD/MM/YYYY"));
};

const getOngoingStartDate = (joinDate: number, start: number): Moment => {
  const today = moment().startOf("day");

  if (!joinDate) {
    return today;
  }

  if (start < 0) {
    throw new Error("start parameter should be a positive number");
  }

  if (!start) {
    // this works for start === 0, by the way
    return moment(joinDate).startOf("day");
  }

  const startDate = moment(joinDate).startOf("day").add(start, "days");

  if (startDate.isBefore(today)) {
    return getOngoingStartDate(startDate.valueOf(), start); // adding again until in future
  }

  return startDate;
};

export {
  computeForDisplay,
  parseDateRange,
  getLegacyCampaignDates,
  getOngoingStartDate,
  formatDates,
};

export default {
  computeForDisplay,
  parseDateRange,
  getLegacyCampaignDates,
  getOngoingStartDate,
  formatDates,
};
