import { PromisePool } from "@skillup/promise-pool";
import { Either } from "./either";

export type Future<L, R> = Promise<Either<L, R>>;

type ExtractLeft<T extends Array<() => Future<any, any>>> = T[number] extends () => Future<
  infer Left,
  any
>
  ? Left
  : never;

type ExtractRight<T extends Array<() => Future<any, any>>> = {
  [P in keyof T]: T[P] extends () => Future<any, infer Right> ? Right : never;
};

export namespace Future {
  export function biMap<L, R, VL, VR>(
    f: Future<L, R>,
    leftMapFn: (val: L) => VL,
    mapFn: (val: R) => VR
  ): Future<VL, VR> {
    return f.then((either) => either.map(mapFn).leftMap(leftMapFn));
  }

  export function map<L, R, V>(f: Future<L, R>, fn: (val: R) => V): Future<L, V> {
    return f.then(async (value) => {
      if (value.isRight()) {
        const res = await Promise.resolve(fn(value.right()));
        return Either.Right(res)
      } else {
        return Either.Left(value.left());
      }
    });
  }

  export function flatMap<L, R, VL, VR>(
    f: Future<L, R>,
    fn: (val: R) => Future<VL, VR>
  ): Future<L | VL, VR> {
    return f.then(async (value) => {
      if (value.isLeft()) {
        return value;
      }

      return fn(value.right());
    });
  }

  export function leftMap<L, R, V>(f: Future<L, R>, fn: (val: L) => V): Future<V, R> {
    return f.then((value) => value.leftMap(fn));
  }

  export async function takeRightOrCatch<L, R>(
    f: Future<L, R>,
    leftErr: (err: L) => R,
    catchErr?: (err: any) => R
  ): Promise<R> {
    const value = await f.catch(catchErr && ((err) => Either.Right(catchErr(err))));
    if (value.isRight()) return value.right();
    return leftErr(value.left());
  }

  export function takeLeft<L, R>(f: Future<L, R>) {
    return f.then((value) => value.left());
  }
  export function takeRight<L, R>(f: Future<L, R>) {
    return f.then((value) => value.right());
  }

  export async function unwrap<Result, L, R>(
    f: Future<L, R>,
    Errorfn: (val: L) => void,
    SuccessFn: (val: R) => Result
  ): Promise<Result | Error> {
    return f.then((value) =>
      value.fold(
        (err) => {
          Errorfn(err);
          return new Error();
        },
        (val) => SuccessFn(val)
      )
    );
  }

  export function All<T extends [() => Future<any, any>] | Array<() => Future<any, any>>>(
    arrayOfFutures: T,
    concurency?: number
  ): Future<Array<ExtractLeft<T>>, ExtractRight<T>> {
    return PromisePool.All(arrayOfFutures, concurency).then((eithers: any) => {
      return Either.All(eithers) as any;
    });
  }
}
