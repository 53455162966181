import { createMachine, assign } from "xstate";

import { ProcessedSkill } from "types/skills";

type Context = {
  skill?: ProcessedSkill;
  skills?: ProcessedSkill[];
};

type TypeState =
  | { value: "init"; context: Context }
  | { value: "setExpectedLevel"; context: Context }
  | { value: "setExpectedLevels"; context: Context };

type Event =
  | { type: "SET_INITIAL" }
  | { type: "SET_SKILL"; payload: { skill: ProcessedSkill } }
  | { type: "SET_SKILLS"; payload: { skills: ProcessedSkill[] } }
  | { type: "ABORT" }
  | { type: "BACK" };

export const skillMachine = createMachine<Context, Event, TypeState>(
  {
    id: "jobSkill",
    initial: "init",
    states: {
      init: {
        on: {
          SET_SKILL: {
            target: "setExpectedLevel",
            actions: ["setSkill"],
          },
          SET_SKILLS: {
            target: "setExpectedLevels",
            actions: ["setSkills"],
          },
        },
      },
      setExpectedLevel: {
        on: {
          BACK: {
            target: "init",
          },
          ABORT: {
            target: "init",
          },
        },
      },
      setExpectedLevels: {
        on: {
          BACK: {
            target: "init",
          },
          ABORT: {
            target: "init",
          },
        },
      },
    },
    context: {},
  },
  {
    actions: {
      setSkill: assign({
        skill: (_context, event) => {
          if (event.type === "SET_SKILL") {
            return event.payload.skill;
          }
          return undefined;
        },
      }),
      setSkills: assign({
        skills: (_context, event) => {
          if (event.type === "SET_SKILLS") {
            return event.payload.skills;
          }
          return undefined;
        },
      }),
    },
  }
);
