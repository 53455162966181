import React, { useState, useEffect } from "react";

import { ConfigurationRoutes } from "@skillup/espace-rh-bridge";
import { Button } from "@skillup/ui";

import { useTypedFetch } from "hooks";
import User from "utils/User";
import { ScheduleSelector, DragAndDropFieldsLayout } from "../components";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

import styles from "./ScheduleTabsConfiguration.module.scss";

const mapping = {
  // recueil
  "collection-all": "Recueil - Tout",
  "collection-denied": "Recueil - Refusé",
  "collection-validating": "Recueil - En attente de validation manager",
  "collection-requalifying": "Recueil - À requalifier",
  "collection-pending": "Recueil - À valider RH",
  // plan
  "plan-approved": "Plan - À inscrire",
  "plan-positioning": "Plan - Choix de session en cours",
  "plan-processing": "Plan - Inscrit",
  "plan-attended": "Plan - Réalisé",
  "plan-denied": "Plan - Annulé",
  "plan-all": "Plan - Tout",
};

type Schedule = ConfigurationRoutes.GetConfiguration["response"]["schedules"][0];

type Field = Schedule["tabs"]["collection"][0]["fields"];

interface ITabs {
  [x: string]: Field;
}

export default function ScheduleTabsConfiguration() {
  const user = User.getUserData();
  const { data } = useTypedFetch<ConfigurationRoutes.GetConfiguration>({
    method: "GET",
    path: "/configuration/{companyUuid}",
    params: { companyUuid: user.activeCompany.uuid },
    query: {},
  });

  const [selectedSchedule, selectSchedule] = useState<Schedule>();
  const tabKeys = [
    "collection-validating",
    "collection-requalifying",
    "collection-pending",
    "collection-denied",
    "collection-all",
    "plan-approved",
    "plan-positioning",
    "plan-processing",
    "plan-attended",
    "plan-denied",
    "plan-all",
  ];
  const initialTabsConfig: ITabs = tabKeys.reduce((acc, key) => ({ ...acc, [key]: [] }), {});
  const [tabs, setTabs] = useState(initialTabsConfig);
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  useEffect(() => {
    setTabs(
      [
        ...(selectedSchedule?.tabs?.collection ?? []),
        ...(selectedSchedule?.tabs?.plan ?? []),
      ].reduce(
        (acc, tab) => ({
          ...acc,
          [tab.view]: tab.fields,
        }),
        {}
      )
    );
  }, [selectedSchedule]);

  const submitConfiguration = async () => {
    if (hasChanged) {
      try {
        const configuration = Object.entries(tabs).map(([tabName, fields]) => ({
          view: tabName,
          fields: fields.map((field, index) => ({
            index,
            binding: field.binding,
            width: field.width,
            format: field.format,
          })),
        }));
        await DataLayer.request({
          method: "POST",
          url: `/v1/configuration/tabs/${selectedSchedule.uuid}`,
          body: JSON.stringify(configuration),
        });
        Acta.dispatchEvent("sendAppMessage", {
          message: "Modifications enregistrées avec succès",
          type: "success",
        });
        setHasChanged(false);
      } catch (err) {
        Acta.dispatchEvent("sendAppMessage", {
          message: "Echec des modifications.",
          type: "error",
        });
      }
    } else {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Aucun changement à enregistrer",
        type: "message",
      });
    }
  };

  return (
    <div className={styles.scheduleTabsConfiguration}>
      <ScheduleSelector
        schedules={data?.schedules}
        setSchedule={selectSchedule}
        hasChanged={hasChanged}
        selectedSchedule={selectedSchedule}
      >
        <Button label="Enregistrer" onClick={submitConfiguration} />
      </ScheduleSelector>
      <div className={styles.configuration}>
        {!selectedSchedule && (
          <>
            <h3>Veuillez sélectionner un plan à configurer</h3>
          </>
        )}

        {selectedSchedule && (
          <DragAndDropFieldsLayout
            tabs={tabs}
            setTabs={setTabs}
            tabOrder={tabKeys}
            setHasChanged={setHasChanged}
            labels={mapping}
          />
        )}
      </div>
    </div>
  );
}
