import { ProjectRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

type SummonProjectV2 = SummonProjectWithoutSkillupV2 | SummonProjectSummonedBySkillupV2;

type SummonProjectV2DefaultValue = {
  sessionUuid: string;
  rows: Array<string>;
  dateIntervals: { dateInterval: string[]; hours: string[] }[];
};

type SummonProjectSummonedBySkillupV2 = SummonProjectV2DefaultValue & {
  attachments?: Array<string>;
  content: string;
  subject: string;
  sendCopyToHR: boolean;
  sendCopyToManagers: boolean;
  sendCalendarInvitations: boolean;
  sendCalendarTrainers: boolean;
  isSummonedBySkillup: true;
};

type SummonProjectWithoutSkillupV2 = SummonProjectV2DefaultValue;

async function summonProjectV2(params: SummonProjectV2) {
  try {
    if ("isSummonedBySkillup" in params && params.isSummonedBySkillup === true) {
      const data = await buildRequest<ProjectRoutes.SummonProject>({
        method: "POST",
        path: "/projects/{sessionUuid}/summon/v2",
        params: { sessionUuid: params.sessionUuid },
        payload: {
          attachments: params.attachments,
          content: params.content,
          subject: params.subject,
          sendCopyToHR: params.sendCopyToHR,
          sendCopyToManagers: params.sendCopyToManagers,
          sendCalendarInvitations: params.sendCalendarInvitations,
          sendCalendarTrainers: params.sendCalendarTrainers,
          rows: params.rows,
          dateIntervals: params.dateIntervals,
        },
      })();
      return data;
    }

    const data = await buildRequest<ProjectRoutes.SummonProjectWithoutSkillupV2>({
      method: "POST",
      path: "/projects/{sessionUuid}/summonwithoutskillup/v2",
      params: { sessionUuid: params.sessionUuid },
      payload: {
        rows: params.rows,
      },
    })();
    return data;
  } catch (err) {
    throw new Error(err);
  }
}

export { summonProjectV2, type SummonProjectV2 };
