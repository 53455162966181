import { useMemo } from "react";
import Select from "react-select";
import React, { useState, useEffect } from "react";

import cx from "classnames";
import { orderBy } from "lodash";
import { useRecoilValue } from "recoil";

import { Flex } from "@skillup/ui";

// Utils
import Acta from "utils/Acta";
import type { Skill } from "containers/Supervisor/routes/Skills/Legacy/state/skills";
// Recoil State
import { skillsAtom } from "containers/Supervisor/routes/Skills/Legacy/state/skills";

import RequiredSkill from "../RequiredSkill";
import { showRequiredSkillModal } from "../RequiredSkillModal/RequiredSkillModal";

// Styling
import styles from "./SkillSearch.module.scss";

// Types
export type SkillWithExpectation = Skill & { expectedLevel?: number };

export interface SkillSearchProps {
  readonly label: string;
  readonly className?: string;
  readonly initialData?: { uuid: string; expectedLevel?: number }[];
  readonly onSkillChange: (newSkills: SkillWithExpectation[]) => void;
}

const SkillSearch = ({ className, initialData, label, onSkillChange }: SkillSearchProps) => {
  const companySkills: Skill[] = useRecoilValue(skillsAtom);

  const [requiredSkills, setRequiredSkills] = useState<SkillWithExpectation[]>(
    // @ts-ignore
    orderBy(
      initialData?.map((requiredSkill) => {
        const skillData = companySkills.find(({ skill }) => skill.uuid === requiredSkill.uuid);
        return { ...skillData, expectedLevel: requiredSkill.expectedLevel };
      }) ?? [],
      ["skill.label"],
      ["asc"]
    ).filter((skill) => skill != null)
  );

  const addSkill = (newSkill: SkillWithExpectation) =>
    setRequiredSkills([...requiredSkills, newSkill]);
  const setSkillExpectationLevel = (newLevel: number, skillUuid: string) => {
    const updatedSkills = requiredSkills.map((skillData) => {
      if (skillData.skill.uuid === skillUuid) {
        return { ...skillData, expectedLevel: newLevel };
      }
      return skillData;
    });
    setRequiredSkills(updatedSkills);
  };

  const removeSkill = (skillUuid: string) =>
    setRequiredSkills(requiredSkills.filter(({ skill }) => skill.uuid !== skillUuid));

  useEffect(() => {
    onSkillChange(requiredSkills);
  }, [requiredSkills, onSkillChange]);

  const skillOptions = useMemo(() => {
    let alreadySelectedSkills: Record<string, boolean> = {};
    for (const requiredSkill of requiredSkills)
      alreadySelectedSkills[requiredSkill.skill.uuid] = true;
    return companySkills
      .filter((skill) => !alreadySelectedSkills[skill.skill.uuid])
      .map((skill) => ({
        data: skill,
        label: skill.skill.label,
        value: skill.skill.label,
      }));
  }, [companySkills, requiredSkills]);

  return (
    <div aria-label="compétences-requises" className={cx(styles.SkillSearch, className)}>
      <h4 className={styles.label}>{label}</h4>
      <Flex className={styles.searchInputContainer}>
        <Select
          value={null} // Clear text input between 2 searches
          options={skillOptions}
          className={styles.searchInput}
          placeholder="Rechercher une compétence à assigner"
          onChange={({ data: selectedSkillData }: { data: Skill }) => {
            showRequiredSkillModal({
              onSubmit: (skillUuid: string, expectedLevel: number) => {
                const skillData = companySkills.find(({ skill }) => skill.uuid === skillUuid);
                addSkill({ ...skillData, expectedLevel });
                Acta.dispatchEvent("closeModal");
              },
              skillUuid: selectedSkillData.skill.uuid,
              submitLabel: "Ajouter cette compétence",
            });
          }}
        />
      </Flex>

      {requiredSkills?.map(({ expectedLevel, scale, skill }) => (
        <RequiredSkill
          key={skill.uuid}
          uuid={skill.uuid}
          label={skill.label}
          description={skill.description}
          expectedLevel={scale.find((expectation) => expectation.level === expectedLevel)}
          onDelete={(skillUuid: string) => {
            removeSkill(skillUuid);
          }}
          onUpdate={(skillUuid: string) => {
            const skillData = requiredSkills.find(({ skill }) => skill.uuid === skillUuid);
            showRequiredSkillModal({
              defaultExpectedLevel: skillData?.expectedLevel,
              onSubmit: (_skillUuid: string, newExpectedLevel: number) => {
                setSkillExpectationLevel(newExpectedLevel, skillUuid);
                Acta.dispatchEvent("closeModal");
              },
              skillUuid,
              submitLabel: "Enregistrer mes modifications",
            });
          }}
        />
      ))}
    </div>
  );
};

export default SkillSearch;
