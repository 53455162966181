export interface ReviewTraining {
  uuid: string;
  name: string;
  mode?: string;
  trainingOrganization: string;
}

export type ReviewType = "hot" | "cold";

export interface ReviewSession {
  uuid: string;
  completedAt?: number;
  startDate?: number;
  endDate?: number;
  city?: string;
  type?: string;
  reference?: string;
  training: ReviewTraining;
}

export interface ReviewUser {
  uuid: string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
}

export const ReviewResultStatus = {
  POSITIVE: "positive",
  NEGATIVE: "negative",
} as const;

export const ReviewStatus = {
  NULL: null,
  SUBMITTED: "submitted",
  COMPLETED: "completed",
  PENDING: "pending",
  ABANDONED: "abandoned",
  CANCELLED: "cancelled",
} as const;

export type ReviewResultStatus = (typeof ReviewResultStatus)[keyof typeof ReviewResultStatus];
export type ReviewStatus = (typeof ReviewStatus)[keyof typeof ReviewStatus];

export interface ReviewData {
  comment?: string;
  value: number;
  max: number;
  min: number;
}
export interface ColdReviewData extends ReviewData {
  status: ReviewResultStatus;
}

export interface Review {
  uuid: string;
  url: string;
  status: ReviewStatus;
  session: ReviewSession;
  trainee: ReviewUser;
  trainers: ReviewUser[];
}

export interface HotReview extends Review {
  data?: ReviewData;
}

export interface ColdReview extends Review {
  data?: ColdReviewData;
}

export type ReviewQuestion = {
  question: string;
  category?: string | "none";
} & (
  | {
      type: "comment";
      comment: string;
    }
  | {
      type: "multiple" | "radio";
      score: number;
      min: number;
      max: number;
      sentiment: ReviewResultStatus;
    }
);

export interface ReviewDetails {
  uuid: string;
  url: string;
  trainee: ReviewUser;
  session: ReviewSession;
  questions: ReviewQuestion[];
  mailsHistory?: ReviewMailsHistory;
  status: ReviewStatus;
}

export interface HotReviewDetails extends ReviewDetails {
  comment?: string;
  score: number;
  categories: {
    [key: string]: number;
  };
}

export interface ColdReviewDetails extends ReviewDetails {
  sentiment: ReviewResultStatus;
  data: ColdReviewData;
}

export type ReviewMailsHistory = {
  mailCount?: number;
  lastMailDate?: number;
};

export type ReviewExportData = {
  trainee: ReviewUser;
  trainers: ReviewUser[];
  session: ReviewSession;
  reviewData: {
    createdAt: number;
    status: ReviewStatus;
    answers: string[] | null;
    grades?: (number | undefined)[];
  };
};

export type RowReview = { rowUuid: string; reviews: { [key in ReviewType]?: string } };

export interface GetSessionReviewsOutput {
  sessionUuid: string;
  companyHasColdReviews: boolean;
  companyHasHotReviews: boolean;
  hotReviewsAlreadyGenerated?: boolean;
  coldReviewsAlreadyGenerated?: boolean;
  evalGroupsGenerated?: boolean;
}
