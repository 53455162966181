import { InterviewRoles, TargetActivity, TargetItem, TargetLog } from "@skillup/espace-rh-bridge";
import { enGB, format, fr } from "@skillup/shared-utils";
import { DSAvatar, Flex } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";
import { sortBy } from "lodash";
import { ReactNode, useMemo } from "react";
import Comments from "../Comments";
import styles from "./Activities.module.scss";
import UpdateLogDescription from "./UpdateLog";

const formatDate = ({ locale, date }: { locale: Locale; date?: string }) => {
  return date ? format(new Date(date), "d MMMM yyy", { locale }) : undefined;
};

const computeLogOrderKey = (log: TargetLog, lastAuthor: string) =>
  `${lastAuthor === log.author.fullName ? "Z" : "A"}${log.type === "comment" ? "Z" : "A"}${
    log.createdAt
  }`;

export function Activities({ activities }: { activities: TargetActivity[] }) {
  const { i18n, t } = useTranslation();
  const locale = useMemo(() => (i18n.language === "en" ? enGB : fr), [i18n.language]);

  return (
    <Flex column>
      {activities?.map((activity) => {
        if (activity.logs.length === 0) {
          return null;
        }
        // @ts-ignore
        const lastAuthor = sortBy(activity.logs, ["createdAt"], ["desc"])[0].author.fullName;
        return (
          <div>
            <LogDivider
              label={
                !activity.context.signedAt
                  ? activity.context.campaignName
                  : `${activity.context.campaignName} - ${t(
                      "interview.targets.activity.signedat.label",
                      {
                        defaultValue: "Entretien signé le",
                      }
                    )} ${formatDate({ locale, date: activity.context.signedAt })}`
              }
            />
            {activity.logs
              .sort((logA, logB) =>
                computeLogOrderKey(logA, lastAuthor).localeCompare(
                  computeLogOrderKey(logB, lastAuthor)
                )
              )
              .map((log: TargetLog) => (
                <Log log={log} />
              ))}
          </div>
        );
      })}
    </Flex>
  );
}

const Log = ({ log }: { log: TargetLog }) => {
  const { t } = useTranslation();

  switch (log.type) {
    case "create":
      return (
        <InnerLog
          title={t("interview.targets.activity.create.label", {
            defaultValue: "{{author}} a créé l'objectif",
            author: log.author.fullName,
          })}
          author={log.author}
        />
      );
    case "update":
      return (
        <InnerLog
          title={t("interview.targets.activity.update.author.label", {
            defaultValue: "{{author}} a modifié l'objectif",
            author: log.author.fullName,
          })}
          description={<UpdateLogDescription log={log} />}
          author={log.author}
        />
      );
    case "evaluation":
      const targetItem = log.data as TargetItem;
      const role = log.author.role as keyof TargetItem["completion"];
      let completion: any = targetItem.completion?.[role]["value"];
      if (targetItem.scale === "numeric") {
        completion += targetItem.unit ? " " + targetItem.unit : "%";
      } else {
        completion = targetItem.choices?.[completion - 1];
      }
      return (
        <InnerLog
          title={
            log.author.role === InterviewRoles.EMPLOYEE
              ? t("interview.targets.activity.autoevaluation.label", {
                  defaultValue: "Auto-évaluation de l’objectif: {{completion}}",
                  completion: completion || "",
                })
              : t("interview.targets.activity.evaluation.label", {
                  defaultValue: "Évaluation de l’objectif: {{completion}}",
                  completion: completion || "",
                })
          }
          author={log.author}
        />
      );
    case "comment":
      return (
        <Comments
          data={{
            employee: {
              comment: {
                value: log.author.role === InterviewRoles.EMPLOYEE ? (log.data as string) : null,
                updatedAt: log.author.role === InterviewRoles.EMPLOYEE ? log.createdAt : undefined,
              },
            },
            manager: {
              comment: {
                value: log.author.role === InterviewRoles.MANAGER ? (log.data as string) : null,
                updatedAt: log.author.role === InterviewRoles.MANAGER ? log.createdAt : undefined,
              },
            },
          }}
          author={log.author}
          showComments
          showDate={false}
          className={styles.comments}
        />
      );
    default:
      return <></>;
  }
};

const InnerLog = ({
  title,
  description,
  author,
}: {
  title: string | ReactNode;
  description?: string | ReactNode;
  author: TargetLog["author"];
}) => {
  return (
    <Flex className={styles.log_wrapper}>
      <DSAvatar
        size="M"
        type="Initials"
        imageUrl={undefined}
        initials={author.initials}
        // TODO add uuid in activity's author
        // randomColorSeedString={author.uuid}
        color="Grey"
        generateRandomColor={false}
        tooltip={author?.fullName}
      />
      <div className={styles.log}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </Flex>
  );
};

export const LogDivider = ({ label }: { label?: string }) => {
  if (!label) {
    return null;
  }

  return (
    <Flex className={styles.log_divider}>
      <hr />
      <p>{label}</p>
      <hr />
    </Flex>
  );
};
