import { TargetScalesResult, TargetScalesRoutes } from "@skillup/espace-rh-bridge";
import { ListUtils } from "@skillup/shared-utils";
import { DSDataGrid, DSFilters, FilterRef, useFilters } from "@skillup/ui";
import { useQuery } from "@tanstack/react-query";
import useTranslation from "hooks/useTranslation";
import { useCallback, useReducer, useRef } from "react";
import { buildRequest } from "utils/buildRequest";
import { useTargetScaleData } from "../hooks/useTargetScaleData";

interface QueryAction {
  type: "sort" | "pagination" | "filter";
  sort?: string;
  order?: "ASC" | "DESC";
  page?: number;
  pageSize?: number;
  filter?: { title: string };
}

interface QueryOptions {
  sort?: string;
  order?: "ASC" | "DESC";
  limit?: number;
  offset?: number;
  filter?: string;
}

async function getTargetScales(query: QueryOptions) {
  try {
    return buildRequest<TargetScalesRoutes.GetTargetScales>({
      method: "GET",
      path: "/targetScales",
      query,
    })();
  } catch (err) {
    //add banner or error page
  }
  return [];
}

const queryOptionsReducer = (state: QueryOptions, action: QueryAction) => {
  switch (action.type) {
    case "sort":
      return { ...state, sort: action.sort, order: action.order };
    case "pagination":
      return { ...state, limit: action.pageSize, offset: action.page * action.pageSize };
    case "filter":
      return { ...state, filter: JSON.stringify(action.filter) };
    default:
      return state;
  }
};

const filterConfig = {
  title: {
    type: ListUtils.FilterType.TEXT,
  },
};

const filterProps = {
  title: { label: "Intitulé de l'échelle", placeholder: "Filtrer par titre" },
};

export const TargetScaleDataGrid = () => {
  // FIXME: pagesizeoptions ne fonctionne pas
  const [queryOptions, dispatch] = useReducer(queryOptionsReducer, { limit: 10 });
  const { t } = useTranslation();
  const filterRef = useRef<FilterRef>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filters, _, setFilters] = useFilters(filterConfig, filterProps);

  // Label of "0 objects" is controlled by this translation key: targetScalesDatagrid.toolbar.label
  // Its default value is "{{count}} objets".
  const handleSortModelChange = useCallback(
    (sort: string, order?: "ASC" | "DESC") => {
      dispatch({ type: "sort", sort, order });
    },
    [dispatch]
  );
  const handleFilterModelChange = useCallback((col) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  }, []);
  const handlePageChange = useCallback(
    (page, pageSize) => {
      dispatch({ type: "pagination", page, pageSize });
    },
    [dispatch]
  );
  const handleFilterValuesChange = useCallback(
    (filterValues) => {
      setFilters(filterValues);
      if (filterValues && filterValues?.title?.value !== "") {
        dispatch({ type: "filter", filter: { title: filterValues?.title?.value } });
      } else {
        dispatch({ type: "filter", filter: undefined });
      }
    },
    [setFilters, dispatch]
  );

  const { data: targetScales, isLoading } = useQuery(
    ["targetScales", queryOptions],
    () => getTargetScales(queryOptions),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { columns: gridcolumns, data } = useTargetScaleData(targetScales as TargetScalesResult);

  return (
    <>
      <DSFilters
        ref={filterRef}
        t={t}
        config={filterConfig}
        filters={filters}
        onChange={handleFilterValuesChange}
      />
      <DSDataGrid
        columns={gridcolumns}
        rows={data}
        onSort={handleSortModelChange}
        onFilter={handleFilterModelChange}
        onPageChange={handlePageChange}
        rowCount={(targetScales as TargetScalesResult)?.total ?? 0}
        initialState={{
          sorting: {
            sortModel: [{ field: "title", sort: "asc" }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        pagination
        loading={isLoading}
        slotProps={{ toolbar: { translationPrefix: "targetScalesDatagrid" } }}
      />
    </>
  );
};