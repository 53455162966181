import React from "react";
import cx from "classnames";

import { defaultColumnWidth } from "constants/dataTable";
import { ColumnMoving, Column } from "components/DataTable/types";

import styles from "./ColumnMoverStyles.module.scss";

export interface IProps {
  columnMoving: ColumnMoving;
  columns: Array<Column>;
  containerHeight: number;
  endColumnMoving: (column: Column, targetPosition: number) => void;
  scrollLeft: number;
  dragAndScroll: (mousePositionX: number) => number;
}
export interface IState {
  columnX: number;
  mouseX: number;
  nextPositionTarget: number;
}

export default class ColumnMover extends React.PureComponent<IProps, IState> {
  private _isMoving: boolean;

  public state: IState = {
    columnX: this.props.columnMoving.relativeX + this.props.scrollLeft + 25,
    mouseX: 0,
    nextPositionTarget: 0,
  };

  public componentDidMount(): void {
    document.body.style.cursor = "move";
    window.addEventListener("mousemove", this.moveColumn);
    window.addEventListener("mouseup", this.stopMoving);
    this._isMoving = true;
    this.dragAndScroll();
  }

  private dragAndScroll = (): void => {
    if (this._isMoving) {
      if (this.state.mouseX) this.props.dragAndScroll(this.state.mouseX);
      setTimeout(() => {
        this.dragAndScroll();
      }, 25);
    }
  };

  private moveColumn = (event: MouseEvent): void => {
    this.setState({
      mouseX: event.pageX,
      columnX:
        event.pageX -
        (this.props.columnMoving.absoluteX - this.props.columnMoving.relativeX) +
        this.props.scrollLeft +
        30,
    });
  };

  private stopMoving = (): void => {
    document.body.style.cursor = "initial";

    window.removeEventListener("mousemove", this.moveColumn);
    window.removeEventListener("mouseup", this.stopMoving);

    this.props.endColumnMoving(this.props.columnMoving, this.state.nextPositionTarget);

    this._isMoving = false;
  };

  private getNextPositionLeft = (movingPosition: number): number => {
    if (this.props.columnMoving.position - movingPosition < 0) {
      return movingPosition - 1;
    }
    return movingPosition;
  };

  private getNextPositionRight = (movingPosition: number): number => {
    if (this.props.columnMoving.position - movingPosition <= 0) {
      return movingPosition;
    }
    return movingPosition + 1;
  };

  private getOverlayContainerWidth = (column: Column): number => {
    const columnWidth = column.width || defaultColumnWidth;

    const immediateRightColumn = this.props.columns.find(
      (c) => c.position === this.props.columnMoving.position + 1
    );
    if (!immediateRightColumn) {
      // column moving is the last in datatable
      return columnWidth;
    }

    if (column.position === this.props.columnMoving.position) {
      // if moving column width is 100% + 50% right column
      return columnWidth + Math.round((immediateRightColumn.width || defaultColumnWidth) / 2);
    }

    if (column.position === immediateRightColumn.position) {
      // if immediate right column, width is 50%
      return Math.round(columnWidth / 2);
    }
    return columnWidth;
  };

  private getColumnLateralOverlayWidth = (
    isLeftOverlay: boolean,
    columnPosition: number
  ): string => {
    if (columnPosition === this.props.columnMoving.position) {
      if (isLeftOverlay) {
        return `${this.getOverlayContainerWidth(this.props.columnMoving)}px`;
      }
      return "0%";
    }
    if (columnPosition === this.props.columnMoving.position + 1) {
      return isLeftOverlay ? "0%" : "100%";
    }
    return "50%";
  };

  public render(): JSX.Element {
    const { containerHeight, columns, columnMoving, scrollLeft } = this.props;

    const { columnX } = this.state;

    return (
      <div className={styles.ColumnMover} style={{ marginLeft: -scrollLeft || 0 }}>
        {columns.map((column) => (
          <div
            key={column.key}
            style={{
              width: this.getOverlayContainerWidth(column),
              height: containerHeight,
            }}
            className={cx(styles.columnOverlay, {
              [styles.nonMovable]: !column.movable,
            })}
          >
            <div
              onMouseEnter={() =>
                column.movable &&
                this.setState({
                  nextPositionTarget: this.getNextPositionLeft(column.position),
                })
              }
              style={{
                width: this.getColumnLateralOverlayWidth(true, column.position),
              }}
            />
            <div
              onMouseEnter={() =>
                column.movable &&
                this.setState({
                  nextPositionTarget: this.getNextPositionRight(column.position),
                })
              }
              style={{
                width: this.getColumnLateralOverlayWidth(false, column.position),
              }}
            />
          </div>
        ))}

        <div
          className={styles.fakeColumn}
          style={{
            width: columnMoving.width || defaultColumnWidth,
            left: columnX,
            top: 0,
            height: containerHeight,
          }}
        >
          <h3>
            <span>{columnMoving.label}</span>
          </h3>
          <div />
        </div>
      </div>
    );
  }
}
