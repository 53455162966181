import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useSetState } from "react-use";

import { Modal, DSButton, FileDrop } from "@skillup/ui";

import { CreateChildPayload } from "hooks/useCompany";

import CompanyForm, { IForm, ValidateForm, BuildPayloadFromForm } from "../CompanyForm";

import styles from "./CreateCompany.module.scss";

interface Props {
  readonly submitRequest: (o: CreateChildPayload, file?: File) => void;
  readonly onClose: () => void;
  readonly loading: boolean;
}

export default ({ submitRequest, onClose, loading }: Props) => {
  const [form, setFormState] = useSetState<IForm>({});
  const [file, setFile] = useState<File | undefined>(undefined);

  const handleMainAction = () => {
    const { form: newForm, hasError } = ValidateForm(form);

    if (hasError) {
      setFormState(newForm);
    }

    if (!hasError) {
      const payload = BuildPayloadFromForm(form);

      submitRequest(payload, file);
    }
  };

  const onDrop = useCallback(
    async ([cfile]: File[]) => {
      setFile(cfile);
    },
    [setFile]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: 52428800,
  });

  return (
    <Modal title="Nouvelle entreprise associée" onClose={onClose} disableOnClickAway>
      <div className={styles.createCompany}>
        <FileDrop
          isDragActive={isDragActive}
          rootProps={getRootProps()}
          inputProps={getInputProps()}
          btnLabel="Choisir un logo"
          dropLabel="ou glisser déposer un fichier ici"
          value={file?.name}
        />

        <CompanyForm form={form} setFormState={setFormState} isChild />

        <div className={styles.buttonsWrapper}>
          <DSButton
            className={styles.button}
            emphasis="Low"
            label="Annuler"
            onClick={onClose}
            loading={loading}
          />
          <DSButton
            className={styles.button}
            emphasis="High"
            label="Créer la nouvelle entreprise associée"
            onClick={handleMainAction}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};
