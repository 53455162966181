import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Flex, Text, Loader } from "@skillup/design-system";
import { DSFormGroupTextInput, DSTextInput, VerticalDivider } from "@skillup/ui";

import { useAccessCheckFor } from "hooks/useSettings";
import { useTimelineForFields } from "services/coreHR";

import { Collaborator } from "../../api";
import getLocalizedSalary from "../localizedSalary";
import { EmptyLayout } from "./EmptyLayout";

const sortByStartDate = (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime();

type UserCareerProps = {
  readonly user: Collaborator;
};

const UserCareer = ({ user }: UserCareerProps) => {
  const { t } = useTranslation();

  const canAccessSalaryData = useAccessCheckFor("salary-data");

  const { timelineRecords, isLoading } = useTimelineForFields({
    employeeID: user?.uuid,
    fieldsKeys: ["job.monthlySalary", "job.currency", "job.jobTitle"],
  });

  const convertToLocalizedSalary = useCallback(
    ({ startDate, data }) =>
      getLocalizedSalary({
        startDate,
        rawData: data,
        records: timelineRecords,
        locale: user.currentLanguage,
      }),
    [timelineRecords, user]
  );

  const hasSalaryData = timelineRecords?.["job.monthlySalary"].length > 0;
  const hasJobTitleData = timelineRecords?.["job.jobTitle"].length > 0;

  const sortedSalaryData = timelineRecords?.["job.monthlySalary"].sort(sortByStartDate);
  const sortedJobTitleData = timelineRecords?.["job.jobTitle"].sort(sortByStartDate);

  if (!isLoading && !hasSalaryData && !hasJobTitleData)
    return <EmptyLayout fullName={user.fullName} />;

  return (
    <Flex padding="l" gap="s" justifyContent="center">
      {isLoading ? (
        <Loader fillSpace />
      ) : (
        <Flex
          maxWidth="42rem"
          flexGrow={1}
          gap="s"
          paddingBottom="m"
          flexDirection="row"
          justifyContent="center"
        >
          {/* TODO: Also add feature flag condition in core-hr (backend). Data can be find in request response */}
          {canAccessSalaryData && (
            <Flex width="100%">
              <Flex width="100%">
                <Flex gap="s" flexGrow={1} flexDirection="column" justifyContent="flex-start">
                  <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
                    {t("manager.peopleReview.timelineModal.job.monthlySalary.title", {
                      defaultValue: "Historique de salaire",
                    })}
                  </Text>
                  {!hasSalaryData && (
                    <Text espaceFont="body1Italic" color="plainText-onLight-placeholder">
                      {t("manager.peopleReview.timelineModal.job.salary.noData", {
                        defaultValue: "Pas de donnée disponible",
                      })}
                    </Text>
                  )}
                  {hasSalaryData &&
                    sortedSalaryData.map(({ data, startDate }, index) => (
                      <DSFormGroupTextInput
                        key={index}
                        label={new Date(startDate).toLocaleDateString()}
                      >
                        <Flex paddingVertical="xs" flexDirection="column">
                          <DSTextInput
                            name="annualSalary"
                            value={convertToLocalizedSalary({ data, startDate })}
                            readOnly
                          />
                        </Flex>
                      </DSFormGroupTextInput>
                    ))}
                </Flex>
              </Flex>
              <VerticalDivider left="s" right="s" />
            </Flex>
          )}

          <Flex width="100%">
            <Flex
              gap="s"
              flexGrow={1}
              paddingBottom="s"
              flexDirection="column"
              justifyContent="flex-start"
            >
              <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
                {t("manager.peopleReview.timelineModal.job.jobTitle.title", {
                  defaultValue: "Historique de postes occupés",
                })}
              </Text>
              {!hasJobTitleData && (
                <Text espaceFont="body1Italic" color="plainText-onLight-placeholder">
                  {t("manager.peopleReview.timelineModal.job.jobTitle.noData", {
                    defaultValue: "Pas de donnée disponible",
                  })}
                </Text>
              )}
              {hasJobTitleData &&
                sortedJobTitleData.map(({ data, startDate }, index) => (
                  <DSFormGroupTextInput
                    key={index}
                    label={new Date(startDate).toLocaleDateString()}
                  >
                    <Flex paddingVertical="xs" flexDirection="column">
                      <DSTextInput name="jobTitle" value={data} readOnly />
                    </Flex>
                  </DSFormGroupTextInput>
                ))}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default UserCareer;
