import styles from "./PlaceholderCard.module.scss";
import cx from "classnames";

import DashboardChildCard, {
  Props as DashboardChildCardProps,
} from "../DashboardChildCard/DashboardChildCard";

import { DSButton } from "@skillup/ui";

import placeholderImg from "assets/interview/dashboard/empty_card.png";
import { DashboardChildType } from "@skillup/espace-rh-bridge";
import { useDashboardExport } from "../../hooks/useDashboardExport";

function PlaceholderCard<T extends DashboardChildType>({
  className,
  ...props
}: DashboardChildCardProps<T>) {
  const { exportChild, isExporting, isAvailable } = useDashboardExport(props.child);

  return (
    <DashboardChildCard
      className={cx(styles.PlaceholderCard, className)}
      title={props.typeName}
      {...props}
    >
      {() => (
        <div className={styles.placeholder}>
          <img className={styles.placeholderImg} src={placeholderImg} alt="bientôt disponible" />
          <div className={styles.message}>
            La synthèse pour les questions de type « {props.typeName.toLowerCase()} » sera bientôt
            disponible.
          </div>
          {isAvailable && (
            <>
              <div className={styles.details}>
                En attendant, vous pouvez télécharger l’ensemble des réponses et commentaires
                associés à cette question en cliquant sur le bouton ci-dessous :
              </div>
              <div className={styles.download}>
                <DSButton
                  emphasis="Mid"
                  label="Télécharger"
                  loading={isExporting}
                  onClick={exportChild}
                />
              </div>
            </>
          )}
        </div>
      )}
    </DashboardChildCard>
  );
}

export default PlaceholderCard;
