import { createContext, useContext } from "react";
import { Field, Question } from "./types";

export type TrainingDashboardContextType = {
  fields: Field[];
  detailedIndicatorsUtilities: {
    questionsMap: Record<
      string,
      {
        indicator: string;
        dimension: string;
      }
    >;
    indicatorDimensionMap: Record<string, Record<string, string>>;
  };
  keyIndicators: Question[];
  detailedIndicators: Question[];
  summaryQuestion: Question;
  monthlyQuestion: Question;
};

export const TrainingDashboardContext = createContext<TrainingDashboardContextType>(null);

export const useTrainingDashboardContext = () => {
  const context = useContext(TrainingDashboardContext);

  if (!context) {
    throw new Error(
      "useTrainingDashboardFields must be used within a TrainingDashboardContextProvider"
    );
  }

  return context;
};
