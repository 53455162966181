import TrainingStars from "components/TrainingStars";

import styles from "./TrainingScore.module.scss";

interface Props {
  readonly score: number;
  readonly reviewsCount: number;
}

const TrainingScore = ({ score, reviewsCount }: Props) => (
  <div className={styles.TrainingScore}>
    <TrainingStars score={score} />
    <span className={styles.note}>{Math.round(score * 10) / 10}</span>
    <span className={styles.totalPossible}>/ 10</span>
    {reviewsCount && <span className={styles.reviewsCount}>({reviewsCount} avis)</span>}
  </div>
);

export default TrainingScore;
