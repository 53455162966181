import { MaterialIcons } from "@skillup/ui";
import { memo } from "react";

type ChildIconProps = {
  kind: string;
  type?: string;
  size?: string;
};
export const ChildIcon = memo(function ChildIcon({ kind, type, size = "1rem" }: ChildIconProps) {
  switch (kind) {
    case "question":
      if (type === "radio") return <MaterialIcons.FormatListBulleted size={size} />;
      if (type === "text") return <MaterialIcons.ChatBubble size={size} />;
      if (type === "checkbox") return <MaterialIcons.Checklist size={size} />;
      return <MaterialIcons.ChatBubble size={size} />;
    case "targets":
      return <MaterialIcons.TrackChanges size={size} />;
    case "table":
      return <MaterialIcons.TableRows size={size} />;
    case "guidelines":
      return <MaterialIcons.InfoOutline size={size} />;
    case "personnalisableGuidelines":
      return <MaterialIcons.InfoOutline size={size} />; // todo verify with clotilde
    case "conclusion":
      return <MaterialIcons.InfoOutline size={size} />; // todo verify with clotilde
    case "html":
      return <MaterialIcons.TextSnippet size={size} />;
    case "title":
      return <MaterialIcons.FormatSize size={size} />;
    case "interviewsHistory":
      return <MaterialIcons.History size={size} />;
    case "trainingsHistory":
      return <MaterialIcons.History size={size} />;
    case "trainingsCollection":
      return <MaterialIcons.School size={size} />;
    case "job":
      return <MaterialIcons.Article size={size} />;
    case "upload":
      return <MaterialIcons.AttachFile size={size} />;
    case "globalTargetsCompletion":
      return <MaterialIcons.TableRows size={size} />;
    case "skills":
      return <MaterialIcons.Psychology size={size} />;
    case "userSpecificData":
      return <MaterialIcons.SwapVert size={size} />;
    default:
      return <></>;
  }
});
