import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const selectTemplateBuilder = createSelector(
  (state: RootState) => state.templateBuilder,
  (templateBuilder) => templateBuilder.template
);

export const selectCanRedo = createSelector(
  selectTemplateBuilder,
  (templateBuilder) => templateBuilder.future.length > 0
);

export const selectCanUndo = createSelector(
  selectTemplateBuilder,
  (templateBuilder) => templateBuilder.past.length > 0
);

export const selectTemplate = createSelector(
  selectTemplateBuilder,
  (templateBuilder) => templateBuilder.present
);

export const selectTemplateSections = createSelector(
  selectTemplate,
  (templateBuilder) => templateBuilder.sections
);

export const selectHighlightUuid = createSelector(
  (state: RootState) => state.templateBuilder,
  (templateBuilder) => templateBuilder.highlightUuid
);

export const selectGlobalActionsCount = createSelector(
  (state: RootState) => state.templateBuilder,
  (templateBuilder) => templateBuilder.globalActionsCount
);

export const selectErrors = createSelector(selectTemplateSections, (sections) => {
  if (sections.length === 0) return [];
  
  const mergedFirstSectionAndChild = sections[0].pages[0].children[0];
  return sections.reduce((acc, section) => {
    if (section.uuid === sections[0].uuid && mergedFirstSectionAndChild.errors) {
      acc.push({ uuid: section.uuid, errors: mergedFirstSectionAndChild.errors });
    }
    if (section.errors) {
      acc.push({ uuid: section.uuid, errors: section.errors });
    }
    section.pages.forEach((page) => {
      if (page.errors) {
        acc.push({ uuid: page.uuid, errors: page.errors });
      }
      page.children.forEach((child) => {
        if (child.errors && child.uuid !== mergedFirstSectionAndChild.uuid) {
          acc.push({ uuid: child.uuid, errors: child.errors });
        }
      });
    });
    return acc;
  }, []);
});
