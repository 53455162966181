import React, { useRef, useCallback } from "react";
import { useMount } from "react-use";

import EasingEquations from "utils/EasingEquations";
import Icon from "components/Icon";
import { cross, signError, signInfo, signSuccess, signWarning } from "uiAssets/Icons";
import Colors from "uiAssets/Colors";

import styles from "./styles.module.scss";

export interface IMessageProps {
  duration?: number;
  manualCloseOnly: boolean;
  message: string;
  messageKey: string;
  selfDelete: (messageKey: string) => void;
  title?: string;
  type: string;
}

const Message = (props: IMessageProps) => {
  const { duration, manualCloseOnly, message, messageKey, selfDelete, title, type } = props;
  const container = useRef<HTMLDivElement>(null);

  const selfClose = useCallback(() => {
    if (container.current) {
      container.current.animate(
        [
          { transform: "translateX(0)", opacity: 1 },
          { transform: "translateX(300px)", opacity: 0 },
        ],
        {
          duration: 250,
          easing: EasingEquations.easeInBack,
          fill: "forwards",
        }
      ).onfinish = () => {
        if (container.current) {
          container.current.animate(
            [{ height: `${container.current.offsetHeight}px` }, { height: "0" }],
            {
              duration: 75,
              fill: "forwards",
            }
          ).onfinish = () => selfDelete(messageKey);
        }
      };
    }
  }, [container, selfDelete, messageKey]);

  useMount(() => {
    if (container.current) {
      container.current.animate(
        [
          { transform: "translateX(300px)", opacity: 0 },
          { transform: "translateX(0)", opacity: 1 },
        ],
        {
          duration: 400,
          easing: EasingEquations.easeOutBack,
          fill: "forwards",
        }
      );
    }
    if (!manualCloseOnly) {
      setTimeout(selfClose, duration || 3000);
    }
  });

  return (
    <div
      ref={container}
      style={{ opacity: 0, transform: "translateX(300px)" }}
      className={styles[type] || styles.info}
    >
      {manualCloseOnly && (
        <button onClick={selfClose}>
          <Icon
            icon={cross}
            width={10}
            fill={
              {
                error: Colors.errorD,
                info: Colors.infoD,
                success: Colors.successD,
                warning: Colors.warningD,
              }[type] || Colors.infoD
            }
          />
        </button>
      )}
      <div
        className={styles.iconContainer}
        style={{
          backgroundColor:
            {
              error: Colors.errorL,
              info: Colors.infoL,
              success: Colors.successL,
              warning: Colors.warningL,
            }[type] || Colors.infoL,
        }}
      >
        <Icon
          icon={
            {
              error: signError,
              info: signInfo,
              success: signSuccess,
              warning: signWarning,
            }[type] || signInfo
          }
          fill={
            {
              error: Colors.error,
              info: Colors.info,
              success: Colors.success,
              warning: Colors.warning,
            }[type] || Colors.info
          }
          width={20}
        />
      </div>
      <div
        className={styles.messageContent}
        style={{
          backgroundColor:
            {
              error: Colors.error,
              info: Colors.info,
              success: Colors.success,
              warning: Colors.warning,
            }[type] || Colors.info,
        }}
      >
        {title && <header>{title}</header>}
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Message;
