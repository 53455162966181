import Acta from "utils/Acta";

export const switchOnError = (err: Error) => {
  switch (err.message) {
    case "users":
      Acta.dispatchEvent("sendAppMessage", {
        message: "Veuillez sélectionner au moins un collaborateur",
        type: "error",
      });
      break;
    case "address":
      Acta.dispatchEvent("sendAppMessage", {
        message: "Veuillez indiquer un lieu de session",
        type: "error",
      });
      break;
    case "dates":
      Acta.dispatchEvent("sendAppMessage", {
        message: "Veuillez renseigner les dates de la session",
        type: "error",
      });
      break;
    case "invalidEndDate":
      Acta.dispatchEvent("sendAppMessage", {
        message: "La date de fin de session est antérieure à la date de début de session",
        type: "error",
      });
      break;
    default:
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de cet ajout de ligne",
        type: "error",
      });
      break;
  }
};
