import React from "react";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSModal } from "@skillup/ui";
import { Future } from "@skillup/monads";

import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";
import { OrganizationRoutes } from "@skillup/espace-rh-bridge";
import Acta from "utils/Acta";

type Props = {
  onClose: () => void;
  authStrategyUuid: string;
};

const setIsMandatoryIfUnknownStrategy = async (companyUuid: string, authStrategyUuid: string) => {
  const request = buildSafeRequest<OrganizationRoutes.SetIsMandatoryIfUnknownStrategy>({
    method: "POST",
    path: "/organization/{companyUuid}/auth-strategies/{authStrategyUuid}/is-mandatory",
    params: { companyUuid, authStrategyUuid },
  });

  return request.run();
};

export default ({ onClose, authStrategyUuid }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const credentials = Acta.getState("userData");

  const submitRequest = async () => {
    const request = setIsMandatoryIfUnknownStrategy(
      credentials.activeCompany.uuid,
      authStrategyUuid
    );

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Une erreur est survenue lors la mise à jour de cette stratégie.",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      (_authProvider) => {
        addToast(
          t("authprovider.mandatoryifunknown.success", {
            defaultValue: "Stratégie rendu obligatoire avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Rendre la stratégie obligatoire" />
      </DSModal.Header>

      <DSModal.Content>
        <label>Êtes-vous sûr de vouloir mettre rendre cette stratégie obligatoire ?</label>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={onClose} label="Annuler" />
        <DSModal.Footer.PrimaryButton onClick={() => submitRequest()} label="Confirmer" />
      </DSModal.Footer>
    </DSModal>
  );
};
