import type { SkillsRoutes } from "types/api";
import { useRouteQuery } from "hooks";

type GetCategoriesRoute = SkillsRoutes["Skills.Categories.GetList"];

export function useCategories() {
  const categoriesRequest = useRouteQuery<GetCategoriesRoute>(["skillsCategories"], {
    method: "GET",
    path: "/competences/skills/categories",
  });
  return categoriesRequest;
}
