import React from "react";
import Select from "react-select";
import { TextDropdown } from "@skillup/ui";

import { Child } from "../types";

// Sub components
import DisallowBlock, { commentRestrictions } from "./DisallowBlock";

import { InterviewRoles } from "types/Interview";

type InterviewsHistory = Child & { kind: "interviewsHistory" };
const InterviewsHistoryBlock = ({
  data,
  updateChild,
}: {
  data: InterviewsHistory;
  updateChild: (newChildData: Child) => void;
}) => {
  const units = [
    { value: "months", label: "Mois" },
    { value: "years", label: "Années" },
  ];

  const ageUnitSingular = {
    months: "mois",
    years: "an",
  }[data.ageUnit];

  const ageUnitPlural = {
    months: "mois",
    years: "ans",
  }[data.ageUnit];

  const years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => ({
    value,
    label: `${value} ${value === 1 ? ageUnitSingular : ageUnitPlural}`,
  }));

  const types: InterviewsHistory["types"] = [
    "Entretien annuel",
    "Entretien professionnel",
    "Bilan à 6 ans",
    "Autre",
  ];

  const typesOptions = types.map((type) => ({
    value: type,
    label: type,
  }));

  return (
    <form>
      <label>Unité</label>
      <TextDropdown
        options={units}
        selectedIndex={units.findIndex((unit) => unit.value === data.ageUnit)}
        small
        onSelect={({ value }) =>
          updateChild({ ...data, ageUnit: value as InterviewsHistory["ageUnit"] })
        }
      />
      <label>Remonter jusqu'à:</label>
      <TextDropdown
        options={years}
        selectedIndex={years.findIndex((year) => year.value === data.maxAge)}
        small
        onSelect={({ value }) =>
          updateChild({ ...data, maxAge: value as InterviewsHistory["maxAge"] })
        }
      />
      <label>Type d'entretiens à afficher: (optionnel)</label>
      <Select
        isMulti
        name="interviews-types"
        options={typesOptions}
        // className={styles.select}
        value={(data.types ?? []).map((value) => ({ value, label: value }))}
        onChange={(values) =>
          updateChild({
            ...data,
            types: values.map((t) => t.value),
          })
        }
      />
      <DisallowBlock
        data={data}
        updateChild={updateChild}
        restrictions={[
          ...commentRestrictions,
          {
            action: "downloadPDF",
            label: "Cacher les fichiers PDF à l'employé",
            role: InterviewRoles.EMPLOYEE,
          },
          {
            action: "downloadPDF",
            label: "Cacher les fichiers PDF au manager",
            role: InterviewRoles.MANAGER,
          },
        ]}
      />
    </form>
  );
};

export default InterviewsHistoryBlock;
