import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

export default async (
  rows: string[],
  scheduleUuid: string,
  reloadRows: (uuids: string[]) => void
) => {
  try {
    await DataLayer.request({
      method: "POST",
      url: `/v1/schedule/move-to-plan/${scheduleUuid}`,
      body: JSON.stringify({ rows }),
    });

    Acta.dispatchEvent("sendAppMessage", {
      message:
        rows.length === 1
          ? "Cette ligne a été ajoutée au plan."
          : "Ces lignes ont été ajoutées au plan.",
      type: "success",
    });

    reloadRows(rows);
  } catch (error) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Echec de l’ajout au plan",
      type: "error",
    });
  }
};
