import React, { useRef } from "react";
import ReactTooltip from "react-tooltip";
import { IoIosHelpCircleOutline } from "react-icons/io";

import styles from "./Tooltip.module.scss";

interface IProps {
  id: string;
  content: TemplateStringsArray | string;
  position?: "top" | "bottom" | "left" | "right";
}

const Tooltip = ({ id, content, position = "top" }: IProps): JSX.Element => {
  const tooltipRef = useRef<HTMLParagraphElement>(null);

  return (
    <div className={styles.Tooltip}>
      <p ref={tooltipRef} data-tip={content} />
      <button
        type="button"
        onMouseEnter={() => ReactTooltip.show(tooltipRef.current as Element)}
        onMouseLeave={() => ReactTooltip.hide(tooltipRef.current as Element)}
        data-tip={content}
        data-for={id}
      >
        <IoIosHelpCircleOutline size={24} />
      </button>

      <ReactTooltip
        id={id}
        className={styles.dataTip}
        place={position}
        effect="solid"
        multiline
        html
      />
    </div>
  );
};

export default Tooltip;
