import { useMemo } from "react";

import { Config, ICompanyField } from "@skillup/training-bridge";
import { DSDataGrid, DSFilters } from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";
import { GridColDef } from "@mui/x-data-grid-pro";

import { isSkillupAdmin, isSkillupDeveloper } from "utils/User";
import useTranslation from "hooks/useTranslation";

import useFiltersSettings from "../hooks/useFiltersSettings";
import generateColumns from "../utils/generateColumns";
import { GridProps, initialState } from "./config";
import style from "./GridFields.module.scss";

const GridHiddenFields = (props: GridProps) => {
  const { t } = useTranslation();
  const [filterRef, filters, filterValues, handleFilterValuesChange, handleTableColumnFilterClick] =
    useFiltersSettings(false);

  const isAdmin = isSkillupAdmin() || isSkillupDeveloper();

  const columns: GridColDef[] = useMemo(() => {
    return generateColumns(t, isAdmin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayedRows: ICompanyField[] = useMemo(() => {
    const datasetFilterManager = new ListUtils.InMemoryDataset(
      Config.Fields.FieldsFilters,
      props.fields
    );

    return datasetFilterManager.getRows({ filterValues });
  }, [props.fields, filterValues]);

  return (
    <div className={style.gridContainer}>
      <h4 className={style.mainTitle}>
        {t("fields.hiddenFields.mainTitle", {
          defaultValue: "Champs masqués",
        })}
      </h4>
      <DSFilters
        t={t}
        config={Config.Fields.FieldsFilters}
        ref={filterRef}
        filters={filters}
        onChange={handleFilterValuesChange}
        translationPrefix="fields.hiddenFields"
        className={style.filters}
      />
      <DSDataGrid
        getRowId={(row) => row.uuid}
        loading={props.loading}
        columns={columns}
        rows={displayedRows}
        onRowClick={
          props.onSelectField
            ? (gridRow) => {
                props.onSelectField(gridRow.row);
              }
            : undefined
        }
        pagination
        initialState={initialState}
        translationPrefix="fields.hiddenFields"
        onFilter={handleTableColumnFilterClick}
        className={style.grid}
      />
    </div>
  );
};

export default GridHiddenFields;
