import { ReviewRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type CampaignEmployeesData = ReviewRoutes.GetReviewsForCampaign["response"];
export type CampaignReviewsQuery = ReviewRoutes.GetReviewsForCampaign["query"];

export type GetFieldsDataParams = {
  campaignID: string;
  sorting: CampaignReviewsQuery["sorting"];
  filters: CampaignReviewsQuery["reviewFilters"];
  pagination: CampaignReviewsQuery["pagination"];
  queryOptions: CampaignReviewsQuery["queryOptions"];
};

export async function getReviewsData({
  campaignID,
  filters,
  pagination,
  queryOptions,
  sorting,
}: GetFieldsDataParams) {
  try {
    const filtersFromEmployee = Object.keys(filters).filter((key) =>
      [
        "site",
        "role",
        "email",
        "branch",
        "service",
        "lastName",
        "fullName",
        "division",
        "firstName",
        "perimeters",
        "registrationNumber",
      ].includes(key)
    );

    const filtersFromReview = Object.keys(filters).filter((key) =>
      !filtersFromEmployee.includes(key)
    );
    const response = await buildRequest<ReviewRoutes.GetReviewsForCampaign>({
      method: "POST",
      path: "/reviews",
      query: {
        campaignId: campaignID,
        employeeFilters: filtersFromEmployee.reduce((acc, filterKey) => {
          acc[filterKey] = filters[filterKey];
          return acc;
        }, {}),
        pagination,
        queryOptions,
        reviewFilters: filtersFromReview.reduce((acc, filterKey) => {
          acc[filterKey] = filters[filterKey];
          return acc;
        }, {}),
        sorting,
      },
      target: "PEOPLE_REVIEW",
    })();

    return response;
  } catch (err) {
    return null;
  }
}
