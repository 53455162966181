import { useMemo } from "react";
import { useToasts } from "react-toast-notifications";

import { HotReview } from "@skillup/training-bridge";

import { TranslationType } from "hooks/useTranslation";

import generateTableRows from "../utils/table/generateTableRows";
import generateColumns from "../utils/table/generateColumns";
import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

type Props = {
  reviews?: HotReview[];
  t: TranslationType;
  locale: string;
  refetch: () => void;
  selectedRows: GridRowSelectionModel;
};

function useReviewsTableData({ reviews, t, locale, refetch, selectedRows }: Props) {
  const { addToast } = useToasts();

  const tableData = useMemo(
    () => ({
      rows: generateTableRows(reviews, locale),
      columns: generateColumns({ t, addToast, refetch, selectedRows }),
    }),
    [reviews, t, addToast, locale, refetch, selectedRows]
  );

  return tableData;
}

export default useReviewsTableData;
