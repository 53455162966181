import { buildRequest } from "utils/buildRequest";

import type { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";

export type UpdateCollaboratorPayload = CollaboratorsRoutes.Update["payload"];

export default async (collaboratorUuid: string, payload: CollaboratorsRoutes.Update["payload"]) => {
  const user = await buildRequest<CollaboratorsRoutes.Update>({
    method: "POST",
    path: "/collaborators/{collaboratorUuid}",
    params: { collaboratorUuid },
    payload,
  })();

  return user;
};
