// import { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";

type Interview = any; // CollaboratorsRoutes.GetInterviews["response"][0];

export interface IInterviewState {
  filter?: string[];
  interviews: Interview[];
}

export type TInterviewAction =
  | { type: "setInterviewFilter"; filter: string[] }
  | { type: "setInterviews"; interviews: Interview[]; maxLevel?: number };

const reducer = (state: IInterviewState, action: TInterviewAction): IInterviewState => {
  try {
    switch (action.type) {
      case "setInterviews":
        return { ...state, interviews: action.interviews };
      case "setInterviewFilter":
        return { ...state, filter: action.filter };
      default:
        return state;
    }
  } catch (err) {
    console.error(err);
    return state;
  }
};

export default reducer;
