import styled from "styled-components";
import { DSButton, DSColor, SkuiSpacing } from "@skillup/ui";

export const ListLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: ${DSColor["surface-light-darker"]};
`;

export const EvaluationScaleListWrapper = styled.div`
  padding-top: ${SkuiSpacing.m};
  max-width: 525px;
`;

export const CreationButton = styled(DSButton)`
  margin-bottom: ${SkuiSpacing.s};
`;
