import { useMemo } from "react";

import { useEmployeeFields } from "hooks";

import { Assignee } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import generateColumns from "./utils/generateColumns";
import generateTableRows from "./utils/generateTableRows";

type Props = {
  t: TranslationType;
  employees?: Array<Assignee>;
  actions: (row: { id: string; data: Assignee }) => void;
};

export default function useAssignmentsTableData({ actions, employees, t }: Props) {
  const { getFields } = useEmployeeFields();
  const tableData = useMemo(
    () => ({
      columns: generateColumns({ actions, fields: getFields(), t }),
      rows: generateTableRows(employees),
    }),
    [employees, t, actions, getFields]
  );

  return tableData;
}
