import React from "react";

import { TextInput } from "@skillup/ui";

import { Child } from "../types";
import DisallowBlock, { commentRestrictions } from "./DisallowBlock";
import styles from "./JobBlock.module.scss";

type JobBlock = Child & { kind: "job" };

function JobBlock({
  data,
  updateChild,
}: {
  data: JobBlock;
  updateChild: (newChildData: Child) => void;
}) {
  return (
    <div className={styles.JobBlock}>
      <br />
      <label>Intitulé du bloc</label>
      <TextInput
        key={`title-${data.uuid}`}
        value={data.title}
        onChange={(title) => updateChild({ ...data, title })}
      />
      <DisallowBlock
        data={data}
        updateChild={updateChild}
        restrictions={[...commentRestrictions]}
      />
    </div>
  );
}

export { JobBlock };
