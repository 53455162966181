import { PayloadAction } from "@reduxjs/toolkit";

import { moveChildToPage } from "./moveChildToPage";
import { moveChildToChild } from "./moveChildToChild";
import { moveChildToSection } from "./moveChildToSection";
import type { ActionVector, ChildVector, BuilderStructure } from "../..";

export function moveChild(
  state: BuilderStructure,
  action: PayloadAction<ActionVector<ChildVector>>
) {
  const { fromPosition, toPosition } = action.payload;

  if (fromPosition.type !== "child") {
    throw new Error();
  }
  switch (toPosition.type) {
    case "section":
      if (fromPosition.sectionIndex === toPosition.sectionIndex) {
        return state;
      }
      return {
        ...state,
        sections: moveChildToSection(state.sections, fromPosition, toPosition),
      };
    case "page":
      if (
        fromPosition.sectionIndex === toPosition.sectionIndex &&
        fromPosition.pageIndex === toPosition.pageIndex
      ) {
        return state;
      }
      return {
        ...state,
        sections: moveChildToPage(state.sections, fromPosition, toPosition),
      };
    case "child":
      if (
        fromPosition.sectionIndex === toPosition.sectionIndex &&
        fromPosition.pageIndex === toPosition.pageIndex &&
        fromPosition.childIndex === toPosition.childIndex
      ) {
        return state;
      }
      return {
        ...state,
        sections: moveChildToChild(state.sections, fromPosition, toPosition),
      };
    default:
      throw new Error();
  }
}
