import { useState, useEffect } from "react";
import { loadUsetifulScript } from "../services/Usetiful/usetifulScript";

interface Options {
  readonly consent?: {
    readonly necessary: boolean;
    readonly functionality: boolean;
    readonly analytics: boolean;
  };
}

const useUsetiful = ({ consent }: Options) => {
  const [isUsetifulUp, setIsUsetifulUp] = useState<boolean>(false);

  useEffect(() => {
    if (!isUsetifulUp && consent?.functionality) {
      loadUsetifulScript(
        window,
        document,
        "https://www.usetiful.com/dist/usetiful.js",
        process.env.REACT_APP_USETIFUL_TOKEN
      );
      setIsUsetifulUp(true);
    }
  }, [isUsetifulUp, consent]);

  return {
    isUsetifulUp,
  };
};

export default useUsetiful;
