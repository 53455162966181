import { useTranslation } from "react-i18next";
import { useMemo, useState, useEffect, useReducer, useContext, useCallback } from "react";

import { uniq, uniqBy } from "lodash";

import {
  GridRowId,
  useGridApiRef,
  GridCellParams,
  GridCellModesModel,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

import { ListUtils } from "@skillup/shared-utils";
import { coordinatorReviewStatuses } from "@skillup/people-review-bridge";
import { DSDataGrid, useFilters, UseFilterProp, OnFilterHandler } from "@skillup/ui";

import useAreas from "hooks/useAreas";
import { useCoreHRFields } from "services/coreHR";
import { getPluralizedLabel } from "helpers/functions";
import { useEmployees, useEmployeeFields } from "hooks/useEmployees";

import { useTalentGrid } from "../../hooks";
import { SupervisionContext } from "./../../contexts";
import { Modals, SelectActions } from "./../../components";
import { TalentGridRow, TalentGridState } from "./../../types";

interface QueryAction {
  page?: number;
  sort?: string;
  pageSize?: number;
  order?: "ASC" | "DESC";
  filter?: { title: string };
  type: "sort" | "filter" | "pagination";
}

interface QueryOptions {
  limit: number;
  offset: number;
}

const queryOptionsReducer = (state: QueryOptions, action: QueryAction) => {
  switch (action.type) {
    case "pagination":
      return { ...state, limit: action.pageSize, offset: action.page * action.pageSize };
    default:
      return state;
  }
};

type TalentGridProps = {
  onColumnFilterClick: OnFilterHandler;
  filters: { [x: string]: ListUtils.FilterValue<ListUtils.FilterTypes> };
};

export function TalentGrid({ filters, onColumnFilterClick }: TalentGridProps) {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const { campaign, setColumnsToExport } = useContext(SupervisionContext);
  const { getFields } = useEmployeeFields();
  const employeeFields = getFields();

  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [state, setState] = useState<TalentGridState>({ kind: "idle" });
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [queryOptions] = useReducer(queryOptionsReducer, { limit: 20, offset: 0 });
  const [currentEditedCell, setCurrentEditedCell] = useState<{ id: GridRowId; field: string }>();

  const hasManagerPrep = !!campaign?.managersPreparationEndDate;

  const {
    columns,
    getTogglableColumns,
    groupingModel,
    isErrorEmployeeFieldsData,
    isLoadingEmployeeFieldsData,
    processRowUpdate,
    rows,
    totalEmployeeFieldsData,
  } = useTalentGrid(
    {
      campaignID: campaign?.id,
      filters,
      pagination,
      queryOptions,
      sorting: {
        direction: "ASC",
        property: "fullName",
      },
    },
    // The managerCount is currently not working, when creating a campaign without adding managers in the preparation step,
    // the creation process still adds them if they are in the CSV, therefore, the definition of preparation end date is a safer value
    // to use to know if we should display the manager comment column in the talent grid
    // at least for now, I'm going to make an issue, please remove the comment once the issue is resolved
    // { hasManagerPrep: campaign?.managerCount > 0 ?? false, setState }
    { hasManagerPrep, setState }
  );

  const persistenceID = useMemo(() => {
    return campaign?.id;
  }, [campaign]);

  /** This trick (combined with no rendering when no persistence id)
   * works to get back settings from localStorage.
   * Currently the internal restoreState is broken, not sure why exactly
   */
  const initialState = useMemo(() => {
    const persistedState = localStorage.getItem(`grid-talent-grid.v1.${persistenceID}`);
    return {
      pagination: { paginationModel: { page: 0, pageSize: 20 } },
      pinnedColumns: {
        left: [
          GRID_CHECKBOX_SELECTION_COL_DEF.field,
          employeeFields.fullName.key,
          employeeFields.firstName.key,
          employeeFields.lastName.key,
          employeeFields.role.key,
          employeeFields.email.key,
          employeeFields.division.key,
          employeeFields.service.key,
          employeeFields.site.key,
          employeeFields.joinDate.key,
          employeeFields.contract.key,
          employeeFields.branch.key,
          employeeFields.registrationNumber.key,
          employeeFields.areas.key,
          "reviewManager",
        ],
        right: ["actions"],
      },
      ...JSON.parse(persistedState ?? "{}"),
    };
  }, [employeeFields, persistenceID]);
  const [columnsVisibilityModel, setColumnsVisibilityModel] = useVisibilityModel(
    initialState?.columns?.columnVisibilityModel
  );

  const handlePageChange = useCallback(
    (page: number, pageSize: number) => {
      setPagination({ page, pageSize });
    },
    [setPagination]
  );

  const selectRowsAvailable = apiRef?.current?.selectRows;
  const massSelectionDisabled = campaign?.permissions["mass-selection"].isVisible === false;
  const unselectAllRows = useCallback(() => apiRef?.current?.selectRows([], false, true), [apiRef]);

  useEffect(() => {
    if (massSelectionDisabled && selectRowsAvailable) {
      unselectAllRows();
    }
  }, [apiRef, campaign, massSelectionDisabled, rows, selectRowsAvailable, unselectAllRows]);

  const slotProps = {
    columnsPanel: {
      getTogglableColumns,
    },
  };

  const handleOnCellClick = useCallback(
    (params: GridCellParams<TalentGridRow>, event: React.MouseEvent | React.KeyboardEvent) => {
      const allowedFields = [
        "skillup_people_review_action",
        "assigned_actions",
        "skillup_people_review_comment",
        "skillup_people_review_manager_comment",
      ];

      if (!params.isEditable && !allowedFields.includes(params.field)) return;

      // Ignore portal
      if (
        event.target instanceof Element &&
        event.target.nodeType === Node.ELEMENT_NODE &&
        !event.currentTarget.contains(event.target)
      )
        return;

      const handler = {
        assigned_actions: () =>
          setState({
            kind: "assigningActionsV2",
            row: params.row,
          }),
        skillup_people_review_action: () =>
          setState({
            kind: "editingActions",
            row: params.row,
          }),
        skillup_people_review_comment: () =>
          setState({
            kind: "editingComment",
            row: params.row,
          }),
        skillup_people_review_manager_comment: () =>
          setState({
            kind: "editingComment",
            row: params.row,
          }),
      };

      if (allowedFields.includes(params.field) && params.field) handler[params.field]();

      if (currentEditedCell?.id !== params.id && currentEditedCell?.field !== params.field) {
        apiRef.current.startCellEditMode({
          id: params.id,
          field: params.field,
        });
        setCurrentEditedCell({ id: params.id, field: params.field });
      }
    },
    [setState, apiRef, currentEditedCell]
  );

  const handleCellModesModelChange = useCallback(
    (newModel: GridCellModesModel) => {
      if (currentEditedCell) {
        setCurrentEditedCell(undefined);
      }
    },
    [currentEditedCell]
  );

  const handleRowSelection = useCallback(
    (newRowModesModel: GridRowSelectionModel) => {
      setSelectedRows(newRowModesModel);
    },
    [setSelectedRows]
  );

  const { toggleableColumns } = useToggableColumns();

  const columnsConfig = useMemo(() => {
    const filteredColumns = (columnsToFilter) =>
      columnsToFilter
        .filter((c) => toggleableColumns.includes(c.field))
        .map((column) => ({ headerName: column.headerName, key: column.field }));

    const cols = hasManagerPrep
      ? filteredColumns(columns)
      : filteredColumns(columns.filter((c) => c.field !== "reviewManager"));

    return cols;
  }, [columns, hasManagerPrep, toggleableColumns]);

  useEffect(() => {
    if (columnsVisibilityModel && columnsConfig) {
      const columnsToExport = Object.entries(columnsVisibilityModel).reduce((acc, [key, value]) => {
        if (value && columnsConfig.find((c) => c.key === key) && key !== "reviewManager") {
          acc.push(key);
        }
        return acc;
      }, []);
      setColumnsToExport(columnsToExport);
    }
  }, [columnsVisibilityModel, columnsConfig, setColumnsToExport]);

  const toolbarActions = useMemo(() => {
    if (!campaign) return [];
    return [
      <SelectActions
        setState={setState}
        selectedRows={selectedRows}
        columnsConfig={columnsConfig}
        columnsVisibilityModel={columnsVisibilityModel}
        setColumnsVisibilityModel={setColumnsVisibilityModel}
      />,
    ];
  }, [columnsVisibilityModel, setColumnsVisibilityModel, selectedRows, columnsConfig, campaign]);

  const handleCellKeyDown = useCallback(
    (params: GridCellParams, e: React.KeyboardEvent) => {
      if (e.code === "Enter") handleOnCellClick(params, e);
    },
    [handleOnCellClick]
  );

  const entityName = useMemo(() => {
    return [
      getPluralizedLabel(campaign?.reviews.length ?? 0, "collaborateur", "collaborateurs"),
      getPluralizedLabel(campaign?.managerCount ?? 0, "manager", "managers"),
      getPluralizedLabel(campaign?.coordinators.length ?? 0, "coordinateur", "coordinateurs"),
      getPluralizedLabel(campaign?.observers.length ?? 0, "observateur", "observateurs"),
    ]
      .filter(Boolean)
      .join(" | ");
  }, [
    campaign?.coordinators.length,
    campaign?.managerCount,
    campaign?.observers.length,
    campaign?.reviews.length,
  ]);

  if (!persistenceID) return <></>; // Don't remove this, it will break columns persistence

  return (
    <>
      <DSDataGrid
        editable
        rows={rows}
        editMode="cell"
        apiRef={apiRef}
        columns={columns}
        slotProps={slotProps}
        entityName={entityName}
        disableRowSelectionOnClick
        initialState={initialState}
        paginationModel={pagination}
        toolbarButtons={toolbarActions}
        rowCount={totalEmployeeFieldsData}
        processRowUpdate={processRowUpdate}
        columnGroupingModel={groupingModel}
        isError={isErrorEmployeeFieldsData}
        loading={isLoadingEmployeeFieldsData}
        columnVisibilityModel={columnsVisibilityModel}
        experimentalFeatures={{ columnGrouping: true }}
        checkboxSelection={campaign?.permissions["mass-selection"]?.isVisible}
        persistenceId={persistenceID ? `talent-grid.v1.${persistenceID}` : undefined}
        onFilter={onColumnFilterClick}
        onPageChange={handlePageChange}
        onCellClick={handleOnCellClick}
        onCellKeyDown={handleCellKeyDown}
        onRowSelectionModelChange={handleRowSelection}
        onCellModesModelChange={handleCellModesModelChange}
        emptyOverlay={{
          button: {
            callback: () => apiRef?.current?.setFilterModel({ items: [] }),
            label: t("peopleReview.talentGrid.emptyBtnLabel", {
              defaultValue: "Réinitialiser les filtres",
            }),
          },
          text: t("peopleReview.talentGrid.emptyOverlayText", {
            defaultValue: "Aucun collaborateur ne correspond à vos filtres.",
          }),
        }}
        errorOverlay={{
          text: [
            t("peopleReview.talentGrid.errorOverlayText.firstSentence", {
              defaultValue: `Une erreur est survenue lors du chargement des collaborateurs.`,
            }),
            t("peopleReview.talentGrid.errorOverlayText.secondSentence", {
              defaultValue: `Veuillez réessayer ultérieurement.`,
            }),
            t("peopleReview.talentGrid.errorOverlayText.thirdSentence", {
              defaultValue: `Si l’erreur persiste, contactez votre interlocuteur Skillup.`,
            }),
          ],
        }}
      />
      <Modals
        state={state}
        apiRef={apiRef}
        setState={setState}
        processRowUpdate={processRowUpdate}
      />
    </>
  );
}

export const useTalentGridFilters = () => {
  const { allAreas } = useAreas();
  const { campaign } = useContext(SupervisionContext);
  const { getFields, getFilters } = useEmployeeFields();
  const { t } = useTranslation();
  const { enumerableFields } = useCoreHRFields();

  const config = useMemo(() => {
    const employeeFields = getFields();
    const employeeFilters = getFilters();

    return {
      [employeeFields.branch.key]: {
        type: employeeFilters.branch.type,
        label: t(employeeFields.branch.traductionKey, {
          defaultValue: employeeFields.branch.traductionDefaultValue,
        }),
      },
      [employeeFields.division.key]: {
        type: employeeFilters.division.type,
        label: t(employeeFields.division.traductionKey, {
          defaultValue: employeeFields.division.traductionDefaultValue,
        }),
      },
      [employeeFields.email.key]: {
        type: employeeFilters.email.type,
        label: t(employeeFields.email.traductionKey, {
          defaultValue: employeeFields.email.traductionDefaultValue,
        }),
      },
      [employeeFields.firstName.key]: {
        type: employeeFilters.firstName.type,
        label: t(employeeFields.firstName.traductionKey, {
          defaultValue: employeeFields.firstName.traductionDefaultValue,
        }),
      },
      [employeeFields.fullName.key]: {
        type: employeeFilters.fullName.type,
        label: t(employeeFields.fullName.traductionKey, {
          defaultValue: employeeFields.fullName.traductionDefaultValue,
        }),
      },
      [employeeFields.lastName.key]: {
        type: employeeFilters.lastName.type,
        label: t(employeeFields.lastName.traductionKey, {
          defaultValue: employeeFields.lastName.traductionDefaultValue,
        }),
      },
      [employeeFields.registrationNumber.key]: {
        type: employeeFilters.registrationNumber.type,
        label: t(employeeFields.registrationNumber.traductionKey, {
          defaultValue: employeeFields.registrationNumber.traductionDefaultValue,
        }),
      },
      [employeeFields.role.key]: {
        type: employeeFilters.role.type,
        label: t(employeeFields.role.traductionKey, {
          defaultValue: employeeFields.role.traductionDefaultValue,
        }),
      },
      [employeeFields.service.key]: {
        type: employeeFilters.service.type,
        label: t(employeeFields.service.traductionKey, {
          defaultValue: employeeFields.service.traductionDefaultValue,
        }),
      },
      [employeeFields.site.key]: {
        type: employeeFilters.site.type,
        label: t(employeeFields.site.traductionKey, {
          defaultValue: employeeFields.site.traductionDefaultValue,
        }),
      },
      perimeters: {
        type: ListUtils.FilterType.MULTISELECT,
      },
      reviewManager: {
        type: ListUtils.FilterType.MULTISELECT,
        label: "Manager",
      },
      status: {
        type: ListUtils.FilterType.MULTISELECT,
        label: t("review.status", {
          defaultValue: "Statut",
        }),
      },
      ...enumerableFields.reduce((acc, { uuid, fieldKey, label }) => {
        acc[uuid] = {
          type: ListUtils.FilterType.MULTISELECT,
          label: t(`review.field.${fieldKey}`, {
            defaultValue: label,
          }),
        };
        return acc;
      }, {}),
    };
  }, [getFields, t, getFilters, enumerableFields]);

  const labelsStatus = useMemo(() => {
    return {
      done: t("peopleReview.review.status.done", { defaultValue: "Arbitré" }),
      in_progress: t("peopleReview.review.status.inProgress", { defaultValue: "En cours" }),
      manager_prep: t("peopleReview.review.status.managerPrep", {
        defaultValue: "Préparation manager",
      }),
      no_manager_prep: t("peopleReview.review.status.noManagerPrep", {
        defaultValue: "Sans préparation manager",
      }),
    };
  }, [t]);

  const [{ conf, initialValues }, setConfig] = useState({
    conf: config,
    initialValues: {
      perimeters: {
        defaultValue: allAreas.map(({ uuid }) => uuid),
        initialValue: allAreas.map(({ uuid }) => uuid),
        options: allAreas.map(({ uuid, name }) => ({
          label: name,
          value: uuid,
        })),
        visibilityMode: "always",
      },
      reviewManager: {
        options: [],
        visibilityMode: "always",
      },
      status: {
        options: Object.values(coordinatorReviewStatuses).map((statusKey) => ({
          label: labelsStatus[statusKey],
          value: statusKey,
        })),
        visibilityMode: "always",
      },
      ...enumerableFields.reduce((acc, { uuid, scale }) => {
        acc[uuid] = {
          options: scale.options,
        };
        return acc;
      }, {}),
    } as UseFilterProp<typeof config>,
  });

  const managerIds = uniq(campaign?.reviews?.filter((r) => !!r.managerID).map((r) => r.managerID));
  const { employees: managers } = useEmployees({
    employeeUuids: managerIds,
    keys: ["fullName", "uuid"],
  });

  // TODO:  Turn this into a usememo
  useEffect(() => {
    if (!campaign) return;
    if (!managers?.length) return;
    if (initialValues.reviewManager.options.length) return;
    if (!campaign.reviews.length) return;

    const uniqManagers = uniqBy(
      campaign.reviews
        .filter((r) => !!r?.managerID)
        .map((r) => ({
          label: managers?.find((m) => m.uuid === r.managerID)?.fullName,
          value: r.managerID,
        })),
      "value"
    ).filter((m) => m.value != null);

    // This useEffect is used to update the managers filter which are loaded dynamically
    const newConf = {
      conf: { ...conf },
      initialValues: {
        ...initialValues,
        reviewManager: {
          ...config?.reviewManager,
          defaultValue: uniqManagers.map((m) => m.value),
          options: uniqManagers,
          visibilityMode: "always" as ListUtils.FilterVisibility,
        },
      },
    };

    setConfig(newConf);
  }, [campaign, allAreas, setConfig, conf, initialValues, config.reviewManager, managers]);

  const [filters, filterValues, setFilterValues] = useFilters(conf, initialValues);

  return [filters, filterValues, setFilterValues, config] as [
    typeof filters,
    typeof filterValues,
    typeof setFilterValues,
    typeof config,
  ];
};

function useToggableColumns() {
  const { getFields } = useEmployeeFields();

  const toggleableColumns = useMemo(() => {
    const employeeFields = getFields();
    return [
      employeeFields.firstName.key,
      employeeFields.lastName.key,
      employeeFields.role.key,
      employeeFields.email.key,
      employeeFields.division.key,
      employeeFields.service.key,
      employeeFields.site.key,
      employeeFields.joinDate.key,
      employeeFields.contract.key,
      employeeFields.branch.key,
      employeeFields.registrationNumber.key,
      employeeFields.areas.key,
      "reviewManager",
    ];
  }, [getFields]);

  return { toggleableColumns } as { toggleableColumns: string[] };
}

const defaultVisibility = {
  actions: true,
  areas: true,
  assignedActions: true,
  branch: false,
  contract: false,
  division: false,
  email: false,
  firstName: false,
  fullName: true,
  [GRID_CHECKBOX_SELECTION_COL_DEF.field]: true,
  joinDate: false,
  lastName: false,
  registrationNumber: false,
  reviewManager: true,
  role: false,
  service: false,
  site: false,
  skillup_people_review_comment: true,
  skillup_people_review_manager_comment: true,
  status: true,
};

/**
 * If you feel the need to delete this, please check that this issue is fixed https://github.com/mui/mui-x/issues/11494
 * AND merged in the version YOU are using.
 */
function useVisibilityModel(
  initialStateVisibility: { [key: string]: boolean } = defaultVisibility
) {
  const [columnsVisibilityModel, setColumnsVisibilityModel] = useState<{
    [key: string]: boolean;
  }>(initialStateVisibility);

  useEffect(() => {
    setColumnsVisibilityModel(initialStateVisibility);
  }, [initialStateVisibility]);

  return [columnsVisibilityModel, setColumnsVisibilityModel] as [
    typeof columnsVisibilityModel,
    typeof setColumnsVisibilityModel,
  ];
}
