import { Assignee } from "types/skills";

function parseEmployeeIntoRow(employee: Assignee) {
  const {
    uuid,
    branch,
    companyID: companyUuid,
    contract,
    division,
    email,
    firstName,
    fullName,
    jobID: job,
    jobName,
    lastName,
    registrationNumber,
    role,
    service,
    site,
    validators,
    version,
  } = employee;
  return {
    id: uuid,
    data: {
      uuid,
      branch,
      companyUuid,
      contract,
      division,
      email,
      firstName,
      fullName,
      job,
      jobName,
      lastName,
      registrationNumber,
      role,
      service,
      site,
      validators,
      version,
    },
  };
}
export default parseEmployeeIntoRow;

export type EmployeeRow = ReturnType<typeof parseEmployeeIntoRow>;
