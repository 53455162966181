import React from "react";
import { Button } from "@skillup/ui";

import history from "utils/history";

import styles from "./SummonForIntra.module.scss";
import Acta from "utils/Acta";

interface Props {
  uuid: string;
  trainingName: string;
  formattedTimespan: string;
}

export default ({ uuid, trainingName, formattedTimespan }: Props) => {
  const onClick = () => {
    Acta.dispatchEvent("closeModal");
    history.push(`/responsable/mes-sessions/en-cours/${uuid}`);
  };

  return (
    <div className={styles.SummonForIntra}>
      <div className={styles.container}>
        Pour convoquer la session,{" "}
        <b>
          {trainingName} {formattedTimespan.toLowerCase()}
        </b>
        , vous serez redirigé vers la section "Mes sessions".
      </div>
      <Button onClick={onClick} label="Convoquer" />
    </div>
  );
};
