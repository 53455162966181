import { useMemo, useState } from "react";

import { TableOfContent } from "@skillup/ui";

import { ManagerPortalForm, PortalForm } from "../../PortalForm";
import AdminPortalToggle, { PortalToggleType } from "../AdminPortalToggle/AdminPortalToggle";
import useAdminProperties, { saveChanges } from "../state/useAdminProperties";

import styles from "./PortalNeeds.module.scss";

export default function PortalNeeds() {
  const [active, setActive] = useState<string>();

  const { data, refetch } = useAdminProperties();

  const adminProperties = useMemo(() => {
    return data?.properties ?? null;
  }, [data]);

  const handleOnChange = async (body) => {
    await saveChanges(body);
    await refetch();
  };

  return (
    <div className={styles.PortalNeeds}>
      <div className={styles.content}>
        <div className={styles.toggleItem}>
          <AdminPortalToggle
            propertiesKey="portalUsersCollectionEnabled"
            label="Activer le recueil pour les collaborateurs"
            type={PortalToggleType.ENABLED}
            isSelected={adminProperties ? adminProperties["portalUsersCollectionEnabled"] : false}
            onChange={handleOnChange}
            description={
              "Permet à vos collaborateurs de faire des demandes de formation qui iront alimenter le plan actif."
            }
          />
        </div>

        <div className={styles.toggleItem}>
          <AdminPortalToggle
            propertiesKey="portalManagersCollectionEnabled"
            label="Activer le recueil pour les managers"
            type={PortalToggleType.ENABLED}
            isSelected={
              adminProperties ? adminProperties["portalManagersCollectionEnabled"] : false
            }
            onChange={handleOnChange}
            description={
              "Permet aux managers de faire des demandes de formation qui iront alimenter le plan actif."
            }
          />
        </div>

        <section>
          <h3 id="section-collaborateur" className={styles.title}>
            Espace collaborateur
          </h3>
          <PortalForm />
        </section>
        <section>
          <h3 id="section-manager" className={styles.title}>
            Espace manager
          </h3>
          <ManagerPortalForm />
        </section>
      </div>

      <div className={styles.tableOfContent}>
        <TableOfContent activeItem={active} onChangeItem={setActive}>
          <TableOfContent.Item label="Espace collaborateur" id="section-collaborateur" />
          <TableOfContent.Item label="Espace manager" id="section-manager" />
        </TableOfContent>
      </div>
    </div>
  );
}
