import React, { useRef, useCallback } from "react";
import { useMount, useUnmount } from "react-use";
import cx from "classnames";

import Acta from "utils/Acta";
import Icon from "components/Icon";
import InteractiveButton from "components/InteractiveButton";
import { cross as crossIcon } from "uiAssets/StrokeIcons";
import EasingEquations from "utils/EasingEquations";

import styles from "./AppModalStyles.module.scss";

export interface ModalProps {
  border: boolean;
  size: string;
  title: string;
  containerClassName?: string;
  dialogClassName?: string;
  noHeader: boolean;
  shouldNotClose: boolean;
  showOverflow?: boolean;
  content: any;
  modalType: "alert" | "confirm" | null;
  confirmCallback?: () => void;
  cancelCallback?: () => void;
}

const AppModal = (props: ModalProps) => {
  const {
    border,
    cancelCallback,
    confirmCallback,
    content,
    modalType,
    noHeader,
    size,
    title,
    containerClassName,
    dialogClassName,
    showOverflow,
    shouldNotClose,
  } = props;

  const background = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);

  const selfClose = useCallback(() => {
    if (background.current) {
      background.current.animate([{ opacity: 1 }, { opacity: 0 }], {
        duration: 100,
        fill: "forwards",
      });
    }

    if (container.current) {
      container.current.animate(
        [
          { transform: "translateY(0)", opacity: 1 },
          { transform: "translateY(300px)", opacity: 0 },
        ],
        {
          duration: 200,
          fill: "forwards",
        }
      ).onfinish = () => Acta.setState("modalDisplayed", null);
    }
  }, []);

  const backgroundClick = useCallback(() => {
    /** prevent background click on android as it gets triggered on input text touch */
    if (!shouldNotClose && window.navigator.userAgent.indexOf("Android") === -1) {
      Acta.dispatchEvent("closeModal");
    }
  }, [shouldNotClose]);

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.code === "Escape") {
      Acta.dispatchEvent("closeModal");
      event.preventDefault();
    }
  }, []);

  useMount(() => {
    document.addEventListener("keydown", escFunction, false);
    Acta.subscribeEventWithoutContext("closeModal", selfClose, "AppModal");

    if (background.current) {
      background.current.animate([{ opacity: 0 }, { opacity: 1 }], {
        duration: 200,
        fill: "forwards",
      });
    }

    if (container.current) {
      container.current.animate(
        [
          { transform: "translateY(300px)", opacity: 0 },
          { transform: "translateY(0)", opacity: 1 },
        ],
        {
          duration: 300,
          easing: EasingEquations.easeOutExpo,
          fill: "forwards",
        }
      );
    }
  });

  useUnmount(() => {
    document.removeEventListener("keydown", escFunction, false);
    Acta.unsubscribeEventWithoutContext("closeModal", "AppModal");
  });

  return (
    <div
      className={cx(styles.AppModalContainer, containerClassName, showOverflow && styles.extends)}
    >
      <div className={styles.background} ref={background} onClick={backgroundClick} />
      <div
        className={cx(
          styles.container,
          dialogClassName,
          modalType ? styles.small : styles[size] || styles.full,
          {
            [styles.confirm]: modalType === "confirm",
            [styles.alert]: modalType === "alert",
            [styles.noHeader]: noHeader,
            [styles.border]: border,
            [styles.showOverflow]: showOverflow,
          }
        )}
        ref={container}
      >
        {!noHeader && !modalType && (
          <header>
            <span>{title}</span>
            {!shouldNotClose && (
              <button onClick={() => selfClose()}>
                <Icon strokeIcon={crossIcon} width={12} fill="#666" />
              </button>
            )}
          </header>
        )}
        {!modalType && <main>{content}</main>}
        {modalType === "alert" && (
          <main>
            {content}
            <InteractiveButton size="small" label="OK" onClick={selfClose} />
          </main>
        )}
        {modalType === "confirm" && (
          <main>
            {content}
            <div>
              <InteractiveButton
                size="small"
                label="Confirmer"
                onClick={() => {
                  if (confirmCallback) confirmCallback();
                  return selfClose();
                }}
              />
              <InteractiveButton
                size="small"
                label="Annuler"
                onClick={() => {
                  if (cancelCallback) cancelCallback();
                  return selfClose();
                }}
                background="#999"
              />
            </div>
          </main>
        )}
      </div>
    </div>
  );
};

export default AppModal;
