import * as z from "zod";

export const SkillSchema = z.object({
  skillName: z
    .string({
      required_error: "Ce champ est obligatoire",
    })
    .trim()
    .nonempty({ message: "Ce champ ne doit pas être vide" }),
  evaluationScale: z.object({
    uuid: z.string(),
    name: z.string(),
    levels: z.array(
      z.object({
        uuid: z.string(),
        name: z.string(),
        level: z.number(),
        description: z.string().trim().optional(),
      })
    ),
  }),
  fields: z.array(
    z.object({
      uuid: z.string().uuid(),
      value: z.string().trim().optional(),
      name: z.string(),
      index: z.number(),
    })
  ),
  categoryUuid: z.string().uuid().optional(),
  description: z.string().trim().optional(),
});

export const UpdateSkillSchema = SkillSchema.merge(
  z.object({
    uuid: z.string(),
  })
);
