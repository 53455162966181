import { DateTime, FormatDate, ParseDate } from "@skillup/shared-utils";
import { IUpdateProjectData } from "@skillup/espace-rh-bridge";
import { GetSessionReviewsOutput } from "@skillup/training-bridge";

import { TranslationType } from "hooks/useTranslation";
import { parseDateRange } from "utils/dates/dates";

import { FormDate, State } from "../types";
import { AssistiveErrorText } from "../components";

function generateSessionReferenceRandomPart() {
  return `${Math.floor(Math.random() * 1000)}`.padStart(3, "0");
}

export function generateReference(
  programRef?: string,
  sessionDate: string = new Date().toISOString()
) {
  if (!programRef) {
    return undefined;
  }

  const year = new Date(sessionDate).getFullYear().toString().slice(2);
  const randomNumber = generateSessionReferenceRandomPart();

  let reference = programRef;
  if (programRef.length > 15) {
    reference = reference.slice(0, 15);
  }

  return `${year}-${reference}-${randomNumber}`;
}

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d as any);
}

export function formatDates(dates: FormDate[]): string[] {
  return dates
    .sort((a, b) => {
      const aStartDate = a.dateRange[0];
      const bStartDate = b.dateRange[0];

      const aStartHour = a.hourRange?.[0];
      const bStartHour = b.hourRange?.[0];

      if (!isValidDate(aStartDate) || !isValidDate(bStartDate)) {
        return 0;
      }
      const dateDiff = aStartDate.getTime() - bStartDate.getTime();

      if (dateDiff === 0 && isValidDate(aStartHour) && isValidDate(bStartHour)) {
        return aStartHour.getTime() - bStartHour.getTime();
      }

      return dateDiff;
    })
    .map(({ dateRange, hourRange, allDay }) => {
      const formatISOTime = (date: Date) => date.toISOString().split("T")[1].slice(0, 8) + ".000Z";

      const startDate = dateRange?.[0];
      const endDate = dateRange?.[1];
      const startTime = hourRange?.[0];
      const endTime = hourRange?.[1];

      const formattedStartDate = FormatDate.ToISOShort(
        ParseDate.FromParsableJS(startDate.toString())
      );
      const formattedEndDate = FormatDate.ToISOShort(ParseDate.FromParsableJS(endDate.toString()));

      const formattedStartTime = !allDay && startTime ? `T${formatISOTime(startTime)}` : "";
      const formattedEndTime = !allDay && endTime ? `T${formatISOTime(endTime)}` : "";

      return `${formattedStartDate}${formattedStartTime}/${formattedEndDate}${formattedEndTime}`;
    });
}

export function formatPayloadForCreation(state: State) {
  const dates = formatDates(state.dates);

  return {
    sessionData: {
      dates,
      city: state.city,
      total: state.price.type === "total" ? state.price.value : null,
      totalWithTax: state.price.type === "total" ? state.priceWithTax : null,
      price: state.price.type === "perTrainee" ? state.price.value : null,
      priceWithTax: state.price.type === "perTrainee" ? state.priceWithTax : null,
      areas: state.areas,
      stock: state.max ?? null,
      minStock: state.min ?? null,
      trainers: state.trainers.map(({ uuid }) => uuid),
      room: state.room,
      reference: state.reference,
      link: state.link,
      evalGroupsGenerated: state.evalGroupsGenerated,
    },
    intraUuid: state.intra.uuid,
  };
}

export function formatPayloadForUpdate(state: State): IUpdateProjectData {
  const dates = formatDates(state.dates);

  return {
    city: state.city,
    dates,
    pricePerTrainee: state.price.type === "perTrainee" ? state.price.value : null,
    pricePerTraineeWithTax: state.price.type === "perTrainee" ? state.priceWithTax : null,
    bookingPrice: state.price.type === "total" ? state.price.value : null,
    bookingPriceWithTax: state.price.type === "total" ? state.priceWithTax : null,
    stock: state.max ?? null,
    minStock: state.min ?? null,
    selectedAreas: state.areas,
    selectedTrainers: state.trainers.map(({ uuid }) => uuid),
    room: state.room,
    link: state.link,
    reference: state.reference,
    evalGroupsGenerated: state.evalGroupsGenerated,
    isPostponed: state.isPostponed,
  };
}
export function manageDatesError(date: FormDate, t: TranslationType) {
  const [startDate, endDate] = date.dateRange || [];
  if (!isValidDate(startDate) || !isValidDate(endDate)) return "";

  if (startDate.getTime() > endDate.getTime()) {
    return (
      <AssistiveErrorText
        errorText={t("supervisor.sessions.errors.endDateBefore", {
          defaultValue:
            "La date de fin doit être postérieure ou égale à la date de début de session",
        })}
      />
    );
  }

  if (!date.allDay && startDate.getTime() === endDate.getTime()) {
    const [startHour, endHour] = date.hourRange || [];

    if (!isValidDate(startHour) || !isValidDate(endHour)) return "";

    if (startHour.getHours() === endHour.getHours()) {
      if (startHour.getMinutes() >= endHour.getMinutes()) {
        return (
          <AssistiveErrorText
            errorText={t("supervisor.sessions.errors.endHourBefore", {
              defaultValue: "L’heure de fin doit être postérieure à l’heure de début de session",
            })}
          />
        );
      }
    }
    if (startHour.getHours() > endHour.getHours()) {
      return (
        <AssistiveErrorText
          errorText={t("supervisor.sessions.errors.endHourBefore", {
            defaultValue: "L’heure de fin doit être postérieure à l’heure de début de session",
          })}
        />
      );
    }
  }

  return "";
}

export function processTimeError(
  index: number,
  timeError: { [key: number]: boolean },
  dateRange: Date[],
  hourRange?: Date[],
  allDay = false
) {
  if (allDay || !isValidDate(dateRange?.[0]) || !isValidDate(dateRange?.[1])) {
    return {
      ...timeError,
      [index]: false,
    };
  }

  if (
    dateRange[0].getTime() === dateRange[1].getTime() &&
    isValidDate(hourRange?.[0]) &&
    isValidDate(hourRange?.[1])
  ) {
    return {
      ...timeError,
      [index]:
        hourRange[0].getHours() > hourRange[1].getHours() ||
        (hourRange[0].getHours() === hourRange[1].getHours() &&
          hourRange[0].getMinutes() >= hourRange[1].getMinutes()),
    };
  }

  return {
    ...timeError,
    [index]: false,
  };
}

export function formatToDate({ date, hours }: { date: string; hours?: string }): Date {
  const fromZone = "Europe/Paris";

  const parsedDate = (date: string, format: string) => {
    const localDate = DateTime.fromFormat(date, format, {
      zone: fromZone,
    });
    const targetDate = localDate.toLocal();
    return targetDate.toJSDate();
  };

  if (hours) {
    return parsedDate(`${date} ${hours}`, "dd/MM/yyyy H:mm");
  }

  return parsedDate(date, "dd/MM/yyyy");
}

export function parsedDates(intervals: string[][]) {
  return intervals.map((interval) => {
    const intervalInfos = parseDateRange(interval, {
      dateFormat: "DD/MM/YYYY",
      hourFormat: "H:mm",
    });
    const { days, hours } = intervalInfos;
    const [startDay, endDay = startDay] = days;

    if (!hours) {
      return {
        dateRange: [formatToDate({ date: startDay }), formatToDate({ date: endDay })],
        allDay: true,
      };
    }
    if (hours) {
      const [startHour, endHour = startHour] = hours;
      return {
        dateRange: [formatToDate({ date: startDay }), formatToDate({ date: endDay })],
        hourRange: [
          formatToDate({ date: startDay, hours: startHour }),
          formatToDate({ date: endDay, hours: endHour }),
        ],
        allDay: false,
      };
    }

    return { dateRange: [new Date(), new Date()], allDay: true };
  });
}

export function disableReviewsCheckbox(reviewsState: GetSessionReviewsOutput) {
  if (reviewsState === undefined || reviewsState.evalGroupsGenerated === true) {
    if (
      reviewsState.hotReviewsAlreadyGenerated === true &&
      reviewsState.companyHasColdReviews === false
    )
      return true;
    if (
      reviewsState.hotReviewsAlreadyGenerated === true &&
      reviewsState.coldReviewsAlreadyGenerated === true
    )
      return true;
  }
  return false;
}

export function onlyColdReviewsWouldBeDisabled(reviewsState: GetSessionReviewsOutput) {
  return (
    reviewsState?.companyHasColdReviews === true &&
    reviewsState?.hotReviewsAlreadyGenerated === true &&
    reviewsState?.coldReviewsAlreadyGenerated === false
  );
}
