import React from "react";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSModal } from "@skillup/ui";
import { Future } from "@skillup/monads";

import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";
import { AuthStrategy, OrganizationRoutes } from "@skillup/espace-rh-bridge";
import Acta from "utils/Acta";

type Props = {
  onClose: () => void;
  authStrategy: AuthStrategy;
};

const deleteAuthStrategy = async (companyUuid: string, authStrategyUuid: string) => {
  const request = buildSafeRequest<OrganizationRoutes.DeleteAuthStrategy>({
    method: "DELETE",
    path: `/organization/{companyUuid}/auth-strategies/{authStrategyUuid}`,
    params: { companyUuid, authStrategyUuid },
  });

  return request.run();
};

export default ({ onClose, authStrategy }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const credentials = Acta.getState("userData");

  const submitRequest = async () => {
    const request = deleteAuthStrategy(credentials.activeCompany.uuid, authStrategy.uuid);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Une erreur est survenue lors de la suppression de l'auth provider.",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      (_authProvider) => {
        addToast(
          t("authprovider.delete.success", {
            defaultValue: "Auth provider supprimé avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Supprimer l'auth provider" />
      </DSModal.Header>

      <DSModal.Content>
        <label>Êtes-vous sûr de vouloir supprimer cet auth provider ?</label>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={onClose} label="Annuler" />
        <DSModal.Footer.PrimaryButton onClick={() => submitRequest()} label="Supprimer" />
      </DSModal.Footer>
    </DSModal>
  );
};
