import { useSetState } from "react-use";
import React, { useState, useEffect } from "react";

import { cloneDeep } from "lodash";
import { useRecoilValue } from "recoil";

import type { JobsRoutes } from "types/api";
import TextInput from "components/TextInput";
import { jobFieldsAtom } from "containers/Supervisor/routes/Skills/Legacy/state/jobs";

import styles from "./Details.module.scss";

type JobsFieldsRoute = JobsRoutes["Jobs.Fields.GetList"];
type FieldWithData = JobsFieldsRoute["response"]["jobFields"][0] & {
  value?: string;
};

export interface JobInitialData {
  title: string;
  fieldsMap: Record<string, string>;
}

export interface JobDetails {
  title: string;
  fieldsWithData: FieldWithData[];
}

export interface DetailsProps {
  initialData?: JobInitialData;
  onChange: (newDetails: JobDetails) => void;
}

const Details = ({ initialData, onChange }: DetailsProps) => {
  const [dataLoadStatus, setDataLoadStatus] = useState<"loaded" | "loading">("loading");
  const [details, setJobDetails] = useSetState<JobDetails>();
  const jobFields = useRecoilValue(jobFieldsAtom);

  const changeFieldValue = (newValue: string, fieldIndex: number) => {
    const newFieldsWithData = cloneDeep(details?.fieldsWithData ?? []);
    newFieldsWithData[fieldIndex].value = newValue;
    setJobDetails({
      fieldsWithData: newFieldsWithData,
    });
  };

  useEffect(() => {
    if (dataLoadStatus === "loaded" || !jobFields) return;

    const detailsFields: FieldWithData[] = jobFields?.map((field) => ({
      ...field,
      value: initialData?.fieldsMap[field.uuid],
    }));

    setJobDetails({
      fieldsWithData: detailsFields,
      title: initialData?.title,
    });

    setDataLoadStatus("loaded");
  }, [jobFields, initialData, setJobDetails, dataLoadStatus, setDataLoadStatus]);

  useEffect(() => {
    onChange(details);
  }, [details, onChange]);

  return (
    <div className={styles.details}>
      <TextInput
        alwaysOpen
        showRequired
        name="label"
        label="Titre"
        aria-label="titre"
        defaultValue={details?.title}
        placeholder="Titre de la fiche de poste"
        onChange={(e) => setJobDetails({ title: e.currentTarget.value })}
      />
      {details?.fieldsWithData?.map((fieldWithData, fieldIndex) => (
        <TextInput
          alwaysOpen
          autoComplete="off"
          key={fieldWithData.uuid}
          label={fieldWithData.label}
          defaultValue={fieldWithData?.value}
          aria-label={`champ-${fieldWithData.label}`}
          onChange={(e) => changeFieldValue(e.currentTarget.value, fieldIndex)}
        />
      ))}
    </div>
  );
};

export default Details;
