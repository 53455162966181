import { Flex } from "@skillup/ui";
import { CardProps } from "./Card";

export const KeyIndicator = ({ data, card, loading }: CardProps) => {
  return (
    <Flex>
      <p>{card.short_name}</p>

      {loading && <p>Loading...</p>}
      {JSON.stringify(data)}
    </Flex>
  );
};
