import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function startCampaign(campaignID: string) {
  const response = await buildRequest<CampaignsRoutes.States["start"]>({
    method: "PUT",
    params: {
      campaignID,
    },
    path: "/campaign/{campaignID}/actions/start",
    target: "PEOPLE_REVIEW",
  })();

  return response;
}

export async function pauseCampaign(campaignID: string) {
  const response = await buildRequest<CampaignsRoutes.States["pause"]>({
    method: "PUT",
    params: {
      campaignID,
    },
    path: "/campaign/{campaignID}/actions/pause",
    target: "PEOPLE_REVIEW",
  })();

  return response;
}

export async function closeCampaign(campaignID: string) {
  const response = await buildRequest<CampaignsRoutes.States["stop"]>({
    method: "PUT",
    params: {
      campaignID,
    },
    path: "/campaign/{campaignID}/actions/stop",
    target: "PEOPLE_REVIEW",
  })();

  return response;
}

export async function resumeCampaign(campaignID: string) {
  const response = await buildRequest<CampaignsRoutes.States["resume"]>({
    method: "PUT",
    params: {
      campaignID,
    },
    path: "/campaign/{campaignID}/actions/resume",
    target: "PEOPLE_REVIEW",
  })();

  return response;
}
