export const decorationColors = [
  "Pink",
  "Salmon",
  "Gold",
  "Peach",
  "Tan",
  "Forest",
  "Lime",
  "Sky",
  "Steel",
  "Turquoise",
  "Plum",
  "Violet",
  "Misty",
  "Silver",
  "Grey",
] as const;


export const DSColor = {
  // Action
  "action-notification": "#ff3d33",
  "action-onDark-darker": "#f6b604",

  "action-onDark-darkest": "#e1a809",
  "action-onDark-default": "#ffbb00",
  "action-onDark-lighter": "#ffc21a",
  "action-onDark-lightest": "#ffdb80",
  "action-onLight-darker": "#0076ad",
  "action-onLight-darkest": "#00547a",

  "action-onLight-default": "#0088c7",
  "action-onLight-lighter": "#7ec2e2",
  "action-onLight-lightest": "#c0e2f1",
  "border-onDark": "rgba(255, 255, 255, 0.4)",
  // Border
  "border-onLight": "rgba(105, 121, 140, 0.2)",

  // Brand
  "brand-blue": "#60c3d7",
  "decoration-blue-base": "#60c3d7",

  "decoration-blue-darker": "#4490a7",
  "decoration-blue-lighter": "#ceedf3",
  // Decoration
  "decoration-blue-lightest": "#f3fbfc",
  "decoration-green-base": "#99cd32",
  "decoration-green-darker": "#6b9627",
  "decoration-green-lighter": "#e1f0c1",
  "decoration-green-lightest": "#f8fbf0",
  "decoration-orange-base": "#ff6600",
  "decoration-orange-darker": "#b24a00",
  "decoration-orange-lighter": "#ffd1b3",
  "decoration-orange-lightest": "#fff4ec",

  "decoration-pink-base": "#fe9fd8",
  "decoration-pink-darker": "#b375a7",
  "decoration-pink-lighter": "#ffe0f2",
  "decoration-pink-lightest": "#fff7fc",
  "decoration-red-base": "#f4645d",
  "decoration-red-darker": "#ad4a48",
  "decoration-red-lighter": "#fcd2cf",
  "decoration-red-lightest": "#fef4f3",
  "decoration-yellow-base": "#ffbb00",
  "decoration-yellow-darker": "#b28600",
  "decoration-yellow-lighter": "#feedb4",
  "decoration-yellow-lightest": "#fffbec",

  "effects-hover-onDark": "rgba(5, 38, 56, 0.5)",
  // Effects
  "effects-hover-onLight": "rgba(193, 207, 220, 0.3)",

  "elevation-actionableCard": "0px 2px 4px 0px rgba(12, 92, 136, 0.15)",
  "elevation-actionableCard-hover": "2px 4px 8px rgba(12, 92, 136, 0.2)",
  "elevation-alert": "0px 2px 4px rgba(12, 92, 136, 0.15)",
  "elevation-dataTable": "0px 0px 2px rgba(12, 92, 136, 0.15)",
  "elevation-datePicker": "0px 0px 2px rgba(12, 92, 136, 0.15)",

  "elevation-listItem": "0px 2px 4px rgba(12, 92, 136, 0.15)",
  "elevation-listItem-hover": "2px 4px 8px rgba(12, 92, 136, 0.2)",
  "elevation-menu": "0px 4px 16px rgba(0, 0, 0, 0.08)",
  // Elevations (box-shadow)
  "elevation-modal": "0px 4px 16px rgba(0, 0, 0, 0.08)",
  "elevation-nonActionableCard": "0px 0px 2px rgba(12, 92, 136, 0.15)",
  "espace-rh-text": "black",
  "espace-rh-text-darkMode": "white",
  "greyscale-dark": "#909aa7",
  "greyscale-darker": "#1a304d",
  "greyscale-light": "#e5e8eb",
  // Greyscale
  "greyscale-lighter": "#f6f7f8",
  "greyscale-medium": "#c9ced4",
  "panel-shadow-above": "0px -4px 4px rgba(0, 0, 0, 0.1)",
  // Panel shadows (box-shadow)
  "panel-shadow-below": "0px 4px 4px rgba(0, 0, 0, 0.1)",
  "panel-shadow-left": "-4px 0px 4px rgba(0, 0, 0, 0.1)",
  "panel-shadow-right": "4px 0px 4px rgba(0, 0, 0, 0.1)",
  "placeholder-onLight": "#9aa7b8",
  "plainText-onDark-darker": "#c1cfdc",
  "plainText-onDark-default": "white",
  "plainText-onLight-default": "#1a304d",
  // Plain text
  "plainText-onLight-lighter": "#69798c",
  "plainText-onLight-placeholder": "rgba(26, 48, 77, 0.5)",
  "status-error-darker": "#db0b00",
  "status-error-default": "#ff3d33",

  "status-error-lighter": "#fad3d1",

  // Status
  "status-error-lightest": "#fdeded",
  "status-success-darker": "#005223",
  "status-success-default": "#0eaf84",
  "status-success-lighter": "#9eead6",
  "status-success-lightest": "#d9f7f0",
  "status-warning-darker": "#b74d01",
  "status-warning-default": "#fe9d58",
  "status-warning-lighter": "#f8d9c4",
  "status-warning-lightest": "#fcf1e8",
  "surface-dark": "#0c5d88",

  "surface-dark-darker": "#0a4d71",
  // Surface
  "surface-light": "#ffffff",
  "surface-light-darker": "#fafafa",
  "surface-scrim": "rgba(0, 0, 0, 0.6)",
};

export type ColorsValues = keyof typeof DSColor;

