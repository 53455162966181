const useEnvironment = () => {
  return {
    get isProduction() {
      return process.env.REACT_APP_ENV === "production";
    },
    get isDevelopment() {
      return process.env.NODE_ENV === "development";
    },
  };
};

export default useEnvironment;
