import React from "react";
import EasingEquations from "utils/EasingEquations";

import styles from "./DropDownActionMenuStyles.module.scss";

interface IProps {
  actions: Array<{
    action: () => void;
    label: string;
    actionKey?: string;
  } | null>;
}

export default class Actions extends React.PureComponent<IProps, {}> {
  private actionMenu: HTMLElement | null;

  public open(): void {
    if (!this.actionMenu) return;

    this.actionMenu.animate(
      [
        { opacity: 1, transform: "translateY(0)" },
        { opacity: 0, transform: "translateY(50px)" },
      ],
      {
        duration: 150,
        easing: EasingEquations.easeInOutSine,
        fill: "forwards",
      }
    );
  }

  public componentDidMount(): void {
    if (!this.actionMenu) return;

    this.actionMenu.animate(
      [
        { opacity: 0, transform: "translateY(50px)" },
        { opacity: 1, transform: "translateY(0)" },
      ],
      {
        duration: 150,
        easing: EasingEquations.easeInOutSine,
        fill: "forwards",
      }
    );
  }

  public componentWillUnmount(): void {
    if (!this.actionMenu) return;

    this.actionMenu.animate(
      [
        { opacity: 1, transform: "translateY(0)" },
        { opacity: 0, transform: "translateY(50px)" },
      ],
      {
        duration: 150,
        easing: EasingEquations.easeInOutSine,
        fill: "forwards",
      }
    );
  }

  public render(): JSX.Element {
    const { actions = [] } = this.props;

    return (
      <div className={styles.Actions} ref={(el) => (this.actionMenu = el)}>
        {actions.map((a) => {
          if (!a || !a.label || !a.action) return false;

          return (
            <div onClick={a.action} key={a.label} data-action={a.actionKey || ""}>
              {a.label}
            </div>
          );
        })}
      </div>
    );
  }
}
