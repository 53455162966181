import React, { useRef, useState, useEffect, type HTMLAttributes } from "react";

import { TranslationType } from "types";

import { useModal } from "@skillup/ui";

import { SidePanel } from "./SidePanel";
import { Text, Flex, Title } from "../../styled";
import { SkillRowWrapper } from "./SkillRowWrapper";
import { type Skill, type SidePanelPropsType } from "./types";
import {
  LevelName,
  BoardLayout,
  UpperLegend,
  LowerLegend,
  CategoryName,
  EmptyContainer,
  CenteredEllipsisText,
  EvaluationLevelContainer,
} from "./EvaluationBoard.styled";

export interface EvaluationBoardProps extends HTMLAttributes<HTMLDivElement> {
  readonly anchor?: string;
  readonly jobName: string;
  readonly t: TranslationType;
  readonly categories: Array<{
    readonly name: string;
    readonly skills: Array<Skill>;
  }>;
}

const groupSkillsByEvaluationScaleLevels = (skills: Array<Skill>) => {
  const groupedSkills: Record<string, Array<Skill>> = {};

  skills.forEach((skill) => {
    const key = skill.evaluationScaleLevels.map((level) => level.uuid).join("-");

    if (!groupedSkills[key]) {
      groupedSkills[key] = [];
    }

    const maxEmployeesLength = Math.max(
      ...skill.evaluationScaleLevels.map((level) => level.employees.length)
    );

    const updatedSkill = {
      ...skill,
      evaluationScaleLevels: skill.evaluationScaleLevels.map((level) => ({
        ...level,
        isMostAssigned: level.employees.length === maxEmployeesLength,
      })),
    };

    groupedSkills[key].push(updatedSkill);
  });

  return Object.entries(groupedSkills).map(([key, skills]) => ({ key, skills }));
};

export const EvaluationBoard = ({ anchor, categories, jobName, t }: EvaluationBoardProps) => {
  const sidePanelState = useModal();
  const [sidePanelProps, setSidePanelProps] = useState<undefined | SidePanelPropsType>();

  const openSidePanelWithEmployees = (props: SidePanelPropsType) => {
    setSidePanelProps({ ...props, jobName });
    sidePanelState.show();
  };

  const hasAtLeastOneExpectedValue = categories.some((category) =>
    category.skills.some((skill) => skill.levelExpected)
  );

  const refs = useRef(
    categories.reduce((acc, category) => {
      category.skills.forEach((skill) => {
        acc[skill.uuid] = React.createRef();
      });
      return acc;
    }, {})
  );

  useEffect(() => {
    if (anchor && refs.current[anchor] && refs.current[anchor].current) {
      const element = refs.current[anchor].current;
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [anchor]);

  return (
    <BoardLayout>
      <Title h4 color="plainText-onLight-lighter">
        {t("skills.dashboard.boardTitle", {
          defaultValue: "Répartition des évaluations",
        })}
      </Title>
      {hasAtLeastOneExpectedValue && (
        <Flex marginTop="s">
          <Flex marginRight="s" alignItems="center">
            <LowerLegend />
            <Text>
              {t("skills.dashboard.lowerLegend", {
                defaultValue: "Inférieur au niveau attendu",
              })}
            </Text>
          </Flex>

          <Flex alignItems="center">
            <UpperLegend />
            <Text>
              {t("skills.dashboard.upperLegend", {
                defaultValue: "Supérieur ou égal au niveau attendu",
              })}
            </Text>
          </Flex>
        </Flex>
      )}

      {categories.map((category) => {
        const groupedSkills = groupSkillsByEvaluationScaleLevels(category.skills);

        return (
          <Flex key={category.name} flexDirection="column">
            {groupedSkills.map((group, index) => (
              <Flex key={index} flexDirection="column">
                <Flex marginTop="s">
                  {index === 0 ? (
                    <CategoryName>{category.name}</CategoryName>
                  ) : (
                    <EmptyContainer> </EmptyContainer>
                  )}
                  <EvaluationLevelContainer>
                    {group.skills[0].evaluationScaleLevels.map((level) => (
                      <LevelName key={level.uuid}>
                        <CenteredEllipsisText>{level.name}</CenteredEllipsisText>
                      </LevelName>
                    ))}
                  </EvaluationLevelContainer>
                </Flex>
                {group.skills.map((skill) => (
                  <span key={skill.uuid} ref={refs.current[skill.uuid]}>
                    <SkillRowWrapper
                      skill={skill}
                      anchor={anchor}
                      openSidePanelWithEmployees={openSidePanelWithEmployees}
                    />
                  </span>
                ))}
              </Flex>
            ))}
            {sidePanelState.isOpen && (
              <SidePanel
                t={t}
                isOpen={sidePanelState.isOpen}
                sidePanelProps={sidePanelProps}
                close={() => sidePanelState.hide()}
              />
            )}
          </Flex>
        );
      })}
    </BoardLayout>
  );
};
