import { StrictMode, useState } from "react";
import { useMount } from "react-use";
import { render } from "react-dom";
import { Router } from "react-router";
import { Provider } from "react-redux";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import "intl-pluralrules";
import smoothscroll from "smoothscroll-polyfill";
import "moment";
import "moment/locale/fr";
import { analytics } from "services";
import { store } from "store";

import { LicenseInfo } from "@mui/x-data-grid-pro";

import { DesignSystemContextProvider, Themes } from "@skillup/ui";
import { useQuery } from "@skillup/hooks";
import "@skillup/ui/build/index.css";

import checkAuthentication from "utils/checkAuthentication";
import history from "utils/history";
import "utils/Sentry";
import Loader from "components/Loader";
import App from "containers/App";

import "./i18n";
import "./rum";

// DataGrid license
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY ?? "");

// Prevent further exec with IE<11
if (navigator.appVersion.indexOf("MSIE") !== -1) {
  throw new Error("IE<11 not supported");
}

// Polyfills
smoothscroll.polyfill();

history.listen(({ pathname, search }) => {
  analytics.sendPageview(pathname, search, document.title);
});

const RenderRoutes = () => {
  function Root() {
    const query = useQuery<{ [T: string]: string }>();
    const [loading, setLoading] = useState(true);

    useMount(() => {
      checkAuthentication(query).then(() => setLoading(false));
    });

    return loading ? <Loader style={{ height: "100vh" }} /> : <App />;
  }

  render(
    <StrictMode>
      <Provider store={store}>
        <DesignSystemContextProvider
          context={{
            theme: Themes.ESPACE_RH,
          }}
        >
          <Router history={history}>
            <Root />
          </Router>
        </DesignSystemContextProvider>
      </Provider>
    </StrictMode>,
    document.getElementById("root")
  );
};

RenderRoutes();
