import { useCallback } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import { z } from "zod";
import {
  Label as DSLabel,
  DSButton,
  DSNumberInput,
  DSFormGroupTextInput,
  Flex,
  DSTooltip,
} from "@skillup/ui";

import { selectHighlightUuid } from "../../../../reducer";
import { useDebounce } from "../../../../../utils";
import { Permissions } from "../../Permissions/Permissions";
import { Divided } from "../ChildWrappers";

import { TableChild, TableColumn } from "./types";
import { TableChildColumn } from "./components/TableChildColumn";
import { BuilderFormInput } from "../../BuilderFormInput";

import styles from "./TableChild.module.scss";

const NEW_COLUMN_DEFAULT_TITLE = "Nouvelle colonne";
const NEW_COLUMN_DEFAULT_TYPE = "text";
const NEW_COLUMN_DEFAULT_DISALLOW = {
  employee: { reply: false },
  manager: { reply: true },
};

const rowsCountSchema = z.coerce.number().int().min(0).max(20);

type TableChildProps = {
  child: TableChild;
  updateStructure: (data: TableChild) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
};

export const Table = ({ child, updateStructure, indexes }: TableChildProps) => {
  const highlightUuid = useSelector(selectHighlightUuid);
  const handleChangeLabel = useCallback(
    (value: string) => {
      updateStructure({ ...child, label: value });
    },
    [child, updateStructure]
  );

  const handleChangeRowsCount = useCallback(
    (value: string) => {
      const parsedValue = rowsCountSchema.safeParse(value);
      if (!parsedValue.success) {
        return;
      }
      const newRowsCount = parsedValue.data;
      const newColumns = child.columns.map((column) => {
        if (column.type === "fixed") {
          const newValues = (() => {
            if (column.values.length < newRowsCount) {
              return column.values.concat(new Array(newRowsCount - column.values.length).fill(""));
            }
            if (column.values.length > newRowsCount) {
              return column.values.slice(0, newRowsCount);
            }
            return column.values;
          })();

          return {
            ...column,
            values: newValues,
          };
        }

        return column;
      });
      updateStructure({ ...child, rowsCount: parseInt(value), columns: newColumns });
    },
    [child, updateStructure]
  );

  const handleChangeDescription = useCallback(
    (value: string) => {
      updateStructure({ ...child, description: value });
    },
    [child, updateStructure]
  );

  const removeDescription = useCallback(() => {
    updateStructure({ ...child, description: undefined });
  }, [child, updateStructure]);

  const addColumn = useCallback(() => {
    const newColumns = child.columns.concat({
      uuid: uuid(),
      title: NEW_COLUMN_DEFAULT_TITLE,
      type: NEW_COLUMN_DEFAULT_TYPE,
      disallow: NEW_COLUMN_DEFAULT_DISALLOW,
    });
    updateStructure({ ...child, columns: newColumns });
  }, [child, updateStructure]);

  const handleDeleteColumn = useCallback(
    (index: number) => {
      const newColumns = [...child.columns];
      newColumns.splice(index, 1);
      updateStructure({ ...child, columns: newColumns });
    },
    [child, updateStructure]
  );

  const handleUpdateColumn = useCallback(
    (index: number, column: TableColumn) => {
      const newColumns = [...child.columns];
      newColumns[index] = column;
      updateStructure({ ...child, columns: newColumns });
    },
    [child, updateStructure]
  );
  const moveColumnUpOrDown = useCallback(
    (index: number, direction: "up" | "down") => {
      const newColumns = [...child.columns];
      const column = newColumns[index];
      newColumns.splice(index, 1);
      newColumns.splice(direction === "up" ? index - 1 : index + 1, 0, column);
      updateStructure({ ...child, columns: newColumns });
    },
    [child, updateStructure]
  );
  const debouncedHandleUpdateColumn = useDebounce({
    debounceValue: 50,
    onChange: handleUpdateColumn,
  });
  return (
    <Flex column>
      <Divided>
        <Flex column className={styles.table__general}>
          <div>
            <BuilderFormInput
              type="textarea"
              value={child.label}
              onChange={handleChangeLabel}
              debounceValue={300}
              label="Libellé du tableau"
              name={`table-child-${child.uuid}-label`}
              placeholder="Saisissez le libellé du tableau"
              autoFocus={highlightUuid === child.uuid}
            />
          </div>
          <div className={styles.table__general__assistiveText}>
            {child.description !== undefined ? (
              <div>
                <div className={styles.table__general__description}>
                  <DSLabel label="Description du tableau" />
                  <DSButton
                    label={"Supprimer la description"}
                    className={styles["table__general_button--underline"]}
                    onClick={removeDescription}
                    buttonSize="S"
                    emphasis="Low"
                  />
                </div>
                <BuilderFormInput
                  type="textarea"
                  value={child.description}
                  onChange={handleChangeDescription}
                  debounceValue={300}
                  name={`table-child-${child.uuid}-description`}
                  placeholder="Saisissez la description du tableau"
                />
              </div>
            ) : (
              <DSButton
                label={"Ajouter une description"}
                className={styles["table__general_button--underline"]}
                onClick={() => handleChangeDescription("")}
                buttonSize="S"
                emphasis="Low"
              />
            )}
          </div>
          <div>
            <DSFormGroupTextInput
              label="Nombre de lignes"
              required
              assistiveText="Il s'agit des lignes à remplir par les participants, hors-ligne d'en-tête."
            >
              <DSTooltip
                /* [CSB] changes disabled */
                label="Cet élément ne peut pas être modifié sur une campagne en cours."
                direction="top"
              >
                <DSNumberInput
                  min={1}
                  max={20}
                  name={`child-${child.uuid}-rows-count`}
                  value={child.rowsCount + ""}
                  onChange={handleChangeRowsCount}
                  disabled // [CSB] changes disabled
                />
              </DSTooltip>
            </DSFormGroupTextInput>
          </div>
        </Flex>
        <div className={styles.table__permissions}>
          <p className={styles.table__permissions__assistiveText}>
            Ajoutez des colonnes au tableau afin de configurer les permissions associées à chaque
            colonne.
          </p>
        </div>
      </Divided>
      <Flex column>
        {child.columns.map((column, index) => (
          <TableChildColumn
            key={column.uuid}
            childId={child.uuid}
            rowsCount={child.rowsCount}
            column={column}
            index={index}
            onDeleteColumn={handleDeleteColumn}
            onUpdateColumn={debouncedHandleUpdateColumn}
            onMoveColumnUpOrDown={moveColumnUpOrDown}
            disabledMoveUp={index === 0}
            disabledMoveDown={index === child.columns.length - 1}
          />
        ))}
      </Flex>
      <Divided>
        <Flex column className={styles.table__addColumnButton}>
          <DSButton
            label={"Ajouter une colonne"}
            buttonSize="S"
            emphasis="Mid"
            onClick={addColumn}
            disabled // [CSB] changes disabled
            tooltip="Cet élément ne peut pas être modifié sur une campagne en cours." // [CSB] changes disabled
            tooltipDirection="bottom" // [CSB] changes disabled
          />
        </Flex>
        <div>
          <Permissions
            key={child.uuid}
            indexes={indexes}
            kind={child.kind}
            uuid={child.uuid}
            restrictions={child.disallow}
            override={{ comment: "Qui peut commenter le tableau ?" }}
          />
        </div>
      </Divided>
    </Flex>
  );
};
