import { useCallback } from "react";

import { DSDropdown, DSDropdownItem } from "@skillup/ui";

import User from "utils/User";
import { Assignee } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import { useAssignmentsContext } from "../AssignmentsContext";

const ActionsCell = ({
  actions,
  row,
  t,
}: {
  t: TranslationType;
  row: { id: string; data: Assignee };
  actions: (row: { id: string; data: Assignee }) => void;
}) => {
  const {
    assignEmployeeToJob: { mutator },
  } = useAssignmentsContext();

  const unassignJob = useCallback(async () => {
    await mutator(
      [
        {
          companyUuid: row.data.companyID,
          employeeUuid: row.id,
          version: row.data.version + 1,
        },
      ],
      {
        uuid: null,
        name: null,
      }
    );
  }, [row, mutator]);

  return (
    <DSDropdown buttonSize="S" overflow="initial">
      <DSDropdownItem
        onClick={() => actions(row)}
        label={t("skills.list.collaborator.assign", {
          defaultValue: "Associer une fiche de poste",
        })}
      />
      {row.data.jobName && User.isSkillupDeveloper() && (
        <DSDropdownItem
          onClick={unassignJob}
          label={t("skills.list.collaborator.unassign", {
            defaultValue: "[DEV] Retirer la fiche de poste",
          })}
        />
      )}
    </DSDropdown>
  );
};

export default ActionsCell;
