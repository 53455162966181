import React from "react";

import Icon from "components/Icon";
import { splitStar as splitStarIcon, star as starIcon } from "uiAssets/Icons";
import Colors from "uiAssets/Colors";

interface IStar {
  key: string;
  value: number;
}

interface Props {
  score: number;
}

const TrainingStars = ({ score }: Props) => {
  const stars: IStar[] = [0, 1, 2, 3, 4].map((index: number) => {
    let targetValue = 0;
    if (index < Math.floor(score / 2)) targetValue = 1;
    else if (index < Math.ceil(score / 2)) targetValue = 0.5;
    return {
      key: String(index),
      value: targetValue,
    };
  });

  return (
    <div style={{ display: "flex" }}>
      {stars.map((star: IStar) => (
        <Icon
          key={star.key}
          icon={star.value === 0.5 ? splitStarIcon : starIcon}
          fill={star.value === 0 ? "#ccc" : Colors.yellow}
          width={14}
          style={{ display: "block", marginRight: 2 }}
        />
      ))}
    </div>
  );
};

export default TrainingStars;
