import React, { useRef, useState, useCallback } from "react";
import { useToggle } from "react-use";

import InteractiveButton from "components/InteractiveButton";
import DropDown, { DropDownOptions } from "components/DropDown";
import TextInput from "components/TextInput";
import User from "utils/User";
import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import { statusForHR } from "constants/status";

import styles from "./PositioningModalStyles.module.scss";
import { IRelationCall } from "../utils/fetchItems";

import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";
import { Summary } from "@skillup/types";

interface MoveToOtherScheduleProps {
  scheduleUuid: string;
  reload: () => void;
  rowsUuids: string[];
  currentState: IRelationCall["primaryState"];
  summary: Summary;
}

export default ({
  reload,
  rowsUuids,
  scheduleUuid,
  currentState,
  summary,
}: MoveToOtherScheduleProps) => {
  const scheduleDropdown = useRef<DropDown<DropDownOptions<string>>>(null);
  const [submitting, toggleSubmitting] = useToggle(false);
  const [message, setMessage] = useState<string>();

  const submit = useCallback(async () => {
    if (!scheduleDropdown || !scheduleDropdown.current) return;

    const schedule = scheduleDropdown.current.getValue();
    if (!schedule) return;

    try {
      toggleSubmitting(true);

      await buildRequest<ScheduleRowRoutes.MoveToOtherSchedule>({
        method: "POST",
        path: "/scheduleRow/move-to-schedule/{scheduleUuid}",
        params: { scheduleUuid: schedule },
        payload: { rows: rowsUuids, message },
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: "Opération effectuée.",
        type: "success",
      });

      Acta.dispatchEvent("closeModal");

      reload();
    } catch (error) {
      console.error(error);
      Acta.dispatchEvent("sendAppMessage", {
        message: "La demande a échoué.",
        type: "error",
      });
    } finally {
      toggleSubmitting(false);
    }
  }, [message, toggleSubmitting, scheduleDropdown, rowsUuids, reload]);

  const statusForSummary = statusForHR(summary);
  const computeMessage = (rows) => {
    if (rows.length === 1) {
      return `La demande restera au statut « ${statusForSummary[currentState]} »`;
    }
    return "Les statuts des lignes seront conservés";
  };

  const schedules = User.getSchedules({ archived: true })
    .filter((s) => s.uuid !== scheduleUuid)
    .map((s) => ({ label: s.name, value: s.uuid }));

  return (
    <div className={styles.positioningModal}>
      <DropDown
        label="Déplacer vers:"
        options={schedules}
        placeholder="Sélectionnez un plan"
        ref={scheduleDropdown}
      />

      <TextInput
        onChange={(_e, value) => setMessage(value)}
        label="Commentaire (facultatif)"
        placeholder="Laissez un commentaire dans votre activité"
        alwaysOpen
      />
      <p style={{ marginBottom: 20 }}>{computeMessage(rowsUuids)}.</p>
      <InteractiveButton
        loading={submitting}
        label="Déplacer"
        size="small"
        onClick={submit}
        style={{ fontWeight: 500 }}
      />
    </div>
  );
};
