import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { marked, Renderer } from "marked";

import Acta from "utils/Acta";
import logo from "assets/v2/logo-dark.svg";
import blankLinks from "constants/blankLinks";

import FAQStyles from "./FAQStyles.module.scss";
import FooterStyles from "./FooterStyles.module.scss";

const renderer = new Renderer();
renderer.link = function (href, title, text) {
  const credentials = Acta.getState("userData");
  const orgUuid = credentials && credentials.activeCompany && credentials.activeCompany.uuid;
  if (orgUuid && blankLinks[orgUuid] && blankLinks[orgUuid].includes(href)) {
    return '<a target="_blank" href="' + href + '" title="' + title + '">' + text + "</a>";
  }
  return '<a href="' + href + '" title="' + title + '">' + text + "</a>";
};

interface IState {
  portalFAQ?: string;
  userData?: any;
}

class Footer extends React.PureComponent<{}, IState> {
  public state: IState = {};

  public componentDidMount(): void {
    Acta.subscribeState(
      "userData",
      (userData) =>
        this.setState({ userData }, () => {
          const portalFAQ =
            userData &&
            userData.activeCompany &&
            userData.activeCompany.portalEnabled &&
            userData.activeCompany.portalFAQ;

          this.setState({ portalFAQ });
        }),
      this
    );
  }

  private showFAQ = () => {
    Acta.setState("modalDisplayed", {
      content: (
        <div
          className={FAQStyles.Faq}
          dangerouslySetInnerHTML={{
            __html: marked.parse(this.state.portalFAQ || "", { renderer, async: false }),
          }}
        />
      ),
      size: "small",
      title: "F.A.Q.",
    });
  };

  public render(): JSX.Element {
    const { userData, portalFAQ } = this.state;
    // const credentials = Acta.getState('userData');

    const publicFooter = !userData;
    const portalSearchDisabled =
      userData &&
      userData.activeCompany &&
      userData.activeCompany.portalEnabled &&
      userData.activeCompany.portalSearchDisabled;

    const hasCatalogAndSearch = userData && !portalSearchDisabled;

    return (
      <footer className={FooterStyles.Footer}>
        {!publicFooter && (
          <div className={cx(FooterStyles.columnUser, FooterStyles.site)}>
            <Link className={FooterStyles.logo} to="/">
              <img src={logo} alt="Skillup" width="108" height="40" />
            </Link>
            <div className={cx(FooterStyles.mapUser, FooterStyles.map)}>
              {!!hasCatalogAndSearch && <a href="mailto:support@skillup.co">support@skillup.co</a>}
              {portalFAQ && <button onClick={this.showFAQ}>FAQ</button>}
            </div>
            {!hasCatalogAndSearch && (
              <React.Fragment>
                <div className={cx(FooterStyles.column, FooterStyles.tags)} />
                <div className={cx(FooterStyles.column, FooterStyles.tags)} />
              </React.Fragment>
            )}
          </div>
        )}
      </footer>
    );
  }
}

export default Footer;
