import { ReactNode, useMemo, useState, useCallback } from "react";

import { DSDropdownItem } from "@skillup/ui";

import type { OrganizationRoutesType } from "types/api";
import User from "utils/User";
import { AuthStrategyType, User as UserType } from "../types";
import useAuthStrategy from "../userAuthStrategy";
import ResetPasswordsModal from "./ResetPasswordsModal";
import RestoreUsersModal from "./RestoreUsersModal";
import RevokeUsersModal from "./RevokeUsersModal";
import NewPasswordsModal from "./NewPasswordsModal";
import type { UserRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";

const UserActions = ({
  user,
  connectionSettings,
  onShowModal,
  onUpdate,
}: {
  user: UserType;
  connectionSettings: OrganizationRoutesType.AuthSettings["response"];
  onShowModal: (modal: ReactNode | null) => void;
  onUpdate: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsImmuneUser = useCallback(
    async (uuid: string, isImmune: boolean) => {
      setIsLoading(true);
      try {
        await buildRequest<UserRoutes.PatchAll>({
          method: "POST",
          path: `/users`,
          payload: {
            users: [
              {
                uuid,
                properties: { isImmune },
              },
            ],
          },
        })();
        onUpdate();
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    },
    [onUpdate, setIsLoading]
  );

  const modalProps = {
    users: [user],
    onSubmit: () => {
      onUpdate();
      onShowModal(null);
    },
    onClose: () => onShowModal(null),
  };

  function revokeAccess() {
    onShowModal(<RevokeUsersModal {...modalProps} />);
  }

  function restoreAccess() {
    onShowModal(<RestoreUsersModal {...modalProps} />);
  }

  function resetPassword() {
    onShowModal(
      <ResetPasswordsModal
        {...modalProps}
        onSubmit={([{ password }]) => {
          const passwords = {
            [user.properties.uuid]: password,
          };
          onShowModal(<NewPasswordsModal {...modalProps} passwords={passwords} />);
        }}
      />
    );
  }

  const getAuthStrategy = useAuthStrategy(connectionSettings);

  const authStrategy = getAuthStrategy(user);

  const isImmuneButton = useMemo(() => {
    if (!User.isSkillupAdmin()) {
      return <></>;
    }

    if (user.properties.isImmune) {
      return (
        <DSDropdownItem
          label="[OPS] Enlever l'immunité"
          onClick={() => {
            toggleIsImmuneUser(user.properties.uuid, false);
          }}
          disabled={isLoading}
        />
      );
    } else {
      return (
        <DSDropdownItem
          label="[OPS] Immuniser l'utilisateur"
          onClick={() => {
            toggleIsImmuneUser(user.properties.uuid, true);
          }}
          disabled={isLoading}
        />
      );
    }
  }, [user, isLoading, toggleIsImmuneUser]);

  if (user.properties.disabled) {
    return (
      <>
        <DSDropdownItem label="Restaurer l’accès à Skillup" onClick={restoreAccess} />
      </>
    );
  }
  const isCurrentUser = user.properties.uuid === User.getUserData()?.uuid;
  if (authStrategy === AuthStrategyType.PASSWORD) {
    return (
      <>
        {!isCurrentUser && (
          <DSDropdownItem label="Révoquer l’accès à Skillup" onClick={revokeAccess} />
        )}
        <DSDropdownItem label="Réinitialiser le mot de passe" onClick={resetPassword} />
        {isImmuneButton}
      </>
    );
  }

  return (
    <>
      {!isCurrentUser && (
        <>
          <DSDropdownItem label="Révoquer l’accès à Skillup" onClick={revokeAccess} />
          {isImmuneButton}
        </>
      )}
    </>
  );
};

export default UserActions;
