import Select from "react-select";
import { useToggle } from "react-use";
import React, { Dispatch, useState, Suspense } from "react";

import { useRecoilValue } from "recoil";

import Acta from "utils/Acta";
import type { JobsRoutes } from "types/api";
import type { EmployeesRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";
import InteractiveButton from "components/InteractiveButton";

import { jobsAtom, getJobSelector } from "../state/jobs";

import styles from "./AssignEmployeeToJobModal.module.scss";

/** GET JOBS ROUTE */
type GetJobsListRoute = JobsRoutes["Jobs.GetList"];
export type Job = GetJobsListRoute["response"]["jobs"][0];

/** ASSIGN JOB TO EMPLOYEE ROUTE */
type AssignJobToEmployeeRoute = EmployeesRoutes["Employees.AssignJob"];

/** COMPONENT */
interface IProps {
  onSuccess: () => void;
  currentJobUuid?: string;
  employee: {
    uuid: string;
    fullName: string;
  };
}

const AssignEmployeeToJobModal = ({ currentJobUuid, employee, onSuccess }: IProps) => {
  const [selectedJob, selectJob] = useState<string>(currentJobUuid);

  const [loading, toggleLoading] = useToggle(false);

  const sendAssignment = async () => {
    if (!selectedJob) {
      Acta.dispatchEvent("sendAppMessage", {
        type: "error",
        message: "Veuillez choisir une nouvelle fiche de poste",
      });
    }

    toggleLoading(true);

    try {
      const assignmentRequest = buildRequest<AssignJobToEmployeeRoute>({
        method: "POST",
        params: { employeeUuid: employee.uuid },
        path: "/competences/employees/{employeeUuid}/assign-job/",
        payload: { jobUuid: selectedJob },
      });

      const response = await assignmentRequest();

      if (!response.success) {
        throw new Error();
      }

      Acta.dispatchEvent("sendAppMessage", {
        type: "success",
        message: "Nouvelle fiche de poste assignée avec succès",
      });
      Acta.dispatchEvent("closeModal");
      onSuccess();
    } catch (err) {
      console.log(err);
      Acta.dispatchEvent("sendAppMessage", {
        type: "error",
        message: "Erreur lors de l'assignation de la nouvelle fiche de poste",
      });
    }

    toggleLoading(false);
  };

  return (
    <div className={styles.assignEmployeeToJobModal}>
      <p>Assigner une fiche de poste à {employee.fullName}</p>
      <Suspense
        fallback={
          <Select options={[]} placeholder="Récupération de la liste des fiches de poste..." />
        }
      >
        <JobSelectionDropdown selectJob={selectJob} selectedJob={selectedJob} />
      </Suspense>
      <InteractiveButton loading={loading} label="Enregistrer" onClick={sendAssignment} />
    </div>
  );
};

const JobSelectionDropdown = ({
  selectedJob,
  selectJob,
}: {
  selectedJob: string;
  selectJob: Dispatch<string>;
}) => {
  const jobs = useRecoilValue(jobsAtom);
  const selectedJobData = useRecoilValue(getJobSelector({ jobUuid: selectedJob }));

  return (
    // @ts-ignore
    <Select
      key={selectedJob}
      value={selectedJobData}
      className={styles.searchInput}
      placeholder="Rechercher une fiche de poste"
      onChange={(selectedJob: { data: Job }) => {
        selectJob(selectedJob.data.uuid);
      }}
      options={jobs.map((job) => ({
        data: job,
        label: job.label,
        value: job.uuid,
      }))}
    />
  );
};
export default AssignEmployeeToJobModal;
