import { useState } from "react";
import type { Dispatch } from "react";
import { useModal } from "@skillup/ui";

import { useFilteredTrackings, Tracking } from "../state/tracking";

export enum ModalStates {
  Idle,
  Creating,
  Archiving,
  Unarchive,
  Renewing,
  UpdateValidity,
  UpdateState,
  CreatingDoc,
  ManagingDocs,
  CreatingNote,
  ManagingNote,
}

export type TrackingAction = Tracking["actions"][0];
export enum Actions {
  Renew = "renewal",
  AddDocument = "add-document",
  ManageDocuments = "manage-documents",
  AddNote = "add-note",
  ManageNote = "manage-note",
  AddDate = "add-date",
  ModifyDate = "modify-date",
  ModifyState = "modify-state",
  Archive = "archive",
  Unarchive = "unarchive",
}

export type State =
  | { state: ModalStates.Idle }
  | { state: ModalStates.Creating }
  | { state: ModalStates.Archiving; trackingUuid: string }
  | { state: ModalStates.Renewing; trackingUuid: string }
  | { state: ModalStates.UpdateValidity; trackingUuid: string }
  | { state: ModalStates.UpdateState; trackingUuid: string }
  | { state: ModalStates.CreatingDoc; trackingUuid: string }
  | { state: ModalStates.ManagingDocs; trackingUuid: string }
  | { state: ModalStates.CreatingNote; trackingUuid: string }
  | { state: ModalStates.ManagingNote; trackingUuid: string }
  | { state: ModalStates.Unarchive; trackingUuid: string };

type TrackingsActionsHook = [
  {
    state: State;
    getActionLabel(action: TrackingAction): string;
    getNewStateFromAction({
      action,
      trackingUuid,
    }: {
      action: TrackingAction;
      trackingUuid: string;
    }): State;
  },
  Dispatch<State>
];

const useTrackingsActions = (): TrackingsActionsHook => {
  const { show, hide } = useModal();
  const { refetch } = useFilteredTrackings();

  const [trackingActions, setState] = useState<State>({ state: ModalStates.Idle });

  return [
    {
      state: trackingActions,
      getActionLabel,
      getNewStateFromAction,
    },

    function (stateChange: State) {
      switch (stateChange.state) {
        case ModalStates.Idle: {
          hide();
          refetch();
          break;
        }
        case ModalStates.Creating:
        case ModalStates.Archiving:
        case ModalStates.UpdateValidity:
        case ModalStates.UpdateState:
        case ModalStates.Renewing:
        case ModalStates.CreatingDoc:
        case ModalStates.ManagingDocs: {
          show();
          break;
        }
        case ModalStates.CreatingNote:
        case ModalStates.ManagingNote: {
          show();
          break;
        }
      }

      return setState(stateChange);
    },
  ];
};

export default useTrackingsActions;

const ActionsMap: Record<Tracking["actions"][0]["type"], string> = {
  "add-date": "Ajouter une date de validité",
  "add-document": "Ajouter un document",
  "manage-documents": "Gérer les documents",
  "add-note": "Ajouter une note",
  "manage-note": "Modifier la note",
  "modify-date": "Modifier la date de validité",
  "modify-state": "Modifier l'état",
  archive: "Archiver",
  renewal: "Valider le renouvellement",
  unarchive: "Repasser en ligne active",
};

function getActionLabel(action: TrackingAction) {
  return ActionsMap[action.type];
}

const ModalsMapFromAction: Record<Tracking["actions"][0]["type"], ModalStates> = {
  "add-date": ModalStates.UpdateValidity,
  "add-document": ModalStates.CreatingDoc,
  "manage-documents": ModalStates.ManagingDocs,
  "add-note": ModalStates.CreatingNote,
  "manage-note": ModalStates.ManagingNote,
  "modify-date": ModalStates.UpdateValidity,
  "modify-state": ModalStates.UpdateState,
  archive: ModalStates.Archiving,
  renewal: ModalStates.Renewing,
  unarchive: ModalStates.Unarchive,
};

function getNewStateFromAction({
  action,
  trackingUuid,
}: {
  action: TrackingAction;
  trackingUuid: string;
}) {
  return {
    state: ModalsMapFromAction[action.type],
    trackingUuid,
  };
}
