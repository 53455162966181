import { TextInput } from "@skillup/ui";

import { Child } from "../types";

// Sub components

type UserSpecificData = Child & { kind: "userSpecificData" };
const UserSpecificDataBlock = ({
  data,
  updateChild,
}: {
  data: UserSpecificData;
  updateChild: (newChildData: Child) => void;
}) => {
  return (
    <form>
      <label>User data key</label>
      <TextInput
        key={`specific-user-data-key-${data.uuid}`}
        value={data.userSpecificDataKey}
        onChange={(userSpecificDataKey) => updateChild({ ...data, userSpecificDataKey })}
      />
    </form>
  );
};

export default UserSpecificDataBlock;
