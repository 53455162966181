import DataLayer from "utils/DataLayer";

import { ListInterface } from "../types";

import { State } from "../components/PortalLists";

const getLists = async (group: string, setState: (state: State) => void): Promise<void> => {
  try {
    setState({ isLoading: true });

    const lists = (await DataLayer.request({
      url: `/v1/portal/training-list${group ? `/${group}` : ""}`,
    })) as Array<ListInterface>;

    // avoids content flash
    setTimeout(() => {
      setState({
        isLoading: false,
        lists,
      });
    }, 300);
  } catch (error) {
    setState({
      isLoading: false,
      loadingError: true,
    });
  }
};

export default getLists;
