import { useRef, useCallback, useState, ChangeEvent, FormEvent, KeyboardEvent } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { ITraining, IOrganization } from "@skillup/types";
import { TrainingRoutes } from "@skillup/espace-rh-bridge";
import { useDebouncedCallback } from "use-debounce";
import { useToggle, useClickAway } from "react-use";
import { useBaseUrl } from "@skillup/hooks";

import escapeStringRegexp from "utils/escape-string-regexp";
import searchUtils from "utils/search";
import Icon from "components/Icon";
import { magnifyingGlass as magnifyingGlassIcon } from "uiAssets/StrokeIcons";
import { file as fileIcon, school as schoolIcon, tag as tagIcon } from "uiAssets/Icons";

import styles from "./SearchInputStyles.module.scss";
import { buildRequest } from "utils/buildRequest";

interface IEntity {
  uuid: string;
  slug: string;
  shape: string;
}

interface ITrainingCategory {
  count: number;
  label: string;
  score: number;
}

interface Props {
  readonly onEnter?: () => void;
}

const ANALYTICS_URLS_WHITELIST = [
  "/collaborateur",
  "/collaborateur/mon-portail",
  "/responsable/programmes/catalogue",
];

const SearchInput = ({ onEnter }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const { baseUrl } = useBaseUrl();
  const input = useRef<HTMLInputElement>(null);

  const [hidden, toggleHidden] = useToggle(true);
  const [currentSearch, setCurrentSearch] = useState<string>("");
  const [entitiesResults, setEntitiesResults] = useState<IEntity[]>([]);
  const [organizationsResults, setOrganizationsResults] = useState<IOrganization[]>([]);
  const [trainingResults, setTrainingResults] = useState<ITraining[]>([]);
  const [trainingResultsByTags, setTrainingResultsByTags] = useState<ITrainingCategory[]>([]);

  const handleChange = useDebouncedCallback(async (query: string): Promise<void> => {
    if (!query) return;

    const request = buildRequest<TrainingRoutes.Search.SearchByQuery>({
      method: "GET",
      path: "/training/search-by-query/{query}",
      params: {
        query: encodeURIComponent(query),
      },
    });
    const receivedData = await request();

    const organizationsResults = ((receivedData.organizations || {}).hits || []).slice(0, 5);

    const trainingResultsByTags: ITrainingCategory[] = (
      Object.entries(((receivedData || {}).trainingAggregations || {}).tags || {}) || []
    )
      .map((tagElement) => ({
        count: tagElement[1].doc_count,
        label: tagElement[0],
        score: tagElement[1].doc_count,
      }))
      .sort((tagElementA, tagElementB) => {
        if (tagElementA.score > tagElementB.score) return -1;
        if (tagElementA.score < tagElementB.score) return 1;
        if (tagElementA.label > tagElementB.label) return 1;
        return -1;
      })
      .slice(0, 5);

    const trainingResults = ((receivedData.trainings || {}).hits || []).slice(0, 5);
    const entitiesResults = ((receivedData.entities || {}).hits || []).slice(0, 5);

    setEntitiesResults(entitiesResults);
    setOrganizationsResults(organizationsResults);
    setTrainingResults(trainingResults);
    setTrainingResultsByTags(trainingResultsByTags);
  }, 150);

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      const query = event.currentTarget.value.trim().replace(/%/g, "");

      if (!query || query === "") {
        return setCurrentSearch("");
      }

      if (query && currentSearch !== query) {
        setCurrentSearch(query);
        toggleHidden(false);
        handleChange(query);
      }
    },
    [currentSearch, setCurrentSearch, toggleHidden, handleChange]
  );

  const onFocusInput = useCallback(
    (event: FormEvent<HTMLInputElement>): void => {
      setCurrentSearch(event.currentTarget.value);
      toggleHidden(false);
    },
    [setCurrentSearch, toggleHidden]
  );

  const onKeyPressInput = useCallback(
    (event: KeyboardEvent<HTMLInputElement>): void => {
      /* On enter, search */
      if (event.nativeEvent.keyCode === 13 && currentSearch) {
        if (ANALYTICS_URLS_WHITELIST.includes(location.pathname) && onEnter) {
          onEnter();
        }

        history.push(`${baseUrl}/recherche/${encodeURIComponent(currentSearch)}`);

        setCurrentSearch("");
        toggleHidden(true);
        input.current.blur();
      }
    },
    [location, currentSearch, baseUrl, history, onEnter, setCurrentSearch, toggleHidden, input]
  );

  const formatSearchResult = useCallback(
    (searchResult: string, currentSearch: string): React.ReactNode => {
      const startIndex = searchResult.search(new RegExp(escapeStringRegexp(currentSearch), "i"));

      if (startIndex === -1) return searchResult;

      return (
        <>
          {searchResult.slice(0, startIndex)}
          <b>{searchResult.slice(startIndex, startIndex + currentSearch.length)}</b>
          {searchResult.slice(startIndex + currentSearch.length)}
        </>
      );
    },
    []
  );

  const onLinkClick = useCallback(
    (e) => {
      if (ANALYTICS_URLS_WHITELIST.includes(location.pathname) && onEnter) {
        onEnter();
      }

      if (currentSearch) {
        return;
      }

      e.preventDefault();
    },
    [currentSearch, location, onEnter]
  );

  useClickAway(input, () => setTimeout(toggleHidden, 125));

  return (
    <div className={styles.SearchInput}>
      <input
        ref={input}
        id="SearchInputInput"
        placeholder="Rechercher"
        autoComplete="off"
        onChange={onChange}
        onFocus={onFocusInput}
        onKeyPress={onKeyPressInput}
      />
      <Link
        onClick={onLinkClick}
        to={`${baseUrl}/recherche/${encodeURIComponent(currentSearch || "")}`}
      >
        <Icon strokeIcon={magnifyingGlassIcon} width={15} />
      </Link>

      {currentSearch && !hidden && (
        <div>
          <Link
            to={`${baseUrl}/recherche/${encodeURIComponent(currentSearch)}`}
            id="SearchInputAnchor"
            onClick={() => {
              if (ANALYTICS_URLS_WHITELIST.includes(location.pathname) && onEnter) {
                onEnter();
              }
            }}
          >
            <Icon strokeIcon={magnifyingGlassIcon} width={13} />
            <span>{`Rechercher "${currentSearch}"`}</span>
          </Link>

          {organizationsResults && organizationsResults.length > 0 && (
            <div>
              <Icon fill="#999" icon={schoolIcon} width={16} />
              Organismes
            </div>
          )}

          {organizationsResults &&
            organizationsResults.map((organization: IOrganization) => (
              <Link
                key={organization.uuid}
                to={`${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/formations-${
                  organization.slug
                }`}
              >
                <span>
                  {formatSearchResult(organization.name as string, currentSearch)} (
                  {organization.trainingsCount})
                </span>
              </Link>
            ))}

          {trainingResultsByTags && trainingResultsByTags.length > 0 && (
            <div>
              <Icon fill="#999" icon={tagIcon} width={16} />
              Catégories
            </div>
          )}

          {trainingResultsByTags &&
            trainingResultsByTags.map((trainingTag: ITrainingCategory) => (
              <Link
                key={trainingTag.label}
                to={`${baseUrl || ""}/recherche/${encodeURIComponent(
                  currentSearch
                )}${searchUtils.getQueryStringFromFilters({
                  tags: [trainingTag.label],
                })}`}
              >
                <span>
                  {formatSearchResult(trainingTag.label, currentSearch)} ({trainingTag.count})
                </span>
              </Link>
            ))}

          {trainingResults && trainingResults.length > 0 && (
            <div>
              <Icon fill="#999" icon={fileIcon} width={16} />
              Formations
            </div>
          )}

          {trainingResults &&
            trainingResults.map((training: ITraining) => (
              <Link
                key={training.uuid}
                to={`${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/${
                  training.seoSlug_original
                }`}
              >
                <span>
                  {formatSearchResult(training.name, currentSearch)} (
                  {formatSearchResult(training.organization, currentSearch)})
                </span>
              </Link>
            ))}

          {entitiesResults && entitiesResults.length > 0 && (
            <div>
              <Icon fill="#999" icon={tagIcon} width={16} />
              Entités
            </div>
          )}

          {entitiesResults &&
            entitiesResults.map((entity: IEntity) => (
              <Link
                key={entity.uuid}
                to={`${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/formations-entity-${
                  entity.slug
                }`}
              >
                <span>{formatSearchResult(entity.shape, currentSearch)}</span>
              </Link>
            ))}
        </div>
      )}
    </div>
  );
};

export default SearchInput;
