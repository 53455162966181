import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { IRoutes } from "@skillup/types";

import { DSAlert, DSAlertDisplay, DSAlertType, DSCard } from "@skillup/ui";

import {
  CampaignMailingSettings,
  CampaignsRoutes,
  InterviewCreationRuleError,
  InterviewState,
  PossibleCodes,
} from "@skillup/espace-rh-bridge";
import DateUtils from "utils/dates";
import { buildRequest } from "utils/buildRequest";
import { InterviewPairs } from "../../models/InterviewPair";
import { createData, createPayload } from "../../requests/CreateCampain";

import styles from "./Steps.module.scss";
import { Link } from "react-router-dom";
import { plural } from "utils/locale";

type CheckError = InterviewCreationRuleError;

type FinalStepState =
  | {
      step: "checking";
    }
  | {
      step: "errors";
      errors: string[][];
      warnings: string[][];
      showErrors: boolean;
      showWarnings: boolean;
    }
  | {
      step: "canCreate";
      warnings: string[][];
      showWarnings: boolean;
    };

const FinalStep = (props: IProps): JSX.Element => {
  const { titleForAttendees, titleForHR, template, type, setCanCreateCampaign, interviews } = props;

  const [state, setState] = useState<FinalStepState>({
    step: "checking",
  });

  const performCheck = useCallback(async () => {
    const payload = createPayload({
      interviews,
      confidential: props.confidential,
      data: createData({
        type: props.type,
        titleForHR: props.titleForHR,
        titleForAttendees: props.titleForAttendees,
        template: props.template,
        hideFromEmployeeUntil: props.hideFromEmployeeUntil,
        duration: props.duration,
        start: props.start,
        timezoneOffset: 0,
        mailingSettings: props.mailingSettings,
      }),
    });

    const res = await buildRequest<CampaignsRoutes.RunCheck>({
      method: "POST",
      path: "/campaigns/run-campaign-checks",
      payload,
    })();

    if (res.errors?.length) {
      setState({
        step: "errors",
        errors: parseErrors(res.errors, "Erreur"),
        warnings: parseErrors(res.warnings, "Warning"),
        showErrors: true,
        showWarnings: true,
      });

      setCanCreateCampaign(false);
    } else {
      setState({
        step: "canCreate",
        warnings: parseErrors(res.warnings ?? [], "Warning"),
        showWarnings: true,
      });
      setCanCreateCampaign(true);
    }
  }, [props, setCanCreateCampaign, interviews]);

  useEffect(() => {
    if (state.step === "checking") {
      performCheck();
    }
  }, [state, performCheck]);

  // We check for length > 1 because the first element is the row with columns titles
  const thereAreWarnings = useMemo(
    () => (state.step === "errors" || state.step === "canCreate") && state.warnings.length > 1,
    [state]
  );
  const thereAreErrors = useMemo(() => state.step === "errors" && state.errors.length > 1, [state]);

  return (
    <div className={styles.CardWrapper}>
      <DSCard className={styles.FinalStepCard} showTitle={false}>
        <div className={styles.table}>
          <div className={styles.row}>
            <div className={styles.element}>
              <div className={styles.title}>Type de campagne</div>
              <div className={styles.value}>{typeEntretienLabel[type]}</div>
            </div>
            <div className={styles.element}>
              <div className={styles.title}>Trame d'entretien</div>
              <div className={styles.value}>{template.title}</div>
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.element}>
              <div className={styles.title}>Nom de la campagne (pour les RH)</div>
              <div className={styles.value}>{titleForHR}</div>
            </div>
            <div className={styles.element}>
              <div className={styles.title}>Nom de la campagne (pour les participants)</div>
              <div className={styles.value}>{titleForAttendees}</div>
            </div>
          </div>
          {type === "legacy" && RenderClassicCampaignRecap(props)}
          {type === "ongoing" && renderOngoingCampaignRecap(props)}
          {type === "adHoc" && renderAdHocCampaignRecap(props)}
        </div>
      </DSCard>

      {(state.step === "errors" || state.step === "canCreate") && (
        <span className={styles.FinalStepAlert}>
          {state.step === "errors" && thereAreErrors && state.showErrors && (
            <DSAlert
              className={styles.alert}
              type={DSAlertType.ERROR}
              display={DSAlertDisplay.INLINE}
              closeButton={true}
              onClose={() => setState((state) => ({ ...state, showErrors: false }))}
            >
              La campagne ne peut pas être créée car les informations renseignées contiennent des
              erreurs.
              <div className={styles.alertButton}>
                {
                  <CSVLink className={styles.alertButton} data={state.errors}>
                    Télécharger le rapport d'erreur
                  </CSVLink>
                }
              </div>
            </DSAlert>
          )}

          {thereAreWarnings && state.showWarnings && (
            <DSAlert
              className={styles.alert}
              type={DSAlertType.WARNING}
              display={DSAlertDisplay.INLINE}
              closeButton={true}
              onClose={() => setState((state) => ({ ...state, showWarnings: false }))}
            >
              <WarningAlertText warnings={state.warnings} />
            </DSAlert>
          )}
        </span>
      )}
    </div>
  );
};

function WarningAlertText({ warnings }: { warnings: string[][] }) {
  /* Helloo :) This logic supposes that warnings are all the same: missing-job for a user
    This is true for now since we just added the warning system, but it will change in the future
    So if you want to add a new warning, you'll have to change this logic to have one component per type of warning,
    or to have a more generic text.
  */
  const realLength = warnings.length - 1; // -1 because the first element is the row with columns titles

  return (
    <>
      {plural(realLength, "%n collaborateur%s")}{" "}
      {plural(realLength, "n'a pas de fiche de poste associée", {
        pluralText: "n'ont pas de fiche de poste associée",
      })}{" "}
      <CSVLink className={styles.warningDownload} data={warnings}>
        (Télécharger le rapport d'erreur)
      </CSVLink>
      . Le bloc "Fiche de poste" sera donc vide dans l'entretien pour{" "}
      {plural(realLength, "ce collaborateur", { pluralText: "ces collaborateurs" })}. Vous pouvez
      associer{" "}
      {plural(realLength, "la fiche de poste manquante", {
        pluralText: "les fiches de poste manquantes",
      })}{" "}
      dans l'onglet{" "}
      <Link
        className={styles.warningDownload}
        to="/responsable/referentiels/collaborateurs"
        target="_blank"
      >
        Compétences
      </Link>
      .
    </>
  );
}

function parseErrors(errors: CheckError[], errorType: "Erreur" | "Warning") {
  const mappedErrors = errors
    .map((e) => {
      switch (e.code) {
        case PossibleCodes.NoOrdinalChoices:
        case PossibleCodes.NoCategories:
        case PossibleCodes.CampaignIsArchived:
          return { Erreur: e.message, "Type d'erreur": errorType };
        case PossibleCodes.UserHasNoJobAssignement:
          return e.payload.map((e2) => ({
            "Type d'erreur": errorType,
            Erreur: e.message,
            "Adresse email": e2.email,
            Nom: e2.fullName,
          }));
      }
    })
    .flat();

  const columns = [
    { label: "Type d'erreur", width: 100 },
    { label: "Erreur", width: 300 },
    { label: "Adresse email", width: 200 },
    { label: "Nom", width: 250 },
  ];

  const data: string[][] = [
    columns.map((column) => column.label),
    ...mappedErrors.map((error) => columns.map((column) => error[column.label])),
  ];
  return data;
}

interface ITemplate {
  title: string;
  uuid: string;
}

interface IProps {
  readonly titleForHR: string;
  readonly titleForAttendees: string;
  readonly template?: ITemplate;
  readonly interviews?: InterviewPairs[];
  readonly start?: number;
  readonly preparationTime?: number;
  readonly duration?: number;
  readonly type: IRoutes["/campaigns"]["GET"]["/"][0]["type"];
  readonly hideFromEmployeeUntil?: { managerState: InterviewState };
  readonly mailingSettings?: CampaignMailingSettings;
  readonly managerOnly: boolean;
  readonly confidential: boolean;
  setCanCreateCampaign: (arg: boolean) => void;
}

const typeEntretienLabel = {
  ongoing: "Entretiens en continu",
  legacy: "Campagne d'entretiens",
  adHoc: "Entretiens ponctuels",
};

const RenderClassicCampaignRecap = (props: IProps) => {
  const { start, duration, interviews, managerOnly, hideFromEmployeeUntil, confidential } = props;

  const { start: startDate, endDate } = DateUtils.getLegacyCampaignDates({
    start,
    duration,
  });

  const interviewsCount = interviews?.length || 0;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.element}>
          <div className={styles.title}>Date de début de campagne</div>
          {/* TODO: handle start: number case */}
          <div className={styles.value}>{startDate}</div>
        </div>
        <div className={styles.element}>
          <div className={styles.title}>Date limite de signature</div>
          <div className={styles.value}>{endDate}</div>
        </div>
      </div>
      {(managerOnly || hideFromEmployeeUntil) &&
        WorfklowDetails(managerOnly, hideFromEmployeeUntil)}
      {ConfidentialityDetails(confidential)}

      <div className={styles.row}>
        <div className={styles.element}>
          <div className={styles.title}>Nombre d’entretiens à venir</div>
          <div className={styles.value}>{interviewsCount}</div>
        </div>
      </div>
    </>
  );
};

const renderOngoingCampaignRecap = (props: IProps) => {
  const { start, duration, interviews, managerOnly, hideFromEmployeeUntil, confidential } = props;

  const interviewsCount = interviews?.filter((i) => !i.invalid).length;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.element}>
          <div className={styles.title}>Date de début (jours après l'embauche)</div>
          <div className={styles.value}>{start}</div>
        </div>
        <div className={styles.element}>
          <div className={styles.title}>Délai de signature (durée en jours)</div>
          <div className={styles.value}>{duration}</div>
        </div>
      </div>
      {(managerOnly || hideFromEmployeeUntil) &&
        WorfklowDetails(managerOnly, hideFromEmployeeUntil)}
      {ConfidentialityDetails(confidential)}
      <div className={styles.row}>
        <div className={styles.element}>
          <div className={styles.title}>Nombre d’entretiens à venir</div>
          <div className={styles.value}>{interviewsCount}</div>
        </div>
      </div>
    </>
  );
};

const renderAdHocCampaignRecap = (props: IProps) => {
  const { managerOnly, hideFromEmployeeUntil, confidential } = props;

  return (
    <>
      {(managerOnly || hideFromEmployeeUntil) &&
        WorfklowDetails(managerOnly, hideFromEmployeeUntil)}
      {ConfidentialityDetails(confidential)}
    </>
  );
};

const ConfidentialityDetails = (confidential: boolean) => {
  return (
    <div className={styles.row}>
      <div className={styles.element}>
        <div className={styles.title}>Confidentialité de la campagne</div>
        {confidential ? (
          <div className={styles.value}>
            La campagne est confidentielle. Les entretiens ne seront consultables que par les
            collaborateurs et leurs responsables d’entretien. Ils ne seront pas accessibles aux
            futurs managers ainsi qu’aux supérieurs hiérarchiques des collaborateurs.
          </div>
        ) : (
          <div className={styles.value}>
            Les entretiens de cette campagne seront accessibles aux futurs managers ainsi qu’aux
            supérieurs hiérarchiques (N+2, N+3…) des collaborateurs
          </div>
        )}
      </div>
    </div>
  );
};

const WorfklowDetails = (
  managerOnly: boolean,
  hideFromEmployeeUntil: { managerState: "pending" | "shared" | "signed" | "unsigned" }
) => {
  return (
    <div className={styles.row}>
      <div className={styles.element}>
        <div className={styles.title}>Préférences de workflow</div>
        {managerOnly && (
          <div className={styles.value}>Entretiens gérés unilatéralement par les responsables</div>
        )}
        {hideFromEmployeeUntil && (
          <div className={styles.value}>
            Entretiens ouverts aux collaborateurs uniquement après partage du responsable
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalStep;
