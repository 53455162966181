import React, { useMemo } from "react";

import { SelectFilter } from "components/DataTable/v2/components/Filters";

export default ({ initialData, column }) => {
  const states = useMemo(
    () => Array.from(new Set(initialData?.map(({ properties }) => properties.state))),
    [initialData]
  );

  return <SelectFilter column={column} options={states} />;
};
