import React from "react";
import { isNil } from "lodash";
import { useToggle } from "react-use";
import { Field } from "@skillup/types";
import cx from "classnames";

import Icon from "components/Icon";
import { chevronBottom, chevronTop } from "uiAssets/Icons";
import MassEditDropDown from "./MassEditDropdown";

import styles from "./EditInputStyles.module.scss";

interface IProps {
  field: Field;
  editMode: boolean;
  numberOfRows: number;
  handleChange?: (fieldName: string, value: string) => void;
  handleSelectChange?: (fieldName: string, value: Field["options"][0]) => void;
}

const EditInput = (props: IProps) => {
  const [dropdownOpened, toggleDropDown] = useToggle(false);
  const { editMode, field, handleChange, handleSelectChange, numberOfRows } = props;
  const { label, type, options = [], rawValue, value, enabled } = field;

  const handleChoice = (newValue: Field["options"][0]) => {
    toggleDropDown(false);
    handleSelectChange(field.fieldName, newValue);
  };

  const onInputChange = (e) => {
    const {
      currentTarget: { value },
    } = e;
    handleChange(field.fieldName, value);
  };

  const inputField = (
    <div
      className={cx(styles.editPanel, {
        [styles.editPanelDropdown]: dropdownOpened,
      })}
    >
      {type === "number" && (
        <input type={type} value={rawValue} step={0.01} onChange={onInputChange} />
      )}
      {type === "monetary" && (
        <input
          type="number"
          value={!isNil(rawValue) ? (rawValue as number) / 100 : ""}
          step={0.01}
          onChange={onInputChange}
        />
      )}
      {type === "text" && (
        <input type={type} value={(value as string) ?? ""} onChange={onInputChange} />
      )}
      {type === "textarea" && <textarea value={(value as string) ?? ""} onChange={onInputChange} />}
      {type === "select" && (
        <div className={styles.dropdown}>
          <div onClick={toggleDropDown}>
            <p className={styles.dropdownOption}>{value}</p>
            <span>
              <Icon width={15} icon={dropdownOpened ? chevronTop : chevronBottom} />
            </span>
          </div>
          {dropdownOpened && (
            <div className={styles.options}>
              {options
                .filter((o) => !o.disabled)
                .map((o) => (
                  <div key={o.key} onClick={() => handleChoice(o)}>
                    {o.value}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );

  const multipleRows = numberOfRows > 1;
  const canEditSingleField = !multipleRows && editMode && field.canEdit && enabled;

  return (
    <div className={styles.editInput}>
      <label>{label}</label>

      {multipleRows && isNil(value) && (
        <MassEditDropDown numberOfRows={numberOfRows}>{inputField}</MassEditDropDown>
      )}
      {multipleRows && !isNil(value) && inputField}
      {canEditSingleField && inputField}
      {!canEditSingleField && !multipleRows && (
        <div className={styles.editPanel}>{field.value}</div>
      )}
    </div>
  );
};

export default EditInput;
