import { v4 as uuid } from "uuid";
import { PayloadAction } from "@reduxjs/toolkit";

import { FromChild } from "../addBlock";
import { BuilderStructure, Child } from "../../../types";
import { generateEmptyChild } from "./generateEmptyChild";
import { ActionHelper } from "../utils";

type CommonChildParams = {
  uuid: string;
  from: FromChild;
  blockType: "child";
};

type QuestionChildParams = CommonChildParams & {
  kind: "question";
  type: Extract<Child, { kind: "question" }>["type"];
};

type TargetsChildParams = CommonChildParams & {
  kind: "targets";
  type: Extract<Child, { kind: "targets" }>["type"];
};

type OtherChildren = CommonChildParams & {
  kind: Exclude<Child["kind"], "question" | "targets" | "guidelines" | "personnalisableGuidelines">;
};

export type AddChildAction = OtherChildren | QuestionChildParams | TargetsChildParams;

export const addChild = (
  state: BuilderStructure,
  action: PayloadAction<AddChildAction>
): BuilderStructure => {
  let childData: Child;
  if (action.payload.kind === "question") {
    childData = generateEmptyChild({
      uuid: action.payload.uuid,
      kind: action.payload.kind,
      type: action.payload.type,
    });
  } else if (action.payload.kind === "targets") {
    childData = generateEmptyChild({
      uuid: action.payload.uuid,
      kind: action.payload.kind,
      type: action.payload.type,
    });
  } else {
    childData = generateEmptyChild({ uuid: action.payload.uuid, kind: action.payload.kind });
  }

  if (
    ActionHelper.comesFromChild(action) &&
    state.sections[action.payload.from.sectionIndex] &&
    state.sections[action.payload.from.sectionIndex].pages[action.payload.from.pageIndex]
  ) {
    state.sections[action.payload.from.sectionIndex].pages[
      action.payload.from.pageIndex
    ].children.splice(action.payload.from.childIndex + 1, 0, childData);
    return state;
  }

  if (ActionHelper.comesFromPage(action) && state.sections[action.payload.from.sectionIndex]) {
    // special case if by accident you have a section with no pages, we create one (hideInBuilder)
    if (state.sections[action.payload.from.sectionIndex].pages.length === 0) {
      state.sections[action.payload.from.sectionIndex].pages.push({
        uuid: uuid(),
        title: "",
        children: [childData],
        hideInBuilder: true,
      });
    } else {
      state.sections[action.payload.from.sectionIndex].pages[
        action.payload.from.pageIndex
      ].children.unshift(childData);
    }

    return state;
  }

  if (ActionHelper.comesFromSection(action)) {
    if (state.sections[action.payload.from.sectionIndex].pages.length === 0) {
      state.sections[action.payload.from.sectionIndex].pages.push({
        uuid: uuid(),
        title: "",
        children: [childData],
        hideInBuilder: true,
      });
    } else {
      state.sections[action.payload.from.sectionIndex].pages[0].children.unshift(childData);
    }
    return state;
  }

  throw new Error();
};
