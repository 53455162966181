import React from "react";

import EasingEquations from "utils/EasingEquations";
import Icon from "components/Icon";
import { chevronBottom, check } from "uiAssets/StrokeIcons";

import CellDropDownStyles from "./CellDropDownStyles.module.scss";

export interface IDropDownProps {
  background?: string;
  borderColor?: string;
  defaultValue?: string | boolean | number | null | { url: string; label: string };
  icon?: string;
  iconStyles?: object;
  isRequired?: boolean;
  onSelect?: (optionKey: number) => void;
  options: Array<{
    value: string | boolean | number;
    label: string;
  }>;
  placeholder?: string;
  strokeIcon?: string;
  styles?: object;
}

export interface IDropDownState {
  currentValue: number | string | object | null;
  optionsDisplayed: boolean;
}

export default class CellDropDown extends React.PureComponent<IDropDownProps, IDropDownState> {
  private container: HTMLDivElement;
  public iconContainer: HTMLDivElement;
  private openIcon: SVGSVGElement;
  private optionsContainer: HTMLDivElement;
  private optionsInnerContainer: HTMLDivElement;
  private originalHeight: number;

  public state: IDropDownState = {
    currentValue: String(this.props.defaultValue) || null,
    optionsDisplayed: false,
  };

  public getValue = (): string | number | object | null => this.state.currentValue;

  /**
   * Animation functions
   */
  private selfOpen = (): void => {
    if (this.state.optionsDisplayed) return;
    this.setState({ optionsDisplayed: true });

    setTimeout(() => {
      this.triggerRendering();
    }, 0);
  };

  private triggerRendering = (): void => {
    this.optionsContainer.style.display = "block";
    this.originalHeight = this.originalHeight || this.optionsContainer.offsetHeight;
    this.optionsInnerContainer.style.overflowY = "hidden";
    this.container.style.zIndex = "999";
    this.openIcon.animate([{ transform: "rotate(0deg)" }, { transform: "rotate(180deg)" }], {
      duration: 150,
      easing: EasingEquations.easeOutBack,
      fill: "forwards",
    });
    this.optionsContainer.animate(
      [
        { opacity: 0, height: "0px" },
        {
          height: `${this.originalHeight}px`,
          opacity: 1,
        },
      ],
      {
        duration: 100,
        fill: "forwards",
      }
    );
    this.props.options.forEach((_value, index) =>
      this[`option${index}`].animate(
        [
          {
            opacity: 0,
            transform: "transLateX(150px)",
          },
          {
            opacity: 1,
            transform: "transLateX(0px)",
          },
        ],
        {
          delay: 100 + index * 50,
          duration: 100,
          easing: EasingEquations.easeOutSine,
          fill: "forwards",
        }
      )
    );

    window.setTimeout(() => {
      if (this.optionsContainer) {
        this.optionsInnerContainer.style.overflowY = "auto";
        if (this.container) this.container.style.zIndex = "8";
      }
    }, 300);
  };

  private selfClose = (): void => {
    if (!this.state.optionsDisplayed) return;

    window.removeEventListener("mouseup", this.selfClose);
    if (!this.optionsContainer) return;
    this.optionsInnerContainer.style.overflowY = "hidden";

    this.openIcon.animate([{ transform: "rotate(180deg)" }, { transform: "rotate(0deg)" }], {
      duration: 80,
      easing: EasingEquations.easeOutBack,
      fill: "forwards",
    });
    this.props.options.forEach((_value, index) =>
      this[`option${index}`].animate(
        [
          {
            opacity: 1,
          },
          {
            opacity: 0,
          },
        ],
        {
          delay: this.props.options.length - index * 50,
          duration: 50,
          fill: "forwards",
        }
      )
    );
    this.optionsContainer.animate([{ height: `${this.originalHeight}px` }, { height: "0px" }], {
      delay: 80,
      duration: 100,
      fill: "forwards",
    }).onfinish = () => {
      if (this.optionsContainer) this.optionsContainer.style.display = "none";
      if (this.container) this.container.style.zIndex = "1";
      this.setState({ optionsDisplayed: false });
    };
  };

  private displayOptions = (): void => {
    if (this.state.optionsDisplayed) {
      this.selfClose();
      return;
    }
    this.selfOpen();
    window.setTimeout(() => window.addEventListener("mouseup", this.selfClose, false), 50);
  };

  private selectOption = (optionKey): void => {
    this.setState({ currentValue: optionKey });
    if (this.props.onSelect) this.props.onSelect(optionKey);
    this.selfClose();
  };

  public render(): JSX.Element {
    const { icon, iconStyles, isRequired, options, placeholder, strokeIcon, styles } = this.props;
    const { currentValue, optionsDisplayed } = this.state;

    const option = options.find((o) => o.value === currentValue);
    const currentLabel = option ? option.label || option.value : placeholder || null;

    return (
      <div
        className={CellDropDownStyles.CellDropDown}
        style={styles}
        ref={(div) => (this.container = div as HTMLDivElement)}
      >
        <input
          onChange={() => null}
          onFocus={this.displayOptions}
          required={!!isRequired}
          value={String(currentValue) || ""}
        />
        <button onMouseUp={this.displayOptions} type="button">
          {(icon || strokeIcon) && (
            <div
              ref={(div) => (this.iconContainer = div as HTMLDivElement)}
              className={CellDropDownStyles.iconContainer}
            >
              <Icon
                className={CellDropDownStyles.icon}
                icon={icon || undefined}
                strokeIcon={strokeIcon || undefined}
                style={iconStyles || {}}
              />
            </div>
          )}
          <span>{currentLabel}</span>
          <Icon
            strokeIcon={chevronBottom}
            width={10}
            ref={(element) => (this.openIcon = element)}
          />
        </button>
        <div className={CellDropDownStyles.borderBottom} />
        {optionsDisplayed ? (
          <div
            className={CellDropDownStyles.optionsContainer}
            ref={(div) => (this.optionsContainer = div as HTMLDivElement)}
          >
            <div
              className={CellDropDownStyles.optionsInnerContainer}
              ref={(div) => (this.optionsInnerContainer = div as HTMLDivElement)}
            >
              {options.map((currentOption, index) => [
                <button
                  className={CellDropDownStyles.option}
                  key={String(currentOption)}
                  ref={(el) => (this[`option${index}`] = el)}
                  onClick={() => this.selectOption(currentOption.value)}
                  type="button"
                >
                  <span>{currentOption.label || currentOption.value}</span>
                  {currentOption.value === currentValue && <Icon strokeIcon={check} width={15} />}
                </button>,
                index < String(currentOption).length - 1 ? (
                  <div
                    className={CellDropDownStyles.border}
                    key={`${currentOption.value}-border`}
                  />
                ) : null,
              ])}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
