import { colors, Icon } from "@skillup/ui";

import styles from "../AddTraineesContent.module.scss";
import { ISimpleSelectionGroup, ISimpleSelectionUserWithManager } from "@skillup/types";

interface IProps {
  onSelect: (users: ISimpleSelectionUserWithManager[]) => void;
  onUnSelect: (group: ISimpleSelectionGroup) => void;
  groups: ISimpleSelectionGroup[];
  usersToPickFrom: ISimpleSelectionUserWithManager[][];
  label: string;
}

const PendingUsersGroupList = ({
  label,
  groups,
  usersToPickFrom,
  onSelect,
  onUnSelect,
}: IProps) => {
  return (
    <>
      <div className={styles.filterSection}>
        <h2>{label}</h2>
        <div className={styles.waitingList}>
          {groups?.length ? (
            <div>
              {groups.map((group, i) => {
                const partialGroup = usersToPickFrom[i];
                return (
                  <li key={group.label} aria-label={group.label} tag-test="waiting-user">
                    <p>
                      {group.label} ({partialGroup.length})
                    </p>
                    <div
                      aria-label={`selectionner-groupe-${group.label}`}
                      className={styles.chevronList}
                      onClick={() => {
                        if (partialGroup.length) onSelect(partialGroup);
                        else onUnSelect(group);
                      }}
                    >
                      {partialGroup.length ? (
                        <Icon.ChevronRight color={colors.primaryBlue} size="1.125rem" />
                      ) : (
                        <Icon.ChevronLeft color={colors.primaryBlue} size="1.125rem" />
                      )}
                    </div>
                  </li>
                );
              })}
            </div>
          ) : (
            <p className={styles.emptyWaitingList}>La liste est vide</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PendingUsersGroupList;
