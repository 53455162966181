import { DatasetKey, FieldKey, TimelineRoutes } from "@skillup/core-hr-bridge";

import { buildRequest } from "utils/buildRequest";

export async function getTimelineForFields({
  employeeID,
  fieldsKeys,
}: {
  employeeID: string;
  fieldsKeys: FieldKey[];
}): Promise<TimelineRoutes<DatasetKey>["GetTimelineForFieldsAsHR"]["response"]> {
  try {
    return await buildRequest<TimelineRoutes<DatasetKey>["GetTimelineForFieldsAsHR"]>({
      method: "GET",
      path: `/timeline/hr/employees/{employeeID}`,
      params: { employeeID },
      query: { fieldsKeys },
      target: "CORE_HR",
    })();
  } catch (err) {
    throw new Error("Error fetching timeline for fields");
  }
}
