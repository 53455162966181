import React from "react";

export interface IContext<StateType, ActionType> {
  state: StateType;
  dispatch: React.Dispatch<ActionType>;
}

export interface IProps<ContextType> {
  children: React.ReactNode;
  value?: ContextType;
}

const generateContext = <T, A>(initialState: T, reducer: (s: T, action: A) => T) => {
  // @ts-ignore
  const context = React.createContext<IContext<T, A>>({ state: initialState });

  const ContextProvider = (props: IProps<IContext<T, A>>) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = { state, dispatch };
    return <context.Provider value={props.value || value}>{props.children}</context.Provider>;
  };

  return {
    context,
    ContextProvider,
    ContextConsumer: context.Consumer,
  };
};

export default generateContext;
