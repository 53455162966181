import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import z from "zod";
import { Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { Label, DSModal, DSColor } from "@skillup/ui";
import { Flex, FormikInputText } from "@skillup/design-system";

import { useActions } from "services/peopleReview";

import ColorPicker from "../../picker/ColorPicker";

const initialValues = { label: "" };

interface CreateActionParams {
  label: string;
}

const ActionSchema = z.object({
  label: z
    .string({
      required_error: "Ce champ est obligatoire",
    })
    .trim()
    .min(1, "Ce champ ne doit pas être vide"),
});

export const CreateActionModal = ({ closeModal, isOpen }) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { mutations } = useActions({ refetchOnMount: false, refetchOnWindowFocus: false });

  const [selectedColor, setSelectedColor] = useState(DSColor["greyscale-dark"]);

  const createAction = async ({ label }: CreateActionParams) => {
    try {
      const result = await mutations.create.mutateAsync({ color: selectedColor, label });
      addToast("Action créée avec succès", { appearance: "success" });
      closeModal();

      return result;
    } catch (err) {
      addToast("Une erreur est survenue", { appearance: "error" });
      return [];
    }
  };

  const { isLoading, isSuccess } = mutations.create;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(ActionSchema)}
      onSubmit={createAction}
    >
      {({ handleSubmit, values }) => (
        <DSModal isOpen={isOpen} portalTarget={document.body}>
          <DSModal.Header onClose={closeModal}>
            <DSModal.Header.Title
              title={t("peoplereviewsConfiguration.actions.modals.creation.title", {
                defaultValue: "Nouvelle action",
              })}
            />
          </DSModal.Header>
          <DSModal.Content>
            <Flex paddingBottom="s" flexDirection="column">
              <Label required aria-required label="Intitulé de l'action" />
              <FormikInputText
                name="label"
                value={values.label}
                placeholder={t(
                  "peoplereviewsConfiguration.actions.modals.creation.fields.title.placeholder",
                  {
                    defaultValue: "Intitulé de l'action",
                  }
                )}
              />
              <ColorPicker selected={selectedColor} onSelect={setSelectedColor} />
            </Flex>
          </DSModal.Content>
          <DSModal.Footer>
            <DSModal.Footer.CancelButton
              onClick={closeModal}
              label={t("peoplereviewsConfiguration.actions.modals.creation.cancel", {
                defaultValue: "Annuler",
              })}
            />
            <DSModal.Footer.PrimaryButton
              loading={isLoading}
              disabled={isLoading || isSuccess}
              onClick={() => handleSubmit()}
              label={t("peoplereviewsConfiguration.actions.modals.creation.confirm", {
                defaultValue: "Confirmer",
              })}
            />
          </DSModal.Footer>
        </DSModal>
      )}
    </Formik>
  );
};