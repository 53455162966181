import { Flex } from "@skillup/design-system";
import { DSRadio, DSRadioGroup } from "@skillup/ui";

import { EvaluationScale } from "types/skills";
import useTranslation from "hooks/useTranslation";

import { FormLabel } from "../JobForm.styled";

interface Props {
  skillUuid: string;
  levelUuid?: string;
  evaluationScale: EvaluationScale;
  onChange: (levelUuid: string | undefined) => void;
}

const SkillLevelContent = ({ evaluationScale, levelUuid, onChange, skillUuid }: Props) => {
  const { t } = useTranslation();
  const handleChange = (value: string | undefined) => {
    onChange(value);
  };

  return (
    <Flex flexDirection="column">
      <FormLabel
        label={t("job.form.skillLevelModal.expectedSkillLevel", {
          defaultValue: "Niveau de maîtrise attendu sur la fiche de poste",
        })}
      />
      <Flex gap="xs" flexDirection="column">
        <DSRadioGroup name={"job-expected-levels" + skillUuid}>
          <DSRadio
            key={"no-level"}
            onChange={() => handleChange(undefined)}
            label={t("job.form.skillLevelModal.noLevelSelected", {
              defaultValue: "Non spécifié",
            })}
          />
          {evaluationScale.levels.map((level) => (
            <DSRadio
              key={level.uuid}
              label={level.name}
              helperText={level.description}
              checked={level.uuid === levelUuid}
              onChange={() => handleChange(level.uuid)}
            />
          ))}
        </DSRadioGroup>
      </Flex>
    </Flex>
  );
};

export default SkillLevelContent;
