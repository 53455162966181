import { useHistory } from "react-router";
import React, { useState, useEffect } from "react";

import { getInitials } from "commons";

import { DSAvatarGroup } from "@skillup/ui";

import {
  SkillRow,
  LevelBlock,
  EmployeeNumber,
  LevelContainer,
  SkillContainer,
  LinkEllipsisText,
} from "./EvaluationBoard.styled";

export const SkillRowWrapper = ({ anchor, openSidePanelWithEmployees, skill }) => {
  const history = useHistory();
  const [isSelected, setIsSelected] = useState(anchor === skill.uuid);

  useEffect(() => {
    setIsSelected(anchor === skill.uuid);

    const timer = setTimeout(() => {
      setIsSelected(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [anchor, skill.uuid]);

  return (
    <SkillRow isSelected={isSelected}>
      <SkillContainer key={skill.uuid}>
        <LinkEllipsisText
          onClick={() => history.push(`/responsable/analyses/competence/${skill.uuid}`)}
        >
          {skill.name}
        </LinkEllipsisText>
      </SkillContainer>
      <LevelContainer>
        {skill.evaluationScaleLevels.map((evaluationScaleLevel) => (
          <LevelBlock
            key={evaluationScaleLevel.uuid}
            isAboveExpectedLevel={
              skill.levelExpected ? evaluationScaleLevel.level >= skill.levelExpected : false
            }
            onClick={() =>
              openSidePanelWithEmployees({
                level: evaluationScaleLevel,
                skillName: skill.name,
                skillUuid: skill.uuid,
              })
            }
          >
            {skill.evaluationScaleLevels.length < 6 ? (
              <DSAvatarGroup
                size="S"
                maxItems={4}
                firstElementForeground
                totalItems={evaluationScaleLevel.employees.length}
                users={evaluationScaleLevel.employees.map((employee) => ({
                  uuid: employee.uuid,
                  fullName: employee.fullName,
                  initials: getInitials(employee.fullName),
                }))}
              />
            ) : (
              <EmployeeNumber isMostAssigned={evaluationScaleLevel.isMostAssigned}>
                {evaluationScaleLevel.employees.length}
              </EmployeeNumber>
            )}
          </LevelBlock>
        ))}
      </LevelContainer>
    </SkillRow>
  );
};
