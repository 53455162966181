import type { GetUsersByFilterResponse } from "types/api";

export enum AuthStrategyType {
  OTL = "otl",
  PASSWORD = "password",
  SAML = "saml",
}

export interface BulkAction {
  name: string;
  available: (user: User) => boolean;
  action: (users: User[]) => void;
}

export type ConnectionModeProps = {
  label: string;
  helpText: string;
  enabled: boolean;
};

export type User = GetUsersByFilterResponse[0];

export type UserModalProps = {
  users: User[];
  onClose: () => void;
  onSubmit: (data?: any) => void;
};
