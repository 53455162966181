import { DashboardConfig } from "../types";

//TODO: Define real default config
export const defaultConfig: DashboardConfig = {
  short_name: "dashboard_1",
  description: "This is a dashboard that does something...",
  panels: [
    {
      short_name: "panel_1",
      description: "This is a panel that does something...",
      relative_width: 0.5,
      cards: [
        {
          short_name: "card_1",
          description: "This is a card that does something...",
          relative_width: 0.5,
          question_name: "sum_of_real_cost_by_completion_phase",
          selected_charts: ["table", "bar"],
          selected_filters: ["filter_plan_line_completion_phase_multi_select"],
          x_label: "X-toto",
          y_label: "Y-toto",
        },
      ],
    },
  ],
  feature_tags: ["training"],
};
