import { useCallback, useMemo } from "react";

import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";

import { DNDChildItem } from "../../dndReducer";
import { StructureChild } from "./StructureChild";
import { useBuilderContext } from "../../../BuilderContext";
import { useBuilderModalsContext } from "../../../BuilderModalsContext";

import { useDispatch, useSelector } from "react-redux";
import { selectHighlightUuid, setHighlightUuid } from "../../../reducer";

import styles from "../../TemplateBuilderStructure.module.scss";

type DraggableChild = DNDChildItem & {
  type: "child";
  sectionIndex: number;
  pageIndex: number;
  childIndex: number;
};

export const SortableChild = ({ child }: { child: DraggableChild }) => {
  const highlightUuid = useSelector(selectHighlightUuid);
  const item = useMemo(() => {
    return {
      type: child.type,
      uuid: child.uuid,
      sectionIndex: child.sectionIndex,
      pageIndex: child.pageIndex,
      childIndex: child.childIndex,
    };
  }, [child]);

  const { active, isDragging, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: child.uuid,
      data: { ...item, uuid: undefined },
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { clickStructureItem } = useBuilderContext();
  const { handleDeletion, handleDuplication, showActionsButtons } = useBuilderModalsContext();
  const handleClick = useCallback(() => {
    clickStructureItem(child.uuid);
  }, [clickStructureItem, child.uuid]);

  const dispatchStore = useDispatch();
  const handleFocus = useCallback(() => {
    dispatchStore(setHighlightUuid(child.uuid));
  }, [dispatchStore, child.uuid]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={isDragging ? styles.dragging : ""}
      {...attributes}
    >
      <StructureChild
        id={`structure_${child.uuid}`}
        displayMenuOnHover={!active}
        kind={child.kind}
        type={child.kindType}
        label={child.label}
        errors={child.errors?.map((error) => error.structure)}
        dragListeners={listeners}
        highlight={highlightUuid === child.uuid}
        onClick={handleClick}
        onFocus={handleFocus}
        onDuplication={() => handleDuplication(item)}
        onDeletion={() => handleDeletion(item)}
        showActionsButtons={showActionsButtons(item)}
      />
    </div>
  );
};
