import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { match, useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  DSButton,
  DSDropdown,
  DSDropdownItem,
  DSTooltip,
  Flex,
  MaterialIcons,
  DSAlert,
  DSAlertType,
  DSAlertDisplay,
} from "@skillup/ui";

import {Loader} from "@skillup/design-system"
import { StructureBuilderContent } from "./content";
import { IWithRouter } from "utils/types";
import { plural } from "utils/locale";
import { isUndoKeyboardEvent, isRedoKeyboardEvent } from "./utils";
import { useCampaign } from "services/structure";
import {
  init,
  clear,
  setSavedKey,
  selectCanRedo,
  selectCanUndo,
  selectStructure,
  selectErrors,
  Section,
} from "./reducer";
import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import { StructureBuilderStructure } from "./structure";
import { WarningQuitWithoutSave, ErrorsOnSaveModal } from "./modals";
import { useDispatch, useSelector } from "react-redux";
import { ActionCreators } from "redux-undo";
import { BuilderProvider, useBuilderContext } from "./BuilderContext";
import { EventService } from "./eventService";
import { filterObject } from "../utils";
import { BuilderModalsProvider, useBuilderModalsContext } from "./BuilderModalsContext";
import { ErrorBadge } from "../components";
import { getChildErrors, getPageErrors, getSectionErrors } from "./reducer/utils";

import styles from "./StructureBuilder.module.scss";

interface StructureBuilderProps extends IWithRouter {
  match: match<{ campaignUuid?: string }>;
}

export enum ScrollTriggeredBy {
  User = "triggered-by-user",
  Builder = "triggered-by-builder",
}

export const StructureBuilder = ({ match: { params } }: StructureBuilderProps): JSX.Element => {
  const { campaignUuid } = params;
  const { isLoading, isError, structure, campaignTitle } = useCampaign(campaignUuid);
  const eventService = new EventService();
  const storeDispatch = useDispatch();

  useEffect(() => {
    return () => {
      storeDispatch(clear());
    };
  }, [campaignUuid, storeDispatch]);

  if (isLoading || isError) {
    return (
      <LoadingOrErrorBuilder
        campaignUuid={campaignUuid}
        campaignTitle={campaignTitle}
        isLoading={isLoading}
      />
    );
  }

  return (
    <BuilderProvider
      structure={structure}
      campaignTitle={campaignTitle}
      eventService={eventService}
    >
      <BuilderModalsProvider>
        <StructureBuilderLayout />
      </BuilderModalsProvider>
    </BuilderProvider>
  );
};

export const StructureBuilderLayout = memo(() => {
  const { addToast, removeAllToasts } = useToasts();
  const history = useHistory();
  const { campaignUuid } = useParams<{ campaignUuid: string }>();
  const {
    structure,
    campaignTitle,
    structureNavRef,
    contentItemsRefs: itemsRefs,
    focusStructureItemFromErrorDropdown,
    updatedItemIdRef,
  } = useBuilderContext();

  const { saveContent } = useCampaign(campaignUuid);

  const { modalToDisplay: builderModalToDisplay } = useBuilderModalsContext();
  // specific modal quitting warning
  const [isOpenWarningOnQuit, setIsOpenWarningOnQuit] = useState(false);
  const [errorsOnSave, setErrorsOnSave] = useState(0);

  // store structure and savedKey for handling save button disabled state
  const [lastSavedKey, setLastSavedKey] = useState<string>();
  // [CSB] we do not allow to edit target scales for now
  // const choicesForOrdinalTargets = useRef<string[]>();
  const structureStore = useSelector(selectStructure);
  const canRedo = useSelector(selectCanRedo);
  const canUndo = useSelector(selectCanUndo);
  const builderErrors = useSelector(selectErrors);
  const isFirstInitStructure = useRef<boolean>(true);
  const storeDispatch = useDispatch();

  const isLastSavedState = useMemo(
    () => lastSavedKey === structureStore.savedKey,
    [lastSavedKey, structureStore]
  );

  const createAndSetSavedKey = useCallback(() => {
    const savedKey = uuid();
    setLastSavedKey(savedKey);
    storeDispatch(setSavedKey(savedKey));
  }, [storeDispatch, setLastSavedKey]);

  // init the store only once
  useEffect(() => {
    if (structure) {
      if (isFirstInitStructure.current) {
        if (structure.sections?.length === 2) {
          updatedItemIdRef.current = {
            uuid: structure.sections[1].uuid,
            fromAction: "init",
          };
        }
        storeDispatch(init({ structure }));
        createAndSetSavedKey();
        storeDispatch(ActionCreators.clearHistory());
        isFirstInitStructure.current = false;
      } else {
        // [CSB] we do not allow to edit target scales for now
        // if (!isEqual(choicesForOrdinalTargets.current, structure.choicesForOrdinalTargets)) {
        //   choicesForOrdinalTargets.current = structure.choicesForOrdinalTargets;
        //   storeDispatch(
        //     checkErrors({ choicesForOrdinalTargets: structure.choicesForOrdinalTargets })
        //   );
        // }
      }
    } else {
      if (!isFirstInitStructure.current) {
        history.push(`/responsable/campagne/${campaignUuid}`);
      }
    }
  }, [structure, updatedItemIdRef, history, createAndSetSavedKey, storeDispatch, campaignUuid]);

  const handleSave = useCallback(async () => {
    // [CSB] we do not allow to edit target scales for now
    // storeDispatch(
    //   checkErrors({ choicesForOrdinalTargets: structure?.choicesForOrdinalTargets ?? [] })
    // );
    const errorsInStructureItems = getErrorsInStructureItems(
      structureStore.sections
      // [CSB] we do not allow to edit target scales for now
      // structure?.choicesForOrdinalTargets ?? []
    );
    if (errorsInStructureItems.length > 0 || builderErrors.length > 0) {
      setErrorsOnSave(errorsInStructureItems.length);
      return;
    }
    setErrorsOnSave(0);

    const filteredSections = structureStore.sections.map((section) => {
      const filteredSection = {
        ...section,
        pages: section.pages.map((page) => {
          const filteredPage = {
            ...page,
            children: page.children.map((child) =>
              filterObject(child, ([key]) => key !== "errors")
            ),
          };
          return filterObject(filteredPage, ([key]) => key !== "errors");
        }),
      };
      return filterObject(filteredSection, ([key]) => key !== "errors");
    });

    try {
      const updatedStructure = await saveContent({
        sections: filteredSections,
        campaignUuid,
      });
      storeDispatch(init({ structure: updatedStructure }));
      createAndSetSavedKey();
      addToast(
        <span>
          La trame de la campagne « {campaignTitle ?? ""} » a bien été modifiée.
          <br />
          <p className={styles.toaster__subtext}>
            <br />
            Vos modifications n'ont pas été répercutées sur la trame d'entretien initialement
            utilisée (« {structure?.title ?? ""} »).
          </p>
        </span>,
        {
          appearance: "success",
          autoDismiss: false,
        }
      );
    } catch (e) {
      addToast("Une erreur est survenue lors de l'enregistrement de la trame", {
        appearance: "error",
      });
      return;
    }
  }, [
    structure,
    structureStore,
    saveContent,
    createAndSetSavedKey,
    addToast,
    setErrorsOnSave,
    builderErrors,
    campaignTitle,
    campaignUuid,
    storeDispatch,
  ]);

  const handleBackLink = useCallback(() => {
    if (isLastSavedState) {
      history.push(`/responsable/campagne/${campaignUuid}`);
    } else {
      setIsOpenWarningOnQuit(true);
    }
  }, [isLastSavedState, history, campaignUuid]);

  useEffect(() => {
    const undoRedoAction = (event: KeyboardEvent) => {
      if (canUndo && isUndoKeyboardEvent(event)) {
        storeDispatch(ActionCreators.undo());
      }

      if (canRedo && isRedoKeyboardEvent(event)) {
        storeDispatch(ActionCreators.redo());
      }
    };
    document.addEventListener("keydown", undoRedoAction);

    return () => document.removeEventListener("keydown", undoRedoAction);
  }, [canUndo, canRedo, storeDispatch]);

  const itemsErrors = useMemo(() => {
    return builderErrors.flatMap(({ uuid, errors: childErrors }) =>
      childErrors.map(({ header }) => ({
        uuid,
        error: header,
      }))
    );
  }, [builderErrors]);

  return (
    <>
      <DSLayout
        className={styles.structure__builder__layout}
        onClickLayout={removeAllToasts}
        title={campaignTitle ?? ""}
        parent={{
          title: "Correction de la trame de la campagne",
          onClick: handleBackLink,
        }}
        layouts={[
          {
            customHeaderItems: (
              <div className={styles.header__buttons__undoRedo}>
                <DSTooltip label="Annuler (Ctrl+Z / ⌘+Z)" lightColor>
                  <DSButton
                    iconOnly
                    darkMode
                    buttonSize="M"
                    emphasis="High"
                    icon={<MaterialIcons.Undo />}
                    disabled={!canUndo}
                    onClick={() => storeDispatch(ActionCreators.undo())}
                  />
                </DSTooltip>
                <DSTooltip label="Rétablir (Ctrl+Y / ⌘+Y)" lightColor>
                  <DSButton
                    iconOnly
                    darkMode
                    buttonSize="M"
                    emphasis="High"
                    icon={<MaterialIcons.Redo />}
                    disabled={!canRedo}
                    onClick={() => storeDispatch(ActionCreators.redo())}
                  />
                </DSTooltip>
                {itemsErrors.length > 0 && (
                  <DSTooltip
                    label={`Cette trame contient ${itemsErrors.length} ${plural(
                      itemsErrors.length,
                      "erreur%s"
                    )}. Vous devez ${plural(itemsErrors.length, "la", {
                      pluralText: "les",
                    })} corriger pour enregistrer vos modifications.`}
                  >
                    <DSDropdown button={<ErrorBadge value={itemsErrors.length} />}>
                      {itemsErrors.map(({ uuid, error }) => (
                        <DSDropdownItem
                          key={uuid}
                          onClick={() => {
                            itemsRefs.current[uuid]?.focus();
                            focusStructureItemFromErrorDropdown(uuid);
                          }}
                          label={error}
                        />
                      ))}
                    </DSDropdown>
                  </DSTooltip>
                )}
              </div>
            ),
            primaryButton: (
              <DSNewHeaderButton
                label="Appliquer les modifications à la campagne"
                onClick={handleSave}
                disabled={isLastSavedState || builderErrors.length > 0}
              />
            ),
          },
        ]}
      >
        <Flex column>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.FULL_BLEED}>
            <p>
              Les corrections apportées ici seront appliquées à tous les entretiens (présents et
              futurs, signés ou non) de la campagne « {campaignTitle ?? ""} ». <br />
              Elles ne seront pas répercutées sur la trame d'entretien initialement utilisée («{" "}
              {structure?.title ?? ""} »).
            </p>
          </DSAlert>
          <Flex row className={styles.StructureBuilder}>
            <nav
              aria-label="Structure Structure"
              className={styles.structure}
              ref={structureNavRef}
              id="structure_nav"
            >
              <StructureBuilderStructure />
            </nav>
            <StructureBuilderContent />
          </Flex>
        </Flex>
      </DSLayout>
      {builderModalToDisplay}
      <WarningQuitWithoutSave
        isOpen={isOpenWarningOnQuit}
        closeModal={() => setIsOpenWarningOnQuit(false)}
        onConfirm={() =>
          history.length > 2 ? history.goBack() : history.push("/responsable/campagnes")
        }
      />
      <ErrorsOnSaveModal errors={errorsOnSave} closeModal={() => setErrorsOnSave(0)} />
    </>
  );
});

const getErrorsInStructureItems = (
  sections: Section[]
  // [CSB] we do not allow to edit target scales for now
  // choicesForOrdinalTargets?: string[]
): string[] => {
  const errors = [];
  sections.reduce((acc, section) => {
    const sectionErrors = getSectionErrors(section);
    if (sectionErrors?.length > 0) errors.push(...sectionErrors);
    section.pages.forEach((page) => {
      const pageErrors = getPageErrors(page);
      if (pageErrors?.length > 0) errors.push(...pageErrors);
      page.children.forEach((child) => {
        // const childErrors = getChildErrors(child, choicesForOrdinalTargets);
        const childErrors = getChildErrors(child);
        if (childErrors?.length > 0) errors.push(...childErrors);
      });
    });
    return errors?.length ? [...acc, ...errors] : acc;
  }, []);
  return errors;
};

const LoadingOrErrorBuilder = ({campaignUuid, campaignTitle, isLoading}: {campaignUuid: string; campaignTitle: string; isLoading:boolean}) => {
  const history = useHistory();
  return (
    <DSLayout
      title={campaignTitle ?? ""}
      parent={{
        title: "Correction de la trame de la campagne",
        onClick: () => history.push(`/responsable/campagne/${campaignUuid}`),
      }}
    >
      <Flex row className={styles.StructureBuilder}>
          <nav
            aria-label="Structure Structure"
            className={styles.structure}
            id="structure_nav"
          >
            <Flex column className={styles.structure}></Flex>
          </nav>
          <div className={styles.content}>
            {isLoading && <Loader className={styles.loader}/>}
          </div>
        </Flex>
    </DSLayout>
  )
}
