import { Moment } from "moment";
import omit from "lodash/omit";
import { Collaborator } from "../../api";

export interface IFilterState {
  lastName?: string;
  firstName?: string;
  email?: string;
  role?: string;
  manager?: string;
  joinDateAfter?: Moment;
  joinDateBefore?: Moment;
}

export type TFilter = keyof Collaborator | "joinDateAfter" | "joinDateBefore";

export type TFilterAction =
  | { type: "addFilter"; value: string | Moment; name: TFilter }
  | { type: "removeFilter"; name: string };

const reducer = (state: IFilterState, action: TFilterAction): IFilterState => {
  try {
    switch (action.type) {
      // removes the selection
      case "addFilter":
        return {
          ...state,
          [action.name]: action.value,
        };
      case "removeFilter":
        return omit(state, [action.name]);
      default:
        return state;
    }
  } catch (err) {
    console.error(err);
    return state;
  }
};

export default reducer;
