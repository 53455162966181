import { DSButton, DSTooltip, MaterialIcons } from "@skillup/ui";

import styles from "./ButtonsMutateContentItem.module.scss";

type ButtonsMutateContentItemProps = {
  duplicationTooltipLabel: string;
  deletionTooltipLabel: string;
  showActionsButtons: boolean;
  onDuplication: () => void;
  onDeletion: () => void;
};

export const ButtonsMutateContentItem = ({
  duplicationTooltipLabel,
  deletionTooltipLabel,
  showActionsButtons,
  onDuplication,
  onDeletion,
}: ButtonsMutateContentItemProps) => {
  if (!showActionsButtons) {
    return <div className={styles["content__item__no__actions"]} />;
  }

  return (
    <div className={styles["content__item__actions"]}>
      <DSTooltip label={duplicationTooltipLabel} lightColor>
        <DSButton
          buttonSize="S"
          iconOnly
          darkMode
          icon={<MaterialIcons.ContentCopy />}
          onClick={onDuplication}
        />
      </DSTooltip>
      <DSTooltip label={deletionTooltipLabel} lightColor>
        <DSButton
          buttonSize="S"
          iconOnly
          darkMode
          icon={<MaterialIcons.Delete />}
          onClick={onDeletion}
        />
      </DSTooltip>
    </div>
  );
};
