import { useMemo, useState } from "react";
import { useFetch } from "hooks";
import { uniqBy } from "lodash";

import { ClosedQuestionAnswer, DashboardChildType } from "@skillup/espace-rh-bridge";
import {
  MaterialIcons,
  DSProgressBar,
  TypeProgressBar,
  DSAvatarGroup,
  ValueLayoutProgressBar,
} from "@skillup/ui";
import { InterviewWithStates } from "@skillup/types";

import { useDashboardData } from "../../context";
import QuestionCard from "../QuestionCard/QuestionCard";
import { StructureChildPayload } from "../DashboardChildCard/DashboardChildCard";

import RepliesDetailModal from "./RepliesDetailModal/RepliesDetailModal";
import { UnresponsiveUsersBanner } from "./UnresponsiveUserBanner/UnresponsiveUsersBanner";

import styles from "./ClosedEndedQuestion.module.scss";

export interface Props {
  child: StructureChildPayload;
}
export interface user {
  uuid: string;
  fullName: string;
  initials: string;
}

function GetInterviews() {
  const { activeCampaign } = useDashboardData();
  const result = useFetch<InterviewWithStates[]>({
    method: "GET",
    url: `/v1/campaigns/${activeCampaign}/interviews`,
  });
  return result;
}

function getRoles(disallow) {
  const roles = [];
  for (let role in disallow) {
    if (!disallow[role].reply) {
      roles.push(role);
    }
  }
  return roles;
}

const ClosedEndedQuestion = ({ child }: Props) => {
  const [selectedAnswer, setSelectedAnswer] = useState<ClosedQuestionAnswer>();

  const interviews = GetInterviews();
  const roles = getRoles(child.disallow);

  const users: user[] = useMemo(() => {
    const users = [];
    interviews?.data?.forEach((interview) => {
      roles.forEach((role) => {
        users.push({
          uuid: interview[role].uuid,
          fullName: interview[role].fullName,
          initials: interview[role].initials,
        });
      });
    });
    return uniqBy(users, "uuid");
  }, [roles, interviews]);

  function showAnswerModal(answer: ClosedQuestionAnswer) {
    setSelectedAnswer(answer);
  }
  function hideAnswerModal() {
    setSelectedAnswer(null);
  }

  return (
    <QuestionCard<DashboardChildType.CLOSED_QUESTION>
      child={child}
      className={styles.ClosedEndedQuestion}
      icon={<MaterialIcons.FormatListBulleted />}
      typeName="Question fermée"
    >
      {(dashboardPayload, { attendeeRole }) => {
        return (
          <div className={styles.answers}>
            {dashboardPayload.answers && (
              <DSProgressBar
                data={dashboardPayload.answers.map((answer) => ({
                  label: answer.label,
                  value:
                    dashboardPayload.respondents.count &&
                    Math.round(
                      (100 * answer.replies.count) /
                        dashboardPayload.answers.reduce(
                          (acc, { replies }) => acc + replies.count,
                          0
                        )
                    ),
                  containerProps: answer.replies.count && {
                    onClick: () => showAnswerModal(answer),
                    className: styles.answerContainer,
                  },
                  headerProps: {
                    className: styles.answerHeader,
                  },
                  barProps: {
                    className: styles.answerBar,
                  },
                  component: (
                    <DSAvatarGroup
                      className={styles.avatars}
                      avatarClassName={styles.avatar}
                      moreClassName={styles.more}
                      users={answer.replies.users}
                      totalItems={answer.replies.count}
                      size="S"
                      disabled={false}
                    />
                  ),
                }))}
                layout="block"
                type={TypeProgressBar.PERCENTAGE}
                valueLayout={ValueLayoutProgressBar.PERCENTAGE}
              />
            )}
            {selectedAnswer && (
              <RepliesDetailModal
                child={child}
                attendeeRole={attendeeRole}
                answer={selectedAnswer}
                onClose={hideAnswerModal}
              />
            )}

            {child.type === "closed-question" && users && (
              <UnresponsiveUsersBanner users={users} dashboardPayload={dashboardPayload} />
            )}
          </div>
        );
      }}
    </QuestionCard>
  );
};

export default ClosedEndedQuestion;
