import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { format } from "@skillup/shared-utils";
import { colors, ProgressBar, RectangleTags, Icon, useModal, DSDropdown } from "@skillup/ui";
import { IRoutes } from "@skillup/types";
import { CampaignListItem } from "@skillup/espace-rh-api/src/controllers/campaign";
import { CampaignsRoutes } from "@skillup/espace-rh-bridge";

import CampaignActions from "../../Campaign/components/CampaignActions";
import { EditionContext } from "../../Campaign/components/modals/utils";

import styles from "./CampaignCard.module.scss";
import User from "utils/User";
import { CloseCampaignModal } from "../../Campaign/components/modals";

type Campaign = CampaignsRoutes.GetList["response"][0];

const numberOfDaysbeforePrintingTag = 14;

const renderLegacyCard = ({ startDate, endDate }: Campaign) => (
  <>
    <li className={styles.meta}>
      Date de début de campagne : {format(new Date(startDate), "dd/MM/yyyy")}
    </li>
    <li className={styles.meta}>
      Date limite de signature : {format(new Date(endDate), "dd/MM/yyyy")}
    </li>
  </>
);

const renderOngoingCard = ({ frequency, duration }: Campaign) => (
  <>
    <li className={styles.meta}>Déclenchement automatique: {frequency} jours après l'embauche</li>
    <li className={styles.meta}>Délai de signature: {duration} jours</li>
  </>
);

const renderAdHocCard = (_: Campaign) => (
  <>
    <li className={styles.meta}>Déclenchement manuel</li>
  </>
);

const tags = {
  legacy: <RectangleTags.LegacyCampaign key="legacy-campaign" />,
  ongoing: <RectangleTags.OngoingCampaign key="ongoing-campaign" />,
  adHoc: <RectangleTags.AdHocCampaign key="adhoc-campaign" />,
};

interface PropsNumberOfDays {
  type: IRoutes["/campaigns"]["GET"]["/"][0]["type"];
  endDate?: string;
}

const NumberOfDays = ({ type, endDate }: PropsNumberOfDays) => {
  if (type === "legacy") {
    const diff = moment(endDate).diff(moment(), "days");

    if (diff < numberOfDaysbeforePrintingTag && diff >= 0) {
      return <RectangleTags.DaysRemaining numberOfDays={diff} daysForRed={7} />;
    }
  }

  return <></>;
};

interface PropsCampaignIsOver {
  type: IRoutes["/campaigns"]["GET"]["/"][0]["type"];
  endDate?: string;
  total: number;
  current: number;
}

const CampaignIsOver = ({ type, endDate, total, current }: PropsCampaignIsOver) => {
  if (type === "legacy") {
    const diff = moment(endDate).diff(moment(), "days");

    if (diff < 0) {
      if (total === current) return <RectangleTags.CampaignIsOver />;
      else return <RectangleTags.CampaignOutOfTime />;
    }
  }

  return <></>;
};

export interface Props {
  readonly campaign: Campaign;
  readonly onDelete: () => void;
  readonly resync: () => Promise<void>;
  readonly setActionsState: (stateChange: EditionContext) => void;
  readonly setCampaignState: Dispatch<SetStateAction<CampaignListItem>>;
}

const CampaignCard = ({ campaign, onDelete, resync, setActionsState, setCampaignState }: Props) => {
  const {
    uuid,
    titleForHR,
    type,
    endDate,
    signedInterviews,
    totalInterviews,
    managerOnly,
    hideFromEmployeeUntil,
    hideFromManagerUntil,
  } = campaign;

  const {
    isOpen: isCampaignClosingOpen,
    show: showCampaignClosingModal,
    hide: hideCloseCampaignModal,
  } = useModal();

  const history = useHistory();
  const progress =
    totalInterviews === 0 ? 0 : Math.round((signedInterviews / totalInterviews) * 100);

  const diff = endDate && moment(endDate).diff(moment(), "days");

  const tagsToDisplay = [
    tags[type],
    ...(User.isSkillupAdmin()
      ? [
          ...(managerOnly
            ? [<RectangleTags.IsManagerOnlyCampaign key="manager-only-campaign" />]
            : []),
          ...(hideFromEmployeeUntil
            ? [
                <RectangleTags.HideFromEmployeeUntil
                  status={hideFromEmployeeUntil.managerState}
                  key="hide-for-employee-until"
                />,
              ]
            : []),
          ...(hideFromManagerUntil
            ? [
                <RectangleTags.HideFromManagerUntil
                  status={hideFromManagerUntil.employeeState}
                  key="hide-from-manager-until"
                />,
              ]
            : []),
        ]
      : []),
  ];

  const menuItems = useMemo(() => {
    return CampaignActions({
      campaign,
      onDelete,
      resync,
      setActionsState,
      onClick: showCampaignClosingModal,
    });
  }, [campaign, onDelete, resync, setActionsState, showCampaignClosingModal]);

  const [dropdownIsOpened, setDropdownIsOpened] = useState(false);

  const onClick = (value: boolean) => {
    setDropdownIsOpened(value);
    setCampaignState(campaign);
  };
  return (
    <>
      {isCampaignClosingOpen && (
        <CloseCampaignModal campaign={campaign} onClose={hideCloseCampaignModal} resync={resync} />
      )}
      <section
        className={styles.CampaignCard}
        onClick={() => {
          if (!dropdownIsOpened) history.push(`/responsable/campagne/${uuid}`);
        }}
      >
        <div className={styles.tags}>
          <div className={styles.tagsLeft}>{tagsToDisplay}</div>
          <div className={styles.tagsRight}>
            <NumberOfDays type={type} endDate={endDate} />
            <CampaignIsOver
              type={type}
              total={totalInterviews}
              current={signedInterviews}
              endDate={endDate}
            />
            {menuItems.length > 0 && (
              <DSDropdown onToggleDropdown={onClick} className={styles.dropdowContainer}>
                {menuItems}
              </DSDropdown>
            )}
          </div>
        </div>

        <div className={styles.infos}>
          <h4 className={styles.name}>{titleForHR}</h4>
          <ul className={styles.props}>
            {type === "legacy" && renderLegacyCard(campaign)}
            {type === "ongoing" && renderOngoingCard(campaign)}
            {type === "adHoc" && renderAdHocCard(campaign)}
          </ul>
        </div>

        <div className={styles.progress}>
          <div className={styles.progressBarInfos}>
            {diff &&
            diff < numberOfDaysbeforePrintingTag &&
            signedInterviews !== totalInterviews ? (
              <Icon.SignError color="#535d6d" />
            ) : undefined}
            <div className={styles.textWrapper}>
              <h5 className={styles.percentage}>{progress}%</h5>
              <span
                className={styles.details}
              >{`(${signedInterviews}/${totalInterviews} entretiens signés)`}</span>
            </div>
          </div>
          <ProgressBar
            max={totalInterviews}
            value={signedInterviews}
            color={colors.lightblue}
            side="none"
          />
        </div>
      </section>
    </>
  );
};

export default CampaignCard;
