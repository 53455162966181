import { FilterRef, UseFilterProp, useFilters } from "@skillup/ui";
import { Config } from "@skillup/training-bridge";

import { useCallback, useMemo, useRef } from "react";
import useTranslation from "hooks/useTranslation";
import { GridColDef } from "@mui/x-data-grid-pro";

const useFiltersSettings = (displayIsTrainingField = false) => {
  const filterRef = useRef<FilterRef>();
  const { t } = useTranslation();

  const filterProps = useMemo(() => {
    const filtersConfig: UseFilterProp<typeof Config.Fields.CustomFieldsFilters> = {
      label: {
        visibilityMode: "always",
      },
      type: {
        options: [
          {
            label: t("fields.type.number", {
              defaultValue: "nombre",
            }),
            value: "number",
          },
          {
            label: t("fields.type.select", {
              defaultValue: "liste d'options",
            }),
            value: "select",
          },
          {
            label: t("fields.type.text", {
              defaultValue: "text",
            }),
            value: "text",
          },
          {
            label: t("fields.type.monetary", {
              defaultValue: "monetary",
            }),
            value: "monetary",
          },
          {
            label: t("fields.type.textarea", {
              defaultValue: "textarea",
            }),
            value: "textarea",
          },
          {
            label: t("fields.type.date", {
              defaultValue: "date",
            }),
            value: "date",
          },
        ],
      },
      isTrainingField: {
        visibilityMode: displayIsTrainingField ? "default" : "never",
        // select options doenst take boolean values, parse to string for bypass that
        options: [
          {
            label: t("fields.isTrainingField.true", {
              defaultValue: "Oui",
            }),
            value: "true",
          },
          {
            label: t("fields.isTrainingField.false", {
              defaultValue: "Non",
            }),
            value: "false",
          },
        ],
      },
    };

    return filtersConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filters, filterValues, setFilters] = useFilters(
    displayIsTrainingField ? Config.Fields.CustomFieldsFilters : Config.Fields.FieldsFilters,
    filterProps
  );

  const handleFilterValuesChange = useCallback(
    (filterValues) => {
      setFilters(filterValues);
    },
    [setFilters]
  );

  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  };

  return [
    filterRef,
    filters,
    filterValues,
    handleFilterValuesChange,
    handleTableColumnFilterClick,
  ] as const;
};

export default useFiltersSettings;
