import { buildRequest } from "utils/buildRequest";
import { AddToast } from "react-toast-notifications";
import history from "utils/history";

import type { IntraRoutes } from "types/api";

export type IntraUpdatePayload = IntraRoutes.UPDATE["payload"];
export type IntraUpdateParams = {
  intraUuid: string;
  intraDetails: IntraUpdatePayload;
  addToast: AddToast;
};

const updateIntra = async ({ intraUuid, intraDetails, addToast }: IntraUpdateParams) => {
  try {
    const request = buildRequest<IntraRoutes.UPDATE>({
      method: "POST",
      params: {
        intraUuid,
      },
      path: "/intra/{intraUuid}",
      payload: intraDetails,
    });

    const { success } = await request();
    if (!success) {
      throw new Error();
    }

    addToast("Modifications enregistrées avec succès", {
      appearance: "success",
      autoDismiss: true,
    });
    return history.push(`/responsable/programmes/gestion-intras/`);
  } catch (err) {
    addToast("Erreur lors de la modification des détails.", {
      appearance: "error",
      autoDismiss: true,
    });
  }
};

export default updateIntra;
export type IntraUpdateFunction = typeof updateIntra;
