import React, { useRef, useState, useCallback, useEffect, Fragment } from "react";
import { useSetState, useMount, useToggle } from "react-use";
import { useParams } from "react-router-dom";
import { IoMdInformationCircle } from "react-icons/io";

import { useFetch } from "hooks";
import useTranslation from "hooks/useTranslation";
import cx from "classnames";
import { isNil } from "lodash";
import { analytics } from "services";
import moment, { Moment } from "moment";

import { Select as DSSelect, Label } from "@skillup/ui";
import type { IScheduleLead, IOrganization } from "@skillup/types";
import { BookingRoutes } from "@skillup/espace-rh-bridge";
import { EmployeeTrainingSessionState } from "@skillup/training-bridge";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import DateUtils from "utils/dates";
import User from "utils/User";
import { buildRequest } from "utils/buildRequest";
import injectSafeHtml from "utils/sanitizer";
import modalitiesConstants from "constants/modalities";

import TrainingScore from "components/TrainingScore";
import InteractiveButton from "components/InteractiveButton";
import DropDown, { DropDownOptions } from "components/DropDown";
import TextInput from "components/TextInput";
import StepRegistration from "./components/StepRegistration";

import { position, phone as phoneIcon } from "uiAssets/StrokeIcons";
import Colors from "uiAssets/Colors";

import styles from "./TrainingInscriptionStyles.module.scss";
import { GetEmployeeTrainingSessionsStateRoute } from "@skillup/espace-rh-bridge/src/routes/training/getEmployeeTrainingSessionsState";

interface State {
  displayProgram: boolean;
  step: string;
  training?: any;
  trainee?: any;
  company?: any;
  lead?: IScheduleLead;
  currentSessionPlace?: any;
  currentSession?: any;
  organization?: IOrganization;
  success?: boolean;
  properties?: any;
  sessionsState?: EmployeeTrainingSessionState[];
}

const TrainingInscription = () => {
  const { t } = useTranslation();
  const { rowUuid } = useParams<{ rowUuid?: string }>();

  const sessionDropdown = useRef<DropDown<DropDownOptions<string>>>(null);
  const [submitting, toggleSubmitting] = useToggle(false);

  const [state, setState] = useSetState<State>({
    displayProgram: false,
    step: "home",
  });

  const {
    training,
    organization,
    trainee,
    company,
    currentSessionPlace,
    currentSession,
    displayProgram,
    lead,
    step,
    sessionsState,
  } = state;

  const [role, setRole] = useState<string>();
  const [phone, setPhone] = useState<string>();

  const { data, loading, error } = useFetch<{ step: string; properties: any }>({
    url: `/v1/scheduleRow/action/${rowUuid}`,
  });

  useMount(() => {
    analytics.sendEvent("Booking", "Open booking page", rowUuid);
  });

  useEffect(() => {
    if (data && !loading) {
      const { properties, step } = data;

      if (step !== "home") {
        return setState({ step, ...properties });
      }

      setRole(properties.trainee.role || "");
      setPhone(properties.trainee.phone || "");

      return setState({
        training: properties.training, // This can be an intra, but it's transparent.
        trainee: properties.trainee,
        company: properties.company,
        lead: properties.lead,
        currentSessionPlace:
          properties.training.places && properties.training.places[0]
            ? properties.training.places[0].value
            : null,
        currentSession: null,
        organization: properties.organization,
        success: false,
      });
    }

    if (error) {
      console.trace(error);
      return setState({ step: "error", properties: {} });
    }
  }, [data, loading, error, setState, setRole, setPhone]);

  useEffect(() => {
    if (!training || !trainee) {
      return;
    }

    const fetchSessionsState = async () => {
      try {
        const sessionsState = await DataLayer.request<GetEmployeeTrainingSessionsStateRoute>({
          method: "GET",
          url: `/v1/training/${training.uuid}/sessions-state/${trainee.uuid}`,
        });

        setState((state) => ({ ...state, sessionsState }));
      } catch (error) {
        console.error("An error occured while fetching session state", error);
      }
    };

    fetchSessionsState();
  }, [training, trainee, setState]);

  const hasOnlineSessions = (sessions) => {
    if (isNil(sessions) || !sessions.length) return false;
    return sessions.filter((session) => session.online).length > 0;
  };

  const onSetPlace = useCallback(
    (city) => {
      setState({
        currentSessionPlace: city,
        currentSession: null,
      });

      if (sessionDropdown.current) {
        sessionDropdown.current.selectOption(null);
      }
    },
    [setState, sessionDropdown]
  );

  const onSetSession = useCallback(
    (session) => {
      setState({ currentSession: session });
    },
    [setState]
  );

  const submit = useCallback(
    async (e): Promise<void> => {
      e.preventDefault();

      const {
        lead: { type },
        currentSession,
        trainee,
      } = state;

      if (!currentSession) {
        return Acta.dispatchEvent("sendAppMessage", {
          message: "Vous devez choisir une date de session.",
          type: "error",
        });
      }

      toggleSubmitting(true);

      try {
        const data = {
          session: currentSession,
          scheduleRow: rowUuid,
        };

        if (type === "inter") {
          const dataWithTrainees = {
            ...data,
            trainee: {
              fullName: trainee.fullName,
              email: trainee.email,
              role,
              noEmail: trainee.noEmail,
              phone,
              invoicingBudgetaryCode: trainee.invoicingBudgetaryCode,
              company: trainee.company,
            },
          };
          await buildRequest<BookingRoutes.CreateInterBooking>({
            method: "POST",
            path: "/booking/booking-inter",
            payload: dataWithTrainees,
          })();
          setState({ step: "success" });
          analytics.sendEvent("Booking", "Success page", rowUuid);
        } else if (type === "intra") {
          await DataLayer.request({
            method: "POST",
            url: "/v1/scheduleLead/position-on-session",
            body: JSON.stringify(data),
          });
          setState({ step: "success" });
        }
      } catch (error) {
        console.trace(error);
        if (error.statusCode === 429) {
          Acta.dispatchEvent("sendAppMessage", {
            message:
              "Trop de demandes simultanées. Veuillez patienter un instant et essayer à nouveau de soumettre votre demande.",
            type: "error",
          });
        } else if (error.statusCode === 410) {
          setState({ step: "full" });
        } else {
          analytics.sendEvent("Booking", "Error page", rowUuid);
          setState({ step: "error" });
        }

        toggleSubmitting(false);
      }
    },
    [rowUuid, state, role, phone, setState, toggleSubmitting]
  );

  const computeSessionDates = useCallback((dates?: string[]): JSX.Element => {
    if (!dates) return undefined;

    const intervals = dates
      .map((d) => d.split("/").filter(Boolean))
      .map((interval) => interval.map((date) => moment(date)));

    const countedDays = new Array<number>();
    const durationAsDays = intervals.reduce((acc, interval: Moment[]) => {
      // We don't want to count the same day twice.
      if (countedDays.includes(interval[0].startOf("day").unix())) return acc;
      countedDays.push(interval[0].startOf("day").unix());

      if (interval.length === 1) return acc + 1;
      return acc + 1 + interval[interval.length - 1].diff(interval[0], "day");
      // +1 is because moment doesn't think the last day is included.
    }, 0);

    const formattedDates = DateUtils.computeForDisplay(dates.map((d) => d.split("/")));

    return (
      <div className={styles.sessionDatesPanel}>
        <div>
          <IoMdInformationCircle size={32} color={Colors.altScoreColor} />
        </div>
        <div className={styles.content}>
          <span>
            Formation sur {durationAsDays} jour{durationAsDays === 1 ? "" : "s"}
          </span>
          <ul>
            {formattedDates.map((formattedDate) => (
              <li key={formattedDate}>{formattedDate}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }, []);

  if (!training) {
    if (step === "home") return null;

    return (
      <main className={styles.TrainingInscription}>
        <StepRegistration step={step} trainee={trainee} currentSession={null} />;
      </main>
    );
  }

  const { name, reviews = [], sessions = [], places = [], sessionsByPlace } = training;

  let possibleDates = [];

  if (currentSessionPlace) {
    possibleDates =
      sessionsByPlace &&
      sessionsByPlace[currentSessionPlace].map((session) => {
        const startDateRange = DateUtils.parseDateRange(session.dates?.[0]?.split("/"), {
          dateFormat: "ddd D MMM yyyy",
        });

        const isDisabled = sessionsState?.some((s) => s.sessionUuid === session.uuid && s.isPositioned);

        return {
          value: session.uuid,
          label: `${startDateRange.days[0]}${
            startDateRange.hours ? ` | ${startDateRange.hours[0]}` : ""
          } ${isDisabled ? 
              ` ${t("training.inscription.session.already_register", {
                defaultValue: "(le collaborateur est déjà inscrit à cette session)",
              })}`: 
              ""
            }`,
          isDisabled,
        };
      });
  }

  const hasSessions = hasOnlineSessions(sessions);
  const isRf = User.isRF();

  const organizationLogoUrl =
    organization && organization.profileLogoOptiSmall
      ? `${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${organization.profileLogoOptiSmall}`
      : null;

  const companyLogoUrl =
    company && company.logo ? `${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${company.logo}` : null;

  // Details data
  const trainingDuration = training.formattedDuration;

  const mappedTrainingContent = [
    {
      title: "Objectifs",
      content: training.objectives,
    },
    {
      title: "Pré-requis",
      content: training.prerequisites,
    },
    {
      title: "Programme",
      content: training.program,
    },
  ];

  const selectedSessionData = sessions.find((s) => s.uuid === currentSession);

  const currentSessionDatesPanel =
    lead && lead.type === "intra" ? computeSessionDates((selectedSessionData || {}).dates) : null;

  return (
    <main className={styles.TrainingInscription}>
      {step === "home" ? (
        <div className={cx(styles.home, styles.card)}>
          <header>
            <h1>Inscription à une formation</h1>
            {trainee && trainee.fullName && <p>Pour {trainee.fullName}</p>}
          </header>
          <div className={styles.training}>
            <div className={styles.summary}>
              <div className={cx(styles.top, { [styles.open]: displayProgram })}>
                {!!organizationLogoUrl && (
                  <div>
                    <div
                      className={styles.orgLogo}
                      style={{ backgroundImage: `url(${organizationLogoUrl})` }}
                    />
                  </div>
                )}
                <div className={styles.title}>
                  <h2>Formation {name}</h2>
                  {!!training.reviewsGlobalScore && !!reviews.length && (
                    <TrainingScore
                      score={training.reviewsGlobalScore}
                      reviewsCount={reviews.length}
                    />
                  )}
                  <div className={styles.organization}>Par {organization.name}</div>
                </div>
                <div className={styles.containerTrainingInfos}>
                  {training.mode && <div>{modalitiesConstants.mapping[training.mode]}</div>}
                  {trainingDuration && <div>{trainingDuration}</div>}
                </div>
                {training.program && (
                  <div
                    className={styles.iconMore}
                    onClick={() => setState({ displayProgram: !displayProgram })}
                  >
                    {displayProgram ? "Fermer le programme" : "Afficher le programme"}
                  </div>
                )}
              </div>
              {displayProgram && (
                <section className={styles.containerContent}>
                  {mappedTrainingContent.map(
                    (item) =>
                      item.content && (
                        <Fragment key={item.title}>
                          <h3 key={item.title}>{item.title}</h3>
                          <blockquote
                            key={`content-${item.title}`}
                            {...injectSafeHtml(item.content)}
                          />
                        </Fragment>
                      )
                  )}
                  <div
                    className={styles.iconMore}
                    onClick={() => setState({ displayProgram: false })}
                  >
                    Fermer le programme
                  </div>
                </section>
              )}
            </div>
          </div>
          <form onSubmit={submit}>
            {hasSessions && (
              <div className={styles.forms}>
                <div className={styles.session}>
                  <Fragment>
                    <div className={styles.dropdown} id="cities">
                      <Label className={styles.Label} label="Lieu" />
                      <DSSelect
                        value={currentSessionPlace}
                        className={styles.DSSelect}
                        options={places}
                        placeholder="Sélectionnez un lieu"
                        onChange={(value) => onSetPlace(value)}
                      />
                    </div>
                    <div className={styles.dropdown} id="dates">
                      <Label className={styles.Label} label="Date" />
                      <DSSelect
                        defaultValue={currentSession}
                        className={styles.DSSelect}
                        options={possibleDates}
                        placeholder="Sélectionnez une date"
                        onChange={(value) => onSetSession(value)}
                      />
                    </div>
                    {currentSessionDatesPanel}
                  </Fragment>
                </div>
                <div className={styles.divider} />
                <div className={styles.trainee}>
                  <div className={styles.traineeForm}>
                    <TextInput
                      label="Poste / Titre"
                      name="role"
                      small
                      placeholder="Poste ou titre du stagiaire"
                      defaultValue={role}
                      onChange={(_, value) => setRole(value)}
                      required
                      strokeIcon={position}
                      style={{ width: 275 }}
                      type="text"
                    />
                    {organization.traineePhoneRequired && (
                      <TextInput
                        label="Téléphone"
                        name="phone"
                        small
                        placeholder="Téléphone du stagiaire"
                        defaultValue={phone}
                        onChange={(_, value) => setPhone(value)}
                        required
                        strokeIcon={phoneIcon}
                        style={{ width: 275 }}
                        type="tel"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {hasSessions && !state.success && (
              <InteractiveButton
                background={Colors.gradientYellow}
                color="#333"
                size="small"
                label="Valider l'inscription"
                type="submit"
                style={{
                  padding: "0 32px",
                  fontWeight: 400,
                  fontSize: 14,
                }}
                loading={submitting}
              />
            )}
            {!hasSessions && (
              <div className={styles.error}>
                <p>
                  Vous ne pouvez pas vous inscrire à cette formation pour le moment car aucune
                  session ne semble ouverte à l’inscription.
                </p>
                <p>Nous vous recommandons de contacter votre responsable formation.</p>
              </div>
            )}
          </form>
          {!isRf && (
            <div className={styles.containerMoreQuestions}>
              <p>
                Pour toute question sur cette inscription, vous pouvez contacter votre gestionnaire
                de formation.
                {/* <b><a href="mailto:contact@skillup.co?subject=Probleme dans la page de booking">contact@skillup.co</a></b> */}
              </p>
            </div>
          )}
        </div>
      ) : (
        <StepRegistration
          step={step}
          trainee={trainee}
          currentSession={
            sessions && {
              ...sessions.find((session) => session.uuid === currentSession),
              place: currentSessionPlace,
            }
          }
        />
      )}
      {!!companyLogoUrl && (
        <div className={styles.companyLogo} style={{ backgroundImage: `url(${companyLogoUrl})` }} />
      )}
    </main>
  );
};

export default TrainingInscription;
