import { useMemo } from "react";

import { JobList } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import { JobRow } from "./utils/parseJobIntoRow";
import generateColumns from "./utils/generateColumns";
import { DatatableJobFields } from "./utils/getFields";
import generateTableRows from "./utils/generateTableRows";

type Props = {
  t: TranslationType;
  jobs?: Array<JobList>;
  actions: { archiveRow: (row: JobRow) => void; setRowPrivacy: (row: JobRow) => void };
};

export default function useJobsTableData({ actions, jobs = [], t }: Props) {
  const tableData = useMemo(
    () => ({
      columns: generateColumns({
        actions,
        customFields: jobs[0]?.fields || [],
        fields: DatatableJobFields,
        t,
      }),
      rows: generateTableRows(jobs),
    }),
    [jobs, t, actions]
  );

  return tableData;
}
