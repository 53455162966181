import React, { useMemo } from "react";
import { upperFirst } from "lodash";

import { Status, StatusType } from "@skillup/ui";
import useTranslation from "hooks/useTranslation";

interface Props {
  readonly className?: string;
  readonly state:
    | "pending_for_manager"
    | "to_arbitrate"
    | "pending_for_additionnal_validation"
    | "pending_for_HR"
    | "denied_or_cancelled"
    | "approved"
    | "waiting_for_subscription"
    | "subscribed"
    | "realized"
    | "denied"
    | "cancelled"
    | "isSpecificTrainingRequest";
}

const AggregationsState = ({ state }: Props) => {
  const { t } = useTranslation();

  const { label, type } = useMemo(() => {
    const data = {
      pending_for_manager: {
        label: t("training.request.state.pending_for_manager", {
          defaultValue: "en attente de validation manager",
        }),
        type: StatusType.WARNING,
      },
      to_arbitrate: {
        label: t("training.request.state.to_arbitrate", {
          defaultValue: "à arbitrer",
        }),
        type: StatusType.WARNING,
      },
      pending_for_additionnal_validation: {
        label: t("training.request.state.pending_for_additionnal_validation", {
          defaultValue: "en attente de validation complémentaire",
        }),
        type: StatusType.WARNING,
      },
      pending_for_HR: {
        label: t("training.request.state.pending_for_HR", {
          defaultValue: "en attente de validation RH",
        }),
        type: StatusType.WARNING,
      },
      denied_or_cancelled: {
        label: t("training.request.state.denied_or_cancelled", {
          defaultValue: "refusée ou annulée",
        }),
        type: StatusType.ERROR,
      },
      approved: {
        label: t("training.request.state.approved", {
          defaultValue: "validée",
        }),
        type: StatusType.SUCCESS,
      },
      waiting_for_subscription: {
        label: t("training.request.state.waiting_for_subscription", {
          defaultValue: "en attente d'inscription",
        }),
        type: StatusType.PROGRESS,
      },
      subscribed: {
        label: t("training.request.state.subscribed", {
          defaultValue: "inscrit(e)",
        }),
        type: StatusType.PROGRESS,
      },
      realized: {
        label: t("training.request.state.realized", {
          defaultValue: "réalisée",
        }),
        type: StatusType.SUCCESS,
      },
      denied: {
        label: t("training.request.state.denied", {
          defaultValue: "refusée",
        }),
        type: StatusType.ERROR,
      },
      cancelled: {
        label: t("training.request.state.cancelled", {
          defaultValue: "annulée",
        }),
        type: StatusType.ERROR,
      },
      isSpecificTrainingRequest: {
        label: t("training.request.state.isSpecificTrainingRequest", {
          defaultValue: "Remontée libre",
        }),
        type: StatusType.PROGRESS,
      },
    };

    return data[state] ? data[state] : { label: state, type: StatusType.ERROR };
  }, [state, t]);

  return <Status label={upperFirst(label)} type={type} />;
};

export default AggregationsState;
