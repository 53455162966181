import {
  DSButton,
  DSFormGroupTextInput,
  MaterialIcons,
  DSDropdown,
  DSDropdownItem,
} from "@skillup/ui";

import { Duration } from "@skillup/shared-utils";

import SidePanel from "components/SidePanel";
import styles from "./HabilitationSidePanel.module.scss";

import { useHabilitationByUuid } from "../../state/habilitations";
import { useMemo } from "react";
import { useEffect } from "react";
import { plural } from "utils/locale";
import { Habilitation } from "../../types";

type HabilitationAction = {
  label?: string;
  disabled?: boolean;
  tooltip?: string;
};
interface Props {
  readonly habilitationUuid: string;
  readonly onDeleteHabilitation: () => void;
  readonly onModifyHabilitation: () => void;
  readonly onClose: () => void;
  readonly openActionModal: (habilitation: Habilitation, action: HabilitationAction) => void;
  readonly getLabelFromAction: (action: HabilitationAction) => string;
}

export default ({
  habilitationUuid,
  onDeleteHabilitation,
  onModifyHabilitation,
  onClose,
  openActionModal,
  getLabelFromAction,
}: Props) => {
  const habilitation = useHabilitationByUuid(habilitationUuid);
  const habilitationNotFound = useMemo(() => habilitation.isLeft(), [habilitation]);

  useEffect(() => {
    if (habilitationNotFound) {
      onClose();
    }
  }, [habilitationNotFound, onClose]);

  const data = useMemo(
    () => (habilitation.isRight() ? habilitation.right() : null),
    [habilitation]
  );

  const trackingsCountText = useMemo(() => {
    return data ? generateTrackingsCountText(data.trackingsCount) : "";
  }, [data]);

  const canAssociateCollabs = useMemo(() => {
    if (data.actions.find((a) => a.type === "addTrackingsInBulk")) return true;
    return false;
  }, [data]);

  return (
    <div>
      {data && (
        <SidePanel
          className={styles.habilitationSidePanel}
          title={data.name}
          subTitle={data.category.name}
          headerDetails={trackingsCountText}
          headerBottomTools={
            <div className={styles.headerBottomTools}>
              {canAssociateCollabs === true && (
                <DSButton
                  label="Associer des collaborateurs"
                  emphasis={"Mid"}
                  buttonSize={"S"}
                  fontWeight="normal"
                  darkMode
                  onClick={() =>
                    openActionModal(data, { type: "addTrackingsInBulk" } as HabilitationAction)
                  }
                />
              )}
              <div className={styles.headerDropDown}>
                <DSDropdown
                  button={
                    <DSButton
                      label="dropdown"
                      darkMode
                      icon={<MaterialIcons.MoreVert />}
                      iconOnly
                    />
                  }
                >
                  {data.actions.length > 0 &&
                    data.actions.map((action) => (
                      <DSDropdownItem
                        label={getLabelFromAction(action)}
                        disabled={action.disabled}
                        onClick={() => {
                          openActionModal(data, action);
                        }}
                        tooltipLabel={action.tooltip}
                        tooltipDirection="top"
                      />
                    ))}
                </DSDropdown>
              </div>
            </div>
          }
          onClose={onClose}
        >
          <div className={styles.habilitationContainer}>
            <div className={styles.habilitationContent}>
              <section className={styles.habilitationContentSection}>
                <DSFormGroupTextInput label="Catégorie">
                  <p className={styles.habilitationContentValue}>{data.category.name}</p>
                </DSFormGroupTextInput>
              </section>
              {data.validity === "limited" && (
                <section>
                  <div className={styles.habilitationContentSection}>
                    <DSFormGroupTextInput label="Durée de validité">
                      <p className={styles.habilitationContentValue}>
                        {Duration.fromIsoToHumain(data.duration)}
                      </p>
                    </DSFormGroupTextInput>
                  </div>
                  <div className={styles.habilitationContentSection}>
                    <DSFormGroupTextInput label="Anticipation de l'échéance">
                      <p className={styles.habilitationContentValue}>
                        {Duration.fromIsoToHumain(data.deadlineAnticipation)}
                      </p>
                    </DSFormGroupTextInput>
                  </div>
                </section>
              )}
            </div>

            <div className={styles.buttonsWrapper}>
              <DSButton
                emphasis="Low"
                buttonSize="S"
                fontWeight="normal"
                disabled={data.trackingsCount !== 0}
                tooltip={
                  data.trackingsCount !== 0
                    ? `Cette habilitation est associée à ${data.trackingsCount} collaborateurs et ne peut donc pas être supprimée.`
                    : undefined
                }
                label="Supprimer"
                onClick={onDeleteHabilitation}
              />
              <DSButton
                emphasis="Mid"
                buttonSize="S"
                fontWeight="normal"
                label="Modifier l'habilitation"
                onClick={onModifyHabilitation}
              />
            </div>
          </div>
        </SidePanel>
      )}
    </div>
  );
};

function generateTrackingsCountText(trackingsCount: number): string {
  return plural(trackingsCount, "%n collaborateur%s associé%s");
}
