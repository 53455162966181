import { Status, StatusType } from "@skillup/ui";

import type { OrganizationRoutesType } from "types/api";

import useAuthStrategy from "../userAuthStrategy";
import { AuthStrategyType, User } from "../types";

const UserConnectionMode = ({
  user,
  connectionSettings,
}: {
  user: User;
  connectionSettings: OrganizationRoutesType.AuthSettings["response"];
}) => {
  const getAuthStrategy = useAuthStrategy(connectionSettings);

  if (user.properties.disabled) return <Status label="Désactivé" type={StatusType.ERROR} />;
  if (!user.login) return <Status label="Sans accès" type={StatusType.WARNING} />;

  const authStrategy = getAuthStrategy(user);

  switch (authStrategy) {
    case AuthStrategyType.OTL:
      return <Status label="OTL" type={StatusType.SUCCESS} />;
    case AuthStrategyType.PASSWORD:
      return <Status label="Mot de passe" type={StatusType.DONE} />;
    case AuthStrategyType.SAML:
      return <Status label="SSO" type={StatusType.PROGRESS} />;
  }
};

export default UserConnectionMode;
