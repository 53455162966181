import { memo, useCallback, useMemo } from "react";
import cx from "classnames";

import { DSButton, DSTooltip, Flex, MaterialIcons } from "@skillup/ui";

import { ItemActions } from "../ItemActions";

import styles from "../../TemplateBuilderStructure.module.scss";

export const StructureSection = memo(function StructureSection({
  id,
  title,
  dragListeners,
  displayMenuOnHover = true,
  highlight = false,
  onClick,
  onFocus,
  showPages,
  toggleShowPages,
  onDuplication,
  onDeletion,
  errors,
  showActionsButtons = false,
  isImmutableSection = false,
}: {
  id?: string;
  title: string;
  dragListeners?: any;
  displayMenuOnHover?: boolean;
  highlight?: boolean;
  onClick?: () => void;
  onFocus?: () => void;
  showPages?: boolean;
  toggleShowPages?: () => void;
  onDuplication?: () => void;
  onDeletion?: () => void;
  errors?: string[];
  showActionsButtons?: boolean;
  isImmutableSection?: boolean;
}) {
  const handleToggleShowPages = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      toggleShowPages && toggleShowPages();
    },
    [toggleShowPages]
  );
  const emptyTitle = useMemo(() => title.length === 0, [title]);
  return (
    <Flex
      row
      className={cx(
        highlight ? styles.highlight : "",
        styles.section + (displayMenuOnHover ? " " + styles["displayMenuOnHover"] : ""),
        errors?.length ? styles["error"] : ""
      )}
      id={id}
      tabIndex={0}
      onClick={onClick}
      onFocus={onFocus}
    >
      {isImmutableSection ? (
        <div
          className={cx(styles["no-drag-indicator"], errors?.length ? "" : styles["no-errors"])}
        />
      ) : (
        <DSButton
          iconOnly
          buttonSize="S"
          icon={<MaterialIcons.DragIndicator />}
          className={cx(styles["drag-indicator"], errors?.length ? "" : styles["no-errors"])}
          {...dragListeners}
        />
      )}
      <DSButton
        iconOnly
        buttonSize="M"
        icon={
          isImmutableSection ? (
            <MaterialIcons.Remove />
          ) : showPages ? (
            <MaterialIcons.ExpandMore />
          ) : (
            <MaterialIcons.ChevronRight />
          )
        }
        className={styles["arrow"]}
        onClick={handleToggleShowPages}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
      />
      {errors?.length > 0 && (
        <DSTooltip label={errors[0]}>
          <MaterialIcons.Error
            className={cx(styles["error-icon"], styles["error-icon-structural-item"])}
          />
        </DSTooltip>
      )}
      <div className={cx(styles["ellipsisBlock"], emptyTitle ? styles["emptySectionTitle"] : "")}>
        {emptyTitle ? "Sans libellé" : title}{" "}
      </div>
      <Flex grow />
      <ItemActions
        deletionTooltipLabel="Supprimer la section et son contenu"
        duplicationTooltipLabel="Dupliquer la section et son contenu"
        showActionsButtons={showActionsButtons}
        onDuplication={onDuplication}
        onDeletion={onDeletion}
      />
    </Flex>
  );
});
