import { TemplateRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type ArchiveTemplateParams = TemplateRoutes.ArchiveTemplateRoute["params"];

export async function archiveTemplate({ templateUuid }: ArchiveTemplateParams) {
  await buildRequest<TemplateRoutes.ArchiveTemplateRoute>({
    method: "POST",
    params: { templateUuid },
    path: `/templates/{templateUuid}/archive`,
    target: "PEOPLE_REVIEW",
  })();
}
