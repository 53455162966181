import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import { Label as DSLabel, DSButton, Flex, DSCheckbox, DSTooltip, DSSortable } from "@skillup/ui";

import { selectHighlightUuid } from "../../../../../reducer";
import { Divided } from "../../ChildWrappers";
import { Permissions } from "../../../Permissions/Permissions";
import { QuestionChild } from "../Question";
import { BuilderFormInput } from "../../../BuilderFormInput";

import styles from "./MultipleChoicesQuestion.module.scss";

type MultipleChoicesQuestionChild =
  | Extract<QuestionChild, { type: "checkbox" }>
  | Extract<QuestionChild, { type: "radio" }>;

type MultipleChoicesQuestionProps = {
  child: MultipleChoicesQuestionChild;
  updateStructure: (data: QuestionChild) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
};

type ChoiceWithId = {
  id: string;
  label: string;
};

export const MultipleChoicesQuestion = ({
  child,
  updateStructure,
  indexes,
}: MultipleChoicesQuestionProps) => {
  const defaultChoicesWithId = useMemo(() => {
    return child.choices.map((choice) => ({
      id: uuid(),
      label: choice.label,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [choicesWithId, setChoicesWithId] = useState<ChoiceWithId[]>(defaultChoicesWithId);

  useEffect(() => {
    updateStructure({
      ...child,
      choices: choicesWithId.map((choice) => ({
        label: choice.label,
      })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choicesWithId]);

  const highlightUuid = useSelector(selectHighlightUuid);
  const changeLabel = useCallback(
    (value: string) => {
      updateStructure({ ...child, label: value });
    },
    [child, updateStructure]
  );

  const changeDescription = useCallback(
    (value: string) => {
      updateStructure({ ...child, description: value });
    },
    [child, updateStructure]
  );

  const removeDescription = useCallback(() => {
    updateStructure({ ...child, description: undefined });
  }, [child, updateStructure]);

  const handleAddChoice = () => {
    setChoicesWithId((prev) => {
      return [
        ...prev,
        {
          id: uuid(),
          label: "",
        },
      ];
    });
  };

  const handleChangeChoices = useCallback(
    (value: string, index: number) => {
      const newChoices = [...child.choices];
      newChoices[index] = {
        ...newChoices[index],
        label: value,
      };
      updateStructure({ ...child, choices: newChoices });
    },
    [child, updateStructure]
  );

  const handleRemoveChoice = (id) => {
    setChoicesWithId((prev) => {
      return prev.filter((choice) => choice.id !== id);
    });
  };

  const handleReorderChoices = (
    items: {
      id: string;
      title: React.ReactNode;
    }[],
    startIndex: number,
    endIndex: number
  ) => {
    setChoicesWithId((prev) => {
      const reorderedChoices = [...prev];
      const [replaced] = reorderedChoices.splice(startIndex, 1);
      reorderedChoices.splice(endIndex, 0, replaced);
      return reorderedChoices;
    });
  };

  const changeRequired = useCallback(
    (value: boolean) => {
      updateStructure({ ...child, required: value });
    },
    [child, updateStructure]
  );

  const choiceItems = useMemo(() => {
    return choicesWithId?.map((choice, index) => ({
      id: choice.id,
      title: (
        <BuilderFormInput
          value={choice.label}
          onChange={(newChoice) => handleChangeChoices(newChoice, index)}
          debounceValue={300}
          name={`child-${child.uuid}-choice-${index}-label`}
          placeholder={`Libellé du choix ${index + 1}`}
          inputClassName={styles.choiceInput}
          disabled // [CSB] changes disabled
        />
      ),
      disabled: true, // [CSB] changes disabled
      remove: {
        // [CSB] changes disabled
        disabled: true,
      },
    }));
  }, [choicesWithId, child.uuid, handleChangeChoices]);

  return (
    <Divided>
      <div className={styles.container}>
        <div>
          <BuilderFormInput
            type="textarea"
            value={child.label}
            onChange={changeLabel}
            debounceValue={300}
            label="Libellé de la question"
            name={`question-child-${child.uuid}-label`}
            required
            placeholder="Saisissez le libellé de la question"
            autoFocus={highlightUuid === child.uuid}
          />
        </div>
        <div className={styles.descriptionHelp}>
          {child.description !== undefined ? (
            <div>
              <div className={styles.labelAndButton}>
                <DSLabel label="Description de la question" />
                <DSButton
                  label={"Supprimer la description"}
                  className={styles.buttonUnderline}
                  onClick={removeDescription}
                  buttonSize="S"
                  emphasis="Low"
                />
              </div>
              <BuilderFormInput
                type="textarea"
                value={child.description}
                onChange={changeDescription}
                debounceValue={300}
                name={`question-child-${child.uuid}-description`}
                placeholder="Saisissez la description de la question"
              />
            </div>
          ) : (
            <DSButton
              label={"Ajouter une description"}
              className={styles.buttonUnderline}
              onClick={() => changeDescription("")}
              buttonSize="S"
              emphasis="Low"
            />
          )}
        </div>
        <div>
          <DSLabel label="Choix possibles" required />
          <Flex className={styles.choiceRows}>
            <DSTooltip
              label={
                "Cet élément ne peut pas être modifié sur une campagne en cours."
              } /* [CSB] permission changes disabled */
              className={styles.choiceTooltip}
              direction="top"
            >
              <DSSortable
                handle
                removable //[CSB] changes disabled
                removeAlwaysVisible
                onRemove={handleRemoveChoice}
                items={choiceItems}
                // @ts-expect-error the types in the DS are wrong, they should be generic
                reorderItems={handleReorderChoices}
                locked={true} // [CSB] changes disabled
              />
            </DSTooltip>
          </Flex>
          <DSButton
            label={"Ajouter un choix possible"}
            onClick={() => handleAddChoice()}
            buttonSize="S"
            emphasis="Mid"
            disabled // [CSB] permission changes disabled
            tooltip="Cet élément ne peut pas être modifié sur une campagne en cours." // [CSB] permission changes disabled
          />
        </div>
      </div>
      <div>
        <Flex className={styles.permissions__action}>
          <DSCheckbox
            name={`${child.uuid}-required`}
            className={styles.permissions__actionChoice}
            label="Réponse requise"
            checked={child.required}
            onChange={changeRequired}
          />
        </Flex>
        <Permissions
          key={child.uuid}
          indexes={indexes}
          kind={child.kind}
          uuid={child.uuid}
          restrictions={child.disallow}
          order={["reply", "comment"]}
          mandatory={
            child.required
              ? {
                  reply:
                    "La réponse à cette question étant requise, vous devez sélectionner au moins un participant qui devra répondre.",
                }
              : undefined
          }
        />
      </div>
    </Divided>
  );
};
