import { DSModal, Select, DSFormGroupTextInput, TextInput } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";
import { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

import User from "utils/User";
import { useRouteQuery } from "hooks";
import { OrganizationRoutesType } from "types/api";
import { useTemplate } from "services/interviews";

import styles from "./TransferCopyTemplateModal.module.scss";

type Props = {
  templateUuid: string;
  templateName: string;
  onClose: () => void;
};

type ModalState =
  | { step: "idle" }
  | { step: "transfering" }
  | { step: "success" }
  | { step: "error"; error: string };

export function TransferCopyTemplateModal({ templateName, templateUuid, onClose }: Props) {
  const { transferCopy } = useTemplate(templateUuid);
  const companyName = User.getUserData().activeCompany?.name;

  const [modalState, setModalState] = useState<ModalState>({ step: "idle" });
  useStateEffects({ modalState, onClose });

  const { data: companiesData, isFetched } =
    useRouteQuery<OrganizationRoutesType.GetSimpleOrganisations>(["organizations"], {
      path: "/organization/list",
      method: "GET",
    });

  const [selectedOrganization, setSelectedOrganization] = useState<{ uuid: string; name: string }>(
    undefined
  );

  const [newTitle, setNewTitle] = useState<string>();

  const handleTransfer = useCallback(async () => {
    if (!selectedOrganization) return;
    if (modalState.step === "transfering") return;

    setModalState({ step: "transfering" });

    try {
      await transferCopy({
        toCompanyUuid: selectedOrganization.uuid,
        newTitle,
      });
      setModalState({ step: "success" });
    } catch (err) {
      setModalState({
        error: "Echec lors du transfert de la trame",
        step: "error",
      });
    }
  }, [selectedOrganization, transferCopy, modalState.step, newTitle]);

  return (
    <DSModal isOpen className={styles.transferModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={`Transférer une copie de la trame « ${templateName} » vers une autre entreprise`}
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex flexDirection="column">
          {isFetched && (
            <>
              <DSFormGroupTextInput label="Titre de la trame transférée" required>
                <TextInput defaultValue={templateName} onChange={setNewTitle} />
              </DSFormGroupTextInput>
              <DSFormGroupTextInput
                label="Entreprise vers laquelle transférer la copie de la trame"
                required
              >
                <Select
                  options={companiesData.map(({ organization: org }) => ({
                    value: org,
                    label: org.name,
                  }))}
                  onChange={(value) => setSelectedOrganization(value)}
                  placeholder="Choisir une entreprise..."
                />
              </DSFormGroupTextInput>
            </>
          )}
          <Text espaceFont="captionRegular">
            La trame « {templateName} » restera accessible pour l’entreprise initiale ({companyName}
            ) et une copie sera transférée vers l’autre entreprise
            {selectedOrganization ? ` (${selectedOrganization.name})` : ""}.
          </Text>
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton label="Annuler" onClick={onClose} />
        <DSModal.Footer.PrimaryButton
          label="Transférer"
          disabled={modalState.step === "transfering" || !selectedOrganization}
          loading={modalState.step === "transfering"}
          onClick={handleTransfer}
        />
      </DSModal.Footer>
    </DSModal>
  );
}

function useStateEffects({ modalState, onClose }: { modalState: ModalState; onClose: () => void }) {
  const { addToast } = useToasts();

  useEffect(() => {
    switch (modalState.step) {
      case "error":
        addToast(modalState.error, {
          appearance: "error",
        });
        break;
      case "success":
        addToast("Trame transférée avec succès", {
          appearance: "success",
        });
        onClose();
        break;
      case "idle":
      case "transfering":
      default:
        return;
    }
  }, [modalState, onClose, addToast]);
}
