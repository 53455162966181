import { InterviewActiveAttendeeRoles } from "../../../../types";

import { GetDashboardOpenQuestionRoute } from "./openQuestion";
import { GetDashboardClosedQuestionRoute } from "./closedQuestion";
import { GetDashboardTableRoute } from "./table";
import { GetDashboardCurrentTargetsRoute } from "./currentTargets";
import { GetDashboardFutureTargetsRoute } from "./futureTargets";
import { GetDashboardGlobalTargetsCompletionRoute } from "./globalTargetsCompletion";
import { GetDashboardTrainingsHistoryRoute } from "./trainingsHistory";
import { GetDashboardInterviewsHistoryRoute } from "./interviewsHistory";
import { GetDashboardTrainingsCollectionRoute } from "./trainingsCollection";
import { GetDashboardSkillsRoute } from "./skills";
import { GetDashboardUploadRoute } from "./upload";

export enum DashboardChildType {
  OPEN_QUESTION = "open-question",
  CLOSED_QUESTION = "closed-question",
  TABLE = "table",
  CURRENT_TARGETS = "current-targets",
  FUTURE_TARGETS = "future-targets",
  GLOBAL_TARGETS_COMPLETION = "global-targets-completion",
  TRAININGS_HISTORY = "trainings-history",
  INTERVIEWS_HISTORY = "interviews-history",
  TRAININGS_COLLECTION = "trainings-collection",
  SKILLS = "skills",
  UPLOAD = "upload",
}

export type DashboardChildParams = {
  uuid: string;
  companyUuid: string;
  role?: InterviewActiveAttendeeRoles;
  userAreas?: string[];
};

export type GetDashboardChildRoutes = {
  [DashboardChildType.OPEN_QUESTION]: GetDashboardOpenQuestionRoute;
  [DashboardChildType.CLOSED_QUESTION]: GetDashboardClosedQuestionRoute;
  [DashboardChildType.TABLE]: GetDashboardTableRoute;
  [DashboardChildType.CURRENT_TARGETS]: GetDashboardCurrentTargetsRoute;
  [DashboardChildType.FUTURE_TARGETS]: GetDashboardFutureTargetsRoute;
  [DashboardChildType.GLOBAL_TARGETS_COMPLETION]: GetDashboardGlobalTargetsCompletionRoute;
  [DashboardChildType.TRAININGS_HISTORY]: GetDashboardTrainingsHistoryRoute;
  [DashboardChildType.INTERVIEWS_HISTORY]: GetDashboardInterviewsHistoryRoute;
  [DashboardChildType.TRAININGS_COLLECTION]: GetDashboardTrainingsCollectionRoute;
  [DashboardChildType.SKILLS]: GetDashboardSkillsRoute;
  [DashboardChildType.UPLOAD]: GetDashboardUploadRoute;
};

export { PlaceholderResponse } from "./types";

export * from "./openQuestion";
export * from "./closedQuestion";
export * from "./table";
export * from "./currentTargets";
export * from "./futureTargets";
export * from "./globalTargetsCompletion";
export * from "./trainingsHistory";
export * from "./interviewsHistory";
export * from "./trainingsCollection";
export * from "./skills";
export * from "./upload";
