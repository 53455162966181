import { useQuery } from "@tanstack/react-query";
import { TargetsRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

export interface QueryAction {
  type: "sort" | "pagination" | "filter";
  sort?: string;
  order?: "ASC" | "DESC";
  page?: number;
  pageSize?: number;
  filter?: {
    isEvaluated: boolean;
    name?: string;
    targetCategoryLabel?: string;
    campaigns?: string[];
    "evaluationCampaign.titleForHR"?: string;
    "creationCampaign.titleForHR"?: string;
    manager?: string[];
  };
}

export interface QueryOptions {
  sort?: string;
  order?: "ASC" | "DESC";
  limit?: number;
  offset?: number;
  filter?: string;
}

async function getUserTargets({
  userUuid,
  queryOptions,
}: {
  userUuid: string;
  queryOptions: QueryOptions;
}) {
  try {
    const result = await buildRequest<TargetsRoutes.GetUserTargets>({
      method: "GET",
      path: "/user/{userUuid}/targets",
      params: { userUuid },
      query: queryOptions,
    })();
    return result;
  } catch (err) {
    return { targets: [], totalRows: 0, globalFilters: { campaigns: [], evaluators: [] } };
  }
}

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};
const defaultOptions: Options = {
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export function useTargets({
  userUuid,
  queryOptions,
}: {
  userUuid: string;
  queryOptions: QueryOptions;
}) {
  const { data: targetsWithTotal, isLoading } = useQuery(
    ["targets", userUuid, queryOptions],
    async () => getUserTargets({ userUuid, queryOptions }),
    {
      ...defaultOptions,
      keepPreviousData: true,
    }
  );
  return {
    targetsList: targetsWithTotal?.targets ?? [],
    targetsTotal: targetsWithTotal?.totalRows ?? 0,
    campaigns: targetsWithTotal?.globalFilters.campaigns ?? [],
    evaluators: targetsWithTotal?.globalFilters.evaluators ?? [],
    isLoading,
  };
}
