import React, { useState } from "react";
import { useSetState } from "react-use";
import { useHistory } from "react-router-dom";
import type { InterviewUser } from "@skillup/types";
import { isUndefined } from "lodash";

import { QuestionsStep, FinalStep } from "./components/Steps";
import { createNewCampaign as createNewCampaignRequest } from "./requests/CreateCampain";
import { Step } from "./models/Step";

import CreateFormInterviewWrapper from "./CreateInterviewFormWrapper";
import useTranslation from "hooks/useTranslation";
import OptionsStep from "./components/Steps/OptionsStep";

interface IState {
  titleForHR: string;
  titleForAttendees: string;
  template?: { title: string; uuid: string };
  hideFromEmployeeUntil?: { managerState: InterviewUser["state"] };
  managerOnly?: boolean;
  confidential: boolean;
}

const CreateNewAdHocInterviews = (): JSX.Element => {
  const history = useHistory();
  const { t } = useTranslation();

  const [state, setState] = useSetState<IState>({
    titleForHR: "",
    titleForAttendees: "",
    confidential: false,
  });

  const {
    titleForHR,
    titleForAttendees,
    template,
    hideFromEmployeeUntil,
    managerOnly,
    confidential,
  } = state;

  const [canCreateCampaign, setCanCreateCampaign] = useState(false);

  const steps: Step[] = [
    {
      label: "Trame et nom",
      id: "modelAndName",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <QuestionsStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          setQuestionsTemplate={(template) => setState({ template })}
          setCampaignTitle={(campaignName, scope: "HR" | "attendees") => {
            if (scope === "HR") {
              setState({ titleForHR: campaignName });
            } else if (scope === "attendees") {
              setState({ titleForAttendees: campaignName });
            }
          }}
        />
      ),
    },
    {
      label: "Workflow et confidentialité",
      id: "workflowAndConfidentiality",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <OptionsStep
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          setHideFromEmployeeUntil={(hideFromEmployeeUntil) => setState({ hideFromEmployeeUntil })}
          managerOnly={managerOnly}
          setManagerOnly={(managerOnly) => setState({ managerOnly })}
          confidential={confidential}
          setConfidentiality={(confidential) => setState({ confidential })}
        />
      ),
    },
    {
      label: "Synthèse",
      id: "summary",
      disabled:
        !(titleForHR.length > 0) || !(titleForAttendees.length > 0) || isUndefined(template),
      component: (
        <FinalStep
          titleForHR={titleForHR}
          titleForAttendees={titleForAttendees}
          template={template}
          type="adHoc"
          setCanCreateCampaign={setCanCreateCampaign}
          managerOnly={managerOnly}
          hideFromEmployeeUntil={hideFromEmployeeUntil}
          confidential={confidential}
        />
      ),
    },
  ];

  const createNewCampaign = async (toggleLoading: (nextValue?: boolean) => void) => {
    const campaignUuid = await createNewCampaignRequest({
      interviews: [],
      toggleLoading,
      t,
      confidential,
      data: {
        titleForHR: titleForHR,
        titleForAttendees: titleForAttendees,
        template: template.uuid,
        managerOnly,
        type: "adHoc",
        hideFromEmployeeUntil,
      },
    });

    if (campaignUuid) history.push(`/responsable/campagne/${campaignUuid}`);
  };
  return (
    <CreateFormInterviewWrapper
      steps={steps}
      title="Entretiens ponctuels"
      sendButtonLabel="Créer les entretiens"
      createNewCampaign={createNewCampaign}
      canCreateCampaign={canCreateCampaign}
    />
  );
};

export default CreateNewAdHocInterviews;
