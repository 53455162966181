export type {
  DatasetKey,
  DatasetsRoutes,
  EmployeesRoutes,
  FieldKey,
  FieldsRoutes,
  HistoricalRecordType,
  ScalesRoutes,
  TimelineRoutes,
  TimelineType,
  PaginatedResult,
  KeyofEmployee,
} from "@skillup/core-hr";

import type { DatasetKey } from "@skillup/core-hr";
import { ListUtils } from "@skillup/shared-utils";

export const datasetKeys: Array<DatasetKey> = ["job", "review"];

export const EmployeeFilters: ListUtils.FilterConfigurationMap = {
  branch: { type: ListUtils.FilterType.TEXT },
  customField0: { type: ListUtils.FilterType.TEXT },
  customField1: { type: ListUtils.FilterType.TEXT },
  customField2: { type: ListUtils.FilterType.TEXT },
  customField3: { type: ListUtils.FilterType.TEXT },
  customField4: { type: ListUtils.FilterType.TEXT },
  customField5: { type: ListUtils.FilterType.TEXT },
  customField6: { type: ListUtils.FilterType.TEXT },
  customField7: { type: ListUtils.FilterType.TEXT },
  customField8: { type: ListUtils.FilterType.TEXT },
  customField9: { type: ListUtils.FilterType.TEXT },
  division: { type: ListUtils.FilterType.TEXT },
  email: {
    type: ListUtils.FilterType.TEXT,
  },
  firstName: { type: ListUtils.FilterType.TEXT },
  fullName: {
    type: ListUtils.FilterType.TEXT,
  },
  lastName: { type: ListUtils.FilterType.TEXT },
  perimeters: { type: ListUtils.FilterType.MULTISELECT },
  registrationNumber: { type: ListUtils.FilterType.TEXT },
  role: { type: ListUtils.FilterType.TEXT },
  service: { type: ListUtils.FilterType.TEXT },
  site: { type: ListUtils.FilterType.TEXT },
  validators: { type: ListUtils.FilterType.MULTISELECT },
};

export const EmployeeFields = {
  email: {
    fieldType: "TEXT",
    traductionKey: "employee.field.email",
    traductionDefaultValue: "Email",
    key: "email",
  },
  registrationNumber: {
    fieldType: "TEXT",
    traductionKey: "employee.field.registrationNumber",
    traductionDefaultValue: "Matricule",
    key: "registrationNumber",
  },
  role: {
    fieldType: "TEXT",
    traductionKey: "employee.field.role",
    traductionDefaultValue: "Rôle",
    key: "role",
  },
  firstName: {
    fieldType: "TEXT",
    traductionKey: "employee.field.firstname",
    traductionDefaultValue: "Prénom",
    key: "firstName",
  },
  lastName: {
    fieldType: "TEXT",
    traductionKey: "employee.field.lastname",
    traductionDefaultValue: "Nom",
    key: "lastName",
  },
  fullName: {
    fieldType: "TEXT",
    traductionKey: "employee.field.fullName",
    traductionDefaultValue: "Nom complet",
    key: "fullName",
  },
  joinDate: {
    fieldType: "DATE",
    traductionKey: "employee.field.joinDate",
    traductionDefaultValue: "Date d'entrée",
    key: "joinDate",
  },
  birthDate: {
    fieldType: "DATE",
    traductionKey: "employee.field.birthDate",
    traductionDefaultValue: "Date de naissance",
    key: "birthDate",
  },
  roleDate: {
    fieldType: "DATE",
    traductionKey: "employee.field.roleDate",
    traductionDefaultValue: "Date de rôle",
    key: "roleDate",
  },
  expectedDepartureDate: {
    fieldType: "DATE",
    traductionKey: "employee.field.expectedDepartureDate",
    traductionDefaultValue: "Date de départ prévue",
    key: "expectedDepartureDate",
  },
  updatedAt: {
    fieldType: "DATE",
    traductionKey: "employee.field.updatedAt",
    traductionDefaultValue: "Mis à jour le",
    key: "updatedAt",
  },
  createdAt: {
    fieldType: "DATE",
    traductionKey: "employee.field.createdAt",
    traductionDefaultValue: "Créé le",
    key: "createdAt",
  },
  areas: {
    fieldType: "TEXT",
    traductionKey: "employee.field.areas",
    traductionDefaultValue: "Périmètres",
    key: "areas",
  },
  branch: {
    fieldType: "TEXT",
    traductionKey: "employee.field.branch",
    traductionDefaultValue: "Branche",
    key: "branch",
  },
  division: {
    fieldType: "TEXT",
    traductionKey: "employee.field.division",
    traductionDefaultValue: "Département",
    key: "division",
  },
  site: {
    fieldType: "TEXT",
    traductionKey: "employee.field.site",
    traductionDefaultValue: "Site",
    key: "site",
  },
  contract: {
    fieldType: "TEXT",
    traductionKey: "employee.field.contract",
    traductionDefaultValue: "Contrat",
    key: "contract",
  },
  service: {
    fieldType: "TEXT",
    traductionKey: "employee.field.service",
    traductionDefaultValue: "Service",
    key: "service",
  },
  company: {
    fieldType: "TEXT",
    traductionKey: "employee.field.company",
    traductionDefaultValue: "Société",
    key: "company",
  },
  isImmune: {
    fieldType: "TEXT",
    traductionKey: "employee.field.isImmune",
    traductionDefaultValue: "Immunisé",
    key: "isImmune",
  },
} as const;

type Validator = {
  uuid: string;
  email: string;
  fullName: string;
  lastName: string;
  firstName: string;
};

export type Employee = {
  uuid: string;
  site?: string;
  role?: string;
  email: string;
  branch?: string;
  areas: string[];
  company?: string;
  service?: string;
  contract?: string;
  division?: string;
  createdAt: string;
  updatedAt: string;
  roleDate?: string;
  joinDate?: string;
  fullName?: string;
  lastName?: string;
  customField9?: any;
  customField8?: any;
  customField7?: any;
  customField6?: any;
  customField5?: any;
  customField4?: any;
  customField3?: any;
  customField2?: any;
  customField1?: any;
  customField0?: any;
  isImmune?: boolean;
  birthDate?: string;
  firstName?: string;
  validators: Validator[];
  registrationNumber: string;
  expectedDepartureDate?: string;
};
