import React from "react";
import CreatableSelect from "react-select/creatable";
import { v4 as uuid } from "uuid";
import { isEmpty } from "lodash";

import { Child } from "../types";

import { reorder } from "../DragnDrop";

import { Button, TextDropdown, Icon, TextArea, TextInput, Toggle } from "@skillup/ui";

import styles from "./Child.module.scss";

// Sub components
import DisallowBlock, { commentRestrictions, replyRestrictions } from "./DisallowBlock";

import { InterviewTypes } from "types/Interview";

type InterviewTable = Child & { kind: "table" };
type TableColumn = InterviewTable["columns"][0];
const ColumnBlock = ({
  data,
  updateColumn,
}: {
  data: TableColumn;
  index: number;
  updateColumn: (columnData: TableColumn) => void;
}) => {
  const options = [
    { label: "Texte", value: "text" },
    { label: "Fixé", value: "fixed" },
    { label: "Choix multiples", value: "dropdown" },
  ];

  const { disallow } = data;
  const toggleRight = (role: InterviewTypes.ActiveRoles, restrictionValue: boolean) => {
    updateColumn({
      ...data,
      disallow: {
        ...(data.disallow ?? {}),
        [role]: { reply: restrictionValue },
      },
    });
  };

  return (
    <div aria-label={`colonne ${data.type} ${data.title}`} className={styles.columnBlock}>
      <label>Nom de la colonne</label>
      <TextInput
        aria-label="Titre colonne"
        key={data.uuid}
        type="text"
        value={data.title}
        onChange={(title) => updateColumn({ ...data, title })}
      />
      <label>Type de la colonne</label>
      <TextDropdown
        aria-label="Type colonne"
        options={options}
        selectedIndex={options.findIndex((option) => option.value === data.type)}
        onSelect={(newOption) => {
          const choices = newOption.value === "dropdown" ? [] : undefined;
          const values = newOption.value === "fixed" ? [] : undefined;

          // @ts-ignore
          updateColumn({ ...data, choices, values, type: newOption.value });
        }}
      />
      <label>Largeur de la colonne (optionnel, défaut 200px)</label>
      <TextInput
        aria-label="Largeur colonne"
        type="number"
        key={`width-${data.uuid}`}
        value={`${data.width}`}
        onChange={(width) => {
          updateColumn({ ...data, width: parseInt(width, 10) });
        }}
      />

      {data.type === "dropdown" && (
        <>
          <label>Choix possibles</label>
          <CreatableSelect
            isMulti
            name="Choix"
            options={(data?.choices ?? []).map((choice) => ({ label: choice, selected: true }))}
            value={(data?.choices ?? []).map((choice) => ({ label: choice, selected: true }))}
            onChange={(choices) => {
              updateColumn({
                ...data,
                choices: choices.map((c) => c.label),
              });
            }}
          />
        </>
      )}
      {data.type === "fixed" && (
        <>
          <label>Valeurs</label>
          <CreatableSelect
            isMulti
            isValidNewOption={(input) => !isEmpty(input)}
            name="Valeurs"
            options={(data?.values ?? []).map((value) => ({ label: value, selected: true }))}
            value={(data?.values ?? []).map((value) => ({ label: value, selected: true }))}
            onChange={(values) => {
              updateColumn({
                ...data,
                values: values.map((v) => v.label),
              });
            }}
          />
        </>
      )}
      <div className={styles.disallow}>
        <h3>Restrictions</h3>
        {replyRestrictions.map((restriction) => (
          <div
            aria-label={`restriction ${restriction.action} ${restriction.role}`}
            key={restriction.label}
            className={styles.disallowAuth}
          >
            <Toggle
              className={styles.toggle}
              active={disallow?.[restriction.role]?.[restriction.action]}
              onToggle={(authorized) => toggleRight(restriction.role, authorized)}
            />
            <p>{restriction.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const TableBlock = ({
  data,
  updateChild,
}: {
  data: InterviewTable;
  updateChild: (newChildData: Child) => void;
}) => {
  const updateColumn = (index: number) => (columnData: TableColumn) => {
    const newColumns = [...data.columns];
    newColumns[index] = columnData;
    updateChild({ ...data, columns: newColumns });
  };

  const deleteColumn = (columnIndex: number) => {
    const newColumns = data.columns
      .slice(0, columnIndex)
      .concat(data.columns.slice(columnIndex + 1));

    updateChild({ ...data, columns: newColumns });
  };

  const reorderColumns = (startIndex: number, newIndex: number) => {
    const newColumns = reorder(data.columns, startIndex, newIndex);
    updateChild({ ...data, columns: newColumns });
  };

  const addColumn = () => {
    updateChild({
      ...data,
      columns: [
        ...data.columns,
        {
          disallow: {
            employee: { reply: true },
            manager: { reply: true },
          },
          title: "Nouvelle colonne",
          type: "text",
          uuid: uuid(),
        },
      ],
    });
  };

  return (
    <form>
      <label>Label</label>
      <TextInput
        aria-label="Label tableau"
        key={`title-${data.uuid}`}
        value={data.label}
        onChange={(label) => updateChild({ ...data, label })}
      />
      <label aria-label="Description tableau">Description</label>
      <TextArea
        key={`description-${data.uuid}`}
        value={data.description}
        onChange={(description) => updateChild({ ...data, description })}
      />
      <label>Nombre de lignes</label>
      <TextInput
        aria-label="Nombre de lignes du tableau"
        type="number"
        key={`rowsCount-${data.uuid}`}
        value={`${data.rowsCount}`}
        onChange={(rowsCount) => updateChild({ ...data, rowsCount: parseInt(rowsCount, 10) })}
      />
      <label>Colonnes</label>
      <div aria-label="Colonnes du tableau" className={styles.columns}>
        {data.columns.map((column, index) => (
          <div key={`${column.type}-${index}}`} className={styles.columnBlockWrapper}>
            <p>Colonne {index + 1}</p>
            <div className={styles.delete} aria-label={`Supprimer colonne ${index}`}>
              <Icon.Trash
                size={20}
                onClick={() => {
                  const confirmation = window.confirm(
                    "Êtes-vous sûr de vouloir supprimer cette colonne ?"
                  );
                  if (confirmation) deleteColumn(index);
                }}
              />
            </div>
            {index !== 0 && (
              <div
                className={styles.moveColumnUp}
                aria-label={`Déplacer vers le haut ${index}`}
                onClick={() => reorderColumns(index, index - 1)}
              >
                <Icon.ChevronLeft size={20} />
              </div>
            )}
            {index !== data.columns.length - 1 && (
              <div
                className={styles.moveColumnDown}
                aria-label={`Déplacer vers le bas ${index}`}
                onClick={() => reorderColumns(index, index + 1)}
              >
                <Icon.ChevronRight size={20} />
              </div>
            )}
            <ColumnBlock data={column} index={index} updateColumn={updateColumn(index)} />
          </div>
        ))}
      </div>
      <Button
        aria-label="Ajouter une colonne"
        label="Ajouter une colonne"
        onClick={addColumn}
        type="button"
        icon={() => {
          return <Icon.Plus className={styles.addIcon} size="1rem" />;
        }}
      />
      <DisallowBlock data={data} updateChild={updateChild} restrictions={commentRestrictions} />
    </form>
  );
};

export default TableBlock;
