import { DSModal } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";

import styles from "./IntraEditionView.module.scss";

type Props = {
  fields: Array<string>;
  onSubmit: () => void;
  onClose: () => void;
};

const ConfirmModal = ({ fields, onSubmit, onClose }: Props) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <DSModal className={styles.DeleteOptionModal} isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.intra.edition.modal.title", {
            defaultValue: "Modification",
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <Flex gap="s" flexDirection="column">
          <p>
            {t("trainings.intra.edition.modal.content", {
              defaultValue:
                "La nouvelle valeur du champ {{ fields }} sera appliquée sur toutes les lignes non convoquées liées à ce programme. La mise à jour des lignes du plan peut prendre quelques minutes",
              fields: fields.map((field) => `"${field}"`).join(", "),
              count: fields.length,
            })}
          </p>
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("trainings.intra.edition.modal.cancel", { defaultValue: "Annuler" })}
          onClick={onClose}
        />
        <DSModal.Footer.PrimaryButton
          label={t("trainings.intra.edition.modal.confirm", {
            defaultValue: "Enregistrer les modifications",
          })}
          onClick={handleSubmit}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default ConfirmModal;
