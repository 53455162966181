import { atom, selector, useSetRecoilState } from "recoil";

import { buildRequest } from "utils/buildRequest";

import type { EmployeesRoutes } from "types/api";

const employeesFetchID = atom<number>({
  default: 1,
  key: "employeesFetchID",
});

type EmployeesListRoute = EmployeesRoutes["Employees.GetList"];
type Employees = EmployeesListRoute["response"]["employees"];

const employeesFetchSelector = selector<Employees>({
  key: "employeesFetchSelector",
  get: async ({ get }) => {
    get(employeesFetchID); // Makes it a dependency: if id changes, the list refetches
    const request = buildRequest<EmployeesRoutes["Employees.GetList"]>({
      method: "GET",
      path: "/competences/employees",
    });

    const data = await request();
    return data.employees;
  },
});

export const employeesAtom = atom<Employees>({
  key: "employeesAtom",
  default: employeesFetchSelector,
});

export const useEmployeesState = () => {
  const setFetchId = useSetRecoilState(employeesFetchID);

  return {
    refreshList: () => {
      setFetchId((currentId) => currentId + 1);
    },
  };
};
