import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

export default async (row: string, fileUUID: string) => {
  try {
    await DataLayer.request({
      method: "DELETE",
      url: `/v1/booking/delete/${row}/${fileUUID}`,
    });
    Acta.dispatchEvent("closeModal");
    Acta.dispatchEvent("sendAppMessage", {
      message: "Fichier supprimé.",
      type: "success",
    });
  } catch (error) {
    Acta.dispatchEvent("closeModal");
    Acta.dispatchEvent("sendAppMessage", {
      message: "Une erreur est survenue lors de la suppression de votre fichier.",
      type: "error",
    });
  }
};
