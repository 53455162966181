import React, { Fragment, useState } from "react";
import cx from "classnames";

import isNil from "lodash/isNil";

import Icon from "components/Icon";
import PlaceFilter from "components/PlaceFilter";

import { check } from "uiAssets/Icons";
import Colors from "uiAssets/Colors";

import { IFilters, ITrainingAggregations } from "../Actions/getTrainings";
import SessionTypeFilter from "./Filters/SessionTypeFilter";
import generateCounts from "./Filters/generateCounts";

import styles from "./SidebarStyles.module.scss";

interface IProps {
  filters: IFilters;
  aggregations: ITrainingAggregations;
  setFilters: (newFilters: IFilters) => void;
  searchInput: () => React.ReactElement;
}

export default ({ filters, aggregations, setFilters }: IProps) => {
  const [seeMoreDates, setSeeMoreDates] = useState<boolean>(false);
  const [seeMoreOrgs, setSeeMoreOrgs] = useState<boolean>(false);
  const counts = generateCounts(aggregations, filters, setFilters);

  if (filters.type !== "inter") return <div className={styles.Sidebar} />;

  return (
    <div className={styles.Sidebar}>
      <div className={styles.filters}>
        {counts.place && (
          <div className={styles.sessionToggle}>
            <h3>Avec sessions</h3>
            <button
              className={cx({ [styles.active]: filters.withSessions })}
              onClick={() => setFilters({ ...filters, withSessions: !filters.withSessions })}
            />
          </div>
        )}
        {counts.isCPF && (
          <>
            <h3>Eligibilité CPF</h3>
            <ul>
              <li>
                <div
                  onClick={() => setFilters({ ...filters, isCPF: !filters.isCPF })}
                  className={styles.checkbox}
                >
                  <div>{filters.isCPF && <Icon icon={check} fill={Colors.blue} width={10} />}</div>
                  Eligibilité CPF
                </div>
                <span>{!isNil(counts.isCPF) ? counts.isCPF : ""}</span>
              </li>
            </ul>
          </>
        )}

        {!!counts.place && (
          <Fragment>
            <PlaceFilter
              defaultValue={filters.place?.label}
              onPlaceSelect={(place) => setFilters({ ...filters, place })}
              onClearValue={() => setFilters({ ...filters, place: undefined })}
            />
            <SessionTypeFilter
              aggregations={aggregations}
              filters={filters}
              onToggleSessionType={(sessionType) => setFilters({ ...filters, sessionType })}
            />
          </Fragment>
        )}
        {counts && counts.sessionMonths && (
          <>
            <h3>Date de session</h3>
            <ul>
              {(seeMoreDates ? counts.sessionMonths : counts.sessionMonths.slice(0, 3)).map(
                (count) => (
                  <li key={count.label}>
                    <div onClick={count.onClick} className={styles.checkbox}>
                      <div>
                        {count.isSelected && <Icon icon={check} fill={Colors.blue} width={10} />}
                      </div>
                      {count.label}
                    </div>
                    <span>{count.count}</span>
                  </li>
                )
              )}
            </ul>
            <button
              className={styles.deploy}
              key="seeMoreDates"
              onClick={() => setSeeMoreDates(!seeMoreDates)}
            >
              {seeMoreDates ? "- Réduire" : "...toutes les dates +"}
            </button>
          </>
        )}
        {counts && counts.organization && (
          <>
            <h3>Organisme</h3>
            <ul>
              {(seeMoreOrgs ? counts.organization : counts.organization.slice(0, 3)).map(
                (count) => (
                  <li key={count.label}>
                    <div onClick={count.onClick} className={styles.checkbox}>
                      <div>
                        {count.isSelected && <Icon icon={check} fill={Colors.blue} width={10} />}
                      </div>
                      {count.label}
                    </div>
                    <span>{count.count}</span>
                  </li>
                )
              )}
            </ul>

            <button
              className={styles.deploy}
              key="seeMoreOrgs"
              onClick={() => setSeeMoreOrgs(!seeMoreOrgs)}
            >
              {seeMoreOrgs ? "- Réduire" : "...tous les organismes +"}
            </button>
          </>
        )}
        {counts && counts.durationGroup && (
          <>
            <h3>Durée</h3>
            <ul>
              {counts.durationGroup.map((count) => (
                <li key={count.label}>
                  <div onClick={count.onClick} className={styles.checkbox}>
                    <div>
                      {count.isSelected && <Icon icon={check} fill={Colors.blue} width={10} />}
                    </div>
                    {count.label}
                  </div>
                  <span>{count.count}</span>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
