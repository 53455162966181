import animateScrollTo from "animated-scroll-to";

interface IOptions {
  maxDuration?: number;
  minDuration?: number;
  speed?: number;
}

const scrollTo = (top: number, elementToScroll: HTMLElement | Window, options?: IOptions): void => {
  animateScrollTo(top, {
    elementToScroll,
    maxDuration: 500,
    minDuration: 250,
    ...options,
  });
};

export default scrollTo;
