import { useState } from "react";
import downloadFileAsUser from "utils/downloadFileAsUser";
import { formatError } from "services/errors";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";
import { DashboardChildType } from "@skillup/espace-rh-bridge";
import { buildURL } from "utils/buildRequest";

import { useDashboardData } from "../context";
import { StructureChildPayload } from "../components/DashboardChildCard/DashboardChildCard";

export function useDashboardExport({ uuid, type }: StructureChildPayload) {
  const { dashboardData, activeCampaign, userAreas } = useDashboardData();

  const { t } = useTranslation();
  const [isExporting, setIsExporting] = useState(false);
  const { addToast } = useToasts();

  if (
    [
      DashboardChildType.SKILLS,
      DashboardChildType.TRAININGS_HISTORY,
      DashboardChildType.INTERVIEWS_HISTORY,
      DashboardChildType.UPLOAD,
    ].includes(type)
  ) {
    return {
      isAvailable: false,
      exportChild: undefined,
      isExporting: false,
    };
  }

  async function exportChild() {
    setIsExporting(true);
    const campaignTitle = dashboardData?.[activeCampaign]?.campaign.title;
    const fileTitle = `${campaignTitle ?? "dashboard"}-export.xlsx`;

    const exportUrl = buildURL({
      path: "/interviews/dashboard/child/{type}/{uuid}/export",
      params: { type, uuid },
      query: {
        areas: userAreas?.join(","),
      },
      target: "API",
    });

    try {
      await downloadFileAsUser(
        exportUrl,
        fileTitle,
        "API",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
    } catch (e) {
      addToast(
        formatError(t, e, {
          defaultValue: t("error.interview.dashboard.export"),
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    }
    setIsExporting(false);
  }

  return {
    isAvailable: true,
    exportChild,
    isExporting,
  };
}
