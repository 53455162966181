import { Dispatch, SetStateAction } from "react";

import { Flex, Text } from "@skillup/design-system";
import { Label, Status, DSButton, StatusType, DSSimpleTextArea } from "@skillup/ui";

import { SelectedAction } from "../../AssignActionsModal";

import styles from "./Action.module.scss";

interface ActionProps {
  isReadonly: boolean;
  actions: SelectedAction[];
  selectedAction: SelectedAction;
  labelsMap: { [key: string]: string };
  handleRemove: (SelectedAction) => void;
  campaignActions: { id: string; label: string }[];
  setActions: Dispatch<SetStateAction<SelectedAction[]>>;
}

export const Action = ({
  actions,
  handleRemove,
  isReadonly,
  labelsMap,
  selectedAction,
  setActions,
}: ActionProps) => {
  return (
    <Flex
      padding="s"
      flexDirection="column"
      className={styles.Action}
      key={selectedAction.actionID}
    >
      <Flex flexDirection="column">
        {selectedAction.actionID.includes("random_skillup_action_") ? (
          <>
            <Label required label="Type d'action" className={styles.Action__Label} />
            <Text>{labelsMap[selectedAction.actionID]}</Text>
          </>
        ) : (
          <Flex justifyContent="space-between">
            <Text espaceFont="h4">{labelsMap[selectedAction.actionID]}</Text>
            {(selectedAction.origin === "MANAGER" || !!selectedAction.managerComment) && (
              <Status type={StatusType.SUCCESS} label="Proposition manager" />
            )}
          </Flex>
        )}
      </Flex>

      {!!selectedAction.managerComment && (
        <Flex marginBottom="xs" flexDirection="column">
          <Label label="Commentaire manager" className={styles.Action__Label} />
          <Text espaceFont="body2Regular" color="plainText-onLight-default">
            {selectedAction.managerComment ? selectedAction.managerComment : "Aucun commentaire."}
          </Text>
        </Flex>
      )}

      <Flex flexDirection="column">
        <Label label="Commentaire coordinateur" className={styles.Action__Label} />
        <DSSimpleTextArea
          disabled={isReadonly}
          defaultValue={selectedAction.coordinatorComment ?? ""}
          onChange={(newValue) => {
            setActions(
              actions.map((s) => {
                if (s.actionID === selectedAction.actionID) {
                  return {
                    ...s,
                    coordinatorComment: newValue,
                  };
                }
                return s;
              })
            );
          }}
        />
      </Flex>

      {!isReadonly && (
        <Flex flexDirection="row" justifyContent="flex-end">
          <DSButton
            buttonSize="S"
            emphasis="Low"
            label="Supprimer l'action"
            onClick={() => handleRemove(selectedAction)}
          />
        </Flex>
      )}
    </Flex>
  );
};
