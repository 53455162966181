import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  EventService,
  ClickContentItemObservable,
  ClickStructureItemObservable,
  FocusContentItemFromScrollObservable,
  FocusStructureItemFromErrorDropdownObservable,
  ItemsUpdateAfterDragEndObservable,
  ItemsUpdateAfterActionOnBlockObservable,
} from "../eventService";
import { debounce } from "lodash";
import contentStyle from "../content/TemplateBuilderContent.module.scss";

export enum ScrollTriggeredBy {
  User = "triggered-by-user",
  Builder = "triggered-by-builder",
}
type UseBuilderEventsType = {
  structureNavRef: React.MutableRefObject<HTMLDivElement | null>;
  contentItemsRefs: React.MutableRefObject<Record<string, HTMLDivElement>>;
  closedItems: Record<string, boolean>;
  setClosedItems: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  parentsIds: React.MutableRefObject<Record<string, string[]>>;
  scrollTriggeredBy: ScrollTriggeredBy;
  setScrollTriggeredBy: React.Dispatch<React.SetStateAction<ScrollTriggeredBy>>;
};
export const useBuilderEvents = (eventService: EventService): UseBuilderEventsType => {
  const structureNavRef = useRef<HTMLDivElement>(null);
  const contentItemsRefs = useRef<Record<string, HTMLDivElement>>({});
  const [closedItems, setClosedItems] = useState<Record<string, boolean>>({});
  // Keeps track of : section and page id for children, and section id for pages.
  // This is used to open parents in structure when needed
  const parentsIds = useRef<Record<string, string[]>>({});
  const [scrollTriggeredBy, setScrollTriggeredBy] = useState<ScrollTriggeredBy>(
    ScrollTriggeredBy.User
  );

  // This is a hack to avoid triggering the useEffect with subscriptions to events when closedItems changes
  const closedItemsMutable = useRef({});
  useEffect(() => {
    closedItemsMutable.current = closedItems;
  }, [closedItems]);

  //Scroll In Structure item (only if needed i.e. if item is not visible)
  const scrollToStructureIfNeeded = useCallback(
    (uuid: string, callback?: (uuid: string) => void) => {
      const element = document.getElementById(`structure_${uuid}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const structureNav = structureNavRef.current;
        const structureRect = structureNav?.getBoundingClientRect();
        if (rect.top < structureRect?.top || rect.bottom > structureRect?.bottom) {
          requestAnimationFrame(() => {
            element.scrollIntoView({ block: "center" });
          });
        }
      }
      if (callback) {
        return callback(uuid);
      }
    },
    []
  );

  // Debounced version of scrollToStructureIfNeeded (useMemo + cancel on rerender to avoid memory leaks)
  const debouncedScrollToStructureIfNeeded = useMemo(
    () => debounce(scrollToStructureIfNeeded, 200),
    [scrollToStructureIfNeeded]
  );
  useEffect(() => {
    debouncedScrollToStructureIfNeeded.cancel();
  }, [debouncedScrollToStructureIfNeeded]);

  // Open parents in structure (only if needed i.e. if item is in page or section closed)
  const openParentsInStructureIfNeeded = useCallback(
    (uuid: string, callback?: (uuid: string) => void) => {
      const itemParentsIds = parentsIds.current[uuid];
      if (itemParentsIds) {
        const idsToOpen = itemParentsIds.filter((uuid) => closedItemsMutable.current[uuid]);
        if (idsToOpen.length > 0) {
          setClosedItems((prev) =>
            idsToOpen.reduce((acc, uuid) => ({ ...acc, [uuid]: undefined }), prev)
          );
        }
      }
      if (callback) {
        return callback(uuid);
      }
    },
    [parentsIds, closedItemsMutable, setClosedItems]
  );

  // Debounced version of openParentsInStructureIfNeeded (useMemo + cancel on rerender to avoid memory leaks)
  const debouncedOpenParentsInStructureIfNeeded = useMemo(
    () => debounce(openParentsInStructureIfNeeded, 50),
    [openParentsInStructureIfNeeded]
  );
  useEffect(() => {
    debouncedOpenParentsInStructureIfNeeded.cancel();
  }, [debouncedOpenParentsInStructureIfNeeded]);

  // Scroll to content item
  const scrollToContent = useCallback((uuid: string, callback?: (uuid: string) => void) => {
    setScrollTriggeredBy(ScrollTriggeredBy.Builder);
    const element = contentItemsRefs.current[uuid];
    if (element) {
      const previousSibling = element.previousElementSibling;
      if (
        previousSibling &&
        previousSibling.classList.contains(contentStyle["content__item__addButton"])
      ) {
        requestAnimationFrame(() => {
          previousSibling.scrollIntoView({ inline: "start" });
        });
      } else {
        requestAnimationFrame(() => {
          element.scrollIntoView({ inline: "start" });
        });
      }
    }
    if (callback) {
      return callback(uuid);
    }
  }, []);

  const debouncedScrollToContent = useMemo(() => debounce(scrollToContent, 100), [scrollToContent]);
  useEffect(() => {
    debouncedScrollToContent.cancel();
  }, [debouncedScrollToContent]);

  useEffect(() => {
    // Subscribe to events
    const clickContentObservable = new ClickContentItemObservable(eventService);
    const clickStructureObservable = new ClickStructureItemObservable(eventService);
    const focusContentItemFromScrollObservable = new FocusContentItemFromScrollObservable(
      eventService
    );
    const focusStructureItemFromErrorDropdownObservable =
      new FocusStructureItemFromErrorDropdownObservable(eventService);
    const itemsUpdateAfterDragEndObservable = new ItemsUpdateAfterDragEndObservable(eventService);
    const itemsUpdateAfterActionOnBlockObservable = new ItemsUpdateAfterActionOnBlockObservable(
      eventService
    );

    clickContentObservable.subscribe((id: string) => {
      openParentsInStructureIfNeeded(id, (id) => {
        debouncedScrollToStructureIfNeeded(id);
      });
    });

    focusContentItemFromScrollObservable.subscribe((id: string) => {
      openParentsInStructureIfNeeded(id, (id) => {
        debouncedScrollToStructureIfNeeded(id);
      });
    });

    focusStructureItemFromErrorDropdownObservable.subscribe((uuid: string) => {
      scrollToContent(uuid, (uuid) => {
        debouncedOpenParentsInStructureIfNeeded(uuid, (uuid) => {
          debouncedScrollToStructureIfNeeded(uuid);
        });
      });
    });

    clickStructureObservable.subscribe((id: string) => {
      scrollToContent(id);
    });

    itemsUpdateAfterDragEndObservable.subscribe((id: string) => {
      debouncedScrollToContent(id);
    });

    itemsUpdateAfterActionOnBlockObservable.subscribe((id: string) => {
      debouncedScrollToContent(id, (id) => {
        debouncedScrollToStructureIfNeeded(id);
      });
    });
    // Cleanup: Remove the event listener when the component is unmounted
    return () => {
      clickContentObservable.unsubscribe();
      focusContentItemFromScrollObservable.unsubscribe();
      focusStructureItemFromErrorDropdownObservable.unsubscribe();
      clickStructureObservable.unsubscribe();
      itemsUpdateAfterDragEndObservable.unsubscribe();
      itemsUpdateAfterActionOnBlockObservable.unsubscribe();
    };
  }, [
    eventService,
    scrollToContent,
    scrollToStructureIfNeeded,
    openParentsInStructureIfNeeded,
    debouncedOpenParentsInStructureIfNeeded,
    debouncedScrollToStructureIfNeeded,
    debouncedScrollToContent,
  ]);

  return {
    structureNavRef,
    contentItemsRefs,
    closedItems,
    setClosedItems,
    parentsIds,
    scrollTriggeredBy,
    setScrollTriggeredBy,
  };
};

type UseBuilderEventsDispatchType = {
  clickContentItem: (id: string) => void;
  clickStructureItem: (id: string) => void;
  focusContentItemFromScroll: (id: string) => void;
  focusStructureItemFromErrorDropdown: (id: string) => void;
  itemsUpdateAfterDragEnd: (id: string) => void;
  itemsUpdateAfterActionOnBlock: (id: string) => void;
};

export const useBuilderEventsDispatch = (
  eventService: EventService
): UseBuilderEventsDispatchType => {
  const clickContentItem = useCallback(
    (uuid: string) => {
      eventService.dispatchEvent({ type: "clickContentItem", uuid });
    },
    [eventService]
  );
  const clickStructureItem = useCallback(
    (uuid: string) => {
      eventService.dispatchEvent({ type: "clickStructureItem", uuid });
    },
    [eventService]
  );
  const focusContentItemFromScroll = useCallback(
    (uuid: string) => {
      eventService.dispatchEvent({ type: "focusContentItemFromScroll", uuid });
    },
    [eventService]
  );

  const focusStructureItemFromErrorDropdown = useCallback(
    (uuid: string) => {
      eventService.dispatchEvent({ type: "focusStructureItemFromErrorDropdown", uuid });
    },
    [eventService]
  );

  const itemsUpdateAfterDragEnd = useCallback(
    (uuid: string) => {
      eventService.dispatchEvent({ type: "itemsUpdateAfterDragEnd", uuid });
    },
    [eventService]
  );

  const itemsUpdateAfterActionOnBlock = useCallback(
    (uuid: string) => {
      eventService.dispatchEvent({ type: "itemsUpdateAfterActionOnBlock", uuid });
    },
    [eventService]
  );

  return {
    clickContentItem,
    clickStructureItem,
    focusContentItemFromScroll,
    focusStructureItemFromErrorDropdown,
    itemsUpdateAfterDragEnd,
    itemsUpdateAfterActionOnBlock,
  };
};
