import type { ReloadRowsDataRoute } from "types/api";
import { buildRequest } from "utils/buildRequest";

import { ScheduleTab } from "@skillup/entities-provider";

export type TTab = ReloadRowsDataRoute["payload"]["tab"];

/**
 * This function partially reloads table with new data:
 * It receives two lists from the API:
 * - updatedRows: the rows that have been updated but are still in the table
 * - movedRows: the rows that have been moved to another tab.
 */
const reloadRows = async (
  { tab, scheduleUuid }: { tab: ScheduleTab; scheduleUuid: string },
  rowsUuids: string[]
) => {
  const request = await buildRequest<ReloadRowsDataRoute>({
    method: "POST",
    path: "/schedule/{scheduleUuid}/rows",
    params: { scheduleUuid },
    payload: {
      rowsUuids,
      tab,
    },
  });

  return request();
};

export default reloadRows;
