export const DatatableJobFields = {
  name: {
    fieldType: "TEXT",
    traductionKey: "job.field.name",
    traductionDefaultValue: "Intitulé",
    key: "name",
  },
  category: {
    fieldType: "TEXT",
    traductionKey: "job.field.category",
    traductionDefaultValue: "Famille de métiers",
    key: "category",
  },
  employeesEvaluated: {
    fieldType: "TEXT",
    traductionKey: "job.field.employeesEvaluated",
    traductionDefaultValue: "Nombre de collaborateurs évalués",
    key: "employeesEvaluated",
  },
  averageEvaluationScore: {
    fieldType: "TEXT",
    traductionKey: "job.field.averageEvaluationScore",
    traductionDefaultValue: "Moyenne des évaluations",
    key: "averageEvaluationScore",
  },
  averageSkillsExpectedLevelDifference: {
    fieldType: "TEXT",
    traductionKey: "job.field.averageSkillsExpectedLevelDifference",
    traductionDefaultValue: "Compétences à l'attendu",
    key: "averageSkillsExpectedLevelDifference",
  },
  averageExpectedLevelDifference: {
    fieldType: "TEXT",
    traductionKey: "job.field.averageExpectedLevelDifference",
    traductionDefaultValue: "Écart à l'attendu",
    key: "averageExpectedLevelDifference",
  },
} as const;
