import { useMemo } from "react";

import { DSModal, Flex } from "@skillup/ui";

import styles from "./Deletion.module.scss";

interface IProps {
  content: JSX.Element;
  type: string;
  title: string;
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
}

export const Deletion = ({ content, type, title, isOpen, closeModal, onConfirm }: IProps) => {
  const displayType = useMemo(() => (type === "section" ? "section" : "sous-section"), [type]);

  return (
    <DSModal isOpen={isOpen} className={styles.DeletionModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title={`Suppression de la ${displayType} «${title}»`} />
      </DSModal.Header>
      <DSModal.Content>
        <Flex column className={styles.content}>
          {content}
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={closeModal} label="Annuler" />
        <DSModal.Footer.PrimaryButton
          label={`Supprimer la ${displayType} et son contenu`}
          onClick={onConfirm}
        />
      </DSModal.Footer>
    </DSModal>
  );
};
