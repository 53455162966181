import { useTranslation } from "react-i18next";

import { useFormikContext } from "formik";

import { DSModal, DSCheckbox } from "@skillup/ui";
import { Flex, FormikInputText } from "@skillup/design-system";

import { useCoreHRFields } from "services/coreHR";
import { useActions } from "services/peopleReview";
import { FormLabel } from "containers/Supervisor/routes/Skills/Jobs/components/Form/JobForm.styled";

type TemplateFormProps = {
  title: string;
  fields: string[];
  actions: string[];
};

export function TemplateForm({ actions, fields, title }: TemplateFormProps) {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { fields: availableFields } = useCoreHRFields();
  const { actions: availableActions } = useActions();

  return (
    <DSModal.Content>
      <Flex flexDirection="column">
        <FormLabel
          label={t("peoplereviewsConfiguration.templates.modals.creation.inputs.titleLabel", {
            defaultValue: "Intitulé du modèle",
          })}
        />
        <FormikInputText
          name="title"
          value={title}
          placeholder={t(
            "peoplereviewsConfiguration.templates.modals.creation.fields.title.placeholder",
            {
              defaultValue: "Saisissez une valeur",
            }
          )}
        />
        <Flex gap="s" marginVertical="s">
          <Flex flexGrow={1} flexDirection="column">
            <FormLabel
              label={t(
                "peoplereviewsConfiguration.templates.modals.creation.fields.evaluationFields.title",
                {
                  defaultValue: "Champs d'évaluation",
                }
              )}
            />
            <Flex gap="xxs" flexDirection="column">
              {availableFields?.map((field) => (
                <DSCheckbox
                  value={field.uuid}
                  label={field.label}
                  checked={fields.includes(field.uuid)}
                  onChange={(checked: boolean) => {
                    if (checked && !fields.includes(field.uuid)) {
                      setFieldValue("fields", [...fields, field.uuid]);
                    } else {
                      setFieldValue(
                        "fields",
                        fields.filter((f) => f !== field.uuid)
                      );
                    }
                  }}
                />
              ))}
            </Flex>
          </Flex>
          <Flex flexGrow={1} flexDirection="column">
            <FormLabel
              label={t(
                "peoplereviewsConfiguration.templates.modals.creation.fields.actions.title",
                {
                  defaultValue: "Action(s) utilisée(s)",
                }
              )}
            />
            <Flex gap="xxs" flexDirection="column">
              {availableActions?.map((action) => (
                <DSCheckbox
                  value={action.uuid}
                  label={action.label}
                  checked={actions.includes(action.uuid)}
                  onChange={(checked: boolean) => {
                    if (checked && !actions.includes(action.uuid)) {
                      setFieldValue("actions", [...actions, action.uuid]);
                    } else {
                      setFieldValue(
                        "actions",
                        actions.filter((a) => a !== action.uuid)
                      );
                    }
                  }}
                />
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </DSModal.Content>
  );
}
