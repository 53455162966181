import { useEffect, useState } from "react";
import { buildRequest } from "utils/buildRequest";
import type { TagsRoutes } from "types/api";

export type Level_1_Tag = TagsRoutes.GET_LEVEL_1_LIST["response"][0];

const getTags = async (setState) => {
  try {
    const request = buildRequest<TagsRoutes.GET_LEVEL_1_LIST>({
      method: "GET",
      path: "/tag/level1",
    });

    const tags = await request();

    setState(tags);
  } catch (err) {
    setState([]);
  }
};

export function useTags() {
  const [tags, setTags] = useState<Level_1_Tag[]>([]);

  useEffect(() => {
    getTags(setTags);
  }, []);

  return { tags, setTags };
}
