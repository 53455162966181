import { v4 as uuid } from "uuid";
import { PayloadAction } from "@reduxjs/toolkit";

import { BuilderTemplate, Section, Page, Child } from "../types";
import { Template } from "services/interviews";
import { getChildErrors, getPageErrors, getSectionErrors } from "../utils";

export const initializeState = (
  _state: BuilderTemplate,
  action: PayloadAction<{ template: Template; savedKey?: string }>
) => {
  const choicesForOrdinalTargets = action.payload.template.choicesForOrdinalTargets ?? [];
  return {
    sections: action.payload.template.sections.map((section) => {
      const builderSection: Section = {
        uuid: section.uuid ?? uuid(),
        title: section.title,
        pages: section.pages.map((page) => {
          const builderPage: Page = {
            uuid: page.uuid ?? uuid(),
            title: page.title,
            hideInBuilder: page.hideInBuilder,
            children: page.children.map((child) => {
              const builderChild: Child = {
                uuid: child.uuid ?? uuid(),
                ...child,
              };
              builderChild.errors = getChildErrors(builderChild, choicesForOrdinalTargets);
              return builderChild;
            }),
          };
          builderPage.errors = getPageErrors(builderPage);
          return builderPage;
        }),
      };
      builderSection.errors = getSectionErrors(builderSection);
      return builderSection;
    }),
  };
};
