import { v4 as uuid } from "uuid";
import { arrayMove } from "./utils/arrayMove";
import { immutableSplice } from "./utils/immutableSplice";
import type { Child, Page, Section } from "../../reducer";

// change section
export function addPageToSection(section: Section, toPageIndex: number, fromPage: Page) {
  const newSection = {
    ...section,
    pages: immutableSplice(section.pages, toPageIndex, 0, fromPage),
  };
  return newSection;
}

export function removePageFromSection(section: Section, fromPageIndex: number) {
  const newSection = {
    ...section,
    pages: immutableSplice(section.pages, fromPageIndex, 1),
  };
  if (newSection.pages.length === 0) {
    newSection.pages = [
      {
        uuid: uuid(),
        title: "Nouvelle sous-section",
        children: [],
        hideInBuilder: true,
      },
    ];
  }
  return newSection;
}

export function replacePageInSection(section: Section, newPage: Page, pageIndex: number) {
  const newSection = {
    ...section,
    pages: immutableSplice(section.pages, pageIndex, 1, newPage),
  };
  if (newSection.pages.length === 0) {
    newSection.pages = [
      {
        uuid: uuid(),
        title: "Nouvelle sous-section",
        children: [],
        hideInBuilder: true,
      },
    ];
  }
  return newSection;
}

export function replacePagesInSection(section: Section, newPages: Page[]) {
  const newSection = {
    ...section,
    pages: newPages,
  };
  if (newSection.pages.length === 0) {
    newSection.pages = [
      {
        uuid: uuid(),
        title: "Nouvelle sous-section",
        children: [],
        hideInBuilder: true,
      },
    ];
  }
  return newSection;
}

export function replaceSection(sections: Section[], section: Section, fromSectionIndex: number) {
  const newSections = immutableSplice(sections, fromSectionIndex, 1, section);
  return newSections;
}

export function switchPages(pages: Page[], fromPageIndex: number, toPageIndex: number) {
  const newPage = arrayMove(pages, fromPageIndex, toPageIndex);
  return newPage;
}

// change page
export function removeChildFromPage(page: Page, fromChildIndex: number) {
  const newPage = {
    ...page,
    children: immutableSplice(page.children, fromChildIndex, 1),
  };
  return newPage;
}

export function addChildToPage(page: Page, toChildIndex: number, fromChild: Child) {
  const newPage = {
    ...page,
    children: immutableSplice(page.children, toChildIndex, 0, fromChild),
  };
  return newPage;
}

export function replaceChildrenInPage(page: Page, newChildren: Child[]) {
  const newPage = {
    ...page,
    children: newChildren,
  };
  return newPage;
}

export function switchChildren(children: Child[], fromChildIndex: number, toChildIndex: number) {
  const newChildren = arrayMove(children, fromChildIndex, toChildIndex);
  return newChildren;
}
