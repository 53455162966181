import React, { ReactNode } from "react";

import { ConfigurationRoutes } from "@skillup/espace-rh-bridge";
import { Button } from "@skillup/ui";

import Acta from "utils/Acta";

import styles from "./ScheduleSelector.module.scss";

type Schedule = ConfigurationRoutes.GetConfiguration["response"]["schedules"][0];

export interface Props {
  readonly children: ReactNode;
  readonly schedules?: Array<Schedule>;
  readonly selectedSchedule?: Schedule;
  readonly hasChanged: boolean;
  readonly setSchedule: (schedule: Schedule) => void;
}

export default function ScheduleTabsConfiguration({
  children,
  schedules,
  setSchedule,
  selectedSchedule,
  hasChanged,
}: Props) {
  return (
    <div className={styles.schedules}>
      {(schedules ?? []).map((schedule) => (
        <Button
          label={schedule.name}
          onClick={() => {
            if (hasChanged) {
              Acta.dispatchEvent("sendAppMessage", {
                message: "Veuillez enregistrer vos changements",
                type: "message",
              });
            } else {
              setSchedule(schedule);
            }
          }}
          key={schedule.uuid}
          kind={selectedSchedule?.uuid === schedule.uuid ? "primary" : "secondary"}
        />
      ))}

      {children}
    </div>
  );
}
