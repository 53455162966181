import { ISimpleSelectionUser, SIMPLE_SELECTION_USER_TYPE } from "@skillup/types";
import { uniqBy } from "lodash";

export const SESSION_STATES = {
  provisional: "Provisoire",
  achieved: "Réalisée",
  summoned: "Convoquée",
  canceled: "Annulée",
  closed: "Clôturée",
  expired: "Date dépassée",
} as const;

type ValueOf<T> = T[keyof T];

export type SessionState = ValueOf<typeof SESSION_STATES>;

export const AFTER_SUMMONED_STATES = Array.from<SessionState>([
  SESSION_STATES.achieved,
  SESSION_STATES.summoned,
  SESSION_STATES.canceled,
  SESSION_STATES.closed,
]);

export const buildRegisteredUsers = (project): Array<ISimpleSelectionUser> => {
  const users = uniqBy(
    [...project.positionnedTrainees, ...project.summonedTrainees].map((user) =>
      project.summonedTrainees.includes(user)
        ? {
            ...user,
            type: SIMPLE_SELECTION_USER_TYPE.SUMMONED,
          }
        : {
            ...user,
            type: SIMPLE_SELECTION_USER_TYPE.POSITIONED,
          }
    ),
    "email"
  );

  return users;
};
