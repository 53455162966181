import React, { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-use";

import { UserRoutes } from "@skillup/espace-rh-bridge";
import { IWithRouter } from "utils/types";
import { hasHotReviewsEnabled, hasColdReviewsEnabled } from "utils/User";
import Acta from "utils/Acta";
import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";

import { useAreas } from "hooks";

import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import DSLayout from "components/DSLayout";
import HotReviewListView from "./HotReviewListView";
import ColdReviewListView from "./ColdReviewListView";

const SupervisorReviewListView = (props: IWithRouter) => {
  const { pathname } = useLocation();
  const { activeAreas } = useAreas();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onExport = useCallback(async () => {
    try {
      setIsLoading(true);
      await downloadTypedFileAsUser<UserRoutes.GetReviewExportRouteData>(
        {
          method: "GET",
          path: "/review/export/Evaluations.xlsx",
          query: {
            timestamp: new Date().valueOf(),
            ...(activeAreas?.length && { areas: activeAreas.join(",") }),
            ...(activeAreas?.length === 0 && { areas: "" }),
          },
        },
        {
          filename: "Evaluations.xlsx",
          target: "API",
          mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }
      );
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors du téléchargement de votre fichier.",
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  }, [activeAreas]);

  const layouts = useMemo(() => {
    const layouts = [
      hasHotReviewsEnabled() && {
        tab: {
          id: "hot",
          label: "Évaluations à chaud",
          url: "/responsable/evaluations/hot",
        },
      },
      hasColdReviewsEnabled() && {
        tab: {
          id: "cold",
          label: "Évaluations à froid",
          url: "/responsable/evaluations/cold",
        },
      },
    ] as const;

    return layouts.filter(Boolean).map((e) => {
      return {
        ...e,
        primaryButton: (
          <DSNewHeaderButton
            label="Télécharger les données"
            onClick={onExport}
            loading={isLoading}
          />
        ),
      };
    });
  }, [isLoading, onExport]);

  const selectedTab = useMemo(() => {
    return (
      layouts.find((e) => pathname === e.tab.url)?.tab.id ||
      layouts.find((e) => pathname.startsWith(e.tab.url))?.tab.id
    );
  }, [layouts, pathname]);

  return (
    <DSLayout
      title="Évaluations"
      layouts={layouts}
      activeLayout={hasColdReviewsEnabled() || hasHotReviewsEnabled() ? selectedTab : undefined}
    >
      <>
        {selectedTab === "cold" && <ColdReviewListView />}
        {selectedTab === "hot" && <HotReviewListView />}
      </>
    </DSLayout>
  );
};

export default SupervisorReviewListView;
