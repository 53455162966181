import { Flex, Text } from "@skillup/design-system";
import { DSDropdown, DSDropdownItem } from "@skillup/ui";

import { plural } from "utils/locale";

import styles from "./ActionCard.module.scss";

interface ActionCardProps {
  label: string;
  color: string;
  onEdit: () => void;
  onArchive: () => void;
  assignedToTemplates: Array<string>;
}

export const ActionCard = ({
  assignedToTemplates,
  color,
  label,
  onArchive,
  onEdit,
}: ActionCardProps) => {
  return (
    <Flex padding="xs" flexDirection="column" className={styles["action-card"]}>
      <Flex
        width="100%"
        marginBottom="xs"
        alignItems="center"
        flexDirection="row"
        justifyContent="start"
      >
        <div
          className={styles["status-indicator"]}
          style={{
            backgroundColor: color,
          }}
        ></div>
        <Text espaceFont="body1Bold" color="plainText-onLight-default">
          {label}
        </Text>
        <DSDropdown buttonSize="S" className={styles.actions}>
          <DSDropdownItem label="Modifier" onClick={onEdit} />
          <DSDropdownItem label="Archiver" onClick={onArchive} />
        </DSDropdown>
      </Flex>
      <Flex width="100%" justifyContent="start">
        <Text espaceFont="captionRegular">
          Utilisée dans {plural(assignedToTemplates.length, "%n modèle%s")}
        </Text>
      </Flex>
    </Flex>
  );
};
