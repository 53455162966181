import { DatasetKey, TimelineRoutes } from "@skillup/core-hr-bridge";

import { buildRequest } from "utils/buildRequest";

export async function getTimeline<TKey extends DatasetKey>(
  key: TKey,
  employeeID: string
): Promise<TimelineRoutes<TKey>["GetTimeline"]["response"]> {
  try {
    const result = await buildRequest<TimelineRoutes<TKey>["GetTimeline"]>({
      method: "GET",
      path: `/datasets/{datasetKey}/employees/{employeeID}`,
      params: { datasetKey: key, employeeID },
      target: "CORE_HR",
    })();

    return result;
  } catch (err) {
    throw new Error("Error fetching fields");
  }
}
