import React from "react";
import InteractiveButton from "components/InteractiveButton";
import Colors from "uiAssets/Colors";
import Acta from "utils/Acta";

import styles from "./RemoveTrainingFromListModal.module.scss";

export interface IProps {
  confirmDelete: () => Promise<void>;
  trainingName: string;
  listName: string;
}

const RemoveTrainingFromList = ({ confirmDelete, trainingName, listName }: IProps) => {
  return (
    <div className={styles.RemoveTrainingFromList}>
      <p>
        Êtes-vous sûr(e) de vouloir retirer la formation "{trainingName}" de la liste "{listName}" ?
      </p>

      <div>
        <InteractiveButton
          label="Annuler"
          background="#fff"
          color={Colors.blue}
          onClick={() => Acta.dispatchEvent("closeModal")}
        />
        <InteractiveButton
          label="Retirer"
          onClick={() => {
            confirmDelete();
            Acta.dispatchEvent("closeModal");
          }}
        />
      </div>
    </div>
  );
};

export default RemoveTrainingFromList;
