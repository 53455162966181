import { Chip } from "@skillup/design-system";
import type { Tracking } from "../../state/tracking";

export function translateState(state: string) {
  switch (state) {
    case "pending":
      return "À inscrire";
    case "won":
      return "Inscrit(e)";
    case "realized":
      return "Réalisé";
    default:
      return "";
  }
}

type Props = {
  state: Tracking["associatedRowState"];
  asChip?: boolean;
};

function TrackingState({ state, asChip }: Props) {
  const translatedState = translateState(state);
  if (asChip) {
    return <Chip label={translatedState} darkMode />;
  }
  return <>{translatedState}</>;
}

export default TrackingState;
