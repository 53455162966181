import { useMemo } from "react";
import { uniqBy } from "lodash";

import { FormatDate, ParseDate } from "@skillup/shared-utils";
import { Column, ColumnType } from "@skillup/ui";

import { Collaborator } from "../../api";

type Props = {
  collaborators: Array<Collaborator>;
  maxLevel: number;
};

function useTableData({ maxLevel, collaborators }: Props): {
  data: CollaboratorRow[];
  columns: Column<CollaboratorRow>[];
} {
  const tableData = useMemo(
    () => ({
      columns: generateColumns({ maxLevel, collaborators }),
      data: generateTableRows(collaborators ?? []),
    }),
    [collaborators, maxLevel]
  );

  return tableData;
}

export default useTableData;

const generateColumns = ({ maxLevel, collaborators }: Props) => {
  const roleOptions = uniqBy(collaborators, (c) => {
    return c.role ?? "";
  })
    .map((e) => ({ value: e.role ?? "", label: e.role ?? "" }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const bookingManagerOptions = uniqBy(collaborators, (c) => {
    return c.bookingManager ?? "";
  })
    .map((e) => ({ value: e.bookingManager ?? "", label: e.bookingManager ?? "" }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const subCompaniesOptions = uniqBy(collaborators, (c) => {
    return c.subCompanies[0]?.name ?? "";
  })
    .map((e) => ({ value: e.subCompanies[0]?.name ?? "", label: e.subCompanies[0]?.name ?? "" }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const areasOptions = uniqBy(collaborators, (c) => {
    return c.areas[0]?.name ?? "";
  })
    .map((e) => ({ value: e.areas[0]?.name ?? "", label: e.areas[0]?.name ?? "" }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const groupsOptions = uniqBy(collaborators, (c) => {
    return c.groups[0]?.name ?? "";
  })
    .map((e) => ({ value: e.groups[0]?.name ?? "", label: e.groups[0]?.name ?? "" }))
    .sort((a, b) => a.label.localeCompare(b.label));
  
  const columns: Array<Column<CollaboratorRow>> = [
    {
      key: "lastName",
      title: "Nom",
      filterable: true,
      width: 10,
      sticky: true,
    },
    {
      key: "firstName",
      title: "Prénom",
      filterable: true,
      width: 10,
      sticky: true,
    },
    {
      key: "email",
      title: "Email",
      filterable: true,
      width: 12.5,
    },
    {
      key: "manager0Lvl0",
      title: "Manager formations N+1",
      filterable: true,
      width: 12.5,
    },
    {
      key: "manager0Lvl1",
      title: "Manager formations N+2",
      filterable: true,
      width: 12.5,
    },
    {
      key: "bookingManager",
      title: "Gestionnaire de réservation",
      filterable: true,
      type: ColumnType.SELECT,
      options: bookingManagerOptions,
      width: 16,
    },
    {
      key: "subCompanies",
      title: "Sous-entreprise",
      filterable: true,
      type: ColumnType.SEARCH_SELECT,
      options: subCompaniesOptions,
      filterFn: (row, values: Set<string>) => {
        return [...values].some((value) => row.data.subCompanies === value);
      },
      width: 12.5,
    },
    {
      key: "role",
      title: "Rôle",
      filterable: true,
      type: ColumnType.SEARCH_SELECT,
      options: roleOptions,
      filterFn: (row, values: Set<string>) => {
        return [...values].some((value) => row.data.role === value);
      },
      width: 12.5,
    },
    {
      key: "registrationNumber",
      title: "Matricule",
      filterable: true,
    },
    {
      key: "joinDate",
      title: "Date d'embauche",
      filterable: true,
      type: ColumnType.DATE,
      sortable: true,
      sortFn: (a, b) => {
        return ParseDate.FromISO(a.data.joinDate).toJSDate().getTime() - ParseDate.FromISO(b.data.joinDate).toJSDate().getTime();
      },
      renderCell: (row) => {
        return row.joinDate
          ? FormatDate.toStringLiteralFormatFromUtc(row.joinDate, "dd/MM/yyyy")
          : "";
      },
      width: 12.5,
    },
    {
      key: "areas",
      title: "Périmètres",
      filterable: true,
      type: ColumnType.SEARCH_SELECT,
      options: areasOptions,
      filterFn: (row, values: Set<string>) => {
        return [...values].some((value) => row.data.areas === value);
      },
      width: 12.5,
    },
    {
      key: "groups",
      title: "Groupes",
      filterable: true,
      type: ColumnType.SEARCH_SELECT,
      options: groupsOptions,
      filterFn: (row, values: Set<string>) => {
        return [...values].some((value) => row.data.groups === value);
      },
      width: 12.5,
    },
    ...Array.from({ length: maxLevel }, (_, i) => i + 1).map((level) => ({
      key: `observerLvl${level}`,
      title: `Manager entretiens N+${level}`,
      filterable: true,
      width: 12.5,
    })),
  ];

  return columns;
};

function generateTableRows(collaborators: Collaborator[]): Array<CollaboratorRow> {
  return collaborators?.map(parseCollaboratorIntoRow);
}

function parseCollaboratorIntoRow(collaborator: Collaborator) {
  return {
    id: collaborator.uuid,
    data: {
      lastName: collaborator.lastName,
      firstName: collaborator.firstName,
      email: collaborator.email,
      manager0Lvl0: collaborator.manager0Lvl0,
      manager0Lvl1: collaborator.manager0Lvl1,
      bookingManager: collaborator.bookingManager,
      subCompanies: collaborator.subCompanies[0]?.name,
      role: collaborator.role,
      registrationNumber: collaborator.registrationNumber,
      joinDate: collaborator.joinDate,
      areas: collaborator.areas[0]?.name,
      groups: collaborator.groups[0]?.name,
      ...collaborator.hierarchy?.observers
        .map((observer: { level: number; fullName: string; uuid: string; email: string }) => ({
          [`observerLvl${observer.level}`]: observer.fullName,
        }))
        .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
    },
  };
}

export type CollaboratorRow = ReturnType<typeof parseCollaboratorIntoRow>;
