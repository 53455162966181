import React, { useCallback } from "react";
import { useToggle } from "react-use";
import type { InterviewWithStates } from "@skillup/types";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import InteractiveButton from "components/InteractiveButton";
import Colors from "uiAssets/Colors";

interface IProps {
  readonly campaignUuid: string;
  readonly interview: InterviewWithStates;
  readonly resync: () => Promise<InterviewWithStates[]>;
}

const ReopenModal = ({ campaignUuid, interview, resync }: IProps): JSX.Element => {
  const [loading, toggleLoading] = useToggle(false);

  const sendReopen = useCallback(async (): Promise<void> => {
    toggleLoading(true);

    try {
      await DataLayer.request({
        method: "POST",
        url: `/v1/campaigns/${campaignUuid}/interviews/${interview.uuid}/reopen`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "Entretien rouvert.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Impossible de réouvrir l'entretien",
        type: "error",
      });
    } finally {
      resync();
      toggleLoading(false);
      Acta.dispatchEvent("closeModal");
    }
  }, [resync, toggleLoading, campaignUuid, interview]);

  return (
    <div style={{ fontSize: 14, padding: 20 }}>
      <p>
        La réouverture de l’entretien permettra à <strong>{interview.employee.fullName} </strong>
        et <strong>{interview.manager.fullName}</strong> de le modifier à nouveau.
      </p>
      <footer style={{ display: "flex", padding: 20 }}>
        <InteractiveButton
          label="Précécent"
          onClick={() => Acta.dispatchEvent("closeModal")}
          size="small"
          background="#fff"
          color={Colors.blue}
        />
        <InteractiveButton
          loading={loading}
          label="Rouvrir"
          title="re-open-interview"
          onClick={sendReopen}
          size="small"
        />
      </footer>
    </div>
  );
};

export default ReopenModal;
