import type { Child } from "../../../types";
import { defaultRestrictions } from "./defaultRestrictions";

export type GenerateEmptyChildType = QuestionChild | TargetsChild | OtherChildren;

type QuestionChild = {
  uuid: string;
  kind: "question";
  type: Extract<Child, { kind: "question" }>["type"];
};

type TargetsChild = {
  uuid: string;
  kind: "targets";
  type: Extract<Child, { kind: "targets" }>["type"];
};

type OtherChildren = {
  uuid: string;
  kind: Exclude<Child["kind"], "question" | "targets" | "guidelines" | "personnalisableGuidelines">;
};

export const generateEmptyChild = (params: GenerateEmptyChildType): Child => {
  if (params.kind === "question") {
    return emptyQuestion({ uuid: params.uuid, type: params.type });
  } else if (params.kind === "targets") {
    return emptyTargets({ uuid: params.uuid, type: params.type });
  } else {
    switch (params.kind) {
      case "title":
        return emptyTitle(params.uuid);
      case "globalTargetsCompletion":
        return emptyGlobalTargetsCompletion(params.uuid);
      case "html":
        return emptyHtml(params.uuid);
      case "interviewsHistory":
        return emptyInterviewsHistory(params.uuid);
      case "job":
        return emptyJob(params.uuid);
      case "skills":
        return emptySkills(params.uuid);
      case "table":
        return emptyTable(params.uuid);
      case "trainingsCollection":
        return emptyTrainingsCollection(params.uuid);
      case "trainingsHistory":
        return emptyTrainingsHistory(params.uuid);
      case "upload":
        return emptyUpload(params.uuid);
      case "userSpecificData":
        return emptyUserSpecificData(params.uuid);
      case "conclusion":
        return emptyConclusion(params.uuid);
      default:
        throw new Error(`Unknown child kind`);
    }
  }
};

const emptyQuestion = ({
  uuid,
  type,
}: {
  uuid: string;
  type: QuestionChild["type"];
}): Extract<Child, { kind: "question" }> => {
  const defaultChild = {
    kind: "question" as const,
    uuid,
    label: "",
    required: false,
    disallow: defaultRestrictions.question,
  };

  switch (type) {
    case "text":
      return {
        ...defaultChild,
        type: "text",
      };
    case "radio":
      return {
        ...defaultChild,
        type: "radio",
        choices: [],
      };
    case "checkbox":
      return {
        ...defaultChild,
        type: "checkbox",
        choices: [],
      };
    default:
      throw new Error(`Unknown question type: ${type}`);
  }
};

const emptyTitle = (uuid: string): Extract<Child, { kind: "title" }> => {
  return {
    uuid,
    kind: "title",
    title: "",
    variant: "H1",
  };
};

const emptyGlobalTargetsCompletion = (
  uuid: string
): Extract<Child, { kind: "globalTargetsCompletion" }> => {
  return {
    uuid,
    kind: "globalTargetsCompletion",
    forcePercentWeight: false,
    required: false,
    disallow: defaultRestrictions.globalTargetsCompletion,
  };
};

const emptyHtml = (uuid: string): Extract<Child, { kind: "html" }> => {
  return {
    uuid,
    kind: "html",
    content: "",
    variant: "warning",
  };
};

const emptyInterviewsHistory = (uuid: string): Extract<Child, { kind: "interviewsHistory" }> => {
  return {
    uuid,
    kind: "interviewsHistory",
    types: [],
    ageUnit: "years",
    maxAge: 1,
    disallow: defaultRestrictions.interviewsHistory,
  };
};

const emptyJob = (uuid: string): Extract<Child, { kind: "job" }> => {
  return {
    uuid,
    kind: "job",
    title: "",
    disallow: defaultRestrictions.job,
  };
};

const emptySkills = (uuid: string): Extract<Child, { kind: "skills" }> => {
  return {
    uuid,
    kind: "skills",
    disallow: defaultRestrictions.skills,
  };
};

const emptyTargets = ({
  uuid,
  type,
}: {
  uuid: string;
  type: TargetsChild["type"];
}): Extract<Child, { kind: "targets" }> => {
  switch (type) {
    case "current":
      return {
        uuid,
        kind: "targets",
        type: "current",
        disallow: defaultRestrictions.targets,
        hidePeriod: true,
        hideWeight: true,
        disableGoalSelection: true,
      };
    case "next":
      return {
        uuid,
        kind: "targets",
        type: "next",
        disallow: defaultRestrictions.targets,
        hidePeriod: true,
        hideWeight: true,
        disableGoalSelection: true,
      };
    default:
      throw new Error(`Unknown targets type: ${type}`);
  }
};

const emptyTable = (uuid: string): Extract<Child, { kind: "table" }> => {
  return {
    uuid,
    kind: "table",
    label: "",
    columns: [],
    rowsCount: 0,
    disallow: defaultRestrictions.table,
  };
};

const emptyTrainingsCollection = (
  uuid: string
): Extract<Child, { kind: "trainingsCollection" }> => {
  return {
    uuid,
    kind: "trainingsCollection",
    disallow: defaultRestrictions.trainingsCollection,
  };
};

const emptyTrainingsHistory = (uuid: string): Extract<Child, { kind: "trainingsHistory" }> => {
  return {
    uuid,
    kind: "trainingsHistory",
    ageUnit: "years",
    maxAge: 1,
    disallow: defaultRestrictions.trainingsHistory,
  };
};

const emptyUpload = (uuid: string): Extract<Child, { kind: "upload" }> => {
  return {
    uuid,
    kind: "upload",
    disallow: defaultRestrictions.upload,
  };
};

const emptyUserSpecificData = (uuid: string): Extract<Child, { kind: "userSpecificData" }> => {
  return {
    uuid,
    kind: "userSpecificData",
    userSpecificDataKey: "",
  };
};

const emptyConclusion = (uuid: string): Extract<Child, { kind: "conclusion" }> => {
  return {
    uuid,
    kind: "conclusion",
  };
};
