import { useQuery } from "@tanstack/react-query";
import { isEmpty } from "lodash";

import { buildRequest } from "utils/buildRequest";
import { UserRoutes } from "@skillup/espace-rh-bridge";

async function searchUsersByQueryV2(params: UseUsersParams) {
  if (isEmpty(params.query)) {
    return [];
  }
  try {
    const data = await buildRequest<UserRoutes.SearchByQueryRouteV2>({
      method: "GET",
      path: "/user/search-by-query/v2",
      query: {
        input: params.query,
      },
    })();

    return data;
  } catch (err) {
    return [];
  }
}

type UseUsersParams = {
  query: string;
  excludedUserUuids?: string[];
};

export function useUsers(params: UseUsersParams) {
  const query = useQuery([`users-${params.query}`], async () => searchUsersByQueryV2(params), {});
  const data = query.data?.filter((user) => !params.excludedUserUuids?.includes(user.uuid)) ?? [];
  return { ...query, data };
}
