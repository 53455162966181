import styled from "styled-components";
import { Form } from "formik";

import { DSColor, SkuiSpacing } from "@skillup/ui";

import { FlexCenterButton } from "@skillup/design-system";

export const FormStyled = styled(Form)`
  @media (max-width: 1200px) {
    order: 2;
  }
`;

export const EvaluationForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 35%;
  grid-template-areas: "evaluation-scale-name options" "evaluation-scale-levels options" "evaluation-scale-inputs options";
  padding: ${SkuiSpacing.m};
  gap: ${SkuiSpacing.s};

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-areas: "evaluation-scale-name" "options" "evaluation-scale-levels" "evaluation-scale-inputs";
  }
`;

export const AddLevelButton = styled(FlexCenterButton)`
  padding: ${SkuiSpacing.xxs} ${SkuiSpacing.xs};
  color: ${DSColor["action-onLight-default"]};
`;
