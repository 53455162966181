import styled from "styled-components";

import { DSColor, DSTextInput, SkuiSpacing } from "@skillup/ui";

export const SearchInput = styled(DSTextInput)`
  width: 100%;

  input {
    width: 100%;
    border-color: ${DSColor["border-onLight"]};

    &::placeholder {
      font-style: italic;
    }
  }
  svg {
    fill: ${DSColor["plainText-onLight-lighter"]};
    height: ${SkuiSpacing.m};
    width: ${SkuiSpacing.m};
  }
`;

export const SearchResult = styled.span`
  display: flex;
  position: absolute;
  z-index: 99999;
  top: 66px;
  width: 40%;
  max-height: 400px;
  overflow: scroll;
  padding: ${SkuiSpacing.xs};
  border: 1px solid ${DSColor["border-onLight"]};
  border-radius: 0 0 ${SkuiSpacing.xxs} ${SkuiSpacing.xxs};
  background-color: ${DSColor["surface-light"]};
  flex-direction: column;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);

  > * {
    margin-bottom: ${SkuiSpacing.xs};
  }

  > div {
    cursor: pointer;
    color: ${DSColor["plainText-onLight-default"]};
    color: #1a304d;
  }

  > span {
    margin-top: ${SkuiSpacing.xs};
    color: ${DSColor["plainText-onLight-lighter"]};
  }
`;

export const Link = styled.a`
  cursor: pointer;
  display: flex;
`;
