import { TemplatesRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

const transferRequest =
  () => async (params: { templateUuid: string; toCompanyUuid: string; newTitle?: string }) => {
    return buildRequest<TemplatesRoutes.TransferCopy>({
      method: "POST",
      path: "/templates/transfer-copy/{templateUuid}",
      params: { templateUuid: params.templateUuid },
      payload: {
        toCompanyUuid: params.toCompanyUuid,
        title: params.newTitle,
      },
    })();
  };

export const transferTemplateCopy = async ({ templateUuid, toCompanyUuid, newTitle }) => {
  return transferRequest()({
    templateUuid,
    toCompanyUuid,
    newTitle,
  });
};