import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import { useSetState } from "react-use";

import { DSModal } from "@skillup/ui";
import { CampaignsRoutes } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import RadioButtons from "components/RadioButtons";
import { IRadioOption } from "components/RadioButtons/RadioButtons";

import { InterviewRoles, InterviewTypes } from "types/Interview";

type ModalState = {
  selection: IRadioOption | null;
  isLoading: boolean;
};

const initialState: ModalState = {
  selection: null as IRadioOption | null,
  isLoading: false,
};

export default ({
  isOpen,
  closeModal,
  campaignUuid,
}: {
  isOpen: boolean;
  closeModal: () => void;
  campaignUuid: string;
}) => {
  const [{ selection, isLoading }, setState] = useSetState<ModalState>(initialState);

  useEffect(() => {
    if (!isOpen) {
      setState(initialState);
    }
  }, [isOpen, setState]);

  const send = async (): Promise<void> => {
    // event.preventDefault();

    if (!selection) return;

    // checking multiple calls
    if (isLoading) return;
    setState({ isLoading: true });

    let param: { role: InterviewTypes.ActiveRoles; state: "shared" | "pending" };
    switch (selection.value) {
      case "employeeNotShared": {
        param = { role: InterviewRoles.EMPLOYEE, state: "pending" };
        break;
      }
      case "employeeNotSigned":
        param = { role: InterviewRoles.EMPLOYEE, state: "shared" };
        break;
      case "managerNotShared": {
        param = { role: InterviewRoles.MANAGER, state: "pending" };
        break;
      }
      case "managerNotSigned":
        param = { role: InterviewRoles.MANAGER, state: "shared" };
        break;
    }

    try {
      await buildRequest<CampaignsRoutes.Interviews.SendReminders>({
        method: "POST",
        path: `/campaigns/{campaignUuid}/interviews/reminders`,
        params: { campaignUuid },
        query: param,
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: "Relances envoyées.",
        type: "success",
      });
      closeModal();
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Erreur lors de l'envoi des relances.",
        type: "error",
      });
      setState({ isLoading: false });
    }
  };

  return (
    <DSModal isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title="Relancer les entretiens" />
      </DSModal.Header>

      <DSModal.Content>
        <RadioButtons
          onChange={(option) => setState({ selection: option })}
          options={[
            {
              label: " Les collaborateurs qui n’ont pas signé",
              value: "employeeNotSigned",
            },
            {
              label: "Les managers qui n'ont pas signé",
              value: "managerNotSigned",
            },
          ]}
          value={selection?.value}
          firstOptionAsDefaultValue={false}
        />
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={closeModal} label="Annuler" />
        <DSModal.Footer.PrimaryButton
          disabled={isEmpty(selection)}
          tooltip={isEmpty(selection) ? "Sélectionnez un groupe" : ""}
          loading={isLoading}
          onClick={() => {
            send();
          }}
          label="Relancer"
        />
      </DSModal.Footer>
    </DSModal>
  );
};
