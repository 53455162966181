import { Route, Switch, useHistory } from "react-router-dom";

import { generateModalContext } from "@skillup/ui";

import DSLayout from "components/DSLayout";
import { isSkillupDeveloper } from "utils/User";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton/DSNewHeaderButton";

import { Fields } from "./Fields";
import { ScalesManagement } from "./Scales/Scales";
import { ImportsExports } from "./ImportsExports/ImportsExports";
import { CreateScaleModal } from "../../components/CreateScaleModal";

const useModal = generateModalContext();
export const CoreHRManagement = () => {
  const history = useHistory();
  const currentTab = history.location.pathname.split("/").pop() || "";
  const createScaleModal = useModal();

  const isDeveloper = isSkillupDeveloper();

  if (!isDeveloper) {
    history.push("/responsable");
  }

  return (
    <DSLayout
      activeLayout={currentTab}
      title="Configuration du Core HR"
      layouts={[
        {
          primaryButton: (
            <DSNewHeaderButton
              label="Créer une nouvelle échelle"
              onClick={createScaleModal.openModal}
            />
          ),
          tab: {
            id: "echelles",
            label: "Configuration des échelles",
            url: `/responsable/configuration-du-core-hr/echelles`,
          },
        },
        {
          primaryButton: null,
          tab: {
            id: "champs-utilisateur",
            label: "Propriétés d'un utilisateur",
            url: `/responsable/configuration-du-core-hr/champs-utilisateur`,
          },
        },

        {
          primaryButton: null,
          tab: {
            id: "imports-exports",
            label: "Imports & Exports",
            url: `/responsable/configuration-du-core-hr/imports-exports`,
          },
        },
      ]}
    >
      <Switch>
        <Route path={`/responsable/configuration-du-core-hr/echelles`}>
          <ScalesManagement />
          <CreateScaleModal
            isOpen={createScaleModal.isOpen}
            onClose={createScaleModal.closeModal}
          />
        </Route>

        <Route path={`/responsable/configuration-du-core-hr/champs-utilisateur`}>
          <Fields />
        </Route>

        <Route path={`/responsable/configuration-du-core-hr/imports-exports`}>
          <ImportsExports />
        </Route>
      </Switch>
    </DSLayout>
  );
};
