import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

import useTranslation from "hooks/useTranslation";

import { MultipleArchiveButton } from "../../ListSkills.styled";

interface Props {
  archiveMultipleSkills: () => void;
  selectedRows: GridRowSelectionModel;
}

export const ToolbarButton = ({ archiveMultipleSkills, selectedRows }: Props) => {
  const { t } = useTranslation();

  return (
    <MultipleArchiveButton
      onClick={archiveMultipleSkills}
      label={t("skills.list.label.archiveMultiple", {
        count: selectedRows.length,
        defaultValue: "Archiver {{count}} compétences",
      })}
    />
  );
};
