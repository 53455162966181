import { useMemo } from "react";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

import {
  DndContext,
  DragOverlay,
  rectIntersection,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  MouseSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import { Flex } from "@skillup/ui";

import { SortableSection, ImmutableSection } from "./components";
import { useTemplateDnd, DNDTemplate } from "./dndReducer";

import styles from "./TemplateBuilderStructure.module.scss";
import { TemplateItemPosition } from "../reducer";

export const TemplateBuilderStructure = () => {
  const {
    templateState,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDragCancel,
    disabledSortablePages,
    disabledSortableChildren,
    dragActive,
    overlay,
  } = useTemplateDnd();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={rectIntersection}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableSections
        template={templateState}
        disabledSortablePages={disabledSortablePages}
        disabledSortableChildren={disabledSortableChildren}
        dragActive={dragActive}
      />
      <DragOverlay className={styles.DragOverlay}>{dragActive && overlay}</DragOverlay>
    </DndContext>
  );
};

const SortableSections = ({
  template,
  disabledSortablePages = false,
  disabledSortableChildren = false,
  dragActive,
}: {
  template: DNDTemplate;
  disabledSortablePages: boolean;
  disabledSortableChildren: boolean;
  dragActive: TemplateItemPosition<any> | undefined;
}) => {
  // Todo : find the best strategy and collision detection for our use case (not so obvious)
  const { sections, sectionsIds } = useMemo(() => {
    if (!template) {
      return { sections: [], sectionsIds: [] };
    }
    return {
      sections: template.sections,
      sectionsIds: template.sections.map((section) => section.uuid),
    };
  }, [template]);

  const lastSectionIndex = useMemo(() => {
    return sections.length - 1 > 0 ? sections.length - 1 : 0;
  }, [sections]);

  return (
    <SortableContext items={sectionsIds} strategy={verticalListSortingStrategy}>
      <Flex column className={styles.Structure + " " + (dragActive ? styles["is-dragging"] : "")}>
        {sections.map((section, index) =>
          index === 0 || index === lastSectionIndex ? (
            <ImmutableSection key={section.uuid} section={section} isDragging={!!dragActive} />
          ) : (
            <SortableSection
              disabledSortablePages={disabledSortablePages}
              disabledSortableChildren={disabledSortableChildren}
              key={section.uuid}
              section={{ ...section, type: "section", sectionIndex: index }}
              lastSectionIndex={lastSectionIndex}
            />
          )
        )}
      </Flex>
    </SortableContext>
  );
};
