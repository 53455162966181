import { v4 as generateUuid } from "uuid";

import { Collaborator } from "@skillup/espace-rh-bridge";

import { SkillEvaluationsScalesModel } from "@skillup-skills/kafka-bridge";

import {
  Skill,
  JobSkill,
  JobFields,
  ProcessedSkill,
  SkillWithLevelUuid,
  ProcessedSkillWithLevelUuid,
} from "types/skills";

export function partition<A, B>(
  arr: Array<A>,
  partitionner: (v: A) => B
): Array<{ key: B; value: Array<A> }> {
  const mutAccMap = new Map<string, { key: B; value: Array<A> }>();

  arr.forEach((e) => {
    const key = partitionner(e);
    const keyString = JSON.stringify(key);
    const maybeValues = mutAccMap.get(keyString);

    mutAccMap.set(
      keyString,
      !maybeValues ? { key: key, value: [e] } : { ...maybeValues, value: [...maybeValues.value, e] }
    );
  });

  return [...mutAccMap.values()];
}

export function FormatSkillArray<T extends Skill | ProcessedSkill>(
  skills: Array<T>,
  noCategoryLabel: string,
  generatedByAiLabel: string = null
) {
  const dataWithCategories = skills.filter((skill) => skill.category);
  const dataWithoutCategories = skills.filter((skill) => !skill.category && !skill.generated);
  const dataWithGeneratedCategories = skills.filter((skill) => skill.generated);
  const dataWithCategoriesPartitioned = partition(
    dataWithCategories,
    (skill) => skill.category.label
  );
  const dataWithCategoriesOrdered = dataWithCategoriesPartitioned.sort((a, b) =>
    a.key.localeCompare(b.key)
  );

  return [
    ...dataWithCategoriesOrdered,
    ...(dataWithoutCategories.length > 0
      ? [
          {
            key: noCategoryLabel,
            value: dataWithoutCategories,
          },
        ]
      : []),
    ...(dataWithGeneratedCategories.length > 0
      ? [
          {
            key: generatedByAiLabel,
            value: dataWithGeneratedCategories,
          },
        ]
      : []),
  ];
}

export function formatSkillListWithLevelUuid(
  skills: Array<ProcessedSkill>,
  currentSkills: JobSkill[]
): Array<ProcessedSkillWithLevelUuid> {
  return (skills || [])
    .filter((skill) => currentSkills.some((e) => e.skillUuid === skill.uuid))
    .map((skill) => ({
      ...skill,
      levelUuid: currentSkills.find((e) => e.skillUuid === skill.uuid)?.expectedLevelUuid,
    }));
}

export function formatGeneratedSkillList(
  skills: Array<JobSkill>,
  defaultEvaluationScale: SkillEvaluationsScalesModel.EvaluationScale
) {
  return (skills || []).map((skill) => ({
    uuid: skill.skillUuid ?? generateUuid(),
    category: undefined,
    createdAt: new Date(),
    description: "",
    employees: {},
    evaluationScale: defaultEvaluationScale,
    expectedLevelUuid: skill.expectedLevelUuid,
    fields: [],
    generated: true,
    isArchived: false,
    jobs: {},
    levelUuid: skill.expectedLevelUuid,
    name: skill.skillName,
    updatedAt: new Date(),
    updatedBy: undefined,
    version: 0,
  }));
}

export function FormatSkillList(
  skills: Array<SkillWithLevelUuid | ProcessedSkillWithLevelUuid>,
  noCategoryLabel: string,
  generatedByAiLabel: string
) {
  return FormatSkillArray(skills, noCategoryLabel, generatedByAiLabel).map((e) => ({
    name: e.key,
    skills: e.value
      .map((skill) => ({
        evaluatedAt: "",
        evaluatedBy: "",
        generated: skill.generated ?? false,
        level: skill.evaluationScale.levels.find((level) => level.uuid === skill.levelUuid)?.level,
        levelName: skill.evaluationScale.levels.find((level) => level.uuid === skill.levelUuid)
          ?.name,
        name: skill.name,
        skillUuid: skill.uuid,
        totalLevel: skill.evaluationScale.levels
          .map(({ level }) => ({ level }))
          .sort((a, b) => a.level - b.level),
      }))
      .sort((a, b) => a.name.localeCompare(b.name)),
  }));
}

export const parseFormFields = (fields: undefined | JobFields[]): JobFields[] => {
  return (fields || []).map((field) => ({
    uuid: field.uuid,
    index: field.index,
    name: field.name,
    value: field.value,
  }));
};

export const getCollabNameFromUuid = (
  collaboratorsData: {
    maxLevel: number;
    collaborators: Collaborator[];
  },
  uuid: string
) => {
  if (!collaboratorsData?.collaborators) return undefined;
  return (
    collaboratorsData?.collaborators?.find((collaborator) => collaborator.uuid === uuid)
      ?.fullName ?? ""
  );
};
