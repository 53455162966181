import { Flex, Text } from "@skillup/design-system";
import { Collaborator } from "../../../api";
import placeholderImg from "assets/collaborator/empty_target.png";

interface Props {
  readonly user: Collaborator;
  readonly selectedFilter: "evaluated" | "inProgress";
}

export const UserTargetEmpty = ({ user, selectedFilter }: Props) => {
  return (
    <Flex flexDirection="column" alignItems="center" flexGrow={1}>
      <img src={placeholderImg} alt="bientôt disponible" />
      <Text espaceFont="body1Regular">
        Aucun objectif n'a encore été {selectedFilter === "evaluated" ? "évalué" : "défini"} pour{" "}
        {user.fullName}
      </Text>
    </Flex>
  );
};
