import { ActionRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type UnarchiveActionParams = ActionRoutes.UnarchiveActionRoute["params"];

export async function unarchiveAction({ actionID }: UnarchiveActionParams) {
  await buildRequest<ActionRoutes.UnarchiveActionRoute>({
    method: "POST",
    params: {
      actionID,
    },
    path: `/actions/{actionID}/unarchive`,
    target: "PEOPLE_REVIEW",
  })();
}
