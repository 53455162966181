import DataLayer from "utils/DataLayer";
import Acta from "utils/Acta";

import { TrainingInterface } from "../types";
import { IState } from "../components/List";

const addTrainingToList =
  (listUuid: string, trainings: TrainingInterface[], setState: (state: IState) => void) =>
  async (uuid) => {
    const training = (await DataLayer.request({
      method: "POST",
      url: `/v1/portal/training-list/${listUuid}/training/${uuid}`,
    })) as TrainingInterface;

    setState({
      trainings: [...trainings, training],
    });

    Acta.dispatchEvent("closeModal");
  };

export default addTrainingToList;
