import { AddToast } from "react-toast-notifications";
import DataLayer from "utils/DataLayer";
import { IState } from "../components/List";

interface Iprops {
  uuid: string;
  addToast: AddToast;
  t: (
    key: string,
    options?: {
      [key: string]: any;
      defaultValue?: string;
    }
  ) => string;
  setState: (e: (prevState: IState) => Partial<IState>) => void;
}

const saveListName =
  ({ uuid, addToast, t, setState }: Iprops) =>
  async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const targetValue: string = event.currentTarget.elements["listNameInput"].value;

    try {
      await DataLayer.request({
        method: "POST",
        url: `/v1/portal/training-list/${uuid}`,
        body: JSON.stringify({ title: targetValue }),
      });
      setState((prevState) => ({
        ...prevState,
        title: targetValue,
        isEditingTitle: false,
      }));
      addToast(
        t("ortal.saveListName.sucess", {
          defaultValue: "Le nom de la liste a été modifié avec succès.",
        }),
        { appearance: "success" }
      );
    } catch (error) {
      addToast(
        t("portal.saveListName.error", {
          defaultValue: "Une erreur est survenue lors de la modification du nom de la liste.",
        }),
        { appearance: "error" }
      );
    }
  };

export default saveListName;
