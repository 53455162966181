import React, { createContext, useContext, useMemo, useReducer } from "react";
import { ISimpleSelectionUser, ISimpleManager } from "@skillup/types";
import { forEach, pull, uniq } from "lodash";

interface IState {
  selectedUsers: ISimpleSelectionUser[];
  selectedPairs: { employee: ISimpleSelectionUser; manager: ISimpleManager }[];
}

type ActionValue =
  | ISimpleSelectionUser
  | { employee: ISimpleSelectionUser; manager: ISimpleManager };

interface IAction {
  type: "add" | "remove" | "addPair" | "removePair";
  value: ActionValue;
}

export const isPair = (
  entity: ActionValue
): entity is { employee: ISimpleSelectionUser; manager: ISimpleManager } => {
  return (
    (entity as { employee: ISimpleSelectionUser; manager: ISimpleManager })?.employee !== undefined
  );
};
export const isSimpleManager = (entity: ActionValue): entity is ISimpleManager => {
  return (entity as ISimpleManager)?.linkedTo !== undefined;
};

type Dispatch = (action: IAction) => void;

type CountProviderProps = { children: React.ReactNode; content?: Partial<IState> };

const UsersListContext = createContext<{ state: IState; dispatch: Dispatch } | undefined>(
  undefined
);

const isSelectedButDuplicateError = (
  selectedUsers: ISimpleSelectionUser[],
  value: ISimpleSelectionUser
): boolean => {
  const isSelected = selectedUsers.find((user) => user.email === value.email);
  const hasDuplicatesError = value.errors?.includes("duplicate");

  return isSelected && hasDuplicatesError;
};

function usersListReducer(state: IState, action: IAction) {
  switch (action.type) {
    case "add": {
      const { value } = action;
      if (isPair(value)) {
        return state;
      }
      if (state.selectedUsers.find((user) => user.email === value.email)) {
        // Update other duplicates
        const others = state.selectedUsers.filter((user) => user.email === value.email);
        forEach(others, (user) => {
          if (user.errors?.includes["duplicate"]) return;
          const indexUser = state.selectedUsers.indexOf(user);

          user.errors = user.errors?.length ? uniq([...user.errors, "duplicate"]) : ["duplicate"];
          state.selectedUsers.splice(indexUser, 1, user);
        });

        value.errors = value.errors?.length ? uniq([...value.errors, "duplicate"]) : ["duplicate"];
      }
      if (isSelectedButDuplicateError(state.selectedUsers, value)) {
        value.index = state.selectedUsers.filter((user) => user.email === value.email).length;
      } else {
        value.index = 0;
      }
      const newArray = [...state.selectedUsers];
      newArray.unshift(value);
      return { ...state, selectedUsers: newArray };
    }
    case "remove": {
      const { value } = action;
      if (isPair(value)) {
        return state;
      }

      // Update other duplicates
      if (isSelectedButDuplicateError(state.selectedUsers, value)) {
        const others = state.selectedUsers.filter((user) => user.email === value.email);
        if (others.length - 1 === 1) {
          const toUpdate = others.find((item) => item.index !== value.index);

          const indexToUpdate = state.selectedUsers.indexOf(toUpdate);
          pull(toUpdate?.errors, "duplicate");
          state.selectedUsers.splice(indexToUpdate, 1, toUpdate);
        }
      }

      return {
        ...state,
        selectedUsers: state.selectedUsers.filter((item) => item !== action.value),
      };
    }
    case "addPair": {
      const { value } = action;
      if (!isPair(value)) {
        return state;
      }
      if (state.selectedPairs.find((pair) => pair.employee.email === value.employee.email)) {
        // Update other duplicates
        const others = state.selectedPairs.filter(
          ({ employee }) => employee.email === value.employee.email
        );
        forEach(others, (pair) => {
          if (pair.employee.errors?.includes["duplicate"]) return;
          const indexPair = state.selectedPairs.indexOf(pair);

          pair.employee.errors = pair.employee.errors?.length
            ? uniq([...pair.employee.errors, "duplicate"])
            : ["duplicate"];
          state.selectedPairs.splice(indexPair, 1, pair);
        });
        value.employee.errors = value.employee.errors?.length
          ? uniq([...value.employee.errors, "duplicate"])
          : ["duplicate"];
      }
      if (
        isSelectedButDuplicateError(
          state.selectedPairs.map((pair) => pair.employee),
          value.employee
        )
      ) {
        value.employee.index = state.selectedPairs.filter(
          (user) => user.employee.email === value.employee.email
        ).length;
      } else {
        value.employee.index = 0;
      }

      const newArray = [...state.selectedPairs];
      newArray.unshift(value);
      return { ...state, selectedPairs: newArray };
    }
    case "removePair": {
      const { value } = action;
      if (!isPair(value)) {
        return state;
      }

      // Update other duplicates
      if (
        isSelectedButDuplicateError(
          state.selectedPairs.map((pair) => pair.employee),
          value.employee
        )
      ) {
        const others = state.selectedPairs.filter(
          ({ employee }) => employee.email === value.employee.email
        );
        if (others.length - 1 === 1) {
          const toUpdate = others.find((item) => item.employee.index !== value.employee.index);

          const indexToUpdate = state.selectedPairs.indexOf(toUpdate);
          pull(toUpdate.employee.errors, "duplicate");
          state.selectedPairs.splice(indexToUpdate, 1, toUpdate);
        }
      }

      return {
        ...state,
        selectedPairs: [...state.selectedPairs.filter((item) => item.employee !== value.employee)],
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UsersListProvider({ children, content }: CountProviderProps) {
  const [state, dispatch] = useReducer(usersListReducer, {
    selectedUsers: content?.selectedUsers ?? [],
    selectedPairs: content?.selectedPairs ?? [],
  });
  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );
  return <UsersListContext.Provider value={value}>{children}</UsersListContext.Provider>;
}

function useUsersList() {
  const context = useContext(UsersListContext);
  if (context === undefined) {
    throw new Error("useUsersList must be used within a UsersListContext");
  }
  return context;
}

export { UsersListProvider, useUsersList };
