import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRef, useMemo, useState, useContext, useCallback } from "react";

import cx from "classnames";

import type { GridColDef } from "@mui/x-data-grid-pro";

import { Flex, Text } from "@skillup/design-system";
import {
  DSAvatar,
  DSButton,
  DSFilters,
  UserPopover,
  DSProgressBar,
  MaterialIcons,
  DSDropdownItem,
  type FilterRef,
  TypeProgressBar,
  DSDropdownDivider,
  ToggleButtonGroup,
} from "@skillup/ui";

import User from "utils/User";
import DSLayout from "components/DSLayout";
import { useCampaignPermissions } from "services/peopleReview";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import { PeopleReviewViews } from "../../types";
import { SupervisionContext } from "../../contexts";
// TODO: rename to be applide on both the talent grid and the talent matrix
import { useTalentGridFilters } from "../../components/TalentGrid/TalentGrid";
import { PeopleReviewRoutes, BaseUrl as PeopleReviewBaseURL } from "../router";
import { AddEmployeeToCampaignModal } from "../../components/AddEmployeeToCampaignModal/AddEmployeeToCampaignModal";
import { ManagerPreparationWarningModal } from "../../components/ManagerPreparationWarningModal/ManagerPreparationWarningModal";
import {
  TalentGrid,
  TalentMatrix,
  PausedStatus,
  NotStartedStatus,
  ObserversCoordinatorsModal,
} from "../../components";

import styles from "./CampaignSupervision.module.scss";
import peopleRevieWStyles from "../../people-review.module.scss";

type State =
  | { type: "idle" }
  | { type: "launching" }
  | { type: "manager-preparation-warning" }
  | { type: "manage-coordinators-observers" }
  | { type: "add-employee-modal-to-campaign" };

const views: { slug: string; label: string; icon?: JSX.Element }[] = [
  {
    icon: <MaterialIcons.FormatListBulleted />,
    label: "Tableau d'arbitrage",
    slug: PeopleReviewViews.GRID,
  },
  {
    icon: <MaterialIcons.GridOnIcon />,
    label: "Matrice de positionnement",
    slug: PeopleReviewViews.MATRIX,
  },
];

export type DrawerState =
  | { type: "idle" }
  | {
      type: "n-box-not-positionned-cell-selected";
      collaborators: {
        id: string;
        role?: string;
        lastName: string;
        fullName?: string;
        firstName: string;
      }[];
    }
  | {
      type: "n-box-cell-selected";
      xAxis: { scaleLabel: string; optionLabel: string; optionValue: number };
      yAxis: { scaleLabel: string; optionLabel: string; optionValue: number };
      collaborators: {
        id: string;
        role?: string;
        lastName: string;
        fullName?: string;
        firstName: string;
      }[];
    };

export function CampaignSupervision() {
  const history = useHistory();

  const { t } = useTranslation();
  const isDev = User.isSkillupDeveloper();

  const { campaign, campaignMutations, columnsToExport, currentView } =
    useContext(SupervisionContext);
  const permissions = useCampaignPermissions(campaign?.permissions);

  const [state, setState] = useState<State>({ type: "idle" });
  const [drawerState, setDrawerState] = useState<DrawerState>({
    type: "idle",
  });

  const isThereManagerPreparation = useMemo(() => {
    return !!campaign?.managersPreparationEndDate;
  }, [campaign]);

  const usersIDs = useMemo(() => {
    if (!campaign) return { collaborators: [], managers: [] };

    return campaign.reviews.reduce<{
      managers: string[];
      collaborators: string[];
    }>(
      (acc, review) => {
        acc.collaborators.push(review.userID);
        if (review.managerID) {
          acc.managers.push(review.managerID);
        }
        return acc;
      },
      { collaborators: [], managers: [] }
    );
  }, [campaign]);

  const progressData = useMemo(() => {
    if (!campaign) return [];

    const total = campaign.reviews.length;
    const progress: React.ComponentProps<typeof DSProgressBar>["data"] = [];

    if (campaign.progress.arbitrated > 0) {
      progress.push({
        color: "success" as const,
        label: t("peoplereview.campaignSupervision.progress.arbitrated", {
          count: campaign.progress.arbitrated,
          defaultValue: `Arbitrage réalisé pour {{count}} collaborateur${campaign.progress.arbitrated > 1 ? "s" : ""}`,
        }),
        value: (campaign.progress.arbitrated * 100) / total,
      });
    }

    if ("waitingForManagerPrep" in campaign.progress) {
      if (campaign.progress.managerPrepDone > 0) {
        progress.push({
          color: "successLight" as const,
          label: t("peoplereview.campaignSupervision.progress.managerPrepDone", {
            count: campaign.progress.managerPrepDone,
            defaultValue: `Préparation manager réalisée pour {{count}} collaborateur${campaign.progress.managerPrepDone > 1 ? "s" : ""}`,
          }),
          value: (campaign.progress.managerPrepDone * 100) / total,
        });
      }
      if (campaign.progress.waitingForManagerPrep > 0) {
        progress.push({
          color: "transparent" as const,
          label: t("peoplereview.campaignSupervision.progress.waitingForManagerPrep", {
            count: campaign.progress.waitingForManagerPrep,
            defaultValue: `En attente de préparation manager pour {{count}} collaborateur${campaign.progress.waitingForManagerPrep > 1 ? "s" : ""}`,
          }),
          value: (campaign.progress.waitingForManagerPrep * 100) / total,
        });
      }
    } else {
      if (campaign.progress.waitingForArbitration > 0) {
        progress.push({
          color: "transparent" as const,
          label: t("peoplereview.campaignSupervision.progress.waitingForManagerPrep", {
            count: campaign.progress.waitingForArbitration,
            defaultValue: `Arbitrage en attente pour {{count}} collaborateur${campaign.progress.waitingForArbitration > 1 ? "s" : ""}`,
          }),
          value: (campaign.progress.waitingForArbitration * 100) / total,
        });
      }
    }

    return progress;
  }, [t, campaign]);

  const launchCampaign = useCallback(() => {
    setState({ type: "launching" });
    campaignMutations.start();
  }, [campaignMutations]);

  const layouts = useMemo(() => {
    const campaignState = campaign?.state;
    if (!campaignState) return [];

    const cannotExport = !permissions?.export?.isEnabled;

    return [
      {
        dropdownContent: [
          <DSDropdownItem
            disabled
            key="rename-campaign"
            onClick={() => {}}
            label={t("peopleReview.button.renameCampaign", {
              defaultValue: "Renommer la campagne",
            })}
            tooltipLabel={t("peopleReview.button.soonAvailable", {
              defaultValue: "Bientôt disponible",
            })}
          />,
          <DSDropdownItem
            disabled
            key="check-model"
            onClick={() => {}}
            label={t("peopleReview.button.checkModel", {
              defaultValue: "Consulter le modèle utilisé",
            })}
            tooltipLabel={t("peopleReview.button.soonAvailable", {
              defaultValue: "Bientôt disponible",
            })}
          />,
          <DSDropdownDivider />,
          <DSDropdownItem
            key="manage-reviews"
            disabled={campaign.state === "closed"}
            onClick={() => setState({ type: "add-employee-modal-to-campaign" })}
            label={t("peopleReview.button.addReview", {
              defaultValue: "Ajouter des collaborateurs",
            })}
            tooltipLabel={
              campaign.state === "closed" &&
              t("peopleReview.button.addReview.disabledReason", {
                defaultValue: "Impossible d'ajouter des collaborateurs sur une campagne terminée",
              })
            }
          />,
          <DSDropdownItem
            key="manage-coordinators-observers"
            onClick={() => setState({ type: "manage-coordinators-observers" })}
            disabled={
              isDev ? permissions?.["manage-coordinators-observers"]?.isEnabled === false : true
            }
            label={t("peopleReview.button.manageCoordinatorsAndObservers", {
              defaultValue: "Gestion des responsables",
            })}
            tooltipLabel={
              !isDev
                ? t("peopleReview.button.soonAvailable", {
                    defaultValue: "Bientôt disponible",
                  })
                : undefined
            }
          />,
          <DSDropdownItem
            disabled
            key="manage-notifications"
            onClick={() => {}}
            tooltipLabel={t("peopleReview.button.soonAvailable", {
              defaultValue: "Bientôt disponible",
            })}
            label={t("peopleReview.button.manageNotifications", {
              defaultValue: "Paramétrer les notifications",
            })}
          />,
          <DSDropdownDivider />,
          permissions?.export?.isVisible && (
            <DSDropdownItem
              key="export-campaign"
              disabled={cannotExport}
              onClick={() => {
                campaignMutations.export(columnsToExport);
              }}
              label={t("peopleReview.button.exportCampaign", {
                defaultValue: "Exporter la campagne",
              })}
            />
          ),
          permissions?.canSeeCampaignStateButtons && <DSDropdownDivider />,
          permissions?.resume?.isVisible && (
            <DSDropdownItem
              key="resume-campaign"
              disabled={!permissions?.resume?.isEnabled}
              onClick={campaignMutations.resume}
              label={t("peopleReview.button.resumeCampaign", {
                defaultValue: "Reprendre la campagne",
              })}
              tooltipLabel={
                permissions?.resume?.isEnabled === false
                  ? t(permissions?.resume?.reasonKey, {
                      defaultValue:
                        "Vous n'avez pas les droits nécessaires pour reprendre cette campagne.",
                    })
                  : null
              }
            />
          ),
          permissions?.pause?.isVisible && (
            <DSDropdownItem
              key="pause-campaign"
              disabled={!permissions?.pause?.isEnabled}
              onClick={campaignMutations.pause}
              label={t("peopleReview.button.pauseCampaign", {
                defaultValue: "Suspendre la campagne",
              })}
              tooltipLabel={
                permissions?.pause?.isEnabled === false
                  ? t(permissions?.pause?.reasonKey, {
                      defaultValue:
                        "Vous n'avez pas les droits nécessaires pour suspendre cette campagne.",
                    })
                  : null
              }
            />
          ),
          permissions?.close?.isVisible && (
            <DSDropdownItem
              key="close-campaign"
              disabled={!permissions?.close?.isEnabled}
              onClick={campaignMutations.close}
              label={t("peopleReview.button.closeCampaign", {
                defaultValue: "Clôturer la campagne",
              })}
              tooltipLabel={
                permissions?.close?.isEnabled === false
                  ? t(permissions?.close?.reasonKey, {
                      defaultValue:
                        "Vous n'avez pas les droits nécessaires pour clôturer cette campagne.",
                    })
                  : null
              }
            />
          ),
          permissions?.delete?.isVisible && (
            <DSDropdownItem
              key="delete-campaign"
              disabled={!permissions?.delete?.isEnabled}
              label={t("peopleReview.button.deleteCampaign", {
                defaultValue: "Supprimer la campagne",
              })}
              onClick={async () => {
                await campaignMutations.delete();
                history.replace(PeopleReviewRoutes.PPRCampaignsList);
              }}
              tooltipLabel={
                permissions?.delete?.isEnabled === false
                  ? t(permissions?.delete?.reasonKey, {
                      defaultValue:
                        "Vous n'avez pas les droits nécessaires pour supprimer cette campagne.",
                    })
                  : null
              }
            />
          ),
        ],
        primaryButton: permissions?.launch?.isVisible && (
          <DSNewHeaderButton
            loading={state?.type === "launching"}
            disabled={!permissions?.launch?.isEnabled || state?.type === "launching"}
            label={t("peopleReview.button.launchCampaign", {
              defaultValue: "Démarrer la campagne",
            })}
            onClick={() => {
              if (isThereManagerPreparation) {
                setState({ type: "manager-preparation-warning" });
              } else {
                launchCampaign();
              }
            }}
            tooltip={
              permissions?.launch?.isEnabled === false
                ? t(permissions?.launch?.reasonKey, {
                    defaultValue:
                      "Vous n'avez pas les droits nécessaires pour démarrer cette campagne.",
                  })
                : null
            }
          />
        ),
      },
    ];
  }, [
    campaign?.state,
    permissions,
    t,
    isDev,
    campaignMutations,
    state?.type,
    columnsToExport,
    history,
    isThereManagerPreparation,
    launchCampaign,
  ]);

  const filterRef = useRef<FilterRef>();
  const [filters, filterValues, setFilterValues, config] = useTalentGridFilters();

  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  };
  // TODO: commented because we will need this after release
  // const cutSize = Math.ceil(campaign?.reviews.length / 3);
  // const employeesWithArbitrage = cutSize;
  // const employeesWithManagerPrep = cutSize;
  // const employeesWaiting = campaign?.reviews.length - (cutSize + cutSize);

  return (
    <DSLayout
      layouts={layouts}
      className={peopleRevieWStyles.layout}
      title={campaign?.titleForHR || "People Review"}
      parent={{
        onClick: () => {
          history.push(PeopleReviewRoutes.PPRCampaignsList);
        },
        title: "Détails d'une campagne",
      }}
      leftItem={
        <Flex height="37px" alignItems="flex-end">
          {campaign?.state === "not_started" && <NotStartedStatus isDark={false} />}
          {campaign?.state === "paused" && <PausedStatus isDark={false} />}
        </Flex>
      }
      header={
        <Flex paddingTop="xxs" paddingBottom="s" paddingHorizontal="s" className={styles.header}>
          <Flex width="30rem" marginLeft="xl">
            <DSProgressBar
              thin
              border
              layout="segmented"
              data={progressData}
              showLegends={false}
              type={TypeProgressBar.PERCENTAGE}
              disabled={campaign?.state === "closed" || campaign?.state === "paused"}
            />
          </Flex>
        </Flex>
      }
    >
      <Flex width={"100%"} flexDirection="row">
        <Flex
          flex={1}
          padding="s"
          flexDirection="column"
          className={cx(styles.peopleReviewPage, {
            [styles.peopleReviewPageDrawerOpen]: drawerState.type !== "idle",
          })}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <DSFilters
              t={t}
              ref={filterRef}
              config={config}
              filters={filters}
              translationPrefix="peopleReview.talentGrid"
              onChange={setFilterValues}
            />

            <Flex height="34px">
              {isDev && (
                <ToggleButtonGroup
                  value={currentView}
                  className={styles.toggle}
                  options={views.map((view) => ({
                    icon: view.icon,
                    label: view.label,
                    value: view.slug,
                  }))}
                  onChange={() => {
                    const newView =
                      currentView === PeopleReviewViews.GRID
                        ? PeopleReviewViews.MATRIX
                        : PeopleReviewViews.GRID;
                    history.push(`${PeopleReviewBaseURL}/campaigns/${campaign.id}/${newView}`);
                  }}
                />
              )}
            </Flex>
          </Flex>
          {currentView === PeopleReviewViews.GRID && (
            <Flex maxHeight="100%">
              <TalentGrid
                filters={filterValues}
                onColumnFilterClick={handleTableColumnFilterClick}
              />
            </Flex>
          )}
          {currentView === PeopleReviewViews.MATRIX && (
            <Flex height="100%">
              <TalentMatrix
                drawerState={drawerState}
                openDrawer={(params: DrawerState) =>
                  setDrawerState({
                    type: "n-box-cell-selected",
                    ...params,
                  })
                }
              />
            </Flex>
          )}
        </Flex>
        <TalentMatrixDrawer
          campaignID={campaign.id}
          drawerState={drawerState}
          campaignTitle={campaign.titleForHR}
          closeDrawer={() => setDrawerState({ type: "idle" })}
        />
      </Flex>

      {state.type === "manager-preparation-warning" && (
        <ManagerPreparationWarningModal
          onConfirm={launchCampaign}
          onClose={() => setState({ type: "idle" })}
          // TODO: Add real users data here
          usersData={{
            collaborators: usersIDs.collaborators.map((collabUuid) => ({
              uuid: collabUuid,
              fullName: undefined,
            })),
            managersCount: campaign.managerCount,
          }}
        />
      )}
      {state.type === "manage-coordinators-observers" && (
        <ObserversCoordinatorsModal onClose={() => setState({ type: "idle" })} />
      )}
      {state.type === "add-employee-modal-to-campaign" && (
        <AddEmployeeToCampaignModal
          campaignID={campaign.id}
          hasManagers={Boolean(campaign.managerCount > 0 && campaign.managersHelpText)}
          onClose={() => setState({ type: "idle" })}
          onConfirm={() => setState({ type: "idle" })}
        />
      )}
    </DSLayout>
  );
}

type TalentMatrixDrawerProps = {
  campaignID: string;
  campaignTitle: string;
  closeDrawer: () => void;
  drawerState: DrawerState;
};

const TalentMatrixDrawer = ({
  campaignID,
  campaignTitle,
  closeDrawer,
  drawerState,
}: TalentMatrixDrawerProps) => {
  const history = useHistory();

  const content = useMemo(() => {
    switch (drawerState.type) {
      case "n-box-cell-selected":
        return (
          <Flex width={"100%"} flexDirection="column">
            <Flex flexDirection="column">
              <Flex marginBottom="xs" flexDirection="column">
                <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                  {drawerState.xAxis.scaleLabel}
                </Text>
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {drawerState.xAxis.optionLabel}
                </Text>
              </Flex>
              <Flex flexDirection="column">
                <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                  {drawerState.yAxis.scaleLabel}
                </Text>
                <Text espaceFont="body1Regular" color="plainText-onLight-default">
                  {drawerState.yAxis.optionLabel}
                </Text>
              </Flex>
            </Flex>
            <Flex flex={1} marginTop="m" overflowY="auto" flexDirection="column">
              {!drawerState.collaborators.length ? (
                <Text espaceFont="body2Regular" color="plainText-onLight-default">
                  Aucun collaborateur positionné
                </Text>
              ) : (
                drawerState.collaborators.map((collaborator) => (
                  <Flex marginBottom="s" key={collaborator.id}>
                    <UserPopover
                      position="down-right"
                      properties={[collaborator.role]}
                      user={{
                        uuid: collaborator.id,
                        fullName:
                          collaborator.fullName ??
                          `${collaborator.firstName} ${collaborator.lastName}`,
                        initials: "AB",
                      }}
                      profileBtn={{
                        label: "Voir le profil",
                        onClick: () =>
                          history.push(
                            `/responsable/collaborateurs/${collaborator.id}/career?previousPageURI=/responsable/people-review/campaigns/${campaignID}/matrix&previousPageTitle=${campaignTitle}`
                          ),
                      }}
                    >
                      <Flex alignItems="center" justifyContent="center">
                        <DSAvatar
                          darkMode
                          size="S"
                          type="Initials"
                          initials={"AB"}
                          imageUrl={undefined}
                          randomColorSeedString={collaborator.id}
                        />
                        <Text marginLeft="xs" color="plainText-onLight-default">
                          {collaborator.fullName ??
                            `${collaborator.firstName} ${collaborator.lastName}`}
                        </Text>
                      </Flex>
                    </UserPopover>
                  </Flex>
                ))
              )}
            </Flex>
            <Flex marginTop="xs">
              <DSButton buttonSize="S" emphasis="Mid" label="Voir dans la vue d’arbitrage" />
            </Flex>
          </Flex>
        );
      case "n-box-not-positionned-cell-selected":
        return (
          <Flex width={"100%"} flexDirection="column">
            <Flex flexDirection="column">
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                Collaborateurs non positionnés
              </Text>
            </Flex>
            <Flex flex={1} marginTop="m" overflowY="auto" flexDirection="column">
              {!drawerState.collaborators.length ? (
                <Text espaceFont="body2Regular" color="plainText-onLight-default">
                  Aucun collaborateur non positionné
                </Text>
              ) : (
                drawerState.collaborators.map((collaborator) => (
                  <Flex marginBottom="s" key={collaborator.id}>
                    <UserPopover
                      position="down-right"
                      properties={[collaborator.role]}
                      user={{
                        uuid: collaborator.id,
                        fullName:
                          collaborator.fullName ??
                          `${collaborator.firstName} ${collaborator.lastName}`,
                        initials: "AB",
                      }}
                      profileBtn={{
                        label: "Voir le profil",
                        onClick: () =>
                          history.push(
                            `/responsable/collaborateurs/${collaborator.id}/career?previousPageURI=/responsable/people-review/campaigns/${campaignID}/matrix&previousPageTitle=${campaignTitle}`
                          ),
                      }}
                    >
                      <Flex alignItems="center" justifyContent="center">
                        <DSAvatar
                          darkMode
                          size="S"
                          type="Initials"
                          initials={"AB"}
                          imageUrl={undefined}
                          randomColorSeedString={collaborator.id}
                        />
                        <Text marginLeft="xs" color="plainText-onLight-default">
                          {collaborator.fullName ??
                            `${collaborator.firstName} ${collaborator.lastName}`}
                        </Text>
                      </Flex>
                    </UserPopover>
                  </Flex>
                ))
              )}
            </Flex>
            <Flex marginTop="xs">
              <DSButton buttonSize="S" emphasis="Mid" label="Voir dans la vue d’arbitrage" />
            </Flex>
          </Flex>
        );
      default:
        return <></>;
    }
  }, [drawerState, campaignID, campaignTitle, history]);

  if (drawerState.type === "idle") return <></>;

  return (
    <Flex padding="s" flexDirection="column" className={styles.talentMatrixDrawer}>
      <Flex padding="s" width={"100%"} className={styles.talentMatrixDrawerContainer}>
        <DSButton
          iconOnly
          label="close"
          icon={<MaterialIcons.Close />}
          className={styles.talentMatrixDrawerCloseButton}
          onClick={closeDrawer}
        />
        {content}
      </Flex>
    </Flex>
  );
};
