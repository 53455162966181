import { useQuery } from "@tanstack/react-query";

import { TargetCategoriesRoutes } from "@skillup/espace-rh-bridge";
import { DataTable } from "@skillup/ui";

import { buildRequest } from "utils/buildRequest";

import useTableDataLegacy, { type LegacyCategoryRow } from "../hooks/useTableDataLegacy";

async function getLegacyTargetCategories() {
  try {
    return buildRequest<TargetCategoriesRoutes.GetCategoriesLegacy>({
      method: "GET",
      path: "/targetCategoriesFromAppTargets",
    })();
  } catch (err) {}
  return [];
}

export const TargetCategoriesLegacyDataTable = () => {
  const { data: legacyTargetCategories } = useQuery(
    ["legacyTargetCategories"],
    getLegacyTargetCategories,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { columns: legacyColumns, data: legacyTableData } =
    useTableDataLegacy(legacyTargetCategories);

  return (
    <DataTable<LegacyCategoryRow>
      columns={legacyColumns}
      rows={legacyTableData}
      defaultSort={{ key: "title", desc: false }}
      header={{
        totalRowsLabel: (total) => `${total} catégories`,
      }}
      pagination={{
        rowsPerPageLabel: "Lignes par page",
        itemsCountLabel: "Objets %range% sur %count%",
        pageLabel: "Page",
      }}
      mode="compact"
    />
  );
};
