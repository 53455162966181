import React from "react";
import InteractiveButton from "components/InteractiveButton";

import Acta from "utils/Acta";

import Colors from "uiAssets/Colors";
import { trashBin as trashBinIcon, cross as crossIcon } from "uiAssets/StrokeIcons";

export interface IProps {
  onDelete: () => void;
}

export default class DeleteFileModal extends React.PureComponent<IProps, {}> {
  public render(): JSX.Element {
    return (
      <div style={{ textAlign: "center" }}>
        <p
          style={{
            color: "#333",
            padding: "20px 20px 0",
          }}
        >
          Attention, cette action est irréversible.
        </p>
        <InteractiveButton
          background={Colors.gradientRed}
          color="#fff"
          label="supprimer"
          onClick={() => this.props.onDelete()}
          strokeIconLeft={trashBinIcon}
          style={{ margin: 0, display: "inline-block" }}
        />
        <InteractiveButton
          label="annuler"
          onClick={() => Acta.dispatchEvent("closeModal")}
          strokeIconLeft={crossIcon}
          style={{ margin: 20, display: "inline-block" }}
        />
      </div>
    );
  }
}
