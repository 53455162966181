import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useQuery } from "@tanstack/react-query";
import Fuse from "fuse.js";

import { getHRs } from "./hrs/getHRs";

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};

const defaultOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
};

export function useHRs(options: Options = defaultOptions) {
  const { data, isLoading, isError } = useQuery(["hrs"], getHRs, {
    ...defaultOptions,
    ...options,
  });

  const [hrs, setHrs] = useState<typeof data>(data ?? []);

  useEffect(() => {
    if (data) {
      setHrs(data);
    }
  }, [data]);

  const search = useCallback(
    async (search: string) => {
      if (isEmpty(search)) {
        setHrs(data);
      } else {
        const fuse = new Fuse(data, {
          keys: ["properties.fullName", "properties.email"],
          threshold: 0.333,
          shouldSort: true,
          includeScore: true,
        });
        setHrs(fuse.search(search)?.map((result) => result.item));
      }
    },
    [data]
  );

  return {
    hrs,
    isLoading,
    isError,
    mutations: {
      search,
    },
  };
}
