import { Switch, Route } from "react-router-dom";

import AuthAsUser from "containers/Login/AuthAsUserView";
import CatalogRoot from "containers/CatalogRoot";
import { SupervisorView } from "containers/Supervisor";
import Redirect from "containers/Redirect";
import TrainingInscription from "containers/TrainingInscription";
import TrainingPage from "containers/TrainingPage";
import TrainingCategory from "containers/TrainingCategory";
import TrainingsResults from "containers/TrainingsResults";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/connexion-en-tant-que" component={AuthAsUser} />

      <Route path="/responsable" component={SupervisorView} />

      <Route exact path="/entreprise/inscription/:rowUuid" component={TrainingInscription} />

      <Route exact path="/catalogue/:slug?" component={CatalogRoot} />
      <Route exact path="/formation-:slug" component={TrainingPage} />
      <Route exact path="/formations-:slug" component={TrainingCategory} />

      <Route exact path="/recherche/:query" component={TrainingsResults} />
      <Route exact path="/uuid/:uuid" component={Redirect} />
    </Switch>
  );
};

export default Routes;
