import get from "lodash/get";
import set from "lodash/set";
import isUndefined from "lodash/isUndefined";

import DataLayer, { ResponseValue, TargetType } from "utils/DataLayer";
import { BasicRouteType } from "types/route";
import { buildURL } from "./buildRequest";

type MimeType =
  | "application/octet-stream"
  | "application/pdf"
  | "application/zip"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

type DownloadOptionsDeduced = {
  target?: TargetType;
  deduceFileDataFromResponseHeaders: true;
};

type DownloadOptionsSpecified = {
  target?: TargetType;
  filename: string;
  mimeType: MimeType;
  deduceFileDataFromResponseHeaders?: boolean;
};

type DownloadOptions = DownloadOptionsDeduced | DownloadOptionsSpecified;

/**
 * Careful, the default target is PORTAL, not API
 */
function downloadTypedFileAsUser<Route extends BasicRouteType>(
  { params, payload, path, method, query }: Omit<Route, "response">,
  options: DownloadOptions
): Promise<void> {
  const url = buildURL({ params, path, query, target: options.target });

  return new Promise((resolve, reject) =>
    DataLayer.request<Route>({
      method,
      responseType: "arraybuffer",
      responseValue: ResponseValue.RAW_RESPONSE,
      target: options.target ?? "PORTAL",
      body: JSON.stringify(payload),
      url,
    })
      .then((request: any) => {
        const data = request.response;
        const contentDisposition = request.getResponseHeader("content-disposition");
        const responseFileName =
          options.deduceFileDataFromResponseHeaders === true
            ? decodeURIComponent(contentDisposition?.match(/filename="(.*?)"/)?.[1] ?? "")
            : options.filename;
        const responseMimeType =
          options.deduceFileDataFromResponseHeaders === true
            ? request.getResponseHeader("content-type")
            : options.mimeType;

        const blob =
          typeof File === "function"
            ? new File([data], responseFileName, {
                type: responseMimeType ?? "application/octet-stream",
              })
            : new Blob([data], { type: responseMimeType ?? "application/octet-stream" });

        // @ts-ignore msSaveBlob has been removed from type defintion as W3C deprecated it. This is required for IE11 support
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // @ts-ignore msSaveBlob has been removed from type defintion as W3C deprecated it. This is required for IE11 support
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const URL = get(window, "URL", get(window, "webkitURL"));

          if (isUndefined(URL.createObjectURL)) {
            throw new ReferenceError("createObjectURL is not defined");
          }

          const href = URL.createObjectURL(blob);
          const tmp = document.createElement("a");

          if (isUndefined(tmp.download)) {
            set(window, "location", href);
          } else {
            set(tmp, "href", href);
            set(tmp, "download", responseFileName);

            document.body.appendChild(tmp);
            tmp.click();
            document.body.removeChild(tmp);
          }

          setTimeout(() => {
            URL.revokeObjectURL(href);
            resolve();
          }, 100);
        }
      })
      .catch(reject)
  );
}

export default downloadTypedFileAsUser;
