import { v4 as uuid } from "uuid";
import { PayloadAction } from "@reduxjs/toolkit";

import { FromIndexes } from "../addBlock";
import { BuilderStructure, Page } from "../../../types";
import { ActionHelper } from "../utils";

export type AddSectionAction = {
  uuid: string;
  from: FromIndexes;
  blockType: "section";
};

export const addSection = (
  state: BuilderStructure,
  action: PayloadAction<AddSectionAction>
): BuilderStructure => {
  if (
    ActionHelper.comesFromChild(action) &&
    state.sections[action.payload.from.sectionIndex] &&
    state.sections[action.payload.from.sectionIndex].pages[action.payload.from.pageIndex]
  ) {
    const children = state.sections[action.payload.from.sectionIndex].pages[
      action.payload.from.pageIndex
    ].children.splice(action.payload.from.childIndex + 1);

    // delete pages at from.pageIndex to put them in the new sections
    const restPages = state.sections[action.payload.from.sectionIndex].pages.splice(
      action.payload.from.pageIndex + 1
    );
    const pages: Page[] = [];
    if (children.length || restPages?.length === 0) {
      pages.push({
        uuid: uuid(),
        title: "Nouvelle sous-section",
        children,
        // if the new page is the only one in section, hide it in builder
        hideInBuilder: restPages?.length === 0 ? true : undefined,
      });
    }
    pages.push(...restPages);

    state.sections.splice(action.payload.from.sectionIndex + 1, 0, {
      uuid: action.payload.uuid,
      title: "Nouvelle section",
      pages,
    });

    return state;
  }

  if (ActionHelper.comesFromPage(action) && state.sections[action.payload.from.sectionIndex]) {
    const children =
      state.sections[action.payload.from.sectionIndex].pages[
        action.payload.from.pageIndex
      ].children.splice(0);
    // delete pages at from.pageIndex to put them in the new sections
    const restPages = state.sections[action.payload.from.sectionIndex].pages.splice(
      action.payload.from.pageIndex + 1
    );
    const pages: Page[] = [];
    if (children.length || restPages?.length === 0) {
      pages.push({
        uuid: uuid(),
        title: "Nouvelle sous-section",
        children,
        // if the new page is the only one in section, hide it in builder
        hideInBuilder: restPages?.length === 0 ? true : undefined,
      });
    }
    pages.push(...restPages);

    state.sections.splice(action.payload.from.sectionIndex + 1, 0, {
      uuid: action.payload.uuid,
      title: "Nouvelle section",
      pages,
    });
    return state;
  }

  if (ActionHelper.comesFromSection(action)) {
    state.sections.splice(action.payload.from.sectionIndex, 0, {
      uuid: state.sections[action.payload.from.sectionIndex].uuid,
      title: state.sections[action.payload.from.sectionIndex].title,
      pages: [
        {
          uuid: uuid(),
          title: "Nouvelle sous-section",
          children: [],
          // for new empty section, we create one page and hide it in builder
          hideInBuilder: true,
        },
      ],
    });

    state.sections[action.payload.from.sectionIndex + 1].title = "Nouvelle section";
    state.sections[action.payload.from.sectionIndex + 1].uuid = action.payload.uuid;
    return state;
  }

  throw new Error();
};
