import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@skillup/hooks";
import { stringify } from "qs";
import Icon from "components/Icon";
import { cross as crossIcon } from "uiAssets/StrokeIcons";

import history from "utils/history";

import styles from "./FiltersSummary.module.scss";

function Filter({ id, value, pathname, query, columns }) {
  const { [id]: currentFilter, ...rest } = query;

  function removeFilter(filter = {}) {
    const withQuery = stringify({ ...filter, ...rest }, { addQueryPrefix: true });

    history.push(`${pathname}${withQuery}`);
  }

  const filterName = useMemo(() => {
    const { Header } = columns.find((column) => (column.id ?? column.accessor) === id) ?? {};

    return Header;
  }, [columns, id]);

  return value.map((val) => (
    <div className={styles.Sorter} role="option" aria-selected="false" key={val}>
      <span>{filterName}</span>
      <span> contient "{val}"</span>
      <button
        onClick={(_) => removeFilter({ [id]: value.filter((v) => v !== val) })}
        className={styles.removeButton}
      >
        <Icon stroke="#fff" strokeIcon={crossIcon} width={5} />
      </button>
    </div>
  ));
}

function Sorter({ columns, id, value, pathname, query }) {
  function removeSorter() {
    const {
      sortBy: { [id]: currentFilter, ...restSortBy },
      ...rest
    } = query;
    const withQuery = stringify(
      {
        ...rest,
        sortBy: restSortBy,
      },
      { addQueryPrefix: true }
    );

    history.push(`${pathname}${withQuery}`);
  }

  const sorterName = useMemo(() => {
    const { Header } = columns.find((column) => (column.id ?? column.accessor) === id) ?? {};

    return Header;
  }, [columns, id]);

  return (
    <div className={styles.Sorter} role="option" aria-selected="false">
      <span>{sorterName}</span>
      <span> {value === "true" ? "(desc)" : "(asc)"}</span>
      <button onClick={removeSorter} className={styles.removeButton}>
        <Icon stroke="#fff" strokeIcon={crossIcon} width={5} />
      </button>
    </div>
  );
}
export default ({ columns }) => {
  const { pathname } = useLocation();
  const query = useQuery<{ T: string; sortBy: { T: string } }>();
  const { sortBy, ...filters } = query;
  const allowedFilters = useMemo(
    () => columns.map((column) => column.id ?? column.accessor),
    [columns]
  );

  const selectedFilters = Object.entries(filters ?? {})
    .filter(([id]) => allowedFilters.includes(id))
    .map(([id, value]) => (
      <Filter
        key={value}
        id={id}
        value={value}
        pathname={pathname}
        query={query}
        columns={columns}
      />
    ));

  const selectedSorters = Object.entries(sortBy ?? {}).map(([id, value]) => (
    <Sorter
      key={value + id}
      id={id}
      value={value}
      pathname={pathname}
      query={query}
      columns={columns}
    />
  ));

  return selectedFilters.length || selectedSorters.length ? (
    <div className={styles.FiltersSummary}>
      {selectedFilters.length ? (
        <div data-testid="filters-container" className={styles.subContainer}>
          <span>Filtrer par:</span> {selectedFilters}
        </div>
      ) : null}
      {selectedSorters.length ? (
        <div data-testid="sorters-container" className={styles.subContainer}>
          <span>Trier par:</span> {selectedSorters}
        </div>
      ) : null}
    </div>
  ) : null;
};
