import { useRecoilState } from "recoil";
import { v4 as uuid } from "uuid";

import { templateState, selectedPageDataState } from "./store";
import { Child } from "./types";

type Question = Child & { kind: "question" };
type Disallow = Question["disallow"];
type Targets = Child & { kind: "targets" };
type TargetsDisallow = Targets["disallow"];
// TODO see if this is needed type GlobalTargetsCompletion = Child & { kind: "globalTargetsCompletion" };

// a little function to help us with reordering stuff
export const reorder = function <T>(list: T[], startIndex, endIndex): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const FullDisallow: Disallow = {
  employee: {
    comment: true,
    reply: true,
  },
  manager: {
    comment: true,
    reply: true,
  },
};

const FullCommentDisallow = {
  employee: {
    comment: true,
  },
  manager: {
    comment: true,
  },
  observer: {
    comment: true,
  },
};

const FullTargetsDisallow: TargetsDisallow = {
  employee: {
    comment: true,
    commentTarget: true,
    creation: true,
    deletion: true,
    edition: true,
    evaluation: true,
  },
  manager: {
    comment: true,
    commentTarget: true,
    creation: true,
    deletion: true,
    edition: true,
    evaluation: true,
  },
};

export const generatorToChildMapping = {
  title: { source: /générateur titre/i, generatedChild: "Paramétrage block title" },
  table: { source: /générateur tableau/i, generatedChild: "Paramétrage block table" },
  guidelines: { source: /générateur guidelines/i, generatedChild: "Paramétrage block guidelines" },
  question_text: {
    source: /générateur question texte/i,
    generatedChild: "Paramétrage block question",
  },
  question_radio: {
    source: /générateur question radio/i,
    generatedChild: "Paramétrage block question",
  },
  question_checkbox: {
    source: /générateur question choix multiples/i,
    generatedChild: "Paramétrage block question",
  },
  html: { source: /générateur html/i, generatedChild: "Paramétrage block html" },
  targets: { source: /générateur objectifs/i, generatedChild: "Paramétrage block targets" },
  trainingsHistory: {
    source: /générateur historique de formation/i,
    generatedChild: "Paramétrage block trainingsHistory",
  },
  interviewsHistory: {
    source: /générateur historique d'entretiens/i,
    generatedChild: "Paramétrage block interviewsHistory",
  },
  skills: {
    source: /générateur évaluation de compétences/i,
    generatedChild: "Paramétrage block skills",
  },
};

/** Drag & drop configuration */
const DragnDrop = () => {
  const [template, setTemplate] = useRecoilState(templateState);
  const [selectedPageData, setSelectedPageData] = useRecoilState(selectedPageDataState);

  return {
    handleChildDropInPage: (result) => {
      if (result?.destination?.droppableId !== "selectedPage") {
        return;
      }

      const { source, destination } = result;

      setSelectedPageData({
        ...selectedPageData,
        children: reorder(selectedPageData.children, source.index, destination.index),
      });
    },
    handlePageDrop: (result) => {},
    handleSectionDrop: (result) => {
      const { source, destination } = result;

      if (destination.droppableId === "sectionsList") {
        const updatedSectionsList = reorder(template.sections, source.index, destination.index);

        setTemplate({
          ...template,
          sections: updatedSectionsList,
        });
      }
    },
    handleChildTemplateDropInBuilder: (result) => {
      if (result?.destination?.droppableId !== "selectedPage") {
        return;
      }
      const childKind: Child["kind"] = result.draggableId.split("_")[1];
      let newChildData: Child = undefined;
      switch (childKind) {
        case "guidelines":
          newChildData = {
            kind: "guidelines",
            uuid: uuid(),
          };
          break;
        case "personnalisableGuidelines":
          newChildData = {
            kind: "personnalisableGuidelines",
            employee: {
              title: "Collaborateur",
              fields: [
                {
                  type: "field",
                  label: "Prénom et nom :",
                  fieldName: "fullName",
                  fieldType: "string" as const,
                },
                {
                  type: "field",
                  label: "Fonction :",
                  fieldName: "role",
                  fieldType: "string" as const,
                },
                {
                  type: "field",
                  label: "Date d’embauche :",
                  fieldName: "joinDate",
                  fieldType: "date" as const,
                },
                {
                  type: "field",
                  label: "Poste à l'entrée dans l'entreprise :",
                  fieldName: "joinRole",
                  fieldType: "string" as const,
                },
                {
                  type: "field",
                  label: "Date d’entrée sur le poste actuel :",
                  fieldName: "roleDate",
                  fieldType: "date" as const,
                },
              ],
            },
            manager: {
              title: "Responsable de l'entretien",
              fields: [
                {
                  type: "field",
                  label: "Prénom et nom :",
                  fieldName: "fullName",
                  fieldType: "string" as const,
                },
                {
                  type: "field",
                  label: "Fonction :",
                  fieldName: "role",
                  fieldType: "string" as const,
                },
                {
                  type: "const",
                  label: "Lien hiérarchique :",
                  value: "Manager",
                },
              ],
            },
            uuid: uuid(),
          };
          break;
        case "title":
          newChildData = {
            kind: "title",
            uuid: uuid(),
            variant: "H1",
            title: "Titre",
          };
          break;
        case "html":
          newChildData = {
            kind: "html",
            uuid: uuid(),
            variant: "info",
            content: "<p>Block HTML</p>",
          };
          break;
        case "trainingsHistory":
          newChildData = {
            kind: "trainingsHistory",
            uuid: uuid(),
            ageUnit: "years",
            maxAge: 3,
            disallow: FullCommentDisallow,
          };
          break;
        case "interviewsHistory":
          newChildData = {
            kind: "interviewsHistory",
            uuid: uuid(),
            ageUnit: "years",
            maxAge: 3,
            disallow: {
              employee: {
                comment: true,
                downloadPDF: true,
              },
              manager: {
                comment: true,
                downloadPDF: true,
              },
            },
            types: [],
          };
          break;
        case "targets":
          newChildData = {
            kind: "targets",
            uuid: uuid(),
            type: "next",
            hidePeriod: true,
            hideWeight: true,
            restrictScaleTo: undefined,
            disallow: FullTargetsDisallow,
          };
          break;
        case "globalTargetsCompletion":
          newChildData = {
            kind: "globalTargetsCompletion",
            uuid: uuid(),
            forcePercentWeight: true,
            required: false,
            disallow: FullCommentDisallow,
          };
          break;
        case "table":
          newChildData = {
            kind: "table",
            columns: [],
            rowsCount: 1,
            disallow: FullCommentDisallow,
            uuid: uuid(),
          };
          break;
        case "question":
          const type = result.draggableId.split("_")[2];
          if (type === "text") {
            newChildData = {
              kind: "question",
              type: "text",
              label: "question label",
              uuid: uuid(),
              required: false,
              disallow: FullDisallow,
            };
          } else if (type === "checkbox") {
            newChildData = {
              kind: "question",
              type: "checkbox",
              choices: [{ label: "Choix 1" }],
              label: "question label",
              uuid: uuid(),
              required: false,
              disallow: FullDisallow,
            };
          } else if (type === "radio") {
            newChildData = {
              kind: "question",
              type: "radio",
              choices: [{ label: "Choix 1" }],
              label: "question label",
              uuid: uuid(),
              required: false,
              disallow: FullDisallow,
            };
          } else {
            alert("This question type is not handled yet");
          }
          break;
        case "trainingsCollection": {
          newChildData = {
            kind: "trainingsCollection",
            uuid: uuid(),
            disallow: {
              employee: {
                comment: true,
                requestTraining: true,
              },
              manager: {
                comment: true,
                requestTraining: true,
              },
            },
          };
          break;
        }
        case "upload": {
          newChildData = {
            kind: "upload",
            uuid: uuid(),
            disallow: {
              employee: {
                upload: true,
                comment: true,
              },
              manager: {
                upload: true,
                comment: true,
              },
            },
          };
          break;
        }
        case "skills": {
          newChildData = {
            kind: "skills",
            uuid: uuid(),
            disallow: {
              employee: {
                evaluation: true,
                comment: true,
                commentEvaluation: true,
              },
              manager: {
                evaluation: false,
                comment: true,
                commentEvaluation: true,
              },
            },
          };
          break;
        }
        case "userSpecificData": {
          newChildData = {
            kind: "userSpecificData",
            uuid: uuid(),
            userSpecificDataKey: "",
          };
          break;
        }
        case "job": {
          newChildData = {
            kind: "job",
            uuid: uuid(),
            disallow: {
              employee: {
                comment: true,
              },
              manager: {
                comment: true,
              },
            },
          };
          break;
        }
        default:
          alert("unhandled child kind");
          break;
      }

      if (newChildData) {
        const { children } = selectedPageData;

        const head = children.slice(0, result?.destination?.index);
        const tail = children.slice(result?.destination?.index, children.length);

        const newChildren = [...head, newChildData, ...tail];

        setSelectedPageData({
          ...selectedPageData,
          children: newChildren,
        });
      }
    },
  };
};

export default DragnDrop;
