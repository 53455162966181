import { ScheduleTab } from "@skillup/entities-provider";
import type { ReloadRowsDataRoute } from "types/api";
import { buildRequest } from "utils/buildRequest";

export type TTab = ReloadRowsDataRoute["payload"]["tab"];

/**
 * This function injects the table with new data:
 * It receives two lists from the API:
 * - updatedRows: the rows that have been updated but are still in the table
 * - movedRows: the rows that have been moved to another tab.
 */
const addRows = (context: any) => async (rowsUuids: string[]) => {
  /**
   * This is quite an old pattern: context is actually the parent component.
   * We need to access the parent component's props to get the current tab (from the URI)
   */
  const {
    match: { params },
  } = context.props;

  /** We get the newly added rows' data from the API */
  const tab: TTab = params.tab;
  const scheduleUuid: string = params.uuid;

  const data = await pureAddRows(tab, scheduleUuid, rowsUuids);

  const addedRows = data.updatedRows;

  const addedRowsUuids = addedRows.map((row) => row.uuid);
  const newRows = [...addedRows, ...context.state.rows];

  const newSelectedRows = addedRowsUuids;

  context.setState(
    {
      rows: newRows,
      selectedRows: newSelectedRows,
      reloadingRows: [newSelectedRows],
    },
    () => {
      // After a few milliseconds we remove the loading effect.
      setTimeout(() => {
        context.setState({ reloadingRows: [] });
      }, 300);
    }
  );
};

export const pureAddRows = async (tab: ScheduleTab, scheduleUuid: string, rowsUuids: string[]) => {
  const request = await buildRequest<ReloadRowsDataRoute>({
    method: "POST",
    path: "/schedule/{scheduleUuid}/rows",
    params: { scheduleUuid },
    payload: {
      rowsUuids,
      tab,
    },
  });

  return request();
};

export default addRows;
