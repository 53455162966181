import React from "react";

import useAreas from "hooks/useAreas";

import SupervisorTrainingSchedule from "./SupervisorTrainingSchedule";

export default () => {
  const areas = useAreas();

  return <SupervisorTrainingSchedule areas={areas} />;
};
