import { useCallback, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";

import { DSButton, Label as DSLabel, Select, DSTooltip, MaterialIcons } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import type { CreationTargetCategory } from "@skillup/espace-rh-bridge";

import { useTemplates, type Template } from "services/interviews";

import styles from "./TargetSettingsModal.module.scss";

type CreationTargetCategoryWithId = CreationTargetCategory & { id: string };

export const TargetsCategoriesSettings = ({
  selectedCategories,
  addCategory,
  removeCategory,
  handleChangeCategories,
  usedCategories,
}: {
  selectedCategories: CreationTargetCategoryWithId[];
  addCategory: () => void;
  removeCategory: (id: string) => void;
  handleChangeCategories: (value: CreationTargetCategory | string, index: number) => void;
  usedCategories: string[];
}) => {
  const { existingCategories } = useTemplates();
  const options: { label: string; value: CreationTargetCategory }[] = useMemo(
    () =>
      existingCategories.reduce((acc, category) => {
        if (
          category &&
          !category.isArchived &&
          // have it match category.uuid when selectedCategories in useCategories lists the uuids
          !selectedCategories.map((c) => c.label).includes(category.label)
        ) {
          acc.push({
            value: { uuid: category.uuid, label: category.label },
            label: category.label,
          });
        }
        return acc;
      }, []),
    [existingCategories, selectedCategories]
  );

  return (
    <Flex flexDirection="column">
      <DSLabel className={styles.label} label="Catégories d'objectifs" />
      <Flex flexDirection="column" gap="xxs">
        {selectedCategories.length ? (
          selectedCategories.map((category, index) => {
            const disabled = usedCategories?.includes(category.label);
            const tooltipLabel =
              "Cette catégorie ne peut être ni modifiée ni supprimée car elle est utilisée dans cette trame.";
            return (
              <DSTooltip
                label={tooltipLabel}
                direction={"top"}
                disabled={!disabled}
                key={category.id}
              >
                <Flex flexDirection="row" gap="s" paddingRight="xs">
                  <Select
                    disabled={disabled}
                    defaultValue={{
                      label: category.label,
                      value: { uuid: category?.uuid, label: category.label },
                    }}
                    options={options}
                    onChange={(e: CreationTargetCategory | string) =>
                      handleChangeCategories(e, index)
                    }
                    createIfInexistent
                    createLabel="Créer "
                    className={styles.select}
                  />
                  <DSButton
                    onClick={() => removeCategory(category.id)}
                    iconOnly
                    icon={<MaterialIcons.Delete />}
                    buttonSize="S"
                    emphasis="Low"
                    disabled={disabled}
                  />
                </Flex>
              </DSTooltip>
            );
          })
        ) : (
          <p className={styles.noItem}>Aucune catégorie définie</p>
        )}
      </Flex>
      <DSButton
        label={"Ajouter une catégorie"}
        className={styles.addButton}
        onClick={() => addCategory()}
        buttonSize="S"
        emphasis="Low"
      />
    </Flex>
  );
};

export function useCategories(template: Template) {
  const [selectedCategories, setSelectedCategories] = useState<CreationTargetCategoryWithId[]>(
    template.targetCategories && template.targetCategories.length
      ? template.targetCategories
          .map((category) => ({
            id: uuid(),
            uuid: category.uuid,
            label: category.label,
          }))
          .toReversed()
      : // [TCY-1] retrocompatibility
      template.targetsCategories
      ? template.targetsCategories.map((category) => ({
          id: uuid(),
          label: category,
        }))
      : []
  );

  const addCategory = useCallback(() => {
    setSelectedCategories((prev) => [
      ...prev,
      {
        id: uuid(),
        label: "",
      },
    ]);
  }, [setSelectedCategories]);

  const handleChangeCategories = useCallback(
    (value: CreationTargetCategory | string, index: number) => {
      const newCategories = [...selectedCategories];
      newCategories[index] = {
        id: selectedCategories[index].id,
        uuid: typeof value === "string" ? undefined : value.uuid,
        label: typeof value === "string" ? value : value.label,
      };
      setSelectedCategories(newCategories);
    },
    [selectedCategories, setSelectedCategories]
  );

  const removeCategory = useCallback(
    (id: string) => {
      setSelectedCategories((prev) => prev.filter((category) => category.id !== id));
    },
    [setSelectedCategories]
  );

  return {
    selectedCategories,
    addCategory,
    handleChangeCategories,
    removeCategory,
  };
}
