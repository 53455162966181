import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import {
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  DSFormGroupTextInput,
  DSModal,
  DSTextInput,
} from "@skillup/ui";

import styles from "./CancelModal.module.scss";
import { useToggle } from "react-use";
import { buildRequest } from "utils/buildRequest";
import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";

interface Props {
  rowsUuids: Array<string>;
  onBack?: () => void;
  onClose: (needRefresh?: boolean) => void;
  hasReceivedNotification: boolean;
  type?: "deny" | "cancel";
}

const CancelModal = ({ rowsUuids, type, onBack, onClose, hasReceivedNotification }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [loading, toggleLoading] = useToggle(false);
  const [comment, setComment] = useState<string>(null);

  const handleSubmit = useCallback(async (): Promise<void> => {
    toggleLoading(true);

    try {
      const { cancelledRows } = await buildRequest<ScheduleRowRoutes.CancelRow>({
        method: "POST",
        path: "/scheduleRow/cancel",
        payload: {
          rows: rowsUuids,
          comment,
        },
      })();

      if (cancelledRows.length === rowsUuids.length) {
        addToast(
          t("trainings.schedules.cancel.success", { defaultValue: "Demande annulée avec succès." }),
          {
            appearance: "success",
          }
        );

        onClose(true);
      }
    } catch (error) {
      addToast(t("trainings.schedules.cancel.error", { defaultValue: "La demande a échoué." }), {
        appearance: "error",
      });
    } finally {
      toggleLoading(false);
    }
  }, [toggleLoading, onClose, comment, rowsUuids, addToast, t]);

  const isDenied = type === "deny";

  return (
    <DSModal isOpen className={styles.CancelModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t(`trainings.schedules.${type}.title`, {
            count: rowsUuids.length,
            defaultValue: `${isDenied ? "Refuser" : "Annuler"} la demande`,
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <p>
          {t(`trainings.schedules.${type}.description`, {
            count: rowsUuids.length,
            defaultValue: `Si vous confirmez cette action, la demande passera dans l'onglet "${
              isDenied ? "Refusées" : "Annulées"
            }".`,
          })}
        </p>
        {hasReceivedNotification && (
          <DSAlert display={DSAlertDisplay.INLINE} type={DSAlertType.INFO}>
            {t(`trainings.schedules.cancel.alert.info`, {
              defaultValue:
                "Le collaborateur recevra une notification.\nSi une invitation agenda avait été incluse dans la convocation initiale, elle sera supprimée.",
            })}
          </DSAlert>
        )}
        {!hasReceivedNotification && (
          <DSAlert display={DSAlertDisplay.INLINE} type={DSAlertType.WARNING}>
            {t(`trainings.schedules.${type}.alert.info`, {
              count: rowsUuids.length,
              defaultValue: `Le collaborateur ne sera pas notifié ${
                isDenied ? "du refus" : "de l'annulation"
              }.`,
            })}
          </DSAlert>
        )}
        <form onSubmit={(e) => e.preventDefault()}>
          <DSFormGroupTextInput
            label={t("trainings.schedules.cancel.comment.label", {
              defaultValue: "Commentaire (facultatif)",
            })}
            assistiveText={t("trainings.schedules.cancel.comment.assistiveText", {
              defaultValue: `Le commentaire ci-dessus ne sera pas transmis au collaborateur ${
                isDenied ? "ni à son manager" : ""
              } mais figurera dans l'activité de la ligne`,
            })}
          >
            <DSTextInput
              autoFocus={true}
              placeholder={t("trainings.schedules.cancel.comment.placeholder", {
                defaultValue: "Laissez un commentaire dans votre activité",
              })}
              onChange={(value) => setComment(value)}
              name="message"
            />
          </DSFormGroupTextInput>
        </form>
      </DSModal.Content>
      <DSModal.Footer>
        {!!onBack && (
          <DSModal.Footer.CancelButton
            disabled={loading}
            onClick={onBack}
            label={t("app.modal.action.back", {
              defaultValue: "Retour",
            })}
          />
        )}
        <DSModal.Footer.PrimaryButton
          loading={loading}
          onClick={handleSubmit}
          label={t(`trainings.schedules.cancel.${type}.confirm`, {
            defaultValue: `Confirmer ${isDenied ? "le refus" : "l'annulation"}`,
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default React.memo(CancelModal);
