import React from "react";

import { Loader as DSLoader } from "@skillup/ui";

import LoaderWrapper, { type LoaderInterface } from "./LoaderWrapper";

export type SkillLoaderProps = LoaderInterface;

export const Loader = ({ ...props }) => {
  return (
    <LoaderWrapper {...props}>
      <DSLoader />
    </LoaderWrapper>
  );
};
