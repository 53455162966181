import { useLocation } from "react-router-dom";
import { parse } from "qs";

const useQueryString = <T>(): T => {
  const { search } = useLocation();

  const query: T = parse(search, {
    ignoreQueryPrefix: true, // bypass the leading question mark
  }) as unknown as T;

  return query;
};

export default useQueryString;
