export { DefaultCurrency, type Currency, SupportedCurrencies } from "./types";

import { type Locales } from "src/locale";
import { type Currency, DefaultCurrency } from "./types";

export type FormatMonetaryOptions = {
  decimals?: number;
  currency?: Currency;
  locale?: Locales;
};

const getFractionLimit = (decimals?: number) => {
  if (decimals === undefined) {
    return 0;
  }

  if (decimals < 0) {
    return 0;
  }

  if (decimals > 20) {
    return 20;
  }

  return decimals;
};

export function formatMonetary(price: number, options: FormatMonetaryOptions = {}): string {
  return Intl.NumberFormat(options.locale ?? "fr", {
    style: "currency",
    currencyDisplay: "symbol",
    currency: options.currency ?? DefaultCurrency,
    maximumFractionDigits: getFractionLimit(options.decimals),
    minimumFractionDigits: 0,
  }).format(price);
}

export const getMonetarySymbol = (options: FormatMonetaryOptions = {}) =>
  formatMonetary(0, { ...options, decimals: 0 })
    .replace(/\d/g, "")
    .trim();
