import { Dispatch, SetStateAction } from "react";
import { useToasts } from "react-toast-notifications";

import { DSModal } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { useIntras } from "services/intras";

import { ModalState } from "../IntraListView";
import styles from "./DeleteIntraModal.module.scss";

const DeleteIntraModal = ({
  modalState,
  setModalState,
}: {
  modalState: ModalState;
  setModalState: Dispatch<SetStateAction<ModalState>>;
}) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { softDeleteIntra, isLoading } = useIntras();
  const { isOpen, selectedRow } = modalState;

  if (selectedRow === undefined) {
    return null;
  }

  function closeModal() {
    setModalState({ selectedRow: undefined, isOpen: false });
  }

  async function handleSubmit() {
    if (!isLoading) {
      try {
        await softDeleteIntra(selectedRow.uuid);
        addToast(
          t("supervisor.intra.delete.success", {
            defaultValue: "Programme supprimé",
          }),
          { appearance: "success", autoDismiss: true }
        );
        closeModal();
      } catch (err) {
        addToast(
          t("supervisor.intra.delete.error", {
            defaultValue: "La suppression du programme a échoué",
          }),
          { appearance: "error", autoDismiss: true }
        );
      }
    }
  }

  return (
    <DSModal isOpen={isOpen} className={styles.DeleteModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("supervisor.intra.delete.title", {
            defaultValue: "Supprimer un programme",
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <p>
          {t("supervisor.intra.delete.description", {
            defaultValue:
              'Le programme "{{ name }}" ne sera plus visible mais la suppression n’aura aucun impact sur les lignes du plan et du recueil, ni sur les sessions.',
            name: selectedRow.name,
          })}
        </p>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("app.modal.action.back", {
            defaultValue: "Retour",
          })}
          onClick={closeModal}
        />
        <DSModal.Footer.PrimaryButton
          label={t("app.modal.action.delete", {
            defaultValue: "Supprimer",
          })}
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default DeleteIntraModal;
