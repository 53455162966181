import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

import useTranslation from "hooks/useTranslation";

import { MultipleArchiveButton } from "../Jobs.styled";

interface Props {
  archiveMultipleJob: () => void;
  disabledMultipleArchived: boolean;
  selectedRows: GridRowSelectionModel;
}

export const ToolbarButton = ({
  archiveMultipleJob,
  disabledMultipleArchived,
  selectedRows,
}: Props) => {
  const { t } = useTranslation();

  return (
    <MultipleArchiveButton
      disabled={disabledMultipleArchived}
      onClick={archiveMultipleJob}
      label={t("jobs.list.label.archiveMultiple", {
        count: selectedRows.length,
        defaultValue: "Archiver {{count}} fiches de poste",
      })}
      tooltip={
        disabledMultipleArchived
          ? t("jobs.list.label.archiveMultiple.tooltip", {
              defaultValue:
                "Au moins l’une des fiches de poste sélectionnées est associée à des collaborateurs",
            })
          : undefined
      }
    />
  );
};
