import { useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";
import debounce from "lodash/debounce";

import {
  DSCheckbox,
  DSFormGroupTextInput,
  AssistiveArea,
  DSTooltip,
  DSTextInput,
} from "@skillup/ui";
import {
  FormikInputText,
  FormikInputSelect,
  FormikInputNumber,
  Flex,
  DatePicker,
} from "@skillup/design-system";
import { EditableCompanyField } from "@skillup/training-bridge";

import useTranslation from "hooks/useTranslation";
import FieldFormItem from "./Item";

import styles from "../Form.module.scss";
import { parseFieldValue } from "../../../utils/parseField";
import { generateBindingFromLabel } from "../utils";
import FieldSelectOptions from "./SelectOptions";

export type Props = {
  isEdit: boolean;
};

const FieldFormList = ({ isEdit }: Props) => {
  const { t } = useTranslation();
  const { values, setFieldValue, setTouched, initialValues } =
    useFormikContext<EditableCompanyField>();
  const [bindingTouched, setBindingTouched] = useState(false);

  const notEditableText = t("fields.form.notEditable", {
    defaultValue: "Ce champ n'est pas modifiable",
  });

  useEffect(() => {
    if (!values.label && values.binding) {
      setBindingTouched(true);
    }
  }, [values.binding, values.label]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGenerateBinding = useCallback(
    debounce((label) => {
      setFieldValue("binding", generateBindingFromLabel(label), true);
      setTouched({ binding: true });
    }, 400),
    []
  );

  useEffect(() => {
    if (!isEdit && !bindingTouched && values.label) {
      debouncedGenerateBinding(values.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.label, bindingTouched]);

  return (
    <Flex gap="s" flexDirection="column" className={styles.FormContent}>
      <DSFormGroupTextInput
        label={t("fields.form.label.name", { defaultValue: "Intitulé" })}
        required
        assistiveText={isEdit && <span>{values.binding}</span>}
      >
        {values.restrictions.includes("label") ? (
          <DSTooltip label={notEditableText}>{values.label}</DSTooltip>
        ) : (
          <FormikInputText
            name="label"
            value={values.label ?? ""}
            placeholder={t("fields.form.label.name", {
              defaultValue: "Intitulé",
            })}
          />
        )}
      </DSFormGroupTextInput>
      {!isEdit && (
        <FieldFormItem
          label={t("fields.form.binding.name", {
            defaultValue: "Identifiant technique",
          })}
          required
          disabled={values.restrictions.includes("binding")}
          disabledValue={values.binding}
          disableTooltip={notEditableText}
        >
          <FormikInputText
            name="binding"
            value={values.binding ?? ""}
            placeholder={t("fields.form.binding.name", {
              defaultValue: "Identifiant technique",
            })}
          />
        </FieldFormItem>
      )}

      <FieldFormItem
        label={t("fields.form.type.name", { defaultValue: "Type" })}
        required
        disabled={values.restrictions.includes("type") || isEdit}
        disabledValue={t(`fields.type.${values.type}`)}
        disableTooltip={notEditableText}
      >
        <FormikInputSelect
          name="type"
          value={values.type ?? ""}
          options={[
            {
              label: t("fields.type.number", {
                defaultValue: "nombre",
              }),
              value: "number",
            },
            {
              label: t("fields.type.select", {
                defaultValue: "liste d'options",
              }),
              value: "select",
            },
            {
              label: t("fields.type.text", {
                defaultValue: "text",
              }),
              value: "text",
            },
            {
              label: t("fields.type.monetary", {
                defaultValue: "monetary",
              }),
              value: "monetary",
            },
            {
              label: t("fields.type.textarea", {
                defaultValue: "textarea",
              }),
              value: "textarea",
            },
            {
              label: t("fields.type.date", {
                defaultValue: "date",
              }),
              value: "date",
            },
          ]}
          placeholder={t("fields.form.type.name", { defaultValue: "Type" })}
        />
      </FieldFormItem>

      {values.type === "select" && (
        <FieldFormItem
          label={t("fields.form.options.name", { defaultValue: "Options du select" })}
          disabled={values.restrictions.includes("options")}
          disabledValue={
            <div>
              {values.options.map((o) => (
                <DSTextInput
                  className={styles.optionsReadOnly}
                  name={o.key}
                  readOnly
                  value={o.value}
                />
              ))}
            </div>
          }
          disableTooltip={notEditableText}
        >
          <Flex gap="xs" flexDirection="column">
            <FieldSelectOptions
              values={values}
              setFieldValue={setFieldValue}
              initialOptions={initialValues["options"] ?? []}
            />
          </Flex>
        </FieldFormItem>
      )}

      {!values.restrictions.includes("defaultValue") && !values?.hasRows && (
        <FieldFormItem
          label={t("fields.form.defaultValue.name", {
            defaultValue: "Valeur par défaut (optionnelle)",
          })}
          disabled={values.restrictions.includes("defaultValue")}
          disabledValue={parseFieldValue(values.type, values.defaultValue, values.options)}
          disableTooltip={notEditableText}
        >
          {values.type === "select" && (
            <FormikInputSelect
              name="defaultValue"
              value={values.defaultValue}
              options={values.options.map((o) => ({ label: o.value, value: o.key }))}
              placeholder={t("fields.form.defaultValue.name", {
                defaultValue: "Valeur par défaut (optionnelle)",
              })}
            />
          )}
          {["number", "monetary"].includes(values.type) && (
            <FormikInputNumber
              name="defaultValue"
              value={values.defaultValue}
              placeholder={t("fields.form.defaultValue.name", {
                defaultValue: "Valeur par défaut (optionnelle)",
              })}
            />
          )}
          {["text"].includes(values.type) && (
            <FormikInputText
              name="defaultValue"
              value={values.defaultValue}
              placeholder={t("fields.form.defaultValue.name", {
                defaultValue: "Valeur par défaut (optionnelle)",
              })}
            />
          )}
          {["textarea"].includes(values.type) && (
            <FormikInputText
              name="defaultValue"
              value={values.defaultValue}
              placeholder={t("fields.form.defaultValue.name", {
                defaultValue: "Valeur par défaut (optionnelle)",
              })}
            />
          )}
          {["date"].includes(values.type) && (
            <DatePicker
              value={
                // Problem with Date is not compliant with never
                (values.defaultValue ? new Date(values.defaultValue) : undefined) as never
              }
              onChange={(date) => setFieldValue("defaultValue", date)}
            />
          )}
        </FieldFormItem>
      )}

      <FieldFormItem
        label={t("fields.section.options", { defaultValue: "Options" })}
        gap="xs"
        classname={styles.OptionsLabel}
      >
        <Flex gap="xs" flexDirection="column">
          <DSTooltip
            label={values.restrictions.includes("massEditAvailable") ? notEditableText : undefined}
          >
            <DSCheckbox
              checked={values.massEditAvailable}
              label={t("fields.form.massEditAvailable", {
                defaultValue: "Edition de masse",
              })}
              disabled={values.restrictions.includes("massEditAvailable")}
              onChange={(checked) =>
                !values.restrictions.includes("massEditAvailable") &&
                setFieldValue("massEditAvailable", checked)
              }
            />
          </DSTooltip>

          {!values.isStandard && (
            <Flex gap="xxs" flexDirection="column">
              <DSCheckbox
                checked={values.isTrainingField ?? false}
                label={t("fields.form.isTrainingField", {
                  defaultValue: "Ajouter ce champ au programme",
                })}
                disabled={values.restrictions.includes("isTrainingField")}
                onChange={(checked) =>
                  !values.restrictions.includes("isTrainingField") &&
                  setFieldValue("isTrainingField", checked)
                }
              />
              {values.isTrainingField && !values.isTrainingField && (
                <AssistiveArea
                  mode={"warning"}
                  text={t("fields.form.isTrainingFieldWarning", {
                    defaultValue:
                      'Le champ "{{ field }}" sera retiré des programmes. Toutes les lignes non convoquées liées au programme auront une valeur vide, les autres lignes conserveront leur valeur.',
                    field: values.label,
                  })}
                />
              )}
            </Flex>
          )}

          <DSTooltip label={values.isStandard ? notEditableText : undefined}>
            <DSCheckbox
              checked={!values.restrictions.includes("editable_rf")}
              label={t("fields.form.isRF", {
                defaultValue: "Les RF peuvent modifier ce champ",
              })}
              disabled={values.isStandard}
              onChange={(checked) => {
                const restrictions = [...values.restrictions.filter((r) => r !== "editable_rf")];

                if (!checked) {
                  restrictions.push("editable_rf");
                }

                setFieldValue("restrictions", restrictions);
              }}
            />
          </DSTooltip>

          <DSCheckbox
            checked={values.isHidden}
            label={t("fields.form.isHidden", {
              defaultValue: "Afficher ce champ uniquement dans les exports",
            })}
            onChange={(checked) => setFieldValue("isHidden", checked)}
          />
        </Flex>
      </FieldFormItem>
    </Flex>
  );
};

export default FieldFormList;
