import { FormatDate, ParseDate } from "@skillup/shared-utils";
import {
  DSProgressBar,
  dataInPercent,
  DSFormGroupTextInput,
  DSTextInput,
  TypeProgressBar,
  Loader,
  DSAlert,
  DSAlertType,
  DSAlertDisplay,
  COLORS,
} from "@skillup/ui";
import { plural } from "utils/locale";
import { useDashboardData } from "../../context";
import styles from "./Summary.module.scss";

function Summary() {
  const { dashboardData, activeCampaign, isLoading } = useDashboardData();
  const campaignData = dashboardData?.[activeCampaign];

  if (isLoading || !campaignData) {
    return (
      <div className={styles.Summary}>
        <Loader />
      </div>
    );
  }

  const startDate = ParseDate.FromISO(campaignData.campaign.startDate);
  const endDate = ParseDate.FromISO(campaignData.campaign.endDate);

  return (
    <>
      <div id="summary" />
      <div className={styles.Summary}>
        {isLoading && <Loader />}
        <div className={styles.header}>
          <label className={styles.title}>Synthèse</label>
          {startDate.isValid && (
            <DSFormGroupTextInput label="Date de lancement de la campagne" darkMode>
              <DSTextInput
                name="date1"
                readOnly
                className={styles.dateInput}
                value={FormatDate.ToStringFormat(startDate, "dd/MM/yyyy")}
                darkMode
              />
            </DSFormGroupTextInput>
          )}
          {endDate.isValid && (
            <DSFormGroupTextInput label="Date limite de signature" darkMode>
              <DSTextInput
                name="date2"
                readOnly
                className={styles.dateInput}
                value={FormatDate.ToStringFormat(endDate, "dd/MM/yyyy")}
                darkMode
              />
            </DSFormGroupTextInput>
          )}
        </div>
        <div className={styles.main}>
          <div className={styles.title}>
            <label>{plural(campaignData.interview.collaborators, "%n collaborateur%s")}</label>
            <p>{plural(campaignData.interview.managers, "%n responsable%s d'entretien")}</p>
          </div>
          <DSProgressBar
            className={styles.progressBar}
            darkMode
            layout="segmented"
            legendsLayout="column"
            type={TypeProgressBar.PERCENTAGE}
            data={dataInPercent([
              {
                label: "Préparations non partagées",
                value: campaignData.interview.notShared,
                color: "grey" as COLORS,
              },
              {
                label: "Préparation partagée par au moins une des deux parties",
                value: campaignData.interview.shared,
                color: "blue" as COLORS,
              },
              {
                label: "Entretien signé par au moins une des deux parties",
                value: campaignData.interview.signed,
                color: "green" as COLORS,
              },
            ])}
          />
        </div>
      </div>
      {endDate.diffNow("days").days > 0 && (
        <DSAlert type={DSAlertType.INFO} display={DSAlertDisplay.INLINE} className={styles.Alert}>
          Seules les réponses des préparations partagées sont consignées ci-dessous. Elles sont
          susceptibles d’évoluer jusqu’à ce que la campagne soit clôturée (la date limite de
          signature est fixée au {FormatDate.ToStringFormat(endDate, "dd/MM/yyyy")}).
        </DSAlert>
      )}
    </>
  );
}

export default Summary;
