import { useMemo, useState } from "react";

import { Config, ICompanyField } from "@skillup/training-bridge";
import { DSButton, DSDataGrid, DSFilters } from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";
import { GridColDef } from "@mui/x-data-grid-pro";

import useTranslation from "hooks/useTranslation";
import { isSkillupDeveloper, isSkillupAdmin } from "utils/User";

import useFiltersSettings from "../hooks/useFiltersSettings";
import generateColumns from "../utils/generateColumns";
import { GridProps, initialState } from "./config";
import style from "./GridFields.module.scss";
import CreateFieldView from "../views/Create";

interface IParsedCompanyField extends Omit<ICompanyField, "isTrainingField"> {
  readonly isTrainingField: string;
}

const GridCustomFields = (props: GridProps) => {
  const { t } = useTranslation();
  const [filterRef, filters, filterValues, handleFilterValuesChange, handleTableColumnFilterClick] =
    useFiltersSettings(true);
  const [create, setCreate] = useState(false);

  const isAdmin = isSkillupAdmin() || isSkillupDeveloper();

  const columns: GridColDef[] = useMemo(() => {
    return generateColumns(t, isAdmin, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parsedFieldsIsTraining: IParsedCompanyField[] = useMemo(() => {
    return props.fields.map((field) => ({
      ...field,
      // parse to string for filtering matching
      isTrainingField: field.isTrainingField.toString(),
    }));
  }, [props.fields]);

  const displayedRows: IParsedCompanyField[] = useMemo(() => {
    const datasetFilterManager = new ListUtils.InMemoryDataset(
      Config.Fields.CustomFieldsFilters,
      parsedFieldsIsTraining
    );

    return datasetFilterManager.getRows({ filterValues });
  }, [parsedFieldsIsTraining, filterValues]);

  return (
    <div className={style.gridContainer}>
      <h4 className={style.mainTitle}>
        {t("fields.customFields.mainTitle", {
          defaultValue: "Champs personnalisés",
        })}
      </h4>
      <DSFilters
        t={t}
        config={Config.Fields.CustomFieldsFilters}
        ref={filterRef}
        filters={filters}
        onChange={handleFilterValuesChange}
        translationPrefix="fields.customFields"
        className={style.filters}
      />
      <DSDataGrid
        getRowId={(row) => row.uuid}
        loading={props.loading}
        columns={columns}
        rows={displayedRows}
        onRowClick={(gridRow) => {
          props.onSelectField(gridRow.row);
        }}
        rowCount={displayedRows.length}
        pagination
        initialState={initialState}
        translationPrefix="fields.customFields"
        onFilter={handleTableColumnFilterClick}
        className={style.grid}
      />
      {isAdmin && (
        <DSButton
          onClick={() => setCreate(true)}
          label={t("fields.customFields.button.add-custom-field", {
            defaultValue: "Ajouter un champ personnalisé",
          })}
        />
      )}
      {create && <CreateFieldView onClose={() => setCreate(false)} />}
    </div>
  );
};

export default GridCustomFields;
