import React, { useEffect } from "react";
import { useSetState } from "react-use";
import { sortBy, omitBy, trim, isEmpty, slice } from "lodash";
import { useTypedFetch } from "hooks";

import type { OrganizationRoutesType } from "types/api";

import Acta from "utils/Acta";
import User from "utils/User";
import useAreas from "hooks/useAreas";

import auth from "actions/auth";

import styles from "./SupervisorCompanySwitchStyles.module.scss";
import { useRecoilState } from "recoil";
import { companiesState, cursorState } from "./store";
import { Button } from "@skillup/ui";
import { buildRequest } from "utils/buildRequest";
import DSLayout from "components/DSLayout";

export default () => {
  const [{ search, results, maxCursor }, setState] = useSetState({
    results: [],
    search: "",
    maxCursor: 0,
  });

  const { data: companiesData = [] } = useTypedFetch<OrganizationRoutesType.GetSimpleOrganisations>(
    {
      path: "/organization/list",
      method: "GET",
    }
  );

  const [companies, setCompanies] = useRecoilState(companiesState);
  const [cursor, setCursor] = useRecoilState(cursorState);

  useEffect(() => {
    if (companiesData?.length) {
      const cleanList = omitBy(
        companiesData.map((company) => ({ scope: ["company"], ...company.organization })),
        ({ name }) => isEmpty(trim(name))
      );
      const sortedList = sortBy(cleanList, "name");
      setCompanies(sortedList);
      const maxCursor = companiesData.length;
      setState({
        maxCursor,
      });
    }
  }, [companiesData, setCompanies, setState]);
  useEffect(() => {
    if (companies?.length) {
      setState({
        results: slice(companies, 0, 25),
      });
    }
  }, [companies, setState]);
  useEffect(() => {
    setState({
      results: slice(companies, 0, cursor),
    });
  }, [cursor, setState, companies]);

  const seeMore = () => {
    if (cursor > maxCursor) {
      return;
    }
    setCursor(cursor + 20);
  };
  const { clearActiveAreas } = useAreas();

  const switchTo = (uuid) => {
    return async (event) => {
      event.preventDefault();
      await auth.switchTo(uuid);
      clearActiveAreas();
      window.location.reload();
    };
  };

  const switchToGroup = (uuid) => {
    return async (event) => {
      event.preventDefault();
      await auth.switchToGroup(uuid);
    };
  };

  const onSearch = async (event) => {
    const {
      target: { value },
    } = event;
    setState({ search: value });
    if (isEmpty(value) && companies?.length) {
      setState({
        results: slice(companies, 0, 25),
      });
      setCursor(20);

      return;
    }

    const results = await buildRequest<OrganizationRoutesType.CompanySwitch>({
      method: "GET",
      path: "/organization/company-switch/{searchQuery}",
      params: { searchQuery: value },
      query: { type: "company" },
    })();

    setState({
      results: results.filter((r) => r.scope && r.scope.includes("company")),
    });
  };

  const userData = Acta.getState("userData") || {};
  const activeCompany = userData.activeCompany || {};

  const groups = User.getGroups();
  const userGroup = User.getUserGroup();

  return (
    <DSLayout title="Changer d'entreprise">
      <div className={styles.AccountCompanySwitch}>
        <div className={styles.wrapper}>
          <h1>{activeCompany.name}</h1>
          <h5>{activeCompany.uuid}</h5>
          <div className={styles.search}>
            <label>Rechercher :</label>
            <input value={search} onChange={onSearch} autoFocus />
            <div className={styles.quickAccess}>
              {results.map((result) => (
                <button key={result.uuid} onClick={switchTo(result.uuid)}>
                  {result.name}
                </button>
              ))}
            </div>
          </div>
          {cursor < maxCursor && !search ? (
            <Button label="Voir plus" onClick={seeMore} />
          ) : undefined}
          <hr />
          <h2>{userGroup.name}</h2>
          <div className={styles.quickAccess}>
            {groups.map((g) => (
              <button key={g.uuid} onClick={switchToGroup(g.uuid)}>
                {g.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </DSLayout>
  );
};
