type GetLocalizedParams = {
  startDate: string;
  rawData: string;
  records?: Record<
    string,
    {
      startDate: string;
      data?: string | number;
    }[]
  > | null;
  locale?: string;
};

const getLocalizedSalary = ({ startDate, rawData, records, locale = "fr" }: GetLocalizedParams) => {
  if (!records) return null;

  const rawSalary = Number(rawData) / 100;
  const currencyRecords = records["job.currency"];
  const salaryTimestamp = new Date(startDate).getTime();

  const currencyRecordAtDate = currencyRecords.find(
    ({ startDate }) => new Date(startDate).getTime() >= salaryTimestamp
  );

  const currencyIsoCode = currencyRecordAtDate?.data?.toString() || "EUR";

  const options: Intl.NumberFormatOptions = {
    currency: currencyIsoCode,
    minimumFractionDigits: 0,
    style: "currency",
  };

  return rawSalary.toLocaleString(locale, options);
};

export default getLocalizedSalary;
