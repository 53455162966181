import { useMemo, useCallback } from "react";
import cx from "classnames";

import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { Flex } from "@skillup/ui";

import { DNDPageItem } from "../../dndReducer";
import { StructurePage } from "./StructurePage";

import { SortablePageChildren } from "./SortablePageChildren";
import { Collapsible } from "../Collapsible/Collapsible";
import { useBuilderContext } from "../../../BuilderContext";
import { useBuilderModalsContext } from "../../../BuilderModalsContext";

import styles from "../../StructureBuilderStructure.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectHighlightUuid, setHighlightUuid } from "../../../reducer";

type DraggablePage = DNDPageItem & {
  type: "page";
  sectionIndex: number;
  pageIndex: number;
};

export const SortablePage = ({
  onlyOnePage,
  page,
  disabledSortableChildren,
}: {
  onlyOnePage: boolean;
  page: DraggablePage;
  disabledSortableChildren: boolean;
}) => {
  const highlightUuid = useSelector(selectHighlightUuid);
  const item = useMemo(() => {
    return {
      type: page.type,
      uuid: page.uuid,
      sectionIndex: page.sectionIndex,
      pageIndex: page.pageIndex,
    };
  }, [page]);

  const { active, isOver, isDragging, attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: page.uuid,
      data: { ...item, uuid: undefined },
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { closedItems, setClosedItems, clickStructureItem } = useBuilderContext();

  const toggleShowChildren = useCallback(() => {
    setClosedItems((prev) => ({ ...prev, [page.uuid]: prev[page.uuid] ? undefined : true }));
    if (!closedItems[page.uuid] && highlightUuid) {
      const isHighlightIdInChildren = page.children.some((child) => child.uuid === highlightUuid);
      if (isHighlightIdInChildren) {
        const structureItem = document.getElementById(`structure_${page.uuid}`);
        if (structureItem) {
          structureItem.focus();
        }
        clickStructureItem(page.uuid);
      }
    }
  }, [closedItems, clickStructureItem, highlightUuid, page, setClosedItems]);

  const isOverByChild = useMemo(() => {
    return isOver && active?.data.current?.type === "child";
  }, [isOver, active]);

  const handleClick = useCallback(() => {
    clickStructureItem(page.uuid);
  }, [clickStructureItem, page.uuid]);

  const { handleDeletion, handleDuplication, showActionsButtons } = useBuilderModalsContext();

  const dispatchStore = useDispatch();
  const handleFocus = useCallback(() => {
    dispatchStore(setHighlightUuid(page.uuid));
  }, [dispatchStore, page.uuid]);

  const showPage = useMemo(() => {
    return !(page.hideInBuilder && (onlyOnePage || page.children?.length === 0));
  }, [onlyOnePage, page]);

  return (
    <Flex
      column
      ref={setNodeRef}
      style={style}
      className={cx(
        isDragging ? styles.dragging : "",
        isOverByChild && closedItems[page.uuid] ? styles.overClosed : ""
      )}
      {...attributes}
    >
      {showPage && (
        <StructurePage
          id={`structure_${page.uuid}`}
          displayMenuOnHover={!active}
          title={page.title}
          errors={page.errors?.map((error) => error.structure)}
          dragListeners={listeners}
          highlight={highlightUuid === page.uuid}
          onClick={handleClick}
          onFocus={handleFocus}
          showChildren={!closedItems[page.uuid]}
          toggleShowChildren={toggleShowChildren}
          onDuplication={() => handleDuplication(item)}
          onDeletion={() => handleDeletion(item)}
          showActionsButtons={showActionsButtons(item)}
        />
      )}
      <Collapsible isOpen={!closedItems[page.uuid]}>
        <SortablePageChildren
          page={page}
          disabled={closedItems[page.uuid] || disabledSortableChildren}
        />
      </Collapsible>
    </Flex>
  );
};
