import { useState } from "react";

import { useModal } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";

import { SidePanel } from "./SidePanel";
import {
  Gauge,
  Angle,
  GaugeName,
  GaugeValue,
  CollabCircle,
  GaugeContainer,
  AvatarGroupStyled
} from "./ScaleLevelGauge.styled";

export interface Employee {
  role: string;
  level: number;
  fullName: string;
  employeeUuid: string;
}

interface Level {
  uuid: string;
  name: string;
  level: number;
}

interface GroupedEmployeesByEvaluation {
  [level: number]: Employee[];
}

interface GaugePropsType {
  name: string;
  levels: Array<Level>;
  employees: Array<Employee>;
}

export interface SidePanelPropsType {
  name: string;
  levels: Array<Level>;
  employees: Array<Employee>;
}

const ScaleLevelGauge = ({ employees, levels, name }: GaugePropsType) => {
  const sidePanelState = useModal();

  const [sidePanelProps, setSidePanelProps] = useState<undefined | SidePanelPropsType>();

  const openSidePanelWithEmployees = (props: SidePanelPropsType) => {
    setSidePanelProps(props);
    sidePanelState.show();
  };

  const groupedEmployeeByEvaluation = employees.reduce((acc, employee) => {
    const bin = Math.floor(employee.level);
    const median = bin - 0.5;

    if (!acc[median]) acc[median] = [];
    acc[median].push(employee);

    return acc;
  }, {} as GroupedEmployeesByEvaluation);

  const roundEvaluationToPercentage = (value: number) => {
    const evaluation = Math.round(value * 2) / 2;

    if (evaluation === levels.length) return (evaluation / levels.length) * 98;
    if (evaluation === 0) return 0.5;

    return (evaluation / levels.length) * 100;
  };

  return (
    <GaugeContainer>
      <Gauge>
        {Object.keys(groupedEmployeeByEvaluation)
          .sort((a, b) => parseFloat(a) - parseFloat(b))
          .map((key, index) => {
            const evaluation = parseFloat(key);
            const employees = groupedEmployeeByEvaluation[key];
            return (
              <CollabCircle
                key={index}
                level={roundEvaluationToPercentage(evaluation)}
                onClick={() =>
                  openSidePanelWithEmployees({
                    employees,
                    levels,
                    name,
                  })
                }
              >
                <Angle />
                <AvatarGroupStyled
                  size="S"
                  maxItems={5}
                  firstElementForeground
                  totalItems={employees.length}
                  onClick={() =>
                    openSidePanelWithEmployees({
                      employees,
                      levels,
                      name,
                    })
                  }
                  users={employees.map((employee) => ({
                    uuid: employee.employeeUuid,
                    fullName: employee.fullName,
                    initials: getInitials({ fullName: employee.fullName }),
                  }))}
                />
              </CollabCircle>
            );
          })}
        {levels.map((_, index) => (
          <GaugeValue key={index} position={(index / levels.length) * 100} />
        ))}
        <GaugeValue key="last" position={100} />
      </Gauge>
      <Flex width="100%" marginTop="xs">
        {levels.map((level, index) => (
          <GaugeName
            key={level.uuid}
            widthSize={100 / levels.length}
          >{`${index + 1}. ${level.name}`}</GaugeName>
        ))}
      </Flex>

      {sidePanelState.isOpen && (
        <SidePanel
          isOpen={sidePanelState.isOpen}
          sidePanelProps={sidePanelProps}
          close={() => sidePanelState.hide()}
        />
      )}
    </GaugeContainer>
  );
};

export default ScaleLevelGauge;
