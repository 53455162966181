import { GridColDef } from "@mui/x-data-grid-pro";
import { DSTooltip } from "@skillup/ui";
import styles from "../Components/DashboardDatagrid.module.scss";

import { ComputedIndicatorData } from "../types";

type column = {
  field: string;
  headerName: string;
  sortable: boolean;
  width: number;
};

const generateColumns = (initialColumns: Array<string>): GridColDef<column>[] => {
  return initialColumns.map((col, index) => ({
    field: col,
    sortable: false,
    width: index === 0 ? 180 : 120,
    align: index > 0 ? "right" : "left",
    renderCell: (params) => {
      return (
        <DSTooltip label={params.value} className={styles.TooltipCell} enabledOnOverflow>
          {params.value}
        </DSTooltip>
      );
    },
  }));
};

const generateRows = (initialRows: Array<any>, columns: GridColDef<any>[]): any[] => {
  const rows = initialRows.map((row: Array<any>, rowsIndex) => {
    const mappedRow = row.reduce((acc, col, colIndex) => {
      acc[columns[colIndex].field] = col.formatted;

      return acc;
    }, {});

    return {
      id: rowsIndex.toString(),
      ...mappedRow,
    };
  });

  return rows;
};

const useTableData = (data: ComputedIndicatorData) => {
  const columns = generateColumns(data.columns);
  const rows = generateRows(data.data, columns);

  return { rows, columns };
};

export default useTableData;
