import React, { type HTMLAttributes, type ReactElement, useMemo } from "react";
import cx from "classnames";

import styles from "./AssistiveArea.module.scss";

import { type Themes } from "../../commonProps";
import { MaterialIcons } from "../../../index";

type AssistiveText = string | ReactElement;
type MultipleAssistiveTexts = {
  [DisplayMode.DEFAULT]: AssistiveText;
  [DisplayMode.ERROR]: AssistiveText;
};

export enum DisplayMode {
  DEFAULT = "default",
  WARNING = "warning",
  ERROR = "error",
}
export interface AssistiveAreaProps extends HTMLAttributes<HTMLDivElement> {
  readonly text?: AssistiveText;
  readonly multipleTexts?: MultipleAssistiveTexts;
  readonly area?: string;

  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly mode?: `${DisplayMode}`;
}

export const AssistiveArea = ({
  text,
  multipleTexts,
  area,
  darkMode = false,
  theme,
  className,
  mode = DisplayMode.DEFAULT,
  ...props
}: AssistiveAreaProps) => {
  const assistiveText = useMemo(() => {
    if (text) return text;
    if (multipleTexts) return multipleTexts[mode];
    return undefined;
  }, [text, multipleTexts, mode]);

  return (
    <div
      className={cx(styles.AssistiveArea, className, styles[theme], styles[mode], {
        [styles.darkMode]: darkMode,
        [styles.warning]: mode === "warning",
        [styles.error]: mode === "error",
      })}
      aria-label={`assistivearea-${assistiveText}`}
      role="presentation"
      {...props}
    >
      {assistiveText && (
        <div className={styles.textAndIcon}>
          <ModeIcon mode={mode} />
          <span>{assistiveText}</span>
        </div>
      )}
      <div className={styles.area}>{area}</div>
    </div>
  );
};

function ModeIcon({ mode }: { mode: `${DisplayMode}` }) {
  switch (mode) {
    case DisplayMode.WARNING:
      return (
        <MaterialIcons.Warning aria-label="assistive-icon" className={styles.icon} size="1rem" />
      );
    case DisplayMode.ERROR:
      return (
        <MaterialIcons.Error aria-label="assistive-icon" className={styles.icon} size="1rem" />
      );
    default:
      return null;
  }
}

