import React, { type ReactNode } from "react";

import TextWrapper, { type TextInterface } from "./TextWrapper";

export type SkillTextProps = {
  children: ReactNode | undefined;
} & TextInterface;

export const Text = ({ children, ...props }: SkillTextProps) => {
  return <TextWrapper {...props}>{children}</TextWrapper>;
};
