import { useMemo } from "react";
import { IForm } from "../components/HabilitationForm";
import { HabilitationValidity } from "../components/HabilitationForm/config";

export const useFormState = (form: IForm) => {
  const isFormStateValid = useMemo(() => {
    return (
      !!form["name"]?.value &&
      !!form["categoryUuid"]?.value &&
      ((form["validity"]?.value === HabilitationValidity.LIMITED &&
        !!form["duration"]?.value &&
        !!form["deadlineAnticipation"]?.value) ||
        form["validity"]?.value === HabilitationValidity.ADVITAM)
    );
  }, [form]);

  const tooltip = !isFormStateValid
    ? "Tous les champs obligatoires (signalés par une astérisque rouge) doivent être renseignés"
    : undefined;

  return {
    disabled: !isFormStateValid,
    tooltip,
  };
};
