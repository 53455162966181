import React from "react";

import { Icon } from "@skillup/ui";
import { colors } from "@skillup/ui";

import styles from "./RequiredSkill.module.scss";

export interface RequiredSkillProps {
  uuid: string;
  label: string;
  description: string;
  onUpdate: (skillUuid: string) => void;
  onDelete: (skillUuid: string) => void;
  expectedLevel?: {
    level: number;
    label: string;
    description?: string;
  };
}

const RequiredSkill = ({
  uuid,
  description,
  expectedLevel,
  label,
  onDelete,
  onUpdate,
}: RequiredSkillProps) => {
  const [actionsVisible, setActionsVisible] = React.useState(false);

  const showRequiredLevelModal = () => onUpdate(uuid);

  return (
    <div
      className={styles.RequiredSkill}
      aria-label={`required-skill-${label}`}
      onClick={() => {
        setActionsVisible(false);
      }}
    >
      <div aria-label="montrer-actions" className={styles.actionsToggle}>
        <Icon.More
          onClick={(e) => {
            e.stopPropagation();
            setActionsVisible(!actionsVisible);
          }}
        />
      </div>
      {actionsVisible && (
        <div aria-label="actions" className={styles.actions}>
          <span
            aria-label="retirer de la fiche"
            onClick={(e) => {
              e.stopPropagation();
              const confirmed = window.confirm(
                "Êtes-vous certain de vouloir supprimer cette compétence de la fiche de poste ?"
              );
              if (confirmed) onDelete(uuid);
            }}
          >
            <Icon.Cross color={colors.slate} />
            Retirer de la fiche
          </span>
        </div>
      )}
      <div className={styles.header} onClick={showRequiredLevelModal}>
        <span className={styles.label}>{label}</span>
        <span className={styles.description}>{description}</span>
      </div>
      <div className={styles.separator} />
      <div className={styles.body} onClick={showRequiredLevelModal}>
        {expectedLevel && (
          <>
            <span className={styles.expectedLevel_label}>
              Niveau de maîtrise attendu: <span>{expectedLevel.label}</span>
            </span>
            <span className={styles.expectedLevel_description}>{expectedLevel.description}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default RequiredSkill;
