import { FieldArray, FormikProps } from "formik";

import { Label as DSLabel } from "@skillup/ui";

import { Flex, Text, FormikInputText } from "@skillup/design-system";
import useTranslation from "hooks/useTranslation";

import EvaluationScaleLevel from "./components/Level/EvaluationScaleLevel";
import { FormEvaluationLevel } from "types/skills";
import EvaluationScaleLevelOptions from "./EvaluationScaleLevelOptions";
import { FormStyled, EvaluationForm, AddLevelButton } from "./EvaluationScaleForm.styled";

interface FormikState {
  evaluationScaleName: string;
  startAtZero: boolean;
  hasDescriptions: boolean;
  levels: FormEvaluationLevel[];
}

export type FormikSetFieldLevel = (
  field: string,
  value: FormEvaluationLevel[],
  shouldValidate?: boolean
) => void;

interface EvaluationScaleFormProps {
  formikProps: FormikProps<FormikState>;
}

const getLastLevel = (
  t: (key: string, options?: { [key: string]: any; defaultValue?: string }) => string,
  levels: Array<FormEvaluationLevel>
): FormEvaluationLevel => {
  return {
    level: levels.at(-1).level + 1,
    name: "",
    description: "",
    deletable: true,
    placeholder: t("portal.config.skills.createEvaluationScale.defaultPlaceholder", {
      defaultValue: "Ex: ...",
    }),
  };
};

export function rewriteLevels<T>(startAtZero: boolean, values: Array<T>) {
  return values.map((e, index) => {
    const level = startAtZero ? index : index + 1;
    return {
      ...e,
      level,
    };
  });
}

function setDeletable<T>(values: Array<T>) {
  return values.map((e, index) => {
    return {
      ...e,
      deletable: index > 1,
    };
  });
}

function printLevels<T>(startAtZero: boolean, values: Array<T>) {
  return setDeletable(rewriteLevels(startAtZero, values));
}

export default function EvaluationScaleForm(props: EvaluationScaleFormProps) {
  const { t } = useTranslation();
  const {
    formikProps: { values },
  } = props;

  return (
    <Flex width="100%" overflow="auto" backgroundColor="surface-light-darker">
      <FormStyled>
        <FieldArray name="levels">
          {({ push, remove, insert }) => (
            <>
              <EvaluationForm>
                <Flex flexDirection="column" gridArea="evaluation-scale-name" gap="xs">
                  <DSLabel
                    label={t("portal.config.skills.createEvaluationScale.nameLabel", {
                      defaultValue: "Nom de l'échelle d'évaluation",
                    })}
                    required
                  />
                  <FormikInputText
                    name="evaluationScaleName"
                    placeholder={t(
                      "portal.config.skills.createEvaluationScale.nameLabel.placeholder",
                      {
                        defaultValue: "Ex : Échelle d’évaluation 1",
                      }
                    )}
                    value={values.evaluationScaleName ?? ""}
                  />
                </Flex>
                <EvaluationScaleLevelOptions
                  push={push}
                  insert={insert}
                  remove={remove}
                  formikProps={props.formikProps}
                />
                <Flex
                  flexDirection="column"
                  gridArea="evaluation-scale-levels"
                  gap="xs"
                  alignItems="flex-start"
                >
                  <Text espaceFont="body2Bold">
                    {t("portal.config.skills.createEvaluationScale.evaluationLevels", {
                      defaultValue: "Niveaux d'évaluations",
                    })}
                  </Text>
                  <Text espaceFont="captionRegular">
                    {t("portal.config.skills.createEvaluationScale.evaluationLevels.description", {
                      defaultValue:
                        "Les niveaux doivent être définis dans l’ordre croissant de maîtrise de la compétence. (Ex. : Niveau 1 = Débutant ; Niveau 4 = Expert)",
                    })}
                  </Text>
                </Flex>
                <Flex flexDirection="column" gridArea="evaluation-scale-inputs" gap="xs">
                  {printLevels(values.startAtZero, values.levels).map((level, index) => (
                    <EvaluationScaleLevel
                      key={index}
                      index={index}
                      item={level}
                      activeDescription={values.hasDescriptions}
                      deleteEvaluationLevel={() => {
                        remove(index);
                      }}
                    />
                  ))}
                  <AddLevelButton
                    type="button"
                    label={t("portal.config.skills.createEvaluationScale.addEvaluationLevel", {
                      defaultValue: "Ajouter un niveau d'évaluation",
                    })}
                    onClick={() => push(getLastLevel(t, values.levels))}
                    disabled={
                      values.startAtZero ? values.levels.length >= 11 : values.levels.length >= 10
                    }
                    buttonSize="S"
                    emphasis="Mid"
                    darkMode={false}
                  />
                </Flex>
              </EvaluationForm>
            </>
          )}
        </FieldArray>
      </FormStyled>
    </Flex>
  );
}
