import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type CampaignSettings = {
  observers: string[];
  coordinators: string[];
};

export async function editCampaignSettings({
  campaignId,
  settings,
}: {
  campaignId: string;
  settings: CampaignSettings;
}) {
  const response = await buildRequest<CampaignsRoutes.EditSettings>({
    method: "PUT",
    params: {
      campaignId,
    },
    path: "/campaigns/{campaignId}/settings",
    payload: settings,
    target: "PEOPLE_REVIEW",
  })();

  return response;
}
