import { useEffect, useMemo } from "react";
import { FormikInputSelect, FormikInputText } from "@skillup/design-system";
import { Flex } from "@skillup/ui";

import type { CardConfigForm } from "../../types";
import { useTrainingDashboardContext } from "../../context";

type Props = {
  path: string;
  card: CardConfigForm;
  setFieldValue: (field: string, value: any) => void;
};

export const KeyIndicatorCard = ({ path, card, setFieldValue }: Props) => {
  const { fields, keyIndicators } = useTrainingDashboardContext();

  useEffect(() => {
    if (card.indicator) {
      const question = keyIndicators.find((question) => question.fields[0] === card.indicator);

      if (card.question_name !== question?.name) {
        setFieldValue(path, {
          ...card,
          question_name: question.name,
          short_name: question?.recommended_short_name,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.indicator]);

  const indicatorOptions = useMemo(() => {
    return keyIndicators.map((keyIndicator) => {
      return {
        label: fields.find((field) => field.name === keyIndicator.fields[0])
          ?.recommended_display_name,
        value: keyIndicator.fields[0],
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyIndicators]);

  return (
    <Flex column style={{ margin: 50 }}>
      <FormikInputText name={`${path}.short_name`} value={card.short_name} />
      <FormikInputSelect
        name={`${path}.indicator`}
        value={card.indicator}
        options={indicatorOptions}
      />
    </Flex>
  );
};
