import React, { cloneElement, type ButtonHTMLAttributes } from "react";
import cx from "classnames";

import { type Themes } from "../commonProps";
import { Tooltip, type TooltipProps } from "../Tooltip/Tooltip";
import { Loader } from "../Loader";

import styles from "./Button.module.scss";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly buttonSize?: "S" | "M";
  readonly fontWeight?: "light" | "normal" | "bold";
  readonly disabled?: boolean;
  readonly tooltip?: TooltipProps["label"];
  readonly tooltipWithPortal?: boolean;
  readonly tooltipDirection?: TooltipProps["direction"];
  readonly tooltipDarkMode?: boolean;
  readonly icon?: JSX.Element;
  readonly actionIcon?: JSX.Element;
  readonly darkMode?: boolean;
  readonly emphasis?: "High" | "Mid" | "Low";
  readonly label?: string;
  readonly loading?: boolean;
  readonly iconOnly?: boolean;
  readonly navButton?: boolean;
  readonly theme: Themes;
  readonly onFocus?: (event: React.FocusEvent<HTMLButtonElement>) => void;
  readonly onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
}

const Button = ({
  className,
  label,
  disabled = false,
  tooltip,
  tooltipDirection,
  tooltipDarkMode = false,
  tooltipWithPortal = false,
  emphasis = "High",
  fontWeight,
  darkMode = false,
  icon,
  actionIcon,
  buttonSize = "M",
  navButton,
  onClick,
  onFocus,
  onBlur,
  loading,
  iconOnly,
  theme,
  ...props
}: ButtonProps) => {
  return (
    <Tooltip
      label={tooltip ?? undefined}
      direction={tooltipDirection ?? "top"}
      className={cx(className, styles.ButtonWrapper)}
      style={props.style}
      theme={theme}
      darkMode={tooltipDarkMode ?? darkMode}
      withPortal={tooltipWithPortal}
    >
      <button
        aria-label={label}
        className={cx(
          styles.Button,
          !iconOnly && styles[emphasis.toLowerCase()],
          !iconOnly && styles[theme],
          !iconOnly && styles[buttonSize],
          !iconOnly && fontWeight && styles[fontWeight.toLowerCase()],
          {
            [styles.loading]: loading,
            [styles.disabled]: disabled || loading,
            [styles.hasIcon]: icon,
            [styles.darkMode]: darkMode,
            [styles.iconOnly]: iconOnly,
            [styles.withActionIcon]: !!actionIcon,
          }
        )}
        aria-disabled={disabled ? "true" : "false"}
        data-testid="button-container"
        onClick={disabled || loading ? undefined : onClick}
        onFocus={onFocus ?? undefined}
        onBlur={onBlur}
        {...props}
      >
        {label && !iconOnly && (
          <div className={styles.contentWrapper}>
            {icon}
            <span className={styles.label}>{label}</span>

            {actionIcon &&
              cloneElement(actionIcon, {
                className: styles.actionIcon,
              })}
          </div>
        )}

        {iconOnly && (
          <div
            className={cx(styles.iconWrapper, styles[buttonSize], {
              [styles.navButton]: navButton,
            })}
          >
            {icon}
          </div>
        )}
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      </button>
    </Tooltip>
  );
};

export { Button };
