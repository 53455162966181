import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useToggle } from "react-use";
import { isNil, isEmpty } from "lodash";
import { get } from "@skillup/helpers";
import { TrainingUtils } from "@skillup/training-bridge";

import { ScheduleRowOperationsRoute } from "@skillup/espace-rh-bridge";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";

import Colors from "uiAssets/Colors";
import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";
import Acta from "utils/Acta";
import User from "utils/User";
import { buildRequest } from "utils/buildRequest";

import styles from "./EditLeadModal.module.scss";
import useTypedFetch from "hooks/useTypedFetch";

interface Props {
  rows: string[];
  reload: () => void;
}

const EditLeadModal = ({ rows, reload }: Props) => {
  const { data, loading } = useTypedFetch<ScheduleRowOperationsRoute.Details>({
    method: "POST",
    path: "/scheduleRow/operations",
    payload: { rows },
  });

  const row = useMemo(() => data && data[0], [data]);

  const [submitting, toggleSubmitting] = useToggle(false);
  const [sessionPrice, setSessionPrice] = useState<string>();
  const [sessionPriceWithTax, setSessionPriceWithTax] = useState<string>();
  const [discountRate, setDiscountRate] = useState<string>();
  const [total, setTotal] = useState<string>();
  const [totalWithTax, setTotalWithTax] = useState<string>();
  const [invoicingTermsRate, setInvoicingTermsRate] = useState<string>();

  const { i18n } = useTranslation();
  const settings = useSettings();

  useEffect(() => {
    if (row && !loading) {
      setSessionPrice(
        !isNil(get(row, "lead.sessionPrice")) ? get(row, "details.sessionPrice") : null
      );
      setSessionPriceWithTax(
        !isNil(get(row, "lead.sessionPriceWithTax"))
          ? get(row, "details.sessionPriceWithTax")
          : null
      );
      setDiscountRate(
        !isNil(get(row, "lead.discountRate")) ? get(row, "details.discountRate") : null
      );
      setTotal(!isNil(get(row, "lead.total")) ? get(row, "details.total") : null);
      setTotalWithTax(
        !isNil(get(row, "lead.totalWithTax")) ? get(row, "details.totalWithTax") : null
      );
      setInvoicingTermsRate(
        !isNil(get(row, "lead.invoicingTermsRate")) ? get(row, "details.invoicingTermsRate") : null
      );
    }
  }, [row, loading]);

  const handleSubmit = useCallback(async (): Promise<void> => {
    toggleSubmitting(true);

    try {
      const properties = {
        sessionPrice: isEmpty(sessionPrice) ? null : Math.round(parseFloat(sessionPrice) * 100),
        sessionPriceWithTax:
          !User.isCompanyWithTax() || isEmpty(sessionPriceWithTax)
            ? null
            : Math.round(parseFloat(sessionPriceWithTax) * 100),
        discountRate: isEmpty(discountRate) ? null : Math.round(parseFloat(discountRate) * 100),
        total: isEmpty(total) ? null : Math.round(parseFloat(total) * 100),
        totalWithTax:
          !User.isCompanyWithTax() || isEmpty(totalWithTax)
            ? null
            : Math.round(parseFloat(totalWithTax) * 100),
        invoicingTermsRate: isEmpty(invoicingTermsRate)
          ? null
          : Math.round(parseFloat(invoicingTermsRate) * 100),
      };

      await buildRequest<ScheduleRowOperationsRoute.Leads>({
        method: "POST",
        path: "/scheduleRow/operations/leads",
        payload: rows.map((uuid) => ({ uuid, properties })),
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message: "Ligne mise à jour avec succès.",
        type: "success",
      });

      Acta.dispatchEvent("closeModal");

      reload();
    } catch (error) {
      console.error(error);
      Acta.dispatchEvent("sendAppMessage", {
        message: "La demande a échoué.",
        type: "error",
      });
    } finally {
      toggleSubmitting(false);
    }
  }, [
    toggleSubmitting,
    sessionPrice,
    sessionPriceWithTax,
    discountRate,
    total,
    totalWithTax,
    invoicingTermsRate,
    rows,
    reload,
  ]);

  const priceToString = (price: number | undefined) =>
    TrainingUtils.ScheduleRow.formatScheduleRowPrice({
      price,
      options: {
        locale: i18n.language,
        currency: settings?.settings?.currency,
      },
    });

  return (
    <div className={styles.CancelModal}>
      <div>
        <p>
          Prix HT de la formation au catalogue : {priceToString(get(row, "details.trainingPrice"))}
        </p>
        {User.isCompanyWithTax() && (
          <p>
            Prix TTC de la formation au catalogue :{" "}
            {priceToString(get(row, "details.trainingPriceWithTax"))}
          </p>
        )}
        <p>
          Prix HT de la session au catalogue :{" "}
          {priceToString(get(row, "details.trainingSessionPrice"))}
        </p>
        {User.isCompanyWithTax() && (
          <p>
            Prix TTC de la session au catalogue :{" "}
            {priceToString(get(row, "details.trainingSessionPriceWithTax"))}
          </p>
        )}
        <p>
          Taux de commission organisme :{" "}
          {get(row, "details.trainingOrganizationLegalTermsRate", "-")} %
        </p>
        <p>Accord cadre : {get(row, "details.trainingDiscountRate", "-")} %</p>
        <p>
          Prix HT de la formation avec remise :{" "}
          {priceToString(get(row, "details.trainingPriceWithDiscount"))}
        </p>
        {User.isCompanyWithTax() && (
          <p>
            Prix TTC de la formation avec remise :{" "}
            {priceToString(get(row, "details.trainingPriceWithDiscountAndTax"))}
          </p>
        )}
        <p>
          Prix HT de la session avec remise :{" "}
          {priceToString(get(row, "details.trainingSessionPriceWithDiscount"))}
        </p>
        {User.isCompanyWithTax() && (
          <p>
            Prix TTC de la session avec remise :{" "}
            {priceToString(get(row, "details.trainingSessionPriceWithDiscountAndTax"))}
          </p>
        )}
        <TextInput
          autoComplete="off"
          alwaysOpen
          key="sessionPrice"
          label="Prix HT unitaire session"
          name="sessionPrice"
          type="number"
          min={0}
          onChange={(_e, value) => setSessionPrice(value)}
          defaultValue={sessionPrice}
          required
        />
        {User.isCompanyWithTax() && (
          <TextInput
            autoComplete="off"
            alwaysOpen
            key="sessionPriceWithTax"
            label="Prix TTC unitaire session"
            name="sessionPriceWithTax"
            type="number"
            min={0}
            onChange={(_e, value) => setSessionPriceWithTax(value)}
            defaultValue={sessionPriceWithTax}
            required
          />
        )}
        <TextInput
          autoComplete="off"
          alwaysOpen
          key="discountRate"
          label="Accord cadre"
          name="discountRate"
          type="number"
          min={0}
          onChange={(_e, value) => setDiscountRate(value)}
          defaultValue={discountRate}
          required
        />
        <TextInput
          autoComplete="off"
          alwaysOpen
          key="total"
          label="Total HT"
          name="total"
          type="number"
          min={0}
          onChange={(_e, value) => setTotal(value)}
          defaultValue={total}
          required
        />
        {User.isCompanyWithTax() && (
          <TextInput
            autoComplete="off"
            alwaysOpen
            key="totalWithTax"
            label="Total TTC"
            name="totalWithTax"
            type="number"
            min={0}
            onChange={(_e, value) => setTotalWithTax(value)}
            defaultValue={totalWithTax}
            required
          />
        )}
        <TextInput
          autoComplete="off"
          alwaysOpen
          key="invoicingTermsRate"
          label="Taux de commission"
          name="invoicingTermsRate"
          type="number"
          min={0}
          onChange={(_e, value) => setInvoicingTermsRate(value)}
          defaultValue={invoicingTermsRate}
          required
        />
      </div>
      <InteractiveButton
        label={"Enregistrer"}
        onClick={handleSubmit}
        background={Colors.gradientBlue}
        style={{ fontWeight: 500 }}
        size="small"
        loading={submitting}
      />
    </div>
  );
};

export default EditLeadModal;
