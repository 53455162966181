import { useCallback, useState, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSFormGroupTextInput, DatePicker, DSButton, Modal } from "@skillup/ui";
import { Future } from "@skillup/monads";
import { ParseDate, FormatDate } from "@skillup/shared-utils";

import { Tracking } from "../../../state/tracking";

import { formatError } from "services/errors";
import { buildSafeRequest } from "utils/buildRequest";

import type { TrackingRoutes } from "types/api";

import styles from "./UpdateValidityModal.module.scss";

export interface Props {
  tracking: Tracking;
  onClose: () => void;
}

enum ModalState {
  Loading,
  Idle,
}

const UpdateValidityModal = ({ tracking, onClose }: Props) => {
  const [date, setDate] = useState<string>(
    tracking.validSince == null
      ? undefined
      : FormatDate.ToStringFormat(ParseDate.FromISO(tracking.validSince), "yyyy-MM-dd")
  );
  const [state, setState] = useState<ModalState>(ModalState.Idle);

  const { addToast } = useToasts();
  const { t } = useTranslation();

  const title = useMemo(() => {
    return tracking.validSince == null
      ? "Ajouter une date de validité"
      : "Modifier la date de validité";
  }, [tracking]);

  const handleSubmit = useCallback(async () => {
    setState(ModalState.Loading);
    const request = await buildSafeRequest<TrackingRoutes.UpdateValidity>({
      method: "POST",
      params: { trackingUuid: tracking.uuid },
      payload: { validityDate: date },
      path: "/tracking/{trackingUuid}/update-validity",
    });

    await Future.unwrap(
      request.run(),
      (error) => handleError(addToast, t, error, setState),
      () => handleSuccess(addToast, t, onClose)
    );
  }, [tracking, date, addToast, t, onClose]);

  return (
    <Modal size="big" title={title} onClose={onClose} disableOnClickAway>
      <div className={styles.updateValidityModal}>
        <DSFormGroupTextInput label="Date de validité">
          <DatePicker
            value={date && new Date(date)}
            onChange={(value) => setDate(value?.toISOString())}
            autoFormat
            autoFocus
          />
        </DSFormGroupTextInput>

        <div className={styles.actions}>
          <DSButton label="Annuler" emphasis="Low" onClick={onClose} />
          <DSButton
            loading={state === ModalState.Loading}
            disabled={date == null}
            label="Confirmer"
            emphasis="High"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default UpdateValidityModal;

function handleSuccess(addToast, t, onClose: () => void) {
  addToast(
    t("tracking.updateValidity.success", {
      defaultValue: "La date de validité a bien été mise à jour.",
    }),
    {
      appearance: "success",
      autoDismiss: true,
    }
  );
  onClose();
}

function handleError(addToast, t, error: string, setState) {
  addToast(
    formatError(t, error, {
      defaultValue:
        "Une erreur est survenue lors de l'enregistrement de la nouvelle date de validité.",
    }),
    {
      appearance: "error",
      autoDismiss: true,
    }
  );

  setState(ModalState.Idle);
}
