import { ITrainingDataCall } from "@skillup/types";
import DateUtils from "utils/dates";

type Session = ITrainingDataCall["sessions"][0];

export const getSessionDateWithHours = (session) => {
  const startDateRange = DateUtils.parseDateRange(session.dates?.[0]?.split("/"), {
    dateFormat: "ddd D MMM yyyy",
  });
  return {
    value: session.uuid,
    label: `${startDateRange.days[0]}${
      startDateRange.hours ? ` | ${startDateRange.hours[0]}` : ""
    }`,
  };
};

export const mapSessions = (sessions = [] as Array<Session>) =>
  sessions.map((session) => getSessionDateWithHours(session));

export const mapPlaces = (places = [] as Array<string>) =>
  places.map((place) => ({
    label: place,
    value: place,
  }));

export const computeCurrentPlace = (placeFromQuery, sessionsByPlace) => {
  const places = Object.keys(sessionsByPlace);
  const fallBack = places[0];

  if (placeFromQuery) {
    return places.find((place) => placeFromQuery.includes(place)) ?? fallBack;
  }

  return fallBack;
};
