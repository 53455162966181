import { isEqual } from "lodash";
import { CampaignsRoutes, InterviewPageChild, InterviewSection, InterviewSectionPage } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";
import { Structure } from "../useCampaign";

type EditStructureParams = { 
  campaignUuid: string;
  sections?: Structure["sections"];
  // title?: string;
  // targetsCategories?: Structure["targetsCategories"];
  // targetCategories?: { uuid?: string; label: string }[];
  // choicesForOrdinalTargets?: Structure["choicesForOrdinalTargets"];
  structure: Structure
 };

async function editStructure(params: EditStructureParams): Promise<Structure | undefined> {
  try {
    const {
      campaignUuid,
      // title,
      // type,
      sections,
      // targetsCategories,
      // targetCategories,
      // choicesForOrdinalTargets,
      structure
    } = params;

    const changes = sections.reduce((acc, section) => {
      const structureSection = structure.sections.find(s => s.uuid === section.uuid);
      const updatedSection = filterUpdated(section, structureSection, "pages");
      const sectionPayload = updatedSection ? {node_type: "InterviewSection", uuid: section.uuid, ...updatedSection} : undefined;

      const pagesPayload = section.pages.map(page => {
        const structurePage = structureSection.pages.find(p => p.uuid === page.uuid);
        const updatedPage = filterUpdated(page, structurePage, "children");
        const pagePayload = updatedPage ? {node_type: "SectionPage", uuid: page.uuid, ...updatedPage} : undefined;

        const childrenPayload = page.children.map((child) => {
          const updatedPageChild = filterUpdated(child, structurePage.children.find(c => c.uuid === child.uuid));
          return updatedPageChild ? {node_type: "PageChild", uuid: child.uuid, kind: child.kind, ...updatedPageChild} : undefined;
        })
        return [pagePayload, ...childrenPayload];
      });
      return acc.concat(sectionPayload).concat(...pagesPayload);
    }, []).filter(Boolean)
    
    // [CSB] we do not allow to edit the structure / campaign's title for now
    // if (title) {
    //   changes.concat({
    //     node_type: "Structure",
    //     title,
    //   })
    // }
   
    const response = await buildRequest<CampaignsRoutes.UpdateStructure>({
      method: "PUT",
      path: "/campaigns/{uuid}/structure",
      payload: {
        campaign_uuid: campaignUuid,
        updated: changes,
      },
      params: { uuid: campaignUuid },
    })();

    return response;
  } catch (err) {
    throw new Error("Edit structure unsuccessful", err);
  }
}

export { editStructure, type EditStructureParams };

type StructureItem = InterviewSection | InterviewSectionPage | InterviewPageChild;

function filterUpdated(save: StructureItem, structure: StructureItem, omit?: string) {
  if (!structure) {
    return save;
  }

  const diffProperties = getUpdatedKeys({ structure, save, omit });
  if (!diffProperties) return undefined;

  return {
    uuid: structure.uuid,
    ...diffProperties,
  };
}

function getUpdatedKeys<T>({ structure, save, omit }: { structure: T; save: T; omit?: string }) {
  if (isEqual(structure, save)) return undefined;

  const diffKeys = Object.keys(save).filter((key) =>
    omit ? key !== omit && !isEqual(structure[key], save[key]) : !isEqual(structure[key], save[key])
  );
  if (diffKeys.length === 0) return undefined;

  return diffKeys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: save[key] === undefined ? null : save[key],
    };
  }, {});
}
