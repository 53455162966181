import React from "react";
import { ContextProvider } from "./Context";

import UserTable from "./components/UserTable";

import styles from "./SupervisorUserView.module.scss";

export default () => {
  return (
    <ContextProvider>
      <div className={styles.supervisorUserView}>
        <UserTable />
      </div>
    </ContextProvider>
  );
};
