import React from "react";

import { Project } from "../getProjects";
import Acta from "utils/Acta";

import DeleteConfirmationModal from "../../Components/DeleteConfirmationModal";

const showDeleteConfirmationModal = (project: Project, refetchList: VoidFunction) => {
  Acta.setState("modalDisplayed", {
    title: "Supprimer la session",
    size: "small",
    content: <DeleteConfirmationModal refetchList={refetchList} project={project} />,
  });
};

export default showDeleteConfirmationModal;
