const accountOptions = [
  {
    name: "Je possède un compte Skillup",
    value: "existing",
  },
  {
    name: "Je crée un compte Skillup",
    value: "new",
  },
];

const userOptions = [
  {
    name: "Je suis une entreprise",
    value: "b2b",
  },
  {
    name: "Je suis un particulier",
    value: "b2c",
  },
];

const genderOptions = [
  {
    name: "Civilité",
    value: "undefined",
  },
  {
    name: "Monsieur",
    value: "male",
  },
  {
    name: "Madame",
    value: "female",
  },
];

const opcaOptions = [
  {
    name: "OPCA affilié",
    value: "undefined",
  },
  {
    name: "ACTALIANS",
    value: "actalians",
  },
  {
    name: "ADEFIM",
    value: "adefim",
  },
  {
    name: "AFDAS",
    value: "afdas",
  },
  {
    name: "AGECIF CAMA",
    value: "agecif-cama",
  },
  {
    name: "AGECIF IEG",
    value: "agecif-ieg",
  },
  {
    name: "AGECIF RATP",
    value: "agecif-ratp",
  },
  {
    name: "AGECIF SNCF",
    value: "agecif-sncf",
  },
  {
    name: "AGEFOS",
    value: "agefos",
  },
  {
    name: "ANFA",
    value: "anfa",
  },
  {
    name: "ANFH",
    value: "anfh",
  },
  {
    name: "ANPE",
    value: "anpe",
  },
  {
    name: "ASCBC",
    value: "ascbc",
  },
  {
    name: "AUVICOM",
    value: "auvicom",
  },
  {
    name: "CONSTRUCTYS",
    value: "constructys",
  },
  {
    name: "FAF SECURITE SOCIALE",
    value: "faf-securite-sociale",
  },
  {
    name: "FAFIEC",
    value: "fafiec",
  },
  {
    name: "FAFIH",
    value: "fafih",
  },
  {
    name: "FAFSEA",
    value: "fafsea",
  },
  {
    name: "FAFTT",
    value: "faftt",
  },
  {
    name: "FONGECIF",
    value: "fongecif",
  },
  {
    name: "FORCO",
    value: "forco",
  },
  {
    name: "INTERGROS",
    value: "intergros",
  },
  {
    name: "OPCA 3+",
    value: "opca-3-plus",
  },
  {
    name: "OPCA BAIA",
    value: "opca-baia",
  },
  {
    name: "OPCA DEFI",
    value: "opca-defi",
  },
  {
    name: "OPCA HABITAT FORMATION",
    value: "opca-habitat-formation",
  },
  {
    name: "OPCAIM",
    value: "opcaim",
  },
  {
    name: "OPCALIA",
    value: "opcalia",
  },
  {
    name: "OPCALIM",
    value: "opcalim",
  },
  {
    name: "TRANSPORTS ET SERVICES",
    value: "transports-et-services",
  },
  {
    name: "UNAGECIF",
    value: "unagecif",
  },
  {
    name: "UNIFAF",
    value: "unifaf",
  },
  {
    name: "UNIFORMATION",
    value: "uniformation",
  },
];

const states = [
  {
    label: "Demande invalide",
    value: "invalid",
  },
  {
    label: "Demande en cours de traitement",
    value: "processing",
  },
  {
    label: "Demande en cours de traitement",
    value: "submitted",
  },
  {
    label: "Demande en cours d’annulation",
    value: "cancelling",
  },
  {
    label: "Demande acceptée",
    value: "won",
  },
  {
    label: "Formation réalisée et clôturée",
    value: "closed",
  },
  {
    label: "Formation réalisée",
    value: "attended",
  },
  {
    label: "Demande annulée",
    value: "lost",
  },
];

enum UploadTypes {
  "quote" = "Devis",
  "convention" = "Convention",
  "convocation" = "Convocation",
  "invoice" = "Facture",
  "timesheet" = "Feuille de présence",
  "training-certificate" = "Attestation de formation",
  "other" = "Divers",
}

export { accountOptions, userOptions, genderOptions, opcaOptions, states, UploadTypes };

export default {
  accountOptions,
  genderOptions,
  opcaOptions,
  states,
  userOptions,
  UploadTypes,
};
