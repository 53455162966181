import cx from "classnames";
import styles from "./ErrorPage.module.scss";

interface Props {
  readonly className?: string;
  readonly message: string;
  readonly iconSrc: string;
}
export default function ErrorPage(props: Props) {
  const { message, iconSrc, className } = props;
  return (
    <div className={cx(styles.ErrorPage, className)}>
      <img src={iconSrc} />
      {message && <p>{message}</p>}
    </div>
  );
}
