import { TemplateRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type Template = TemplateRoutes.GetTemplatesRoute["response"][number];

export async function getTemplates() {
  const result = await buildRequest<TemplateRoutes.GetTemplatesRoute>({
    method: "GET",
    path: `/templates`,
    target: "PEOPLE_REVIEW",
  })();

  return result;
}
