import { useState, useCallback, useEffect } from "react";
import { buildRequest, buildFileRequest } from "utils/buildRequest";

import type { OrganizationRoutesType, OrganizationRoutesTypeChild } from "types/api";
import type { GetSettingsForCompany } from "types/api";
import type { PortailUploadImage } from "types/api";

export type Settings = GetSettingsForCompany["response"]["properties"];
export type OrganizationDetails = OrganizationRoutesType.GetByUuid["response"];
export type UpdatePayload = OrganizationRoutesType.Update["payload"];
export type CreateChildPayload = OrganizationRoutesTypeChild.Create["payload"];
export type UpdateChildPayload = OrganizationRoutesTypeChild.Update["payload"];

export interface Props {
  readonly companyUuid: string;
}

const useSubCompanies = ({ companyUuid }: Props) => {
  const [loading, setLoading] = useState(false);
  const [currentCompany, setCurrentCompany] = useState<OrganizationDetails | undefined>(undefined);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const company = await buildRequest<OrganizationRoutesType.GetByUuid>({
        method: "GET",
        path: "/organization/{companyUuid}",
        params: { companyUuid },
      })();
      setCurrentCompany(company);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [setLoading, setCurrentCompany, companyUuid]);

  const update = useCallback(
    async (payload: UpdatePayload) => {
      setLoading(true);
      try {
        const company = await buildRequest<OrganizationRoutesType.Update>({
          method: "POST",
          path: "/organization/{companyUuid}",
          params: { companyUuid },
          payload,
        })();
        setCurrentCompany(company);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        throw err;
      }
    },
    [setLoading, setCurrentCompany, companyUuid]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  const createChild = useCallback(
    async (payload: CreateChildPayload) => {
      setLoading(true);
      try {
        const createdChildUuid = await buildRequest<OrganizationRoutesTypeChild.Create>({
          method: "POST",
          path: "/organization/{companyUuid}/child",
          params: { companyUuid },
          payload,
        })();
        await fetch();
        setLoading(false);
        return createdChildUuid;
      } catch (err) {
        setLoading(false);
        throw err;
      }
    },
    [setLoading, fetch, companyUuid]
  );

  const updateChild = useCallback(
    async ({ childUuid, payload }: { childUuid: string; payload: UpdateChildPayload }) => {
      setLoading(true);
      try {
        await buildRequest<OrganizationRoutesTypeChild.Update>({
          method: "POST",
          path: "/organization/{companyUuid}/child/{childUuid}",
          params: { companyUuid, childUuid },
          payload,
        })();
        await fetch();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        throw err;
      }
    },
    [setLoading, fetch, companyUuid]
  );

  const deleteChild = useCallback(
    async ({ childUuid }: { childUuid: string }) => {
      setLoading(true);
      try {
        await buildRequest<OrganizationRoutesTypeChild.Delete>({
          method: "DELETE",
          path: "/organization/{companyUuid}/child/{childUuid}",
          params: { companyUuid, childUuid },
        })();
        await fetch();
        setLoading(false);
      } catch (err) {
        setLoading(false);
        throw err;
      }
    },
    [setLoading, fetch, companyUuid]
  );

  const uploadLogo = useCallback(
    async (companyUuid: string, file: File) => {
      setLoading(true);
      try {
        await buildFileRequest<PortailUploadImage>({
          method: "POST",
          path: "/portal/settings/image/{companyUuid?}",
          params: { companyUuid },
          query: { target: "portalLogo" },
          file,
        })();
        await fetch();
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [setLoading, fetch]
  );

  return {
    company: currentCompany,
    update,
    createChild,
    updateChild,
    refresh: fetch,
    deleteChild,
    uploadLogo,
    loading,
  };
};

export default useSubCompanies;
