import { useToasts } from "react-toast-notifications";

import { DSDropdown, DSDropdownItem } from "@skillup/ui";
import { ICompanyField } from "@skillup/training-bridge";
import { FieldRoutesType } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import useTranslation from "hooks/useTranslation";

import { useFieldRefetch } from "../context";

type Props = {
  field: ICompanyField;
};

const ActionsCell = ({ field }: Props) => {
  const refetch = useFieldRefetch();
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const isDeleted = field.deletedAt !== null || field.deletedAt === undefined;

  const toggleHideField = async () => {
    try {
      await buildRequest<FieldRoutesType.UpdateV2>({
        method: "POST",
        path: "/fields-v2",
        payload: {
          ...field,
          isHidden: !field.isHidden,
          deletedAt: isDeleted ? null : new Date().toISOString(),
        },
      })();

      refetch();
    } catch {
      addToast(
        t("field.config.error.query", {
          defaultValue: "Une erreur est survenue au chargement des champs.",
        }),
        {
          appearance: "error",
        }
      );
    }
  };

  const buttonLabel =
    field.isHidden || isDeleted
      ? t("field.action.label.show", { defaultValue: "[OPS] Afficher" })
      : t("field.action.label.hide", { defaultValue: "[OPS] Masquer" });

  return (
    <DSDropdown buttonSize="S" overflow="initial">
      <DSDropdownItem label={buttonLabel} onClick={toggleHideField} />
    </DSDropdown>
  );
}

export default ActionsCell;
