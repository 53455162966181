import React from "react";

import { Child } from "../types";
import DisallowBlock, { commentRestrictions } from "./DisallowBlock";

import { InterviewRoles } from "types/Interview";

type FileUpload = Child & { kind: "upload" };

const FilesUploadBlock = ({
  data,
  updateChild,
}: {
  data: FileUpload;
  updateChild: (newChildData: Child) => void;
}) => {
  return (
    <div>
      <DisallowBlock
        data={data}
        updateChild={updateChild}
        restrictions={[
          ...commentRestrictions,
          {
            action: "upload",
            label: "Empêcher l'ajout de fichiers par le collab",
            role: InterviewRoles.EMPLOYEE,
          },
          {
            action: "upload",
            label: "Empêcher l'ajout de fichiers par le manager",
            role: InterviewRoles.MANAGER,
          },
        ]}
      />
    </div>
  );
};
export default FilesUploadBlock;
