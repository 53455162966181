import { useEffect, useMemo, useState } from "react";

import { DSModal, DSRadio, DSRadioGroup, DSTooltip, Select } from "@skillup/ui";
import { Flex } from "@skillup/design-system";
import { EditableCompanyField } from "@skillup/training-bridge";

import useTranslation from "hooks/useTranslation";

import styles from "../Form.module.scss";

type Props = {
  closeModal: () => void;
  field: EditableCompanyField;
  optionToDelete: EditableCompanyField["options"][number];
  setFormOptions: (options: EditableCompanyField["options"]) => void;
};

const DeleteOptionModal = ({ optionToDelete, closeModal, field, setFormOptions }: Props) => {
  const { t } = useTranslation();
  const [deleteValue, setDeleteValue] = useState(false);
  const [replaceOption, setReplaceOption] = useState();
  const { options, label: fieldLabel } = field;

  const optionsToSelect = useMemo(
    () =>
      options
        .filter((option) => !option.disabled)
        .map((option) => ({ label: option.value, value: option.key })) || [],
    [options]
  );

  const optionsIsEmpty = optionsToSelect.length < 1;
  const isOpen = useMemo(() => Object.keys(optionToDelete).length > 0, [optionToDelete]);

  const handleSubmit = () => {
    const optionsForUpdate = options.filter((option) => option.key !== optionToDelete.key);

    if (deleteValue) {
      setFormOptions(optionsForUpdate);
    } else {
      setFormOptions([...optionsForUpdate, { ...optionToDelete, fallback: replaceOption }]);
    }
    closeModal();
  };

  useEffect(() => {
    if (optionsIsEmpty) {
      setDeleteValue(true);
    }
  }, [optionsIsEmpty]);

  return (
    <DSModal className={styles.DeleteOptionModal} isOpen={isOpen}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("fields.config.form.deleteModal.title", {
            defaultValue: "Remplacement de la valeur du champ",
          })}
        />
      </DSModal.Header>
      <DSModal.Content>
        <Flex gap="s" flexDirection="column">
          <p>
            {t("fields.config.form.deleteModal.content", {
              defaultValue:
                'Que voulez-vous faire pour les lignes du plan ayant "{{ option }}" comme "{{ field }}" ?',
              option: optionToDelete.value,
              field: fieldLabel,
            })}
          </p>
          <DSRadioGroup name="deleteRadioGroup">
            <DSTooltip
              label={t("fields.config.form.deleteModal.replace.disabled", {
                defaultValue: "Aucune valeur de remplacement n’est disponible",
              })}
            >
              <DSRadio
                label={t("fields.config.form.deleteModal.replace", {
                  defaultValue: "Remplacer la valeur par autre valeur",
                })}
                disabled={optionsIsEmpty}
                onChange={() => setDeleteValue(false)}
              />
            </DSTooltip>

            <Select
              className={styles.selectReplaceOptions}
              placeholder={t("fields.config.form.deleteModal.replacePlaceholder", {
                defaultValue: "Sélectionner une valeur de remplacement",
              })}
              disabled={optionsIsEmpty}
              options={optionsToSelect}
              onChange={(option) => setReplaceOption(option)}
            />
            <DSRadio
              label={t("fields.config.form.deleteModal.delete", {
                defaultValue: "Supprimer la valeur",
              })}
              checked={deleteValue}
              onChange={() => setDeleteValue(true)}
            />
          </DSRadioGroup>
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          label={t("fields.config.form.deleteModal.cancel", { defaultValue: "Annuler" })}
          onClick={closeModal}
        />
        <DSModal.Footer.PrimaryButton
          label={t("fields.config.form.deleteModal.confirm", {
            defaultValue: "Modifier",
          })}
          disabled={!deleteValue && !replaceOption}
          onClick={handleSubmit}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default DeleteOptionModal;
