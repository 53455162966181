import React, { Fragment, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";

import useFetch from "hooks/useFetch";

interface IRouteMatch {
  readonly uuid: string;
}

interface IRedirectDataCall {
  readonly path: string;
}

const UuidRedirect = (): JSX.Element => {
  const match = useRouteMatch<IRouteMatch>("/uuid/:uuid");

  const { data, loading } = useFetch<IRedirectDataCall>({
    target: "API",
    url: `/v1/redirect/${match?.params.uuid}`,
  });

  useEffect(() => {
    if (!loading) {
      window.location.pathname = data?.path
        ? `/responsable/programmes/catalogue/${data.path}`
        : "/";
    }
  }, [data, loading]);

  return <Fragment />;
};

export default UuidRedirect;
