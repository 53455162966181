import { useRef, useEffect, useState } from "react";
import { useToggle, useSetState } from "react-use";
import type { IOrganization, ITraining, IUser, ITrainingDataCall } from "@skillup/types";
import { isNil, isUndefined } from "lodash";

import { computeFullName } from "utils/User";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import User from "utils/User";
import { analytics } from "services";

import {
  arobase,
  position,
  user as userIcon,
  phone as phoneIcon,
  cross,
} from "uiAssets/StrokeIcons";

import TextInput from "components/TextInput";
import DropDown, { DropDownOptions } from "components/DropDown";
import InteractiveButton from "components/InteractiveButton";
import Icon from "components/Icon";
import Error from "components/Error";

import styles from "./BookingModalStyles.module.scss";
import { mapSessions, mapPlaces, computeCurrentPlace } from "./utils";

import PlaceAndDatePicker from "./PlaceAndDatePicker";
import { buildRequest } from "utils/buildRequest";
import { BookingRoutes } from "@skillup/espace-rh-bridge";

export interface IProperties extends ITraining {
  name: string;
  seoSlug: string;
  uuid: string;
}

export interface IProps {
  properties: IProperties;
  organization: IOrganization;
  trainingDuration: string;
  sessions: ITrainingDataCall["sessions"];
  sessionsByPlace: {
    [key: string]: ITrainingDataCall["sessions"];
  };
  placeFromQuery?: {
    place: string;
  };
  mode: "byTrainee" | "forTrainee";
}

export interface IState {
  hasSubmitted: boolean;
  currentSession?: string;
  currentPlace?: string;
  possibleDates?: {
    label: string;
    value: string;
  }[];
  possiblePlaces: {
    label: string;
    value: string;
  }[];
  hasError: string | null;
  messages: {
    byTrainee?: string;
    forTrainee?: string;
  };
}

export default (props: IProps): JSX.Element | null => {
  const { organization, mode } = props;

  const [managerEmail, setManagerEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<string>();
  const [phone, setPhone] = useState<string>();

  const [loading, toggleLoading] = useToggle(false);
  const scheduleDropdown = useRef<DropDown<DropDownOptions<string>>>(null);

  const [state, setState] = useSetState<IState>({
    possiblePlaces: [],
    hasSubmitted: false,
    hasError: null,
    messages: {
      byTrainee:
        "Après avoir envoyé la demande, vous pourrez la consulter dans l‘onglet plan de formation",
      forTrainee:
        "Après avoir confirmé l‘inscription, vous pourrez la consulter dans l‘onglet inscription",
    },
  });

  const canDisplayPhoneInput = state.hasSubmitted && organization.traineePhoneRequired;

  if (!props.sessionsByPlace) {
    return null;
  }

  useEffect(() => {
    const { sessionsByPlace, placeFromQuery } = props;

    if (sessionsByPlace) {
      const currentPlace = computeCurrentPlace(placeFromQuery, sessionsByPlace);
      const possibleDates = mapSessions(sessionsByPlace[currentPlace]);
      const possiblePlaces = mapPlaces(Object.keys(sessionsByPlace));

      setState({
        currentPlace,
        possibleDates,
        possiblePlaces,
      });
    }
  }, [props, setState]);

  const onSetSession = (currentSession: string): void => {
    setState({ currentSession });
  };

  const onSetPlace = (currentPlace: string): void => {
    const { sessionsByPlace } = props;

    const possibleDates = mapSessions(sessionsByPlace[currentPlace]);

    setState({
      currentSession: undefined,
      currentPlace,
      possibleDates,
    });
  };

  const getUserByEmail = async (): Promise<IUser> => {
    const { mode } = props;
    try {
      const { properties } = (await DataLayer.request({
        method: "GET",
        url: `/v1/user/get-by-email?email=${encodeURIComponent(email ?? "")}`,
      })) as { properties: IUser };

      return properties;
    } catch (error) {
      if (error.statusCode === 404) {
        setState({
          hasSubmitted: true,
          messages: {
            [mode]:
              "Ce stagiaire ne figure pas dans la base collaborateurs. Merci de renseigner les champs suivants :",
          },
        });
      }
      return undefined;
    }
  };

  const subscribeTrainees = async (trainee, isNewUser) => {
    const { properties, mode } = props;

    try {
      const bookByTrainee = mode === "forTrainee" ? false : true;

      const schedule = scheduleDropdown?.current?.getValue();

      if (bookByTrainee) {
        const data = {
          properties: [{ binding: "traineeEmail", value: trainee.email }],
          state: "approved_by_hr",
          training: properties.uuid,
          userData: trainee,
          isNewUser,
        };

        await DataLayer.request({
          body: JSON.stringify(data),
          method: "POST",
          url: `/v1/schedule/create-lead${schedule ? `/${schedule}` : ""}?type=plan`,
        });
      } else {
        const data: BookingRoutes.CreateInterBooking["payload"] = {
          session: state.currentSession,
          trainee,
          schedule,
        };

        await buildRequest<BookingRoutes.CreateInterBooking>({
          method: "POST",
          path: "/booking/booking-inter",
          payload: data,
        })();
      }

      analytics.sendConversion("bookingInter");
      Acta.dispatchEvent("closeModal");

      Acta.dispatchEvent("sendAppMessage", {
        message: bookByTrainee ? "Email envoyé avec succès." : "Inscription réalisée.",
        type: "success",
      });
    } catch (error) {
      if (error.statusCode === 429) {
        Acta.dispatchEvent("sendAppMessage", {
          message:
            "Trop de demandes simultanées. Veuillez patienter un instant et essayer à nouveau de soumettre votre demande.",
          type: "error",
        });
      } else {
        Acta.dispatchEvent("sendAppMessage", {
          message: "Une erreur est survenue ; si elle persiste, veuillez contacter le support.",
          type: "error",
        });
      }
    } finally {
      toggleLoading(false);
    }
  };

  let title =
    mode === "forTrainee" ? "Inscrire le stagiaire" : "Demander au stagiaire de s‘inscrire";

  if (state.hasSubmitted) {
    title = "Stagiaire inconnu";
  }

  const addTrainee = async (e): Promise<void> => {
    e.preventDefault();
    let user;

    const canCheckUser = !state.hasSubmitted;

    if (mode === "forTrainee" && !state.currentSession) {
      setState({ hasError: "Vous devez choisir une session." });
      return;
    }

    if (isUndefined(email)) {
      setState({ hasError: "L'email du stagiaire doit être renseigné." });
      return;
    }

    toggleLoading(true);

    if (canCheckUser) {
      user = await getUserByEmail();
      setState({ hasError: null });

      if (!user) {
        toggleLoading(false);
        return;
      }
    }

    if (state.hasSubmitted && !user) {
      const hasError =
        isUndefined(email) ||
        isUndefined(firstName) ||
        isUndefined(lastName) ||
        isUndefined(role) ||
        (canDisplayPhoneInput && isUndefined(phone));

      if (hasError) {
        setState({ hasError: "Les champs non marqués en optionnel sont requis." });
        toggleLoading(false);
        return;
      }
    }

    subscribeTrainees(
      {
        email,
        firstName: firstName ?? user.firstName,
        fullName: firstName && lastName ? computeFullName({ firstName, lastName }) : user.fullName,
        lastName: lastName ?? user.lastName,
        managerEmail,
        phone,
        role: role ?? user.role,
        invoicingBudgetaryCode: user?.invoicingBudgetaryCode,
        company: user?.company,
        noEmail: user?.noEmail,
      },
      isNil(user)
    );
  };

  const schedules = User.getSchedules().map((s) => ({ label: s.name, value: s.uuid }));
  const activeSchedule = User.getActiveSchedule();

  return (
    <div className={styles.BookingModal}>
      <div className={styles.topBorder} />
      <button className={styles.closeModalButton} onClick={() => Acta.dispatchEvent("closeModal")}>
        <Icon strokeIcon={cross} width={15} />
      </button>
      <form onSubmit={addTrainee} className={styles.addTrainee}>
        <h2>{title}</h2>
        <span style={{ fontSize: 12, marginTop: 10 }}>{state.messages[mode]}</span>
        {!!props.sessions?.length && mode === "forTrainee" && (
          <PlaceAndDatePicker
            currentPlace={state.currentPlace}
            currentSession={state.currentSession}
            possiblePlaces={state.possiblePlaces}
            possibleDates={state.possibleDates}
            onSetSession={onSetSession}
            onSetPlace={onSetPlace}
          />
        )}
        {schedules.length > 1 && (
          <DropDown
            defaultValue={activeSchedule.uuid}
            label="Plan"
            options={schedules}
            placeholder="Sélectionnez un plan"
            ref={scheduleDropdown}
          />
        )}
        <TextInput
          alwaysOpen
          label="Email"
          name="email"
          placeholder="Email du stagiaire"
          onChange={(_, value) => setEmail(value)}
          strokeIcon={arobase}
          style={{ width: "100%", margin: "10px 0" }}
          type="email"
        />
        {state.hasSubmitted && (
          <TextInput
            label="Prénom"
            name="firstName"
            placeholder="Prénom du stagiaire"
            onChange={(_, value) => setFirstName(value)}
            strokeIcon={userIcon}
            style={{ width: "100%", margin: "10px 0" }}
            type="text"
          />
        )}
        {state.hasSubmitted && (
          <TextInput
            label="Nom"
            name="lastName"
            placeholder="Nom du stagiaire"
            onChange={(_, value) => setLastName(value)}
            strokeIcon={userIcon}
            style={{ width: "100%", margin: "10px 0" }}
            type="text"
          />
        )}
        {state.hasSubmitted && (
          <TextInput
            label="E-mail du manager (optionnel)"
            name="managerEmail"
            placeholder="E-mail du manager"
            onChange={(_, value) => setManagerEmail(value)}
            strokeIcon={userIcon}
            style={{ width: "100%", margin: "10px 0" }}
            type="text"
          />
        )}
        {state.hasSubmitted && (
          <TextInput
            label="Poste / Titre"
            name="role"
            placeholder="Poste ou titre du stagiaire"
            onChange={(_, value) => setRole(value)}
            strokeIcon={position}
            style={{ width: "100%", margin: "10px 0" }}
            type="text"
          />
        )}
        {canDisplayPhoneInput && (
          <TextInput
            label="Téléphone"
            name="phone"
            placeholder="Téléphone du stagiaire"
            onChange={(_, value) => setPhone(value)}
            strokeIcon={phoneIcon}
            style={{ width: "100%", margin: "10px 0" }}
            type="tel"
          />
        )}
        {state.hasError && <Error message={state.hasError} />}
        <InteractiveButton
          label={mode === "forTrainee" ? "Inscrire" : "Envoyer"}
          loading={loading}
          style={{ margin: "40px auto 0 auto" }}
          type="submit"
          className={styles.submitButton}
        />
      </form>
    </div>
  );
};
