import get from "lodash/get";
import set from "lodash/set";
import isUndefined from "lodash/isUndefined";

import DataLayer, { TargetType } from "utils/DataLayer";

export enum MimeTypeEnum {
  "OCTET" = "application/octet-stream",
  "PDF" = "application/pdf",
  "ZIP" = "application/zip",
  "EXCEL" = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "XLS" = "application/vnd.ms-excel",
}

export type MimeType =
  | "application/octet-stream"
  | "application/pdf"
  | "application/zip"
  | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  | "image/jpeg"
  | "image/jpeg"
  | "image/png";

const downloadFileAsUser = (
  url: string,
  filename: string,
  target?: TargetType,
  mimeType?: MimeType,
  openOnDownload: boolean = false
): Promise<void> =>
  new Promise((resolve, reject) =>
    DataLayer.request({
      method: "GET",
      responseType: "arraybuffer",
      target: target ?? "PORTAL",
      url,
    })
      .then((data) => {
        const blob =
          typeof File === "function"
            ? new File([data as ArrayBuffer], filename, {
                type: mimeType ?? "application/octet-stream",
              })
            : new Blob([data as BlobPart], { type: mimeType ?? "application/octet-stream" });

        // @ts-ignore msSaveBlob has been removed from type defintion as W3C deprecated it. This is required for IE11 support
        if (typeof window.navigator.msSaveBlob !== "undefined") {
          // @ts-ignore msSaveBlob has been removed from type defintion as W3C deprecated it. This is required for IE11 support
          window.navigator.msSaveBlob(blob, filename);
        } else {
          const URL = get(window, "URL", get(window, "webkitURL"));

          if (isUndefined(URL.createObjectURL)) {
            throw new ReferenceError("createObjectURL is not defined");
          }

          const href = URL.createObjectURL(blob);
          const tmp = document.createElement("a");

          if (isUndefined(tmp.download)) {
            set(window, "location", href);
          } else if (openOnDownload) {
            set(tmp, "href", href);
            set(tmp, "target", "_blank");

            document.body.appendChild(tmp);
            tmp.click();
            document.body.removeChild(tmp);
          } else {
            set(tmp, "href", href);
            set(tmp, "download", filename);

            document.body.appendChild(tmp);
            tmp.click();
            document.body.removeChild(tmp);
          }

          setTimeout(() => {
            URL.revokeObjectURL(href);
            resolve();
          }, 100);
        }
      })
      .catch(reject)
  );

export default downloadFileAsUser;
