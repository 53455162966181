import { TrainingRoutes } from "@skillup/espace-rh-bridge";
import { buildRequest } from "utils/buildRequest";

export default (trainingUuid: string, employeeUuid: string) => {
  const request = buildRequest<TrainingRoutes.GetEmployeeTrainingSessionsState>({
    method: "GET",
    path: "/training/{trainingUuid}/sessions-state/{employeeUuid}",
    params: {
      trainingUuid,
      employeeUuid,
    },
  });
  return request();
};