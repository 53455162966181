import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { edit, selectHighlightUuid } from "../../../reducer";
import type { ContentItem } from "../../types";
import styles from "./Section.module.scss";
import { BuilderFormInput } from "../BuilderFormInput";

export const SectionContent = ({
  section,
}: {
  section: Extract<ContentItem, { type: "section" }>;
}) => {
  const highlightUuid = useSelector(selectHighlightUuid);
  const dispatch = useDispatch();

  const multipleAssistiveTexts = useMemo(() => {
    return {
      default: (
        <>
          Ce titre sera repris dans la navigation, donc visible par toutes les personnes habilitées
          à voir l’entretien.
          <br />
          Il ne sera pas repris dans le corps de l’entretien.
        </>
      ),
      error: "Ce champ doit être rempli.",
    };
  }, []);

  const updateTemplate = (title: Extract<ContentItem, { type: "section" }>["data"]["title"]) => {
    dispatch(
      edit({
        data: {
          title: title,
        },
        from: {
          sectionIndex: section.sectionIndex,
        },
        type: "section",
      })
    );
  };
  return (
    <div>
      <BuilderFormInput
        label="Intitulé de la section"
        name={`section-${section.sectionIndex}`}
        placeholder={`Section ${section.sectionIndex + 1}`}
        value={section.data.title}
        onChange={updateTemplate}
        debounceValue={50}
        required
        multipleAssistiveTexts={multipleAssistiveTexts}
        formGroupClassName={styles.section}
        autoFocus={highlightUuid === section.uuid}
      />
    </div>
  );
};
