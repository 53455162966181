import { useTranslation } from "react-i18next";
import { Dispatch, useState, Suspense, useCallback } from "react";

import { Select, DSModal } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";

import { Assignee, DatatableJob } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import { useAssignmentsContext } from "./AssignmentsContext";
import { AssignModal, AssignModalSelect } from "./Assignments.styled";

interface AssignEmployeeToJobModalV4Props {
  onClose: () => void;
  jobs: Array<DatatableJob>;
  employees: Array<Assignee>;
}

interface JobSelectionDropdownProps {
  t: TranslationType;
  jobs: Array<DatatableJob>;
  selectedJob: null | string;
  selectJob: Dispatch<string>;
}

const sortByName = (a: DatatableJob, b: DatatableJob) => {
  return a.name.localeCompare(b.name);
};

export const AssignmentsModal = ({ employees, jobs, onClose }: AssignEmployeeToJobModalV4Props) => {
  const { t } = useTranslation();
  const currentJobUuid = employees.length === 1 ? employees[0].jobID : undefined;
  const [selectedJobUuid, setSelectedJobUuid] = useState<null | string>(currentJobUuid);
  const sortedJobs = jobs.sort(sortByName);
  const {
    assignEmployeeToJob: { isLoading, mutator },
  } = useAssignmentsContext();

  const handleSubmit = useCallback(async () => {
    const targetJob = jobs?.find(({ uuid }) => {
      return uuid === selectedJobUuid;
    });

    const employeesArray = employees.map(
      ({ uuid: employeeUuid, companyID: companyUuid, version }) => ({
        companyUuid,
        employeeUuid,
        version: version ? version + 1 : 1,
      })
    );

    await mutator(employeesArray, {
      uuid: targetJob?.uuid,
      name: targetJob?.name,
    });
    onClose();
  }, [employees, selectedJobUuid, onClose, jobs, mutator]);

  return (
    <AssignModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("skills.list.collaborator.assign", {
            defaultValue: "Associer une fiche de poste",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        <Text display="flex" espaceFont="captionBold">
          {employees.length > 1 ? (
            <span>
              {t("portal.config.employee.assignEmployeeToJob.multipleAssignLabel", {
                defaultValue: "Fiche de poste à associer à ces collaborateurs",
              })}
            </span>
          ) : (
            <span>
              {t("portal.config.employee.assignEmployeeToJob.simpleAssignLabel", {
                defaultValue: "Fiche de poste à associer à {{fullName}}",
                fullName: employees[0].fullName,
              })}
            </span>
          )}
          <Text marginLeft="xxs" color="decoration-red-base">
            *
          </Text>
        </Text>

        <Flex marginBottom="xs">
          <Suspense
            fallback={
              <Select
                options={[]}
                placeholder={t("skills.list.collaborator.loading", {
                  defaultValue: "Récupération de la liste des fiches de poste...",
                })}
              />
            }
          >
            <JobSelectionDropdown
              t={t}
              jobs={sortedJobs}
              selectedJob={selectedJobUuid}
              selectJob={setSelectedJobUuid}
            />
          </Suspense>
        </Flex>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={onClose}
          label={t("skills.list.collaborator.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          loading={isLoading}
          disabled={currentJobUuid === selectedJobUuid}
          onClick={handleSubmit}
          label={t("skills.list.collaborator.assignJob", {
            defaultValue: "Associer la fiche de poste",
          })}
        />
      </DSModal.Footer>
    </AssignModal>
  );
};

const JobSelectionDropdown = ({ jobs, selectedJob, selectJob, t }: JobSelectionDropdownProps) => {
  const onChange = useCallback(
    (jobUuid: string | undefined) => {
      const job = jobs.find(({ uuid }) => uuid === jobUuid);
      selectJob(job ? job.uuid : null);
    },
    [jobs, selectJob]
  );

  return (
    <AssignModalSelect
      key={selectedJob}
      value={selectedJob ?? null}
      onChange={onChange}
      options={jobs?.map((job) => ({
        data: job,
        label: job.name,
        value: job.uuid,
      }))}
      placeholder={t("skills.list.collaborator.selectJob", {
        defaultValue: "Sélectionnez une fiche de poste",
      })}
    />
  );
};
