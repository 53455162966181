import React from "react";
import { useToggle } from "react-use";
import { useToasts } from "react-toast-notifications";

import { Modal, Checkbox, DSButton, DSAlertType, DSAlertDisplay, DSAlert } from "@skillup/ui";
import { CampaignsRoutes, CampaignListItem } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import styles from "./CloseCampaignModal.module.scss";

interface Props {
  readonly campaign: CampaignListItem;
  readonly onClose: () => void;
  readonly resync: () => Promise<void>;
}

export default ({ campaign, onClose, resync }: Props) => {
  const [confirmedCloseCampaign, toggleCloseCampaign] = useToggle(false);
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useToggle(false);

  const archiveCampaign = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await buildRequest<CampaignsRoutes.Archive>({
        method: "POST",
        path: `/campaigns/{campaignUuid}/archive`,
        params: { campaignUuid: campaign.uuid },
      })();
      addToast(`La campagne ${campaign.titleForHR} a été clôturée.`, { appearance: "success" });
      resync();
    } catch (e) {
      addToast(
        `La campagne ${campaign.titleForHR} n'a pas pu être clôturée. Veuillez réessayer ultérieurement.`,
        { appearance: "error" }
      );
    }

    onClose();
    setIsLoading(false);
  };

  const { titleForHR, signedInterviews, totalInterviews } = campaign;
  const itwNotSignedNumber = totalInterviews - signedInterviews;
  const itwNotSignedPercent = Math.round((itwNotSignedNumber / totalInterviews) * 100);
  const hasNotSignedItw = totalInterviews !== signedInterviews;

  return (
    <Modal title={`Clôturer la campagne « ${titleForHR} »`} onClose={onClose} disableOnClickAway>
      <div className={styles.closeCampaign}>
        {hasNotSignedItw ? (
          <>
            <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
              {itwNotSignedNumber > 1
                ? `${itwNotSignedNumber} entretiens (${itwNotSignedPercent}%) n’ont pas encore été signés
              et seront verrouillés sans signature.`
                : `Un entretien (${itwNotSignedPercent}%) n'a pas encore été signé et sera verrouillé sans signature.`}
            </DSAlert>
            <p className={styles.message}>
              La clôture d'une campagne verrouille les entretiens qu'elle contient :
              <ul>
                <li>
                  Les entretiens en attente de signature passeront au statut « Non signé » et ne
                  pourront plus être modifiés ni signés.
                </li>
                <li>
                  Les entretiens seront masqués des vues courantes pour les collaborateurs et
                  responsables.
                </li>
              </ul>
            </p>
            <span className={styles.warning}>Cette action est irréversible</span>
            <Checkbox
              key="close-campaign"
              className={styles.confirmClose}
              label="J'ai bien compris les conséquences de la clôture de campagne."
              value="close"
              onClick={toggleCloseCampaign}
              checked={confirmedCloseCampaign}
              readOnly={isLoading}
            />
          </>
        ) : (
          <p className={styles.information}>
            Les entretiens seront masqués des vues courantes pour les collaborateurs et
            responsables.
          </p>
        )}
        {isLoading && (
          <DSAlert
            type={DSAlertType.WARNING}
            display={DSAlertDisplay.INLINE}
            className={styles.closeCampaignLoading}
          >
            La campagne est en cours de clôture. Cela peut durer quelques instants. Ne quittez pas
            cette page tant que la clôture n’est pas finalisée.
          </DSAlert>
        )}
        <div className={styles.buttonsWrapper}>
          <DSButton
            className={styles.button}
            emphasis="Low"
            label="Annuler"
            onClick={onClose}
            disabled={isLoading}
          />
          <DSButton
            className={styles.button}
            emphasis="High"
            label={!isLoading && "Confirmer la clôture de campagne"}
            loading={isLoading}
            onClick={archiveCampaign}
            disabled={isLoading || (!confirmedCloseCampaign && hasNotSignedItw)}
          />
        </div>
      </div>
    </Modal>
  );
};
