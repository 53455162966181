import React, { type ReactElement, useEffect, useRef } from "react";

import { Chip } from "../Chip/Chip";
import { Status, StatusType } from "../Status/Status";
import { Button as DSButton } from "../Button";
import { Dropdown as DSDropdown, type DSDropdownItem } from "../Dropdown";

import { Close } from "../MaterialIcons";

import { useThemes } from "../../hooks/useThemes";

import { Themes } from "components/commonProps";
import { Tooltip } from "components/Tooltip";

import styles from "./DSSidePanel.module.scss";

type SidePanelHeaderProps = {
  children: Array<ReactElement> | ReactElement;
};

export const HeaderChips: React.FunctionComponent<{
  chips: Array<string>;
  mode?: "chip" | "status";
  statusType?: StatusType;
}> = ({ chips, mode = "chip", statusType = StatusType.PROGRESS }) => {
  const { theme } = useThemes();

  if (!chips) return null;
  return (
    <div className={styles.chips}>
      {chips?.map((chipLabel, idx) => {
        if (mode === "status") {
          return (
            <Status
              key={idx}
              type={statusType}
              darkMode={theme === Themes.ESPACE_RH}
              theme={theme}
              label={chipLabel}
            />
          );
        }
        return (
          <Chip key={idx} darkMode={theme === Themes.ESPACE_RH} theme={theme} label={chipLabel} />
        );
      })}
    </div>
  );
};

export const HeaderTitle: React.FunctionComponent<{ title: string }> = ({ title }) => {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [showTooltip, setShowTooltip] = React.useState(false);

  useEffect(() => {
    if (titleRef.current) {
      const titleStyle = getComputedStyle(titleRef.current);
      const lineHeight = parseFloat(titleStyle.lineHeight);
      const numberOfLines = titleRef.current.offsetHeight / lineHeight;
      if (numberOfLines >= 5) setShowTooltip(true);
    }
  }, [title]);

  return (
    <Tooltip label={title} direction="bottom" disabled={!showTooltip}>
      <h2 ref={titleRef} className={styles.title}>
        {title}
      </h2>
    </Tooltip>
  );
};

export const HeaderSubtitle: React.FunctionComponent<{ subtitle: string }> = ({ subtitle }) => (
  <h6 className={styles.subtitle}>{subtitle}</h6>
);

export const HeaderCustomContent: React.FunctionComponent<{ children }> = ({ children }) => (
  <div className={styles.customContent}>{children}</div>
);

export const HeaderActions: React.FunctionComponent<{
  mainActionLabel?: string;
  secondaryActionLabel?: string;
  onMainActionClick?: () => void;
  onSecondaryActionClick?: () => void;
  moreActions?: Array<React.ReactElement<typeof DSDropdownItem>>;
  mainButtonSize?: "S" | "M";
  secondaryButtonSize?: "S" | "M";
}> = ({
  mainActionLabel,
  secondaryActionLabel,
  onSecondaryActionClick,
  onMainActionClick,
  mainButtonSize,
  secondaryButtonSize,
  moreActions,
}) => {
  return (
    <div className={styles.actions}>
      {secondaryActionLabel && (
        <DSButton
          className={styles.secondaryAction}
          buttonSize={secondaryButtonSize}
          label={secondaryActionLabel}
          onClick={onSecondaryActionClick ?? undefined}
          emphasis="Mid"
          darkMode
        />
      )}

      {mainActionLabel && (
        <DSButton
          className={styles.mainAction}
          buttonSize={mainButtonSize}
          label={mainActionLabel}
          onClick={onMainActionClick ?? undefined}
          emphasis="High"
          darkMode
        />
      )}

      {moreActions && (
        <DSDropdown position="down-left" buttonSize="M" darkMode>
          {moreActions}
        </DSDropdown>
      )}
    </div>
  );
};

export function HeaderCloseIcon({ onClose }: { onClose: () => void }) {
  return (
    <DSButton
      darkMode
      className={styles.close}
      icon={<Close />}
      onClick={onClose}
      iconOnly
      emphasis="Low"
      aria-label="close"
    />
  );
}

export function SidePanelHeader({
  children,
  onClose,
}: SidePanelHeaderProps & { onClose: () => void }) {
  return (
    <header>
      {children}
      <HeaderCloseIcon onClose={onClose} />
    </header>
  );
}

SidePanelHeader.Chips = HeaderChips;
SidePanelHeader.Title = HeaderTitle;
SidePanelHeader.SubTitle = HeaderSubtitle;
SidePanelHeader.Actions = HeaderActions;
SidePanelHeader.CustomContent = HeaderCustomContent;

export type HeaderType = React.ReactElement<typeof SidePanelHeader>;
