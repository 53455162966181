import { ScalesRoutes } from "@skillup/core-hr-bridge";
import { buildRequest } from "utils/buildRequest";

export type Scale = ScalesRoutes["GetList"]["response"]["scales"][0];
export async function getScales() {
  const result = await buildRequest<ScalesRoutes["GetList"]>({
    method: "GET",
    path: `/scales`,
    target: "CORE_HR",
  })();

  return result.scales;
}
