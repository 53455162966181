import { stringify } from "qs";
import { isEmpty } from "lodash";
import { storage } from "services";
import actions from "actions";
import { allPersistentStorages } from "services/storage";

const checkAuthentication = async (query: { [T: string]: string } = {}): Promise<void> => {
  if (typeof localStorage !== "undefined") {
    const lsItems = ["X-Auth-Token", "X-Auth-As-Organization", "X-Auth-As-User", "trackerApiToken"];

    for (const item of lsItems) {
      if (!storage.get(item, allPersistentStorages)) {
        storage.remove(item, allPersistentStorages);
      }
    }
  }

  const authLocations = ["/authentification", "/connexion"];

  if (!authLocations.some((l) => window.location.pathname.indexOf(l) === 0)) {
    const previousUserData = storage.get("_skillup_userData");
    const data = await actions.auth.signin(false);

    if (!data) {
      const { organization, reason } = query;
      let withQueryArgs;

      if (previousUserData && isEmpty(organization) && isEmpty(reason)) {
        withQueryArgs = stringify(
          {
            organization: previousUserData?.activeCompany?.reference,
            reason: "inactivity",
            redirect: window.location.href,
          },
          { addQueryPrefix: true }
        );
      } else {
        withQueryArgs = stringify(
          {
            organization,
            reason,
            redirect: window.location.href,
          },
          { addQueryPrefix: true }
        );
      }

      window.location.href = `${process.env.REACT_APP_PUBLIC_APP_URL}connexion/${withQueryArgs}`;
      throw new Error("Unauthorized");
    }
  }
};

export default checkAuthentication;
