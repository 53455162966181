import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import toNumber from "lodash/toNumber";
import qs from "qs";

const sortProperties = (unordered) => {
  const ordered = {};
  Object.keys(unordered)
    .sort()
    .forEach((key) => {
      ordered[key] = unordered[key];
    });
  return ordered;
};

const parseSearchQuery = (text) => {
  const trimmed = text.trim();
  const coalescedWhitespaces = trimmed.replace(/\s/g, " ");
  return coalescedWhitespaces;
};

const validFilters = [
  "place",
  "tags",
  "sessionMonth",
  "sessionType",
  "durationGroup",
  "isCPF",
  "language",
  "mode",
  "isCertifying",
  "organization",
  "page",
  "entities",
];

const getFiltersFromQueryString = (queryString): any => {
  if (isEmpty(queryString) || queryString === "?") {
    return {};
  }

  const filters = qs.parse(queryString.substring(1)) as any;

  for (const key of Object.keys(filters)) {
    if (!validFilters.includes(key)) {
      delete filters[key];
      continue;
    }

    switch (key) {
      case "isCPF":
      case "isCertifying":
        filters[key] = filters[key] === "true";
        break;
      case "page":
        filters[key] = toNumber(filters[key]);
        break;
      default:
        break;
    }
  }

  return filters;
};

const getQueryStringFromFilters = (filters) => {
  if (isEmpty(filters)) return "";

  const processedFilters = { ...filters };

  for (const key of Object.keys(processedFilters)) {
    switch (key) {
      case "place":
      case "isCPF":
      case "isCertifying":
        if (!processedFilters[key]) delete processedFilters[key];
        break;
      case "language":
      case "tags":
      case "organization":
      case "sessionMonth":
      case "sessionType":
      case "durationGroup":
      case "mode":
      case "entities":
        if (isEmpty(processedFilters[key])) delete processedFilters[key];
        break;
      case "page":
        if (processedFilters[key] < 2) delete processedFilters[key];
        break;
      default:
        break;
    }

    if (isNil(processedFilters[key])) delete processedFilters[key];
  }

  return isEmpty(processedFilters)
    ? ""
    : `?${qs.stringify(sortProperties(processedFilters), {
        arrayFormat: "brackets",
      })}`;
};

export { parseSearchQuery, getFiltersFromQueryString, getQueryStringFromFilters };

export default {
  parseSearchQuery,
  getFiltersFromQueryString,
  getQueryStringFromFilters,
};
