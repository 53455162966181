import { useMachine } from "@xstate/react";

import { confirmMachine } from "../stateMachine/confirm";

const useConfirmAction = (onConfirm, onBack, onClose) => {
  const [current, send] = useMachine(confirmMachine, {
    actions: {
      onBack: () => onBack(),
      onClose: () => onClose(),
    },
    services: {
      onConfirm: () => onConfirm(),
    },
  });

  return { current, send };
};

export default useConfirmAction;
