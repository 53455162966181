import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { z } from "zod";
import { Formik } from "formik";
import { isEmpty } from "lodash";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { GridColDef, useGridApiRef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Flex, Text } from "@skillup/design-system";
import { DSButton, DSDataGrid, DSTextInput, MaterialIcons } from "@skillup/ui";

import User from "utils/User";
import { useHRs } from "services/company";
import { CampaignCreationState } from "types/peopleReview";

import { DataGridRadio } from "./../../../components";

import styles from "./../steps.module.scss";

type AdminGridRow = {
  id: string;
  noRole: boolean;
  observator: boolean;
  coordinator: boolean;
  administrator: string;
  setRole: (userId: string, role: "noRole" | "observator" | "coordinator") => void;
};

const coordinatorsAndObserversValidationSchema = toFormikValidationSchema(
  z.object({
    coordinators: z.array(
      z.object({
        id: z.string(),
        fullName: z.string(),
      })
    ),
    observers: z.array(
      z.object({
        id: z.string(),
        fullName: z.string(),
      })
    ),
  })
);

const rowToUser = ({ id, administrator }) => ({ id, fullName: administrator });

const columns: GridColDef[] = [
  {
    field: "administrator",
    flex: 1.5,
    headerName: "Responsables",
    resizable: false,
  },
  {
    type: "boolean",
    editable: false,
    field: "coordinator",
    flex: 1,
    headerName: "Coordinateur",
    renderCell: (params: GridRenderCellParams<AdminGridRow>) => <DataGridRadio {...params} />,
    resizable: false,
  },
  {
    type: "boolean",
    editable: false,
    field: "observator",
    flex: 1,
    headerName: "Observateur",
    renderCell: (params: GridRenderCellParams<AdminGridRow>) => <DataGridRadio {...params} />,
    resizable: false,
  },
  {
    type: "boolean",
    editable: false,
    field: "noRole",
    flex: 1,
    headerName: "Aucun rôle",
    renderCell: (params: GridRenderCellParams<AdminGridRow>) => <DataGridRadio {...params} />,
    resizable: false,
  },
];

interface AccessAndRolesProps {
  prevStep: (data: Partial<CampaignCreationState>) => void;
  nextStep: (data: Partial<CampaignCreationState>) => void;
  selectedObservers: {
    id: string;
    fullName: string;
  }[];
  selectedCoordinators: {
    id: string;
    fullName: string;
  }[];
}

export default function AccessAndRoles({
  nextStep,
  prevStep,
  selectedCoordinators,
  selectedObservers,
}: AccessAndRolesProps) {
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const userData = User.getUserData();
  const [search, setSearch] = useState("");
  const { hrs, isError, isLoading, mutations } = useHRs();

  const [selectedRoles, setSelectedRoles] = useState({
    ...selectedCoordinators.reduce((acc, { id }) => ({ ...acc, [id]: "coordinator" }), {}),
    ...selectedObservers.reduce((acc, { id }) => ({ ...acc, [id]: "observator" }), {}),
  });

  const handleSetRole = (userId: string, role: "noRole" | "observator" | "coordinator") => {
    setSelectedRoles((prev) => ({ ...prev, [userId]: role }));
  };

  const rows = useMemo(() => {
    return hrs
      .map((hr) => {
        return {
          id: hr.properties.uuid,
          administrator: hr.properties.fullName,
          coordinator: selectedRoles[hr.properties.uuid] === "coordinator",
          noRole:
            !selectedRoles[hr.properties.uuid] || selectedRoles[hr.properties.uuid] === "noRole",
          observator: selectedRoles[hr.properties.uuid] === "observator",
          setRole: handleSetRole,
        };
      })
      .sort((a, b) => a.administrator.localeCompare(b.administrator))
      .sort((a) => (a.id === userData.uuid ? -1 : 0));
  }, [hrs, selectedRoles, userData.uuid]);

  const getManagers = () => {
    const rowModelsMap = apiRef.current.getRowModels();
    const selectedHrsArray = Array.from(rowModelsMap.values());
    const filteredObservers = selectedHrsArray.filter((hr) => hr.observator);
    const filteredCoordinators = selectedHrsArray.filter((hr) => hr.coordinator);
    const coordinators = filteredCoordinators.map(rowToUser);
    const observers = filteredObservers.map(rowToUser);

    return { coordinators, observers };
  };

  const onSubmit = () => {
    const { coordinators, observers } = getManagers();

    nextStep({ coordinators, observers });
  };

  return (
    <Formik
      validateOnMount={false}
      validationSchema={coordinatorsAndObserversValidationSchema}
      onSubmit={onSubmit}
      initialValues={{
        coordinators: [] as CampaignCreationState["coordinators"],
        observers: [] as CampaignCreationState["observers"],
      }}
    >
      {({ handleSubmit }) => (
        <Flex gap="l" width="100%" maxWidth="50rem" flexDirection="column" marginHorizontal="auto">
          <Flex gap="m" width="100%">
            <Flex width="100%" flexDirection="column">
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {t("peopleReview.campaignCreation.accessAndRoles.description.coordinators", {
                  defaultValue: "Permissions des coordinateurs :",
                })}
              </Text>
              <ul className={styles.columnsText}>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peopleReview.campaignCreation.accessAndRoles.description.coordinators.1", {
                      defaultValue: "Visualiser et modifier les valeurs",
                    })}
                  </Text>
                </li>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peopleReview.campaignCreation.accessAndRoles.description.coordinators.2", {
                      defaultValue: "Retirer ou ajouter des collaborateurs",
                    })}
                  </Text>
                </li>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peopleReview.campaignCreation.accessAndRoles.description.coordinators.3", {
                      defaultValue: "Clôturer la campagne",
                    })}
                  </Text>
                </li>
              </ul>
            </Flex>
            <Flex width="100%" flexDirection="column">
              <Text espaceFont="captionBold" color="plainText-onLight-lighter">
                {t("peopleReview.campaignCreation.accessAndRoles.description.observers", {
                  defaultValue: "Permissions des observateurs :",
                })}
              </Text>
              <ul className={styles.columnsText}>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peopleReview.campaignCreation.accessAndRoles.description.observers.1", {
                      defaultValue: "Visualiser les arbitrages",
                    })}
                  </Text>
                </li>
                <li>
                  <Text espaceFont="body1Regular" color="plainText-onLight-default">
                    {t("peopleReview.campaignCreation.accessAndRoles.description.observers.1", {
                      defaultValue: "Visualiser les actions",
                    })}
                  </Text>
                </li>
              </ul>
            </Flex>
          </Flex>

          <DSTextInput
            value={search}
            name="searchHr"
            actionButton={<MaterialIcons.Search />}
            onChange={(str) => {
              mutations.search(str);
              setSearch(str);
            }}
            placeholder={t("peopleReview.campaignCreation.accessAndRoles.searchHr", {
              defaultValue: "Rechercher un responsable",
            })}
          />
          <DSDataGrid
            rows={rows}
            apiRef={apiRef}
            isError={isError}
            columns={columns}
            hideFooter={true}
            loading={isLoading}
            disableRowSelectionOnClick
            emptyOverlay={{
              text: t("peopleReview.creationStep.accessAndRoles.emptyOverlayText", {
                defaultValue: "Aucun responsable trouvé.",
              }),
            }}
            entityName={t("peopleReview.campaignCreation.accessAndRoles.datagrid.entityName", {
              count: rows.length,
              defaultValue: `{{count}} responsable${rows.length > 1 ? "s" : ""}`,
            })}
            errorOverlay={{
              text: [
                t("peopleReview.creationStep.accessAndRoles.errorOverlayText.firstSentence", {
                  defaultValue: `Une erreur est survenue lors du chargement des responsables.`,
                }),
                t("peopleReview.creationStep.accessAndRoles.errorOverlayText.secondSentence", {
                  defaultValue: `Veuillez réessayer ultérieurement.`,
                }),
                t("peopleReview.creationStep.accessAndRoles.errorOverlayText.thirdSentence", {
                  defaultValue: `Si l’erreur persiste, contactez votre interlocuteur Skillup.`,
                }),
              ],
            }}
          />
          <Flex justifyContent="space-between">
            <DSButton
              emphasis="Low"
              label={t("previousStep", {
                defaultValue: "Précédent",
              })}
              onClick={() => {
                const { coordinators, observers } = getManagers();
                prevStep({ coordinators, observers });
              }}
            />
            <DSButton
              disabled={isEmpty(rows) || isLoading}
              onClick={() => handleSubmit()}
              label={t("nextStep", {
                defaultValue: "Suivant",
              })}
            />
          </Flex>
        </Flex>
      )}
    </Formik>
  );
}
