import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

import Acta from "utils/Acta";
import type { SkillsRoutes } from "types/api";
import { buildFileRequest } from "utils/buildRequest";

import { useSkillsState } from "../state/skills";

import styles from "./UploadSkillsModal.module.scss";

type UploadRoute = SkillsRoutes["Skills.Upload"];

const UploadSkillsModal = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const SkillsState = useSkillsState();

  const onDrop = async (files: File[]) => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      const uploadRequest = buildFileRequest<UploadRoute>({
        file: files[0],
        method: "POST",
        path: "/competences/skills/upload",
      });

      await uploadRequest();

      Acta.dispatchEvent("sendAppMessage", {
        type: "success",
        message: "Fichier envoyé.",
      });

      SkillsState.refreshList();
      Acta.dispatchEvent("closeModal");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        type: "error",
        message: "Une erreur est survenue lors de l’envoi de votre fichier.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const { getInputProps, getRootProps } = useDropzone({
    maxSize: 52428800,
    multiple: false,
    onDrop,
  });

  return (
    <div>
      <section className={styles.dropZone}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div>
            <p>Glissez / Déposez un fichier ici</p>
            <p>ou</p>
            <p className={styles.addFile}>Ajoutez un fichier depuis votre ordinateur</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UploadSkillsModal;
