import { plural } from "utils/locale";

import type { SectionBlock, PageBlock } from "../../reducer";

export function buildModalContent({
  item,
  counts,
  type,
  action,
}: {
  item: SectionBlock | PageBlock;
  counts: { pagesCount: number; childrenCount: number };
  type: "section" | "page";
  action: "duplication" | "deletion";
}) {
  const displayAction = action === "duplication" ? "dupliqué" : "supprimé";

  if (item.type === "section" && counts.pagesCount > 1) {
    return (
      <p>
        Cette section contient {counts.pagesCount}
        {plural(counts.pagesCount, " sous-section%s")} et {counts.childrenCount}
        {plural(counts.childrenCount, " élément%s")}.
        <br />
        Ils seront également {displayAction}s.
      </p>
    );
  } else {
    return (
      <p>
        Cette {type === "section" ? "section" : "sous-section"} contient {counts.childrenCount}
        {plural(counts.childrenCount, " élément%s")}.
        <br />
        {plural(counts.childrenCount, `Il sera également ${displayAction}`, {
          pluralText: `Ils seront également ${displayAction}s`,
        })}
        .
      </p>
    );
  }
}
