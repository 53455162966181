import { format } from "@skillup/shared-utils";

import { ProcessedSkill } from "types/skills";

const parseSkillIntoRow = (skill: ProcessedSkill) => {
  const {
    uuid,
    category,
    description,
    employees,
    evaluationScale,
    fields,
    isArchived,
    jobs,
    name,
    updatedAt,
    updatedBy,
    version,
  } = skill;  

  const rowData = {
    uuid,
    category,
    categoryLabel: category?.label,
    description,
    employees,
    employeesWithSkill: employees,
    evaluationScale,
    fields,
    isArchived,
    jobs,
    jobsCount: Object.keys(jobs).length,
    jobsWithSkill: Object.values(jobs),
    lastEdit: format(new Date(updatedAt), "dd/MM/yyyy"),
    name,
    updatedAt,
    updatedBy,
    version,
  };

  skill.fields.forEach((field) => {
    rowData[field.name] = field.value;
  });

  return {
    id: skill.uuid,
    ...rowData,
  };
};

export default parseSkillIntoRow;

export type SkillRow = ReturnType<typeof parseSkillIntoRow>;
