import { buildRequest } from "utils/buildRequest";
import { IntraRoutes } from "types/api";

export const fetchIntraFields = async (trainingUuid: string) => {
  const request = await buildRequest<IntraRoutes.GET_INTRA_FIELDS>({
    path: "/trainings/{trainingUuid}/fields",
    method: "GET",
    params: { trainingUuid },
  });

  return request();
};
