import React from "react";

import {
  TimePicker as MUITimePicker,
  type TimePickerProps as MUITimePickerProps,
} from "@mui/x-date-pickers/TimePicker";
import { type PickerValidDate } from "@mui/x-date-pickers-pro";

import { useTheme } from "context/themes";

import Popper from "../components/Popper";

export type TimePickerProps = Omit<
  MUITimePickerProps<PickerValidDate>,
  "theme" | "disableHighlightToday" | "slots"
>;

const TimePicker = React.forwardRef((props: TimePickerProps, ref: any) => {
  const theme = useTheme();

  return (
    <MUITimePicker
      {...props}
      ref={ref}
      slots={{
        popper: Popper,
      }}
      slotProps={{
        ...props.slotProps,
        popper: {
          ...props.slotProps?.popper,
          theme: theme,
        } as any,
      }}
    />
  );
});

export default TimePicker;
