import React from "react";
import Select from "react-select";
import { useSetState } from "react-use";
import { concat } from "lodash";

import { TextInput } from "@skillup/ui";

import InteractiveButton from "components/InteractiveButton";
import { Schedule } from "../PortalSchedules";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

import styles from "./AddTrainingScheduleModal.module.scss";

interface IProps {
  schedules: Schedule[];
  reload: () => Promise<Schedule[]>;
  companyName: string;
}

const defaultSchedule = (activeSchedules) => {
  return {
    label: activeSchedules.name,
    value: activeSchedules.uuid,
    selected: true,
  };
};

const AddTrainingScheduleModal = (props: IProps) => {
  const { schedules, companyName, reload } = props;
  const formatSchedulesOptions = (schedules) =>
    schedules.map((schedule) => ({ value: schedule.uuid, label: schedule.name, selected: false }));
  const defaultOption = {
    value: "default",
    label: "Configuration par défaut",
    selected: false,
  };
  const formattedOption = concat(formatSchedulesOptions(schedules), defaultOption);
  const activeSchedules = schedules.filter((schedule) => schedule.active);

  const [state, setState] = useSetState<any>({
    schedulesOptions: formattedOption,
    selectedSchedule:
      activeSchedules.length === 0 ? defaultOption : defaultSchedule(activeSchedules[0]),
    name: "",
  });
  const addSchedule = async () => {
    if (
      !window.confirm(
        `Etes-vous sûr de vouloir ajouter un nouveau plan de formation à ${companyName}`
      )
    )
      return;

    const { selectedSchedule, name } = state;
    const isDefault = selectedSchedule?.value === "default";
    try {
      await DataLayer.request({
        url: "/v1/schedule/operations/add",
        method: "POST",
        body: JSON.stringify({
          scheduleId: !isDefault ? selectedSchedule?.value : undefined,
          name: name || undefined,
          useDefault: isDefault,
        }),
      });
      await reload();
      triggerSuccessToast();
      Acta.dispatchEvent("closeModal");

      return;
    } catch {
      triggerErrorToast();
    }
  };

  const triggerSuccessToast = () =>
    Acta.dispatchEvent("sendAppMessage", {
      message: "Plan de formation crée avec succès",
      type: "success",
    });

  const triggerErrorToast = () =>
    Acta.dispatchEvent("sendAppMessage", {
      message: "Erreur lors de la création du plan de formation",
      type: "error",
    });

  const setSelectedSchedules = (selectedSchedule) => {
    setState({ selectedSchedule });
  };

  return (
    <>
      <div className={styles.addTrainingScheduleModal}>
        <h2>Titre du plan de formation: </h2>
        <TextInput
          key={"scheduleNameInput"}
          className={styles.addTrainingScheduleTitle}
          aria-label="Titre du plan"
          value={state.name}
          onChange={(newTitle) => setState({ name: newTitle })}
          placeholder={"Plan de formation"}
        />
        <h2>Paramétrage à appliquer: </h2>
        <Select
          className={styles.addTrainingScheduleSelect}
          aria-label="Select de plan"
          options={state.schedulesOptions}
          onChange={setSelectedSchedules}
          defaultValue={state.selectedSchedules}
          value={state.selectedSchedules}
        />
        <InteractiveButton
          className={styles.addTrainingScheduleButton}
          onClick={addSchedule}
          label="Valider"
        />
      </div>
    </>
  );
};

export default AddTrainingScheduleModal;
