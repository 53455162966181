import { AddToast } from "react-toast-notifications";
import history from "utils/history";
import { buildRequest } from "utils/buildRequest";

import type { IntraRoutes } from "types/api";

export type IntraCreationDataType = IntraRoutes.CREATE["payload"];

export type IntraCreationParams = {
  v2?: boolean;
  data: IntraCreationDataType;
  addToast: AddToast;
};

const createIntra = async ({ data, v2, addToast }: IntraCreationParams) => {
  try {
    const intraCreationRequest = buildRequest<IntraRoutes.CREATE>({
      method: "POST",
      payload: data,
      path: "/intra",
    });

    const { intraUuid } = await intraCreationRequest();

    addToast("Programme créé avec succès !", {
      appearance: "success",
      autoDismiss: true,
    });

    if (v2) {
      return history.push(`/responsable/programmes/gestion-intras/`);
    }
    history.push(`/responsable/programmes/gestion-intras/${intraUuid}`);
  } catch (err) {
    addToast("Erreur lors de la création du programme.", {
      appearance: "error",
      autoDismiss: true,
    });
    return;
  }
};

export default createIntra;
export type IntraCreationFunction = typeof createIntra;
