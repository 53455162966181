import React from "react";
import { useHistory } from "react-router-dom";
import { FlatButton } from "@skillup/ui";

import styles from "./ChooseCampaignModale.module.scss";
import Acta from "utils/Acta";

const ChooseCampaignModale = () => {
  const history = useHistory();

  const pickOption = (route: string) => () => {
    Acta.dispatchEvent("closeModal");
    history.push(route);
  };

  return (
    <div className={styles.chooseCampaignModale}>
      <FlatButton
        className={styles.option}
        title="Campagne d'entretiens"
        description="Créer une campagne d'entretiens avec la date du lancement et la date limite de signature."
        onClick={pickOption("/responsable/campagne/creation-campagne-entretiens")}
      />
      <FlatButton
        className={styles.option}
        title="Entretiens automatisés"
        description="Paramétrer des entretiens qui se génèrent automatiquement à une date donnée."
        onClick={pickOption("/responsable/campagne/creation-entretiens-automatises")}
      />
      <FlatButton
        className={styles.option}
        title="Entretiens ponctuels"
        description="Créer des entretiens manuellement pour répondre à des besoins ponctuels."
        onClick={pickOption("/responsable/campagne/creation-entretiens-ponctuels")}
      />
    </div>
  );
};

export default ChooseCampaignModale;
