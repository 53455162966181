import { useEffect, useState } from "react";
import cx from "classnames";

import { DSAlert, DSAlertDisplay, DSAlertType, Loader, Select } from "@skillup/ui";

import {
  useDashboardIndicatorData,
  useDashboardIndicatorConfig,
  useDashboardContext,
} from "../../useDashboardContext";

import BarChart from "../BarChart/BarChart";
import BarList from "../BarList/BarList";
import { IndicatorConfig, ItemLayout } from "../../types";

import styles from "./DashboardIndicator.module.scss";
import { isEmpty } from "lodash";
import DashboardDatagrid from "../../Components/DashboardDatagrid";

interface Props {
  indicator: IndicatorConfig["attributes"];
  layout?: ItemLayout;
  onChange?: (name: string) => void;
}

export default function DashboardIndicator({ item }: { item: ItemLayout }) {
  const [name, setName] = useState(item.name);
  const { updateIndicator } = useDashboardContext();
  const { data, isFetching, isError } = useDashboardIndicatorConfig(name);

  useEffect(() => {
    if (isFetching || isError) {
      return;
    }
    updateIndicator(name, item.panel?.name, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, data, isFetching, isError]);

  if (isFetching || isError) {
    return (
      <div className={cx(styles.card, { [styles.L]: item.size === "large" })}>
        <h2>{isFetching ? "Chargement..." : name}</h2>
        {isFetching && <Loader />}
        {isError && (
          <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE}>
            Une erreur est survenue lors du chargement des données
          </DSAlert>
        )}
      </div>
    );
  }

  switch (data?.kinds?.[0]) {
    case "table":
      return <DashboardTable key={item.name} indicator={data} layout={item} />;
    case "bar":
      return <DashboardBar key={item.name} indicator={data} layout={item} onChange={setName} />;
    default:
      return (
        <div key={item.name} className={styles.card}>
          Error: invalid kind {data?.kinds?.[0]}
        </div>
      );
  }
}

const DashboardBar = ({ indicator, layout, onChange }: Props) => {
  const { filtersQuery } = useDashboardContext();
  const { data, isFetching, isError } = useDashboardIndicatorData(indicator, filtersQuery);

  return (
    <div className={cx(styles.card, { [styles.L]: layout.size === "large" })}>
      <h2>{isFetching ? "Chargement..." : layout?.panel?.label || indicator?.label}</h2>
      {!isFetching && layout?.indicators && (
        <Select
          value={indicator.name}
          options={layout.indicators.map((indicator) => ({
            label: indicator.label,
            value: indicator.name,
          }))}
          onChange={onChange}
        />
      )}
      {isError && (
        <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE} className={styles.error}>
          Une erreur est survenue lors du chargement des données
        </DSAlert>
      )}
      <div className={styles.scrollable}>
        {isFetching && <Loader />}
        {data?.data && !isFetching && isEmpty(data.data) && (
          <div className={styles.empty}>Aucune donnée</div>
        )}
        {data?.data && !isFetching && !isEmpty(data.data) && data.data.length < 8 && (
          <BarChart
            data={data?.data?.map((row) => ({
              label: row[0].formatted,
              rawValue: row[1].raw,
              numberValue: row[1].number,
              formattedValue: row[1].formatted,
            }))}
          />
        )}
        {data?.data && !isFetching && data.data.length >= 8 && (
          <BarList
            data={data?.data?.map((row) => ({
              label: row[0].formatted,
              rawValue: row[1].raw,
              numberValue: row[1].number,
              formattedValue: row[1].formatted,
            }))}
          />
        )}
      </div>
    </div>
  );
};

const DashboardTable = ({ indicator, layout }: Props) => {
  const { filtersQuery } = useDashboardContext();
  const { data, isFetching, isError } = useDashboardIndicatorData(indicator, filtersQuery);

  return (
    <div className={cx(styles.card, { [styles.L]: layout.size === "large" })}>
      <h2>{isFetching ? "Chargement..." : indicator?.label}</h2>
      {isError && (
        <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.INLINE}>
          Une erreur est survenue lors du chargement des données
        </DSAlert>
      )}
      {isFetching && <Loader />}
      {!isFetching && data && isEmpty(data.data) && (
        <div className={styles.empty}>Aucune donnée</div>
      )}
      {!isFetching && !isEmpty(data?.data) && <DashboardDatagrid data={data} />}
    </div>
  );
};