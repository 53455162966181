import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { CancelCircle, RemoveCircle, CheckCircle } from "components/MaterialIcons";
import { Tooltip } from "components/Tooltip";

import styles from "./ExpectedSkillLevel.module.scss";

interface SkillLevelProps {
  readonly level?: number;
  readonly totalLevel: Array<{ level: number }>;
  readonly expectedLevel?: number;
}

const ExpectedSkillLevel = ({ level, totalLevel, expectedLevel }: SkillLevelProps) => {
  const { t } = useTranslation();
  const computeStatus = () => {
    if (expectedLevel === undefined || level === undefined) {
      return (
        <Tooltip
          label={t("skillListDetail.tooltip.notEvaluated", {
            defaultValue: "Vous n’avez pas été évalué sur cette compétence",
          })}
          direction="top"
          className={styles.tooltip}
        >
          <RemoveCircle className={styles.notEvaluated} />
        </Tooltip>
      );
    }

    return level >= expectedLevel ? (
      <Tooltip
        label={t("skillListDetail.tooltip.reached", {
          defaultValue: "Vous avez le niveau attendu sur cette compétence",
        })}
        direction="top"
        className={styles.tooltip}
      >
        <CheckCircle className={styles.reached} />
      </Tooltip>
    ) : (
      <Tooltip
        label={t("skillListDetail.tooltip.notReached", {
          defaultValue: "Vous n’avez pas le niveau attendu sur cette compétence",
        })}
        direction="top"
        className={styles.tooltip}
      >
        <CancelCircle className={styles.notReached} />
      </Tooltip>
    );
  };

  return (
    <>
      <div className={cx(styles.SkillLevel)}>
        {totalLevel
          .sort((a, b) => a.level - b.level)
          .map((e) => (
            <span
              key={e.level}
              className={cx(styles.level, {
                [styles.reached]: expectedLevel !== undefined && expectedLevel >= e.level,
              })}
            ></span>
          ))}
      </div>
      <div className={cx(styles.badgeContainer)}>{computeStatus()}</div>
    </>
  );
};

export { ExpectedSkillLevel };
