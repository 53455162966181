import { useHistory } from "react-router-dom";
import React, { Suspense, useState } from "react";
import { useToggle, useSetState } from "react-use";

import { DSTextArea } from "@skillup/ui";
import { DSFormGroupTextInput } from "@skillup/ui";

import Acta from "utils/Acta";
import DSLayout from "components/DSLayout";
import type { JobsRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";

import Details from "../components/Details";
import { useJobsState } from "../../state/jobs";
import SkillSearch from "../components/SkillSearch";
import { JobDetails } from "../components/Details/Details";
import type { SkillWithExpectation } from "../components/SkillSearch";

import styles from "./CreateJob.module.scss";

const CreateJob = () => {
  const history = useHistory();
  const JobsState = useJobsState();

  const [details, setJobDetails] = useSetState<JobDetails>();
  const [description, setDescription] = useState<string>();

  const [relatedSkills, setRelatedSkills] = useState<SkillWithExpectation[]>([]);

  const [loading, toggleLoading] = useToggle(false);

  const handleSubmit = async () => {
    toggleLoading(true);
    try {
      type CreationRoute = JobsRoutes["Jobs.Create"];
      const creationRequest = buildRequest<CreationRoute>({
        method: "POST",
        path: "/competence/job",
        payload: {
          data: {
            description,
            label: details.title,
          },
          fields: details.fieldsWithData.map(({ uuid, value }) => ({ uuid, value })),
          requiredSkills: relatedSkills.map(({ expectedLevel, skill: { uuid } }) => ({
            uuid,
            expectedLevel,
          })),
        },
      });

      const response = await creationRequest();
      if (!response?.uuid) {
        throw new Error();
      }

      Acta.dispatchEvent("sendAppMessage", {
        type: "success",
        message: "Fiche de poste ajoutée avec succès.",
      });

      JobsState.refreshList();

      history.push("/responsable/competences/fiches-de-poste");
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        type: "error",
        message: "Echec lors de l'ajout de la fiche de poste.",
      });
    }
    toggleLoading(false);
  };

  return (
    <DSLayout
      title="Nouvelle fiche de poste"
      parent={{
        onClick: () => history.goBack(),
        title: "Création d'une fiche de poste",
      }}
      layouts={
        [
          {
            primaryButton: (
              <DSNewHeaderButton loading={loading} label="Enregistrer" onClick={handleSubmit} />
            ),
          },
        ] as const
      }
    >
      <div className={styles.CreateJob}>
        <div className={styles.content}>
          <section>
            <Suspense fallback={<div>Loading...</div>}>
              <Details onChange={setJobDetails} />
            </Suspense>
          </section>
          <section>
            <DSFormGroupTextInput label="Description">
              <DSTextArea
                value={description}
                id="description-rich-editor"
                placeholder="Éditez ici la fiche de poste ou copier-collez depuis un fichier"
                onChange={setDescription}
              />
            </DSFormGroupTextInput>
          </section>
          <section>
            <Suspense fallback={<div>Loading...</div>}>
              <SkillSearch
                label="Compétences liées"
                onSkillChange={(newSkills) => setRelatedSkills(newSkills)}
              />
            </Suspense>
          </section>
        </div>
      </div>
    </DSLayout>
  );
};

export default CreateJob;
