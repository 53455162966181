import React, { useState, useCallback } from "react";

import { useRecoilValue } from "recoil";

import Acta from "utils/Acta";
import type { SkillsRoutes } from "types/api";
import { buildRequest } from "utils/buildRequest";
import InteractiveButton from "components/InteractiveButton";

import { useSkillsState, getSkillSelector, skillsCategoriesAtom } from "../state/skills";

import styles from "./SkillDetailsModal.module.scss";

interface SkillsDetailsModalProps {
  skillUuid: string;
}

const SkillsDetailsModal = ({ skillUuid }: SkillsDetailsModalProps) => {
  const companyCategories = useRecoilValue(skillsCategoriesAtom);
  const skillData = useRecoilValue(getSkillSelector({ skillUuid }));
  const { categories, scale, skill } = skillData;

  return (
    <div className={styles.skillsDetailsModal}>
      <div className={styles.headerBar} />
      <div className={styles.content}>
        <h2>{skill.label}</h2>

        {companyCategories.map(({ uuid, label }) => (
          <div key={uuid} className={styles.detail}>
            <label>{label}</label>
            <p>{categories[uuid] ?? "NC"}</p>
          </div>
        ))}

        <div className={styles.detail}>
          <label>Description</label>
          <p>{skill.description ?? "NC"}</p>
        </div>

        <div className={styles.separator} />

        <div className={styles.detail}>
          <label>Echelle d'évaluation</label>
        </div>

        <div className={styles.evaluations}>
          {scale.map((evaluation) => (
            <div key={evaluation.level} className={styles.evaluation}>
              <label>{evaluation.label}</label>
              <p>{evaluation.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

interface SkillDeleteModaleProps {
  skillUuid: string;
  skillLabel: string;
}

const SkillDeleteModale = ({ skillLabel, skillUuid }: SkillDeleteModaleProps) => {
  const [loading, toggleLoading] = useState(false);
  const SkillState = useSkillsState();

  const deleteSkill = useCallback(async () => {
    toggleLoading(true);
    try {
      const deletionRequest = buildRequest<SkillsRoutes["Skills.Delete"]>({
        method: "DELETE",
        params: { skillUuid },
        path: "/competences/skills/{skillUuid}",
      });

      await deletionRequest();

      Acta.dispatchEvent("sendAppMessage", {
        type: "success",
        message: "Compétence supprimée avec succès !",
      });
      SkillState.refreshList();
      Acta.dispatchEvent("closeModal");
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        type: "error",
        message: "Echec lors de la suppression de la compétence.",
      });
    } finally {
      toggleLoading(false);
    }
  }, [toggleLoading, skillUuid, SkillState]);

  return (
    <div className={styles.skillsDeletionModal}>
      <div className={styles.headerBar} />
      <div className={styles.content}>
        <h2>Voulez vous supprimer la compétence {skillLabel} ?</h2>

        <div className={styles.buttonsWrapper}>
          <InteractiveButton
            size="xsmall"
            label="Annuler"
            onClick={() => Acta.dispatchEvent("closeModal")}
          />
          <InteractiveButton
            size="small"
            loading={loading}
            label="Supprimer"
            onClick={() => deleteSkill()}
          />
        </div>
      </div>
    </div>
  );
};

export const showSkillDetailsModal = ({ skillUuid }: SkillsDetailsModalProps) => {
  Acta.setState("modalDisplayed", {
    content: <SkillsDetailsModal skillUuid={skillUuid} />,
    noHeader: true,
    size: "medium",
  });
};

export const showDeleteSkillModal = ({ skillLabel, skillUuid }: SkillDeleteModaleProps) => {
  Acta.setState("modalDisplayed", {
    content: <SkillDeleteModale skillUuid={skillUuid} skillLabel={skillLabel} />,
    noHeader: true,
    size: "medium",
  });
};
