export const loadJimoScript = function (wind, doc, scriptUrl, token): any {
  const r: any = doc.createElement("script");
  const a: any = doc.getElementsByTagName("head")[0];

  r.async = true;
  r.type = "text/javascript";
  r.src = scriptUrl;
  r.setAttribute("id", "jimoScript");
  wind["JIMO_PROJECT_ID"] = token;
  a.appendChild(r);
};
