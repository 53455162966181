import { useEffect } from "react";
import { TableOfContent } from "@skillup/ui";

import User, { getGroups } from "utils/User";

import PortalLists from "../../PortalLists";

import ContentToggles from "./ContentToggles";
import PortalContentCategories from "./PortalContentCategories";
import styles from "./PortalContent.module.scss";
import { Group } from "../../../types";
import { useSetState } from "react-use";
import LMSBanners from "./LMSBanners";

export interface State {
  active?: string;
  groups?: Group[];
}

export default function PortalContent() {
  const [{ active, groups }, setState] = useSetState<State>({
    active: "",
    groups: undefined,
  });

  useEffect(() => {
    setState({ groups: getGroups() });
  }, [setState]);

  const userIsAdmin = User.isSkillupAdmin();

  return (
    <div className={styles.PortalContent}>
      <div className={styles.content}>
        {userIsAdmin && groups && <ContentToggles hasGroups={groups.length > 1} />}
        {groups && <PortalLists location={location} groups={groups} />}
        {userIsAdmin && <LMSBanners />}
        {userIsAdmin && <PortalContentCategories />}
      </div>
      <div className={styles.tableOfContent}>
        <TableOfContent
          activeItem={active}
          onChangeItem={(itemId: string) => setState({ active: itemId })}
          className={styles.TableOfContent}
        >
          {userIsAdmin && (
            <>
              <TableOfContent.Item label="Informations affichées" id="section-content-toggles" />
              <TableOfContent.Item label="Formations recommandées" id="section-formations" />
              <TableOfContent.Item label="Bannières LMS" id="section-lms-banners" />
              <TableOfContent.Item label="Catégories" id="section-categories" />
            </>
          )}
        </TableOfContent>
      </div>
    </div>
  );
}
