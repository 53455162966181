type LsmBannerTitle = {
  label: string;
  color: string;
};

type LmsBannerButton = {
  label: string;
  redirUrl: string;
  theme: LmsBannerTheme;
};
export enum LmsBannerTheme {
  LIGHT = "light",
  DARK = "dark",
}

export type LmsBanner = {
  uuid: string;
  index: number;
  logo: string;
  background: string;
  title: LsmBannerTitle;
  subtitle?: LsmBannerTitle;
  button: LmsBannerButton;
};

export type LmsBannerCreationPayload = {
  logo: Blob;
  background: string;
  title: LsmBannerTitle;
  subtitle?: LsmBannerTitle;
  button: LmsBannerButton;
};

export type LmsBannerUpdatePayload = {
  uuid: string;
  logo?: Blob;
  background?: string;
  title?: LsmBannerTitle;
  subtitle?: LsmBannerTitle;
  button?: LmsBannerButton;
};

export type LmsBannerResponse = { uuid: string };
export type LmsBannersResponse = LmsBanner[];
