import React from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@skillup/hooks";
import { stringify } from "qs";

import history from "utils/history";

import FilterWrapper from "./FilterWrapper";
import Sorter from "./Sorter";

import styles from "./SelectFilter.module.scss";

export default ({ column: { id }, options, column }) => {
  const { pathname } = useLocation();
  const query: any = useQuery();
  const { [id]: currentFilter = [], ...rest } = query;

  function onFilterChange(optionSelected: string) {
    const optionsSelected = options.reduce((acc, option) => {
      const isInArray = (value) => currentFilter.find((filter) => filter === value);
      const isCurrent = optionSelected === option;

      if (isCurrent && !isInArray(option)) {
        return [...acc, option];
      }

      if (!isCurrent && isInArray(option)) {
        return [...acc, option];
      }

      return acc;
    }, []);

    const withQuery = stringify(
      {
        ...rest,
        ...(optionSelected && { [id]: optionsSelected }),
      },
      { addQueryPrefix: true }
    );

    history.push(`${pathname}${withQuery}`);
  }

  return (
    <FilterWrapper>
      <div className={styles.Filter}>
        <p>Filtrer</p>
        <div className={styles.filterContainer}>
          {options?.map((option) => (
            <div key={option} className={styles.option} onClick={() => onFilterChange(option)}>
              <input type="checkbox" readOnly checked={currentFilter.includes(option)} />
              <label>{option}</label>
            </div>
          ))}
        </div>
      </div>
      <Sorter column={column} />
    </FilterWrapper>
  );
};
