import { DSDropdownItem } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";

interface Props {
  readonly t: TranslationType;
  readonly downloadHighchartsXLS: () => void;
  readonly downloadHighchartsPNG: () => void;
}

const HistogramActions = ({ downloadHighchartsPNG, downloadHighchartsXLS, t }: Props) => {
  let histogramActions = [];

  histogramActions.push(
    <DSDropdownItem
      key="export-xls"
      data-target="export-xls"
      onClick={() => {
        downloadHighchartsXLS();
      }}
      label={t("skills.dashboard.actions.downloadXLS", {
        defaultValue: "Télécharger le graphique en XLS",
      })}
    />
  );

  histogramActions.push(
    <DSDropdownItem
      key="export-png"
      data-target="export-png"
      onClick={() => {
        downloadHighchartsPNG();
      }}
      label={t("skills.dashboard.actions.downloadPNG", {
        defaultValue: "Télécharger le graphique en PNG",
      })}
    />
  );

  return histogramActions;
};

export default HistogramActions;
