import { useMachine } from "@xstate/react";

import { ProcessedSkill, SkillWithExpectedLevel } from "types/skills";

import AddSkillModal from "./AddSkillModal";
import { skillMachine } from "./stateMachine";
import AddExpectedLevelModal from "./AddExpectedLevelModal";
import AddExpectedLevelsModal from "./AddExpectedLevelsModal";

interface Props {
  onAbort: () => void;
  skills: Array<ProcessedSkill>;
  skillAlreadyAdded: Array<string>;
  onConfirm: (skills: SkillWithExpectedLevel[]) => void;
}

export const JobModal = ({ onAbort, onConfirm, skillAlreadyAdded, skills }: Props) => {
  const [current, send] = useMachine(skillMachine);

  const handleSkillSelection = (selectedSkills: Array<ProcessedSkill>) => {
    if (selectedSkills.length > 1) {
      send({ type: "SET_SKILLS", payload: { skills: selectedSkills } });
    } else if (selectedSkills.length === 1) {
      send({ type: "SET_SKILL", payload: { skill: selectedSkills[0] } });
    }
  };

  const confirmSelection = (skillsWithExpectedLevel: Array<SkillWithExpectedLevel>) => {
    onConfirm(skillsWithExpectedLevel);
  };

  if (current.matches("setExpectedLevel")) {
    return (
      <AddExpectedLevelModal
        skill={current.context.skill}
        onClose={() => send("ABORT")}
        onBack={() => {
          send("BACK");
        }}
        onConfirm={(skillWithExpectedLevel) => confirmSelection([skillWithExpectedLevel])}
      />
    );
  }

  if (current.matches("setExpectedLevels")) {
    return (
      <AddExpectedLevelsModal
        skills={current.context.skills}
        onBack={() => send("BACK")}
        onClose={() => send("ABORT")}
        onConfirm={(skillsWithExpectedLevel) => confirmSelection(skillsWithExpectedLevel)}
      />
    );
  }

  if (current.matches("init")) {
    return (
      <AddSkillModal
        skillAlreadyAdded={skillAlreadyAdded}
        skills={(skills || []).filter((skill) => skill.isArchived === false)}
        onConfirm={handleSkillSelection}
        onClose={() => {
          send("ABORT");
          onAbort();
        }}
      />
    );
  }

  return <></>;
};
