import { useEffect, useState } from "react";
import { useTypedFetch } from "hooks";

import { FieldRoutesType } from "@skillup/espace-rh-bridge";
import { Flex } from "@skillup/design-system";
import { IParsedField } from "@skillup/training-bridge";

import { isSkillupDeveloper, isSkillupAdmin, isRF } from "utils/User";

import GridCustomFields from "./components/GridCustomFields";
import GridHiddenFields from "./components/GridHiddenFields";
import GridStandardFields from "./components/GridStandardFields";
import FieldDetailsView from "./views/Details";

import styles from "./EnrichmentFields.module.scss";

import { FieldRefetchProvider } from "./context";

const EnrichmentFields = () => {
  const [fields, setFields] = useState<{
    standardFields: IParsedField[];
    customFields: IParsedField[];
    hiddenFields: IParsedField[];
  }>({
    standardFields: [],
    customFields: [],
    hiddenFields: [],
  });
  const [selectedField, setSelectedField] = useState<IParsedField>();

  const { data, loading, refetch } = useTypedFetch<FieldRoutesType.GetV2>({
    method: "GET",
    path: "/fields-v2",
    query: { showDeletedFields: true, showHiddenFields: true },
  });

  useEffect(() => {
    if (!data) return;

    const isHidden = (field: IParsedField) => field.deletedAt && field.deletedAt !== null;

    setFields({
      standardFields: data.filter((field) => field.isStandard && !isHidden(field)),
      customFields: data.filter((field) => !field.isStandard && !isHidden(field)),
      hiddenFields: data.filter((field) => isHidden(field)),
    });
  }, [data]);

  const isAuthorized = isSkillupAdmin() || isSkillupDeveloper();
  const isAuthorizedForCustom = isAuthorized || isRF();

  return (
    <FieldRefetchProvider value={refetch}>
      <Flex flexDirection="column" className={styles.container}>
        {isAuthorizedForCustom && (
          <GridCustomFields
            fields={fields.customFields}
            loading={loading}
            onSelectField={setSelectedField}
          />
        )}
        {isAuthorized && (
          <>
            <GridStandardFields
              fields={fields.standardFields}
              loading={loading}
              onSelectField={setSelectedField}
            />
            <GridHiddenFields fields={fields.hiddenFields} loading={loading} />
          </>
        )}
      </Flex>

      {selectedField && (
        <FieldDetailsView
          key={selectedField.binding}
          selectedField={selectedField}
          onClose={() => setSelectedField(undefined)}
        />
      )}
    </FieldRefetchProvider>
  );
};

export default EnrichmentFields;
