import once from "lodash/once";

import ga from "./ga";
import hotjar from "./hotjar";

const analyticsEnabled =
  process.env.REACT_APP_ENV === "production" && typeof window !== "undefined";

const init = once((_uuid?: string) => {
  if (analyticsEnabled) {
    ga.init();
    hotjar.init();
  }
});

const sendPageview = (
  pathname: string,
  search: string,
  _title: string,
  _event?: any,
  _detail?: any
) => {
  if (analyticsEnabled) {
    ga.sendPageview(pathname + search);
  }
};

const sendEvent = (category: string, action: string, label?: string, value?: string) => {
  if (analyticsEnabled) {
    ga.sendEvent(category, action, label, value);
  }
};

const sendConversion = (label: string, value?: string, currency?: string) => {
  if (analyticsEnabled) {
    ga.sendEvent("conversion", label, currency, value);
  }
};

const setDimension = (label: string, value: string) => {
  if (analyticsEnabled) {
    ga.setDimension(label, value);
  }
};

const setUser = (uuid: string) => {
  if (analyticsEnabled) {
    ga.setUser(uuid);
  }
};

export default {
  init,
  sendPageview,
  sendEvent,
  sendConversion,
  setDimension,
  setUser,
};
