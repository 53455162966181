import React from "react";
import { colors, FoldedCard, Icon } from "@skillup/ui";
import { USER_FILE_VALIDATION_TYPE } from "@skillup/types";

interface IProps {
  scope: USER_FILE_VALIDATION_TYPE;
  errorsCount: number;
  errorList: string[];
}

const ErrorListCard = ({ scope, errorsCount, errorList }: IProps) => {
  return (
    <>
      <div
        style={{
          padding:
            scope === USER_FILE_VALIDATION_TYPE.FREE_ROW ||
            scope === USER_FILE_VALIDATION_TYPE.INTRA_SESSION
              ? "15px 15px 20px 15px"
              : "15px 0px 20px 0px",
          width: "100%",
          borderBottom:
            scope === USER_FILE_VALIDATION_TYPE.FREE_ROW ||
            scope === USER_FILE_VALIDATION_TYPE.INTRA_SESSION
              ? "none"
              : "1px solid #eaeaf1",
        }}
        aria-label="error-list-container"
      >
        <FoldedCard
          color={colors.bwBlack}
          width="100%"
          border={`1px solid ${colors.secondaryRed}`}
          backgroundColor="rgba(255, 103, 96, 0.2)"
          label={`${errorsCount} erreur(s) lors du chargement`}
          description={errorList}
        >
          <Icon.Block color={colors.secondaryRed} size="1.25rem" style={{ marginRight: "15px" }} />
        </FoldedCard>
      </div>
    </>
  );
};

export default ErrorListCard;
