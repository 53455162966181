import Acta from "utils/Acta";

import type { UpdateProjectRoute } from "types/api";
import { buildRequest } from "utils/buildRequest";

export type UpdateSessionParams = {
  projectUuid: string;
  updateData: UpdateProjectRoute["payload"];
};

export async function updateSession({ projectUuid, updateData }: UpdateSessionParams) {
  try {
    const project = await buildRequest<UpdateProjectRoute>({
      method: "POST",
      params: {
        uuid: projectUuid,
      },
      path: "/projects/{uuid}",
      payload: updateData,
    })();

    if (!project) {
      throw new Error();
    }

    Acta.dispatchEvent("sendAppMessage", {
      message: "Session mise à jour avec succès !",
      type: "success",
    });

    return project;
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Une erreur est survenue lors de la modification de la session",
      type: "error",
    });

    return undefined;
  }
}
