import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

export const selectStructureBuilder = createSelector(
  (state: RootState) => state.structureBuilder,
  (structureBuilder) => structureBuilder.structure
);

export const selectCanRedo = createSelector(
  selectStructureBuilder,
  (structureBuilder) => structureBuilder.future.length > 0
);

export const selectCanUndo = createSelector(
  selectStructureBuilder,
  (structureBuilder) => structureBuilder.past.length > 0
);

export const selectStructure = createSelector(
  selectStructureBuilder,
  (structureBuilder) => structureBuilder.present
);

export const selectStructureSections = createSelector(
  selectStructure,
  (structureBuilder) => structureBuilder.sections
);

export const selectHighlightUuid = createSelector(
  (state: RootState) => state.structureBuilder,
  (structureBuilder) => structureBuilder.highlightUuid
);

export const selectGlobalActionsCount = createSelector(
  (state: RootState) => state.structureBuilder,
  (structureBuilder) => structureBuilder.globalActionsCount
);

export const selectErrors = createSelector(selectStructureSections, (sections) => {
  if (sections.length === 0) return [];

  const mergedFirstSectionAndChild = sections[0].pages[0].children[0];
  return sections.reduce((acc, section) => {
    if (section.uuid === sections[0].uuid && mergedFirstSectionAndChild.errors) {
      acc.push({ uuid: section.uuid, errors: mergedFirstSectionAndChild.errors });
    }
    if (section.errors) {
      acc.push({ uuid: section.uuid, errors: section.errors });
    }
    section.pages.forEach((page) => {
      if (page.errors) {
        acc.push({ uuid: page.uuid, errors: page.errors });
      }
      page.children.forEach((child) => {
        if (child.errors && child.uuid !== mergedFirstSectionAndChild.uuid) {
          acc.push({ uuid: child.uuid, errors: child.errors });
        }
      });
    });
    return acc;
  }, []);
});
