import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { useRecoilValue } from "recoil";

import { InterviewPageChild } from "@skillup/espace-rh-bridge";

import availableChildren from "./availableChildren";
import styles from "./PageSettings.module.scss";
import ChildGenerator from "./ChildGenerator";
import Child from "../Child";

import { selectedPageDataState } from "../store";

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : undefined,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const PageSettings = () => {
  const pageData = useRecoilValue(selectedPageDataState);

  return (
    <div className={styles.pageSettings} aria-label="Colonne de configuration de page">
      {pageData && (
        <>
          <div aria-label="Liste des blocks disponibles" className={styles.pool}>
            <Droppable droppableId="pool" index={1}>
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {availableChildren.map((availableChild, index) => (
                    <ChildGenerator
                      key={availableChild.kind}
                      index={index}
                      child={availableChild}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div aria-label="Zone de drop des children" className={styles.content}>
            {/* <div className={styles.breadcrumb}>
              {selectedSectionData?.title} {'>'} {selectedPageData?.title}
            </div> */}
            <div className={styles.drop}>
              <Droppable droppableId="selectedPage" index={1}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {pageData.children.map((child: InterviewPageChild, childIndex) => (
                      <Draggable
                        key={`${child.uuid}-${childIndex}`}
                        draggableId={`child-form_${child.uuid}-${childIndex}`}
                        index={childIndex}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={styles.item}
                            key={`${child.uuid}`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <Child childIndex={childIndex} />
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </div>
        </>
      )}

      {!pageData && (
        <div aria-label="Indications sur la marche à suivre" className={styles.drop}></div>
      )}
    </div>
  );
};

export default PageSettings;
