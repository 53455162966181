import { Project } from "../../Actions/getProjects";

type ProjectUser = Project["pendingTrainees"][0];

const FormattedUsers = ({
  project,
  label,
  users,
}: {
  project: Project;
  label?: string;
  users: ProjectUser[];
}) => {
  return (
    <>
      {users
        .sort((a, b) => a.fullName?.localeCompare(b.fullName))
        .map((t) => (
          <li key={`${project.properties.uuid}-${t.email}`}>
            <span>{t.fullName}</span> (
            {label === undefined ? (t.noEmail ? "sans email" : t.email) : label})
          </li>
        ))}
    </>
  );
};

export default FormattedUsers;
