import { Either } from "./either";
import { Right } from "./right";

export class Left<L, R> implements Either<L, R> {
  public readonly value: L;

  private readonly _isLeft = true;
  private readonly _isRight = false;

  constructor(value: L) {
    this.value = value;
  }

  public left(): L {
    return this.value;
  }

  /**
   * @throws When isLeft(), this method will throw the left() content
   * @example
   * ```
   * function foo() {
   *  return Either.right(true)
   * }
   *
   * function bar() {
   *  return Either.left(new Error("oups I did it again"))
   * }
   *
   * const result = foo()
   * console.log(result.right())
   * // returns true
   *
   * const result = bar()
   * console.log(result.right())
   * // throws Error "oups I did it again" instead of returning anything
   * ```
   */
  public right(): never {
    throw this.value;
  }

  /**
   * @throws When isLeft(), this method will throw the left() content
   * @example
   * ```
   * function foo() {
   *  return Either.right(true)
   * }
   *
   * function bar() {
   *  return Either.left(new Error("oups I did it again"))
   * }
   *
   * const result = foo()
   * result.assertIsRight()
   * console.log(result.right())
   * // returns true
   *
   * const result = bar()
   * result.assertIsRight()
   * // throws Error "oups I did it again" here before going further
   * console.log(result.right())
   * ```
   */
  public assertIsRight(): void {
    throw this.value;
  }

  public map<T>(_: (r: R) => T): Left<L, never> {
    return new Left(this.value);
  }

  public leftMap<T>(cb: (l: L) => T): Left<T, never> {
    return new Left(cb(this.value));
  }

  public flatMap<T>(_: (r: R) => Either<L, T>): Left<L, never> {
    return new Left(this.value);
  }

  public isLeft(): this is Left<L, never> {
    return this._isLeft;
  }

  public isRight(): this is Right<never, R> {
    return this._isRight;
  }

  public fold<NL, NR>(lcb: (r: L) => NL, _: (r: R) => NR): NL {
    return lcb(this.value);
  }
}
