import { PayloadAction } from "@reduxjs/toolkit";

import { movePageToPage } from "./movePageToPage";
import { movePageToSection } from "./movePageToSection";
import type { ActionVector, PageVector, BuilderStructure } from "../..";

export function movePage(state: BuilderStructure, action: PayloadAction<ActionVector<PageVector>>) {
  const { fromPosition, toPosition } = action.payload;

  if (fromPosition.type !== "page") {
    throw new Error();
  }
  switch (toPosition.type) {
    case "section":
      if (fromPosition.sectionIndex === toPosition.sectionIndex) {
        return state;
      }
      return movePageToSection(state, fromPosition, toPosition);
    case "page":
      return movePageToPage(state, fromPosition, toPosition);
    default:
      throw new Error();
  }
}
