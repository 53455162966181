import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Label as DSLabel, DSSortable, DSTooltip, Flex, Select } from "@skillup/ui";

import { useSkills } from "services/Skills/useSkills";

import { type Child, selectHighlightUuid } from "../../../../reducer";
import { Permissions } from "../../Permissions/Permissions";

import { Divided } from "../ChildWrappers";

import styles from "./SkillsChild.module.scss";

type SkillsChildType = Extract<Child, { kind: "skills" }>;

export function SkillsChild({
  child,
  indexes,
  updateChild,
}: {
  child: SkillsChildType;
  updateChild: (child: Partial<SkillsChildType>) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
}) {
  const highlightUuid = useSelector(selectHighlightUuid);
  const { data: skills } = useSkills();
  const data = child;

  const toList = useMemo(() => {
    if (data.categoryValuesOrder) {
      return data.categoryValuesOrder;
    }
    if (skills?.skills) {
      return [
        ...new Set(
          skills.skills.reduce<string[]>((acc, skill) => {
            if (skill.categories[data.groupByCategoryUuid]) {
              acc.push(skill.categories[data.groupByCategoryUuid]);
            }
            return acc;
          }, [])
        ),
      ].sort((a, b) => a.localeCompare(b));
    }
    return [];
  }, [skills, data.groupByCategoryUuid, data.categoryValuesOrder]);

  function updateCategory(categoryUuid: string) {
    const preExistingValuesOrder =
      categoryUuid === data?.groupByCategoryUuid ? data?.categoryValuesOrder : undefined;

    updateChild({
      ...data,
      groupByCategoryUuid: categoryUuid,
      categoryValuesOrder: preExistingValuesOrder,
    });
  }
  const currentCategory = () => {
    const category = skills.categories.find(
      (category) => category.uuid === data.groupByCategoryUuid
    );
    if (!category) return undefined;
    return {
      label: category.label,
      value: category.uuid,
    };
  };

  const handleReorder = (
    items: {
      id: string;
      title: string;
    }[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    updateChild({ ...data, categoryValuesOrder: result.map((item) => item.id) });
    return result;
  };

  return (
    <Divided>
      <Flex column className={styles.skillsChild}>
        <p>
          Le bloc « Évaluation de compétences » liste l’ensemble des compétences liées à la fiche de
          poste assignée au collaborateur.
        </p>
        <DSLabel label="Regroupement des compétences" />
        {skills && (
          <DSTooltip
            /* [CSB] permission changes disabled */
            label="Cet élément ne peut pas être modifié sur une campagne en cours."
            className={styles.skillsChild__categoryTooltip}
          >
            <Select
              className={styles.skillsChild__categorySelect}
              defaultValue={currentCategory()}
              noOptionsMessage="Catégorie non trouvée"
              clearable={true}
              placeholder="Sélectionnez une catégorie"
              options={
                skills.categories?.map((category) => ({
                  label: category.label,
                  value: category.uuid,
                })) ?? []
              }
              value={child.groupByCategoryUuid}
              onChange={(categoryUuid: string) => updateCategory(categoryUuid)}
              autoFocus={highlightUuid === child.uuid}
              disabled // [CSB] permission changes disabled
            />
          </DSTooltip>
        )}

        <DSSortable
          handle
          items={toList.map((v) => ({ id: v, title: v }))}
          // @ts-expect-error the types in the DS are wrong, they should be generic
          reorderItems={handleReorder}
          locked={true} // [CSB] permission changes disabled
        />
      </Flex>
      <Flex column>
        <Permissions
          uuid={child.uuid}
          indexes={indexes}
          kind="skills"
          restrictions={child.disallow}
          order={["evaluation", "commentEvaluation", "comment"]}
          override={{
            evaluation:
              "Qui peut évaluer le collaborateur sur les compétences liées à sa fiche de poste ?",
            comment: "Qui peut commenter globalement la liste des compétences ?",
            commentEvaluation: "Qui peut commenter individuellement chaque compétence à évaluer ?",
          }}
        />
      </Flex>
    </Divided>
  );
}
