import Icon from "components/Icon";
import { filter as filterIcon } from "uiAssets/StrokeIcons";

import styles from "./FilterWrapper.module.scss";
import { Popover } from "@skillup/ui";

export default ({ children }) => {
  return (
    <Popover
      className={styles.FilterWrapper}
      placement={["bottom", "auto"]}
      content={() => (
        <div className={styles.filters} role="listbox">
          {children}
        </div>
      )}
    >
      <button className={styles.openIcon} role="dialog">
        <Icon strokeIcon={filterIcon} width={10} />
      </button>
    </Popover>
  );
};
