import cx from "classnames";

import { ParseDate } from "@skillup/shared-utils";

import { plural } from "utils/locale";

import styles from "./TrackingDuration.module.scss";

interface TrackingDurationProps {
  readonly validUntil?: string;
}
function TrackingDuration({ validUntil }: TrackingDurationProps) {
  if (!validUntil) return <></>;
  const now = Date.now();
  const date1 = ParseDate.FromMiliTimestamp(now).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const date2 = ParseDate.FromISO(validUntil);
  const { days } = date2.diff(date1, ["days", "hours"]);
  if (days < 0)
    return (
      <div className={cx(styles.TrackingDuration, styles.expired)}>
        {plural(-days, "- %n jour%s")}
      </div>
    );
  const { months } = date2.diff(date1, ["months", "days"]);
  if (months < 2) return <div className={styles.TrackingDuration}>{plural(days, "%n jour%s")}</div>;
  if (months < 12)
    return <div className={styles.TrackingDuration}>{plural(months, "+ %n mois")}</div>;
  const { years } = date2.diff(date1, ["years", "months"]);
  return <>{plural(years, "+ %n an%s")}</>;
}

export default TrackingDuration;
