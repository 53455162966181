import React from "react";
import { CopyText } from "../../CopyText";

import styles from "./Reference.module.scss";

export default ({ row, initialData }) => {
  const currentRow = initialData[row.id];
  const reference = currentRow?.properties.reference;

  return (
    <div className={styles.Reference}>
      <span>
        <CopyText text={reference} />
      </span>
      &nbsp;
      <span>{reference}</span>
    </div>
  );
};
