import { useToggle } from "react-use";
import React, { useState } from "react";

import Acta from "utils/Acta";
import type { JobsRoutes } from "types/api";
import TextInput from "components/TextInput";
import { buildRequest } from "utils/buildRequest";
import InteractiveButton from "components/InteractiveButton";

import styles from "./CreateJobField.module.scss";

type CreateJobFieldRoute = JobsRoutes["Jobs.Fields.Create"];

const CreateJobFieldModal = () => {
  const [label, setLabel] = useState<string>();
  const [index, setIndex] = useState<string>();

  const [loading, toggleLoading] = useToggle(false);

  const createJobField = async () => {
    toggleLoading(true);
    try {
      const creationRequest = buildRequest<CreateJobFieldRoute>({
        method: "POST",
        path: "/competences/jobs",
        payload: {
          jobFieldData: {
            index: parseInt(index),
            label,
          },
        },
      });

      const response = await creationRequest();

      if (!response.uuid) {
        throw new Error();
      }

      Acta.dispatchEvent("sendAppMessage", {
        type: "success",
        message: "Champ de fiche de poste ajouté avec succès !",
      });
      Acta.dispatchEvent("closeModal");
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        type: "error",
        message: "Echec lors de l'ajout d'un champ de fiche de poste.",
      });
    }
    toggleLoading(false);
  };

  return (
    <div className={styles.createJobFieldModal}>
      <TextInput
        alwaysOpen
        label="Label"
        autoComplete="off"
        onChange={(e) => setLabel(e.currentTarget.value)}
      />
      <TextInput
        min={0}
        alwaysOpen
        type="number"
        label="Index"
        onChange={(e) => setIndex(e.currentTarget.value)}
      />
      <InteractiveButton label="Créer" loading={loading} onClick={createJobField} />
    </div>
  );
};

export default CreateJobFieldModal;
