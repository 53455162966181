import { useRecoilState } from "recoil";
import { userAreasFilter } from "../../state/filters";

import styles from "./UserAreaSelect.module.scss";
import UserAreaSelect from "components/UserAreaSelect/UserAreaSelect";

function RegulatoryUserArea() {
  const [userAreas, setUserAreas] = useRecoilState(userAreasFilter);

  return (
    <UserAreaSelect
      className={styles.UserAreaSelect}
      selectClassName={styles.select}
      value={userAreas}
      onChange={setUserAreas}
    />
  );
}

export default RegulatoryUserArea;
