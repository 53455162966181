import { Button } from "@skillup/ui";
import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useSetState } from "react-use";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

import styles from "./InterviewsHistoryImport.module.scss";

interface State {
  loading?: boolean;
  errors?: { index: number; fields: string[] }[];
  warnings?: { index: number; fields: string[] }[];
  commit: boolean;
  body?: FormData;
}

export default () => {
  const [state, setState] = useSetState<State>({ commit: false, loading: false });

  const onDrop = async (files) => {
    setState({ loading: true });

    const body = new FormData();
    body.append("file", files[0]);

    try {
      const { errors, warnings } = (await DataLayer.request({
        body,
        contentType: "multipart/form-data",
        method: "POST",
        url: `/v1/history/interviews/report`,
      })) as {
        errors: { index: number; fields: string[] }[];
        warnings: { index: number; fields: string[] }[];
      };

      console.log({ errors, warnings });

      setState({ errors, warnings, body, commit: true, loading: false });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de l’envoi de votre fichier.",
        type: "error",
      });
    }
  };

  const onUpload = async () => {
    setState({ loading: true });

    try {
      await DataLayer.request({
        body: state.body,
        contentType: "multipart/form-data",
        method: "POST",
        url: `/v1/history/interviews/import`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "Upload réussi",
        type: "success",
      });
    } catch (error) {
      console.error(error);

      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de l’envoi de votre fichier.",
        type: "error",
      });
    } finally {
      onReset();
    }
  };

  const onReset = () =>
    setState({ commit: false, body: null, warnings: null, errors: null, loading: false });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 52428800,
    multiple: false,
  });

  const hasErrors = useMemo(() => state.errors?.length, [state.errors]);
  const hasWarnings = useMemo(() => state.warnings?.length, [state.warnings]);
  const isOK = useMemo(
    () => state.commit && !state.warnings?.length && !state.errors?.length,
    [state.commit, state.warnings, state.errors]
  );

  return (
    <div className={styles.InterviewsHistoryImport}>
      <section className={styles.dropZone}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {state.loading ? (
            <p>En cours de traitement...</p>
          ) : (
            <p>Déposez un fichier ici ou cliquez ici pour choisir un fichier</p>
          )}
        </div>
      </section>
      {hasErrors ? (
        <div className={styles.errorsContainer}>
          Il y a des erreurs dans le fichier d'import, veuillez vous référer à la console pour voir
          les lignes concernées. Attention, si vous souhaitez tout de même importer le fichier, les
          lignes comportant des erreurs seront ignorées.
        </div>
      ) : null}
      {hasWarnings ? (
        <div className={styles.warningsContainer}>
          Il y a des warnings dans le fichier d'import, veuillez vous référer à la console pour voir
          les lignes concernées.
        </div>
      ) : null}
      {isOK ? <div className={styles.successContainer}>Tout est OK!</div> : null}
      {state.commit && (
        <div className={styles.buttonContainer}>
          <Button label="Importer" kind="primary" disabled={!state.body} onClick={onUpload} />
          <Button label="Annuler" kind="secondary" onClick={onReset} />
        </div>
      )}
    </div>
  );
};
