import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Text, Flex } from "@skillup/design-system";
import { FormatDate, ParseDate } from "@skillup/shared-utils";

import RoundChart from "./RoundChart";
import { SkillHeaderContainer, StyledLink } from "./SkillHeader.styled";

interface Props {
  totalEvaluations: number;
  expectedEvaluations: number;
  overExpectedEvaluations: number;
  job: { uuid: string; name: string };
  evaluatedAt?: Date;
}

export const SkillHeader = ({
  totalEvaluations,
  expectedEvaluations,
  overExpectedEvaluations,
  job,
  evaluatedAt,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const expectedAndOverExpectedEvaluations = expectedEvaluations + overExpectedEvaluations;
  const expectedEvaluationScore = `${expectedAndOverExpectedEvaluations}/${totalEvaluations}`;
  const underExpectedEvaluations = totalEvaluations - expectedAndOverExpectedEvaluations;
  const underExpectedEvaluationsScore = `${underExpectedEvaluations}/${totalEvaluations}`;

  return (
    <SkillHeaderContainer>
      <Flex flexDirection="column" width="100%" paddingVertical="s" paddingHorizontal="m">
        <Flex alignItems="center">
          <Text espaceFont="h6">
            {t("collaborator.skills.skillTitle", {
              defaultValue: "Compétences évaluées depuis la fiche de poste ",
            })}
            <StyledLink
              onClick={() =>
                history.push({
                  pathname: `/responsable/referentiels/fiches-de-poste/${job.uuid}`,
                })
              }
            >
              {job.name}
            </StyledLink>
          </Text>
        </Flex>
        <Text espaceFont="body2Regular">
          {t("collaborator.skills.lastEvaluationDate", {
            defaultValue: "Dernière évaluation le {{date}}",
            date: FormatDate.ToStringFormat(ParseDate.FromParsableJS(evaluatedAt), "dd/MM/yyyy"),
          })}
        </Text>
        {expectedAndOverExpectedEvaluations !== 0 && (
          <Flex flexDirection="row" marginTop="m">
            <Flex alignItems="center">
              <RoundChart
                expected={expectedEvaluations}
                overExpected={overExpectedEvaluations}
                underExpected={underExpectedEvaluations}
              />
            </Flex>
            <Flex flexDirection="column" marginLeft="m" gap="s">
              <Flex alignItems="center">
                <Text espaceFont="h1" marginRight="xs">
                  {expectedEvaluationScore}
                </Text>
                <Text espaceFont="body2Bold">
                  {t("collaborator.skills.overEvaluatedSkills1", {
                    defaultValue: "compétences sont évaluées",
                  })}
                  <br />
                  {t("collaborator.skills.overEvaluatedSkills2", {
                    defaultValue: "au niveau attendu ou au-delà",
                  })}
                </Text>
              </Flex>
              {underExpectedEvaluations > 1 && (
                <Flex>
                  <Text espaceFont="captionBold">
                    {t("collaborator.skills.underEvaluatedSkills", {
                      defaultValue: "{{score}} compétences ont été évaluées sous le niveau attendu",
                      score: underExpectedEvaluationsScore,
                    })}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </SkillHeaderContainer>
  );
};
