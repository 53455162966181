import { Left as EitherLeft } from "./left";
import { Right as EitherRight } from "./right";

export interface Either<L, R> {
  map<T>(cb: (r: R) => T): Either<L, T>;
  leftMap<T>(cb: (l: L) => T): Either<T, R>;
  flatMap<T>(cb: (r: R) => Either<L, T>): Either<L, T>;
  left(): L;
  right(): R;
  isLeft(): this is Either<L, never>;
  isRight(): this is Either<never, R>;
  assertIsRight(): void;
  fold<NL, NR>(lcb: (r: L) => NL, rcb: (r: R) => NR): NL | NR;
}

type ExtractLeft<T extends Array<Either<any, any>>> =
  T[number] extends Either<infer Left, any> ? Left : never;

type ExtractRight<T extends Array<Either<any, any>>> = {
  [P in keyof T]: T[P] extends Either<any, infer Right> ? Right : never;
};

export namespace Either {
  export function All<T extends [Either<any, any>] | Array<Either<any, any>>>(
    arrayOfEithers: T
  ): Either<Array<ExtractLeft<T>>, ExtractRight<T>> {
    const right = [];
    const left = [];

    for (const either of arrayOfEithers) {
      if (either.isLeft()) {
        left.push(either.left());
      } else {
        right.push(either.right());
      }
    }

    if (left.length > 0) {
      return Either.Left(left);
    }

    return new EitherRight(right) as any;
  }

  export function Right<R>(value: R): EitherRight<never, R> {
    return new EitherRight(value);
  }

  export function Left<L>(value: L): EitherLeft<L, never> {
    return new EitherLeft(value);
  }

  export function right<R>(value: R): EitherRight<never, R> {
    return Either.Right(value);
  }

  export function left<L>(value: L): EitherLeft<L, never> {
    return Either.Left(value);
  }

  export function FromTry<R>(value: () => R): Either<unknown, R> {
    try {
      return Right(value());
    } catch (e) {
      return Left(e);
    }
  }
}
