import React from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@skillup/hooks";
import { stringify } from "qs";

import history from "utils/history";

import styles from "./Sorter.module.scss";

export default ({ column: { id, toggleSortBy }, column }) => {
  const { pathname } = useLocation();
  const query: any = useQuery();

  function onChange(desc = true) {
    toggleSortBy(desc);

    const withQuery = stringify(
      {
        ...query,
        sortBy: {
          ...query.sortBy,
          [id]: desc,
        },
      },
      { addQueryPrefix: true }
    );

    history.push(`${pathname}${withQuery}`);
  }

  return (
    <div {...column.getHeaderProps(column.getSortByToggleProps())} className={styles.Sorter}>
      <p>Trier</p>
      <p onClick={() => onChange(false)}>Trier de A → Z</p>
      <p onClick={() => onChange(true)}>Trier de Z → A</p>
    </div>
  );
};
