import { ProjectRoutes } from "@skillup/espace-rh-bridge";

import { Project } from "./getProjects";
import { UserList } from "components/UserChoiceModal/UserChoiceModal";
import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";

let isSubmitting;

const linkUsersToProject = async (users: UserList, project: Project, doNotNotify?: boolean) => {
  if (isSubmitting) {
    return;
  }

  try {
    isSubmitting = true;

    await buildRequest<ProjectRoutes.LinkUsersToProject>({
      method: "POST",
      path: "/projects/link-users",
      payload: {
        doNotNotify,
        project: {
          type: "intra",
          uuid: project.properties.uuid,
        },
        users: users.map((u) => ({
          email: u.email,
        })),
      },
    })();

    const message =
      users.length === 1
        ? "Collaborateur ajouté avec succès"
        : "Collaborateurs ajoutés avec succès";
    Acta.dispatchEvent("sendAppMessage", {
      message,
      type: "success",
    });

    Acta.dispatchEvent("closeModal");
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Erreur lors de l'ajout de collaborateur(s)",
      type: "error",
    });
  } finally {
    isSubmitting = false;
  }
};

export default linkUsersToProject;
