import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useTemplates } from "./useTemplates";
import { archiveTemplate } from "./archiveTemplate";
import { unarchiveTemplate } from "./unarchiveTemplate";
import { editTemplate, EditTemplatePayload } from "./editTemplate";

function useTemplatesRefresh() {
  const queryClient = useQueryClient();

  function refresh() {
    queryClient.invalidateQueries(["people-review-templates"]);
  }

  return refresh;
}

export function useTemplate(templateUuid: string) {
  const { templates } = useTemplates({ refetchOnMount: false, refetchOnWindowFocus: false });
  const refreshTemplates = useTemplatesRefresh();

  const archiveMutation = useMutation(
    async () => {
      const response = await archiveTemplate({ templateUuid });
      refreshTemplates();
      return response;
    },
    {
      mutationKey: ["archive-template", templateUuid],
    }
  );

  const unarchiveMutation = useMutation(
    async () => {
      const response = await unarchiveTemplate({ templateUuid });
      refreshTemplates();
      return response;
    },
    {
      mutationKey: ["unarchive-template", templateUuid],
    }
  );

  const editMutation = useMutation(
    async (payload: EditTemplatePayload) => {
      const response = await editTemplate({ payload, templateUuid });
      refreshTemplates();
      return response;
    },
    {
      mutationKey: ["edit-template", templateUuid],
    }
  );

  const template = templates?.find((template) => template.uuid === templateUuid);

  return {
    mutations: {
      archive: archiveMutation,
      edit: editMutation,
      unarchive: unarchiveMutation,
    },
    template,
  };
}
