import React from "react";
import CheckBox from "components/CheckBox";
import { noneFilter, allFilter } from "constants/dataTable";

import styles from "./SelectFiltersStyles.module.scss";

export interface IProps {
  filter: any;
  onChange: (...args: any[]) => void;
  options: Array<any>;
}

export default class SelectFilters extends React.PureComponent<IProps> {
  private container: HTMLElement | null;

  private onChange = (option: any, checked): void => {
    const { options, filter, onChange: updateFilters } = this.props;

    let currentFilter = filter || options;
    const isNoneFilter = currentFilter.length === 1 && currentFilter[0].mode === "none";
    if (isNoneFilter) currentFilter = [];

    const didSelectNone = !checked && currentFilter.length === 1;
    const didSelectAll = checked && options.length === currentFilter.length + 1;

    if (didSelectNone) {
      updateFilters([noneFilter]);
    } else if (didSelectAll) {
      updateFilters(null);
    } else if (checked) {
      updateFilters([...currentFilter, option]);
    } else {
      const optionIndex = currentFilter.findIndex((filter) => filter.value === option.value);

      const newFilters = [
        ...currentFilter.slice(0, optionIndex),
        ...currentFilter.slice(optionIndex + 1),
      ];

      updateFilters(newFilters);
    }
  };

  private isSelected = (item) => {
    const { filter } = this.props;
    return !filter || filter.some((f) => f.value === item.value && f.mode === item.mode);
  };

  private selectAll = (_, checked) => {
    const { onChange } = this.props;

    if (checked) {
      onChange(null);
    } else {
      onChange([noneFilter]);
    }
  };

  public render(): JSX.Element {
    const { options, filter } = this.props;

    return (
      <div className={styles.SelectFilters} ref={(elem) => (this.container = elem)}>
        <CheckBox
          onChange={this.selectAll}
          // @ts-ignore
          item={allFilter}
          isSelected={!filter}
        />
        {options.map((item) => (
          <CheckBox
            key={item.value ? `checkbox-value-${item.value}` : `checkbox-mode-${item.mode}`}
            onChange={this.onChange}
            item={item}
            isSelected={this.isSelected(item)}
          />
        ))}
      </div>
    );
  }
}
