import { ProcessedSkill, SkillWithExpectedLevel } from "types/skills";

import AddExpectedLevelModal from "./AddExpectedLevelModal";

interface Props {
  onAbort: () => void;
  skill: ProcessedSkill;
  onConfirm: (skills: SkillWithExpectedLevel[]) => void;
}

export const EditSkillModal = ({ onAbort, onConfirm, skill }: Props) => {
  return (
    <AddExpectedLevelModal
      skill={skill}
      onBack={onAbort}
      onClose={onAbort}
      onConfirm={(skillWithExpectedLevel) => onConfirm([skillWithExpectedLevel])}
    />
  );
};
