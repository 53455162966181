import styled from "styled-components";

import { DSButton } from "@skillup/ui";

export const FullWidth = styled.div`
  width: 100%;
`;

export const FlexCenterButton = styled(DSButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
