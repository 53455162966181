import useTranslation from "hooks/useTranslation";
import styles from "./NotAvailableInNarrowView.module.scss";
import mobileViewImg from "assets/mobile_view.svg";

const NotAvailableInNarrowView = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.NotAvailableInNarrowView}>
      <img src={mobileViewImg} alt="narrow version is not supported" />
      <span>
        {t("commons.tooNarrow", {
          defaultValue:
            "Cette vue n’est pas optimisée pour une utilisation sur une taille d’écran réduite.",
        })}
        <br />
        {t("commons.useDesktop", {
          defaultValue: "Agrandissez votre fenêtre ou utilisez plutôt un ordinateur.",
        })}
      </span>
    </div>
  );
};

export default NotAvailableInNarrowView;
