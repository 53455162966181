import type { IParsedField } from "../Fields";

export * from "./useCases/importTrainings/types";

export const TRAINING_VALIDATION_ERROR_CODES = {
  TRAINING_NOT_FOUND: "TRAINING_NOT_FOUND",
  TRAINING_NOT_UNIQUE: "TRAINING_NOT_UNIQUE",
  STRING_EMPTY: "STRING_EMPTY",
  STRING_UNDEFINED: "STRING_UNDEFINED",
  STRING_INVALID: "STRING_INVALID",
  NUMBER_UNDEFINED: "NUMBER_UNDEFINED",
  NUMBER_INVALID: "NUMBER_INVALID",
  NUMBER_NEGATIVE: "NUMBER_NEGATIVE",
  MODE_INVALID: "MODE_INVALID",
  TAG_INVALID: "TAG_INVALID",
  PRICE_WITH_TAX_UNDEFINED: "PRICE_WITH_TAX_UNDEFINED",
  PRICE_WITH_TAX_NOT_ALLOWED: "PRICE_WITH_TAX_NOT_ALLOWED",
  FIELD_INVALID: "FIELD_INVALID",
  FIELD_VALUE_INVALID_OPTION: "FIELD_VALUE_INVALID_OPTION",
  FIELD_VALUE_INVALID_NUMBER: "FIELD_VALUE_INVALID_NUMBER",
  FIELD_VALUE_INVALID_DATE: "FIELD_VALUE_INVALID_DATE",
  FIELD_VALUE_INVALID_TEXT: "FIELD_VALUE_INVALID_TEXT",
  TRAILER_INVALID: "TRAILER_INVALID",
  REFERENCE_INVALID: "REFERENCE_INVALID",
} as const;

export type TrainingValidationErrorCode =
  (typeof TRAINING_VALIDATION_ERROR_CODES)[keyof typeof TRAINING_VALIDATION_ERROR_CODES];

export type TrainingValidationError = {
  field: string;
  value?: any;
  code: TrainingValidationErrorCode;
  message: string;
};

export type TrainingValidationChunk = TrainingValidationError[] | null;

export const MODES = [
  "presential",
  "blended",
  "elearning",
  "mooc",
  "distancial",
  "individual",
  "digital",
  "virtual-classroom",
  "webinar",
  "conference",
] as const;

export type Mode = (typeof MODES)[number];

export type GetEmployeeTrainingRequestError =
  | "invalid-params"
  | "organization-not-found"
  | "unauthorized"
  | "row-not-found"
  | "user-not-found";

export type GetMultipleGroupsTrainingsError =
  | "invalid-params"
  | "organization-not-found"
  | "unauthorized";

export type TrainingType = "Intra" | "Inter";

export type GetTrainingsByUuidsError = "invalid-params" | "organization-not-found" | "unauthorized";

export type GetEmployeesRowsResponse = { uuid: string };

export type ActiveCompany = {
  uuid: string;
  catalogDiscountRates?:
    | {
        uuid: string;
        globalDiscountRate: number;
      }[]
    | undefined;
  globalDiscountRate?: number | undefined;
};

export interface OrganizationFrameworkAgreements {
  uuid: string;
  catalogDiscountRates?: Array<{
    uuid: string;
    globalDiscountRate: number;
  }>;
  globalDiscountRate?: number;
}

export interface IPortalList {
  uuid: string;
  index: number;
  title: string;
  trainings: { uuid: string; index: number; name: string }[];
  courses: {
    index: number;
    name: string;
    uuid: string;
    modules: { uuid: string; index: number; tags?: string[]; name: string }[];
  }[];
}

export interface IDBTraining {
  uuid: string;
  createdAt: string;
  name: string;
  seoSlug: string;
  mode: Mode;
  duration: string;
  price: number;
  priceWithTax?: number;
  hoursPerDay?: number;
  certification?: string;
  reviewsCount: number;
  reviewsGlobalScore: number;
  trailer?: string;
  targetAudience?: string;
  objectives?: string;
  prerequisites?: string;
  program?: string;
  description?: string;

  trainingOrganizationName: string;
  isCPF?: boolean;
  hasDistancialSessions?: boolean;
  organizationLogo?: string;
  reference?: string;
  subtitle?: string;
  tag?: {
    uuid: string;
    name: string;
    seoSlug: string;
  };
  stats?: {
    futureSessionsCount: number;
    doneSessionsCount: number;
  };
}

export type GetTrainingsByUuidsResponse = IDBTraining[];
export interface ITrainingOptions {
  filter?: (training: IDBTraining) => boolean;
  parser?: (training: IDBTraining) => Promise<any>;
}
export type GetMultipleGroupsTrainingsResponse = IPortalList[];

export type GetEmployeeTrainingSessionsStateError =
  | "invalid-params"
  | "organization-not-found"
  | "training-not-found";

export type TrainingSessionState = {
  sessionUuid: string;
  isPositioned: boolean;
};

export type TrainingFieldDetails = {
  uuid: string;
  version: number;
  fields: Record<string, IParsedField>;
};

export type EmployeeTrainingSessionState = {
  sessionUuid: string;
  isPositioned: boolean;
};

export type ScheduleRowExposedStates =
  | "to_arbitrate"
  | "pending_for_additionnal_validation"
  | "pending_for_HR"
  | "denied_or_cancelled"
  | "approved"
  | "waiting_for_subscription"
  | "waiting_for_subscription_collab"
  | "subscribed"
  | "realized"
  | "denied"
  | "cancelled"
  | "isSpecificTrainingRequest";

export type CollaboratorTrainingRequest = {
  uuid: string;
  state: ScheduleRowExposedStates;
  isImported?: boolean;
  startDate?: string;
  endDate?: string;
  createdAt: string;
  price?: string;
  organizationName?: string;
  training?: {
    name?: string;
    types?: string[];
    seoSlug?: string;
  };
  duration?: string;
  city?: string;
};

export type TrainingPayload = Omit<
  IDBTraining,
  "seoSlug" | "reviewsCount" | "reviewsGlobalScore" | "createdAt"
>;

export type CreationTrainingPayload = Omit<TrainingPayload, "uuid">;

export type TrainingFields = Record<string, IParsedField>;

export type TrainingDetails = IDBTraining & {
  version?: number;
  fields?: TrainingFields;
};

export type GetEmployeesTrainingRequestsError =
  | "invalid-params"
  | "organization-not-found"
  | "not-found"
  | "no-collaborators"
  | "unauthorized";

export type CollectionData = {
  binding: string;
  label: string;
  value: string | number;
}[];

export type TrainingRequest = {
  id: string;
  state: ScheduleRowExposedStates;
  collaborator: {
    name?: string;
    role?: string;
  };
  training?: {
    name?: string;
  };
  organizationName?: string;
  startDate?: string;
  endDate?: string;
  createdAt?: string;
  price?: any;
  session?: {
    formattedTranslatableDates?: string[];
    city?: string;
    type?: string;
  };
  duration?: string;
  seoSlug?: string;
  schedule: {
    uuid: string;
    name: string;
    isImported?: boolean;
  };
  validationLevel?: number;
};

export type GetEmployeesPendingTrainingRequestsError =
  | "invalid-params"
  | "organization-not-found"
  | "unauthorized";

export type PendingTrainingRequest = {
  id: string;
  state: ScheduleRowExposedStates;
  collaborator: {
    name?: string;
    role?: string;
  };
  training: {
    name: string | undefined;
  };
  comment: string | undefined;
  organizationName?: string;
  createdAt: string;
  priority?: string | number | undefined;
};

export type PrepareImportTrainingData = {
  tags: { uuid: string; name: string }[];
  companyFields: IParsedField[];
  isCompanyWithTax: boolean;
  modes: readonly Mode[];
};
