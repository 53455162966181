import React from "react";
import { useSetState } from "react-use";
import type { InterviewWithStates } from "@skillup/types";
import { isUndefined } from "lodash";

import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import InteractiveButton from "components/InteractiveButton";
import Loader from "components/Loader";
import Icon from "components/Icon";
import { cross } from "uiAssets/StrokeIcons";

import UserSearchInput from "../../../../Projects/Components/UserSearchInput";
import styles from "./CampaignUserUpdateModalStyles.module.scss";

interface IUser {
  email: string;
  uuid?: string;
  fullName: string;
}
interface IState {
  selectedUser?: IUser;
  isAdding: boolean;
}

interface IProps {
  readonly campaignUuid: string;
  readonly interview: InterviewWithStates;
  readonly resync: () => Promise<InterviewWithStates[]>;
}

const CampaignUserUpdateModal = ({ resync, campaignUuid, interview }: IProps): JSX.Element => {
  const [{ selectedUser, isAdding }, setState] = useSetState<IState>({ isAdding: false });
  const handleSelect = (user: IUser): void => setState({ selectedUser: user });
  const removeUser = (): void => setState({ selectedUser: undefined });

  const updateManager = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (isUndefined(selectedUser)) return;

    setState({ isAdding: true });

    try {
      await DataLayer.request({
        method: "POST",
        url: `/v1/campaigns/${campaignUuid}/interviews/${interview.uuid}/manager`,
        body: JSON.stringify({ newManagerUuid: selectedUser.uuid }),
      });

      resync();
      Acta.dispatchEvent("closeModal");
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Changement de manager impossible",
        type: "error",
      });
    }

    setState({ isAdding: false });
  };

  return (
    <div className={styles.CampaignUserUpdateModal}>
      <form className={styles.searchForm} onSubmit={async (e) => updateManager(e)}>
        <UserSearchInput autoFocus className={styles.searchInput} onSelect={handleSelect} />

        <div className={styles.usersList}>
          {!isUndefined(selectedUser) && (
            <div className={styles.user}>
              <div className={styles.meta}>
                <span className={styles.fullName}>{selectedUser.fullName}</span>
                <small className={styles.email}>{selectedUser.email}</small>
              </div>
              <button className={styles.removeUserBtn} type="button" onClick={removeUser}>
                <Icon className={styles.removeUserIcon} strokeIcon={cross} width={10} />
              </button>
            </div>
          )}
        </div>

        <div className={styles.modalFooter}>
          {isAdding ? (
            <Loader />
          ) : (
            <InteractiveButton label="Changer le manager" size="small" type="submit" />
          )}
        </div>
      </form>
    </div>
  );
};

export default CampaignUserUpdateModal;
