import React, { useMemo } from "react";
import cx from "classnames";

import { Badge, DSTooltip as Tooltip } from "@skillup/ui";
import styles from "./PositionnedTrainees.module.scss";
import { SESSION_STATES } from "../../helper";

export default ({ initialData, row, value }) => {
  const currentRow = initialData[row.id];
  const { minStock, stock } = currentRow.properties;
  const unsummonedTraineesCount = row.original.unsummonedTraineesCount;
  const state = row.values.state;

  const grammarTooltip = useMemo(() => {
    if (unsummonedTraineesCount === 1) {
      return "1 collaborateur inscrit n'a pas reçu de convocation";
    } else {
      return `${unsummonedTraineesCount} collaborateurs inscrits n'ont pas reçu de convocation`;
    }
  }, [unsummonedTraineesCount]);

  return (
    <div className={styles.PositionnedTrainees}>
      <div className={styles.high}>
        <span>{value}</span>
        {state === SESSION_STATES.summoned && unsummonedTraineesCount > 0 && (
          <Tooltip label={grammarTooltip}>
            <Badge
              value={unsummonedTraineesCount}
              className={styles.badge}
              backgroundColor={"#FCF1E8"}
            />
          </Tooltip>
        )}
      </div>
      {minStock && (
        <p className={cx(styles.default, { [styles.min]: minStock && value < minStock })}>
          min: {minStock}
        </p>
      )}
      {stock && (
        <p className={cx(styles.default, { [styles.max]: stock && value > stock })}>max: {stock}</p>
      )}
    </div>
  );
};
