import { useRef, useState, useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { GridColDef, GridRowSelectionModel } from "@mui/x-data-grid-pro";

import {
  Flex,
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  DSDataGrid,
  DSFilters,
  FilterRef,
} from "@skillup/ui";
import { ListUtils } from "@skillup/shared-utils";
import { ReviewsRoutes } from "@skillup/espace-rh-bridge";
import { Config, HotReview } from "@skillup/training-bridge";

import { useTypedFetch } from "hooks";
import useTranslation from "hooks/useTranslation";
import useReviewsTableData from "./hooks/useReviewsTableData";

import useReviewFilters from "../utils/useReviewFilters";
import { initialState } from "../utils/dataGridProps";
import { recallTrainees } from "../utils/recallTrainees";
import { ReviewRow } from "../utils/types";

import { ToolbarButton } from "../components/ToolbarButton";
import HotReviewSidePanel from "./components/HotReviewSidePanel";

import styles from "./HotReviewListView.module.scss";

const HotReviewListView = () => {
  const { t, i18n } = useTranslation();
  const [loadingSendMails, setLoadingSendMails] = useState(false);
  const { id: currentUrlId } = useParams<{ id: string | null }>();
  const [showAlert, setShowAlert] = useState(false);
  const filterRef = useRef<FilterRef>();
  const [filters, filterValues, setFilterValues] = useReviewFilters(t);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [currentReviewUuid, setCurrentReviewUuid] = useState<string>();
  const [paginationModel, setPaginationModel] = useState<ListUtils.PaginationProperties>({
    page: 0,
    pageSize: 20,
  });
  // paginationModel type is not aligned with the one used in the data grid component.
  const actualPaginationModel = useMemo(() => {
    return {
      page: paginationModel.page ?? 0,
      pageSize: paginationModel.pageSize ?? 20,
    };
  }, [paginationModel]);

  const [sortOptions, setSortOptions] = useState<ListUtils.SortingProperties>({
    property: "sessionStartDate",
    direction: "DESC",
  });

  const history = useHistory();
  const { addToast } = useToasts();

  const locale = i18n.language;

  const {
    data: { items: reviews = [], total: reviewsCount = 0 } = {},
    loading,
    refetch,
  } = useTypedFetch<ReviewsRoutes.GetCompanyHotReviews>({
    method: "POST",
    path: `/reviews/hot`,
    payload: {
      filters: filterValues,
      sorting: sortOptions,
      pagination: paginationModel,
    },
  });

  const { rows, columns } = useReviewsTableData({ reviews, t, locale, refetch, selectedRows });

  useEffect(() => {
    setCurrentReviewUuid(currentUrlId);
  }, [currentUrlId]);

  const handleOnClickRow = useCallback(
    ({ row }: { row: ReviewRow<HotReview> }) => {
      const existInSelection = selectedRows.includes(row.id);
      if (existInSelection) {
        return;
      }

      history.push({
        pathname: `/responsable/evaluations/hot/${row.id}`,
        search: new URLSearchParams(window.location.search).toString(),
      });
    },
    [history, selectedRows]
  );

  const handleCloseSidePanel = useCallback(() => {
    history.push({
      pathname: `/responsable/evaluations/hot`,
      search: new URLSearchParams(window.location.search).toString(),
    });

    setCurrentReviewUuid(undefined);
  }, [history]);

  const handleTableColumnFilterClick = (col: GridColDef) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  };

  const handleTableColumnSortClick = (property: string, direction?: "ASC" | "DESC") => {
    if (!direction) {
      setSortOptions({
        property: "sessionStartDate",
        direction: "DESC",
      });
    } else {
      setSortOptions({ property, direction });
    }
  };

  const handleMultipleReviewAction = async () => {
    const selectedReviews = reviews.filter((review) => selectedRows.includes(review.uuid));

    const selectedReviewsUuids = selectedReviews.map((review) => review.uuid);

    await recallTrainees(
      {
        reviewsUuids: selectedReviewsUuids,
        setLoadingSendMails,
        setShowAlert,
        refetch,
      },
      addToast,
      t
    );
  };

  return (
    <Flex column className={styles.container}>
      {showAlert && (
        <DSAlert
          type={DSAlertType.WARNING}
          display={DSAlertDisplay.FULL_BLEED}
          onClose={() => setShowAlert(false)}
          closeButton
        >
          Les mails sont en cours d’envoi, cela peut prendre quelques minutes. Les statuts seront
          mis à jour une fois l’opération effectuée
        </DSAlert>
      )}
      <div className={styles.content}>
        <div className={styles.reviewList}>
          <DSFilters
            ref={filterRef}
            t={t}
            config={Config.Reviews.Filters}
            filters={filters}
            onChange={setFilterValues}
          />
          <DSDataGrid
            toolbarButtons={[
              <ToolbarButton
                selectedRows={selectedRows}
                reviews={reviews}
                handleMultipleReviewAction={handleMultipleReviewAction}
              />,
            ]}
            rows={rows || []}
            initialState={initialState}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectedRows(newRowSelectionModel);
            }}
            disableRowSelectionOnClick
            onRowClick={handleOnClickRow}
            loading={loading || loadingSendMails}
            onFilter={handleTableColumnFilterClick}
            onSort={handleTableColumnSortClick}
            sorting={sortOptions}
            onPageChange={(page, pageSize) => setPaginationModel({ page, pageSize })}
            paginationModel={actualPaginationModel}
            rowCount={reviewsCount}
            translationPrefix="supervisor.reviews"
          />
        </div>
        {currentReviewUuid && (
          <HotReviewSidePanel
            uuid={currentReviewUuid}
            onClose={handleCloseSidePanel}
            refetchRow={refetch}
          />
        )}
      </div>
    </Flex>
  );
};

export default HotReviewListView;
