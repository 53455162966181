import React from "react";

import Acta from "utils/Acta";
import InteractiveButton from "components/InteractiveButton";
import Colors from "uiAssets/Colors";

import styles from "./DeleteListModalStyles.module.scss";

export interface IProps {
  confirmDelete: () => void;
  title: string;
  trainingCount: number;
}

const deleteListModal: React.FC<IProps> = ({
  confirmDelete,
  title,
  trainingCount,
}): JSX.Element => (
  <div className={styles.deleteListModal}>
    <p>
      Êtes-vous sûr(e) de vouloir retirer la liste "{title}" contenant {trainingCount} formations ?
    </p>
    <InteractiveButton
      label="Annuler"
      background="#fff"
      color={Colors.blue}
      onClick={() => Acta.dispatchEvent("closeModal")}
    />
    <InteractiveButton
      label="Supprimer"
      onClick={() => {
        confirmDelete();
        Acta.dispatchEvent("closeModal");
      }}
    />
  </div>
);

export default deleteListModal;
