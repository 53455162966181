import React, { useState } from "react";

import Acta from "utils/Acta";
import InteractiveButton from "components/InteractiveButton";

export default ({ rows, isBypass, onMoveToPlan }) => {
  const isPlural = rows.length > 1;
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    try {
      await onMoveToPlan(rows);
      Acta.dispatchEvent("closeModal");
    } catch {
      setLoading(false);
    }
  };

  return (
    <div style={{ lineHeight: "1.6em", padding: "20px" }}>
      <p style={{ marginBottom: "1rem" }}>
        {isBypass && isPlural && (
          <>
            Attention, ces demandes n’ont pas encore été validées par le(s) manager(s).
            <br />
            <br />
          </>
        )}
        {isBypass && !isPlural && (
          <>
            Attention, cette demande n‘a pas encore été validée par le(s) manager(s).
            <br />
            <br />
          </>
        )}
        {isPlural &&
          `Êtes vous sûr(e) de vouloir valider ces ${rows.length} demandes et de les passer au
          plan (les collaborateurs ne seront pas notifiés de ces actions) ?`}
        {!isPlural &&
          `Êtes vous sûr(e) de vouloir valider cette demande et de la passer au
          plan (le collaborateur ne sera pas notifié de cette action) ?`}
      </p>
      <InteractiveButton label="Valider" loading={loading} onClick={onSubmit} />
    </div>
  );
};
