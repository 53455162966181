import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import moment, { Moment } from "moment";
import cx from "classnames";

import styles from "./DatePicker.module.scss";
import { UnmanagedBookingContext } from "../../../context/UnmanagedBookingContext";

interface Props {
  form: UnmanagedBookingContext["form"];
  setForm: Function;
  className?: string;
}

const FORMAT = "YYYY-MM-DD";

const OPTIONS = {
  noBorder: true,
  hideKeyboardShortcutsPanel: true,
  numberOfMonths: 1,
  placeholder: "jj/mm/aaaa",
  showClearDate: true,
};

export default ({ form, setForm, className }: Props) => {
  const [isStartDateVisible, setStartDateVisibility] = useState(false);
  const [isEndDateVisible, setEndDateVisibility] = useState(false);

  function setStartDate(date: Moment) {
    if (!date) {
      setForm({
        sessionStartDate: undefined,
        sessionEndDate: undefined,
      });
      return;
    }

    const isStartDateAfterEndDate =
      form.sessionEndDate && date.isAfter(moment(form.sessionEndDate, FORMAT));

    setForm({
      sessionStartDate: date.format(FORMAT),
      ...(isStartDateAfterEndDate && { sessionEndDate: null }),
    });
  }

  const isDayBlocked = (day: Moment) =>
    form.sessionStartDate && day.isBefore(moment(form.sessionStartDate, FORMAT));

  return (
    <div className={cx(styles.calendars, className)}>
      <div className={styles.calendar}>
        <span>Date de début de formation :</span>
        <SingleDatePicker
          date={form.sessionStartDate && moment(form.sessionStartDate, FORMAT)}
          onDateChange={setStartDate}
          focused={isStartDateVisible}
          onFocusChange={({ focused }) => setStartDateVisibility(focused)}
          isOutsideRange={() => false}
          id="start-date"
          {...OPTIONS}
        />
        <div className={styles.linear} />
      </div>
      <div className={styles.calendar}>
        <span>Date de fin de formation :</span>
        <SingleDatePicker
          date={form.sessionEndDate && moment(form.sessionEndDate, FORMAT)}
          onDateChange={(date) => setForm({ sessionEndDate: date && date.format(FORMAT) })}
          focused={isEndDateVisible}
          openDirection="up"
          isDayBlocked={isDayBlocked}
          isOutsideRange={() => false}
          onFocusChange={({ focused }) => setEndDateVisibility(focused)}
          id="end-date"
          {...OPTIONS}
          disabled={!form.sessionStartDate}
          placeholder={
            !form.sessionStartDate ? "Date de début de formation requise" : OPTIONS.placeholder
          }
        />
        <div className={styles.linear} />
      </div>
    </div>
  );
};
