import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "@skillup/ui";
import { MdClose } from "react-icons/md";

import User from "utils/User";
import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

import { useFetch, useTypedFetch } from "hooks";

import styles from "./SidePanelConfiguration.module.scss";
import type { FieldRoutesType } from "types/api";

interface ISettings {
  schedules: {
    uuid: string;
    name: string;
    sidePanel: {
      sections: {
        title: string;
        index: number;
        uuid: string;
        fields: { binding: string; label: string; type: string; isHidden: boolean }[];
      }[];
    };
  }[];
}

// a little function to help us with reordering the result
const reorder = function <T>(list: T[], startIndex, endIndex): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const remove = function <T>(list: T[], index): T[] {
  const cleanedList = list.slice(0, index).concat(list.slice(index + 1));
  return cleanedList;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "lightblue" : "#ebecf0",
  width: 250,
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "#fff",

  // styles we need to apply on draggables
  ...draggableStyle,
});

/**
 * First, we need to select a company. For now we use
 * the one in activeCompany
 *
 * Then, we fetch the settings which contains all schedules and their
 * fields configured on sidepanel
 *
 * Then let the user select a schedule
 *
 * Then, we allow the user to drag fields in the order he sees fit.
 *
 */

interface IField {
  binding: string;
  label: string;
  type: string;
  isHidden: boolean;
}

export default function SidePanelConfiguration() {
  const { data: organizationFields } = useTypedFetch<FieldRoutesType.Get>({
    method: "GET",
    path: "/fields",
    query: { showDeletedFields: true, showHiddenFields: true },
  });

  const user = User.getUserData();
  const { data, refetch } = useFetch<ISettings>({
    method: "GET",
    url: `/v1/configuration/${user.activeCompany.uuid}?showHiddenFields=true`,
  });

  const [selectedSchedule, selectSchedule] = useState<ISettings["schedules"][0]>();
  // const [fields, setFields] = useState<ISettings['schedules'][0]['sidePanel']['fields']>([]);
  const [sections, setSections] = useState<ISettings["schedules"][0]["sidePanel"]["sections"]>([]);
  const [fieldsToPick, setFieldsToPick] = useState<
    ISettings["schedules"][0]["sidePanel"]["sections"][0]["fields"]
  >([]);
  const [schedules, setSchedules] = useState<ISettings["schedules"]>([]);

  useEffect(() => {
    if (data?.schedules) {
      setSchedules(data.schedules);
    }
  }, [data]);

  useEffect(() => {
    if (selectedSchedule?.sidePanel?.sections) {
      if (sections.length === 0) {
        // initial load
        setSections(selectedSchedule?.sidePanel?.sections);
      }

      // We check fields that have already been picked
      const bindings = sections
        .reduce((acc, section) => [...acc, ...section.fields], [])
        ?.map((f) => f.binding);

      // We filter these fields from the fields available for picking.
      setFieldsToPick(
        (organizationFields?.fields ?? []).filter((f) => !bindings.includes(f.binding))
      );
    }
  }, [selectedSchedule, organizationFields, sections]);

  const getList = (id) => {
    if (id === "availableFields") {
      // Moving in
      return fieldsToPick;
    }

    return sections.find((section) => section.uuid === id).fields;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside of any list, or in the availableFields list
    if (!destination || destination?.droppableId === "availableFields") {
      // From available fields, we do nothing
      if (source.droppableId === "availableFields") {
        return;
      }

      // From inside a section. ==> we remove the field from the section.
      const updatedSections = sections.map((section) => {
        if (section.uuid === source.droppableId) {
          return {
            ...section,
            fields: remove(section.fields, source.index),
          };
        }

        return section;
      });

      setSections(updatedSections);
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId !== "availableFields") {
        // Reorder inside the same list
        const updatedFields = reorder(getList(source.droppableId), source.index, destination.index);
        const updatedSections = sections.map((section) => {
          if (section.uuid === destination.droppableId) {
            return {
              ...section,
              fields: updatedFields,
            };
          }
          return section;
        });
        setSections(updatedSections);
      }
    } else {
      // Dropped from one list to another
      const updatedData = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      const updatedSource = updatedData[source.droppableId] as IField[];
      const updatedDestination = updatedData[destination.droppableId] as IField[];
      const updatedSections = sections.map((section) => {
        if (section.uuid === destination.droppableId) {
          return {
            ...section,
            fields: updatedDestination,
          };
        }
        if (section.uuid === source.droppableId && source.droppableId !== "availableFields") {
          return {
            ...section,
            fields: updatedSource,
          };
        }
        return section;
      });
      setSections(updatedSections);
    }
  };

  const saveConfiguration = async () => {
    try {
      await DataLayer.request({
        method: "POST",
        url: `/v1/configuration/sidepanel/${selectedSchedule.uuid}`,
        body: JSON.stringify({
          sections: sections.map((section) => ({
            uuid: section.uuid,
            title: section.title,
            fields: section.fields.map((f) => f.binding),
          })),
        }),
      });
      Acta.dispatchEvent("sendAppMessage", {
        message: "Modifications enregistrées avec succès",
        type: "success",
      });

      await refetch();
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Echec des modifications.",
        type: "error",
      });
    }
  };

  const addSection = async () => {
    try {
      const newSection = (await DataLayer.request({
        method: "POST",
        url: `/v1/configuration/sidepanel/${selectedSchedule.uuid}/section`,
        body: JSON.stringify({
          index: sections.length,
        }),
      })) as ISettings["schedules"][0]["sidePanel"]["sections"][0];
      setSections([...sections, newSection]);
      Acta.dispatchEvent("sendAppMessage", {
        message: "Section ajoutée avec succès",
        type: "success",
      });
    } catch (err) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Echec de l'ajout de section.",
        type: "error",
      });
    }
  };

  const setSectionTitle = (sectionId: string) => (newTitle: string) => {
    setSections(
      sections.map((section) => {
        if (section.uuid === sectionId) {
          return {
            ...section,
            title: newTitle,
          };
        }

        return section;
      })
    );
  };

  const removeSection = (sectionId: string) => {
    setSections(sections.filter((section) => section.uuid !== sectionId));
  };

  const displayType = (type: string) =>
    ({
      text: "Texte",
      textarea: "TextArea",
      date: "Date",
      monetary: "Prix",
      select: "Select",
      number: "Nombre",
    }[type] || "");

  const onSelectSchedule = (schedule) => {
    selectSchedule(schedule);
    setSections(schedule?.sidePanel?.sections);
  };

  return (
    <div className={styles.sidePanelConfiguration}>
      <div className={styles.schedules}>
        {schedules.map((schedule) => (
          <Button
            label={schedule.name}
            onClick={() => onSelectSchedule(schedule)}
            kind={selectedSchedule?.uuid === schedule.uuid ? "primary" : "secondary"}
          />
        ))}
      </div>
      <div className={styles.configuration}>
        {!selectedSchedule && (
          <>
            <h3>Veuillez sélectionner un plan à configurer</h3>
          </>
        )}
        {selectedSchedule && fieldsToPick && (
          <>
            <Button label="Enregistrer" onClick={saveConfiguration} />
            <Button label="Ajouter une section" onClick={addSection} />
            <DragDropContext onDragEnd={onDragEnd}>
              {/* <SortableList
            onSortEnd={onSortEnd}
            fields={fields}
          /> */}
              <div className={styles.dragDrop}>
                {/* Champs utilisables */}
                <Droppable droppableId="availableFields">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      className={styles.section}
                    >
                      <h3>Champs disponibles</h3>
                      <div className={styles.list}>
                        {fieldsToPick.map((field, index) => (
                          <Draggable key={field.binding} draggableId={field.binding} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                className={styles.field}
                              >
                                <div>
                                  {displayType(field.type)}
                                  {field.isHidden ? "- Champ Caché" : ""}
                                </div>
                                <div>{field.label}</div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                <div className={styles.sections}>
                  {sections.map((section) => (
                    <Droppable key={section.uuid} droppableId={section.uuid}>
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          className={styles.section}
                        >
                          <div
                            className={styles.remove}
                            onClick={() => removeSection(section.uuid)}
                          >
                            <MdClose size={20} />
                          </div>
                          <h3>
                            <input
                              value={section.title}
                              onChange={(e) => setSectionTitle(section.uuid)(e.currentTarget.value)}
                            ></input>
                          </h3>
                          <div className={styles.list}>
                            {section.fields.map((field, index) => (
                              <Draggable
                                key={field.binding}
                                draggableId={field.binding}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className={styles.field}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <div>
                                      {displayType(field.type)}
                                      {field.isHidden ? "- Champ Caché" : ""}
                                    </div>
                                    <div>{field.label}</div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ))}
                  {/* Champs utilisés dans le panneau */}
                </div>
              </div>
            </DragDropContext>
          </>
        )}
      </div>
    </div>
  );
}
