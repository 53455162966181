import { DSModal } from "@skillup/ui";

export default function DownloadModal({ onClose, onConfirm }) {
  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Télécharger l’entretien" />
      </DSModal.Header>

      <DSModal.Content>
        <p>Le PDF de l’entretien peut être vide ou incomplet</p>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={onClose} label="Annuler" />
        <DSModal.Footer.PrimaryButton onClick={onConfirm} label="Confirmer" />
      </DSModal.Footer>
    </DSModal>
  );
}
