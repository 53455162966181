import React from "react";
import { InterviewSectionPage } from "@skillup/types";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { TextDropdown } from "@skillup/ui";

import { templateState } from "../store";

import styles from "./TransferToAnotherSection.module.scss";

interface PageTransferProps {
  sectionUuid: string;
  page: InterviewSectionPage;
}

const TransferToAnotherSection = ({ sectionUuid, page }: PageTransferProps) => {
  const template = useRecoilValue(templateState);
  const setTemplate = useSetRecoilState(templateState);

  const transfer = (targetSectionUuid: string) => {
    const newTemplate = {
      ...template,
      sections: template.sections.map((section) => {
        if (section.uuid === sectionUuid) {
          return {
            ...section,
            pages: section.pages.filter((p) => p.uuid !== page.uuid),
          };
        }

        if (section.uuid === targetSectionUuid) {
          // I put it on top to see it directly when changing page
          return {
            ...section,
            pages: [...section.pages, page],
          };
        }

        return section;
      }),
    };

    setTemplate(newTemplate);
  };

  const sections = template.sections
    .filter((s) => s.uuid !== sectionUuid)
    .map((s) => ({
      label: s.title,
      value: s.uuid,
    }));

  return (
    <div className={styles.pageTransfer} aria-label="transférer la page">
      <TextDropdown
        options={sections}
        small
        placeholder="Transférer la page vers"
        onSelect={(selection) => {
          transfer(selection.value as string);
        }}
      />
    </div>
  );
};

export default TransferToAnotherSection;
