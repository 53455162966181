import styled from "styled-components";

import { Flex } from "@skillup/design-system";
import { DSColor, SkuiSpacing } from "@skillup/ui";

export const HeaderSection = styled(Flex)`
  border-bottom: 0.5rem solid ${DSColor["decoration-blue-base"]};
`;

export const Container = styled(Flex)`
  flex-direction: column;
  margin-top: ${SkuiSpacing.l};
  align-items: center;
  border: 1px solid ${DSColor["border-onLight"]};
  border-radius: ${SkuiSpacing.s};
  background-color: ${DSColor["surface-light"]};
  overflow: hidden;
  padding-top: ${SkuiSpacing.s};
`;

export const PieContainer = styled(Container)`
  width: 35%;
`;

export const KPIContainer = styled(Flex)`
  margin: ${SkuiSpacing.m} 0;
  flex-flow: row wrap;
  gap: ${SkuiSpacing.s};
  justify-content: space-between;
`;
