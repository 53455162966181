import qs from "qs";
import { IScheduleDataCall } from "@skillup/types";

import DataLayer from "utils/DataLayer";
import { keyBy, pick } from "lodash";
import { buildRequest } from "utils/buildRequest";
import { ScheduleRoutes } from "@skillup/espace-rh-api/src/routes/schedule";
import { Filters, SortTarget } from "components/DataTable/types";
import { ScheduleTab } from "@skillup/entities-provider";

export type ReturnedColumn = ScheduleRoutes.GetRowsForSchedule["response"]["returnedColumns"][0];

interface Options {
  keepSelection?: boolean;
  keepSortedRows?: boolean;
  initialRowCount?: number;
}

interface Params {
  tab: ScheduleTab | "dashboard";
  scheduleUuid: string;
  type: "plan-de-formation" | "recueil-des-besoins";
  sort?: any;
  filters?: Filters;
  areas: { activeAreas: string[]; hasAllAreasActive: boolean };
}

export default async function (
  { tab, type, sort, filters, scheduleUuid, areas }: Params,
  { initialRowCount = 50 }: Options = {}
) {
  if (tab && tab !== "dashboard") {
    const view = type === "plan-de-formation" ? "plan" : "collection";

    const filtersPayload: Filters = Object.keys(filters ?? {}).length ? filters : undefined;
    const sortPayload: SortTarget = sort
      ? {
          ...sort,
          target: pick(sort.target, ["key", "type"]),
        }
      : undefined;

    const query = {
      type: view,
      tab: (tab ?? "collection-pending") as ScheduleTab,
      filters: filtersPayload ? JSON.stringify(filtersPayload) : undefined,
      sort: sortPayload ? JSON.stringify(sortPayload) : undefined,
      areas: areas.activeAreas?.join(","),
    } as const;

    const rowList = await buildRequest<ScheduleRoutes.GetRowsForSchedule>({
      method: "GET",
      path: "/schedule/rows/{scheduleUuid?}",
      params: { scheduleUuid },
      query: query,
    })();

    const { returnedColumns } = rowList;
    const rowUuids = rowList.rows;
    const rowUuidsChunk = rowUuids.slice(0, initialRowCount);

    const { activeAreas, hasAllAreasActive } = areas;
    let queryString = hasAllAreasActive
      ? ""
      : activeAreas?.length
      ? "&" + qs.stringify({ areas: activeAreas.join(",") })
      : "&" + qs.stringify({ areas: "" });
    queryString += "&rows=" + rowUuidsChunk.join(",");

    const schedule = (await DataLayer.request({
      url: scheduleUuid
        ? `/v1/schedule/${scheduleUuid}?type=${view}&tab=${
            /* TEMPORARY BEFORE WE HAVE BOTH DASHBOARDS */ tab || "collection-pending"
          }${queryString}`
        : `/v1/schedule?type=${view}&tab=${
            /* TEMPORARY BEFORE WE HAVE BOTH DASHBOARDS */ tab || "collection-pending"
          }${queryString}`,
    })) as IScheduleDataCall;

    const rowsByUuid = keyBy(schedule.rows, "uuid");
    const requestedUuids = keyBy(rowUuidsChunk);

    const rows = rowUuids
      .filter((uuid) => !requestedUuids[uuid] || rowsByUuid[uuid]) // remove rows that were requested but not returned
      .map((uuid) => {
        if (rowsByUuid[uuid]) return rowsByUuid[uuid];
        return {
          uuid,
          columns: {},
          lazyLoading: true,
        };
      });
    const columns = schedule.view.columns;

    return {
      rows,
      columns,
      returnedColumns,
    };
  }

  return undefined;
}
