import { useToggle } from "react-use";
import Colors from "uiAssets/Colors";

import { ScheduleRowRoutes } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";
import Acta from "utils/Acta";

import InteractiveButton from "components/InteractiveButton";

import styles from "./RevertToApprovedModalStyles.module.scss";

export interface Props {
  rows: Array<string>;
  reload: () => void;
  activeTab: string;
  users: Array<{ noEmail: boolean; fullName: string }>;
}

export default ({ rows, reload, activeTab, users }: Props) => {
  const [loading, toggleLoading] = useToggle(false);

  const handleSubmit = async (): Promise<void> => {
    toggleLoading(true);

    try {
      await buildRequest<ScheduleRowRoutes.RevertToApprovedHr>({
        path: "/scheduleRow/revert-to-approved-hr",
        method: "POST",
        payload: { rows },
      })();

      Acta.dispatchEvent("sendAppMessage", {
        message:
          rows.length === 1 ? "Ligne renvoyée avec succès." : "Lignes renvoyées avec succès.",
        type: "success",
      });

      Acta.dispatchEvent("closeModal");

      reload();
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "La demande a échoué.",
        type: "error",
      });
    } finally {
      toggleLoading(false);
    }
  };

  const text: Array<string> =
    rows.length > 1
      ? [
          `${rows.length} stagiaires ont reçu un mail contenant un lien les invitant à choisir une session.`,
          `Ce lien ne sera plus valide dès le renvoi de ces lignes au statut « À inscrire ».`,
        ]
      : [
          `${users[0].fullName} a reçu un mail contenant un lien l’invitant à choisir une session.`,
          `Ce lien ne sera plus valide dès le renvoi de cette ligne au statut « À inscrire ».`,
        ];

  const canceledText: Array<string> =
    rows.length > 1
      ? [
          `${rows.length} stagiaires n’ont pas été notifiés de l’annulation.`,
          `Pensez à les informer une fois ces lignes renvoyées au statut « À inscrire ».`,
        ]
      : [
          `${users[0].fullName} n’a pas été notifié de l’annulation.`,
          `Pensez à l’informer une fois cette ligne renvoyée au statut « À inscrire ».`,
        ];

  return (
    <div className={styles.RevertToApprovedHrModal}>
      <>
        {activeTab === "plan-denied"
          ? canceledText.map((para, index) => <p key={index}>{para}</p>)
          : text.map((para, index) => <p key={index}>{para}</p>)}
      </>
      <div className={styles.buttonsContainer}>
        <InteractiveButton
          label="Annuler"
          onClick={() => {
            Acta.dispatchEvent("closeModal");
          }}
          background="#fff"
          color={Colors.blue}
          style={{ fontWeight: 500 }}
          size="small"
          loading={loading}
        />
        <InteractiveButton
          label="Confirmer le renvoi"
          onClick={handleSubmit}
          background={Colors.gradientBlue}
          style={{ fontWeight: 500 }}
          size="small"
          loading={loading}
        />
      </div>
    </div>
  );
};
