import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRef, useMemo, Suspense, useState, useEffect, useCallback } from "react";

import {
  useGridApiRef,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

import { ListUtils } from "@skillup/shared-utils";
import { Text, Flex, Loader } from "@skillup/design-system";
import { useModal, DSButton, FilterRef, DSFilters, DSDataGrid, useMediaQueries } from "@skillup/ui";

import { computeFilteredRows } from "helpers/functions";
import { DatatableJob, AssignCollabPayload } from "types/skills";
import EmptyView from "containers/Supervisor/components/Empty/Empty";
import NotAvailableInMobileView from "components/NotAvailableInMobileView";

import useJobsTableData from "./useJobsTableData";
import { ToolbarButton } from "./components/ToolbarButton";
import { useJobsGridFilters } from "./utils/useJobsGridFilters";
import { JobsProvider, useJobsContext } from "../../JobsContext";
import { JobRow, SelectedJobRowData } from "./utils/parseJobIntoRow";
import { ArchiveModal, ArchiveJobsModal } from "../../components/Modals";

const Jobs = () => {
  return (
    <JobsProvider>
      <Flex height="100%" flexDirection="column">
        <Suspense fallback={<Loader fillSpace />}>
          <Layout />
        </Suspense>
      </Flex>
    </JobsProvider>
  );
};

const Layout = () => {
  const { isMobile } = useMediaQueries();
  const { t } = useTranslation();
  const history = useHistory();
  const apiRef = useGridApiRef();
  const pathname = history.location.pathname;
  const [selectedJob, setSelectedJob] = useState<SelectedJobRowData>();
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const {
    archiveJob,
    jobList,
    jobListError,
    jobListLoading,
    jobListStatus,
    reAssignEmployeeToJobAndArchiveJob,
    setJobPrivacy,
  } = useJobsContext();
  const archiveJobModal = useModal();
  const {
    hide: hideArchiveListModal,
    isOpen: isArchiveListModalOpen,
    show: showArchiveListModal,
  } = useModal();

  const [conf, filters, filterValues, setFilterValues, setFilterConfig] = useJobsGridFilters(t);
  const filterRef = useRef<FilterRef>();

  const jobs = useMemo(() => {
    if (jobList && jobList.length > 0) {
      jobList[0].fields.map((customField) => {
        setFilterConfig(({ conf, initialValues }) => {
          return {
            conf: {
              ...conf,
              [customField.name]: {
                type: ListUtils.FilterType.TEXT,
                label: customField.name,
              },
            },
            initialValues: {
              ...initialValues,
              [customField.name]: {
                label: customField.name,
                placeholder: `Filtrer par ${customField.name}`,
              },
            },
          };
        });
      });
    }

    return jobList?.map((e) => ({
      ...e,
      createdAt: new Date(e.createdAt),
      updatedAt: new Date(e.updatedAt),
    })) as undefined | Array<DatatableJob>;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobList]);

  const addActionToArchive = (row: JobRow) => {
    setSelectedJob(row);
    if (row.employees.length > 0) {
      archiveJobModal.show();
    } else {
      handleSetArchive([row.uuid], true);
    }
  };

  const addActionToSetPrivacy = (row: JobRow) => {
    handleSetJobPrivacy(row.uuid, !row.isPrivate);
  };

  const { columns, rows } = useJobsTableData({
    actions: { archiveRow: addActionToArchive, setRowPrivacy: addActionToSetPrivacy },
    jobs,
    t,
  });

  const jobsToDisplay = useMemo(() => {
    return computeFilteredRows(rows, filterValues);
  }, [rows, filterValues]);

  const archivedJobs = jobs?.filter((job) => job.isArchived);

  /* This useEffect is here to solve the problem
  that when we dynamically add a column, it is pinned instead of action column */
  const updateColumns = useCallback(() => {
    if (apiRef.current?.updateColumns) {
      apiRef.current.updateColumns(columns);
    }

    const hasPrivateJobRows = rows.some((row) => row.isPrivate);
    if (apiRef.current?.setColumnVisibility) {
      if (!hasPrivateJobRows) {
        apiRef.current?.setColumnVisibility("isPrivate", false);
      } else {
        apiRef.current?.setColumnVisibility("isPrivate", true);
      }
    }
  }, [apiRef, columns, rows]);

  useEffect(updateColumns, [updateColumns]);

  const jobHasEmployee = useMemo(() => {
    const uuids = Array.from(selectedRows).map(String);

    return rows.some((job) => uuids.includes(job.uuid) && job.employees.length > 0);
  }, [selectedRows, rows]);

  const reassignJobEmployeesAndArchive = (assignPayload: AssignCollabPayload) => {
    const job = rows.find((job) => selectedJob.uuid === job.uuid);
    const jobWithVersion = { uuid: job.uuid, version: job.version + 1 };

    reAssignEmployeeToJobAndArchiveJob(
      jobWithVersion,
      assignPayload.employees,
      assignPayload.targetJob
    );
    archiveJobModal.hide();
  };

  const initialState = {
    pagination: {
      paginationModel: {
        page: 0,
        pageSize: 20,
      },
    },
    pinnedColumns: {
      left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
      right: ["actions"],
    },
  };

  const handleSetArchive = useCallback(
    (jobsUuid: Array<string>, isArchive: boolean) => {
      const jobsWithVersion = rows
        .filter((job) => jobsUuid.includes(job.uuid))
        .map((job) => {
          return {
            uuid: job.uuid,
            version: job.version + 1,
          };
        });

      isArchive && archiveJobModal.hide();
      archiveJob(jobsWithVersion, isArchive);
    },
    [rows, archiveJobModal, archiveJob]
  );

  const handleSetJobPrivacy = useCallback(
    (jobUuid: string, isPrivate: boolean) => {
      const job = rows.find((job) => job.uuid === jobUuid);

      setJobPrivacy({ uuid: job.uuid, version: job.version + 1 }, isPrivate);
    },
    [rows, setJobPrivacy]
  );

  const archiveMultipleJobs = useCallback(() => {
    const uuids = Array.from(selectedRows).map(String);

    handleSetArchive(uuids, true);
  }, [selectedRows, handleSetArchive]);

  const toolbarActions = useMemo(
    () => [
      selectedRows.length > 1 && (
        <ToolbarButton
          key="archiveMultipleJobs"
          selectedRows={selectedRows}
          archiveMultipleJob={archiveMultipleJobs}
          disabledMultipleArchived={jobHasEmployee}
        />
      ),
    ],
    [archiveMultipleJobs, jobHasEmployee, selectedRows]
  );

  if (isMobile) return <NotAvailableInMobileView />;

  if (jobListStatus === "loading") return <Loader fillSpace />;

  if (jobListStatus === "error") return <Text>Error loading jobs: {jobListError.message}</Text>;

  if (jobs.length === 0) {
    return (
      <EmptyView
        withStyle
        buttonClick={() => history.push(`${pathname}/new`)}
        buttonLabel={t("skills.jobList.label.createJob", {
          defaultValue: "Créer une fiche de poste",
        })}
        message={t("skills.jobList.label.noJobCreated", {
          defaultValue: "Vous n’avez pas encore créé de fiche de poste.",
        })}
      />
    );
  }

  return (
    <Flex paddingTop="s" paddingHorizontal="s" flexDirection="column">
      <DSFilters t={t} config={conf} ref={filterRef} filters={filters} onChange={setFilterValues} />
      <DSDataGrid
        editable
        pagination
        apiRef={apiRef}
        checkboxSelection
        columns={columns}
        persistenceId={"jobs"}
        loading={jobListLoading}
        rows={jobsToDisplay || []}
        disableRowSelectionOnClick
        initialState={initialState}
        rowCount={jobsToDisplay.length}
        toolbarButtons={toolbarActions}
        onRowClick={(row) => history.push(`${pathname}/${row.id}`)}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedRows(newRowSelectionModel);
        }}
        emptyOverlay={{
          text: t("skills.list.job.emptyOverlayText", {
            defaultValue: "Aucune fiche de poste ne correspond à votre recherche.",
          }),
        }}
        entityName={
          jobsToDisplay.length > 1
            ? `${jobsToDisplay.length} ${t("skills.list.job.entityName", {
                defaultValue: "fiches de poste",
              })}`
            : `1 ${t("skills.list.job.entityName", {
                defaultValue: "fiche de poste",
              })}`
        }
        errorOverlay={{
          text: [
            t("skills.list.job.errorOverlayText.firstSentence", {
              defaultValue: `Une erreur est survenue lors du chargement des fiches de poste.`,
            }),
            t("skills.list.collaborator.errorOverlayText.secondSentence", {
              defaultValue: `Veuillez réessayer ultérieurement.`,
            }),
            t("skills.list.collaborator.errorOverlayText.thirdSentence", {
              defaultValue: `Si l’erreur persiste, contactez votre interlocuteur Skillup.`,
            }),
          ],
        }}
      />

      {archivedJobs?.length > 0 && (
        <Flex marginTop="m" marginBottom="s">
          <DSButton
            emphasis="Low"
            buttonSize="S"
            onClick={() => showArchiveListModal()}
            label={
              archivedJobs?.length > 1
                ? t("jobs.button.list.seeArchivedJobs", {
                    count: archivedJobs?.length,
                    defaultValue: "Voir les {{count}} fiches de poste archivées",
                  })
                : t("jobs.button.list.seeArchivedJob", {
                    defaultValue: "Voir la fiche de poste archivée",
                  })
            }
          />
        </Flex>
      )}

      <ArchiveModal
        archivedJobs={archivedJobs}
        close={hideArchiveListModal}
        open={isArchiveListModalOpen}
        handleSetArchive={handleSetArchive}
      ></ArchiveModal>

      <ArchiveJobsModal
        datatableRows={rows}
        selectedJob={selectedJob}
        close={archiveJobModal.hide}
        open={archiveJobModal.isOpen}
        reassignAndArchive={reassignJobEmployeesAndArchive}
      />
    </Flex>
  );
};

export default Jobs;
