const defaultColumnWidth = 200;
const minColumnWidth = 100;
const rowHeight = 45;
const headerRowHeight = 43;
const defaultDisplayableRowCount = 60;
const dragAndScrollTriggerAreaWidth = 50;
const allFilter = { label: "Tout sélectionner", mode: "all" };
const emptyFilter = { label: "(Vide)", mode: "empty" };
const noneFilter = { label: "(Aucun)", mode: "none" };

export {
  defaultColumnWidth,
  defaultDisplayableRowCount,
  minColumnWidth,
  rowHeight,
  headerRowHeight,
  dragAndScrollTriggerAreaWidth,
  allFilter,
  emptyFilter,
  noneFilter,
};

export default {
  defaultColumnWidth,
  defaultDisplayableRowCount,
  minColumnWidth,
  rowHeight,
  headerRowHeight,
  dragAndScrollTriggerAreaWidth,
  allFilter,
  emptyFilter,
  noneFilter,
};
