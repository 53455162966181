import { SkillAnalytic } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import { DatatableSkillFields } from "../../getSkillsFields";
import generateSkillsTableRows from "../../generateSkillsTableRows";
import { createXlsx } from "./xlsx";

export const exportXlsxSkills = (data: Array<SkillAnalytic>, t: TranslationType) => {
  const { headers, rows } = generateXlsxSkills(data, t);

  return createXlsx(rows, headers);
};

const generateXlsxSkills = (data: Array<SkillAnalytic>, t: TranslationType) => {
  const headers = Object.keys(DatatableSkillFields).reduce(
    (acc, key) => ({
      ...acc,
      [key]: t(DatatableSkillFields[key].traductionKey, {
        defaultValue: DatatableSkillFields[key].traductionDefaultValue,
      }),
    }),
    {}
  );

  const rows = generateSkillsTableRows(data).map((row) =>
    Object.keys(headers).reduce((acc, key) => {
      let value: unknown = row[key];

      if (key === "averageEmployeesExpectedLevelDifference") {
        value = row[key] ? `${row[key].percentage}% (${row[key].values})` : "";
      }

      return { ...acc, [key]: value };
    }, {})
  );

  return { headers, rows };
};
