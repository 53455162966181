import React from "react";

import { TextArea, TextInput, Toggle } from "@skillup/ui";

// Sub components
import DisallowBlock from "./DisallowBlock";

import { Child } from "../types";

type TextQuestion = Child & { kind: "question"; type: "text" };
const TextQuestionBlock = ({
  data,
  updateChild,
}: {
  data: TextQuestion;
  updateChild: (newChildData: Child) => void;
}) => {
  return (
    <form>
      <label>Label</label>
      <TextInput
        key={`label-${data.uuid}`}
        aria-label="question-label"
        value={data.label}
        onChange={(label) => updateChild({ ...data, label })}
      />
      <label>Description</label>
      <TextArea
        key={`description-${data.uuid}`}
        aria-label="question-description"
        value={data.description}
        onChange={(description) => updateChild({ ...data, description })}
      />
      <label>Placeholder</label>
      <TextInput
        key={`placeholder-${data.uuid}`}
        aria-label="question-placeholder"
        value={data.replyPlaceholder}
        onChange={(replyPlaceholder) => updateChild({ ...data, replyPlaceholder })}
      />
      <label>Question requise ?</label>
      <Toggle
        aria-label="question-requise"
        active={data.required}
        onToggle={() => updateChild({ ...data, required: !data.required })}
      />
      <DisallowBlock data={data} updateChild={updateChild} />
    </form>
  );
};

export default TextQuestionBlock;
