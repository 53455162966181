import { useMemo, useCallback } from "react";

import { saveAs } from "file-saver";

import { DSDropdownItem } from "@skillup/ui";

import { trpc } from "utils/trpc";
import history from "utils/history";
import DSLayout from "components/DSLayout";
import useTranslation from "hooks/useTranslation";
import { JobAnalytic, SkillAnalytic } from "types/skills";

import Overview from "./pages/Overview";
import { exportXlsxJobs, exportXlsxSkills } from "./pages/Overview/utils/export/xlsx";

const SkillsDashboard = () => {
  const { t } = useTranslation();

  const analytics = trpc.analytics.getAnalytics.useQuery(undefined, { enabled: false });

  const exportXlsx = useCallback(async () => {
    const { data } = await analytics.refetch();

    switch (history.location.pathname) {
      case "/responsable/analyses/competences": {
        saveAs(
          exportXlsxSkills(data.skillAnalytics as Array<SkillAnalytic>, t),
          t("skills.dashboard.overview.layout.analytics-xlsx-export.skills.filename", {
            defaultValue: "analyse-évaluation-compétences.xlsx",
          })
        );
        break;
      }
      case "/responsable/analyses/fiches-de-poste": {
        saveAs(
          exportXlsxJobs(data.jobAnalytics as Array<JobAnalytic>, t),
          t("skills.dashboard.overview.layout.analytics-xlsx-export.jobs.filename", {
            defaultValue: "analyse-évaluation-fiche-de-poste.xlsx",
          })
        );
        break;
      }
    }
  }, [analytics, t]);

  const layouts = useMemo(
    () => [
      {
        dropdownContent: [
          <DSDropdownItem
            key="analytics-xlsx-export"
            onClick={exportXlsx}
            label={t("skills.dashboard.overview.layout.analytics-xlsx-export.button", {
              defaultValue: "Exporter la vue",
            })}
          />,
        ],
      },
    ],
    [exportXlsx, t]
  );

  return (
    <DSLayout
      layouts={layouts}
      title={t("skills.dashboard.overview.layout.name", {
        defaultValue: "Analyse des évaluations",
      })}
    >
      <Overview />
    </DSLayout>
  );
};

export default SkillsDashboard;
