import { ActionRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type EditActionPayload = ActionRoutes.EditActionRoute["payload"];

export type EditActionParams = {
  payload: EditActionPayload;
  actionID: ActionRoutes.EditActionRoute["params"]["actionID"];
};

export async function editAction({ actionID, payload }: EditActionParams) {
  const result = await buildRequest<ActionRoutes.EditActionRoute>({
    method: "POST",
    params: {
      actionID,
    },
    path: `/actions/{actionID}/edit`,
    payload,
    target: "PEOPLE_REVIEW",
  })();

  return result;
}
