import React, { ReactNode } from "react";
import cx from "classnames";

import styles from "./Button.module.scss";

interface IProps {
  className?: string;
  type?: "button" | "submit" | "reset";
  children: ReactNode;
  disabled?: boolean;
  id?: string;
  onClick?: () => any | Promise<any>;
}

const Button = ({ className, type, children, disabled, onClick, id }: IProps): JSX.Element => (
  <button
    className={cx(styles.Button, className)}
    type={type}
    disabled={disabled}
    onClick={onClick}
    id={id}
  >
    {children}
  </button>
);

export default Button;
