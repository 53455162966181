import { Fragment } from "react";
import { isUndefined } from "lodash";

import Loader from "components/Loader";
import DateUtils from "utils/dates";

import { Campaign } from "./modals/useCampaignModals";
import styles from "./CampaignDetailsStyles.module.scss";

interface IProps {
  readonly isLoading: boolean;
  readonly campaign?: Campaign;
}

const Meta = ({ campaign }: { campaign: Campaign }): JSX.Element | null => {
  switch (campaign.type) {
    case "legacy": {
      const [startDate, endDate] = DateUtils.formatDates([campaign.startDate, campaign.endDate]);

      return (
        <Fragment>
          <div className={styles.dateBlock}>
            <label>Date de début de campagne</label>
            <span>{startDate}</span>
          </div>
          <div className={styles.dateBlock}>
            <label>Date limite de signature</label>
            <span>{endDate}</span>
          </div>
        </Fragment>
      );
    }
    case "ongoing": {
      const { duration, frequency } = campaign;

      return (
        <Fragment>
          <div className={styles.automated}>
            <div className={styles.dateBlock}>
              <label>Date de début :</label>
              <span>{frequency} jours après l'embauche</span>
            </div>
            <div className={styles.dateBlock}>
              <label>Délai de signature :</label>
              <span>{duration} jours</span>
            </div>
          </div>
        </Fragment>
      );
    }
    case "adHoc": {
      return (
        <div className={styles.dateBlock}>
          <label>Déclenchement manuel</label>
        </div>
      );
    }
    default:
      return <></>;
  }
};

const CampaignDetails = ({ isLoading, campaign }: IProps): JSX.Element => {
  if (isLoading || isUndefined(campaign)) {
    return <Loader />;
  }

  return (
    <aside className={styles.CampaignDetails}>
      <div className={styles.meta}>
        <Meta campaign={campaign} />
      </div>
    </aside>
  );
};

export default CampaignDetails;
