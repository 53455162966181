import React from "react";
import cx from "classnames";

import { headerRowHeight } from "constants/dataTable";
import { Filters, Column } from "components/DataTable/types";

import HeaderCell from "../Cell/HeaderCell";
import styles from "../../DataTableStyles.module.scss";

export interface IProps {
  columns: Column[];
  closeFilterModal: () => void;
  filters: Filters;
  filterModalColumn?: Column;
  openFilterModalForColumn: (column: Column) => void;
  sortedColumnKey: string;
  width: number;
  deactivateFilters?: boolean;
  deactivateMultiSelect?: boolean;
}

export default class BodyRow extends React.PureComponent<IProps, {}> {
  public render(): JSX.Element {
    const {
      closeFilterModal,
      columns,
      filters,
      filterModalColumn,
      openFilterModalForColumn,
      sortedColumnKey,
      width,
      deactivateFilters,
      deactivateMultiSelect,
    } = this.props;

    return (
      <div
        aria-label="Ligne header"
        className={cx(styles.headerRow, styles.row)}
        style={{
          height: `${headerRowHeight}px`,
          marginLeft: deactivateMultiSelect ? 0 : 40,
          width: `${width}px`,
        }}
      >
        {columns.map((column) => (
          <HeaderCell
            column={column as Column}
            key={`${column.key}-header-cell`}
            openFilterModal={openFilterModalForColumn}
            hasFilter={filters.hasOwnProperty(column.key) || sortedColumnKey === column.key}
            closeFilterModal={closeFilterModal}
            isBeingFiltered={filterModalColumn ? column.key === filterModalColumn.key : false}
            deactivateFilters={deactivateFilters}
          />
        ))}
      </div>
    );
  }
}
