import { HotReview } from "@skillup/training-bridge";
import { FormatDate, ParseDate } from "@skillup/shared-utils";
import { ReviewRow } from "../../../utils/types";

function parseReviewIntoRow(review: HotReview, locale: string): ReviewRow<HotReview> {
  const startDate = FormatDate.ToStringFormat(
    ParseDate.FromMiliTimestamp(review.session.startDate),
    FormatDate.getDayMonthYearFormat(locale)
  );

  return {
    id: review.uuid,
    data: {
      url: review.url,
      status: review.status,
      trainee: review.trainee.fullName,
      trainingName: review.session.training.name,
      trainingOrganization: review.session.training.trainingOrganization,
      score: review?.data?.value,
      max: review?.data?.max,
      startDate,
      sessionReference: review.session.reference,
      trainers: review.trainers,
      comment: review?.data?.comment || "-",
    },
  };
}

export default parseReviewIntoRow;