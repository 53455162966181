import React from "react";
import Loader from "components/Loader";

import styles from "./SidePanelWrapperStyles.module.scss";

interface IProps {
  readonly getContent: (items: Array<{}>) => React.ReactChild;
  readonly selectedRows: Array<{}>;
  readonly close: () => void;
  readonly items: Array<{}>;
  readonly children: React.ReactChild;
}

export default class SidePanelWrapper extends React.PureComponent<IProps, {}> {
  public render(): JSX.Element {
    const { selectedRows, getContent, items, children } = this.props;
    if (children) {
      return <div className={styles.SidePanelWrapper}>{children}</div>;
    }

    if (selectedRows.length !== 0 && items.length === 0) {
      return (
        <div className={styles.SidePanelWrapper}>
          <Loader className={styles.loader} />
        </div>
      );
    }

    if (selectedRows.length === 0 || items.length === 0) {
      return <div />;
    }

    return <div className={styles.SidePanelWrapper}>{getContent(items)}</div>;
  }
}
