import { useContext, useMemo } from "react";
import { isEmpty } from "lodash";

import { DSAlert, DSAlertType, DSAlertDisplay, DropDownCheckbox } from "@skillup/ui";
import { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";

import { useTypedFetch } from "hooks";
import LoaderShimmer from "components/LoaderShimmer";
import placeholderImg from "assets/collaborator/empty_interview.png";

import { Collaborator } from "../../api";
import { IContext, SupervisorUserViewContext } from "../../Context";
import TableView from "./partials/TableView";

import styles from "./UserInterview.module.scss";
interface Props {
  readonly user: Collaborator;
}

const TYPES = ["Autre", "Bilan à 6 ans", "Entretien annuel", "Entretien professionnel"];

export default ({ user }: Props) => {
  const {
    dispatch,
    state: {
      interview: { filter },
    },
  } = useContext<IContext>(SupervisorUserViewContext);

  const {
    data: interviews,
    loading,
    error,
  } = useTypedFetch<CollaboratorsRoutes.GetInterviews>({
    method: "GET",
    path: `/collaborators/{uuid}/interviews`,
    params: { uuid: user.uuid },
  });

  const interviewsFiltered = useMemo(
    () => interviews?.filter((interview) => !filter || filter.includes(interview.type)) || [],
    [interviews, filter]
  );

  if (loading) {
    return (
      <div className={styles.UserInterview}>
        <div className={styles.loading}>
          <LoaderShimmer className={styles.loadingLogo} />
          <p>Chargement en cours…</p>
        </div>
      </div>
    );
  }

  if (isEmpty(interviews) && !error) {
    return (
      <div className={styles.UserInterview}>
        <img className={styles.placeholderImg} src={placeholderImg} alt="bientôt disponible" />
        <p>{user.fullName} n’a encore réalisé aucun entretien.</p>
      </div>
    );
  }

  return (
    <div className={styles.UserInterview}>
      {!error && (
        <>
          <div className={styles.filter}>
            <DropDownCheckbox
              className={styles.dropdownCheckbox}
              labels={{
                itemSingular: "statut",
                itemPlural: "statuts",
                allItemsSelected: "Tous les types d’entretien",
                noItemSelected: "Aucun statut",
              }}
              items={TYPES.map((type) => ({
                label: type,
                value: type,
                isSelected: !filter || filter.includes(type),
              }))}
              onChange={(selection) => {
                dispatch({
                  type: "setInterviewFilter",
                  filter: selection.filter((state) => state.isSelected).map((state) => state.value),
                });
              }}
            />
          </div>
          <TableView interviews={interviewsFiltered} />
        </>
      )}
      {error && (
        <div className={styles.UserInterview}>
          <DSAlert type={DSAlertType.ERROR} display={DSAlertDisplay.FULL_BLEED}>
            Impossible de récupérer les entretiens du collaborateur
          </DSAlert>
        </div>
      )}
    </div>
  );
};
