import { type GridColDef } from "@mui/x-data-grid-pro";
import { type GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { type GridEvents } from "@mui/x-data-grid/models/events";
import { type MutableRefObject, useEffect } from "react";

function parseOrNull(raw: unknown) {
  if (!raw) return null;

  if (typeof raw === "string") {
    try {
      return JSON.parse(raw);
    } catch (e) {
      console.warn(`Failed to parse: ${raw.substring(0, 50)}`);
      return null;
    }
  }

  return null;
}

type UsePersistColumnSettings = {
  persistenceId?: string;
  columns: GridColDef<any>[];
  apiRef?: MutableRefObject<GridApiPro>;
};

export function usePersistColumnSettings({
  apiRef,
  persistenceId,
  columns,
}: UsePersistColumnSettings) {
  if (!apiRef) return;

  useEffect(() => {
    if (!persistenceId) return;
    const ref = apiRef.current;
    const subs: VoidFunction[] = [];

    const save = () => {
      const state = { ...(ref.exportState() ?? {}) };
      delete state["pagination"];
      if (state) {
        localStorage.setItem(
          `grid-${persistenceId}`,
          JSON.stringify({
            columns: state.columns,
          })
        );
      }
    };

    if (!ref?.subscribeEvent) return;

    const raw = localStorage.getItem(`grid-${persistenceId}`);
    if (raw) {
      const parsed = parseOrNull(raw);
      if (parsed) {
        try {
          ref.restoreState(parsed);
        } catch (e) {
          console.warn(`Failed to restore grid state`, e);
        }
      }
    } else {
      save();
    }

    const subscribe = <E extends GridEvents>(event: E) => {
      subs.push(ref.subscribeEvent(event, save));
    };

    subscribe("columnResizeStop");
    subscribe("columnOrderChange");
    subscribe("pinnedColumnsChange");
    subscribe("columnVisibilityModelChange");

    return () => {
      subs.forEach((unsub) => {
        unsub();
      });
    };
  }, [apiRef.current, persistenceId, columns]);
}
