import React from "react";
import { chunk, isEmpty, isNil } from "lodash";
import cx from "classnames";

import { OrganizationDetails } from "hooks/useCompany";
import { matchingFields } from "../CompanyForm";

import styles from "./CompanyCard.module.scss";
import useTranslation from "hooks/useTranslation";

type Organization = OrganizationDetails["properties"];

interface CompanyCardProps {
  readonly className?: string;
  readonly details: Organization;
  readonly onClick: () => void;
  readonly isChild?: boolean;
  readonly vertical?: boolean;
}

const CompanyCard = ({ className, details, onClick, vertical, isChild }: CompanyCardProps) => {
  const { t } = useTranslation();

  const informations = matchingFields.map((e) => {
    if (e.field === "currency" && isChild) return null;
    const value = (() => {
      if (isNil(details[e.field]) || isEmpty(details[e.field])) {
        return "-";
      }

      if (e.type === "Radio") {
        return details[e.field] ? "Oui" : "Non";
      }

      if (e.field === "currency") {
        return t(`currency.${details[e.field]}`);
      }

      return details[e.field];
    })();
    return {
      name: e.name,
      value,
    };
  });

  return (
    <button
      className={cx(
        styles.CompanyCard,
        {
          [styles.vertical]: vertical,
          [styles.child]: isChild,
        },
        className
      )}
      onClick={() => onClick()}
    >
      <div className={styles.header}>
        <h1 className={styles.name}>{details.name}</h1>
      </div>
      <div className={styles.content}>
        {details.portalLogo && (
          <div className={styles.logo}>
            <img
              alt="logo"
              src={`${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${details.portalLogo}`}
            />
          </div>
        )}
        <div className={styles.infos}>
          {chunk(informations, 5).map((block, index) => (
            <div className={styles.block} key={index}>
              {block.map((line) => (
                <div className={styles.cardLine} key={line?.name}>
                  <h2 className={styles.header}>{line?.name}</h2>
                  <span className={styles.content}>{line?.value}</span>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </button>
  );
};

export default CompanyCard;
