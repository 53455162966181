import { useEffect, useMemo } from "react";

import { DSFormGroupTextInput, Select } from "@skillup/ui";

import useAreas from "hooks/useAreas";
import history from "utils/history";

import { useDashboardData } from "../../context";
import { useCampaigns } from "../../state/campaigns";

import UserAreaSelect from "components/UserAreaSelect/UserAreaSelect";

import styles from "./GlobalFilters.module.scss";

function GlobalFilters({ activeCampaign }: { activeCampaign?: string }) {
  const { userAreas, setUserAreas } = useDashboardData();
  const { allAreas } = useAreas();

  return (
    <div className={styles.GlobalFilters}>
      {allAreas?.length > 1 && (
        <DSFormGroupTextInput label="Périmètres" className={styles.filter}>
          <UserAreaSelect
            selectClassName={styles.select}
            value={userAreas}
            onChange={setUserAreas}
          />
        </DSFormGroupTextInput>
      )}
      <DSFormGroupTextInput label="Campagne d'entretiens" className={styles.filter}>
        <InterviewCampaignSelect activeCampaignUuid={activeCampaign} />
      </DSFormGroupTextInput>
    </div>
  );
}

function InterviewCampaignSelect({ activeCampaignUuid }: { activeCampaignUuid: string }) {
  const { userAreas } = useDashboardData();
  const campaignsData = useCampaigns(userAreas);

  const { activeCampaign, setActiveCampaign } = useDashboardData();

  useEffect(() => {
    if (!campaignsData) return;
    if (activeCampaignUuid) {
      setActiveCampaign(activeCampaignUuid);
      return;
    }
    setActiveCampaign(campaignsData[0].uuid);
  }, [campaignsData, setActiveCampaign, activeCampaignUuid]);

  const campaignOptions = useMemo(
    () =>
      campaignsData?.map((c) => ({
        label: c.titleForHR,
        value: c.uuid,
      })) ?? [],
    [campaignsData]
  );

  return (
    <Select
      className={styles.select}
      value={activeCampaign}
      onChange={(value) => {
        history.push(`/responsable/dashboard/interview/${value}`);
      }}
      options={campaignOptions}
    />
  );
}

export default GlobalFilters;
