import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSButton, Modal } from "@skillup/ui";
import { Future } from "@skillup/monads";
import type { TrackingRoutes } from "types/api";

import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";

import { useTrackingByUuid } from "../../../state/tracking";
import TrackingUuidNotFound from "../../../components/TrackingUuidNotFound";

import styles from "./ArchiveTrackingModal.module.scss";

interface ArchiveProps {
  readonly trackingUuid: string;
  readonly onClose: () => void;
}

function ArchiveTrackingModal(props: ArchiveProps) {
  const { trackingUuid, onClose } = props;
  const tracking = useTrackingByUuid(trackingUuid);

  const { addToast } = useToasts();
  const { t } = useTranslation();

  async function archiveTracking(trackingUuid: string, onClose: () => void) {
    const archiveTrackingRequest = await generateArchiveRequest(trackingUuid);
    await handleArchiveResponse(archiveTrackingRequest, addToast, t, onClose);
  }

  if (tracking.isLeft()) {
    return <TrackingUuidNotFound trackingUuid={trackingUuid} onClose={props.onClose} />;
  }

  const data = tracking.right();

  return (
    <Modal size="big" title="Archiver la ligne de suivi" onClose={props.onClose} disableOnClickAway>
      <div className={styles.archiveTrackingModal}>
        <div className={styles.warningText}>
          <p>
            En archivant cette ligne de suivi, vous supprimez l’association entre{" "}
            <b>{data.user.fullName}</b> et l'habilitation <b>{data.habilitation.name}</b>.
          </p>
          <p>Vous pourrez retrouver cette ligne dans les lignes archivées.</p>
        </div>

        <div className={styles.actions}>
          <DSButton label="Annuler" emphasis="Low" onClick={props.onClose} />
          <DSButton
            label="Archiver"
            emphasis="High"
            onClick={() => archiveTracking(trackingUuid, onClose)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ArchiveTrackingModal;

async function handleArchiveResponse(
  archiveTracking: {
    run: () => Future<string, { success: true }>;
  },
  addToast,
  t,
  onClose: () => void
) {
  await Future.unwrap(
    archiveTracking.run(),
    handleError(addToast, t),
    handleSuccess(addToast, t, onClose)
  );
}

function handleSuccess(
  addToast: any,
  t: any,
  onClose: () => void
): (val: { success: true }) => void {
  return () => {
    addToast(
      t("tracking.archive.success", { defaultValue: "Ligne de suivi archivée avec succès" }),
      {
        appearance: "success",
      }
    );
    onClose();
  };
}

function handleError(
  addToast: any,
  t: any
): (
  val: "tracking.archive.invalid-tracking" | "tracking.archive.snapshot-creation-failed"
) => void {
  return (error) => {
    addToast(
      formatError(t, error, {
        defaultValue: "Une erreur est survenue lors de l'archivage de la ligne de suivi.",
      }),
      {
        appearance: "error",
        autoDismiss: true,
      }
    );
  };
}

async function generateArchiveRequest(trackingUuid: string) {
  return buildSafeRequest<TrackingRoutes.Archive>({
    method: "POST",
    params: {
      trackingUuid,
    },
    path: "/tracking/{trackingUuid}/archive",
  });
}
