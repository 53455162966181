import { useQuery, QueryKey, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { BasicRouteType } from "types/route";
import { buildRequest } from "utils/buildRequest";

function useRouteQuery<Route extends BasicRouteType>(
  queryKey: QueryKey,
  fetchOptions: Omit<Route, "response">,
  queryOptions?: Omit<UseQueryOptions<unknown, unknown, unknown, QueryKey>, "queryKey" | "queryFn">
): UseQueryResult<Route["response"], unknown> {
  const query = useQuery(
    queryKey,
    async () => {
      const request = buildRequest<Route>(fetchOptions);
      const data = await request();
      return data;
    },
    queryOptions
  );

  return query;
}

export default useRouteQuery;
