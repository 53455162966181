import React, { useCallback, useState, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import cx from "classnames";

import { DSButton, Loader, useModal } from "@skillup/ui";

import Acta from "utils/Acta";
import useCompany, {
  CreateChildPayload,
  UpdateChildPayload,
  UpdatePayload,
} from "hooks/useCompany";

import CompanyCard from "./components/CompanyCard/CompanyCard";
import ModifyCompany from "./components/ModifyCompany";
import CreateCompany from "./components/CreateCompany";
import { Organization } from "./components/CompanyForm";

import styles from "./CompanySettings.module.scss";

const CompanySettings = () => {
  const userData = Acta.getState("userData");
  const companyUuid = userData.activeCompany.uuid;
  const [isSidePanelOpen, setSidePanelOpen] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<
    { isChild: boolean; org: Organization & { uuid: string } } | undefined
  >();
  const { addToast } = useToasts();

  const {
    isOpen: isCreationModalOpen,
    show: showCreationModal,
    hide: hideCreationModal,
  } = useModal();

  const { company, loading, update, createChild, updateChild, deleteChild, uploadLogo } =
    useCompany({
      companyUuid,
    });

  const closeSidePanel = useCallback(async () => {
    setSidePanelOpen(false);
    setSelectedCompany(undefined);
  }, [setSidePanelOpen, setSelectedCompany]);

  const openCreationModal = useCallback(async () => {
    closeSidePanel();
    showCreationModal();
  }, [closeSidePanel, showCreationModal]);

  const createChildCompany = useCallback(
    async (o: CreateChildPayload, file?: File) => {
      try {
        const createdChild = await createChild(o);

        if (file !== undefined) {
          await uploadLogo(createdChild.uuid, file);
        }

        hideCreationModal();
      } catch (err) {
        addToast("Erreur lors de la création de la sous entreprise", { appearance: "error" });
      }
    },
    [createChild, uploadLogo, addToast, hideCreationModal]
  );

  type Payload<T extends Boolean> = T extends true ? UpdateChildPayload : UpdatePayload;

  const updateCompany = useCallback(
    async function <T extends Boolean>(uuid: string, isChild: T, o: Payload<T>, file?: File) {
      try {
        if (isChild) {
          await updateChild({ childUuid: uuid, payload: o });
        } else {
          await update(o as Payload<false>);
        }

        if (file !== undefined) {
          await uploadLogo(uuid, file);
        }

        closeSidePanel();
      } catch (err) {
        addToast("Erreur lors de la mise à jour de la sous entreprise", { appearance: "error" });
      }
    },
    [update, updateChild, addToast, closeSidePanel, uploadLogo]
  );

  const deleteRequest = useCallback(
    async (childUuid: string) => {
      try {
        await deleteChild({ childUuid });
        closeSidePanel();
      } catch (err) {
        addToast("Erreur lors de la suppression de la sous entreprise", { appearance: "error" });
      }
    },
    [deleteChild, addToast, closeSidePanel]
  );

  const addLinkedCompanyButton = (
    <DSButton
      className={styles.addLinkedButton}
      label="Ajouter une entreprise associée"
      emphasis="Mid"
      onClick={openCreationModal}
    />
  );

  const hasChildren = useMemo(() => {
    return company?.children.length > 0;
  }, [company?.children.length]);

  return (
    <div className={styles.CompanySettings}>
      <div className={styles.scrollWrapper}>
        <div
          className={cx(styles.mainCompanyWrapper, {
            [styles.withChildren]: hasChildren,
          })}
        >
          <h1 className={styles.header}>Mon entreprise</h1>
          {loading && <Loader />}
          {!loading && company && (
            <CompanyCard
              details={company.properties}
              onClick={() => {
                setSidePanelOpen(true);
                setSelectedCompany({ isChild: false, org: company.properties });
              }}
              vertical={hasChildren}
            />
          )}
          {!loading && company && !hasChildren && addLinkedCompanyButton}
        </div>

        {hasChildren && (
          <div className={styles.subCompaniesWrapper}>
            <div className={styles.companyList}>
              <div className={styles.headerWrapper}>
                <h1 className={styles.header}>Entreprises associées</h1>
                {addLinkedCompanyButton}
              </div>
              <div className={styles.subCompaniesList}>
                {company.children.map((child) => (
                  <CompanyCard
                    className={styles.childCard}
                    key={child.uuid}
                    details={child}
                    onClick={() => {
                      setSidePanelOpen(true);
                      setSelectedCompany({ isChild: true, org: child });
                    }}
                    isChild
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {isSidePanelOpen && selectedCompany !== undefined && (
          <ModifyCompany
            uuid={selectedCompany.org.uuid}
            details={selectedCompany.org}
            isChild={selectedCompany.isChild}
            submitRequest={updateCompany}
            deleteRequest={deleteRequest}
            onClose={() => closeSidePanel()}
            loading={loading}
          />
        )}

        {isCreationModalOpen && (
          <CreateCompany
            loading={loading}
            submitRequest={createChildCompany}
            onClose={hideCreationModal}
          />
        )}
      </div>
    </div>
  );
};

export default CompanySettings;
