import React from "react";

import styles from "./TrainingOrganization.module.scss";

export default ({ row, initialData }) => {
  const currentRow = initialData[row.id];

  return (
    <div className={styles.TrainingOrganization}>
      <p> {currentRow.training?.organization}</p>
    </div>
  );
};
