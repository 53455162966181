import styles from "./StartDate.module.scss";

export default ({ value, row, initialData }) => {
  const currentRow = initialData[row.id];
  const { dates } = currentRow.properties;

  function formatDate(d) {
    if (!d) return "";
    return new Date(d).toLocaleDateString("fr-FR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  }

  return (
    <div className={styles.StartDate}>
      <span>{formatDate(value)}</span>
      <div className={styles.details}>
        {dates
          .map((dates) => dates.map(formatDate))
          .map(([startDate, endDate], i) => (
            <div key={i}>
              {startDate}
              {endDate && endDate !== startDate && ` - ${endDate}`}
            </div>
          ))}
      </div>
    </div>
  );
};
