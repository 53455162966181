import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { getFields } from "./getFields";

import { type Field } from "./getFields";

export { Field };

export type EnumerableField = Extract<Field, { kind: "enumerable" }>;

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};

const defaultOptions = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
};

type FieldsMap = Record<string, Field>;
type EnumerableFieldsMap = Record<string, Field & { kind: "enumerable" }>;

export const useCoreHRFields = (options: Options = defaultOptions) => {
  const query = useQuery(["core-hr-fields"], getFields, {
    ...defaultOptions,
    ...options,
  });

  const enumerableFields = useMemo(() => query.data?.filter(filterEnumerable) ?? [], [query.data]);
  const fieldsMap = useMemo(() => {
    if (!query.data) {
      return {} satisfies FieldsMap;
    }
    return query.data?.reduce<Record<string, Field>>((acc, field) => {
      acc[field.uuid] = field;
      return acc;
    }, {} satisfies FieldsMap);
  }, [query.data]);

  const enumerableFieldsMap = useMemo(() => {
    if (!query.data) {
      return {} satisfies EnumerableFieldsMap;
    }

    return query.data?.reduce<Record<string, EnumerableField>>((acc, field) => {
      if (filterEnumerable(field)) {
        acc[field.uuid] = field;
      }
      return acc;
    }, {} satisfies EnumerableFieldsMap);
  }, [query.data]);

  return {
    fields: query.data,
    fieldsMap,
    enumerableFields,
    enumerableFieldsMap,
    isError: query.isError,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
  };
};

function filterEnumerable(item: Field): item is EnumerableField {
  return item.kind === "enumerable";
}
