import React from "react";
import cx from "classnames";

import { ScheduleDashboardRoutes } from "@skillup/espace-rh-bridge";
import { Currency, formatMonetary } from "@skillup/shared-utils";
import { LocaleDate } from "@skillup/shared-utils";

import calendarMonths from "./calendarMonths";
import formatNumber from "./formatNumber";

import styles from "./PlanDashboardStyles.module.scss";

type MonthData =
  ScheduleDashboardRoutes.GetDashboardDataForSchedule<"plan">["response"]["months"][0];

const blue = "#2782ff";

interface IProps {
  years: Array<string>;
  budget: number;
  title: string;
  currentYear: string;
  months: Array<MonthData>;
  setYear: (string) => void;
  currency: Currency;
  locale: LocaleDate.Locales;
}

export default class TimeGraphContainer extends React.PureComponent<IProps, {}> {
  public componentDidMount(): void {
    this.buildGraph();
    window.addEventListener("resize", this.buildGraph.bind(this));
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", this.buildGraph.bind(this));
  }

  public componentDidUpdate(): void {
    this.buildGraph();
  }

  private formatPrice(priceInNumber: number): String {
    return formatMonetary(priceInNumber, {
      currency: this.props.currency,
      locale: this.props.locale,
    });
  }

  private buildGraph(): void {
    /**
     * The future will start day one of the current month
     */
    const { months, currentYear } = this.props;
    const presentMonth: number = new Date(
      `${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`
    ).getTime();
    const { budget } = this.props;

    /**
     * Build the data model for the graph
     */
    let totalEngaged = 0;
    let totalDone = 0;
    const currentYearAsInt = parseInt(currentYear, 10);
    const data = calendarMonths.map((calendarMonth, index) => {
      const monthData = months.find(
        (month) => month.month === index && month.year === currentYearAsInt
      ) ?? {
        bookingDoneAmount: 0,
        bookingDoneCount: 0,
        bookingDoneHours: 0,
        month: index + 1,
        trainingDoneAmount: 0,
        trainingDoneCount: 0,
        trainingDoneHours: 0,
        year: currentYearAsInt,
      };

      const date: Date = new Date(`${monthData.year}-${monthData.month + 1}-1`);
      const isPast: boolean = presentMonth - date.getTime() > 0;

      totalDone += monthData.trainingDoneAmount || 0;
      totalEngaged += isPast ? monthData.trainingDoneAmount || 0 : monthData.bookingDoneAmount || 0;

      return {
        amountAtDate: totalEngaged,
        date,
        monthLong: calendarMonth.long,
        monthShort: calendarMonth.short,
        monthlyAmount: isPast
          ? monthData.trainingDoneAmount || 0
          : monthData.bookingDoneAmount || 0,
      };
    });

    /**
     * Init the svg
     */
    const graphContainer = document.getElementById("timeGraphContainer") as HTMLElement;
    const margins = {
      bottom: 50,
      left: 60,
      right: 20,
      top: 20,
    };

    /** Should not happen often ; only if the component
     * is unmounted before the data are laoded
     */
    if (!graphContainer) return;

    const targetHeight = 350;
    const graphWidth = graphContainer.offsetWidth - (margins.left + margins.right);
    const graphHeight = targetHeight - (margins.top + margins.bottom);
    let graph = `
    <style>
      .onHoverData:hover {
        opacity: 1 !important;
      }
    </style>
    <svg
      width='${graphContainer.offsetWidth}'
      height='${targetHeight}'
      class='timeGraph'
    >
      <g
        style='transform: translate(${margins.left}px, ${margins.top}px)'>
    `;

    /**
     * Build the scale
     */
    const maxAmountY = Math.max(
      data.reduce(
        (memo, dataPoint) => (dataPoint.amountAtDate > memo ? dataPoint.amountAtDate : memo),
        0
      ),
      budget || 1
    );
    const magnitudeY = Math.pow(10, String(Math.ceil(maxAmountY)).length - 1);
    const maxScaleY = Math.ceil(maxAmountY / magnitudeY) * magnitudeY;
    const scaleY = (value) => (value * graphHeight) / maxScaleY;
    const scaleX = (value) => (value / data.length) * graphWidth;
    const yStops = [
      [0.25, 0.5, 0.75],
      [0.5, 1, 1.5],
      [1, 2],
      [1, 2, 3],
      [1, 2, 3, 4],
      [2, 4, 6],
      [2, 4, 6],
      [2, 4, 6, 8],
      [2, 4, 6, 8],
      [2, 4, 6, 8],
    ][maxScaleY / magnitudeY - 1];

    // Failsafe
    if (!yStops) return;

    /**
     * Y axis
     */
    for (const yStop of yStops) {
      graph += `<line
        style='stroke: #eee; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1px;'
        x1='${0}'
        x2='${graphWidth}'
        y1='${graphHeight - scaleY(yStop * magnitudeY)}'
        y2='${graphHeight - scaleY(yStop * magnitudeY)}'
      ></line>`;

      graph += `<text
        style='fill: #666; font-size: 11px; text-anchor: end;'
        x='-9'
        y='${graphHeight - scaleY(yStop * magnitudeY)}'
        dy='0.32em'
      >
        ${formatNumber(yStop * magnitudeY)}
      </text>`;
    }
    graph += `<line
        style='stroke: #ccc; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1px;'
        x1='${0}'
        x2='${graphWidth}'
        y1='${graphHeight}'
        y2='${graphHeight}'
      ></line>`;

    if (budget) {
      /* budget line */
      graph += `<line
        style='stroke: #24d600; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1px; opacity: 0.5'
        x1='${0}'
        x2='${graphWidth}'
        y1='${graphHeight - scaleY(budget)}'
        y2='${graphHeight - scaleY(budget)}'
      ></line>`;
    }

    /**
     * Append the elements
     */
    for (let index = 0; index < data.length; index++) {
      const dataPoint = data[index];
      const xPosition: number = scaleX(index) + graphWidth / data.length / 2;
      const prevXPosition: number = scaleX(index - 1) + graphWidth / data.length / 2;
      const monthlyY: number = scaleY(dataPoint.monthlyAmount);
      const cumulativeY: number = scaleY(dataPoint.amountAtDate);
      const prevCumulativeY: number = scaleY((data[index - 1] || {}).amountAtDate);
      const isPast: boolean = presentMonth - dataPoint.date.getTime() > 0;

      /* Bar for the monthly */
      graph += `<rect
        x='${xPosition - 8}'
        y='${graphHeight - monthlyY}'
        width='16'
        height='${monthlyY}'
        style='${
          isPast
            ? "fill: #2782ff;"
            : `stroke: ${blue};
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1px;
        fill: none;
        stroke-dasharray: 2, 5;`
        }'
      ></rect>`;

      /* Add the month */

      graph += `<text
        x='${xPosition}'
        y='${graphHeight + 23}'
        style='text-anchor: middle; font-size: 11px; fill: #666;'
      >
        ${dataPoint.monthShort}
      </text>`;

      /* Line and circle for the cumulative */
      if (index > 0) {
        graph += `<line
          style='${
            isPast
              ? `stroke: ${blue};
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1px;`
              : `stroke: ${blue};
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1px;
        stroke-dasharray: 3, 4;`
          }'
          x1='${prevXPosition}'
          x2='${xPosition}'
          y1='${graphHeight - prevCumulativeY}'
          y2='${graphHeight - cumulativeY}'
        ></line>`;
      }
      graph += `<circle
        cx='${xPosition}'
        cy='${graphHeight - cumulativeY}'
        r='3'
        style='fill: #2782ff;'
      ></circle>`;
    }

    for (let index = 0; index < data.length; index++) {
      const dataPoint = data[index];
      const xPosition: number = scaleX(index) + graphWidth / data.length / 2;
      const isPast: boolean = presentMonth - dataPoint.date.getTime() > 0;

      /**
       * Data on hover
       */
      graph += `<g
        class='onHoverData'
        style='opacity: 0; cursor: pointer; transition: opacity 300ms ease-out; z-index: 99;'
      >
        <rect
          x='${xPosition - graphWidth / data.length / 2}'
          y='30'
          width='${graphWidth / data.length}'
          height='${graphHeight - 31}'
          style='fill: #2782ff; opacity: 0.1'
        ></rect>
        <rect
          x='${xPosition - (index > data.length / 2 ? 275 : -10)}'
          y='200'
          rx='10'
          ry='10'
          width='275'
          height='60'
          fill='#fff'
          stroke='#eee'
          style='pointer-events: none;'
          stroke-width='1px'>
        </rect>
        <text
          x='${xPosition + 15 - (index > data.length / 2 ? 30 : -10)}'
          y='225'
          fill='#2782ff'
          style='pointer-events: none; fill: #666; font-weight: 700; font-size: 12px' text-anchor='${
            index > data.length / 2 ? "end" : "start"
          }'
        >
          ${isPast ? "Réalisé" : "Inscrit"} en ${dataPoint.monthLong} : ${this.formatPrice(
        dataPoint.monthlyAmount
      )}
        </text>
        <text
          x='${xPosition + 15 - (index > data.length / 2 ? 30 : -10)}'
          y='245'
          fill='#2782ff'
          style='pointer-events: none; fill: #666; font-weight: 700; font-size: 12px' text-anchor='${
            index > data.length / 2 ? "end" : "start"
          }'
        >
          Cumul jan - ${dataPoint.monthShort} : ${this.formatPrice(dataPoint.amountAtDate)}
        </text>
      </g>`;
    }

    /**
     * Total done
     */
    graph += `<g transform='translate(50, 20)'>
        <rect rx='10' ry='10' width='150' height='${
          budget ? "120" : "70"
        }' fill='#fff' stroke='#eee' stroke-width='1px'></rect>
        <text x='75' y='${
          budget ? "80" : "25"
        }' fill='#2782ff' style='font-weight: 700;  font-size: 13px' text-anchor='middle'>
          Réalisé
        </text>
        <text x='75' y='${
          budget ? "103" : "48"
        }' fill='#666' style='font-weight: 700;  font-size: 13px' text-anchor='middle'>
          ${this.formatPrice(totalDone)}
        </text>
        ${
          budget &&
          `
          <text x='75' y='25' fill='#24d600' style='font-weight: 700;  font-size: 13px' text-anchor='middle'>
            Budget
          </text>
          <text x='75' y='48' fill='#666' style='font-weight: 700;  font-size: 13px' text-anchor='middle'>
            ${budget ? this.formatPrice(budget) : "Non défini."}
          </text>
          `
        }
      </g>`;

    graph += "</g></svg>";
    graphContainer.innerHTML = graph;
  }

  public render(): JSX.Element {
    const { years, currentYear, setYear } = this.props;

    return (
      <div className={styles.timeGraphContainer}>
        <div className={styles.header}>
          <h2>{this.props.title}</h2>
          {years.map((year) => (
            <button
              key={year}
              className={cx({
                [styles.yearButton]: true,
                [styles.active]: currentYear === year,
              })}
              onClick={() => setYear(year)}
            >
              {year}
            </button>
          ))}
        </div>
        <div id="timeGraphContainer" />
      </div>
    );
  }
}
