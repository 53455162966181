import { useHistory } from "react-router-dom";
import { useMemo } from "react";

import { DSCard, DSDropdownItem, StatusType } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import { Flex, Text } from "@skillup/design-system";
import { plural } from "utils/locale";
import { EvaluationScale } from "types/skills";

import { JobStatus } from "./EvaluationScaleCard.styled";
import styles from "./EvaluationScaleCard.module.scss";

export interface EvaluationScaleCardProps {
  item: EvaluationScale;
  remove: () => Promise<void>;
  setAsDefault: () => Promise<void>;
}

const EvaluationScaleCard = ({ item, remove, setAsDefault }: EvaluationScaleCardProps) => {
  const history = useHistory();
  const pathname = history.location.pathname;
  const { t } = useTranslation();

  const archivedSkills = useMemo(() => {
    return item.skills.filter((skill) => skill.isArchived);
  }, [item.skills]);

  const deleteTooltip = useMemo(() => {
    if (item?.skills.length > 0) {
      return t("portal.configuration.evaluationScales.card.delete.tooltip.skills", {
        defaultValue: plural(
          item?.skills.length,
          "Cette échelle est utilisée par %n compétence%s et ne peut pas être"
        ),
        skillsCount: item?.skills.length,
      });
    }

    if (item?.isDefault) {
      return t("portal.configuration.evaluationScales.card.delete.tooltip.default", {
        defaultValue: "Vous ne pouvez pas supprimer l'échelle par défaut",
      });
    }

    return undefined;
  }, [item, t]);

  return (
    <DSCard
      title={item.name}
      width="100%"
      titleClassName={styles.title}
      labels={
        item?.isDefault && [
          <JobStatus
            key="default"
            type={StatusType.PROGRESS}
            label={t("portal.configuration.evaluationScales.card.isDefaultTag", {
              defaultValue: "Par défaut",
            })}
          />,
        ]
      }
      actionItems={
        <>
          <DSDropdownItem
            label={t("portal.configuration.evaluationScales.card.modidfy", {
              defaultValue: "Modifier l’échelle d’évaluation",
            })}
            onClick={() => history.push(`${pathname}/edit/${item.uuid}`)}
          />
          <DSDropdownItem
            label={t("portal.configuration.evaluationScales.card.defineDefault", {
              defaultValue: "Définir comme échelle par défaut",
            })}
            onClick={setAsDefault}
            disabled={item?.isDefault}
            tooltipLabel={
              item?.isDefault
                ? t("portal.configuration.evaluationScales.card.defineDefault.tooltip", {
                    defaultValue: "Cette échelle est déjà l'échelle par défaut",
                  })
                : undefined
            }
          />
          <DSDropdownItem
            label={t("portal.configuration.evaluationScales.card.delete", {
              defaultValue: "Supprimer l'échelle d'évaluation",
            })}
            onClick={remove}
            disabled={item?.isDefault || item?.skills.length > 0}
            tooltipLabel={deleteTooltip}
          />
        </>
      }
    >
      <Text marginBottom="s" espaceFont="captionRegular">
        {t("portal.configuration.evaluationScales.card.skillsCount.skills", {
          defaultValue: plural(
            item?.skills.length,
            `Cette échelle est utilisée sur ${item.skills.length} compétences`
          ),
          skillsCount: item?.skills.length,
        })}
        {archivedSkills?.length > 0 &&
          t("portal.configuration.evaluationScales.card.skillsCount.archivedSkills", {
            defaultValue: plural(item?.skills.length, `, dont ${archivedSkills.length} archivées`),
            archivedSkillsCount: archivedSkills.length,
          })}
      </Text>
      <Flex flexDirection="column" gap="xs">
        {item.levels.map((level, index) => (
          <div key={index}>
            <Text espaceFont="body2Regular" color="plainText-onLight-default">
              {level.level}. {level.name}
            </Text>
            <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
              {level.description}
            </Text>
          </div>
        ))}
      </Flex>
    </DSCard>
  );
};

export default EvaluationScaleCard;
