import { TargetScalesRoutes } from "@skillup/espace-rh-bridge";
import { ListUtils } from "@skillup/shared-utils";
import { DSDataGrid, DSFilters, FilterRef, useFilters } from "@skillup/ui";
import { useQuery } from "@tanstack/react-query";
import useTranslation from "hooks/useTranslation";
import { useCallback, useRef } from "react";
import { buildRequest } from "utils/buildRequest";
import { useTargetScaleDataLegacy } from "../hooks/useTargetScaleDataLegacy";

async function getTargetScalesLegacy() {
  try {
    return buildRequest<TargetScalesRoutes.GetScalesLegacy>({
      method: "GET",
      path: "/targetScalesFromTemplates",
    })();
  } catch (err) {
    //add banner or error page
  }
  return [];
}
const filterConfig = {
  title: {
    type: ListUtils.FilterType.TEXT,
  },
  levels: {
    type: ListUtils.FilterType.TEXT,
  },
};

const filterProps = {
  title: { label: "Intitulé de l'échelle", placeholder: "Filtrer par titre" },
  levels: { label: "Niveaux de l'échelle", placeholder: "Filtrer par niveaux" },
};

export const TargetScaleDataGridLegacy = () => {
  const { t } = useTranslation();
  const { data: targetScaleLegacy } = useQuery(["targetScalesLegacy"], getTargetScalesLegacy, {
    refetchOnWindowFocus: false,
  });
  const { columns, data: tableData } = useTargetScaleDataLegacy(targetScaleLegacy);

  const filterRef = useRef<FilterRef>();
  const [filters, filterValues, setFilters] = useFilters(filterConfig, filterProps);

  const handleFilterModelChange = useCallback((col) => {
    if (filterRef.current) {
      filterRef.current.addFilter(col.field);
    }
  }, []);

  const handleFilterValuesChange = useCallback(
    (filterValues) => {
      setFilters(filterValues);
    },
    [setFilters]
  );

  const rows = tableData
    .filter((row) =>
      filterValues?.title?.value
        ? row.title.toLowerCase().includes(filterValues?.title?.value.toLowerCase())
        : true
    )
    .filter((row) =>
      filterValues?.levels?.value
        ? row.levels.join(", ").toLowerCase().includes(filterValues?.levels?.value.toLowerCase())
        : true
    );

  return (
    <>
      <DSFilters
        ref={filterRef}
        t={t}
        config={filterConfig}
        filters={filters}
        onChange={handleFilterValuesChange}
      />
      <DSDataGrid
        columns={columns}
        rows={rows}
        initialState={{
          sorting: {
            sortModel: [{ field: "title", sort: "asc" }],
          },
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        pagination
        paginationMode="client"
        slotProps={{ toolbar: { translationPrefix: "targetScaleDatagrid" } }}
        onFilter={handleFilterModelChange}
      />
    </>
  );
};
