import { Row } from "../Projects";

function matchesSearch(text: string, search: string) {
  return String(text).toLowerCase().includes(String(search).toLowerCase());
}

export default function (rows: { original: Row }[], _id: string[], filterValue: string) {
  return rows.filter((row) => {
    return matchesSearch(row.original.cancelledBy, filterValue);
  });
}
