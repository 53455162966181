import { buildRequest } from "utils/buildRequest";
import { PromisePool } from "@skillup/promise-pool";

import type {
  GetConfigRouteType,
  DashboardConfig,
  GetFieldsRouteType,
  GetFieldRouteType,
  GetQuestionsRouteType,
  GetQuestionRouteType,
} from "../types";

export const fetchAnalyticsConfig = async (companyUuid) => {
  try {
    const config = await buildRequest<GetConfigRouteType>({
      method: "GET",
      path: "/public/config/{companyUuid}",
      params: { companyUuid },
      target: "ANALYTICS_CONFIG_SERVICE",
    })();

    return config.data.attributes.dashboards.find((dashboard) => {
      return dashboard.feature_tags.includes("training");
    });
  } catch (error) {
    return null;
  }
};

export const upsertAnalyticsConfig = async (companyUuid: string, config: DashboardConfig) => {
  try {
    return await buildRequest({
      method: "POST",
      path: "/refine/config",
      target: "ANALYTICS_CONFIG_SERVICE",
      payload: {
        data: {
          attributes: {
            company_uuid: companyUuid,
            dashboards: [{ ...config, feature_tags: ["training"] }],
            axes: [
              {
                field_name: "training_plan_plan_completion_phase",
                short_name: "My pretty short name",
                description: "This is an axis that does something...",
                coalescing_value: "2",
                value_bindings: {
                  value_1: "VaLuE 1",
                },
              },
            ],
          },
        },
      },
    })();
  } catch (error) {
    throw new Error("Failed to upsert analytics config");
  }
};

export const getFields = async () => {
  const fieldsUuids = await buildRequest<GetFieldsRouteType>({
    method: "GET",
    path: "/refine/fields",
    target: "ANALYTICS_CONFIG_SERVICE",
  })();

  const fieldPromises = fieldsUuids.data.attributes.field_ids.map((uuid) => () => {
    return buildRequest<GetFieldRouteType>({
      method: "GET",
      path: `/refine/field/{fieldId}`,
      params: { fieldId: uuid },
      target: "ANALYTICS_CONFIG_SERVICE",
    })();
  });

  const fields = await PromisePool.All(fieldPromises);

  return fields.map((field) => field.data.attributes);
};

export const getQuestions = async () => {
  const questionIds = await buildRequest<GetQuestionsRouteType>({
    method: "GET",
    path: `/refine/questions`,
    target: "ANALYTICS_CONFIG_SERVICE",
  })();

  const cardsData = questionIds.data.attributes.question_ids.map((id) => () => {
    return getQuestion(id);
  });

  const cards = await PromisePool.All(cardsData);

  return cards.map((card) => card.data.attributes);
};

export const getQuestion = (questionId: string) => {
  return buildRequest<GetQuestionRouteType>({
    method: "GET",
    path: `/refine/question/{questionId}`,
    params: { questionId },
    target: "ANALYTICS_CONFIG_SERVICE",
  })();
};
