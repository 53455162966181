import { useHistory } from "react-router-dom";

import { Flex, Text } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";
import { DSAvatar, UserPopover, DSSidePanel } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { Employee, SidePanelPropsType } from "./LevelGauge";

interface SidePanelProps {
  isOpen: boolean;
  close: () => void;
  sidePanelProps?: SidePanelPropsType;
}

export const SidePanel = ({ close, isOpen, sidePanelProps }: SidePanelProps) => {
  const { employees, name } = sidePanelProps || {};
  const { t } = useTranslation();
  const history = useHistory();

  const employeeRange = employees.reduce(
    (acc, val) => {
      const averageScore = val.averageEvaluationsScore;
      if (averageScore > acc.maxEvaluation) {
        acc.maxEvaluation = averageScore;
        acc.maxEmployee = val;
      }
      if (averageScore < acc.minEvaluation) {
        acc.minEvaluation = averageScore;
        acc.minEmployee = val;
      }
      return acc;
    },
    { maxEmployee: null, maxEvaluation: -Infinity, minEmployee: null, minEvaluation: Infinity } as {
      maxEvaluation: number;
      minEvaluation: number;
      maxEmployee: null | Employee;
      minEmployee: null | Employee;
    }
  );

  const maxRange = Math.ceil(employeeRange.maxEmployee?.averageEvaluationsScore ?? 1);
  const minRange = Math.floor(employeeRange.minEmployee?.averageEvaluationsScore ?? maxRange - 1);

  return (
    <DSSidePanel isOpen={isOpen} portalTarget={document.body}>
      <DSSidePanel.Header onClose={close}>
        <DSSidePanel.Header.Title title={name} />
        <DSSidePanel.Header.SubTitle
          subtitle={t("skills.dashboard.sidePanel.levelDescription", {
            defaultValue:
              "Évaluations des collaborateurs dont la moyenne se situe entre {{lowRange}} et {{highRange}}",
            highRange: maxRange,
            lowRange: minRange,
          })}
        />
      </DSSidePanel.Header>
      <DSSidePanel.Content>
        <Flex flexDirection="column">
          <Flex marginTop="s">
            {employees.length > 0 && (
              <Flex flexDirection="column">
                {employees
                  .sort((a, b) => a.averageEvaluationsScore - b.averageEvaluationsScore)
                  .map((employee) => {
                    const { averageEvaluationsScore, employeeUuid, fullName, role } = employee;

                    return (
                      <Flex marginBottom="s" key={employeeUuid} alignItems="center">
                        <UserPopover
                          position="down-right"
                          properties={[role].filter(Boolean)}
                          user={{
                            uuid: employeeUuid,
                            fullName,
                            initials: getInitials({ fullName }),
                          }}
                          profileBtn={{
                            label: t("skills.dashboard.sidePanel.seeProfile", {
                              defaultValue: "Voir le profil",
                            }),
                            onClick: () => {
                              history.push(
                                `/responsable/collaborateurs/${employeeUuid}/competences`
                              );
                            },
                          }}
                        >
                          <Flex key={employeeUuid} alignItems="center">
                            <DSAvatar
                              size="S"
                              randomColorSeedString={employeeUuid}
                              initials={getInitials({ fullName })}
                            />
                            <Flex marginLeft="xs" flexDirection="column">
                              <Text espaceFont="body1Regular" color="plainText-onLight-default">
                                {fullName}
                              </Text>
                              <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
                                {t("skills.dashboard.sidePanel.averageGrade", {
                                  averageEvaluationsScore,
                                  defaultValue: "Note moyenne : {{averageEvaluationsScore}}",
                                })}
                              </Text>
                            </Flex>
                          </Flex>
                        </UserPopover>
                      </Flex>
                    );
                  })}
              </Flex>
            )}
          </Flex>
        </Flex>
      </DSSidePanel.Content>
      <DSSidePanel.FooterEmpty />
    </DSSidePanel>
  );
};
