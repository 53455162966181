import DataLayer from "utils/DataLayer";

export interface IFiles {
  booking: string;
  files: Array<{
    properties: {
      category: string;
      createdAt: string;
      name: string;
      uuid: string;
    };
  }>;
  uuid: string;
}

export default async (row: string) => {
  const data = (await DataLayer.request({
    method: "GET",
    target: "API",
    url: `/v1/scheduleRow/files/${row}`,
  })) as IFiles;

  return data;
};
