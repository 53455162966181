import React, { useState } from "react";
import { useToggle } from "react-use";
import { IoIosWarning } from "react-icons/io";

import InteractiveButton from "components/InteractiveButton";
import Acta from "utils/Acta";

import deleteGroup from "../../actions/deleteGroup";
import type { Group } from "../../types";

import styles from "./DeleteModalStyles.module.scss";

interface Props {
  onSuccess: (groups: Group[]) => void;
  groupUuid: string;
}

export default ({ onSuccess, groupUuid }: Props) => {
  const [loading, toggleLoading] = useToggle(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  const onSubmit = async () => {
    try {
      toggleLoading(true);
      const groups = await deleteGroup(groupUuid);
      onSuccess(groups);
      Acta.dispatchEvent("closeModal");
    } catch (err) {
      setIsValid(false);
      toggleLoading(false);
    }
  };

  return (
    <div className={styles.deleteModal}>
      <div>
        <IoIosWarning size={64} />
      </div>
      <p>
        Ce groupe va être supprimé du catalogue du client. La suppression ne fonctionnera QUE SI
        aucun collaborateur n'est déjà rattaché au groupe. Sinon, il faut le faire à la main !
      </p>
      {isValid && (
        <iframe
          title="lxyy_njLmxw"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/lxyy_njLmxw?autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      )}
      {!isValid && (
        <iframe
          title="YRQvkoRXbTo"
          width="560"
          height="315"
          src="https://www.youtube.com/embed/YRQvkoRXbTo?autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      )}
      <InteractiveButton loading={loading} onClick={onSubmit} label="Je confirme la suppresion" />
    </div>
  );
};
