import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { usePrevious } from "react-use";

import { DSFormGroupText, DSTextInput, Label as DSLabel, DSSimpleTextArea } from "@skillup/ui";

import { useDebounce } from "../../../utils";
import { selectGlobalActionsCount } from "../../reducer";

export function BuilderFormInput({
  type = "text",
  value,
  onChange,
  debounceValue = 0,
  label,
  name,
  required,
  disabled,
  placeholder,
  multipleAssistiveTexts,
  assistiveArea,
  assistiveText,
  formGroupClassName,
  inputClassName,
  autoFocus,
}: {
  type?: "text" | "textarea";
  value: string;
  onChange?: (newValue: string) => void;
  debounceValue?: number;
  label?: string;
  name?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  assistiveArea?: any;
  assistiveText?: string;
  multipleAssistiveTexts?: any;
  formGroupClassName?: string;
  inputClassName?: string;
  autoFocus?: boolean;
}) {
  const globalActionCount = useSelector(selectGlobalActionsCount);
  const prevProps = usePrevious({ globalActionCount });

  const debouncedChange = useDebounce({ debounceValue: debounceValue, onChange: onChange });
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (prevProps && prevProps.globalActionCount !== globalActionCount) {
      setLocalValue(value);
    }
  }, [globalActionCount, prevProps, setLocalValue, value]);

  const isFormGroupForAssistiveArea = useMemo(() => {
    return multipleAssistiveTexts || assistiveArea || assistiveText;
  }, [multipleAssistiveTexts, assistiveArea, assistiveText]);

  if (isFormGroupForAssistiveArea) {
    return (
      <DSFormGroupText
        label={label}
        multipleAssistiveTexts={multipleAssistiveTexts}
        assistiveArea={assistiveArea}
        assistiveText={assistiveText}
        required={required}
        onChange={(newValue) => {
          setLocalValue(newValue);
          debouncedChange(newValue);
        }}
        className={formGroupClassName}
      >
        <BuilderFormInputInGroup
          type={type}
          value={localValue}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          className={inputClassName}
          autoFocus={autoFocus}
        />
      </DSFormGroupText>
    );
  }

  return (
    <>
      {label && <DSLabel label={label} required={required} />}

      {type === "text" && (
        <DSTextInput
          name={name}
          placeholder={placeholder}
          value={localValue}
          onChange={(newValue) => {
            setLocalValue(newValue);
            debouncedChange(newValue);
          }}
          className={inputClassName}
          disabled={disabled}
          autoFocus={autoFocus}
        />
      )}

      {type === "textarea" && (
        <DSSimpleTextArea
          name={name}
          placeholder={placeholder}
          value={localValue}
          onChange={(newValue) => {
            setLocalValue(newValue);
            debouncedChange(newValue);
          }}
          autoFocus={autoFocus}
          className={inputClassName}
        />
      )}
    </>
  );
}

const BuilderFormInputInGroup = ({
  type,
  value,
  name,
  placeholder,
  disabled,
  className,
  autoFocus,
}: {
  type: string;
  value: string;
  name: string;
  placeholder: string;
  disabled: boolean;
  className: string;
  autoFocus?: boolean;
}) => {
  if (type === "text") {
    return (
      <>
        <DSFormGroupText.TextInput
          name={name}
          placeholder={placeholder}
          value={value}
          className={className}
          disabled={disabled}
          autoFocus={autoFocus}
        />
      </>
    );
  }
  if (type === "textarea") {
    return (
      <>
        <DSSimpleTextArea
          name={name}
          placeholder={placeholder}
          value={value}
          className={className}
          autoFocus={autoFocus}
        />
      </>
    );
  }
  return null;
};
