import { PageBlock, SectionBlock, type BuilderStructure, ChildBlock } from "../../reducer";
import type { PositionItem } from "../useBuilderModals";
import { buildModalContent } from "./buildModalContent";

export function getModalDetails(
  structure: BuilderStructure,
  item: SectionBlock | PageBlock,
  action: "duplication" | "deletion"
) {
  const structureItem = getStructureItem(structure, item);
  const counts = getChildrenCount(structure, item);
  const content = buildModalContent({ item, counts, type: item.type, action });
  return {
    structureItem,
    content,
    counts,
  };
}

export function getStructureItem(structure: BuilderStructure, item: SectionBlock | PageBlock) {
  return item.type === "section"
    ? structure.sections[item.sectionIndex]
    : structure.sections[item.sectionIndex]?.pages[item.pageIndex];
}

export function getChildrenCount(structure: BuilderStructure, item: SectionBlock | PageBlock) {
  const section = structure.sections[item.sectionIndex];
  let counts = { pagesCount: 0, childrenCount: 0 };
  if (!section) return counts;

  if (item.type === "section") {
    counts = {
      pagesCount: section.pages.length,
      childrenCount: section.pages.reduce((acc, page) => acc + page.children.length, 0),
    };
  }
  if (item.type === "page") {
    counts = {
      pagesCount: 0,
      childrenCount: section.pages[item.pageIndex]?.children.length ?? 0,
    };
  }
  return counts;
}

export function getIndexes(item: PositionItem) {
  const indexes = {
    sectionIndex: item.sectionIndex,
    pageIndex: undefined,
    childIndex: undefined,
  };

  if (item.type === "page") {
    indexes.pageIndex = item.pageIndex;
  }

  if (item.type === "child") {
    indexes.pageIndex = item.pageIndex;
    indexes.childIndex = item.childIndex;
  }
  return indexes;
}

export function getPrecedentStructureItemUuid(
  structure: BuilderStructure,
  item: SectionBlock | PageBlock | ChildBlock
) {
  switch (item.type) {
    case "section":
      return structure.sections[item.sectionIndex - 1].uuid;
    case "page":
      return item.pageIndex === 0
        ? structure.sections[item.sectionIndex].uuid
        : structure.sections[item.sectionIndex].pages[item.pageIndex - 1].uuid;
    case "child":
      return item.childIndex === 0
        ? structure.sections[item.sectionIndex].pages[item.pageIndex].uuid
        : structure.sections[item.sectionIndex].pages[item.pageIndex].children[item.childIndex - 1]
            .uuid;
  }
}
