import { useCallback } from "react";
import { useSelector } from "react-redux";
import { Flex, Label as DSLabel, DSNumberInput, Select } from "@skillup/ui";

import { Divided } from "../ChildWrappers";
import { Permissions } from "../../Permissions/Permissions";

import { type Child, selectHighlightUuid } from "../../../../reducer";

import styles from "./InterviewsHistory.module.scss";

type InterviewsHistory = Extract<Child, { kind: "interviewsHistory" }>;

export function InterviewsHistoryChild({
  child,
  indexes,
  updateTemplate,
}: {
  child: InterviewsHistory;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
  updateTemplate: (data: InterviewsHistory) => void;
}) {
  const highlightUuid = useSelector(selectHighlightUuid);
  const changeDurationNumber = useCallback(
    (newMaxAge: InterviewsHistory["maxAge"]) => {
      updateTemplate({
        ...child,
        maxAge: newMaxAge,
      });
    },
    [child, updateTemplate]
  );

  const changeDurationUnit = useCallback(
    (newUnit: InterviewsHistory["ageUnit"]) => {
      updateTemplate({
        ...child,
        ageUnit: newUnit,
      });
    },
    [child, updateTemplate]
  );

  const changeDisplayedInterviewsTypes = useCallback(
    (newDisplayedInterviewsTypes: InterviewsHistory["types"]) => {
      updateTemplate({
        ...child,
        types: newDisplayedInterviewsTypes,
      });
    },
    [child, updateTemplate]
  );

  return (
    <Divided>
      <Flex column className={styles.interviewsHistoryChild}>
        <p>
          Le bloc « Historique d’entretiens » liste l’ensemble des entretiens qu’a réalisés le
          collaborateur.
        </p>

        <Flex column className={styles.interviewsHistoryChild__timeSettings}>
          <DSLabel label="Durée de l'historique" required />
          <Flex row>
            <DSNumberInput
              min={1}
              max={20}
              name="interviews-history-maxAge"
              value={child.maxAge?.toString() ?? undefined}
              onChange={(newMaxAge) => changeDurationNumber(Number(newMaxAge))}
              autoFocus={highlightUuid === child.uuid}
            />

            <Select
              className={styles.interviewsHistoryChild__unitSelect}
              options={[
                {
                  label: "Mois",
                  value: "months",
                },
                {
                  label: "An(s)",
                  value: "years",
                },
              ]}
              value={child.ageUnit}
              onChange={(newUnit) => changeDurationUnit(newUnit)}
            />
          </Flex>
        </Flex>

        <Flex column className={styles.interviewsHistoryChild__displayedTypes}>
          <DSLabel label="Type d'entretiens à afficher" />
          <Select
            multi
            extraValuesLabel={(count) => `${count} types sélectionnés`}
            allValuesLabel="Tous les types"
            selectAllLabel="Tout selectionner"
            options={[
              {
                label: "Entretien annuel",
                value: "Entretien annuel",
              },
              {
                label: "Entretien professionnel",
                value: "Entretien professionnel",
              },
              {
                label: "Bilan à 6 ans",
                value: "Bilan à 6 ans",
              },
              {
                label: "Autre",
                value: "Autre",
              },
            ]}
            canSelectAll
            value={child.types}
            onChange={(newDisplayedInterviewsTypes: InterviewsHistory["types"]) =>
              changeDisplayedInterviewsTypes(newDisplayedInterviewsTypes)
            }
          />
        </Flex>
      </Flex>
      <Flex column>
        <Permissions
          restrictions={child.disallow}
          uuid={child.uuid}
          indexes={indexes}
          kind="interviewsHistory"
          order={["comment", "downloadPDF"]}
          override={{
            comment: "Qui peut commenter l'historique d'entretiens ?",
          }}
        />
      </Flex>
    </Divided>
  );
}
