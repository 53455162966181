import styles from "./ChildWrappers.module.scss";

export function FullWidth({ children }: { children: React.ReactNode }) {
  return <div className={styles.fullWidth}>{children}</div>;
}

/**
 * @description
 * This component is used to display two children side by side.
 * The first one will be put into a white panel on the left.
 * The second one will be put into a slightly darker panel on the right.
 * It won't display more than two children.
 */
export function Divided({ children }: { children: [React.ReactNode, React.ReactNode] }) {
  return (
    <div className={styles.divided}>
      <div className={styles.divided__left}>{children[0]}</div>
      <div className={styles.divided__right}>{children[1]}</div>
    </div>
  );
}
