import { omit } from "lodash";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";

import type { ProjectsRoutes } from "types/api";
import { TDateRange } from "containers/Supervisor/routes/Projects/Components/MultiDateHoursRangeInput";

export type IProjectCreationData = Omit<
  ProjectsRoutes.Create["payload"]["sessionData"],
  "dates"
> & {
  dateRanges: TDateRange[];
};

export type SessionCreationParams = { intraUuid: string; sessionData: IProjectCreationData };

export async function createSession({ sessionData, intraUuid }: SessionCreationParams) {
  try {
    const request = buildRequest<ProjectsRoutes.Create>({
      method: "POST",
      path: "/projects",
      payload: {
        intraUuid,
        sessionData: {
          ...omit(sessionData, "dateRanges"),
          dates: sessionData.dateRanges.map((range) => range.date),
        },
      },
    });

    const { uuid: projectUuid } = await request();

    Acta.dispatchEvent("sendAppMessage", {
      message: "Session créée avec succès !",
      type: "success",
    });
    Acta.dispatchEvent("closeModal");

    return projectUuid;
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Une erreur est survenue lors de la création d'une session.",
      type: "error",
    });

    return undefined;
  }
}
