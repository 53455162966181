import { useToasts } from "react-toast-notifications";
import { useMutation } from "@tanstack/react-query";

import { FieldRoutesType } from "@skillup/espace-rh-bridge";
import { EditableCompanyField } from "@skillup/training-bridge";

import { buildRequest } from "utils/buildRequest";
import useTranslation from "hooks/useTranslation";

import { ModalFieldForm } from "../components/Form";

import { useFieldRefetch } from "../context";

const CreateFieldView = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const refetchLists = useFieldRefetch();
  const { addToast } = useToasts();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: EditableCompanyField) => {
      return buildRequest<FieldRoutesType.UpdateV2>({
        path: "/fields-v2",
        method: "POST",
        target: "API",
        payload: {
          ...data,
        },
      })();
    },
    onSuccess: () => {
      refetchLists();

      onClose();

      addToast(t("fields.form.create.success"), { appearance: "success" });
    },
    onError: () => {
      addToast(t("app.errors.message"), { appearance: "error" });
    },
  });

  return <ModalFieldForm loading={isLoading} onSubmit={mutateAsync} onClose={onClose} />;
};

export default CreateFieldView;
