import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import debounce from "lodash/debounce";

const saveDataToBase = debounce(async (context: React.Component<{}, {}>) => {
  // @ts-ignore
  const { updates } = context;
  // @ts-ignore
  const { schedule } = context.state;

  // @ts-ignore
  context.updates = {
    columns: [],
    rows: [],
  };

  if (updates.columns.length !== 0) {
    try {
      await DataLayer.request({
        body: JSON.stringify({ columns: updates.columns }),
        method: "PATCH",
        url: `/v1/scheduleView/override/${schedule.view.uuid}`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "Modifications enregistrées.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue et vos modifications n’ont pas été enregistrées.",
        type: "error",
      });
    }
  }

  if (updates.rows.length !== 0) {
    try {
      await DataLayer.request({
        body: JSON.stringify({
          columns: [],
          rows: updates.rows,
        }),
        method: "POST",
        url: `/v1/schedule/batch/${schedule.properties.uuid}/${schedule.view.uuid}?type=booking`,
      });

      Acta.dispatchEvent("sendAppMessage", {
        message: "Modifications enregistrées.",
        type: "success",
      });
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue et vos modifications n’ont pas été enregistrées.",
        type: "error",
      });
    }
  }
}, 1000);

export default saveDataToBase;
