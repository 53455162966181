import { Flex } from "@skillup/ui";

import { Divided } from "../ChildWrappers";
import { Permissions } from "../../Permissions/Permissions";

import styles from "./TrainingsCollectionChild.module.scss";
import type { Child } from "../../../../reducer";

type TrainingsCollection = Extract<Child, { kind: "trainingsCollection" }>;

export function TrainingsCollectionChild({
  child,
  indexes,
}: {
  child: TrainingsCollection;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
}) {
  return (
    <Divided>
      <Flex row className={styles.trainingsCollectionChild}>
        Le bloc « Demande de formation » liste l’ensemble des formations demandées pour le
        collaborateur, qu’elles aient été réalisées ou non, et permet de faire une nouvelle demande.
      </Flex>
      <Flex column>
        <Permissions
          restrictions={child.disallow}
          uuid={child.uuid}
          indexes={indexes}
          kind="trainingsCollection"
          order={["requestTraining", "comment"]}
          override={{
            comment: "Qui peut commenter le bloc ?",
          }}
        />
      </Flex>
    </Divided>
  );
}
