import React, { MouseEventHandler } from "react";
import { FlatButton } from "@skillup/ui";
import Icon from "components/Icon";

import styles from "./Choices.module.scss";

interface Props {
  choices: {
    icon: string;
    title: string;
    description: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
  }[];
}

const Choices = (props: Props) => {
  const { choices } = props;
  return (
    <div className={styles.choices} aria-label="Choix disponibles">
      {choices.map((choice) => (
        <FlatButton
          key={choice.title}
          icon={<Icon strokeIcon={choice.icon} width={30} />}
          ariaLabel={`choix-${choice.title}`}
          title={choice.title}
          description={choice.description}
          onClick={choice.onClick}
        />
      ))}
    </div>
  );
};

export default Choices;
