import collabReducer, { IContextState, TCollabAction } from "./collab";
import filtersReducer, { IFilterState, TFilterAction } from "./filters";
import sortingReducer, { ISortingState, TSortingAction } from "./sorting";
import trainingReducer, { ITrainingState, TTrainingAction } from "./training";
import interviewReducer, { IInterviewState, TInterviewAction } from "./interview";

export interface IState {
  users: IContextState;
  filters: IFilterState;
  sorting: ISortingState;
  training: ITrainingState;
  interview: IInterviewState;
}

export type TAction =
  | TCollabAction
  | TFilterAction
  | TSortingAction
  | TTrainingAction
  | TInterviewAction;

const initialState: IState = {
  users: {
    collabs: [],
  },
  filters: {},
  sorting: {},
  training: { trainings: [] },
  interview: { interviews: [] },
};

/** This is the main reducer. It combines different reducers by passing them
 * the actions coming from a single dispatch.
 */
const mainReducer = (state: IState = initialState, action: TAction): IState => ({
  filters: filtersReducer(state.filters, action as TFilterAction),
  users: collabReducer(state.users, action as TCollabAction),
  sorting: sortingReducer(state.sorting, action as TSortingAction),
  training: trainingReducer(state.training, action as TTrainingAction),
  interview: interviewReducer(state.interview, action as TInterviewAction),
});

export { initialState };
export default mainReducer;
