import React, { useMemo } from "react";
import cx from "classnames";
import { MaterialIcons, DSTooltip as Tooltip } from "@skillup/ui";

import { SESSION_STATES, SessionState } from "../../helper";

import styles from "./State.module.scss";

export default ({ value, row }: { value: SessionState; row: any }) => {
  const unsummonedTraineesCount = row.original.unsummonedTraineesCount;
  const greenStatus = Array.from<SessionState>([
    SESSION_STATES.achieved,
    SESSION_STATES.closed,
    SESSION_STATES.summoned,
  ]).includes(value);
  const yellowStatus = Array.from<SessionState>([SESSION_STATES.provisional]).includes(value);
  const redStatus = Array.from<SessionState>([
    SESSION_STATES.expired,
    SESSION_STATES.canceled,
  ]).includes(value);

  const grammarTooltip = useMemo(() => {
    if (unsummonedTraineesCount === 1) {
      return "1 collaborateur inscrit n'a pas reçu de convocation";
    } else {
      return `${unsummonedTraineesCount} collaborateurs inscrits n'ont pas reçu de convocation`;
    }
  }, [unsummonedTraineesCount]);

  return (
    <div className={styles.stateWrap}>
      <div
        className={cx(styles.State, {
          [styles.yellow]: yellowStatus,
          [styles.green]: greenStatus,
          [styles.red]: redStatus,
        })}
      >
        <p>{value}</p>
      </div>
      {value === SESSION_STATES.summoned && unsummonedTraineesCount > 0 && (
        <div className={styles.icon}>
          <Tooltip label={grammarTooltip}>
            <MaterialIcons.Warning size={24} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
