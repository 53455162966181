import { DSButton, Modal } from "@skillup/ui";
import { Future } from "@skillup/monads";
import { HabilitationsRoutes } from "@skillup/espace-rh-bridge";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import styles from "./DeleteHabilitation.module.scss";
import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";

interface Props {
  readonly onClose: () => void;
  readonly habilitationUuid: string;
  readonly habilitationName: string;
}

export default ({ habilitationName, habilitationUuid, onClose }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const handleMainAction = async () => {
    const deleteRequest = deleteHabilitation(habilitationUuid);
    await handleResponse({ deleteRequest, addToast, t, onClose });
  };

  return (
    <Modal title="Supprimer une habilitation" onClose={onClose} disableOnClickAway>
      <div className={styles.deleteHabilitation}>
        <p>Êtes-vous sûr de vouloir supprimer l’habilitation "{habilitationName}"</p>
        <div className={styles.buttonsWrapper}>
          <DSButton className={styles.button} emphasis="Low" label="Annuler" onClick={onClose} />
          <DSButton
            className={styles.button}
            emphasis="High"
            label="Confirmer"
            onClick={handleMainAction}
          />
        </div>
      </div>
    </Modal>
  );
};

async function handleResponse({
  deleteRequest,
  addToast,
  t,
  onClose,
}: {
  deleteRequest: Future<
    HabilitationsRoutes.Delete["errors"],
    HabilitationsRoutes.Delete["response"]
  >;
  addToast;
  t;
  onClose: () => void;
}) {
  await Future.unwrap(
    deleteRequest,
    (error) => {
      addToast(
        formatError(t, error, {
          defaultValue: "Echec lors de la suppression d'une habilitation.",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
    () => {
      addToast(
        t("habilitation.delete.success", {
          defaultValue: "Habilitation supprimée avec succès.",
        }),
        { appearance: "success", autoDismiss: true }
      );

      onClose();
    }
  );
}

function deleteHabilitation(habilitationUuid: string) {
  return buildSafeRequest<HabilitationsRoutes.Delete>({
    method: "DELETE",
    path: `/habilitations/{habilitationUuid}`,
    params: { habilitationUuid },
  }).run();
}
