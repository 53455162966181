import { useRef, useMemo, useState, Suspense, useCallback } from "react";

import {
  GridColDef,
  useGridApiRef,
  GridInitialState,
  GridRowSelectionModel,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from "@mui/x-data-grid-pro";

import { FilterRef, DSDataGrid, generateModalContext } from "@skillup/ui";
import { Flex, Loader, ColumnsVisibilityPopper } from "@skillup/design-system";

import { plural } from "utils/locale";
import { Assignee } from "types/skills";
import useTranslation from "hooks/useTranslation";
import { useEmployeeFields } from "hooks/useEmployees";

import { AssignmentsModal } from "./AssignmentsModal";
import { DSFiltersStyled } from "./Assignments.styled";
import { ToolbarButton } from "./components/ToolbarButton";
import useAssignmentsTableData from "./useAssignmentsTableData";
import { AssignmentsProvider, useAssignmentsContext } from "./AssignmentsContext";

const Assignments = () => {
  return (
    <AssignmentsProvider>
      <Flex height="100%" flexDirection="column">
        <Suspense fallback={<Loader fillSpace />}>
          <Layout />
        </Suspense>
      </Flex>
    </AssignmentsProvider>
  );
};

const Layout = () => {
  const filterRef = useRef<FilterRef>();

  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const useAssignEmployeeModal = generateModalContext();
  const assignEmployeeModal = useAssignEmployeeModal();
  const [employeesSelected, setEmployeesSelected] = useState<Array<Assignee>>(undefined);
  const { toggleableColumns } = useToggableColumns();

  const {
    employees: { data: employees, isLoading: employeesLoading },
    filtersHook: { conf, filters, setFilterValues },
    jobs: { data: jobs, isLoading: jobsLoading },
    paginationHook: { actualPaginationModel, setPaginationModel, total: totalRows },
    sortHook: { setSortModel, sortModel },
    visibleColumns: { columnsVisibilityModel, setColumnsVisibilityModel },
  } = useAssignmentsContext();

  const addActionToEmployee = (row: { id: string; data: Assignee }) => {
    const employee = employees.find((e) => e.uuid === row.id);
    setEmployeesSelected([employee]);
    assignEmployeeModal.openModal();
  };

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const { columns, rows } = useAssignmentsTableData({
    actions: addActionToEmployee,
    employees,
    t,
  });

  const assignMultipleEmployees = useCallback(() => {
    const uuids = new Set(selectedRows);
    const employeesToAssign = employees.filter((employee) => uuids.has(employee.uuid));

    setEmployeesSelected(employeesToAssign);
    assignEmployeeModal.openModal();
  }, [assignEmployeeModal, employees, selectedRows]);

  const initialState: GridInitialState = useMemo(() => {
    const persistedState = localStorage.getItem(`grid-assignments`);

    return {
      pagination: { paginationModel: { page: 0, pageSize: 20 } },
      pinnedColumns: {
        left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
        right: ["actions"],
      },
      ...JSON.parse(persistedState ?? "{}"),
    };
  }, []);

  const orderedColumns = orderColumns(columns, initialState.columns?.orderedFields);

  const columnsConfig = useMemo(() => {
    return columns
      .filter((column) => toggleableColumns.includes(column.field))
      .map((column) => ({
        headerName: column.headerName,
        key: column.field,
      }));
  }, [columns, toggleableColumns]);

  const toolbarActions = useMemo(
    () => [
      selectedRows.length > 1 && (
        <ToolbarButton
          selectedRows={selectedRows}
          key="assignMultipleEmployees"
          assignMultipleEmployees={assignMultipleEmployees}
        />
      ),
      <ColumnsVisibilityPopper
        columnsConfig={columnsConfig}
        key={"assignmentColumnsVisibilityPopper"}
        columnsVisibilityModel={columnsVisibilityModel}
        setColumnsVisibilityModel={setColumnsVisibilityModel}
      />,
    ],
    [
      selectedRows,
      assignMultipleEmployees,
      columnsConfig,
      columnsVisibilityModel,
      setColumnsVisibilityModel,
    ]
  );

  return (
    <Flex paddingTop="s" paddingHorizontal="s" flexDirection="column">
      <DSFiltersStyled
        t={t}
        config={conf}
        ref={filterRef}
        filters={filters}
        translationPrefix="skills.assignmentsGrid"
        onChange={setFilterValues}
      />

      <DSDataGrid
        apiRef={apiRef}
        rows={rows || []}
        checkboxSelection
        rowCount={totalRows}
        sortModel={sortModel}
        columns={orderedColumns}
        initialState={initialState}
        persistenceId={"assignments"}
        toolbarButtons={toolbarActions}
        paginationModel={actualPaginationModel}
        loading={employeesLoading || jobsLoading}
        columnVisibilityModel={columnsVisibilityModel}
        onSortModelChange={setSortModel}
        onPageChange={(page, pageSize) => {
          setPaginationModel({ page, pageSize });
        }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedRows(newRowSelectionModel);
        }}
        entityName={plural(
          totalRows || 0,
          `%n ${t("skills.list.collaborator.entityName", {
            defaultValue: "collaborateur",
          })}%s`
        )}
        emptyOverlay={{
          text: t("skills.list.collaborator.emptyOverlayText", {
            defaultValue: "Aucun collaborateur ne correspond à votre recherche.",
          }),
        }}
        errorOverlay={{
          text: [
            t("skills.list.collaborator.errorOverlayText.firstSentence", {
              defaultValue: `Une erreur est survenue lors du chargement des collaborateurs.`,
            }),
            t("skills.list.collaborator.errorOverlayText.secondSentence", {
              defaultValue: `Veuillez réessayer ultérieurement.`,
            }),
            t("skills.list.collaborator.errorOverlayText.thirdSentence", {
              defaultValue: `Si l’erreur persiste, contactez votre interlocuteur Skillup.`,
            }),
          ],
        }}
      />

      {assignEmployeeModal.isOpen && (
        <AssignmentsModal
          jobs={jobs}
          employees={employeesSelected}
          onClose={() => {
            assignEmployeeModal.closeModal();
          }}
        />
      )}
    </Flex>
  );
};

function useToggableColumns() {
  const { getFields } = useEmployeeFields();

  const toggleableColumns = useMemo(() => {
    const employeeFields = getFields();
    return [
      employeeFields.firstName.key,
      employeeFields.lastName.key,
      employeeFields.role.key,
      employeeFields.email.key,
      "jobName",
      employeeFields.division.key,
      employeeFields.service.key,
      employeeFields.site.key,
      employeeFields.joinDate.key,
      employeeFields.contract.key,
      employeeFields.branch.key,
      employeeFields.registrationNumber.key,
    ];
  }, [getFields]);

  return { toggleableColumns } as { toggleableColumns: string[] };
}
function orderColumns(columns: Array<GridColDef>, order?: Array<string>) {
  return [...columns].sort((a, b) => {
    const indexA = order ? order.indexOf(a.field) : -1;
    const indexB = order ? order.indexOf(b.field) : -1;
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });
}

export default Assignments;
