type GetNotificationsPieChartParams = {
  failedRate: number;
  pendingRate: number;
  successRate: number;
};

export function getNotificationsPieChart({
  failedRate,
  pendingRate,
  successRate,
}: GetNotificationsPieChartParams) {
  const pieGraphConf: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    plotOptions: {
      pie: {
        dataLabels: [
          {
            distance: -20,
            enabled: true,
          },
          {
            distance: -40,
            enabled: true,
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
            format: "{point.percentage:.1f}%",
            style: {
              fontSize: "1.2em",
              opacity: 0.7,
              textOutline: "none",
            },
          },
        ],
      },
    },
    series: [
      {
        type: "pie",
        // colorByPoint: true,
        data: [
          successRate > 0 && {
            name: "SUCCESS",
            y: successRate,
          },
          failedRate > 0 && {
            name: "FAILED",
            y: failedRate,
          },
          pendingRate > 0 && {
            name: "PENDING",
            y: pendingRate,
          },
        ].filter(Boolean),
        name: "Percentage",
      },
    ],
    title: {
      text: "Mail sending success rate",
    },
    tooltip: {
      valueSuffix: "%",
    },
  };
  return pieGraphConf;
}
