import { JobAnalytic } from "types/skills";

const parseJobIntoRow = (job: JobAnalytic) => {
  const {
    uuid,
    averageEvaluationScore,
    averageExpectedLevelDifference,
    averageSkillsExpectedLevelDifference,
    category,
    employeesEvaluated,
    name,
  } = job;

  const rowData = {
    uuid,
    averageEvaluationScore,
    averageExpectedLevelDifference,
    averageSkillsExpectedLevelDifference,
    category,
    employeesEvaluated,
    name,
  };

  return {
    ...rowData,
    id: uuid,
    averageEmployeesExpectedLevelDifference: job.averageSkillsExpectedLevelDifference
      ? {
          percentage: Math.round(job.averageSkillsExpectedLevelDifference?.percentage) ?? 0,
          values: job.averageSkillsExpectedLevelDifference?.values ?? "",
        }
      : null,
    category: job.category?.value || "",
  };
};

export default parseJobIntoRow;

export type JobRow = ReturnType<typeof parseJobIntoRow>;

export type SelectedJobRowData = JobRow;
