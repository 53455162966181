import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";

export interface UploadFile {
  body: FormData;
  row: string;
  type: string;
  category: string;
}

export default async ({ body, row, type, category }: UploadFile) => {
  try {
    await DataLayer.request({
      body,
      contentType: "multipart/form-data",
      method: "POST",
      url: `/v1/booking/upload/${row}?type=${type}&category=${category}`,
    });
    Acta.dispatchEvent("sendAppMessage", {
      message: "Fichier ajouté avec succès.",
      type: "success",
    });
  } catch (error) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Une erreur est survenue lors de l'ajout de votre fichier.",
      type: "error",
    });
  }
};
