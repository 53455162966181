import { HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import { Status, StatusType } from "@skillup/ui";

interface CampaignStateProps extends HTMLAttributes<HTMLDivElement> {
  isDark: boolean;
}

export function NotStartedStatus({ className, isDark }: CampaignStateProps) {
  const { t } = useTranslation();
  return (
    <Status
      darkMode={isDark}
      className={className}
      type={StatusType.DONE}
      label={t("peoplereview.campaign.state.notStarted", { defaultValue: "Non démarrée" })}
    />
  );
}
