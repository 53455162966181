import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useActions } from "./useActions";
import { archiveAction } from "./archiveAction";
import { unarchiveAction } from "./unarchiveAction";
import { editAction, EditActionPayload } from "./editAction";

function useActionsRefresh() {
  const queryClient = useQueryClient();

  function refresh() {
    queryClient.invalidateQueries(["people-review-actions"]);
  }

  return refresh;
}

export function useAction(actionID: string) {
  const { actions } = useActions({ refetchOnMount: false, refetchOnWindowFocus: false });
  const refreshActions = useActionsRefresh();

  const archiveMutation = useMutation(
    async () => {
      const response = await archiveAction({ actionID });
      refreshActions();
      return response;
    },
    { mutationKey: ["archive-action", actionID] }
  );

  const unarchiveMutation = useMutation(
    async () => {
      const response = await unarchiveAction({ actionID });
      refreshActions();
      return response;
    },
    {
      mutationKey: ["unarchive-action", actionID],
    }
  );

  const editMutation = useMutation(
    async (payload: EditActionPayload) => {
      const response = await editAction({ actionID, payload });
      refreshActions();
      return response;
    },
    {
      mutationKey: ["edit-action", actionID],
    }
  );

  const action = actions?.find((action) => action.uuid === actionID);

  return {
    action,
    mutations: {
      archive: archiveMutation,
      edit: editMutation,
      unarchive: unarchiveMutation,
    },
  };
}
