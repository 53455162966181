import { PayloadAction } from "@reduxjs/toolkit";

import { BuilderStructure } from "../../types";
import { AddPageAction, addPage } from "./addPage/addPage";
import { AddChildAction, addChild } from "./addChild/addChild";
import { AddSectionAction, addSection } from "./addSection/addSection";
import { isChildAction, isPageAction, isSectionAction } from "../../utils";

export type FromSection = {
  sectionIndex: number;
};

export type FromPage = {
  sectionIndex: number;
  pageIndex: number;
};

export type FromChild = {
  sectionIndex: number;
  pageIndex: number;
  childIndex: number;
};

export type FromIndexes = FromSection | FromPage | FromChild;

type AddAction = AddSectionAction | AddPageAction | AddChildAction;

export const addBlock = (state: BuilderStructure, action: PayloadAction<AddAction>) => {
  if (isSectionAction(action)) {
    return addSection(state, action);
  }

  if (isPageAction(action)) {
    return addPage(state, action);
  }

  if (isChildAction(action)) {
    return addChild(state, action);
  }

  throw new Error();
};
