import type { GetAllKeysSpecificUserDataRouteType } from "./getAllKeysSpecificUserData";
import type { GetModulesRouteType } from "./getModules";
import type { GetSpecificDataRouteType } from "./getSpecificData";
import type { GetUserExportRouteType } from "./getUserExport";
import type { GetAllUserExportRouteType } from "./getAllUserExport";
import type { GetReviewExportRouteType } from "./getReviewExport";
import type { GetUserPropertiesRouteType } from "./getUserProperties";
import type { GetUsersImmuneRouteType } from "./getUsersImmune";
import type { PatchUserLanguageRouteType } from "./patchUserLanguage";
import type { SearchUsersByQueryRouteTypeV2 } from "./searchUsersByQueryV2";

export { Scopes, ScopeType } from "./getScopes";
export { Modules, ModulesAccessList, ModuleType } from "./getModules";

export namespace UserRoutes {
  export type GetModules = GetModulesRouteType;
  export type GetUserProperties = GetUserPropertiesRouteType;
  export type GetUsersImmune = GetUsersImmuneRouteType;
  export type GetSpecificData = GetSpecificDataRouteType;
  export type GetAllKeysSpecificUserData = GetAllKeysSpecificUserDataRouteType;
  export type GetUserExportRouteData = GetUserExportRouteType;
  export type GetAllUsersExportRouteData = GetAllUserExportRouteType;
  export type GetReviewExportRouteData = GetReviewExportRouteType;
  export type PatchUserLanguageRouteData = PatchUserLanguageRouteType;
  export type SearchByQueryRouteV2 = SearchUsersByQueryRouteTypeV2;
}
