import { PermissionRoles } from "../types";
import { DSRadioGroup, DSRadio } from "@skillup/ui";

export const TableChildColumnPermissionsRadioFormRoles = ({
  childId,
  columnUuid,
  role,
  onGrantForRole,
}: {
  childId: string;
  columnUuid: string;
  role: PermissionRoles;
  onGrantForRole: (selectedRole: PermissionRoles) => void;
}) => {
  return (
    <DSRadioGroup name={"table-child-role-permissions" + childId + columnUuid}>
      <DSRadio
        label="Le collaborateur"
        checked={role === "employee"}
        onChange={() => onGrantForRole("employee")}
      />
      <DSRadio
        label="Le responsable d'entretien"
        checked={role === "manager"}
        onChange={() => onGrantForRole("manager")}
      />
    </DSRadioGroup>
  );
};
