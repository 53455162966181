import React, { useEffect, useMemo, useState } from "react";
import { isEmpty, orderBy } from "lodash";

import { parse as parseUtil } from "@skillup/shared-utils";
import { Carousel, Timeline } from "@skillup/ui";

import { TrainingResults } from "../types";
import TrainingCard from "./TrainingCard";
import { STATES_ORDER } from "../UserTraining";

import styles from "../UserTraining.module.scss";

interface Props {
  readonly trainings: TrainingResults;
}

export default ({ trainings }: Props) => {
  const [slidesWidth, setSlidesWidth] = useState(1100);

  const trainingsTimeline = useMemo(() => {
    function getMainTrainingDate(training: TrainingResults[0]): Date | null {
      if (
        training.state === "realized" ||
        (training.state === "subscribed" && training.formattedTranslatableDates?.length)
      ) {
        if (training.formattedTranslatableDates?.length === 1) {
          if (training.formattedTranslatableDates[0].length === 4) {
            return new Date(training.formattedTranslatableDates[0]);
          }
          return parseUtil(training.formattedTranslatableDates[0], "yyyy-MM-dd", new Date());
        }
        if (training.formattedTranslatableDates?.length === 2) {
          if (training.formattedTranslatableDates[1].length === 4) {
            return new Date(training.formattedTranslatableDates[0]);
          }
          return parseUtil(training.formattedTranslatableDates[1], "yyyy-MM-dd", new Date());
        }
      }
      const requestedAt = training.collectionData.find(
        ({ binding }) => binding === "requestedAt"
      )?.value;
      return new Date(requestedAt);
    }

    if (!trainings) return {};
    // organize by date
    const trainingsByYear = trainings.reduce((acc, training) => {
      training.order = STATES_ORDER[training.state];
      training.date = getMainTrainingDate(training);
      if (training.date) {
        const year = training.date.getFullYear();
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(training);
      }
      return acc;
    }, {});

    // add missing years
    let lastYear;
    for (const year of Object.keys(trainingsByYear).sort()) {
      const yearDiff = Number(year) - lastYear;
      if (lastYear && yearDiff > 1) {
        for (let i = 1; i < yearDiff; i++) {
          trainingsByYear[Number(lastYear) + i] = null;
        }
      }
      lastYear = year;
    }

    // compute title and sort trainings
    return Object.keys(trainingsByYear).reduce((acc, year) => {
      const trainingsOrdered = orderBy(trainingsByYear[year], ["order", "state", "date"]);
      acc[year] = {
        title: isEmpty(trainingsOrdered) ? `Pas de formation en ${year}` : undefined,
        data: trainingsOrdered,
      };
      return acc;
    }, {});
  }, [trainings]);

  useEffect(() => {
    function handleResize(e = null) {
      const isNavigationOpen = localStorage.getItem("navigationState") === "true";
      let width = window.innerWidth - 170;
      if (isNavigationOpen) {
        width -= 208;
      }
      setSlidesWidth(width);
    }

    window.addEventListener("navigationStateChanged", handleResize);
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("navigationStateChanged", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Timeline
      items={Object.keys(trainingsTimeline)
        .sort((year1, year2) => year2.localeCompare(year1))
        .map((timePeriod) => ({
          subTitle: trainingsTimeline[timePeriod].title,
          timePeriod,
          content: (
            <Carousel
              className={styles.carousel}
              // Need to provide width to see all the carousel
              style={{ width: slidesWidth }}
              backgroundRgbColor="250,250,250"
              slidesPerView={slidesWidth / 421}
              slides={trainingsTimeline[timePeriod].data.map((training) => (
                <TrainingCard training={training} />
              ))}
            />
          ),
        }))}
    />
  );
};
