import { Route, Switch, Redirect } from "react-router-dom";

import User from "utils/User";

import { ActionsList } from "./Actions/ActionsList";
import { TemplatesList } from "./Templates/TemplatesList";
import { DevMonitoringCampaigns } from "./DevMonitoring/DevMonitoringCampaigns/DevMonitoringCampaigns";
import { DevMonitoringCampaignDetails } from "./DevMonitoring/DevMonitoringCampaignDetails/DevMonitoringCampaignDetails";

export const PeopleReviewManagementRouter = () => {
  if (!User.isSkillupDeveloper()) {
    return (
      <Switch>
        <Route component={ActionsList} path={PR_Management_Routes.ActionsList} />
        <Route exact component={TemplatesList} path={PR_Management_Routes.TemplatesList} />
        <Redirect to={PR_Management_Routes.TemplatesList} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route component={ActionsList} path={PR_Management_Routes.ActionsList} />
      <Route exact component={TemplatesList} path={PR_Management_Routes.TemplatesList} />
      <Route
        exact
        component={DevMonitoringCampaigns}
        path={PR_Management_Routes.DevMonitoringCampaigns}
      />
      <Route
        exact
        component={DevMonitoringCampaignDetails}
        path={PR_Management_Routes.DevMonitoringCampaignDetails}
      />
      <Redirect to={PR_Management_Routes.TemplatesList} />
    </Switch>
  );
};

const BaseURL = "/responsable/configuration-des-people-reviews";
const DevMonitoringBaseUrl = `${BaseURL}/dev-monitoring`;
export const PR_Management_Routes = {
  ActionsList: `${BaseURL}/actions/`,
  BaseURL,
  DevMonitoringBaseUrl,
  DevMonitoringCampaignDetails: `${DevMonitoringBaseUrl}/campaigns/:campaignID/`,
  DevMonitoringCampaigns: `${DevMonitoringBaseUrl}/campaigns/`,
  TemplatesList: `${BaseURL}/modeles-de-campagne/`,
} as const;
