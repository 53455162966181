import React from "react";

import * as MaterialIcons from "../../components/MaterialIcons";

import styles from "./DSSidePanel.module.scss";

export function SidePanelAlert({ type, message }: SidePanelAlertProps) {
  return (
    <div className={styles.alertContainer}>
      <div className={styles.alertTypeIcon}>{iconMap[type]}</div>
      <div className={styles.alertMessage}>{message}</div>
    </div>
  );
}

export type SidePanelAlertProps = {
  type: "success" | "error" | "warning" | "info";
  message: string;
};

export type SidePanelAlertType = React.ReactElement<typeof SidePanelAlert>;

const iconMap = {
  success: <MaterialIcons.Check fontSize={"1.5rem"} color="green" />,
  error: <MaterialIcons.Error fontSize={"1.5rem"} color="#FF3D33" />,
  warning: <MaterialIcons.Warning fontSize={"1.5rem"} color="#FE9D58" />,
  info: <MaterialIcons.Info fontSize={"1.5rem"} color="#0088C7" />,
};
