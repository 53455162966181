import styled from "styled-components";
import { SkuiSpacing } from "@skillup/ui";

export const LevelOptions = styled.div`
  grid-area: options;
  padding-bottom: ${SkuiSpacing.xs};

  @media (max-width: 1200px) {
    padding-top: ${SkuiSpacing.xs};
  }
`;
