import React from "react";
import cx from "classnames";

import styles from "./Row.module.scss";

interface Props {
  row: { [k: string]: any };
  style: { [k: string]: string };
  onOpen: () => void;
  onClose: () => void;
}

export default ({ row, style, onOpen, onClose }: Props) => (
  <div
    {...row.getRowProps({ style })}
    onClick={row.isSelected ? onClose : onOpen}
    className={cx(styles.RowContainer, { [styles.active]: row.isSelected })}
  >
    {row.cells.map((cell) => {
      const {
        column: { width },
      } = cell;

      return (
        <div role="row" {...cell.getCellProps()} className={styles.row} style={{ width }}>
          {cell.render("Cell")}
        </div>
      );
    })}
  </div>
);
