import { ScheduleDashboardRoutes } from "@skillup/espace-rh-bridge";
import { Currency, formatMonetary } from "@skillup/shared-utils";
import { LocaleDate } from "@skillup/shared-utils";

type PlanDashboardDataCall =
  ScheduleDashboardRoutes.GetDashboardDataForSchedule<"plan">["response"];

/**
 * Number format
 */
const formatNumber = (value: number): string => {
  let letter = "";
  let divider = 1;
  if (value > 1000000) {
    letter = "m";
    divider = 1000000;
  } else if (value > 1000) {
    letter = "k";
    divider = 1000;
  }
  return (value / divider).toFixed(1).replace(".0", "").replace(".", ",") + letter;
};

export const formatDashBoardMonetaryFields = (
  data: PlanDashboardDataCall,
  currency: Currency,
  locale: LocaleDate.Locales
) => {
  let formatedData: PlanDashboardDataCall = {
    ...data,
  };

  Object.keys(data).forEach((key) => {
    if (data[key]?.cost) {
      formatedData[key] = {
        ...formatedData[key],
        cost: formatMonetary(formatedData[key].cost, {
          currency,
          locale: locale,
        }),
      };
    }
  });

  return formatedData;
};

export default formatNumber;
