import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { v4 as uuid } from "uuid";
import { isEmpty } from "lodash";

import { DSModal, DSTextInput, DSCheckbox, Select, Autocomplete, Label, Flex } from "@skillup/ui";

import { useTemplates, type CreateTemplateParams } from "services/interviews";
import styles from "./CreateTemplateModal.module.scss";
import { interviewTypes, InterviewType } from "../types";

const DEFAULT_DESCRIPTION =
  "<p>L’entretien professionnel concerne tous les salariés. Il a lieu tous les deux ans au minimum et peut également avoir lieu au retour d’un congé ou d’un arrêt de longue durée. Il s’agit ici de proposer un état des lieux de la carrière du collaborateur (une sorte de bilan d’étape 😊) et d’évoquer ses possibilités d’évolution, ses besoins de formation et de développement des compétences. L’objectif n’est en aucun cas d’évaluer les performances du collaborateur.</p>";
const INTRODUCTION_SECTION: CreateTemplateParams["sections"][number] = {
  uuid: uuid(),
  title: "Introduction",
  pages: [
    {
      uuid: uuid(),
      title: "Introduction",
      children: [
        {
          kind: "personnalisableGuidelines",
          uuid: uuid(),
          description: DEFAULT_DESCRIPTION,
          employee: {
            title: "Collaborateur",
            fields: [
              {
                type: "field",
                label: "Prénom et nom :",
                fieldName: "fullName",
                fieldType: "string" as const,
              },
              {
                type: "field",
                label: "Fonction :",
                fieldName: "role",
                fieldType: "string" as const,
              },
              {
                type: "field",
                label: "Date d’embauche :",
                fieldName: "joinDate",
                fieldType: "date" as const,
              },
              {
                type: "field",
                label: "Poste à l'entrée dans l'entreprise :",
                fieldName: "joinRole",
                fieldType: "string" as const,
              },
              {
                type: "field",
                label: "Date d’entrée sur le poste actuel :",
                fieldName: "roleDate",
                fieldType: "date" as const,
              },
            ],
          },
          manager: {
            title: "Responsable de l'entretien",
            fields: [
              {
                type: "field",
                label: "Prénom et nom :",
                fieldName: "fullName",
                fieldType: "string" as const,
              },
              {
                type: "field",
                label: "Fonction :",
                fieldName: "role",
                fieldType: "string" as const,
              },
              {
                type: "const",
                label: "Lien hiérarchique :",
                value: "Manager",
              },
            ],
          },
        },
      ],
    },
  ],
};

const CONCLUSION_SECTION: CreateTemplateParams["sections"][number] = {
  uuid: uuid(),
  title: "Partage et signature",
  pages: [
    {
      title: "Partage et signature",
      children: [
        {
          kind: "conclusion",
          uuid: uuid(),
        },
      ],
    },
  ],
};

const DEFAULT_NEW_TEMPLATE = {
  title: "",
  type: null,
  choicesForOrdinalTargets: [],
  targetsCategories: [],
  sections: [INTRODUCTION_SECTION, CONCLUSION_SECTION],
};
type CreateTemplateModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

type ModalState =
  | { step: "idle" }
  | { step: "creating" }
  | { step: "success"; templateUuid: string }
  | { step: "error"; error: string };

export const CreateTemplateModal = ({ isOpen, closeModal }: CreateTemplateModalProps) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const { templates, actions } = useTemplates({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const searchForAutocomplete = useCallback(
    async (search: string) => {
      const foundTemplates = await actions.search(search);
      return foundTemplates.map((template) => ({
        label: template.title,
        value: template.uuid,
      }));
    },
    [actions]
  );
  const [modalState, setModalState] = useState<ModalState>({ step: "idle" });
  const [useExistingTemplate, setUseExistingTemplate] = useState(false);
  const [templateToCopyUuid, setTemplateToCopyUuid] = useState<string>(null);

  const { formState, setFormState, setTitle, setType, isValid } = useTemplateForm();

  const handleTemplateToCopyChange = useCallback(
    (templateUuid: string) => {
      const templateToCopy = templates.find((template) => template.uuid === templateUuid);
      if (templateToCopy) {
        setTemplateToCopyUuid(templateToCopy.uuid);
        setFormState({
          title: templateToCopy.title + " (copie)",
          type: templateToCopy.type,
          choicesForOrdinalTargets: templateToCopy.choicesForOrdinalTargets,
          targetsCategories: templateToCopy.targetsCategories,
          sections: templateToCopy.sections,
        });
      } else {
        setTemplateToCopyUuid(null);
      }
    },
    [templates, setFormState, setTemplateToCopyUuid]
  );

  const handleUseExistingTemplateChange = useCallback(
    (checked) => {
      setUseExistingTemplate(checked);
      if (!checked) {
        setFormState(DEFAULT_NEW_TEMPLATE);
        setTemplateToCopyUuid(null);
      }
    },
    [setUseExistingTemplate, setFormState, setTemplateToCopyUuid]
  );

  const handleConfirm = useCallback(async () => {
    setModalState({ step: "creating" });
    try {
      const templateUuid = await actions.create(formState);
      setModalState({ step: "success", templateUuid });
    } catch (err) {
      setModalState({
        error: "Echec lors de la création de la trame",
        step: "error",
      });
    }
  }, [formState, actions, setModalState]);

  useEffect(() => {
    switch (modalState.step) {
      case "error":
        addToast(modalState.error, {
          appearance: "error",
        });
        break;
      case "success":
        closeModal();
        history.push(`/responsable/template/${modalState.templateUuid}`);
        break;
      case "idle":
      case "creating":
      default:
        return;
    }
  }, [modalState, addToast, closeModal, history]);

  return (
    <DSModal isOpen={isOpen} className={styles.modal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title="Nouvelle trame" />
      </DSModal.Header>
      <DSModal.Content>
        <Flex column>
          <Flex column className={styles.inputSection}>
            <Label label={"Titre de la nouvelle trame"} required />
            <DSTextInput
              className={styles.DSComponent}
              placeholder="Nouvelle trame"
              name="title"
              autoFocus
              value={formState.title}
              onChange={setTitle}
            />
          </Flex>
          <Flex column className={styles.inputSection}>
            <Label label={"Type d'entretien"} required />
            <Select
              className={styles.DSComponent}
              placeholder="Sélectionner un type d'entretien"
              options={interviewTypes.map((type) => ({ label: type, value: type }))}
              value={formState.type}
              clearable={false}
              isSearchable={false}
              onChange={setType}
            />
          </Flex>
          <Flex column className={styles.inputSection}>
            <DSCheckbox
              className={styles.DSComponent}
              label="Repartir d'une trame existante"
              checked={useExistingTemplate}
              onChange={handleUseExistingTemplateChange}
            />
          </Flex>
          {useExistingTemplate && (
            <Flex column className={styles.inputSection}>
              <Label label={"Trame à utiliser comme point de départ"} required />
              <Autocomplete
                className={styles.DSComponent}
                placeholder={"Choisir une trame d'entretien..."}
                aria-label={"Choisir une trame d'entretien..."}
                query={searchForAutocomplete}
                onChange={handleTemplateToCopyChange}
                debounce={300}
                noOptionsMessage="Aucun résultat"
              />
            </Flex>
          )}
        </Flex>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton label="Retour" onClick={closeModal} />
        <DSModal.Footer.PrimaryButton
          label="Créer la trame"
          onClick={handleConfirm}
          loading={modalState.step === "creating"}
          disabled={!isValid || (useExistingTemplate && !templateToCopyUuid)}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

function useTemplateForm() {
  const [formState, setFormState] = useState<CreateTemplateParams>(DEFAULT_NEW_TEMPLATE);

  const setTitle = useCallback(
    (title: string) => {
      setFormState((prevState) => ({
        ...prevState,
        title,
      }));
    },
    [setFormState]
  );

  const setType = useCallback(
    (type: InterviewType) => {
      setFormState((prevState) => ({
        ...prevState,
        type,
      }));
    },
    [setFormState]
  );

  const isValid: boolean = useMemo(() => {
    if (isEmpty(formState.title)) {
      return false;
    }

    if (isEmpty(formState.type)) {
      return false;
    }

    return true;
  }, [formState]);

  return {
    formState,
    setFormState,
    setType,
    setTitle,
    isValid,
  };
}
