import { useMemo } from "react";

import { ICompanyField } from "@skillup/training-bridge";
import { FormikInputText } from "@skillup/design-system";
import { AssistiveArea } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

type Props = {
  index: number;
  value: ICompanyField["options"][number];
  isNewOption: boolean;
};

const WarningAlert = ({ currentValue, prevValue }: { currentValue: string; prevValue: string }) => {
  const { t } = useTranslation();

  const cropValue = (value: string) => {
    return value.length > 30 ? `${value.slice(0, 30)}...` : value;
  };

  return (
    currentValue !== prevValue && (
      <AssistiveArea
        mode="warning"
        text={t("fields.config.form.warning.update", {
          defaultValue:
            'Toutes les lignes du plan ayant la valeur "{{ prevValue }}" seront remplacées par la valeur ci-dessus',
          prevValue: cropValue(prevValue),
        })}
      />
    )
  );
};

const FieldDragableOption = ({ index, value, isNewOption = false }: Props) => {
  const prevValue = useMemo(
    () => value.value,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div style={{ flex: 1, textOverflow: "ellipsis", overflow: "hidden", maxWidth: "250px" }}>
      <FormikInputText
        name={`options[${index}].value`}
        value={value.value}
        disabled={value.disabled}
      />
      {!isNewOption && <WarningAlert currentValue={value.value} prevValue={prevValue} />}
    </div>
  );
};

export default FieldDragableOption;
