import { useSetState } from "react-use";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { isNil } from "lodash";

import {
  DatePicker,
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  DSButton,
  DSTooltip,
  DSFormGroupTextInput,
  Modal,
  Switch,
  TextInput,
} from "@skillup/ui";

import useTranslation from "hooks/useTranslation";
import DateUtils from "utils/dates";

import { EditionContext, submitModification } from "./utils";
import { Campaign } from "./useCampaignModals";

import styles from "./ModifyCampaignModal.module.scss";
import { CampaignMailingSettings } from "@skillup/espace-rh-bridge";
import { useMemo } from "react";

export type Target = "duration" | "startDate" | "endDate";

export type AdditionalProps = {
  duration: number;
  preparationTime: number;
  startDate: number;
  frequency: number;
  mailingSettings?: CampaignMailingSettings;
};

interface IProps {
  readonly campaign: Campaign;
  readonly context: EditionContext;
  readonly resync: () => void;
  readonly onClose: () => void;
}

type TTarget = "duration" | "startDate" | "endDate";

export interface IEditionState {
  isUpdating: boolean;
  startDate: number;
  frequency: number;
  duration: number;
  titleForHR?: string;
  titleForAttendees?: string;
  mailingSettings: CampaignMailingSettings;
  errors: TTarget[];
  confidential: boolean;
}

export default ({ campaign, resync, onClose }: IProps) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [state, setState] = useSetState<IEditionState>({
    isUpdating: false,
    startDate: campaign.startDate ? moment(campaign.startDate).startOf("day").valueOf() : 0,
    duration: campaign.duration,
    frequency: campaign.frequency,
    titleForHR: campaign.titleForHR,
    titleForAttendees: campaign.titleForAttendees,
    mailingSettings: campaign.mailingSettings,
    errors: [],
    confidential: campaign.confidential,
  });

  const { startDate, duration } = state;

  const { start } = DateUtils.getLegacyCampaignDates(
    {
      start: startDate,
      duration,
    },
    "timestamp"
  ) as { start: number };

  const endDate = moment(start)
    .add(duration || 1, "days")
    .toDate();

  const onStartDateChange = (date: Date) => {
    if (!isNil(date)) {
      setState({
        startDate: moment(date).startOf("day").valueOf(),
      });
    }
  };

  const onEndDateChange = (date: Date) => {
    if (!isNil(date)) {
      const differenceInDays = moment(date).diff(start, "days");
      setState({ duration: differenceInDays });
    }
  };

  const showCampaignStartDateInput = useMemo(
    () => moment().isBefore(campaign.startDate),
    [campaign.startDate]
  );

  return (
    <Modal title="Modifier la campagne">
      <div className={styles.content}>
        <div className={styles.form}>
          <div className={styles.titles}>
            <DSFormGroupTextInput label="Nom de la campagne pour les RH">
              <TextInput
                required
                autoFocus
                className={styles.input}
                defaultValue={state.titleForHR}
                onChange={(value) => setState({ titleForHR: value })}
              />
            </DSFormGroupTextInput>

            <DSFormGroupTextInput label="Nom de la campagne pour les participants">
              <TextInput
                required
                className={styles.input}
                defaultValue={state.titleForAttendees}
                onChange={(value) => setState({ titleForAttendees: value })}
              />
            </DSFormGroupTextInput>
          </div>
          {campaign.type === "legacy" && (
            <div className={styles.dateBlock}>
              <div className={styles.inputLine}>
                <DSFormGroupTextInput
                  label="Date de début de campagne"
                  assistiveText={
                    !showCampaignStartDateInput &&
                    "La campagne a déjà commencé, vous ne pouvez plus modifier sa date de lancement."
                  }
                >
                  <DatePicker
                    className={styles.datePicker}
                    placeholder="Choisissez une date"
                    value={moment(start).toDate() || moment().toDate()}
                    minValue={moment().toString()}
                    onChange={onStartDateChange}
                    withIcon={false}
                    disabled={!showCampaignStartDateInput}
                    autoFormat
                  />
                </DSFormGroupTextInput>
              </div>

              <div className={styles.inputLine}>
                <DSFormGroupTextInput label="Date limite de signature">
                  <DatePicker
                    className={styles.datePicker}
                    placeholder="Choisissez une date"
                    value={endDate}
                    minValue={moment(start).toString()}
                    onChange={onEndDateChange}
                    withIcon={false}
                    autoFormat
                  />
                </DSFormGroupTextInput>
              </div>
            </div>
          )}
          <DSTooltip
            label="Ce paramètre ne peut plus être modifié car la campagne est déjà lancée"
            direction="left"
            disabled={!campaign.confidential}
          >
            <div className={styles.confidentialLine}>
              <DSFormGroupTextInput label="Confidentialité de la campagne">
                <Switch
                  active={state.confidential}
                  onToggle={(checked) => {
                    setState((state) => ({
                      ...state,
                      confidential: checked,
                    }));
                  }}
                  label="Cette campagne est confidentielle"
                  className={styles.switch}
                  labelClassName={styles.labelSwitch}
                  disabled={campaign.confidential}
                />
              </DSFormGroupTextInput>
              <p className={styles.description}>
                Les entretiens de cette campagne ne pourront être consultés que par les
                collaborateurs et leurs responsables d’entretien. Les futurs managers et la
                hiérarchie du collaborateur ne pourront pas les consulter.
              </p>
            </div>
          </DSTooltip>
          {!campaign.confidential && state.confidential && (
            <div className={styles.OptionsStepWarning}>
              <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
                Attention : une fois enregistré, ce paramètre ne pourra plus être modifié par la
                suite.
              </DSAlert>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <DSButton className={styles.button} emphasis="Low" label="Annuler" onClick={onClose} />
          <DSButton
            className={styles.button}
            emphasis="High"
            label="Enregistrer les modifications"
            onClick={() =>
              submitModification({
                campaign,
                state,
                setState,
                addToast,
                onClose,
                resync,
                t,
              })
            }
          />
        </div>
      </div>
    </Modal>
  );
};
