import React from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { IWithRouter } from "utils/types";

import TrainingScore from "components/TrainingScore";
import Icon from "components/Icon";
import { trashBin as trashBinIcon } from "uiAssets/StrokeIcons";

import GetBaseURLFromLocation from "utils/GetBaseURLFromLocation";

import TrainingCardStyles from "./TrainingCardStyles.module.scss";

interface ITrainingCard extends IWithRouter {
  onDeleteRequest?: (event: React.MouseEvent) => void;
  index: number;
  isEditable?: boolean;
  name: string;
  organizationLogo: string;
  organizationName: string;
  reviewsCount: number;
  reviewsGlobalScore: number | null;
  seoSlug: string;
  tags: Array<string>;
  trainingsLength?: number;
  uuid: string;
}

export default class TrainingCard extends React.PureComponent<ITrainingCard, {}> {
  public render(): JSX.Element {
    const {
      onDeleteRequest,
      isEditable,
      location,
      name,
      organizationLogo,
      organizationName,
      reviewsCount,
      reviewsGlobalScore,
      seoSlug,
      tags,
      trainingsLength,
    } = this.props;
    const baseURL = GetBaseURLFromLocation(location.pathname);
    const trainingURL = baseURL ? `${baseURL}/programmes/catalogue/${seoSlug}` : `/${seoSlug}`;

    return (
      <div
        className={cx(TrainingCardStyles.TrainingCard, {
          [TrainingCardStyles.editable]: isEditable,
        })}
      >
        <div>
          <aside className={TrainingCardStyles.logo}>
            <img
              src={
                organizationLogo
                  ? `${process.env.REACT_APP_PUBLIC_UPLOADS_URL}${organizationLogo}`
                  : ""
              }
              alt="logo"
            />
          </aside>
          <div>
            <Link to={trainingURL} target="_blank">
              {`${String(name).trim().slice(0, 40)}${String(name).length > 40 ? "..." : ""}`}
            </Link>
            <p>{organizationName}</p>
          </div>
        </div>

        {!!reviewsCount && reviewsGlobalScore && (
          <TrainingScore score={reviewsGlobalScore} reviewsCount={reviewsCount} />
        )}

        {!!tags &&
          tags.map((tag) => (
            <span className={TrainingCardStyles.tag} key={tag}>
              {tag}
            </span>
          ))}

        {isEditable && trainingsLength && onDeleteRequest && (
          <footer>
            <button onClick={onDeleteRequest}>
              <Icon strokeIcon={trashBinIcon} width={14} />
            </button>
          </footer>
        )}
      </div>
    );
  }
}
