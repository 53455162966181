export const managerReviewStatuses = {
  ARBITRATED: "arbitrated",
  IN_PROGRESS: "in_progress",
  NOT_STARTED: "not_started",
  SHARED: "shared",
} as const;
export type ManagerReviewStatus =
  (typeof managerReviewStatuses)[keyof typeof managerReviewStatuses];

export const coordinatorReviewStatuses = {
  DONE: "done",
  IN_PROGRESS: "in_progress",
  MANAGER_PREP: "manager_prep",
  NO_MANAGER_PREP: "no_manager_prep",
} as const;

export type CoordinatorReviewStatus =
  (typeof coordinatorReviewStatuses)[keyof typeof coordinatorReviewStatuses];
