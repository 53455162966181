import InteractiveButton from "components/InteractiveButton";

import type { ManualTrainingData } from "./Training";
import { modes } from "./Training";

import styles from "./Session.module.scss";
import AddTraineesContent from "components/AddTraineesModal/AddTraineesContent";
import { useUsersList } from "components/AddTraineesModal/add-trainees-context";
import { ISimpleSelectionUser, USER_FILE_VALIDATION_TYPE } from "@skillup/types";
import { switchOnError } from "../AddRow/helper";
import Acta from "utils/Acta";
import { useAreaUserGroups } from "components/AddTraineesModal/helpers";

export type User = ISimpleSelectionUser;

interface ManualUserSelectionProps {
  trainingData: ManualTrainingData;
  isLoading?: boolean;
  target: "collection" | "plan";
  onSuccess: (data: { users: User[] }) => void;
}

const ManualImportUser = ({
  isLoading = false,
  onSuccess,
  trainingData,
}: ManualUserSelectionProps) => {
  const {
    state: { selectedUsers },
  } = useUsersList();

  const submit = async () => {
    try {
      if (selectedUsers.length === 0) {
        throw new Error("users");
      }
      if (selectedUsers.some((user) => !!user.errors?.length)) {
        Acta.dispatchEvent("sendAppMessage", {
          message: "Certains collaborateurs sélectionnés contiennent des erreurs",
          type: "error",
        });
        return;
      }
      onSuccess({
        users: selectedUsers,
      });
    } catch (err) {
      switchOnError(err);
    }
  };

  const groups = useAreaUserGroups();

  return (
    <div className={styles.manualImportSessionModal}>
      <div className={styles.preview} aria-label="preview-formation">
        <p className={styles.recall}>Programme créé hors Skillup</p>
        <p>
          <strong>{trainingData.trainingName}</strong> - {[trainingData.sessionType]} -{" "}
          {modes[trainingData.sessionMode]} - {trainingData.trainingOrganizationName} -{" "}
          {`${trainingData.total * 0.01} € - ${trainingData.sessionDuration} heures`}
        </p>
      </div>
      <AddTraineesContent
        canUploadManager={false}
        scope={USER_FILE_VALIDATION_TYPE.FREE_ROW}
        customUsersLists={
          groups && [
            {
              label: "Sélectionner par périmètre",
              groups,
            },
          ]
        }
      />
      <div className={styles.nextStep}>
        <InteractiveButton loading={isLoading} label="Ajouter" onClick={submit} />
      </div>
    </div>
  );
};

export default ManualImportUser;
