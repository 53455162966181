import { PayloadAction, combineReducers, createAction, createSlice } from "@reduxjs/toolkit";
import undoable, { ActionTypes, excludeAction } from "redux-undo";

import {
  initializeState,
  moveBlock,
  addBlock,
  duplicateBlock,
  changePermission as changePermissionAction,
  removeBlock,
  editBlock,
  checkTemplateErrors,
} from "./actions";

import type { BuilderTemplate } from "./types";

const initialState: BuilderTemplate = { sections: [] };

// ACTIONS
const setSavedKey = createAction<string>("setSavedKey");
const setHighlightUuid = createAction<string>("setHighlightUuid");
const clearState = createAction("clearState");

// Template Reducer
const templateBuilderSlice = createSlice({
  name: "templateBuilder",
  initialState: initialState,
  reducers: {
    init: initializeState,
    move: moveBlock,
    edit: editBlock,
    changePermission: changePermissionAction,
    add: addBlock,
    remove: removeBlock,
    duplicate: duplicateBlock,
    checkErrors: checkTemplateErrors,
  },
  extraReducers: (builder) => {
    builder.addCase(setSavedKey, (state, action) => {
      state.savedKey = action.payload;
    });
    builder.addMatcher(
      (action) =>
        ![ActionTypes.CLEAR_HISTORY, setSavedKey.type, setHighlightUuid.type].includes(action.type),
      (state, _) => {
        if (state.savedKey) {
          state.savedKey = undefined;
        }
      }
    );
  },
});

const undoableTemplateBuilder = undoable(templateBuilderSlice.reducer, {
  initTypes: [clearState.type],
  filter: excludeAction(setHighlightUuid.type),
  limit: 100,
});

const { init, move, changePermission, add, remove, duplicate, edit, checkErrors } =
  templateBuilderSlice.actions;

// Combined template reducer + highlightUuid reducer
const templateBuilder = combineReducers({
  template: undoableTemplateBuilder,
  globalActionsCount: (state: number = 0, action: any) => {
    if (action.type === init.type) {
      return 0;
    }
    if ([ActionTypes.UNDO, ActionTypes.REDO].includes(action.type)) {
      return state + 1;
    }
    return state;
  },
  highlightUuid: (state: string = null, action: PayloadAction<any>) => {
    if (action.type === setHighlightUuid.type) {
      return action.payload;
    }
    switch (action.type) {
      case init.type:
        return action.payload.template.sections[0]?.uuid ?? state;
      case add.type:
      case duplicate.type:
        return action.payload.uuid;
      case remove.type:
        return action.payload.newHighlightUuid;
      default:
        return state;
    }
  },
});

export {
  templateBuilder,
  init,
  move,
  changePermission,
  add,
  remove,
  duplicate,
  edit,
  setSavedKey,
  clearState as clear,
  setHighlightUuid,
  checkErrors,
};
