import { Label as DSLabel } from "@skillup/ui";
import { Flex, FormikInputText } from "@skillup/design-system";

import { JobFields } from "types/skills";

interface CustomFieldProps {
  index: number;
  field: JobFields;
}

export const CustomField = ({ field, index }: CustomFieldProps) => {
  return (
    <Flex flexDirection="column">
      <DSLabel label={field.name} />
      <FormikInputText
        placeholder={"..."}
        value={field.value ?? ""}
        name={`fields.${index}.value`}
      />
    </Flex>
  );
};
