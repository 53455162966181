import { GridRowSelectionModel } from "@mui/x-data-grid-pro";
import { DSButton } from "@skillup/ui";
import { ColdReview, HotReview } from "@skillup/training-bridge";

import useTranslation from "hooks/useTranslation";

import styles from "./ToolbarButton.module.scss";

interface Props {
  selectedRows: GridRowSelectionModel;
  reviews: HotReview[] | ColdReview[];
  handleMultipleReviewAction: () => void;
}

export const ToolbarButton = ({ selectedRows, reviews, handleMultipleReviewAction }: Props) => {
  const { t } = useTranslation();

  if (selectedRows.length === 0) {
    return null;
  }

  const completedReviews = selectedRows.filter((reviewUuid) => {
    const review = reviews.find((review) => review.uuid === reviewUuid);
    return review?.status === "completed";
  });

  if (completedReviews.length > 0) {
    return (
      <span className={styles.noMassActionAvailable}>
        {t("trainings.manage.reviews.list.actions.noMassActionAvailableForCurrentSelection", {
          defaultValue: "Aucune action de masse n’est disponible pour la sélection courante",
        })}
      </span>
    );
  }

  return (
    <DSButton
      onClick={handleMultipleReviewAction}
      label={t("trainings.manage.reviews.list.actions.sendMultipleMails", {
        defaultValue: "Inviter à évaluer",
      })}
    />
  );
};
