import React, { useState } from "react";
import cx from "classnames";

import Tooltip from "components/Tooltip";

import styles from "./RadioButtonsStyles.module.scss";

export interface IRadioOption {
  label: string;
  value: string | boolean | number;
  help?: TemplateStringsArray | string;
}

export interface IRadioButtonsProps {
  disabled?: boolean;
  // name?: string;
  onChange: (option: IRadioOption) => void;
  options: Array<IRadioOption>;
  value?: string | boolean | number;
  className?: string;
  firstOptionAsDefaultValue?: boolean;
}

const RadioButtons = ({
  value: onMountValue,
  onChange,
  options,
  disabled = false,
  firstOptionAsDefaultValue = true,
  className,
}: IRadioButtonsProps): JSX.Element => {
  const defaultValue = onMountValue || (firstOptionAsDefaultValue ? options[0].value : undefined);
  const [value, setValue] = useState(defaultValue);

  const handleSelectOption = (radioOption: IRadioOption): void => {
    if (disabled) return;

    setValue(radioOption.value);
    return onChange(radioOption);
  };

  return (
    <div className={cx(styles.RadioButtons, className)}>
      {options.map((radioOption) => (
        <div
          key={radioOption.label}
          className={cx(styles.option, {
            [styles.checked]: radioOption.value === value,
            [styles.disabled]: disabled,
          })}
          onClick={() => handleSelectOption(radioOption)}
        >
          <div className={styles.circle} />
          <label>{radioOption.label}</label>
          {radioOption.help && (
            <Tooltip id={radioOption.label} content={radioOption.help} position="right" />
          )}
        </div>
      ))}
    </div>
  );
};

export default RadioButtons;
