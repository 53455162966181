import React from "react";

import styles from "./TrainingName.module.scss";

export default ({ value, row, initialData }) => {
  const currentRow = initialData[row.id];

  return (
    <div className={styles.TrainingName}>
      <p>{value}</p>
      <span> {currentRow?.training.reference}</span>
    </div>
  );
};
