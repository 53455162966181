import { CollaboratorsRoutes } from "@skillup/espace-rh-bridge";

type Training = CollaboratorsRoutes.GetTrainings["response"][0];

export interface ITrainingState {
  filter?: string[];
  trainings: Training[];
}

export type TTrainingAction =
  | { type: "setTrainingFilter"; filter: string[] }
  | { type: "setTrainings"; trainings: Training[]; maxLevel?: number };

const reducer = (state: ITrainingState, action: TTrainingAction): ITrainingState => {
  try {
    switch (action.type) {
      case "setTrainings":
        return { ...state, trainings: action.trainings };
      case "setTrainingFilter":
        return { ...state, filter: action.filter };
      default:
        return state;
    }
  } catch (err) {
    console.error(err);
    return state;
  }
};

export default reducer;
