/**
 *
 *
 * @export
 * @param {IParams} parameters
 * @returns {string}
 */

import { Currency, LocaleDate, formatMonetary } from "@skillup/shared-utils";

export interface IParams {
  price: number;
  returnAsNumber?: boolean;
  withTax?: boolean;
  currency: Currency;
  language: LocaleDate.Locales;
}

export default function FormatPrice(parameters: IParams): string | number {
  const { price, returnAsNumber, withTax } = parameters;

  if (!price || price <= 0) {
    if (returnAsNumber) return 0;
    return "Sur devis";
  }

  if (returnAsNumber) return price;

  const formattedPrice = formatMonetary(price, {
    currency: parameters.currency,
    locale: parameters.language,
  });

  return `${formattedPrice} ${withTax ? "TTC" : "HT"}`;
}
