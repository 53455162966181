import { useToggle } from "react-use";
import { Step } from "../../models/Step";

import styles from "./FooterNav.module.scss";
import { DSButton } from "@skillup/ui";

interface IProps {
  readonly currentStep: number;
  readonly setCurrentStep: (currentStep: number) => void;
  readonly steps: Step[];
  readonly createNewCampaign: (toggleLoading: (nextValue?: boolean) => void) => Promise<void>;
  readonly sendButtonLabel: string;
  readonly canCreateCampaign: boolean;
}

const FooterNav = ({
  currentStep,
  setCurrentStep,
  steps,
  createNewCampaign,
  sendButtonLabel,
  canCreateCampaign,
}: IProps): JSX.Element => {
  const [loading, toggleLoading] = useToggle(false);

  const canReachNextStep = !steps[currentStep].disabled;
  const hasPrevSteps = currentStep > 0;
  const hasNextSteps = currentStep + 1 < steps.length;

  const getPrevStep = (): void => {
    if (hasPrevSteps) {
      return setCurrentStep(currentStep - 1);
    }
  };

  const getNextStep = (): void => {
    if (hasNextSteps) {
      return setCurrentStep(currentStep + 1);
    }
  };

  const createCampaign = async (): Promise<void> => {
    return createNewCampaign(toggleLoading);
  };

  return (
    <div className={styles.FooterNav}>
      <div className={styles.prev}>
        {hasPrevSteps && <DSButton label="Précédent" emphasis="Low" onClick={getPrevStep} />}
      </div>
      <div className={styles.next} data-target="next-step">
        {hasNextSteps ? (
          <DSButton
            label="Suivant"
            emphasis="Mid"
            onClick={canReachNextStep ? getNextStep : undefined}
            disabled={!canReachNextStep}
          />
        ) : (
          <DSButton
            loading={loading}
            label={sendButtonLabel}
            emphasis="Mid"
            onClick={canCreateCampaign ? createCampaign : undefined}
            disabled={!canReachNextStep}
          />
        )}
      </div>
    </div>
  );
};

export default FooterNav;
