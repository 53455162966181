import { DSModal } from "@skillup/ui";

import { plural } from "utils/locale";

interface IProps {
  errors: number;
  closeModal: () => void;
}

const ErrorsOnSaveModal = ({ errors, closeModal }: IProps) => {
  return (
    <DSModal isOpen={errors > 0}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title="Enregistrer une trame" />
      </DSModal.Header>

      <DSModal.Content>
        <p>
          Votre trame n’a pas pu être enregistrée car elle contient {errors}{" "}
          {plural(errors, "erreur%s")}. <br />
          Veuillez {plural(errors, "la", { pluralText: "les" })} corriger afin de pouvoir
          enregistrer votre trame.
        </p>
      </DSModal.Content>

      <DSModal.FooterInfo>
        <DSModal.Footer.CancelButton onClick={closeModal} label="Fermer" />
      </DSModal.FooterInfo>
    </DSModal>
  );
};

export { ErrorsOnSaveModal };
