import React, { useCallback } from "react";

import { DSModal } from "@skillup/ui";
import { ScheduleRowOperationsRoute } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";
import { plural } from "utils/locale";

interface IProps {
  rows: string[];
  reload: () => void;
  activeTab: string;
}

const DeleteModal = ({ reload, rows, activeTab }: IProps) => {
  const handleSubmit = useCallback(async (): Promise<void> => {
    try {
      const { deletedRows } = await buildRequest<ScheduleRowOperationsRoute.Delete>({
        method: "DELETE",
        path: "/scheduleRow/operations",
        payload: {
          rows,
        },
      })();

      if (deletedRows.length === rows.length) {
        Acta.dispatchEvent("sendAppMessage", {
          message:
            rows.length === 1 ? "Ligne supprimée avec succès." : "Lignes supprimées avec succès.",
          type: "success",
        });

        Acta.dispatchEvent("closeModal");
        reload();
      } else {
        throw new Error();
      }
    } catch (error) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "La demande a échoué.",
        type: "error",
      });
    }
  }, [rows, reload]);

  const onClose = useCallback(() => {
    Acta.setState("modalDisplayed", null);
  }, []);

  let text = "";

  if (activeTab.includes("collection") || activeTab.includes("plan-denied")) {
    text = plural(
      rows.length,
      "Cette action est irréversible. Vous ne pourrez par récupérer cette ligne.",
      {
        pluralText: "Cette action est irréversible. Vous ne pourrez par récupérer ces lignes.",
      }
    );
  } else {
    text = plural(
      rows.length,
      "Attention, si cette demande est liée à une réservation, celle-ci ne sera pas annulée !",
      {
        pluralText:
          "Attention, si ces demandes sont liées à des réservations, celles-ci ne seront pas annulées !",
      }
    );
  }

  const buttonText = `Supprimer ${rows.length} ligne${rows.length > 1 ? "s" : ""}`;

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Supprimer" />
      </DSModal.Header>

      <DSModal.Content>
        <p>{text}</p>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={onClose} label="Annuler" />
        <DSModal.Footer.PrimaryButton onClick={handleSubmit} label={buttonText} />
      </DSModal.Footer>
    </DSModal>
  );
};

export default DeleteModal;
