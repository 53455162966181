import styled from "styled-components";

import { Flex, DSColor } from "@skillup/design-system";
import { EspaceFont, SkuiSpacing, DSAvatarGroup } from "@skillup/ui";

export const GaugeContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${SkuiSpacing.l} 0;
  margin-top: ${SkuiSpacing.l};
  flex: 1;
`;

export const Gauge = styled(Flex)`
  position: relative;
  width: 100%;
  padding: 3px;
  background: linear-gradient(90deg, #feedb4 0%, #ffbb00 100%);
`;

export const GaugeValue = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 10px;
  background: ${DSColor["surface-dark-darker"]};

  > span {
    position: absolute;
    font-size: 14px;
    top: 8px;
    color: ${DSColor["plainText-onLight-default"]};
    ${EspaceFont.rh.body2Bold};
  }
`;

export const FirstGaugeValue = styled(GaugeValue)`
  left: 0%;
`;

export const SecondGaugeValue = styled(GaugeValue)`
  left: 50%;

  > span {
    left: -8px;
  }
`;

export const ThirdGaugeValue = styled(GaugeValue)`
  right: 0%;

  > span {
    left: -22px;
  }
`;

export const CollabCircle = styled(Flex)<{ level: number }>`
  cursor: pointer;
  position: absolute;
  top: -25px;
  left: ${({ level }) => `${level}%`};
  transform: translate(-50%, -50%);
  border: 3px solid ${DSColor["decoration-yellow-base"]};
  border-radius: 20px;
  align-items: center;
  justify-content: center;
`;

export const Angle = styled.span`
  position: absolute;
  bottom: -6px;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  background: ${DSColor["decoration-yellow-base"]};
`;

export const AvatarGroupStyled = styled(DSAvatarGroup)`
  border-radius: 24px;
  z-index: 2;
  padding: 0.25rem;
  background: white;
  margin: 0;
  
    > div > div > span {
      margin-left: ${SkuiSpacing.xxs};
    }
  }
`;

export const SidePanelLevelName = styled(Flex)`
  flex-direction: column;
  margin-bottom: ${SkuiSpacing.xs};
  color: ${DSColor["plainText-onLight-lighter"]};
  ${EspaceFont.rh.body2Regular};
`;
