export const DatatableSkillFields = {
  name: {
    fieldType: "TEXT",
    traductionKey: "skills.list.tableColumns.name",
    traductionDefaultValue: "Intitulé",
    key: "name",
  },
  categoryLabel: {
    fieldType: "TEXT",
    traductionKey: "skills.list.tableColumns.category",
    traductionDefaultValue: "Catégorie",
    key: "categoryLabel",
  },
  jobsCount: {
    fieldType: "TEXT",
    traductionKey: "skills.list.tableColumns.jobsCount",
    traductionDefaultValue: "Fiches de poste liées",
    tooltipTraductionKey: "skills.list.tableColumns.tooltip.jobsCount",
    tooltipTraductionDefaultValue: "Nombre de fiches de poste auxquelles cette compétence est liée",
    key: "jobsCount",
  },
  employeesWithSkill: {
    fieldType: "TEXT",
    traductionKey: "skills.list.tableColumns.employeesWithSkill",
    traductionDefaultValue: "Collaborateurs concernés",
    tooltipTraductionKey: "skills.list.tableColumns.tooltip.employeesWithSkill",
    tooltipTraductionDefaultValue: "Collaborateurs ayant la compétence dans leur fiche de poste",
    key: "employeesWithSkill",
  },
} as const;
