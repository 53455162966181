import React from "react";

import { Child } from "../types";
import DisallowBlock, { commentRestrictions } from "./DisallowBlock";

import { InterviewRoles } from "types/Interview";

type TrainingsCollection = Child & { kind: "trainingsCollection" };
const TrainingsCollectionBlock = ({
  data,
  updateChild,
}: {
  data: TrainingsCollection;
  updateChild: (newChildData: Child) => void;
}) => {
  return (
    <div>
      <DisallowBlock
        data={data}
        updateChild={updateChild}
        restrictions={[
          ...commentRestrictions,
          {
            action: "requestTraining",
            label: "Empêcher la demande par le collab",
            role: InterviewRoles.EMPLOYEE,
          },
          {
            action: "requestTraining",
            label: "Empêcher la demande par le manager",
            role: InterviewRoles.MANAGER,
          },
        ]}
      />
    </div>
  );
};
export default TrainingsCollectionBlock;
