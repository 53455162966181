import { useCallback, useMemo } from "react";
import { atom, useRecoilState, RecoilRoot } from "recoil";
import { useEvent } from "react-use";

import User from "utils/User";

let defaultAreas;
try {
  defaultAreas = JSON.parse(localStorage.getItem("activeAreas")) || undefined;
} catch {}

const areasAtom = atom({
  key: "area",
  default: defaultAreas,
});

// for testing purposes
export const mountWithAreas = (children, areas: Array<string>) => {
  return (
    <RecoilRoot
      initializeState={(snap) => {
        snap.set(areasAtom, areas);
      }}
    >
      {children}
    </RecoilRoot>
  );
};

const useAreas = () => {
  const [activeAreas, setAreas] = useRecoilState(areasAtom);

  const allAreas = User.getAreas();
  const hasAllAreasActive = useMemo(
    () => !activeAreas || activeAreas?.length === allAreas?.length,
    [activeAreas, allAreas]
  );

  const setActiveAreas = useCallback((areas: string[]) => {
    localStorage.setItem("activeAreas", JSON.stringify(areas));
    setAreas(areas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearActiveAreas = useCallback(() => {
    localStorage.removeItem("activeAreas");
    setAreas(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEvent("storage", ({ key, oldValue, newValue }) => {
    if (key === "activeAreas" && oldValue !== newValue) {
      try {
        if (newValue !== JSON.stringify(activeAreas)) {
          const areas = JSON.parse(newValue);
          setAreas(areas);
        }
      } catch {
        clearActiveAreas();
      }
    }
  });

  return useMemo(
    () => ({ activeAreas, allAreas, hasAllAreasActive, setActiveAreas, clearActiveAreas }),
    [activeAreas, allAreas, hasAllAreasActive, setActiveAreas, clearActiveAreas]
  );
};

export default useAreas;
