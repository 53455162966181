import DataLayer from "utils/DataLayer";
import { TPrimaryState } from "@skillup/types";

export interface IPrimaryCall {
  rows: Array<IPrimary>;
  actions: string[];
  state: {
    isNotManagedBySkillup: boolean;
    noSessions: boolean;
    hasReceivedNotification: boolean;
  };
  tab: string;
}

export interface IPrimary {
  hasSessions?: boolean;
  primaryState: TPrimaryState;
  sessionUuid?: string;
  uuid: string;
  type: string;
  isCompanyTraining: boolean;
  managedBySkillup?: boolean;
  bookedForTrainingSession?: boolean;
  holdReviews: boolean;
  hasReviews: boolean;
  sessionDuration: string;
  sessionEndDate: null | number;
  sessionStartDate: null | number;
  total: number;
  totalWithTax?: number | null;
  trainingName: string;
  formattedTimespan?: string;
  realized: boolean;
}

export default async (rows: Array<string>, useCache: boolean): Promise<IPrimaryCall> => {
  const request = (await DataLayer.request({
    body: JSON.stringify({ rows, useCache }),
    method: "POST",
    target: "API",
    url: "/v1/scheduleRow/primaryState",
  })) as IPrimaryCall;

  return request;
};
