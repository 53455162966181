function mapIndex<T>(array: Array<T>) {
  return array.map((t, index) => ({
    ...t,
    index,
  }));
}

export default <T>(array: Array<T>, from: number, to: number): Array<T & { index: number }> => {
  if (to === from) return mapIndex(array);

  const result = [...array];
  const target = result[from];
  const increment = to < from ? -1 : 1;

  for (let k = from; k !== to; k += increment) {
    result[k] = result[k + increment];
  }
  result[to] = target;

  return mapIndex(result);
};
