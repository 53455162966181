import React from "react";
import { isNil } from "lodash";

import Icon from "components/Icon";

import { chevronLeft as chevronLeftIcon } from "uiAssets/StrokeIcons";

import styles from "./ControlledHeader.module.scss";

interface ControlledHeaderProps {
  label: string;
  backButtonHandler?: () => void;
}

const ControlledHeader = ({ label, backButtonHandler }: ControlledHeaderProps) => {
  return (
    <div className={styles.header} aria-label="header modale">
      <div className={styles.topLine} />
      {!isNil(backButtonHandler) && (
        <div className={styles.back} onClick={backButtonHandler} aria-label="retour">
          <Icon width={15} height={15} strokeIcon={chevronLeftIcon} />
        </div>
      )}
      <p aria-label="titre modale">{label}</p>
    </div>
  );
};

export default ControlledHeader;
