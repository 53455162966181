import { DataTable, DSDropdownItem, DSDropdownDivider, Select, useModal2 } from "@skillup/ui";
import { useState } from "react";

import { plural } from "utils/locale";

import { useFilteredTrackings, Tracking as TrackingType } from "../state/tracking";

import Reglementaire from "../Reglementaire";
import TrackingSidePanel from "../components/TrackingSidePanel/TrackingSidePanel";
import { TrackingFilterKeys, trackingsStatusFilter } from "../state/filters";

import styles from "./Trackings.module.scss";

import useTrackingsActions, { ModalStates, TrackingAction } from "./useTrackingActions";
import useTrackingsTableData, { TrackingRow } from "./useTrackingsTableData";
import useTrackingsModals from "./useTrackingsModals";
import { useEffect } from "react";

import UserAreaSelect from "../components/UserAreaSelect/UserAreaSelect";
import { atom, useRecoilState } from "recoil";
import User from "utils/User";

const Trackings = () => {
  const { data, refetch } = useFilteredTrackings();
  const [{ state: actionsState, getActionLabel, getNewStateFromAction }, setTrackingsActions] =
    useTrackingsActions();
  const {
    state: { tracking: sidePanelTracking },
    toggle: toggleSidePanel,
    isOpen: isSidePanelOpen,
  } = useSidepanel();
  const activeSchedule = User.getActiveSchedule();
  const [filters, setFilters] = useState({});
  
  const modalToDisplay = useTrackingsModals(
    actionsState,
    setTrackingsActions,
    data?.trackings ?? [],
    refetch
  );

  const openActionModal = (trackingUuid: string, action: TrackingAction): void => {
    setTrackingsActions(getNewStateFromAction({ action, trackingUuid }));
  };

  const [page, setPage] = useState(0);
  const { rows, columns } = useTrackingsTableData(data?.trackings ?? [], openActionModal);

  const paginationHook = useRecoilState(trackingsPagination);

  return (
    <Reglementaire
      actions={{
        label: "Ajouter une ligne de suivi",
        onClick: () =>
          setTrackingsActions({
            state: ModalStates.Creating,
          }),
      }}
    >
      <div className={styles.tracking}>
        <div className={styles.globalFilters}>
          <StatusFilter />
          <UserAreaSelect />
        </div>
        <div className={styles.deleteList}>
          {rows && (
            <DataTable<TrackingRow>
              className={styles.TableWrapper}
              containerClassName={styles.Table}
              rows={rows}
              columns={columns}
              filters={{
                activeFilters: filters,
                onFiltersChange: setFilters,
              }}
              defaultSort={{ key: "status", desc: false }}
              onClickRow={(row) =>
                toggleSidePanel({
                  newSelection: data.trackings.find((t) => t.uuid === row.id),
                })
              }
              actions={(row) => {
                const actions = row.data.actions.map((action) => (
                  <DSDropdownItem
                    key={action.type}
                    label={getActionLabel(action)}
                    disabled={action.disabled}
                    onClick={() => {
                      openActionModal(row.id, action);
                    }}
                    tooltipLabel={action.tooltip}
                    tooltipDirection="top"
                  />
                ));
                const url =
                  activeSchedule &&
                  `/responsable/${activeSchedule.uuid}/plan-de-formation/plan-all?traineeFullName=${row.data.user}`;

                const goToButton = url && (
                  <DSDropdownItem
                    key={row.id}
                    label={`Voir dans le plan les lignes de ${row.data.user}`}
                    onClick={() => {
                      const win = window.open(url, "_blank");
                      win.focus();
                    }}
                  />
                );
                return actions.concat([<DSDropdownDivider key={"divider"} />, goToButton]);
              }}
              header={{
                selectedRowsLabel: (selected, total) =>
                  `${plural(selected, "%n ligne%s de suivi sélectionnée%s")} sur ${total}`,
                totalRowsLabel: (total) => {
                  let label = plural(total, "%n ligne%s de suivi");
                  if (total < data?.trackings?.length) {
                    label += `${plural(total, " filtrée%s")} sur ${data?.trackings.length}`;
                  }
                  return label;
                },
              }}
              pagination={{
                rowsPerPageLabel: "Lignes par page",
                itemsCountLabel: "Lignes de suivi %range% sur %count%",
                pageLabel: "Page",
                stateHook: paginationHook,
                activeTablePage: page,
                onPageChange: setPage,
              }}
              mode="compact"
            />
          )}
        </div>
      </div>

      {isSidePanelOpen && (
        <TrackingSidePanel
          tracking={sidePanelTracking}
          onClose={() => toggleSidePanel({ newSelection: undefined })}
          refetchTrackings={refetch}
          openActionModal={openActionModal}
          getActionLabel={getActionLabel}
        />
      )}

      {modalToDisplay}
    </Reglementaire>
  );
};

export default Trackings;

const trackingsPagination = atom<number>({
  key: "trackings.pagination",
  default: 10,
});
function StatusFilter() {
  const [status, setStatus] = useRecoilState(trackingsStatusFilter);

  function handleChange(newStatus?: TrackingFilterKeys) {
    setStatus(newStatus);
  }

  return (
    <Select<TrackingFilterKeys>
      className={styles.statusFilter}
      value={status}
      aria-label="filter-select"
      onChange={handleChange}
      isSearchable={false}
      placeholder="Statut..."
      options={[
        {
          label: "Toutes",
          value: "all",
        },
        {
          label: "Lignes actives",
          value: "active",
        },
        {
          label: "Lignes archivées",
          value: "archived",
        },
      ]}
    />
  );
}

const useSidepanel = () => {
  const { hide, show, isOpen } = useModal2();
  const { data, dataUpdatedAt } = useFilteredTrackings();
  const [tracking, setTracking] = useState<TrackingType>();

  useEffect(() => {
    if (data) {
      const newTracking = data.trackings.find((t) => t.uuid === tracking?.uuid);
      if (!newTracking && isOpen) hide();
      setTracking(newTracking);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdatedAt, data, tracking, setTracking]);

  function openSidepanelForTracking(newSelection: TrackingType) {
    setTracking(newSelection);
    show();
  }

  function hideSidepanel() {
    hide();
    setTracking(undefined);
  }

  return {
    state: { tracking },
    toggle: ({ newSelection }: { newSelection: TrackingType }) => {
      if (newSelection == null) {
        return hideSidepanel();
      }

      if (newSelection.uuid === tracking?.uuid) {
        hideSidepanel();
      } else {
        openSidepanelForTracking(newSelection);
      }
    },
    isOpen,
  };
};
