import { TemplateRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export type EditTemplatePayload = TemplateRoutes.EditTemplateRoute["payload"];

export type EditTemplateParams = {
  templateUuid: string;
  payload: EditTemplatePayload;
};

export async function editTemplate({
  payload: { actionsIds, fieldsUuids, title },
  templateUuid,
}: EditTemplateParams) {
  const result = await buildRequest<TemplateRoutes.EditTemplateRoute>({
    method: "POST",
    params: {
      templateUuid,
    },
    path: `/templates/{templateUuid}`,
    payload: {
      actionsIds,
      fieldsUuids,
      title,
    },
    target: "PEOPLE_REVIEW",
  })();

  return result;
}
