import { useHistory } from "react-router-dom";

import { Flex, Text } from "@skillup/design-system";
import { getInitials } from "@skillup/shared-utils";
import { DSAvatar, UserPopover, DSSidePanel } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

import { SidePanelPropsType } from "./ScaleLevelGauge";

interface SidePanelProps {
  isOpen: boolean;
  close: () => void;
  sidePanelProps?: SidePanelPropsType;
}

export const SidePanel = ({ close, isOpen, sidePanelProps }: SidePanelProps) => {
  const { employees, levels, name } = sidePanelProps || {};
  const { t } = useTranslation();
  const history = useHistory();

  const level = levels.find((e) => e.level === employees[0].level);

  return (
    <DSSidePanel isOpen={isOpen} portalTarget={document.body}>
      <DSSidePanel.Header onClose={close}>
        <DSSidePanel.Header.Title title={name} />
        <DSSidePanel.Header.SubTitle subtitle={`${level.level}. ${level.name}`} />
      </DSSidePanel.Header>
      <DSSidePanel.Content>
        <Flex flexDirection="column">
          <Text espaceFont="body2Regular" color="plainText-onLight-lighter">
            {t("skills.dashboard.sidePanel.levelDescription", {
              defaultValue: "Collaborateurs ayant été évalués au niveau {{level}}",
              level: `${level.level}. ${level.name}`,
            })}
          </Text>
          <Flex marginTop="s">
            {employees.length > 0 && (
              <Flex flexDirection="column">
                {employees.map((employee) => {
                  const { employeeUuid, fullName, role } = employee;

                  return (
                    <Flex marginBottom="s" key={employeeUuid} alignItems="center">
                      <UserPopover
                        position="down-right"
                        properties={[role].filter(Boolean)}
                        user={{
                          uuid: employeeUuid,
                          fullName,
                          initials: getInitials({ fullName }),
                        }}
                        profileBtn={{
                          label: t("skills.dashboard.sidePanel.seeProfile", {
                            defaultValue: "Voir le profil",
                          }),
                          onClick: () => {
                            history.push(`/responsable/collaborateurs/${employeeUuid}/competences`);
                          },
                        }}
                      >
                        <Flex key={employeeUuid} alignItems="center">
                          <DSAvatar
                            size="S"
                            initials={getInitials({ fullName })}
                            randomColorSeedString={employeeUuid}
                          />
                          <Flex marginLeft="xs" flexDirection="column">
                            <Text espaceFont="body1Regular" color="plainText-onLight-default">
                              {employee.fullName}
                            </Text>
                          </Flex>
                        </Flex>
                      </UserPopover>
                    </Flex>
                  );
                })}
              </Flex>
            )}
          </Flex>
        </Flex>
      </DSSidePanel.Content>
      <DSSidePanel.FooterEmpty />
    </DSSidePanel>
  );
};
