import React, { useState, useCallback } from "react";
import { useToggle } from "react-use";

import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";
import SkillupLogo from "uiAssets/SkillupLogo";
import { arobase as arobaseIcon, key as keyIcon } from "uiAssets/StrokeIcons";
import { useQueryString } from "hooks";
import { auth } from "actions";

import styles from "./AuthAsUserViewStyles.module.scss";

export default () => {
  const [loading, toggleLoading] = useToggle(false);
  const [email, setEmail] = useState<string>();
  const { redirect } = useQueryString<{ redirect?: string }>();

  const onSubmitLogin = useCallback(
    async (event) => {
      event.preventDefault();

      if (loading) return;
      toggleLoading(true);

      try {
        await auth.switchToUserByEmail(email);
        if (redirect) {
          window.location.href = redirect;
        } else {
          window.location.href = process.env.REACT_APP_PUBLIC_APP_URL as string;
        }
      } catch (error) {
        console.error(error);
        toggleLoading(false);
      }
    },
    [loading, toggleLoading, email, redirect]
  );

  return (
    <main className={styles.view}>
      <div className={styles.space} />
      <form onSubmit={onSubmitLogin}>
        <header>
          <SkillupLogo />
          <h1>Connexion en tant que...</h1>
        </header>

        <div className={styles.formContainer}>
          <p>Saisissez une adresse email valide</p>
          <TextInput
            alwaysOpen={true}
            placeholder="email@entreprise.com"
            name="email"
            onChange={(_e, value) => setEmail(value)}
            required
            strokeIcon={arobaseIcon}
            type="email"
            label="E-mail"
            disabled={loading}
            autoFocus
          />
          <InteractiveButton
            label="Se connecter"
            strokeIconLeft={keyIcon}
            loading={loading}
            type="submit"
          />
        </div>
        <footer />
      </form>
    </main>
  );
};
