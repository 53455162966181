import React from "react";
import { IUser } from "@skillup/types";
import { match } from "react-router-dom";

import { IWithRouter } from "utils/types";
import Acta from "utils/Acta";
import TrainingsResults from "./TrainingsResults";
import TrainingResultsLoadingView from "./components/TrainingResultsLoadingView";
import { buildRequest } from "utils/buildRequest";
import { TrainingRoutes } from "@skillup/espace-rh-bridge";

interface IProps extends IWithRouter {
  formationsData?: object;
  match: match<{ query?: string }>;
}

interface IState {
  isLoading: boolean;
  serverError: boolean;
  formationsData?: object;
  userData?: IUser;
}

export default class TrainingsResultsContainer extends React.PureComponent<IProps, IState> {
  public state: IState = {
    isLoading: true,
    serverError: false,
  };

  constructor(props) {
    super(props);

    if (props.formationsData) {
      this.state = {
        formationsData: props.formationsData,
        isLoading: false,
        serverError: false,
        userData: Acta.getState("userData") as IUser,
      };
    } else if (props.match.params.query) {
      this.loadData(props);
    }
  }

  public componentDidMount(): void {
    Acta.subscribeState("userData", (userData) => this.setState({ userData }), this);
  }

  /**
   * Update the formations data width the data called
   * by the route when we are not in the account.
   */
  public static getDerivedStateFromProps(props, state) {
    if (state.formationsData !== props.formationsData && props.formationsData) {
      return {
        formationsData: props.formationsData,
        isLoading: false,
        serverError: false,
      };
    }

    return null;
  }

  /**
   * Update the formations with a direct data call when we are
   * in the account.
   */
  public componentDidUpdate(prevProps: IProps): void {
    if (
      this.props.location.pathname === prevProps.location.pathname &&
      this.props.location.search === prevProps.location.search
    ) {
      return;
    }

    this.setState({
      serverError: false,
    });
    if (!this.props.formationsData && this.props.match.params.query) {
      this.loadData(this.props);
    }
  }

  private loadData = async (props: IProps): Promise<void> => {
    try {
      const request = buildRequest<TrainingRoutes.Search.SearchByQuery>({
        method: "GET",
        path: "/training/search-by-query/{query}",
        params: {
          query: `${props.match.params.query}${props.location.search}`,
        },
      });
      // : `/v1/training/search-by-slug/${props.match.params.slug}${
      //     props.location.search
      //   }`;
      const formationsData = await request();
      // @ts-ignore
      this.setState({ formationsData, isLoading: false });
    } catch (error) {
      this.setState({ serverError: true, isLoading: false });
    }
  };

  public render(): JSX.Element {
    const { isLoading, serverError } = this.state;

    if (isLoading) return <TrainingResultsLoadingView />;

    if (serverError) return <div>Server error</div>;

    // @ts-ignore
    return <TrainingsResults {...this.props} {...this.state} />;
  }
}
