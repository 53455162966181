import { FormatDate, ParseDate } from "@skillup/shared-utils";

export const parseFieldValue = (
  type: "select" | "number" | "text" | "monetary" | "textarea" | "date",
  value: any,
  options?: { key: string; value: string }[]
): string => {
  if (type === "select") {
    return options?.find((option) => option.key === value)?.value;
  }

  if (type === "date") {
    const date = ParseDate.FromISO(value);
    date.setLocale("fr");

    return FormatDate.ToStringFormat(date, "dd/MM/yyyy");
  }

  return value?.toString() ?? "";
};
