import { forwardRef, useCallback, useEffect, useMemo, useRef } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import { ScrollTriggeredBy } from "../StructureBuilder";
import { useBuilderContext } from "../BuilderContext";

import { ContentItem, SectionItem } from "./types";
import { ChildContent, PageContent, SectionContent, EmptyStructureBlock } from "./components";

import styles from "./StructureBuilderContent.module.scss";
import {
  Section,
  selectHighlightUuid,
  selectStructureSections,
  setHighlightUuid,
} from "../reducer";
import { useFindVisibleItem } from "./useFindVisibleItem";

export const StructureBuilderContent = () => {
  const sections = useSelector(selectStructureSections);
  const highlightUuid = useSelector(selectHighlightUuid);
  const dispatch = useDispatch();

  const items = useStructureContent(sections);
  const mainRef = useRef(null);
  const {
    contentItemsRefs: itemsRefs,
    scrollTriggeredBy,
    setScrollTriggeredByUser,
    focusContentItemFromScroll,
    clickContentItem,
    itemsUpdateAfterDragEnd,
    itemsUpdateAfterActionOnBlock,
    updatedItemIdRef,
  } = useBuilderContext();
  const { findVisibleItem } = useFindVisibleItem(items);

  const hasOnlyIntroductionAndConclusion = useMemo(() => {
    return sections.length === 2;
  }, [sections]);

  const handleScroll = useMemo(() => {
    return debounce(() => {
      if (scrollTriggeredBy === ScrollTriggeredBy.User) {
        const mainRect = mainRef.current.getBoundingClientRect();
        const item = findVisibleItem(mainRect);
        if (item) {
          itemsRefs.current[item.uuid]?.focus();
          focusContentItemFromScroll(item.uuid);
        }
      } else {
        setScrollTriggeredByUser();
      }
    }, 100);
  }, [
    mainRef,
    findVisibleItem,
    scrollTriggeredBy,
    setScrollTriggeredByUser,
    itemsRefs,
    focusContentItemFromScroll,
  ]);
  useEffect(() => {
    handleScroll.cancel();
  }, [handleScroll]);

  useEffect(() => {
    if (updatedItemIdRef.current) {
      switch (updatedItemIdRef.current.fromAction) {
        case "dragEnd":
          itemsUpdateAfterDragEnd(updatedItemIdRef.current.uuid);
          break;
        case "addBlock":
        case "deleteItem":
        case "duplicateItem":
        case "removeItem":
        case "init":
          itemsUpdateAfterActionOnBlock(updatedItemIdRef.current.uuid);
          break;
        default:
          break;
      }
      updatedItemIdRef.current = { uuid: null, fromAction: null };
    }
  }, [items, itemsUpdateAfterDragEnd, itemsUpdateAfterActionOnBlock, updatedItemIdRef]);

  const handleClick = useCallback(
    (uuid: string) => {
      clickContentItem(uuid);
    },
    [clickContentItem]
  );

  const handleFocus = useCallback(
    (uuid: string) => {
      dispatch(setHighlightUuid(uuid));
    },
    [dispatch]
  );

  const lastSectionIndex = useMemo(() => {
    const length = items.filter((item) => item.type === "section").length;
    return length - 1 > 0 ? length - 1 : 0;
  }, [items]);

  return (
    <main className={styles.content} ref={mainRef} onScroll={handleScroll}>
      {hasOnlyIntroductionAndConclusion ? (
        <>
          <ContentItemWrapper
            item={items[0]}
            title={items[0].type !== "child" ? items[0].data.title : undefined}
            highlightUuid={highlightUuid}
            onFocus={() => handleFocus(items[0].uuid)}
            onClick={() => handleClick(items[0].uuid)}
            ref={(ref) => (itemsRefs.current[items[0].uuid] = ref)}
            lastSectionIndex={lastSectionIndex}
          >
            {items[0].type === "section" && (
              <SectionWrapper item={items[0]} lastSectionIndex={lastSectionIndex} />
            )}
          </ContentItemWrapper>
          <EmptyStructureBlock />
          <ContentItemWrapper
            item={items[1]}
            title={items[1].type !== "child" ? items[1].data.title : undefined}
            highlightUuid={highlightUuid}
            onFocus={() => handleFocus(items[1].uuid)}
            onClick={() => handleClick(items[1].uuid)}
            ref={(ref) => (itemsRefs.current[items[1].uuid] = ref)}
            lastSectionIndex={lastSectionIndex}
          >
            {items[1].type === "section" && (
              <SectionWrapper item={items[1]} lastSectionIndex={lastSectionIndex} />
            )}
          </ContentItemWrapper>
        </>
      ) : (
        items.map((item) => {
          return (
            <ContentItemWrapper
              item={item}
              title={item.type !== "child" ? item.data.title : undefined}
              highlightUuid={highlightUuid}
              onFocus={() => handleFocus(item.uuid)}
              onClick={() => handleClick(item.uuid)}
              ref={(ref) => (itemsRefs.current[item.uuid] = ref)}
              lastSectionIndex={lastSectionIndex}
            >
              {item.type === "section" && (
                <SectionWrapper item={item} lastSectionIndex={lastSectionIndex} />
              )}
              {item.type === "page" && <PageContent page={item} key={item.uuid} />}
              {item.type === "child" && <ChildContent child={item} key={item.uuid} />}
            </ContentItemWrapper>
          );
        })
      )}
    </main>
  );
};

const SectionWrapper = ({
  item,
  lastSectionIndex,
}: {
  item: SectionItem;
  lastSectionIndex: number;
}) => {
  const getChildren = useCallback(() => {
    if (item.data?.pages?.[0]?.children) {
      return item.data.pages[0].children;
    }
    return [];
  }, [item]);
  if (item.sectionIndex === 0) {
    return (
      <>
        <SectionContent section={item} key={item.uuid} />
        {getChildren().map((child, childIndex) => (
          <ChildContent
            child={{
              type: "child",
              data: child,
              sectionIndex: item.sectionIndex,
              pageIndex: 0,
              childIndex: childIndex,
              uuid: child.uuid,
            }}
            key={child.uuid}
          />
        ))}
      </>
    );
  }
  if (item.sectionIndex === lastSectionIndex) {
    return (
      <>
        {getChildren().map((child, childIndex) => (
          <ChildContent
            child={{
              type: "child",
              data: child,
              sectionIndex: item.sectionIndex,
              pageIndex: 0,
              childIndex: childIndex,
              uuid: child.uuid,
            }}
            key={child.uuid}
          />
        ))}
      </>
    );
  }
  return <SectionContent section={item} key={item.uuid} />;
};

type ContentItemWrapperProps = {
  item: ContentItem;
  title?: string;
  children: React.ReactNode;
  highlightUuid?: string;
  onFocus?: () => void;
  onClick?: () => void;
  lastSectionIndex: number;
};

const ContentItemWrapper = forwardRef<HTMLDivElement, ContentItemWrapperProps>(
  ({ item, children, highlightUuid, onFocus, onClick }, ref) => {
    // [CSB] We do not allow to duplicate or delete a block in the Structure Builder for now
    // const { handleDeletion, showActionsButtons, handleDuplication } = useBuilderModalsContext();

    // const duplicationTooltipLabel = useMemo(() => {
    //   switch (item.type) {
    //     case "section":
    //       return "Dupliquer la section et son contenu";
    //     case "page":
    //       return "Dupliquer la sous-section et son contenu";
    //     case "child":
    //       return "Dupliquer le bloc";
    //   }
    // }, [item.type]);

    // const deletionTooltipLabel = useMemo(() => {
    //   switch (item.type) {
    //     case "section":
    //       return "Supprimer la section et son contenu";
    //     case "page":
    //       return "Supprimer la sous-section et son contenu";
    //     case "child":
    //       return "Supprimer le bloc";
    //   }
    // }, [item.type]);

    return (
      <>
        <div
          id={`content_${item.uuid}`}
          className={cx(
            styles["content__item"],
            item.uuid === highlightUuid ? styles["content__item--highlight"] : ""
          )}
          tabIndex={0}
          onFocus={onFocus}
          onClick={onClick}
          ref={ref}
        >
          {children}
          {/* [CSB] We do not allow to duplicate or delete a block in the Structure Builder for now
          <ButtonsMutateContentItem
            duplicationTooltipLabel={duplicationTooltipLabel}
            deletionTooltipLabel={deletionTooltipLabel}
            showActionsButtons={showActionsButtons(item)}
            onDuplication={() => handleDuplication(item)}
            onDeletion={() => handleDeletion(item)}
          /> */}
        </div>
        {/*[CSB] We do not allow to add a block in the Structure Builder for now
         {item.sectionIndex !== 0 && item.sectionIndex !== lastSectionIndex && (
          <div className={styles["content__item__addButton"]}>
            <ButtonAddContentItem item={item} />
          </div>
        )} */}
      </>
    );
  }
);

export function useStructureContent(sections: Section[]): ContentItem[] {
  const contentItems = useMemo(() => {
    if (!sections) {
      return [];
    }

    return sections.flatMap((section, sIndex) => {
      const sectionItem: ContentItem = {
        uuid: section.uuid,
        type: "section",
        sectionIndex: sIndex,
        data: section,
      };
      if (sIndex === 0 || sIndex === sections.length - 1) {
        return [sectionItem];
      }
      const pagesItems = section.pages.flatMap((page, pIndex) => {
        const childrenItems: ContentItem[] = [];
        if (section.pages.length > 1 || !page.hideInBuilder) {
          childrenItems.push({
            uuid: page.uuid,
            type: "page",
            pageIndex: pIndex,
            sectionIndex: sIndex,
            data: page,
          });
        }

        for (const [childIndex, child] of page.children.entries()) {
          childrenItems.push({
            uuid: child.uuid,
            type: "child",
            childIndex,
            sectionIndex: sIndex,
            pageIndex: pIndex,
            data: child,
          });
        }

        return childrenItems;
      });

      return [sectionItem, ...pagesItems];
    });
  }, [sections]);

  return contentItems;
}
