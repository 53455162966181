import React, { useState, useCallback } from "react";
import { useToggle } from "react-use";

import InteractiveButton from "components/InteractiveButton";
import TextInput from "components/TextInput";
import { pdfDoc, paragraph, user, userPortal, calendar } from "uiAssets/StrokeIcons";
import Acta from "utils/Acta";
import { analytics } from "services";
import { buildRequest } from "utils/buildRequest";

import styles from "./AskEstimateModalStyles.module.scss";
import { BookingRoutes } from "@skillup/espace-rh-bridge";

interface Props {
  readonly organization: {
    name: string;
    uuid: string;
  };
  readonly properties: {
    name: string;
    seoSlug: string;
    uuid: string;
  };
}

const AskEstimateModal = ({ organization, properties }: Props) => {
  const userData = Acta.getState("userData");
  const [loading, toggleLoading] = useToggle(false);

  const [message, setMessage] = useState<string>();
  const [participants, setParticipants] = useState<string>();
  const [location, setLocation] = useState<string>();
  const [period, setPeriod] = useState<string>();

  const onSubmit = useCallback(
    async (e): Promise<void> => {
      e.preventDefault();

      const payload = {
        message,
        participants,
        location,
        period,
        trainingName: properties.name,
        trainingOrganization: organization.name,
        trainingOrganizationUuid: organization.uuid,
        trainingSeoSlug: properties.seoSlug,
        trainingUuid: properties.uuid,
      };

      toggleLoading(true);

      try {
        // This route need to be a company-admin
        await buildRequest<BookingRoutes.CreateEstimate>({
          method: "POST",
          path: "/booking/ask-estimate",
          payload,
        })();

        analytics.sendConversion("quickInfo");

        setTimeout(() => {
          Acta.dispatchEvent("closeModal");

          Acta.dispatchEvent("sendAppMessage", {
            message:
              "Nous avons bien reçu votre demande. Vous allez être recontacté très prochainement pour donner suite à votre demande.",
            type: "success",
          });
        }, 500);
      } catch (error) {
        if (error.statusCode === 429) {
          Acta.dispatchEvent("sendAppMessage", {
            message:
              "Trop de demandes simultanées. Veuillez patienter un instant et essayer à nouveau de soumettre votre demande.",
            type: "error",
          });
        } else {
          Acta.dispatchEvent("sendAppMessage", {
            message: "Une erreur est survenue ; si elle persiste, veuillez contacter le support.",
            type: "error",
          });
        }

        toggleLoading(false);
      }
    },
    [toggleLoading, message, participants, location, period, properties, organization]
  );

  return (
    <div className={styles.AskEstimateModal}>
      <form onSubmit={onSubmit}>
        <p>{`Demander un devis en tant que ${userData.email}`}</p>

        <TextInput
          label="Nombre de participants"
          type="textarea"
          required
          onChange={(_, value) => setParticipants(value)}
          strokeIcon={user}
        />

        <TextInput
          label="Date/période souhaitée"
          type="textarea"
          required
          onChange={(_, value) => setPeriod(value)}
          strokeIcon={calendar}
        />

        <TextInput
          label="Lieu"
          type="textarea"
          required
          onChange={(_, value) => setLocation(value)}
          strokeIcon={userPortal}
        />

        <TextInput
          label="Message"
          type="textarea"
          required
          placeholder="À l’attention de vos destinaires."
          onChange={(_, value) => setMessage(value)}
          strokeIcon={paragraph}
        />

        <InteractiveButton
          hasTransitionIn
          label="Demander un devis"
          loading={loading}
          strokeIconLeft={pdfDoc}
          style={{ marginTop: 40 }}
          type="submit"
        />
      </form>
    </div>
  );
};

export default AskEstimateModal;
