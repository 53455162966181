import { TemplatesRoutes } from "@skillup/espace-rh-bridge";

import downloadTypedFileAsUser from "utils/downloadTypedFileAsUser";


export async function downloadTemplatePDF(templateUuid) {
  await downloadTypedFileAsUser<TemplatesRoutes.GetPdf>(
    {
      method: "GET",
      path: "/templates/{templateUuid}/pdf",
      params: { templateUuid },
    },
    {
      target: "API",
      deduceFileDataFromResponseHeaders: true,
    }
  );
}
