import { Label as DSLabel } from "@skillup/ui";
import { Flex, FormikInputText } from "@skillup/design-system";

import { SkillFields } from "types/skills";

interface CustomFieldProps {
  index: number;
  field: SkillFields;
}

export default function CustomField(props: CustomFieldProps) {
  const { field, index } = props;

  return (
    <Flex flexDirection="column">
      <DSLabel label={field.name} />
      <FormikInputText
        placeholder={"..."}
        value={field.value ?? ""}
        name={`fields.${index}.value`}
      />
    </Flex>
  );
}
