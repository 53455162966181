import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { orderBy, isEqual, isEmpty, isNumber } from "lodash";
import { useMutation, useQuery } from "@tanstack/react-query";

import { Duration, ListUtils } from "@skillup/shared-utils";

import { buildRequest } from "utils/buildRequest";

import {
  ActiveIndicator,
  DashboardFreshnessData,
  IndicatorConfig,
  GetDashboardConfigRouteType,
  GetIndicatorDataRouteType,
  GetFeedingFreshnessRouteType,
  GetIndicatorConfigRouteType,
  GetFeedingRefreshRouteType,
  GetIndicatorFiltersRouteType,
  IndicatorData,
  ComputedIndicatorData,
} from "./types";
import { FilterProp, useFilters } from "@skillup/ui";

const EMPTY_GROUP_LABEL = "N/A";

export interface Context {
  filters: FilterProp<any>;
  filterConfig: ListUtils.FilterConfigurationMap;
  filterValues: ListUtils.FilterValues<any>;
  filtersQuery: string;
  updateFilters?: (filters: ListUtils.FilterValues<any>) => void;
  activeIndicators: ActiveIndicator[];
  updateActiveIndicators?: (indicators: ActiveIndicator[]) => void;
  updateIndicator?: (
    name: string,
    panel: string | undefined,
    config: IndicatorConfig["attributes"]
  ) => void;
}

const DashboardDataContext = createContext<Context>({
  filters: {},
  filterConfig: {},
  filterValues: {},
  activeIndicators: [],
  filtersQuery: "",
});

const FilterValuesCacheMap: Map<
  string,
  Promise<GetIndicatorFiltersRouteType["response"]>
> = new Map();

export const DashboardDataContextProvider = (props: { children: React.ReactNode }) => {
  const [{ config: filterConfig = {}, props: filterProps = {} }, setFilterConfigAndProps] =
    useState<ListUtils.FilterConfigurationMap>({});
  const [filters, filterValues, setFilterValues] = useFilters(filterConfig, filterProps);
  const [activeIndicators, setActiveIndicators] = useState([]);

  const filtersQuery = useMemo(() => {
    // Transform filters into api queries (filter[gender]=male)
    const queryStringParams: string[] = [];
    Object.keys(filterValues).forEach((filterId) => {
      const filterValue = filterValues[filterId];

      if (filterValue?.value) {
        const values = Array.isArray(filterValue.value) ? filterValue.value : [filterValue.value];
        values.forEach((value) => {
          queryStringParams.push(`filter[${filterId}]=${encodeURIComponent(value)}`);
        });
      }
    });
    if (!isEmpty(queryStringParams)) {
      return "?" + queryStringParams.join("&");
    }
    return "";
  }, [filterValues]);

  useEffect(() => {
    const configAndProps = activeIndicators.reduce(
      (acc, curr) => {
        if (curr.config) {
          for (const filter of curr.config.filters) {
            if (curr.config.value_bindings[filter] && curr.config.label_bindings[filter]) {
              const values = curr.config.value_bindings[filter];

              acc.props[filter] = {
                label: curr.config.label_bindings[filter],
                options: Object.keys(values ?? {}).map((key) => ({
                  label: values[key],
                  placeholder: "",
                  value: key,
                })),
              };
              acc.config[filter] = {
                type:
                  values !== undefined
                    ? ListUtils.FilterType.MULTISELECT
                    : ListUtils.FilterType.TEXT,
              };
            }
          }
        }
        return acc;
      },
      { config: {}, props: {} }
    );

    setFilterConfigAndProps(configAndProps);
  }, [activeIndicators]);

  const handleUpdateIndicator = (name, panel, config) => {
    if (!config) return;
    let found = false;
    let newIndicators = activeIndicators.map((indicator) => {
      if (indicator.name === name) {
        found = true;
        return {
          ...indicator,
          config,
        };
      }
      return indicator;
    });
    if (!found && panel) {
      newIndicators = activeIndicators.map((indicator) => {
        if (indicator.panel === panel) {
          found = true;
          return {
            ...indicator,
            name,
            config,
          };
        }
        return indicator;
      });
    }
    setActiveIndicators(newIndicators);
  };

  const handleUpdateFilters = (newFilters) => {
    // check deep equality to avoid multiple rerender
    if (!isEqual(filters, newFilters)) {
      setFilterValues(newFilters);
    }
  };

  return (
    <DashboardDataContext.Provider
      value={{
        filters,
        filterConfig,
        filterValues,
        filtersQuery,
        updateFilters: handleUpdateFilters,
        activeIndicators,
        updateActiveIndicators: setActiveIndicators,
        updateIndicator: handleUpdateIndicator,
      }}
    >
      {props.children}
    </DashboardDataContext.Provider>
  );
};

export function useDashboardContext() {
  const context = useContext(DashboardDataContext);

  if (context === undefined) {
    throw new Error("useDashboardContext must be used within a DashboardDataContext");
  }

  return context;
}

async function getDashboardSettings() {
  const response = await buildRequest<GetDashboardConfigRouteType>({
    method: "GET",
    path: "/dashboard/config",
    target: "INSIGHTS_SERVICE",
  })();

  const panels = Object.keys(response.data?.[0].attributes.panels);
  const indicators = orderBy(
    panels.map((panel) => {
      const panelConfig = response.data?.[0].attributes.panels[panel];
      return {
        name: panelConfig.indicators[0].name,
        size: panelConfig.width === 0.5 ? "small" : "large",
        order: panelConfig.order,
        panel: { name: panel, label: panelConfig.label },
        indicators: panelConfig.indicators,
      };
    }),
    "order"
  );

  return indicators;
}

async function getDashboardFreshnessData(): Promise<Date> {
  const response = await buildRequest<GetFeedingFreshnessRouteType>({
    method: "GET",
    path: "/feeding-pipeline/freshness",
    target: "INSIGHTS_SERVICE",
  })();

  return new Date(response.data[0].attributes.last_successful_run_datetime);
}

function transformData(
  data: string | number,
  column: IndicatorData["attributes"]["columns"][0],
  value_bindings: IndicatorConfig["attributes"]["value_bindings"]
): { raw: any; number?: number; formatted: string | number } {
  switch (column.type) {
    case "duration":
      const asHours = Duration.formatDurationAsQuantity(data as string);
      return {
        raw: data,
        number: asHours,
        formatted: isNumber(asHours) ? Math.round(asHours).toLocaleString() : EMPTY_GROUP_LABEL,
      };
    case "number":
      return {
        raw: data,
        number: data as number,
        formatted: isNumber(data) ? Math.round(data as number).toLocaleString() : EMPTY_GROUP_LABEL,
      };
    case "integer":
      return {
        raw: data,
        number: data as number,
        formatted: isNumber(data) ? Math.round(data as number).toString() : EMPTY_GROUP_LABEL,
      };
    // TODO: add monatory
    default:
      if (value_bindings && value_bindings[column.name]) {
        return { raw: data, formatted: value_bindings[column.name][data] ?? data };
      }

      return { raw: data, formatted: data };
  }
}

async function getDashboardIndicatorConfig(name: string): Promise<IndicatorConfig["attributes"]> {
  const response = await buildRequest<GetIndicatorConfigRouteType>({
    method: "GET",
    path: `/indicator/{name}/config`,
    params: { name: encodeURI(name) },
    target: "INSIGHTS_SERVICE",
  })();

  const config = response.data[0].attributes;
  // retreive filters values for custom fields
  for (const filter of config.filters) {
    if (config.label_bindings[filter] && !config.value_bindings[filter]) {
      // cache queries to the same filter
      let filterValuesPromise = FilterValuesCacheMap.get(filter);
      if (!FilterValuesCacheMap.has(filter)) {
        filterValuesPromise = buildRequest<GetIndicatorFiltersRouteType>({
          method: "GET",
          path: `/indicator/{name}/filter_values/{filter}`,
          params: { name: encodeURI(name), filter },
          target: "INSIGHTS_SERVICE",
        })();
        FilterValuesCacheMap.set(filter, filterValuesPromise);
      }
      const filterValues = await filterValuesPromise;
      config.value_bindings[filter] = filterValues.data[0].attributes.data.reduce((acc, curr) => {
        const value = curr[0];
        // @ts-expect-error string index
        acc[value] = value;
        return acc;
      }, {});
    }
  }

  return config;
}

async function getDashboardIndicatorData(
  config: IndicatorConfig["attributes"],
  filtersQuery: string
): Promise<ComputedIndicatorData> {
  const response = await buildRequest<GetIndicatorDataRouteType>({
    method: "GET",
    path: `/indicator/{name}/data{filtersQuery?}`,
    target: "INSIGHTS_SERVICE",
    params: { name: encodeURI(config.name), filtersQuery },
  })();

  const { columns, data } = response.data[0].attributes;
  return {
    columns: columns?.map((c) => config.label_bindings[c.name] ?? c.name),
    data: data?.map((item) =>
      item?.map((element, index) => transformData(element, columns[index], config.value_bindings))
    ),
  };
}

export function useDashboardSettings() {
  const { activeIndicators, updateActiveIndicators } = useDashboardContext();
  const {
    data: layout,
    isFetching,
    isError,
    refetch: refetchDashboardSettings,
  } = useQuery(["training-dashboard-settings"], getDashboardSettings, {
    refetchOnWindowFocus: false,
  });
  const {
    data: freshnessDate,
    isFetching: isFetchingFreshness,
    refetch: refetchFreshness,
  } = useQuery(["training-dashboard-freshness-data"], getDashboardFreshnessData, {
    refetchOnWindowFocus: false,
  });
  const [isWaitingRefresh, setIsWaitingRefresh] = useState(false);

  const { mutate, isLoading: isFetchingRefresh } = useMutation<{
    data: DashboardFreshnessData[];
    meta: object | null;
    included: object | null;
  }>({
    mutationFn: async () => {
      const response = await buildRequest<GetFeedingRefreshRouteType>({
        method: "GET",
        path: "/feeding-pipeline/refresh",
        target: "INSIGHTS_SERVICE",
      })();
      return response;
    },
    onSuccess(response) {
      const refreshData = response.data[0].attributes;
      const waitTime =
        Duration.formatDurationAsSeconds(refreshData.last_percentile_run_duration) * 1.15;
      setIsWaitingRefresh(true);
      setTimeout(() => {
        refetchDashboardSettings();
        refetchFreshness();
        setIsWaitingRefresh(false);
      }, Number(waitTime * 1000));
    },
  });

  useEffect(() => {
    if (!isEmpty(layout)) {
      const newActiveIndicators = layout.map((item) => ({
        name: item.name,
        panel: item.panel?.name,
        config: activeIndicators.find((indicator) => indicator.name === item.name)?.config,
      }));
      updateActiveIndicators(newActiveIndicators);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout]);

  return {
    lastUpdate: freshnessDate,
    isFetching: isFetching || isFetchingFreshness,
    isWaitingRefresh: isWaitingRefresh || isFetchingRefresh,
    isError: isError,
    reload: () => mutate(),
    layout,
  };
}

export function useDashboardIndicatorConfig(name: string) {
  const { data, isFetching, isError } = useQuery(
    ["training-dashboard-indicator-config", name],
    ({ queryKey }) => getDashboardIndicatorConfig(queryKey[1]),
    { refetchOnWindowFocus: false }
  );

  return { data, isFetching, isError };
}

export function useDashboardIndicatorData(
  config: IndicatorConfig["attributes"],
  filtersQuery = ""
) {
  const { data, isFetching, isError } = useQuery(
    ["training-dashboard-indicator-data", config.name, filtersQuery],
    () => getDashboardIndicatorData(config, filtersQuery),
    { refetchOnWindowFocus: false }
  );

  return { data, isFetching, isError };
}
