import DataLayer from "utils/DataLayer";
import Acta from "utils/Acta";

interface IUser {
  email: string;
  firstName?: string;
  lastName?: string;
  managerEmail?: string;
  role?: string;
}

export interface Props {
  target: { uuid: string; type: "inter" | "intra" };
  users: IUser[];
  scheduleUuid: string;
  type: "plan" | "collection";
  onError?: Function;
}

export default async ({ target, users, scheduleUuid, type, onError }: Props): Promise<string[]> => {
  try {
    const result = await DataLayer.request({
      body: JSON.stringify({ target, users, addTo: type }),
      method: "POST",
      url: `/v1/scheduleRow/add-row-to-plan/${scheduleUuid}`,
    });

    Acta.dispatchEvent("closeModal");
    Acta.dispatchEvent("sendAppMessage", {
      message: "Ligne(s) ajoutée(s) au plan avec succès",
      type: "success",
    });

    return result["rows"];
  } catch (err) {
    if (onError) onError();

    Acta.dispatchEvent("sendAppMessage", {
      message: "Erreur lors de l'ajout de ligne(s) au plan",
      type: "error",
    });
  }

  return [];
};
