import { useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";

import classNames from "classnames";

import { Flex, Grid, Text, Loader } from "@skillup/design-system";
import { DSButton, DSTooltip, DSDropdown, MaterialIcons, DSDropdownItem } from "@skillup/ui";

import { EnumerableField } from "services/coreHR";

import { SupervisionContext } from "../../contexts";
import { useMatrixData, type MatrixData } from "./useMatrixData";
import { DrawerState } from "../../pages/CampaignSupervision/CampaignSupervision";

import styles from "./TalentMatrix.module.scss";

type ArrowLeftProps = {
  label: string;
  fields: EnumerableField[];
  hasMoreThanTwoFields: boolean;
  currentFields: EnumerableField[];
  onChangeField: (field: EnumerableField) => void;
};

const ArrowLeft = ({
  currentFields,
  fields,
  hasMoreThanTwoFields,
  label,
  onChangeField,
}: ArrowLeftProps) => (
  <Flex className={classNames(styles.arrows, styles.arrowLeft)}>
    <Flex flex={1} className={classNames(styles.arrowBodys, styles.arrowBody)} />
    <span className={styles.arrowEnd}></span>
    <Flex gap="xs" className={classNames(styles.labels, styles.label)}>
      <Text espaceFont="body2Bold" color="plainText-onLight-lighter">
        {label}
      </Text>

      {hasMoreThanTwoFields && (
        <DSDropdown
          buttonSize="S"
          overflow="initial"
          button={<DSButton iconOnly type="button" buttonSize="S" icon={<MaterialIcons.Edit />} />}
        >
          {fields?.map((field) => (
            <DSDropdownItem
              label={field?.label}
              disabled={currentFields.includes(field)}
              onClick={() => onChangeField(field)}
            />
          ))}
        </DSDropdown>
      )}
    </Flex>
  </Flex>
);

type YAxisProps = {
  label: string;
  scaleLabels: string[];
  allFields: EnumerableField[];
  selectedFields: EnumerableField[];
  onAxisLabelClick: (field: EnumerableField) => void;
};

const YAxis = ({ allFields, label, onAxisLabelClick, scaleLabels, selectedFields }: YAxisProps) => (
  <>
    <Flex className={classNames(styles.axis, styles.yAxis)}>
      <ArrowLeft
        label={label}
        fields={allFields}
        currentFields={selectedFields}
        hasMoreThanTwoFields={allFields.length > 2}
        onChangeField={(field) => onAxisLabelClick(field)}
      />
    </Flex>

    {scaleLabels && (
      <Flex alignItems="center" className={styles.yScale} justifyContent="space-evenly">
        {scaleLabels.map((label) => (
          <div key={label} className={styles.yScaleLabelContainer}>
            <p className={styles.yScaleLabel}>{label}</p>
          </div>
        ))}
      </Flex>
    )}
  </>
);

type ArrowBottomProps = {
  label: string;
  fields: EnumerableField[];
  hasMoreThanTwoFields: boolean;
  currentFields: EnumerableField[];
  onChangeField: (field: EnumerableField) => void;
};

const ArrowBottom = ({
  currentFields,
  fields,
  hasMoreThanTwoFields,
  label,
  onChangeField,
}: ArrowBottomProps) => (
  <Flex className={classNames(styles.arrows, styles.arrowBottom)}>
    <Flex flex={1} className={classNames(styles.arrowBodys, styles.arrowBody)} />
    <span className={styles.arrowEnd}></span>
    <Flex gap="xs" className={classNames(styles.labels, styles.label)}>
      <Text espaceFont="body2Bold" color="plainText-onLight-lighter">
        {label}
      </Text>{" "}
      {hasMoreThanTwoFields && (
        <DSDropdown
          buttonSize="S"
          overflow="initial"
          position="up-right"
          button={<DSButton iconOnly type="button" buttonSize="S" icon={<MaterialIcons.Edit />} />}
        >
          {fields.map((field) => (
            <DSDropdownItem
              label={field?.label}
              disabled={currentFields.includes(field)}
              onClick={() => onChangeField(field)}
            />
          ))}
        </DSDropdown>
      )}
    </Flex>
  </Flex>
);

type XAxisProps = {
  label: string;
  scaleLabels: string[];
  allFields: EnumerableField[];
  selectedFields: EnumerableField[];
  onAxisLabelClick: (field: EnumerableField) => void;
};

const XAxis = ({ allFields, label, onAxisLabelClick, scaleLabels, selectedFields }: XAxisProps) => (
  <>
    <Flex className={classNames(styles.axis, styles.xAxis)}>
      <ArrowBottom
        label={label}
        fields={allFields}
        currentFields={selectedFields}
        hasMoreThanTwoFields={allFields.length > 2}
        onChangeField={(field) => onAxisLabelClick(field)}
      />
    </Flex>

    {scaleLabels && (
      <Flex alignItems="center" className={styles.xScale} justifyContent="space-evenly">
        {scaleLabels?.map((label) => (
          <div key={label} className={styles.xScaleLabelContainer}>
            <p className={styles.xScaleLabel}>{label}</p>
          </div>
        ))}
      </Flex>
    )}
  </>
);

type SwapButtonProps = {
  onSwap: () => void;
};

const SwapButton = ({ onSwap }: SwapButtonProps) => (
  <Flex alignItems="center" justifyContent="center" className={styles.axisSwap}>
    <DSTooltip direction="top" label="Inverser les axes">
      <DSButton
        iconOnly
        type="button"
        buttonSize="M"
        icon={<MaterialIcons.SwapHorizIcon />}
        onClick={onSwap}
      />
    </DSTooltip>
  </Flex>
);

type UseXParams = {
  data: MatrixData;
};

const useX = ({ data }: UseXParams) => {
  const nBoxWidth = data?.xAxis.scale.options.length;
  const xAxisLabel = data?.xAxis.fieldLabel;
  const xScaleLabels = data?.xAxis.scale.options.map((option) => option?.label);

  return { nBoxWidth, xAxisLabel, xScaleLabels };
};

type UseYParams = {
  data: MatrixData;
};

const useY = ({ data }: UseYParams) => {
  const nBoxHeight = data?.yAxis.scale.options.length;
  const yAxisLabel = data?.yAxis.fieldLabel;
  const yScaleLabels = data?.yAxis.scale.options
    .sort((a, b) => b.value - a.value)
    .map((option) => option?.label);

  return { nBoxHeight, yAxisLabel, yScaleLabels };
};

type TalentMatrixProps = {
  drawerState: DrawerState;
  openDrawer: (params: DrawerState) => void;
};

export const TalentMatrix = ({ drawerState, openDrawer }: TalentMatrixProps) => {
  const { t } = useTranslation();

  const { campaign, enumerableFieldsMap } = useContext(SupervisionContext);

  const {
    data,
    isLoading,
    mutations,
    xAxis: selectedXAxis,
    yAxis: selectedYAxis,
  } = useMatrixData({
    campaign: campaign,
    enumerableFieldsMap,
  });

  const fields = useMemo(() => {
    return campaign.fields.map((field) => enumerableFieldsMap[field]);
  }, [campaign.fields, enumerableFieldsMap]);

  const cells = data?.matrix.flat() ?? [];

  const { nBoxWidth, xAxisLabel, xScaleLabels } = useX({ data });
  const { nBoxHeight, yAxisLabel, yScaleLabels } = useY({ data });

  const positionedCollabsCount = useMemo(
    () => campaign.reviews.length - (data?.notPositionned?.reviews?.length ?? 0),
    [campaign.reviews, data]
  );
  const notPositionedCollabsCount = useMemo(
    () => data?.notPositionned?.reviews?.length ?? 0,
    [data]
  );

  const textColor: Record<
    MatrixData["notPositionned"]["heatMapStatus"],
    "plainText-onDark-default" | "plainText-onLight-default"
  > = useMemo(() => {
    return {
      "over-median": "plainText-onDark-default",
      p10: "plainText-onLight-default",
      p90: "plainText-onDark-default",
      "under-median": "plainText-onLight-default",
    };
  }, []);

  return (
    <Flex gap="s" width="100%" flexDirection="column">
      <Flex flexDirection="column">
        <Text espaceFont="body1Bold" color="plainText-onLight-default">
          {positionedCollabsCount > 1
            ? t("peopleReview.talentMatrix.positionedCollabsCount", {
                count: positionedCollabsCount,
                defaultValue: `{{count}} collaborateurs positionnés`,
              })
            : t("peopleReview.talentMatrix.positionedCollabCount", {
                count: positionedCollabsCount,
                defaultValue: `{{count}} collaborateur positionné`,
              })}
        </Text>
        <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
          {t("peopleReview.talentMatrix.positionedCollabsDetailsPart1", {
            defaultValue: `Seuls les collaborateurs ayant été évalués sur ${xAxisLabel} et ${yAxisLabel} sont positionnés dans la matrice ci-dessous.`,
          })}
        </Text>
        <Text espaceFont="captionRegular" color="plainText-onLight-lighter">
          {t("peopleReview.talentMatrix.positionedCollabsDetailsPart2", {
            defaultValue: `Cliquez sur une case pour afficher la liste des collaborateurs qu’elle contient.`,
          })}
        </Text>
      </Flex>

      <div className={styles.container}>
        {selectedYAxis && (
          <YAxis
            allFields={fields}
            scaleLabels={yScaleLabels}
            label={selectedYAxis?.label}
            selectedFields={[selectedXAxis, selectedYAxis]}
            onAxisLabelClick={(field) =>
              mutations.swap({
                newXAxisFieldID: selectedXAxis.uuid,
                newYAxisFieldID: field.uuid,
              })
            }
          />
        )}

        {selectedXAxis && (
          <XAxis
            allFields={fields}
            scaleLabels={xScaleLabels}
            label={selectedXAxis?.label}
            selectedFields={[selectedXAxis, selectedYAxis]}
            onAxisLabelClick={(field) =>
              mutations.swap({
                newXAxisFieldID: field.uuid,
                newYAxisFieldID: selectedYAxis.uuid,
              })
            }
          />
        )}

        <SwapButton
          onSwap={() => {
            const oldX = selectedXAxis;

            mutations.swap({
              newXAxisFieldID: selectedYAxis.uuid,
              newYAxisFieldID: oldX.uuid,
            });
          }}
        />

        {isLoading && (
          <Flex alignItems="center" justifyContent="center" className={styles.loader}>
            <Loader fillSpace />
          </Flex>
        )}
        {!isLoading && (
          <Grid
            className={styles.matrix}
            gridTemplateRows={[nBoxHeight, 1]}
            gridTemplateColumns={{
              columnNumbers: nBoxWidth,
              columnWidth: "1fr",
            }}
          >
            {cells &&
              cells.map((cell, i) => {
                const userInCellCount = cell.reviews.length;
                return (
                  <Flex
                    key={i}
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    onClick={() =>
                      openDrawer({
                        type: "n-box-cell-selected",
                        collaborators: cell.reviews,
                        xAxis: {
                          optionLabel: selectedXAxis.scale.options.find(
                            (o) => o.value === cell.xValue
                          )?.label,
                          optionValue: cell.xValue,
                          scaleLabel: selectedXAxis.label,
                        },
                        yAxis: {
                          optionLabel: selectedYAxis.scale.options.find(
                            (o) => o.value === cell.yValue
                          )?.label,
                          optionValue: cell.yValue,
                          scaleLabel: selectedYAxis.label,
                        },
                      })
                    }
                    className={classNames(styles.matrixCell, {
                      [styles.noSelected]:
                        (drawerState.type === "n-box-cell-selected" &&
                          (cell.xValue !== drawerState.xAxis.optionValue ||
                            cell.yValue !== drawerState.yAxis.optionValue)) ||
                        drawerState.type === "n-box-not-positionned-cell-selected",
                      [styles.overMedian]: cell.heatMapStatus === "over-median",
                      [styles.p10]: cell.heatMapStatus === "p10",
                      [styles.p90]: cell.heatMapStatus === "p90",
                      [styles.selected]:
                        drawerState.type === "n-box-cell-selected" &&
                        cell.xValue === drawerState.xAxis.optionValue &&
                        cell.yValue === drawerState.yAxis.optionValue,
                      [styles.underMedian]: cell.heatMapStatus === "under-median",
                    })}
                  >
                    <DSTooltip
                      direction="right"
                      label={`${(cell.fraction ?? 0).toFixed(2)} %`}
                      darkMode={
                        cell.heatMapStatus === "p90" || cell.heatMapStatus === "over-median"
                      }
                    >
                      <Text espaceFont="h2" color={textColor[cell.heatMapStatus]}>
                        {Math.round(cell.fraction ?? 0)} %
                      </Text>
                    </DSTooltip>

                    <Text espaceFont="captionBold" color={textColor[cell.heatMapStatus]}>
                      {userInCellCount > 1
                        ? t("peopleReview.talentMatrix.positionedCollabsDetails", {
                            count: userInCellCount,
                            defaultValue: "{{count}} collaborateurs",
                          })
                        : t("peopleReview.talentMatrix.positionedCollabDetails", {
                            count: userInCellCount,
                            defaultValue: "{{count}} collaborateur",
                          })}
                    </Text>
                  </Flex>
                );
              })}
          </Grid>
        )}
      </div>

      <Flex gap="xxs" flexDirection="column">
        <Text espaceFont="body1Bold" color="plainText-onLight-default">
          {notPositionedCollabsCount > 1
            ? t("people.talentMatrix.notPositionnedCollabs", {
                count: notPositionedCollabsCount,
                defaultValue: "{{count}} collaborateurs non positionnés",
              })
            : t("people.talentMatrix.notPositionnedCollab", {
                count: notPositionedCollabsCount,
                defaultValue: "{{count}} collaborateur non positionné",
              })}
        </Text>
        <Flex
          gap="xxs"
          height="80px"
          width="200px"
          flexDirection="column"
          onClick={() =>
            openDrawer({
              type: "n-box-not-positionned-cell-selected",
              collaborators: data?.notPositionned.reviews,
            })
          }
          className={classNames(styles.matrixCell, {
            [styles.noSelected]: drawerState.type === "n-box-cell-selected",
            [styles.overMean]: data?.notPositionned.heatMapStatus === "over-median",
            [styles.p10]: data?.notPositionned.heatMapStatus === "p10",
            [styles.p90]: data?.notPositionned.heatMapStatus === "p90",
            [styles.selected]: drawerState.type === "n-box-not-positionned-cell-selected",
            [styles.underMean]: data?.notPositionned.heatMapStatus === "under-median",
          })}
        >
          {isLoading && <Loader fillSpace />}
          {!isLoading && (
            <>
              <DSTooltip
                direction="right"
                label={`${(data?.notPositionned.fraction ?? 0).toFixed(2)} %`}
                darkMode={
                  data?.notPositionned.heatMapStatus === "p90" ||
                  data?.notPositionned.heatMapStatus === "over-median"
                }
              >
                <Text espaceFont="h2" color={textColor[data?.notPositionned.heatMapStatus]}>
                  {Math.round(data?.notPositionned.fraction ?? 0)} %
                </Text>
              </DSTooltip>
              <Text espaceFont="captionBold" color={textColor[data?.notPositionned.heatMapStatus]}>
                {notPositionedCollabsCount > 1
                  ? t("people.talentMatrix.notPositionnedCollabsShort", {
                      count: notPositionedCollabsCount ?? 0,
                      defaultValue: "{{count}} collaborateurs",
                    })
                  : t("people.talentMatrix.notPositionnedCollabShort", {
                      count: notPositionedCollabsCount ?? 0,
                      defaultValue: "{{count}} collaborateur",
                    })}
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};
