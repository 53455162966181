import React, { useState } from "react";
import { Button } from "@skillup/ui";

import { useUnmanagedBookingContext } from "../../context";

import styles from "./Warnings.module.scss";

export default () => {
  const [unmanagedBookingContext, setUnmanagedBookingContext] = useUnmanagedBookingContext();

  function renderWarnings() {
    let warnings = [];
    const hasEndDateAfterTenDays = unmanagedBookingContext.warnings.find(
      ({ key }) => key === "end-date-is-after-ten-days"
    );
    const hasEndDateBeforeStartDate = unmanagedBookingContext.warnings.find(
      ({ key }) => key === "end-date-before-start-date"
    );
    const hasExcedeedEndDate = unmanagedBookingContext.warnings.find(
      ({ key }) => key === "excedeed-end-date"
    );
    const hasInvalidEndDate = unmanagedBookingContext.warnings.find(
      ({ key }) => key === "invalid-end-date"
    );
    const hasInvalidStartDate = unmanagedBookingContext.warnings.find(
      ({ key }) => key === "invalid-start-date"
    );
    const hasInvalidEvals = unmanagedBookingContext.warnings.find(
      ({ key }) => key === "invalid-eval"
    );
    const hasInvalidConfirmation = unmanagedBookingContext.warnings.find(
      ({ key }) => key === "invalid-confirmation"
    );
    const hasInvalidEmails = hasInvalidEvals?.value || hasInvalidConfirmation?.value;

    if (hasInvalidEndDate) {
      warnings.push(
        <div key="invalid-end-date">
          <p>Vous n'avez pas saisi de date de fin de formation: </p>
          <ul>
            {!unmanagedBookingContext.addedManually && (
              <li>Le questionaire d'évaluation ne sera pas envoyé</li>
            )}
            {unmanagedBookingContext.tab !== "plan-processing" && (
              <li>
                La ligne restera au statut "Inscrit" de votre plan. Une action manuelle sera
                nécessaire pour la passer au statut "Réalisé".
              </li>
            )}
            {!hasInvalidStartDate && <li>La ligne restera au statut "Compléter".</li>}
          </ul>
        </div>
      );
    }

    if (hasInvalidStartDate) {
      warnings.push(
        <div key="invalid-start-date">
          <p>Vous n'avez pas saisi de date de début de formation: </p>
          <ul>
            <li>La ligne restera au statut "Compléter".</li>
          </ul>
        </div>
      );
    }

    if (hasExcedeedEndDate || hasEndDateAfterTenDays || hasEndDateBeforeStartDate) {
      warnings.push(
        <>
          {hasEndDateAfterTenDays && (
            <div key="date-excedeed-ten-days">
              <p>La date de fin de formation est dépassée de 10 jours: </p>
              <ul>
                <li>Le questionaire d'évaluation ne sera pas envoyé</li>
              </ul>
            </div>
          )}
          {hasExcedeedEndDate && (
            <div key="date-excedeed">
              La date de fin de formation saisie est dépassée:
              <ul>
                <li>La ligne passera au statut "Réalisé" de votre plan</li>
              </ul>
            </div>
          )}
          {hasEndDateBeforeStartDate && (
            <div key="end-date-before-start-date">
              La date de fin saisie est antérieure à la date de début
            </div>
          )}
        </>
      );
    }

    if (hasInvalidEmails?.length) {
      const renderInvalidEmail = ({ fullName }, index) => {
        if (index === 0) {
          return <b>{fullName}</b>;
        }
        if (index === hasInvalidEmails.length) {
          return (
            <>
              {" "}
              et <b>{fullName}</b>
            </>
          );
        }
        return (
          <>
            , <b>{fullName}</b>
          </>
        );
      };

      warnings.push(
        <div key="invalid-emails">
          <p>
            {hasInvalidEmails.map(renderInvalidEmail)} ne possède
            {hasInvalidEmails.length > 1 ? "nt" : ""} pas d'adresse e-mail:
          </p>
          <ul>
            {hasInvalidEvals && <li>Le questionnaire d'évaluation ne sera pas envoyé</li>}
            {hasInvalidConfirmation && (
              <li>l'e-mail de confirmation d'inscription ne sera pas envoyé</li>
            )}
          </ul>
        </div>
      );
    }

    return warnings;
  }

  const [submitting, setSubmitting] = useState(false);

  function book() {
    setSubmitting(true);
    if (unmanagedBookingContext.tab === "plan-approved") {
      unmanagedBookingContext.book(unmanagedBookingContext.form, unmanagedBookingContext.reload);
    } else {
      unmanagedBookingContext.update(unmanagedBookingContext.form, unmanagedBookingContext.reload);
    }
  }

  const warnings = renderWarnings();
  const label =
    unmanagedBookingContext.tab === "plan-approved" ? "Inscrire hors Skillup" : "Modifier";

  return (
    <div className={styles.Warnings}>
      {warnings}
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => setUnmanagedBookingContext({ activeStep: "off-bounds-positioning-form" })}
          label="Retour"
        />
        <Button onClick={book} label={label} readOnly={submitting} />
      </div>
    </div>
  );
};
