const cities = [];

const fixtures = [
  { label: "Bordeaux", location: { lat: 44.8637834, lng: -0.6211603 } },
  { label: "Lille", location: { lat: 50.6311167, lng: 3.0121412 } },
  { label: "Lyon", location: { lat: 45.7580101, lng: 4.8001017 } },
  { label: "Marseille", location: { lat: 43.2804941, lng: 5.3350994 } },
  { label: "Montpellier", location: { lat: 43.6100788, lng: 3.8391422 } },
  { label: "Nantes", location: { lat: 47.2383172, lng: -1.6307597 } },
  { label: "Nice", location: { lat: 43.7031195, lng: 7.217915 } },
  { label: "Paris", location: { lat: 48.8589507, lng: 2.2775176 } },
  { label: "Rennes", location: { lat: 48.1159674, lng: -1.7234738 } },
  { label: "Strasbourg", location: { lat: 48.5692058, lng: 7.6920546 } },
  { label: "Toulouse", location: { lat: 43.6008029, lng: 1.3628014 } },
];

const sortOptions = {
  date_asc: "Date Croissante",
  price_asc: "Prix Croissant",
  price_desc: "Prix Décroissant",
  relevance: "Pertinence",
  review_count: "Nombre d’avis",
  review_score: "Note",
};

const possibleFilters = [
  "place",
  "sessionMonth",
  "sessionType",
  "types",
  "isCPF",
  "language",
  "mode",
  "certification",
  "durationGroup",
  "priceGroup",
  "organization",
  "entities",
];

export default {
  cities,
  fixtures,
  possibleFilters,
  sortOptions,
};
