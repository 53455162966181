import React from "react";

import { Themes } from "commons";
import { useTheme } from "context";

import { HelpIcon, StyledTooltip } from "./TooltipContainer.styled";

export interface TooltipContainerProps {
  readonly label: string;
  readonly darkMode: boolean;
  readonly tooltipDirection?: "top" | "left" | "right" | "bottom";
}
const TooltipContainer = ({ darkMode = false, label, tooltipDirection }: TooltipContainerProps) => {
  const theme = useTheme();

  return (
    <StyledTooltip
      theme={theme}
      label={label}
      darkMode={darkMode}
      direction={tooltipDirection ?? "top"}
      isRHTheme={theme === Themes.ESPACE_RH}
    >
      <HelpIcon size="1rem" darkMode={darkMode} />
    </StyledTooltip>
  );
};

export default TooltipContainer;
