import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useRecoilValue } from "recoil";
import { TextDropdown, TextArea, Toggle, TextInput } from "@skillup/ui";

import { templateState } from "../store";
import { Child } from "../types";

import styles from "./Child.module.scss";
import DisallowBlock from "./DisallowBlock";

import { InterviewRoles } from "types/Interview";

type Targets = Child & { kind: "targets" };
const TargetsBlock = ({
  data,
  updateChild,
}: {
  data: Targets;
  updateChild: (newChildData: Child) => void;
}) => {
  const animatedComponents = makeAnimated();
  const { targetsCategories } = useRecoilValue(templateState);
  const types = [
    { value: "current", label: "À évaluer" },
    { value: "next", label: "Futurs" },
  ];
  const scales = [
    { value: "numeric", label: "Quantitatifs" },
    { value: "ordinal", label: "Qualitatifs" },
    { value: "both", label: "Les deux" },
  ];
  return (
    <form>
      <label>Type</label>
      <TextDropdown
        options={types}
        small
        selectedIndex={types.findIndex((type) => type.value === data.type)}
        onSelect={({ value }) => updateChild({ ...data, type: value as Targets["type"] })}
      />
      <label>Afficher les objectifs des catégories: (Si aucune sélection: affiche tout) </label>
      <Select<{ value: string }, true>
        isMulti
        name="displayCategories"
        options={(targetsCategories ?? []).map((value) => ({ value, label: value }))}
        components={animatedComponents as any}
        className={styles.select}
        value={(data.displayCategories ?? []).map((value) => ({ value, label: value }))}
        onChange={(values) =>
          updateChild({
            ...data,
            displayCategories: values.map((c) => c.value),
          })
        }
      />
      <label>Créer des objectifs dans les catégories: (Si aucune sélection: 'default')</label>
      <Select<{ value: string }, true>
        isMulti
        name="creationCategories"
        options={(targetsCategories ?? []).map((value) => ({ value, label: value }))}
        components={animatedComponents as any}
        className={styles.select}
        value={(data.creationCategories ?? []).map((value) => ({ value, label: value }))}
        onChange={(values) =>
          updateChild({
            ...data,
            creationCategories: values.map((c) => c.value),
          })
        }
      />
      <label>Cacher la période</label>
      <Toggle
        className={styles.toggle}
        active={data.hidePeriod}
        onToggle={(hidePeriod) => updateChild({ ...data, hidePeriod })}
      />
      <label>Cacher la pondération</label>
      <Toggle
        className={styles.toggle}
        active={data.hideWeight}
        onToggle={(hideWeight) => updateChild({ ...data, hideWeight })}
      />
      <label>Cacher le détail de la sélection d'objectif Quantitatif (default %)</label>
      <Toggle
        className={styles.toggle}
        active={data.disableGoalSelection}
        onToggle={(disableGoalSelection) => updateChild({ ...data, disableGoalSelection })}
      />
      <label>N'utiliser que les objectifs de type</label>
      <TextDropdown
        small
        options={scales}
        selectedIndex={
          data.restrictScaleTo == null
            ? 2
            : scales.findIndex((scale) => scale.value === data.restrictScaleTo)
        }
        onSelect={(selection) => {
          if (selection.value === "both") {
            updateChild({ ...data, restrictScaleTo: undefined });
          } else {
            updateChild({
              ...data,
              restrictScaleTo: selection.value as Targets["restrictScaleTo"],
            });
          }
        }}
      />
      <label>Placeholder nom de l'objectif (optionnel)</label>
      <TextInput
        key={`target-name-placeholder-${data.uuid}`}
        aria-label="target-name-placeholder"
        value={data.targetNamePlaceholder}
        onChange={(targetNamePlaceholder) => updateChild({ ...data, targetNamePlaceholder })}
      />
      <label>Placeholder descriptif de l'objectif (optionnel)</label>
      <TextInput
        key={`target-description-placeholder-${data.uuid}`}
        aria-label="target-description-placeholder"
        value={data.targetDescriptionPlaceholder}
        onChange={(targetDescriptionPlaceholder) =>
          updateChild({ ...data, targetDescriptionPlaceholder })
        }
      />
      <label>Description du bloc objectif (optionnel)</label>
      <TextArea
        key={`description-${data.uuid}`}
        value={data.description}
        onChange={(description) => updateChild({ ...data, description })}
      />
      {/* <label>Contenu</label>
    <TextArea value={data.content} onChange={content => updateChild({ ...data, content })} /> */}
      <DisallowBlock
        restrictions={[
          // Commentaire Child entier
          { action: "comment", role: InterviewRoles.EMPLOYEE, label: "Commentaire employé" },
          { action: "comment", role: InterviewRoles.MANAGER, label: "Commentaire manager" },
          // Création des objectifs
          {
            action: "creation",
            role: InterviewRoles.EMPLOYEE,
            label: "Création des objectifs par l'employé",
          },
          {
            action: "creation",
            role: InterviewRoles.MANAGER,
            label: "Création des objectifs par le manager",
          },
          // Commentaire objectif par objectif
          {
            action: "commentTarget",
            role: InterviewRoles.EMPLOYEE,
            label: "Commentaire des objectifs par l'employé",
          },
          {
            action: "commentTarget",
            role: InterviewRoles.MANAGER,
            label: "Commentaire des objectifs par le manager",
          },
          // Evaluation de l'objectif
          {
            action: "evaluation",
            role: InterviewRoles.EMPLOYEE,
            label: "Evaluation des objectifs par l'employé",
          },
          {
            action: "evaluation",
            role: InterviewRoles.MANAGER,
            label: "Evaluation des objectifs par le manager",
          },
          // Edition des objectifs
          {
            action: "edition",
            role: InterviewRoles.EMPLOYEE,
            label: "Edition des objectifs par l'employé",
          },
          {
            action: "edition",
            role: InterviewRoles.MANAGER,
            label: "Edition des objectifs par le manager",
          },
          // Suppression des objectifs
          {
            action: "deletion",
            role: InterviewRoles.EMPLOYEE,
            label: "Suppression des objectifs par l'employé",
          },
          {
            action: "deletion",
            role: InterviewRoles.MANAGER,
            label: "Suppression des objectifs par le manager",
          },
        ]}
        data={data}
        updateChild={updateChild}
      />
    </form>
  );
};

export default TargetsBlock;
