export enum Scopes {
  Admin = "admin",
  Trainer = "trainer",
  TrainerWithoutIntras = "trainer-without-intras",
  TrainerQHSE = "trainer-qhse",
  AdminWithoutIntras = "admin-without-intras",
  Manager = "manager",
  Developer = "developer",
  InterviewManager = "interview-manager",
  Custom = "custom",
}

export type ScopeType = `${Scopes}`;
