import { useCallback, useState } from "react";
import { useHistory } from "react-router";

import { DSModal, RadioForm, Label as DSLabel, DSTooltip } from "@skillup/ui";

import type { Template } from "services/interviews";

import styles from "./ChooseCampaignTypeModal.module.scss";

interface IProps {
  readonly template: Template;
  isOpen: boolean;
  closeModal: () => void;
}

const ChooseCampaignTypeModal = ({ template, isOpen, closeModal }: IProps) => {
  const [active, setActive] = useState<any>();
  const history = useHistory();

  const handleChange = useCallback(
    (value: any) => {
      setActive(value);
    },
    [setActive]
  );

  const handleSubmit = useCallback(() => {
    if (active === "legacy")
      history.push(`/responsable/campagne/creation-campagne-entretiens?template=${template.uuid}`);
    if (active === "ongoing")
      history.push(
        `/responsable/campagne/creation-entretiens-automatises?template=${template.uuid}`
      );
    if (active === "adHoc")
      history.push(`/responsable/campagne/creation-entretiens-ponctuels?template=${template.uuid}`);
  }, [active, history, template]);

  return (
    <DSModal isOpen={isOpen} className={styles.ChooseCampaignType}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title title="Nouvelle campagne" />
      </DSModal.Header>

      <DSModal.Content>
        <DSLabel label={"Sélectionnez le type de campagne"} required className={styles.label} />
        <RadioForm
          choices={options}
          active={active}
          onChange={handleChange}
          className={styles.radioform}
        />
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={closeModal} label="Annuler" />
        <DSTooltip
          label={
            "Avant de poursuivre, vous devez choisir le type de campagne que vous souhaitez créer."
          }
          disabled={!!active}
        >
          <DSModal.Footer.PrimaryButton label="Suivant" onClick={handleSubmit} disabled={!active} />
        </DSTooltip>
      </DSModal.Footer>
    </DSModal>
  );
};

export { ChooseCampaignTypeModal };

const options = [
  {
    label: "Campagne d'entretiens",
    value: "legacy",
    helpText:
      "Créer une campagne d’entretiens définie par une date de lancement et une date limite de signature",
  },
  {
    label: "Entretiens automatisés",
    value: "ongoing",
    helpText: "Paramétrer des entretiens qui se génèrent automatiquement à une date donnée",
  },
  {
    label: "Entretiens ponctuels",
    value: "adHoc",
    helpText: "Créer des entretiens manuellement pour répondre à des besoins ponctuels",
  },
];
