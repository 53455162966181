import { DSList } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";

import history from "utils/history";
import { isSkillupDeveloper } from "utils/User";
import { type Scale, useCoreHRScales } from "services/coreHR";

/**
 * This page displays a list of scales, and allows to create & soon to edit them.
 * It is only accessible to Skillup developers for now since it's in development.
 * Scales are used to define the possible values for a given CoreHR field.
 * Some scales are hard-linked to certain fields (like performance) and not re-usable for other fields
 * in order to prevent mistakes on edition (e.g. changing the a scale that affects both perf & potential would be dangerous)
 * Other scales will be re-usable for multiple fields.
 */
export function ScalesManagement() {
  const isDeveloper = isSkillupDeveloper();
  const { isError, isLoading, scales } = useCoreHRScales();

  if (!isDeveloper) {
    history.push("/responsable");
    return null;
  }

  if (isError) {
    return <ErrorPanel />;
  }

  return <div>{isLoading ? <Loading /> : <List scales={scales} />}</div>;
}

function ErrorPanel() {
  return <div>Une erreur est survenue</div>;
}

function Loading() {
  return <div>Chargement...</div>;
}

function List({ scales }: { scales: Scale[] }) {
  return (
    <Flex padding="l" flexDirection="column">
      {scales.map((scale) => (
        <Flex padding="m" flexWrap="wrap" flexDirection="column">
          <Text fontSize="1rem" espaceFont="body2Bold">
            {scale.label}
          </Text>
          <DSList title="Options">
            {scale.options.map((option, index) => (
              <DSList.Item>
                <Flex>
                  <Text paddingLeft="l" espaceFont="body2Bold">
                    Label:
                  </Text>
                  <Text paddingLeft="m" textAlign="end" espaceFont="body2Regular">
                    {option.label}
                  </Text>
                </Flex>
                <Flex>
                  <Text paddingLeft="l" espaceFont="body2Bold">
                    Code client:{" "}
                  </Text>
                  <Text paddingLeft="m" textAlign="end" espaceFont="body2Regular">
                    {option.value}
                  </Text>
                </Flex>
                <Flex>
                  <Text paddingLeft="l" espaceFont="body2Bold">
                    Activée:{" "}
                  </Text>
                  <Text paddingLeft="m" textAlign="end" espaceFont="body2Regular">
                    {option.isActive ? "Oui" : "Non"}
                  </Text>
                </Flex>
              </DSList.Item>
            ))}
          </DSList>
        </Flex>
      ))}
    </Flex>
  );
}
