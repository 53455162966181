import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import {
  DSModal,
  DSAlert,
  DSAlertDisplay,
  DSAlertType,
  DSFormGroupTextInput,
  DSTextInput,
} from "@skillup/ui";

import DataLayer from "utils/DataLayer";

import styles from "../Components/PostponeModal.module.scss";

interface Props {
  rows: string[];
  rowsAreManuallyAdded: boolean;
  rowsManagedBySkillup: boolean;
  type: string;
  hasReceivedNotification: boolean;
  onDone: () => void;
  onBack: () => void;
  onClose: (needRefresh?: boolean) => void;
}

export default function PostponeModal({
  rows,
  rowsAreManuallyAdded,
  rowsManagedBySkillup,
  type,
  hasReceivedNotification,
  onDone,
  onBack,
  onClose,
}: Props) {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string>();

  const handlePostponeSubmit = useCallback(async (): Promise<void> => {
    setLoading(true);

    try {
      await DataLayer.request({
        body: JSON.stringify({ rows, comment: message }),
        method: "POST",
        url: `/v1/scheduleRow/postpone`,
      });

      addToast(
        t("trainings.schedules.postpone.success", {
          defaultValue: "Demande reportée avec succès",
        }),
        {
          appearance: "success",
        }
      );

      if (rows.length === 1 && !rowsAreManuallyAdded) {
        if (rowsManagedBySkillup) {
          onDone();
        } else if (type === "Intra") {
          onDone();
        } else {
          onClose(true);
        }
      } else {
        onClose(true);
      }
    } catch (error) {
      addToast(t("trainings.schedules.postpone.error", { defaultValue: "La demande a échoué" }), {
        appearance: "error",
      });
    } finally {
      setLoading(false);
    }
  }, [
    rows,
    message,
    rowsAreManuallyAdded,
    rowsManagedBySkillup,
    type,
    onDone,
    onClose,
    addToast,
    t,
  ]);

  return (
    <DSModal isOpen className={styles.PostponeModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.schedules.postpone.title", {
            count: rows.length,
            defaultValue: "Reporter la demande",
          })}
        />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <p>
          {t("trainings.schedules.postpone.description", {
            defaultValue:
              'Si vous confirmez cette action, la demande repassera dans l\'onglet "À inscrire". Vous pourrez ensuite réinscrire le stagiaire à une nouvelle session ou requalifier la demande.',
          })}
        </p>
        {hasReceivedNotification && (
          <DSAlert display={DSAlertDisplay.INLINE} type={DSAlertType.INFO}>
            {t("trainings.schedules.postpone.alert.info", {
              defaultValue:
                "Les collaborateurs recevront une notification.\nSi une invitation agenda avait été incluse dans la convocation initiale, elle sera supprimée.",
            })}
          </DSAlert>
        )}
        {!hasReceivedNotification && (
          <DSAlert display={DSAlertDisplay.INLINE} type={DSAlertType.WARNING}>
            {t("trainings.schedules.postpone.alert.withoutnotifciations.info", {
              defaultValue: "Les collaborateurs ne seront pas notifiés du report",
            })}
          </DSAlert>
        )}
        <form onSubmit={(e) => e.preventDefault()}>
          <DSFormGroupTextInput
            label={t("trainings.schedules.postpone.comment.label", {
              defaultValue: "Commentaire (facultatif)",
            })}
            assistiveText={t("trainings.schedules.postpone.comment.assistiveText", {
              defaultValue:
                "Le commentaire ci-dessus ne sera pas transmis au collaborateur mais figurera dans l'activité de la ligne",
            })}
          >
            <DSTextInput
              autoFocus={true}
              placeholder={t("trainings.schedules.postpone.comment.placeholder", {
                defaultValue: "Laissez un commentaire dans votre activité",
              })}
              onChange={(value) => setMessage(value)}
              name="message"
            />
          </DSFormGroupTextInput>
        </form>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          disabled={loading}
          onClick={onBack}
          label={t("app.modal.action.back", {
            defaultValue: "Retour",
          })}
        />
        <DSModal.Footer.PrimaryButton
          loading={loading}
          onClick={handlePostponeSubmit}
          label={t("trainings.schedules.postpone.confirm", {
            defaultValue: "Confirmer le report",
          })}
        />
      </DSModal.Footer>
    </DSModal>
  );
}
