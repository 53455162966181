import React from "react";

import { Avatar as DSAvatar, type DSAvatarProps } from "components/Avatar";

import styles from "./DSSidePanel.module.scss";
import { Loader } from "components/Loader";
import { Button as DSButton, type DSButtonProps } from "components/Button";
import { Flex } from "components/Flex";

function LastUpdateAvatar(props: DSAvatarProps) {
  return <DSAvatar className={styles.lastUpdatedAvatar} {...props} size="S" />;
}

function LastUpdateText({ fullName, updatedAt }: { fullName: string; updatedAt: Date }) {
  return (
    <span className={styles.lastUpdatedText}>
      Dernière modification le {updatedAt.toLocaleDateString()} par {fullName}
    </span>
  );
}

interface BasicSidePanelFooterProps {
  loading?: boolean;
  errors?: boolean;
  children: React.ReactNode;
}

export function EmptySidePanelFooter() {
  return <></>;
}

export function LoadingSidePanelFooter() {
  return (
    <footer>
      <Loader />
    </footer>
  );
}

export function ActionableSidePanelFooter(props: {
  mainAction: Pick<
    DSButtonProps,
    "aria-label" | "disabled" | "loading" | "actionIcon" | "iconOnly" | "icon"
  > &
    Required<{
      onClick: DSButtonProps["onClick"];
      label: string;
    }>;
  cancelAction: Pick<DSButtonProps, "aria-label"> &
    Required<{
      onClick: DSButtonProps["onClick"];
      label: string;
    }>;
  optionAction?: Pick<
    DSButtonProps,
    "aria-label" | "disabled" | "loading" | "actionIcon" | "iconOnly" | "icon"
  > &
    Required<{
      onClick: DSButtonProps["onClick"];
      label: string;
    }>;
  hidden?: boolean;
}) {
  if (props.hidden) {
    return null;
  }
  return (
    <footer>
      <Flex style={{ justifyContent: "flex-end" }}>
        <DSButton {...props.cancelAction} emphasis="Low" buttonSize="S" />
        {props.optionAction && (
          <DSButton
            {...props.optionAction}
            emphasis="Mid"
            buttonSize="S"
            style={{ marginLeft: "0.5rem" }}
          />
        )}
        <DSButton
          {...props.mainAction}
          emphasis="High"
          buttonSize="S"
          style={{ marginLeft: "0.5rem" }}
        />
      </Flex>
    </footer>
  );
}

export function ErrorSidePanelFooter() {
  return <footer>Could not load user</footer>;
}

export function BasicSidePanelFooter({ loading, errors, children }: BasicSidePanelFooterProps) {
  if (errors) return <ErrorSidePanelFooter />;
  if (loading) return <LoadingSidePanelFooter />;
  return <footer>{children}</footer>;
}

export type BasicSidePanelFooterType = React.ReactElement<typeof BasicSidePanelFooter>;
export type EmptySidePanelFooterType = React.ReactElement<typeof EmptySidePanelFooter>;
export type ErrorSidePanelFooterType = React.ReactElement<typeof EmptySidePanelFooter>;
export type ActionableSidePanelFooterType = React.ReactElement<typeof ActionableSidePanelFooter>;

export type FooterType =
  | BasicSidePanelFooterType
  | EmptySidePanelFooterType
  | ActionableSidePanelFooterType;

export const SidePanelFooter = Object.assign(BasicSidePanelFooter, {
  LastUpdateAvatar,
  LastUpdateText,
});
