import styled from "styled-components";
import { DSColor, EspaceFont, SkuiSpacing } from "commons";

import { Flex } from "../../styled";

export const StyledFooter = styled(Flex)`
  ${EspaceFont.rh.captionRegular};
  background-color: ${DSColor["surface-light"]};
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  max-height: 7rem;
  width: 100%;
`;

export const FooterContent = styled(Flex)<{ padding?: string; containerWidth?: string }>`
  width: 100%;
  height: 100%;
  align-items: center;
  padding: ${({ padding }) => padding ?? `${SkuiSpacing.s} ${SkuiSpacing.l}`};
  justify-content: ${({ containerWidth }) => (containerWidth ? "center" : "space-between")};
`;

export const StyledButton = styled.button`
  ${EspaceFont.rh.body1Regular};
  padding: ${SkuiSpacing.xxs} ${SkuiSpacing.s};
  border-radius: 4px;
  border: none;
`;

export const PrimaryButton = styled(StyledButton)<{ small?: boolean }>`
  background-color: ${DSColor["action-onLight-darker"]};
  color: ${DSColor["plainText-onDark-default"]};
  ${({ small }) => small && `font-size: 12px`};
`;

export const SecondaryButton = styled(StyledButton)<{ small?: boolean }>`
  color: ${DSColor["action-onLight-darkest"]};
  background-color: transparent;
  margin-right: ${({ small }) => (small ? SkuiSpacing.m : SkuiSpacing.l)};
  ${({ small }) => small && `font-size: 12px`};
`;
