import React from "react";
import styles from "./PortalCategoriesStyles.module.scss";

export interface CategoryInterface {
  name: string;
  uuid: string;
  background: string;
  trainingCount?: number;
}

export interface PortalCategoriesLazyRenderedListPropsInterface {
  allCategories: CategoryInterface[];
  addCategory: Function;
}

export interface PortalCategoriesLazyRenderedListStateInterface {
  allCategories?: CategoryInterface[];
  displayedCategories: CategoryInterface[];
  startIndex: number;
}

export default class PortalCategoriesLazyRenderedList extends React.PureComponent<
  PortalCategoriesLazyRenderedListPropsInterface,
  PortalCategoriesLazyRenderedListStateInterface
> {
  public state: PortalCategoriesLazyRenderedListStateInterface = {
    displayedCategories: [],
    startIndex: 0,
  };

  static getDerivedStateFromProps(
    props: PortalCategoriesLazyRenderedListPropsInterface,
    state
  ): PortalCategoriesLazyRenderedListStateInterface | null {
    if (state.allCategories !== props.allCategories) {
      return {
        allCategories: props.allCategories,
        displayedCategories: props.allCategories.slice(0, 30),
        startIndex: 0,
      };
    }

    return null;
  }

  private processScroll = (event: React.UIEvent<HTMLDivElement>): void => {
    const { allCategories } = this.props;
    const startIndex: number = Math.max(0, Math.floor(event.currentTarget.scrollTop / 30) - 10);
    this.setState({
      displayedCategories: allCategories.slice(startIndex, startIndex + 30),
      startIndex,
    });
  };

  private onClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    const target = event.target as HTMLDivElement;
    this.props.addCategory(
      this.props.allCategories.find((category: CategoryInterface) => category.uuid === target.id)
    );
  };

  public render(): JSX.Element {
    const { allCategories } = this.props;
    const { displayedCategories, startIndex } = this.state;

    return (
      <div className={styles.lazyRenderedList} onScroll={this.processScroll}>
        <div
          className={styles.scrollContainer}
          style={{ height: allCategories.length * 30 + 4 }}
          onClick={this.onClick}
        >
          {(displayedCategories || []).map((category: CategoryInterface, index: number) => (
            <div
              key={category.uuid}
              style={{
                transform: `translateY( ${(startIndex + index) * 30 + 2}px )`,
              }}
              id={category.uuid}
            >
              {category.name}
              <div>ajouter</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
