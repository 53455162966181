import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export async function getCampaigns() {
  try {
    const result = await buildRequest<CampaignsRoutes.GetAll>({
      method: "GET",
      path: `/campaigns`,
      target: "PEOPLE_REVIEW",
    })();

    return result;
  } catch (err) {
    return [];
  }
}
