import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Radar, Switch } from "@skillup/ui";
import { computeRadarData } from "@skillup/shared-utils";
import { Flex, Text, Title } from "@skillup/design-system";

import {
  Container,
  WarningIcon,
  AverageLegend,
  ExpectedLegend,
  RadarContainer,
} from "./RadarList.styled";

type RadarListProps = {
  totalSkills: number;
  categories: Array<{
    categoryName: string;
    skills: Array<{
      uuid: string;
      name: string;
      level: number;
      href?: string;
      levelName: string;
      expectedLevel?: number;
      levelDescription?: string;
      expectedLevelName?: string;
      expectedLevelDescription?: string;
      totalLevel: Array<{ level: number }>;
      levelRange: { min: number; max: number };
    }>;
  }>;
};

const RadarList = ({ categories, totalSkills }: RadarListProps) => {
  const { t } = useTranslation();
  const [isSingleRadar, setSingleRadar] = useState(false);

  const concatenatedCategories = useMemo(() => {
    if (isSingleRadar) {
      const newCategories = categories.reduce(
        (acc, category) => {
          acc.categoryName = t("collaborator.skills.allCategories", {
            defaultValue: "Toutes les catégories",
          });
          acc.skills.push(...category.skills);
          return acc;
        },
        {
          categoryName: "",
          skills: [],
        }
      );
      return [newCategories];
    } else {
      return categories;
    }
  }, [isSingleRadar, categories, t]);

  const radar = useMemo(() => {
    return concatenatedCategories?.map((category) => {
      const { skills } = category;

      const { enhancedSkills, gridLevels, keys } = computeRadarData(skills);

      let margin = { bottom: 130, left: 0, right: 0, top: 70 };

      if (skills.length > 12) {
        margin = { bottom: 60, left: 0, right: 0, top: 30 };
      }

      return (
        <RadarContainer key={category.categoryName}>
          <Text espaceFont="h6" color="plainText-onLight-lighter">
            {category.categoryName}
          </Text>
          <Radar
            keys={keys}
            margin={margin}
            data={enhancedSkills}
            gridLevels={gridLevels}
            displayLevelBelowExpectation={true}
          />
        </RadarContainer>
      );
    });
  }, [concatenatedCategories]);

  const numberOfSkillsBelowExpected = categories.reduce((acc, category) => {
    return (
      acc +
      category.skills.reduce((acc, skill) => {
        return skill.expectedLevel && skill.level < skill.expectedLevel ? acc + 1 : acc;
      }, 0)
    );
  }, 0);

  const hasAtLeastOneExpectedValue = categories.some((category) => {
    return category.skills.some((skill) => skill.expectedLevel);
  });

  return (
    <Container>
      <Flex alignItems="center" justifyContent="space-between">
        <Title h4 color="plainText-onLight-lighter">
          {t("skills.dashboard.evaluationAverageBySkill", {
            defaultValue: "Moyenne des évaluations par compétence",
          })}
        </Title>
        {categories.length > 1 && (
          <Flex paddingVertical="xs">
            <Switch
              active={isSingleRadar}
              onToggle={setSingleRadar}
              label={t("collaborator.skills.groupAllSkills", {
                defaultValue: "Afficher toutes les compétences sur un seul radar",
              })}
            />
          </Flex>
        )}
      </Flex>
      {numberOfSkillsBelowExpected !== 0 && (
        <Flex marginTop="s" alignItems="center">
          <WarningIcon />
          <Text marginLeft="xxs" espaceFont="captionRegular" color="plainText-onLight-default">
            {t("skills.dashboard.averageBelowExpected", {
              defaultValue:
                "{{skillNumber}} des compétences évaluées en moyenne sous le niveau attendu",
              skillNumber: `${numberOfSkillsBelowExpected}/${totalSkills}`,
            })}
          </Text>
        </Flex>
      )}
      <Flex flex={1} flexDirection="column">
        <Flex marginVertical="s">
          <Flex marginRight="s" alignItems="center">
            <AverageLegend />
            <Text espaceFont="captionRegular">
              {t("skills.dashboard.evaluationAverage", {
                defaultValue: "Moyenne des évaluations",
              })}
            </Text>
          </Flex>

          {hasAtLeastOneExpectedValue && (
            <Flex alignItems="center">
              <ExpectedLegend />
              <Text espaceFont="captionRegular">
                {t("collaborator.skills.expectedLevel", {
                  defaultValue: "Niveau de maîtrise attendu",
                })}
              </Text>
            </Flex>
          )}
        </Flex>
        {radar}
      </Flex>
    </Container>
  );
};

export default RadarList;
