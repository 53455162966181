import { css } from "styled-components";

import { Themes } from "./theme";

const commonStyles = {
  body1Bold: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
  },
  body1Link: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.5rem",
    textDecoration: "underline",
  },
  body1Regular: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: "1.5rem",
  },
  body1Italic: {
    fontWeight: 400,
    lineHeight: "1.5rem",
    fontSize: "1rem",
    fontStyle: "italic",
  },
  body2Bold: {
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: "1.3125rem",
  },
  body2Link: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.3125rem",
    textDecoration: "underline",
  },
  body2Regular: {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "1.3125rem",
  },
  captionBold: {
    fontSize: "0.75rem",
    fontWeight: 600,
    lineHeight: "1.125rem",
  },
  captionLink: {
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: "1.125rem",
    textDecoration: "underline",
  },
  captionRegular: {
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: "1.125rem",
  },
  h1: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: "3rem",
  },
  h2: {
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: "2.625rem",
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: 600,
    lineHeight: "2.25rem",
  },
  h4: {
    fontSize: "1.25rem",
    fontWeight: 600,
    lineHeight: "1.875rem",
  },
  h5: {
    fontSize: "1.125rem",
    fontWeight: 600,
    lineHeight: "1.6875rem",
  },
  h6: {
    fontSize: "1rem",
    fontWeight: 600,
    lineHeight: "1.5rem",
  },
};

const commonStylesWithFont = (fontFamily: string) => {
  const newStyles = { ...commonStyles };
  for (const style in commonStyles) {
    newStyles[style] = { ...commonStyles[style], fontFamily };
  }

  return newStyles;
};

export const FontFamily = {
  collab: "DM Sans, Helvetica Neue, sans-serif",
  rh: "Noto Sans Display, Helvetica Neue, sans-serif",
};

export const EspaceFont = {
  collab: commonStylesWithFont(FontFamily.collab),
  rh: commonStylesWithFont(FontFamily.rh),
};

export const getEspaceFont = (theme: Themes, selector?: keyof typeof EspaceFont.rh) => {
  if (selector) {
    return css(EspaceFont[theme === Themes.ESPACE_RH ? "rh" : "collab"][selector]);
  }
  return css(EspaceFont[theme === Themes.ESPACE_RH ? "rh" : "collab"]);
};
