import { ListUtils } from "@skillup/shared-utils";

export const Filters: ListUtils.FilterConfigurationMap = {
  reviewStatus: {
    type: ListUtils.FilterType.MULTISELECT,
  },
  traineeName: {
    type: ListUtils.FilterType.TEXT,
  },
  trainerName: {
    type: ListUtils.FilterType.TEXT,
  },
  trainingName: {
    type: ListUtils.FilterType.TEXT,
  },
  sessionStartDate: {
    type: ListUtils.FilterType.TEXT,
  },
  trainingOrganization: {
    type: ListUtils.FilterType.TEXT,
  },
  sessionReference: {
    type: ListUtils.FilterType.TEXT,
  },
  area: {
    type: ListUtils.FilterType.MULTISELECT,
  },
};
