import { InterviewType } from "@skillup/espace-rh-bridge";

const interviewTypes: InterviewType[] = [
  "Autre",
  "Bilan à 6 ans",
  "Entretien annuel",
  "Entretien professionnel",
];

export { interviewTypes, type InterviewType };
