import React from "react";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSModal } from "@skillup/ui";
import { Future } from "@skillup/monads";

import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";
import { OrganizationRoutes } from "@skillup/espace-rh-bridge";
import Acta from "utils/Acta";

type Props = {
  onClose: () => void;
};

const deleteIsMandatoryIfUnknowStrategy = async (companyUuid: string) => {
  const request = buildSafeRequest<OrganizationRoutes.DeleteIsMandatoryIfUnknownStrategy>({
    method: "DELETE",
    path: `/organization/{companyUuid}/auth-strategies/is-mandatory`,
    params: { companyUuid },
  });

  return request.run();
};

export default ({ onClose }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const credentials = Acta.getState("userData");

  const submitRequest = async () => {
    const request = deleteIsMandatoryIfUnknowStrategy(credentials.activeCompany.uuid);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Une erreur est survenue lors de la mise à jour de la stratégie.",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      (_authProvider) => {
        addToast(
          t("authprovider.deleteismandatoryifunknown.success", {
            defaultValue: "Stratégie rendue optionelle avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );
  };

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Rendre la stratégie optionnelle" />
      </DSModal.Header>

      <DSModal.Content>
        <label>Êtes-vous sûr de vouloir rendre optionnelle cette stratégie ?</label>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={onClose} label="Annuler" />
        <DSModal.Footer.PrimaryButton onClick={() => submitRequest()} label="Supprimer" />
      </DSModal.Footer>
    </DSModal>
  );
};
