import React from "react";
import { useSetState } from "react-use";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSModal, DSFormGroupTextInput, DSTextInput, Select, Toggle } from "@skillup/ui";
import { Future } from "@skillup/monads";

import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";
import { AuthStrategy, OrganizationRoutes, AuthStrategyType } from "@skillup/espace-rh-bridge";
import Acta from "utils/Acta";
import { isNil } from "lodash";
import { FormType } from "./CreateAuthStrategyModal";

type Props = {
  authStrategy: AuthStrategy;
  onClose: () => void;
};

const modifyAuthStrategy = async (
  companyUuid: string,
  authStrategyUuid: string,
  payload: OrganizationRoutes.UpdateAuthStrategy["payload"]
) => {
  const request = buildSafeRequest<OrganizationRoutes.UpdateAuthStrategy>({
    method: "POST",
    path: "/organization/{companyUuid}/auth-strategies/{authStrategyUuid}",
    params: { companyUuid, authStrategyUuid },
    payload,
  });

  return request.run();
};

export default ({ authStrategy, onClose }: Props) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const credentials = Acta.getState("userData");

  const [form, setState] = useSetState<FormType>({
    name: authStrategy?.name,
    type: authStrategy?.type,
    isDefaultStrategy: authStrategy?.isDefaultStrategy,
    authProvider:
      authStrategy?.type === AuthStrategyType.SAML ? authStrategy?.authProvider : undefined,
    samlRequestQueryParameters: JSON.stringify(
      authStrategy?.authSettings?.samlRequestQueryParameters
    ),
  });

  const setProperty =
    <T extends keyof FormType>(property: T) =>
    (value: FormType[T]) =>
      setState({ [property]: value });

  const submitRequest = async () => {
    if (isNil(form.isDefaultStrategy)) {
      form.isDefaultStrategy = false;
    }

    const { samlRequestQueryParameters, type, authProvider, ...rest } = form;

    const payload: OrganizationRoutes.UpdateAuthStrategy["payload"] = {
      ...rest,
      type,
      ...(type === AuthStrategyType.SAML && { authProvider }),
      ...(samlRequestQueryParameters && { authSettings: { samlRequestQueryParameters } }),
    };

    const request = modifyAuthStrategy(credentials.activeCompany.uuid, authStrategy.uuid, payload);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Une erreur est survenue lors de la modification de l'auth provider.",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      (_authProvider) => {
        addToast(
          t("authprovider.modification.success", {
            defaultValue: "Auth provider modifié avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );
  };

  const options = [
    {
      label: "OTL",
      value: AuthStrategyType.OTL,
    },
    {
      label: "SAML",
      value: AuthStrategyType.SAML,
    },
    {
      label: "PASSWORD",
      value: AuthStrategyType.PASSWORD,
    },
  ];

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Modifier l'auth provider" />
      </DSModal.Header>

      <DSModal.Content>
        <div>
          <DSFormGroupTextInput label="Nom" required>
            <DSTextInput
              name="Nom"
              onChange={setProperty("name")}
              value={form.name}
              placeholder="Nom de la stratégie de connexion"
            />
          </DSFormGroupTextInput>
          <DSFormGroupTextInput label="Type" required>
            <Select
              options={options}
              onChange={setProperty("type")}
              value={form.type}
              placeholder="Sélectionnez un type"
            />
          </DSFormGroupTextInput>

          <DSFormGroupTextInput label="Définir en tant que stratégie par défaut">
            <Toggle active={form.isDefaultStrategy} onToggle={setProperty("isDefaultStrategy")} />
          </DSFormGroupTextInput>

          {form.type === AuthStrategyType.SAML && (
            <DSFormGroupTextInput label="Auth Provider" required>
              <DSTextInput
                name="Auth Provider"
                value={form.authProvider}
                onChange={setProperty("authProvider")}
              />
            </DSFormGroupTextInput>
          )}

          <DSFormGroupTextInput label="Auth Settings">
            <DSTextInput
              name="Saml Request Query Parameters"
              value={form.samlRequestQueryParameters}
              onChange={setProperty("samlRequestQueryParameters")}
            />
          </DSFormGroupTextInput>
        </div>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton onClick={onClose} label="Annuler" />
        <DSModal.Footer.PrimaryButton onClick={() => submitRequest()} label="Ajouter" />
      </DSModal.Footer>
    </DSModal>
  );
};
