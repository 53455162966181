import type { PatchAdminDataForCompany } from "types/api";
import { Switch } from "@skillup/ui";

import styles from "./AdminPortalToggle.module.scss";

export type CompanyAdminPayload = PatchAdminDataForCompany["payload"];

export enum PortalToggleType {
  DISABLED = "disabled",
  ENABLED = "enabled",
}

export default function AdminPortalToggle({
  propertiesKey,
  label,
  isSelected,
  type,
  onChange,
  description,
  darkMode,
}: {
  propertiesKey?: keyof CompanyAdminPayload;
  label: string;
  isSelected: boolean;
  type: PortalToggleType;
  description?: string;
  onChange: (body: CompanyAdminPayload) => void;
  darkMode?: boolean;
}) {
  const onToggleChange = async (value: boolean, key?: keyof CompanyAdminPayload) => {
    if (!key) {
      onChange({});
      return;
    }
    const body: CompanyAdminPayload = {
      [key]: value,
    };

    onChange(body);
  };

  const handleToggle = (value: boolean, key?: keyof CompanyAdminPayload): void => {
    onToggleChange(value, key);
  };

  return (
    <div className={styles.AdminPortalToggle}>
      <Switch
        label={label}
        darkMode={darkMode}
        active={type === PortalToggleType.DISABLED ? !isSelected : isSelected}
        onToggle={(status) => {
          const value = type === PortalToggleType.DISABLED ? !status : status;
          handleToggle(value, propertiesKey);
        }}
      />
      {description && <span className={styles.description}>{description}</span>}
    </div>
  );
}
