import { Suspense, useState } from "react";

import { useRecoilValue } from "recoil";
import { isEmpty, orderBy } from "lodash";

import { RadioForm } from "@skillup/ui";

import Acta from "utils/Acta";
import TextInput from "components/TextInput";
import InteractiveButton from "components/InteractiveButton";
import { getSkillSelector } from "containers/Supervisor/routes/Skills/Legacy/state/skills";

import styles from "./RequiredSkillModal.module.scss";

export interface RequiredSkillModalProps {
  skillUuid: string;
  defaultExpectedLevel?: number;
  onSubmit: (skillUuid: string, expectedLevel?: number) => void;
  submitLabel: "Ajouter cette compétence" | "Enregistrer mes modifications";
}

const RequiredSkillModal = ({
  defaultExpectedLevel,
  onSubmit,
  skillUuid,
  submitLabel,
}: RequiredSkillModalProps) => {
  const { scale, skill } = useRecoilValue(getSkillSelector({ skillUuid }));
  const [expectedLevel, setExpectedLevel] = useState<number>(defaultExpectedLevel);

  return (
    <div className={styles.RequiredSkillModal} aria-label="modale-competence-requise">
      <div className={styles.blueTop} />

      <div className={styles.header}>
        <h2>{skill.label}</h2>
      </div>

      <div className={styles.content}>
        {!isEmpty(skill.description) && (
          <TextInput
            disabled
            alwaysOpen
            type="textarea"
            label="Description"
            defaultValue={skill.description}
          />
        )}
      </div>

      <div className={styles.levels}>
        <h3>Niveau de maîtrise attendu</h3>
        {scale?.length ? (
          <RadioForm
            active={expectedLevel}
            onChange={(value) => setExpectedLevel(parseInt(value))}
            choices={orderBy(scale, ["level"], ["asc"]).map((scale) => ({
              help: scale.description,
              label: scale.label,
              value: scale.level,
            }))}
          />
        ) : (
          <p>Aucun niveau défini pour cette compétence</p>
        )}
      </div>

      <div className={styles.actions}>
        <InteractiveButton
          label={submitLabel}
          aria-label="enregistrer"
          onClick={() => onSubmit(skillUuid, expectedLevel)}
        />
      </div>
    </div>
  );
};

export const showRequiredSkillModal = ({
  defaultExpectedLevel,
  onSubmit,
  skillUuid,
  submitLabel,
}: RequiredSkillModalProps) => {
  Acta.setState("modalDisplayed", {
    content: (
      <Suspense fallback={<div>Chargement...</div>}>
        <RequiredSkillModal
          skillUuid={skillUuid}
          submitLabel={submitLabel}
          defaultExpectedLevel={defaultExpectedLevel}
          onSubmit={onSubmit}
        />
      </Suspense>
    ),
    noHeader: true,
    showOverflow: true,
    size: "small",
  });
};

export default RequiredSkillModal;
