import { FeatureFlags, Settings } from "@skillup/espace-rh-bridge";

const selectOptionsEnabled = (
  companySettings: Omit<Settings, "features"> & {
    features: Array<`${FeatureFlags}`>;
  }
): {
  adminOptions: {
    value: string;
    label: string;
  }[];
  hasAdminOptions: boolean;
} => {
  const hasAdminTrainerEnabled = companySettings.features.includes("trainer");
  const hasAdminTrainerWithoutIntrasEnabled =
    companySettings.features.includes("admin-without-intras");
  const hasTrainerWithoutIntrasEnabled =
    companySettings.features.includes("trainer-without-intras");
  const hasTrainerQHSEEnabled = companySettings.features.includes("trainer-qhse");

  const adminOptions = [
    {
      value: "Admin",
      label: "Admin",
    },
  ];

  if (hasAdminTrainerEnabled) {
    adminOptions.push({ value: "Formateur", label: "Formateur" });
  }
  if (hasAdminTrainerWithoutIntrasEnabled) {
    adminOptions.push({ value: "Admin sans Programmes", label: "Admin sans Programmes" });
  }
  if (hasTrainerWithoutIntrasEnabled) {
    adminOptions.push({ value: "Formateur sans Programmes", label: "Formateur sans Programmes" });
  }
  if (hasTrainerQHSEEnabled) {
    adminOptions.push({ value: "Formateur QHSE", label: "Formateur QHSE" });
  }

  return {
    adminOptions,
    hasAdminOptions:
      hasAdminTrainerEnabled ||
      hasAdminTrainerWithoutIntrasEnabled ||
      hasTrainerWithoutIntrasEnabled ||
      hasTrainerQHSEEnabled,
  };
};

export default selectOptionsEnabled;
