import React, { useState, useMemo } from "react";
import { Select, TextArea } from "@skillup/ui";

import { Child } from "../types";
import DisallowBlock, { commentRestrictions } from "./DisallowBlock";

import { InterviewRoles } from "types/Interview";
import { useSkills } from "services/Skills/useSkills";

import styles from "./SkillsBlock.module.scss";
import List from "components/List";

type InterviewSkills = Child & { kind: "skills" };

const SkillsBlock = ({
  data,
  updateChild,
}: {
  data: InterviewSkills;
  updateChild: (newChildData: Child) => void;
}) => {
  const { data: skills } = useSkills();
  const [groupByCategoryUuid, setGroupByCategoryUuid] = useState<string>(data?.groupByCategoryUuid);
  const [categoryValuesOrder, setCategoryValuesOrder] = useState<string[]>(
    data?.categoryValuesOrder
  );

  const toList = useMemo(() => {
    if (categoryValuesOrder) {
      return categoryValuesOrder;
    }
    if (skills?.skills) {
      return [
        ...new Set(
          skills.skills.reduce<string[]>((acc, skill) => {
            if (skill.categories[groupByCategoryUuid]) {
              acc.push(skill.categories[groupByCategoryUuid]);
            }
            return acc;
          }, [])
        ),
      ].sort((a, b) => a.localeCompare(b));
    }
    return [];
  }, [skills, groupByCategoryUuid, categoryValuesOrder]);

  function updateCategory(categoryUuid: string) {
    setGroupByCategoryUuid(categoryUuid);
    const preExistingValuesOrder =
      categoryUuid === data?.groupByCategoryUuid ? data?.categoryValuesOrder : undefined;
    setCategoryValuesOrder(preExistingValuesOrder);

    updateChild({
      ...data,
      groupByCategoryUuid: categoryUuid,
      categoryValuesOrder: preExistingValuesOrder,
    });
  }

  function updateCategoryValuesOrder(values: string[]) {
    setCategoryValuesOrder(values);
    updateChild({ ...data, categoryValuesOrder: values });
  }

  const currentCategory = () => {
    const category = skills.categories.find(
      (category) => category.uuid === data.groupByCategoryUuid
    );
    if (!category) return undefined;
    return {
      label: category.label,
      value: category.uuid,
    };
  };

  return (
    <div className={styles.SkillsBlock}>
      <label>Description du bloc évaluation (optionnel)</label>
      <TextArea
        key={`description-${data.uuid}`}
        value={data.description}
        onChange={(description) => updateChild({ ...data, description })}
      />
      <DisallowBlock
        data={data}
        updateChild={updateChild}
        restrictions={[
          ...commentRestrictions,
          {
            action: "commentEvaluation",
            label: "Commentaire évaluation collab",
            role: InterviewRoles.EMPLOYEE,
          },
          {
            action: "commentEvaluation",
            label: "Commentaire évaluation manager",
            role: InterviewRoles.MANAGER,
          },
          {
            action: "evaluation",
            label: "Évaluation collab",
            role: InterviewRoles.EMPLOYEE,
          },
          {
            action: "evaluation",
            label: "Évaluation manager",
            role: InterviewRoles.MANAGER,
          },
        ]}
      />
      <>
        <h3>Tri des catégories</h3>
        {skills && (
          <Select
            className={styles.select}
            defaultValue={currentCategory()}
            noOptionsMessage="Catégorie non trouvée"
            clearable={true}
            placeholder="Sélectionnez une catégorie"
            options={
              skills.categories?.map((category) => ({
                label: category.label,
                value: category.uuid,
              })) ?? []
            }
            onChange={(categoryUuid: string) => updateCategory(categoryUuid)}
          />
        )}
        {!!toList.length && (
          <>
            <h3>Sous-catégories sélectionnées</h3>
            <div className={styles.draggable}>
              <List toList={toList} onChange={updateCategoryValuesOrder} />
            </div>
          </>
        )}
      </>
    </div>
  );
};
export default SkillsBlock;
