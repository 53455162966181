import { Either } from "@skillup/monads";
import type { TrackingRoutes } from "types/api";

import { useRouteQuery } from "hooks";
import { useMemo } from "react";
import { useRecoilState } from "recoil";
import { TrackingFilterChoices, trackingsStatusFilter, userAreasFilter } from "./filters";

export type Tracking = TrackingRoutes.Get["response"]["trackings"][0];

export const useTrackings = () => {
  const query = useRouteQuery<TrackingRoutes.Get>(["tracking"], {
    method: "GET",
    path: "/tracking",
  });

  return query;
};

export const useFilteredTrackings = () => {
  const [userAreas] = useRecoilState(userAreasFilter);
  const [status] = useRecoilState(trackingsStatusFilter);
  const areas = useMemo(() => userAreas?.join(","), [userAreas]);

  const query = useRouteQuery<TrackingRoutes.Get>(["tracking.filtered", areas], {
    method: "GET",
    path: "/tracking",
    query: {
      areas,
    },
  });

  const queryFiltered = useMemo(() => {
    if (!query.data) return query;

    return {
      ...query,
      data: {
        trackings: query.data.trackings.filter((t) => TrackingFilterChoices[status](t.status)),
      },
    };
  }, [query, status]);

  return queryFiltered;
};

export const useTrackingByUuid = (trackingUuid: string): Either<"tracking-not-found", Tracking> => {
  const trackings = useFilteredTrackings();
  const tracking = trackings.data?.trackings.find((tracking) => {
    return tracking.uuid === trackingUuid;
  });

  if (!tracking) {
    return Either.left("tracking-not-found");
  }

  return Either.right(tracking);
};
