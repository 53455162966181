import fetchFiles from "./fetchFiles";
import uploadFile from "./uploadFile";
import deleteFile from "./deleteFile";

export { fetchFiles, uploadFile, deleteFile };

export default {
  deleteFile,
  fetchFiles,
  uploadFile,
};
