import { PanelConfig, PanelConfigType, Question } from "../types";

export const getPanelType = (
  panel: PanelConfig,
  questions: Question[],
  summaryQuestion: Question,
  monthlyQuestion: Question
): PanelConfigType => {
  const card = panel.cards[0];

  if (!card) {
    console.warn("No card found in panel", panel);

    return undefined;
  }

  const question = questions.find((q) => q.name === card.question_name);

  if (question.name === summaryQuestion.name) {
    return "summary";
  }

  if (question.name === monthlyQuestion.name) {
    return "monthly";
  }

  if (question?.fields.length === 1) {
    return "key-indicators";
  }

  if (question?.fields.length === 2) {
    return "detail-indicators";
  }

  console.error("Unknown panel type", panel);

  return undefined;
};
