import { useCallback } from "react";

import type { GetConnectionSettingsResponse } from "types/api";
import { AuthStrategyType, User } from "./types";

const useAuthStrategy = (connectionSettings: GetConnectionSettingsResponse) => {
  return useCallback(
    (user: User): AuthStrategyType => {
      const defaultAuthStrategy = connectionSettings.authStrategies.find((e) => e.isDefault);
      const specificUserAuthStrategy =
        user.authStrategy &&
        connectionSettings.authStrategies.find((e) => e.name === user.authStrategy);

      return (specificUserAuthStrategy ?? defaultAuthStrategy)?.type;
    },
    [connectionSettings]
  );
};

export default useAuthStrategy;
