import { useEffect, useMemo, useState } from "react";
import { useSetState } from "react-use";
import isNil from "lodash/isNil";
import moment from "moment";
import { Button } from "@skillup/ui";
import { getMonetarySymbol } from "@skillup/shared-utils";

import useTranslation from "hooks/useTranslation";
import useSettings from "hooks/useSettings";
import User, { hasColdReviewsEnabled, hasHotReviewsEnabled } from "utils/User";
import DropDown from "components/DropDown";
import TextInput from "components/TextInput";

import { useUnmanagedBookingContext } from "../../context";
import { UnmanagedBookingContext } from "../../context/UnmanagedBookingContext";

import DatePicker from "./components/DatePicker";
import Options from "./components/Options";

import styles from "./UnmanagedBookingForm.module.scss";

const DURATION_OPTIONS = [
  { label: "Heures", value: "hours" },
  { label: "Jours", value: "days" },
] as const;

const DATE_FORMAT = "DD/MM/YYYY";

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface Options {
  canSendConfirmation?: boolean;
  canSendEval?: boolean;
  canCreateOpsFolder?: boolean;
}

export default () => {
  const { t, i18n } = useTranslation();
  const isOps = User.isSkillupAdmin();
  const settings = useSettings();
  const [unmanagedBookingContext, setUnmanagedBookingContext] = useUnmanagedBookingContext();
  const [form, setForm] = useSetState<UnmanagedBookingContext["form"]>();

  const isPlanProcessing = unmanagedBookingContext.tab === "plan-processing";
  const label = isPlanProcessing ? "Modifier" : "Inscrire";

  const currencySymbol = getMonetarySymbol({
    locale: i18n.language,
    currency: settings?.settings?.currency,
  });

  useEffect(() => {
    if (unmanagedBookingContext.form) {
      setForm(unmanagedBookingContext.form);
    }
  }, [unmanagedBookingContext.form, setForm]);

  const { addedManually } = unmanagedBookingContext;
  const canShowConfirmation =
    !addedManually &&
    !isPlanProcessing &&
    unmanagedBookingContext.users?.some((u) => isNil(u.noEmail));

  const canShowEval = () => {
    if (hasHotReviewsEnabled() || hasColdReviewsEnabled()) {
      return !addedManually && unmanagedBookingContext.users?.some((u) => isNil(u.noEmail));
    }
    return false;
  };
  const canShowUnavaibleOptions = unmanagedBookingContext.users?.every((u) => u.noEmail === true);

  const computeWarnings = (form) => {
    let warnings = [];
    const rowsWithInvalidEmails = unmanagedBookingContext.users.filter((u) => u.noEmail === true);
    const isEndDateAfterTenDays = moment(form?.sessionEndDate, DATE_FORMAT)
      .clone()
      .add(10, "days")
      .isBefore(moment());
    const isExcedeedEndDate = moment(form?.sessionEndDate, DATE_FORMAT).isBefore(moment());
    const isEndDateBeforeStartDate = moment(form?.sessionEndDate, DATE_FORMAT).isBefore(
      moment(form?.sessionStartDate, DATE_FORMAT)
    );

    if (form.canSendConfirmation && rowsWithInvalidEmails?.length) {
      warnings.push({ key: "invalid-confirmation", value: rowsWithInvalidEmails });
    }

    if (form.canSendEval && rowsWithInvalidEmails?.length) {
      warnings.push({ key: "invalid-eval", value: rowsWithInvalidEmails });
    }

    if (isPlanProcessing && !form.sessionStartDate) {
      warnings.push({ key: "invalid-start-date", value: unmanagedBookingContext.users });
    }

    if (!form.sessionEndDate) {
      warnings.push({ key: "invalid-end-date", value: unmanagedBookingContext.users });
    }

    if (isExcedeedEndDate && isPlanProcessing) {
      warnings.push({ key: "excedeed-end-date", value: unmanagedBookingContext.users });
    }

    if (isEndDateAfterTenDays && !unmanagedBookingContext.addedManually) {
      warnings.push({ key: "end-date-is-after-ten-days", value: unmanagedBookingContext.users });
    }

    if (isEndDateBeforeStartDate) {
      warnings.push({ key: "end-date-before-start-date", value: unmanagedBookingContext.users });
    }

    return warnings;
  };

  const errors = useMemo(() => {
    let errors = {};

    const isEndDateBeforeStartDate = moment(form?.sessionEndDate, DATE_FORMAT).isBefore(
      moment(form?.sessionStartDate, DATE_FORMAT)
    );

    if (isEndDateBeforeStartDate) {
      errors["datePicker"] = t("training.plan.unmanagedbookingform.date.error", {
        defaultValue:
          "La date de début de formation doit être antérieure à la date de fin de formation",
      });
    }

    return errors;
  }, [form, t]);

  const [submitting, setSubmitting] = useState(false);

  const cantSubmit = useMemo(() => {
    if (submitting) return true;
    return Object.keys(errors).length > 0;
  }, [errors, submitting]);

  async function submit(e) {
    e.preventDefault();

    if (cantSubmit) {
      return;
    }

    const warnings = computeWarnings(form);
    const formWithRows = { ...form, rows: unmanagedBookingContext.uuids };

    if (warnings?.length) {
      setUnmanagedBookingContext({ activeStep: "warnings", form: formWithRows, warnings });
      return;
    }

    setSubmitting(true);

    if (unmanagedBookingContext.tab === "plan-approved") {
      await unmanagedBookingContext.book(formWithRows, unmanagedBookingContext.reload);
    } else {
      await unmanagedBookingContext.update(formWithRows, unmanagedBookingContext.reload);
    }
  }

  return (
    <form className={styles.OffBoundsPositioningForm} onSubmit={submit}>
      <div>
        {isOps ? (
          <>
            <TextInput
              defaultValue={form.price}
              label={t("training.plan.unmanagedbookingform.pedagogicprice", {
                defaultValue: "Coût pédagogique HT ({{currencySymbol}})",
                currencySymbol: currencySymbol,
              })}
              type="number"
              min={0}
              step="any"
              alwaysOpen
              disabled
            />
            {User.isCompanyWithTax() && (
              <TextInput
                defaultValue={form.priceWithTax}
                label={t("training.plan.unmanagedbookingform.pedagogicprice.withtax", {
                  defaultValue: "Coût pédagogique TTC ({{currencySymbol}})",
                  currencySymbol: currencySymbol,
                })}
                type="number"
                min={0}
                step="any"
                alwaysOpen
                disabled
              />
            )}
          </>
        ) : undefined}
        <TextInput
          required
          onChange={(_, price) => setForm({ price: Number(price) })}
          alwaysOpen
          defaultValue={isOps ? form.trainingPrice : form.price}
          label={
            isOps
              ? t("training.plan.unmanagedbookingform.trainingprice", {
                  defaultValue: "Prix Catalogue HT ({{currencySymbol}})* :",
                  currencySymbol: currencySymbol,
                })
              : t("training.plan.unmanagedbookingform.price", {
                  defaultValue: "Prix HT ({{currencySymbol}})* :",
                  currencySymbol: currencySymbol,
                })
          }
          type="number"
          min={0}
          step="any"
        />
        {User.isCompanyWithTax() && (
          <TextInput
            required
            onChange={(_, priceWithTax) => setForm({ priceWithTax: Number(priceWithTax) })}
            alwaysOpen
            defaultValue={isOps ? form.trainingPriceWithTax : form.priceWithTax}
            label={
              isOps
                ? t("training.plan.unmanagedbookingform.trainingprice.withtax", {
                    defaultValue: "Prix Catalogue TTC ({{currencySymbol}})* :",
                    currencySymbol: currencySymbol,
                  })
                : t("training.plan.unmanagedbookingform.price.withtax", {
                    defaultValue: "Prix TTC ({{currencySymbol}})* :",
                    currencySymbol: currencySymbol,
                  })
            }
            type="number"
            min={0}
            step="any"
          />
        )}
      </div>
      <div className={styles.durationContainer}>
        <TextInput
          required
          onChange={(_, duration) => setForm({ duration: Number(duration) })}
          alwaysOpen
          defaultValue={form.duration}
          type="number"
          label={t("training.plan.unmanagedbookingform.duration", {
            defaultValue: "Durée* :",
          })}
        />
        <DropDown
          options={DURATION_OPTIONS}
          onSelect={(durationUnit) => setForm({ durationUnit })}
          background="#f3f3f3"
          defaultValue={form.durationUnit || "hours"}
          isRequired
        />
      </div>
      <DatePicker
        className={errors["datePicker"] && styles.errorForm}
        form={form}
        setForm={setForm}
      />
      <div>
        <TextInput
          required
          onChange={(_, sessionCity) => setForm({ sessionCity })}
          alwaysOpen
          defaultValue={form.sessionCity}
          label="Lieu"
          type="text"
        />
      </div>

      <Options
        showFolderOps={User.isSkillupAdmin() && !isPlanProcessing && !addedManually}
        showConfirmation={canShowConfirmation}
        showEval={canShowEval()}
        showUnavaibleOptions={canShowUnavaibleOptions}
        form={form}
        setForm={setForm}
      />
      {Object.keys(errors).length > 0 && (
        <div className={styles.errors}>
          {Object.keys(errors).map((key) => (
            <div key={key}>{errors[key]}</div>
          ))}
        </div>
      )}
      <div>
        <Button className={styles.button} type="submit" label={label} readOnly={cantSubmit} />
      </div>
    </form>
  );
};
