import { ReviewStatus } from "../../domain/Reviews/types";

export const canSendReviewRecall = (status: ReviewStatus) => {
  const validStatus = [
    ReviewStatus.SUBMITTED,
    ReviewStatus.PENDING,
    ReviewStatus.ABANDONED,
    ReviewStatus.CANCELLED,
  ] as ReviewStatus[];

  return validStatus.includes(status);
};
