import styled from "styled-components";
import { SkuiSpacing } from "@skillup/ui";
import { Label as DSLabel, DSButton } from "@skillup/ui";

export const EvaluationScaleLevel = styled.div`
  padding: ${SkuiSpacing.xs} 0;

  &:nth-child(-n + 2) {
    margin-right: ${SkuiSpacing.xxl};
  }
`;

export const Description = styled(DSLabel)`
  margin-top: ${SkuiSpacing.xxs};
`;

export const DeleteLevelButton = styled(DSButton)`
  padding: 0 ${SkuiSpacing.xs};
  width: ${SkuiSpacing.xxl};
  margin-top: ${SkuiSpacing.s};
`;
