import { useToasts } from "react-toast-notifications";
import { useToggle } from "react-use";

import { DSModal } from "@skillup/ui";
import { Text } from "@skillup/design-system";

import { trpc } from "utils/trpc";
import { Category } from "types/skills";
import useTranslation from "hooks/useTranslation";

import styles from "./SkillCategoryModal.module.scss";

interface ModalProps {
  category: Category;
  isOpen: boolean;
  closeModal: () => void;
  refetch: () => void;
}

const RemoveSkillCategoryModal = ({ category, isOpen, refetch, closeModal }: ModalProps) => {
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const [loading, toggleLoading] = useToggle(false);

  const deletionMutator = trpc.categories.removeCategory.useMutation({
    onSuccess: () => {
      toggleLoading(false);
      addToast(
        t("portal.config.skills.removeCategory.success", {
          defaultValue: "La catégorie a bien été supprimée",
        }),
        {
          appearance: "success",
        }
      );
      refetch();
    },
    onError: () => {
      toggleLoading(false);
      addToast(
        t("portal.config.skills.removeCategory.error", {
          defaultValue: "La catégorie n'a pas pu être supprimée",
        }),
        {
          appearance: "error",
        }
      );
    },
  });

  const send = () => {
    toggleLoading(true);
    deletionMutator.mutate({
      uuid: category.uuid,
      version: category.version + 1,
    });
    closeModal();
  };

  return (
    <DSModal isOpen={isOpen} className={styles.skillCategoryModal}>
      <DSModal.Header onClose={closeModal}>
        <DSModal.Header.Title
          title={t("skills.removeSkillCategoryModal.title", {
            defaultValue: "Supprimer la catégorie",
          })}
        />
      </DSModal.Header>

      <DSModal.Content>
        {category.skillsLinked > 0 && (
          <Text espaceFont="body1Regular" color="plainText-onLight-default" fontSize="1rem">
            {category.skillsLinked > 1
              ? t("skills.removeSkillCategoryModal.warnlinkToSkillMultiple", {
                  defaultValue:
                    "Si vous supprimez cette catégorie, les {{skillsLinked}} compétences qu'elle contient ne seront plus catégorisées.",
                  skillsLinked: category.skillsLinked,
                })
              : t("skills.removeSkillCategoryModal.warnlinkToSkill", {
                  defaultValue:
                    "Si vous supprimez cette catégorie, la compétence qu'elle contient ne sera plus catégorisée.",
                })}
          </Text>
        )}
        <Text espaceFont="body1Bold" color="status-error-darker" fontSize="1rem">
          Cette action est irréversible
        </Text>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          onClick={closeModal}
          label={t("skills.removeSkillCategoryModal.cancel", {
            defaultValue: "Annuler",
          })}
        />
        <DSModal.Footer.PrimaryButton
          onClick={() => {
            send();
          }}
          label={t("skills.removeSkillCategoryModal.title", {
            defaultValue: "Supprimer la catégorie",
          })}
          loading={loading}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default RemoveSkillCategoryModal;
