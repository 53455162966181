import React from "react";
import cx from "classnames";

import styles from "./Toggle.module.scss";

interface Props {
  readonly item: string;
  readonly isSelected: boolean;
  readonly onChange: (item: string, value: boolean) => void;
  readonly disabled?: boolean;
}

const Toogle = ({ item, onChange, isSelected }: Props) => (
  <div className={styles.Toggle}>
    <div>
      <input
        type="checkbox"
        id="checked"
        className={cx(styles.cbx, styles.hidden)}
        checked={isSelected}
        onChange={() => onChange(item, !isSelected)}
      />
      <label onClick={() => onChange(item, !isSelected)} className={styles.lbl} />
    </div>
  </div>
);

export default Toogle;
