import { useMemo, useState, useEffect } from "react";

import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";

import { DSButton, Modal, DSFormGroupTextInput, TextArea } from "@skillup/ui";
import { Future, Either } from "@skillup/monads";

import styles from "./CreateOrManageTrackingNote.module.scss";

import { buildSafeRequest } from "utils/buildRequest";
import type { TrackingRoutes } from "types/api";
import { formatError } from "services/errors";
import { useTrackingByUuid } from "../../state/tracking";

interface Props {
  readonly trackingUuid: string;
  readonly note: string;
  readonly userUuid: string;
  readonly onClose: () => void;
}

export default ({ trackingUuid, note, userUuid, onClose }: Props) => {
  const tracking = useTrackingByUuid(trackingUuid);
  const trackingNotFound = useMemo(() => tracking.isLeft(), [tracking]);
  const [newNote, setNewNote] = useState<string>(note);

  const { addToast } = useToasts();
  const { t } = useTranslation();

  useEffect(() => {
    if (trackingNotFound) {
      onClose();
    }
  }, [trackingNotFound, onClose]);

  const [loading, setLoading] = useState(false);

  const createRequest = async () => {
    const request = createTrackingNote(trackingUuid, newNote, userUuid);
    setLoading(true);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Échec lors de l'ajout de la note",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      () => {
        addToast(
          t("tracking.note.creation.success", {
            defaultValue: "note ajoutée avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );

    setLoading(false);
  };

  const updateRequest = async () => {
    const request = updateTrackingNote(trackingUuid, newNote, userUuid);
    setLoading(true);

    await Future.unwrap(
      request,
      (err) => {
        addToast(
          formatError(t, err, {
            defaultValue: "Échec lors de la modification de la note",
          }),
          {
            appearance: "error",
            autoDismiss: true,
          }
        );
      },
      () => {
        addToast(
          t("tracking.note.updated.success", {
            defaultValue: "note modifiée avec succès",
          }),
          {
            appearance: "success",
          }
        );
        onClose();
      }
    );

    setLoading(false);
  };

  return (
    <Modal
      title={note ? "Modifier la note" : "Ajouter une note"}
      onClose={onClose}
      disableOnClickAway
    >
      <div className={styles.createOrManageNoteWrapper}>
        <div className={styles.createOrManageNote}>
          <div className={styles.inputLine}>
            <DSFormGroupTextInput className={styles.label}>
              <TextArea
                value={newNote}
                placeholder="Votre note ici..."
                onChange={(e) => setNewNote(e)}
                className={styles.input}
                autoFocus
              />
            </DSFormGroupTextInput>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <DSButton className={styles.button} emphasis="Low" label="Annuler" onClick={onClose} />
          <DSButton
            className={styles.button}
            disabled={!newNote}
            loading={loading}
            onClick={!note ? createRequest : updateRequest}
            emphasis="High"
            label={note ? "Modifier la note" : "Ajouter la note"}
          />
        </div>
      </div>
    </Modal>
  );
};

const createTrackingNote = async (
  trackingUuid: string,
  note: string,
  userUuid: string
): Promise<Either<TrackingRoutes.CreateNote["errors"], TrackingRoutes.CreateNote["response"]>> => {
  const request = buildSafeRequest<TrackingRoutes.CreateNote>({
    method: "POST",
    path: "/tracking/{trackingUuid}/note",
    params: {
      trackingUuid,
    },
    payload: {
      note,
      userUuid,
    },
  });
  return request.run();
};

const updateTrackingNote = async (
  trackingUuid: string,
  note: string,
  userUuid: string
): Promise<Either<TrackingRoutes.UpdateNote["errors"], TrackingRoutes.UpdateNote["response"]>> => {
  const request = buildSafeRequest<TrackingRoutes.UpdateNote>({
    method: "POST",
    path: "/tracking/{trackingUuid}/update-note",
    params: {
      trackingUuid,
    },
    payload: {
      note,
      userUuid,
    },
  });
  return request.run();
};
