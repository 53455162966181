import React, { useCallback, forwardRef } from "react";
import type { CSSProperties, PropsWithoutRef, MouseEvent } from "react";
import cx from "classnames";

import Icon from "components/Icon";
import Loader from "components/Loader";

import styles from "./InteractiveButton.module.scss";

interface Props extends PropsWithoutRef<JSX.IntrinsicElements["button"]> {
  readonly hasTransitionIn?: boolean;
  readonly background?: CSSProperties["background"];
  readonly "data-testid"?: string;
  readonly loading?: boolean;
  readonly iconColor?: string;
  readonly iconLeft?: string;
  readonly iconRight?: string;
  readonly label?: string | string[];
  readonly loaderColor?: string;
  readonly noMouseInteraction?: boolean;
  readonly size?: "xsmall" | "small" | "big";
  readonly strokeIconLeft?: string;
  readonly strokeIconRight?: string;
  readonly disabled?: boolean;
}

export interface IState {
  baseWidth: number;
  isHover: boolean;
  isLoading: boolean;
}

const InteractiveButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    background,
    className,
    id,
    color,
    iconColor,
    iconLeft,
    iconRight,
    label,
    onClick,
    noMouseInteraction,
    size,
    style,
    strokeIconRight,
    strokeIconLeft,
    loaderColor,
    type,
    loading,
    title,
    name,
    disabled,
  } = props;

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (!loading && onClick) {
        return onClick(event);
      }
    },
    [loading, onClick]
  );

  const content = [
    iconLeft || strokeIconLeft ? (
      <Icon
        icon={iconLeft || ""}
        strokeIcon={strokeIconLeft || ""}
        width={
          {
            big: 18,
            small: 12,
            xsmall: 12,
          }[size || "none"] || 14
        }
        fill={iconColor || color || "#fff"}
        stroke={iconColor || color || "#fff"}
        style={{
          display: "block",
          flexGrow: 0,
          marginRight: label
            ? {
                big: 12,
                small: 7,
                xsmall: 7,
              }[size || "none"] || 10
            : 0,
        }}
        key="iconLeft"
      />
    ) : null,
    label,
    iconRight || strokeIconRight ? (
      <Icon
        icon={iconRight || ""}
        strokeIcon={strokeIconRight || ""}
        width={
          {
            big: 18,
            small: 12,
            xsmall: 12,
          }[size || "none"] || 14
        }
        fill={iconColor || color || "#fff"}
        stroke={iconColor || color || "#fff"}
        style={{
          display: "block",
          flexGrow: 0,
          marginLeft: label
            ? {
                big: 12,
                small: 7,
                xsmall: 7,
              }[size || "none"] || 10
            : 0,
        }}
        key="iconRight"
      />
    ) : null,
  ];

  return (
    <button
      aria-label={props["aria-label"]}
      data-testid={props["data-testid"]}
      className={cx(styles.InteractiveButton, className, styles[size], {
        [styles.noMouseInteraction]: noMouseInteraction,
        [styles.loading]: loading,
      })}
      id={id}
      onClick={handleClick}
      name={name}
      ref={ref}
      style={{ background, color, ...style }}
      title={title || ""}
      type={type || "button"}
      disabled={loading || disabled}
    >
      {loading && (
        <Loader className={styles.interactiveButtonLoader} fill={loaderColor || "white"} />
      )}
      <div className={styles.innerContent}>{content}</div>
    </button>
  );
});

export default InteractiveButton;
