import React from "react";
import Icon from "components/Icon";
import { cross as crossIcon } from "uiAssets/StrokeIcons";

import { Filters, SortTarget } from "components/DataTable/types";

import styles from "./FiltersSummaryStyles.module.scss";

export interface IProps {
  filters: Filters;
  setSort: (...args: any[]) => void;
  sortTarget?: SortTarget;
  openFilterModal: (key: string) => void;
  updateFilter: (...args: any[]) => void;
}

export default class FilterSummary extends React.PureComponent<IProps, {}> {
  private renderFilter = (filter, index) => {
    const { sortTarget, updateFilter, openFilterModal } = this.props;

    const andSuffix = ((sortTarget && index === 0) || index > 0) && (
      <div className={styles.and}>
        <span>et</span>
      </div>
    );

    const { label, column, value } = filter;
    let filterWording = "";

    if ((column.filterType ?? column.type) === "select") {
      return (
        <React.Fragment key={value}>
          {andSuffix}
          <div>
            <div onClick={() => openFilterModal(column.key)}>
              <span>{label} : </span>
              {filter.value.map((item, index) => (
                <span
                  key={
                    item.value
                      ? `filtersummary-value-${item.value}`
                      : `filtersummary-mode-${item.mode}`
                  }
                  style={{ fontWeight: 400 }}
                >
                  {item.label || item.value}
                  {filter.value.length > 1 && index !== filter.value.length - 1 && (
                    <span key={index} style={{ fontWeight: 700 }}>
                      {" "}
                      ou{" "}
                    </span>
                  )}
                </span>
              ))}
            </div>
            <button onClick={() => updateFilter(column, null)}>
              <Icon stroke="#fff" strokeIcon={crossIcon} width={5} />
            </button>
          </div>
        </React.Fragment>
      );
    }

    switch (column.type) {
      case "url":
      case "text":
        filterWording = `contient "${value}"`;
        break;
      case "number":
        filterWording = `est ${
          {
            equal: "égal",
            inferior: "inférieur",
            superior: "supérieur",
          }[value.filterType]
        } à ${value.limit}`;
        break;
      case "price":
        filterWording = `le prix est ${
          {
            equal: "égal",
            inferior: "inférieur",
            superior: "supérieur",
          }[value.filterType]
        } à ${value.limit || "0"} €`;
        break;
      case "date":
        filterWording = filter.value;
        break;
      default:
        break;
    }
    return (
      <React.Fragment key={`${column.key}-filter-summary`}>
        {andSuffix}
        <div>
          <div onClick={() => openFilterModal(column.key)}>
            <span>{label} : </span>
            {filterWording}
          </div>
          <button onClick={() => updateFilter(column, null)}>
            <Icon stroke="#fff" strokeIcon={crossIcon} width={5} />
          </button>
        </div>
      </React.Fragment>
    );
  };

  public render(): JSX.Element {
    const { filters, setSort, sortTarget, openFilterModal } = this.props;

    return (
      <menu className={styles.FilterSummary} id="filtersSummary">
        <div>
          <h3>Filtres :</h3>
          {sortTarget && (
            <div>
              <div onClick={() => openFilterModal(sortTarget.target.key)}>
                {`Tri sur : ${sortTarget.target.label} (${
                  sortTarget.direction === "up" ? "croissant" : "décroissant"
                })`}
              </div>
              <button onClick={() => setSort(null)}>
                <Icon stroke="#fff" strokeIcon={crossIcon} width={5} />
              </button>
            </div>
          )}
          {filters && Object.values(filters).map(this.renderFilter)}
        </div>
      </menu>
    );
  }
}
