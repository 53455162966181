import React, { Suspense } from "react";

import { isNil } from "lodash";
import { useRecoilValue } from "recoil";

import Acta from "utils/Acta";
import injectSafeHtml from "utils/sanitizer";

import { getSkillSelector } from "../state/skills";
import { jobFieldsAtom, getJobSelector } from "../state/jobs";

import styles from "./JobsDetailsModal.module.scss";

interface Props {
  jobUuid: string;
}

const JobDetailsModal = ({ jobUuid }: Props) => {
  return (
    <div className={styles.JobDetailsModal}>
      <div className={styles.content}>
        <Suspense fallback={<div />}>
          <JobFieldDetails jobUuid={jobUuid} />
        </Suspense>

        <div className={styles.detail}>
          <label>Description</label>
          <Suspense fallback={<div />}>
            <JobDescription jobUuid={jobUuid} />
          </Suspense>
        </div>

        <div className={styles.detail}>
          <label>Compétences liées</label>
        </div>

        <div className={styles.skills}>
          <div className={styles.list}>
            <Suspense fallback={<LoadingSkillBox />}>
              <SkillBoxes jobUuid={jobUuid} />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

const showJobDetailsModal = (job: { uuid: string; label: string }) => {
  Acta.setState("modalDisplayed", {
    content: <JobDetailsModal jobUuid={job.uuid} />,
    showOverflow: true,
    size: "xxLarge",
    title: job.label,
  });
};

// const selectedSkillBox = atom<string>({
//   default: undefined,
//   key: "selected-skillbox",
// });

const LoadingSkillBox = () => {
  return <div className={styles.skill}></div>;
};
const JobFieldDetails = ({ jobUuid }: { jobUuid: string }) => {
  const job = useRecoilValue(getJobSelector({ jobUuid }));
  const jobFields = useRecoilValue(jobFieldsAtom);

  return (
    <>
      {Object.keys(job?.fields ?? {}).map((fieldKey, index) => {
        const jobField = jobFields.find((field) => field.uuid === fieldKey);
        return (
          <div key={index} className={styles.detail}>
            <label>{jobField?.label}</label>
            <p>{job.fields[fieldKey] ?? "NC"}</p>
          </div>
        );
      })}
    </>
  );
};

const JobDescription = ({ jobUuid }: { jobUuid: string }) => {
  const job = useRecoilValue(getJobSelector({ jobUuid }));

  return <div className={styles.preview} {...injectSafeHtml(job?.description ?? "")} />;
};

const SkillBoxes = ({ jobUuid }: { jobUuid: string }) => {
  const job = useRecoilValue(getJobSelector({ jobUuid }));

  return (
    <>
      {job?.requiredSkills?.map((requiredSkill, index) => {
        return (
          <Suspense key={index} fallback={<LoadingSkillBox />}>
            <SkillBox uuid={requiredSkill.uuid} expectedLevel={requiredSkill.expectedLevel} />
          </Suspense>
        );
      })}
    </>
  );
};
const SkillBox = ({ uuid: skillUuid, expectedLevel }: { uuid: string; expectedLevel: number }) => {
  const skillData = useRecoilValue(getSkillSelector({ skillUuid }));
  // const [showOptionsForSkill, toggleOptionsVisibility] = useRecoilState(selectedSkillBox);

  const scaleValue = skillData?.scale?.find(({ level }) => level === expectedLevel)?.label;

  return (
    <div className={styles.skill}>
      <h3>{skillData?.skill?.label}</h3>
      <div className={styles.categories}>
        {Object.entries(skillData?.categories).map(([key, value]) => {
          return (
            <div key={key} className={styles.categoryValue}>
              <div className={styles.ellipse} />
              <span>{value}</span>
            </div>
          );
        })}
      </div>

      {!isNil(scaleValue) && (
        <div className={styles.expectedLevel}>
          Niveau de maîtrise attendu : <span>{scaleValue}</span>
        </div>
      )}

      {/* <div
        className={styles.more}
        onClick={() => {
          toggleOptionsVisibility(skillData.skill.uuid);
        }}
      >
        <Icon.More />
      </div>

      {showOptionsForSkill === skillData.skill.uuid && (
        <div className={styles.options}>
          <div>
            <Icon.Edit /> Modifier
          </div>
          <div>
            <Icon.Trash /> Supprimer
          </div>
        </div>
      )} */}
    </div>
  );
};

export { showJobDetailsModal };
