import { useParams, useHistory } from "react-router-dom";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { DSAvatar, UserPopover } from "@skillup/ui";
import { Text, Flex } from "@skillup/design-system";

import { getUserInitials } from "utils/User";

import { TalentGridRow } from "../../../types";

type UserPopoverCellProps = {
  params: GridRenderCellParams<TalentGridRow>;
};

export const UserPopoverCell = ({ params }: UserPopoverCellProps) => {
  const history = useHistory();
  const { campaignID } = useParams<{ campaignID: string }>();

  const role = params.row.role;
  const fullName = params.row.fullName;
  const uuid = params.row.userId;
  const initials = getUserInitials({ fullName });

  return (
    <UserPopover
      position="down-right"
      properties={[role].filter(Boolean)}
      user={{ uuid, fullName, initials }}
      profileBtn={{
        label: "Voir le profil",
        onClick: () =>
          history.push(
            `/responsable/collaborateurs/${params.row.userId}/career?previousPageURI=/responsable/people-review/campaigns/${campaignID}&previousPageTitle=Talent-Grid`
          ),
      }}
    >
      <Flex alignItems="center" justifyContent="center">
        <DSAvatar
          darkMode
          size="S"
          type="Initials"
          initials={initials}
          imageUrl={undefined}
          randomColorSeedString={uuid}
        />
        <Text marginLeft="xs" color="plainText-onLight-default">
          {fullName}
        </Text>
      </Flex>
    </UserPopover>
  );
};

export const userPopoverColDef = {
  field: "fullName",
  headerName: "Collaborateur",
  renderCell: (params: GridRenderCellParams<TalentGridRow>) => <UserPopoverCell params={params} />,
  width: 150,
} satisfies GridColDef;
