import React, { type ReactNode } from "react";

import TitleWrapper, { type TitleInterface } from "./TitleWrapper";

export type SkillTitleProps = {
  children: ReactNode | undefined;
} & TitleInterface;

export const Title = ({ children, ...props }) => {
  return <TitleWrapper {...props}>{children}</TitleWrapper>;
};
