import React, { ComponentClass } from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import cx from "classnames";

import Header from "components/Header";

import User from "utils/User";

import styles from "./SupervisorPortalManagementStyles.module.scss";

import Navigation, { PortalRoutes } from "./Navigation";
import type { IRouteInterface } from "./Navigation";
export { IRouteInterface };

export default class AccountPortalManagement extends React.PureComponent<
  {
    location: {
      pathname: string;
    };
  },
  {}
> {
  public render(): JSX.Element {
    const { location } = this.props;

    const scope = User.isSkillupAdmin() ? "admin" : "regular";

    const currentRoute: IRouteInterface | undefined = PortalRoutes.find(
      (route: IRouteInterface) => route.path === location.pathname
    );

    return (
      <div
        className={cx(
          styles.AccountPortalManagement,
          currentRoute && currentRoute.label === "Listes" ? styles.dark : ""
        )}
      >
        <Header label={`Configuration : ${currentRoute ? currentRoute.label : ""}`} />
        <Navigation />

        <Switch>
          {PortalRoutes.filter((p) => p.scope.includes(scope)).map(
            ({ path, component }: IRouteInterface) => (
              <Route
                exact
                key={path}
                path={path}
                component={component as ComponentClass<RouteComponentProps, any>}
              />
            )
          )}
        </Switch>
      </div>
    );
  }
}
