import React, { useCallback, useMemo, useState } from "react";
import { isEmpty } from "lodash";
import { useSetState } from "react-use";

import { DSButton, Modal } from "@skillup/ui";

import Acta from "utils/Acta";
import useSubCompanies from "hooks/useCompany";

import { CreateCollaboratorPayload, UpdateCollaboratorPayload } from "../../api";
import UserForm, {
  ParseChildrenCompaniesFromOrganization,
  ParseUserAreasFromCredentials,
  BuildPayloadFromForm,
  IForm,
  matchingFields,
} from "../UserForm";

import styles from "./AddUserModal.module.scss";

export type SubmitData =
  | {
      payload: CreateCollaboratorPayload;
    }
  | {
      collaboratorUuid: string;
      payload: UpdateCollaboratorPayload;
    };

interface Props {
  readonly submitRequest: (o: CreateCollaboratorPayload) => void;
  readonly onClose: () => void;
  readonly submitLabel?: string;
  readonly loading: boolean;
}

export default ({ onClose, loading, submitRequest }: Props) => {
  const [newObserverFields, setNewObserverFields] = useState<number>(0);
  const [form, setFormState] = useSetState<IForm>({});
  const {
    activeCompany: {
      uuid: companyUuid,
      groups: companyGroups,
      accessControlList: { userAreas: possibleUserAreas },
    },
  } = Acta.getState("userData");
  const { company } = useSubCompanies({ companyUuid });

  const subCompanies = useMemo(() => {
    return ParseChildrenCompaniesFromOrganization(company);
  }, [company]);

  const userAreas = useMemo(() => {
    return ParseUserAreasFromCredentials(possibleUserAreas);
  }, [possibleUserAreas]);

  const handleMainAction = useCallback(() => {
    let anyError = false;
    const fields = matchingFields().withObserverFields(newObserverFields).generate();
    for (const matchingField of fields) {
      const value = form[matchingField.field]?.value;

      if (matchingField.required && isEmpty(value)) {
        setFormState({
          [matchingField.field]: {
            value,
            error: true,
          },
        });
        anyError = true;
      }
    }

    if (!anyError) {
      const payload = BuildPayloadFromForm(form, newObserverFields);
      submitRequest(payload);
    }
  }, [setFormState, submitRequest, form, newObserverFields]);
  return (
    <Modal title="Ajouter un collaborateur" onClose={onClose}>
      <div className={styles.addUserModal}>
        <UserForm
          form={form}
          userAreas={userAreas}
          companyGroups={companyGroups.map((g) => ({ label: g.name, value: g.uuid }))}
          setFormState={setFormState}
          subCompanies={subCompanies}
          newObserverFields={newObserverFields}
          setNewObserverFields={setNewObserverFields}
        />

        <div className={styles.buttonsWrapper}>
          <DSButton
            className={styles.button}
            emphasis="Low"
            label="Annuler"
            onClick={onClose}
            loading={loading}
          />
          <DSButton
            className={styles.button}
            emphasis="High"
            label="Créer l'utilisateur"
            onClick={handleMainAction}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};
