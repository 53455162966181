import React from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineArrowDown, AiOutlineUser } from "react-icons/ai";
import { pick } from "lodash";

import { UserImportsRoutes } from "@skillup/espace-rh-bridge";

import InteractiveButton from "components/InteractiveButton";
import DSLayout from "components/DSLayout";
import { buildRequest } from "utils/buildRequest";
import Acta from "utils/Acta";
import { useTypedFetch } from "hooks";

import List, { ListProps } from "../Skills/Legacy/components/List";
import Tag, { ColorSettings, TagColors } from "./Tag";
import DropZone from "./Dropzone";

import styles from "./UploadUserDatabases.module.scss";

export type UploadUserDatabasesProps = {};
type UserImportList = UserImportsRoutes.GetUserImportsData["response"]["userImports"];
export type UserImport = UserImportList[0];

const UploadUserDatabases = (props: UploadUserDatabasesProps) => {
  const history = useHistory();
  const { data, refetch } = useTypedFetch<UserImportsRoutes.GetUserImportsData>({
    method: "GET",
    path: "/user-imports",
  });

  const userImports = data?.userImports;

  const rows: ListProps["rows"] = userImports?.map((userImport) => {
    return {
      id: userImport.uuid,
      ...pick(
        userImport,
        accessors.map(({ accessor }) => accessor)
      ),
    };
  });

  const canDropFile = userImports && !userImports.some(({ state }) => state === "En attente");

  const columns = generateColumns(refetch);
  return (
    <DSLayout
      title="Import de base"
      parent={{
        title: "Collaborateurs",
        onClick: () => history.push("/responsable/collaborateurs"),
      }}
    >
      <div className={styles.uploadUserDatabases}>
        <div className={styles.content}>
          <div>
            <DropZone refetch={refetch} canDropFile={canDropFile} />
          </div>
          <h2>Historique des imports</h2>
          <div className={styles.list}>
            <List theme="new" columns={columns} rows={rows ?? []} />
          </div>
        </div>
      </div>
    </DSLayout>
  );
};

export default UploadUserDatabases;

const accessors = [
  { accessor: "state", label: "Statut", width: 160 },
  { accessor: "name", label: "Fichier", width: 220 },
  { accessor: "upload.createdAt", label: "Date de l'import", width: 180 },
  { accessor: "upload.by", label: "Importé par", width: 150 },
  { accessor: "sourceRawType", label: "Format", width: 130 },
  { accessor: "rowsCount", label: "Lignes", width: 100 },
  { accessor: "validatedAt", label: "Date de validation", width: 180 },
];

const generateColumns = (refetch: () => void): ListProps["columns"] =>
  accessors.map(({ accessor, label, width }) => {
    const baseSettings = {
      accessor,
      label,
      width,
      filterable: false,
      disableFilters: true,
      disableSortBy: true,
    };

    const confirmCancelation = (importUuid: string) => () => {
      Acta.setState("modalDisplayed", {
        content: (
          <div className={styles.confirmCancelation}>
            <p>Voulez-vous vraiment annuler l'import ?</p>
            <div className={styles.actions}>
              <InteractiveButton
                label="Confirmer"
                onClick={async () => {
                  try {
                    await buildRequest<UserImportsRoutes.CancelData>({
                      method: "POST",
                      params: { importUuid },
                      path: "/cancel/{importUuid}",
                    })();

                    Acta.dispatchEvent("sendAppMessage", {
                      message: "Import annulé avec succès !",
                      type: "success",
                    });

                    refetch();
                    Acta.dispatchEvent("closeModal");
                  } catch (err) {
                    Acta.dispatchEvent("sendAppMessage", {
                      message: err.message,
                      type: "error",
                    });
                  }
                }}
              />
            </div>
          </div>
        ),
        title: "Confirmez l'annulation",
        size: "small",
      });
    };
    switch (accessor) {
      case "upload.by":
        return {
          ...baseSettings,
          Cell: ({ row }) => {
            const uploader = row.values?.["upload.by"];
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {uploader === "Automatique" ? (
                  <>
                    <AiOutlineArrowDown color="#7B89B2" style={{ marginRight: 5 }} size={16} />{" "}
                    {row.values["upload.by"]}
                  </>
                ) : (
                  <>
                    <AiOutlineUser color="#7B89B2" style={{ marginRight: 5 }} size={16} />{" "}
                    {row.values["upload.by"]}
                  </>
                )}
              </div>
            );
          },
        };
      case "validatedAt":
        return {
          ...baseSettings,
          Cell: ({ row }) => {
            const { validatedAt } = row.values;
            const state: UserImportList[0]["state"] = row.values.state;

            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                {state === "En attente" ? (
                  <p className={styles.cancelCell} onClick={confirmCancelation(row.original.id)}>
                    Annuler
                  </p>
                ) : (
                  <p>{validatedAt}</p>
                )}
              </div>
            );
          },
        };
      case "state": {
        return {
          ...baseSettings,
          Cell: ({ row }) => {
            const colors: Record<UserImportList[0]["state"], ColorSettings> = {
              "En attente": TagColors.YELLOW,
              Annulé: TagColors.GRAY,
              Validé: TagColors.GREEN,
              Rejeté: TagColors.RED,
            };

            const { state } = row.values;
            return (
              <div>
                <Tag color={colors[state]} label={state} />
              </div>
            );
          },
        };
      }
      default: {
        return baseSettings;
      }
    }
  });
