import { ChildrenRestrictions } from "@skillup/espace-rh-bridge";

const defaultQuestionRestrictions: ChildrenRestrictions["question"] = {
  employee: {
    comment: true,
    reply: true,
  },
  manager: {
    comment: true,
    reply: true,
  },
};
const defaultTargetsRestrictions: ChildrenRestrictions["targets"] = {
  employee: {
    evaluation: true,
    commentTarget: true,
    comment: true,
    creation: true,
    edition: true,
    deletion: true,
  },
  manager: {
    evaluation: true,
    commentTarget: true,
    comment: true,
    creation: true,
    edition: false,
    deletion: false,
  },
};

const defaultFileUploadRestrictions: ChildrenRestrictions["upload"] = {
  employee: {
    comment: true,
    upload: true,
  },
  manager: {
    comment: true,
    upload: true,
  },
};

const defaultSkillsRestrictions: ChildrenRestrictions["skills"] = {
  employee: {
    comment: true,
    commentEvaluation: true,
    evaluation: true,
  },
  manager: {
    comment: true,
    commentEvaluation: true,
    evaluation: true,
  },
};

const defaultInterviewsHistoryRestrictions: ChildrenRestrictions["interviewsHistory"] = {
  employee: {
    comment: true,
    downloadPDF: true,
  },
  manager: {
    comment: true,
    downloadPDF: true,
  },
};

const defaultTrainingsHistoryRestrictions: ChildrenRestrictions["trainingsHistory"] = {
  employee: {
    comment: true,
  },
  manager: {
    comment: true,
  },
};

const defaultTrainingsCollectionRestrictions: ChildrenRestrictions["trainingsCollection"] = {
  employee: {
    comment: true,
    requestTraining: true,
  },
  manager: {
    comment: true,
    requestTraining: true,
  },
};

const defaultTableRestrictions: ChildrenRestrictions["table"] = {
  employee: {
    comment: true,
  },
  manager: {
    comment: true,
  },
};

const defaultGlobalTargetsCompletionRestrictions: ChildrenRestrictions["globalTargetsCompletion"] =
  {
    employee: {
      comment: true,
    },
    manager: {
      comment: true,
    },
  };

const defaultJobRestrictions: ChildrenRestrictions["job"] = {
  employee: {
    comment: true,
  },
  manager: {
    comment: true,
  },
};

export const defaultRestrictions = {
  question: defaultQuestionRestrictions,
  targets: defaultTargetsRestrictions,
  upload: defaultFileUploadRestrictions,
  skills: defaultSkillsRestrictions,
  interviewsHistory: defaultInterviewsHistoryRestrictions,
  trainingsHistory: defaultTrainingsHistoryRestrictions,
  trainingsCollection: defaultTrainingsCollectionRestrictions,
  table: defaultTableRestrictions,
  globalTargetsCompletion: defaultGlobalTargetsCompletionRestrictions,
  job: defaultJobRestrictions,
};
