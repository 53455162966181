import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import cx from "classnames";

import { Flex, Text } from "@skillup/design-system";
import { DSAvatarGroup, DSProgressBar, TypeProgressBar, VerticalDivider } from "@skillup/ui";

import { getUserInitials } from "utils/User";
import type { BasicCampaign } from "services/peopleReview/campaign/usePeopleReviewCampaigns";

import { PausedStatus } from "../CampaignState/PausedStatus";
import { BaseUrl as PeopleReviewBaseUrl } from "../../pages/router";
import { NotStartedStatus } from "./../CampaignState/NotStartedStatus";

import styles from "./PPRCampaignCard.module.scss";

export const PPRCampaignCard = ({ campaign }: { campaign: BasicCampaign }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const isClosed = campaign.state === "closed";

  const progressData = useMemo(() => {
    const total = campaign.reviews.length;
    const progress: React.ComponentProps<typeof DSProgressBar>["data"] = [];

    if (campaign.progress.arbitrated > 0) {
      progress.push({
        color: "success" as const,
        label: t("peoplereview.campaignCard.progress.arbitrated", {
          count: campaign.progress.arbitrated,
          defaultValue: `Arbitrage réalisé pour {{count}} collaborateur${campaign.progress.arbitrated > 1 ? "s" : ""}`,
        }),
        value: (campaign.progress.arbitrated * 100) / total,
      });
    }

    if ("waitingForManagerPrep" in campaign.progress) {
      if (campaign.progress.managerPrepDone > 0) {
        progress.push({
          color: "successLight" as const,
          label: t("peoplereview.campaignCard.progress.managerPrepDone", {
            count: campaign.progress.managerPrepDone,
            defaultValue: `Préparation manager réalisée pour {{count}} collaborateur${campaign.progress.managerPrepDone > 1 ? "s" : ""}`,
          }),
          value: (campaign.progress.managerPrepDone * 100) / total,
        });
      }
      if (campaign.progress.waitingForManagerPrep > 0) {
        progress.push({
          color: "transparent" as const,
          label: t("peoplereview.campaignCard.progress.waitingForManagerPrep", {
            count: campaign.progress.waitingForManagerPrep,
            defaultValue: `En attente de préparation manager pour {{count}} collaborateur${campaign.progress.waitingForManagerPrep > 1 ? "s" : ""}`,
          }),
          value: (campaign.progress.waitingForManagerPrep * 100) / total,
        });
      }
    } else {
      if (campaign.progress.waitingForArbitration > 0) {
        progress.push({
          color: "transparent" as const,
          label: t("peoplereview.campaignCard.progress.waitingForArbitration", {
            count: campaign.progress.waitingForArbitration,
            defaultValue: `En attente de préparation manager pour {{count}} collaborateur${campaign.progress.waitingForArbitration > 1 ? "s" : ""}`,
          }),
          value: (campaign.progress.waitingForArbitration * 100) / total,
        });
      }
    }

    return progress;
  }, [t, campaign.progress, campaign.reviews]);

  return (
    <Flex
      gap="xs"
      paddingVertical="xs"
      paddingHorizontal="s"
      flexDirection="column"
      className={cx(styles.campaignCard, {
        [styles.closed]: isClosed,
      })}
      onClick={() => history.push(`${PeopleReviewBaseUrl}/campaigns/${campaign.id}/grid`)}
    >
      {campaign.state === "not_started" && (
        <NotStartedStatus isDark={false} className={styles.state} />
      )}
      {campaign.state === "paused" && <PausedStatus isDark={false} className={styles.state} />}
      <Flex alignItems="center" justifyContent="space-between">
        <Text
          espaceFont="h4"
          color={isClosed ? "plainText-onLight-lighter" : "plainText-onLight-default"}
        >
          {campaign.title}
        </Text>
      </Flex>
      <Flex gap="xs" marginBottom="s" flexDirection="column">
        <Flex flexWrap="wrap">
          <Text espaceFont="captionRegular">
            {t("peoplereview.campaignCard.population.collabortorCount", {
              count: campaign.reviews.length,
              defaultValue: `{{count}} collaborateur${campaign.reviews.length > 0 ? "s" : ""}`,
            })}
          </Text>
          <VerticalDivider left="xs" right="xs" />
          <Text espaceFont="captionRegular">
            {t("peoplereview.campaignCard.population.managerCount", {
              count: campaign.managerCount,
              defaultValue: `{{count}} manager${campaign.managerCount > 0 ? "s" : ""}`,
            })}
          </Text>
          <VerticalDivider left="xs" right="xs" />
          <Text espaceFont="captionRegular">
            {t("peoplereview.campaignCard.population.coordinatorsCount", {
              count: campaign.coordinators.length,
              defaultValue: `{{count}} coordinateur${campaign.coordinators.length > 0 ? "s" : ""}`,
            })}
          </Text>
        </Flex>
        <DSAvatarGroup
          size="S"
          maxItems={5}
          greyMode={isClosed}
          firstElementForeground
          totalItems={campaign.reviews.length}
          users={campaign.collabPreviews.map((employee) => ({
            uuid: employee.uuid,
            fullName: employee.fullName,
            initials: getUserInitials({
              fullName: employee.fullName,
            }),
          }))}
        />
      </Flex>
      {/* TODO: commented because we will need this after release */}
      {campaign.state !== "not_started" && (
        <DSProgressBar
          thin
          border
          layout="segmented"
          data={progressData}
          showLegends={false}
          disabled={isClosed}
          type={TypeProgressBar.PERCENTAGE}
        />
      )}
    </Flex>
  );
};
