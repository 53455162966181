import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { ListUtils } from "@skillup/shared-utils";

import { type FilterProp, type FilterProps } from "./types";

export type UseFilterProp<Config extends ListUtils.FilterConfigurationMap> = {
  [key in keyof Config]: FilterProps<Config[key]["type"]> & {
    initialValue?: ListUtils.FilterValueType[Config[key]["type"]];
  };
};

export const useFilters = <Config extends ListUtils.FilterConfigurationMap>(
  config: Config,
  filters: UseFilterProp<Config>
) => {
  const initialFilters = useMemo(() => {
    return Object.keys(config).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          type: config[key].type,
          visibilityMode: filters[key]?.visibilityMode,
          defaultValue: filters[key]?.defaultValue,
          options: filters[key]?.options,
          value: filters[key]?.initialValue,
          operator: ListUtils.FilterOperator.CONTAINS,
          label: config[key]?.label,
          placeholder: filters[key]?.placeholder,
        },
      }),
      {} as FilterProp<Config>
    );
  }, [config, filters]);
  const [filterValues, setFilterValues] = useState<ListUtils.FilterValues<Config>>(
    Object.keys(config)
      .filter(
        (filterId) =>
          initialFilters[filterId] &&
          initialFilters[filterId].value !== undefined &&
          initialFilters[filterId].value !== null
      )
      .reduce(
        (acc, filterId) => ({
          ...acc,
          [filterId]: {
            type: initialFilters[filterId].type,
            value: initialFilters[filterId].value,
            operator: initialFilters[filterId].operator,
          },
        }),
        {} as FilterProp<Config>
      )
  );

  return [initialFilters, filterValues, setFilterValues] as [
    typeof initialFilters,
    typeof filterValues,
    typeof setFilterValues
  ];
};

export const useUrlSyncedFilters = <Config extends ListUtils.FilterConfigurationMap>(
  config: Config,
  initialValues: UseFilterProp<Config>
) => {
  const history = useHistory();

  const filtersConfig = useMemo(() => {
    const qs = new URLSearchParams(window.location.search);

    const filters: UseFilterProp<Config> = Array.from(qs.entries()).reduce((acc, [key, value]) => {
      if (config[key]) {
        return {
          ...acc,
          [key]: {
            ...(acc[key] ?? {}),
            initialValue: JSON.parse(decodeURIComponent(value)),
          },
        };
      }

      return acc;
    }, initialValues);

    return filters;
  }, [config]);

  const [filters, values, setFilters] = useFilters(config, filtersConfig);

  useEffect(() => {
    const url = new URL(window.location.href);

    Object.keys(config).forEach((filterId) => {
      if (
        values[filterId] !== undefined &&
        values[filterId].value !== undefined &&
        values[filterId].value !== null
      ) {
        url.searchParams.set(filterId, encodeURIComponent(JSON.stringify(values[filterId].value)));
      } else {
        url.searchParams.delete(filterId);
      }
    });

    history.push(url.pathname + url.search);
  }, [values, config, history]);

  return [filters, values, setFilters] as [typeof filters, typeof values, typeof setFilters];
};
