import { Dispatch, createContext, useCallback, useContext, useRef } from "react";
import { useBuilderEvents, useBuilderEventsDispatch, ScrollTriggeredBy } from "./hooks";
import type { Template } from "services/interviews/useTemplates";
import { EventService } from "./eventService";

export type ItemRef = {
  uuid: string;
  fromAction: "init" | "dragEnd" | "addBlock" | "deleteItem" | "duplicateItem" | "removeItem";
};

type BuilderContextType = {
  template: Template;
  closedItems: Record<string, boolean>;
  setClosedItems: Dispatch<React.SetStateAction<Record<string, boolean>>>;
  updatedItemIdRef: React.MutableRefObject<ItemRef | null>;
  parentsIds: React.MutableRefObject<Record<string, string[]>>;
  structureNavRef: React.MutableRefObject<HTMLDivElement | null>;
  contentItemsRefs: React.MutableRefObject<Record<string, HTMLDivElement>>;
  scrollTriggeredBy: ScrollTriggeredBy;
  clickContentItem: (id: string) => void;
  clickStructureItem: (id: string) => void;
  focusContentItemFromScroll: (id: string) => void;
  focusStructureItemFromErrorDropdown: (id: string) => void;
  itemsUpdateAfterDragEnd: (id: string) => void;
  itemsUpdateAfterActionOnBlock: (id: string) => void;
  setScrollTriggeredByUser: () => void;
  setScrollTriggeredByBuilder: () => void;
};

const BuilderContext = createContext<BuilderContextType>(null);

const useBuilderContext = () => {
  const context = useContext(BuilderContext);
  if (!context) {
    throw new Error("useBuilder must be used within a BuilderProvider");
  }
  return context;
};

const BuilderProvider = ({
  template,
  eventService,
  children,
}: {
  template: Template;
  eventService: EventService;
  children: React.ReactNode;
}) => {
  const updatedItemIdRef = useRef<ItemRef | null>(null);
  const {
    structureNavRef,
    contentItemsRefs,
    closedItems,
    setClosedItems,
    parentsIds,
    scrollTriggeredBy,
    setScrollTriggeredBy,
  } = useBuilderEvents(eventService);

  const {
    clickContentItem,
    clickStructureItem,
    focusContentItemFromScroll,
    focusStructureItemFromErrorDropdown,
    itemsUpdateAfterDragEnd,
    itemsUpdateAfterActionOnBlock,
  } = useBuilderEventsDispatch(eventService);

  const setScrollTriggeredByUser = useCallback(() => {
    setScrollTriggeredBy(ScrollTriggeredBy.User);
  }, [setScrollTriggeredBy]);
  const setScrollTriggeredByBuilder = useCallback(() => {
    setScrollTriggeredBy(ScrollTriggeredBy.Builder);
  }, [setScrollTriggeredBy]);

  return (
    <BuilderContext.Provider
      value={{
        template,
        closedItems,
        setClosedItems,
        updatedItemIdRef,
        parentsIds,
        structureNavRef,
        contentItemsRefs,
        scrollTriggeredBy,
        clickContentItem,
        clickStructureItem,
        focusContentItemFromScroll,
        focusStructureItemFromErrorDropdown,
        itemsUpdateAfterDragEnd,
        itemsUpdateAfterActionOnBlock,
        setScrollTriggeredByUser,
        setScrollTriggeredByBuilder,
      }}
    >
      {children}
    </BuilderContext.Provider>
  );
};

export { BuilderContext, useBuilderContext, BuilderProvider };
