import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Summary as ISummary } from "@skillup/types";

import Icon from "components/Icon";
import { time } from "uiAssets/Icons";
import Loader from "components/Loader";
import User from "utils/User";

import styles from "./SummaryStyles.module.scss";
import useTranslation from "hooks/useTranslation";

export interface IProps {
  summary: ISummary | {};
  selection: string;
}

const Summary = (props: IProps) => {
  const { t } = useTranslation();

  const summary = (props.summary || {}) as ISummary;
  const {
    selectionLength,
    price,
    priceWithTax,
    duration,
    trainingName,
    trainingOrganization,
    trainingUrl,
    types,
    modality,
    total,
    totalWithTax,
    totalDuration,
  } = summary;

  const multipleLines = selectionLength > 1;
  const singleLines = selectionLength === 1;
  const isLoading = props.selection && props.selection !== summary.selection;

  const durationText = useMemo(() => {
    const hours = Math.floor(totalDuration);
    const minutes = Math.round((totalDuration - hours) * 60);

    const hoursText = t("common.duration.hours", {
      defaultValue: "{{count}} heures",
      count: hours,
    });

    if (minutes > 0) {
      const minutesText = t("common.duration.minutes", {
        defaultValue: "{{count}} minutes",
        count: minutes,
      });

      return `${hoursText} ${minutesText}`;
    }

    return hoursText;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalDuration]);

  return (
    <div className={styles.Summary}>
      {isLoading && (
        <div className={styles.loader}>
          <Loader width={30} />
        </div>
      )}

      {!isLoading && multipleLines && (
        <div className={styles.multipleLines}>
          <div>
            <strong>{selectionLength}</strong> actions de formation selectionnées
          </div>
          <div>
            Prix total : <strong>{User.isCompanyWithTax() ? totalWithTax ?? total : total}</strong>
          </div>
          {totalDuration && (
            <div>
              Durée totale : <strong>{durationText}</strong>
            </div>
          )}
        </div>
      )}

      {!isLoading && singleLines && (
        <div className={styles.singleLine}>
          <div>
            <Link to={trainingUrl || ""}>{trainingName}</Link>
          </div>
          <div>
            <span>
              <div>{!!trainingOrganization && <p>par {trainingOrganization}</p>}</div>
              <div>
                {!!duration && (
                  <>
                    <Icon icon={time} fill="#666" width={16} style={{ marginRight: 5 }} />
                    <span className={styles.duration}>{duration}</span>
                  </>
                )}
                {!!modality && <span className={styles.modality}>{modality}</span>}
                {!!types && types === "Intra" && <span className={styles.types}>{types}</span>}
              </div>
            </span>
            {(!!price || price === "Sur devis") && (
              <span className={styles.price}>
                {User.isCompanyWithTax() ? priceWithTax ?? price : price}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Summary;
