import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";

import { AssistiveArea, DSCheckbox, Flex } from "@skillup/ui";

import { Child, changePermission } from "../../../reducer";
import { usePermissions, Res, GetActions } from "./usePermissions";
import { useBuilderContext } from "../../../BuilderContext";

import styles from "./Permissions.module.scss";

type Props<TKind extends Child["kind"], TRestrictions extends Res<TKind>> = {
  restrictions: TRestrictions;
  uuid: string;
  kind: TKind;
  override?: Partial<Record<GetActions<TKind>, string>>;
  order?: GetActions<TKind>[];
  indexes: {
    section: number;
    page: number;
    child: number;
  };
  mandatory?: Partial<Record<GetActions<TKind>, string>>;
};

export function Permissions<TKind extends Child["kind"], TRestrictions extends Res<TKind>>(
  props: Props<TKind, TRestrictions>
) {
  const { template } = useBuilderContext();
  const choicesForOrdinalTargets = useMemo(() => {
    return template.choicesForOrdinalTargets ?? [];
  }, [template]);
  const storeDispatch = useDispatch();
  const permissions = usePermissions(props.restrictions);
  const actions = permissions.actionsForThisChild;
  const orderedActions = useOrder(actions, props.order);

  const employeeCanPerformAction = useCallback(
    (action) => {
      return permissions.employee.canPerformAction(action);
    },
    [permissions.employee]
  );

  const managerCanPerformAction = useCallback(
    (action) => {
      return permissions.manager.canPerformAction(action);
    },
    [permissions.manager]
  );

  const isErrorOnReplyMandatory = useCallback(
    (action: GetActions<TKind>) => {
      return (
        props.mandatory &&
        props.mandatory[action] &&
        !employeeCanPerformAction(action) &&
        !managerCanPerformAction(action)
      );
    },
    [employeeCanPerformAction, managerCanPerformAction, props.mandatory]
  );

  const employeeHelpText = useCallback(
    (action: string) => {
      switch (true) {
        case action === "creation" && props.kind === "targets":
          return "Le collaborateur pourra également modifier et supprimer les objectifs qu'il a créés.";
        default:
          return undefined;
      }
    },
    [props.kind]
  );

  const managerHelpText = useCallback(
    (action: string) => {
      switch (true) {
        case action === "evaluation" && props.kind === "targets":
          return "L'évaluation du responsable de l'entretien est obligatoire.";
        default:
          return undefined;
      }
    },
    [props.kind]
  );

  const isDisabled = useCallback(
    (action: string) => {
      return props.kind === "targets" && action === "evaluation";
    },
    [props.kind]
  );

  return (
    <Flex column className={styles.permissions}>
      {orderedActions.map((action) => (
        <Flex column key={`${props.uuid}-${String(action)}`} className={styles.permissions__action}>
          <Label action={String(action)} override={props.override ?? {}} kind={props.kind} />
          <Flex
            column
            className={cx({
              [styles.permissions__errorContainer]: isErrorOnReplyMandatory(String(action)),
            })}
          >
            {isErrorOnReplyMandatory(String(action)) && (
              <AssistiveArea text={props.mandatory[action]} mode="error" />
            )}
            <DSCheckbox
              className={styles.permissions__actionChoice}
              name={`${props.uuid}-${String(action)}-employee`}
              helpText={employeeHelpText(String(action))}
              label="Le collaborateur"
              checked={employeeCanPerformAction(action)}
              onChange={() => {
                storeDispatch(
                  changePermission({
                    type: employeeCanPerformAction(action) ? "revokePermission" : "grantPermission",
                    action: action as string,
                    indexes: props.indexes,
                    role: "employee",
                    choicesForOrdinalTargets,
                  })
                );
              }}
            />
            <DSCheckbox
              className={styles.permissions__actionChoice}
              name={`${props.uuid}-${String(action)}-manager`}
              helpText={managerHelpText(String(action))}
              label="Le responsable de l'entretien"
              checked={managerCanPerformAction(action)}
              disabled={isDisabled(String(action))}
              onChange={() => {
                if (!isDisabled(String(action))) {
                  storeDispatch(
                    changePermission({
                      type: managerCanPerformAction(action)
                        ? "revokePermission"
                        : "grantPermission",
                      action: action as string,
                      indexes: props.indexes,
                      role: "manager",
                      choicesForOrdinalTargets,
                    })
                  );
                }
              }}
            />
          </Flex>
        </Flex>
      ))}
      <ActionBlockAssistiveArea kind={props.kind} />
    </Flex>
  );
}

const manipulatedItems = {
  targets: "des objectifs",
  skills: "des compétences",
};
function Label(props: { action: string; override: Record<string, string>; kind: Child["kind"] }) {
  const label =
    props.override[props.action] ??
    {
      comment: `Qui peut commenter la réponse ?`,
      reply: `Qui peut répondre à la question ?`,
      edition: `Ces participants peuvent éditer ${manipulatedItems[props.kind]}:`,
      deletion: `Ces participants peuvent supprimer ${manipulatedItems[props.kind]}:`,
      creation: `Ces participants peuvent créer ${manipulatedItems[props.kind]}:`,
      evaluation: `Ces participants peuvent évaluer ${manipulatedItems[props.kind]}:`,
      commentTarget: "Ces participants peuvent commenter des objectifs:",
      commentEvaluation: "Ces participants peuvent commenter des évaluations de compétences:",
      downloadPDF: "Qui peut télécharger les fichiers PDF de compte-rendu d’entretiens ?",
      upload: "Qui peut ajouter des fichiers ?",
      requestTraining: "Qui peut faire une demande de formation pour le collaborateur ?",
    }[props.action] ??
    props.action;

  const assistiveText = getAssistiveText({ action: props.action });

  if (!assistiveText) {
    return <p className={styles.permissions__actionLabel}>{label}</p>;
  }

  return (
    <>
      <p className={styles.permissions__actionLabel}>{label}</p>
      <AssistiveArea text={assistiveText} />
    </>
  );
}

function getAssistiveText(props: { action: string }) {
  switch (props.action) {
    case "downloadPDF":
      return "(Si les paramètres de confidentialité de la campagne le permettent)";
    default:
      return undefined;
  }
}

function useOrder<T>(actions: T[], order: T[] | undefined) {
  if (!order) return actions;
  return actions.sort((a, b) => {
    const aIndex = order.indexOf(a);
    const bIndex = order.indexOf(b);
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;
    return aIndex - bIndex;
  });
}

const ActionBlockAssistiveArea = ({ kind }: { kind: Child["kind"] }) => {
  if (kind !== "targets") {
    return null;
  }

  return (
    <AssistiveArea
      text={
        "Le responsable de l'entretien peut toujours modifier et supprimer les objectifs, qu'ils aient été créés dans l'entretien en cours ou non, par le collaborateur ou lui-même."
      }
    />
  );
};
