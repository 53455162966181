export const loadFrillScript = function (wind, doc, scriptUrl, token): any {
  const a: any = doc.getElementsByTagName("body")[0];
  const r: any = doc.createElement("script");

  r.async = 1;
  r.src = scriptUrl;
  r.setAttribute("id", "frillScript");
  // r.dataset.token = token;
  a.appendChild(r);
};
