import { useState } from "react";
import { TableOfContent as DSTableOfContent } from "@skillup/ui";

import styles from "./TableOfContent.module.scss";
import { useDashboardData } from "../../context";

function TableOfContent() {
  const { campaignStructure } = useDashboardData();

  const [active, setActive] = useState<string>();

  return (
    <DSTableOfContent
      activeItem={active}
      onChangeItem={setActive}
      className={styles.TableOfContent}
    >
      {[
        <DSTableOfContent.Item label="Synthèse" id="summary" key="summary" />,
        ...campaignStructure.map((section) => (
          <DSTableOfContent.Item
            key={JSON.stringify(section)}
            label={section.title}
            id={`section-${section.uuid}`}
          >
            {section?.pages.length > 1 &&
              section?.pages
                ?.filter((page) => page.title)
                .map((page) => (
                  <DSTableOfContent.SubItem
                    key={JSON.stringify(page)}
                    label={page.title}
                    id={`page-${page.uuid}`}
                  />
                ))}
          </DSTableOfContent.Item>
        )),
      ]}
    </DSTableOfContent>
  );
}

export default TableOfContent;
