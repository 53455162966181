import React from "react";
import { withRouter } from "react-router-dom";
import { History, Location } from "history";

import Acta from "utils/Acta";

import storage, { allPersistentStorages } from "services/storage";

interface IProps {
  readonly history: History<any>;
  readonly location: Location<any>;
}

class Process extends React.PureComponent<IProps, {}> {
  private userToken: string | null;

  public componentDidMount(): void {
    this.handleLocationChange(this.props.location);
    storage.on("X-Auth-Token", this.onAuthTokenChange, allPersistentStorages);

    const user = Acta.getState("userData");
    this.userToken = user && user.token;

    Acta.subscribeState(
      "userData",
      (userData) => {
        if (userData && userData.token !== this.userToken) {
          this.userToken = userData.token;
        } else if (!userData && this.userToken) {
          this.userToken = null;
        }
      },
      this
    );

    // @ts-ignore
    if (!window.location.hash.length > 0) window.scrollTo(0, 0);
  }

  public componentDidUpdate({ location: prevLocation }): void {
    const { location: nextLocation } = this.props;

    if (prevLocation !== nextLocation) {
      this.handleLocationChange(nextLocation, prevLocation);
    }
  }

  public componentWillUnmount(): void {
    storage.off("X-Auth-Token", this.onAuthTokenChange, allPersistentStorages);
  }

  private onAuthTokenChange = (value, oldValue): void => {
    if (value === oldValue) return;

    const userData = Acta.getState("userData");

    const tokenUpdate = (!userData && !!value) || (userData && userData.token) !== value;

    if (tokenUpdate) {
      // actions.auth.signin();
      if (navigator.userAgent.indexOf("Trident/") === -1) {
        window.location.href = process.env.REACT_APP_STATIC_FRONTEND_URL as string;
      }
    }
  };

  private handleLocationChange = (nextLocation: Location, previousLocation?: Location): void => {
    const pathname = nextLocation && nextLocation.pathname;
    const previousPathname = previousLocation && previousLocation.pathname;

    if (previousPathname !== pathname && typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }
  };

  public render(): JSX.Element {
    return <React.Fragment />;
  }
}

// @ts-ignore
export default withRouter(Process);
