import React, { Suspense, useState, useEffect } from "react";

import { useRecoilValue } from "recoil";
import { FiEdit3 } from "react-icons/fi";
import { IoMdMore } from "react-icons/io";

import { SearchInput, DropDownMenu } from "@skillup/ui";

import User from "utils/User";
import type { SkillsRoutes } from "types/api";
import LoaderShimmer from "components/LoaderShimmer";

import type { ListProps } from "../components/List";
import List, { TextFilter } from "../components/List";
import { skillsAtom, skillsCategoriesAtom } from "../state/skills";
import { showDeleteSkillModal, showSkillDetailsModal } from "./SkillDetailsModal";

import styles from "./SkillsList.module.scss";

type GetSkillsRoute = SkillsRoutes["Skills.GetList"];

const SkillsList = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <div className={styles.SkillsList}>
      <SearchInput
        value={searchQuery}
        className={styles.searchInput}
        placeholder="Rechercher une compétence"
        onChange={handleSearch}
      />
      <div className={styles.tableContainer}>
        <Suspense
          fallback={
            <div className={styles.loading}>
              <LoaderShimmer className={styles.loadingLogo} />
              <p>Chargement en cours…</p>
            </div>
          }
        >
          <FilteredList searchQuery={searchQuery} />
        </Suspense>
      </div>
    </div>
  );
};

const columns: ListProps["columns"] = [
  {
    accessor: "label",
    Filter: TextFilter,
    filterType: "text",
    label: "Compétence",
    width: 300,
  },
  {
    accessor: "description",
    Filter: TextFilter,
    filterType: "text",
    label: "Description",
    width: 650,
  },
];

const FilteredList = ({ searchQuery }: { searchQuery: string }) => {
  const skills = useRecoilValue(skillsAtom);
  const companyCategories = useRecoilValue(skillsCategoriesAtom);

  const [displayedSkills, setDisplayedSkills] = useState<GetSkillsRoute["response"]["skills"]>(
    skills ?? []
  );

  useEffect(() => {
    if (searchQuery?.length) {
      setDisplayedSkills(
        skills?.filter((skill) =>
          skill.skill?.label?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setDisplayedSkills(skills ?? []);
    }
  }, [searchQuery, setDisplayedSkills, skills]);

  const companyCategoriesColumns: ListProps["columns"] = (companyCategories ?? []).map(
    (category) => ({
      accessor: category.uuid,
      Filter: TextFilter,
      filterType: "text",
      label: category.label,
      width: 150,
    })
  );

  return (
    <List
      rowProps={(row) => ({
        className: styles.previewLink,
        onClick: () => showSkillDetailsModal({ skillUuid: row.original.id }),
      })}
      rows={displayedSkills.map(({ categories, skill }) => ({
        id: skill.uuid,
        description: skill.description ?? "",
        label: skill.label ?? "",
        skill,
        ...companyCategories.reduce(
          (acc, category) => ({
            ...acc,
            [category.uuid]: categories[category.uuid] ?? "",
          }),
          {}
        ),
      }))}
      columns={[
        ...columns,
        ...companyCategoriesColumns,
        ...(User.isSkillupAdmin()
          ? [
              {
                accessor: "actions",
                Cell: ({ cell }) => {
                  const { uuid, label } = cell.row.original.skill;
                  return (
                    <DropDownMenu
                      togglerClassName={styles.actionsToggler}
                      items={[
                        {
                          icon: <FiEdit3 size={16} />,
                          label: "Supprimer la compétence",
                          onClick: () =>
                            showDeleteSkillModal({
                              skillLabel: label,
                              skillUuid: uuid,
                            }),
                        },
                      ]}
                    >
                      <IoMdMore size={16} />
                    </DropDownMenu>
                  );
                },
                disableFilters: true,
                disableSortBy: true,
                label: "Actions",
                showOverflow: true,
                width: 100,
              },
            ]
          : []),
      ]}
    />
  );
};

export default SkillsList;
