import DataLayer from "utils/DataLayer";
import { TrainingInterface } from "../types";

import { IState } from "../components/List";
import moveInArray from "utils/moveInArray";

const moveTrainingInList = async (
  listUuid: string,
  trainings: TrainingInterface[],
  setState: (state: IState) => void,
  from: number,
  to: number
) => {
  const updatedTrainings = moveInArray(trainings, from, to);
  setState({
    trainings: [...updatedTrainings],
  });

  await DataLayer.request({
    method: "POST",
    url: `/v1/portal/training-list/${listUuid}/sort-trainings`,
    body: JSON.stringify(
      updatedTrainings
        .sort((trainingA, trainingB) => (trainingA.index <= trainingB.index ? -1 : 1))
        .map((training, index) => ({
          uuid: training.properties.uuid,
          index: index,
        }))
    ),
  });
};

export default moveTrainingInList;
