import { PayloadAction } from "@reduxjs/toolkit";
import { FromChild, FromIndexes, FromPage, FromSection } from "../addBlock";

export const ActionHelper = {
  comesFromChild<T extends { from: FromIndexes }>(
    action: PayloadAction<T>
  ): action is PayloadAction<T & { from: FromChild }> {
    return "childIndex" in action.payload.from && action.payload.from.childIndex !== undefined;
  },
  comesFromSection<T extends { from: FromIndexes }>(
    action: PayloadAction<T>
  ): action is PayloadAction<T & { from: FromSection }> {
    return (
      "sectionIndex" in action.payload.from &&
      action.payload.from.sectionIndex !== undefined &&
      !ActionHelper.comesFromPage(action)
    );
  },
  comesFromPage<T extends { from: FromIndexes }>(
    action: PayloadAction<T>
  ): action is PayloadAction<T & { from: FromPage }> {
    return (
      "pageIndex" in action.payload.from &&
      action.payload.from.pageIndex !== undefined &&
      !ActionHelper.comesFromChild(action)
    );
  },
};
