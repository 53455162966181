import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useMachine } from "@xstate/react";

import {
  DSAvatarGroup,
  DSModal,
  DSFormGroupTextInput,
  DSTextInput
} from "@skillup/ui";
import { SessionUser } from "@skillup/espace-rh-bridge";

import { confirmMachine } from "stateMachine/confirm";
import { PartialPostponeParams } from "services/sessions/partialPostpone";
import { getUserInitials } from "utils/User";

import AttendeeNotificationAlert from "./AttendeeNotificationAlert";
import { Project } from "./types";
import styles from "./PostponeModal.module.scss";

type Props = {
  trainees: Array<SessionUser>;
  project: Project;
  onBack: () => void;
  onClose: () => void;
  partialPostpone: (params: PartialPostponeParams) => Promise<void>;
};
const PostponeModal = ({ trainees, onBack, onClose, partialPostpone, project }: Props) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState<string>(null);
  const [current, send] = useMachine(confirmMachine, {
    actions: {
      onBack: () => onBack(),
      onClose: () => onClose(),
    },
    services: {
      onConfirm: () => {
        return partialPostpone({
          rowsUuid: trainees.map((trainee) => trainee.row),
          comment,
        }).then(() => {
          onClose();
        });
      },
    },
  });

  return (
    <>
      <DSModal isOpen className={styles.PostponeModal}>
        <DSModal.Header onClose={onClose}>
          <DSModal.Header.Title
            title={t("trainings.manage.users.postpone.title", {
              count: trainees.length,
              defaultValue: "Reporter la demande pour {{ count }} stagiaire",
            })}
          />
          <DSModal.Header.SubHeader>
            <DSAvatarGroup
              size="S"
              tooltipDirection="bottom"
              maxItems={5}
              totalItems={trainees.length}
              users={trainees.map((trainee) => {
                return {
                  uuid: trainee.uuid,
                  fullName: trainee.fullName,
                  initials: getUserInitials(trainee),
                };
              })}
            />
          </DSModal.Header.SubHeader>
        </DSModal.Header>

        <DSModal.Content className={styles.content}>
          <p className={styles.description}>
            {t("trainings.manage.users.postpone.description", {
              count: trainees.length,
              defaultValue:
                "Si vous confirmez cette action, les stagiaires pourront être inscrits à d’autres sessions.",
            })}
          </p>

          <form onSubmit={(e) => e.preventDefault()}>
            <DSFormGroupTextInput
              label={t("trainings.manage.users.postpone.comment.label", {
                defaultValue: "Commentaire (facultatif)",
              })}
              assistiveText={t("trainings.manage.users.postpone.comment.assistiveText", {
                count: trainees.length,
                defaultValue:
                  "Le commentaire sera visible pour le manager et les responsables ayant accès au plan, mais ne sera pas transmis au collaborateur.",
              })}
            >
              <DSTextInput
                autoFocus={true}
                placeholder={t("trainings.manage.users.postpone.comment.placeholder", {
                  defaultValue: "Saisissez votre commentaire",
                })}
                onChange={(value) => setComment(value)}
                name="message"
              />
            </DSFormGroupTextInput>
          </form>

          <AttendeeNotificationAlert className={styles.attendeeNotificationAlert} type="postpone" project={project} trainees={trainees}/>
        </DSModal.Content>

        <DSModal.Footer>
          <DSModal.Footer.CancelButton
            disabled={current.context.loading}
            label={t("trainings.manage.users.form.goback", { defaultValue: "Retour" })}
            onClick={() => send("BACK")}
          />
          <DSModal.Footer.PrimaryButton
            disabled={current.context.loading}
            label={t("trainings.manage.users.form.postpone.next", {
              defaultValue: "Confirmer le report",
            })}
            onClick={() => send("CONFIRM")}
          />
        </DSModal.Footer>
      </DSModal>
    </>
  );
};

export default PostponeModal;