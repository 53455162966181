import { useMemo } from "react";

import { JobAnalytic } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import generateColumns from "../utils/generateJobsColumns";
import { DatatableJobFields } from "../utils/getJobsFields";
import generateTableRows from "../utils/generateJobsTableRows";

type Props = {
  t: TranslationType;
  jobs?: Array<JobAnalytic>;
};

export default function useJobsTableData({ jobs, t }: Props) {
  const tableData = useMemo(
    () => ({
      columns: generateColumns({ fields: DatatableJobFields, t }),
      rows: generateTableRows(jobs),
    }),
    [jobs, t]
  );

  return tableData;
}
