import React from "react";
import cx from "classnames";

import { DSTooltip } from "@skillup/ui";

import EasingEquations from "utils/EasingEquations";

import styles from "./DropDownActionMenuStyles.module.scss";

interface IProps {
  actions: Array<{
    action: () => void;
    label: string;
    disabled?: boolean;
    tooltip?: string;
    actionKey?: string;
  } | null>;
}

export default class Actions extends React.PureComponent<IProps, {}> {
  private actionMenu: HTMLElement | null;

  public componentDidMount(): void {
    if (!this.actionMenu) return;

    this.actionMenu.animate(
      [
        { opacity: 0, transform: "translateY(50px)" },
        { opacity: 1, transform: "translateY(0)" },
      ],
      {
        duration: 150,
        easing: EasingEquations.easeInOutSine,
        fill: "forwards",
      }
    );
  }

  public componentWillUnmount(): void {
    if (!this.actionMenu) return;

    this.actionMenu.animate(
      [
        { opacity: 1, transform: "translateY(0)" },
        { opacity: 0, transform: "translateY(50px)" },
      ],
      {
        duration: 150,
        easing: EasingEquations.easeInOutSine,
        fill: "forwards",
      }
    );
  }

  public render(): JSX.Element {
    const { actions = [] } = this.props;

    return (
      <div className={styles.Actions} ref={(el) => (this.actionMenu = el)}>
        {actions
          .filter((action) => action && action.label && action.action)
          .map((action) => (
            <div
              className={cx(styles.actionItem, {
                [styles.disabled]: action.disabled,
              })}
              onClick={!action.disabled ? action.action : undefined}
              key={action.label}
              data-action={action.actionKey || ""}
            >
              <DSTooltip disabled={!action.tooltip} label={action.tooltip}>
                {action.label}
              </DSTooltip>
            </div>
          ))}
      </div>
    );
  }
}
