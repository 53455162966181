import { GridColDef } from "@mui/x-data-grid-pro";
import type { TargetScale, TargetScalesResult } from "@skillup/espace-rh-bridge";
import { useMemo } from "react";

export type TargetScaleRow = ReturnType<typeof parseTableRows>;

export const useTargetScaleData = (
  scales: TargetScalesResult | undefined
): {
  data: TargetScaleRow[];
  columns: Array<GridColDef>;
} => {
  const columns = useMemo(generateColumns, []);
  const data = useMemo(() => generateTableRows(scales?.data ?? []), [scales]);

  return { columns, data };
};

function generateTableRows(scales: Array<TargetScale>) {
  return scales.map(parseTableRows);
}

const parseTableRows = (scale: TargetScale) => ({
  id: scale.uuid,
  data: {
    title: scale.title,
    levels: scale.scaleLevels,
  },
});

function generateColumns() {
  const columns = [
    {
      field: "title",
      headerName: "Intitulé de l'échelle",
      filterable: true,
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) => row.data.title,
    },
    {
      field: "levels",
      headerName: "Niveaux de l'échelle",
      filterable: false,
      sortable: false,
      minWidth: 500,
      flex: 2,
      valueGetter: ({ row }) => row.data.levels.map((level) => level.label).join(", "),
    },
  ];
  return columns;
}
