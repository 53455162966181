import { useEffect } from "react";
import TextField from "@mui/material/TextField";
import { DSFormGroupTextInput, AutocompleteBasic } from "@skillup/ui";

import { IIntra, useIntras } from "services/intras";

import styles from "./SelectIntraAutocomplete.module.scss";

function searchOption({
  name,
  orgName,
  reference,
  query,
}: {
  name: string;
  reference: string;
  orgName: string;
  query: string;
}) {
  const lowerCaseQuery = query?.toLowerCase() ?? "";
  return (
    name.toLowerCase().includes(lowerCaseQuery) ||
    orgName.toLowerCase().includes(lowerCaseQuery) ||
    reference?.toLowerCase()?.includes(lowerCaseQuery)
  );
}

const SelectIntraAutocomplete = ({
  intraUuid,
  intraReference,
  intra,
  onChange,
  placeholder,
}: {
  intraUuid?: string;
  intraReference?: string;
  intra?: IIntra;
  onChange: (intra: IIntra) => void;
  placeholder?: string;
}) => {
  const { data: intras = [] } = useIntras();

  useEffect(() => {
    let intra: IIntra;

    if (intraUuid) {
      intra = intras.find((intra) => intra.uuid === intraUuid);
    }

    if (intraReference) {
      intra = intras.find((intra) => intra.reference === intraReference);
    }

    if (!intra) {
      return undefined;
    }

    onChange(intra);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intras, intraUuid, intraReference]);

  return (
    <DSFormGroupTextInput className={styles.autocompleteInput} label="Programme" required>
      <AutocompleteBasic
        key={intra?.uuid}
        openOnFocus
        defaultValue={intra}
        options={intras}
        onChange={(_, intra) => onChange(intra)}
        disablePortal={true}
        getOptionLabel={(option) => option.name}
        placeholder={placeholder}
        filterOptions={(options, { inputValue }) => {
          return options.filter((option) =>
            searchOption({
              name: option.name,
              reference: option.reference,
              orgName: option.trainingOrganization,
              query: inputValue,
            })
          );
        }}
        renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
        renderOption={(props, intra) => {
          return (
            <li {...props} key={intra.uuid} className={styles.option} data-uuid={intra.uuid}>
              <div className={styles.orgAndLabel}>
                <span>{intra.name}</span>
                <span>{intra.trainingOrganization}</span>
              </div>
              <div className={styles.reference}>{intra.reference}</div>
            </li>
          );
        }}
      />
    </DSFormGroupTextInput>
  );
};

export default SelectIntraAutocomplete;
