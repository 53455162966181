import { useRef, useContext, useCallback, createContext } from "react";

import { trpc } from "utils/trpc";
import { Analytics, JobAnalytic, SkillAnalytic, AnalyticsFilters } from "types/skills";

type AnalyticsContextType = {
  getJobAnalytics: (filters?: AnalyticsFilters) => {
    data: Array<JobAnalytic>;
    status: "error" | "loading" | "success";
    error: ReturnType<typeof trpc.analytics.getAnalytics.useQuery>["error"];
  };
  getSkillAnalytics: (filters?: AnalyticsFilters) => {
    data: Array<SkillAnalytic>;
    status: "error" | "loading" | "success";
    error: ReturnType<typeof trpc.analytics.getAnalytics.useQuery>["error"];
  };
};

const AnalyticsContext = createContext<AnalyticsContextType>(null);

const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalyticsContext must be used within an AnalyticsContext");
  }
  return context;
};

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const data = useRef<Analytics>(undefined);
  const error = useRef(undefined);
  const status = useRef(undefined);

  const fetchAnalytics = useCallback((filters: AnalyticsFilters = undefined) => {
    const response = trpc.analytics.getAnalytics.useQuery(
      {
        filters: {
          employees: {
            perimeters: filters?.perimeters,
          },
        },
      },
      {
        keepPreviousData: true,
      }
    );

    data.current = response.data as unknown as Analytics;
    error.current = response.error;
    status.current = response.status;
  }, []);

  fetchAnalytics();

  const getJobAnalytics = useCallback(
    (filters) => {
      fetchAnalytics(filters);

      const sortedJobAnalytics = data.current
        ? data.current.jobAnalytics.sort((a, b) => b.employeesEvaluated - a.employeesEvaluated)
        : undefined;
      return {
        data: sortedJobAnalytics as unknown as Array<JobAnalytic>,
        error: error.current,
        status: status.current,
      };
    },
    [fetchAnalytics]
  );

  const getSkillAnalytics = useCallback(
    (filters) => {
      fetchAnalytics(filters);

      const sortedSkillAnalytics = data.current
        ? data.current.skillAnalytics.sort((a, b) => b.employeesEvaluated - a.employeesEvaluated)
        : undefined;
      return {
        data: sortedSkillAnalytics as Array<SkillAnalytic>,
        error: error.current,
        status: status.current,
      };
    },
    [fetchAnalytics]
  );

  return (
    <AnalyticsContext.Provider
      value={{
        getJobAnalytics,
        getSkillAnalytics,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export { AnalyticsContext, AnalyticsProvider, useAnalyticsContext };
