import stateFiltering from "./stateFilter";
import startDateFiltering from "./startDateFilter";
import trainingFiltering from "./trainingFilter";
import trainingOrganizationFiltering from "./trainingOrganizationFilter";
import remainingPlacesFn from "./remainingPlaces";

import {
  TrainingNameCell,
  StartDateCell,
  PositionnedTraineesCell,
  TotalPriceCell,
  StateCell,
  StateFilter,
  AreasCell,
  CancelledAtCell,
  CancelledByCell,
  CancelledMessageCell,
  TrainerCell,
  TrainingOrganization,
} from "../Components/DataTable";
import cancelledAtFilter from "./cancelledAtFilter";
import cancelledByFilter from "./cancelledByFilter";
import cancelledMessageFilter from "./cancelledMessageFilter";
import { ReferenceCell } from "../Components/DataTable/Cell";

export const allColumns = [
  {
    Header: "Statut",
    accessor: "state",
    filter: stateFiltering,
    Filter: StateFilter,
    Cell: StateCell,
    width: 150,
  },
  {
    Header: "Formation",
    accessor: "trainingName",
    filter: trainingFiltering,
    Cell: TrainingNameCell,
    width: 250,
  },
  {
    Header: "Organisme",
    accessor: "trainingOrganization",
    filter: trainingOrganizationFiltering,
    Cell: TrainingOrganization,
    width: 150,
  },
  {
    Header: "Date de session",
    accessor: "startDate",
    filter: startDateFiltering,
    Cell: StartDateCell,
    width: 150,
  },
  {
    Header: "Lieu",
    accessor: "sessionCity",
    width: 125,
  },
  {
    Header: "Participants",
    accessor: "positionnedTraineesCount",
    Cell: PositionnedTraineesCell,
    width: 125,
  },
  {
    id: "remainingTraineesCount",
    Header: "Places restantes",
    accessor: remainingPlacesFn,
    width: 100,
  },
  {
    Header: "Prix",
    accessor: "totalPrice",
    Cell: TotalPriceCell,
    width: 150,
  },
  {
    Header: "Périmètres",
    accessor: "areaNames",
    Cell: AreasCell,
    width: 150,
  },
  {
    Header: "Formateur",
    accessor: "trainerNames",
    Cell: TrainerCell,
    width: 150,
  },
  {
    Header: "ID",
    accessor: "reference",
    Cell: ReferenceCell,
    width: 150,
  },
];

export const cancelledColumns = [
  {
    Header: "Statut",
    accessor: "state",
    filter: stateFiltering,
    Filter: StateFilter,
    Cell: StateCell,
    width: 150,
  },
  {
    Header: "Formation",
    accessor: "trainingName",
    filter: trainingFiltering,
    Cell: TrainingNameCell,
    width: 250,
  },
  {
    Header: "Date de session",
    accessor: "startDate",
    filter: startDateFiltering,
    Cell: StartDateCell,
    width: 150,
  },
  {
    Header: "Lieu",
    accessor: "sessionCity",
    width: 125,
  },
  {
    Header: "Date d'annulation",
    accessor: "cancelledAt",
    filter: cancelledAtFilter,
    Cell: CancelledAtCell,
    width: 125,
  },
  {
    Header: "Annulée par",
    accessor: "cancelledBy",
    filter: cancelledByFilter,
    Cell: CancelledByCell,
    width: 125,
  },
  {
    Header: "Commentaire d'annulation",
    accessor: "cancelledMessage",
    filter: cancelledMessageFilter,
    Cell: CancelledMessageCell,
    width: 250,
  },
];
