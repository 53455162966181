import { useState } from "react";

import { useQuery, useMutation } from "@tanstack/react-query";

import { CampaignsRoutes } from "@skillup/people-review-bridge";

import { EnumerableField } from "services/coreHR";
import { CampaignDetails } from "services/peopleReview";
import { getCampaignMatrix } from "services/peopleReview/campaign/getCampaignMatrix";

type MatrixData = CampaignsRoutes.GetMatrix["response"];

type Options = {
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
};

const defaultOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};

type UseMatrixDataParams = {
  campaign: CampaignDetails;
  enumerableFieldsMap: Record<string, EnumerableField>;
};

const useMatrixData = (
  { campaign, enumerableFieldsMap }: UseMatrixDataParams,
  options: Options = defaultOptions
) => {
  const [axis, setAxis] = useState<{
    x: EnumerableField;
    y: EnumerableField;
  }>({
    x: enumerableFieldsMap[campaign.fields[0]],
    y: enumerableFieldsMap[campaign.fields[1]],
  });

  const { data, isFetching, isLoading } = useQuery(
    ["matrix", axis.x, axis.y],
    () => {
      return getCampaignMatrix({
        campaignID: campaign.id,
        xAxisFieldID: axis.x.uuid,
        yAxisFieldID: axis.y.uuid,
      });
    },
    options
  );

  const swapMutations = useMutation({
    mutationFn: async ({
      newXAxisFieldID,
      newYAxisFieldID,
    }: {
      newXAxisFieldID: string;
      newYAxisFieldID: string;
    }) => {
      setAxis({
        x: enumerableFieldsMap[newXAxisFieldID],
        y: enumerableFieldsMap[newYAxisFieldID],
      });
    },
  });

  return {
    data,
    isLoading: isLoading || isFetching,
    mutations: {
      swap: swapMutations.mutate,
    },
    xAxis: axis.x,
    yAxis: axis.y,
  };
};

export { useMatrixData, type MatrixData };
