enum Actions {
  MODIFY = "modify",
  DELETE = "delete",
  ADD_TRACKINGS_IN_BULK = "addTrackingsInBulk",
}

enum HabilitationValidity {
  LIMITED = "limited",
  ADVITAM = "advitam",
}

export type HabilitationAction = {
  type: `${Actions}`;
  disabled?: true;
  tooltip?: string;
};

export type HabilitationsList = (
  | {
      duration: string;
      deadlineAnticipation: string;
      validity: "limited";
      name: string;
      uuid: string;
      category: {
        uuid: string;
        name: string;
      };
      trackingsCount: number;
      actions: HabilitationAction[];
    }
  | {
      validity: "advitam";
      name: string;
      uuid: string;
      category: {
        uuid: string;
        name: string;
      };
      trackingsCount: number;
      actions: HabilitationAction[];
    }
)[];

export type LimitedHabilitationPayload = {
  name: string;
  validity: HabilitationValidity.ADVITAM;
};
export type AdVitamHabilitationPayload = {
  name: string;
  validity: HabilitationValidity.LIMITED;
  duration: string;
  deadlineAnticipation: string;
};

export type HabilitationPayload = AdVitamHabilitationPayload | LimitedHabilitationPayload;

export type CreateHabilitationPayload = Omit<HabilitationPayload, "validity"> & {
  validity: "limited" | "advitam";
};

export namespace CreateHabilitationErrors {
  export type CouldNotLinkToCompany = "habilitation.create.could-not-link-to-company";
  export type CouldNotLinkToCategory = "habilitation.create.failed_to_link_to_category";
  export type InvalidPayload = "habilitation.create.invalid_payload";
}

type HabilitationDoesNotBelongToCompany = "habilitation-does-not-belong-to-company";

export namespace DeleteHabilitationErrors {
  export type DoesNotBelongToCompany = HabilitationDoesNotBelongToCompany;
  export type AlreadyTracked = "habilitation.delete.already-tracked";
  export type NotFound = "habilitation.delete.not-found";
}

export namespace UpdateHabilitationErrors {
  export type InvalidPayload = "habilitation.update.invalid-payload";
  export type CouldNotLinkToCategory = "habilitation.update.could-not-link-to-category";
  export type CouldNotRemoveLinkToCategory =
    "habilitation.update.could-not-remove-link-to-category";
  export type DoesNotBelongToCompany = HabilitationDoesNotBelongToCompany;
}

export type UpdateHabilitationPayload = Omit<HabilitationPayload, "validity"> & {
  validity: "limited" | "advitam";
};
