export const SkuiSpacing = {
  "3xl": "3.5rem",
  jumbo: "4rem",
  l: "2rem",
  m: "1.5rem",
  s: "1rem",
  xl: "2.5rem",
  xs: "0.5rem",
  xxl: "3rem",
  xxs: "0.25rem",
};
