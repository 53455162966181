import React from "react";
import cx from "classnames";

import { type Themes } from "../commonProps";

import styles from "./SkillsList.module.scss";
import { SkillItem } from "./components/SkillItem";

interface SkillsProps {
  readonly name: string;
  readonly skillUuid: string;
  readonly level?: number;
  readonly totalLevel: Array<{ level: number }>;
  readonly generated: boolean;
}

interface CategoryProps {
  readonly name: string;
  readonly skills: SkillsProps[];
}

export interface SkillsListProps {
  readonly categories: CategoryProps[];
  readonly className?: string;
  readonly darkMode?: boolean;
  readonly theme: Themes;
  readonly onEdit: (skillUuid: string) => void;
  readonly onRemove: (skillUuid: string) => void;
}

const SkillsList = ({
  categories,
  theme,
  darkMode,
  className,
  onEdit,
  onRemove,
}: SkillsListProps) => {
  return (
    <div
      className={cx(
        styles.SkillsList,
        styles[theme],
        {
          [styles.darkMode]: darkMode,
        },
        className
      )}
    >
      {categories.map((category, i) => {
        return (
          <div key={i}>
            <div className={styles.categoryName}>{category.name}</div>
            <div
              className={cx(styles.categoriesContainer, {
                [styles.generatedCategory]: category.skills[0].generated,
              })}
            >
              {category.skills.map((skill, i) => {
                return <SkillItem skill={skill} key={i} onEdit={onEdit} onRemove={onRemove} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export { SkillsList };
