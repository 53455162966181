import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid-pro";

export const initialState = {
  pagination: {
    paginationModel: {
      pageSize: 20,
      page: 0,
    },
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ["actions"],
  },
};