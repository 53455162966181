import { formatMonetary, type FormatMonetaryOptions } from "@skillup/shared-utils";
import { type ScheduleRowExposedStates } from "../../domain/Trainings/types";

type TFunction = (
  key: string,
  options?: {
    [key: string]: any;
    defaultValue?: string;
  }
) => string;

interface Props {
  t: TFunction;
  createdAt?: string;
  startDate?: string;
  endDate?: string;
  state: ScheduleRowExposedStates;
}

export const isDateYearFormat = (date: string) => {
  return date.length === 4;
};

export const DateRangeFormat = {
  SESSION_DATE: "SESSION_DATE",
  SESSION_DATE_RANGE: "SESSION_DATE_RANGE",
  CREATE_DATE: "CREATE_DATE",
  UNKNOWN: "UNKNOWN",
} as const;
export type DateRangeFormats = `${keyof typeof DateRangeFormat}`;

export const getYearFromDateLabel = (date: string) => {
  return date.slice(-4) ?? "-";
};

export function getScheduleRowDateRangeFormat({
  createdAt,
  startDate,
  endDate,
  state,
}: Omit<Props, "t">) {
  if ((state === "subscribed" || state === "realized") && startDate) {
    if (!endDate || startDate === endDate) {
      return DateRangeFormat.SESSION_DATE;
    }

    return DateRangeFormat.SESSION_DATE_RANGE;
  }

  if (createdAt) {
    return DateRangeFormat.CREATE_DATE;
  }

  return DateRangeFormat.UNKNOWN;
}

export function formatScheduleRowDateRange(
  { t, createdAt, startDate, endDate, state }: Props,
  defaultFormat?: DateRangeFormats
) {
  const format =
    defaultFormat ?? getScheduleRowDateRangeFormat({ createdAt, startDate, endDate, state });

  switch (format) {
    case DateRangeFormat.SESSION_DATE:
      return t("single.date", {
        startDate: startDate,
        defaultValue: "{{ startDate }}",
      });
    case DateRangeFormat.SESSION_DATE_RANGE:
      // We are sure that startDate and endDate are defined because of the format value
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const dateIsYearFormat = startDate!.length === 4 && endDate!.length === 4;

      if (dateIsYearFormat) {
        return `${t("span.date.startYear", {
          startDate: startDate,
          defaultValue: "De {{ startDate }}",
        })} ${t("span.date.endYear", {
          endDate: endDate,
          defaultValue: "à {{ endDate }}",
        })}`;
      }

      return `${t("span.date.start", {
        startDate: startDate,
        defaultValue: "Du {{ startDate }}",
      })} ${t("span.date.end", {
        endDate: endDate,
        defaultValue: "au {{ endDate }}",
      })}`;
    case DateRangeFormat.CREATE_DATE:
      return createdAt;
    default:
      return undefined;
  }
}

export function getScheduleRowDateSourceLabel(
  { t, createdAt, startDate, endDate, state }: Props,
  defaultFormat?: DateRangeFormats
) {
  const format =
    defaultFormat ?? getScheduleRowDateRangeFormat({ createdAt, startDate, endDate, state });

  switch (format) {
    case DateRangeFormat.SESSION_DATE:
      return t("date.session.label", {
        defaultValue: "Date de la session",
      });
    case DateRangeFormat.SESSION_DATE_RANGE:
      return t("date.session.label.plural", {
        defaultValue: "Dates de la session",
      });
    case DateRangeFormat.CREATE_DATE:
      return t("date.create.label", {
        defaultValue: "Date de la demande",
      });
    default:
      return undefined;
  }
}

export function formatScheduleRowDateAndLabel(props: Props) {
  const format = getScheduleRowDateRangeFormat(props);
  const value = formatScheduleRowDateRange(props);
  const label = getScheduleRowDateSourceLabel(props, format);

  if (!value || !label) {
    return undefined;
  }

  return { value, label };
}

export function getScheduleRowDateSentence(props: Props) {
  const { t, createdAt, startDate } = props;

  const format = getScheduleRowDateRangeFormat(props);
  const value = formatScheduleRowDateRange(props, format);

  switch (format) {
    case DateRangeFormat.SESSION_DATE:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (isDateYearFormat(startDate!)) {
        return t("date.session.sentence.obfuscated", {
          starDate: value,
          defaultValue: "Session de {{ starDate }}",
        });
      }
      return t("date.session.sentence", {
        starDate: value,
        defaultValue: "Session du {{ starDate }}",
      });
    case DateRangeFormat.SESSION_DATE_RANGE:
      return t("date.session.sentence.plural", {
        range: value,
        defaultValue: "Sessions {{ range }}",
      });
    case DateRangeFormat.CREATE_DATE:
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (isDateYearFormat(createdAt!)) {
        return t("date.create.sentence.obfuscated", {
          createdAt: value,
          defaultValue: "Date de la demande de {{ createdAt }}",
        });
      }
      return t("date.create.sentence", {
        createdAt: value,
        defaultValue: "Date de la demande du {{ createdAt }}",
      });
    default:
      return undefined;
  }
}

export function formatScheduleRowPrice({
  price,
  options,
}: {
  price: number | null | undefined;
  options?: FormatMonetaryOptions;
}) {
  // undefined: Obfuscated or not available / null: Price is disabled for company
  if (price === undefined || price === null) {
    return "-";
  }

  return formatMonetary(price, options);
}

export function formatDurationAsHours({ t, duration }: { t: TFunction; duration?: string }) {
  if (!duration) {
    return "-";
  }

  return t("training.single.duration.hours", {
    duration,
    defaultValue: "{{ duration }} heures",
  });
}
