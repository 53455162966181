export default {
  contrastColor: "#ffd042",
  mainColor: "#1f6389",
  mainColorD: "#0e2d3e",
  mainColorL: "#f5f8fc",

  error: "#ff3d33",
  errorD: "#db0b00",
  errorL: "#fdeded",
  info: "#378ad4",
  infoD: "#134979",
  infoL: "#96bde0",
  success: "#92c123",
  successD: "#4d6908",
  successL: "#c9ec74",
  warning: "#f39400",
  warningD: "#8c590b",
  warningL: "#ffd088",

  headerAltColor: "#4c4c4c",
  highlightColor: "#ffd042",
  paragraphColor: "#2e5062",
  primaryColor: "#2099cc",
  secondaryColor: "#40c4f4",
  smallTextColor: "#aaa",
  textColor: "#333",

  borderColor: "#cbcbcb",
  borderColorAlt: "#e5e5e5",
  borderColorLight: "#f5f5f5",
  borderColorLightAlt: "#eaeaea",

  altScoreColor: "#8c8c8c",
  scoreColor: "#78bb5a",

  blue: "#2782ff",
  blueDarker: "#2b71f6",
  yellow: "#ffd042",

  bigBoxShadow: "rgba(40,139,255,0.3) 0 0 20px",
  buttonBoxShadow: "rgba(40,139,255,0.3) 0 2px 5px",
  buttonHoverBoxShadow: "rgba(40,139,255,0.3) 0 3px 7px",
  dataTableActiveLine: "#f6f6F6",
  dataTableRegularBorder: "#e9e9e9",

  gradientBlue: "linear-gradient( 45deg, #0066ff, #00b4ff )",
  gradientGreen: "linear-gradient( 45deg, #88a000, #a0e400 )",
  gradientMain: "linear-gradient( 45deg, #0066ff, #00b4ff )",
  gradientOrange: "linear-gradient( 45deg, #c75d00, #ff9b00 )",
  gradientPink: "linear-gradient( 45deg, #9600c7, #fb11d3 )",
  gradientRed: "linear-gradient( 45deg, #9e0000, #ff3636 )",
  gradientYellow: "linear-gradient( 45deg, rgb(249, 172, 0), rgb(255, 214, 90) )",
};
