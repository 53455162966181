import { isNil } from "lodash";

import { ISO8601Date } from "@skillup/espace-rh-bridge";
import { InterviewWithStates, IRoutes } from "@skillup/types";
import { DSButton, generateModalContext } from "@skillup/ui";

import Acta from "utils/Acta";

import { CampaignFollowUpModal } from "../modals";
import { AddUserModal } from "../modals";
import { DownloadRepliesButton } from "../DownloadRepliesButton";

import styles from "./InterviewsHeader.module.scss";

interface IProps {
  readonly campaign: {
    uuid: string;
    titleForHR: string;
    type: IRoutes["/campaigns"]["GET"]["/"][0]["type"];
    archivedAt?: ISO8601Date;
  };
  readonly interviews: InterviewWithStates[];
  readonly resync: () => Promise<InterviewWithStates[]>;
}

const InterviewsHeader = ({ campaign, interviews, resync }: IProps): JSX.Element => {
  const useModal = generateModalContext();
  const { isOpen, openModal: openFollowupModal, closeModal } = useModal(false);

  const isCampaignClosed = !isNil(campaign.archivedAt);
  const cantAddUserTooltip = "Vous ne pouvez pas ajouter d'entretien à une campagne clôturée";
  const cantRemindTooltipOnClosedCampaign =
    "Vous ne pouvez pas relancer les entretiens d'une campagne clôturée";
  const cantRemindTooltipOnEmptyCampaign = "Aucun entretien dans cette campagne";
  return (
    <div className={styles.InterviewsHeader}>
      <DownloadRepliesButton interviewsUuids={interviews.map((interview) => interview.uuid)} />
      {campaign.type !== "adHoc" && (
        <DSButton
          disabled={isCampaignClosed || !interviews.length}
          tooltip={
            isCampaignClosed
              ? cantRemindTooltipOnClosedCampaign
              : interviews.length === 0
              ? cantRemindTooltipOnEmptyCampaign
              : undefined
          }
          name="reminder"
          label="Relancer"
          buttonSize="M"
          fontWeight="light"
          emphasis="Mid"
          onClick={openFollowupModal}
        />
      )}
      <DSButton
        disabled={isCampaignClosed}
        tooltip={isCampaignClosed ? cantAddUserTooltip : undefined}
        buttonSize="M"
        label="Ajouter un entretien"
        fontWeight="light"
        name="add-users"
        emphasis="High"
        onClick={() =>
          Acta.setState("modalDisplayed", {
            content: (
              <AddUserModal
                campaignUuid={campaign.uuid}
                interviews={interviews}
                resync={resync}
                campaignType={campaign.type}
              />
            ),
            size: "small",
            title: "Ajouter un entretien",
            showOverflow: true,
          })
        }
      />

      <CampaignFollowUpModal isOpen={isOpen} campaignUuid={campaign.uuid} closeModal={closeModal} />
    </div>
  );
};

export default InterviewsHeader;
