import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ReviewRoutes } from "@skillup/people-review-bridge";

import { buildRequest } from "utils/buildRequest";

export function useChangeManager({ campaignID }: { campaignID: string }) {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    async ({ managerID, reviewIDs }: { managerID: string; reviewIDs: string[] }) => {
      try {
        await buildRequest<ReviewRoutes.ChangeManager>({
          method: "POST",
          path: "/review/change-manager",
          payload: {
            campaignID,
            managerID,
            reviewIDs,
          },
          target: "PEOPLE_REVIEW",
        })();
      } catch (err) {
        console.error(err);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["campaigns", "details", campaignID]);
        queryClient.invalidateQueries(["people-reviews", campaignID]);
      },
    }
  );

  return { changeManager: mutate };
}
