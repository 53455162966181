import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getTimeline } from "./getTimeline";
import { DatasetKey } from "@skillup/core-hr-bridge";

function useCoreHRRecordsRefresh(key: string) {
  const queryClient = useQueryClient();

  function refresh() {
    queryClient.invalidateQueries(["core-hr-timeline", key]);
  }

  return refresh;
}

export function useCoreHRRecords({
  userUuid,
  key,
  options = defaultOptions,
}: {
  userUuid: string;
  key: DatasetKey;
  options?: Options;
}) {
  const refreshRecords = useCoreHRRecordsRefresh(key);

  const query = useQuery(["core-hr-timeline", key], () => getTimeline(key, userUuid), {
    ...defaultOptions,
    ...options,
  });

  return {
    timelineRecords: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    refreshRecords,
  };
}

type Options = {
  refetchOnWindowFocus?: boolean;
  refetchOnMount?: boolean;
};

const defaultOptions = {
  refetchOnWindowFocus: true,
  refetchOnMount: true,
};
