import React, { PureComponent } from "react";
import cx from "classnames";

import TextInput from "components/TextInput";
import DropDown from "components/DropDown";
import { Column, Filter, Row, FilterValue } from "components/DataTable/types";
import { cross as crossIcon } from "uiAssets/StrokeIcons";
import Icon from "components/Icon";

import { emptyFilter } from "constants/dataTable";

import SelectFilters from "../Filters/SelectFilters";
import styles from "./FilterModalStyles.module.scss";
import { ReturnedColumn } from "containers/Supervisor/routes/TrainingSchedule/utils/loadPartialData";

const numberFilterOptions = [
  { value: "equal", label: "=" },
  { value: "inferior", label: "<" },
  { value: "superior", label: ">" },
] as const;

export interface IProps {
  column: Column;
  currentFilter: Filter;
  onClose: () => void;
  setSort?: (target?: Column, direction?: "up" | "down") => void;
  updateFilter: (column: Column, value: FilterValue | string, useDebounce?: boolean) => void;
  deleteColumn: () => void;
  scrollContainer: HTMLElement;
  rows: Array<Row>;
  returnedColumns?: ReturnedColumn[];
}

interface IState {
  left: number;
  defaultLabel: string;
  isDeleting: boolean;
  numberFilterValue?: number;
}

export default class FilterModal extends PureComponent<IProps, IState> {
  private oldScrollLeft: number;

  constructor(props) {
    super(props);

    this.oldScrollLeft = props.scrollContainer.scrollLeft;

    this.state = {
      left: this.computePosition(props.column.key),
      defaultLabel: props.column.label,
      isDeleting: false,
      numberFilterValue: (this.props.currentFilter?.value as FilterValue)?.limit,
    };
  }

  private container: HTMLMenuElement;
  private innerContainer: HTMLElement;
  private quitIcon: HTMLElement;
  private numberFilterType: DropDown<typeof numberFilterOptions>;

  public componentDidMount(): void {
    setTimeout(() => this.selfShow(), 100);
  }

  // compute the left position of the filter modal
  private computePosition = (columnKey) => {
    const modalPosition = document.getElementById(`${columnKey}-filter-button`);
    if (!modalPosition) {
      return 0;
    }
    const scrollcontainerRect = this.props.scrollContainer.getBoundingClientRect();
    return modalPosition.getBoundingClientRect().left - scrollcontainerRect.left;
  };

  private selfShow = () => {
    if (!this.container || !this.innerContainer) return;

    this.container.style.display = "block";
    this.innerContainer.classList.add(styles.visible);
    this.quitIcon.style.opacity = "1";
    ["mousedown", "mouseup", "scroll"].forEach((e: string) => {
      this.container.addEventListener(e, this.stopProp);
    });
    window.addEventListener("mousedown", this.selfClose);
    window.addEventListener("keydown", this.closeOnEnter);

    if (this.props.scrollContainer) {
      this.props.scrollContainer.addEventListener("scroll", this.handleScroll);
    }
  };

  private handleScroll = (_event) => {
    if (this.oldScrollLeft !== this.props.scrollContainer.scrollLeft) {
      this.selfClose();
    }
  };

  private selfClose = () => {
    if (this.props.scrollContainer) {
      this.props.scrollContainer.removeEventListener("scroll", this.handleScroll);
    }
    window.removeEventListener("mousedown", this.selfClose);
    window.removeEventListener("keydown", this.closeOnEnter);

    if (!this.container || !this.innerContainer) return;

    this.innerContainer.classList.toggle(styles.visible);
    this.quitIcon.style.opacity = "0";

    ["mousedown", "mouseup", "scroll"].forEach((e: string) => {
      this.container.removeEventListener(e, this.stopProp);
    });

    this.container.style.display = "none";
    this.container.style.overflow = "hidden";

    window.setTimeout(() => this.props.onClose(), 0);
  };

  private stopProp = (e) => {
    e.stopPropagation();
  };

  private closeOnEnter = (e) => {
    if (e.keyCode === 13 || e.keyCode === 27) this.selfClose();
  };

  private numberFilterValueChanged = () => {
    if (
      !this.numberFilterType ||
      !this.state.numberFilterValue ||
      // if any of the following is null it can be interpreted as a filter on 0
      !this.numberFilterType.getValue()
    ) {
      return;
    }

    this.props.updateFilter(
      this.props.column,
      this.state.numberFilterValue
        ? {
            filterType: this.numberFilterType.getValue(),
            limit: this.state.numberFilterValue,
          }
        : null,
      true
    );
  };

  private renderFilters = () => {
    const { column, currentFilter, updateFilter, returnedColumns, rows } = this.props;
    const colName = column.binding;
    if ((column.filterType ?? column.type) === "select") {
      const options = column.options
        ? column.options[(column.optionSelected as string) || "default"]
        : null;
      const values = returnedColumns
        ? returnedColumns.find((o) => o.key === colName).values
        : Array.from(new Set(rows.map((r) => r.columns?.[column.key]?.value as string | number)));

      const filterOptions = options
        ? options.reduce(
            (acc, val) => (values.some((v) => v === val.value) ? [...acc, val] : acc),
            []
          )
        : values
            .filter((value) => !!value)
            .sort((a: string, b: string) => a.localeCompare(b))
            .map((value) => ({ value }));

      const hasEmptyValues = options
        ? values.some((v) => !options.some((o) => o.value === v))
        : !!values.filter((value) => !value).length;
      if (hasEmptyValues) filterOptions.push(emptyFilter);

      return (
        filterOptions && (
          <SelectFilters
            onChange={(e) => updateFilter(column, e)}
            filter={currentFilter && currentFilter.value}
            options={filterOptions}
          />
        )
      );
    }

    return (
      <TextInput
        onChange={(event) => updateFilter(column, event.currentTarget.value, true)}
        defaultValue={currentFilter && (currentFilter.value as string)}
        style={{ paddingTop: "0px" }}
        placeholder="Filtrer les lignes contenant..."
        alwaysOpen
        autoFocus
        autoComplete="off"
        small
      />
    );
  };

  public render(): JSX.Element {
    const { column, setSort } = this.props;
    const { left } = this.state;
    const isColumnNumeric = column.type === "price" || column.type === "number";
    const filters = this.renderFilters();

    const isHiddenByLeftScroll = left - 270 < 0;
    const leftAsPx = `${left - (isHiddenByLeftScroll ? 38 : 228)}px`;

    //left: leftAsPx,

    return (
      <menu
        className={cx(styles.FilterModal, { [styles.isHiddenByLeftScroll]: isHiddenByLeftScroll })}
        style={{ left: leftAsPx }}
        ref={(menu) => (this.container = menu as HTMLMenuElement)}
      >
        <button ref={(btn) => (this.quitIcon = btn)} onClick={this.selfClose}>
          <Icon strokeIcon={crossIcon} width={12} />
        </button>
        <div ref={(div) => (this.innerContainer = div)}>
          <div>
            <div className={styles.containerFilters}>
              <h3>Filtrer</h3>
              <div className={styles.filterInputs}>{filters}</div>
            </div>
            <div className={styles.separator} />
            {setSort && (
              <React.Fragment>
                <div className={styles.titleSortLink}>
                  <div>Trier</div>
                </div>
                <div className={styles.sortLink} onClick={() => setSort(column, "up")}>
                  Trier de {isColumnNumeric ? "0 \u2192 9" : "A \u2192 Z"}
                </div>
                <div className={styles.sortLink} onClick={() => setSort(column, "down")}>
                  Trier de {isColumnNumeric ? "9 \u2192 0" : "Z \u2192 A"}
                </div>
              </React.Fragment>
            )}
          </div>
          {/* {(column.editable || column.removable) && (
            <React.Fragment>
              <div className={styles.separator} />
              <div className={styles.containerFilters}>
                <h3>Modifier la colonne</h3>
                {column.editable && (
                  <TextInput
                    onChange={e => updateColumTitle(e, column)}
                    defaultValue={defaultLabel}
                    strokeIcon={textIcon}
                    label="Titre de la colonne"
                    placeholder="Entrer un titre"
                    ref={input => (this.titleInput = input)}
                  />
                )}
                {column.removable && (
                  <div className={styles.deleteContainer}>
                    {isDeleting ? (
                      <React.Fragment>
                        <InteractiveButton
                          label="Annuler"
                          strokeIconLeft={chevronBottomIcon}
                          size="small"
                          background="#666"
                          onClick={() => this.setState({ isDeleting: false })}
                        />
                        <InteractiveButton
                          label="Confirmer"
                          strokeIconLeft={trashBinIcon}
                          size="small"
                          background={Colors.error}
                          onClick={() => {
                            this.selfClose();
                            deleteColumn(column);
                          }}
                        />
                      </React.Fragment>
                    ) : (
                      <InteractiveButton
                        label="Supprimer la colone"
                        strokeIconLeft={trashBinIcon}
                        size="small"
                        background={Colors.error}
                        onClick={() => this.setState({ isDeleting: true })}
                      />
                    )}
                  </div>
                )}
              </div>
            </React.Fragment>
          )} */}
        </div>
      </menu>
    );
  }
}
