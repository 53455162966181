import { useState } from "react";

import { Config } from "@skillup/training-bridge";
import { ListUtils } from "@skillup/shared-utils";
import { useUrlSyncedFilters } from "@skillup/ui";

import { TranslationType } from "hooks/useTranslation";
import useAreas from "hooks/useAreas";

type FiltersInputType = {
  area: {
    visibilityMode: ListUtils.FilterVisibility;
    options: {
      label: string;
      value: string;
    }[];
  };
  reviewStatus: {
    options: {
      label: string;
      value: string;
    }[];
  };
};

export const useReviewFilters = (t: TranslationType) => {
  const { allAreas } = useAreas();

  const [filtersInput] = useState<FiltersInputType>({
    area: {
      visibilityMode: "always",
      options: allAreas.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })),
    },
    reviewStatus: {
      options: [
        {
          label: t("review.status.pending", {
            defaultValue: "Non envoyée",
          }),
          value: "pending",
        },
        {
          label: t("review.status.submitted", {
            defaultValue: "En attente",
          }),
          value: "submitted",
        },
        {
          label: t("review.status.completed", {
            defaultValue: "Complétée",
          }),
          value: "completed",
        },
        {
          label: t("review.status.cancelled", {
            defaultValue: "Annulée",
          }),
          value: "cancelled",
        },
        {
          label: t("review.status.abandoned", {
            defaultValue: "Expirée",
          }),
          value: "abandoned",
        },
      ],
    },
  });

  const [filters, filterValues, setFilterValues] = useUrlSyncedFilters(
    Config.Reviews.Filters,
    filtersInput
  );

  return [filters, filterValues, setFilterValues] as [
    typeof filters,
    typeof filterValues,
    typeof setFilterValues
  ];
};

export default useReviewFilters;
