import { DSDropdownDivider } from "@skillup/ui";
import { TemplatePreviewRoles } from "@skillup/espace-rh-bridge";

import type { Template, TemplateAction } from "services/interviews";
import { State as ActionsModalState } from "../hooks";
import { TemplateActions } from "../components";
import { asyncPreviewAction } from "../utils";

export const getActions = (
  template: Template,
  setActionsState: (state: ActionsModalState) => void,
  canDo: (action: TemplateAction) => boolean,
  previewLinks: () => Promise<Record<TemplatePreviewRoles, string>>
) => {
  const actions = [];

  if (canDo("update")) {
    actions.push(
      TemplateActions.Edit({
        template: template,
        setActionsState: setActionsState,
        relatedCampaigns: template.relatedCampaigns,
      })
    );
  }

  if (canDo("rename")) {
    actions.push(TemplateActions.Rename({ template: template, setActionsState: setActionsState }));
  }

  if (canDo("archive")) {
    actions.push(TemplateActions.Archive({ template: template, setActionsState: setActionsState }));
  }

  if (canDo("duplicate")) {
    actions.push(TemplateActions.Duplicate({ template: template }));
    actions.push(<DSDropdownDivider />);
  }

  if (canDo("downloadPDF")) {
    actions.push(TemplateActions.DownloadPDF({ templateUuid: template.uuid }));
  }

  if (canDo("preview")) {
    actions.push(
      TemplateActions.Preview({
        as: "collab",
        asyncAction: () => asyncPreviewAction("collab", previewLinks),
      })
    );
    actions.push(
      TemplateActions.Preview({
        as: "manager",
        asyncAction: () => asyncPreviewAction("manager", previewLinks),
      })
    );
  }

  if (canDo("transfer")) {
    actions.push(<DSDropdownDivider />);
    actions.push(
      TemplateActions.TransferCopy({ template: template, setActionsState: setActionsState })
    );
  }

  if (canDo("createCampaign")) {
    actions.push(<DSDropdownDivider />);
    actions.push(
      TemplateActions.CreateCampaign({ template: template, setActionsState: setActionsState })
    );
  }

  return actions;
};
