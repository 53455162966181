import { useMemo } from "react";
import { getActiveSchedule } from "utils/User";
import useSettings from "hooks/useSettings";
import createUserAccessChecker from "hooks/userAccessChecker";

export const useDefaultRedirectionPath = () => {
  const activeSchedule = useMemo(() => {
    return getActiveSchedule();
  }, []);

  const { settings, userAccessModules } = useSettings();

  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);

  const defaultRedirectionPath = useMemo(() => {
    // Dashboards
    if (UserAccessChecker.Dashboards.toTrainingDashboard())
      return "/responsable/dashboard/training";
    if (UserAccessChecker.Dashboards.toInterviewDashboard())
      return "/responsable/dashboard/interview";

    // Formation
    if (UserAccessChecker.Trainings.toTrainingsSchedulePlan() && activeSchedule)
      return `/responsable/${activeSchedule.uuid}/recueil-des-besoins/collection-pending`;
    if (UserAccessChecker.Trainings.toTrainingsScheduleCollection() && activeSchedule)
      return `/responsable/${activeSchedule.uuid}/plan-de-formation/dashboard`;
    if (UserAccessChecker.Trainings.toTrainingsIntrasOrganization())
      return "/responsable/programmes/gestion-intras";
    if (UserAccessChecker.Trainings.toTrainingsSessions())
      return "/responsable/mes-sessions/en-cours";

    // Interviews
    if (UserAccessChecker.Interviews.toInterviewsTrackings()) return "/responsable/campagnes";

    return "/responsable/collaborateurs";
  }, [UserAccessChecker, activeSchedule]);

  return defaultRedirectionPath;
};
