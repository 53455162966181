import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useBaseUrl } from "@skillup/hooks";
import { useFetch } from "hooks";
import { useToggle } from "react-use";
import cx from "classnames";

import Icon from "components/Icon";
import { chevronRight as chevronRightIcon } from "uiAssets/StrokeIcons";

import styles from "./CatalogMenuStyles.module.scss";

interface ICategory {
  endpoint: boolean;
  name: string;
  organizationWeight: number | null;
  parents: Array<string>;
  seoSlug: string;
  slug: string;
  startpoint: boolean;
  trainingCount: number;
  uuid: string;
  visibility: string;
  weight: number | null;
}

const CatalogMenu = () => {
  const { baseUrl } = useBaseUrl();

  const [menuVisible, toggleMenuVisibility] = useToggle(false);
  const [allCatalogHover, toggleAllCatalogHover] = useToggle(false);
  const [activeCategorySlug, setActiveCategorySlug] = useState<string>();

  const { data, loading } = useFetch<{ tags: ICategory[] }>({ url: "/v1/data/catalog-by-tag" });

  const menuData = useMemo(() => {
    const tags = loading ? [] : data.tags.map((t) => ({ ...t }));

    const menuData = tags.reduce((acc, category: ICategory) => {
      category.weight =
        category.weight === undefined || category.weight === null
          ? Number.MAX_SAFE_INTEGER
          : category.weight;

      if (!category.parents.length) {
        acc.push(category);
      } else {
        category.parents.forEach((parentName) => {
          const parent = tags.find((t) => t.name === parentName);
          if (parent) {
            // @ts-ignore
            parent.children = [...(parent.children || []), category];
          }
        });
      }

      return acc;
    }, []);

    return menuData.sort((category1, category2) => category1.weight - category2.weight);
  }, [data, loading]);

  return (
    <div className={styles.CatalogMenu} onMouseLeave={() => toggleMenuVisibility(false)}>
      <button
        className={cx({ [styles.menuVisible]: menuVisible })}
        id="megaMenuButton"
        onMouseEnter={() => toggleMenuVisibility(true)}
        onClick={toggleMenuVisibility}
      >
        <span>Catalogue</span>
        <Icon strokeIcon={chevronRightIcon} width={8} />
      </button>
      <menu
        className={cx({
          [styles.menuVisible]: menuVisible,
          [styles.allCatalogHover]: allCatalogHover,
        })}
        onMouseLeave={() => setActiveCategorySlug(undefined)}
      >
        <Link
          data-slug=""
          onMouseEnter={() => {
            toggleAllCatalogHover(true);
            setActiveCategorySlug(undefined);
          }}
          onMouseLeave={() => toggleAllCatalogHover(false)}
          onClick={() => toggleMenuVisibility(false)}
          to={`${baseUrl}/programmes/catalogue`}
        >
          <span>Tout le catalogue</span>
        </Link>
        {(menuData || []).map((category) => (
          <div
            className={cx({ [styles.subMenuVisible]: activeCategorySlug === category.seoSlug })}
            id={`mega-menu-${category.seoSlug}`}
            key={category.seoSlug}
            onMouseEnter={() => setActiveCategorySlug(category.seoSlug)}
          >
            <Link
              key={category.seoSlug}
              to={`${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/${category.seoSlug}`}
              onClick={() => toggleMenuVisibility(false)}
              data-slug={category.seoSlug}
            >
              <span>{category.name}</span>
              <Icon strokeIcon={chevronRightIcon} width={8} />
            </Link>
            <div>
              <Link
                key={category.seoSlug}
                to={`${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/${category.seoSlug}`}
                onClick={() => toggleMenuVisibility(false)}
              >
                Tout "{category.name}"
              </Link>
              {(category.children || []).map((subCategory) => (
                <Link
                  key={subCategory.seoSlug}
                  to={`${baseUrl ? `${baseUrl}/programmes/catalogue` : ""}/${subCategory.seoSlug}`}
                  onClick={() => toggleMenuVisibility(false)}
                  id={`mega-menu-${subCategory.seoSlug}`}
                >
                  {subCategory.name}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </menu>
    </div>
  );
};

export default CatalogMenu;
