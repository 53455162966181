import { OrganizationDetails } from "hooks/useCompany";

import { HabilitationsRoutes } from "@skillup/espace-rh-bridge";

export type Organization = Omit<OrganizationDetails["properties"], "uuid">;
export type Payload = HabilitationsRoutes.Create["payload"];

export type FormFieldsLimited = {
  duration: number;
  deadlineAnticipation: number;
  durationType: string;
  deadlineAnticipationType: string;
  validity: HabilitationValidity.LIMITED;
};

export type FormFieldsAdvitam = {
  name: string;
  categoryUuid: string;
  validity: HabilitationValidity.ADVITAM;
};

export type FormsField = FormFieldsLimited | FormFieldsAdvitam;

export enum HabilitationValidity {
  LIMITED = "limited",
  ADVITAM = "advitam",
}

enum DurationType {
  ANS = "Ans",
  MOIS = "Mois",
  JOURS = "Jours",
}

interface FieldElementBasic {
  name: string;
  required?: boolean;
  scope?: string;
}

export interface FieldTextElement extends FieldElementBasic {
  type: "TextInput";
  placeholder?: string;
  field: Extract<keyof FormFieldsAdvitam, "name">;
  defaultValue?: string;
  options?: Array<{ label: DurationType; value: string }>;
}

export interface FieldSelect extends FieldElementBasic {
  type: "Select";
  options?: Array<{ label: string; value: string }>;
  field: Extract<keyof FormFieldsAdvitam, "categoryUuid">;
  placeholder?: string;
  defaultValue?: string;
}

export interface FieldBoolean extends FieldElementBasic {
  type: "Switch";
  field: Extract<keyof FormFieldsAdvitam, "validity">;
  defaultValue: boolean;
}

export interface FieldDuration extends FieldElementBasic {
  type: "Duration";
  options: Array<{ label: DurationType; value: string }>;
  field: Extract<keyof FormFieldsLimited, "duration" | "deadlineAnticipation">;
  placeholder?: string;
  defaultValue?: string;
}

export type FieldElement = FieldDuration | FieldTextElement | FieldSelect | FieldBoolean;

export const matchingFields: Array<FieldElement> = [
  {
    name: "Nom de l'habilitation",
    placeholder: "Ex. : CACES - Engins de chantier",
    field: "name",
    type: "TextInput",
    required: true,
    scope: "advitam",
  },
  {
    name: "Catégorie",
    placeholder: "Selectionnez une catégorie",
    field: "categoryUuid",
    type: "Select",
    required: true,
    scope: "advitam",
  },
  {
    name: "Validité illimitée",
    field: "validity",
    type: "Switch",
    required: false,
    defaultValue: false,
    scope: "advitam",
  },
  {
    name: "Durée de validité",
    field: "duration",
    type: "Duration",
    placeholder: "Ex. : 4",
    options: [
      { label: DurationType.ANS, value: "1" },
      { label: DurationType.MOIS, value: "2" },
    ],
    defaultValue: "1",
    required: true,
    scope: "limited",
  },
  {
    name: "Anticipation échéance",
    field: "deadlineAnticipation",
    type: "Duration",
    placeholder: "Ex. : 120",
    options: [
      { label: DurationType.ANS, value: "1" },
      { label: DurationType.MOIS, value: "2" },
      { label: DurationType.JOURS, value: "3" },
    ],
    defaultValue: "2",
    required: true,
    scope: "limited",
  },
];
