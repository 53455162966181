import { DSButton, DSButtonProps, MaterialIcons, useMediaQueries } from "@skillup/ui";

const DSNewHeaderButton = (props: DSButtonProps): JSX.Element => {
  const { icon, ...rest } = props;
  const { isMobile } = useMediaQueries();

  return (
    <DSButton
      iconOnly={isMobile}
      icon={isMobile ? icon ?? <MaterialIcons.Add /> : undefined}
      {...rest}
    />
  );
};
export default DSNewHeaderButton;
