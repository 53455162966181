import { IFilterManager } from "./IFilterManager";
import { type FilterValue, FilterOperator, type FilterConfiguration, type FilterConfigurationMap } from "./types";

export class Neo4JFilterBuilder<Config extends FilterConfigurationMap> extends IFilterManager<
  [string, object],
  Config
> {
  constructor(configuration: Config, private propertyMap: { [id: string]: string }) {
    super(configuration);
  }

  override buildMultiSelectFilter(
    id: string,
    _filter: FilterConfiguration<"multiselect">,
    filterValue: FilterValue<"multiselect">
  ): [string, object] {
    switch (filterValue.operator) {
      case FilterOperator.CONTAINS:
        return [`${this.propertyMap[id]} IN $${id}`, { [id]: filterValue.value }];
    }
  }

  override buildTextFilter(
    id: string,
    _filter: FilterConfiguration<"text">,
    filterValue: FilterValue<"text">
  ): [string, object] {
    switch (filterValue.operator) {
      case FilterOperator.CONTAINS:
        return [
          `toLower(${this.propertyMap[id]}) CONTAINS $${id}`,
          { [id]: filterValue.value!.toString().toLowerCase() },
        ];
    }
  }

  public buildNeo4JFilter(filterId: string, filterValue: FilterValue<any>) {
    return this.buildFilter(filterId, filterValue);
  }
}
