import durationGroups from "constants/durationGroups";

import { IFilters, ITrainingAggregations } from "../../Actions/getTrainings";

type Count = string | number | undefined;
interface ICounts {
  isCPF?: Count;
  sessionMonths?: Array<{
    label: string;
    count: Count;
    onClick: () => void;
    isSelected: boolean | undefined;
  }>;
  organization?: Array<{
    label: string;
    count: Count;
    onClick: () => void;
    isSelected: boolean | undefined;
  }>;
  durationGroup?: Array<{
    label: string;
    count: Count;
    onClick: () => void;
    isSelected: boolean | undefined;
  }>;
  place?: true;
}

const generateCounts = (
  aggregations: ITrainingAggregations,
  filters: IFilters,
  setFilters: (filters: IFilters) => void
): ICounts => {
  const counts: ICounts = {};
  if (aggregations !== undefined && aggregations !== null) {
    /*** CPF ***/
    counts.isCPF = (
      Object.values(aggregations.isCPF).find((i) => i.key_as_string === "true") || { doc_count: "" }
    ).doc_count;

    /*** Session's date per month ***/
    const today = new Date();
    counts.sessionMonths = Array.from(Array(12), (_value, index) => {
      const filter = `${today.getFullYear() + Math.floor((index + today.getMonth()) / 12)}-${`0${
        index + today.getMonth() + (index + today.getMonth() > 11 ? -11 : 1)
      }`.slice(-2)}`;

      return {
        label: new Date(filter).toLocaleString("fr-FR", {
          month: "long",
          year: "numeric",
        }),
        isSelected: filters.sessionMonth && filters.sessionMonth.includes(filter),
        count: (aggregations[`month_${index}`] || {}).doc_count,
        onClick: () => {
          if (!filters.sessionMonth) {
            setFilters({ ...filters, sessionMonth: [filter] });
          } else {
            setFilters({
              ...filters,
              sessionMonth: filters.sessionMonth.includes(filter)
                ? filters.sessionMonth.filter((m) => m !== filter)
                : [...filters.sessionMonth, filter],
            });
          }
        },
      };
    });

    /*** Training Organizations ***/
    counts.organization = Object.entries(aggregations.organization).map(([orgName, value]) => ({
      label: orgName,
      count: value.doc_count,
      isSelected: filters.organization && filters.organization.includes(orgName),
      onClick: () => {
        if (!filters.organization) {
          setFilters({ ...filters, organization: [orgName] });
        } else {
          setFilters({
            ...filters,
            organization: filters.organization.includes(orgName)
              ? filters.organization.filter((m) => m !== orgName)
              : [...filters.organization, orgName],
          });
        }
      },
    }));

    /*** Training Duration ***/
    counts.durationGroup = [1, 2, 3, 4].map((_value, index) => {
      const updatedDurationGroups = new Set(filters.durationGroup || []);
      if (updatedDurationGroups.has(String(index))) {
        updatedDurationGroups.delete(String(index));
      } else updatedDurationGroups.add(String(index));
      return {
        label: durationGroups.values[index],
        count: aggregations[`duration_${index}`].doc_count,
        isSelected: filters.durationGroup && filters.durationGroup.includes(String(index)),
        onClick: () =>
          setFilters({
            ...filters,
            durationGroup: Array.from(updatedDurationGroups.values()),
          }),
      };
    });

    /*** Place ***/
    counts.place = true;
  }

  return counts;
};

export default generateCounts;
