import type { OrganizationRoutesTypeUserGroup } from "types/api";

import { buildRequest } from "utils/buildRequest";

export default (userGroupUuid: string) => {
  return buildRequest<OrganizationRoutesTypeUserGroup.Delete>({
    method: "DELETE",
    path: "/organization/group/{userGroupUuid}",
    params: { userGroupUuid },
  })();
};
