import * as Sentry from "@sentry/react";

import Acta from "utils/Acta";

const canLogErrors =
  typeof window !== "undefined" && !window["isBot"] && process.env.REACT_APP_ENV !== "development";

const configureScope = (scope, userData) => {
  if (userData && userData.uuid) {
    const { email, fullName: username, uuid: id } = userData;
    scope.setUser({ email, id, username });
  }

  if (userData && userData.activeCompany) {
    scope.setTag("company_uuid", userData.activeCompany.uuid);
    scope.setTag("company_name", userData.activeCompany.name);
  }

  if (userData && userData.activeTrainingOrganization) {
    scope.setTag("training_organization_uuid", userData.activeTrainingOrganization.uuid);
    scope.setTag("training_organization_name", userData.activeTrainingOrganization.name);
  }
};

if (canLogErrors) {
  Sentry.init({
    dsn: "https://9e2c4eb2ee604e4e9cef4be5756689de@sentry.io/113144",
    environment: process.env.REACT_APP_ENV,
  });

  Acta.subscribeStateWithoutContext(
    "userData",
    (userData) => {
      Sentry.configureScope((scope) => configureScope(scope, userData));
    },
    "SentryWithUserData"
  );
}

const logError = ({ payload, error }) => {
  if (canLogErrors) {
    const userData = Acta.getState("userData");

    Sentry.withScope((scope) => {
      configureScope(scope, userData);

      Object.keys(payload).forEach((key) => scope.setExtra(key, payload[key]));

      scope.setLevel(Sentry.Severity.Error);

      Sentry.captureException(error);
    });
  }
};

export { logError };

export default {
  logError,
};
