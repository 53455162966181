import { useState } from "react";
import { atom, useRecoilState } from "recoil";

import { DSDropdownItem, DataTable, generateModalContext } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";

import { trpc } from "utils/trpc";
import { plural } from "utils/locale";
import { Category } from "types/skills";
import useTranslation from "hooks/useTranslation";
import useTableData from "./useTableData";
import {
  NewSkillCategoryModal,
  UpdateSkillCategoryModal,
  RemoveSkillCategoryModal,
} from "../Modals";

import { SkillLayout, CreationButton, SkillCategoriesListWrapper } from "./SkillCategories.styled";

const categoriesPagination = atom<number>({
  key: "categories.pagination",
  default: 10,
});

const SkillCategories = (): JSX.Element => {
  const { t } = useTranslation();
  const { data, refetch } = trpc.categories.getAllCategories.useQuery();
  const [selectedCategory, setSelectCategory] = useState<Category>();
  const paginationHook = useRecoilState(categoriesPagination);

  const useModal = generateModalContext();
  const {
    isOpen: isModalOpen,
    openModal: openNewSkillCategoryModal,
    closeModal: closeNewSkillCategoryModal,
  } = useModal(false);

  const {
    isOpen: isUpdateCategoryModalOpen,
    openModal: openUpdateCategoryModal,
    closeModal: closeUpdateCategoryModal,
  } = useModal(false);

  const {
    isOpen: isRemoveModalOpen,
    openModal: openRemoveSkillCategoryModal,
    closeModal: closeRemoveSkillCategoryModal,
  } = useModal(false);
  const categories: Category[] | undefined = data as unknown as Category[] | undefined;

  const { columns, data: dataTable } = useTableData(categories ?? []);

  const categoriesToDisplay = dataTable.filter((category) => !category.data.deletedAt);

  return (
    <SkillLayout>
      <SkillCategoriesListWrapper>
        <Flex flexDirection="column" marginTop="m">
          {categoriesToDisplay.length === 0 ? (
            <Text>
              {t("portal.config.skills.noCategories", {
                defaultValue: "Aucune catégorie de compétences",
              })}
            </Text>
          ) : (
            <>
              <DataTable
                header={{
                  totalRowsLabel: (total) => {
                    let label = plural(total, "%n catégorie%s");
                    if (total < categoriesToDisplay.length) {
                      label += `${plural(total, " filtrée%s")} sur ${categoriesToDisplay.length}`;
                    }
                    return label;
                  },
                }}
                actions={(row) => {
                  return (
                    <>
                      <DSDropdownItem
                        label={t("skills.list.skillCategory.editSkillCategory", {
                          defaultValue: "Renommer la catégorie",
                        })}
                        onClick={() => {
                          setSelectCategory(row.data);
                          openUpdateCategoryModal();
                        }}
                      />
                      <DSDropdownItem
                        label={t("skills.list.skillCategory.deleteSkillCategory", {
                          defaultValue: "Supprimer la catégorie",
                        })}
                        onClick={() => {
                          setSelectCategory(row.data);
                          openRemoveSkillCategoryModal();
                        }}
                      />
                    </>
                  );
                }}
                columns={columns}
                rows={categoriesToDisplay}
                mode="compact"
                pagination={
                  categoriesToDisplay.length > 10 && {
                    rowsPerPageLabel: "Lignes par page",
                    itemsCountLabel: "Catégories %range% sur %count%",
                    pageLabel: "Page",
                    stateHook: paginationHook,
                  }
                }
              />
            </>
          )}
          <CreationButton
            label={t("portal.config.skills.list.createCategory", {
              defaultValue: "Ajouter une catégorie",
            })}
            onClick={openNewSkillCategoryModal}
            emphasis="High"
            buttonSize="M"
          />
        </Flex>
        {isModalOpen && (
          <NewSkillCategoryModal
            isOpen={isModalOpen}
            refetch={refetch}
            closeModal={closeNewSkillCategoryModal}
          />
        )}
        {isUpdateCategoryModalOpen && (
          <UpdateSkillCategoryModal
            isOpen={isUpdateCategoryModalOpen}
            refetch={refetch}
            closeModal={closeUpdateCategoryModal}
            category={selectedCategory}
          />
        )}
        {isRemoveModalOpen && (
          <RemoveSkillCategoryModal
            isOpen={isRemoveModalOpen}
            refetch={refetch}
            closeModal={closeRemoveSkillCategoryModal}
            category={selectedCategory}
          />
        )}
      </SkillCategoriesListWrapper>
    </SkillLayout>
  );
};

export default SkillCategories;
