import { DSButton, Modal } from "@skillup/ui";
import { Future } from "@skillup/monads";
import { useToasts } from "react-toast-notifications";
import useTranslation from "hooks/useTranslation";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import styles from "./LinkUsersToHabilitation.module.scss";
import { buildSafeRequest } from "utils/buildRequest";
import { formatError } from "services/errors";
import AddTraineesContent, { User } from "components/AddTraineesModal/AddTraineesContent";
import { USER_FILE_VALIDATION_TYPE } from "@skillup/types";
import { useAreaUserGroups } from "components/AddTraineesModal/helpers";
import { UsersListProvider, useUsersList } from "components/AddTraineesModal/add-trainees-context";
import useTypedFetch from "hooks/useTypedFetch";
import type { TrackingRoutes } from "types/api";
import { useMemo } from "react";
import { difference } from "lodash";
import { useState } from "react";

interface Props {
  readonly onClose: () => void;
  readonly habilitationUuid: string;
}

export default ({ habilitationUuid, onClose }: Props) => {
  const { data: habilitationTrackings } = useTypedFetch<TrackingRoutes.GetForHabilitation>({
    method: "GET",
    path: "/habilitation/{uuid}/trackings",
    params: {
      uuid: habilitationUuid,
    },
  });
  const selectedUsers = useMemo(
    () => habilitationTrackings?.trackings.filter((t) => !t.archivedAt).map((t) => t.user),
    [habilitationTrackings]
  );

  return (
    <Modal title="Associer des collaborateurs" fill onClose={onClose} disableOnClickAway>
      {selectedUsers ? (
        <UsersListProvider
          content={{
            selectedUsers,
          }}
        >
          <LinkUserModal
            habilitationUuid={habilitationUuid}
            preSelectedUsers={selectedUsers}
            onClose={onClose}
          />
        </UsersListProvider>
      ) : (
        <div className={cx(styles.linkUsers, styles.loading)} />
      )}
    </Modal>
  );
};

interface LinkUserModalProps {
  habilitationUuid: string;
  preSelectedUsers: User[];
  onClose: () => void;
}
function LinkUserModal({ habilitationUuid, preSelectedUsers, onClose }: LinkUserModalProps) {
  const { addToast } = useToasts();
  const { t } = useTranslation();
  const groups = useAreaUserGroups();
  const { state } = useUsersList();
  const newUsersUuids = useMemo(
    () =>
      difference(
        state.selectedUsers.map((u) => u.uuid),
        preSelectedUsers.map((u) => u.uuid)
      ),
    [preSelectedUsers, state.selectedUsers]
  );
  const isError = useMemo(
    () => state.selectedUsers.some((u) => u.errors?.length),
    [state.selectedUsers]
  );

  const history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const handleMainAction = async () => {
    setLoading(true);

    const createRequest = createTrackings(habilitationUuid, newUsersUuids);
    await handleResponse({
      createRequest,
      addToast,
      t,
      onClose,
      onAction: () => history.push("/responsable/reglementaire/suivi"),
    });

    setLoading(false);
  };

  return (
    <div className={styles.linkUsers}>
      <AddTraineesContent
        canUploadManager={false}
        scope={USER_FILE_VALIDATION_TYPE.HABILITATION}
        customUsersLists={
          groups && [
            {
              label: "Sélectionner par périmètre",
              groups,
            },
          ]
        }
        preselectedUsers={preSelectedUsers}
      />
      <div className={styles.buttonsWrapper}>
        <DSButton className={styles.button} emphasis="Low" label="Annuler" onClick={onClose} />
        <DSButton
          className={styles.button}
          emphasis="High"
          label="Associer les collaborateurs"
          loading={isLoading}
          disabled={!newUsersUuids.length || isError}
          onClick={handleMainAction}
        />
      </div>
    </div>
  );
}

async function handleResponse({
  createRequest,
  addToast,
  t,
  onClose,
  onAction,
}: {
  createRequest: Future<TrackingRoutes.CreateBulk["errors"], TrackingRoutes.CreateBulk["response"]>;
  addToast;
  t;
  onClose: () => void;
  onAction: () => void;
}) {
  await Future.unwrap(
    createRequest,
    (error) => {
      addToast(
        formatError(t, error, {
          defaultValue: "Echec lors de la création des lignes de suivi.",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
    ({ trackingUuids }) => {
      addToast(
        <>
          {t("habilitation.create_bulk.success", {
            count: trackingUuids.length,
            defaultValue: "Lignes de suivi ajoutées avec succès",
          })}
          <div className={styles.alertAction}>
            <DSButton
              buttonSize={"S"}
              emphasis={"Mid"}
              label="Voir les lignes ajoutées"
              onClick={onAction}
              className={styles.alertButton}
            />
          </div>
        </>,
        { appearance: "success", autoDismiss: true }
      );

      onClose();
    }
  );
}

function createTrackings(habilitationUuid: string, userUuids: string[]) {
  return buildSafeRequest<TrackingRoutes.CreateBulk>({
    method: "POST",
    path: "/trackings",
    payload: {
      data: userUuids.map((userUuid) => ({
        habilitationUuid,
        userUuid,
      })),
    },
  }).run();
}
