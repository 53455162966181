import { useToasts } from "react-toast-notifications";
import { isEmpty, isNil } from "lodash";

import { CampaignIsArchivable, CampaignsRoutes } from "@skillup/espace-rh-bridge";
import { DSDropdownItem } from "@skillup/ui";
import { InterviewWithStates } from "@skillup/types";

import User from "utils/User";
import Acta from "utils/Acta";
import { EditionContext } from "./modals/utils";
import { buildRequest } from "utils/buildRequest";
import Colors from "uiAssets/Colors";
import InteractiveButton from "components/InteractiveButton";
import { Campaign } from "./modals/useCampaignModals";

import styles from "./CampaignActions.module.scss";

interface Props {
  readonly campaign?: Campaign;
  readonly interviews?: Array<InterviewWithStates>;
  readonly onDelete: () => void;
  readonly resync: () => Promise<void>;
  readonly setActionsState?: (stateChange: EditionContext) => void;
  readonly onClick?: () => void;
}

const CampaignActions = ({ campaign, interviews, onDelete, setActionsState }: Props) => {
  const { addToast } = useToasts();
  const cantArchiveMessage =
    "Vous ne pouvez pas clôturer une campagne tant que la date limite de signature n’est pas passée";
  const cantArchiveMessageInvisible =
    "Vous ne pouvez pas clôturer cette campagne car certains entretiens auxquels vous n’avez pas accès n’ont pas encore atteint la date limite de signature.";

  const deleteCampaign = async (): Promise<void> => {
    try {
      await buildRequest<CampaignsRoutes.Delete>({
        method: "DELETE",
        path: `/campaigns/{campaignUuid}`,
        params: { campaignUuid: campaign.uuid },
      })();
      Acta.dispatchEvent("closeModal");

      addToast(`La campagne « ${campaign.titleForHR} » a bien été supprimée.`, {
        appearance: "success",
      });
      onDelete();
    } catch (error) {
      Acta.dispatchEvent("closeModal");

      addToast(`Impossible de supprimer la campagne « ${campaign.titleForHR} ».`, {
        appearance: "error",
      });

      console.error(error);
    }
  };

  let campaignActions = [];

  // open to client
  campaignActions.push(
    <DSDropdownItem
      key="campaign-update"
      data-target="campaign-update"
      onClick={() => {
        setActionsState(EditionContext.MODIFY);
      }}
      label="Modifier la campagne"
    />
  );

  campaignActions.push(
    <DSDropdownItem
      key="campaign-parameter"
      data-target="campaign-parameters"
      onClick={() => {
        setActionsState(EditionContext.PARAMETER);
      }}
      label="Paramètres de la campagne"
    />
  );

  if (campaign.type === "legacy") {
    campaignActions.push(
      <DSDropdownItem
        key="campaign-mailing-update"
        data-target="campaign-mailing-update"
        onClick={() => {
          setActionsState(EditionContext.NOTIFICATION);
        }}
        label="Paramètres notifications"
      />
    );
  }

  if (User.isSkillupAdmin()) {
    if (!campaign.archivedAt) {
      campaignActions.push(
        <DSDropdownItem
          key="campaign-template-update"
          data-target="campaign-template-update"
          onClick={() => {
            setActionsState(EditionContext.UPDATE_STRUCTURE);
          }}
          label="[OPS] Corriger la trame de la campagne"
        />
      );
    }
    campaignActions.push(
      <DSDropdownItem
        key="campaign-delete"
        onClick={() =>
          Acta.setState("modalDisplayed", {
            content: (
              <div className={styles.confirmationModal}>
                <p className={styles.modalText}>
                  Êtes-vous sûr(e) de vouloir supprimer définitivement la campagne{" "}
                  <strong>{campaign.titleForHR}</strong> ? Attention cette action est irréversible.
                </p>
                <div className={styles.buttonWrapper}>
                  <InteractiveButton
                    label="Précédent"
                    onClick={() => Acta.dispatchEvent("closeModal")}
                    size="small"
                    background="#fff"
                    color={Colors.blue}
                  />
                  <InteractiveButton label="Supprimer" onClick={deleteCampaign} size="small" />
                </div>
              </div>
            ),
            size: "small",
            title: "Supprimer définitivement la campagne",
          })
        }
        label="[OPS] Supprimer la campagne"
      />
    );
  }

  if (!isNil(interviews) && !isEmpty(interviews) && isNil(campaign.archivedAt)) {
    campaignActions.push(
      <DSDropdownItem
        key="campaign-archive"
        onClick={() => setActionsState(EditionContext.CLOSE)}
        label="Clôturer la campagne"
        disabled={campaign.isArchivable !== CampaignIsArchivable.YES}
        tooltipLabel={
          campaign.isArchivable === CampaignIsArchivable.NO
            ? cantArchiveMessage
            : campaign.isArchivable ===
              CampaignIsArchivable.NOT_VISIBLE_INTERVIEW_ARE_NOT_ARCHIVABLE
            ? cantArchiveMessageInvisible
            : null
        }
        tooltipDirection={campaign.isArchivable !== CampaignIsArchivable.YES ? "bottom" : null}
      />
    );
  }

  return campaignActions;
};

export default CampaignActions;
