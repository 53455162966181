import { PayloadAction } from "@reduxjs/toolkit";

import { arrayMove } from "../utils/arrayMove";
import { ActionVector, SectionVector, BuilderTemplate } from "../..";

export function moveSection(
  state: BuilderTemplate,
  action: PayloadAction<ActionVector<SectionVector>>
) {
  const { fromPosition, toPosition } = action.payload;

  if (fromPosition.type !== "section") {
    throw new Error();
  }
  if (toPosition.type === "section") {
    if (fromPosition.sectionIndex === toPosition.sectionIndex) {
      return state;
    }
  }
  return {
    ...state,
    sections: arrayMove(state.sections, fromPosition.sectionIndex, toPosition.sectionIndex),
  };
}
