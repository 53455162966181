import React, { useCallback, useContext, useEffect, useState } from "react";
import { head } from "lodash";

import { buildRequest } from "utils/buildRequest";

import type { ScheduleRoutes } from "types/api";

export type Schedule = ScheduleRoutes.GetList["response"][0];

const getSchedules = (): Promise<Array<Schedule>> => {
  return buildRequest<ScheduleRoutes.GetList>({
    method: "GET",
    path: "/schedule/list",
  })();
};

const defaultDashboardDataMock = {
  budget: 356787,
  done: {
    actions: 0,
    cost: 105398,
    hours: 200,
    trainees: 65,
  },
  totalInPlan: {
    actions: 0,
    cost: 6787,
    hours: 520,
    trainees: 100,
  },
  trainingCost: {
    educationalCost: 156787,
    salaryCost: 35687,
    additionalExpenses: 12924,
    funding: -100000,
  },
  gender: {
    femaleCount: 110,
    maleCount: 110,
    otherCount: 45,
  },
  csp: {
    cadreCount: 245,
    workerCount: 20,
    otherCount: 0,
  },
  age: {
    range1824: 50,
    range2534: 100,
    range3544: 50,
    range45: 25,
  },
};

interface GetScheduleDashboardDataParams {
  scheduleUuid: string;
}

const getScheduleDashboardData = (_: GetScheduleDashboardDataParams) => {
  return Promise.resolve(defaultDashboardDataMock);
};

export interface Context {
  activeSchedule: string | undefined;
  dashboardData: typeof defaultDashboardDataMock;
  schedules: Array<Schedule>;
  isLoading: boolean;
  isError?: any;
  setActiveSchedule: (filesUuids: string | undefined) => void;
}

export interface Props {
  children: React.ReactNode;
}

const DashboardDataContext = React.createContext<Context>({
  isLoading: false,
  activeSchedule: undefined,
  schedules: [],
  dashboardData: undefined,
  setActiveSchedule: () => {},
});

export const DashboardDataContextProvider = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<any>(undefined);
  const [schedules, setSchedules] = useState<Array<Schedule>>();
  const [activeSchedule, setActiveSchedule] = useState<string | undefined>();
  const [dashboardData, setDashboardData] = useState<{ [scheduleUuid: string]: any }>({});

  const changeActiveSchedule = useCallback(
    async (scheduleUuid: string | undefined) => {
      if (scheduleUuid === activeSchedule) {
        return;
      }

      setActiveSchedule(scheduleUuid);

      if (!scheduleUuid) {
        return;
      }

      setIsLoading(true);
      try {
        const data = await getScheduleDashboardData({ scheduleUuid });
        setDashboardData({
          ...dashboardData,
          [scheduleUuid]: data,
        });
      } catch (e) {
        setIsError(e);
      } finally {
        setIsLoading(false);
      }
    },
    [activeSchedule, setActiveSchedule, dashboardData, setDashboardData, setIsLoading, setIsError]
  );

  useEffect(() => {
    if (!isLoading && schedules === undefined) {
      setIsLoading(true);
      getSchedules()
        .then((s) => {
          setSchedules(s);
          changeActiveSchedule(s.find((e) => e.active)?.uuid ?? head(s)?.uuid);
        })
        .catch(({ error }) => {
          setSchedules([]);
          setIsError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [setIsLoading, isLoading, setIsError, changeActiveSchedule, setSchedules, schedules]);

  const value = {
    activeSchedule,
    setActiveSchedule: changeActiveSchedule,
    schedules: schedules ?? [],
    isLoading,
    isError,
    dashboardData: dashboardData[activeSchedule],
  };

  return (
    <DashboardDataContext.Provider value={value}>{props.children}</DashboardDataContext.Provider>
  );
};

export function useDashboardData() {
  const context = useContext(DashboardDataContext);

  if (context === undefined) {
    throw new Error("useDashboardData must be used within a DashboardDataContext");
  }

  return context;
}
