import { useMemo, useState } from "react";
import cx from "classnames";
import { v4 } from "uuid";

import { EditableCompanyField } from "@skillup/training-bridge";
import { DSButton, DSSortable, MaterialIcons } from "@skillup/ui";
import { Flex } from "@skillup/design-system";

import useTranslation from "hooks/useTranslation";
import User from "utils/User";

import FieldDragableOption from "./DragableOption";
import DeleteOptionModal from "./DeleteOptionModal";

import styles from "../Form.module.scss";

type Props = {
  values: EditableCompanyField;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  initialOptions: EditableCompanyField["options"];
};

const FieldSelectOptions = ({ values, setFieldValue, initialOptions }: Props) => {
  const { t } = useTranslation();
  const [showHiddenList, setShowHiddenList] = useState(false);
  const [optionToDelete, setOptionToDelete] = useState({});

  const isAdmin = User.isSkillupAdmin() || User.isSkillupDeveloper();

  const handleSetVisibility = (option: EditableCompanyField["options"][number], index: number) => {
    const baseForUpdate = values.options.filter((_, i) => i !== index);
    const indexToInsert = option.disabled
      ? values.options.findIndex((o) => o.disabled) // Move to the end of visible list
      : values.options.length - 1; // Move to the end of disable list

    const newOptions = baseForUpdate
      .slice(0, indexToInsert)
      .concat({ ...option, disabled: !option.disabled }, baseForUpdate.slice(indexToInsert));

    setFieldValue("options", newOptions);
  };

  const handleReoder = (_items: any, from: number, to: number): any => {
    const newOptions = [...values.options];
    const [replaced] = newOptions.splice(from, 1);
    newOptions.splice(to, 0, replaced);

    setFieldValue("options", newOptions);
  };

  const handleAddOption = () => {
    const newOptions = [...values.options];
    let startIndexVisibleOptions = newOptions.findIndex((o) => o.disabled);

    if (startIndexVisibleOptions === -1) {
      startIndexVisibleOptions = newOptions.length;
    }

    if (startIndexVisibleOptions === -1) {
      newOptions.push({ key: v4(), value: "", disabled: false });
    } else {
      newOptions.splice(startIndexVisibleOptions, 0, { key: v4(), value: "", disabled: false });
    }

    setFieldValue("options", newOptions);
  };

  const orderAlphabeticalSort = () => {
    const optionsVisiblesList = [...values.options.filter((o) => !o.disabled)];
    const optionsHiddenList = [...values.options.filter((o) => o.disabled)];
    optionsVisiblesList.sort((a, b) => a.value.localeCompare(b.value));
    optionsHiddenList.sort((a, b) => a.value.localeCompare(b.value));

    setFieldValue("options", [...optionsVisiblesList, ...optionsHiddenList]);
  };

  const optionsItems = useMemo(() => {
    const componentsOptions = values.options
      .filter((o) => !o.fallback)
      .map((option) => {
        const index = values.options.findIndex((o) => o.key === option.key);
        const isNewOption = initialOptions.findIndex((o) => o.key === option.key) === -1;
        const deleteDisabled = !isNewOption && !isAdmin;

        return {
          id: option.key,
          disabled: option.disabled,
          title: (
            <Flex
              justifyContent="space-between"
              gap="xs"
              marginRight="xxs"
              className={cx({ [styles.disabled]: option.disabled })}
            >
              <FieldDragableOption index={index} value={option} isNewOption={isNewOption} />
              {(option.disabled || isNewOption) && (
                <DSButton
                  iconOnly
                  icon={<MaterialIcons.Delete />}
                  disabled={deleteDisabled}
                  tooltip={
                    deleteDisabled
                      ? t("fields.config.form.options.delete.disabled", {
                          defaultValue:
                            "Pour supprimer cette valeur contactez votre interlocuteur opérationnel Skillup",
                        })
                      : t("fields.config.form.options.delete", {
                          defaultValue: "Supprimer cette option",
                        })
                  }
                  tooltipWithPortal
                  onClick={() => {
                    if (isNewOption) {
                      setFieldValue(
                        "options",
                        values.options.filter((o) => o.key !== option.key)
                      );
                    } else {
                      setOptionToDelete(option);
                    }
                  }}
                />
              )}
              {!isNewOption && (
                <DSButton
                  iconOnly
                  icon={
                    option.disabled ? <MaterialIcons.VisibilityOff /> : <MaterialIcons.Visibility />
                  }
                  tooltip={
                    option.disabled
                      ? t("fields.config.form.options.show", {
                          defaultValue: "Afficher cette option",
                        })
                      : t("fields.config.form.options.hide", {
                          defaultValue: "Masquer cette option",
                        })
                  }
                  tooltipWithPortal
                  onClick={() => handleSetVisibility(option, index)}
                />
              )}
            </Flex>
          ),
        };
      });
    const visibleDelimiter = values.options.findIndex((o) => o.disabled);
    return {
      visibleOptions: componentsOptions,
      hiddenOptions: visibleDelimiter !== -1 ? componentsOptions.splice(visibleDelimiter) : [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.options]);

  return (
    <Flex gap="xs" flexDirection="column" className={styles.dragableOptions}>
      <DSButton
        onClick={orderAlphabeticalSort}
        emphasis="Low"
        buttonSize="S"
        label={t("fields.config.form.sort.alphabetically", {
          defaultValue: "Afficher par ordre alphabétique",
        })}
      />
      {optionsItems.visibleOptions.length > 0 && (
        <DSSortable handle items={optionsItems.visibleOptions} reorderItems={handleReoder} />
      )}
      {optionsItems.hiddenOptions.length > 0 && (
        <div className={cx(styles.hiddenContainer, { [styles.hide]: !showHiddenList })}>
          <DSSortable
            items={optionsItems.hiddenOptions}
            reorderItems={handleReoder}
            style={{ marginTop: "2px" }}
            handle
          />
        </div>
      )}
      {optionsItems.hiddenOptions.length > 0 && (
        <DSButton
          emphasis="Low"
          buttonSize="S"
          icon={showHiddenList ? <MaterialIcons.ExpandLess /> : <MaterialIcons.ExpandMore />}
          label={t(
            optionsItems.hiddenOptions.length > 1
              ? "field.config.form.hideLabel.plurial"
              : "field.config.form.hideLabel",
            {
              defaultValue:
                optionsItems.hiddenOptions.length > 1
                  ? "{{ count }} options masquées"
                  : "{{ count }} option masquée",
              count: optionsItems.hiddenOptions.length,
            }
          )}
          onClick={() => setShowHiddenList(!showHiddenList)}
        />
      )}

      <DSButton
        buttonSize="S"
        emphasis="Mid"
        label={t("fields.config.form.addOption", { defaultValue: "Ajouter une option" })}
        disabled={values.restrictions.includes("addOption")}
        onClick={handleAddOption}
      />

      <DeleteOptionModal
        closeModal={() => setOptionToDelete({})}
        field={values}
        optionToDelete={optionToDelete as EditableCompanyField["options"][number]}
        setFormOptions={(o) => setFieldValue("options", o)}
      />
    </Flex>
  );
};

export default FieldSelectOptions;
