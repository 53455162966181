import { GridRowSelectionModel } from "@mui/x-data-grid-pro";

import useTranslation from "hooks/useTranslation";

import { MassAssignButton } from "../Assignments.styled";

interface Props {
  selectedRows: GridRowSelectionModel;
  assignMultipleEmployees: () => void;
}

export const ToolbarButton = ({ assignMultipleEmployees, selectedRows }: Props) => {
  const { t } = useTranslation();

  if (selectedRows.length === 0) {
    return null;
  }

  return (
    <MassAssignButton
      onClick={assignMultipleEmployees}
      label={t("employees.list.label.assignMultiple", {
        count: selectedRows.length,
        defaultValue: "Assigner une fiche de poste à {{count}} employés",
      })}
    />
  );
};
