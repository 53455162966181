import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";

import { Flex } from "@skillup/design-system";
import { Status, StatusType } from "@skillup/ui";

import { TalentGridRow } from "../../../types";

const statuses = {
  done: StatusType.DONE,
  in_progress: StatusType.PROGRESS,
  manager_prep: StatusType.SUCCESS,
  no_manager_prep: StatusType.WARNING,
};

const StatusCell = ({ status }: { status: string }) => {
  const { t } = useTranslation();
  const labels = useMemo(() => {
    return {
      done: t("peopleReview.review.status.done", { defaultValue: "Arbitré" }),
      in_progress: t("peopleReview.review.status.inProgress", { defaultValue: "En cours" }),
      manager_prep: t("peopleReview.review.status.managerPrep", {
        defaultValue: "Préparation manager",
      }),
      no_manager_prep: t("peopleReview.review.status.noManagerPrep", {
        defaultValue: "Sans préparation manager",
      }),
    };
  }, [t]);

  return (
    <Flex alignItems="center" justifyContent="center">
      <Status label={labels[status] ?? ""} type={statuses[status] ?? StatusType.DONE} />
    </Flex>
  );
};

export const statusColDef = {
  field: "status",
  flex: 1,
  headerName: "Statut",
  minWidth: 200,
  renderCell: (params: GridRenderCellParams<TalentGridRow>) => (
    <StatusCell status={params.row.status} />
  ),
} satisfies GridColDef;
