import { memo, useCallback } from "react";

import { Flex } from "@skillup/ui";

import { StructureSection } from "./Section/StructureSection";
import { StructurePage } from "./Page/StructurePage";
import { StructureChild } from "./Child/StructureChild";
import { DNDPageItem, DNDStructure } from "../dndReducer";
import { Collapsible } from "./Collapsible/Collapsible";
import { useBuilderContext } from "../../BuilderContext";

import styles from "../StructureBuilderStructure.module.scss";

export const Overlay = {
  Section: memo(function SectionOverlay({ section }: { section: DNDStructure["sections"][0] }) {
    const { closedItems } = useBuilderContext();
    return (
      <Flex grow column className={styles.Structure}>
        <StructureSection
          title={section.title}
          displayMenuOnHover={false}
          showPages={!closedItems[section.uuid]}
        />
        <Collapsible isOpen={!closedItems[section.uuid]}>
          <SectionPages section={section} closedItems={closedItems} />
        </Collapsible>
      </Flex>
    );
  }),
  Page: memo(function PageOverlay({ page }: { page: DNDStructure["sections"][0]["pages"][0] }) {
    const { closedItems } = useBuilderContext();
    return (
      <Flex grow column className={styles.Structure}>
        <StructurePage
          title={page.title}
          displayMenuOnHover={false}
          showChildren={!closedItems[page.uuid]}
        />
        <Collapsible isOpen={!closedItems[page.uuid]}>
          <PageChildren page={page} />
        </Collapsible>
      </Flex>
    );
  }),
  Child: memo(function ChildOverlay({
    child,
  }: {
    child: DNDStructure["sections"][0]["pages"][0]["children"][0];
  }) {
    return (
      <Flex grow column className={styles.Structure}>
        <StructureChild
          kind={child.kind}
          type={child.kindType}
          displayMenuOnHover={false}
          label={child.label}
        />
      </Flex>
    );
  }),
};

const PageChildren = ({ page }: { page: DNDStructure["sections"][0]["pages"][0] }) => {
  return (
    <Flex column>
      {page.children.map((child) => (
        <StructureChild
          kind={child.kind}
          type={child.kindType}
          label={child.label}
          displayMenuOnHover={false}
          key={child.uuid}
        />
      ))}
    </Flex>
  );
};

const SectionPages = ({
  section,
  closedItems,
}: {
  section: DNDStructure["sections"][0];
  closedItems: Record<string, boolean>;
}) => {
  const showPage = useCallback(
    (page: DNDPageItem) => {
      return !(page.hideInBuilder && (section.pages.length === 1 || page.children?.length === 0));
    },
    [section.pages]
  );
  return (
    <Flex column className={styles["section__pages"]}>
      {section.pages.map((page) => (
        <Flex column key={page.uuid}>
          {showPage(page) && (
            <StructurePage
              title={page.title}
              displayMenuOnHover={false}
              showChildren={!closedItems[page.uuid]}
            />
          )}
          <Collapsible isOpen={!closedItems[page.uuid]}>
            <PageChildren page={page} />
          </Collapsible>
        </Flex>
      ))}
    </Flex>
  );
};
