import React from "react";
import DropDown from "components/DropDown";
import { calendar as calendarIcon, mapPin as mapPinIcon } from "uiAssets/StrokeIcons";

interface IProps {
  currentPlace?: string;
  possiblePlaces: Array<{ label: string; value: string }>;
  possibleDates?: Array<{ label: string; value: string }>;
  onSetSession: (session: string) => void;
  onSetPlace: (place: string) => void;
  currentSession?: string;
}

export default ({
  currentPlace,
  currentSession,
  possiblePlaces = [],
  possibleDates = [],
  onSetSession,
  onSetPlace,
}: IProps) => {
  return (
    <>
      <DropDown
        defaultValue={currentPlace}
        id="locationDropdown"
        label="Lieu"
        onSelect={onSetPlace}
        options={possiblePlaces}
        placeholder="Sélectionnez un lieu"
        strokeIcon={mapPinIcon}
      />
      <DropDown
        defaultValue={currentSession}
        label="Date"
        id="dateDropdown"
        onSelect={onSetSession}
        options={possibleDates}
        placeholder="Sélectionnez une date"
        strokeIcon={calendarIcon}
      />
    </>
  );
};
