import { ReactElement } from "react";

import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid-pro";

import { Flex } from "@skillup/design-system";

import { Assignee } from "types/skills";
import { TranslationType } from "hooks/useTranslation";
import { EmployeeFieldsType } from "hooks/useEmployees";

import ActionsCell from "../components/ActionsCell";

const jobFields = {
  jobName: {
    key: "jobName",
    traductionDefaultValue: "Fiche de poste",
    traductionKey: "employee.field.jobName",
  },
};

const renderFlexCell = (value: string | ReactElement) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      {typeof value === "string" ? <p>{value}</p> : value}
    </Flex>
  );
};

const generateColumns = ({
  actions,
  fields,
  t,
}: {
  t: TranslationType;
  fields: EmployeeFieldsType;
  actions: (row: { id: string; data: Assignee }) => void;
}): GridColDef[] => {
  const employeesFields = [
    fields.lastName.key,
    fields.firstName.key,
    fields.email.key,
    fields.role.key,
    jobFields.jobName.key,
    fields.division.key,
    fields.service.key,
    fields.site.key,
    fields.contract.key,
    fields.branch.key,
    fields.registrationNumber.key,
  ];

  const columns: GridColDef[] = employeesFields.map((key) => {
    const fieldData = Object.keys(jobFields).includes(key) ? jobFields[key] : fields[key];
    return {
      field: key,
      filterable: true,
      headerName: t(fieldData.traductionKey, {
        defaultValue: fieldData.traductionDefaultValue,
      }),
      minWidth: 200,
      renderCell: ({ row }: GridValueGetterParams) => {
        return renderFlexCell(row.data[key]);
      },
      sortable: true,
    };
  });

  columns.push({
    type: "actions",
    field: "actions",
    filterable: false,
    getActions: ({ row }) => [<ActionsCell t={t} row={row} actions={actions} />],
    sortable: false,
    width: 50,
  });

  return columns;
};

export default generateColumns;
