import { useDispatch } from "react-redux";
import { useMemo } from "react";

import User from "utils/User";
import type { ContentItem } from "../../types";
import { Guidelines } from "./Guidelines";
import { PersonnalizedGuidelines } from "./PersonnalizedGuidelines";
import { Child, edit } from "../../../reducer";
import { TrainingsHistoryChild } from "./TrainingsHistory";
import { HTMLChild } from "./HTML/HTMLChild";
import { TrainingsCollectionChild } from "./TrainingsCollection";
import { FileUploadChild } from "./FileUpload";
import { InterviewsHistoryChild } from "./InterviewsHistory";
import { Title } from "./Title";
import { SkillsChild } from "./Skills";
import { Question } from "./Question";
import { JobChild } from "./Job";
import { Table } from "./Table/TableChild";
import { GlobalTargetsCompletionChild } from "./GlobalTargetsCompletion";
import { Conclusion } from "./Conclusion/Conclusion";
import { UserSpecificData } from "./UserSpecificData/UserSpecificData";
import { Targets } from "./Targets";

export type ChildContent = Extract<ContentItem, { type: "child" }>;

export const ChildContent = ({ child }: { child: ChildContent }) => {
  const dispatch = useDispatch();

  // [CSB] we do not allow to edit target scales for now
  const updateStructure = (data: Child /*, choicesForOrdinalTargets?: string[]*/) => {
    dispatch(
      edit({
        data,
        from: {
          sectionIndex: child.sectionIndex,
          pageIndex: child.pageIndex,
          childIndex: child.childIndex,
        },
        type: "child",
        // choicesForOrdinalTargets,
      })
    );
  };

  const updateChild = (data: Partial<Child>) => {
    dispatch(
      edit({
        data: { ...child.data, ...data } as Child,
        from: {
          sectionIndex: child.sectionIndex,
          pageIndex: child.pageIndex,
          childIndex: child.childIndex,
        },
        type: "child",
      })
    );
  };

  const indexes = useMemo(
    () => ({
      child: child.childIndex,
      page: child.pageIndex,
      section: child.sectionIndex,
    }),
    [child]
  );

  switch (child.data.kind) {
    case "question":
      // 🤯
      return <Question child={child.data} updateStructure={updateStructure} indexes={indexes} />;
    case "targets":
      return <Targets child={child.data} onChange={updateStructure} indexes={indexes} />;
    case "table":
      return <Table child={child.data} updateStructure={updateStructure} indexes={indexes} />;
    case "guidelines":
      return <Guidelines child={child.data} updateStructure={updateStructure} />;
    case "personnalisableGuidelines":
      return <PersonnalizedGuidelines child={child.data} updateStructure={updateStructure} />;
    case "conclusion":
      return <Conclusion />;
    case "html":
      return <HTMLChild child={child.data} updateChild={updateChild} />;
    case "title":
      return <Title child={child.data} updateStructure={updateStructure} />;
    case "interviewsHistory":
      return (
        <InterviewsHistoryChild
          child={child.data}
          updateStructure={updateStructure}
          indexes={indexes}
        />
      );
    case "trainingsHistory":
      return (
        <TrainingsHistoryChild
          child={child.data}
          updateStructure={updateStructure}
          indexes={indexes}
        />
      );
    case "trainingsCollection":
      return (
        <TrainingsCollectionChild
          child={child.data}
          indexes={{
            child: child.childIndex,
            page: child.pageIndex,
            section: child.sectionIndex,
          }}
        />
      );
    case "job":
      return <JobChild child={child.data} indexes={indexes} updateChild={updateChild} />;
    case "upload":
      return <FileUploadChild child={child.data} indexes={indexes} />;
    case "globalTargetsCompletion":
      return (
        <GlobalTargetsCompletionChild
          child={child.data}
          indexes={indexes}
          updateChild={updateChild}
        />
      );
    case "skills":
      return (
        <SkillsChild
          child={child.data}
          updateChild={updateChild}
          indexes={{
            child: child.childIndex,
            page: child.pageIndex,
            section: child.sectionIndex,
          }}
        />
      );
    case "userSpecificData": {
      if (User.isSkillupAdmin()) {
        return <UserSpecificData child={child.data} updateChild={updateChild} />;
      }
      return <UserSpecificData child={child.data} readOnly />;
    }
    default:
      return <div>Unknown child type</div>;
  }
};
