import { useMemo } from "react";

import { SkillAnalytic } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import generateColumns from "../utils/generateSkillsColumns";
import { DatatableSkillFields } from "../utils/getSkillsFields";
import generateTableRows from "../utils/generateSkillsTableRows";

type Props = {
  t: TranslationType;
  skills?: Array<SkillAnalytic>;
};

export default function useSkillsTableData({ skills, t }: Props) {
  const tableData = useMemo(
    () => ({
      columns: generateColumns({ fields: DatatableSkillFields, t }),
      rows: generateTableRows(skills),
    }),
    [skills, t]
  );

  return tableData;
}
