import { useEffect, useMemo } from "react";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@tanstack/react-query";

import { FieldRoutesType } from "@skillup/espace-rh-bridge";
import { EditableCompanyField, ICompanyField } from "@skillup/training-bridge";

import { buildRequest } from "utils/buildRequest";
import { useTypedFetch } from "hooks";
import useTranslation from "hooks/useTranslation";

import Loader from "components/Loader";
import { SidePanelFieldForm } from "../components/Form";

import { useFieldRefetch } from "../context";

const FieldDetailsView = ({
  selectedField,
  onClose,
}: {
  selectedField: ICompanyField;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const refetchLists = useFieldRefetch();
  const { addToast } = useToasts();

  const { data, loading, refetch, error } = useTypedFetch<FieldRoutesType.GetV2>({
    method: "GET",
    path: `/fields-v2`,
    query: {
      bindings: [selectedField?.binding],
      showHiddenFields: true,
    },
  });

  const [field] = data ?? [];

  const fieldHash = useMemo(() => {
    return Math.random();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field]);

  useEffect(() => {
    if (error) {
      addToast(t("fields.fetch.error"), { appearance: "error" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const {
    mutateAsync,
    data: updatedField,
    isLoading,
  } = useMutation({
    mutationFn: (data: EditableCompanyField) => {
      return buildRequest<FieldRoutesType.UpdateV2>({
        path: "/fields-v2",
        method: "POST",
        target: "API",
        payload: {
          ...(field ?? {}),
          ...data,
        },
      })();
    },
    onSuccess: () => {
      refetch();
      refetchLists();

      addToast(t("fields.form.success"), { appearance: "success" });
    },
    onError: () => {
      addToast(t("app.errors.message"), { appearance: "error" });
    },
  });

  const fieldData: EditableCompanyField = useMemo(() => {
    if (!field && !updatedField) {
      return undefined;
    }

    const finalField = updatedField ?? field;

    return {
      uuid: finalField.uuid,
      binding: finalField.binding,
      type: finalField.type,
      label: finalField.label,
      isTrainingField: finalField.isTrainingField,
      options: finalField.options,
      isHidden: finalField.isHidden,
      massEditAvailable: finalField.massEditAvailable,
      restrictions: finalField.restrictions,
      defaultValue: finalField.defaultValue,
      isStandard: finalField.isStandard,
      hasRows: finalField.hasRows,
    };
  }, [updatedField, field]);

  if (!fieldData || loading) {
    return <Loader />;
  }

  return (
    <SidePanelFieldForm
      key={fieldData.binding + fieldHash}
      loading={isLoading}
      field={fieldData}
      onSubmit={mutateAsync}
      onClose={onClose}
    />
  );
};

export default FieldDetailsView;
