import styled from "styled-components";

import { DSColor } from "@skillup/ui";

export const LoadingContainer = styled.div`
  height: 200px;
  background: white;
  width: 100%;
  border: 1px solid;
  border-color: ${DSColor["border-onLight"]};
  border-radius: 3px;
  padding: 16px 16px;
`;

export const Loader = styled.div`
  width: 20px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #69798c 90%, #0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
  }
  @keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
  }
`;
