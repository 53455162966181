import { useCallback, useMemo } from "react";
import { Flex, AssistiveArea } from "@skillup/ui";

import { TableColumn, TableColumnDisallow } from "../types";
import { TableChildColumnPermissionsRadioFormRoles } from "./TableChildColumnPermissionsRadioFormRoles";

import styles from "../TableChild.module.scss";

export const TableChildColumnPermissions = ({
  childId,
  column,
  index,
  onUpdatePermissions,
}: {
  childId: string;
  column: TableColumn;
  index: number;
  onUpdatePermissions: (disallow: TableColumnDisallow) => void;
}) => {
  const defaultRoleAllowReply = useMemo(() => {
    if (!column.disallow?.employee?.reply) {
      return "employee";
    }
    if (!column.disallow?.manager?.reply) {
      return "manager";
    }
    return "employee";
  }, [column.disallow]);

  const handleGrantReplyToRole = useCallback(
    (role: "employee" | "manager") => {
      const newDisallow = {
        employee: { ...column.disallow?.employee, reply: role !== "employee" },
        manager: { ...column.disallow?.manager, reply: role !== "manager" },
      };
      onUpdatePermissions(newDisallow);
    },
    [column, onUpdatePermissions]
  );
  return (
    <Flex column className={styles.table__column__permission}>
      <p className={styles.table__column__permission__actionLabel}>
        Qui peut remplir la colonne {index + 1} ({column.title}) ?
      </p>
      {column.type === "fixed" && (
        <AssistiveArea
          text={"Le contenu de la colonne étant fixé, aucun participant ne peut le modifier."}
        />
      )}
      {column.type !== "fixed" && (
        <>
          <AssistiveArea text={"Seul un des participants peut remplir une cellule d'un tableau."} />
            <TableChildColumnPermissionsRadioFormRoles
              childId={childId}
              columnUuid={column.uuid}
              role={defaultRoleAllowReply}
              onGrantForRole={handleGrantReplyToRole}
            />
        </>
      )}
    </Flex>
  );
};
