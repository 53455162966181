import { IPrimary } from "../../utils/fetchPrimary";

import {
  showAssociateToFileModal,
  showCancelModal,
  showCancelOrPostponeModal,
  showCancelReviewModal,
  showConfirmValidationModal,
  showDeleteModal,
  showEditModal,
  getRevertToPendingModal,
  showMoveToScheduleModal,
  showNoSessionModal,
  showPostponeModal,
  showRevertToApprovedHrModal,
  showSummonForIntraModal,
  showUnmanagedBySkillupModal,
} from "../../SupervisorTrainingScheduleModals";
import { reversedModes } from "../../Modals/ManualRowForms/Training";

import hasOneSessionAvailable from "../helpers/hasOneSessionAvailable";

import { IProps } from "./ActionsButton";

const ActionFactory = (
  props: IProps,
  rows: IPrimary[],
  history,
  selectedRows: string[],
  tab: string,
  noEmail: boolean,
  noManager: boolean,
  noEmailManager: boolean,
  reloadSelection: () => void,
  noSessions: boolean,
  openBookingPage: () => void,
  hasReceivedNotification: boolean,
  scheduleUuid: string,
  setCurrentModal
) => {
  return (actionKey: string) => {
    const { data = [], users, summary, onMoveToPlan, sessionsState } = props;
    const isCompanyTraining = !rows?.some((i) => !i.isCompanyTraining);

    switch (actionKey) {
      case "canSeeTrainingSession":
        return {
          action: () => history.push(`/responsable/mes-sessions/toutes/${rows[0].sessionUuid}`),
          label: "Voir la session",
        };
      case "canSummonASession":
        return {
          action: () =>
            showSummonForIntraModal({
              uuid: rows[0].sessionUuid,
              trainingName: rows[0].trainingName,
              formattedTimespan: rows[0].formattedTimespan,
            }),
          label: "Convoquer",
        };
      case "canUpdateUnmanagedBooking":
        return {
          action: () =>
            showUnmanagedBySkillupModal({
              uuids: selectedRows,
              summary,
              users,
              activeTab: tab,
              noEmail,
              noManager,
              reload: reloadSelection,
            }),
          label: "Compléter",
        };
      case "canCreateBookingUnmanaged":
        return {
          action: () =>
            showUnmanagedBySkillupModal({
              uuids: selectedRows,
              summary,
              users,
              activeTab: tab,
              noEmail,
              noManager,
              reload: reloadSelection,
            }),
          label: "Inscrire hors Skillup",
        };
      case "canMoveToPlan":
        return {
          action: () => showConfirmValidationModal(selectedRows, false, onMoveToPlan),
          label: "Valider et passer au plan",
        };
      case "canSendBookingMailReminder":
        return noSessions
          ? {
              action: () =>
                showNoSessionModal({
                  rows: selectedRows,
                  summary: props.summary,
                  reload: reloadSelection,
                  type: isCompanyTraining ? "intra" : "inter",
                }),
              actionKey,
              label: "Relancer par email",
            }
          : {
              action: () => props.showBookingMailReminderModal(selectedRows),
              label: "Relancer par email",
            };
      case "canBook":
        if (noSessions) {
          return {
            action: () =>
              showNoSessionModal({
                rows: selectedRows,
                summary: props.summary,
                reload: reloadSelection,
                type: isCompanyTraining ? "intra" : "inter",
              }),
            actionKey,
            label: "Inscrire à une session",
          };
        }

        if (sessionsState && !hasOneSessionAvailable(sessionsState) && selectedRows.length === 1) {
          const query = new URLSearchParams();
          if (props.summary.trainingUuid) {
            query.set("mode", "create");
            query.set("trainingUuid", props.summary.trainingUuid);
          }

          return {
            action: () => {
              history.push(`/responsable/mes-sessions/en-cours?${query.toString()}`);
            },
            actionKey,
            label: "Ajouter une session",
          };
        }

        return {
          action: () =>
            props.showPositioningModal(selectedRows, noEmail, noManager, noEmailManager),
          actionKey,
          label: "Inscrire à une session",
        };
      case "canSendValidationMailReminder":
        return {
          action: () => props.showValidationMailReminderModal(selectedRows),
          label: "Relancer le manager par email",
        };
      case "canByPassBooking":
        return {
          action: openBookingPage,
          label: "Inscrire le stagiaire",
        };
      case "canByPassValidation":
        return {
          action: () => showConfirmValidationModal(selectedRows, true, onMoveToPlan),
          label: "Forcer la validation et passer au plan",
        };
      case "canRequalify":
        return {
          action: () => props.showRequalifyModal(selectedRows, summary, props.fetch),
          actionKey,
          label: "Requalifier",
        };
      case "canUpdateManually":
        return {
          action: () =>
            props.showManualEditionProcess(
              selectedRows,
              {
                sessionDuration: summary.durationAsHours,
                // @ts-ignore
                sessionMode: reversedModes[summary.modality],
                // @ts-ignore
                sessionType: summary.types,
                total: summary.rawPrice,
                totalWithTax: summary.rawPriceWithTax,
                trainingName: summary.trainingName,
                trainingOrganizationName: summary.trainingOrganization,
              },
              props.fetch
            ),
          actionKey,
          label: "Éditer",
        };
      case "canDeleteManually":
        return {
          action: () => props.showDeleteManualRowsModal(selectedRows),
          label: "Supprimer",
          actionKey,
        };
      case "canDeny":
        return {
          action: () =>
            showCancelModal({
              rowsUuids: selectedRows,
              type: "deny",
              reload: reloadSelection,
              hasReceivedNotification,
            }),
          label: "Refuser la demande",
        };
      case "canCancel":
        return {
          action: () =>
            showCancelModal({
              rowsUuids: selectedRows,
              type: "cancel",
              reload: reloadSelection,
              hasReceivedNotification,
            }),
          label: "Annuler la demande",
          actionKey,
        };
      case "canCancelOrPostpone":
        return {
          action: () =>
            showCancelOrPostponeModal({
              rows: selectedRows,
              addedManually: summary.addedManually,
              managedBySkillup: summary.managedBySkillup,
              type: summary.types,
              scheduleUuid,
              reload: reloadSelection,
              hasReceivedNotification,
            }),
          label: "Reporter ou annuler",
        };
      case "canMoveToSchedule":
        return {
          action: () =>
            showMoveToScheduleModal({
              reload: reloadSelection,
              currentState: data[0].primaryState,
              rowsUuids: selectedRows,
              scheduleUuid,
              summary,
            }),
          label: "Déplacer vers un autre plan",
        };
      case "canRevertToPending":
        return {
          action: () =>
            setCurrentModal(
              getRevertToPendingModal({
                reload: reloadSelection,
                onClose: () => setCurrentModal(null),
                rowsUuids: selectedRows,
              })
            ),
          label: "Remettre dans “A valider RH”",
        };
      case "canPostpone":
        return {
          // @ts-ignore
          action: () => showPostponeModal(rows[0], props.context),
          label: "[OPS] Reporter",
        };
      case "canAssociateToFile":
        return {
          action: () => showAssociateToFileModal(selectedRows),
          label: "[OPS] Réassocier à un dossier",
        };
      case "canRevertToApproved":
        return {
          action: () =>
            showRevertToApprovedHrModal({
              rows: selectedRows,
              reload: reloadSelection,
              activeTab: tab,
              users,
            }),
          label: "Remettre dans « À inscrire »",
        };
      case "canEdit":
        return {
          action: () =>
            showEditModal({
              rows: selectedRows,
              type: rows[0].type,
              reload: reloadSelection,
            }),
          label: "[OPS] Modifier",
        };
      case "canDelete":
        return {
          action: () =>
            showDeleteModal({
              reload: reloadSelection,
              rows: selectedRows,
              activeTab: tab,
            }),
          label: "Supprimer",
        };
      case "canCreateBookingUnmanagedForOPS":
        return {
          action: () =>
            showUnmanagedBySkillupModal({
              uuids: selectedRows,
              summary,
              users,
              activeTab: tab,
              noEmail,
              noManager,
              reload: reloadSelection,
            }),
          label: "[OPS] Inscrire",
        };
      case "canUpdateUnmanagedBookingForOPS":
        return {
          action: () =>
            showUnmanagedBySkillupModal({
              uuids: selectedRows,
              summary,
              users,
              activeTab: tab,
              noEmail,
              noManager,
              reload: reloadSelection,
            }),
          label: "[OPS] Compléter",
        };
      case "canCancelReview":
        return {
          action: () => showCancelReviewModal({ uuid: props.data[0].uuid }),
          disabled: !rows[0]?.hasReviews,
          tooltip: !rows[0]?.hasReviews ? "Aucune évaluation à annuler" : "",
          label: "Annuler l'évaluation",
        };
      default:
        return null;
    }
  };
};

export default ActionFactory;
