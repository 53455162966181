import { useState } from "react";

import {
  DSFormGroupTextInput,
  DSAvatarGroup,
  DSModal,
  DSTextInput
} from "@skillup/ui";
import { SessionUser } from "@skillup/espace-rh-bridge";

import { PartialCancelParams } from "services/sessions/partialCancel";

import { Project } from "services/sessions";
import { getUserInitials } from "utils/User";
import useConfirmAction from "hooks/useConfirmAction";
import useTranslation from "hooks/useTranslation";

import AttendeeNotificationAlert from "./AttendeeNotificationAlert";
import styles from "./CancelModal.module.scss";

type Props = {
  project: Project;
  trainees: Array<SessionUser>;
  onBack: () => void;
  onClose: () => void;
  partialCancel: (params: PartialCancelParams) => Promise<void>;
};

const CancelModal = ({ project, trainees, onBack, onClose, partialCancel }: Props) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState<string>();

  const onConfirm = () => {
    return partialCancel({
      rowsUuid: trainees.map((trainee) => trainee.row),
      comment,
    }).then(() => {
      onClose();
    });
  };

  const { current, send } = useConfirmAction(onConfirm, onBack, onClose);

  return (
    <DSModal isOpen className={styles.CancelModal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title
          title={t("trainings.manage.users.cancel.title", {
            count: trainees.length,
            defaultValue: "Annuler la participation de {{count}} stagiaire",
          })}
        />
        <DSModal.Header.SubHeader>
          <DSAvatarGroup
            size="S"
            tooltipDirection="bottom"
            maxItems={5}
            totalItems={trainees.length}
            users={trainees.map((trainee) => {
              return {
                uuid: trainee.uuid,
                fullName: trainee.fullName,
                initials: getUserInitials(trainee),
              };
            })}
          />
        </DSModal.Header.SubHeader>
      </DSModal.Header>

      <DSModal.Content>
        <p>
          {t("trainings.manage.users.cancel.description", {
            count: trainees.length,
            defaultValue:
              "Si vous confirmez cette action, le stagiaire ne pourra pas être réinscrit à d'autres sessions.",
          })}
        </p>

        <DSFormGroupTextInput
          className={styles.groupTextInput}
          label={t("trainings.manage.users.cancel.comment.label", {
            defaultValue: "Commentaire (facultatif)",
          })}
          assistiveText={t("trainings.manage.users.cancel.comment.assistiveText", {
            count: trainees.length,
            defaultValue:
              "Le commentaire sera visible pour le manager et les responsables ayant accès au plan, mais ne sera pas transmis au collaborateur.",
          })}
        >
          <DSTextInput
            autoFocus={true}
            placeholder={t("trainings.manage.users.cancel.comment.placeholder", {
              defaultValue: "Saisissez votre commentaire",
            })}
            value={comment}
            onChange={setComment}
            name="message"
          />
        </DSFormGroupTextInput>
        
        <AttendeeNotificationAlert className={styles.attendeeNotificationAlert} type="cancel" project={project} trainees={trainees}/>
      </DSModal.Content>

      <DSModal.Footer>
        <DSModal.Footer.CancelButton
          disabled={current.context.loading}
          label={t("trainings.manage.users.form.goback", { defaultValue: "Retour" })}
          onClick={() => send("BACK")}
        />
        <DSModal.Footer.PrimaryButton
          loading={current.context.loading}
          label={t("trainings.manage.users.form.cancel", {
            defaultValue: "Confirmer l'annulation",
          })}
          onClick={() => send("CONFIRM")}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default CancelModal;
