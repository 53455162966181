import { getUserData } from "utils/User";

function useCheckCompanyScope(scope: string) {
  const userData = getUserData();

  return userData.activeCompany?.scope?.includes(scope) ?? false;
}

export function useIsPentestCompany() {
  return useCheckCompanyScope("pentest");
}
