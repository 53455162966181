import { ListUtils } from "@skillup/shared-utils";

export const FieldsFilters: ListUtils.FilterConfigurationMap = {
  label: {
    type: ListUtils.FilterType.TEXT,
  },
  binding: {
    type: ListUtils.FilterType.TEXT,
  },
  type: {
    type: ListUtils.FilterType.MULTISELECT,
  },
};

export const CustomFieldsFilters: ListUtils.FilterConfigurationMap = {
  ...FieldsFilters,
  isTrainingField: {
    type: ListUtils.FilterType.MULTISELECT,
  },
};
