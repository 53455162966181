import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import storage, { allPersistentStorages } from "services/storage";
import User from "utils/User";
import { analytics } from "services";

import { IUser } from "@skillup/types";

const MAX_RETRY = 3;
const RETRY_INTERVAL = 1000;
const wait = (interval) => new Promise((resolve) => setTimeout(resolve, interval));
async function trySignin(tryNumber = 1): Promise<IDataType | void> {
  try {
    return (await DataLayer.request({
      target: "AUTH",
      url: "/signin",
    })) as IDataType;
  } catch (error) {
    if (tryNumber > MAX_RETRY) {
      throw error;
    }
    await wait(RETRY_INTERVAL * tryNumber);
    return await trySignin(tryNumber + 1);
  }
}

export interface IDataType {
  token: {};
  credentials: {};
  humanValidationRequired?: boolean;
}

const removeStoredAuth = async () => {
  if (typeof window === "undefined") return;

  await DataLayer.request({
    target: "AUTH",
    url: "/signout",
  });

  storage.remove("X-Auth-Token", allPersistentStorages);
  storage.remove("X-Auth-As-Organization");
  storage.remove("X-Auth-As-User");
  storage.remove("_skillup_userData");
  storage.remove("activeAreas");

  Acta.setState("userData", null, "localStorage");
  Acta.setState("LOCALE", null, "localStorage");
};

const requestHash = async (email?: string, hash?: string, redirectUrl?: string | null) => {
  if (!email && !hash) throw new Error("Invalid e-mail or hash");

  await DataLayer.request({
    body: JSON.stringify({ email, hash, redirectUrl }),
    target: "AUTH",
    method: "POST",
    url: "/link",
  });
};

const signin = async (canRemoveUserAreaToken = true): Promise<IDataType | void> => {
  let data;
  try {
    data = await trySignin();
  } catch (error) {
    if (error.statusCode && error.statusCode !== 429) {
      removeStoredAuth();
    }
    return;
  }

  if (data.token) {
    authenticate(data, canRemoveUserAreaToken);
    return data;
  } else {
    removeStoredAuth();
  }
};

const switchTo = async (uuid) => {
  const data = (await DataLayer.request({
    target: "AUTH",
    method: "GET",
    url: `/organization/${uuid}`,
  })) as IDataType;

  authenticate(data);

  return data;
};

const switchToGroup = async (uuid) => {
  const data = (await DataLayer.request({
    target: "AUTH",
    method: "GET",
    url: `/group/${uuid}`,
  })) as IDataType;

  authenticate(data);

  return data;
};

const switchToUserByEmail = async (email) => {
  const data = (await DataLayer.request({
    target: "AUTH",
    method: "GET",
    url: `/user-by-email/${email}`,
  })) as IDataType;

  authenticate(data);

  return data;
};

const signinWithHash = async (hash, human) => {
  if (!hash) throw new Error("Invalid hash");

  const data = (await DataLayer.request({
    target: "AUTH",
    method: "GET",
    url: `/link/${hash}${human ? "?human=true" : ""}`,
  })) as IDataType;

  if (data.humanValidationRequired) {
    return data;
  }

  authenticate(data);

  return data;
};

const signinWithUserLogin = async (loginData) => {
  const data = (await DataLayer.request({
    target: "AUTH",
    body: JSON.stringify(loginData),
    method: "POST",
    url: "/signin",
  })) as IDataType;

  authenticate(data);

  return data;
};

/**
 * Only used in <Redirect /> (legacy portal mostly ?)
 */
const signinWithCompanyToken = async (queryToken) => {
  storage.set("X-Auth-Token", queryToken);

  const data = (await DataLayer.request({
    target: "AUTH",
    authentificationToken: queryToken,
    method: "GET",
    url: "/signin",
  })) as IDataType;

  authenticate(data);

  return data;
};

/**
 * Store authentication token, update state and analytics
 */
const authenticate = (data, canRemoveUserAreaToken = true) => {
  const { credentials, token } = data;

  if (canRemoveUserAreaToken) {
    storage.remove("activeAreas");
  }

  if (token) {
    const storageEngine = getStorageEngine(credentials);
    const otherStorages = allPersistentStorages.filter((s) => s !== storageEngine);
    storage.remove("X-Auth-Token", otherStorages);

    if (storageEngine) {
      storage.set("X-Auth-Token", token, 0, [storageEngine]);
    }
  }

  userDataForAnalytics(credentials);

  storage.set("_skillup_userData", JSON.stringify(credentials));
  Acta.setState("userData", credentials, "localStorage");
};
function getStorageEngine(credentials: IUser) {
  switch (credentials.persistStrategy) {
    case "sessionStorage":
      return sessionStorage;
    case "localStorage":
      return localStorage;
    default:
      return null;
  }
}

/**
 * See https://slite.com/api/s/TnHmfvS4ic6ukYxyrJ4gXg/User%20Level%20Access
 */
const userDataForAnalytics = (credentials) => {
  const activeCompany = credentials && credentials.activeCompany;
  const activeTrainingOrganization = credentials && credentials.activeTrainingOrganization;

  // User level
  let userLvl = "Not found";
  if (User.isSkillupAdmin(credentials) || User.isSkillupDeveloper(credentials)) {
    userLvl = "SkillupUser"; // skillup
  } else if (User.isRF(credentials)) {
    userLvl = "RH"; //  RH
  } else if (User.isManager(credentials)) {
    userLvl = "Manager"; // Userless
  } else if (User.isRegularUser(credentials)) {
    userLvl = "User"; // Regular user
  } else if (User.isUserless(credentials)) {
    userLvl = "Userless"; // Userless
  } else if (User.isOF(credentials)) {
    userLvl = "TrainingOrganizationUser"; // Training org user
  }

  // Company name
  let companyName = "Not found";
  if (activeCompany && activeCompany.name) {
    companyName = activeCompany.name;
  } else if (activeTrainingOrganization) {
    companyName = activeTrainingOrganization.name;
  }

  analytics.setDimension("dimension1", companyName);
  analytics.setDimension("dimension2", userLvl);
  analytics.setDimension("dimension4", credentials.uuid || "userless");

  if (credentials.uuid) analytics.setUser(credentials.uuid);

  analytics.sendEvent("Authentication", "Authenticated");
};
export {
  requestHash,
  signin,
  signinWithCompanyToken,
  signinWithHash,
  signinWithUserLogin,
  removeStoredAuth as signout,
  switchTo,
  switchToGroup,
  switchToUserByEmail,
};

export default {
  requestHash,
  signin,
  signinWithCompanyToken,
  signinWithHash,
  signinWithUserLogin,
  signout: removeStoredAuth,
  switchTo,
  switchToGroup,
  switchToUserByEmail,
};
