import React from "react";
import TargetsHistoryImport from "./TargetsHistoryImport";

import styles from "./Targets.module.scss";

export default () => (
  <div>
    <div className={styles.Targets}>
      <h2>Importer un historique d'objectifs</h2>
      <TargetsHistoryImport />
    </div>
  </div>
);
