import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { atom, useRecoilState } from "recoil";
import cx from "classnames";
import { useTypedFetch } from "hooks";
import { keyBy } from "lodash";

import {
  TextInput,
  DropDownCheckbox,
  DSButton,
  Select,
  SelectMenuPlacement,
  SelectProps,
  DataTable,
  Flex,
  DSDropdownItem,
  generateModalContext,
} from "@skillup/ui";
import { OrganizationRoutes } from "@skillup/espace-rh-bridge";

import type { UserRoutes, GetUsersByFilterResponse, OrganizationRoutesType } from "types/api";
import { plural } from "utils/locale";
import User from "utils/User";
import useAreas from "hooks/useAreas";
import Acta from "utils/Acta";

import { AuthStrategyType, BulkAction, User as UserType } from "./types";

import Loader from "components/Loader";
import ClipboardText from "./components/ClipboardText";
import RestoreUsersModal from "./components/RestoreUsersModal";
import RevokeUsersModal from "./components/RevokeUsersModal";
import ResetPasswordsModal from "./components/ResetPasswordsModal";
import NewPasswordsModal from "./components/NewPasswordsModal";
import UserConnectionMode from "./components/UserConnectionMode";
import LastConnection from "./components/LastConnection";
import UserActions from "./components/UserActions";
import CompanyConnectionMode from "./components/CompanyConnectionMode";

import CreateAuthStrategyModal from "./modals/CreateAuthStrategyModal";
import UpdateAuthStrategyModal from "./modals/UpdateAuthStrategyModal";

import useTableData from "./useTableData";
import useAuthStrategy from "./userAuthStrategy";

import styles from "./ConnectionModes.module.scss";
import { AuthStrategy } from "@skillup/espace-rh-bridge";
import DeleteAuthStrategyModal from "./modals/DeleteAuthStrategyModal";
import SetDefaultStrategyModal from "./modals/SetDefaultStrategyModal";
import SetIsMandatoryIfUnknownStrategyModal from "./modals/SetIsMandatoryIfUnknownStrategyModal";
import DeleteIsMandatoryIfUnknownStrategyModal from "./modals/DeleteIsMandatoryIfUnknownStrategyModal";
import AuthStrategyConnectionInfoModal from "./modals/AuthStrategyConnectionInfoModal";
import { buildRequest } from "utils/buildRequest";

const useCreateModal = generateModalContext();
const useModifyModal = generateModalContext();
const useDeleteModal = generateModalContext();
const useSetDefaultStrategyModal = generateModalContext();
const useSetIsMandatoryIfUnknownStrategyModal = generateModalContext();
const useDeleteIsMandatoryIfUnknownStrategyModal = generateModalContext();
const useAuthStrategyConnectionInfo = generateModalContext();

const ConnectionModes = () => {
  const { data: connectionSettings, loading } = useTypedFetch<OrganizationRoutesType.AuthSettings>({
    method: "GET",
    path: `/organization/auth-settings`,
  });

  const { addToast } = useToasts();
  async function handleCopy(value: string) {
    if (!value) return;
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(value);
    }
    addToast("Lien de connexion copié dans le presse-papier", { appearance: "success" });
  }

  const refreshProviders = useCallback(async () => {
    await buildRequest({
      target: "AUTH",
      method: "GET",
      path: `/refresh-providers`,
    })();
  }, []);

  const [userFilter, setUserFilter] = React.useState("");
  function handleUserFilterChange(value) {
    setUserFilter(value);
  }
  const { allAreas } = useAreas();
  const [areasFilter, setAreasFilter] = React.useState<
    Array<{
      label: string;
      value: string;
      isSelected: boolean;
    }>
  >([]);
  useEffect(() => {
    setAreasFilter(
      allAreas.map((area) => ({
        label: area.name,
        value: area.uuid,
        isSelected: true,
      }))
    );
  }, [allAreas]);
  function handleAreasFilterChange(value) {
    setAreasFilter(value);
  }

  const getAuthStrategy = useAuthStrategy(connectionSettings);

  const quickFilters = useMemo(
    () => ({
      sso_all: (user: UserType) => getAuthStrategy(user) === AuthStrategyType.SAML,
      sso_noconnect: (user: UserType) =>
        getAuthStrategy(user) === AuthStrategyType.SAML && !user.properties.lastConnectDate,
      password_all: (user: UserType) => getAuthStrategy(user) === AuthStrategyType.PASSWORD,
      password_noconnect: (user: UserType) =>
        getAuthStrategy(user) === AuthStrategyType.PASSWORD && !user.properties.lastConnectDate,
      password_none: (user: UserType) =>
        getAuthStrategy(user) === AuthStrategyType.PASSWORD && !user.hasHashedPassword,
      noaccess: (user: UserType) => !!user.properties.disabled,
    }),
    [getAuthStrategy]
  );

  const { data: users, refetch } = useTypedFetch<UserRoutes.Get>({
    path: "/user",
    method: "GET",
    query: { deleted: "all" },
  });

  type QuickFilterKey = keyof typeof quickFilters;
  const quickFilterOptions = useMemo(() => {
    let res: SelectProps<QuickFilterKey>["options"] = [];
    if (!connectionSettings) return res;
    if (connectionSettings.authStrategies.some((e) => e.type === AuthStrategyType.SAML)) {
      res.push({
        label: "Collaborateurs avec connexion SSO",
        options: [
          {
            label: "Tous",
            selectedLabel: "SSO",
            value: "sso_all",
          },
          {
            label: "Qui ne se sont jamais connectés",
            selectedLabel: "SSO - jamais connectés",
            value: "sso_noconnect",
          },
        ],
      });
    }
    if (connectionSettings.authStrategies.some((e) => e.type === AuthStrategyType.PASSWORD)) {
      res.push({
        label: "Collaborateurs avec mot de passe",
        options: [
          {
            label: "Tous",
            selectedLabel: "Mot de passe",
            value: "password_all",
          },
          {
            label: "Qui ne se sont jamais connectés",
            selectedLabel: "Mot de passe - jamais connectés",
            value: "password_noconnect",
          },
          {
            label: "Dont le mot de passe n'a jamais été généré",
            selectedLabel: "Mot de passe - sans mot de passe",
            value: "password_none",
          },
        ],
      });
    }
    if (Array.isArray(users) && users.some((user) => user.properties.disabled)) {
      res.push({
        label: "Collaborateurs sans accès à Skillup",
        options: [
          {
            label: "Tous",
            selectedLabel: "Sans accès",
            value: "noaccess",
          },
        ],
      });
    }
    return res;
  }, [users, connectionSettings]);
  const [quickFilterKey, setQuickFilterKey] = useState<QuickFilterKey>(null);
  const quickFilter = useMemo(() => quickFilters[quickFilterKey], [quickFilters, quickFilterKey]);

  const filteredUsers = useMemo(() => {
    if (!Array.isArray(users)) return [];
    const selectedAreas = areasFilter.filter((area) => area.isSelected);
    return users.filter((user) => {
      if (userFilter) {
        const filter = userFilter.toLowerCase();
        const {
          login,
          properties: { fullName },
        } = user;
        if (!fullName?.toLowerCase().includes(filter) && !login?.toLowerCase().includes(filter))
          return false;
      }
      if (selectedAreas.length !== areasFilter.length) {
        const hasArea = user.areas.some((area) =>
          selectedAreas.some((filter) => filter.value === area.uuid)
        );
        if (!hasArea) return false;
      }
      if (quickFilter && !quickFilter(user)) return false;
      return true;
    }) as GetUsersByFilterResponse;
  }, [users, userFilter, areasFilter, quickFilter]);
  const [checkedUsers, setCheckedUsers] = useState(new Set<string>());

  function closeModal() {
    setUserActionModal(null);
  }
  function closeModalAndRefresh() {
    closeModal();
    refetch();
  }
  type BulkUsersProp = {
    users: UserType[];
  };
  function restoreAccess({ users }: BulkUsersProp) {
    setUserActionModal(
      <RestoreUsersModal users={users} onClose={closeModal} onSubmit={closeModalAndRefresh} />
    );
  }
  function revokeAccess({ users }: BulkUsersProp) {
    setUserActionModal(
      <RevokeUsersModal users={users} onClose={closeModal} onSubmit={closeModalAndRefresh} />
    );
  }
  function generatePasswords({ users }: BulkUsersProp) {
    setUserActionModal(
      <ResetPasswordsModal
        users={users}
        onClose={closeModal}
        onSubmit={(passwords) => showPasswordsGenerated(users, passwords)}
      />
    );
  }

  function showPasswordsGenerated(
    users: UserType[],
    passwords: Array<{ userUuid: string; password: string }>
  ) {
    const passwordsByUser = passwords.reduce<Record<string, string>>((acc, password) => {
      acc[password.userUuid] = password.password;
      return acc;
    }, {});

    setUserActionModal(
      <NewPasswordsModal
        users={users}
        passwords={passwordsByUser}
        onClose={closeModal}
        onSubmit={closeModal}
      />
    );
  }

  const [userActionModal, setUserActionModal] = useState<ReactNode>(null);

  const bulkActions = useMemo(() => {
    if (checkedUsers.size === 0) return null;
    const currentUserUuid = User.getUserData()?.uuid;
    let allBulkActions = [
      {
        name: "Restaurer l’accès à Skillup",
        available: (user: UserType) => user.properties.disabled,
        action: (users: UserType[]) => {
          restoreAccess({ users });
        },
      },
      {
        name: "Révoquer l’accès à Skillup",
        available: (user: UserType) =>
          !user.properties.disabled && user.properties.uuid !== currentUserUuid,
        action: (users: UserType[]) => {
          revokeAccess({ users });
        },
      },
      {
        name: "Générer les mots de passe",
        available: (user: UserType) =>
          !user.properties.disabled && getAuthStrategy(user) === AuthStrategyType.PASSWORD,
        action: (users: UserType[]) => {
          generatePasswords({ users });
        },
      },
    ];

    const usersByUuid = keyBy(filteredUsers, (user) => user.properties.uuid);
    const existingCheckedUsers = [...checkedUsers].filter((uuid) => usersByUuid[uuid]);
    return allBulkActions.filter((bulkAction) => {
      return existingCheckedUsers.every((uuid) => bulkAction.available(usersByUuid[uuid]));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedUsers, filteredUsers, getAuthStrategy]);

  function handleBulkAction(bulkAction: BulkAction) {
    const usersByUuid = keyBy(filteredUsers, (user) => user.properties.uuid);
    const selectedUsers = [...checkedUsers].map((uuid) => usersByUuid[uuid]).filter(Boolean);
    bulkAction.action(selectedUsers);
  }

  const collabListTableData = useMemo(
    () =>
      filteredUsers.map((user) => ({
        id: user.properties.uuid,
        data: {
          user,
          lastName: user.properties.lastName,
          firstName: user.properties.firstName,
          login: user.login,
          lastConnectDate: user.properties.lastConnectDate,
          isImmune: user.properties.isImmune,
        },
      })),
    [filteredUsers]
  );

  const paginationHook = useRecoilState(connectionModesPagination);

  const createModal = useCreateModal();
  const modifyModal = useModifyModal();
  const deleteModal = useDeleteModal();
  const setDefaultStrategyModal = useSetDefaultStrategyModal();
  const setIsMandatoryIfUnknownStrategyModal = useSetIsMandatoryIfUnknownStrategyModal();
  const deleteIsMandatoryIfUnknownStrategyModal = useDeleteIsMandatoryIfUnknownStrategyModal();
  const authStrategyConnectionInfo = useAuthStrategyConnectionInfo();

  const userData = Acta.getState("userData");
  const companyUuid = userData.activeCompany.uuid;
  const { data: authStrategiesData = [], refetch: refetchAuthStrategies } =
    useTypedFetch<OrganizationRoutes.GetAuthStrategies>({
      method: "GET",
      path: `/organization/{companyUuid}/auth-strategies`,
      params: {
        companyUuid,
      },
    });

  const [currentAuthStrategy, setCurrentAuthStrategy] = useState<AuthStrategy>();

  const { columns: authStrategiesColumns, data: authStrategiesTableData } = useTableData(
    authStrategiesData ?? [],
    connectionSettings?.authLoginUrl
  );

  const collabListColumns = useMemo(() => {
    const hasAtLeastOneCollabImmune = collabListTableData.some(
      (collab) => collab.data.user.properties.isImmune
    );

    return [
      {
        key: "connectionType",
        title: "Type de connexion",
        renderCell: ({ user }) => (
          <UserConnectionMode user={user} connectionSettings={connectionSettings} />
        ),
        sortable: false,
        filterable: false,
      },
      {
        key: "lastName",
        title: "Nom",
      },
      {
        key: "firstName",
        title: "Prénom",
      },
      {
        key: "login",
        title: "Identifiant",
      },
      {
        key: "lastConnectDate",
        title: "Dernière connexion",
        renderCell: ({ lastConnectDate }) => <LastConnection date={lastConnectDate} />,
        filterable: false,
      },
      ...(hasAtLeastOneCollabImmune
        ? [
            {
              key: "collabImmune",
              title: "Immunisé à l'import",
              renderCell: ({ user }) => <>{user.properties.isImmune ? "Oui" : "Non"}</>,
              sortFn: ({ data: user1 }, { data: user2 }) => {
                return user1.isImmune === user2.isImmune ? 0 : user1.isImmune ? -1 : 1;
              },
              filterFn: ({ data: user }, value) => (user.isImmune ? "Oui" : "Non").includes(value),
            },
          ]
        : []),
    ];
  }, [collabListTableData, connectionSettings]);

  if (loading) {
    return (
      <main className={cx(styles.ConnectionModes, styles.loading)}>
        <h1 className={styles.header}>Mon entreprise</h1>
        <Loader />
      </main>
    );
  }
  return (
    <main className={styles.ConnectionModes}>
      <section className={styles.companySettings}>
        <div className={styles.companyConnectionModesWrapper}>
          <div className={styles.companyConnectionModes}>
            <CompanyConnectionMode
              label="Via SSO"
              helpText="Le Single Sign-On (SSO) est une technologie d’authentification unique permettant de se
              connecter avec un seul identifiant à de multiples applications"
              enabled={connectionSettings?.authStrategies.some(
                (e) => e.type === AuthStrategyType.SAML
              )}
            />
            <CompanyConnectionMode
              label="Avec mot de passe"
              helpText="Ce type de connexion est intéressant pour les collaborateurs ne possédant pas
              d’adresse email professionnelle"
              enabled={connectionSettings?.authStrategies.some(
                (e) => e.type === AuthStrategyType.PASSWORD
              )}
            />
            <CompanyConnectionMode
              label="Avec lien unique"
              helpText="La connexion One Time Link (OTL) est une technologie d’authentification permettant
              de se connecter via un lien temporaire liant un utilisateur à une session"
              enabled={connectionSettings?.authStrategies.some(
                (e) => e.type === AuthStrategyType.OTL
              )}
            />
          </div>
          <div className={styles.connectionModesDisclaimer}>
            Vous ne pouvez pas activer/désactiver un mode de connexion vous-même. Veuillez vous
            rapprocher de votre responsable de compte.
          </div>
        </div>
        <div className={styles.companyConnectionLink}>
          <h2>Lien de connexion à Skillup :</h2>
          <div className={styles.content}>
            <ClipboardText
              value={connectionSettings?.authLoginUrl}
              tooltipText="Copier l’URL de connexion"
            />
            <div className={styles.description}>
              L’url à communiquer à vos collaborateurs pour la connexion à Skillup
            </div>
          </div>
        </div>
      </section>

      {User.isSkillupAdmin() && (
        <section className={styles.authStrategy}>
          <h2>[OPS] Gestion des stratégies de connexion</h2>
          <div className={styles.buttonsWrapper}>
            <DSButton
              emphasis="Mid"
              label="Refresh Providers"
              onClick={refreshProviders}
              loading={loading}
            />
            <DSButton
              emphasis="High"
              label="Ajouter une stratégie"
              onClick={createModal.openModal}
              loading={loading}
            />
          </div>
          {authStrategiesTableData.length > 0 && (
            <Flex className={styles.AuthStrategiesTable}>
              <DataTable
                className={styles.table}
                columns={authStrategiesColumns}
                rows={authStrategiesTableData}
                mode="compact"
                onClickRow={(row) => {
                  handleCopy(row.data?.link);
                }}
                actions={(row) => {
                  const authStrategy = authStrategiesData.find((h) => row.id === h.uuid);
                  const disabled = (actionType: string) => {
                    return authStrategy.isDefaultStrategy && actionType === "delete";
                  };
                  const tooltip = (disabled: boolean, actionType: string) => {
                    if (disabled && actionType === "delete") {
                      return "Impossible de supprimer la stratégie par défaut";
                    }
                    return undefined;
                  };
                  return row.data.actions.map((action) => (
                    <DSDropdownItem
                      key={action.type}
                      label={action.label}
                      disabled={disabled(action.type)}
                      tooltipLabel={tooltip(disabled(action.type), action.type)}
                      tooltipDirection="top"
                      onClick={() => {
                        setCurrentAuthStrategy(authStrategy);
                        switch (action.type) {
                          case "modify":
                            modifyModal.openModal();
                            break;
                          case "delete":
                            deleteModal.openModal();
                            break;
                          case "default-strategy":
                            setDefaultStrategyModal.openModal();
                            break;
                          case "mandatory-unknown":
                            setIsMandatoryIfUnknownStrategyModal.openModal();
                            break;
                          case "delete-mandatory-unknown":
                            deleteIsMandatoryIfUnknownStrategyModal.openModal();
                            break;
                          case "display-connection-info":
                            authStrategyConnectionInfo.openModal();
                            break;
                        }
                      }}
                    />
                  ));
                }}
              />
            </Flex>
          )}
        </section>
      )}

      <section className={styles.userSettings}>
        <h2>Préférences de connexion par collaborateur</h2>
        <div className={styles.userFilters}>
          {allAreas && (
            <div className={styles.userFilter}>
              <div className={styles.label}>Périmètres</div>
              <div className={styles.input}>
                <DropDownCheckbox
                  className={styles.userAreaFilter}
                  labels={{
                    itemSingular: "périmètre",
                    itemPlural: "périmètres",
                    allItemsSelected: "Tous les périmètres",
                    noItemSelected: "Aucun périmètre",
                  }}
                  items={areasFilter}
                  onChange={handleAreasFilterChange}
                />
              </div>
            </div>
          )}
          {!!quickFilterOptions.length && (
            <div className={styles.userFilter}>
              <div className={styles.label}>Filtres rapides</div>
              <div className={styles.input}>
                <Select
                  className={styles.quickFilterToggle}
                  placeholder="Choisir un filtre..."
                  menuPlacement={SelectMenuPlacement.TOP}
                  clearable
                  options={quickFilterOptions}
                  value={quickFilterKey}
                  onChange={setQuickFilterKey}
                />
              </div>
            </div>
          )}
          <div className={styles.userFilter}>
            <div className={styles.label}>Collaborateur</div>
            <div className={styles.input}>
              <TextInput
                className={styles.inputFilter}
                placeholder="Rechercher un collaborateur"
                value={userFilter}
                onChange={(value) => handleUserFilterChange(value)}
              />
            </div>
          </div>
        </div>
        {Array.isArray(users) && (
          <DataTable<(typeof collabListTableData)[0]>
            className={styles.TableWrapper}
            containerClassName={styles.Table}
            columns={collabListColumns}
            rows={collabListTableData}
            checkboxes
            actions={({ data }) => (
              <UserActions
                user={data.user}
                connectionSettings={connectionSettings}
                onShowModal={setUserActionModal}
                onUpdate={refetch}
              />
            )}
            header={{
              selectedRowsLabel: (selected, total) =>
                `${plural(selected, "%n collaborateur%s sélectionné%s")} sur ${total}`,
              totalRowsLabel: (total) => {
                let label = plural(total, "%n collaborateur%s");
                if (total < users.length) {
                  label += `${plural(total, " filtré%s")} sur ${users.length}`;
                }
                return label;
              },
              actions: () =>
                bulkActions &&
                (bulkActions.length ? (
                  bulkActions.map((bulkAction) => (
                    <DSButton
                      key={bulkAction.name}
                      label={bulkAction.name}
                      onClick={() => handleBulkAction(bulkAction)}
                    />
                  ))
                ) : (
                  <span>Aucune action de masse n’est disponible pour la sélection courante</span>
                )),
            }}
            pagination={{
              rowsPerPageLabel: "Lignes par page",
              itemsCountLabel: "Collaborateur %range% sur %count%",
              pageLabel: "Page",
              stateHook: paginationHook,
            }}
            onCheckRows={setCheckedUsers}
          />
        )}
      </section>

      {createModal.isOpen && (
        <CreateAuthStrategyModal
          onClose={() => {
            createModal.closeModal();
            refetchAuthStrategies();
          }}
        />
      )}

      {modifyModal.isOpen && currentAuthStrategy && (
        <UpdateAuthStrategyModal
          authStrategy={currentAuthStrategy}
          onClose={() => {
            modifyModal.closeModal();
            refetchAuthStrategies();
          }}
        />
      )}

      {deleteModal.isOpen && currentAuthStrategy && (
        <DeleteAuthStrategyModal
          authStrategy={currentAuthStrategy}
          onClose={() => {
            deleteModal.closeModal();
            refetchAuthStrategies();
          }}
        />
      )}

      {setDefaultStrategyModal.isOpen && currentAuthStrategy && (
        <SetDefaultStrategyModal
          authStrategyUuid={currentAuthStrategy?.uuid}
          onClose={() => {
            setDefaultStrategyModal.closeModal();
            refetchAuthStrategies();
          }}
        />
      )}

      {setIsMandatoryIfUnknownStrategyModal.isOpen && currentAuthStrategy && (
        <SetIsMandatoryIfUnknownStrategyModal
          authStrategyUuid={currentAuthStrategy?.uuid}
          onClose={() => {
            setIsMandatoryIfUnknownStrategyModal.closeModal();
            refetchAuthStrategies();
          }}
        />
      )}

      {deleteIsMandatoryIfUnknownStrategyModal.isOpen && currentAuthStrategy && (
        <DeleteIsMandatoryIfUnknownStrategyModal
          onClose={() => {
            deleteIsMandatoryIfUnknownStrategyModal.closeModal();
            refetchAuthStrategies();
          }}
        />
      )}

      {authStrategyConnectionInfo.isOpen && currentAuthStrategy && (
        <AuthStrategyConnectionInfoModal
          authStrategyProvider={
            currentAuthStrategy?.type === AuthStrategyType.SAML
              ? currentAuthStrategy?.authProvider
              : undefined
          }
          onClose={() => {
            authStrategyConnectionInfo.closeModal();
            refetchAuthStrategies();
          }}
        />
      )}
      {userActionModal}
    </main>
  );
};

const connectionModesPagination = atom<number>({
  key: "connectionModes.pagination",
  default: 10,
});

export default ConnectionModes;
