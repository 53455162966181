import i18n from "i18n";

const { t } = i18n;

type MatrixColdReviewQuestionsAnswers = {
  [question: string]: {
    [score: number]: string;
  };
};

type MatrixColdReviewComments = {
  [question: string]: {
    positive: boolean;
    negative: boolean;
    relate?: string;
  };
};

export const matrixColdReviewQuestionsAnswers: MatrixColdReviewQuestionsAnswers = {
  "Considérez-vous que cette formation vous a permis de prendre confiance en vous :": {
    0: t("trainings.manage.reviews.cold.answer.confiance.noImprovement", {
      defaultValue: "Pas d'amélioration de la confiance en soi",
    }),
    1: t("trainings.manage.reviews.cold.answer.confiance.improved", {
      defaultValue: "Amélioration de la confiance en soi",
    }),
  },
  "Considérez-vous que cette formation vous a permis de faciliter votre quotidien :": {
    0: t("trainings.manage.reviews.cold.answer.quotidienFacilite.notEased", {
      defaultValue: "Pas d'amélioration du quotidien",
    }),
    1: t("trainings.manage.reviews.cold.answer.quotidienFacilite.eased", {
      defaultValue: "Facilitation du quotidien",
    }),
  },
  "Considérez-vous que cette formation vous a permis d’améliorer la qualité ou l’efficacité de votre travail :":
    {
      0: t("trainings.manage.reviews.cold.answer.workQuality.noImprovement", {
        defaultValue: "Pas d'amélioration de la qualité ou l'efficacité de son travail",
      }),
      1: t("trainings.manage.reviews.cold.answer.workQuality.improved", {
        defaultValue: "Amélioration de la qualité ou de l'efficacité du travail",
      }),
    },
  "Considérez-vous que cette formation vous a permis de vous perfectionner dans un domaine que vous connaissiez déjà :":
    {
      0: t("trainings.manage.reviews.cold.answer.skillEnhancement.noEnhancement", {
        defaultValue: "Pas de perfectionnement dans un domaine déjà connu",
      }),
      1: t("trainings.manage.reviews.cold.answer.skillEnhancement.enhanced", {
        defaultValue: "Perfectionnement dans un domaine déjà connu",
      }),
    },
  "Considérez-vous que cette formation vous a permis de développer de nouvelles compétences :": {
    0: t("trainings.manage.reviews.cold.answer.newSkills.noDevelopment", {
      defaultValue: "Pas de nouvelles compétences développées",
    }),
    1: t("trainings.manage.reviews.cold.answer.newSkills.developed", {
      defaultValue: "Développement de nouvelles compétences",
    }),
  },
  "Si oui, avez-vous obtenu le diplôme ou la certification visé(e) ?": {
    0: t("trainings.manage.reviews.cold.answer.certificationAchievement.notAchieved", {
      defaultValue: "Échec du diplôme ou de la certification visée",
    }),
    1: t("trainings.manage.reviews.cold.answer.certificationAchievement.achieved", {
      defaultValue: "Obtention du diplôme ou de la certification visée",
    }),
  },
  "La formation a-t-elle répondu à vos attentes initiales ?": {
    1: t("trainings.manage.reviews.cold.answer.expectations.notInLine", {
      defaultValue: "Formation pas du tout conforme aux attentes initiales",
    }),
    2: t("trainings.manage.reviews.cold.answer.expectations.slightlyInLine", {
      defaultValue: "Formation pas vraiment conforme aux attentes initiales",
    }),
    3: t("trainings.manage.reviews.cold.answer.expectations.partiallyInLine", {
      defaultValue: "Formation partiellement conforme aux attentes initiales",
    }),
    4: t("trainings.manage.reviews.cold.answer.expectations.completelyInLine", {
      defaultValue: "Formation tout à fait conforme aux attentes initiales",
    }),
  },
  "Estimez-vous que la formation était en adéquation avec le métier ou les réalités du secteur ?": {
    1: t("trainings.manage.reviews.cold.answer.jobRelevance.notRelevant", {
      defaultValue: "Formation pas du tout adaptée au métier ou aux réalités du secteur",
    }),
    2: t("trainings.manage.reviews.cold.answer.jobRelevance.slightlyRelevant", {
      defaultValue: "Formation pas vraiment adaptée au métier ou aux réalités du secteur",
    }),
    3: t("trainings.manage.reviews.cold.answer.jobRelevance.partiallyRelevant", {
      defaultValue: "Formation partiellement adaptée au métier ou aux réalités du secteur",
    }),
    4: t("trainings.manage.reviews.cold.answer.jobRelevance.completelyRelevant", {
      defaultValue: "Formation tout à fait adaptée au métier ou aux réalités du secteur",
    }),
  },
  "Recommanderiez-vous ce stage à une personne exerçant le même métier que vous ?": {
    1: t("trainings.manage.reviews.cold.answer.recommendation.notRecommended", {
      defaultValue:
        "Formation pas du tout recommandée aux personnes exerçant le même métier que {user.name}",
    }),
    2: t("trainings.manage.reviews.cold.answer.recommendation.notReallyRecommended", {
      defaultValue:
        "Formation pas vraiment recommandée aux personnes exerçant le même métier que {user.name}",
    }),
    3: t("trainings.manage.reviews.cold.answer.recommendation.partiallyRecommended", {
      defaultValue:
        "Formation partiellement recommandée aux personnes exerçant le même métier que {user.name}",
    }),
    4: t("trainings.manage.reviews.cold.answer.recommendation.highlyRecommended", {
      defaultValue:
        "Formation tout à fait recommandée aux personnes exerçant le même métier que {user.name}",
    }),
  },
  "Utilisez-vous les connaissances acquises lors de la formation ?": {
    1: t("trainings.manage.reviews.cold.answer.knowledgeUse.notUsed", {
      defaultValue: "Aucune utilisation des connaissances acquises durant la formation",
    }),
    2: t("trainings.manage.reviews.cold.answer.knowledgeUse.slightlyUsed", {
      defaultValue: "Faible utilisation des connaissances acquises durant la formation",
    }),
    3: t("trainings.manage.reviews.cold.answer.knowledgeUse.partiallyUsed", {
      defaultValue: "Utilisation partielle des connaissances acquises durant la formation",
    }),
    4: t("trainings.manage.reviews.cold.answer.knowledgeUse.highlyUsed", {
      defaultValue: "Forte utilisation des connaissances acquises durant la formation",
    }),
  },
};

export const matrixColdReviewComments: MatrixColdReviewComments = {
  "Considérez-vous que cette formation vous a permis de développer de nouvelles compétences :": {
    positive: true,
    negative: true,
    relate: "Autres (à préciser)",
  },
  "Si oui, avez-vous obtenu le diplôme ou la certification visé(e) ?": {
    positive: false,
    negative: true,
    relate: "Si non, pourquoi :",
  },
};
