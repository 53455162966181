import { ModulesAccessList } from "@skillup/espace-rh-bridge";

import { ModuleType } from "hooks/useSettings";
import User from "utils/User";

const Interviews = (hasAccess: { to: (module: ModuleType) => boolean }) => {
  const hasInterviewsModuleEnabled = (moduleAccess: ModulesAccessList) =>
    User.isSkillupDeveloper() || (User.hasInterviewsEnabled() && hasAccess.to(moduleAccess));

  const toInterviewsTrackings = () =>
    hasInterviewsModuleEnabled(ModulesAccessList.InterviewsTrackings);
  const toInterviewsTemplates = () =>
    hasInterviewsModuleEnabled(ModulesAccessList.InterviewsTemplates) ||
    (User.isSkillupAdmin() && toInterviewsTrackings());

  const toInterviewTarget360 = () =>
    hasInterviewsModuleEnabled(ModulesAccessList.InterviewsTarget360);

  const toInterviews = () => toInterviewsTrackings() || toInterviewsTemplates();
  return {
    toInterviews,
    toInterviewsTrackings,
    toInterviewsTemplates,
    toInterviewTarget360,
  };
};

export default Interviews;
