import type { Child } from "../../../../reducer";
import { MultipleChoicesQuestion } from "./MultipleChoicesQuestion/MultipleChoicesQuestion";
import { TextQuestion } from "./TextQuestion/TextQuestion";

export type QuestionChild = Extract<Child, { kind: "question" }>;

type QuestionProps = {
  child: QuestionChild;
  updateStructure: (data: QuestionChild) => void;
  indexes: {
    child: number;
    page: number;
    section: number;
  };
};

const Question = ({ child, updateStructure, indexes }: QuestionProps) => {
  switch (child.type) {
    case "checkbox":
    case "radio":
      return (
        <MultipleChoicesQuestion
          child={child}
          updateStructure={updateStructure}
          indexes={indexes}
        />
      );
    case "text":
      return (
        <TextQuestion child={child} updateStructure={updateStructure} indexes={indexes}/>
      );
    default:
      return <div>Unknown question type</div>;
  }
};

export { Question };
