import { buildRequest } from "utils/buildRequest";
import Acta from "utils/Acta";

import type { IntraRoutes } from "types/api";

export default async function deleteIntra(uuid: string) {
  try {
    const response = await buildRequest<IntraRoutes.DELETE>({
      method: "DELETE",
      params: { uuid },
      path: `/intra/{uuid}`,
    })();

    return response;
  } catch (err) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "La suppression du programme a échoué",
      type: "error",
    });

    return undefined;
  }
}
