import { FontFamily } from "commons";
import styled from "styled-components";

import { DSColor, DSTooltip, SkuiSpacing, MaterialIcons } from "@skillup/ui";

export const StyledTooltip = styled(DSTooltip)<{ darkMode: boolean; isRHTheme: boolean }>`
  display: flex;
  position: absolute;
  border-radius: ${SkuiSpacing.s};
  top: -0.3rem;
  right: -0.3rem;
  ${({ isRHTheme }) =>
    isRHTheme ? `font-family: ${FontFamily.rh};` : `font-family: ${FontFamily.collab};`};
  ${({ darkMode }) =>
    darkMode
      ? `background-color: ${DSColor["surface-dark"]}`
      : `background-color: ${DSColor["surface-light"]}`};
`;

export const HelpIcon = styled(MaterialIcons.Help)<{ darkMode: boolean }>`
  color: ${({ darkMode }) =>
    darkMode ? `${DSColor["plainText-onDark-darker"]}` : `${DSColor["decoration-blue-darker"]}`};

  &:hover {
    color: ${({ darkMode }) =>
      darkMode ? `${DSColor["plainText-onDark-default"]}` : `${DSColor["action-onLight-darker"]}`};
  }
`;
