import React from "react";

import { Flex } from "../../styled";
import { StyledFooter, PrimaryButton, FooterContent, SecondaryButton } from "./Footer.styled";

export interface FooterProps {
  readonly padding?: string;
  readonly columnsNumber?: number;
  readonly containerWidth?: string;
  readonly primaryButtonLabel?: string;
  readonly secondaryButtonLabel?: string;
  readonly onPrimaryButtonClick?: () => void;
  readonly onSecondaryButtonClick?: () => void;
  readonly firstColumnChildren?: React.ReactNode;
  readonly secondColumnChildren?: React.ReactNode;
}

const Footer = ({
  columnsNumber = 1,
  containerWidth = "inherit",
  firstColumnChildren,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  padding,
  primaryButtonLabel,
  secondaryButtonLabel,
  secondColumnChildren,
}: FooterProps) => {
  const renderChildren = (children: React.ReactNode) => {
    if (React.isValidElement(children)) {
      return children;
    }
    if (Array.isArray(children)) {
      return children.map((child, index) => <React.Fragment key={index}>{child}</React.Fragment>);
    }
    return null;
  };

  const oneColumnFooter = (
    <StyledFooter>
      <FooterContent padding={padding} containerWidth={containerWidth}>
        {renderChildren(firstColumnChildren) ? (
          <Flex width={containerWidth} justifyContent="flex-end">
            {renderChildren(firstColumnChildren)}
          </Flex>
        ) : (
          <Flex flex={1} justifyContent="flex-end">
            {secondaryButtonLabel && (
              <SecondaryButton onClick={onSecondaryButtonClick}>
                {secondaryButtonLabel}
              </SecondaryButton>
            )}
            {primaryButtonLabel && (
              <PrimaryButton onClick={onPrimaryButtonClick}>{primaryButtonLabel}</PrimaryButton>
            )}
          </Flex>
        )}
      </FooterContent>
    </StyledFooter>
  );
  const twoColumnsFooter = (
    <StyledFooter>
      <FooterContent padding={padding}>
        <Flex marginRight="l">{firstColumnChildren}</Flex>
        <Flex>
          {secondaryButtonLabel && (
            <SecondaryButton onClick={onSecondaryButtonClick}>
              {secondaryButtonLabel}
            </SecondaryButton>
          )}
          {primaryButtonLabel && (
            <PrimaryButton onClick={onPrimaryButtonClick}>{primaryButtonLabel}</PrimaryButton>
          )}
        </Flex>
      </FooterContent>
    </StyledFooter>
  );
  const threeColumnsFooter = (
    <StyledFooter>
      <FooterContent padding={padding}>
        <Flex flex={1}>{firstColumnChildren}</Flex>
        <Flex flex={1} margin="0 l" justifyContent="center">
          {secondColumnChildren}
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          <Flex>
            {secondaryButtonLabel && (
              <SecondaryButton small onClick={onSecondaryButtonClick}>
                {secondaryButtonLabel}
              </SecondaryButton>
            )}
          </Flex>
          <Flex>
            {primaryButtonLabel && (
              <PrimaryButton small onClick={onPrimaryButtonClick}>
                {primaryButtonLabel}
              </PrimaryButton>
            )}
          </Flex>
        </Flex>
      </FooterContent>
    </StyledFooter>
  );
  const footers = {
    1: oneColumnFooter,
    2: twoColumnsFooter,
    3: threeColumnsFooter,
  };

  return footers[columnsNumber] || oneColumnFooter;
};

export default Footer;
