import React from "react";
import cx from "classnames";

import Icon from "components/Icon";
import { check as checkIcon } from "uiAssets/StrokeIcons";

import styles from "./CheckBoxStyles.module.scss";

interface IItem {
  label?: string;
  value: string;
}

export interface IProps {
  item: IItem;
  isSelected: boolean;
  onChange: (item: IItem, isSelected: boolean) => void;
  className?: string;
  disabled?: boolean;
}

const CheckBox = ({
  item,
  onChange,
  isSelected,
  className,
  disabled = false,
}: IProps): JSX.Element => (
  <div
    className={styles.containerCheckbox}
    onClick={() => !disabled && onChange(item, !isSelected)}
  >
    <div
      className={cx(styles.checkbox, {
        [styles.selected]: isSelected,
        [styles.disabled]: disabled,
      })}
    >
      <div>
        <Icon strokeIcon={checkIcon} width={8} stroke="#fff" />
      </div>
    </div>
    <div>
      <span className={className}>{item.label || item.value}</span>
    </div>
  </div>
);

export default CheckBox;
