import { useTranslation } from "react-i18next";
import { Pie } from "@skillup/ui";

interface Props {
  expected: number;
  overExpected: number;
  underExpected: number;
}

const RoundChart = ({ expected, overExpected, underExpected }: Props) => {
  const { t } = useTranslation();

  const expectedLabel =
    expected > 1
      ? t("collaborator.skills.roundChart.ExpectedLevelsSkills", {
          defaultValue: "{{expected}} compétences au niveau attendu",
          expected,
        })
      : t("collaborator.skills.roundChart.ExpectedLevelsSkill", {
          defaultValue: "{{expected}} compétence au niveau attendu",
          expected,
        });

  const overExpectedLabel =
    overExpected > 1
      ? t("collaborator.skills.roundChart.OverEvaluatedSkills", {
          defaultValue: "{{overExpected}} compétences au-delà du niveau attendu",
          overExpected,
        })
      : t("collaborator.skills.roundChart.OverEvaluatedSkill", {
          defaultValue: "{{overExpected}} compétence au-delà du niveau attendu",
          overExpected,
        });

  const underExpectedLabel =
    underExpected > 1
      ? t("collaborator.skills.roundChart.underExpectedSkills", {
          defaultValue: "{{underExpected}} compétences sous le niveau attendu",
          underExpected,
        })
      : t("collaborator.skills.roundChart.underExpectedSkill", {
          defaultValue: "{{underExpected}} compétence sous le niveau attendu",
          underExpected,
        });

  const data = [
    {
      value: (expected + overExpected).toString(),
      label: overExpected > 0 ? `${expectedLabel}\n${overExpectedLabel}` : expectedLabel,
      color: "#FFFFFF",
    },
  ];

  if (underExpected > 0)
    data.push({ value: underExpected.toString(), label: underExpectedLabel, color: "#1A304D" });

  const hundredPercentExpected = underExpected === 0;

  return (
    <div>
      <Pie
        innerRadius={0.7}
        margin={{
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
        }}
        width={80}
        height={80}
        data={data}
        startAngle={hundredPercentExpected ? 1 : 0}
        endAngle={hundredPercentExpected ? 359 : 360}
        tooltip={({ datum }) => {
          return (
            <div
              style={{
                background: "white",
                padding: "4px 8px 4px 8px",
                border: "1px solid #00000024",
                color: "#1a304d",
                width: "250px",
                fontSize: "12px",
                borderRadius: "4px",
                textAlign: "center",
              }}
            >
              <span style={{ whiteSpace: "pre-line" }}> {datum.label} </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default RoundChart;
