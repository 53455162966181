import { createMachine, assign } from "xstate";

import { type Project } from "services/sessions";
import { parseDateRange } from "utils/dates/dates";

export type EmailPayload = {
  subject: string;
  body: string;
  attachments: Array<string>;
  options: {
    sendCalendarEvents: boolean;
    sendRecapToHR: boolean;
    sendRecapToManagers: boolean;
  };
};

export type CalendarEvent = {
  dateInterval: string[];
  hours: string[];
};
type Context = {
  session?: Project;
  email?: EmailPayload;
  calendarEvents?: CalendarEvent[];
};

type ContextWithSession = { session: Project };
type ContextWithSessionAndMailOptions = ContextWithSession & { email: EmailPayload };
type ContextWithSessionAndMailOptionsAndCalendarEvents = ContextWithSessionAndMailOptions & {
  calendarEvents: CalendarEvent[];
};

type TypeState =
  | { value: "Idle"; context: Context }
  | { value: "choosingType"; context: ContextWithSession }
  // Self summoning
  | { value: "validateSelfSummoning"; context: ContextWithSession }
  // Skillup summoning
  | { value: "emailSettings"; context: ContextWithSessionAndMailOptions }
  | { value: "validateSkillupSummoning"; context: ContextWithSessionAndMailOptions }
  // Calendar events settings & validation
  | { value: "settingCalendarEvents"; context: ContextWithSessionAndMailOptionsAndCalendarEvents }
  | {
      value: "validateSkillupSummoningWithEvents";
      context: ContextWithSessionAndMailOptionsAndCalendarEvents;
    };

type Event =
  | { type: "START_SKILLUP_SUMMON" }
  | { type: "VALIDATE_SKILLUP_SUMMON"; payload: { email: EmailPayload } }
  | { type: "SET_CALENDAR_EVENTS"; payload: { email: EmailPayload } }
  | { type: "BACK" }
  | { type: "VALIDATE_SELF_SUMMON" }
  | { type: "VALIDATE_SKILLUP_SUMMON_WITH_EVENTS"; payload: { calendarEvents: CalendarEvent[] } }
  | { type: "TRIGGER_SUMMON"; payload: { session: Project } }
  | { type: "TERMINATE" }
  | { type: "ABORT" };

export const summonMachine = createMachine<Context, Event, TypeState>(
  {
    id: "summon",
    initial: "Idle",
    states: {
      choosingType: {
        on: {
          START_SKILLUP_SUMMON: {
            target: "emailSettings",
          },
          VALIDATE_SELF_SUMMON: {
            target: "validateSelfSummoning",
          },
          ABORT: {
            actions: ["reset"],
            target: "Idle",
          },
        },
      },
      emailSettings: {
        on: {
          VALIDATE_SKILLUP_SUMMON: {
            target: "validateSkillupSummoning",
            actions: ["setEmail"],
          },
          SET_CALENDAR_EVENTS: {
            target: "settingCalendarEvents",
            actions: ["setEmail"],
          },
          BACK: {
            target: "choosingType",
          },
          ABORT: {
            actions: ["reset"],
            target: "Idle",
          },
        },
      },
      settingCalendarEvents: {
        on: {
          BACK: {
            target: "emailSettings",
          },
          ABORT: {
            actions: ["reset"],
            target: "Idle",
          },
          VALIDATE_SKILLUP_SUMMON_WITH_EVENTS: {
            target: "validateSkillupSummoningWithEvents",
            actions: ["setCalendarEvents"],
          },
        },
      },
      validateSkillupSummoning: {
        on: {
          BACK: {
            target: "emailSettings",
          },
          ABORT: {
            actions: ["reset"],
            target: "Idle",
          },
          TERMINATE: {
            actions: ["reset"],
            target: "Idle",
          },
        },
      },
      validateSelfSummoning: {
        on: {
          BACK: {
            target: "choosingType",
          },
          ABORT: {
            actions: ["reset"],
            target: "Idle",
          },
          TERMINATE: {
            actions: ["reset"],
            target: "Idle",
          },
        },
      },
      Idle: {
        on: {
          TRIGGER_SUMMON: {
            target: "choosingType",
            actions: ["setSession"],
          },
        },
      },
      validateSkillupSummoningWithEvents: {
        on: {
          BACK: {
            target: "settingCalendarEvents",
          },
          ABORT: {
            actions: ["reset"],
            target: "Idle",
          },
          TERMINATE: {
            actions: ["reset"],
            target: "Idle",
          },
        },
      },
    },
    schema: {
      context: {},
    },
    context: {},
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      setSession: assign({
        session: (_context, event) => {
          if (event.type === "TRIGGER_SUMMON") {
            return event.payload.session;
          }

          return undefined;
        },
      }),
      reset: assign({
        session: () => undefined,
        email: () => undefined,
        calendarEvents: () => undefined,
      }),
      setEmail: assign({
        email: (context, event) => {
          if (event.type === "VALIDATE_SKILLUP_SUMMON") {
            return event.payload.email;
          }

          if (event.type === "SET_CALENDAR_EVENTS") {
            return event.payload.email;
          }

          return undefined;
        },
      }),
      setCalendarEvents: assign({
        calendarEvents: (_context, event) => {
          if (event.type === "VALIDATE_SKILLUP_SUMMON_WITH_EVENTS") {
            return event.payload.calendarEvents;
          }

          return undefined;
        },
      }),
      initCalendarEvents: assign({
        calendarEvents: (context, event) => {
          if (context.calendarEvents) {
            return context.calendarEvents;
          }
          if (event.type === "SET_CALENDAR_EVENTS") {
            return context.session.properties.dates.map((interval) => {
              const { hours } = parseDateRange(interval);
              if (hours) return { hours, dateInterval: interval };
              return { hours: ["09:00", "18:00"], dateInterval: interval };
            });
          }

          return undefined;
        },
      }),
    },
  }
);

// The next two machines are just extracted from the previous one
// they will be modified and used in the next PR.
export const localValidationMachine = {
  initial: "Idle",
  states: {
    Idle: {
      on: {
        SEND_SUMMON: {
          target: "Loading",
        },
      },
    },
    Loading: {
      on: {
        SENT: {
          target: "Success",
        },
        FAILED: {
          target: "Idle",
        },
      },
    },
    Success: {
      on: {
        TERMINATE: {
          target: "Idle",
        },
      },
    },
  },
};

export const localSetEventsMachine = {
  initial: "Valid",
  states: {
    Valid: {
      on: {
        INVALID_DATA: {
          target: "Invalid",
        },
        VALIDATE_SKILLUP_SUMMON: {
          actions: ["nextStep"],
        },
      },
    },
    Invalid: {
      on: {
        VALID_DATA: {
          target: "Valid",
        },
      },
    },
  },
};
