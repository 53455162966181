import DataLayer from "utils/DataLayer";
import { State } from "../components/PortalLists";
import { ListInterface } from "../types";

const moveList =
  (lists: ListInterface[], setState: (state: State) => void) =>
  async (index: number, way: "up" | "down") => {
    const updatedLists = lists.map((list) => {
      let targetIndex: number = list.properties.index;
      if (targetIndex === index) {
        targetIndex += way === "down" ? 1 : -1;
      } else if (way === "up" && targetIndex === index - 1) {
        targetIndex = index;
      } else if (way === "down" && targetIndex === index + 1) {
        targetIndex = index;
      }
      return {
        ...list,
        properties: {
          ...list.properties,
          index: targetIndex,
        },
      };
    });

    setState({
      lists: updatedLists,
    });

    await DataLayer.request({
      body: JSON.stringify(
        updatedLists
          .sort((listA, listB) => (listA.properties.index <= listB.properties.index ? -1 : 1))
          .map((list, idx) => ({
            index: idx,
            uuid: list.properties.uuid,
          }))
      ),
      method: "POST",
      url: "/v1/portal/training-list/sort-lists",
    });
  };

export default moveList;
