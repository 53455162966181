import { PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import { cloneDeep } from "lodash";

import { BuilderTemplate } from "../types";
import { isChildAction, isPageAction, isSectionAction } from "../utils";
import { FromChild, FromPage, FromSection } from "./addBlock/addBlock";

type DuplicateChild = {
  uuid: string;
  from: FromChild;
  blockType: "child";
};

type DuplicatePage = {
  uuid: string;
  from: FromPage;
  blockType: "page";
};

type DuplicateSection = {
  uuid: string;
  from: FromSection;
  blockType: "section";
};

export type DuplicateAction = DuplicateChild | DuplicatePage | DuplicateSection;

export const duplicateBlock = (state: BuilderTemplate, action: PayloadAction<DuplicateAction>) => {
  if (isChildAction(action)) {
    return duplicateChild(state, action);
  }

  if (isPageAction(action)) {
    return duplicatePage(state, action);
  }

  if (isSectionAction(action)) {
    return duplicateSection(state, action);
  }

  throw new Error();
};

const duplicateChild = (
  state: BuilderTemplate,
  action: PayloadAction<DuplicateChild>
): BuilderTemplate => {
  const duplicatedData = cloneDeep(
    state.sections[action.payload.from.sectionIndex].pages[action.payload.from.pageIndex].children[
      action.payload.from.childIndex
    ]
  );

  const duplicatedChild = {
    ...duplicatedData,
    uuid: action.payload.uuid,
  };

  state.sections[action.payload.from.sectionIndex].pages[
    action.payload.from.pageIndex
  ].children.splice(action.payload.from.childIndex + 1, 0, duplicatedChild);

  return state;
};

const duplicatePage = (
  state: BuilderTemplate,
  action: PayloadAction<DuplicatePage>
): BuilderTemplate => {
  const duplicatedData = cloneDeep(
    state.sections[action.payload.from.sectionIndex].pages[action.payload.from.pageIndex]
  );

  const duplicatedPage = {
    ...duplicatedData,
    uuid: action.payload.uuid,
    children: duplicatedData.children.map((child) => ({
      ...child,
      uuid: uuid(),
    })),
  };

  state.sections[action.payload.from.sectionIndex].pages.splice(
    action.payload.from.pageIndex + 1,
    0,
    duplicatedPage
  );

  return state;
};

const duplicateSection = (
  state: BuilderTemplate,
  action: PayloadAction<DuplicateSection>
): BuilderTemplate => {
  const duplicatedData = cloneDeep(state.sections[action.payload.from.sectionIndex]);

  const duplicatedSection = {
    ...duplicatedData,
    uuid: action.payload.uuid,
    pages: duplicatedData.pages.map((page) => ({
      ...page,
      id: uuid(),
      uuid: "",
      children: page.children.map((child) => ({
        ...child,
        id: uuid(),
        uuid: "",
      })),
    })),
  };

  state.sections.splice(action.payload.from.sectionIndex + 1, 0, duplicatedSection);

  return state;
};
