import omit from "lodash/omit";
import findIndex from "lodash/findIndex";

import { Collaborator } from "../../api";

export interface IContextState {
  selectedCollab?: Collaborator;
  collabs: Collaborator[];
  maxLevel?: number;
}

export type TCollabAction =
  | { type: "resetSelection" }
  | { type: "selectCollab"; collab: Collaborator; maxLevel?: number }
  | { type: "replaceCollab"; collab: Collaborator; maxLevel?: number }
  | { type: "setCollabList"; collabs: Collaborator[]; maxLevel?: number }
  | { type: "addCollab"; newCollab: Collaborator; maxLevel?: number };

const reducer = (state: IContextState, action: TCollabAction): IContextState => {
  try {
    switch (action.type) {
      // removes the selection
      case "resetSelection":
        return omit(state, ["selectedCollab"]);

      // Selecting a given collab
      case "selectCollab":
        return { ...state, selectedCollab: action.collab };

      // In case of an update, it changes the modified collab's data in the list
      case "replaceCollab": {
        const collabs = [...state.collabs];
        const index = findIndex(collabs, (c: Collaborator) => c.uuid === action.collab.uuid);
        collabs[index] = {
          ...action.collab,
        };
        let localMaxLevel = 0;
        (action.collab.hierarchy?.observers ?? [])?.forEach((observer) => {
          if (observer.level > localMaxLevel) {
            localMaxLevel = observer.level;
          }
        });
        const newMaxLevel = Math.max(localMaxLevel, state.maxLevel);

        return { collabs, maxLevel: newMaxLevel };
      }

      // This is pretty much a one-time use, occuring after initial fetch of collabs list
      case "setCollabList":
        return { ...state, collabs: action.collabs, maxLevel: action.maxLevel };

      // This happens after the user creates a new collab. We just add him/her to the list,
      // and select it to trigger the sidepanel (re-)rendering.
      case "addCollab": {
        const collabs = [action.newCollab, ...state.collabs];
        let localMaxLevel = 0;
        (action.newCollab.hierarchy?.observers ?? [])?.forEach((observer) => {
          if (observer.level > localMaxLevel) {
            localMaxLevel = observer.level;
          }
        });

        const newMaxLevel = Math.max(localMaxLevel, state.maxLevel);

        return { collabs, selectedCollab: action.newCollab, maxLevel: newMaxLevel };
      }
      default:
        return state;
    }
  } catch (err) {
    console.error(err);
    return state;
  }
};

export default reducer;
