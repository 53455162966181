import { PayloadAction } from "@reduxjs/toolkit";

import { BuilderStructure } from "../types";
import { FromIndexes } from "./addBlock/addBlock";
import { ActionHelper } from "./addBlock/utils";

type RemoveBlockAction = {
  from: FromIndexes;
  newHighlightUuid?: string;
};

export const removeBlock = (state: BuilderStructure, action: PayloadAction<RemoveBlockAction>) => {
  if (ActionHelper.comesFromChild(action)) {
    state.sections[action.payload.from.sectionIndex].pages[
      action.payload.from.pageIndex
    ].children.splice(action.payload.from.childIndex, 1);
    return state;
  }

  if (ActionHelper.comesFromPage(action)) {
    if (state.sections[action.payload.from.sectionIndex].pages.length === 1) {
      state.sections[action.payload.from.sectionIndex].pages[0].hideInBuilder = true;
      state.sections[action.payload.from.sectionIndex].pages[0].title = "Nouvelle sous-section";
    } else {
      state.sections[action.payload.from.sectionIndex].pages.splice(
        action.payload.from.pageIndex,
        1
      );
    }
    return state;
  }

  if (ActionHelper.comesFromSection(action)) {
    state.sections.splice(action.payload.from.sectionIndex, 1);
    return state;
  }

  throw new Error("Invalid action");
};
