import { useEffect, useMemo } from "react";
import { FormikInputSelect, FormikInputText } from "@skillup/design-system";
import { Flex } from "@skillup/ui";

import type { CardConfigForm } from "../../types";
import { useTrainingDashboardContext } from "../../context";

type Props = {
  path: string;
  card: CardConfigForm;
  setFieldValue: (field: string, value: any) => void;
};

export const DetailedIndicatorCard = ({ path, card, setFieldValue }: Props) => {
  const {
    fields,
    detailedIndicators,
    detailedIndicatorsUtilities: { indicatorDimensionMap },
  } = useTrainingDashboardContext();

  useEffect(() => {
    if (card.dimension) {
      const dimensions = Object.keys(indicatorDimensionMap[card.indicator]);
      if (!dimensions.includes(card.dimension)) {
        setFieldValue(`${path}.dimension`, null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.indicator]);

  useEffect(() => {
    if (card.dimension) {
      const question = detailedIndicators.find(
        (question) => question.fields[0] === card.indicator && question.fields[1] === card.dimension
      );

      if (card.question_name !== question?.name) {
        setFieldValue(path, {
          ...card,
          question_name: question.name,
          short_name: question?.recommended_short_name,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card.dimension]);

  const indicatorOptions = useMemo(() => {
    return Object.keys(indicatorDimensionMap).map((key) => {
      return {
        label: fields.find((field) => field.name === key)?.recommended_display_name,
        value: key,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicatorDimensionMap]);

  return (
    <Flex column style={{ margin: 50 }}>
      <FormikInputText name={`${path}.short_name`} value={card.short_name} />

      <FormikInputSelect
        name={`${path}.indicator`}
        value={card.indicator}
        options={indicatorOptions}
      />

      <FormikInputSelect
        name={`${path}.dimension`}
        value={card.dimension}
        options={Object.keys(indicatorDimensionMap[card.indicator] ?? {}).map((key) => {
          return {
            label: fields.find((field) => field.name === key)?.recommended_display_name,
            value: key,
          };
        })}
      />
    </Flex>
  );
};
