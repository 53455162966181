import type { CampaignsRoutes, InterviewState } from "@skillup/espace-rh-bridge";
import { formatError, TranslateFunction } from "services/errors";

import Acta from "utils/Acta";
import { buildRequest } from "utils/buildRequest";

import { InterviewPairs } from "../models/InterviewPair";
import { CampaignMailingSettings } from "@skillup/espace-rh-bridge";

type CampaignCreationPayload = CampaignsRoutes.Create["payload"];
type CampaignCreationData = CampaignCreationPayload["data"];

export interface Props {
  readonly toggleLoading: (nextValue: boolean) => void;
  readonly t: TranslateFunction;
  readonly interviews: InterviewPairs[];
  readonly data: CampaignCreationData;
  readonly confidential: boolean;
}

export const createNewCampaign = async ({
  toggleLoading,
  t,
  interviews,
  data,
  confidential,
}: Props): Promise<string> => {
  try {
    toggleLoading(true);
    const payload: CampaignsRoutes.Create["payload"] = createPayload({
      interviews,
      data,
      confidential,
    });

    const { uuid: campaignUuid } = await buildRequest<CampaignsRoutes.Create>({
      method: "POST",
      path: "/campaigns",
      payload: payload,
    })();

    if (campaignUuid) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Campagne créée.",
        type: "success",
      });

      toggleLoading(false);

      return campaignUuid;
    } else {
      throw new Error("No campaign Id in response");
    }
  } catch (error) {
    toggleLoading(false);
    Acta.dispatchEvent("sendAppMessage", {
      message: formatError(t, error, {
        defaultValue: t("error.campaign.create", {
          message: String(error),
        }),
      }),
      type: "error",
    });

    return undefined;
  }
};

export interface CreateDataProps {
  readonly type: "ongoing" | "legacy" | "adHoc";
  readonly titleForHR: string;
  readonly titleForAttendees: string;
  readonly template: { uuid: string };
  readonly managerOnly?: boolean;
  readonly hideFromEmployeeUntil?: { managerState: InterviewState };
  readonly duration?: number;
  readonly start?: number;
  readonly timezoneOffset?: number;
  readonly mailingSettings?: CampaignMailingSettings;
}

export const createData = ({
  type,
  titleForHR,
  titleForAttendees,
  template,
  managerOnly,
  hideFromEmployeeUntil,
  duration,
  start,
  timezoneOffset,
  mailingSettings,
}: CreateDataProps): CampaignCreationData => {
  switch (type) {
    case "adHoc":
      return {
        titleForHR: titleForHR,
        titleForAttendees: titleForAttendees,
        template: template.uuid,
        managerOnly,
        type: "adHoc",
        hideFromEmployeeUntil,
      };

    case "ongoing":
      return {
        titleForHR: titleForHR,
        titleForAttendees: titleForAttendees,
        template: template.uuid,
        managerOnly,
        type: "ongoing",
        duration,
        preparationTime: duration - 1,
        frequency: start as number,
        hideFromEmployeeUntil,
      };

    case "legacy":
      return {
        titleForHR: titleForHR,
        titleForAttendees: titleForAttendees,
        template: template.uuid,
        managerOnly,
        type: "legacy",
        duration,
        preparationTime: duration - 1,
        startDate: start - timezoneOffset,
        hideFromEmployeeUntil,
        mailingSettings,
      };
  }
};
export interface CreatePayloadProps {
  readonly interviews: InterviewPairs[];
  readonly data: CampaignCreationData;
  readonly confidential: boolean;
}

export const createPayload = ({
  interviews,
  data,
  confidential,
}: CreatePayloadProps): CampaignCreationPayload => {
  return {
    confidential,
    pairs: interviews
      ?.filter((i) => !i.invalid)
      ?.map(({ employee, manager }) => ({
        employeeUuid: employee.uuid,
        employeeJoinDate: employee.joinDate || null,
        managerUuid: manager.uuid,
      })),
    data,
  };
};
