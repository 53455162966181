import { JobAnalytic } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import { DatatableJobFields } from "../../getJobsFields";
import generateJobsTableRows from "../../generateJobsTableRows";
import { createXlsx } from "./xlsx";

export const exportXlsxJobs = (data: Array<JobAnalytic>, t: TranslationType) => {
  const { headers, rows } = generateXlsxJobs(data, t);

  return createXlsx(rows, headers);
};

const generateXlsxJobs = (data: Array<JobAnalytic>, t: TranslationType) => {
  const headers = Object.keys(DatatableJobFields).reduce((acc, key) => {
    return {
      ...acc,
      [key]: t(DatatableJobFields[key].traductionKey, {
        defaultValue: DatatableJobFields[key].traductionDefaultValue,
      }),
    };
  }, {});

  const rows = generateJobsTableRows(data).map((row) =>
    Object.keys(headers).reduce((acc, key) => {
      let value: unknown = row[key];

      if (key === "averageSkillsExpectedLevelDifference") {
        value = "";

        if (row[key]) {
          value =
            row[key].percentage > 0
              ? `${row[key].percentage}% (${row[key].values})`
              : `${row[key].percentage}%`;
        }
      }

      if (key === "averageExpectedLevelDifference" && row[key] === undefined) {
        value = t("skills.dashboard.noExpectedLevel", {
          defaultValue: "Pas de niveau attendu",
        });
      }

      return { ...acc, [key]: value };
    }, {})
  );

  return { headers, rows };
};
