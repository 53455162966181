import React from "react";

import addButtonStyles from "./AddButton.module.scss";
import Icon from "components/Icon";
import { plus } from "uiAssets/StrokeIcons";

interface IProps {
  label: string;
  onClick: (e: React.MouseEvent) => void;
  styles?: React.CSSProperties;
}
export default ({ label, onClick, styles }: IProps) => {
  return (
    <button
      className={addButtonStyles.addElementButton}
      onClick={onClick}
      style={{ ...(styles || {}) }}
    >
      <div>
        <Icon strokeIcon={plus} width={12} />
      </div>
      <p>{label}</p>
    </button>
  );
};
