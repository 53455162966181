import { useMemo } from "react";

import { ProcessedSkill } from "types/skills";
import { TranslationType } from "hooks/useTranslation";

import { SkillRow } from "./utils/parseSkillIntoRow";
import generateColumns from "./utils/generateColumns";
import { DatatableSkillFields } from "./utils/getFields";
import generateTableRows from "./utils/generateTableRows";

type Props = {
  t: TranslationType;
  skills?: Array<ProcessedSkill>;
  actions: (row: SkillRow) => void;
};

export default function useSkillsTableData({ actions, skills = [], t }: Props) {
  const tableData = useMemo(
    () => ({
      columns: generateColumns({
        actions,
        customFields: skills[0]?.fields || [],
        fields: DatatableSkillFields,
        t,
      }),
      rows: generateTableRows(skills),
    }),
    [skills, t, actions]
  );

  return tableData;
}
