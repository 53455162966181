import { atom } from "recoil";
import type { Tracking } from "./tracking";
type TrackingStatus = Tracking["status"];

export const userAreasFilter = atom<string[]>({
  key: "trackings.userAreasFilter",
  default: undefined,
});

export const TrackingFilterChoices = {
  all: () => true,
  active: (s: TrackingStatus) => s !== "archived",
  archived: (s: TrackingStatus) => s === "archived",
};
export type TrackingFilterKeys = keyof typeof TrackingFilterChoices;
export const trackingsStatusFilter = atom<TrackingFilterKeys>({
  key: "trackings.statusFilter",
  default: "active",
});
