import { useCallback } from "react";
import { useToggle } from "react-use";
import { useToasts } from "react-toast-notifications";

import { CampaignExportStates } from "@skillup/espace-rh-bridge";
import { DSButton } from "@skillup/ui";

import { useExportAsyncContext } from "../ExportAsyncContext";

interface Props {
  readonly interviewsUuids: string[];
}

export const DownloadRepliesButton = ({
  interviewsUuids,
}: Props): JSX.Element => {
  const [loading, toggleLoading] = useToggle(false);
  const { addToast } = useToasts();

  const {
    exportCampaignAsync,
    userNotificationState,
  } = useExportAsyncContext();

  const downloadReplies = useCallback(async (): Promise<void> => {
    toggleLoading(true);
    try {
      await exportCampaignAsync(interviewsUuids);
    } catch (e) {
      addToast("Une erreur est survenue ; si elle persiste, veuillez contacter le support.", {
        appearance: "error",
      });
    } finally {
      toggleLoading(false);
    }
  }, [
    toggleLoading,
    addToast,
    interviewsUuids,
    exportCampaignAsync,
  ]);

  const disableAsyncExportButton = userNotificationState === CampaignExportStates.IN_PROGRESS ? true : false

  return (
    <DSButton
      loading={loading}
      buttonSize="M"
      label="Exporter les entretiens (XLS)"
      fontWeight="light"
      emphasis="Low"
      onClick={downloadReplies}
      disabled={disableAsyncExportButton || !interviewsUuids.length}
      tooltip={
        disableAsyncExportButton
          ? "Un export est déjà en cours"
          : interviewsUuids.length === 0
          ? "Aucun entretien dans cette campagne"
          : undefined
      }
    />
  );
};
