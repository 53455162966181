import { useParams } from "react-router-dom";
import { useRef, useMemo, useCallback } from "react";

import Highcharts from "highcharts";
import { useEmployees } from "hooks";
import timeline from "highcharts/modules/timeline";
import HighchartsReact from "highcharts-react-official";

import { GridRowParams } from "@mui/x-data-grid-pro";

import { DSDataGrid } from "@skillup/ui";
import { Text, Flex } from "@skillup/design-system";

import DSLayout from "components/DSLayout";
import { Monitoring, usePeopleReviewCampaigns } from "services/peopleReview";

import { PR_Management_Routes } from "../../router";
import { getNotificationsPieChart } from "./pieNotifications.conf";
import { getNotificationsTimeline } from "./timelineNotifications.conf";



timeline(Highcharts);

function NotificationDetails({ row }) {
  return (
    <ul>
      {row.events.map((event) => (
        <li key={event.id}>
          <Text>{event.properties.date}</Text>
          <Text>
            {event.type} - {event.properties.authorFullName}
          </Text>
        </li>
      ))}
    </ul>
  );
}

export function DevMonitoringCampaignDetails() {
  const timelineComponentRef = useRef<HighchartsReact.RefObject>(null);
  const donutComponentRef = useRef<HighchartsReact.RefObject>(null);
  const { campaignID } = useParams<{ campaignID: string }>();
  const { campaigns } = usePeopleReviewCampaigns({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const campaign = campaigns?.find((c) => c.id === campaignID);

  const { notifications = [] } = Monitoring.useNotifications({
    campaignID: campaign?.id,
  });

  const managerData = useEmployees({
    employeeUuids: notifications.map((notification) => notification.managerID),
    keys: ["fullName", "email", "uuid"],
  });

  const managersDictionary = useMemo(() => {
    if (managerData.isLoading) return {};

    return managerData?.employees?.reduce(
      (acc, { uuid, email, fullName }) => {
        acc[uuid] = { email, fullName };
        return acc;
      },
      {} as Record<string, { email: string; fullName: string }>
    );
  }, [managerData]);

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams) => <NotificationDetails row={row} />,
    []
  );

  const getDetailsPanelHeight = useCallback(() => "auto" as const, []);

  const rows = useMemo(() => {
    return notifications.map((notification) => ({
      id: notification.id,
      events: notification.events,
      eventsCount: notification.events.length,
      managerEmail: managersDictionary[notification.managerID]?.email,
      managerFullName: managersDictionary[notification.managerID]?.fullName,
      managerID: notification.managerID,
      state: notification.state,
    }));
  }, [notifications, managersDictionary]);

  const groupedNotifications = Object.groupBy(notifications, (notif) => notif.state);
  const allEvents = notifications.flatMap((notification) => notification.events);

  const successRate = (groupedNotifications["SUCCESS"]?.length ?? 0 / allEvents.length) * 100;
  const failedRate = (groupedNotifications["FAILED"]?.length ?? 0 / allEvents.length) * 100;
  const pendingRate = (groupedNotifications["PENDING"]?.length ?? 0 / allEvents.length) * 100;

  const pieConf = getNotificationsPieChart({
    failedRate,
    pendingRate,
    successRate,
  });

  const data = allEvents.map((event) => {
    return {
      description: event.properties.authorFullName,
      label: event.properties.date,
      marker: {
        symbol: "circle",
      },
      name: event.type,
      x: new Date(event.properties.date).getTime(),
    };
  });

  const timelineConf = getNotificationsTimeline({
    data,
    title: campaign?.title,
  });

  return (
    <DSLayout
      title="Configuration des People Reviews"
      activeLayout={"Monitoring des campagnes"}
      layouts={[
        {
          tab: {
            id: "Modèles de campagnes",
            label: "Modèles de campagnes",
            url: PR_Management_Routes.TemplatesList,
          },
        },
        {
          tab: {
            id: "Actions",
            label: "Actions",
            url: PR_Management_Routes.ActionsList,
          },
        },
        {
          tab: {
            id: "Monitoring des campagnes",
            label: "Monitoring des campagnes",
            url: PR_Management_Routes.DevMonitoringCampaigns,
          },
        },
      ]}
    >
      <Flex minHeight={500} paddingVertical="s" paddingHorizontal="l" flexDirection="column">
        <Flex justifyContent="space-between">
          <HighchartsReact
            options={timelineConf}
            highcharts={Highcharts}
            ref={timelineComponentRef}
          />
          <HighchartsReact options={pieConf} highcharts={Highcharts} ref={donutComponentRef} />
        </Flex>

        <DSDataGrid
          rows={rows}
          getDetailPanelHeight={getDetailsPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          columns={[
            {
              field: "id",
              headerName: "Notification ID",
              width: 200,
            },
            {
              field: "managerID",
              headerName: "Manager ID",
              width: 300,
            },
            {
              field: "managerFullName",
              headerName: "Manager",
              width: 300,
            },
            {
              field: "managerEmail",
              headerName: "Manager Email",
              width: 300,
            },
            {
              field: "state",
              headerName: "State",
              width: 100,
            },
            {
              field: "eventsCount",
              headerName: "Events",
              width: 120,
            },
          ]}
        />
      </Flex>
    </DSLayout>
  );
}
