import { Flex, FormikInputText } from "@skillup/design-system";
import { Status, StatusType, Label as DSLabel } from "@skillup/ui";

import useTranslation from "hooks/useTranslation";

interface LevelProps {
  index: number;
  item: { name: string; description?: string; customDescription?: string };
}

export default function Level(props: LevelProps) {
  const { index, item } = props;
  const { t } = useTranslation();

  return (
    <Flex gap="xxs" paddingHorizontal="xs" flexDirection="column" alignItems="flex-start">
      <Status label={item.name} type={StatusType.PROGRESS} />
      <Flex width="100%" flexDirection="column">
        <DSLabel
          label={t("portal.config.skills.createSkill.associatedDescription", {
            defaultValue: "Description associée",
          })}
        />
        <FormikInputText
          value={item.customDescription ?? ""}
          placeholder={item.description ?? "Description"}
          name={`evaluationScale.levels.${index}.customDescription`}
        />
      </Flex>
    </Flex>
  );
}
