import { Dispatch } from "react";
import { useMemo } from "react";

import { ModalStates, State } from "./useHabilitationsActions";

import CreateHabilitation from "../components/CreateHabilitation/CreateHabilitation";
import ModifyHabilitation from "../components/ModifyHabilitation/ModifyHabilitation";
import DeleteHabilitation from "../components/DeleteHabilitation/DeleteHabilitation";
import LinkUsersToHabilitation from "../components/LinkUsersToHabilitation/LinkUsersToHabilitation";

const useHabilitationsModals = (currentAction: State, setActionsState: Dispatch<State>) => {
  const modal = useMemo(() => {
    switch (currentAction.state) {
      case ModalStates.Idle:
        return <></>;
      case ModalStates.Creating:
        return <CreateHabilitation onClose={() => setActionsState({ state: ModalStates.Idle })} />;
      case ModalStates.Modifying:
        return (
          <ModifyHabilitation
            habilitation={currentAction.habilitation}
            onClose={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.Deleting:
        return (
          <DeleteHabilitation
            habilitationUuid={currentAction.habilitation.uuid}
            habilitationName={currentAction.habilitation.name}
            onClose={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      case ModalStates.LinkingUsers:
        return (
          <LinkUsersToHabilitation
            habilitationUuid={currentAction.habilitation.uuid}
            onClose={() => setActionsState({ state: ModalStates.Idle })}
          />
        );
      default:
        return <></>;
    }
  }, [currentAction, setActionsState]);

  return modal;
};

export default useHabilitationsModals;
