import styled from "styled-components";
import { DSButton, DSColor, SkuiSpacing } from "@skillup/ui";

export const JobLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background: ${DSColor["surface-light-darker"]};
`;

export const JobTemplateFieldsWrapper = styled.div`
  padding-top: ${SkuiSpacing.m};
  width: 560px;
  & span {
    width: 100%;
    & > button {
      width: 100%;
    }
  }
`;

export const CreationButton = styled(DSButton)`
  margin-bottom: ${SkuiSpacing.s};
`;
