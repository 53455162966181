import React from "react";
import { Link } from "react-router-dom";
import { IWithRouter } from "utils/types";
import cx from "classnames";

import GetBaseURLFromLocation from "utils/GetBaseURLFromLocation";

import styles from "./RelatedFormationsLinksStyles.module.scss";

interface IProps extends IWithRouter {
  currentName?: string;
  links: Array<{
    name: string;
    seoSlug: string;
    trainingCount: number;
  }>;
  tags: Array<string>;
}

const RelatedFormationsLinks: React.SFC<IProps> = ({ currentName, links, location, tags }) => {
  let baseURL = GetBaseURLFromLocation(location.pathname);
  if (location.pathname.includes("/recherche/")) {
    baseURL += "/recherche";
  } else if (baseURL) {
    baseURL += "/programmes/catalogue";
  }
  const [moreLinksDisplayed, toogleMoreLinksDisplayed] = React.useState(false);

  return (
    <nav className={styles.relatedFormationsLinks} id="moreSpecific">
      <h2>Précisez votre recherche</h2>
      <div>
        {links.map((link, index) => (
          <Link
            key={link.seoSlug}
            to={`${baseURL || ""}/${(link.seoSlug || "").replace("/", "")}`}
            className={cx({
              [styles.selected]: tags.includes(link.name) || link.name === currentName,
              [styles.hidden]: !moreLinksDisplayed && index > 4,
            })}
          >
            {link.name}
            <span key="count"> ({link.trainingCount})</span>
          </Link>
        ))}

        {links.length > 5 && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            onClick={() => toogleMoreLinksDisplayed(!moreLinksDisplayed)}
            className={styles.seeMoreLess}
          >
            {`... ${moreLinksDisplayed ? "réduire" : "voir plus"}`}
          </a>
        )}
      </div>
    </nav>
  );
};

export default React.memo(RelatedFormationsLinks);
