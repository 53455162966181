import { format } from "@skillup/shared-utils";

import { JobList } from "types/skills";

const parseJobIntoRow = (job: JobList) => {
  const { uuid, employees, fields, isArchived, isPrivate, name, skillsLinked, updatedAt, version } =
    job;

  const rowData = {
    uuid,
    employees,
    fields,
    isArchived,
    isPrivate,
    lastEdit: format(new Date(updatedAt), "dd/MM/yyyy"),
    name,
    skillsCount: skillsLinked,
    version,
  };

  job.fields.forEach((field) => {
    rowData[field.name] = field.value;
  });

  return {
    id: job.uuid,
    ...rowData,
  };
};

export default parseJobIntoRow;

export type JobRow = ReturnType<typeof parseJobIntoRow>;

export type SelectedJobRowData = JobRow;