import { Flex, Text } from "@skillup/design-system";
import { InterviewRoles, UserTarget } from "@skillup/espace-rh-bridge";
import { DSAvatar, DSProgressBar, TypeProgressBar } from "@skillup/ui";
import { isNil } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getUserInitials } from "utils/User";
import styles from "./UserTargetDetailEvaluation.module.scss";

interface IProps {
  target: UserTarget;
}
export const UserTargetDetailEvaluation = ({ target }: IProps) => {
  const choices = useMemo(() => {
    if (target.scale !== "ordinal") return [];
    return target.choices;
  }, [target]);

  const goal = useMemo(() => {
    if (target.scale !== "numeric") return undefined;
    return target.goal;
  }, [target]);

  return (
    <Flex flexDirection="column" gap="xs" className={styles.EvaluationBlock}>
      <Flex flexDirection="row" gap="xs" alignItems="center">
        <DSAvatar
          size="M"
          type="Initials"
          imageUrl={undefined}
          initials={getUserInitials(target.evaluation?.manager?.user)}
          randomColorSeedString={target.evaluation?.manager?.user?.uuid}
          tooltip={target.evaluation?.manager?.user?.fullName}
        />
        <EvaluationValue
          user={target.evaluation.manager.user}
          role={InterviewRoles.MANAGER}
          evaluation={target.evaluation.manager}
          scale={target.scale}
          choices={choices}
          goal={goal}
        />
      </Flex>
      <Flex flexDirection="row" gap="xs" alignItems="center">
        <DSAvatar
          size="M"
          type="Initials"
          imageUrl={undefined}
          initials={getUserInitials(target.evaluation?.employee?.user)}
          randomColorSeedString={target.evaluation?.employee?.user?.uuid}
          tooltip={target.evaluation?.employee?.user?.fullName}
        />
        <EvaluationValue
          user={target.evaluation.employee.user}
          role={InterviewRoles.EMPLOYEE}
          evaluation={target.evaluation.employee}
          scale={target.scale}
          choices={choices}
          goal={goal}
        />
      </Flex>
    </Flex>
  );
};

const EvaluationValue = ({
  user,
  role,
  evaluation,
  scale,
  choices,
  goal,
}: {
  user?: { fullName: string };
  role: InterviewRoles;
  evaluation?: UserTarget["evaluation"][InterviewRoles.MANAGER | InterviewRoles.EMPLOYEE];
  scale: UserTarget["scale"];
  choices?: string[];
  goal?: number;
}) => {
  const { t } = useTranslation();
  const { emptyLabelKey, emptyLabelDefaultValue } = useMemo(() => {
    switch (role) {
      case InterviewRoles.EMPLOYEE:
        return {
          emptyLabelKey: "interview.targets.profile.autoeval.none",
          emptyLabelDefaultValue: "{{ fullName }} n'a pas auto-évalué cet objectif",
        };
      case InterviewRoles.MANAGER:
        return {
          emptyLabelKey: "interview.targets.profile.evaluation.none",
          emptyLabelDefaultValue: "{{ fullName }} n'a pas évalué cet objectif",
        };
      default:
        return {
          emptyLabelKey: "interview.targets.profile.evaluation.none",
          emptyLabelDefaultValue: "{{ fullName }} n'a pas évalué cet objectif",
        };
    }
  }, [role]);
  if (isNil(evaluation?.completion)) {
    return (
      <div className={styles.noEvaluation}>
        {t(emptyLabelKey, {
          defaultValue: emptyLabelDefaultValue,
          fullName: user?.fullName,
        })}
      </div>
    );
  }
  if (scale === "numeric") {
    // When the goal is custom it is *100 in BDD, but the completion remains what the user entered
    // So we need to /100 here the goal
    return (
      <DSProgressBar
        className={styles.evaluationGauge}
        layout="list"
        type={TypeProgressBar.PERCENTAGE}
        thin={true}
        valueFormatter={(_, value) => <Text espaceFont="body1Bold">{value} %</Text>}
        data={[
          {
            label: "",
            value: goal
              ? Math.round((evaluation.completion * 10000) / goal)
              : evaluation.completion,
          },
        ]}
      />
    );
  }
  return (
    <div className={styles.evaluationChoice}>
      <Text espaceFont="body1Bold" color="plainText-onLight-lighter">
        {choices?.[evaluation?.completion - 1]}
      </Text>
    </div>
  );
};
