import { PayloadAction } from "@reduxjs/toolkit";

import { moveSection, movePage, moveChild } from "../movers";
import { MoveBlockAction, BuilderTemplate } from "../types";
import { isChildAction, isPageAction, isSectionAction } from "../utils";

export const moveBlock = (state: BuilderTemplate, action: PayloadAction<MoveBlockAction>) => {
  const lastSectionIndex = state.sections.length - 1 > 0 ? state.sections.length - 1 : 0;
  if (
    action.payload.toPosition.sectionIndex === 0 ||
    action.payload.toPosition.sectionIndex === lastSectionIndex
  ) {
    return state;
  }

  if (isSectionAction(action)) {
    return moveSection(state, action);
  }
  if (isPageAction(action)) {
    return movePage(state, action);
  }
  if (isChildAction(action)) {
    return moveChild(state, action);
  }
  throw new Error();
};
