import React from "react";
import cx from "classnames";
import { type Themes } from "../commonProps";
import { MaterialIcons } from "../../index";

import styles from "./Property.module.scss";

export interface PropertyProps {
  readonly label: string;
  readonly icon?: JSX.Element;
  readonly className?: string;
  readonly theme: Themes;
  readonly darkMode?: boolean;
}

export const Property = ({ label, icon, className, darkMode, theme }: PropertyProps) => {
  return (
    <div
      data-testid="property"
      className={cx(styles.Property, styles[theme], { [styles.darkMode]: darkMode }, className)}
    >
      {icon || <MaterialIcons.Record />}
      <span className={styles.label}>{label}</span>
    </div>
  );
};
