import React from "react";

import { Modal, DSButton } from "@skillup/ui";

import styles from "./DeleteCompany.module.scss";

interface Props {
  readonly subCompanyName: string;
  readonly submitRequest: () => void;
  readonly onClose: () => void;
  readonly loading: boolean;
}

export default ({ subCompanyName, submitRequest, onClose, loading }: Props) => {
  const handleMainAction = () => {
    submitRequest();
  };

  return (
    <Modal title={`Supprimer ${subCompanyName}`} onClose={onClose} size="small">
      <div className={styles.deleteCompany}>
        <span className={styles.modalText}>
          Si vous supprimez cette sous-entreprise, tous les collaborateurs qui y sont rattachés
          seront rattachés à l’entreprise principale.
        </span>

        <div className={styles.buttonsWrapper}>
          <DSButton
            className={styles.button}
            emphasis="Low"
            label="Annuler"
            onClick={onClose}
            loading={loading}
          />
          <DSButton
            className={styles.button}
            emphasis="High"
            label={`Supprimer ${subCompanyName}`}
            onClick={handleMainAction}
            loading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};
