import React, { useState, useCallback } from "react";
import { useMount, useUnmount } from "react-use";

import Acta from "utils/Acta";

import AppModal, { ModalProps } from "./AppModal";

/**
 * To display from anywhere in the app,
 * set an Acta state like follows :
 * Acta.setState(
 *    'modalDisplayed', {
 *      title: 'title of the message',
 *      size: 'small',
 *      content: 'hello world'
 *    } )
 *
 * @param {String} title - mandatory - title of the modal
 * @param {String} size - optionnal - default : 'full' - will set the size [ 'full', 'small' ]
 * @param {String || React compnenent} content - mandatory - content of the modal
 * @param {Boolean} noHeader - optionnal - display no header
 * @param {Boolean} showOverflow - optional - displays overflow
 */

/**
 * To close an open modal from anywhere
 * dispatch an Acta event like follows :
 * Acta.dispatchEvent( 'closeModal' )
 */

const AppModalContainer = () => {
  const [modal, setModal] = useState<ModalProps>(null);
  const [modalType, setModalType] = useState<"alert" | "confirm" | null>(null);

  const displayModal = useCallback(
    (modal: ModalProps, type: ModalProps["modalType"]) => {
      setModal(modal);
      setModalType(type);

      if (modal) {
        document.body.style.top = `-${window.pageYOffset}px`;
        document.body.style.position = "fixed";
      } else {
        document.body.style.position = "static";
        document.body.style.top = "0px";
      }
    },
    [setModal, setModalType]
  );

  useMount(() => {
    Acta.subscribeStateWithoutContext(
      "modalDisplayed",
      (modal: ModalProps) => displayModal(modal, null),
      "AppModalContainer"
    );
    Acta.subscribeStateWithoutContext(
      "alertDisplayed",
      (modal: ModalProps) => displayModal(modal, "alert"),
      "AppModalContainer"
    );
    Acta.subscribeStateWithoutContext(
      "confirmDisplayed",
      (modal: ModalProps) => displayModal(modal, "confirm"),
      "AppModalContainer"
    );
  });

  useUnmount(() => {
    Acta.unsubscribeStateWithoutContext("modalDisplayed", "AppModalContainer");
    Acta.unsubscribeStateWithoutContext("alertDisplayed", "AppModalContainer");
    Acta.unsubscribeStateWithoutContext("confirmDisplayed", "AppModalContainer");
  });

  if (!modal) return <div />;

  return <AppModal {...modal} modalType={modalType} />;
};

export default AppModalContainer;
