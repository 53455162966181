import styled from "styled-components";
import { EspaceFont, SkuiSpacing } from "@skillup/ui";

export const UserSkillsWrapper = styled.div`
  display: flex;
  justify-content: center;

  > div {
    ${EspaceFont.rh.body1Regular};
    margin-top: ${SkuiSpacing.s};
    width: 60%;
  }
`;

export const UserSkillsImgWrapper = styled.div`
  ${EspaceFont.rh.body1Regular};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    > img {
      max-height: 320px;
    }
  }
`;
