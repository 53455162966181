import { arrayMove } from "@dnd-kit/sortable";

import { DSSortable } from "@skillup/ui";
import { Flex, FormikInputText } from "@skillup/design-system";

interface SortableProps<
  Field extends { ref: string; name: string; index: number; uuid?: string; version: number }
> {
  locked?: boolean;
  inputName: string;
  fields: Array<Field>;
  setCurrentField: (f: Field) => void;
  confirmationModal: {
    isOpen: boolean;
    show: () => void;
    hide: () => void;
  };
  arrayHelpers: {
    remove: (index: number) => Field;
    move: (from: number, to: number) => void;
  };
}

function Sortable<
  Field extends { ref: string; name: string; index: number; uuid?: string; version: number }
>({
  arrayHelpers: { move, remove },
  confirmationModal,
  fields,
  inputName,
  locked,
  setCurrentField,
}: SortableProps<Field>) {
  const items = fields?.map((field, index) => {
    const { name, ref } = field;
    const fieldName = `${inputName}.${index}.name`;
    return {
      id: ref,
      title: <FormikInputText key={index} value={name} name={fieldName} placeholder="Name" />,
    };
  });

  const handleRemove = (id: string) => {
    const fieldToRemove = fields.find((f) => f.ref === id);
    setCurrentField(fieldToRemove);

    if (fieldToRemove.uuid) {
      confirmationModal.show();
    } else {
      const indexToRemove = fields.findIndex((f) => f.ref === fieldToRemove.ref);
      remove(indexToRemove);
    }
  };

  const handleReorder = (items: [], startIndex: number, endIndex: number) => {
    move(startIndex, endIndex);
    return arrayMove(items, startIndex, endIndex);
  };

  return (
    <Flex marginBottom="xxs">
      <DSSortable
        handle
        removable
        items={items}
        locked={locked}
        removeAlwaysVisible
        reorderItems={handleReorder}
        onRemove={handleRemove}
      />
    </Flex>
  );
}
export default Sortable;
