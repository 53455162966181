import { useEffect } from "react";
import { useSetState } from "react-use";
import { marked, Renderer } from "marked";
import cx from "classnames";

import { analytics } from "services";
import Acta from "utils/Acta";
import Icon from "components/Icon";
import {
  chevronLeft as chevronLeftIcon,
  chevronRight as chevronRightIcon,
  cross as crossIcon,
} from "uiAssets/StrokeIcons";
import ExpandableModal from "components/ExpandableModal";
import blankLinks from "constants/blankLinks";

import styles from "./OnboardingSequenceModalStyles.module.scss";

const renderer = new Renderer();

renderer.link = function (href, title, text) {
  const credentials = Acta.getState("userData");
  const orgUuid = credentials && credentials.activeCompany && credentials.activeCompany.uuid;
  if (orgUuid && blankLinks[orgUuid] && blankLinks[orgUuid].includes(href)) {
    return '<a target="_blank" href="' + href + '" title="' + title + '">' + text + "</a>";
  }
  return '<a href="' + href + '" title="' + title + '">' + text + "</a>";
};

export interface Props {
  contents: string;
  openingLabel: string;
  portalIntroButtonColor?: string;
  portalIntroButtonBackgroundColor?: string;
  id?: string;
}

export interface Content {
  navContent?: string[];
  slidesContent?: string[];
  parsingError?: boolean;
}

export interface State extends Content {
  contents?: string;
  currentIndex: number;
  contentsDisplayed: boolean;
}

export function parseOnboardingSequenceModalMark(contents: string): Content {
  let cleanedContents: string = (contents || "").replace(/\n\*\*\*\*\*(\**)\n\n/g, "\n*****\n");
  let navContent: string[] = [];

  if (/\n\*\*\*\*\*\n/.test(cleanedContents)) {
    navContent = cleanedContents
      .split("\n*****\n")[0]
      .split("\n")
      .filter((value: string) => Boolean(value));
    cleanedContents = cleanedContents.split("\n*****\n")[1];
  }

  const slidesContent = cleanedContents
    .replace(/\n-----(-*)\n\n/g, "\n-----\n\n")
    .split("\n-----\n\n")
    .map((section) => marked.parse(section, { renderer, async: false }) as string);

  return {
    navContent,
    slidesContent,
    parsingError: navContent && navContent.length !== slidesContent.length,
  };
}

const OnboardingSequenceModal = (props: Props) => {
  const { openingLabel, portalIntroButtonColor, portalIntroButtonBackgroundColor, id } = props;

  const [state, setState] = useSetState<State>({
    currentIndex: 0,
    contentsDisplayed: false,
    navContent: [],
    slidesContent: [],
  });

  const { contentsDisplayed, currentIndex, navContent, slidesContent } = state;

  useEffect(() => {
    if (state.contents !== props.contents) {
      const data: Content = parseOnboardingSequenceModalMark(props.contents);

      return setState({
        contents: props.contents,
        currentIndex: 0,
        contentsDisplayed: false,
        ...data,
      });
    }
  }, [state.contents, props.contents, setState]);

  return (
    <ExpandableModal
      expandBtnProps={{
        label: openingLabel,
        portalIntroButtonColor,
        portalIntroButtonBackgroundColor,
      }}
      targetWidth={600}
      targetHeight={500}
      onExpand={() => {
        setState({ currentIndex: 0, contentsDisplayed: true });
        analytics.sendEvent("Portal", "Portal_Button_En_Savoir_Plus");
      }}
      id={id}
    >
      {contentsDisplayed && (
        <div className={styles.OnboardingSequenceModal}>
          <nav>
            {navContent.map((navTitle, index) => (
              <button
                key={navTitle}
                onClick={() => setState({ currentIndex: index })}
                className={cx(
                  index === currentIndex ? styles.current : styles.notCurrent,
                  index < currentIndex ? styles.passed : styles.notPassed
                )}
              >
                <span title={navTitle}>{navTitle}</span>
                <b />
              </button>
            ))}
          </nav>

          <div style={{ transform: `translateX( -${currentIndex * 100}% )` }}>
            {slidesContent.map((slide, index) => (
              <section
                key={slide}
                dangerouslySetInnerHTML={{ __html: slide }}
                style={{ transform: `translateX( ${index * 100}% )` }}
              />
            ))}
          </div>

          {currentIndex < slidesContent.length - 1 && (
            <button
              className={styles.buttonNext}
              onClick={() => setState({ currentIndex: currentIndex + 1 })}
            >
              <Icon strokeIcon={chevronRightIcon} width={20} />
            </button>
          )}

          {currentIndex > 0 && (
            <button
              className={styles.buttonPrevious}
              onClick={() => setState({ currentIndex: currentIndex - 1 })}
            >
              <Icon strokeIcon={chevronLeftIcon} width={20} />
            </button>
          )}

          <button onClick={() => Acta.dispatchEvent("closeModal")} className={styles.skipButton}>
            <Icon strokeIcon={crossIcon} width={16} />
          </button>

          {currentIndex === slidesContent.length - 1 && (
            <button onClick={() => Acta.dispatchEvent("closeModal")} className={styles.closeButton}>
              FERMER
            </button>
          )}
        </div>
      )}
    </ExpandableModal>
  );
};

export default OnboardingSequenceModal;
