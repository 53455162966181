import { useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import { DSDropdownAsyncItem, DSDropdownItem } from "@skillup/ui";

import { type Template, useTemplate } from "services/interviews";
import { State, ModalStates } from "../hooks";
import { useCallback } from "react";

type OpenModalActionsProps = {
  template: Template;
  setActionsState: (state: State) => void;
};

type PreviewActionsProps = {
  as: "manager" | "collab" | "hr";
  asyncAction: () => Promise<void>;
  label?: string;
};

const Edit = ({
  template,
  setActionsState,
  relatedCampaigns,
}: {
  template: Template;
  setActionsState: (state: State) => void;
  relatedCampaigns?: { uuid: string; title: string; archivedAt?: string }[];
}) => {
  const { push } = useHistory();
  const unarchivedRelatedCampaigns = relatedCampaigns?.filter(
    (relatedCampaign) => !relatedCampaign?.archivedAt
  );

  const onClickAction = useCallback(() => {
    if (unarchivedRelatedCampaigns && unarchivedRelatedCampaigns?.length <= 0)
      return push(`/responsable/template/${template.uuid}`);
    return setActionsState({ state: ModalStates.Edit, template });
  }, [push, setActionsState, template, unarchivedRelatedCampaigns]);

  return <DSDropdownItem key="edit" label="Modifier la trame" onClick={onClickAction} />;
};

const EditParams = ({ template, setActionsState }: OpenModalActionsProps) => {
  const openEditParamsModal = () => setActionsState({ state: ModalStates.EditParams, template });
  return (
    <DSDropdownItem
      key="editParams"
      label="Paramètres généraux de la trame"
      onClick={openEditParamsModal}
    />
  );
};

const EditTargetSettings = ({ template, setActionsState }: OpenModalActionsProps) => {
  const openEditTargetSettingsModal = () =>
    setActionsState({ state: ModalStates.EditTargetSettings, template });
  return (
    <DSDropdownItem
      key="editTargetSettings"
      label="Paramètres liés aux objectifs"
      onClick={openEditTargetSettingsModal}
    />
  );
};

const Rename = ({ template, setActionsState }: OpenModalActionsProps) => {
  const openRenameModal = () => setActionsState({ state: ModalStates.Rename, template });
  return <DSDropdownItem label="Renommer la trame" onClick={openRenameModal} key="rename" />;
};
const Archive = ({ template, setActionsState }: OpenModalActionsProps) => {
  const openArchiveModal = () => setActionsState({ state: ModalStates.Archive, template });
  return <DSDropdownItem label="Archiver la trame" onClick={openArchiveModal} key="archive" />;
};

const TransferCopy = ({ template, setActionsState }: OpenModalActionsProps) => {
  const openTransferCopyModal = () =>
    setActionsState({ state: ModalStates.TransferCopy, template });
  return (
    <DSDropdownItem
      key="transfer-copy"
      label="[OPS] Transférer une copie de la trame vers une autre entreprise"
      onClick={openTransferCopyModal}
    />
  );
};

const DownloadPDF = ({ templateUuid }: { templateUuid: string }) => {
  const { addToast } = useToasts();
  const templateActions = useTemplate(templateUuid);

  return (
    <DSDropdownAsyncItem
      label="Télécharger le PDF de la trame"
      asyncAction={async () => {
        try {
          await templateActions.downloadPDF();
          addToast("Téléchargement réussi", { appearance: "success" });
        } catch (e) {
          addToast("Echec lors du téléchargement de la trame", {
            appearance: "error",
          });
        }
      }}
    />
  );
};

const Preview = ({ as, asyncAction, label }: PreviewActionsProps) => {
  switch (as) {
    case "collab":
      return (
        <DSDropdownAsyncItem
          key="previewCollab"
          label={label ?? "Prévisualiser en tant que collaborateur"}
          asyncAction={asyncAction}
        />
      );
    case "manager":
      return (
        <DSDropdownAsyncItem
          key="previewManager"
          label={label ?? "Prévisualiser en tant que responsable"}
          asyncAction={asyncAction}
        />
      );
    case "hr":
      return (
        <DSDropdownAsyncItem
          key="previewHR"
          label="[DEV] Prévisualiser en tant que RH"
          asyncAction={asyncAction}
        />
      );
  }
};

const CreateCampaign = ({ template, setActionsState }: OpenModalActionsProps) => {
  const openChooseCampaignTypeModal = () =>
    setActionsState({ state: ModalStates.ChooseCampaignType, template });
  return (
    <DSDropdownItem
      key="createCampaign"
      label="[OPS] Créer une campagne en utilisant cette trame"
      onClick={openChooseCampaignTypeModal}
    />
  );
};

const Duplicate = ({ template, onClose }: { template: Template; onClose?: () => void }) => {
  const templateActions = useTemplate(template.uuid);

  return (
    <DSDropdownItem
      key="duplicateTemplate"
      label="Dupliquer la trame"
      onClick={async () => {
        await templateActions.duplicate(template);
        if (onClose) onClose();
      }}
    />
  );
};

export const TemplateActions = {
  Edit,
  EditParams,
  EditTargetSettings,
  Rename,
  Archive,
  TransferCopy,
  DownloadPDF,
  Preview,
  CreateCampaign,
  Duplicate,
};
