import React from "react";
import { useToggle } from "react-use";
import { SingleDatePicker } from "react-dates";
import moment, { Moment } from "moment";
import isNil from "lodash/isNil";

import styles from "./Steps.module.scss";

type TCampaignType = "ongoing" | "legacy";

interface IProps {
  readonly start?: number;
  readonly duration?: number;
  readonly type?: TCampaignType;
  readonly setStart: (start?: number) => void;
  readonly setDuration: (duration?: number) => void;
  readonly setType?: (type: TCampaignType) => void;
  readonly disabled?: boolean;
}

const ClassicCampaignForm = (props: IProps) => {
  const [focusedStart, setFocusedStart] = useToggle(false);
  const [focusedEnd, setFocusedEnd] = useToggle(false);

  const { start, duration, disabled, setStart, setDuration } = props;

  const endDate = moment(start).add(duration || 1, "days");

  const onStartDateChange = (date: Moment) => {
    if (!isNil(date)) {
      setStart(date.startOf("day").valueOf());
    }
  };

  const onEndDateChange = (date: Moment) => {
    if (!isNil(date)) {
      const differenceInDays = date.diff(start, "days");
      setDuration(differenceInDays);
    }
  };

  return (
    <>
      <section className={styles.option}>
        <label className={styles.requiredField}>Date de début de campagne</label>
        <SingleDatePicker
          isDayBlocked={(day: Moment) => day.isBefore(moment().startOf("day"))}
          focused={focusedStart}
          date={moment(start) || moment()}
          onDateChange={onStartDateChange}
          id="start"
          onFocusChange={setFocusedStart}
          disabled={disabled}
        />
        <span className={styles.info}>
          Date à laquelle un email sera envoyé à tous les collaborateurs et managers pour les
          inviter à préparer leurs entretiens (cet envoi peut être désactivé en étape 4).
        </span>
      </section>
      <section className={styles.option}>
        <label className={styles.requiredField}>Date limite de signature</label>
        <SingleDatePicker
          isOutsideRange={() => false}
          isDayBlocked={(day) => day.startOf("day").isSameOrBefore(start)}
          focused={focusedEnd}
          date={endDate}
          onDateChange={onEndDateChange}
          id="endDate"
          onFocusChange={setFocusedEnd}
        />
        <span className={styles.info}>
          Date à laquelle tous les entretiens devront avoir été réalisés et signés.
        </span>
      </section>
    </>
  );
};

export default ClassicCampaignForm;
