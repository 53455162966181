import React from "react";
import { minColumnWidth, defaultColumnWidth } from "constants/dataTable";

import styles from "./ColumnResizerStyles.module.scss";

import { ColumnResizing, Column } from "components/DataTable/types";

export interface IProps {
  columnResizing: ColumnResizing;
  endColumnResizing: (c: Column, n: number) => void;
  scrollLeft: number;
}

interface IState {
  resizerPosition?: number;
}

export default class ColumnResizer extends React.PureComponent<IProps, IState> {
  private colInitialWidth: number;
  private initalRelativeX: number;
  private minimalLeftPosition: number;

  public constructor(props: IProps) {
    super(props);

    this.colInitialWidth = props.columnResizing.width || defaultColumnWidth;

    this.initalRelativeX = props.columnResizing.relativeX + this.colInitialWidth + 30;

    this.minimalLeftPosition =
      props.columnResizing.relativeX + minColumnWidth + 30 - props.scrollLeft;

    this.state = {
      resizerPosition: this.initalRelativeX - props.scrollLeft,
    };
  }

  public componentDidMount(): void {
    window.addEventListener("mousemove", this.resizeColumn);
    window.addEventListener("mouseup", this.stopResizing);
  }

  public componentWillUnmount(): void {
    window.removeEventListener("mousemove", this.resizeColumn);
    window.removeEventListener("mouseup", this.stopResizing);
  }

  private resizeColumn = (event) => {
    document.body.style.cursor = "col-resize";
    this.setState({
      resizerPosition: Math.max(
        this.minimalLeftPosition,
        event.pageX -
          (this.props.columnResizing.absoluteX - this.initalRelativeX) -
          this.props.scrollLeft
      ),
    });
  };

  private stopResizing = () => {
    document.body.style.cursor = "initial";
    window.removeEventListener("mousemove", this.resizeColumn);
    window.removeEventListener("mouseup", this.stopResizing);

    const newColWidth = Math.max(
      this.colInitialWidth +
        this.state.resizerPosition -
        this.initalRelativeX +
        this.props.scrollLeft,
      minColumnWidth
    );

    this.props.endColumnResizing(this.props.columnResizing, newColWidth);
  };

  public render(): JSX.Element {
    const { resizerPosition } = this.state;

    return (
      <div className={styles.ColumnResizer} style={{ left: resizerPosition + 8 }}>
        <div />
        <div />
      </div>
    );
  }
}
