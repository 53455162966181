import { EnumerableField } from "services/coreHR/fields/useCoreHRFields";

export const useFieldsDataTable = (fields: EnumerableField[]) => {
  return {
    columns: generateTableColumns(),
    data: generateTableData(fields),
  };
};

function generateTableColumns() {
  const columns = [
    {
      key: "label",
      title: "Label",
    },
    {
      key: "fieldKey",
      title: "Clé",
    },
    {
      key: "kind",
      title: "Type",
    },
    {
      key: "scale",
      title: "Echelle d'evaluation",
    },
  ];
  return columns;
}

const generateTableData = (fields: EnumerableField[]) => {
  return fields.map((field) => ({
    id: field.uuid,
    data: {
      fieldKey: field.fieldKey,
      kind: field.kind,
      label: field.label,
      scale: field.scale.label,
    },
  }));
};
