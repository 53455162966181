import { v4 as uuid } from "uuid";
import type { TemplateItemPosition, PageBlock, SectionBlock, BuilderTemplate } from "../../types";

// keep old system with immutable add (but no hideInBuilder system)
// export function movePageToSection(
//   sections: Section[],
//   fromPosition: TemplateItemPosition<PageBlock>,
//   toPosition: TemplateItemPosition<SectionBlock>
// ) {
//   const { sectionIndex: fromSectionIndex, pageIndex: fromPageIndex } = fromPosition;
//   const { sectionIndex: toSectionIndex } = toPosition;

//   const fromSection = sections[fromSectionIndex];
//   const toSection = sections[toSectionIndex];

//   const fromPage = fromSection.pages[fromPageIndex];
//   const newFromSection = removePageFromSection(fromSection, fromPageIndex);
//   const newToSection =
//     toSection.pages.length === 1 &&
//     toSection.pages[0].hideInBuilder &&
//     toSection.pages[0].children?.length === 0
//       ? replacePagesInSection(toSection, [fromPage])
//       : addPageToSection(toSection, 0, fromPage);

//   const newSectionsTemp = replaceSection(sections, newFromSection, fromSectionIndex);
//   const newSections = replaceSection(newSectionsTemp, newToSection, toSectionIndex);

//   return newSections;
// }

export function movePageToSection(
  state: BuilderTemplate,
  fromPosition: TemplateItemPosition<PageBlock>,
  toPosition: TemplateItemPosition<SectionBlock>
) {
  const { sectionIndex: fromSectionIndex, pageIndex: fromPageIndex } = fromPosition;
  const { sectionIndex: toSectionIndex } = toPosition;

  const fromSection = state.sections[fromSectionIndex];
  const toSection = state.sections[toSectionIndex];

  const newPagesToMove = fromSection.pages.splice(fromPageIndex, 1);
  // if the from section now have 0 pages, we add a new page (hideInBuilder)
  if (fromSection.pages.length === 0) {
    fromSection.pages = [
      {
        uuid: uuid(),
        title: "Nouvelle sous-section",
        children: [],
        hideInBuilder: true,
      },
    ];
  }

  // toSection has only one page and this page is hideInBuilder and has no children
  // --> We just show this page now
  if (
    toSection.pages.length === 1 &&
    toSection.pages[0].hideInBuilder &&
    toSection.pages[0].children?.length === 0
  ) {
    // We consider that if you haved move this page, it cannot be a hideInBuilder page
    toSection.pages = newPagesToMove;
    return state;
  }

  // We add the new page at the beginning of the section
  toSection.pages = [...newPagesToMove, ...toSection.pages];
  // To be sure because you add a page in toSection, we remove hideInBuilder flag on all pages
  toSection.pages.forEach((page) => {
    if (page.hideInBuilder) {
      page.hideInBuilder = undefined;
    }
  });
  return state;
}
