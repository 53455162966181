import React from "react";
import { useDropzone } from "react-dropzone";

import type { UserImportsRoutes } from "@skillup/espace-rh-bridge";

import Acta from "utils/Acta";
import { buildFileRequest } from "utils/buildRequest";

import styles from "./Dropzone.module.scss";
import InteractiveButton from "components/InteractiveButton";
import { FcFolder } from "react-icons/fc";
import { useState } from "react";

interface DropzoneProps {
  refetch: () => void;
  canDropFile: boolean;
}

enum DropZoneState {
  IDLE = "idle",
  LOADING = "loading",
}

const DropZone = ({ refetch, canDropFile }: DropzoneProps) => {
  const [status, setStatus] = useState<DropZoneState>(DropZoneState.IDLE);

  const onDrop = async (files: File[]) => {
    if (status === DropZoneState.LOADING) return;
    setStatus(DropZoneState.LOADING);

    try {
      const uploadRequest = buildFileRequest<UserImportsRoutes.UploadImportData>({
        method: "POST",
        path: "/userImports/upload",
        file: files[0],
      });

      await uploadRequest();

      Acta.dispatchEvent("sendAppMessage", {
        message: "Fichier envoyé.",
        type: "success",
      });

      refetch();
      Acta.dispatchEvent("closeModal");
    } catch (e) {
      Acta.dispatchEvent("sendAppMessage", {
        message: "Une erreur est survenue lors de l’envoi de votre fichier.",
        type: "error",
      });
    } finally {
      // setIsLoading(false);
      setStatus(DropZoneState.IDLE);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: 52428800,
    multiple: false,
  });

  return (
    <div className={styles.dropZone}>
      <div {...getRootProps({ disabled: !canDropFile })}>
        <input aria-label="drop-fichier" {...getInputProps({ disabled: !canDropFile })} />
        {canDropFile || status === DropZoneState.LOADING ? (
          <DropFilePanel />
        ) : (
          <CannotDropFilePanel />
        )}
      </div>
    </div>
  );
};

const DropFilePanel = () => (
  <>
    <div className={styles.icon}>
      <FcFolder size={50} />
    </div>
    <div aria-label="Téléchargement" className={styles.description}>
      <p>Glissez / Déposez un fichier ici</p>
      <p>ou</p>
    </div>

    <InteractiveButton
      className={styles.addFileButton}
      label="Parcourir"
      aria-label="Parcourir"
      size="small"
    />
  </>
);

const CannotDropFilePanel = () => (
  <>
    <div aria-label="Envoi impossible" className={styles.cannotDropFile}>
      Une base est en attente de validation. Veuillez l’annuler pour en importer une nouvelle.
    </div>
  </>
);

export default DropZone;
