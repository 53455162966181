import { IWithRouter } from "utils/types";

import useAreas from "hooks/useAreas";

import NewNavigation from "../Navigation/NewNavigation";

import styles from "../SupervisorTrainingScheduleStyles.module.scss";

import type { GetScheduleDataRoute } from "types/api";
import UserAreaSelect from "components/UserAreaSelect";
import NewSearchInSchedule from "../Components/NewSearchInSchedule";
import { withRouter } from "react-router-dom";

interface IParams {
  readonly tab?: GetScheduleDataRoute["query"]["tab"] | "dashboard";
  readonly type?: "plan-de-formation" | "recueil-des-besoins";
  readonly uuid?: string;
  readonly selectedUUID?: string;
}

export interface IProps extends IWithRouter<IParams> {
  onChangeColumns: (columns: string[]) => void;
}

const SupervisorTrainingHeader = ({ match, onChangeColumns }: IProps) => {
  const { params } = match;
  const activeTab = params.tab || "dashboard";
  const { hasAllAreasActive, activeAreas, setActiveAreas } = useAreas();

  const scheduleUuid = params.uuid;

  const type = params.type === "plan-de-formation" ? "plan" : "collection";

  return (
    <div className={styles.navContainer}>
      <div className={styles.searchContainer}>
        <NewSearchInSchedule
          type={type}
          scheduleUuid={scheduleUuid}
          areas={activeAreas}
          hasAllAreasActive={hasAllAreasActive}
        />
        <div className={styles.buttons}>
          <UserAreaSelect
            className={styles.UserAreaSelect}
            selectClassName={styles.select}
            value={activeAreas}
            onChange={setActiveAreas}
            isSearchable
          />
        </div>
      </div>
      <NewNavigation
        onChange={onChangeColumns}
        scheduleViewUuid={scheduleUuid}
        activeTab={activeTab}
        type={type}
      />
    </div>
  );
};

export default withRouter(SupervisorTrainingHeader);
