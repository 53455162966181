import { TranslationType } from "hooks/useTranslation";
import { AddToast } from "react-toast-notifications";
import DataLayer from "utils/DataLayer";

interface Props {
  reviewsUuids: string[];
  setLoadingSendMails: (loading: boolean) => void;
  setShowAlert: (show: boolean) => void;
  refetch: () => void;
}

export const recallTrainees = async (
  { reviewsUuids, setLoadingSendMails, setShowAlert, refetch }: Props,
  addToast: AddToast,
  t: TranslationType
): Promise<void> => {
  try {
    setLoadingSendMails(true);
    setShowAlert(true);
    await DataLayer.request({
      target: "API",
      method: "POST",
      url: "/v1/review/trainee-recall",
      body: JSON.stringify(reviewsUuids),
    });
    addToast(
      t("trainings.manage.reviews.list.actions.mailsSent", {
        defaultValue: "Les emails ont bien été envoyés",
      }),
      { appearance: "success", autoDismiss: true }
    );

    refetch();
  } catch (err) {
    addToast(
      t("trainings.manage.reviews.list.actions.mailsNotSent", {
        defaultValue: "Les emails n’ont pas pu être envoyés",
      }),
      { appearance: "error", autoDismiss: true }
    );
  } finally {
    setShowAlert(false);
    setLoadingSendMails(false);
  }
};
