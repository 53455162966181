import { useCallback, useMemo } from "react";

import { DataTable, DSButton, Flex } from "@skillup/ui";

import DSLayout from "components/DSLayout";
import DSNewHeaderButton from "components/DSNewHeader/DSNewHeaderButton";
import { useTemplates } from "services/interviews";

import { useTemplatesActions, useTemplatesModals, ModalStates } from "../hooks";
import useTableData, { CampaignTemplateRow } from "./useTableData";
import useTemplatesSidePanelActions, { SidePanelStates } from "./useTemplatesSidePanelActions";
import useTemplatesSidePanel from "./useTemplatesSidePanel";
import { getActions } from "./getTemplateListActions";

import styles from "./TemplatesList.module.scss";

export const TemplatesList = () => {
  const {
    templates,
    hasArchivedTemplates,
    actions: { canDo, getPreviewLinksByUuid },
  } = useTemplates();
  const { columns, data: tableData } = useTableData(templates);
  const [{ state: actions }, setActionsState] = useTemplatesActions();
  const [{ state: sidePanelState }, setSidePanelState] = useTemplatesSidePanelActions();
  const sidePanel = useTemplatesSidePanel(sidePanelState, setSidePanelState);
  const closeSidePanel = useCallback(() => {
    setSidePanelState({ state: SidePanelStates.Idle, selectedRow: null });
  }, [setSidePanelState]);
  const modalToDisplay = useTemplatesModals(actions, setActionsState, closeSidePanel);

  const openCreateModal = useCallback(() => {
    setActionsState({ state: ModalStates.Create });
  }, [setActionsState]);

  const handleClickRow = useCallback(
    (rowId: string) => {
      const template = templates.find((template) => template.uuid === rowId);
      setSidePanelState(
        sidePanelState.selectedRow === rowId
          ? { state: SidePanelStates.Idle, selectedRow: null }
          : {
              state: SidePanelStates.Open,
              template: template,
              openActionModal: setActionsState,
              selectedRow: rowId,
            }
      );
    },
    [setSidePanelState, setActionsState, sidePanelState.selectedRow, templates]
  );

  const openArchivedTemplatesModal = () => {
    setActionsState({ state: ModalStates.ArchivedList });
  };

  const layouts = useMemo(
    () =>
      canDo("create")
        ? [
            {
              primaryButton: (
                <DSNewHeaderButton
                  id="create-template-button"
                  label="Créer une nouvelle trame"
                  onClick={openCreateModal}
                />
              ),
            },
          ]
        : [],
    [canDo, openCreateModal]
  );

  return (
    <DSLayout title={"Trames d'entretien"} layouts={layouts}>
      <Flex className={styles.container} column>
        <DataTable<CampaignTemplateRow>
          className={styles.table}
          columns={columns}
          rows={tableData}
          defaultSort={{ key: "date", desc: true }}
          actions={(row) => {
            const template = templates.find((template) => template.uuid === row.id);
            if (template && template.uuid) {
              return getActions(template, setActionsState, canDo, () =>
                getPreviewLinksByUuid(template.uuid)
              );
            }
            return [];
          }}
          header={{
            totalRowsLabel: (total) => `${total} trames`,
          }}
          pagination={{
            rowsPerPageLabel: "Lignes par page",
            itemsCountLabel: "Objets %range% sur %count%",
            pageLabel: "Page",
          }}
          onClickRow={(row) => handleClickRow(row.id)}
          mode="compact"
        />
        {hasArchivedTemplates && (
          <div className={styles.container__footer}>
            <DSButton
              buttonSize="S"
              emphasis="Low"
              label="Afficher les trames archivées"
              onClick={openArchivedTemplatesModal}
            />
          </div>
        )}
        {sidePanel}
        {modalToDisplay}
      </Flex>
    </DSLayout>
  );
};
