import { OrganizationDetails } from "hooks/useCompany";

export type Organization = Omit<OrganizationDetails["properties"], "uuid" | "features">;

interface FieldElementBasic {
  name: string;
  field: keyof Organization;
  required?: boolean;
}

export interface FieldTextElement extends FieldElementBasic {
  type: "TextInput" | "TextArea";
  placeholder?: string;
}

export interface FieldDropdown extends FieldElementBasic {
  type: "TextDropdown";
  options: Array<{ label: string; value: string }>;
  placeholder?: string;
}

export interface FieldBoolean extends FieldElementBasic {
  type: "Radio";
}

export interface FieldUserSearch extends FieldElementBasic {
  type: "UserSearch";
  placeholder?: string;
}

export type FieldElement = FieldUserSearch | FieldTextElement | FieldDropdown | FieldBoolean;

export const matchingFields: Array<FieldElement> = [
  {
    name: "Nom",
    field: "name",
    type: "TextInput",
    required: true,
  },
  {
    name: "Raison sociale",
    field: "legalName",
    type: "TextInput",
  },
  {
    name: "Adresse",
    field: "legalAddress",
    type: "TextArea",
  },
  {
    name: "SIREN",
    field: "legalRegistrationSIREN",
    type: "TextInput",
  },
  {
    name: "SIRET",
    field: "legalRegistrationSIRET",
    type: "TextInput",
  },
  {
    name: "Gestionnaire de réservation",
    field: "bookingManager",
    type: "UserSearch",
    required: true,
  },
  {
    name: "Numéro de téléphone du gestionnaire de réservation",
    field: "bookingManagerPhone",
    type: "TextInput",
    placeholder: "numéro",
  },
  {
    name: "Devise",
    field: "currency",
    type: "TextDropdown",
    options: [
      { label: "Dollar ($)", value: "USD" },
      { label: "Euro (€)", value: "EUR" },
      { label: "Franc suisse (₣)", value: "CHF" },
    ],
  },
  {
    name: "Subrogation de paiement",
    field: "legalOpcaSubrogation",
    type: "Radio",
  },
  {
    name: "OPCO",
    field: "legalOpca",
    type: "TextInput",
  },
  {
    name: "Contact chez l'OPCO",
    field: "legalOpcaContactName",
    type: "TextInput",
  },
  {
    name: "Adresse de l'OPCO",
    field: "legalOpcaAddress",
    type: "TextArea",
  },
  {
    name: "Commentaire pour l'organisme",
    field: "globalBookingsComment",
    type: "TextArea",
    placeholder: "Saisissez votre commentaire",
  },
];
