import React from "react";

import { DSModal } from "@skillup/ui";

import ClipboardText from "../components/ClipboardText";
import styles from "./AuthStrategyConnectionInfoModal.module.scss";

type Props = {
  onClose: () => void;
  authStrategyProvider?: string;
};

export default ({ onClose, authStrategyProvider }: Props) => {
  const metaDataLink = `${process.env.REACT_APP_AUTH_API_ROOT}/v1/saml/${authStrategyProvider}/sp-metadata`;
  const connectionLink = `${process.env.REACT_APP_AUTH_API_ROOT}/v1/saml/${authStrategyProvider}`;

  return (
    <DSModal isOpen>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Informations de connexion" />
      </DSModal.Header>

      <DSModal.Content>
        <div className={styles.modalContent}>
          <div className={styles.companyConnectionLink}>
            <h2>Lien vers les metadata :</h2>
            <div className={styles.content}>
              <ClipboardText value={metaDataLink} tooltipText="Copier l’URL des metadata" />
            </div>
          </div>

          <div className={styles.companyConnectionLink}>
            <h2>Identifiant / Url de Callback :</h2>
            <div className={styles.content}>
              <ClipboardText value={connectionLink} tooltipText="Copier l’URL de connexion" />
            </div>
          </div>
        </div>
      </DSModal.Content>
      <DSModal.FooterEmpty />
    </DSModal>
  );
};
