import { isNil, isUndefined } from "lodash";

import { CampaignMailingSettings } from "@skillup/espace-rh-bridge";
import { Either, Future } from "@skillup/monads";

import { IEditionState } from "./ModifyCampaignModal";

import { Campaign } from "./useCampaignModals";
import { buildSafeRequest } from "utils/buildRequest";
import { CampaignsRoutes } from "@skillup/espace-rh-bridge";
import { AddToast } from "react-toast-notifications";
import { formatError } from "services/errors";

export enum EditionContext {
  IDLE,
  MODIFY,
  UPDATE_STRUCTURE,
  PARAMETER,
  NOTIFICATION,
  CLOSE,
}

export type Target = "duration" | "startDate" | "endDate";
export type AdditionalProps = {
  duration: number;
  preparationTime: number;
  startDate: number;
  frequency: number;
  mailingSettings?: CampaignMailingSettings;
};

export const additionalLegacyProps = (state: IEditionState): Either<Target[], AdditionalProps> => {
  let errors: Target[] = [];
  for (const target of ["duration", "startDate"] as Target[]) {
    if (isNil(state[target])) {
      errors.push(target);
    }
  }

  if (errors.length > 0) {
    return Either.left(errors);
  }

  const date = state.startDate.valueOf();
  const timezoneOffset = new Date(date).getTimezoneOffset() * 60 * 1000;

  return Either.right({
    duration: state.duration,
    preparationTime: state.duration - 1,
    startDate: date - timezoneOffset,
    frequency: undefined,
    mailingSettings: state.mailingSettings,
  });
};

export const additionalOngoingProps = (state: IEditionState): Either<Target[], AdditionalProps> => {
  let errors: Target[] = [];
  for (const target of ["duration", "frequency"] as Target[]) {
    if (isNil(state[target])) {
      errors.push(target);
    }
  }

  if (errors.length > 0) {
    return Either.left(errors);
  }

  return Either.right({
    duration: state.duration,
    preparationTime: state.duration - 1,
    startDate: undefined,
    frequency: state.frequency,
  });
};

export const additionalAdHocProps = (_state: IEditionState): Either<Target[], AdditionalProps> => {
  return Either.right({
    duration: undefined,
    preparationTime: undefined,
    startDate: undefined,
    frequency: undefined,
  });
};

const modifyCampaign = async (
  campaign: Campaign,
  state: IEditionState,
  setState: (
    patch: Partial<IEditionState> | ((prevState: IEditionState) => Partial<IEditionState>)
  ) => void
) => {
  let additionalProps: Either<Target[], AdditionalProps>;

  switch (campaign.type) {
    case "legacy":
      additionalProps = additionalLegacyProps(state);
      break;
    case "ongoing":
      additionalProps = additionalOngoingProps(state);
      break;
    case "adHoc":
      additionalProps = additionalAdHocProps(state);
      break;
  }

  if (additionalProps.isLeft()) {
    setState({ errors: additionalProps.left() });
    throw new Error();
  }

  const confidentialityChanged =
    state.confidential !== campaign.confidential && !isUndefined(state.confidential);

  const data = {
    type: campaign.type,
    titleForHR: state.titleForHR,
    titleForAttendees: state.titleForAttendees,
    confidential: confidentialityChanged ? state.confidential : undefined,
    ...additionalProps.right(),
  };

  const request = await buildSafeRequest<CampaignsRoutes.Modify>({
    method: "POST",
    path: "/campaigns/{campaignUuid}",
    params: { campaignUuid: campaign.uuid },
    payload: { data },
  });
  return request.run();
};

export const submitModification = async ({
  campaign,
  state,
  setState,
  addToast,
  onClose,
  resync,
  t,
}: {
  campaign: Campaign;
  state: IEditionState;
  setState: (
    patch: Partial<IEditionState> | ((prevState: IEditionState) => Partial<IEditionState>)
  ) => void;
  addToast: AddToast;
  onClose: () => void;
  resync: () => void;
  t: (
    key: string,
    options?: {
      [key: string]: any;
      defaultValue?: string;
    }
  ) => string;
}) => {
  const request = modifyCampaign(campaign, state, setState);
  await Future.unwrap(
    request,
    (err) => {
      addToast(
        formatError(t, err, {
          defaultValue: "Une erreur est survenue lors de la modification des paramètres.",
        }),
        {
          appearance: "error",
          autoDismiss: true,
        }
      );
    },
    (_habilitation) => {
      addToast(
        t("campaign.modification.success", {
          defaultValue: "Paramètres modifiés avec succès",
        }),
        {
          appearance: "success",
        }
      );
      onClose();
      resync();
    }
  );
};
