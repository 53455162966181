import { TemplatesRoutes, CampaignTemplate } from "@skillup/espace-rh-bridge";

import { buildRequest } from "utils/buildRequest";

type CreateTemplateParams = Omit<CampaignTemplate, "uuid">;

async function createTemplate(params: CreateTemplateParams): Promise<string | undefined> {
  try {
    const data = params;
    const request = buildRequest<TemplatesRoutes.Create>({
      method: "POST",
      path: "/templates",
      payload: {
        data: JSON.stringify(data),
      },
    });

    const { templateUuid } = await request();
    return templateUuid;
  } catch (err) {
    throw new Error(err);
  }
}

export { createTemplate, type CreateTemplateParams };
