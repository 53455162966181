import { ReactNode, useMemo } from "react";
import { Link } from "react-router-dom";

import PortalCategories from "./components/PortalCategories";
import PortalFAQ from "./components/PortalFAQ";
import PortalListsLegacyWrapper from "./components/PortalListsLegacyWrapper";
import PortalOnboarding from "./components/PortalOnboarding";
import PortalSettings from "./components/PortalSettings";
import PortalAdmin from "./components/PortalAdmin";
import PortalCustomLabels from "./components/PortalCustomLabels";
import { PortalForm, ManagerPortalForm } from "./components/PortalForm";
import User from "utils/User";

import useSettings from "hooks/useSettings";
import createUserAccessChecker from "hooks/userAccessChecker";

export interface IRouteInterface {
  path: string;
  label: string;
  scope: string[];
  component: ReactNode;
  enabled?: () => boolean;
}

function Navigation() {
  const routes = usePortalSettingsRoutes();
  const scope = User.isSkillupAdmin() ? "admin" : "regular";

  const filteredRoutes = routes.filter((e) => e.scope.includes(scope) && (!e.enabled || e.enabled));

  return (
    <nav>
      {filteredRoutes.map((route: IRouteInterface) => (
        <Link
          to={route.path}
          className={route.path === location.pathname ? "active" : "notActive"}
          title={route.label}
          key={route.path}
        >
          {route.label}
        </Link>
      ))}
    </nav>
  );
}

export default Navigation;

const usePortalSettingsRoutes = () => {
  const { settings, userAccessModules } = useSettings();

  const UserAccessChecker = createUserAccessChecker(settings, userAccessModules);
  const hasTrainingsEnabled = UserAccessChecker.Trainings.toTrainings();

  const routes = useMemo(
    () =>
      PortalRoutes.map((route) => {
        if (route.path === "/responsable/gestion-du-portail/lists") {
          return { ...route, enabled: hasTrainingsEnabled };
        }

        return route;
      }),
    [hasTrainingsEnabled]
  );

  return routes;
};

export const PortalRoutes: Array<IRouteInterface> = [
  {
    component: PortalSettings,
    label: "Paramètres",
    path: "/responsable/gestion-du-portail",
    scope: ["admin"],
  },
  {
    component: PortalCategories,
    label: "Catégories",
    path: "/responsable/gestion-du-portail/categories",
    scope: ["admin"],
  },
  {
    component: PortalListsLegacyWrapper,
    label: "Listes",
    path: "/responsable/gestion-du-portail/lists",
    scope: ["admin", "regular"],
  },
  {
    component: PortalFAQ,
    label: "FAQ",
    path: "/responsable/gestion-du-portail/FAQ",
    scope: ["admin"],
  },
  {
    component: PortalOnboarding,
    label: "Onboarding",
    path: "/responsable/gestion-du-portail/onboarding",
    scope: ["admin"],
  },
  {
    component: PortalAdmin,
    label: "Admin",
    path: "/responsable/gestion-du-portail/admin",
    scope: ["admin"],
  },
  {
    component: PortalCustomLabels,
    label: "Labels Customs",
    path: "/responsable/gestion-du-portail/labels-customs",
    scope: ["admin"],
  },
  {
    component: PortalForm,
    label: "Recueil",
    path: "/responsable/gestion-du-portail/recueil",
    scope: ["admin"],
  },
  {
    component: ManagerPortalForm,
    label: "Validation manager",
    path: "/responsable/gestion-du-portail/validation-manager",
    scope: ["admin"],
  },
];
