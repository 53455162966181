import { useCallback, useMemo, useState } from "react";

import { Select, DSButton, DatePicker } from "@skillup/ui";
import { Flex, Text } from "@skillup/design-system";
import { FieldKey, DatasetsRoutes } from "@skillup/core-hr-bridge";

import useTranslation from "hooks/useTranslation";
import { getRecordsAtDate } from "services/coreHR/records/getRecordsAtDate";
import { Collaborator } from "../../../api";
import { FieldData } from "./FieldData";

type Props = {
  readonly user: Collaborator;
};

const FIELDS_KEYS: FieldKey[] = [
  "review.performance",
  "review.engagement",
  "review.flightRisk",
  "review.potential",
  "review.impact",
  "job.annualSalary",
  "job.monthlySalary",
  "job.hourlyRate",
  "job.jobTitle",
  "job.currency",
];

export const FieldsAtDate = ({ user }: Props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFieldsKeys, setSelectedFieldsKeys] = useState<FieldKey[]>([]);
  const [fieldsAtDate, setFieldsAtDate] =
    useState<DatasetsRoutes["GetEmployeeFieldValueAtDateRoute"]["response"]>(undefined);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  const handleTimelineFields = useCallback(async () => {
    try {
      setLoading(true);
      const records = await getRecordsAtDate({
        employeeId: user.uuid,
        fieldsKeys: selectedFieldsKeys,
        date: selectedDate.toISOString(),
      });
      setFieldsAtDate(records);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [user.uuid, selectedFieldsKeys, selectedDate]);

  const fieldsKey: FieldKey[] = useMemo(() => {
    return Object.keys(fieldsAtDate ?? {}) as FieldKey[];
  }, [fieldsAtDate]);

  return (
    <Flex padding="s" flexDirection="column">
      <Text espaceFont="h4">
        {t("collab.historic.fieldskeysAtDate.title", {
          defaultValue: "Champs utilisateur à date",
        })}
      </Text>
      <Flex marginTop="s">
        <Flex padding="xs">
          <Select
            options={FIELDS_KEYS?.map((key) => ({
              label: key,
              value: key,
            }))}
            extraValuesLabel={(count) =>
              t("showmore.count", { count, defaultValue: "et {{count}} de plus" })
            }
            multi
            value={selectedFieldsKeys}
            onChange={(value) => {
              setSelectedFieldsKeys(value);
            }}
            selectPlaceholder={t("collab.historic.fieldskeys.nothingSelected", {
              defaultValue: "Aucun champs séléctionné",
            })}
          />
        </Flex>
        <Flex padding="xs">
          <DatePicker
            placeholder="Choisissez une date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e)}
            withIcon={false}
            autoFormat
          />
        </Flex>
      </Flex>

      <Flex marginTop="s">
        <DSButton
          onClick={handleTimelineFields}
          disabled={selectedFieldsKeys.length === 0}
          label={t("collab.historic.fieldskeys.fetch", {
            defaultValue: "Recupérer les données",
          })}
          loading={loading}
        />
      </Flex>

      <Flex padding="xs" flexWrap="wrap">
        {fieldsKey?.map((field) => {
          return <FieldData fieldKey={field} fieldData={fieldsAtDate[field]} />;
        })}
      </Flex>
    </Flex>
  );
};
