import React from "react";

import {
  DatePicker as MUIDatePicker,
  type DatePickerProps as MUIDatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import { type PickerValidDate } from "@mui/x-date-pickers-pro";

import { useTheme } from "context/themes";

import Popper from "../components/Popper";

export type DatePickerProps = Omit<
  MUIDatePickerProps<PickerValidDate>,
  "theme" | "disableHighlightToday" | "slots"
>;

const DatePicker = (props: DatePickerProps) => {
  const theme = useTheme();

  return (
    <MUIDatePicker
      showDaysOutsideCurrentMonth
      {...props}
      disableHighlightToday
      slots={{
        popper: Popper,
      }}
      slotProps={{
        ...props.slotProps,
        popper: {
          ...props.slotProps?.popper,
          theme: theme,
        } as any,
      }}
    />
  );
};

export default DatePicker;
