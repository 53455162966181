import Acta from "utils/Acta";
import DataLayer from "utils/DataLayer";
import { v4 as uuid } from "uuid";

const addColumn = async (context) => {
  const { schedule } = context.state;
  const { type } = context.props;
  const column = {
    key: uuid(),
    label: "Sans nom",
    position: (context.state.columns || []).length + 1,
    type: "text",
  };

  try {
    await DataLayer.request({
      body: JSON.stringify(column),
      method: "POST",
      url: `/v1/schedule/column/${schedule.properties.uuid}/${schedule.view.uuid}?type=${type}`,
    });

    // Add column to local state data
    context.setState((prevState) => ({
      columns: [
        ...prevState.columns,
        {
          ...column,
          editable: true,
          movable: true,
          removable: true,
        },
      ],
      rows: prevState.rows.map((row) => ({
        columns: {
          ...row.columns,
          [column.key]: {
            key: column.key,
            value: "",
          },
        },
        uuid: row.uuid,
      })),
    }));

    Acta.dispatchEvent("sendAppMessage", {
      message: "Modifications enregistrées.",
      type: "success",
    });
  } catch (error) {
    Acta.dispatchEvent("sendAppMessage", {
      message: "Une erreur est survenue et vos modifications n’ont pas été enregistrées.",
      type: "error",
    });
  }
};

export default addColumn;
