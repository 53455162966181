import type { TemplateItemPosition, PageBlock, SectionBlock, ChildBlock, Section } from "../..";
import { moveChildToPage } from "./moveChildToPage";

export function moveChildToSection(
  sections: Section[],
  fromPosition: TemplateItemPosition<ChildBlock>,
  toPosition: TemplateItemPosition<SectionBlock>
) {
  const toPagePosition = {
    type: "page",
    pageIndex: 0,
    sectionIndex: toPosition.sectionIndex,
  };
  return moveChildToPage(sections, fromPosition, toPagePosition as TemplateItemPosition<PageBlock>);
}
