import { useState } from "react";
import { useHistory } from "react-router-dom";

import { DSModal, DSAlert, DSAlertType, DSAlertDisplay, DSCheckbox } from "@skillup/ui";

import { Campaign } from "./useCampaignModals";
import styles from "./UpdateStructureModal.module.scss";

interface IProps {
  readonly campaign: Campaign;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

const UpdateStructureModal = ({ campaign, isOpen, onClose }: IProps) => {
  const history = useHistory();

  const [acceptedWarnings, setAcceptedWarnings] = useState(false);

  return (
    <DSModal isOpen={isOpen} className={styles.modal}>
      <DSModal.Header onClose={onClose}>
        <DSModal.Header.Title title="Correction d'une campagne en cours" />
      </DSModal.Header>
      <DSModal.Content className={styles.content}>
        <div className={styles.contentWrapper}>
          <DSAlert type={DSAlertType.WARNING} display={DSAlertDisplay.INLINE}>
            Vous vous apprêtez à corriger la trame d’une campagne déjà lancée. Prenez connaissance
            des conséquences listées ci-dessous avant de poursuivre :
          </DSAlert>
          <ul className={styles.warningList}>
            <li>
              Les corrections apportées seront appliquées à tous les entretiens (présents et futurs,
              signés ou non) de la campagne « {campaign.titleForHR} ».
            </li>
            <br />
            <li>
              Elles ne seront pas répercutées sur la trame d’entretien initialement utilisée («{" "}
              {campaign.templateSource.title} »).
            </li>
            <br />
            <li>L’auteur et la nature des corrections seront loggués.</li>
          </ul>
          <DSCheckbox
            label="J’ai pris connaissance des avertissements ci-dessus."
            checked={acceptedWarnings}
            onChange={(checked) => {
              setAcceptedWarnings(checked);
            }}
          ></DSCheckbox>
        </div>
      </DSModal.Content>
      <DSModal.Footer>
        <DSModal.Footer.CancelButton label="Annuler" onClick={onClose} />
        <DSModal.Footer.PrimaryButton
          label="Poursuivre vers la correction"
          disabled={!acceptedWarnings}
          tooltip={
            !acceptedWarnings
              ? "Veuillez cocher la case confirmant que vous avez pris connaissance des avertissements."
              : undefined
          }
          onClick={() => {
            history.push(`/responsable/campagne/${campaign.uuid}/edit`);
          }}
        />
      </DSModal.Footer>
    </DSModal>
  );
};

export default UpdateStructureModal;
