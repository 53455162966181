import React from "react";
import { useHistory } from "react-router";

import { getInitials } from "commons";

import { DSAvatar, UserPopover, DSSidePanel } from "@skillup/ui";

import { SidePanelProps } from "./types";
import { Flex, Text } from "../../styled";
import { SidePanelLevelName } from "./EvaluationBoard.styled";

export const SidePanel = ({ close, isOpen, sidePanelProps, t }: SidePanelProps) => {
  const { jobName, level, skillName, skillUuid } = sidePanelProps || {};
  const employees = level?.employees || [];
  const history = useHistory();

  return (
    <DSSidePanel isOpen={isOpen} portalTarget={document.body}>
      <DSSidePanel.Header onClose={close}>
        <DSSidePanel.Header.Title title={skillName ?? ""} />
        <DSSidePanel.Header.SubTitle subtitle={jobName ?? ""}></DSSidePanel.Header.SubTitle>
        <DSSidePanel.Header.Actions
          secondaryButtonSize="S"
          onSecondaryActionClick={() =>
            history.push(`/responsable/analyses/competence/${skillUuid}`)
          }
          secondaryActionLabel={t("skills.dashboard.sidePanel.seeSkill", {
            defaultValue: "Accéder aux évaluations de cette compétence",
          })}
        />
      </DSSidePanel.Header>
      <DSSidePanel.Content>
        <Flex flexDirection="column">
          {level && (
            <>
              <SidePanelLevelName>
                <Flex>
                  {t("skills.dashboard.sidePanel.levelDescription", {
                    defaultValue: "Évaluations des collaborateurs ayant atteint le niveau :",
                  })}
                </Flex>
                <Flex>
                  {level.name} {level.description && ` - ${level.description}`}
                </Flex>
              </SidePanelLevelName>
              <Flex marginTop="s">
                {employees.length > 0 && (
                  <Flex flexDirection="column">
                    {employees.map((employee) => {
                      const { uuid, fullName, role } = employee;

                      return (
                        <Flex key={uuid} marginBottom="xs" alignItems="center">
                          <UserPopover
                            position="down-right"
                            properties={[role].filter(Boolean)}
                            user={{ uuid, fullName, initials: getInitials(fullName) }}
                            profileBtn={{
                              label: t("skills.dashboard.sidePanel.seeProfile", {
                                defaultValue: "Voir le profil",
                              }),
                              onClick: () => {
                                history.push(`/responsable/collaborateurs/${uuid}/competences`);
                              },
                            }}
                          >
                            <Flex key={uuid} marginBottom="xs" alignItems="center">
                              <DSAvatar
                                size="S"
                                randomColorSeedString={uuid}
                                initials={getInitials(fullName)}
                              />
                              <Text
                                marginLeft="xs"
                                espaceFont="body1Regular"
                                color="plainText-onLight-default"
                              >
                                {fullName}
                              </Text>
                            </Flex>
                          </UserPopover>
                        </Flex>
                      );
                    })}
                  </Flex>
                )}
              </Flex>
            </>
          )}
        </Flex>
      </DSSidePanel.Content>
      <DSSidePanel.FooterEmpty></DSSidePanel.FooterEmpty>
    </DSSidePanel>
  );
};
