import { useQuery, useMutation } from "@tanstack/react-query";

import { getActions, type Action } from "./getActions";
import { createAction, type CreateActionPayload } from "./createAction";

export function useActions(options: Options = defaultOptions) {
  const query = useQuery(["people-review-actions"], getActions, {
    ...defaultOptions,
    ...options,
  });

  const createActionMutation = useMutation(
    async (payload: CreateActionPayload) => {
      const response = await createAction(payload);
      query.refetch();

      return response;
    },
    {
      mutationKey: ["create-action"],
    }
  );

  const actions = query.data?.filter((action) => !action.archivedAt);
  const archivedActions = query.data?.filter((action) => action.archivedAt);

  return {
    actions,
    archivedActions,
    isError: query.isError,
    isLoading: query.isLoading,
    mutations: {
      create: createActionMutation,
    },
  };
}

export type { Action };

export type Options = {
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
};

export const defaultOptions = {
  refetchOnMount: true,
  refetchOnWindowFocus: false,
};
