import { AssistiveArea, Switch } from "@skillup/ui";
import { FormikProps } from "formik";
import { FormEvaluationLevel } from "types/skills";
import { LevelOptions } from "./EvaluationScaleLevelOptions.styled";

import useTranslation from "hooks/useTranslation";
import { Flex, Text } from "@skillup/design-system";

interface FormikState {
  evaluationScaleName: string;
  startAtZero: boolean;
  hasDescriptions: boolean;
  levels: FormEvaluationLevel[];
}

interface EvaluationScaleLevelOptionsProps {
  formikProps: FormikProps<FormikState>;
  insert: (index: number, value: FormEvaluationLevel) => void;
  remove: (index: number) => void;
  push: (value: FormEvaluationLevel) => void;
}

const EvaluationScaleLevelOptions = ({
  push,
  insert,
  remove,
  formikProps,
}: EvaluationScaleLevelOptionsProps) => {
  const { t } = useTranslation();

  const changeEvaluationScaleStartLevel = (startAtZero: boolean) => {
    const commonProps = {
      name: "",
      description: "",
      deletable: false,
    };

    if (startAtZero) {
      insert(0, {
        level: 0,
        placeholder: t("portal.config.skills.createEvaluationScale.skillNotAcquired", {
          defaultValue: "Ex: Compétence non maitrisée",
        }),
        ...commonProps,
      });
    } else {
      remove(0);
      if (formikProps.values.levels.length === 2) {
        push({
          level: 0,
          placeholder: t("portal.config.skills.createEvaluationScale.skillIntermediate", {
            defaultValue: "Ex: Intermédiaire",
          }),
          ...commonProps,
        });
      }
    }
  };

  return (
    <LevelOptions>
      <Flex flexDirection="column" alignItems="flex-start" gap="m" width="100%">
        <Text espaceFont="body2Bold">Options d’échelle d’évaluation</Text>
        <Flex flexDirection="column" alignItems="flex-start">
          <Switch
            active={formikProps.values.startAtZero}
            onToggle={(value) => {
              changeEvaluationScaleStartLevel(value);
              formikProps.setFieldValue("startAtZero", value);
            }}
            label={t("portal.config.skills.createEvaluationScale.startAtZero", {
              defaultValue: "Commencer l’échelle d’évaluation à 0",
            })}
          />
          <Text espaceFont="captionRegular" paddingLeft="xl">
            <AssistiveArea
              text={t("portal.config.skills.createEvaluationScale.startAtZero.description", {
                defaultValue:
                  "Le niveau d’évaluation 0 est recommandé afin de pouvoir évaluer qu’une compétence n’est pas maîtrisée par le collaborateur.",
              })}
            />
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="flex-start">
          <Switch
            active={formikProps.values.hasDescriptions}
            onToggle={(value) => formikProps.setFieldValue("hasDescriptions", value)}
            label={t("portal.config.skills.createEvaluationScale.levelDescriptions", {
              defaultValue: "Définir des descriptions de niveaux",
            })}
          />
          <Text espaceFont="captionRegular" paddingLeft="xl">
            <AssistiveArea
              text={t("portal.config.skills.createEvaluationScale.levelDescriptions.description", {
                defaultValue:
                  "Ces descriptions seront reprises lors de la création de nouvelles compétences. Elles pourront être modifiées et personnalisées pour chaque compétence.",
              })}
            />
          </Text>
        </Flex>
      </Flex>
    </LevelOptions>
  );
};

export default EvaluationScaleLevelOptions;
