import React from "react";
import { TextDropdown, TextArea } from "@skillup/ui";

import { Child } from "../types";

type InterviewPageHTML = Child & { kind: "html" };
const HTMLBlock = ({
  data,
  updateChild,
}: {
  data: InterviewPageHTML;
  updateChild: (newChildData: Child) => void;
}) => {
  const variants = [{ value: "info" }, { value: "warning" }, { value: "error" }];
  return (
    <form>
      <label>Variante</label>
      <TextDropdown
        options={variants}
        small
        selectedIndex={variants.findIndex((variant) => variant.value === data.variant)}
        onSelect={({ value }) =>
          updateChild({ ...data, variant: value as InterviewPageHTML["variant"] })
        }
      />
      <label>Contenu</label>
      <TextArea
        key={`content-${data.uuid}`}
        value={data.content}
        onChange={(content) => updateChild({ ...data, content })}
      />
    </form>
  );
};

export default HTMLBlock;
