import { datadogRum } from "@datadog/browser-rum";

if (process.env.REACT_APP_ENV === "production") {
  datadogRum.init({
    applicationId: "21c98c98-0573-4ac7-87c8-4944a4c30427",
    clientToken: "pub6f0da4fac94aeea77768e0ba85dca038",
    site: "datadoghq.eu",
    service: "hr-app",
    env: process.env.REACT_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 10,
    sessionReplaySampleRate: 2,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [
      process.env.REACT_APP_PUBLIC_APP_URL,
      process.env.REACT_APP_PUBLIC_API_ROOT,
      process.env.REACT_APP_PORTAL_API_ROOT,
      process.env.REACT_APP_AUTH_API_ROOT,
      process.env.REACT_APP_CORE_HR_API_ROOT,
      process.env.REACT_APP_PEOPLE_REVIEW_API_ROOT,
      process.env.REACT_APP_INSIGHTS_SERVICE_API_ROOT,
    ],
  });

  // To start recording session at the replay sample rate defined above
  datadogRum.startSessionReplayRecording();
}
