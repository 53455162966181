import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { type Child, selectHighlightUuid } from "../../../../reducer";
import { Divided } from "../ChildWrappers";
import { BuilderFormInput } from "../../BuilderFormInput";
import styles from "./UserSpecificData.module.scss";

type UserSpecificDataChild = Extract<Child, { kind: "userSpecificData" }>;

type UserSpecificDataProps = {
  child: UserSpecificDataChild;
  updateChild?: (newChild: Partial<UserSpecificDataChild>) => void;
  readOnly?: boolean;
};

export const UserSpecificData = ({ child, updateChild, readOnly }: UserSpecificDataProps) => {
  const highlightUuid = useSelector(selectHighlightUuid);
  const multipleAssistiveTexts = useMemo(() => {
    return {
      default: <> </>,
      error: "Ce champ doit être rempli.",
    };
  }, []);

  const handleChange = useCallback(
    (value: string) => {
      updateChild({ userSpecificDataKey: value });
    },
    [updateChild]
  );

  return (
    <Divided>
      <div className={styles.container}>
        {readOnly && (
          <p>
            Ce bloc permet d’ajouter de la données personnalisée provenant de votre base
            utilisateur. Il ne peut être configuré que par votre interlocuteur dédié Skillup.
          </p>
        )}
        <div>
          <BuilderFormInput
            type="text"
            label="User data key"
            placeholder="Saisissez quelque chose"
            name={`${child.uuid}-specific-data`}
            value={child.userSpecificDataKey}
            onChange={readOnly ? undefined : handleChange}
            debounceValue={50}
            required
            disabled={readOnly}
            multipleAssistiveTexts={multipleAssistiveTexts}
            autoFocus={highlightUuid === child.uuid}
          />
        </div>
      </div>
      <div className={styles.permissions}>
        <p>Aucune action n’est possible pour ce bloc.</p>
      </div>
    </Divided>
  );
};
