import { useMemo } from "react";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { Flex } from "@skillup/ui";

import { DNDPageItem } from "../../dndReducer";
import { SortableChild } from "../Child/SortableChild";

type DraggablePage = DNDPageItem & {
  type: "page";
  sectionIndex: number;
  pageIndex: number;
};

export const SortablePageChildren = ({
  page,
  disabled,
}: {
  page: DraggablePage;
  disabled: boolean;
}) => {
  const { children, childrenIds } = useMemo(() => {
    if (!page) {
      return { children: [], childrenIds: [] };
    }
    return { children: page.children, childrenIds: page.children.map((child) => child.uuid) };
  }, [page]);

  return (
    <SortableContext items={childrenIds} strategy={verticalListSortingStrategy} disabled={disabled}>
      <Flex column>
        {children.map((child, index) => (
          <SortableChild
            key={child.uuid}
            child={{
              ...child,
              type: "child",
              sectionIndex: page.sectionIndex,
              pageIndex: page.pageIndex,
              childIndex: index,
            }}
          />
        ))}
      </Flex>
    </SortableContext>
  );
};
