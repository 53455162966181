import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import type { TargetCategory } from "@skillup/espace-rh-bridge";
import { ChipNumber, MaterialIcons } from "@skillup/ui";
import { useMemo } from "react";

export type CategoryRow = ReturnType<typeof parseTableRows>;

const useTableData = (
  categories: Array<TargetCategory>
): {
  data: CategoryRow[];
  columns: Array<GridColDef>;
} => {
  const columns = useMemo(generateTableColumns, []);
  const data = useMemo(() => generateTableRows(categories ?? []), [categories]);

  return { columns, data };
};

export default useTableData;

function generateTableRows(categories: Array<TargetCategory>) {
  return categories.map(parseTableRows);
}

const parseTableRows = (category: TargetCategory) => ({
  id: category.uuid,
  title: category.label,
  targets: category.targetCount,
  campaigns: category.campaigns.length,
  templates: category.templates.length,
});

function generateTableColumns(): Array<GridColDef> {
  const columns = [
    {
      field: "title",
      headerName: "Intitulé de la catégorie",
      filterable: true,
      minWidth: 256,
      flex: 1,
      sortComparator: (a: string, b: string) => a.localeCompare(b),
    },
    {
      field: "targets",
      renderHeader: () => <MaterialIcons.TrackChanges size="1rem" />,
      description: "Nombre d'objectifs créés dans cette catégorie",
      filterable: false,
      minWidth: 200,
      renderCell: (param: GridRenderCellParams<any, number>) => <ChipNumber value={param.value} />,
    },
    {
      field: "campaigns",
      headerName: "Nombre de campagnes utilisant cette catégorie",
      filterable: false,
      minWidth: 200,
      renderCell: (param: GridRenderCellParams<any, number>) => <ChipNumber value={param.value} />,
    },
    {
      field: "templates",
      headerName: "Nombre de trames utilisant cette catégorie",
      filterable: false,
      minWidth: 200,
      renderCell: (param: GridRenderCellParams<any, number>) => <ChipNumber value={param.value} />,
    },
  ];
  return columns;
}
