import { useMemo } from "react";
import { DSRadio, DSRadioGroup, DSTooltip, Flex } from "@skillup/ui";
import type { TargetDisallow, ReviewType } from "@skillup/espace-rh-bridge";
import { Permissions } from "../../Permissions/Permissions";
import { type Child } from "../../../../reducer";

import styles from "../../Permissions/Permissions.module.scss";

type Targets = Extract<Child, { kind: "targets" }>;

export const TargetsPermissions = ({
  child,
  currentShowCreation = false,
  updateReviewType,
  // [CSB] we do not allow to edit target scales for now
  // choicesForOrdinalTargets,
  indexes,
}: {
  child: Targets;
  currentShowCreation: boolean;
  updateReviewType: (reviewType: ReviewType) => void;
  // choicesForOrdinalTargets: string[];
  indexes: {
    child: number;
    page: number;
    section: number;
  };
}) => {
  const order: ("creation" | "evaluation" | "commentTarget" | "comment")[] = useMemo(() => {
    switch (child.type) {
      case "current":
        return ["evaluation", "commentTarget", "comment", "creation"];
      case "next":
        return ["creation", "commentTarget", "comment"];
      default:
        return [];
    }
  }, [child.type]);

  const disallow = useMemo(() => {
    if (!child.disallow) {
      switch (child.type) {
        case "current":
          const disallowForPermissions: TargetDisallow = {
            employee: {
              commentTarget: false,
              comment: false,
            },
            manager: {
              commentTarget: false,
              comment: false,
            },
          };
          if (currentShowCreation) {
            disallowForPermissions.employee.creation = true;
            disallowForPermissions.manager.creation = true;
          }
          // [CSB] we want to display the evaluation permission if the review type is undefined
          // if (child.reviewType === "evaluation") {
          if (child.reviewType !== "comment") {
            disallowForPermissions.employee.evaluation = true;
            disallowForPermissions.manager.evaluation = false;
          }
          return disallowForPermissions;
        case "next":
          return {
            employee: {
              creation: false,
              commentTarget: false,
              comment: false,
            },
            manager: {
              creation: false,
              commentTarget: false,
              comment: false,
            },
          };
        default:
          // ! maybe it should be an error ?  it would be easier next time to debug
          // throw new Error("Disallow error")
          return {};
      }
    }
    switch (child.type) {
      case "current":
        const disallowForPermissions: TargetDisallow = {
          employee: {},
          manager: {},
        };
        if (currentShowCreation) {
          disallowForPermissions.employee.creation = child.disallow.employee?.creation ?? true;
          disallowForPermissions.manager.creation = child.disallow.manager?.creation ?? true;
        }
        // [CSB] we want to display the evaluation permission if the review type is undefined
        // if (child.reviewType === "evaluation") {
        if (child.reviewType !== "comment") {
          disallowForPermissions.employee.evaluation = child.disallow.employee?.evaluation ?? true;
          disallowForPermissions.manager.evaluation = child.disallow.manager?.evaluation ?? true;
        }
        // [CSB] we want to display all permissions if the review type is undefined
        // if (child.reviewType !== undefined) {
        disallowForPermissions.employee.comment = child.disallow.employee?.comment ?? true;
        disallowForPermissions.employee.commentTarget =
          child.disallow.employee?.commentTarget ?? true;
        disallowForPermissions.manager.comment = child.disallow.manager?.comment ?? true;
        disallowForPermissions.manager.commentTarget =
          child.disallow.manager?.commentTarget ?? true;
        // }
        return disallowForPermissions;
      case "next":
        return {
          employee: {
            creation: child.disallow.employee?.creation,
            commentTarget: child.disallow.employee?.commentTarget,
            comment: child.disallow.employee?.comment,
          },
          manager: {
            creation: child.disallow.manager?.creation,
            commentTarget: child.disallow.manager?.commentTarget,
            comment: child.disallow.manager?.comment,
          },
        };
      default:
        // ! maybe it should be an error ?  it would be easier next time to debug
        // throw new Error("Disallow error")
        return {};
    }
  }, [child.type, child.disallow, currentShowCreation, child.reviewType]);

  return (
    <Flex column>
      {child.type === "current" && (
        <ChooseReviewType
          reviewType={child.reviewType}
          // [CSB] permission changes disabled
          // updateReviewType={updateReviewType}
          // choicesForOrdinalTargets={choicesForOrdinalTargets}
          indexes={indexes}
        />
      )}
      <Permissions
        indexes={indexes}
        order={order}
        override={{
          evaluation: "Qui peut évaluer les objectifs ?",
          commentTarget: "Qui peut commenter individuellement chaque objectif ?",
          comment: "Qui peut commenter globalement la liste d'objectifs ?",
          creation:
            child.type === "current"
              ? "Qui peut créer de nouveaux objectifs ?"
              : "Qui peut créer des objectifs ?",
        }}
        kind={child.kind}
        uuid={child.uuid}
        restrictions={disallow}
        mandatory={
          child.isCreateOptionsVisible || child.type === "next"
            ? {
                creation:
                  "La création d'objectifs étant permise, vous devez sélectionner au moins un participant qui pourra en créer.",
              }
            : undefined
        }
      />
    </Flex>
  );
};

const ChooseReviewType = ({
  reviewType,
  // [CSB] permission changes disabled
  // updateReviewType,
  // choicesForOrdinalTargets,
  indexes,
}: {
  reviewType: ReviewType;
  // updateReviewType: (reviewType: ReviewType) => void;
  // choicesForOrdinalTargets: string[];
  indexes: {
    child: number;
    page: number;
    section: number;
  };
}) => {
  // [CSB] permission changes disabled
  // const storeDispatch = useDispatch();

  if (reviewType === undefined) {
    return null;
  }

  return (
    <Flex column className={styles.permissions__reviewType}>
      <Flex column className={styles.permissions__action}>
        <p className={styles.permissions__actionLabel}>{"De quel type de revue s'agit-il ?"}</p>
        <Flex column className={reviewType === undefined ? styles.permissions__errorContainer : ""}>
          {/* [CSB] permission changes disabled
           {reviewType === undefined && (
            <AssistiveArea text={"Vous devez choisir une option"} mode="error" />
          )} */}
          <DSRadioGroup name={"reviewType" + indexes.section + indexes.page + indexes.child}>
            <Flex className={styles.permissions__actionChoice}>
              <DSTooltip
                // [CSB] permission changes disabled
                label={"Cet élément ne peut pas être modifié sur une campagne en cours."}
              >
              <DSRadio
                label="Évaluation"
                checked={reviewType === "evaluation"}
                // [CSB] permission changes disabled
                // onChange={() => {
                //   updateReviewType("evaluation");
                //   storeDispatch(
                //     changePermission({
                //       type: "grantPermission",
                //       action: "evaluation",
                //       indexes: indexes,
                //       role: "manager",
                //       // choicesForOrdinalTargets,
                //     })
                //   );
                // }}
                disabled
              />
              </DSTooltip>
            </Flex>
            <Flex className={styles.permissions__actionChoice}>
              <DSTooltip
                // [CSB] permission changes disabled
                label={"Cet élément ne peut pas être modifié sur une campagne en cours."}
              >
                <DSRadio
                  label="Commentaire seulement"
                  checked={reviewType === "comment"}
                  // [CSB] permission changes disabled
                  // onChange={() => {
                  //   updateReviewType("comment");
                  //   storeDispatch(
                  //     changePermission({
                  //       type: "revokePermission",
                  //       action: "evaluation",
                  //       indexes: indexes,
                  //       role: "employee",
                  //       // choicesForOrdinalTargets,
                  //     })
                  //   );
                  //   storeDispatch(
                  //     changePermission({
                  //       type: "revokePermission",
                  //       action: "evaluation",
                  //       indexes: indexes,
                  //       role: "manager",
                  //       // choicesForOrdinalTargets,
                  //     })
                  //   );
                  // }}
                  disabled
                />
              </DSTooltip>
            </Flex>
          </DSRadioGroup>
        </Flex>
      </Flex>
    </Flex>
  );
};
