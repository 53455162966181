const Acta = {
  /**
   * Boolean to know if acta has been initialized before
   */
  initialized: false,

  /**
   * states will hold values, subscribers list and history indexed by keys
   * events will hold subscribers list indexed by events keys
   * actions will hold actions function indexed by actions keys
   * actaIDs will be an array of unique ids
   *
   */
  actaIDs: [],
  actions: {},
  events: {},
  states: {},

  /**
   * subscribeState is called from a react component with a callback
   * when that state will be set, its value will be send
   * to the component by the callback
   *
   * that subsribtion can be destroyed manually and will be
   * destroyed automatically when the
   *
   * @param {String} state - The key to name the state
   * @param {Function} callback - Reference to the callback that will
   * be called when the state change
   * @param {Object} context - Reference to the react component from
   * wich the subscribtion is made => that will be needed to unsubscribe
   * when the compnent will unmount
   * @param {String} defaultValue - Optionnal, set a default value for
   * the state if there is none
   *
   */
  subscribeState(state: string, callback: Function, context: any, defaultValue = ""): any {
    /* Ensure the arguments */
    if (
      !state ||
      !callback ||
      !context ||
      typeof state !== "string" ||
      typeof callback !== "function" ||
      typeof context !== "object"
    ) {
      throw new Error(
        "You need to provide a state key, a callback function and a context (a mounted or mounting react component) when subscribing to a state"
      );
    }

    /* If this state does not already exists, creates it */
    this.states[state] = this.states[state] || {
      value: defaultValue || undefined,
      defaultValue: defaultValue || undefined,
      subscribtions: {},
    };
    this.states[state].subscribtions = this.states[state].subscribtions || {};

    /* If the component does not have an acta id, get him one */
    this.ensureActaID(context);

    /**
     * If a subscribtion for this context on this state
     * already exists, stop here
     */
    if (this.states[state].subscribtions[context.actaID]) return false;

    /**
     * Extend the componentWillUnmount hook on the context
     * with a state unsubscribtion, so when
     * the component unmounts, all its states subscribtions
     * will be destroyed
     */
    if (context.componentWillUnmount) {
      const oldComponentWillUnmount = context.componentWillUnmount;
      context.componentWillUnmount = () => {
        this.unsubscribeState(state, context);
        oldComponentWillUnmount.bind(context)();
      };
    } else {
      context.componentWillUnmount = () => this.unsubscribeState(state, context);
    }

    /**
     * Add the callback and the context to the subscribtion list
     * of the state
     */
    this.states[state].subscribtions[context.actaID] = {
      callback,
      context,
    };

    /* Dispatch the initial or current value to the subscriber
		if initialize is not set to false and if there is a valid
		non circular state to dispatch */
    try {
      const valueToReturn = JSON.stringify(this.states[state].value || null);
      callback(JSON.parse(valueToReturn));
      return JSON.parse(valueToReturn);
    } catch (err) {
      console.warn(`Error in subscribeState for "${state}":`, err);
      return null;
    }
  },

  subscribeStateWithoutContext(
    state: string,
    callback: Function,
    actaID: string,
    defaultValue: string = ""
  ): any {
    /* If this state does not already exists, creates it */
    this.states[state] = this.states[state] || {
      value: defaultValue || undefined,
      defaultValue: defaultValue || undefined,
      subscribtions: {},
    };
    this.states[state].subscribtions = this.states[state].subscribtions || {};

    /**
     * If a subscribtion for this context on this state
     * already exists, stop here
     */
    if (this.states[state].subscribtions[actaID]) return false;

    /**
     * Add the callback and the context to the subscribtion list
     * of the state
     */
    this.states[state].subscribtions[actaID] = { callback };

    /* Dispatch the initial or current value to the subscriber
		if initialize is not set to false and if there is a valid
		non circular state to dispatch */
    try {
      const valueToReturn = JSON.stringify(this.states[state].value || null);
      callback(JSON.parse(valueToReturn));
      return JSON.parse(valueToReturn);
    } catch (err) {
      console.warn(`Error in subscribeState for "${state}":`, err);
      return null;
    }
  },

  /**
   * unsubscribeState will simply remove the target context form
   * the subscribtions of the target state
   *
   * @param {String} state - The key to name the state
   * @param {Object} context - Reference to the target react component
   */
  unsubscribeState(state: string, context: object) {
    /* Ensure the arguments */
    if (
      !state ||
      !context ||
      typeof state !== "string" ||
      typeof context !== "object" ||
      !this.states[state]
    ) {
      throw new Error(
        "You need to provide an existing state key, and a context (a mounted or mounting react component) when unsubscribing from a state"
      );
    }

    /* Delete the subscribtion */
    // @ts-ignore
    delete this.states[state].subscribtions[context.actaID];
  },

  unsubscribeStateWithoutContext(state: string, actaID: string) {
    /* Delete the subscribtion */
    delete this.states[state].subscribtions[actaID];
  },

  /**
   * set state will save the value in the the tagret state
   * and will dispatch that update to all the subscriber
   * by he provided callback
   *
   * @param {String} state - mandatory - the state to target
   * @param {*} value - optionnal - can be anything, including falsy values
   * ; if that argument is omitted, the state will become undefined
   * @param {String} persistenceType - optionnal - can be "sessionStorage" or "localStorage"
   * if set, the state will be saved into the corresponding storage
   */
  setState(state: string, value: any, persistenceType?: string) {
    /* Ensure the arguments */
    if (!state || typeof state !== "string") {
      throw new Error("You need to provide a state key.");
    }

    /* If this state does not already exists, creates it */
    if (!this.states[state]) {
      this.states[state] = this.states[state] || {
        value: value || undefined,
        defaultValue: undefined,
        subscribtions: {},
      };
    }

    /* Save the value */
    this.states[state].value = value;

    /* If persistence is configured, store the value */
    if (persistenceType) {
      if (!["sessionStorage", "localStorage"].includes(persistenceType)) {
        throw new Error("Persistence type can only be sessionStorage or localStorage.");
      }
      window[persistenceType].setItem(`__acta__${state}`, JSON.stringify(value));
    }

    /**
     * Try to dispatch to all subscribers & kill the
     * subscribtion if the subscriber has been destroyed
     */
    Object.keys(this.states[state].subscribtions || {}).forEach((actaID) => {
      try {
        this.states[state].subscribtions[actaID].callback(value);
      } catch (err) {
        if (
          !this.states[state].subscribtions[actaID] ||
          !this.states[state].subscribtions[actaID].context ||
          !this.states[state].subscribtions[actaID].callback
        ) {
          console.warn("The context or the callback of an Acta subscribtion does not exists.");
          delete this.states[state].subscribtions[actaID];
        }
      }
    });
  },

  /**
   * Delete a state
   *
   * @param {String} state the state to target
   */
  deleteState(state: string, persistenceType) {
    /* Ensure the arguments */
    if (!state || typeof state !== "string") {
      throw new Error("You need to provide a state key.");
    }

    this.setState(state, null);

    if (persistenceType) {
      if (!["sessionStorage", "localStorage"].includes(persistenceType)) {
        throw new Error("Persistence type can only be sessionStorage or localStorage.");
      }
      // @ts-ignore
      window[persistenceType].removeItem(`__acta__${state}`);
    }

    return delete this.states[state];
  },

  /**
   * return the state form its name
   *
   * @param {String} state - the key to identify the target state
   * @return {*} can be anything
   */
  getState(state: string): any {
    /* Ensure the arguments */
    if (!state || typeof state !== "string" || !this.states[state]) {
      if (process.env.REACT_APP_ENV === "development") {
        console.warn("You need to provide an existing state key.");
      }
      return null;
    }

    return this.states[state].value || this.states[state].defaultValue || null;
  },

  /**
   * check the existence of a state
   *
   * @param {String} state - the key to identify the target state
   */
  hasState(state: string) {
    return !!this.states[state];
  },

  /**
   * subscribeEvent is called from a react component with a callback
   * when the corresponding event is dispatched, the callback is called
   * passing any argument set in the dispatcher
   *
   * that subsribtion can be destroyed manually and will be
   * destroyed automatically when the component unmount
   *
   * @param {String} state - The key to name the state
   * @param {Function} callback - Reference to the callback that will
   * be called when the state change
   * @param {Object} context - Reference to the react component from
   * wich the subscribtion is made => that will be needed to unsubscribe
   * when the compnent will unmount
   */
  subscribeEvent(eventName, callback: Function, context: object): void {
    /* Ensure the arguments */
    if (
      !eventName ||
      !callback ||
      !context ||
      typeof eventName !== "string" ||
      typeof callback !== "function" ||
      typeof context !== "object"
    ) {
      throw new Error(
        "You need to provide a event key, a callback function and a context (a mounted or mounting react component) when subscribing to an event"
      );
    }

    /* If this event does not already exists, creates it */
    this.events[eventName] = this.events[eventName] || {};

    /* If the component does not have an acta id, get him one */
    this.ensureActaID(context);

    /* If this context already listen to that event already exists, stop here */
    // @ts-ignore
    if (this.events[eventName][context.actaID]) return;

    /**
     * Extend the componentWillUnmount hook on the context
     * with a event unsubscribtion, so when
     * the component unmounts, all its events subscribtions
     * will be destroyed
     */
    // @ts-ignore
    if (context.componentWillUnmount) {
      // @ts-ignore
      const oldComponentWillUnmount = context.componentWillUnmount;
      // @ts-ignore
      context.componentWillUnmount = () => {
        this.unsubscribeEvent(eventName, context);
        oldComponentWillUnmount.bind(context)();
      };
    } else {
      // @ts-ignore
      context.componentWillUnmount = () => {
        this.unsubscribeEvent(eventName, context);
      };
    }

    /**
     * Add the callback and the context to the event listener list
     * of the event
     */
    // @ts-ignore
    this.events[eventName][context.actaID] = {
      callback,
      context,
    };
  },

  subscribeEventWithoutContext(eventName: string, callback: Function, actaID: string): void {
    /* If this event does not already exists, creates it */
    this.events[eventName] = this.events[eventName] || {};

    /* If this context already listen to that event already exists, stop here */
    if (this.events[eventName][actaID]) return;

    /**
     * Add the callback and the context to the event listener list
     * of the event
     */
    this.events[eventName][actaID] = { callback };
  },

  /**
   * unsubscribeEvent will remove the target context form
   * the subscribtions of the target eve,t
   *
   * @param {String} eventName - The key to name the eventName
   * @param {Object} context - Reference to the target react component
   */
  unsubscribeEvent(eventName: string, context: object): void {
    /* Ensure the arguments */
    if (
      !eventName ||
      !context ||
      typeof eventName !== "string" ||
      typeof context !== "object" ||
      !this.events[eventName]
    ) {
      throw new Error(
        "You need to provide an existing event name, and a context (a mounted or mounting react component) when unsubscribing from a state"
      );
    }

    /* Delete the subscribtion */
    // @ts-ignore
    delete this.events[eventName][context.actaID];
  },

  unsubscribeEventWithoutContext(eventName: string, actaID: string): void {
    /* Delete the subscribtion */
    if (this.events[eventName]) {
      delete this.events[eventName][actaID];
    }
  },

  /**
   * Dispatch an event
   *
   * @param {String} eventName - the key to target the event
   * @param {*} data - the data passed with the event
   */
  dispatchEvent(eventName: string, data?: any): void {
    /* Ensure the arguments */
    if (!eventName || typeof eventName !== "string") {
      throw new Error("You need to provide an event name to set.");
    }

    /* The event should exist */
    if (!this.events[eventName]) {
      return console.warn("You tried to dispatch an event that does not exist.");
    }

    /* Dispatch to all subscribers */
    Object.keys(this.events[eventName]).forEach((actaID) => {
      try {
        this.events[eventName][actaID].callback(data || null);
      } catch (err) {
        if (!this.events[eventName][actaID].context || !this.events[eventName][actaID].callback) {
          console.warn("The context or the callback of an Acta event does not exists.");
          delete this.events[eventName][actaID];
        }
      }
    });
  },

  /**
   * The init function will search for any previousely set state in the local
   * and session storage and will start listening on the changes in the storages
   * to update itself if the storage is updated from another tab
   */
  init(store: object): void {
    this.states = {};
    this.events = {};
    this.actions = {};
    this.actaIDs = [];
    this.initialized = true;

    /**
     * Load the data from session and local storage
     */
    if (typeof window !== "undefined") {
      const storage = { ...localStorage, ...sessionStorage };
      for (const storedObjectKey in storage) {
        if (storedObjectKey.slice(0, 8) === "__acta__") {
          const state = JSON.parse(storage[storedObjectKey]);
          if (state !== undefined && state !== null) {
            this.setState(storedObjectKey.slice(8), state);
          }
        }
      }
    }

    /**
     * Load the data from the store
     */
    for (const storedObjectKey in store) {
      this.setState(storedObjectKey.slice(8), store[storedObjectKey]);
    }

    if (typeof window !== "undefined") {
      window.addEventListener(
        "storage",
        (event) => {
          if (event.key && event.key.slice(0, 8) === "__acta__") {
            if (event.newValue !== null && event.newValue !== "" && event.newValue !== "null") {
              this.setState(event.key.slice(8), JSON.parse(event.newValue));
            } else {
              this.setState(event.key.slice(8), null);
            }
          }
        },
        false
      );
    }
  },

  /**
   * ensureActaID is a small utility function to
   * provide an unique id to all subscribers
   * (for events and states)
   */
  ensureActaID(context): void {
    /* Does nothing if there is already an ID */
    if (context.actaID) return;

    /* Insert a new ID in the list and attribute it to the context */
    this.actaIDs.unshift(`_${this.actaIDs.length}`);
    return (context.actaID = this.actaIDs[0]);
  },
};

/**
 * Initialize the listernes and attach to the window if not done yet
 */
if (!Acta.initialized) Acta.init({});
// @ts-ignore
if (typeof window !== "undefined" && !window.Acta) window.Acta = Acta;

export default Acta;
