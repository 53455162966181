import { Loader } from "@skillup/ui";
import { useEffect, useState } from "react";
import { getGroups } from "utils/User";
import { Group } from "../types";
import PortalLists from "./PortalLists";

export interface State {
  groups: Group[];
}

// Temporary component, should be removed once the new design
// is made available for all the skillup clients

const PortalListsLegacyWrapper = (props) => {
  const [groups, setGroups] = useState([]);
  useEffect(() => setGroups(getGroups()), [setGroups]);
  return groups.length ? <PortalLists groups={groups} {...props} /> : <Loader />;
};

export default PortalListsLegacyWrapper;
