import { atom, selector, selectorFamily, useRecoilState } from "recoil";

import { buildRequest } from "utils/buildRequest";
import type { SkillsRoutes } from "types/api";

type SkillsListRoute = SkillsRoutes["Skills.GetList"];
type SkillsData = SkillsListRoute["response"];
const skillsListRequestIDState = atom<number>({
  default: 1,
  key: "skillsListRequestID",
});

const skillsListSelector = selector<SkillsData["skills"]>({
  key: "skillsListSelector",
  get: async ({ get }) => {
    get(skillsListRequestIDState); // for refresh
    const request = buildRequest<SkillsListRoute>({
      method: "GET",
      path: "/competences/skills",
    });

    const { skills } = await request();
    return skills;
  },
});

export const skillsAtom = atom<SkillsData["skills"]>({
  key: "skills",
  default: skillsListSelector,
});
export type Skill = SkillsData["skills"][0];

export function useSkillsState() {
  const [skillsListRequestID, updateSkillsListRequestID] = useRecoilState(skillsListRequestIDState);
  return {
    refreshList: () => updateSkillsListRequestID(skillsListRequestID + 1),
  };
}

type SkillSelectorParams = { skillUuid: string };
export const getSkillSelector = selectorFamily<SkillsData["skills"][0], SkillSelectorParams>({
  key: "getSkill",
  get:
    ({ skillUuid }: SkillSelectorParams) =>
    ({ get }) => {
      const skills = get(skillsAtom);
      const skill = skills.find((s) => s.skill.uuid === skillUuid);
      return skill;
    },
});

type SkillCategoriesRoute = SkillsRoutes["Skills.Categories.GetList"];
type SkillCategories = SkillCategoriesRoute["response"]["categories"];
export const skillsCategoriesAtom = atom<SkillCategories>({
  key: "skillsCategories",
  default: undefined,
  effects_UNSTABLE: [
    ({ setSelf }) => {
      const request = buildRequest<SkillCategoriesRoute>({
        method: "GET",
        path: "/competences/skills/categories",
      })().then((data) => data?.categories);

      setSelf(request);
    },
  ],
});
